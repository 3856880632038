import { Grid } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import {
    FullWidthDatePicker,
    FullWidthRadioGroup,
    FullWidthTextField,
    RadioOption,
} from '../../../../../forms';
import { requiredValidator } from '../../../../../forms/validations';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const EditFormInfoDetails = () => {
    return (
        <Grid container spacing={5} justify="flex-start">
            <Grid item xs={6}>
                <FullWidthTextField name="serialNumber" validate={requiredValidator} />
            </Grid>
            <Grid item xs={6}>
                <FullWidthRadioGroup
                    name="serialNumberConsistent"
                    label="Serial Number is Consistent"
                    validate={requiredValidator}
                >
                    <RadioOption value="Yes" />
                    <RadioOption value="No" />
                </FullWidthRadioGroup>
            </Grid>
            <Grid item xs={6}>
                <FullWidthDatePicker name="dateSigned" validate={requiredValidator} disableFuture />
            </Grid>
        </Grid>
    );
};
