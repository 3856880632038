import React from 'react'; // eslint-disable-line

import { TextFieldProps } from '@material-ui/core/TextField';
import { Field, FieldProps } from 'react-final-form';

import { FieldSet, TextFieldTypes } from '../../';
import { MaskedInputProps } from '../text-field';
import { RichTextField } from '../rich-text-editor';

type Props = FieldProps<any, any> &
    TextFieldProps &
    MaskedInputProps & {
        type?: TextFieldTypes;
    };

export const FullWidthRichTextField: React.FunctionComponent<Props> = ({
    name,
    fullWidth = true,
    ...rest
}: Props) => {
    return (
        <FieldSet>
            <Field {...rest} name={name} fullWidth={fullWidth} component={RichTextField} />
        </FieldSet>
    );
};
