import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { ExecutorRequirementsStatus, getDocumentsRequirementsFromClaim } from '../reducer';
import { claimSubmit, Claim } from '../../../../../claim';
import { addRecordReduction } from '../../../../../redux/reductions';
import { RequiredDocument } from '../../../common/requirements-management/reducer';

type SubmitReturn = Promise<any>;
export type AddExecutorDocumentStatusRecord = (
    document: RequiredDocument,
    index: number
) => SubmitReturn;
type AddExecutorDocumentStatusRecordThunk = (
    document: RequiredDocument,
    index: number
) => ReduxThunkAction;

export const addAndSubmitExecutorDocumentAction: AddExecutorDocumentStatusRecordThunk = (
    document: RequiredDocument,
    index: number
) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, getUpdatedClaim(document, index));
    };
};

function getUpdatedClaim(document: RequiredDocument, index: number): (claim: Claim) => Claim {
    return (claim: Claim) => {
        const currentRequirements = getDocumentsRequirementsFromClaim(claim);
        const currentDocumentsStatus: RequiredDocument[] =
            currentRequirements && currentRequirements.executors
                ? currentRequirements.executors[index]['documents'] || []
                    ? currentRequirements.executors[index]['documents'] || []
                    : []
                : [];
        const currentExecutorsDocuments = (currentRequirements
            ? currentRequirements.executors
            : []) as ExecutorRequirementsStatus[];

        currentExecutorsDocuments[index].documents = addRecordReduction(
            currentDocumentsStatus,
            document
        );

        return {
            ...claim,
            documentsStatus: {
                ...currentRequirements,
                executors: currentExecutorsDocuments,
            },
        };
    };
}
