import { ReduxAction } from '../../redux/redux-actions';
import { SET_SELECTED_CLIENTS } from '../types';

export type SetSelectedClientsAction = ReduxAction<string[]>;

export type SetSelectedClients = (clientIds: string[]) => SetSelectedClientsAction;

export const setSelectedClients: SetSelectedClients = clientIds => {
    return {
        type: SET_SELECTED_CLIENTS,
        payload: clientIds,
    };
};
