import { ReactNode } from 'react';

export interface ErrorResult {
    errorMessage?: ReactNode;
    showError: boolean;
}

type Props = Partial<{
    active: boolean;
    data: Record<string, any>;
    dirty: boolean;
    dirtySinceLastSubmit: boolean;
    error: any;
    initial: any;
    invalid: boolean;
    pristine: boolean;
    submitError: any;
    submitFailed: boolean;
    submitSucceeded: boolean;
    touched: boolean;
    valid: boolean;
    visited: boolean;
}>;

export const noError: ErrorResult = { errorMessage: undefined, showError: false };
export function processMetaForErrors({
    error,
    touched,
    pristine,
    dirtySinceLastSubmit,
    submitError,
    submitFailed,
}: Props): ErrorResult {
    if (pristine && !submitFailed) {
        return noError;
    }
    if (submitError && !dirtySinceLastSubmit) {
        return { errorMessage: submitError, showError: true };
    }
    if (error && touched) {
        return { errorMessage: error, showError: true };
    }
    return noError;
}
