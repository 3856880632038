import React from 'react'; // eslint-disable-line

import { Divider, Grid, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { getClaim } from '../../../../../claim';
import { Section } from '../../../../../controls/section';
import { ContractOwner, LifeAssuredContract } from '../../../../../life-assured-details/reducer';
import { State } from '../../../../../redux/root-reducer';
import styles from '../../../../../styles/styles';
import { OrganizationDetailsView } from './organization-details';
import { CommunicationDetailsView } from './communication-details';
import { PersonalDetailsView } from './personal-details';
import { EntityType } from '../../../../../shared-types';

interface Props {
    contract: LifeAssuredContract;
    hideTaxNo?: boolean;
    versionNumber: string;
}
const policyOwnersView: React.FunctionComponent<Props> = ({
    contract,
    hideTaxNo,
    versionNumber,
}: Props) => {
    if (!contract || versionNumber < '1.2.0.0') {
        return null;
    }
    const individuals = contract.policyOwners.filter(co => co.ownerType === EntityType.Individual);
    const organizations = contract.policyOwners.filter(
        co => co.ownerType === EntityType.Organisation
    );
    return (
        <Section title={`Policy Owners`}>
            <IndividualsView hideTaxNo={hideTaxNo} individuals={individuals || []} />
            <OrganizationsView organizations={organizations || []} />
        </Section>
    );
};
interface IndividualsProps {
    readonly hideTaxNo?: boolean;
    readonly individuals: ContractOwner[];
}

const IndividualsView: React.FunctionComponent<IndividualsProps> = ({
    hideTaxNo,
    individuals,
}: IndividualsProps) => {
    return (
        <React.Fragment>
            {individuals.map((policyOwner, index) => (
                <React.Fragment key={`${policyOwner.id}`}>
                    {index > 0 && (
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    )}
                    <PersonalDetailsView
                        hideTaxno={hideTaxNo}
                        policyOwnerDetails={policyOwner.personalDetails}
                    />
                    <CommunicationDetailsView
                        addressDetails={policyOwner.addressDetails}
                        contactDetails={policyOwner.contactDetails}
                    />
                </React.Fragment>
            ))}
        </React.Fragment>
    );
};

interface OrganizationsProps {
    readonly organizations: ContractOwner[];
}

const OrganizationsView: React.FunctionComponent<OrganizationsProps> = ({
    organizations,
}: OrganizationsProps) => {
    return (
        <React.Fragment>
            {organizations.map((organization, index) => (
                <React.Fragment key={organization.organisation.name}>
                    {index > 0 && (
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    )}
                    <OrganizationDetailsView
                        policyOwnerOrganizationDetails={organization.organisation}
                    />
                    <CommunicationDetailsView
                        addressDetails={organization.addressDetails}
                        contactDetails={organization.contactDetails}
                        isOrganization
                    />
                </React.Fragment>
            ))}
        </React.Fragment>
    );
};

const mapStateToProps = (state: State) => {
    const claim = getClaim(state);
    const versionNumber = claim.version ? claim.version.createdInVersion || '1.1.0.0' : '1.1.0.0';
    return {
        versionNumber: versionNumber,
    };
};

export const PolicyOwnersView = connect(mapStateToProps, {})(withStyles(styles)(policyOwnersView));
