import { Theme, WithStyles } from '@material-ui/core';
import { createStyles, StyleRules } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import globalStyles from '../../../../../styles/styles';

export type StyledComponent = WithStyles<typeof styles>;

export default function styles(theme: Theme): StyleRules {
    return createStyles({
        ...globalStyles(theme),
        separatedGridItem: separatedGridItem(),
    });
}

function separatedGridItem(): CSSProperties {
    return {
        borderBottomStyle: 'solid',
        borderBottomColor: 'lightgray',
        '&:last-child': {
            borderBottomStyle: 'none',
        },
    };
}
