import { Omit } from 'lodash';
import { ReduxAction } from '../redux/redux-actions';
import {
    CLOSE_DIALOG,
    DialogDetails,
    OPEN_DIALOG,
    EmptyDialogContent,
    OPEN_TOAST,
    CLOSE_TOAST,
    VIEW_MODAL,
} from './';

type OpenDialogOptions = Omit<DialogDetails, 'isOpen'>;

export type OpenDialog = (options: OpenDialogOptions) => ReduxAction<DialogDetails>;
export const openDialogAction: OpenDialog = (options: OpenDialogOptions) => {
    return {
        type: OPEN_DIALOG,
        payload: {
            isOpen: true,
            content: options.content,
            yesAction: options.yesAction,
            noAction: options.noAction,
            canCancel: options.canCancel,
            cancelLabel: options.cancelLabel,
        },
    };
};

export type CloseDialog = () => ReduxAction<DialogDetails>;
export const closeDialogAction: CloseDialog = () => {
    return {
        type: CLOSE_DIALOG,
        payload: {
            isOpen: false,
            content: EmptyDialogContent,
        },
    };
};

export const openToastAction: any = (toastContent: any) => {
    return {
        type: OPEN_TOAST,
        payload: {
            isToastVisible: true,
            ...toastContent,
        },
    };
};

export const closeToastAction: any = () => {
    return {
        type: CLOSE_TOAST,
        payload: {
            isToastVisible: false,
        },
    };
};

export const viewModalAction: any = (options: any) => {
    return {
        type: VIEW_MODAL,
        payload: {
            isOpenModal: options.isOpen,
            form: options.form,
        },
    };
};
