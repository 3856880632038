import React from 'react'; // eslint-disable-line

import {
    Button,
    Grid,
    Icon,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    withStyles,
} from '@material-ui/core';
import { TableCellProps } from '@material-ui/core/TableCell';
import { connect } from 'react-redux';
import {
    OpenEditModal,
    openEditModalAction,
    OpenModal,
    openModalAction,
} from '../../../form-modal/actions';
import { GridColumn } from '../record-grid';
import { NestedGridColumns } from './nested-grid-columns';
import styles, { StyledComponent } from './styles';

interface Props extends StyledComponent {
    readonly tableHeading: React.ReactNode;
    readonly records: any[];
    readonly leadingColumns?: GridColumn[];
    readonly nestedColumns: NestedGridColumns;
    readonly trailingColumns?: GridColumn[];
    readonly canAdd?: (record: any) => boolean;
    readonly addView?: React.ReactNode;
    readonly viewEditView?: React.ReactNode;
    readonly openEditModal: OpenEditModal;
    readonly openModal: OpenModal;
}

const nestedRecordGrid: React.FunctionComponent<Props> = ({
    classes,
    tableHeading,
    records,
    leadingColumns = [],
    nestedColumns,
    trailingColumns = [],
    canAdd,
    addView,
    viewEditView,
    openEditModal,
    openModal,
}: StyledComponent & Props) => {
    const add = (recordIndex: number) => {
        openEditModal({
            modalContent: addView,
            modalData: {
                recordIndex,
            },
        });
    };

    const view = (parentRecordIndex: number, nestedRecords: any[], recordIndex: number) => {
        if (!viewEditView || nestedRecords.length === 0) {
            return;
        }

        openModal({
            modalContent: viewEditView,
            modalData: {
                parentRecordIndex,
                recordIndex,
                records: nestedRecords,
            },
        });
    };

    const headingColumns = leadingColumns.concat(nestedColumns.columns);

    return (
        <React.Fragment>
            {records.length > 0 && (
                <React.Fragment>
                    <Typography variant="subtitle1">{tableHeading}</Typography>
                    <Grid item lg={12} style={{ overflowX: 'auto' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {headingColumns.map(column => {
                                        return (
                                            <TableCell
                                                key={column.key}
                                                align={column.numeric ? 'right' : undefined}
                                                className={classes.cell}
                                            >
                                                {column.label}
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell className={classes.actionCell}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            {records && (
                                <TableBody>
                                    {records.map((row, entryIndex) => {
                                        const nestedRowCount = row[nestedColumns.key].length;
                                        const hover = !!viewEditView && nestedRowCount > 0;
                                        return (
                                            <React.Fragment key={entryIndex}>
                                                <TableRow hover={hover}>
                                                    {generateLeadingTrailingColumns(
                                                        row,
                                                        leadingColumns,
                                                        nestedRowCount
                                                    )}
                                                    {generateNestedColumns(
                                                        nestedColumns.columns,
                                                        entryIndex,
                                                        row[nestedColumns.key],
                                                        0
                                                    )}
                                                    {generateLeadingTrailingColumns(
                                                        row,
                                                        trailingColumns,
                                                        nestedRowCount
                                                    )}
                                                    <TableCell
                                                        className={classes.actionCell}
                                                        rowSpan={Math.max(
                                                            1,
                                                            row[nestedColumns.key].length
                                                        )}
                                                    >
                                                        <Tooltip title="Add">
                                                            <Button
                                                                id={'Add_' + entryIndex}
                                                                color="secondary"
                                                                className={classes.button}
                                                                onClick={() => add(entryIndex)}
                                                                disabled={!!canAdd && !canAdd(row)}
                                                            >
                                                                <Icon>add_circle</Icon>
                                                            </Button>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                                {(row[nestedColumns.key] as GridColumn[])
                                                    .slice(1)
                                                    .map((nestedRow, nestedRowIndex) => {
                                                        nestedRowIndex++;
                                                        return (
                                                            <TableRow
                                                                key={`${entryIndex}${nestedRowIndex}`}
                                                                hover={hover}
                                                            >
                                                                {generateNestedColumns(
                                                                    nestedColumns.columns,
                                                                    entryIndex,
                                                                    row[nestedColumns.key],
                                                                    nestedRowIndex
                                                                )}
                                                            </TableRow>
                                                        );
                                                    })}
                                            </React.Fragment>
                                        );
                                    })}
                                </TableBody>
                            )}
                        </Table>
                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment>
    );

    function generateLeadingTrailingColumns(
        row: any,
        columns: GridColumn[],
        nestedRowCount: number
    ) {
        const cellProps = {
            className: classes.leadingTrailingCell,
            rowSpan: Math.max(1, nestedRowCount),
        };
        return generateColumns(row, columns, cellProps);
    }

    function generateNestedColumns(
        columns: GridColumn[],
        rowIndex: number,
        nestedRows: any[],
        nestedRowIndex: number
    ) {
        const nestedRow = nestedRows[nestedRowIndex];
        const onClick = () => view(rowIndex, nestedRows, nestedRowIndex);
        const cellProps = {
            onClick,
        };

        return generateColumns(nestedRow, columns, cellProps);
    }

    function generateColumns(row: any, columns: GridColumn[], cellProps?: Partial<TableCellProps>) {
        return columns.map(column => {
            return (
                <TableCell
                    key={column.key}
                    align={column.numeric ? 'right' : undefined}
                    className={classes.cell}
                    style={column.text ? { wordBreak: 'break-word' } : undefined}
                    {...cellProps}
                >
                    {getValue(column, row)}
                </TableCell>
            );
        });
    }

    function getValue(column: GridColumn, record: any) {
        if (!record) {
            return undefined;
        }

        const value = column.value ? column.value(record) : record[column.key];
        return column.format ? column.format(value) : value;
    }
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    openEditModal: openEditModalAction,
    openModal: openModalAction,
};

export const NestedRecordGrid = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(nestedRecordGrid));
