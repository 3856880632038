import { Button, Grid, Tooltip, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { OpenModal, openModalAction } from '../../../../form-modal/actions';
import { FormModalView } from '../../../../form-modal/views/form-modal';
import { MedicalOfficerInputView } from '../../../../medical-officer-input';
import { MedicalReferral } from '../../../../medical-officer-input/reducer';
import { getDisplayDate } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import { LabeledValue } from '../../../../controls/labeled-value';
import { State } from '../../../../redux/root-reducer';
import { ButtonWrapper } from '../../../../forms';

type Props = StyledComponent & {
    isReadOnly: boolean;
    medicalReferral: MedicalReferral;
    openModal: OpenModal;
};
const medicalReferralView: React.FunctionComponent<Props> = ({
    isReadOnly,
    medicalReferral,
    openModal,
}: Props) => {
    return (
        <Grid container spacing={2} justify="flex-start">
            <Grid item xs={12}>
                <Grid container spacing={0} justify="space-between">
                    <Grid container spacing={0} item xs={10} lg={11}>
                        <Grid item xs={6} lg={3}>
                            <LabeledValue
                                label="Date Referred"
                                value={getDisplayDate(medicalReferral.dateReferred)}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <LabeledValue label="Referred By" value={medicalReferral.referredBy} />
                        </Grid>
                    </Grid>
                    <ButtonWrapper>
                        <Grid item xs={2} lg={1} style={{ textAlign: 'right' }}>
                            <Tooltip title="Edit Medical Officer">
                                <Button
                                    id="EditMedicalOfficer"
                                    color="secondary"
                                    onClick={() => edit()}
                                >
                                    <Edit />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </ButtonWrapper>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <LabeledValue
                    label="Reason for Referral"
                    value={medicalReferral.reasonsForReferral}
                />
            </Grid>
            <Grid container spacing={0} item xs={10} lg={11}>
                <Grid item xs={6} lg={3}>
                    <LabeledValue
                        label="Date Feedback Received"
                        value={getDisplayDate(medicalReferral.dateFeedbackReceived)}
                    />
                </Grid>
                <Grid item xs={6} lg={3}>
                    <LabeledValue label="Assessor" value={medicalReferral.assessorName} />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <LabeledValue label="Feedback Received" value={medicalReferral.feedbackReceived} />
            </Grid>
        </Grid>
    );

    function edit() {
        openModal({
            modalContent: <FormModalView formView={MedicalOfficerInputView} />,
        });
    }
};

const mapStateToProps = (state: State) => ({
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = {
    openModal: openModalAction,
};

export const MedicalReferralView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(medicalReferralView));
