export const AccountsLookup = [
    {
        account: 'Evolve',
        bankingDetails: {
            accountType: 'Cheque',
            accountHoldersName: 'Liberty',
            accountNumber: 200358286,
            bankName: 'STANDARD BANK',
            bankBranch: '',
            bankAccountStatus: '',
            branchCode: '051001',
        },
    },

    {
        account: 'Bold',
        bankingDetails: {
            accountType: 'Cheque',
            accountHoldersName: 'Liberty',
            accountNumber: parseInt('002341255'),
            bankName: 'STANDARD BANK',
            bankBranch: '',
            bankAccountStatus: '',
            branchCode: '051001',
        },
    },

    {
        account: 'Gateway',
        bankingDetails: {
            accountType: 'Cheque',
            accountHoldersName: 'Liberty',
            accountNumber: 202649180,
            bankName: 'STANDARD BANK',
            bankBranch: '',
            bankAccountStatus: '',
            branchCode: '051001',
        },
    },

    {
        account: 'Linked life',
        bankingDetails: {
            accountType: 'Cheque',
            accountHoldersName: 'Liberty',
            accountNumber: 865338,
            bankName: 'STANDARD BANK',
            bankBranch: '',
            bankAccountStatus: '',
            branchCode: '051001',
        },
    },

    {
        account: 'LPB Product',
        bankingDetails: {
            accountType: 'Cheque',
            accountHoldersName: 'Liberty',
            accountNumber: 2312832,
            bankName: 'STANDARD BANK',
            bankBranch: '',
            bankAccountStatus: '',
            branchCode: '051001',
        },
    },
];
