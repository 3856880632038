import { ErrorObject, SyncValidator } from '.';

export function ValidateAny(
    validator: SyncValidator,
    values: any,
    ...fields: string[]
): ErrorObject {
    const errors: ErrorObject = {};
    for (const field of fields) {
        const validationError = validator(values[field]);
        if (!validationError) {
            return {};
        }
        errors[field] = validationError;
    }
    return errors;
}
