import React from 'react'; // eslint-disable-line

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    withStyles,
} from '@material-ui/core';
import createDecorator from 'final-form-calculate';
import { sum } from 'lodash';
import { Field, FieldRenderProps } from 'react-final-form';
import { PercentageInput, processMetaForErrors } from '../../forms';
import styles, { StyledComponent } from './styles';

interface HasDutyBreakdown {
    dutyBreakdown: { admin: number; manual: number; supervisory: number; travel: number };
}

export const occupationalDutiesBreakdownTableTotalDecorator = createDecorator(
    {
        field: 'dutyBreakdown.admin',
        updates: {
            'dutyBreakdown.total': getTotal,
        },
    },
    {
        field: 'dutyBreakdown.manual',
        updates: {
            'dutyBreakdown.total': getTotal,
        },
    },
    {
        field: 'dutyBreakdown.supervisory',
        updates: {
            'dutyBreakdown.total': getTotal,
        },
    },
    {
        field: 'dutyBreakdown.travel',
        updates: {
            'dutyBreakdown.total': getTotal,
        },
    }
);

function getTotal(_: any, allValues?: any): any {
    if (!allValues) {
        return undefined;
    }

    const withDutyBreakdown = allValues as HasDutyBreakdown;

    return sum([
        Number(withDutyBreakdown.dutyBreakdown.admin || 0),
        Number(withDutyBreakdown.dutyBreakdown.manual || 0),
        Number(withDutyBreakdown.dutyBreakdown.supervisory || 0),
        Number(withDutyBreakdown.dutyBreakdown.travel || 0),
    ]);
}

type Props = StyledComponent;
const occupationalDutiesBreakdownTable: React.FunctionComponent<Props> = ({ classes }: Props) => {
    return (
        <React.Fragment>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.headerRow}>Occupational Duties</TableCell>
                        <TableCell className={classes.headerRow} align="right">
                            Percentage
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow className={classes.row}>
                        <TableCell component="th" scope="row">
                            Administrative Duties
                        </TableCell>
                        <TableCell align="right">
                            <PercentageInput name="dutyBreakdown.admin" validate={validate} />
                        </TableCell>
                    </TableRow>
                    <TableRow className={classes.row}>
                        <TableCell component="th" scope="row">
                            Supervisory Duties
                        </TableCell>
                        <TableCell align="right">
                            <PercentageInput name="dutyBreakdown.supervisory" validate={validate} />
                        </TableCell>
                    </TableRow>
                    <TableRow className={classes.row}>
                        <TableCell component="th" scope="row">
                            Manual Duties
                        </TableCell>
                        <TableCell align="right">
                            <PercentageInput name="dutyBreakdown.manual" validate={validate} />
                        </TableCell>
                    </TableRow>
                    <TableRow className={classes.row}>
                        <TableCell component="th" scope="row">
                            Travel Duties
                        </TableCell>
                        <TableCell align="right">
                            <PercentageInput name="dutyBreakdown.travel" validate={validate} />
                        </TableCell>
                    </TableRow>
                    <TableRow className={classes.totalRow}>
                        <TableCell component="th" scope="row">
                            Total
                        </TableCell>
                        <TableCell align="right">
                            <PercentageInput
                                name="dutyBreakdown.total"
                                readOnly
                                validate={validate}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Field name="dutyBreakdown.total" component={dutyBreakDownError} />
        </React.Fragment>
    );
};

const dutyBreakDownError: React.FunctionComponent<FieldRenderProps<any, any>> = ({
    meta,
}: FieldRenderProps<any, any>) => {
    const errorDetails = processMetaForErrors(meta);
    if (!errorDetails.showError) {
        return null;
    }

    return (
        <Typography align="right" color="error">
            {errorDetails.errorMessage}
        </Typography>
    );
};

function validate(_: number, values: any) {
    const total = !values.dutyBreakdown
        ? 0
        : sum([
              Number(values.dutyBreakdown.admin || 0),
              Number(values.dutyBreakdown.manual || 0),
              Number(values.dutyBreakdown.supervisory || 0),
              Number(values.dutyBreakdown.travel || 0),
          ]);

    if (total !== 100) {
        return 'Total must equal exactly 100';
    }

    return undefined;
}

export const OccupationalDutiesBreakdownTable = withStyles(styles)(
    occupationalDutiesBreakdownTable
);
