import React from 'react'; // eslint-disable-line
import { Divider, Paper, withStyles } from '@material-ui/core';
import styles, { StyledComponent } from '../../../../styles/styles';
import { AssessmentDecision } from '../../assessment-details-reducer';
import { ERBAssessmentDecisionView } from './early-retirement-assessment-decision';

interface OwnProps {
    policyNumber: string;
    commencementDate?: Date;
    effectiveDate?: Date;
    decisions: AssessmentDecision[];
}

type Props = OwnProps & StyledComponent;
const investmentAssessmentView: React.FunctionComponent<Props> = ({
    decisions,
    classes,
}: Props) => {
    if (!(decisions && decisions.length > 0)) {
        return <Divider className={classes.marginBottom} />;
    }
    return (
        <Paper className={classes.paper} style={{ marginTop: 2 }}>
            {decisions.map((assessmentDecision, assessmentIndex) => {
                return (
                    <React.Fragment key={assessmentDecision.id}>
                        <ERBAssessmentDecisionView assessmentDecision={assessmentDecision} />
                        {assessmentIndex !== decisions.length - 1 && (
                            <Divider
                                key={`divider_${assessmentDecision.id}`}
                                className={classes.topAndBottomMargin}
                            />
                        )}
                    </React.Fragment>
                );
            })}
        </Paper>
    );
};

export const ERBAssessmentView = withStyles(styles)(investmentAssessmentView);
