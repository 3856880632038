import { Grid, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import {
    Condition,
    FullWidthRadioGroup,
    FullWidthSearchSelectField,
    FullWidthTextField,
    RadioOption,
    True,
    VirtualizedSearchSelectField,
    WhenFieldChanges,
} from '../../../../../forms';
import { requiredValidator } from '../../../../../forms/validations';
import { CodeList, UniversalBranch } from '../../../../../lookups';
import { LoadBankBranches, loadBankBranchesAction } from '../../../../../lookups/bank-branch-codes';
import { State } from '../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { FormDetails } from '../../../living-lifestyle/form-details/form-details-reducer';
import { PaymentBeneficiary } from '../../../../../shared-types';
import { Field } from 'react-final-form';
import { NumberMask } from '../../../../../forms/input-masks';

interface BankingProps {
    readonly isAnOrganisation?: boolean;
    readonly prefix?: string;
    readonly formDetails: FormDetails;
}

const bankingDetailsPayment: React.FunctionComponent<BankingProps & StyledComponent> = ({
    classes,
    formDetails,
    ...rest
}: BankingProps & StyledComponent) => {
    return (
        <Grid item xs={6} sm={12}>
            <BankingDetailsEdit formDetails={formDetails} {...rest} />
        </Grid>
    );
};

interface EditBankingProp extends BankingProps {
    readonly bankNames: CodeList<string>;
    readonly loadBankBranches: LoadBankBranches;
    readonly universalBranches: UniversalBranch[];
    readonly formDetails: FormDetails;
}

const defaultBranches: UniversalBranch[] = [];

const mapStateToProps = (state: State) => ({
    bankNames: state.claimSession.lookups.bankNames,
    universalBranches: state.claimSession.lookups.universalBranches,
});

const mapDispatchToProps = {
    loadBankBranches: loadBankBranchesAction,
};

export const BankingDetailsEdit = connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ({
        isAnOrganisation,
        prefix = '',
        bankNames,
        universalBranches,
        loadBankBranches,
        formDetails,
    }: EditBankingProp) => {
        const [branchCodes, setBranchCodes] = useState(defaultBranches);
        const [loadBranches, setLoadBranches] = useState(false);
        const [loadingBranches, setLoadingBranches] = useState(false);
        const getBranches = (bank: string) => {
            if (loadBranches && !loadingBranches) {
                setLoadingBranches(true);
                loadBankBranches(bank)
                    .then(result => {
                        setBranchCodes(result);
                    })
                    .finally(() => {
                        setLoadBranches(false);
                        setLoadingBranches(false);
                    });
            }
        };

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [isElementDisabled, setIsElementDisabled] = useState(false);

        const handlePaymentBeneficiaryChange = (event: string) => {
            if (event === 'Cessionary') {
                setIsElementDisabled(true);
            } else {
                setIsElementDisabled(false);
            }
        };
        const paymentBeneficiary = formDetails?.payment?.information?.paymentBeneficiary;
        useEffect(() => {
            if (paymentBeneficiary === 'Cessionary') {
                setIsElementDisabled(true);
            } else {
                setIsElementDisabled(false);
            }
        }, [paymentBeneficiary]);

        return (
            <>
                <Grid container spacing={5} justify="flex-start" style={{ paddingBottom: 50 }}>
                    <Grid item xs={6}>
                        <FullWidthSearchSelectField
                            label="Payment must be made into the account of"
                            name={`${prefix}paymentBeneficiary`}
                            validate={requiredValidator}
                            options={PaymentBeneficiary}
                            onChange={handlePaymentBeneficiaryChange}
                        />

                        <WhenFieldChanges
                            field={`${prefix}paymentBeneficiary`}
                            becomes={'Cessionary'}
                            set={`${prefix}bankName`}
                            to={''}
                        />
                        <WhenFieldChanges
                            field={`${prefix}paymentBeneficiary`}
                            becomes={'Cessionary'}
                            set={`${prefix}branchCode`}
                            to={''}
                        />
                        <WhenFieldChanges
                            field={`${prefix}paymentBeneficiary`}
                            becomes={'Cessionary'}
                            set={`${prefix}accountType`}
                            to={''}
                        />
                        <WhenFieldChanges
                            field={`${prefix}paymentBeneficiary`}
                            becomes={'Cessionary'}
                            set={`${prefix}branchName`}
                            to={''}
                        />
                        <WhenFieldChanges
                            field={`${prefix}paymentBeneficiary`}
                            becomes={'Cessionary'}
                            set={`${prefix}accountNumber`}
                            to={''}
                        />
                    </Grid>
                    <Condition when={`${prefix}paymentBeneficiary`} is={'Policyholder'}>
                        <True>
                            <Grid item xs={6}>
                                <VirtualizedSearchSelectField
                                    name={`${prefix}bankName`}
                                    validate={requiredValidator}
                                    options={bankNames}
                                    matchPos="start"
                                    disabled={isElementDisabled}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name={`${prefix}bankName`}
                                    subscription={{ value: true }}
                                    component={({ input: { value: bankName } }) => {
                                        const universalBranch = universalBranches.find(
                                            uc => uc.bankName === bankName
                                        );
                                        if (universalBranch) {
                                            const currentBranch =
                                                branchCodes &&
                                                branchCodes.find(cb => cb.bankName === bankName);
                                            if (!currentBranch) {
                                                setBranchCodes([universalBranch]);
                                            }
                                        } else {
                                            getBranches(bankName);
                                        }
                                        return (
                                            <VirtualizedSearchSelectField
                                                name={`${prefix}branchCode`}
                                                options={branchCodes}
                                                label="Branch Name"
                                                validate={requiredValidator}
                                                disabled={
                                                    universalBranch && branchCodes.length === 1
                                                }
                                                matchPos="start"
                                            />
                                        );
                                    }}
                                />
                                <WhenFieldChanges
                                    field={`${prefix}bankName`}
                                    set={`${prefix}branchCode`}
                                    to={(bankName: string) => {
                                        const universalBranch = universalBranches.find(
                                            uc => uc.bankName === bankName
                                        );
                                        if (universalBranch) {
                                            return universalBranch.value;
                                        }
                                        setLoadBranches(true);
                                        return null;
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FullWidthTextField
                                    label="Account Number"
                                    name={`${prefix}accountNumber`}
                                    mask={NumberMask}
                                    disabled={isElementDisabled}
                                    validate={requiredValidator}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FullWidthRadioGroup
                                    name={`${prefix}accountType`}
                                    validate={requiredValidator}
                                    disabled={isElementDisabled}
                                >
                                    <RadioOption value="Current" />
                                    <RadioOption value="Savings" />
                                    <RadioOption value="Transmission" />
                                </FullWidthRadioGroup>
                            </Grid>
                            <Grid item xs={6}>
                                <FullWidthTextField
                                    label="Branch Code"
                                    name={`${prefix}branchCode`}
                                    disabled={isElementDisabled}
                                />
                            </Grid>
                        </True>
                    </Condition>
                    <Condition when={`${prefix}paymentBeneficiary`} is={'Premium Payer'}>
                        <True>
                            <Grid item xs={6}>
                                <VirtualizedSearchSelectField
                                    name={`${prefix}bankName`}
                                    validate={requiredValidator}
                                    options={bankNames}
                                    matchPos="start"
                                    disabled={isElementDisabled}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name={`${prefix}bankName`}
                                    subscription={{ value: true }}
                                    component={({ input: { value: bankName } }) => {
                                        const universalBranch = universalBranches.find(
                                            uc => uc.bankName === bankName
                                        );
                                        if (universalBranch) {
                                            const currentBranch =
                                                branchCodes &&
                                                branchCodes.find(cb => cb.bankName === bankName);
                                            if (!currentBranch) {
                                                setBranchCodes([universalBranch]);
                                            }
                                        } else {
                                            getBranches(bankName);
                                        }
                                        return (
                                            <VirtualizedSearchSelectField
                                                name={`${prefix}branchCode`}
                                                options={branchCodes}
                                                label="Branch Name"
                                                validate={requiredValidator}
                                                disabled={
                                                    universalBranch && branchCodes.length === 1
                                                }
                                                matchPos="start"
                                            />
                                        );
                                    }}
                                />
                                <WhenFieldChanges
                                    field={`${prefix}bankName`}
                                    set={`${prefix}branchCode`}
                                    to={(bankName: string) => {
                                        const universalBranch = universalBranches.find(
                                            uc => uc.bankName === bankName
                                        );
                                        if (universalBranch) {
                                            return universalBranch.value;
                                        }
                                        setLoadBranches(true);
                                        return null;
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FullWidthTextField
                                    label="Account Number"
                                    name={`${prefix}accountNumber`}
                                    disabled={isElementDisabled}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FullWidthRadioGroup
                                    name={`${prefix}accountType`}
                                    validate={requiredValidator}
                                    disabled={isElementDisabled}
                                >
                                    <RadioOption value="Current" />
                                    <RadioOption value="Savings" />
                                    <RadioOption value="Transmission" />
                                </FullWidthRadioGroup>
                            </Grid>
                            <Grid item xs={6}>
                                <FullWidthTextField
                                    label="Branch Code"
                                    name={`${prefix}branchCode`}
                                    disabled={isElementDisabled}
                                />
                            </Grid>
                        </True>
                    </Condition>
                </Grid>
            </>
        );
    }
);

export const BankingDetailsPayment = withStyles(styles)(bankingDetailsPayment);
