import { claimSubmit, Claim } from '../../../../../claim';
import { addRecordReduction } from '../../../../../redux/reductions';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import {
    getRequirementsFromClaim,
    RequiredDocument,
} from '../../../common/requirements-management/reducer';
import { RADeathRequirements } from '../reducer';

type SubmitReturn = Promise<any>;
export type AddLifeAssuredDocumentRecord = (documents: RequiredDocument) => SubmitReturn;
type AddLifeAssureDocumentRecordThunk = (documents: RequiredDocument) => ReduxThunkAction;

export const addAndSubmitLifeAssureDocumentAction: AddLifeAssureDocumentRecordThunk = (
    documents: RequiredDocument
) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, getUpdatedClaim(documents));
    };
};

function getUpdatedClaim(document: RequiredDocument): (claim: Claim) => Claim {
    return (claim: Claim) => {
        const currentRequirements = getRequirementsFromClaim(claim) as RADeathRequirements;
        currentRequirements.lifeAssured.documents = addRecordReduction(
            currentRequirements.lifeAssured.documents,
            document
        );
        return {
            ...claim,
            requirements: {
                ...currentRequirements,
            },
        };
    };
}
