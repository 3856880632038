import React from 'react'; // eslint-disable-line
import { ContactPersonView } from '../common/contact-person';
import { LifeAssuredView } from '../common/life-assured';
import { PolicySummaryView } from '../common/policy-summary';
import { Synopsis } from '../common/synopsis/synopsis';
import { UnderwritingView } from '../common/underwriting';
import { AuthGuard } from '../../../auth/authGuard';

export const LivingLifestyleSynopsis = () => {
    const hasUnderwritterAccess = AuthGuard(['Agent']);

    return (
        <Synopsis>
            <ContactPersonView isUnderWriting={true} />
            <LifeAssuredView isUnderWriting={true} />
            <PolicySummaryView isUnderWriting={true} />
            <UnderwritingView isUnderWriting={hasUnderwritterAccess()} />
        </Synopsis>
    );
};
