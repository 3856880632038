import { claimSubmit, Claim } from '../../../../../claim';
import { addRecordReduction } from '../../../../../redux/reductions';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import {
    getRequirementsFromClaim,
    RequiredDocument,
} from '../../../common/requirements-management/reducer';
import { RADeathRequirements } from '../reducer';

type SubmitReturn = Promise<any>;
export type AddDependantsRequiredDocument = (
    document: RequiredDocument,
    index: number
) => SubmitReturn;
type AddDependantsRequiredDocumentThunk = (
    document: RequiredDocument,
    index: number
) => ReduxThunkAction;

export const addAndSubmitDependantsRequirementsAction: AddDependantsRequiredDocumentThunk = (
    document: RequiredDocument,
    index: number
) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, getUpdatedDependantsRequirements(document, index));
    };
};

function getUpdatedDependantsRequirements(
    document: RequiredDocument,
    index: number
): (claim: Claim) => Claim {
    return (claim: Claim) => {
        const currentRequirements = getRequirementsFromClaim(claim) as RADeathRequirements;
        const currentDocuments: RequiredDocument[] =
            currentRequirements.dependants[index]['documents'];
        const currentDependantRequirements = currentRequirements.dependants;
        currentDependantRequirements[index].documents = addRecordReduction(
            currentDocuments,
            document
        );
        return {
            ...claim,
            requirements: {
                ...currentRequirements,
                dependants: currentDependantRequirements,
            },
        };
    };
}
