import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import {
    changeRecordFromAddChangeSet,
    changeRecordFromChangeSet,
    changeRecordFromDeleteChangeSet,
} from '../claim-audit-trail-functions';
import { addAuditTrailDetailsView } from '../common-views/claim-audit-trail-field-details';
import { mapSectionView } from '../common-views/claim-audit-trail-wrapper';

interface Props {
    changeSet: Record<string, any>;
}

const auditTrailSimpleView: React.FunctionComponent<Props> = ({ changeSet }: Props) => {
    const returnView: React.ReactNode[] = [];
    const deathClaimDetails = 'deathClaimDetails';
    if (Array.isArray(changeSet) && changeSet[0] === null) {
        addAuditTrailDetailsView(returnView, changeRecordFromAddChangeSet(changeSet[1]));
    } else if (Array.isArray(changeSet) && changeSet[1] === null) {
        addAuditTrailDetailsView(returnView, changeRecordFromDeleteChangeSet(changeSet[0]));
    } else if (
        Array.isArray(changeSet[deathClaimDetails]) &&
        changeSet[deathClaimDetails][0] === null
    ) {
        addAuditTrailDetailsView(
            returnView,
            changeRecordFromAddChangeSet(changeSet[deathClaimDetails][1])
        );
    } else if (
        !Array.isArray(changeSet[deathClaimDetails]) &&
        changeSet[deathClaimDetails] !== null &&
        changeSet[deathClaimDetails] !== undefined
    ) {
        addAuditTrailDetailsView(
            returnView,
            changeRecordFromChangeSet(changeSet[deathClaimDetails])
        );
    } else {
        addAuditTrailDetailsView(returnView, changeRecordFromChangeSet(changeSet));
    }

    return mapSectionView(returnView);
};

export const AuditTrailSimpleView = withStyles(styles)(auditTrailSimpleView);
