import { withStyles, Grid } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { ExpansionPanel } from '../../../../forms/form-components/expansion-panel/expansion-panel';
import { Claim, getClaim } from '../../../../claim/reducers/claim-reducer';
import { getObjectWarning } from '../../../../shared-functions';
import { PersonalDetailsView } from '../../common/life-assured/views/personal-details';
import { PreviousPaymentsView } from '../../common/life-assured/views/previous-payments';
import { AstuteInformation } from '../../common/life-assured/views/astute-information';
import { OriginalDoctorsDetailsView } from '../../common/life-assured/views/original-doctors-details';
import { LifeAssuredPortfolio } from '../../common/life-assured/views/life-assured-portfolio';
import { CurrentDoctorsDetailsView } from './current-doctors-details';
import { getLifeAssuredDetails } from '../../../../life-assured-details/reducer';

type Props = StyledComponent & {
    claim: Claim;
    claimLifeNotificationsUpdated?: string;
};
const lifeAssuredView: React.FunctionComponent<Props> = ({
    claim,
    claimLifeNotificationsUpdated,
}: Props) => {
    let notifier;
    if (claim.validationErrors && claim.validationErrors.includes('LifeAssuredDetails')) {
        notifier = getObjectWarning(claim.validationErrors, claim.validationErrors);
    } else if (claimLifeNotificationsUpdated === 'No') {
        notifier = 'Warning';
    }
    return (
        <ExpansionPanel name="LifeAssuredDetails" notifier={notifier}>
            <Grid container spacing={3} justify="flex-start">
                <PersonalDetailsView />
                <PreviousPaymentsView />
                <AstuteInformation />
                <OriginalDoctorsDetailsView />
                <CurrentDoctorsDetailsView />
                <LifeAssuredPortfolio />
            </Grid>
        </ExpansionPanel>
    );
};

const mapStateToProps = (state: State) => ({
    claim: getClaim(state),
    claimLifeNotificationsUpdated: getLifeAssuredDetails(state).claimLifeNotificationsUpdated,
});

export const LifeAssuredView = connect(mapStateToProps, {})(withStyles(styles)(lifeAssuredView));
