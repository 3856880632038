import { Button, Grid, Paper, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { getDeathClaimEventDetails } from '../../../../../claim/reducers/claim-reducers/death-claim-reducer';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { OpenModal, openModalAction } from '../../../../../form-modal/actions';
import { FormModalView } from '../../../../../form-modal/views/form-modal';
import { ButtonWrapper } from '../../../../../forms';
import { State } from '../../../../../redux/root-reducer';
import { getCauseOfdeathDescription, getDisplayDate } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { DeathClaimDetails } from '../reducer';
import { ClaimDetailsEditModal } from './claim-details-edit-modal';

type Props = StyledComponent & {
    openModal: OpenModal;
    claimDetails: DeathClaimDetails;
    isReadOnly: boolean;
};
const claimDetailsView: React.FunctionComponent<Props> = ({
    classes,
    claimDetails,
    openModal,
    isReadOnly,
}: Props) => {
    const isReportedToPolice = claimDetails.reportedToPolice ? 'Yes' : 'No';
    const edit = () => {
        openModal({
            modalContent: <FormModalView formView={ClaimDetailsEditModal} />,
        });
    };
    return (
        <React.Fragment>
            <Grid container justify="flex-start" style={{ marginTop: '4px' }}>
                <Grid item xs={11}>
                    <Typography style={{ marginTop: '8px' }} variant="subtitle2">
                        Claim Details
                    </Typography>
                </Grid>
                <ButtonWrapper>
                    <Grid item xs={1} container justify="flex-end">
                        <Tooltip title="Edit Claim Details">
                            <Button id="EditDeathClaimDetails" color="secondary" onClick={edit}>
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            </Grid>
            <Paper className={classes.paper}>
                <Grid container justify="flex-start">
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="Date of death"
                            value={getDisplayDate(claimDetails.dateOfEvent)}
                        />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue label="Place of death" value={claimDetails.placeOfDeath} />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="Place of death postal code"
                            value={claimDetails.postalCode}
                        />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="Date claim reported"
                            value={getDisplayDate(claimDetails.dateClaimReported)}
                        />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue label="Type of death" value={claimDetails.typeOfDeath} />
                    </Grid>
                    {claimDetails.causeOfDeath !== '799' && claimDetails.causeOfDeath !== '798' && (
                        <Grid item xs={4} lg={3}>
                            <LabeledValue
                                label="Cause of death"
                                value={getCauseOfdeathDescription(claimDetails.causeOfDeath)}
                            />
                        </Grid>
                    )}
                    {(claimDetails.causeOfDeath === '799' ||
                        claimDetails.causeOfDeath === '798') && (
                        <Grid item xs={4} lg={3}>
                            <LabeledValue
                                label="Cause of death"
                                value={claimDetails.causeOfDeathDescription}
                            />
                        </Grid>
                    )}
                    <Grid item xs={4} lg={3}>
                        <LabeledValue label="Reported to the police?" value={isReportedToPolice} />
                    </Grid>
                    {isReportedToPolice === 'Yes' && (
                        <Grid item xs={4} lg={3}>
                            <LabeledValue label="Case number" value={claimDetails.caseNumber} />
                        </Grid>
                    )}
                    {claimDetails.dateOfWill && (
                        <Grid item xs={4} lg={3}>
                            <LabeledValue
                                label="Date of Will"
                                value={getDisplayDate(claimDetails.dateOfWill)}
                            />
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

const mapStateToProps = (state: State) => ({
    claimDetails: getDeathClaimEventDetails(state).deathClaimDetails || {},
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = { openModal: openModalAction };

export const ClaimDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(claimDetailsView));
