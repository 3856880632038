import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { getClaim } from '../../../../../../claim';
import { FormErrors, GenericForm } from '../../../../../../forms';
import {
    ExtendedPersonalDetails,
    UpdatePersonalDetails,
    updatePersonalDetailsAction,
} from '../../actions/update-personal-details';
import { MedicalAidDetails } from '../../../../../../life-assured-details/reducer';
import { MedicalAidDetailsView } from '../../../../../../life-assured-details/views/life-assured-medical-aid-details';
import { State } from '../../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { OnSubmitSuccessProps } from '../../../../../../workflow-navigation';
import { RADeathClaim } from '../../../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { PreviousMarriage, RADeathLifeAssured, defaultRADeathLifeAssured } from '../../reducer';
import { PersonalDetailsEditView } from './personal-details-edit-view';

interface Props extends OnSubmitSuccessProps, StyledComponent {
    readonly dateOfDeath?: Date;
    readonly lifeAssuredDetails: RADeathLifeAssured;
    readonly updatePersonalDetails: UpdatePersonalDetails;
}

const PersonalDetailsForm = GenericForm<{
    medicalAidDetails: MedicalAidDetails;
    personalDetails: ExtendedPersonalDetails;
    previouslyMarried: string;
    previousMarriages: PreviousMarriage[];
}>();

const personalDetailsEditModal: React.FunctionComponent<Props> = ({
    dateOfDeath,
    lifeAssuredDetails,
    updatePersonalDetails,
    ...rest
}: Props) => {
    const extendedPersonalDetails = {
        ...lifeAssuredDetails.personalDetails,
        dateOfDeath: dateOfDeath,
    };
    return (
        <PersonalDetailsForm
            onSubmit={updatePersonalDetails}
            initialValues={{
                ...lifeAssuredDetails,
                personalDetails: extendedPersonalDetails,
            }}
            {...rest}
        >
            <PersonalDetailsEditView />
            <MedicalAidDetailsView />
            <FormErrors />
        </PersonalDetailsForm>
    );
};

const mapStateToProps = (state: State) => {
    const raClaim = getClaim(state) as RADeathClaim;
    const claimDetails = raClaim.claimDetails;
    const dateOfDeath =
        claimDetails &&
        claimDetails.claimEventDetails &&
        claimDetails.claimEventDetails.dateOfEvent;

    return {
        dateOfDeath: dateOfDeath,
        lifeAssuredDetails: raClaim.lifeAssuredDetails || defaultRADeathLifeAssured,
    };
};

const mapDispatchToProps = {
    updatePersonalDetails: updatePersonalDetailsAction,
};

export const PersonalDetailsEditModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(personalDetailsEditModal));
