import React from 'react'; // eslint-disable-line

import { Grid, Paper, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { PolicyStatusCodes } from '../../codes/policy-status';
import {
    FormErrors,
    FormHeading,
    FullWidthDatePicker,
    FullWidthSearchSelectField,
    FullWidthTextField,
    GenericForm,
} from '../../forms';
import { conditionalRequired } from '../../forms/validations';
import { State } from '../../redux/root-reducer';
import styles, { StyledComponent } from '../../styles/styles';
import { OnSubmitSuccessProps } from '../../workflow-navigation';
import { Submit, submitAction } from '../actions/submit';
import { getIncomeManagementPlan, IncomeManagementPlan } from '../reducer';

type Props = OnSubmitSuccessProps &
    StyledComponent & {
        submit: Submit;
        incomeManagementPlan: IncomeManagementPlan;
    };

const Form = GenericForm<IncomeManagementPlan>();
const incomeManagementPlanForm: React.FunctionComponent<Props> = ({
    classes,
    submit,
    incomeManagementPlan,
    ...rest
}: Props) => {
    const onSubmit = (values: any) => {
        return submit(values);
    };
    return (
        <Form onSubmit={onSubmit} initialValues={incomeManagementPlan} {...rest}>
            <InnerForm classes={classes} />
            <FormErrors />
        </Form>
    );
};

const InnerForm: React.FunctionComponent<StyledComponent> = ({ classes }: StyledComponent) => {
    return (
        <Paper className={classes.paper}>
            <FormHeading text="Management Plan" />
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={6}>
                    <FullWidthTextField
                        name="policyNumber"
                        validate={conditionalRequired(values => values.policyStatus)}
                    />
                    <FullWidthDatePicker
                        name="ceaseDate"
                        validate={conditionalRequired(values => values.nextReviewDate)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthSearchSelectField
                        name="policyStatus"
                        options={PolicyStatusCodes}
                        validate={conditionalRequired(values => values.policyNumber)}
                    />
                    <FullWidthDatePicker
                        name="nextReviewDate"
                        validate={conditionalRequired(values => values.ceaseDate)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FullWidthTextField name="reviewRequirements" multiline />
                </Grid>
            </Grid>
        </Paper>
    );
};

const mapStateToProps = (state: State) => ({
    incomeManagementPlan: getIncomeManagementPlan(state),
});

const mapDispatchToProps = {
    submit: submitAction,
};

export const IncomeManagementPlanForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(incomeManagementPlanForm));
