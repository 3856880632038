import { ReadOnlyAction } from '../reducers/read-only-reducer';
import { SET_READ_ONLY } from '../types';

export type SetReadOnly = (readOnly?: boolean) => ReadOnlyAction;
export const setReadOnlyAction: SetReadOnly = (readOnly?: boolean) => {
    sessionStorage.setItem('readOnly', readOnly ? 'true' : 'false');
    return {
        type: SET_READ_ONLY,
        payload: readOnly,
    };
};
