import {
    ADD_INFORMATION_RECORD,
    UPDATE_INFORMATION_RECORD,
    DELETE_INFORMATION_RECORD,
} from './types';
import { collectionReducer } from '../../../../redux/collection-reducer';
import { getProperty } from '../../../../claim';
import { State } from '../../../../redux/root-reducer';
import { combineReducersWithDefault } from '../../../../redux/combine-reducers-with-default';
import { Nullable } from '../../../../shared-types';

export interface AdditionalInformationRequiredWaiver {
    readonly dateWaived?: Date;
    readonly waivedBy?: string;
    readonly reasonWaived?: string;
}

export interface NonBusinessRelatedExpense {
    nonBusinessRelatedExpenseName?: string;
    nonBusinessRelatedExpenseValue?: number;
}

export interface SelfEmployedIncome {
    sales?: number;
    costOfSales?: number;
    income?: number;
    expenses?: number;
    nonBusinessRelatedExpenses?: NonBusinessRelatedExpense[];
    nonBusinessExpensesTotal?: number;
    overheadTotal?: number;
    financialStatementPeriod?: number;
    oebPercentage?: number;
    percentageOfOEB?: number;
    definedIncome?: number;
    businessPercentageShare?: number;
    partnership?: number;
    averageMonthlyEarnings?: number;
    estimatedAfterTaxIncome?: number;
}

export interface InformationRecord {
    readonly id?: string;
    readonly detailsOfInformationRequested?: string;
    readonly reasonForRequest?: string;
    readonly dateRequested?: Date;
    readonly dateReceived?: Date;
    readonly assessedBy?: string;
    readonly hasWaiver?: boolean;
    readonly waiver?: Nullable<AdditionalInformationRequiredWaiver>;
    readonly sendToUnderwriter?: boolean;
    readonly documentID?: string;
    readonly documentName?: string;
    readonly documentDescription?: string;
    readonly dateOfReport?: Date;
    readonly assessor?: string;
    readonly dateOfSummary?: Date;
    readonly summary?: string;
    readonly basicSalary?: number;
    readonly paye?: number;
    readonly incomeAfterTax?: number;
    readonly claimAmount?: number;
    readonly claimPercentage?: number;
    readonly receivedOrAdditional?: string;
    readonly selfEmployedIncome?: SelfEmployedIncome;
}
export interface InformationRecordSummary {
    readonly records: InformationRecord[];
}

export const defaultRecords: InformationRecord[] = [];
export const defaulInformationRequired: InformationRecordSummary = {
    records: defaultRecords,
};

const informationRecordReducer = collectionReducer<InformationRecord>({
    addAction: ADD_INFORMATION_RECORD,
    deleteAction: DELETE_INFORMATION_RECORD,
    updateAction: UPDATE_INFORMATION_RECORD,
});

export const informationRecordSummaryReducer = combineReducersWithDefault<
    Nullable<InformationRecordSummary>
>(defaulInformationRequired, {
    records: informationRecordReducer,
});

export function getInformationRecord(state: State): InformationRecordSummary {
    return getProperty(state, 'informationRecordSummary', defaulInformationRequired);
}
