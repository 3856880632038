import React from 'react'; // eslint-disable-line

import { Typography, withStyles } from '@material-ui/core';
import { GetFormSubmissionState, GetWorkflowState } from '../actions/navigate-to-next-screen';
import { WorkflowScreen } from '../reducers/workflow-screen';
import { WorkflowStep } from '../reducers/workflow-step';
import styles, { StyledComponent } from './styles';
import { WorkflowScreenView } from './workflow-screen';

type Props = StyledComponent & {
    screen: WorkflowScreen;
    getWorkflowState: GetWorkflowState;
    getFormSubmissionState: GetFormSubmissionState;
};

class WorkflowStepViewClass extends React.PureComponent<Props> {
    public render() {
        const { classes, screen, getWorkflowState, getFormSubmissionState } = this.props;
        const heading = getHeading(screen);
        return (
            <React.Fragment>
                <Typography variant="h5" className={classes.pageHeader}>
                    {screen?.screenHeading ? screen?.screenHeading : heading}
                </Typography>
                <WorkflowScreenView
                    workflowScreen={screen}
                    getWorkflowState={getWorkflowState}
                    getFormSubmissionState={getFormSubmissionState}
                />
            </React.Fragment>
        );
    }
}

function getHeading(screen: WorkflowScreen) {
    const ancestors = screen.ancestors.reverse();
    if (screen instanceof WorkflowStep) {
        ancestors.push(screen);
    }

    const heading = ancestors
        .map(s => s.label)
        .filter(label => !!label)
        .join(' - ');

    return heading;
}

export const WorkflowStepView = withStyles(styles)(WorkflowStepViewClass);
