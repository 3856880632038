import { Theme } from '@material-ui/core';

export interface StyledComponent {
    classes: {
        addButton: string;
    };
}

export default function styles(theme: Theme) {
    return {
        addButton: addButton(theme),
    };
}

function addButton(theme: Theme) {
    return {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    };
}
