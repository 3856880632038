import React from 'react'; // eslint-disable-line

import { DeathAssessmentDetailsView } from '../..';
import { RiskAssessmentDecisionAddView } from './risk-assessment-decision-add';
import { InvestmentAssessmentDecisionAddView } from './investment-assessment-decision-add';

export const DeathAssessmentView: React.FunctionComponent = () => {
    return (
        <DeathAssessmentDetailsView
            deathAssessmentDecisionAddView={props => <RiskAssessmentDecisionAddView {...props} />}
            investmentAssessmentDecisionAddView={props => (
                <InvestmentAssessmentDecisionAddView {...props} />
            )}
        />
    );
};
