import { Grid, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import styles, { StyledComponent } from '../../styles/styles';

const initiationInfo = ({ classes }: StyledComponent) => {
    return (
        <Grid item xs={12}>
            <div className={classes.resultsLabel}>
                <p>Please select one of the following options:</p>
                <ul>
                    <li>
                        To start a new claim, select <strong>Initiate New Claim</strong> and Click{' '}
                        <strong>Next</strong>
                    </li>
                    <li>
                        To review/re-assess an existing claim, click on <strong>Link</strong>
                    </li>
                    <li>
                        To copy the summary history of a finalised claim and initiate into a new
                        claim, click <strong>Copy</strong>
                    </li>
                    <li>
                        To view a claim, click <strong>View</strong>
                    </li>
                </ul>
            </div>
        </Grid>
    );
};

export const InitiationInfo = withStyles(styles)(initiationInfo);
