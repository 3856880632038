import { combineReducers, Reducer, ReducersMapObject } from 'redux';

export function combineReducersWithDefault<S>(
    defaultValue: S,
    reducers: ReducersMapObject<S, any>
): Reducer<S> {
    const innerReducer = combineReducers(reducers);
    return (state: S | undefined = defaultValue, action: any): S => {
        const reducedState = innerReducer(state || defaultValue, action);
        if (reducedState !== defaultValue) {
            return reducedState;
        }

        return state;
    };
}
