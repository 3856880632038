import React from 'react'; // eslint-disable-line

import { FormRenderProps } from 'react-final-form';
import { connect } from 'react-redux';
import { EditFormModal } from '../../form-modal';
import { Doctor } from '../reducer';
import DoctorDetailsView from './doctor-details';
import { DialogContentDetails } from '../../confirmation-dialog';

interface Props {
    records: Doctor[];
    updateRecord: (
        doctor: Doctor,
        index: number
    ) => void | Promise<void> | Record<string, any> | Promise<Record<string, any>>;
    deleteRecord: (
        index: number
    ) => void | Promise<void> | Record<string, any> | Promise<Record<string, any>>;
}

const DoctorEditFormModal = EditFormModal<Doctor>();
const doctorDetailsEditModal: React.FunctionComponent<Props> = ({
    records,
    updateRecord,
    deleteRecord,
}: Props) => {
    const saveDialogDetails: DialogContentDetails = {
        title: 'Warning Unsaved Changes',
        body: 'Current changes will be lost, would you like to save them?',
    };

    const deleteDialogDetails: DialogContentDetails = {
        title: "Delete Doctor's Details",
        body: "Are you sure you would like to delete the selected doctor's details?",
    };

    return (
        <DoctorEditFormModal
            records={records}
            recordDetailView={detailView}
            updateRecord={updateRecord}
            deleteRecord={deleteRecord}
            saveDialogDetails={saveDialogDetails}
            deleteDialogDetails={deleteDialogDetails}
        />
    );
};

function detailView(renderProps: FormRenderProps) {
    return <DoctorDetailsView {...renderProps} />;
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export const DoctorDetailsEditModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(doctorDetailsEditModal);
