import { Reducer } from 'redux';
import { getProperty } from '../claim';
import { State } from '../redux/root-reducer';
import { Nullable } from '../shared-types';
import {
    ContactPersonDetails,
    defaultContactPersonDetails,
} from './contact-person/contact-person-details';
import { FinancialAdviserContactDetails } from './financial-adviser/financial-adviser-contact-details';

export interface ClaimContactDetails {
    readonly contactPerson?: ContactPersonDetails;
    readonly financialAdviser?: FinancialAdviserContactDetails;
}

export const defaultContactDetails: ClaimContactDetails = {
    contactPerson: defaultContactPersonDetails,
    financialAdviser: {},
};

export const contactDetailsReducer: Reducer<Nullable<ClaimContactDetails>> = (
    state: Nullable<ClaimContactDetails> = null
) => {
    return state;
};

export function getContactDetails(state: State): ClaimContactDetails {
    return getProperty(state, 'contactDetails', defaultContactDetails);
}
