import { Claim, claimSubmit } from '../../../../../claim';
import { getLifeAssuredDetailsFromClaim } from '../../../../../life-assured-details/reducer';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { FormDetails, getContactDetailsFormClaim } from '../form-details-reducer';

type UpdateFormDetailsReturn = Promise<any>;
export type UpdateFormDetails = (
    formDetails: FormDetails | FormDetails[]
) => UpdateFormDetailsReturn;
type UpdateFormDetailsAction = (
    formDetails: FormDetails | FormDetails[]
) => ReduxThunkAction<UpdateFormDetailsReturn>;

export const updateFormDetailsAction: UpdateFormDetailsAction = (
    formDetails: FormDetails | FormDetails[]
) => {
    return async (dispatch, getState) => {
        if (Array.isArray(formDetails)) {
            const promises = formDetails.map(details => {
                return claimSubmit(dispatch, getState, getUpdatedClaim(details));
            });

            return Promise.all(promises);
        } else {
            return claimSubmit(dispatch, getState, getUpdatedClaim(formDetails));
        }
    };
};

function getUpdatedClaim(formDetails: FormDetails): (claim: Claim) => Claim {
    const isContactPerson =
        formDetails.claimant?.policyDetails?.isOwner === 'Yes' ||
        formDetails.claimant?.policyDetails?.claimingCapacity === 'Relative';

    const isFinancialAdviser =
        formDetails.claimant?.policyDetails?.claimingCapacity === 'Financial Advisor';

    const contactPerson = {
        telephoneNumber: formDetails.claimant?.contactDetails?.telephoneNumber,
        cellphoneNumber: formDetails.claimant?.contactDetails?.cellphoneNumber,
        faxNumber: formDetails.claimant?.contactDetails?.faxNumber,
        emailAddress: formDetails.claimant?.contactDetails?.emailAddress,
        preferredMethodOfCommunication:
            formDetails.claimant?.contactDetails?.preferredMethodOfCommunication,
        initials: formDetails.claimant?.personalDetails?.initials,
        title: formDetails.claimant?.personalDetails?.title,
        firstName: formDetails.claimant?.personalDetails?.firstName,
        lastName: formDetails.claimant?.personalDetails?.surname,
        companyName: formDetails.claimant?.personalDetails?.companyName,
        consultantCode: formDetails.claimant?.personalDetails?.consultantCode,
    };

    return (claim: Claim) => {
        return {
            ...claim,
            contactDetails: {
                financialAdviser: isFinancialAdviser
                    ? contactPerson
                    : getContactDetailsFormClaim(claim).financialAdviser,
                contactPerson: isContactPerson
                    ? contactPerson
                    : getContactDetailsFormClaim(claim).contactPerson,
            },
            lifeAssuredDetails: {
                ...getLifeAssuredDetailsFromClaim(claim),
                medicalAidDetails: {
                    dateJoined:
                        formDetails.lifeAssured?.medicalAidDetails?.lifeAssuredMedicalDateJoined,
                    hasMedicalAid: formDetails.lifeAssured?.medicalAidDetails?.hasMedicalAid,
                    isLifeAssuredTheMainMember:
                        formDetails.lifeAssured?.medicalAidDetails?.isLifeAssuredMainMember,
                    mainMembersFirstName:
                        formDetails.lifeAssured?.medicalAidDetails?.lifeAssuredName,
                    mainMembersIdNumber:
                        formDetails.lifeAssured?.medicalAidDetails?.lifeAssuredIdNumber,
                    mainMembersLastName:
                        formDetails?.lifeAssured?.medicalAidDetails?.lifeAssuredSurname,
                    mainMembersTitle: formDetails?.lifeAssured?.medicalAidDetails?.lifeAssuredTitle,
                    medicalAidDescription:
                        formDetails?.lifeAssured?.medicalAidDetails
                            ?.lifeAssuredMedicalAidDescription,
                    medicalAidName:
                        formDetails?.lifeAssured?.medicalAidDetails?.lifeAssuredMedicalAidName,
                    medicalAidNumber:
                        formDetails?.lifeAssured?.medicalAidDetails?.lifeAssuredMedicalAidNumber,
                },
            },
            formDetails,
        };
    };
}
