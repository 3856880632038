import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { FullWidthTextField, FullWidthDatePicker } from '../../../../../forms';
import { requiredValidator } from '../../../../../forms/validations';
import { TelephoneNumberMask } from '../../../../../forms/input-masks';
import { AddressDetailsSubForm } from '../../../../../forms/sub-forms/address-details';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { FormApi } from 'final-form';
import { NoticeOfDeath } from '../notice-of-death-reducer';
import { FormSpy } from 'react-final-form';
import { FormDetails, getFormDetails } from '../../../funeral/form-details/form-details-reducer';
import { connect } from 'react-redux';
import { State } from '../../../../../redux/root-reducer';
import { FileCopy } from '@material-ui/icons';

interface Props extends StyledComponent {
    formDetails: FormDetails;
    isDeath?: boolean;
}

interface InnerProps extends Props {
    form: FormApi<NoticeOfDeath>;
}

const funeralParlourEditView: React.FunctionComponent<Props> = ({
    formDetails,
    isDeath,
    classes,
}) => {
    return FormSpy<NoticeOfDeath>({
        render: ({ form }) =>
            funeralParlourEditViewForm({
                classes,
                form,
                formDetails,
                isDeath,
            }),
    });
};

const funeralParlourEditViewForm = ({ classes, form, formDetails, isDeath }: InnerProps) => {
    const prefix = 'funeralParlour.';
    const addressType = isDeath ? 'residential' : 'street';
    function populateFromFormDetails() {
        if (!!form) {
            const currentValues = form.getState().values.funeralParlour;
            form.batch(() => {
                form.change('funeralParlour', {
                    ...currentValues,
                    ...formDetails.funeralParlour,
                });
            });
        }
    }
    return (
        <Grid container spacing={5} justify="flex-start">
            {!isDeath && (
                <Grid item xs={12} container justify="flex-end">
                    <Button onClick={populateFromFormDetails} className={classes.copyButton}>
                        <FileCopy className={classes.copyIcon} />
                        Copy from form details
                    </Button>
                </Grid>
            )}
            <Grid item xs={6}>
                <FullWidthTextField name={`${prefix}designationNumber`} />
            </Grid>
            <Grid item xs={6}>
                <FullWidthDatePicker label="Date of Funeral" name={`${prefix}dateOfFuneral`} />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField name={`${prefix}name`} validate={requiredValidator} />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField name={`${prefix}telephoneNumber`} mask={TelephoneNumberMask} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.marginTop}>
                    Residential Address
                </Typography>
            </Grid>
            <AddressDetailsSubForm
                addressPropertyName={`${prefix}addressDetails`}
                addressType={addressType}
            />
        </Grid>
    );
};

const mapStateToProps = (state: State) => ({
    formDetails: getFormDetails(state),
});

export const EditFuneralParlourView = connect(
    mapStateToProps,
    {}
)(withStyles(styles)(funeralParlourEditView));
