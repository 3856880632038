import { Grid, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { Section } from '../../../../controls/section';
import {
    FormErrors,
    FullWidthCheckBoxField,
    FullWidthTextField,
    GenericForm,
    WhenFieldChanges,
} from '../../../../forms';
import { CurrencyMask } from '../../../../forms/input-masks';
import { PercentageMask } from '../../../../forms/input-masks/percentage-mask';
import { requiredValidator } from '../../../../forms/validations';
import { Benefit, LifeAssuredContract } from '../../../../life-assured-details/reducer';
import { State } from '../../../../redux/root-reducer';
import { convertToNumber } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import { OnSubmitSuccessProps } from '../../../../workflow-navigation';
import {
    getAssessmentDetails,
    getExtendedBenefitAmountPayable as getAmountPayable,
    PolicyAssessmentDetails,
} from '../../../decisions/assessment-details-reducer';
import {
    ClaimBoosterModel,
    UpdateIncomeCalculation,
    updateIncomeCalculationAction,
} from '../../actions/update-income-calculation';
import { IncomeCalculation } from '../../reducer';

type Props = StyledComponent &
    OnSubmitSuccessProps & {
        contractBenefit?: Benefit;
        contract?: LifeAssuredContract;
        updateIncomeCalculation: UpdateIncomeCalculation;
        incomeCalculation: IncomeCalculation;
        assessmentDetails: PolicyAssessmentDetails[];
    };

const ClaimBoosterForm = GenericForm<ClaimBoosterModel>();
const editClaimBooster: React.FunctionComponent<Props> = ({
    contractBenefit,
    contract,
    updateIncomeCalculation,
    incomeCalculation,
    assessmentDetails,
    ...rest
}: Props) => {
    const amountPayable = getAmountPayable(assessmentDetails, incomeCalculation.decisionId);
    const initialValues = {
        policyNumber: contract && contract.contractNumber,
        claimBoosterPercentage: incomeCalculation.claimBoosterPercentage || 33.33,
        claimBoosterAmount: incomeCalculation.claimBoosterAmount || amountPayable * (33.33 / 100),
        includeClaimBooster: incomeCalculation.includeClaimBooster,
    };

    return (
        <ClaimBoosterForm
            initialValues={initialValues}
            onSubmit={updateIncomeCalculation}
            {...rest}
        >
            <Section title="Policy Details">
                <Grid item xs={6}>
                    <FullWidthTextField
                        mask={PercentageMask}
                        validate={requiredValidator}
                        parse={convertToNumber}
                        label="Claim Booster Percentage"
                        name="claimBoosterPercentage"
                    />
                    <WhenFieldChanges
                        field="claimBoosterPercentage"
                        set="claimBoosterAmount"
                        to={(value: number) => {
                            return getClaimBoosterAmount(value, amountPayable);
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        mask={CurrencyMask}
                        validate={requiredValidator}
                        label="Claim Booster Value"
                        name="claimBoosterAmount"
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthCheckBoxField
                        name="includeClaimBooster"
                        label="Include Claim Booster"
                        validate={requiredValidator}
                    />
                </Grid>
            </Section>
            <FormErrors />
        </ClaimBoosterForm>
    );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getClaimBoosterAmount = (boosterPercentage: number, benefitAmount: number) => {
    return (benefitAmount * (boosterPercentage / 100.0)).toFixed(2);
};

const mapStateToProps = (state: State) => ({
    assessmentDetails: getAssessmentDetails(state),
});

const mapDispatchToProps = {
    updateIncomeCalculation: updateIncomeCalculationAction,
};

export const EditClaimBooster = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(editClaimBooster));
