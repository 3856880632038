import React, { useState, ReactNode } from 'react'; // eslint-disable-line

import { TextField } from '@material-ui/core';
import { StandardTextFieldProps, TextFieldProps } from '@material-ui/core/TextField';
import { DateTime } from 'luxon';
import {
    DatePicker as MuiDatePicker,
    KeyboardDatePicker,
    KeyboardDatePickerProps,
} from '@material-ui/pickers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { FieldRenderProps } from 'react-final-form';
import { callAllFunctions, getComponentId, nameToLabel } from '../../';
import { ErrorResult, processMetaForErrors, noError } from '../../form-component-utilities';
import { Nullable } from '../../../shared-types';

type Props = FieldRenderProps<Date, any> &
    Partial<KeyboardDatePickerProps> & {
        readOnly?: boolean;
        maxDateField?: string;
        minDateField?: string;
    };

interface ComponentState {
    value?: ParsableDate;
    validationError?: ReactNode;
}

export const DatePicker: React.FunctionComponent<Props> = ({
    input,
    InputProps,
    meta,
    label,
    id,
    helperText,
    readOnly,
    onChange: onChangeProp,
    maxDateField,
    minDateField,
    ...rest
}: Props) => {
    const onChange = callAllFunctions(onChangeProp, input.onChange);
    const [{ value, validationError }, onStateChanged] = useState<ComponentState>({
        value: input.value || null,
        validationError: null,
    });

    const name = input.name;
    const minDateErrorMessage = minDateField
        ? `Date should not be before ${nameToLabel({ name: minDateField })}`
        : undefined;
    const maxDateErrorMessage = maxDateField
        ? `Date should not be after ${nameToLabel({ name: maxDateField })}`
        : undefined;

    if (readOnly) {
        const readonlyInputProps = { ...InputProps, readOnly };
        // @ts-ignore
        const readonlyTextFieldComponent: React.ComponentType<TextFieldProps> = (
            tfProps: StandardTextFieldProps
        ) => <TextField {...tfProps} InputProps={InputProps} />;

        return (
            <MuiDatePicker
                {...rest}
                value={input.value || null}
                id={getComponentId({ id, name })}
                name={name}
                label={nameToLabel({ name, label })}
                InputProps={readonlyInputProps}
                TextFieldComponent={readonlyTextFieldComponent}
                onChange={() => undefined}
            />
        );
    }

    const { errorMessage, showError } = getErrors();
    return (
        <KeyboardDatePicker
            {...rest}
            value={input.value || null}
            id={getComponentId({ id, name })}
            name={name}
            label={nameToLabel({ name, label })}
            placeholder="DD/MM/YYYY"
            format="dd/MM/yyyy"
            onChange={onDateChanged}
            clearable
            error={showError}
            helperText={showError ? errorMessage : helperText}
            onError={onError}
            InputProps={InputProps}
            invalidDateMessage="Invalid Date"
            maxDateMessage={maxDateErrorMessage}
            minDateMessage={minDateErrorMessage}
            allowKeyboardControl
        />
    );

    function getErrors(): ErrorResult {
        const formError = processMetaForErrors(meta);

        if (formError.showError) {
            return formError;
        }

        if (validationError) {
            return { showError: true, errorMessage: validationError };
        }

        return noError;
    }

    function onError(error: ReactNode, invalidValue: ParsableDate) {
        if (error !== validationError || invalidValue !== value) {
            onStateChanged({ value: invalidValue, validationError: error });
        }
    }

    function getJsDateValue(value: Nullable<Date | DateTime>) {
        if (value instanceof Date) {
            const dateValue = new Date(
                Date.UTC(value.getFullYear(), value.getMonth(), value.getDate())
            );
            return dateValue;
        }

        if (value instanceof DateTime && value.isValid) {
            const dateValue = new Date(Date.UTC(value.year, value.month - 1, value.day));
            return dateValue;
        }

        return null;
    }

    function onDateChanged(date: MaterialUiPickersDate, value?: Nullable<string>) {
        const jsDateValue = getJsDateValue(date);
        onStateChanged({
            value: jsDateValue || value,
            validationError: null,
        });
        onChange(jsDateValue);
    }
};
