import { Divider, Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { FullWidthDatePicker, FullWidthTextField } from '../../../../../forms';
import { requiredValidator } from '../../../../../forms/validations';
import styles, { StyledComponent } from '../../../../../styles/styles';

type Props = StyledComponent & {
    heading: React.ReactNode;
};
const doctorsVisitForm: React.FunctionComponent<Props> = ({ heading }: Props) => {
    return (
        <React.Fragment>
            <Typography variant="h5">{heading} </Typography>
            <Divider></Divider>
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={6}>
                    <FullWidthTextField
                        name="reason"
                        label="Reason for Consultation"
                        validate={requiredValidator}
                        multiline
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthDatePicker name="date" label="Date of Consultation" disableFuture />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};
export const DoctorsVisitForm = withStyles(styles)(doctorsVisitForm);
