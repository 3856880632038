import { Divider, Grid, Paper, Typography, withStyles } from '@material-ui/core';
import { FORM_ERROR } from 'final-form';
import React, { useState } from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { getClientsFullName, hasExistingClaims } from '../..';
import { OpenDialog, openDialogAction } from '../../../confirmation-dialog';
import { FullWidthCheckBoxField, GenericForm } from '../../../forms';
import downarrow from '../../../master-layout/views/downarrow.svg';
import uparrow from '../../../master-layout/views/uparrow.svg';
import { State } from '../../../redux/root-reducer';
import { getDisplayDate } from '../../../shared-functions';
import styles, { StyledComponent } from '../../../styles/styles';
import { OnSubmitSuccessProps } from '../../../workflow-navigation';
import {
    deleteScreenFromWorkflow,
    DeleteScreenFromWorkflow,
} from '../../../workflow-navigation/actions/delete-screen-from-workflow';
import {
    NavigateToNextScreen,
    navigateToNextScreenThunk,
} from '../../../workflow-navigation/actions/navigate-to-next-screen';
import { Client } from '../../actions';
import { getIdNumbers } from '../../actions/contract-search';
import {
    InitiateContract,
    initiateContract,
    initiateFuneralClaim,
    InitiateFuneralClaim,
} from '../../actions/initiate-claim';
import { ExistingClaimsView } from '../existing-claims-view';
import { InitiationInfo } from '../initiation-info';
import { OpenAddModal } from '../../../form-modal/actions';

type InnerProps = StyledComponent & {
    clients: Client[];
    selectedClients: Client[];
};

type Props = OnSubmitSuccessProps &
    InnerProps & {
        deleteScreen: DeleteScreenFromWorkflow;
        navigateToNextScreen: NavigateToNextScreen;
        openDialog: OpenDialog;
        initiateClaim: InitiateFuneralClaim;
        initiateContract: InitiateContract;
        openAddModal: OpenAddModal;
    };

const ClientSelectionForm = GenericForm<InnerProps>();
const clientSelectionView: React.FunctionComponent<Props> = ({
    classes,
    clients,
    deleteScreen,
    navigateToNextScreen,
    openDialog,
    selectedClients,
    initiateClaim,
    initiateContract,
    openAddModal,
    ...rest
}: Props) => {
    const onSubmit = (values: any) => {
        const selectedIds = getIdNumbers(values);
        if (!selectedIds || selectedIds.length !== 1) {
            return {
                [FORM_ERROR]: 'Please select one member',
            };
        }
        let hasClaims = false;
        const selectedClients = clients.filter(c => c.clientId === selectedIds[0]);
        const client = selectedClients[0];
        selectedIds.forEach(id => {
            hasClaims =
                clients.filter(c => c.clientId === id && hasExistingClaims(c.existingClaims))
                    .length > 0;
        });

        if (hasClaims) {
            openDialog({
                content: {
                    title: 'Existing Claims Confirmation',
                    body:
                        'The selected life assured has existing claims, are you sure want to create a new claim?',
                },
                yesAction: {
                    action: async () => {
                        if (client.contract) {
                            await initiateContract(client);
                        } else {
                            await initiateClaim(selectedIds);
                        }
                    },
                },
                noAction: {
                    action: () => {
                        return;
                    },
                },
            });
        } else {
            if (client.contract) {
                return initiateContract(client);
            } else {
                return initiateClaim(selectedIds);
            }
        }
    };

    clients.sort((l, r) => (l.firstName + l.lastName).localeCompare(r.firstName + r.lastName));

    const initialValues: any = {};
    clients.forEach(client => {
        initialValues[`select${client.clientId}`] = false;
    });

    selectedClients.forEach(selected => {
        initialValues[`select${selected.clientId}`] = true;
    });

    return (
        <ClientSelectionForm onSubmit={onSubmit} initialValues={initialValues} {...rest}>
            <Paper className={classes.resultsPaper}>
                <Typography className={classes.resultsHeader}>Search Results</Typography>
                <Divider className={classes.resultsHeaderSpacer} />
                <Grid container spacing={5} justify="flex-start">
                    <ClientsView clients={clients} classes={classes} />
                </Grid>
            </Paper>
        </ClientSelectionForm>
    );
};

type ClientProps = StyledComponent & {
    clients: Client[];
};
const ClientsView = ({ clients, classes }: ClientProps) => {
    if (clients.length < 1) {
        return (
            <Grid item xs={12}>
                <Typography>No clients</Typography>
            </Grid>
        );
    }

    return (
        <React.Fragment>
            <InitiationInfo />
            <Grid item xs={12} container>
                <Grid item xs={3}>
                    <Typography className={classes.resultsLabel}>Name</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography className={classes.resultsLabel}>ID number</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography className={classes.resultsLabel}>Date of birth</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography className={classes.resultsLabel}>PDB ID</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography className={classes.resultsLabel}>Role</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography className={classes.resultsLabel}>Initiate new claim</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>
            <Grid item xs={12}>
                {clients.map(renderClient({ classes }))}
            </Grid>
        </React.Fragment>
    );
};

function renderClient({ classes }: StyledComponent) {
    return function (client: Client) {
        const [isExpanded, setIsExpanded] = useState(true);
        const toggleState = (evt: any) => {
            setIsExpanded(value => !value);
            evt.preventDefault();
            return false;
        };
        const expanderElement = (
            <a href="/#" onClick={toggleState}>
                {isExpanded ? (
                    <img alt="collapse" src={uparrow} />
                ) : (
                    <img alt="expand" src={downarrow} />
                )}
            </a>
        );

        return (
            <React.Fragment key={client.clientId}>
                <Divider className={classes.resultsClientSpacer} />
                <Grid container className={classes.resultsTable}>
                    <Grid item xs={3}>
                        <Typography className={classes.resultsText}>
                            {getClientsFullName(client)}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography className={classes.resultsText}>{client.idNumber}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography className={classes.resultsText}>
                            {getDisplayDate(client.dateOfBirth, 'dd MMMM yyyy')}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography className={classes.resultsText}>
                            {client.clientSourceId}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography className={classes.resultsText}>
                            {client.roleDescription}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ marginTop: '-28px', marginBottom: '-8px' }}>
                        <FullWidthCheckBoxField
                            name={`select${client.clientId}`}
                            label="Initiate new claim"
                        />
                    </Grid>
                    <Grid item xs={1} style={{ textAlign: 'right' }}>
                        {expanderElement}
                    </Grid>
                </Grid>
                {isExpanded && (
                    <ExistingClaimsView
                        clientClaims={client.existingClaims}
                        clientId={client.clientId}
                    />
                )}
            </React.Fragment>
        );
    };
}

const mapStateToProps = (state: State) => ({
    clients: state.initiateClaim.clients || [],
    selectedClients: state.initiateClaim.selectedClients || [],
});

const mapDispatchToProps = {
    deleteScreen: deleteScreenFromWorkflow,
    navigateToNextScreen: navigateToNextScreenThunk,
    openDialog: openDialogAction,
    initiateClaim: initiateFuneralClaim,
    initiateContract: initiateContract,
};

export const FuneralClientSelectionView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(clientSelectionView));
