import { combineReducers } from 'redux';

import { ToggleAction } from './actions';
import types from './types';

export interface MasterPageState {
    readonly drawerOpen: boolean;
}

function drawerOpenReducer(state = true, action: ToggleAction) {
    switch (action.type) {
        case types.TOGGLE:
            return !state;
        default:
            return state;
    }
}

const reducer = combineReducers({
    drawerOpen: drawerOpenReducer,
});

export default reducer;
