import { claimSubmit } from '../../../../../claim';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { FinancialDetails } from '../../../death/claim-event-details/reducer';
import { getRAClaimDetailsFromClaim } from '../reducer';

type SubmitReturn = Promise<any>;
export type UpdateFinancialDetails = (financialDetails: FinancialDetails) => SubmitReturn;
type UpdateFinancialDetailsAction = (
    financialDetails: FinancialDetails
) => ReduxThunkAction<SubmitReturn>;

export const updateFinancialDetailsAction: UpdateFinancialDetailsAction = (
    financialDetails: FinancialDetails
) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, claim => {
            return {
                ...claim,
                claimDetails: {
                    ...getRAClaimDetailsFromClaim(claim),
                    financialDetails: financialDetails,
                },
            };
        });
    };
};
