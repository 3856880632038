import { ContractType } from './life-assured-details/reducer';

export type EntityIdType = 'ID Number' | 'Passport Number';
export type YesOrNo = 'Yes' | 'No';

export interface BenefitSummary {
    readonly name: string;
    readonly code: string;
    readonly inForce: boolean;
}

export interface ContractDetails {
    contractNumber?: string;
    contractType?: ContractType;
    isLinkedToClaim?: boolean;
    role?: string;
    benefits: BenefitSummary[];
}

export interface ClaimStatusEntry {
    claimStatus?: string;
    date?: Date;
    userId: string;
}

export interface UserInfo {
    userId?: string;
    name?: string;
}

export interface VersionInfo {
    createdBy?: UserInfo;
    createdInVersion?: string;
    createdDate?: Date;
    lastUpdatedBy?: UserInfo;
    lastUpdatedInVersion?: string;
    lastUpdatedDate?: Date;
}

export type Nullable<T> = T | null | undefined;

export class Lazy<T> {
    private _value?: T;
    private generator: () => T;
    public constructor(generator: () => T) {
        this.generator = generator;
    }

    public value(): T {
        return this._value || (this._value = this.generator());
    }
}

export enum EntityType {
    Individual = 'Individual',
    Organisation = 'Organisation',
    Executor = 'Executor',
    Estate = 'Estate',
    Unknown = 'Unknown',
}

export const PolicyHolderTypes = [
    { value: 'Individual', label: 'Individual' },
    { value: 'Company', label: 'Company' },
];

export const Conditions = [
    { value: 'Accident', label: 'Accident' },
    { value: 'Disease', label: 'Disease' },
];

export const YesOrNoOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
];

export const PaymentBeneficiary = [
    { value: 'Policyholder', label: 'Policyholder' },
    { value: 'Premium Payer', label: 'Premium Payer' },
    { value: 'Cessionary', label: 'Cessionary' },
];

export enum DocumentOwnerType {
    Claimant = 'Claimant',
    LifeAssured = 'Life Assured',
    PolicyOwner = 'Policy Owner',
    Executor = 'Executor',
}
