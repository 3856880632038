import React from 'react'; // eslint-disable-line

import { Grid, Paper, withStyles, Typography } from '@material-ui/core';
import { CountriesOfIssue } from '../../../../../codes/countries-of-issue';
import { NaturalRelationships } from '../../../../../codes/relationship-types';
import {
    Condition,
    FormHeading,
    FullWidthSearchSelectField,
    FullWidthTextField,
    FullWidthRadioGroup,
    RadioOption,
    True,
    WhenFieldChanges,
} from '../../../../../forms';
import { requiredValidator } from '../../../../../forms/validations';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { AddressDetailsEditView } from './edit-address-details';
import { ContactDetailsEditView } from './edit-contact-details';
import { PersonalDetailsView } from './edit-personal-details';

const guardianEditView: React.FunctionComponent<StyledComponent> = ({
    classes,
}: StyledComponent) => {
    return (
        <Paper className={classes.paper}>
            <FormHeading text="Guardian's personal details" />
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={6}>
                    <FullWidthSearchSelectField
                        name={`guardian.relationshipToMinor`}
                        options={NaturalRelationships}
                        validate={requiredValidator}
                    />
                    <WhenFieldChanges
                        field="guardian.relationshipToMinor"
                        set="guardian.relationshipToMinorDescription"
                        to={null}
                    />
                </Grid>
                <Condition when="guardian.relationshipToMinor" is="Other">
                    <True>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name={`guardian.relationshipToMinorDescription`}
                                validate={requiredValidator}
                            />
                        </Grid>
                    </True>
                </Condition>
                <Grid item xs={6}>
                    <FullWidthSearchSelectField
                        name={`guardian.relationshipToLifeAssured`}
                        options={NaturalRelationships}
                        validate={requiredValidator}
                    />
                    <WhenFieldChanges
                        field="guardian.relationshipToLifeAssured"
                        set="guardian.relationshipToLifeAssuredDescription"
                        to={null}
                    />
                </Grid>
                <Condition when="guardian.relationshipToLifeAssured" is="Other">
                    <True>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name={`guardian.relationshipToLifeAssuredDescription`}
                                validate={requiredValidator}
                            />
                        </Grid>
                    </True>
                </Condition>
                <PersonalDetailsView propertyPrefix="guardian" />
                <Grid item xs={6}>
                    <FullWidthRadioGroup name="guardian.taxStatus">
                        <RadioOption value="Tax Paying" />
                        <RadioOption value="Non Tax Paying" />
                    </FullWidthRadioGroup>
                    <WhenFieldChanges
                        field="guardian.taxStatus"
                        becomes="Non Tax Paying"
                        set="guardian.countryOfResidence"
                        to={null}
                    />
                    <WhenFieldChanges
                        field="guardian.taxStatus"
                        becomes="Non Tax Paying"
                        set="guardian.taxReferenceNumber"
                        to={null}
                    />
                </Grid>
                <Condition when="guardian.taxStatus" is="Tax Paying">
                    <True>
                        <Grid item xs={6}>
                            <FullWidthSearchSelectField
                                name="guardian.countryOfResidence"
                                options={CountriesOfIssue}
                                label="Country of tax residence"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthTextField name="guardian.taxReferenceNumber" />
                        </Grid>
                    </True>
                </Condition>
            </Grid>
            <ContactDetailsEditView
                propertyPrefix="guardian.contactDetails"
                heading="Guardian's contact details"
            />
            <Typography variant="subtitle2" className={classes.marginTop}>
                {`Guardian's address details`}
            </Typography>
            <AddressDetailsEditView propertyPrefix="guardian" />
        </Paper>
    );
};

export const GuardianEditView = withStyles(styles)(guardianEditView);
