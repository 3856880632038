import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { OpenModal, openModalAction } from '../../../../../form-modal/actions';
import { FormModalView } from '../../../../../form-modal/views/form-modal';
import { ButtonWrapper } from '../../../../../forms';
import { State } from '../../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { DeathCertificate, getDeathCertificate } from '../death-certification-reducer';
import { DeathCertificateModal } from './death-certificate-modal';

interface CertProps extends StyledComponent {
    deathCertificate: DeathCertificate;
    hasDeferredContracts: boolean;
    openModal: OpenModal;
}

const certificateDetails = ({
    classes,
    deathCertificate,
    hasDeferredContracts,
    openModal,
}: CertProps) => {
    const edit = () => {
        openModal({
            modalContent: <FormModalView formView={DeathCertificateModal} />,
        });
    };
    return (
        <Grid item xs={12} container justify="flex-start" className={classes.marginTop}>
            <Grid item xs={10}>
                <Typography className={classes.heading} variant="body1">
                    Death Certificate
                </Typography>
            </Grid>
            {!hasDeferredContracts && (
                <ButtonWrapper>
                    <Grid item xs={2} className={classes.rightAlign}>
                        <Tooltip title="Edit Death Certificate">
                            <Button id="EditDeathCertificate" color="secondary" onClick={edit}>
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            )}
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Serial Number" value={deathCertificate.serialNumber} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="First Name" value={deathCertificate.firstName} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Second Name" value={deathCertificate.secondName} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Surname" value={deathCertificate.lastName} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="ID Number" value={deathCertificate.idNumber} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Date of Birth"
                    value={getDisplayDate(deathCertificate.dateOfBirth)}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Date of Death"
                    value={getDisplayDate(deathCertificate.dateOfDeath)}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Place of Death" value={deathCertificate.placeOfDeath} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Cause of Death" value={deathCertificate.causeOfDeath} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Issue Date"
                    value={getDisplayDate(deathCertificate.issueDate)}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Issued By" value={deathCertificate.issuedBy} />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state: State) => ({
    deathCertificate: getDeathCertificate(state),
    hasDeferredContracts: state.claimSession.hasDeferredContracts,
});

const mapDispatchToProps = {
    openModal: openModalAction,
};

export const DeathCertificateView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(certificateDetails));
