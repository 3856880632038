import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { ExpansionPanel } from '../../../../forms/form-components/expansion-panel/expansion-panel';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { getObjectWarning } from '../../../../shared-functions';
import {
    getRADeathClaim,
    RADeathClaim,
} from '../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { RAClaimDetailsView } from './ra-claim-details-view';
import { RAFinancialDetailsView } from './ra-claim-financial-status-view';
import { RAClaimDetailsNotes } from './ra-claim-details-notes';
import { VOPDView } from '../../common/vopd/views';
import { RaDeathWrapper } from '../../../../forms';

type Props = StyledComponent & {
    raClaim: RADeathClaim;
};
const raClaimEventDetailsView: React.FunctionComponent<Props> = ({ raClaim }: Props) => {
    const notifier = getObjectWarning(raClaim.claimDetails.claimEventDetails, null);
    const vopd = raClaim.claimDetails.vopd;
    return (
        <ExpansionPanel name="Claim Event Details" notifier={notifier}>
            <RAClaimDetailsView />
            <RaDeathWrapper>
                <VOPDView vopd={vopd} />
            </RaDeathWrapper>
            <RAFinancialDetailsView />
            <RaDeathWrapper>
                <RAClaimDetailsNotes />
            </RaDeathWrapper>
        </ExpansionPanel>
    );
};

const mapStateToProps = (state: State) => ({
    raClaim: getRADeathClaim(state),
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = {};

export const RaClaimEventDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(raClaimEventDetailsView));
