import React, { ReactNode } from 'react'; // eslint-disable-line

import { Field } from 'react-final-form';
import { getComponentId, nameToLabel } from '../..';
import { LabeledValue } from '../../../controls/labeled-value';
import { TypographyProps } from '@material-ui/core/Typography';

interface Props {
    id?: string;
    name: string;
    label?: ReactNode;
    format?: (value: any, name: string) => any;
    value?: string;
    hideLabel?: boolean;
}

export const FullWidthReadonlyField: React.FunctionComponent<Props & TypographyProps> = ({
    name,
    id,
    label,
    value,
    format,
    hideLabel,
    ...rest
}: Props & TypographyProps) => {
    const componentId = getComponentId({ id, name });
    label = hideLabel ? '' : nameToLabel({ label, name });
    if (value) {
        return <LabeledValue id={componentId} label={label} value={value} {...rest} />;
    }

    return (
        <Field name={name} format={format}>
            {({ input: { value: fieldValue } }) => (
                <LabeledValue id={componentId} label={label} value={fieldValue} {...rest} />
            )}
        </Field>
    );
};
