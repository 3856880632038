import React from 'react'; // eslint-disable-line
import { ButtonWrapper } from '../../../../../../forms';
import { Grid, Paper, Typography, withStyles, Tooltip, Button } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { connect } from 'react-redux';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { OpenModal, openModalAction } from '../../../../../../form-modal/actions';
import { FormModalView } from '../../../../../../form-modal/views/form-modal';
import { ContactDetailsEditView } from './contact-details-edit';
import { RADeathClaim } from '../../../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { State } from '../../../../../../redux/root-reducer';
import { getClaim } from '../../../../../../claim';
import { LifeAssuredContactDetails } from '../../actions/update-contact-details';
import { LabeledValue } from '../../../../../../controls/labeled-value';

interface Props extends StyledComponent {
    contactDetails: LifeAssuredContactDetails;
    openModal: OpenModal;
    isReadOnly: boolean;
}

export const contactDetailsView: React.FunctionComponent<Props> = ({
    classes,
    contactDetails,
    openModal,
    isReadOnly,
}: Props) => {
    const edit = () => {
        openModal({
            modalContent: <FormModalView formView={ContactDetailsEditView} />,
        });
    };
    return (
        <React.Fragment>
            <Grid container justify="flex-start">
                <Grid container item xs={8} lg={10} alignItems="center">
                    <Typography variant="subtitle2">Contact details</Typography>
                </Grid>
                <ButtonWrapper>
                    <Grid item xs={4} lg={2} className={classes.rightAlign}>
                        <Tooltip title="Edit Contact Details">
                            <Button
                                id="EditContactDetails"
                                color="secondary"
                                onClick={() => edit()}
                            >
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            </Grid>
            <Paper className={classes.paper}>
                <Grid container justify="flex-start">
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Cellphone number"
                            value={contactDetails.cellphoneNumber}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Home number"
                            value={contactDetails.homeTelephoneNumber}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Work number"
                            value={contactDetails.workTelephoneNumber}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Email address" value={contactDetails.emailAddress} />
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

const mapStateToProps = (state: State) => {
    const claim = getClaim(state);
    const raClaim = claim as RADeathClaim;
    const contactDetails =
        (raClaim.lifeAssuredDetails && raClaim.lifeAssuredDetails.contactDetails) || {};
    return {
        contactDetails,
        isReadOnly: state.claimSession.readOnly,
    };
};

const mapDispatchToProps = {
    openModal: openModalAction,
};

export const ContactDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(contactDetailsView));
