import { reduceAndSubmitClaim } from '../../../../../../../claim/actions/reduce-and-submit-claim';
import { ReduxThunkAction } from '../../../../../../../redux/redux-actions';
import { PostUnderwritingChange } from '../post-underwriting-change-model';
import { addPostUnderwritingChangeActionCreator } from './add-post-underwriting-change';
import { deletePostUnderwritingChangeActionCreator } from './delete-post-underwriting-change';
import { updatePostUnderwritingChangeActionCreator } from './update-post-underwriting-change';

type SubmitReturn = Promise<any>;

export type AddAndSubmitPostUnderwritingChange = (
    policyIndex: number,
    postUnderwritingChange: PostUnderwritingChange
) => SubmitReturn;
type AddAndSubmitPostUnderwritingChangeThunk = (
    policyIndex: number,
    postUnderwritingChange: PostUnderwritingChange
) => ReduxThunkAction<SubmitReturn>;
export const addAndSubmitPostUnderwritingChangeThunk: AddAndSubmitPostUnderwritingChangeThunk = (
    policyIndex: number,
    postUnderwritingChange: PostUnderwritingChange
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        addPostUnderwritingChangeActionCreator(policyIndex, postUnderwritingChange)
    );

export type UpdateAndSubmitPostUnderwritingChange = (
    policyIndex: number,
    postUnderwritingChangeIndex: number,
    postUnderwritingChange: PostUnderwritingChange
) => SubmitReturn;
type UpdateAndSubmitPostUnderwritingChangeThunk = (
    policyIndex: number,
    postUnderwritingChangeIndex: number,
    postUnderwritingChange: PostUnderwritingChange
) => ReduxThunkAction<SubmitReturn>;
export const updateAndSubmitPostUnderwritingChangeThunk: UpdateAndSubmitPostUnderwritingChangeThunk = (
    policyIndex: number,
    postUnderwritingChangeIndex: number,
    postUnderwritingChange: PostUnderwritingChange
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        updatePostUnderwritingChangeActionCreator(
            policyIndex,
            postUnderwritingChangeIndex,
            postUnderwritingChange
        )
    );

export type DeleteAndSubmitPostUnderwritingChange = (
    policyIndex: number,
    postUnderwritingChangeIndex: number
) => SubmitReturn;
type DeleteAndSubmitPostUnderwritingChangeThunk = (
    policyIndex: number,
    postUnderwritingChangeIndex: number
) => ReduxThunkAction<SubmitReturn>;
export const deleteAndSubmitPostUnderwritingChangeThunk: DeleteAndSubmitPostUnderwritingChangeThunk = (
    policyIndex: number,
    postUnderwritingChangeIndex: number
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        deletePostUnderwritingChangeActionCreator(policyIndex, postUnderwritingChangeIndex)
    );
