import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { startCase } from 'lodash';
import { connect } from 'react-redux';
import { State } from '../../redux/root-reducer';
import { showAuditTrail } from '../claim-audit-trail-functions';
import {
    AuditTrailFilter,
    ClaimChangeSet,
    getAuditTrailSortedByTimeStamp,
    sectionNames,
} from '../claim-audit-trail-reducer';
import { ClaimAuditTrailWrapper } from '../common-views/claim-audit-trail-wrapper';
import styles from '../styles';
import { AuditTrailAssessmentView } from './claim-audit-trail-assessment-details';
import { AuditTrailContactDetailsDeathView } from './claim-audit-trail-contact-details-death';
import { AuditTrailContactDetailsView } from './claim-audit-trail-contact-details';
import { AuditTrailDecisionsView } from './claim-audit-trail-decisions-view';
import { AuditTrailLifeAssuredDetailsView } from './claim-audit-trail-life-assured-details';
import { AuditTrailListsView } from './claim-audit-trail-lists-view';
import { AuditTrailSimpleView } from './claim-audit-trail-simple-view';
import { AuditTrailUnderwritingDetailsView } from './claim-audit-trail-underwriting-details-view';
import { AuditTrailDeathAssessmentView } from './claim-audit-trail-death-assessment-details';
import { AuditDocumentsStatus } from './claim-audit-trail-documents-status';
import { PaymentsAuditTrail } from './claim-audit-trail-payment-details';
import { LiquidAndDistributionAuditTrail } from './claim-audit-trail-liquid-and-distribution-details';
import { AuditTrailRADisputesView } from './claim-audit-trail-ra-disputes-details';
import { AuditTrailRaEventDetailsView } from './claim-audit-trail-ra-event-details';
import { DependantsAuditTrailView } from './claim-audit-trail-dependants';
import { AuditTrailMedicalHistoryView } from './claim-audit-trail-medical-history';
import { getClaim } from '../../claim/reducers';
import { Nullable } from '../../shared-types';
import { ClaimType } from '../../claim/claim-types';
import { AuditTrailFuneralClaimDetails } from './claim-audit-trail-funeral-claim-details';
import { AuditTrailFormDetailsView } from './form-details-view';

interface Props {
    auditTrailFilter: AuditTrailFilter;
    claimAuditTrail: ClaimChangeSet[];
    claimType: Nullable<ClaimType>;
}

export interface ChangeView {
    sectionName: string;
    sectionView: React.ReactNode;
}

const auditTrailSectionsView: React.FunctionComponent<Props> = ({
    auditTrailFilter,
    claimAuditTrail,
    claimType,
}: Props) => {
    return (
        <React.Fragment>
            {claimAuditTrail.map((auditTrail, index) => {
                const changeViews: ChangeView[] = getChangeViews(
                    auditTrail,
                    auditTrailFilter,
                    claimType
                );
                return changeViews.length > 0 ? (
                    <ClaimAuditTrailWrapper
                        key={`${auditTrail.timeStamp}${index}`}
                        user={auditTrail.user}
                        viewList={changeViews}
                        timestamp={auditTrail.timeStamp}
                    />
                ) : null;
            })}
        </React.Fragment>
    );
};
function getChangeViews(
    auditTrail: ClaimChangeSet,
    auditTrailFilter: AuditTrailFilter,
    claimType: Nullable<ClaimType>
) {
    const change = JSON.parse(auditTrail.change);
    const changeView: ChangeView[] = [];
    if (change) {
        Object.keys(change).forEach(key => {
            let sectionName: string = startCase(key).split(' ').join(' ');
            let sectionView: React.ReactNode = null;

            ({ sectionName, sectionView } = getSectionViews(
                key,
                sectionName,
                sectionView,
                change,
                claimType
            ));

            if (sectionView && showAuditTrail(auditTrailFilter, sectionName, auditTrail)) {
                changeView.push({
                    sectionName,
                    sectionView,
                });
            }
        });
    }
    return changeView;
}

function getSectionViews(
    key: string,
    sectionName: string,
    sectionView: React.ReactNode,
    change: Record<string, any>,
    claimType: Nullable<ClaimType>
) {
    if (!(change[key][0] === null && !change[key][1])) {
        const hasPayments = change.hasOwnProperty('payments') ? true : false;
        switch (key.toLowerCase()) {
            case 'additionalinformationrequired':
            case 'doctorDetails':
            case 'informationreceivedsummary':
            case 'reinsuranceinputdetails':
            case 'informationrecordsummary':
                if (key.toLowerCase() === 'reinsuranceinputdetails') {
                    sectionName = sectionNames.reinsuranceInput;
                }
                sectionView = <AuditTrailListsView changeSet={change[key]} section={key} />;
                break;
            case 'contactdetails':
                ({ sectionView, sectionName } = getContactDetailsViews(
                    change,
                    key,
                    sectionView,
                    sectionName
                ));
                break;
            case 'claimeventdetails':
                const claimNotes = change[key];
                if (
                    !Array.isArray(change[key]) &&
                    change[key].hasOwnProperty('claimNotes') &&
                    change[key].claimNotes[0] !== null
                ) {
                    sectionView = <AuditTrailListsView changeSet={change[key]} section={key} />;
                }
                if (claimNotes && change[key].hasOwnProperty('deathClaimDetails')) {
                    sectionView = <AuditTrailSimpleView changeSet={claimNotes} />;
                }
                if (change[key].hasOwnProperty('vopd')) {
                    sectionView = <AuditTrailRaEventDetailsView changeSet={change[key]} />;
                }
                break;
            case 'claimdetails':
                if (claimType === ClaimType.Funeral) {
                    sectionView = <AuditTrailFuneralClaimDetails changeSet={change[key]} />;
                } else {
                    if (
                        change[key] !== null &&
                        ((change[key] !== undefined &&
                            change[key].hasOwnProperty('claimEventDetails')) ||
                            (change[key] !== undefined &&
                                change[key].hasOwnProperty('financialDetails')) ||
                            (change[key] !== undefined &&
                                change[key].hasOwnProperty('claimNotes')) ||
                            (change[key][0] == null &&
                                change[key][1] !== null &&
                                ((change[key][1] !== undefined &&
                                    change[key][1].hasOwnProperty('claimEventDetails')) ||
                                    (change[key][1] !== undefined &&
                                        change[key][1].hasOwnProperty('financialDetails')) ||
                                    change[key].hasOwnProperty('vopd') ||
                                    (change[key][1] !== undefined &&
                                        change[key][1].hasOwnProperty('claimNotes')))))
                    ) {
                        sectionName = sectionNames.claimEventDetails;
                        sectionView = <AuditTrailRaEventDetailsView changeSet={change[key]} />;
                    } else {
                        sectionView = <AuditTrailSimpleView changeSet={change[key]} />;
                    }
                }
                break;
            case 'commonmedicalhistory':
                sectionName = 'Claim Details';
                sectionView = <AuditTrailMedicalHistoryView changeSet={change[key]} />;
                break;
            case 'disabilitydetails':
            case 'forensicinput':
            case 'incomemanagementplan':
            case 'medicalofficerinput':
                if (key.toLowerCase() === 'incomemanagementplan') {
                    sectionName = 'Management Plan';
                }
                sectionView = <AuditTrailSimpleView changeSet={change[key]} />;
                break;
            case 'assessmentdetails':
                if (typeof change[key].length !== 'undefined' && !hasPayments) {
                    sectionView = null;
                } else if (!hasPayments) {
                    sectionView = <AuditTrailAssessmentView changeSet={change[key]} />;
                }

                break;
            case 'deathassessmentdetails':
                if (typeof change[key].length !== 'undefined') {
                    sectionView = null;
                } else {
                    sectionView = <AuditTrailDeathAssessmentView changeSet={change[key]} />;
                }
                break;
            case 'retrospectiveunderwritingdecision':
                if (!Array.isArray(change[key])) {
                    sectionName = sectionNames.retrospectiveUnderwriting;
                    sectionView = <AuditTrailDecisionsView changeSet={change[key]} />;
                }
                break;
            case 'underwriting':
                if (!Array.isArray(change[key])) {
                    sectionName = sectionNames.underwritingDetails;
                    sectionView = <AuditTrailUnderwritingDetailsView changeSet={change[key]} />;
                }
                break;
            case 'documentsstatus':
                if (!Array.isArray(change[key])) {
                    sectionName = sectionNames.documentsStatus;
                    sectionView = <AuditDocumentsStatus changeSet={change[key]} />;
                }
                break;
            case 'payments':
                if (change[key] != null) {
                    sectionName = sectionNames.payments;
                    sectionView = <PaymentsAuditTrail changeSet={change[key]} />;
                }
                break;
            case 'liquidanddistribution':
                if (change[key] != null) {
                    sectionName = sectionNames.liquidAndDistribution;
                    sectionView = <LiquidAndDistributionAuditTrail changeSet={change[key]} />;
                }
                break;
            case 'dependants':
                if (change[key] != null) {
                    sectionName = sectionNames.dependants;
                    sectionView = <DependantsAuditTrailView changeSet={change[key]} />;
                }
                break;
            case 'lifeassureddetails':
            case 'claiminformation':
                if (
                    change[key].hasOwnProperty('medicalAidDetails') ||
                    change[key].hasOwnProperty('personalDetails') ||
                    change[key].hasOwnProperty('postalAddressDetails') ||
                    change[key].hasOwnProperty('residentialAddressDetails') ||
                    change[key].hasOwnProperty('previousPayments') ||
                    change[key].hasOwnProperty('contracts') ||
                    change[key].hasOwnProperty('occupationDetails') ||
                    change[key].hasOwnProperty('medicalHistory') ||
                    change[key].hasOwnProperty('MedicalAidDetails') ||
                    change[key].hasOwnProperty('PersonalDetails') ||
                    change[key].hasOwnProperty('PostalAddressDetails') ||
                    change[key].hasOwnProperty('ResidentialAddressDetails') ||
                    change[key].hasOwnProperty('PreviousPayments') ||
                    change[key].hasOwnProperty('Contracts') ||
                    change[key].hasOwnProperty('OccupationDetails') ||
                    change[key].hasOwnProperty('contactDetails') ||
                    change[key].hasOwnProperty('postalAddress') ||
                    change[key].hasOwnProperty('residentialAddress') ||
                    change[key].hasOwnProperty('sameAsResidential') ||
                    change[key].hasOwnProperty('bankingDetails') ||
                    change[key].hasOwnProperty('previousMarriages') ||
                    key.toLowerCase() === 'claiminformation'
                ) {
                    if (!hasPayments) {
                        sectionView = <AuditTrailLifeAssuredDetailsView changeSet={change[key]} />;
                    }
                }
                break;
            case 'disputes':
                if (change[key] != null) {
                    sectionName = sectionNames.disputes;
                    sectionView = <AuditTrailRADisputesView changeSet={change[key]} />;
                }
                break;
            case 'formdetails':
                sectionView = <AuditTrailFormDetailsView changeSet={change[key]} />;
                break;
            default:
                break;
        }
    }
    return { sectionName, sectionView };
}

function getContactDetailsViews(
    change: Record<string, any>,
    key: string,
    sectionView: React.ReactNode,
    sectionName: string
) {
    if (!Array.isArray(change[key])) {
        if (
            change[key].hasOwnProperty('claimants') ||
            change[key].hasOwnProperty('executors') ||
            change[key].hasOwnProperty('otherContacts')
        ) {
            sectionView = <AuditTrailContactDetailsDeathView changeSet={change[key]} />;
        } else {
            sectionName = sectionNames.contactDetails;
            sectionView = <AuditTrailContactDetailsView changeSet={change[key]} />;
        }
    }
    return { sectionView, sectionName };
}

const mapStateToProps = (state: State) => ({
    claimAuditTrail: getAuditTrailSortedByTimeStamp(state),
    claimType: getClaim(state).claimType,
});

const mapDispatchToProps = {};

export const AuditTrailSectionsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(auditTrailSectionsView));
