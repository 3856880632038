import { AnyAction } from 'redux';
import { State } from '../redux/root-reducer';
import { Client, ClientClaim } from './';
import {
    ClientSearchCriteria,
    ClientsLoadedActionPayload,
    SetSearchCriteriaActionPayload,
} from './actions';
import { Contract, ContractsLoadedAction } from './actions/contracts-loaded';
import { SetSelectedClientsAction } from './actions/set-selected-clients';
import {
    CONTRACTS_LOADED,
    SEARCH_RESULTS_LOADED,
    SET_SEARCH_CRITERIA,
    SET_SELECTED_CLIENTS,
} from './types';

export interface InitiateClaim {
    readonly clients?: Client[];
    readonly selectedClients?: Client[];
    readonly searchCriteria?: ClientSearchCriteria;
}

type Action = ContractsLoadedAction | SetSelectedClientsAction | AnyAction;
export function initiateClaimReducer(state: InitiateClaim = {}, action: Action): InitiateClaim {
    switch (action.type) {
        case SET_SEARCH_CRITERIA: {
            const payload: SetSearchCriteriaActionPayload = action.payload;
            return {
                ...state,
                searchCriteria: payload.searchCriteria,
            };
        }
        case SEARCH_RESULTS_LOADED: {
            const payload: ClientsLoadedActionPayload = action.payload;
            return {
                clients: payload.clients,
                selectedClients: undefined,
                searchCriteria: payload.criteria,
            };
        }
        case CONTRACTS_LOADED:
            return mergeContractsIn((action as ContractsLoadedAction).payload, state);
        case SET_SELECTED_CLIENTS: {
            const payload = (action as SetSelectedClientsAction).payload;
            const clients = state.clients || [];
            let selectedClients = [];
            for (const clientId of payload) {
                selectedClients.push(clients.find(client => client.clientId === clientId));
            }
            selectedClients = selectedClients.filter(client => client);
            return {
                clients: state.clients,
                // @ts-ignore
                selectedClients,
                searchCriteria: state.searchCriteria,
            };
        }
        default:
            return state;
    }
}

function mergeContractsIn(contracts: Contract[], state: InitiateClaim): InitiateClaim {
    if (!state.clients) {
        return state;
    }
    const clientId = contracts[0].clientId;

    const clientToAddContractsToo = state.clients.find(client => client.clientId === clientId);
    if (!clientToAddContractsToo) {
        throw new Error(`Client with client id ${clientId} was not found`);
    }

    const clientWithContracts = { ...clientToAddContractsToo, contracts };
    const clients = createNewArrayWithClient(clientWithContracts, state.clients || []);
    const selectedClients = createNewArrayWithClient(
        clientWithContracts,
        state.selectedClients || []
    );

    return {
        searchCriteria: state.searchCriteria,
        clients,
        selectedClients,
    };
}

function createNewArrayWithClient(clientToFind: Client, clients: Client[]): Client[] {
    const newArrayWithoutClient = clients.filter(c => c.clientId !== clientToFind.clientId);
    newArrayWithoutClient.push(clientToFind);
    return newArrayWithoutClient;
}

export function getSearchPolicyNumber(state: State) {
    const searchCriteria = state.initiateClaim.searchCriteria;
    return searchCriteria && searchCriteria.policyNumber;
}

export function hasExistingClaims(clientClaims: ClientClaim[]) {
    return (
        clientClaims &&
        clientClaims.length > 0 &&
        !(clientClaims.length === 1 && clientClaims[0].claimTypes[0] === 'Uninitiated')
    );
}

export function getClientsFullName(client: Client) {
    return [client.title, client.firstName, client.lastName]
        .filter(val => !!val && val !== '')
        .join(' ');
}
