import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { FormErrors, GenericForm } from '../../../forms';
import { State } from '../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../styles/styles';
import {
    UpdatePaymentItem,
    updatePaymentItemAction,
    UpdatePaymentItemModel,
} from '../../actions/update-payment-item';
import { ClaimDecision, getDecisions, Payment } from '../../reducer';
import { PaymentIncomeItemView } from './edit-income-payment-item-view';
import { getClaim } from '../../../claim';

interface Props extends StyledComponent {
    decisions: ClaimDecision[];
    paymentIndex: number;
    paymentItemIndex: number;
    payments: Payment[];
    updatePaymentItem: UpdatePaymentItem;
}

const EditPaymentItemForm = GenericForm<UpdatePaymentItemModel>();
const incomePaymentItemEdit: React.FunctionComponent<Props> = ({
    classes,
    decisions,
    paymentIndex,
    paymentItemIndex,
    payments,
    updatePaymentItem,
    ...rest
}: Props) => {
    const payment = payments[paymentIndex];
    const paymentItem = payment.paymentItems[paymentItemIndex];
    const beneficiaryId = payment.beneficiaryId || '';
    const decision = decisions.find(d => d.decisionId === paymentItem.decisionId);
    const extendedPayment: UpdatePaymentItemModel = {
        ...paymentItem,
        decisionAmount: decision && decision.decisionAmount,
        maximumAmountPayable:
            ((decision && decision.maximumAmountPayable) || 0) + (paymentItem.amountPayable || 0),
    };

    return (
        <EditPaymentItemForm
            onSubmit={values => updatePaymentItem(values, paymentIndex, paymentItemIndex)}
            initialValues={{
                ...extendedPayment,
            }}
            {...rest}
        >
            <PaymentIncomeItemView beneficiaryId={beneficiaryId} edit />
            <FormErrors />
        </EditPaymentItemForm>
    );
};

const mapStateToProps = (state: State) => {
    const claim = getClaim(state);
    return {
        decisions: getDecisions(claim),
        payments: claim.payments,
    };
};

const mapDispatchToProps = {
    updatePaymentItem: updatePaymentItemAction,
};

export const IncomePaymentItemEditView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(incomePaymentItemEdit));
