import React from 'react'; // eslint-disable-line
import { Condition, False, True } from '../condition';

export type Predicate = (value?: any) => boolean;
export const HasValue: Predicate = (value?: any) => !!value;
export const Is = (isValue?: any): Predicate => (value?: any) => value === isValue;

export interface FieldCheck {
    readonly fieldName: string;
    readonly check: Predicate;
}

interface Props {
    readonly fieldChecks: FieldCheck[];
    readonly children: React.ReactNode;
}

export const MultiCondition: React.FunctionComponent<Props> = ({
    fieldChecks,
    children,
}: Props) => {
    if (fieldChecks.length < 1) {
        throw new Error(`No Field Checks supplied to MultiCondition.\n
        Please provide at least 1 Field Check`);
    }
    const reversedChecks = fieldChecks.reverse();
    let nestedCondition = getCondition(reversedChecks[0], children);
    for (const fieldCheck of reversedChecks.slice(1)) {
        nestedCondition = getCondition(fieldCheck, nestedCondition);
    }

    return nestedCondition;
};

function getCondition(
    fieldCheck: FieldCheck,
    trueChildren: React.ReactNode,
    falseChildren?: React.ReactNode
) {
    return (
        <Condition when={fieldCheck.fieldName} passes={fieldCheck.check}>
            <True>{trueChildren}</True>
            <False>{falseChildren}</False>
        </Condition>
    );
}
