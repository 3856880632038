import { claimSubmit } from '../../../claim';
import { ClaimType } from '../../../claim/claim-types';
import { Claim } from '../../../claim/reducers';
import { addRecordReduction, updateRecordReduction } from '../../../redux/reductions';
import { ReduxThunkAction } from '../../../redux/redux-actions';
import { Nullable } from '../../../shared-types';
import { getIncomeCalculationsFromClaim, IncomeCalculation } from '../../income-calculator/reducer';
import {
    AssessmentDecisionViewModel,
    PolicyAssessmentDetails,
} from '../assessment-details-reducer';

type AddAssessmentDecisionReturn = Promise<any>;
export type AddAssessmentDecision = (
    assessmentDecision: AssessmentDecisionViewModel
) => AddAssessmentDecisionReturn;
type AddAssessmentDecisionAction = (
    assessmentDecision: AssessmentDecisionViewModel
) => ReduxThunkAction<AddAssessmentDecisionReturn>;
export const addAssessmentDecisionAction: AddAssessmentDecisionAction = (
    assessmentDecision: AssessmentDecisionViewModel
) => async (dispatch, getState) => {
    return await claimSubmit(dispatch, getState, claim =>
        getUpdatedClaim(claim, assessmentDecision)
    );
};

function getUpdatedClaim(claim: Claim, assessmentDecision: AssessmentDecisionViewModel): Claim {
    if (!('assessmentDetails' in claim)) {
        return claim;
    }

    const assessmentDetails = getUpdatedAssessmentDetails(
        claim.assessmentDetails as PolicyAssessmentDetails[],
        assessmentDecision
    );

    return {
        ...updateIncomeCalculations(claim, assessmentDecision),
        assessmentDetails,
    };
}

function getUpdatedAssessmentDetails(
    assessmentDetails: PolicyAssessmentDetails[],
    assessmentDecisionViewModel: AssessmentDecisionViewModel
): PolicyAssessmentDetails[] {
    if (assessmentDetails.length === 0) {
        return assessmentDetails;
    }

    const policyAssessmentDecisionsIndex = assessmentDetails.findIndex(
        pAD =>
            pAD.policyNumber === assessmentDecisionViewModel.policyNumber ||
            pAD.policyNumber === assessmentDecisionViewModel[0]?.policyNumber
    );
    if (policyAssessmentDecisionsIndex === -1) {
        throw new Error('Invalid Assessment Decision: Policy Number not found');
    }
    let policyAssessmentDecisions = assessmentDetails[policyAssessmentDecisionsIndex];

    const benefitAssessmentDecisionsIndex = policyAssessmentDecisions.benefits.findIndex(
        bAD =>
            bAD.benefitName === assessmentDecisionViewModel.benefitName ||
            bAD.benefitName === assessmentDecisionViewModel[0]?.benefitName
    );
    if (benefitAssessmentDecisionsIndex === -1) {
        throw new Error('Invalid Assessment Decision: Benefit not found');
    }
    let benefitAssessmentDecisions =
        policyAssessmentDecisions.benefits[benefitAssessmentDecisionsIndex];

    benefitAssessmentDecisions = {
        ...benefitAssessmentDecisions,
        decisions: addRecordReduction(
            benefitAssessmentDecisions.decisions,
            assessmentDecisionViewModel
        ),
    };

    policyAssessmentDecisions = {
        ...policyAssessmentDecisions,
        benefits: updateRecordReduction(
            policyAssessmentDecisions.benefits,
            benefitAssessmentDecisions,
            benefitAssessmentDecisionsIndex
        ),
    };

    return updateRecordReduction(
        assessmentDetails,
        policyAssessmentDecisions,
        policyAssessmentDecisionsIndex
    );
}

function updateIncomeCalculations(claim: Claim, decision: AssessmentDecisionViewModel) {
    if (
        (claim.claimType === ClaimType.IncomeProtector ||
            claim.claimType === ClaimType.Retrenchment) &&
        decision.decision === 'Approve'
    ) {
        let calculations: Nullable<IncomeCalculation[]> =
            getIncomeCalculationsFromClaim(claim) || [];
        const policyIndex = calculations.findIndex(c => c.policyNumber === decision.policyNumber);
        const benefitIndex = calculations.findIndex(b => b.benefitCode === decision.benefitCode);
        if (policyIndex === -1 || benefitIndex === -1) {
            calculations = addRecordReduction(calculations, {
                policyNumber: decision.policyNumber,
                decisionId: decision.id,
                benefitCode: decision.benefitCode,
            });
        } else {
            calculations = updateRecordReduction(
                calculations,
                {
                    policyNumber: decision.policyNumber,
                    decisionId: decision.id,
                    benefitCode: decision.benefitCode,
                },
                policyIndex
            );
        }
        return {
            ...claim,
            incomeCalculations: calculations,
        };
    }

    return {
        ...claim,
    };
}
