import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { FullWidthDatePicker, FullWidthTextField } from '../../../../../../forms';
import { LastNameMask, TelephoneNumberMask } from '../../../../../../forms/input-masks';
import { AddressDetailsSubForm } from '../../../../../../forms/sub-forms/address-details';
import { requiredValidator } from '../../../../../../forms/validations';
import styles from '../../../../../../styles/styles';

const funeralParlourEdit = ({ classes }: any) => {
    const prefix = 'funeralParlour.';
    return (
        <Grid container spacing={5}>
            <Grid item xs={6}>
                <FullWidthDatePicker
                    label="Funeral Date"
                    name={`${prefix}dateOfFuneral`}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    label="Parlour Name"
                    name={`${prefix}name`}
                    validate={requiredValidator}
                    mask={LastNameMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    label="Telephone Number"
                    name={`${prefix}telephoneNumber`}
                    validate={requiredValidator}
                    mask={TelephoneNumberMask}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.marginTop}>
                    Address Details
                </Typography>
            </Grid>
            <AddressDetailsSubForm
                addressPropertyName={`${prefix}addressDetails`}
                addressType="street"
                required
            />
        </Grid>
    );
};

export const FuneralParlourEdit = withStyles(styles)(funeralParlourEdit);
