import React from 'react'; // eslint-disable-line

import { Grid, withStyles, Tooltip, Button, Paper, Typography } from '@material-ui/core';
import { AddCircle, Delete, Edit } from '@material-ui/icons';
import { connect, MapDispatchToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AddFormModal, EditFormModal } from '../../../../../form-modal';
import {
    OpenAddModal,
    openAddModalAction,
    OpenEditModal,
    openEditModalAction,
} from '../../../../../form-modal/actions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { State } from '../../../../../redux/root-reducer';
import { OpenDialog, openDialogAction } from '../../../../../confirmation-dialog';
import {
    AddCurrentDoctor,
    DeleteCurrentDoctor,
    UpdateCurrentDoctor,
} from '../action/current-medical-doctor';
import { MedicalDoctorForm } from './medical-doctor-form';
import { Doctors } from '../reducer';
import {
    DeleteDoctorsConsultation,
    AddDoctorsConsultation,
    UpdateDoctorsConsultation,
} from '../action/current-doctors-consultation';
import { MedicalDoctorView } from './medical-doctor-view';
import { DoctorsConsultations } from './doctors-consultations-view';
import { ButtonWrapper } from '../../../../../forms';

interface DispatchProps {
    openAddModal: OpenAddModal;
    openEditModal: OpenEditModal;
    openDialog: OpenDialog;
}

interface OwnProps {
    addDoctor: AddCurrentDoctor;
    updateDoctor: UpdateCurrentDoctor;
    deleteDoctor: DeleteCurrentDoctor;
    addConsultation: AddDoctorsConsultation;
    updateConsultation: UpdateDoctorsConsultation;
    deleteConsultation: DeleteDoctorsConsultation;
}

type Props = StyledComponent &
    DispatchProps &
    OwnProps & {
        doctorsDetails: Doctors[];
        isReadOnly: boolean;
        prefix: string;
    };

const medicalConsultationView: React.FunctionComponent<Props> = ({
    classes,
    openAddModal,
    isReadOnly,
    addDoctor,
    doctorsDetails,
    openEditModal,
    updateDoctor,
    openDialog,
    deleteDoctor,
    addConsultation,
    updateConsultation,
    deleteConsultation,
    prefix,
}: Props) => {
    return (
        <React.Fragment>
            <Grid container justify="space-between" className={classes.marginTop}>
                <Grid item xs={10} lg={11}>
                    <Typography variant="subtitle2">{prefix} Medical Consultation Log</Typography>
                </Grid>
                <ButtonWrapper>
                    <Grid item xs={2} lg={1} className={classes.rightAlign}>
                        <AddDoctorButton
                            addDoctor={addDoctor}
                            isReadOnly={isReadOnly}
                            openAddModal={openAddModal}
                        />
                    </Grid>
                </ButtonWrapper>
            </Grid>
            {doctorsDetails.map((doctor, doctorIndex) => {
                return (
                    <Paper className={classes.paper} key={doctorIndex}>
                        <Grid container justify="flex-start">
                            <Grid item xs={8} lg={10}>
                                <Typography style={{ marginTop: '6px' }} variant="h5">
                                    {`Doctors Details ${doctorIndex + 1}`}
                                </Typography>
                            </Grid>
                            <ButtonWrapper>
                                <Grid item xs={4} lg={2} className={classes.rightAlign}>
                                    <EditDoctorButton
                                        doctorIndex={doctorIndex}
                                        doctors={doctorsDetails}
                                        isReadOnly={isReadOnly}
                                        openEditModal={openEditModal}
                                        updateRecord={updateDoctor}
                                    />
                                    <DeleteDoctorButton
                                        deleteRecord={deleteDoctor}
                                        doctorIndex={doctorIndex}
                                        isReadOnly={isReadOnly}
                                        openDialog={openDialog}
                                    />
                                </Grid>
                            </ButtonWrapper>
                            <MedicalDoctorView doctorsDetails={doctor} />
                            <DoctorsConsultations
                                addConsultation={addConsultation}
                                deleteConsultation={deleteConsultation}
                                doctorIndex={doctorIndex}
                                doctor={doctor}
                                updateConsultation={updateConsultation}
                            />
                        </Grid>
                    </Paper>
                );
            })}
        </React.Fragment>
    );
};

interface AddDoctorProps {
    addDoctor: AddCurrentDoctor;
    isReadOnly: boolean;
    openAddModal: OpenAddModal;
}

const AddDoctorFormModal = AddFormModal<Doctors>();
function AddDoctorButton({ addDoctor, isReadOnly, openAddModal }: AddDoctorProps) {
    if (isReadOnly) {
        return null;
    }

    return (
        <ButtonWrapper>
            <Tooltip title="Add Doctors Details">
                <Button id="AddDoctorsDetails" color="secondary" onClick={addDoctorRecord}>
                    <AddCircle />
                </Button>
            </Tooltip>
        </ButtonWrapper>
    );

    function addDoctorRecord() {
        openAddModal({
            modalContent: (
                <AddDoctorFormModal
                    recordDetailView={<MedicalDoctorForm heading="Add Doctors Details" />}
                    addRecord={record => addDoctor(record)}
                />
            ),
            modalData: {},
        });
    }
}

interface EditDoctorProps {
    doctorIndex: number;
    doctors: Doctors[];
    isReadOnly: boolean;
    openEditModal: OpenEditModal;
    updateRecord: UpdateCurrentDoctor;
}

const EditDoctorFormModal = EditFormModal<Doctors>();
function EditDoctorButton({
    doctorIndex,
    doctors,
    isReadOnly,
    openEditModal,
    updateRecord,
}: EditDoctorProps) {
    if (isReadOnly) {
        return null;
    }

    return (
        <ButtonWrapper>
            <Tooltip title="Edit Doctors Details">
                <Button
                    id="EditDoctorsDetails"
                    color="secondary"
                    onClick={() => openEditView(doctorIndex)}
                >
                    <Edit />
                </Button>
            </Tooltip>
        </ButtonWrapper>
    );

    function openEditView(doctorIndex: number) {
        openEditModal({
            modalContent: (
                <EditDoctorFormModal
                    recordDetailView={<MedicalDoctorForm heading="Edit Doctors Details" />}
                    records={doctors}
                    updateRecord={(doctors, index) => updateRecord(index, doctors)}
                />
            ),
            modalData: {
                recordIndex: doctorIndex,
            },
        });
    }
}

interface DeleteDoctorProps {
    doctorIndex: number;
    deleteRecord: DeleteCurrentDoctor;
    isReadOnly: boolean;
    openDialog: OpenDialog;
}

function DeleteDoctorButton({
    deleteRecord,
    doctorIndex,
    isReadOnly,
    openDialog,
}: DeleteDoctorProps) {
    if (isReadOnly) {
        return null;
    }

    return (
        <Tooltip title="Delete Doctors Details">
            <Button
                id="DeleteDoctorsDetails"
                color="secondary"
                onClick={() => {
                    confirmDelete(doctorIndex);
                }}
            >
                <Delete />
            </Button>
        </Tooltip>
    );

    function confirmDelete(consultationIndex: number) {
        openDialog({
            content: {
                title: 'Delete Doctors Details',
                body: `Are you sure you would like to delete doctors details ${
                    consultationIndex + 1
                }?`,
            },
            yesAction: {
                action: () => deleteRecord(consultationIndex),
            },
        });
    }
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    openAddModal: record => dispatch(openAddModalAction(record)),
    openEditModal: record => dispatch(openEditModalAction(record)),
    openDialog: options => dispatch(openDialogAction(options)),
});

const mapStateToProps = (state: State) => ({
    isReadOnly: state.claimSession.readOnly,
});

export const MedicalConsultationView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(medicalConsultationView));
