import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { getDeathAssessmentDetails } from '../../assessment/decisions/death/death-assessment-details-reducer';
import { State } from '../../redux/root-reducer';
import styles from '../../styles/styles';
import {
    ChangeFieldSet,
    changeRecordFromAddChangeSet,
    changeRecordFromChangeSet,
} from '../claim-audit-trail-functions';
import { AuditTrailDetails } from '../common-views/claim-audit-trail-field-details';
import { mapSectionView } from '../common-views/claim-audit-trail-wrapper';
import { AssessmentDetails } from '../../assessment/decisions/death/death-assessment-details-reducer';

interface Props {
    assessmentDetails: AssessmentDetails;
    changeSet: Record<string, any>;
}

const auditTrailDeathAssessmentView: React.FunctionComponent<Props> = ({
    assessmentDetails,
    changeSet,
}: Props) => {
    const returnView: React.ReactNode[] = [];

    returnView.push(getRiskAssessmentDetails(changeSet, assessmentDetails));
    returnView.push(getInvestmentAssessmentDetails(changeSet, assessmentDetails));

    return mapSectionView(returnView);
};

function getRiskAssessmentDetails(
    changeRecord: Record<string, any>,
    assessmentDetails: AssessmentDetails
) {
    let records: ChangeFieldSet[] = [];
    let policyNumberIndex = -1;
    let benefitNumberIndex = -1;
    let decisionNumberIndex = -1;
    let assessmentDescription = 'Assessment';
    const claimLiability = 'claimLiability';
    const errorMessage = 'errorMessage';
    Object.keys(changeRecord).forEach(policyIndex => {
        Object.keys(changeRecord[policyIndex]).forEach(policyKey => {
            if (policyKey === '_t') {
                return;
            }

            const index = parseInt(policyKey);
            Object.keys(changeRecord[policyIndex][index]).forEach(benefitsKey => {
                if (benefitsKey === '_t') {
                    return;
                }

                if (benefitsKey.toLowerCase() === 'benefits') {
                    if (
                        changeRecord[policyIndex][index][benefitsKey][index].hasOwnProperty(
                            claimLiability
                        )
                    ) {
                        if (
                            changeRecord[policyIndex][index][benefitsKey][index][
                                claimLiability
                            ].hasOwnProperty(errorMessage)
                        ) {
                            changeRecord[policyIndex][index][benefitsKey][index][claimLiability][
                                'message'
                            ] = [
                                changeRecord[policyIndex][index][benefitsKey][index][
                                    claimLiability
                                ][errorMessage][0],
                                changeRecord[policyIndex][index][benefitsKey][index][
                                    claimLiability
                                ][errorMessage][1],
                            ];
                            delete changeRecord[policyIndex][index][benefitsKey][index][
                                claimLiability
                            ][errorMessage];
                        }
                        records = changeRecordFromChangeSet(
                            changeRecord[policyIndex][index][benefitsKey][index][claimLiability]
                        );
                    }
                    Object.keys(changeRecord[policyIndex][index][benefitsKey]).forEach(
                        benefitIndex => {
                            if (!isNaN(Number(benefitIndex))) {
                                Object.keys(
                                    changeRecord[policyIndex][index][benefitsKey][benefitIndex]
                                ).forEach(decisionsKey => {
                                    if (decisionsKey.toLowerCase() === 'decisions') {
                                        Object.keys(
                                            changeRecord[policyIndex][index][benefitsKey][
                                                benefitIndex
                                            ][decisionsKey]
                                        ).forEach(decisionIndex => {
                                            if (!isNaN(Number(decisionIndex))) {
                                                policyNumberIndex = Number(policyIndex);
                                                benefitNumberIndex = Number(benefitIndex);
                                                decisionNumberIndex = Number(decisionIndex);
                                                if (
                                                    Array.isArray(
                                                        changeRecord[policyIndex][index][
                                                            benefitsKey
                                                        ][benefitIndex][decisionsKey][decisionIndex]
                                                    )
                                                ) {
                                                    assessmentDescription =
                                                        changeRecord[policyIndex][index][
                                                            benefitsKey
                                                        ][benefitIndex][decisionsKey][decisionIndex]
                                                            .assessmentType;
                                                    records = changeRecordFromAddChangeSet(
                                                        changeRecord[policyIndex][index][
                                                            benefitsKey
                                                        ][benefitIndex][decisionsKey][
                                                            decisionIndex
                                                        ][0]
                                                    );
                                                } else {
                                                    assessmentDescription =
                                                        changeRecord[policyIndex][index][
                                                            benefitsKey
                                                        ][benefitIndex][decisionsKey][decisionIndex]
                                                            .assessmentType;
                                                    records = changeRecordFromChangeSet(
                                                        changeRecord[policyIndex][index][
                                                            benefitsKey
                                                        ][benefitIndex][decisionsKey][decisionIndex]
                                                    );
                                                }
                                                if (!assessmentDescription) {
                                                    assessmentDescription =
                                                        decisionIndex === '0'
                                                            ? 'Assessment'
                                                            : 'Reassessment';
                                                }
                                            }
                                        });
                                    }
                                });
                            }
                        }
                    );
                }
            });
        });
    });

    const headings: string[] = getSectionHeadings(
        assessmentDescription,
        assessmentDetails,
        policyNumberIndex,
        benefitNumberIndex,
        decisionNumberIndex
    );
    if (records.length > 0) {
        return <AuditTrailDetails subSection={headings} auditTrailRecords={records} />;
    }
}

function getInvestmentAssessmentDetails(
    changeRecord: Record<string, any>,
    assessmentDetails: AssessmentDetails
) {
    let records: ChangeFieldSet[] = [];
    let policyNumberIndex = -1;
    let assessmentDescription = 'Assessment';

    Object.keys(changeRecord).forEach(policyIndex => {
        Object.keys(changeRecord[policyIndex]).forEach(policyKey => {
            if (policyKey === '_t') {
                return;
            }

            const index = parseInt(policyKey);
            Object.keys(changeRecord[policyIndex][index]).forEach(decisionsKey => {
                if (decisionsKey === '_t') {
                    return;
                }
                if (decisionsKey.toLowerCase() === 'claimliability') {
                    if (
                        changeRecord[policyIndex][index][decisionsKey].hasOwnProperty(
                            'errorMessage'
                        )
                    ) {
                        changeRecord[policyIndex][index][decisionsKey]['message'] = [
                            changeRecord[policyIndex][index][decisionsKey]['errorMessage'][0],
                            changeRecord[policyIndex][index][decisionsKey]['errorMessage'][1],
                        ];
                        delete changeRecord[policyIndex][index][decisionsKey]['errorMessage'];
                    }
                    records = changeRecordFromChangeSet(
                        changeRecord[policyIndex][index][decisionsKey]
                    );
                }
                if (decisionsKey.toLowerCase() === 'decisions') {
                    Object.keys(changeRecord[policyIndex][index][decisionsKey]).forEach(
                        decisionIndex => {
                            if (!isNaN(Number(decisionIndex))) {
                                if (decisionsKey.toLowerCase() === 'decisions') {
                                    Object.keys(
                                        changeRecord[policyIndex][index][decisionsKey]
                                    ).forEach(decisionIndex => {
                                        if (!isNaN(Number(decisionIndex))) {
                                            policyNumberIndex = Number(policyIndex);
                                            if (
                                                Array.isArray(
                                                    changeRecord[policyIndex][index][decisionsKey][
                                                        decisionIndex
                                                    ]
                                                )
                                            ) {
                                                assessmentDescription =
                                                    changeRecord[policyIndex][index][decisionsKey][
                                                        decisionIndex
                                                    ].assessmentType;
                                                records = changeRecordFromAddChangeSet(
                                                    changeRecord[policyIndex][index][decisionsKey][
                                                        decisionIndex
                                                    ][0]
                                                );
                                            } else {
                                                assessmentDescription =
                                                    changeRecord[policyIndex][index][decisionsKey][
                                                        decisionIndex
                                                    ].assessmentType;
                                                records = changeRecordFromChangeSet(
                                                    changeRecord[policyIndex][index][decisionsKey][
                                                        decisionIndex
                                                    ]
                                                );
                                            }
                                            if (!assessmentDescription) {
                                                assessmentDescription =
                                                    decisionIndex === '0'
                                                        ? 'Assessment'
                                                        : 'Reassessment';
                                            }
                                        }
                                    });
                                }
                            }
                        }
                    );
                }
            });
        });
    });

    const headings: string[] = getInvestmentSectionHeadings(assessmentDetails, policyNumberIndex);
    if (records.length > 0) {
        return <AuditTrailDetails subSection={headings} auditTrailRecords={records} />;
    }
}

function getSectionHeadings(
    assessmentDescription: string,
    assessmentDetails: AssessmentDetails,
    policyNumberIndex: number,
    benefitNumberIndex: number,
    decisionNumberIndex: number
) {
    const headings: string[] = [];
    if (assessmentDetails.riskPolicyAssessmentDetails[policyNumberIndex]) {
        headings.push(
            `Policy Number - ${assessmentDetails.riskPolicyAssessmentDetails[policyNumberIndex].policyNumber}`
        );
    }
    if (
        assessmentDetails.riskPolicyAssessmentDetails[policyNumberIndex] &&
        assessmentDetails.riskPolicyAssessmentDetails[policyNumberIndex].benefits &&
        assessmentDetails.riskPolicyAssessmentDetails[policyNumberIndex].benefits.length > 0 &&
        assessmentDetails.riskPolicyAssessmentDetails[policyNumberIndex].benefits[
            benefitNumberIndex
        ]
    ) {
        const benefitName =
            assessmentDetails.riskPolicyAssessmentDetails[policyNumberIndex].benefits[
                benefitNumberIndex
            ].benefitName;
        if (
            assessmentDetails.riskPolicyAssessmentDetails[policyNumberIndex].benefits[
                benefitNumberIndex
            ].decisions &&
            assessmentDetails.riskPolicyAssessmentDetails[policyNumberIndex].benefits[
                benefitNumberIndex
            ].decisions.length > 0 &&
            assessmentDetails.riskPolicyAssessmentDetails[policyNumberIndex].benefits[
                benefitNumberIndex
            ].decisions[decisionNumberIndex]
        ) {
            headings.push(`${assessmentDescription} for Benefit - ${benefitName}`);
        }
    }
    return headings;
}

function getInvestmentSectionHeadings(
    assessmentDetails: AssessmentDetails,
    policyNumberIndex: number
) {
    const headings: string[] = [];
    if (assessmentDetails.investmentPolicyAssessments[policyNumberIndex]) {
        headings.push(
            `Policy Number - ${assessmentDetails.investmentPolicyAssessments[policyNumberIndex].policyNumber}`
        );
    }
    return headings;
}

const mapStateToProps = (state: State) => ({
    assessmentDetails: getDeathAssessmentDetails(state),
});

const mapDispatchToProps = {};

export const AuditTrailDeathAssessmentView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(auditTrailDeathAssessmentView));
