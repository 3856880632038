import { AddRecordCollectionAction } from '../../redux/collection-reducer';
import { InformationReceivedRecord } from '../reducer';
import { ADD_INFORMATION_RECEIVED_RECORD } from '../types';

export type AddInformationReceivedRecordAction = AddRecordCollectionAction<InformationReceivedRecord>;

export const addInformationReceivedRecordActionCreator = (
    informationReceivedRecord: InformationReceivedRecord
): AddInformationReceivedRecordAction => {
    return {
        type: ADD_INFORMATION_RECEIVED_RECORD,
        record: informationReceivedRecord,
    };
};
