import React from 'react'; // eslint-disable-line
import { ButtonWrapper } from '../../../../forms';
import { Button, Divider, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { connect, MapStateToProps } from 'react-redux';
import { OpenAddModal, openAddModalAction } from '../../../../form-modal/actions';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import {
    Decision,
    getRaDeathAssessmentDecisions,
    DecisionModel,
    getBlankAssessmentRecord,
} from '../ra-death-assessment-details-reducer';
import RaDeathDecisionEditView from './ra-death-assessment-decision-edit';
import {
    getDisplayDate,
    formatPercent,
    getFullName,
    getCodeDescription,
} from '../../../../shared-functions';
import { ExpansionPanel } from '../../../../forms/form-components/expansion-panel/expansion-panel';
import {
    Dependant,
    getDependants,
    getLinkedBeneficiaries,
    getSpouses,
    getDependantsRelationship,
} from '../../../synopsis/ra-death/dependants/reducer';
import { Code } from '../../../../codes/codes';
import { LabeledValue } from '../../../../controls/labeled-value';
import { AuthState } from '../../../../auth/auth-reducer';
import { AddFormModal } from '../../../../form-modal';
import {
    AddAndSubmitAssessmentDecisionRecord,
    addAndSubmitAssessmentDecisionRecordThunk,
} from '../actions/add-assessment-decision';

interface OwnProps {}

interface DispatchProps {
    openAddModal: OpenAddModal;
    addAssessmentDecision: AddAndSubmitAssessmentDecisionRecord;
}

interface StateProps {
    readonly currentUser: AuthState;
    readonly decisions: Decision[];
    readonly dependants: Dependant[];
    isReadOnly: boolean;
}

type Props = StateProps & DispatchProps & OwnProps & StyledComponent;
const AddAssessmentDecisionFormModal = AddFormModal<DecisionModel>();
const raDeathAssessmentDecisionView: React.FunctionComponent<Props> = ({
    addAssessmentDecision,
    currentUser,
    decisions,
    dependants,
    openAddModal,
    isReadOnly,
}: Props) => {
    const addDecision = () => {
        const blankDecision = getBlankAssessmentRecord(decisions, dependants, currentUser);
        openAddModal({
            modalContent: (
                <AddAssessmentDecisionFormModal
                    recordDetailView={<RaDeathDecisionEditView />}
                    addRecord={record => addAssessmentDecision(record)}
                    blankRecord={blankDecision}
                />
            ),
            modalData: {},
        });
    };
    return (
        <React.Fragment>
            <Grid container justify="space-between">
                <Grid item xs={10}>
                    <Typography variant="subtitle2" style={{ marginTop: 4 }}>
                        Decision / Recommendation
                    </Typography>
                </Grid>
                <ButtonWrapper>
                    <Grid item xs={2} container justify="flex-end">
                        <Tooltip title="Edit recommendation">
                            <Button
                                id="add_assessment_decision"
                                color="secondary"
                                onClick={() => addDecision()}
                            >
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            </Grid>
            <DecisionsView decisions={decisions} dependants={dependants} />
        </React.Fragment>
    );
};

interface DecisionsProps {
    decisions: Decision[];
    dependants: Dependant[];
}
const DecisionsView: React.FunctionComponent<DecisionsProps> = ({
    decisions,
    dependants,
}: DecisionsProps) => {
    if (decisions.length === 0) {
        return (
            <DecisionDetailsView
                decision={getDefaultDecision(dependants)}
                dependants={dependants}
                current
            />
        );
    }
    const currentDecision = decisions[0];
    const otherDecisions: Decision[] = [];
    decisions.forEach((decision, index) => {
        if (index !== 0) {
            otherDecisions.push(decision);
        }
    });
    return (
        <React.Fragment>
            <div style={{ backgroundColor: '#F0F1F4', width: '103%', marginLeft: -16 }}>
                <Grid item xs={12} style={{ marginLeft: 20 }}>
                    <Typography variant="subtitle2">
                        {getDisplayDate(currentDecision.assessmentDate, 'yyyy/MM/dd HH:mm')} |{' '}
                        {currentDecision.assessor} | {currentDecision.assessmentType}
                    </Typography>
                </Grid>
            </div>
            <DecisionDetailsView decision={currentDecision} dependants={dependants} current />
            <DecisionsExpansionView decisions={otherDecisions} dependants={dependants} />
        </React.Fragment>
    );
};

const DecisionsExpansionView: React.FunctionComponent<DecisionsProps> = ({
    decisions,
    dependants,
}: DecisionsProps) => {
    return (
        <React.Fragment>
            {decisions.map((decision, decisionIndex) => {
                return (
                    <ExpansionPanel
                        name="Decision"
                        customHeader={
                            <Typography variant="subtitle2" style={{ marginLeft: 4 }}>
                                {getDisplayDate(decision.assessmentDate, 'yyyy/MM/dd HH:mm')} |{' '}
                                {decision.assessor} | {decision.assessmentType}
                            </Typography>
                        }
                        key={`decision_${decisionIndex}`}
                        style={{ marginLeft: -24, marginRight: -24 }}
                    >
                        <DecisionDetailsView decision={decision} dependants={dependants} />
                    </ExpansionPanel>
                );
            })}
        </React.Fragment>
    );
};

interface DecisionDetailsProps {
    decision: Decision;
    dependants: Dependant[];
    current?: boolean;
}
const DecisionDetailsView: React.FunctionComponent<DecisionDetailsProps> = ({
    current,
    decision,
    dependants,
}: DecisionDetailsProps) => {
    let totalAllocation = 0;
    return (
        <div
            style={{
                backgroundColor: '#F0F1F4',
                width: '103%',
                marginLeft: -16,
                paddingTop: 4,
                paddingBottom: 4,
            }}
        >
            <Grid container justify="space-between" style={{ marginLeft: 20, marginBottom: 4 }}>
                <Grid item xs={3}>
                    <Typography variant="subtitle2">Dependant</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography variant="subtitle2">Age</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="subtitle2">Relationship</Typography>
                    <Typography variant="subtitle2">to Life Assured</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="subtitle2">Reason</Typography>
                </Grid>
                <Grid item xs={2} container justify="flex-end">
                    <Typography variant="subtitle2" style={{ marginRight: 40 }}>
                        {current ? `Current Allocation` : `Allocation`}
                    </Typography>
                    <Typography variant="subtitle2" style={{ marginRight: 40 }}>
                        {getDisplayDate(decision.assessmentDate)}
                    </Typography>
                </Grid>
            </Grid>
            {decision.recommendations.map((recommendation, index) => {
                const dependant = dependants.find(d => d.id === recommendation.dependantId);
                const spouses = getSpouses(dependants);
                const { name, age, relationship, childOf } = getDependantDetails(
                    dependant,
                    spouses
                );
                const reason = recommendation.reason
                    ? recommendation.reason === 'Other'
                        ? `${recommendation.reason} - ${recommendation.reasonDescription}`
                        : recommendation.reason
                    : '-';
                totalAllocation += recommendation.allocation;
                return (
                    <Grid
                        container
                        justify="space-between"
                        key={`recommendation_${index}`}
                        style={{ marginLeft: 20 }}
                    >
                        <Grid item xs={3}>
                            <Typography>{name}</Typography>
                            {childOf && childOf !== ' ' && (
                                <Typography style={{ fontSize: '0.75rem', fontWeight: 300 }}>
                                    {childOf}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={1}>
                            <Typography>{age}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>{relationship}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography>{reason}</Typography>
                        </Grid>
                        <Grid item xs={2} container justify="flex-end">
                            <Typography style={{ marginRight: 40 }}>
                                {formatPercent(recommendation.allocation / 100)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider
                                style={{
                                    backgroundColor: '#8B92A7',
                                    marginRight: 38,
                                    marginLeft: -4,
                                    marginTop: 4,
                                    marginBottom: 4,
                                }}
                            />
                        </Grid>
                    </Grid>
                );
            })}
            <Grid item xs={12} container justify="flex-end">
                <Grid item xs={2} container justify="flex-end">
                    <Typography style={{ marginRight: 21 }}>
                        {formatPercent(totalAllocation / 100)}
                    </Typography>
                </Grid>
            </Grid>
            {decision.briefSummary && (
                <Grid item xs={12} style={{ marginLeft: 16 }}>
                    <LabeledValue
                        label="Brief summary of the claim"
                        value={decision.briefSummary}
                        isUnsafe
                    />
                </Grid>
            )}
            {decision.assessmentSummary && (
                <Grid item xs={12} style={{ marginLeft: 16 }}>
                    <LabeledValue
                        label="Assessor decision summary"
                        value={decision.assessmentSummary}
                        isUnsafe
                    />
                </Grid>
            )}
            {decision.assessmentType === 'Final Decision' && (
                <Grid container style={{ marginLeft: 16 }}>
                    <Grid item xs={12}>
                        <LabeledValue
                            label="Trustee decision summary"
                            value={decision.trusteeSummary}
                            isUnsafe
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LabeledValue
                            label="Trustee meeting date"
                            value={getDisplayDate(decision.trusteeMeetingDate)}
                            isUnsafe
                        />
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

function getDependantDetails(dependant?: Dependant, spouses?: Code<string>[]) {
    if (dependant) {
        if (dependant.dependantType === 'Organisation') {
            const organisationDetails = dependant.organisationDetails || {};
            return {
                name: organisationDetails.companyName || '',
                age: '-',
                relationship: getCodeDescription(
                    'relationship',
                    organisationDetails.relationshipToLifeAssured
                ),
            };
        }
        const personalDetails = dependant.personalDetails || {};
        let childOf = ' ';
        if (dependant.relationship === 'MinorChild') {
            const linkedParents = getLinkedBeneficiaries(spouses || [], dependant.parent);
            if (Array.isArray(linkedParents) && linkedParents.length > 0) {
                childOf = `${getDependantsRelationship(dependant.relationshipDescription)} of ${
                    linkedParents[0]
                }`;
            } else {
                childOf = 'Parent not listed';
            }
        }
        return {
            name: getFullName(personalDetails),
            age: personalDetails.age ? personalDetails.age.toString() : '-',
            relationship: getCodeDescription('relationship', dependant.relationshipDescription),
            childOf,
        };
    }
    return {
        name: 'Unknown (deleted)',
        age: '-',
        relationship: '-',
        childOf: ' ',
    };
}

function getDefaultDecision(dependants: Dependant[]) {
    return {
        id: '',
        assessmentType: '',
        assessmentDate: new Date(),
        assessmentSummary: '',
        assessor: '',
        recommendations: dependants.map(dependant => {
            return {
                dependantId: dependant.id,
                reason: '',
                allocation: 0,
            };
        }),
        percentageSplits: dependants.map(dependant => {
            return {
                dependantId: dependant.id,
                reason: '',
                allocation: 0,
            };
        }),
    };
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    currentUser: state.auth as AuthState,
    decisions: getRaDeathAssessmentDecisions(state),
    dependants: getDependants(state),
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = {
    addAssessmentDecision: (record: any) => addAndSubmitAssessmentDecisionRecordThunk(record),
    openAddModal: openAddModalAction,
};

export const RaDeathAssessmentDecisionView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(raDeathAssessmentDecisionView));
