import React from 'react'; // eslint-disable-line
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { Typography, Paper, withStyles, Grid, Divider } from '@material-ui/core';
import { FullWidthTextField, FullWidthDatePicker } from '../../../../../../forms';
import { UpdateBeneficiariesView } from './update-external-beneficiary';
import { Beneficiary } from '../../reducer';
import { requiredValidator } from '../../../../../../forms/validations';
import { CurrencyMask } from '../../../../../../forms/input-masks';
import { convertToNumber } from '../../../../../../shared-functions';

type Props = StyledComponent & {
    beneficiaries: Beneficiary[];
};
const updateExternalPolicyView: React.FunctionComponent<Props> = ({
    classes,
    beneficiaries,
}: Props) => {
    return (
        <React.Fragment>
            <Typography variant="h5">Update external policy details</Typography>
            <Divider></Divider>
            <Paper className={classes.paper}>
                <Grid container spacing={5} justify="flex-start">
                    <Grid item xs={6} lg={6}>
                        <FullWidthTextField
                            id="insurerName"
                            name="insurerName"
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <FullWidthTextField id="productType" name="productType" />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <FullWidthTextField id="policyNumber" name="policyNumber" />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <FullWidthTextField
                            id="sumAssured"
                            name="sumAssured"
                            mask={CurrencyMask}
                            parse={convertToNumber}
                        />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <FullWidthDatePicker id="issueDate" name="issueDate" disableFuture />
                    </Grid>
                </Grid>
                <UpdateBeneficiariesView classes={classes} beneficiaries={beneficiaries} />
            </Paper>
        </React.Fragment>
    );
};
export const UpdateExternalPolicyView = withStyles(styles)(updateExternalPolicyView);
