import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { ExpansionPanel } from '../../../../../forms/form-components/expansion-panel/expansion-panel';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { ClaimantContactDetailsView } from './claimant-contact-details';
import {
    Claimant,
    getDeathClaimContactDetails,
} from '../../contact-details/contact-details-reducer';
import { AddClaimantDetailsView } from './add-claimant-details-view';
import { connect } from 'react-redux';
import { State } from '../../../../../redux/root-reducer';

type Props = StyledComponent & {
    claimants: Claimant[];
};
const claimantDetailsView: React.FunctionComponent<Props> = ({ claimants }: Props) => {
    const notifier = getContactDetailsObjectWarning(claimants);
    return (
        <ExpansionPanel name="Claimant Details" notifier={notifier}>
            <ClaimantContactDetailsView />
            <AddClaimantDetailsView />
        </ExpansionPanel>
    );
};

const getContactDetailsObjectWarning = (claimants: Claimant[]) => {
    if (claimants.length === 0) {
        return 'Warning';
    }
    return '';
};

const mapStateToProps = (state: State) => ({
    claimants: getDeathClaimContactDetails(state).claimants,
});

export const ClaimantDetailsView = connect(
    mapStateToProps,
    {}
)(withStyles(styles)(claimantDetailsView));
