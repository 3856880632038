import { AuthenticationParameters, AuthResponse, Configuration, UserAgentApplication } from 'msal';
import { env } from '../env';

const regex = /\/?$/gi;
const clientID = () => env()['AzureAD:UiClientId'] || '';
const instance = () => (env()['AzureAD:Instance'] || '').replace(regex, '');
const tenantId = () => env()['AzureAD:TenantId'] || '';

export const authority = () => `${instance()}/${tenantId()}`;
const defaultScopes = () => (env()['AzureAD:Scopes'] || '').split(';');

function config(): Configuration {
    return {
        auth: {
            clientId: clientID(),
            authority: authority(),
            redirectUri: `${window.location.origin}`,
        },
        cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: true,
        },
    };
}

const reqParams: AuthenticationParameters = {
    scopes: defaultScopes(),
    authority: authority(),
    prompt: 'select_account',
};

let _userAgentApplication: UserAgentApplication | null;
function userAgentApplication(): UserAgentApplication {
    if (!_userAgentApplication) {
        _userAgentApplication = new UserAgentApplication(config());
        _userAgentApplication.handleRedirectCallback((error, response) => {
            if (error) {
                return console.error(error);
            }
        });
    }
    return _userAgentApplication;
}

export function redirectToLogin() {
    userAgentApplication().loginRedirect(reqParams);
}

export async function tryGetTokenSilent() {
    try {
        const token = await userAgentApplication().acquireTokenSilent(reqParams);
        return token;
    } catch (err) {
        return;
    }
}

export function getAccount() {
    return userAgentApplication().getAccount();
}

export function logout() {
    return userAgentApplication().logout();
}

let popedUp = false;
export async function getTokenAfterLogin(): Promise<AuthResponse> {
    let token: AuthResponse;
    if (popedUp) {
        await sleep(5000);
        return getTokenAfterLogin();
    }
    // @ts-ignore
    token = await tryGetTokenSilent();
    if (token) {
        return token;
    }
    try {
        popedUp = true;
        token = await userAgentApplication().loginPopup(reqParams);
        return token;
    } catch (err) {
        console.error('Pop up error');
        console.error(err);
    } finally {
        popedUp = false;
    }
    redirectToLogin();
    await sleep(5000);
    // @ts-ignore
    return null;
}

export async function acquireTokenPopup() {
    return await userAgentApplication().acquireTokenPopup(reqParams);
}

export function sleep(milliseconds: number) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}
