import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import React from 'react'; //eslint-disable-line
import { connect } from 'react-redux';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import { ButtonWrapper } from '../../../../../../forms';
import { State } from '../../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import {
    VerifyAccount,
    verifyAccountAction,
} from '../../../../common/banking-details/actions/verify-account';
import { BankResponse } from '../../../../common/banking-details/banking-details';

interface ResponseProps extends StyledComponent {
    bankResponse?: BankResponse;
    beneficiaryId?: string;
    verifyAccount: VerifyAccount;
}

const bankResponse = ({ bankResponse, beneficiaryId, classes, verifyAccount }: ResponseProps) => {
    return (
        <React.Fragment>
            <Grid item xs={10} container justify="flex-start">
                <Typography variant="body1" className={classes.heading}>
                    AVS
                </Typography>
            </Grid>
            <ButtonWrapper>
                <Grid item xs={2} className={classes.rightAlign}>
                    <Tooltip title="Verify bank account">
                        <Button
                            id="VerifyAccount"
                            color="secondary"
                            style={{ textTransform: 'none', marginTop: '-4px' }}
                            onClick={() => verifyAccount(beneficiaryId)}
                        >
                            <Refresh />
                        </Button>
                    </Tooltip>
                </Grid>
            </ButtonWrapper>
            {bankResponse && (
                <React.Fragment>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Surname/Company Name" value={bankResponse.nameMatch} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Initials" value={bankResponse.initialsMatch} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Identity/Registration number"
                            value={bankResponse.idRegistrationMatch}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Account open for > 3 months"
                            value={bankResponse.accountOpen3Months}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Account found" value={bankResponse.accountFound} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Account type" value={bankResponse.accountTypeMatch} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Account open" value={bankResponse.accountOpen} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Accepts debits (collections)"
                            value={bankResponse.acceptsDebits}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Accepts credits (payments)"
                            value={bankResponse.acceptsCredits}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Cellphone number"
                            value={bankResponse.cellPhoneNumberMatch}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Email address"
                            value={bankResponse.emailAddressMatch}
                        />
                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const mapStateToRespProps = (state: State) => ({});
const mapDispatchToRespProps = { verifyAccount: verifyAccountAction };

export const BankResponseView = connect(
    mapStateToRespProps,
    mapDispatchToRespProps
)(withStyles(styles)(bankResponse));
