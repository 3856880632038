export const ClaimEvent = [
    { value: 'Fracture or collar bone', label: 'Fracture or collar bone' },
    { value: 'Fracture or forearm', label: 'Fracture or forearm' },
    {
        value: 'Fracture of skull (Except bones on the nose or face)',
        label: 'Fracture of skull (Except bones on the nose or face)',
    },
    { value: 'Fracture of kneecap', label: 'Fracture of kneecap' },
    { value: 'Fracture of shoulder blade', label: 'Fracture of shoulder blade' },
    {
        value: 'Fracture of facial bones - LE forte III',
        label: 'Fracture of facial bones - LE forte III',
    },
    { value: 'Fracture of thigh (femur)', label: 'Fracture of thigh (femur)' },
    {
        value:
            'Fracture of hind foot (Calcaneus, navicularies, cuboid or either of the three cuneiform bones)',
        label:
            'Fracture of hind foot (Calcaneus, navicularies, cuboid or either of the three cuneiform bones)',
    },
    {
        value: 'Fracture of facial bones - LE forte II',
        label: 'Fracture of facial bones - LE forte II',
    },
    {
        value: 'Fracture of hand requiring plaster or surgery',
        label: 'Fracture of hand requiring plaster or surgery',
    },
    {
        value: 'Hospitalization for longer than a week',
        label: 'Hospitalization for longer than a week',
    },
    {
        value: 'Fracture of leg between knee and foot',
        label: 'Fracture of leg between knee and foot',
    },
    { value: 'Fracture of upper arm', label: 'Fracture of upper arm' },
    { value: 'Fracture of pelvis', label: 'Fracture of pelvis' },
    {
        value:
            'Fracture of spine (Compression fracture of more than 50% of the vertebral body or burst)',
        label:
            'Fracture of spine (Compression fracture of more than 50% of the vertebral body or burst)',
    },
];

export const CurrentTreatment = [
    { value: 'Medical intervention', label: 'Medical intervention' },
    { value: 'Medication', label: 'Medication' },
    { value: 'Surgery', label: 'Surgery' },
    { value: 'Other', label: 'Other' },
];
