import { ReduxThunkAction } from '../../../../../../redux/redux-actions';
import { ExternalPolicy } from '../../reducer';
import {
    UpdateRecordCollectionAction,
    DeleteRecordCollectionAction,
} from '../../../../../../redux/collection-reducer';
import { reduceAndSubmitClaim } from '../../../../../../claim/actions/reduce-and-submit-claim';
import { UPDATE_EXTERNAL_POLICY, DELETE_EXTERNAL_POLICY } from '../../types';

type SubmitReturn = Promise<any>;
export type UpdateAndSubmitExternalPolicyRecord = (
    externalPolicyRecordIndex: number,
    externalPolicy: ExternalPolicy
) => SubmitReturn;
type UpdateAndSubmitExternalPolicyRecordThunk = (
    externalPolicyRecordIndex: number,
    externalPolicy: ExternalPolicy
) => ReduxThunkAction<SubmitReturn>;
export const updateAndSubmitExternalPolicyRecordThunk: UpdateAndSubmitExternalPolicyRecordThunk = (
    externalPolicyRecordIndex: number,
    externalPolicy: ExternalPolicy
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        updateExternalPolicyRecordActionCreator(externalPolicyRecordIndex, externalPolicy)
    );

export type UpdateExternalPolicyRecordAction = UpdateRecordCollectionAction<ExternalPolicy>;

export const updateExternalPolicyRecordActionCreator = (
    externalPolicyRecordIndex: number,
    externalPolicy: ExternalPolicy
): UpdateExternalPolicyRecordAction => {
    return {
        type: UPDATE_EXTERNAL_POLICY,
        record: externalPolicy,
        index: externalPolicyRecordIndex,
    };
};

export type DeleteAndSubmitExternalPolicyRecord = (recordIndex: number) => SubmitReturn;
type DeleteAndSubmitExternalPolicyRecordThunk = (
    recordIndex: number
) => ReduxThunkAction<SubmitReturn>;
export const deleteAndSubmitClaimantRecordThunk: DeleteAndSubmitExternalPolicyRecordThunk = (
    recordIndex: number
) => (dispatch, getState) =>
    reduceAndSubmitClaim(dispatch, getState, deleteExternalPolicyRecordActionCreator(recordIndex));

export type DeleteExternalPolicyRecordAction = DeleteRecordCollectionAction;

export const deleteExternalPolicyRecordActionCreator = (
    recordIndex: number
): DeleteExternalPolicyRecordAction => {
    return {
        type: DELETE_EXTERNAL_POLICY,
        index: recordIndex,
    };
};
