import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { getLifeAssuredDetails, LifeAssuredContract } from '../../life-assured-details/reducer';
import { State } from '../../redux/root-reducer';
import styles from '../../styles/styles';
import {
    ChangeFieldSet,
    changeRecordFromAddChangeSet,
    changeRecordFromChangeSet,
    changeRecordFromDeleteChangeSet,
    getCustomChangeRecord,
    getDisplayName,
} from '../claim-audit-trail-functions';
import { addAuditTrailDetailsView } from '../common-views/claim-audit-trail-field-details';
import { mapSectionView } from '../common-views/claim-audit-trail-wrapper';
import { getViewFromList } from './claim-audit-trail-lists-view';

import { getDisplayDate } from '../../shared-functions';
import { getDeathClaimContactDetailsFromClaim } from '../../assessment/synopsis/death/contact-details/contact-details-reducer';
import { getDependantsFromClaim } from '../../assessment/synopsis/ra-death/dependants/reducer';
import { getClaim } from '../../claim';
import { Code } from '../../codes/codes';
import {
    getDependantsList,
    getClaimantsList,
} from '../../assessment/synopsis/death/policy-details/beneficiary-details-view';

interface Props {
    changeSet: Record<string, any>;
    contracts: LifeAssuredContract[];
    claimants: Code<string>[];
}

const auditTrailLifeAssuredDetailsView: React.FunctionComponent<Props> = ({
    changeSet,
    contracts,
    claimants,
}: Props) => {
    const returnView: React.ReactNode[] = [];
    const dutyBreakdownKey = 'dutyBreakdown';
    const medicalHistoryKey = 'Medical History';
    const claimEventDetails = 'claimEventDetails';
    const doctorsDetails = 'Doctors Details';
    const doctorsVisit = 'Doctors Visit';
    const keys = [
        'medicalAidDetails',
        'personalDetails',
        'postalAddressDetails',
        'residentialAddressDetails',
        'previousPayments',
        'occupationDetails',
        'contactDetails',
        'postalAddress',
        'residentialAddress',
        'sameAsResidential',
        'bankingDetails',
        'previousMarriages',
        dutyBreakdownKey,
        claimEventDetails,
    ];

    if (
        Array.isArray(changeSet) &&
        changeSet[0] === null &&
        changeSet[1].hasOwnProperty('dutyBreakdown')
    ) {
        const record = changeRecordFromAddChangeSet(changeSet[1][dutyBreakdownKey]);
        addAuditTrailDetailsView(returnView, record, [getDisplayName(dutyBreakdownKey)]);
    } else if (changeSet.hasOwnProperty('contracts')) {
        const contractKey = 'contracts';
        getContractsView(changeSet[contractKey], returnView, contracts, claimants);
    } else {
        keys.forEach(key => {
            if (changeSet.hasOwnProperty(key)) {
                const details = changeSet[key];
                if (details) {
                    if (key === 'previousPayments') {
                        getViewFromList(details, returnView, getDisplayName(key));
                    } else if (key === 'previousMarriages') {
                        if (Array.isArray(details) && details[0] === null) {
                            getViewFromList(details[1], returnView, getDisplayName(key));
                        } else {
                            getViewFromList(details, returnView, getDisplayName(key));
                        }
                    } else {
                        getView(details, returnView, key);
                    }
                }
            }
        });
    }
    if (changeSet.hasOwnProperty('medicalHistory')) {
        const medicalHistory = changeSet['medicalHistory'];
        if (
            Array.isArray(medicalHistory) ||
            (changeSet[0] === null &&
                changeSet[0] === undefined &&
                changeSet[1].hasOwnProperty('doctorsDetails'))
        ) {
            if (
                medicalHistory[1]['firstConsultationDate'] != null &&
                medicalHistory[1].hasOwnProperty('firstConsultationDate')
            ) {
                const consultationDate = medicalHistory[1];
                delete consultationDate['doctorsDetails'];

                const firstConsultationDate = changeRecordFromAddChangeSet(consultationDate);
                addAuditTrailDetailsView(returnView, firstConsultationDate, [medicalHistoryKey]);
            } else if (
                Array.isArray(medicalHistory[1]['doctorsDetails']) &&
                medicalHistory[1]['doctorsDetails'].length > 0
            ) {
                const medicalDetails = changeRecordFromAddChangeSet(
                    medicalHistory[1]['doctorsDetails'][0]
                );
                addAuditTrailDetailsView(returnView, medicalDetails, [`${medicalHistoryKey} 1`]);
            }
        } else if (medicalHistory.hasOwnProperty('doctorsDetails')) {
            Object.keys(medicalHistory.doctorsDetails).forEach(key => {
                let indexKey = parseInt(key);
                if (!isNaN(Number(key)) && medicalHistory.doctorsDetails[key]) {
                    if (Array.isArray(medicalHistory.doctorsDetails[key])) {
                        if (medicalHistory.doctorsDetails[key][0]) {
                            getDoctors(
                                medicalHistory.doctorsDetails,
                                indexKey,
                                indexKey.toString(),
                                doctorsDetails,
                                doctorsVisit,
                                returnView
                            );
                        }
                    } else if (medicalHistory.doctorsDetails[key]['doctorsVisits']) {
                        Object.keys(medicalHistory.doctorsDetails[key]['doctorsVisits']).forEach(
                            index => {
                                const visitKey = parseInt(index);
                                indexKey = Number(key) + 1;
                                if (index !== '_t') {
                                    const deletvisitKey = parseInt(index.split('_')[1]);
                                    if (index.startsWith('_')) {
                                        const doctorsIndex = Number(key) + 1;
                                        getDoctorsVisitView(
                                            medicalHistory.doctorsDetails[key]['doctorsVisits'][
                                                index
                                            ],
                                            returnView,
                                            `{${doctorsDetails} ${doctorsIndex}}`,
                                            `{${doctorsVisit} ${deletvisitKey + 1}}`,
                                            key
                                        );
                                    } else {
                                        const doctorsIndex = Number(key) + 1;
                                        getDoctorsVisitView(
                                            medicalHistory.doctorsDetails[key]['doctorsVisits'][
                                                visitKey
                                            ],
                                            returnView,
                                            `{${doctorsDetails} ${doctorsIndex}}`,
                                            `{${doctorsVisit} ${visitKey + 1}}`,
                                            key
                                        );
                                    }
                                }
                            }
                        );
                    } else {
                        indexKey = Number(indexKey) + 1;
                        const medicalDetails = changeRecordFromChangeSet(
                            medicalHistory.doctorsDetails[key]
                        );
                        addAuditTrailDetailsView(returnView, medicalDetails, [
                            `${doctorsDetails} ${indexKey}`,
                        ]);
                    }
                } else {
                    if (Array.isArray(medicalHistory.doctorsDetails[key])) {
                        indexKey = parseInt(key.split('_')[1]);
                        if (medicalHistory.doctorsDetails[key]) {
                            indexKey = Number(indexKey) + 1;
                            const visit = medicalHistory.doctorsDetails[key][0]['doctorsVisits'];
                            visit.push(0);
                            delete medicalHistory.doctorsDetails[key][0]['doctorsVisits'];
                            getDoctorsView(
                                medicalHistory.doctorsDetails[key],
                                returnView,
                                `{${doctorsDetails} ${indexKey}}`,
                                indexKey.toString()
                            );
                            getDoctorsView(
                                visit,
                                returnView,
                                `{${doctorsVisit} ${indexKey}}`,
                                indexKey.toString()
                            );
                        }
                    }
                }
            });
        } else if (
            medicalHistory.hasOwnProperty('firstConsultationDate') &&
            Array.isArray(medicalHistory.firstConsultationDate)
        ) {
            const consultationDate = medicalHistory;
            delete consultationDate['doctorsDetails'];
            if (medicalHistory.firstConsultationDate[0] != null) {
                const firstConsultationDate = changeRecordFromChangeSet(consultationDate);
                addAuditTrailDetailsView(returnView, firstConsultationDate, [medicalHistoryKey]);
            } else {
                consultationDate.firstConsultationDate[1] = getDisplayDate(
                    new Date(consultationDate.firstConsultationDate[1])
                );
                const firstConsultationDate = changeRecordFromAddChangeSet(consultationDate);
                addAuditTrailDetailsView(returnView, firstConsultationDate, [medicalHistoryKey]);
            }
        }
    }

    return mapSectionView(returnView);
};
function getDoctors(
    doctorsDetails: Record<string, any>,
    index: number,
    key: string,
    doctorsHeading: string,
    doctorsVisitHeading: string,
    returnView: React.ReactNode[]
) {
    const doctorsKey = Number(index) + 1;
    let visitIndex;
    const doctorsVisits = doctorsDetails[key][0]['doctorsVisits'];
    delete doctorsDetails[key][0]['doctorsVisits'];
    Object.keys(doctorsVisits).forEach(indexKey => {
        visitIndex = Number(indexKey) + 1;
    });
    getDoctorsView(doctorsDetails[key], returnView, `{${doctorsHeading} ${doctorsKey}}`, key);
    getDoctorsVisitView(
        doctorsVisits,
        returnView,
        `{${doctorsHeading} ${doctorsKey}}`,
        `{${doctorsVisitHeading} ${visitIndex}}`,
        key
    );
}
function getView(
    changeObject: Record<string, any>,
    returnView: React.ReactNode[],
    subSection: string
) {
    let record: ChangeFieldSet[] = [];
    if (Array.isArray(changeObject) && changeObject[0] === null) {
        record = changeRecordFromAddChangeSet(changeObject[1]);
    } else if (Array.isArray(changeObject) && changeObject[1] === null) {
        record = changeRecordFromDeleteChangeSet(changeObject[0]);
    } else {
        record = changeRecordFromChangeSet(changeObject);
    }
    if (record.length > 0) {
        addAuditTrailDetailsView(returnView, record, [getDisplayName(subSection)]);
    }
}
function getDoctorsView(
    changeObject: Record<string, any>,
    returnView: React.ReactNode[],
    subSection: string,
    index: string
) {
    let record: ChangeFieldSet[] = [];
    if (Array.isArray(changeObject) && changeObject[1] === 0) {
        record = changeRecordFromDeleteChangeSet(changeObject[0]);
    } else if (Array.isArray(changeObject)) {
        record = changeRecordFromAddChangeSet(changeObject[0]);
    } else {
        record = changeRecordFromChangeSet(changeObject);
    }
    if (record.length > 0) {
        addAuditTrailDetailsView(returnView, record, [getDisplayName(subSection)]);
    }
}
function getDoctorsVisitView(
    changeObject: Record<string, any>,
    returnView: React.ReactNode[],
    subSection: string,
    subHeading: string,
    index: string
) {
    let record: ChangeFieldSet[] = [];
    if (Array.isArray(changeObject) && changeObject[1] === 0) {
        record = changeRecordFromDeleteChangeSet(changeObject[0]);
    } else if (Array.isArray(changeObject)) {
        record = changeRecordFromAddChangeSet(changeObject[0]);
    } else {
        record = changeRecordFromChangeSet(changeObject);
    }
    if (record.length > 0) {
        addAuditTrailDetailsView(returnView, record, [
            getDisplayName(subSection),
            getDisplayName(subHeading),
        ]);
    }
}
function getContractsView(
    changeObject: Record<string, any>,
    returnView: React.ReactNode[],
    contracts: LifeAssuredContract[],
    claimants: Code<string>[]
) {
    Object.keys(changeObject).forEach(policyKey => {
        if (!isNaN(Number(policyKey))) {
            const contractNumber = contracts[policyKey].contractNumber;
            const benefits = changeObject[policyKey];
            const benefitsKey = 'benefits';
            const BenefitKey = 'Benefits';
            if (benefits) {
                const benefitChanges = benefits[benefitsKey] || benefits[BenefitKey];
                if (benefitChanges) {
                    Object.keys(benefitChanges).forEach(benefitKey => {
                        let auditTrail: ChangeFieldSet[] = [];
                        let benefitName = '';
                        let reinsuranceNumber = 0;
                        if (!isNaN(Number(benefitKey))) {
                            benefitName = contracts[policyKey].benefits[benefitKey].name;
                            const reassurance = benefitChanges[benefitKey];
                            const reassuranceKey = 'reassuranceDetails';
                            if (reassurance[reassuranceKey]) {
                                Object.keys(reassurance[reassuranceKey]).forEach(reinsuranceKey => {
                                    if (!isNaN(Number(reinsuranceKey))) {
                                        reinsuranceNumber = Number(reinsuranceKey) + 1;
                                        auditTrail = changeRecordFromChangeSet(
                                            reassurance[reassuranceKey][reinsuranceKey]
                                        );
                                    }
                                });
                            }
                        }
                        if (auditTrail && auditTrail.length > 0) {
                            addAuditTrailDetailsView(returnView, auditTrail, [
                                `Policy - ${contractNumber}`,
                                `Benefit - ${benefitName}`,
                                `Reinsurance Details ${reinsuranceNumber}`,
                            ]);
                        }
                    });
                }
            }
        }
        if (!isNaN(Number(policyKey))) {
            const contractNumber = contracts[policyKey].contractNumber;
            const beneficiaries = changeObject[policyKey];
            const beneficiariesKey = 'beneficiaries';
            const BeneficiaryKey = 'Beneficiaries';
            if (beneficiaries) {
                const beneficiaryChanges =
                    beneficiaries[beneficiariesKey] || beneficiaries[BeneficiaryKey];
                if (beneficiaryChanges) {
                    Object.keys(beneficiaryChanges).forEach(beneficiaryKey => {
                        let auditTrail: ChangeFieldSet[] = [];
                        let beneficiaryName = '';
                        if (!isNaN(Number(beneficiaryKey))) {
                            beneficiaryName =
                                contracts[policyKey] &&
                                contracts[policyKey].beneficiaries &&
                                contracts[policyKey].beneficiaries[beneficiaryKey].personalDetails
                                    .firstName;

                            auditTrail = getCustomChangeRecord(
                                beneficiaryChanges[beneficiaryKey],
                                claimants
                            );
                        }

                        if (auditTrail && auditTrail.length > 0) {
                            addAuditTrailDetailsView(returnView, auditTrail, [
                                `Policy - ${contractNumber}`,
                                `Beneficiary - ${beneficiaryName}`,
                            ]);
                        }
                    });
                }
            }
        }
    });
}

const getBeneficiary = (state: State) => {
    let claimants: Code<string>[] = [];
    const claim = getClaim(state);

    if (claim.claimType === 'Death') {
        claimants = getClaimantsList(getDeathClaimContactDetailsFromClaim(claim).claimants);
    }
    if (claim.claimType === 'RADeath') {
        claimants = getDependantsList(getDependantsFromClaim(claim));
    }
    return claimants;
};

const mapStateToProps = (state: State) => ({
    contracts: getLifeAssuredDetails(state).contracts,
    claimants: getBeneficiary(state),
});

const mapDispatchToProps = {};
export const AuditTrailLifeAssuredDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(auditTrailLifeAssuredDetailsView));
