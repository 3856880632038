import React from 'react'; // eslint-disable-line
import { Grid, withStyles } from '@material-ui/core';
import { FullWidthSearchSelectField, FormHeading, ButtonWrapper } from '../../../forms';
import { requiredValidator } from '../../../forms/validations';
import styles, { StyledComponent } from '../../../styles/styles';
import { BeneficiaryOption } from '../../reducer';

type Props = StyledComponent & {
    beneficiaryOptions: BeneficiaryOption[];
};
const addBeneficiaryDetailView: React.FunctionComponent<Props> = ({
    beneficiaryOptions,
}: Props) => {
    return (
        <React.Fragment>
            <ButtonWrapper>
                <FormHeading text="Add Beneficiary" />
                <Grid container spacing={5} style={{ marginBottom: '120px' }}>
                    <Grid item xs={6} lg={6}>
                        <FullWidthSearchSelectField
                            name="paymentBeneficiary"
                            label="Beneficiary"
                            validate={requiredValidator}
                            options={beneficiaryOptions}
                        />
                    </Grid>
                </Grid>
            </ButtonWrapper>
        </React.Fragment>
    );
};

export const AddBeneficiaryDetailView = withStyles(styles)(addBeneficiaryDetailView);
