import { DateTime } from 'luxon';
import { Claim, claimSubmit } from '../../../../../claim';
import { isEmpty } from '../../../../../forms/validations';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { Nullable } from '../../../../../shared-types';
import { ForensicInput } from '../reducer';

type SubmitReturn = Promise<any>;
export type Submit = (details: ForensicInput) => SubmitReturn;
type SubmitAction = (details: ForensicInput) => ReduxThunkAction<SubmitReturn>;
export const submitAction: SubmitAction = (forensicInput: ForensicInput) => {
    return async (dispatch, getState) => {
        return claimSubmit(
            dispatch,
            getState,
            claim => ({
                ...claim,
                forensicInput: getForensicInputToBeSubmitted(forensicInput),
            }),
            validate
        );
    };
};

function getForensicInputToBeSubmitted(forensicInput: ForensicInput): Nullable<ForensicInput> {
    if (!forensicInput.dateReferred) {
        return null;
    }

    return forensicInput;
}

function validate(claim: Claim) {
    const errors = {} as any;
    if (!('forensicInput' in claim) || !claim.forensicInput) {
        return errors;
    }
    const details: ForensicInput = claim.forensicInput;
    const forensicInput = {} as any;
    const referredDate = details.dateReferred
        ? DateTime.fromJSDate(details.dateReferred).startOf('day')
        : undefined;
    const resolvedDate = details.dateResolved
        ? DateTime.fromJSDate(details.dateResolved).startOf('day')
        : undefined;
    const feedbackReceivedDate = details.dateFeedbackReceived
        ? DateTime.fromJSDate(details.dateFeedbackReceived).startOf('day')
        : undefined;

    if (!!feedbackReceivedDate && !!referredDate && feedbackReceivedDate < referredDate) {
        forensicInput.dateReferred = 'This must be before Date Feedback Received';
        errors.forensicInput = forensicInput;
    }

    if (!!resolvedDate && !!feedbackReceivedDate && resolvedDate < feedbackReceivedDate) {
        forensicInput.dateFeedbackReceived = 'This must be before or the same as Date Resolved';
        errors.forensicInput = forensicInput;
    }
    if (!!resolvedDate && !!referredDate && resolvedDate < referredDate) {
        forensicInput.dateResolved = 'This must be after Date Referred';
        errors.forensicInput = forensicInput;
    }
    if (!isEmpty(errors)) {
        return {
            'front-end-errors': errors,
        };
    }
    return errors;
}
