import { Claim, claimSubmit } from '../../../../../claim';
import { AddressDetails } from '../../../../../forms/sub-forms/address-details';
import { getPersonalDetails, validate } from '../../../../../life-assured-details/actions/submit';
import {
    getLifeAssuredDetailsFromClaim,
    PersonalDetails,
} from '../../../../../life-assured-details/reducer';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { getFormDetailsFromClaim } from '../../form-details/form-details-reducer';

export interface ExtendedPersonalDetails extends PersonalDetails {
    dateOfDeath?: Date;
}
interface UpdatePersonalDetailsModel {
    personalDetails: ExtendedPersonalDetails;
    addressDetails: AddressDetails;
}

type UpdatePersonalDetailsReturn = Promise<any>;
export type UpdatePersonalDetails = (
    updatePersonalDetailsValues: UpdatePersonalDetailsModel
) => UpdatePersonalDetailsReturn;
type UpdatePersonalDetailsAction = (
    updatePersonalDetailsValues: UpdatePersonalDetailsModel
) => ReduxThunkAction<UpdatePersonalDetailsReturn>;

export const updatePersonalDetailsAction: UpdatePersonalDetailsAction = (
    updatePersonalDetailsValues: UpdatePersonalDetailsModel
) => {
    return async (dispatch, getState) => {
        return claimSubmit(
            dispatch,
            getState,
            getUpdatedClaimPersonalDetails(updatePersonalDetailsValues),
            validate
        );
    };
};

function getUpdatedClaimPersonalDetails(
    updatePersonalDetailsValues: UpdatePersonalDetailsModel
): (claim: Claim) => Claim {
    return (claim: Claim) => {
        const currentLifeAssuredDetails = getLifeAssuredDetailsFromClaim(claim);
        const formDetails = getFormDetailsFromClaim(claim);
        return {
            ...claim,
            lifeAssuredDetails: {
                ...currentLifeAssuredDetails,
                personalDetails: {
                    ...getPersonalDetails(updatePersonalDetailsValues.personalDetails),
                },
                residentialAddressDetails: updatePersonalDetailsValues.addressDetails,
            },
            formDetails: {
                ...formDetails,
                deceasedDetails: {
                    ...formDetails.deceasedDetails,
                    detailsOfDeath: {
                        ...formDetails.deceasedDetails.detailsOfDeath,
                        dateOfDeath: updatePersonalDetailsValues.personalDetails.dateOfDeath,
                    },
                },
            },
        };
    };
}
