import React from 'react'; // eslint-disable-line
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { Typography, Divider, Grid, withStyles, Paper } from '@material-ui/core';
import { FullWidthTextField, FullWidthDatePicker } from '../../../../../../forms';
import { BeneficiariesView } from './add-external-beneficiary';
import { requiredValidator } from '../../../../../../forms/validations';
import { CurrencyMask } from '../../../../../../forms/input-masks';
import { convertToNumber } from '../../../../../../shared-functions';
import { ButtonWrapper } from '../../../../../../forms';

const addExternalPolicy: React.FunctionComponent<StyledComponent> = ({
    classes,
}: StyledComponent) => {
    return (
        <React.Fragment>
            <ButtonWrapper>
                <Typography variant="h5">Add external policy details</Typography>
            </ButtonWrapper>

            <Divider></Divider>
            <Paper className={classes.paper}>
                <Grid container spacing={5} justify="flex-start">
                    <Grid item xs={6} lg={6}>
                        <FullWidthTextField
                            id="insurerName"
                            name="insurerName"
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <FullWidthTextField id="productType" name="productType" />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <FullWidthTextField id="policyNumber" name="policyNumber" />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <FullWidthTextField
                            id="sumAssured"
                            name="sumAssured"
                            mask={CurrencyMask}
                            parse={convertToNumber}
                        />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <FullWidthDatePicker id="issueDate" name="issueDate" disableFuture />
                    </Grid>
                </Grid>
                <BeneficiariesView classes={classes} />
            </Paper>
        </React.Fragment>
    );
};
export const AddExternalPolicy = withStyles(styles)(addExternalPolicy);
