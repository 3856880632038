import { Button, Grid, Paper, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { Passports } from '../../../../../codes/id-document-types';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { ButtonWrapper } from '../../../../../forms';
import {
    getLifeAssuredDetails,
    PersonalDetails,
} from '../../../../../life-assured-details/reducer';
import { State } from '../../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { VerifyStatus, verifyStatusAction } from '../actions/verify';
import { VOPD } from '../reducer';

interface VOPDProps {
    readonly vopd: VOPD;
    readonly personalDetails: PersonalDetails;
}

interface ButtonProps {
    isReadOnly: boolean;
    verify: VerifyStatus;
}

type Props = StyledComponent & VOPDProps & ButtonProps;

const vopdView: React.FunctionComponent<Props> = ({
    classes,
    isReadOnly,
    personalDetails,
    verify,
    vopd,
}: Props) => {
    return (
        <React.Fragment>
            <Grid container justify="flex-start" style={{ marginTop: '4px' }}>
                <Grid item xs={9}>
                    <Typography style={{ marginTop: '8px' }} variant="subtitle2">
                        Claim Details - VOPD
                    </Typography>
                </Grid>
                <VerifyButton isReadOnly={isReadOnly} verify={verify} />
            </Grid>
            <Paper className={classes.paper}>
                <Grid container justify="flex-start">
                    <VerificationDetails personalDetails={personalDetails} vopd={vopd || {}} />
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

const VerificationDetails = ({ personalDetails, vopd }: VOPDProps) => {
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography variant="h5">ID | {getIdNumber(personalDetails)}</Typography>
            </Grid>
            <DisplayValue label="Name" value={vopd.firstName} />
            <DisplayValue label="Surname" value={vopd.lastName} />
            <DisplayValue label="Date of birth" value={getDisplayDate(vopd.dateOfBirth)} />
            <DisplayValue label="Gender" value={vopd.gender} />
            <DisplayValue label="Marital status" value={vopd.maritalStatus} />
            <DisplayValue label="Date of event" value={getDisplayDate(vopd.dateOfDeath)} />
            <DisplayValue label="Place of death" value={vopd.placeOfDeath} />
            <DisplayValue label="Cause of death" value={vopd.causeOfDeath} />
            <DisplayValue label="Deceased status" value={vopd.deceasedStatus} />
            <DisplayValue
                label="Death under investigation marker"
                value={getDisplayText(vopd.deathUnderInvestigation)}
            />
        </React.Fragment>
    );
};

const VerifyButton = ({ isReadOnly, verify }: ButtonProps) => {
    return (
        <ButtonWrapper>
            <Grid item xs={3} container justify="flex-end">
                <Tooltip title="Verify deceased status">
                    <Button
                        id="VerifyDeceased"
                        color="secondary"
                        style={{ textTransform: 'none' }}
                        onClick={verify}
                    >
                        <Refresh />
                        &nbsp;Verify deceased status
                    </Button>
                </Tooltip>
            </Grid>
        </ButtonWrapper>
    );
};

function getDisplayText(indicator?: boolean) {
    if (indicator === undefined || indicator === null) {
        return undefined;
    }
    return indicator ? 'Yes' : 'No';
}

interface DisplayProps {
    label: string;
    value?: string;
}

const DisplayValue = ({ label, value }: DisplayProps) => {
    if (!value) {
        return null;
    }
    return (
        <Grid item xs={6} lg={3}>
            <LabeledValue label={label} value={value} />
        </Grid>
    );
};

function getIdNumber(personalDetails: PersonalDetails) {
    if (!personalDetails) {
        return undefined;
    }
    if (Passports.includes(personalDetails.idType || '')) {
        return personalDetails.passportNumber;
    }

    return personalDetails.idNumber;
}

const mapStateToProps = (state: State) => ({
    personalDetails: getLifeAssuredDetails(state).personalDetails,
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = { verify: verifyStatusAction };

export const VOPDView = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(vopdView));

export const AlternateVOPDView = connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withStyles(styles)(({ classes, isReadOnly, personalDetails, verify, vopd }: Props) => {
        return (
            <Grid
                container
                justify="flex-start"
                style={{ marginTop: '16px', marginBottom: '16px' }}
            >
                <Grid item xs={9}>
                    <Typography variant="body1" className={classes.heading}>
                        VOPD
                    </Typography>
                </Grid>
                <VerifyButton isReadOnly={isReadOnly} verify={verify} />
                <VerificationDetails personalDetails={personalDetails} vopd={vopd} />
            </Grid>
        );
    })
);
