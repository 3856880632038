import React from 'react'; // eslint-disable-line
import { Grid, Paper, withStyles, Button, Tooltip, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { connect } from 'react-redux';
import { LabeledValue } from '../../../../controls/labeled-value';
import { OpenModal, openModalAction } from '../../../../form-modal/actions';
import { FormModalView } from '../../../../form-modal/views/form-modal';
import styles, { StyledComponent } from '../../../../styles/styles';
import { State } from '../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../shared-functions';
import {
    getNoticeOfDeath,
    NoticeOfDeathDeceased,
} from '../../common/notice-of-death/notice-of-death-reducer';
import { NoticeOfDeathForm } from './notice-of-death-edit-form';
import { EditDeceasedDetailsView } from '../../common/notice-of-death/views';
import { DisplayAddress } from '../../../../forms/sub-forms/address-details';
import { ButtonWrapper } from '../../../../forms';

type Props = StyledComponent & {
    openModal: OpenModal;
    deceasedDetails: NoticeOfDeathDeceased;
    isReadOnly: boolean;
};
const deceasedDetailsView: React.FunctionComponent<Props> = ({
    classes,
    deceasedDetails: deceased,
    openModal,
    isReadOnly,
}: Props) => {
    const edit = () => {
        openModal({
            modalContent: (
                <FormModalView
                    formView={() => (
                        <NoticeOfDeathForm
                            title="Deceased Details"
                            view={<EditDeceasedDetailsView isDeath />}
                        />
                    )}
                />
            ),
        });
    };
    const personalDetails = deceased.personalDetails || {};
    const addressDetails = deceased.addressDetails || {};
    return (
        <React.Fragment>
            <Grid container justify="flex-start" style={{ marginTop: '4px' }}>
                <Grid item xs={11}>
                    <Typography style={{ marginTop: '8px' }} variant="subtitle2">
                        Deceased details
                    </Typography>
                </Grid>
                <ButtonWrapper>
                    <Grid item xs={1} container justify="flex-end">
                        <Tooltip title="Edit Deceased Details">
                            <Button id="EditDeceasedDetails" color="secondary" onClick={edit}>
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            </Grid>
            <Paper className={classes.paper}>
                <Grid container justify="flex-start">
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Medical Report Received"
                            value={deceased.medicalReport}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Body Identifiable" value={deceased.bodyIdentifiable} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="First Name" value={personalDetails.firstName} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Second Name" value={personalDetails.secondName} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Surname" value={personalDetails.lastName} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="ID Number" value={personalDetails.idNumber} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Date of Death"
                            value={getDisplayDate(deceased.dateOfDeath)}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Place of Death" value={deceased.placeOfDeath} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Place of Death Registration"
                            value={deceased.placeOfDeathReg}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Cause of Death" value={deceased.causeOfDeath} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Natural Death" value={deceased.naturalDeath} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Police Report" value={deceased.policeReport} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Hospital" value={deceased.hospital} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <DisplayAddress label="Residential Address" address={addressDetails} />
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

const mapStateToProps = (state: State) => ({
    deceasedDetails: getNoticeOfDeath(state).deceased || {},
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = { openModal: openModalAction };

export const DeceasedDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(deceasedDetailsView));
