export function addRecord<T>(array: T[], record: T): T[] {
    if (!record) {
        return array;
    }
    const newRecord = record as any;
    const existingIndex = (array as any[]).findIndex(
        arr =>
            (newRecord.id && arr.id === newRecord.id) ||
            (newRecord.uniqueId && arr.uniqueId === newRecord.uniqueId)
    );
    if (existingIndex !== -1) {
        return [...array.slice(0, existingIndex), record, ...array.slice(existingIndex + 1)];
    }
    return [...array.slice(), record];
}
