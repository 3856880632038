import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import {
    getAssessmentDetails,
    PolicyAssessmentDetails,
} from '../../assessment/decisions/assessment-details-reducer';
import { nameToLabel } from '../../forms';
import { State } from '../../redux/root-reducer';
import styles from '../../styles/styles';
import {
    ChangeFieldSet,
    changeRecordFromAddChangeSet,
    changeRecordFromChangeSet,
    getDisplayValue,
} from '../claim-audit-trail-functions';
import {
    addAuditTrailDetailsView,
    AuditTrailDetails,
} from '../common-views/claim-audit-trail-field-details';
import { mapSectionView } from '../common-views/claim-audit-trail-wrapper';

interface Props {
    policyAssessmentDecisions: PolicyAssessmentDetails[];
    changeSet: Record<string, any>;
}

const auditTrailDecisionsView: React.FunctionComponent<Props> = ({
    policyAssessmentDecisions,
    changeSet,
}: Props) => {
    const returnView: React.ReactNode[] = [];
    Object.keys(changeSet).forEach(key => {
        const changeRecord = changeSet[key];
        const lowerCaseKey = key.toLowerCase();

        if (lowerCaseKey === 'decisiondetails') {
            addAuditTrailDetailsView(returnView, changeRecordFromChangeSet(changeRecord));
        }

        if (lowerCaseKey.toLowerCase() === 'effectivedateofclaim') {
            addAuditTrailDetailsView(returnView, [
                {
                    fieldName: nameToLabel({ name: key }),
                    currentValue: getDisplayValue('effectiveDateOfClaim', changeSet[key][1]),
                    previousValue: getDisplayValue('effectiveDateOfClaim', changeSet[key][0]),
                },
            ]);
        }

        if (
            lowerCaseKey === 'policybenefitfinaldecisions' ||
            lowerCaseKey === 'policyassessmentdecisions'
        ) {
            policyBenefitFinalDecisions(returnView, changeRecord, policyAssessmentDecisions);
        }
    });

    return mapSectionView(returnView);
};

function policyBenefitFinalDecisions(
    returnView: React.ReactNode[],
    changeRecord: Record<string, any>,
    policyAssessmentDecisions: PolicyAssessmentDetails[]
) {
    let decisionDescription = '';
    let policyNumberIndex = -1;
    Object.keys(changeRecord).forEach(changeKey => {
        Object.keys(changeRecord[changeKey]).forEach(decisionKey => {
            if (
                decisionKey.toLowerCase() === 'finaldecisions' ||
                decisionKey.toLowerCase() === 'assessmentdecisions'
            ) {
                Object.keys(changeRecord[changeKey][decisionKey]).forEach(key => {
                    if (!isNaN(Number(key))) {
                        decisionDescription = decisionKey;
                        policyNumberIndex = Number(changeKey);
                        if (Array.isArray(changeRecord[changeKey][decisionKey][key])) {
                            const decisionRecordArray = changeRecord[changeKey][decisionKey][key];
                            if (Array.isArray(decisionRecordArray)) {
                                decisionRecordArray.forEach(decision => {
                                    setupView(
                                        decisionDescription,
                                        decision,
                                        policyNumberIndex,
                                        policyAssessmentDecisions,
                                        returnView
                                    );
                                });
                            }
                        } else {
                            setupView(
                                decisionDescription,
                                changeRecord[changeKey][decisionKey][key],
                                policyNumberIndex,
                                policyAssessmentDecisions,
                                returnView
                            );
                        }
                    }
                });
            }
        });
    });
}

function setupView(
    decisionDescription: string,
    decisionRecord: any,
    policyNumberIndex: number,
    policyAssessmentDecisions: PolicyAssessmentDetails[],
    returnView: React.ReactNode[]
) {
    let policyNumber = '';
    let records: ChangeFieldSet[] = [];
    if (decisionDescription === 'finalDecisions') {
        records.push(
            {
                fieldName: nameToLabel({ name: 'decisionDate' }),
                currentValue: getDisplayValue('decisionDate', decisionRecord.decisionDate),
            },
            {
                fieldName: nameToLabel({
                    name: 'retrospectiveUnderwritingDecision',
                }),
                currentValue: getDisplayValue(
                    'retrospectiveUnderwritingDecision',
                    decisionRecord.retrospectiveUnderwritingDecision
                ),
            }
        );
    } else if (decisionDescription === 'assessmentDecisions') {
        const policyNumberKey = 'policyNumber';
        if (decisionRecord[policyNumberKey]) {
            records = changeRecordFromAddChangeSet(decisionRecord);
        } else {
            if (decisionDescription === 'assessmentDecisions' && policyNumberIndex >= 0) {
                policyNumber = policyAssessmentDecisions[policyNumberIndex].policyNumber;
            }
            records = changeRecordFromChangeSet(decisionRecord);
        }
    }

    const headings: string[] = [
        `Policy Number - ${
            decisionRecord.policyNumber ? decisionRecord.policyNumber : policyNumber
        }`,
    ];

    if (decisionRecord.benefitName) {
        headings.push(`Benefit - ${decisionRecord.benefitName}`);
    }

    returnView.push(<AuditTrailDetails subSection={headings} auditTrailRecords={records} />);
}

const mapStateToProps = (state: State) => ({
    policyAssessmentDecisions: getAssessmentDetails(state),
});

const mapDispatchToProps = {};

export const AuditTrailDecisionsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(auditTrailDecisionsView));
