import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { ExpansionPanel } from '../../../../../forms/form-components/expansion-panel/expansion-panel';
import { State } from '../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { Dependant, getDependants } from '../reducer';
import { AddDependantView } from './dependant-view-add';
import { DependantsView } from './dependant-view';
import {
    DecisionModel,
    getBlankAssessmentRecord,
} from '../../../../decisions/ra-death/ra-death-assessment-details-reducer';
import { getRADeathClaim } from '../../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { totalAllocation } from '../../../../../shared-functions';

type Props = StyledComponent & {
    dependants: Dependant[];
    decision: DecisionModel;
};
const possibleDependantsView: React.FunctionComponent<Props> = ({
    dependants,
    decision,
}: Props) => {
    const notifier = getContactDetailsObjectWarning(dependants, decision);
    return (
        <ExpansionPanel name="Possible dependants" notifier={notifier}>
            <DependantsView />
            <AddDependantView />
        </ExpansionPanel>
    );
};

const getContactDetailsObjectWarning = (dependants: Dependant[], decision: DecisionModel) => {
    if (dependants.length === 0 || totalAllocation(decision) !== 100) {
        return 'Warning';
    }
    return undefined;
};

const mapStateToProps = (state: State) => ({
    dependants: getDependants(state),
    decision: getBlankAssessmentRecord(
        getRADeathClaim(state).assessmentDetails.decisions,
        getRADeathClaim(state).dependants,
        state.auth?.name
    ),
});

export const PossibleDependantsView = connect(
    mapStateToProps,
    {}
)(withStyles(styles)(possibleDependantsView));
