import { AnyAction, Reducer } from 'redux';
import { Nullable } from '../../shared-types';
import { SetActiveWorkflowAction } from '../actions';
import { AddScreenToWorkflowAction } from '../actions/add-screen-to-workflow';
import { DeleteScreenFromWorkflowAction } from '../actions/delete-screen-from-workflow';
import { SetActiveScreenAction } from '../actions/set-active-screen';
import {
    ADD_SCREEN_TO_WORKFLOW,
    DELETE_SCREEN_FROM_WORKFLOW,
    SET_ACTIVE_SCREEN,
    SET_ACTIVE_WORKFLOW,
} from '../types';
import { Workflow } from './workflow';
import { WorkflowScreen } from './workflow-screen';
import { WorkflowScreenContainer } from './workflow-screen-container';
import { WorkflowStep } from './workflow-step';

export interface WorkflowState {
    readonly activeWorkflow: Workflow;
    readonly activeScreen: Nullable<WorkflowScreen>;
}

export const initialWorkflowState = (initialWorkflow: Workflow): WorkflowState => ({
    activeWorkflow: initialWorkflow,
    activeScreen: null,
});

export type Action =
    | SetActiveWorkflowAction
    | SetActiveScreenAction
    | AddScreenToWorkflowAction
    | DeleteScreenFromWorkflowAction
    | AnyAction;
export const workflowReducer = (initialWorkflow: Workflow): Reducer<WorkflowState, Action> => (
    state: WorkflowState = initialWorkflowState(initialWorkflow),
    action: Action
) => {
    switch (action.type) {
        case SET_ACTIVE_SCREEN: {
            const activeScreen = (action as SetActiveScreenAction).payload;
            return {
                ...state,
                activeScreen,
            };
        }
        case SET_ACTIVE_WORKFLOW: {
            return setActiveWorkflow(action, state);
        }
        case DELETE_SCREEN_FROM_WORKFLOW: {
            if (!state.activeScreen) {
                return state;
            }

            const activeScreenPaths = state.activeScreen.path();
            const screenContainerd = state.activeScreen.parent as WorkflowScreenContainer;
            if (!screenContainerd) {
                return state;
            }
            if (!state.activeWorkflow) {
                return state;
            }

            const screenToRemove = (action as AddScreenToWorkflowAction).payload;
            const screenPath = [screenContainerd.path(), screenToRemove.pathPart()].join('/');
            const screenExists = screenContainerd
                .children()
                .find(child => child.path() === screenPath);

            if (!screenExists) {
                return state;
            }
            const screenIndex = screenContainerd.children().indexOf(screenExists);
            console.log(screenIndex);
            screenContainerd.children().pop();
            const newWorkflows = new Workflow(
                state.activeWorkflow.label,
                state.activeWorkflow.children() as WorkflowStep[]
            );
            const newActiveScreens = newWorkflows
                .descendantScreens()
                .find(screen => screen.path() === activeScreenPaths);

            if (!newActiveScreens) {
                throw new Error(`New screen ${activeScreenPaths} not found`);
            }
            return {
                ...state,
                activeWorkflow: newWorkflows,
                activeScreen: newActiveScreens,
            };
        }
        case ADD_SCREEN_TO_WORKFLOW: {
            if (!state.activeScreen) {
                console.warn("No active screen, can't add new screen!");
                return state;
            }
            const activeScreenPath = state.activeScreen.path();
            const screenContainer = state.activeScreen.parent as WorkflowScreenContainer;
            if (!screenContainer) {
                console.warn("No screen parent, can't add new screen!");
                return state;
            }
            if (!state.activeWorkflow) {
                console.warn("No active workflow, can't add new screen!");
                return state;
            }

            const newScreen = (action as AddScreenToWorkflowAction).payload;
            const newScreenPath = [screenContainer.path(), newScreen.pathPart()].join('/');
            const alreadyExists = screenContainer
                .children()
                .find(child => child.path() === newScreenPath);

            if (alreadyExists) {
                return state;
            }
            screenContainer.children().push(newScreen);
            const newWorkflow = new Workflow(
                state.activeWorkflow.label,
                state.activeWorkflow.children() as WorkflowStep[]
            );
            const newActiveScreen = newWorkflow
                .descendantScreens()
                .find(screen => screen.path() === activeScreenPath);
            if (!newActiveScreen) {
                throw new Error(`New screen ${newScreenPath} not found`);
            }
            return {
                activeWorkflow: newWorkflow,
                activeScreen: newActiveScreen,
            };
        }
        default:
            return state;
    }
};
function setActiveWorkflow(action: Action, state: WorkflowState) {
    const newActiveWorkflow = (action as SetActiveWorkflowAction).payload;
    let newActiveScreen: WorkflowScreen | undefined;
    if (!!state.activeScreen) {
        newActiveScreen = newActiveWorkflow
            .descendantScreens()
            .find(screen => screen.path() === (state.activeScreen as WorkflowScreen).path());
    }
    return {
        ...state,
        activeScreen: newActiveScreen,
        activeWorkflow: newActiveWorkflow,
    };
}
