import { ReduxThunkAction } from '../../redux/redux-actions';
import { FormSubmissionState } from '../reducers/fom-submission-reducer';
import { WorkflowState } from '../reducers/workflow-reducer';
import { WorkflowScreen } from '../reducers/workflow-screen';
import { navigateToScreenThunk } from './navigate-to-screen';

export type NavigationDirection = 'Forward' | 'Backward';

export type GetWorkflowState = (state: any) => WorkflowState;
export type GetFormSubmissionState = (state: any) => FormSubmissionState;

type NavigateToNextScreenReturn = Promise<any>;
export type NavigateToNextScreen = (
    workflowState: GetWorkflowState,
    formSubmissionState: GetFormSubmissionState,
    navigationDirection?: NavigationDirection
) => NavigateToNextScreenReturn;
type NavigateToNextScreenThunk = (
    getWorkflowState: GetWorkflowState,
    getFormSubmissionState: GetFormSubmissionState,
    navigationDirection?: NavigationDirection
) => ReduxThunkAction<NavigateToNextScreenReturn>;
export const navigateToNextScreenThunk: NavigateToNextScreenThunk = (
    getWorkflowState: GetWorkflowState,
    getFormSubmissionState: GetFormSubmissionState,
    navigationDirection?: NavigationDirection
) => {
    return async (dispatch, getState) => {
        const state = getState();
        const workflowState = getWorkflowState(state);
        const formSubmissionState = getFormSubmissionState(state);

        const navigationIncrement =
            !navigationDirection || navigationDirection === 'Forward' ? 1 : -1;

        if (!workflowState.activeWorkflow) {
            return;
        }
        const activeScreen = workflowState.activeScreen;
        const currentScreenIndex = workflowState.activeWorkflow
            .descendantScreens()
            .findIndex(compareScreens);

        const newScreen = workflowState.activeWorkflow.descendantScreens()[
            currentScreenIndex + navigationIncrement
        ];

        if (newScreen?.label === 'Assessor List') {
            return;
        }

        if (!!newScreen) {
            dispatch(navigateToScreenThunk(newScreen, formSubmissionState, true));
            return;
        } else {
            console.error('No new screen to move too');
        }

        function compareScreens(child: WorkflowScreen): boolean {
            if (!activeScreen) {
                return false;
            }
            return child.path() === activeScreen.path();
        }
    };
};
