import React from 'react'; // eslint-disable-line

import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import { Titles } from '../../../../../codes/titles';
import { FullWidthSearchSelectField, FullWidthTextField } from '../../../../../forms';
import { FirstNameMask, InitialsMask, LastNameMask } from '../../../../../forms/input-masks';
import { CommunicationDetailsSubForm } from '../../../../../forms/sub-forms/communication-details';
import { requiredValidator } from '../../../../../forms/validations';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { ContactType, ContactTypes } from '../contact-details-reducer';

type Props = StyledComponent & {
    contactType: string;
    isEdit?: boolean;
};

const OtherContactDetailsEdit: React.FunctionComponent<Props> = ({
    classes,
    contactType,
    isEdit,
}: Props) => {
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography variant="h6">{`${contactType} Contact Details`}</Typography>
            </Grid>
            <Paper className={classes.paper}>
                <Grid container spacing={2} justify="flex-start">
                    <Grid item xs={6}>
                        <FullWidthSearchSelectField
                            name="contactType"
                            options={ContactTypes}
                            initialValue={contactType}
                            disabled={isEdit}
                        />
                    </Grid>
                    {contactType === ContactType.Other ? (
                        <Grid item xs={6}>
                            <FullWidthTextField name="description" validate={requiredValidator} />
                        </Grid>
                    ) : undefined}
                    <Grid item xs={6}>
                        <FullWidthSearchSelectField name="title" label="Title" options={Titles} />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            name="initials"
                            label="Initials"
                            mask={InitialsMask}
                            inputProps={{
                                style: { textTransform: 'uppercase' },
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            name="firstName"
                            label="Name"
                            mask={FirstNameMask}
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            name="lastName"
                            label="Surname"
                            mask={LastNameMask}
                            validate={requiredValidator}
                        />
                    </Grid>
                    <CommunicationDetailsSubForm
                        displayOptions={{
                            telephoneNumber: true,
                            cellphoneNumber: true,
                            emailAddress: true,
                        }}
                        required="Any"
                        requiredOptions={{
                            cellphoneNumber: true,
                            emailAddress: true,
                        }}
                    />
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

export const OtherContactDetailsEditView = withStyles(styles)(OtherContactDetailsEdit);
