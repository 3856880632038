import { Claim, getClaim, getPropertyFromClaim } from '../../../../claim';
import { collectionReducer } from '../../../../redux/collection-reducer';
import { State } from '../../../../redux/root-reducer';
import { ADD_CLAIM_COMMENTS } from './types';

export interface Comments {
    assessor: string;
    comment?: string;
    date: Date;
    id: string;
}

export const defaultComments: Comments[] = [];

export const commentsReducer = collectionReducer<Comments>({
    addAction: ADD_CLAIM_COMMENTS,
});

export function getComments(state: State) {
    return getCommentsFromClaim(getClaim(state));
}

export function getCommentsFromClaim(claim: Claim): Comments[] {
    return getPropertyFromClaim(claim, 'comments', defaultComments);
}
