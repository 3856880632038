import { claimSubmit } from '../../../../../claim';
import { Claim } from '../../../../../claim/reducers';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import {
    Beneficiary,
    getLifeAssuredDetailsFromClaim,
} from '../../../../../life-assured-details/reducer';

type UpdateBeneficiaryReturn = Promise<any>;
export type UpdateBeneficiary = (
    beneficiary: Beneficiary,
    contractIndex: number,
    beneficiaryIndex: number
) => UpdateBeneficiaryReturn;
type UpdateBeneficiaryAction = (
    beneficiary: Beneficiary,
    contractIndex: number,
    beneficiaryIndex: number
) => ReduxThunkAction<UpdateBeneficiaryReturn>;

export const updateBeneficiaryAction: UpdateBeneficiaryAction = (
    beneficiary: Beneficiary,
    contractIndex: number,
    beneficiaryIndex: number
) => {
    return async (dispatch, getState) => {
        return claimSubmit(
            dispatch,
            getState,
            getUpdatedBeneficiary(beneficiary, contractIndex, beneficiaryIndex)
        );
    };
};

function getUpdatedBeneficiary(
    beneficiary: Beneficiary,
    contractIndex: number,
    beneficiaryIndex: number
): (claim: Claim) => Claim {
    return (claim: Claim) => {
        const currentLifeAssuredDetails = getLifeAssuredDetailsFromClaim(claim);

        currentLifeAssuredDetails.contracts[contractIndex].beneficiaries[
            beneficiaryIndex
        ] = beneficiary;

        return {
            ...claim,
            lifeAssuredDetails: {
                ...currentLifeAssuredDetails,
            },
        };
    };
}
