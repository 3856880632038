import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect, MapStateToProps } from 'react-redux';
import { getClaim } from '../../claim';
import { ClaimType } from '../../claim/claim-types';
import { State } from '../../redux/root-reducer';
import { Nullable } from '../../shared-types';
import styles, { StyledComponent } from '../../styles/styles';
import { PaymentDetails } from './payments/payment-details';

interface StateProps {
    claimType: Nullable<ClaimType>;
}

type Props = StyledComponent & StateProps;
const paymentsView: React.FunctionComponent<Props> = ({ claimType }: Props) => {
    switch (claimType) {
        case ClaimType.Uninitiated:
        case ClaimType.Retrenchment:
            return null;
        default:
            return <PaymentDetails />;
    }
};

const mapStateToProps: MapStateToProps<StateProps, Record<string, any>, State> = (
    state: State
) => ({
    claimType: getClaim(state).claimType,
});

export const PaymentsView = connect(mapStateToProps, {})(withStyles(styles)(paymentsView));
