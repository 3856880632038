import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { GenericForm, FormErrors } from '../../../../../../forms';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { connect } from 'react-redux';
import { RADeathClaim } from '../../../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { getClaim } from '../../../../../../claim';
import { State } from '../../../../../../redux/root-reducer';
import { BankingDetails } from '../../../../common/banking-details/banking-details';
import { BankingDetailsView } from '../../../../common/banking-details/views/edit-banking-details';
import {
    UpdateBankingDetails,
    updateBankingDetailsAction,
} from '../../actions/update-banking-details';

interface Props extends StyledComponent {
    bankingDetails?: BankingDetails;
    updateBankingDetails: UpdateBankingDetails;
}

const AddressDetailsForm = GenericForm<BankingDetails>();

const bankingDetailsEdit: React.FunctionComponent<Props> = ({
    classes,
    bankingDetails,
    updateBankingDetails,
    ...rest
}: Props) => {
    return (
        <AddressDetailsForm
            onSubmit={updateBankingDetails}
            initialValues={{
                ...bankingDetails,
            }}
            {...rest}
        >
            <BankingDetailsView />
            <FormErrors />
        </AddressDetailsForm>
    );
};

const mapStateToProps = (state: State) => {
    const claim = getClaim(state);
    const raClaim = claim as RADeathClaim;
    return {
        ...raClaim.lifeAssuredDetails,
    };
};

const mapDispatchToProps = {
    updateBankingDetails: updateBankingDetailsAction,
};

export const BankingDetailsEditView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(bankingDetailsEdit));
