import React from 'react'; // eslint-disable-line

import { Grid, Modal, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import { State } from '../../redux/root-reducer';
import { ModalState } from '../reducer';
import styles, { StyledComponent } from '../styles';

interface ComponentState {
    readonly modalId?: string;
}
type Props = StyledComponent & {
    readonly modalState: ModalState;
};

class ModalViewClass extends React.Component<Props, ComponentState> {
    private static openModalIds: string[] = [];

    public constructor(props: Props) {
        super(props);

        this.state = {};
    }

    public shouldComponentUpdate(nextProps: Props) {
        if (!this.props.modalState.activeModal || !nextProps.modalState.activeModal) {
            return true;
        }

        if (!this.state.modalId) {
            return true;
        }

        if (ModalViewClass.openModalIds.includes(this.state.modalId)) {
            return true;
        }

        if (nextProps.modalState.activeModal.modalId !== this.state.modalId) {
            return false;
        }

        return (
            this.props.modalState.activeModal.modalData !==
                nextProps.modalState.activeModal.modalData ||
            this.props.modalState.activeModal.modalContent !==
                nextProps.modalState.activeModal.modalContent
        );
    }
    public componentDidUpdate(prevProps: Props) {
        if (!this.props.modalState.activeModal) {
            return;
        }

        if (!!this.state.modalId) {
            return;
        }

        const modalId = this.props.modalState.activeModal.modalId;

        if (ModalViewClass.openModalIds.includes(modalId)) {
            return;
        }

        this.setState({ modalId });
        ModalViewClass.openModalIds.push(modalId);
    }

    public render() {
        const {
            classes,
            modalState: { activeModal },
        } = this.props;

        if (
            !activeModal ||
            (!!this.state.modalId && !ModalViewClass.openModalIds.includes(this.state.modalId))
        ) {
            return null;
        }

        return (
            <Modal open={true} className={classes.modal}>
                <Grid container spacing={0} justify="center" className={classes.modalItem}>
                    <ReduxToastr
                        timeOut={5000}
                        newestOnTop={true}
                        preventDuplicates
                        position="top-right"
                        transitionIn="bounceIn"
                        transitionOut="fadeOut"
                        progressBar
                    />
                    <Grid item xs={12} lg={8}>
                        {activeModal.modalContent}
                    </Grid>
                </Grid>
            </Modal>
        );
    }

    public componentWillUnmount() {
        ModalViewClass.openModalIds.pop();
    }
}

const mapStateToProps = (state: State) => ({
    modalState: state.formModal,
});

const mapDispatchToProps = {};

export const ModalView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(ModalViewClass));
