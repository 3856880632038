import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { Titles } from '../../../../../../codes/titles';
import {
    BindFieldToField,
    Condition,
    False,
    FullWidthDatePicker,
    FullWidthRadioGroup,
    FullWidthSearchSelectField,
    FullWidthTextField,
    RadioOption,
    WhenFieldChanges,
} from '../../../../../../forms';
import {
    FirstNameMask,
    InitialsMask,
    LastNameMask,
    NumberMask,
} from '../../../../../../forms/input-masks';
import {
    composeValidators,
    requiredValidator,
    rsaIdNumberValidator,
    UserInput,
    ValidationResult,
} from '../../../../../../forms/validations';
import { convertToUpperCase, defaultDateOfBirth } from '../../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { NaturalRelationships } from '../../../../../../codes/relationship-types';
import { isAMinor } from '../../../../../../shared-functions';

interface Props extends StyledComponent {
    prefix?: string;
}
const guardianDetailsEdit = ({ prefix, classes }: Props) => {
    const fullPrefix = !!prefix && prefix !== '' ? `${prefix}.guardianDetails.` : '';
    const relationshipOptions = NaturalRelationships || [];
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.marginTop}>
                    {' '}
                    Gaurdian Details{' '}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    name={`${fullPrefix}relationshipToMinor`}
                    options={relationshipOptions.filter(x => x.value !== 'Child')}
                    validate={requiredValidator}
                    sortOptions
                />
                <WhenFieldChanges
                    field={`${fullPrefix}relationshipToMinor`}
                    set={`${fullPrefix}relationshipToMinorDescription`}
                    to={null}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthSearchSelectField name={`${fullPrefix}title`} options={Titles} />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${fullPrefix}initials`}
                    mask={InitialsMask}
                    parse={convertToUpperCase}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${fullPrefix}firstName`}
                    validate={requiredValidator}
                    mask={FirstNameMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField name={`${fullPrefix}secondName`} mask={FirstNameMask} />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${fullPrefix}lastName`}
                    label="Surname"
                    validate={requiredValidator}
                    mask={LastNameMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${fullPrefix}idNumber`}
                    validate={composeValidators(requiredValidator, rsaIdNumberValidator)}
                    mask={NumberMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthDatePicker
                    name={`${fullPrefix}dateOfBirth`}
                    validate={composeValidators(requiredValidator, ageValidator)}
                    disableFuture
                />
                <Condition when={`${fullPrefix}dateOfBirth`} hasValue>
                    <False>
                        <BindFieldToField
                            field={`${fullPrefix}dateOfBirth`}
                            toField={`${fullPrefix}idNumber`}
                            transform={defaultDateOfBirth}
                        />
                    </False>
                </Condition>
            </Grid>
            <Grid item xs={6}>
                <FullWidthRadioGroup
                    name={`${fullPrefix}detailsMatchesID`}
                    validate={requiredValidator}
                >
                    <RadioOption value="Yes" />
                    <RadioOption value="No" />
                </FullWidthRadioGroup>
            </Grid>
        </React.Fragment>
    );
};

function ageValidator(value: UserInput): ValidationResult {
    if (value && isAMinor(value as Date)) {
        return 'Guardian should at least be 18 years old';
    }
    return undefined;
}

export const GuardianDetailsEdit = withStyles(styles)(guardianDetailsEdit);
