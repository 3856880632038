import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect, MapDispatchToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ExpansionPanel } from '../../../../../forms/form-components/expansion-panel/expansion-panel';
import { State } from '../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../styles/styles';
import {
    DeleteDoctorsConsultation,
    deleteCurrentDoctorsConsultationThunk,
    addCurrentDoctorsConsultationThunk,
    AddDoctorsConsultation,
    UpdateDoctorsConsultation,
    updateCurrentDoctorsConsultationThunk,
} from '../action/current-doctors-consultation';
import {
    AddCurrentDoctor,
    addCurrentDoctorThunk,
    DeleteCurrentDoctor,
    deleteCurrentDoctorThunk,
    UpdateCurrentDoctor,
    updateCurrentDoctorThunk,
} from '../action/current-medical-doctor';
import { Doctors, getMedicalHistory } from '../reducer';
import { MedicalConsultationView } from './medical-consultation-view';

interface DispatchProps {
    addDoctor: AddCurrentDoctor;
    updateDoctor: UpdateCurrentDoctor;
    deleteDoctor: DeleteCurrentDoctor;
    addConsultation: AddDoctorsConsultation;
    updateConsultation: UpdateDoctorsConsultation;
    deleteConsultation: DeleteDoctorsConsultation;
}

interface OwnProps {}
type Props = StyledComponent &
    DispatchProps &
    OwnProps & {
        doctorsDetails: Doctors[];
    };

const currentMedicalConsultationView: React.FunctionComponent<Props> = ({
    addDoctor,
    doctorsDetails,
    updateDoctor,
    deleteDoctor,
    addConsultation,
    updateConsultation,
    deleteConsultation,
}: Props) => {
    return (
        <ExpansionPanel name="Current Medical Consultations" style={{ marginBottom: '14px' }}>
            <MedicalConsultationView
                doctorsDetails={doctorsDetails}
                addDoctor={addDoctor}
                updateDoctor={updateDoctor}
                deleteDoctor={deleteDoctor}
                addConsultation={addConsultation}
                updateConsultation={updateConsultation}
                deleteConsultation={deleteConsultation}
                prefix="Current"
            />
        </ExpansionPanel>
    );
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    addDoctor: record => dispatch(addCurrentDoctorThunk(record)),
    updateDoctor: (index, doctors) => dispatch(updateCurrentDoctorThunk(index, doctors)),
    deleteDoctor: index => dispatch(deleteCurrentDoctorThunk(index)),
    addConsultation: (record, doctors, index) =>
        dispatch(addCurrentDoctorsConsultationThunk(record, doctors, index)),
    updateConsultation: (consultation, doctors, visitIndex, doctorsIndex) =>
        dispatch(
            updateCurrentDoctorsConsultationThunk(consultation, doctors, visitIndex, doctorsIndex)
        ),
    deleteConsultation: (doctors, consultIndex, doctorsIndex) =>
        dispatch(deleteCurrentDoctorsConsultationThunk(doctors, consultIndex, doctorsIndex)),
});

const mapStateToProps = (state: State) => ({
    doctorsDetails: getMedicalHistory(state).currentMedicalHistory || [],
});

export const CurrentMedicalConsultationView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(currentMedicalConsultationView));
