import axios from 'axios';
import { claimUpdatedThunk, Claim } from '../../../claim';
import { ClaimType } from '../../../claim/claim-types';
import { openDialogAction } from '../../../confirmation-dialog';
import { env } from '../../../env';
import { hideLoadingAction, showLoadingAction } from '../../../forms/loading-screen/actions';
import { getLifeAssuredDetailsFromClaim } from '../../../life-assured-details/reducer';
import { ReduxThunkAction } from '../../../redux/redux-actions';
import { Nullable } from '../../../shared-types';
import { getAssessmentDetailsFromClaim } from '../assessment-details-reducer';

export interface RaiseLiabilityRequest {
    policyNumber: string;
    benefitCode?: string;
    causeOfDeath?: string;
    dateOfDeath?: Date;
    claimAmount?: number;
    claimType?: Nullable<ClaimType>;
    valueIndicator?: string;
}

type RaiseLiabilityReturn = Promise<any>;
export type RaiseLiability = (request: RaiseLiabilityRequest) => RaiseLiabilityReturn;
type RaiseLiabilityAction = (
    request: RaiseLiabilityRequest
) => ReduxThunkAction<RaiseLiabilityReturn>;
export const raiseLiabilityAction: RaiseLiabilityAction = (request: RaiseLiabilityRequest) => {
    return async (dispatch, getState) => {
        dispatch(showLoadingAction());
        const claim = getState().claimSession.claim;
        const raiseLiabilityUri = `${env().CLAIMS_API}/api/Claim/${claim.claimId}/RaiseLiability`;

        const lifeAssured = getLifeAssuredDetailsFromClaim(claim);
        const contract = lifeAssured.contracts.find(c => c.contractNumber === request.policyNumber);
        const decision = getDecision(claim, request);

        const liabilityRequest = {
            id: decision && decision.id,
            claimType: request.claimType || (decision && decision.claimType) || claim.claimType,
            compassClientId: lifeAssured.personalDetails.compassClientId,
            clientId: lifeAssured.personalDetails.clientId,
            contractType: contract && contract.contractType,
            contractNumber: request.policyNumber,
            benefitCode: request.benefitCode,
            valueIndicator: request.valueIndicator || 'Amount',
            claimAmount: decision && decision.amountPayable,
            effectiveDate:
                request.dateOfDeath || (decision && getEffectiveDate(decision.effectiveDate)),
            causeOfDeath: request.causeOfDeath,
        };

        await axios
            .post<Claim>(raiseLiabilityUri, liabilityRequest)
            .then(response => {
                if (response.data) {
                    dispatch(claimUpdatedThunk(response.data));
                }
            })
            .catch(error => {
                let errorMessage = '';
                if (error.response) {
                    errorMessage = `Error Code ${error.response.status}`;
                } else {
                    errorMessage = error.message;
                }
                showErrorMessage(errorMessage);
            })
            .then(() => dispatch(hideLoadingAction()));

        function showErrorMessage(error: any) {
            const errorMessage = `An error (${error}) occured while raising liability for contract ${request.policyNumber}, please retry later`;
            dispatch(
                openDialogAction({
                    content: {
                        title: 'Error raising liability',
                        body: errorMessage,
                        error: true,
                    },
                })
            );
        }
    };
};

function getDecision(claim: Claim, request: RaiseLiabilityRequest) {
    if (claim.claimType === ClaimType.Death || claim.claimType === ClaimType.RADeath) {
        return;
    }

    const assessmentContract = getAssessmentDetailsFromClaim(claim).find(
        c => c.policyNumber === request.policyNumber
    );
    const assessmentBenefit =
        assessmentContract &&
        assessmentContract.benefits.find(b => b.benefitCode === request.benefitCode);
    return (
        assessmentBenefit &&
        assessmentBenefit.decisions
            .sort((a, b) =>
                (a.assessmentDate || new Date()) < (b.assessmentDate || new Date())
                    ? 1
                    : (b.assessmentDate || new Date()) < (a.assessmentDate || new Date())
                    ? -1
                    : 0
            )
            .find(d => d.final === true)
    );
}

function getEffectiveDate(date?: Date) {
    if (date && date.getDate() !== 1) {
        date.setMonth(date.getMonth() + 1);
        date.setDate(1);
    }

    return date;
}
