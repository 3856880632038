export enum DocumentStatus {
    Requested = 'Requested',
    Received = 'Received',
    Verified = 'Verified',
    Waived = 'Waived',
}

export enum TypeOfDeath {
    Natural = 'Natural',
    Unnatural = 'Unnatural',
}

export enum DocumentNames {
    PoliceStatement = 'Police statement',
    NoticeOfDeath = 'BI 1663/Notice of death',
    IdentityDocument = 'ID / Passport',
    MedicalReport = 'Extract of medical report',
    DeathCertificate = 'Death certificate',
    ExchangeForm = 'Exchange Form',
    ProofOfBankAccount = 'Proof of Bank Account',
    Executorship = 'Proof of executorship',
    TrustResolutionOrDeed = 'Trust Resolution/Trust Deed',
    LetterOfAuthority = 'Letter of Authority',
    Ck1Document = 'CK1 Document',
    CompanyResolution = 'Company Resolution',
    IdDocumentsOfMembers = 'Company Members ID Documents',
}

export enum ReasonForRequest {
    Outstanding = 'Outstanding',
    IncorrectDocument = 'Incorrect document',
    IllegibleDocument = 'Illegible document',
    IncompleteDocument = 'Incomplete document',
    AdditionalDocument = 'Additional document required',
    NoLongerRequired = 'No Longer Required',
}

export enum UserTypes {
    System = 'System',
}

export enum FieldNames {
    DateRequested = 'Date requested',
    BankingDetails = 'Banking Details',
    Decision = 'decision',
    Payment = 'Payment Details',
    LiquidAndDistribution = 'Liquid And Distribution',
    Dependant = 'Dependant',
    PersonalDetails = 'Personal Details',
    ResidentialAddress = 'Residential Address',
    PostalAddress = 'Postal Address',
    ContactDetails = 'Contact Details',
    FinancialInformation = 'Financial Information',
    IncomeExpenditure = 'Income Expenditure Breakdown',
}

export enum ObjectTypes {
    PersonalDetails = 'personalDetails',
    ResidentialAddress = 'residentialAddress',
    PostalAddress = 'postalAddress',
    BankingDetails = 'bankingDetails',
    ContactDetails = 'contactDetails',
    FinancialInformation = 'financialInformation',
    IncomeExpenditure = 'breakdown',
    Occupation = 'occupation',
    RelationshipDescription = 'relationshipDescription',
    Relationship = 'relationship',
    Breakdown = 'breakdown',
    OrganisationDetails = 'organisationDetails',
    Address = 'address',
    CommunicationDetails = 'communicationDetails',
    AuthorisedPerson = 'authorisedPerson',
    FinancialAdviser = 'financialAdviser',
    Guardian = 'guardian',
    Disability = 'disability',
    IsExecutor = 'isExecutor',
    BeneficiaryOfWill = 'beneficiaryOfWill',
    ClaimCapacity = 'claimCapacity',
    Parent = 'parent',
    ParentDetails = 'parentDetails',
    Curator = 'curator',
}

export enum ActionTypes {
    Add = 'add',
    Edit = 'edit',
    Delete = 'delete',
}
