import React from 'react'; // eslint-disable-line

import { AssessmentDetailsView } from '../../common/views/assessment-details';
import { IncomeDisabilityAssessmentDecisionDetailView } from './income-disability-assessment-decision-add';

export const IncomeDisabilityAssessmentDetailsView: React.FunctionComponent = () => {
    return (
        <AssessmentDetailsView
            assessmentDecisionAddView={IncomeDisabilityAssessmentDecisionDetailView}
        />
    );
};
