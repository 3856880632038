import React from 'react'; // eslint-disable-line
import { RetrospectiveUnderwritingDecisionView } from '../common/retrospective-underwriting-decision';
import { UnderwritingDecision } from '../common/synopsis/underwriting-decision';
import { AuthGuard } from '../../../auth/authGuard';

export const LivingLifestyleDecision = () => {
    const hasUnderwritterAccess = AuthGuard(['Agent']);
    return (
        <UnderwritingDecision>
            <RetrospectiveUnderwritingDecisionView isUnderWriting={hasUnderwritterAccess()} />
        </UnderwritingDecision>
    );
};
