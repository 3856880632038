import React from 'react'; // eslint-disable-line
import { ERBAssessmentDecisionAddView } from './early-retirement-assessment-decision-add';
import { ERBAssessmentDetailsView } from './early-retirement-assessment-details-view';

export const EarlyRetirementAssessmentDetailsView: React.FunctionComponent = () => {
    return (
        <ERBAssessmentDetailsView
            investmentAssessmentDecisionAddView={props => (
                <ERBAssessmentDecisionAddView {...props} />
            )}
        />
    );
};
