import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect, MapDispatchToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Section } from '../../../../../controls/section';
import { ButtonWrapper } from '../../../../../forms';
import {
    getLifeAssuredDetails,
    LifeAssuredDetails,
} from '../../../../../life-assured-details/reducer';
import { State } from '../../../../../redux/root-reducer';
import { getFullName } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { RequiredDocument } from '../../../common/requirements-management/reducer';
import {
    AddDocumentButton,
    RequiredDocumentsView,
} from '../../../common/requirements-management/views';
import {
    AddAndDocumentStatusRecord,
    addAndSubmitDocumentStatusAction,
} from '../actions/add-deceased-required-document';
import { getDocumentsRequirements } from '../reducer';
import { EditDocumentsButton } from './requirements-edit-modal';
import { DocumentOwnerType } from '../../../../../shared-types';

interface DispatchProps {
    addDocument: AddAndDocumentStatusRecord;
}
interface OwnProps {}
type Props = StyledComponent &
    OwnProps &
    DispatchProps & {
        documents: RequiredDocument[];
        lifeAssuredDetails: LifeAssuredDetails;
    };
const deceasedRequirementView: React.FunctionComponent<Props> = ({
    documents,
    addDocument,
    lifeAssuredDetails,
}: Props) => {
    if (documents.length === 0) {
        return null;
    }

    const laName = getFullName(lifeAssuredDetails.personalDetails);
    return (
        <Section title="Deceased requirements">
            <Grid container spacing={0} justify="flex-start">
                <Grid item xs={8} lg={10}>
                    <Typography variant="h5">{laName}</Typography>
                </Grid>
                <ButtonWrapper>
                    <Grid item xs={4} lg={2} style={{ textAlign: 'right' }}>
                        <EditDocumentsButton
                            documents={documents}
                            name={laName}
                            prefix="deceased"
                        />
                        <AddDocumentButton
                            addAction={addDocument}
                            isDeath
                            documentOwnerType={DocumentOwnerType.LifeAssured}
                            documents={documents}
                        />
                    </Grid>
                </ButtonWrapper>
            </Grid>
            <RequiredDocumentsView documents={documents} />
        </Section>
    );
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    addDocument: record => dispatch(addAndSubmitDocumentStatusAction(record)),
});

const mapStateToProps = (state: State) => {
    const requirements = getDocumentsRequirements(state) || {};
    return {
        documents: requirements.deceased || [],
        lifeAssuredDetails: getLifeAssuredDetails(state),
    };
};

export const DeceasedRequirementView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(deceasedRequirementView));
