import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { getClaim, Claim } from '../../claim/reducers';
import { State } from '../../redux/root-reducer';
import { Nullable } from '../../shared-types';
import { SetActiveWorkflow, setActiveWorkflowAction } from '../../workflow-navigation';
import { Workflow } from '../../workflow-navigation/reducers/workflow';
import { disabilityWorkflow } from '../workflow-provider/disability-workflow';
import { incomeProtectorWorkflow } from '../workflow-provider/income-protector-workflow';
import { initiateClaimWorkflow } from '../workflow-provider/initiate-claim-workflow';
import { raDeathWorkflow } from '../workflow-provider/ra-death-workflow';
import { retrenchmentWorkflow } from '../workflow-provider/retrenchment-workflow';
import { deathWorkflow } from '../workflow-provider/death-workflow';
import { ClaimType } from '../../claim/claim-types';
import { dreadWorkflow } from '../workflow-provider/dread-workflow';
import { push } from 'connected-react-router';
import { ClaimWorkflow } from '../workflow-provider/claim-workflow';
import { funeralWorkflow } from '../workflow-provider/funeral-workflow';
import { terminalIllnessWorkflow } from '../workflow-provider/terminal-illness-workflow';
import { earlyRetirementWorkflow } from '../workflow-provider/early-retirement-workflow';

const workflows: ClaimWorkflow[] = [
    disabilityWorkflow,
    incomeProtectorWorkflow,
    raDeathWorkflow,
    retrenchmentWorkflow,
    deathWorkflow,
    dreadWorkflow,
    funeralWorkflow,
    terminalIllnessWorkflow,
    earlyRetirementWorkflow,
];

interface Props {
    readonly activeWorkflow?: Workflow;
    readonly claimType: Nullable<ClaimType>;
    readonly newWorkflow: Workflow;
    readonly setActiveWorkflow: SetActiveWorkflow;
    readonly setLocation: any; // eslint-disable-line
    readonly children: React.ReactNode;
}

class WorkflowProviderClass extends React.Component<Props> {
    public componentDidMount() {
        this.updateWorkflow();
    }

    public componentDidUpdate() {
        this.updateWorkflow();
    }

    public render() {
        return this.props.children;
    }

    private updateWorkflow() {
        if (this.props.activeWorkflow && !this.props.claimType) {
            return;
        }

        if (
            !!this.props.activeWorkflow &&
            this.props.activeWorkflow.label === this.props.newWorkflow.label
        ) {
            return;
        }

        this.props.setActiveWorkflow(this.props.newWorkflow);
    }
}

function getWorkflow(claim: Claim): Workflow {
    const newWorkflow = workflows.find(workflow => workflow.handlesClaim(claim));
    return newWorkflow || initiateClaimWorkflow;
}

const mapStateToProps = (state: State) => ({
    activeWorkflow: state.claimSession.workflow.activeWorkflow,
    claimType: getClaim(state).claimType,
    newWorkflow: getWorkflow(getClaim(state)),
});

const mapDispatchToProps = {
    setActiveWorkflow: setActiveWorkflowAction,
    setLocation: push,
};

export const WorkflowProvider = connect(mapStateToProps, mapDispatchToProps)(WorkflowProviderClass);
