import { Button, Grid, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { Titles } from '../../../../../../codes/titles';
import {
    BindFieldToField,
    Condition,
    False,
    FullWidthDatePicker,
    FullWidthSearchSelectField,
    FullWidthTextField,
} from '../../../../../../forms';
import {
    FirstNameMask,
    InitialsMask,
    LastNameMask,
    NumberMask,
} from '../../../../../../forms/input-masks';
import {
    composeValidators,
    requiredValidator,
    rsaIdNumberValidator,
} from '../../../../../../forms/validations';
import { convertToUpperCase, defaultDateOfBirth } from '../../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { FileCopy } from '@material-ui/icons';
import { FormApi } from 'final-form';
import { FormSpy } from 'react-final-form';
import {
    getNoticeOfDeath,
    NoticeOfDeath,
} from '../../../../common/notice-of-death/notice-of-death-reducer';
import { State } from '../../../../../../redux/root-reducer';
import { connect } from 'react-redux';
import { FormDetails } from '../../form-details-reducer';
import { OnSubmitSuccessProps } from '../../../../../../workflow-navigation';
import {
    DeathCertificate,
    getDeathCertificate,
} from '../../../death-certification/death-certification-reducer';

interface Props extends OnSubmitSuccessProps, StyledComponent {
    readonly deathCertificate: DeathCertificate;
    readonly noticeOfDeath: NoticeOfDeath;
}

const DeceasedDetailsModal: React.FunctionComponent<Props> = ({
    classes,
    deathCertificate,
    noticeOfDeath,
    ...rest
}: Props) => {
    return <InnerForm {...{ classes, noticeOfDeath, deathCertificate }} />;
};

type InnerFormProps = StyledComponent & {
    noticeOfDeath: NoticeOfDeath;
    deathCertificate: DeathCertificate;
};
const InnerForm: React.FunctionComponent<InnerFormProps> = ({
    classes,
    noticeOfDeath,
    deathCertificate,
}) => {
    return FormSpy<FormDetails>({
        render: ({ form }) =>
            deceasedDetailsEdit({
                classes,
                form,
                noticeOfDeath,
                deathCertificate,
            }),
    });
};

type InnerProps = InnerFormProps & {
    form: FormApi<any>;
};

const deceasedDetailsEdit: React.FunctionComponent<InnerProps> = ({
    classes,
    form,
    deathCertificate,
    noticeOfDeath,
}: InnerProps) => {
    function populateFromNoticeOfDeath() {
        if (!!form) {
            const deceased = noticeOfDeath.deceased || {};
            const personalDetails = deceased.personalDetails || {};
            form.change('deceasedDetails.title', personalDetails.title);
            form.change('deceasedDetails.initials', personalDetails.initials);
            form.change('deceasedDetails.firstName', personalDetails.firstName);
            form.change('deceasedDetails.secondName', personalDetails.secondName);
            form.change('deceasedDetails.lastName', personalDetails.lastName);
            form.change(
                'deceasedDetails.idNumber',
                (personalDetails.idNumber && personalDetails.idNumber.toString()) || ''
            );
            form.change('deceasedDetails.dateOfBirth', personalDetails.dateOfBirth);
        }
    }

    function populateFromDeathCertificate() {
        if (!!form) {
            const personalDetails = deathCertificate || {};
            form.change('deceasedDetails.firstName', personalDetails.firstName);
            form.change('deceasedDetails.secondName', personalDetails.secondName);
            form.change('deceasedDetails.lastName', personalDetails.lastName);
            form.change(
                'deceasedDetails.idNumber',
                (personalDetails.idNumber && personalDetails.idNumber.toString()) || ''
            );
            form.change('deceasedDetails.dateOfBirth', personalDetails.dateOfBirth);
        }
    }

    return (
        <React.Fragment>
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={12} container justify="flex-end">
                    <Button onClick={populateFromNoticeOfDeath} className={classes.copyButton}>
                        <FileCopy className={classes.copyIcon} />
                        Copy from notice of death
                    </Button>
                    <Button onClick={populateFromDeathCertificate} className={classes.copyButton}>
                        <FileCopy className={classes.copyIcon} />
                        Copy from death certificate
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <FullWidthSearchSelectField name="deceasedDetails.title" options={Titles} />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        name="deceasedDetails.initials"
                        mask={InitialsMask}
                        parse={convertToUpperCase}
                        validate={requiredValidator}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        name="deceasedDetails.firstName"
                        validate={requiredValidator}
                        mask={FirstNameMask}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField name="deceasedDetails.secondName" mask={FirstNameMask} />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        name="deceasedDetails.lastName"
                        label="Surname"
                        validate={requiredValidator}
                        mask={LastNameMask}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        name="deceasedDetails.idNumber"
                        label="ID Number"
                        validate={composeValidators(requiredValidator, rsaIdNumberValidator)}
                        mask={NumberMask}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FullWidthDatePicker
                        name="deceasedDetails.dateOfBirth"
                        validate={requiredValidator}
                        disableFuture
                    />
                    <Condition when="deceasedDetails.dateOfBirth" hasValue>
                        <False>
                            <BindFieldToField
                                field="deceasedDetails.dateOfBirth"
                                toField="deceasedDetails.idNumber"
                                transform={defaultDateOfBirth}
                            />
                            <BindFieldToField
                                field="deceasedDetails.age"
                                toField="deceasedDetails.dateOfBirth"
                                transform={undefined}
                            />
                        </False>
                    </Condition>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const mapStateToProps = (state: State) => ({
    deathCertificate: getDeathCertificate(state),
    noticeOfDeath: getNoticeOfDeath(state),
});

const mapDispatchToProps = {};

export const DeceasedDetailsEdit = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(DeceasedDetailsModal));
