import { Button, Grid, Tooltip, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { LabeledValue } from '../../../../controls/labeled-value';
import { Section } from '../../../../controls/section';
import { OpenModal, openModalAction } from '../../../../form-modal/actions';
import { FormModalView } from '../../../../form-modal/views/form-modal';
import { ButtonWrapper } from '../../../../forms';
import { Benefit, LifeAssuredContract } from '../../../../life-assured-details/reducer';
import { State } from '../../../../redux/root-reducer';
import { formatCurrency, getDisplayDate } from '../../../../shared-functions';
import styles from '../../../../styles/styles';
import { PolicyAssessmentDetails } from '../../../decisions/assessment-details-reducer';
import { EditPolicyDetails } from './edit-policy-details';

interface Props {
    benefitCode: string;
    contract: LifeAssuredContract | undefined;
    contractBenefit?: Benefit;
    decisionId: string;
    isReadOnly: boolean;
    openModal: OpenModal;
    policyAssessment?: PolicyAssessmentDetails;
}

const defaultContract: LifeAssuredContract = {
    associatedContracts: [],
    policyOwners: [],
    beneficiaries: [],
    lifeAssureds: [],
};

const policyDetails = ({
    benefitCode,
    contract = defaultContract,
    contractBenefit,
    decisionId,
    isReadOnly,
    openModal,
    policyAssessment,
}: Props) => {
    const assessmentBenefit =
        policyAssessment && policyAssessment.benefits.find(b => b.benefitCode === benefitCode);
    const decision =
        assessmentBenefit && assessmentBenefit.decisions.find(d => d.id === decisionId);

    const openEditView = () => {
        openModal({
            modalContent: (
                <FormModalView
                    formView={() => (
                        <EditPolicyDetails
                            benefitName={contractBenefit && contractBenefit.name}
                            policyDetails={contract}
                        />
                    )}
                />
            ),
        });
    };
    const editPolicyDetailsButton = (
        <ButtonWrapper>
            <Tooltip title="Edit Policy Premium Details">
                <Button id="EditPolicyPremiumDetails" color="secondary" onClick={openEditView}>
                    <Edit />
                </Button>
            </Tooltip>
        </ButtonWrapper>
    );
    return (
        <Section
            title="Policy Details"
            gutterElement={isReadOnly ? undefined : editPolicyDetailsButton}
        >
            <Grid item lg={3} xs={6}>
                <LabeledValue
                    label="Policy Number"
                    value={policyAssessment && policyAssessment.policyNumber}
                />
            </Grid>
            <Grid item lg={3} xs={6}>
                <LabeledValue
                    label="Benefit Name"
                    value={contractBenefit && contractBenefit.name}
                />
            </Grid>
            <Grid item lg={3} xs={6}>
                <LabeledValue label="ABI" value={`${contract.automaticBenefitIncrease || 0}%`} />
            </Grid>
            <Grid item lg={3} xs={6}>
                <LabeledValue
                    label="Anniversary Date"
                    value={getAnniversaryDate(
                        assessmentBenefit && assessmentBenefit.commencementDate
                    )}
                />
            </Grid>
            <Grid item lg={3} xs={6}>
                <LabeledValue
                    label="Premium Amount"
                    value={formatCurrency(contractBenefit && contractBenefit.premiumAmount)}
                />
            </Grid>
            <Grid item lg={3} xs={6}>
                <LabeledValue label="Premium Pattern" value={contract.premiumPatternDesc} />
            </Grid>
            <Grid item lg={3} xs={6}>
                <LabeledValue
                    label="ACI"
                    value={`${contract.aciPercentage || contract.aciAmount || 0}%`}
                />
            </Grid>
            <Grid item lg={3} xs={6}>
                <LabeledValue
                    label="Sum Assured"
                    value={formatCurrency(contractBenefit && contractBenefit.benefitAmount)}
                />
            </Grid>
            <Grid item lg={3} xs={6}>
                <LabeledValue
                    label="Approved Date From"
                    value={getDisplayDate(decision && decision.effectiveDate)}
                />
            </Grid>
            <Grid item lg={3} xs={6}>
                <LabeledValue
                    label="Approved Date To"
                    value={getDisplayDate(decision && decision.expiryDate)}
                />
            </Grid>
        </Section>
    );
};

const getAnniversaryDate = (commencementDate?: Date) => {
    let currentDate = new Date();
    if (commencementDate) {
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
        const anniversaryMonth = commencementDate.getMonth();
        const anniversaryDay = commencementDate.getDate();
        if (
            currentMonth > anniversaryMonth ||
            (currentMonth === anniversaryMonth && currentDate.getDate() > anniversaryDay)
        ) {
            currentDate = new Date(currentYear + 1, anniversaryMonth, anniversaryDay);
        } else {
            currentDate = new Date(currentYear, anniversaryMonth, anniversaryDay);
        }
    }
    return getDisplayDate(currentDate);
};

const mapStateToProps = (state: State) => ({
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = {
    openModal: openModalAction,
};

export const PolicyDetails = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(policyDetails));
