import React from 'react'; // eslint-disable-line

import { Grid, Paper, withStyles } from '@material-ui/core';
import { CommunicationMethods } from '../../../../../codes/communication-methods';
import { Titles } from '../../../../../codes/titles';
import { FormHeading, FullWidthSearchSelectField, FullWidthTextField } from '../../../../../forms';
import { FirstNameMask, InitialsMask, LastNameMask } from '../../../../../forms/input-masks';
import { CommunicationDetailsSubForm } from '../../../../../forms/sub-forms/communication-details';
import { conditionalRequired } from '../../../../../forms/validations';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { getPropertyValueFunction } from '../../../../../forms/sub-forms/utility-functions';
import { FinancialAdviserContactDetails } from '../../../../../contact-details/financial-adviser/financial-adviser-contact-details';

type FinancialAdviserProps = StyledComponent & {
    communicationMethod?: boolean;
    propertyName?: string;
    required?: boolean;
};

const financialAdviserDetails: React.FunctionComponent<FinancialAdviserProps> = ({
    classes,
    communicationMethod,
    propertyName,
    required,
}: FinancialAdviserProps) => {
    const propertyPrefix = !!propertyName ? `${propertyName}.` : '';
    const propValue = getPropertyValueFunction<FinancialAdviserContactDetails>(propertyPrefix);
    return (
        <Paper className={classes.paper}>
            <FormHeading text="Financial Adviser Details" />
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={6}>
                    <FullWidthSearchSelectField
                        name={`${propertyPrefix}title`}
                        label="Title"
                        options={Titles}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        name={`${propertyPrefix}initials`}
                        label="Initials"
                        mask={InitialsMask}
                        inputProps={{
                            style: { textTransform: 'uppercase' },
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        name={`${propertyPrefix}firstName`}
                        label="Name"
                        mask={FirstNameMask}
                        validate={conditionalRequired(values => {
                            if (required && !propValue(values, 'companyName')) {
                                return true;
                            }
                            if (
                                (propValue(values, 'title') ||
                                    propValue(values, 'initials') ||
                                    propValue(values, 'lastName') ||
                                    propValue(values, 'consultantCode') ||
                                    propValue(values, 'telephoneNumber') ||
                                    propValue(values, 'cellphoneNumber') ||
                                    propValue(values, 'emailAddress')) &&
                                !propValue(values, 'companyName')
                            ) {
                                return true;
                            }
                            return false;
                        })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        name={`${propertyPrefix}lastName`}
                        label="Surname"
                        mask={LastNameMask}
                        validate={conditionalRequired(values => {
                            if (required && !propValue(values, 'companyName')) {
                                return true;
                            }
                            if (
                                (propValue(values, 'title') ||
                                    propValue(values, 'initials') ||
                                    propValue(values, 'firstName') ||
                                    propValue(values, 'consultantCode') ||
                                    propValue(values, 'telephoneNumber') ||
                                    propValue(values, 'cellphoneNumber') ||
                                    propValue(values, 'emailAddress')) &&
                                !propValue(values, 'companyName')
                            ) {
                                return true;
                            }
                            return false;
                        })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        name={`${propertyPrefix}companyName`}
                        validate={conditionalRequired(values => {
                            if (
                                required &&
                                propValue(values, 'firstName') === undefined &&
                                propValue(values, 'lastName') === undefined
                            ) {
                                return true;
                            }
                            if (
                                (propValue(values, 'consultantCode') !== undefined ||
                                    propValue(values, 'telephoneNumber') !== undefined ||
                                    propValue(values, 'cellphoneNumber') !== undefined ||
                                    propValue(values, 'emailAddress') !== undefined) &&
                                (!propValue(values, 'firstName') || !propValue(values, 'lastName'))
                            ) {
                                return true;
                            }
                            return false;
                        })}
                    />
                </Grid>
                <CommunicationDetailsSubForm
                    communicationDetailsPropertyName={propertyName}
                    displayOptions={{
                        telephoneNumber: true,
                        cellphoneNumber: true,
                        emailAddress: true,
                    }}
                />
                <Grid item xs={6}>
                    <FullWidthTextField
                        name={`${propertyPrefix}consultantCode`}
                        label="Consultant Code"
                    />
                </Grid>
                {!!communicationMethod && (
                    <Grid item xs={6}>
                        <FullWidthSearchSelectField
                            name={`${propertyPrefix}preferredMethodOfCommunication`}
                            label="Preferred Method Of Communication"
                            options={CommunicationMethods}
                        />
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
};

export const FinancialAdviserDetailsEditView = withStyles(styles)(financialAdviserDetails);
