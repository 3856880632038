import React from 'react'; // eslint-disable-line

import { Divider, Grid, Paper, Typography, withStyles } from '@material-ui/core';
import { Titles } from '../../codes/titles';
import {
    Condition,
    False,
    FullWidthCheckBoxField,
    FullWidthSearchSelectField,
    FullWidthTextField,
    True,
    WhenFieldChanges,
} from '../../forms';
import { FirstNameMask, InitialsMask, LastNameMask } from '../../forms/input-masks';
import { CommunicationDetailsSubForm } from '../../forms/sub-forms/communication-details';
import { isEmpty } from '../../forms/validations';
import styles, { StyledComponent } from '../views/styles';
import { connect } from 'react-redux';
import { OpenDialog, openDialogAction } from '../../confirmation-dialog';
import { FormApi } from 'final-form';
import { FormSpy } from 'react-final-form';

interface Props extends StyledComponent {
    openModal: OpenDialog;
}

const CaptureClaimContactDetailsView: React.FunctionComponent<Props> = ({
    classes,
    openModal,
}: Props) => {
    return (
        <Paper className={classes.paper}>
            <Typography variant="h5" className={classes.spacedTypography}>
                Contact Person For The Claim
            </Typography>
            <Divider />
            <Condition when="financialAdviser" passes={isEmpty}>
                <True>
                    <DetailsOfContactPerson openModal={openModal} disabled />
                </True>
                <False>
                    <DetailsOfContactPerson openModal={openModal} />
                </False>
            </Condition>
            <Condition when="contactPerson.sameDetailsAsFa" is={true}>
                <False>
                    <InnerForm sourceProperty="contactPerson" />
                </False>
                <True>
                    <InnerForm sourceProperty="financialAdviser" disabled />
                </True>
            </Condition>
        </Paper>
    );
};

interface ContactPersonProps {
    disabled?: boolean;
    openModal: OpenDialog;
}
const DetailsOfContactPerson = ({ disabled, openModal }: ContactPersonProps) => {
    return FormSpy<any>({
        render: ({ form }) =>
            SameDetailsAsFa({
                disabled,
                form,
                openModal,
            }),
    });
};

interface SameDetailsAsFaProps {
    disabled?: boolean;
    openModal: OpenDialog;
    form: FormApi<any>;
}

const SameDetailsAsFa: React.FunctionComponent<SameDetailsAsFaProps> = ({
    disabled,
    openModal,
    form,
}: SameDetailsAsFaProps) => {
    function open(value?: boolean) {
        if (value) {
            openModal({
                content: {
                    title: 'Details same as financial Adviser',
                    body:
                        'Are you sure you want to prepopulate the above data to the section below?',
                },
                noAction: { action: () => form.change('contactPerson.sameDetailsAsFa', false) },
                yesAction: { action: () => ({}) },
            });
        }
    }

    return (
        <React.Fragment>
            <FullWidthCheckBoxField
                name="contactPerson.sameDetailsAsFa"
                id="sameDetailsCheckbox"
                label="Details Same As Financial Adviser"
                disable={disabled}
            />
            <WhenFieldChanges
                field="contactPerson.sameDetailsAsFa"
                becomes={true}
                set="test"
                to={(value?: boolean) => open(value)}
            />
        </React.Fragment>
    );
};

interface InnerProps {
    sourceProperty: string;
    disabled?: boolean;
}
const InnerForm: React.FunctionComponent<InnerProps> = ({
    sourceProperty,
    disabled,
}: InnerProps) => {
    return (
        <Grid container spacing={5} justify="flex-start">
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    name={`${sourceProperty}.title`}
                    id="contactPersonTitle"
                    label="Title"
                    options={Titles}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${sourceProperty}.initials`}
                    id="contactPersonInitials"
                    label="Initials"
                    disabled={disabled}
                    inputProps={{
                        style: { textTransform: 'uppercase' },
                    }}
                    mask={InitialsMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${sourceProperty}.firstName`}
                    id="contactPersonFirstName"
                    label="Name"
                    mask={FirstNameMask}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${sourceProperty}.lastName`}
                    id="contactPersonLastName"
                    label="Surname"
                    mask={LastNameMask}
                    disabled={disabled}
                />
            </Grid>
            <CommunicationDetailsSubForm
                communicationDetailsPropertyName={sourceProperty}
                idBaseName="contactPerson"
                disabled={disabled}
            />
        </Grid>
    );
};

const mapStateToProps = () => {};
const mapDispatchToProps = {
    openModal: openDialogAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(CaptureClaimContactDetailsView));
