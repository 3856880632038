import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ErrorObject, isEmpty } from '../../forms/validations';
import { GetState, State } from '../../redux/root-reducer';
import { Claim } from '../reducers';
import { submitClaimThunk } from './submit-claim';

export async function claimSubmit(
    dispatch: ThunkDispatch<State, null, AnyAction>,
    getState: GetState,
    updateClaim?: (claim: Claim) => Claim,
    validate?: (claim: Claim) => ErrorObject
) {
    let claim = getState().claimSession.claim;

    if (!claim) {
        throw new Error('Unable to Submit, Claim is Null or Undefined');
    }

    if (!!updateClaim) {
        claim = updateClaim(claim);
    }

    if (!!validate) {
        const errors = validate(claim);
        if (!isEmpty(errors)) {
            return errors;
        }
    }

    const serverErrors = await dispatch(submitClaimThunk(claim));
    return serverErrors;
}
