import React from 'react'; // eslint-disable-line
import { Grid } from '@material-ui/core';
import { FullWidthDatePicker, FullWidthSelectField, FullWidthTextField } from '../../../../forms';
import { composeValidators, maxValue, requiredValidator } from '../../../../forms/validations';
import { minDateValidator } from '../../../../forms/validations/date-validator';
import { CurrencyMask } from '../../../../forms/input-masks';
import { convertToNumber } from '../../../../shared-functions';

interface Props {
    sumAssured?: number;
}

export const IncomeAssessmentDecisionFields: React.FunctionComponent<Props> = ({
    sumAssured,
}: Props) => {
    return (
        <React.Fragment>
            <Grid item xs={4}>
                <FullWidthDatePicker name="effectiveDate" validate={requiredValidator} />
            </Grid>
            <Grid item xs={4}>
                <FullWidthDatePicker
                    name="expiryDate"
                    minDateField="effectiveDate"
                    validate={composeValidators(
                        requiredValidator,
                        minDateValidator('effectiveDate')
                    )}
                />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4}>
                <FullWidthSelectField
                    name="paymentBasis"
                    options={[
                        { value: 'Sum assured amount', label: 'Sum assured amount' },
                        { value: 'Proven highest salary', label: 'Proven highest salary' },
                        { value: 'Loss of income', label: 'Loss of income' },
                    ]}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={4}>
                <FullWidthTextField
                    name="amountPayable"
                    mask={CurrencyMask}
                    validate={composeValidators(
                        requiredValidator,
                        maxValue(sumAssured || 0, 'sum assured')
                    )}
                    parse={convertToNumber}
                />
            </Grid>
        </React.Fragment>
    );
};
