import { FinancialAdviserContactDetails } from '../../../../../contact-details/financial-adviser/financial-adviser-contact-details';
import { isEmpty } from '../../../../../forms/validations';
import { UpdateRecordCollectionAction } from '../../../../../redux/collection-reducer';
import { UPDATE_CLAIMANT_RECORD } from './types';
import { Claimant } from '../../contact-details/contact-details-reducer';

export type UpdateClaimantRecordAction = UpdateRecordCollectionAction<Claimant>;

export const updateClaimantRecordActionCreator = (
    claimantRecordIndex: number,
    claimantRecord: Claimant
): UpdateClaimantRecordAction => {
    return {
        type: UPDATE_CLAIMANT_RECORD,
        record: getClaimantRecord(claimantRecord),
        index: claimantRecordIndex,
    };
};

function getClaimantRecord(claimant: Claimant): Claimant {
    return {
        id: claimant.id,
        claimantDetails: claimant.claimantDetails,
        financialAdviser: getFinancialAdvisor(claimant.financialAdviser),
    };
}

function getFinancialAdvisor(financialAdviser?: FinancialAdviserContactDetails) {
    if (isEmpty(financialAdviser)) {
        return undefined;
    }

    return financialAdviser;
}
