import React from 'react'; // eslint-disable-line

import {
    Button,
    Divider,
    Grid,
    Paper,
    Tooltip,
    Typography,
    withStyles,
    Checkbox,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { OpenModal, openModalAction } from '../../../../form-modal/actions';
import { FormModalView } from '../../../../form-modal/views/form-modal';
import { Benefit } from '../../../../life-assured-details/reducer';
import { formatCurrency, getDisplayDate } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import { LabeledValue } from '../../../../controls/labeled-value';
import { ReassuranceDetailsView } from '../../common/policy-summary/reassurance-details';
import { ReassuranceEditModal } from './reassurance-details-edit-modal';
import { State } from '../../../../redux/root-reducer';
import { ButtonWrapper } from '../../../../forms';

type Props = StyledComponent & {
    benefits: Benefit[];
    contractIndex: number;
    openModal: OpenModal;
};
const deathBenefitDetails: React.FunctionComponent<Props> = ({
    classes,
    benefits,
    contractIndex,
    openModal,
}: Props) => {
    if (contractIndex < 0 || benefits.length === 0) {
        return null;
    }

    const edit = () => {
        openModal({
            modalContent: <FormModalView formView={ReassuranceEditModal} />,
            modalData: contractIndex,
        });
    };
    return (
        <React.Fragment>
            <Grid container justify="space-between">
                <Grid item xs={10} lg={11} className={classes.marginTop}>
                    <Typography variant="subtitle2">Benefits</Typography>
                </Grid>
                <ButtonWrapper>
                    <Grid item xs={2} lg={1} className={classes.rightAlign}>
                        <Tooltip title="Refer to Reinsurance">
                            <Button
                                id="EditReassuranceDetails"
                                color="secondary"
                                onClick={() => edit()}
                            >
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            </Grid>
            <Paper className={classes.paper}>
                {benefits.map((benefit, index) => {
                    return (
                        <Grid container justify="flex-start" key={`${benefit.name}`}>
                            {index > 0 && (
                                <Grid item xs={12}>
                                    <Divider
                                        className={classNames(
                                            classes.marginTop,
                                            classes.marginBottom
                                        )}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">{benefit.name}</Typography>
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Commencement date"
                                    value={getDisplayDate(benefit.startDate)}
                                />
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Sum Assured"
                                    value={formatCurrency(benefit.benefitAmount)}
                                />
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Policy Age"
                                    value={getPolicyAge(benefit.startDate)}
                                />
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <Typography variant="subtitle2" className={classes.marginTop}>
                                    Paid
                                </Typography>
                                <Checkbox
                                    checked={Boolean(benefit.isBenefitPaid)}
                                    disabled
                                    style={{ margin: 0, padding: 0 }}
                                />
                            </Grid>
                            <ReassuranceDetailsView
                                reassuranceDetails={benefit.reassuranceDetails || []}
                            />
                        </Grid>
                    );
                })}
            </Paper>
        </React.Fragment>
    );
};

export function getPolicyAge(startDate?: Date) {
    const policyStartDate = startDate || new Date();
    const today = new Date();
    let months;
    const totalMonths =
        (today.getFullYear() - policyStartDate.getFullYear()) * 12 +
        today.getMonth() -
        policyStartDate.getMonth();
    let years = today.getFullYear() - policyStartDate.getFullYear();

    if (policyStartDate.getMonth() > today.getMonth()) {
        years = years - 1;
    } else if (policyStartDate.getMonth() === today.getMonth()) {
        if (policyStartDate.getDate() > today.getDate()) {
            years = years - 1;
        }
    }

    if (policyStartDate.getMonth() === today.getMonth()) {
        months = totalMonths % 12;
    } else {
        months = Math.round(totalMonths % 12);
    }

    return `${years} Years ${months} Months`;
}

const mapStateToProps = (state: State) => ({
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = {
    openModal: openModalAction,
};

export const DeathBenefitDetails = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(deathBenefitDetails));
