import { getProperty } from '../../../../claim';
import { collectionReducer } from '../../../../redux/collection-reducer';
import { combineReducersWithDefault } from '../../../../redux/combine-reducers-with-default';
import { State } from '../../../../redux/root-reducer';
import { Nullable } from '../../../../shared-types';
import {
    ADD_ADDITIONAL_INFORMATION_REQUIRED,
    DELETE_ADDITIONAL_INFORMATION_REQUIRED,
    UPDATE_ADDITIONAL_INFORMATION_REQUIRED,
} from './types';

export interface AdditionalInformationRequiredWaiver {
    readonly dateWaived?: Date;
    readonly waivedBy?: string;
    readonly reasonWaived?: string;
}

export interface AdditionalInformationRequiredRecord {
    readonly id?: string;
    readonly detailsOfInformationRequested?: string;
    readonly reasonForRequest?: string;
    readonly dateRequested?: Date;
    readonly dateReceived?: Date;
    readonly assessedBy?: string;
    readonly hasWaiver?: boolean;
    readonly waiver?: Nullable<AdditionalInformationRequiredWaiver>;
    readonly sendToUnderwriter?: boolean;
}

export interface AdditionalInformationRequired {
    readonly records: AdditionalInformationRequiredRecord[];
}

export const defaultRecords: AdditionalInformationRequiredRecord[] = [];
export const defaultAdditionalInformationRequired: AdditionalInformationRequired = {
    records: defaultRecords,
};

const additionalInformationRequiredRecordReducer = collectionReducer<AdditionalInformationRequiredRecord>(
    {
        addAction: ADD_ADDITIONAL_INFORMATION_REQUIRED,
        deleteAction: DELETE_ADDITIONAL_INFORMATION_REQUIRED,
        updateAction: UPDATE_ADDITIONAL_INFORMATION_REQUIRED,
    }
);

export const additionalInformationRequiredReducer = combineReducersWithDefault<
    Nullable<AdditionalInformationRequired>
>(defaultAdditionalInformationRequired, {
    records: additionalInformationRequiredRecordReducer,
});

export function getAdditionalInformationRequired(state: State): AdditionalInformationRequired {
    return getProperty(
        state,
        'additionalInformationRequired',
        defaultAdditionalInformationRequired
    );
}
