import { AddRecordCollectionAction } from '../../redux/collection-reducer';
import { ReinsurerReferal } from '../reducer';
import { ADD_REINSURER_REFERAL_RECORD } from '../types';

export type AddReinsurerReferalRecordAction = AddRecordCollectionAction<ReinsurerReferal>;

export const addReinsurerReferalRecordActionCreator = (
    reinsurerReferalRecord: ReinsurerReferal
): AddReinsurerReferalRecordAction => {
    return {
        type: ADD_REINSURER_REFERAL_RECORD,
        record: reinsurerReferalRecord,
    };
};
