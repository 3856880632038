import React from 'react'; // eslint-disable-line
import { ExpansionPanel } from '../../../../../forms/form-components/expansion-panel/expansion-panel';
import { DeceasedRequirementView } from './deceased-requirements';
import { ClaimantRequirementView } from './claimant-requirements';
import { ExecutorRequirementView } from './executor-requirements';
import { getObjectWarning } from '../../../../../shared-functions';
import { connect } from 'react-redux';
import { State } from '../../../../../redux/root-reducer';
import { getDeathClaim } from '../../../../../claim/reducers/claim-reducers/death-claim-reducer';

interface Props {
    validationErrors: string;
}

const requirementManagementView: React.FunctionComponent<Props> = ({ validationErrors }: Props) => {
    let notifier;
    if (validationErrors && validationErrors.includes('documentsStatus')) {
        notifier = getObjectWarning(validationErrors, validationErrors);
    }
    return (
        <ExpansionPanel name="Requirement management" notifier={notifier}>
            <DeceasedRequirementView />
            <ClaimantRequirementView />
            <ExecutorRequirementView />
        </ExpansionPanel>
    );
};

const mapStateToProps = (state: State) => ({
    validationErrors: getDeathClaim(state).validationErrors,
});

export const RequirementManagementView = connect(mapStateToProps, {})(requirementManagementView);
