import { Grid, Typography } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { LabeledValue } from '../../../../../controls/labeled-value';
import { getCodeDescription, GetVerificationWidget } from '../../../../../shared-functions';
import { BankingDetails } from '../banking-details';
import { ViewBankResponse } from './view-bank-response';

interface BankingProps {
    bankingDetails: BankingDetails;
    beneficiaryId?: string;
}

export const ViewBankingDetails: React.FunctionComponent<BankingProps> = ({
    bankingDetails,
    beneficiaryId,
}: BankingProps) => {
    return (
        <React.Fragment>
            <Grid item xs={12} container justify="flex-start">
                <Typography variant="subtitle1" style={{ fontSize: '20px' }}>
                    Banking details
                </Typography>
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Account holder's initials"
                    value={bankingDetails.accountHoldersInitials}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Account holder's surname/company name"
                    value={bankingDetails.accountHoldersName}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label={`Account holder's ${getIdType(
                        bankingDetails.accountHoldersIdType
                    )} number`}
                    value={bankingDetails.accountHoldersIdNumber}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Bank name"
                    value={getCodeDescription('bankName', bankingDetails.bankName)}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Branch name" value={bankingDetails.bankBranch} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Branch code" value={bankingDetails.branchCode} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Account number" value={bankingDetails.accountNumber} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Special Instructions"
                    value={bankingDetails.specialInstructions}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Account type" value={bankingDetails.accountType} />
            </Grid>
            {bankingDetails.accountHolderRelationship && (
                <Grid item xs={6} lg={3}>
                    <LabeledValue
                        label="Account holder relationship"
                        value={bankingDetails.accountHolderRelationship}
                    />
                </Grid>
            )}
            {bankingDetails.accountHolderRelationshipDescription && (
                <Grid item xs={6} lg={3}>
                    <LabeledValue
                        label="Relationship description"
                        value={bankingDetails.accountHolderRelationshipDescription}
                    />
                </Grid>
            )}
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Account status"
                    value={GetVerificationWidget(bankingDetails.bankAccountStatus)}
                />
            </Grid>
            <ViewBankResponse
                bankResponse={bankingDetails.bankResponse}
                beneficiaryId={beneficiaryId}
            />
        </React.Fragment>
    );
};
function getIdType(idType?: string) {
    if (typeof idType === 'undefined' || idType === null || idType === '') {
        return '';
    } else {
        return idType === 'ID' ? idType : idType?.toLowerCase();
    }
}
