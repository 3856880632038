import { Divider, Grid, Typography } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { FullWidthDatePicker, FullWidthTextField, GenericForm } from '../../../../../forms';
import { FirstNameMask, TelephoneNumberMask } from '../../../../../forms/input-masks';
import { requiredValidator } from '../../../../../forms/validations';
import { State } from '../../../../../redux/root-reducer';
import { OnSubmitSuccessProps } from '../../../../../workflow-navigation';
import { SubmitPoliceReport, submitPoliceReportAction } from '../actions/submit-police-report';
import { getPoliceReport, PoliceReport } from '../police-report-reducer';

interface Props extends OnSubmitSuccessProps {
    submitPoliceReport: SubmitPoliceReport;
    policeReport: PoliceReport;
}

const PoliceReportForm = GenericForm<PoliceReport>();
const policeReportEditModal: React.FunctionComponent<Props> = ({
    policeReport,
    submitPoliceReport,
    ...rest
}: Props) => {
    return (
        <PoliceReportForm onSubmit={submitPoliceReport} initialValues={policeReport} {...rest}>
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={12}>
                    <Typography variant="h5">Update Police Report</Typography>
                    <Divider style={{ marginBottom: '16px', marginTop: '16px' }} />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField label="Case Number" name="caseNumber" />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthDatePicker label="Case Date" name="caseDate" disableFuture />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        label="Police Station"
                        name="policeStation"
                        validate={requiredValidator}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        label="Investigating Officer"
                        name="investigatingOfficer"
                        mask={FirstNameMask}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        label="Telephone Number"
                        name="telephoneNumber"
                        mask={TelephoneNumberMask}
                    />
                </Grid>
            </Grid>
        </PoliceReportForm>
    );
};

const mapStateToProps = (state: State) => ({
    policeReport: getPoliceReport(state),
});

const mapDispatchToProps = {
    submitPoliceReport: submitPoliceReportAction,
};

export const PoliceReportEditModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(policeReportEditModal);
