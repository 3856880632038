import {
    DeathCertification,
    defaultDeathCertification,
    funeralDeathCertificationReducer,
} from '../../../assessment/synopsis/funeral/death-certification/death-certification-reducer';
import {
    FormDetails,
    formDetailsReducer,
} from '../../../assessment/synopsis/funeral/form-details/form-details-reducer';
import { funeralRequirementsReducer } from '../../../assessment/synopsis/funeral/manage-requirements/reducer';
import {
    NoticeOfDeath,
    noticeOfDeathReducer,
} from '../../../assessment/synopsis/common/notice-of-death/notice-of-death-reducer';
import {
    PoliceReport,
    policeReportReducer,
} from '../../../assessment/synopsis/common/police-report/police-report-reducer';
import {
    LifeAssuredDetails,
    lifeAssuredDetailsReducer,
} from '../../../life-assured-details/reducer';
import { combineReducersWithDefault } from '../../../redux/combine-reducers-with-default';
import { Nullable } from '../../../shared-types';
import { BaseClaim, baseClaimReducers, initialBaseClaim } from './base-claim-reducer';
import {
    funeralAssessmentDetailsReducer,
    AssessmentDetails,
} from '../../../assessment/decisions/funeral/funeral-assessment-details-reducer';
import {
    Comments,
    commentsReducer,
    defaultComments,
} from '../../../assessment/synopsis/funeral/comments/reducer';

export interface FuneralClaim extends BaseClaim {
    readonly lifeAssuredDetails?: Nullable<LifeAssuredDetails>;
    readonly deathCertification: DeathCertification;
    readonly formDetails?: Nullable<FormDetails>;
    readonly policeReport?: Nullable<PoliceReport>;
    readonly noticeOfDeath?: Nullable<NoticeOfDeath>;
    readonly comments: Comments[];
    readonly assessmentDetails: AssessmentDetails[];
}

export const initialFuneralClaim: FuneralClaim = {
    ...initialBaseClaim,
    deathCertification: defaultDeathCertification,
    comments: defaultComments,
    assessmentDetails: [],
};

export const funeralClaimReducers = {
    ...baseClaimReducers,
    lifeAssuredDetails: lifeAssuredDetailsReducer,
    deathCertification: funeralDeathCertificationReducer,
    formDetails: formDetailsReducer,
    policeReport: policeReportReducer,
    noticeOfDeath: noticeOfDeathReducer,
    requirements: funeralRequirementsReducer,
    comments: commentsReducer,
    assessmentDetails: funeralAssessmentDetailsReducer,
};

export const funeralClaimReducer = combineReducersWithDefault<FuneralClaim>(
    initialFuneralClaim,
    funeralClaimReducers
);
