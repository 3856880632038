import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import {
    ChangeFieldSet,
    changeRecordFromAddChangeSet,
    changeRecordFromChangeSet,
    changeRecordFromDeleteChangeSet,
} from '../claim-audit-trail-functions';
import { addAuditTrailDetailsView } from '../common-views/claim-audit-trail-field-details';
import { mapSectionView } from '../common-views/claim-audit-trail-wrapper';

interface Props {
    changeSet: Record<string, any>;
    section: string;
}

const auditTrailListsView: React.FunctionComponent<Props> = ({ changeSet, section }: Props) => {
    const returnView: React.ReactNode[] = [];
    const { changeRecordKey, recordSection } = getSectionVariables(section);

    if (Array.isArray(changeSet) && changeSet[0] === null) {
        let recordKey = '';
        let RecordKey = '';
        let changeRecord = null;
        const changeSection = section.toLowerCase();
        if (changeSection === 'reinsuranceinputdetails') {
            recordKey = 'reinsurerReferrals';
            RecordKey = 'ReinsurerReferrals';
        } else if (changeSection === 'informationrecordsummary') {
            recordKey = 'records';
            RecordKey = 'Records';
        } else if (changeSection === 'doctordetails') {
            recordKey = 'doctors';
            RecordKey = 'Doctors';
        } else if (changeSection === 'informationreceivedsummary') {
            recordKey = 'documents';
            RecordKey = 'Documents';
        } else {
            recordKey = 'records';
            RecordKey = 'Records';
        }
        changeRecord = changeSet[1][recordKey] || changeSet[1][RecordKey];
        addAuditTrailDetailsView(returnView, changeRecordFromAddChangeSet(changeRecord[0]), [
            `${recordSection} ${1}`,
        ]);
    } else {
        Object.keys(changeSet).forEach(key => {
            const changeRecord = changeSet[key];
            if (key.toLowerCase() === changeRecordKey.toLowerCase()) {
                getViewFromList(changeRecord, returnView, recordSection);
            }
        });
    }

    return mapSectionView(returnView);
};

function getSectionVariables(section: string) {
    const changeSection = section.toLowerCase();
    let changeRecordKey = '';
    let recordSection = 'document';
    if (changeSection === 'reinsuranceinputdetails') {
        changeRecordKey = 'reinsurerReferrals';
        recordSection = 'Reinsurance Input Details';
    } else if (changeSection === 'informationreceivedsummary') {
        changeRecordKey = 'documents';
    } else if (changeSection === 'informationrecordsummary') {
        changeRecordKey = 'records';
        recordSection = 'Record';
    } else if (changeSection === 'doctordetails') {
        changeRecordKey = 'doctors';
        recordSection = 'Doctor';
    } else if (section === 'claimEventDetails') {
        recordSection = 'note';
        changeRecordKey = 'claimNotes';
    } else {
        changeRecordKey = 'records';
    }
    return { changeRecordKey, recordSection };
}

export function getViewFromList(
    changeRecord: Record<string, any>,
    returnView: React.ReactNode[],
    recordSection: string,
    subSection: string[] = []
) {
    Object.keys(changeRecord).forEach(recordKey => {
        let auditTrail: ChangeFieldSet[] = [];
        let documentNumber = 0;
        if (!isNaN(Number(recordKey)) && changeRecord[recordKey]) {
            documentNumber = Number(recordKey) + 1;
            if (Array.isArray(changeRecord[recordKey])) {
                if (changeRecord[recordKey][0]) {
                    auditTrail = changeRecordFromAddChangeSet(changeRecord[recordKey][0]);
                }
            } else {
                auditTrail = changeRecordFromChangeSet(changeRecord[recordKey]);
            }
        } else {
            const deleteKey = recordKey.split('_');
            if (!isNaN(Number(deleteKey[1]))) {
                documentNumber = Number(deleteKey[1]) + 1;
                auditTrail = changeRecordFromDeleteChangeSet(changeRecord[recordKey][0]);
            }
        }
        if (auditTrail && auditTrail.length > 0) {
            addAuditTrailDetailsView(returnView, auditTrail, [
                ...subSection,
                `${recordSection} ${documentNumber}`,
            ]);
        }
    });
}

export const AuditTrailListsView = withStyles(styles)(auditTrailListsView);
