import { Button, Grid, Paper, withStyles } from '@material-ui/core';
import { FormApi } from 'final-form';
import React from 'react'; // eslint-disable-line
import { FormSpy } from 'react-final-form';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { GenericForm } from '../../forms';
import { State } from '../../redux/root-reducer';
import { OnSubmitSuccessProps, submitFormAction } from '../../workflow-navigation';
import { CloseModal, closeModalAction, RecordView } from '../actions';
import { getRecordCount } from '../reducer';
import styles, { StyledComponent } from '../styles';

interface ComponentState {
    readonly recordCount: number;
    readonly initialValues?: any;
}

interface InnerProps<TValues> extends ComponentState, StyledComponent {
    recordDetailView: React.ReactNode | RecordView;
    addRecord: (
        values: TValues
    ) => void | Promise<void> | Record<string, any> | Promise<Record<string, any>>;
    maxRecords?: number;
    hideAddAnother?: boolean;
    isSkipClose?: boolean;
    closeModal: CloseModal;
    hideSave?: boolean;
    saveAndAddAnother?: (
        values: TValues
    ) => void | Promise<void> | Record<string, any> | Promise<Record<string, any>>;
    isUseAltFunc?: boolean;
}

interface Props<TValues> extends OnSubmitSuccessProps, InnerProps<TValues> {
    blankRecord?: TValues;
    validate?: (values: TValues) => Record<string, any> | Promise<Record<string, any>>;
}

interface FormProps<TValues> {
    readonly form: FormApi<TValues>;
}

type InnerFormProps<TValues> = InnerProps<TValues> & FormProps<TValues>;

class AddFormModalFormWrapper<TValues> extends React.Component<Props<TValues>, ComponentState> {
    public constructor(props: Props<TValues>) {
        super(props);
        this.state = {
            recordCount: this.props.recordCount,
            initialValues: this.props.blankRecord,
        };
    }

    public render() {
        const Form = GenericForm<TValues>();
        const {
            validate,
            classes,
            recordDetailView,
            addRecord,
            hideAddAnother,
            isSkipClose,
            hideSave,
            maxRecords,
            closeModal,
            isUseAltFunc,
            saveAndAddAnother,
            ...rest
        }: Props<TValues> = this.props;
        const recordCount = this.state.recordCount;
        const initialValues = this.state.initialValues;
        return (
            <Form
                initialValues={initialValues}
                onSubmit={this.onSubmit}
                validate={validate}
                {...rest}
            >
                {() =>
                    FormSpy<TValues>({
                        render: ({ form }) =>
                            this.addFormModal({
                                classes,
                                recordDetailView,
                                addRecord,
                                maxRecords,
                                hideAddAnother,
                                hideSave,
                                isSkipClose,
                                recordCount,
                                closeModal,
                                form,
                                initialValues,
                                isUseAltFunc,
                                saveAndAddAnother,
                            }),
                    })
                }
            </Form>
        );
    }

    private addFormModal: React.FunctionComponent<InnerFormProps<TValues>> = ({
        classes,
        recordDetailView,
        addRecord,
        maxRecords,
        hideAddAnother,
        hideSave,
        isSkipClose,
        recordCount,
        closeModal,
        form,
        initialValues,
        isUseAltFunc,
        saveAndAddAnother,
    }: InnerFormProps<TValues>) => {
        const setSubmitFunction = (
            func: (
                record: TValues
            ) => void | Promise<void> | Record<string, any> | Promise<Record<string, any>>
        ) => {
            this.submitFunction = func;
            return undefined;
        };

        const save = async (record: TValues) => {
            const result = await addRecord(record);
            return result;
        };

        const saveAndClose = async (record: TValues) => {
            const result = await addRecord(record);
            if ((!result || Object.keys(result).length === 0) && !isSkipClose) {
                closeModal();
            }
            return result;
        };

        const saveAndAddAction = async (record: TValues) => {
            const result = isUseAltFunc
                ? saveAndAddAnother && (await saveAndAddAnother(record))
                : await addRecord(record);

            if (!result || Object.keys(result).length === 0) {
                const id = uuid();
                this.setState({
                    recordCount: ++recordCount,
                    initialValues: { ...initialValues, id: id, uniqueId: id },
                });
                setTimeout(form.reset, 0);
            }
            return result;
        };

        const detailView =
            typeof recordDetailView === 'function'
                ? (recordDetailView as RecordView)()
                : recordDetailView;

        return (
            <Paper className={classes.paper}>
                {detailView}
                <Grid container spacing={0} justify="flex-end">
                    {!hideSave && (
                        <Button
                            id="Save"
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={() => {
                                setSubmitFunction(save);
                                form.submit();
                            }}
                        >
                            Save
                        </Button>
                    )}
                    <Button
                        id="SaveClose"
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={() => {
                            setSubmitFunction(saveAndClose);
                            form.submit();
                        }}
                    >
                        {'Save & Close'}
                    </Button>
                    {!hideAddAnother && (
                        <Button
                            id="SaveAddAnother"
                            variant="contained"
                            color="secondary"
                            disabled={!!maxRecords && recordCount >= maxRecords - 1}
                            className={classes.button}
                            onClick={() => {
                                setSubmitFunction(saveAndAddAction);
                                form.submit();
                            }}
                        >
                            {'Save & Add Another'}
                        </Button>
                    )}
                    <Button
                        id="Cancel"
                        variant="contained"
                        className={classes.button}
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Paper>
        );
    };

    private submitFunction: (
        values: TValues
    ) => void | Promise<void> | Record<string, any> | Promise<Record<string, any>> = () => {};

    private onSubmit = (values?: TValues) => {
        if (values) {
            return this.submitFunction(values);
        }
    };
}

const mapStateToProps = (state: State) => ({
    recordCount: getRecordCount(state),
});

const mapDispatchToProps = {
    closeModal: closeModalAction,
    submitForm: submitFormAction,
};

export function AddFormModal<TValues>() {
    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        withStyles(styles)(
            AddFormModalFormWrapper as new (
                props: Props<TValues>
            ) => AddFormModalFormWrapper<TValues>
        )
    );
}
