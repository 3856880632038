import React from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import { getDisplayDate } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { ContractOwnerOrganisation } from '../../../../../life-assured-details/reducer';

type InnerProps = StyledComponent & {
    policyOwnerOrganizationDetails: ContractOwnerOrganisation;
};

const organizationDetailsView: React.FunctionComponent<InnerProps> = ({
    policyOwnerOrganizationDetails,
}: InnerProps) => {
    return (
        <React.Fragment>
            <Grid item xs={6} lg={2}>
                <LabeledValue label="Company Name" value={policyOwnerOrganizationDetails.name} />
            </Grid>
            <Grid item xs={6} lg={2}>
                <LabeledValue
                    label="Company Registration Number"
                    value={policyOwnerOrganizationDetails.registrationNumber}
                />
            </Grid>
            <Grid item xs={6} lg={2}>
                <LabeledValue label="Company Type" value={policyOwnerOrganizationDetails.suffix} />
            </Grid>
            <Grid item xs={6} lg={2}>
                <LabeledValue
                    label="Company Registration Date"
                    value={getDisplayDate(policyOwnerOrganizationDetails.registrationDate)}
                />
            </Grid>
        </React.Fragment>
    );
};

export const OrganizationDetailsView = withStyles(styles)(organizationDetailsView);
