import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { GenericForm } from '../../forms';
import { State } from '../../redux/root-reducer';
import styles, { StyledComponent } from '../../styles/styles';
import { OnSubmitSuccessProps } from '../../workflow-navigation';
import { AddDoctor, addDoctorAction } from '../actions/add-doctor';
import { DeleteDoctor, deleteDoctorAction } from '../actions/delete-doctor';
import { Submit, submitAction } from '../actions/submit';
import { UpdateDoctor, updateDoctorAction } from '../actions/update-doctor';
import { DoctorsDetails, getDoctorDetails } from '../reducer';
import { DoctorsDetailsGridView } from './doctors-details-grid';

interface Props extends OnSubmitSuccessProps, StyledComponent {
    doctorsDetails: DoctorsDetails;
    addDoctor: AddDoctor;
    updateDoctor: UpdateDoctor;
    deleteDoctor: DeleteDoctor;
    submit: Submit;
}

const maxRecords = 10;
const DoctorsDetailsForm = GenericForm<DoctorsDetails>();
const doctorsDetailsView: React.FunctionComponent<Props> = ({
    doctorsDetails,
    addDoctor,
    updateDoctor,
    deleteDoctor,
    submit,
    ...rest
}: Props) => {
    return (
        <DoctorsDetailsForm
            onSubmit={() => submit(doctorsDetails)}
            initialValues={doctorsDetails}
            {...rest}
        >
            <DoctorsDetailsGridView
                formHeading="Doctors' Details"
                maxRecords={maxRecords}
                records={doctorsDetails.doctors}
                addRecord={record => {
                    addDoctor(record);
                }}
                updateRecord={(record, index) => {
                    updateDoctor(record, index);
                }}
                deleteRecord={deleteDoctor}
            />
        </DoctorsDetailsForm>
    );
};

const mapStateToProps = (state: State) => ({
    doctorsDetails: getDoctorDetails(state),
});

const mapDispatchToProps = {
    addDoctor: addDoctorAction,
    updateDoctor: updateDoctorAction,
    deleteDoctor: deleteDoctorAction,
    submit: submitAction,
};

export const DoctorsDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(doctorsDetailsView));
