import { Action, Reducer } from 'redux';
import { Nullable } from '../../../../shared-types';
import { State } from '../../../../redux/root-reducer';
import { getProperty } from '../../../../claim';

export interface PoliceReport {
    readonly caseNumber?: string;
    readonly policeStation?: string;
    readonly investigatingOfficer?: string;
    readonly telephoneNumber?: string;
    readonly caseDate?: Date;
}

export const defaultPoliceReport: PoliceReport = {};
export const policeReportReducer: Reducer = (
    state: Nullable<PoliceReport> = null,
    action: Action
) => {
    return state;
};

export function getPoliceReport(state: State) {
    return getProperty(state, 'policeReport', defaultPoliceReport);
}
