import { Button, Grid, Tooltip, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { getClaim } from '../../../../../../claim';
import { State } from '../../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import {
    ClaimantRequirements,
    Correspondence,
    getCorrespondenceFromClaim,
    getRequirementsFromClaim,
} from '../../../../common/requirements-management/reducer';
import { FuneralRequirements } from '../../reducer';
import { OpenAddModal, openAddModalAction } from '../../../../../../form-modal/actions';
import { CommunicationEditView } from './communications-edit-modal';
import { CommunicationsFormModalView } from './communications-form-view';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import { getDisplayDate, getDisplayTime } from '../../../../../../shared-functions';
import { Email } from '@material-ui/icons';
import { ButtonWrapper } from '../../../../../../forms';
import { ResultUnderInvestigationContent } from './result-under-investigation-content';
interface DispatchProps {
    openAddModal: OpenAddModal;
    policyNumber?: string;
}
interface StateProps {
    claimantRequirements: ClaimantRequirements[];
    correspondence: Correspondence;
}

type Props = StyledComponent & DispatchProps & StateProps;
const emailCorrespondenceInvestigationView: React.FunctionComponent<Props> = ({
    classes,
    claimantRequirements,
    correspondence,
    openAddModal,
    policyNumber,
}: Props) => {
    const openCommunicationsModal = () => {
        openAddModal({
            modalContent: (
                <CommunicationsFormModalView
                    title="Notify Claimant"
                    previewScreen={() => <ResultUnderInvestigationContent />}
                    formView={() => (
                        <CommunicationEditView
                            isShowDocumentRequirements={false}
                            isUnderInvestigation={true}
                            policyNumber={policyNumber}
                        />
                    )}
                />
            ),
            modalData: {},
        });
    };

    return (
        <>
            <ButtonWrapper>
                <Grid
                    container
                    item
                    xs={12}
                    justify="flex-end"
                    style={{ margin: '8px auto 8px auto' }}
                >
                    <Tooltip title="Notify Claimant">
                        <Button
                            id="notifyClaimant"
                            onClick={openCommunicationsModal}
                            style={{ textTransform: 'none', color: 'black' }}
                        >
                            <Email style={{ marginRight: '4px', color: '#000' }} />
                            Notify Claimant
                        </Button>
                    </Tooltip>
                </Grid>
            </ButtonWrapper>
            <Grid item xs={12} lg={12}>
                <Grid item xs={12} className={classes.marginTop} container justify="flex-start">
                    {correspondence &&
                        correspondence.allocation &&
                        correspondence.allocation.map((allocationItem, index) => (
                            <React.Fragment key={index}>
                                <Grid item md={4} xs={12}>
                                    <LabeledValue
                                        label="Claimant Name"
                                        value={allocationItem.claimantSurname}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <LabeledValue
                                        label="Email Address sent to"
                                        value={allocationItem.to}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <LabeledValue
                                        label="Additional Recipients"
                                        value={allocationItem.ccs}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <LabeledValue
                                        label="Latest Email sent date"
                                        value={getDisplayDate(allocationItem.date)}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <LabeledValue
                                        label="Time email sent"
                                        value={getDisplayTime(allocationItem.date)}
                                    />
                                </Grid>
                            </React.Fragment>
                        ))}
                </Grid>
            </Grid>
        </>
    );
};

const mapStateToProps = (state: State) => ({
    claimantRequirements:
        (getRequirementsFromClaim(getClaim(state)) as FuneralRequirements).claimants || [],
    correspondence: (getCorrespondenceFromClaim(getClaim(state)) as Correspondence) || {},
});

const mapDispatchToProps = {
    openAddModal: openAddModalAction,
};

export const EmailCorrespondenceInvestigationView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(emailCorrespondenceInvestigationView));
