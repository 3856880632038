import { Claim, claimSubmit } from '../../claim';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { getLifeAssuredDetailsFromClaim, PersonalDetails, MedicalAidDetails } from '../reducer';
import { getPersonalDetails, getMedicalAidDetails, validate } from './submit';
import { getDeathClaimEventDetailsFromClaim } from '../../assessment/synopsis/death/claim-event-details/reducer';
import {
    getFormDetailsMedicalAidDetails,
    getFormDetailsFromClaim,
    getFormDetailsListFromClaim,
} from '../../assessment/synopsis/living-lifestyle/form-details/form-details-reducer';

export interface ExtendedPersonalDetails extends PersonalDetails {
    dateOfDeath?: Date;
}
interface UpdatePersonalDetailsModel {
    personalDetails: ExtendedPersonalDetails;
    medicalAidDetails: MedicalAidDetails;
}

type UpdatePersonalDetailsReturn = Promise<any>;
export type UpdatePersonalDetails = (
    updatePersonalDetailsValues: UpdatePersonalDetailsModel
) => UpdatePersonalDetailsReturn;
type UpdatePersonalDetailsAction = (
    updatePersonalDetailsValues: UpdatePersonalDetailsModel
) => ReduxThunkAction<UpdatePersonalDetailsReturn>;

export const updatePersonalDetailsAction: UpdatePersonalDetailsAction = (
    updatePersonalDetailsValues: UpdatePersonalDetailsModel
) => {
    return async (dispatch, getState) => {
        return claimSubmit(
            dispatch,
            getState,
            getUpdatedClaimPersonalDetails(updatePersonalDetailsValues),
            validate
        );
    };
};

function getUpdatedClaimPersonalDetails(
    updatePersonalDetailsValues: UpdatePersonalDetailsModel
): (claim: Claim) => Claim {
    return (claim: Claim) => {
        const currentLifeAssuredDetails = getLifeAssuredDetailsFromClaim(claim);
        const currentFormDetails = getFormDetailsFromClaim(claim);
        const formDetailsList = getFormDetailsListFromClaim(claim);
        const updatedDateOfEvent = updatePersonalDetailsValues.personalDetails.dateOfDeath;
        if (claim.claimType === 'Death') {
            const claimEventDetails = getDeathClaimEventDetailsFromClaim(claim);

            return {
                ...claim,
                lifeAssuredDetails: {
                    ...currentLifeAssuredDetails,
                    personalDetails: {
                        ...getPersonalDetails(updatePersonalDetailsValues.personalDetails),
                    },
                    medicalAidDetails: getMedicalAidDetails(
                        updatePersonalDetailsValues.medicalAidDetails
                    ),
                },
                claimEventDetails: {
                    ...claimEventDetails,
                    deathClaimDetails: {
                        ...claimEventDetails.deathClaimDetails,
                        dateOfEvent: updatedDateOfEvent,
                    },
                },
            };
        }

        if (
            claim.claimType === 'Disability' ||
            claim.claimType === 'TerminalIllness' ||
            claim.claimType === 'Dread'
        ) {
            return {
                ...claim,
                lifeAssuredDetails: {
                    ...currentLifeAssuredDetails,
                    personalDetails: {
                        ...getPersonalDetails(updatePersonalDetailsValues.personalDetails),
                    },
                    medicalAidDetails: getMedicalAidDetails(
                        updatePersonalDetailsValues.medicalAidDetails
                    ),
                },
                formDetails: {
                    ...currentFormDetails,
                    lifeAssured: {
                        ...currentFormDetails.lifeAssured,
                        medicalAidDetails: {
                            hasMedicalAid:
                                currentFormDetails.lifeAssured?.medicalAidDetails?.hasMedicalAid,
                            ...getFormDetailsMedicalAidDetails(
                                updatePersonalDetailsValues.medicalAidDetails
                            ),
                        },
                    },
                },
                ...formDetailsList,
            };
        }

        return {
            ...claim,
            lifeAssuredDetails: {
                ...currentLifeAssuredDetails,
                personalDetails: {
                    ...getPersonalDetails(updatePersonalDetailsValues.personalDetails),
                },
                medicalAidDetails: getMedicalAidDetails(
                    updatePersonalDetailsValues.medicalAidDetails
                ),
            },
        };
    };
}
