// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react';
import { withStyles, Grid, Typography, Paper, Tooltip, Button } from '@material-ui/core';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { Edit } from '@material-ui/icons';
import { FormDetails, getFormDetails } from '../form-details-reducer';
import { connect } from 'react-redux';
import { State } from '../../../../../redux/root-reducer';
import { OpenModal, openModalAction } from '../../../../../form-modal/actions';
import { editLifeAssureDetails } from './life-assured-edit';
import { UpdateFormDetails, updateFormDetailsAction } from '../actions/update-form-details';

interface BaseProps {
    formDetails?: FormDetails;
    updateFormDetails?: UpdateFormDetails;
    disableEditButton?: boolean;
}
interface Props extends BaseProps, StyledComponent {
    readonly openModal: OpenModal;
}

const otherDetailsView: React.FunctionComponent<Props> = ({
    classes,
    formDetails,
    updateFormDetails,
    openModal,
    disableEditButton,
}: Props) => {
    const edit = () => {
        const details = formDetails || {};
        editLifeAssureDetails(openModal, details, 6, updateFormDetails);
    };

    const otherDetails = formDetails?.lifeAssured?.otherDetails;

    return (
        <React.Fragment>
            <Grid container justify="space-between" className={classes.marginTop2}>
                <Grid container item xs={12} alignItems="center">
                    <Typography variant="h6" className={classes.heading}>
                        Other Details
                    </Typography>
                </Grid>
            </Grid>
            <Paper className={classes.paper} elevation={3}>
                <Grid container item xs={12} alignItems="center" justify="flex-end">
                    <Tooltip title="Edit Other Details">
                        <Button
                            id="EditPersonalDetails"
                            color="secondary"
                            onClick={edit}
                            disabled={!disableEditButton}
                        >
                            <Edit />
                        </Button>
                    </Tooltip>
                </Grid>
                <Grid container spacing={2}>
                    {/* Column One Start */}
                    <Grid container item xs={12} sm={6} direction="column">
                        <Grid item>
                            <LabeledValue
                                label="What is your highest qualification?"
                                value={otherDetails?.highestQualification}
                            />
                        </Grid>
                        <Grid item>
                            <LabeledValue
                                label="Have you ever been declared insolvent or are sequestration hearing proceedings, pending or completed?"
                                value={otherDetails?.hasInsolvency}
                            />
                        </Grid>
                        <Grid item>
                            <LabeledValue
                                label="Do you consume alcohol?"
                                value={otherDetails?.consumesAlcohol}
                            />
                        </Grid>
                        {otherDetails?.consumesAlcohol === 'Yes' && (
                            <>
                                <Grid item>
                                    <LabeledValue
                                        label="How many spirits and tots per week?"
                                        value={otherDetails?.totCount}
                                    />
                                </Grid>
                                <Grid item>
                                    <LabeledValue
                                        label="How many wines in a glass per week?"
                                        value={otherDetails?.wineGlassCount}
                                    />
                                </Grid>
                                <Grid item>
                                    <LabeledValue
                                        label="How many beers/ciders in a can/bottle per week?"
                                        value={otherDetails?.beerCanOrCider}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item>
                            <LabeledValue
                                label="Have you ever received advice to reduce or discontinue alcohol consumption, or have you ever been charged with drunken driving?"
                                value={otherDetails?.receivedAdviceToReduceAlcohol}
                            />
                        </Grid>
                        {otherDetails?.receivedAdviceToReduceAlcohol === 'Yes' && (
                            <Grid item>
                                <LabeledValue
                                    label="Please provide full details"
                                    value={otherDetails?.alcoholAdviceDetails}
                                />
                            </Grid>
                        )}
                    </Grid>

                    {/* Column Two Start */}
                    <Grid container item xs={12} sm={6} direction="column">
                        <Grid item>
                            <LabeledValue
                                label="Do you currently practice any avocation/s e.g scuba diving, flying, etc"
                                value={otherDetails?.practiceAvocation}
                            />
                        </Grid>
                        {otherDetails?.practiceAvocation === 'Yes' && (
                            <Grid item>
                                <LabeledValue
                                    label="Please provide full details"
                                    value={otherDetails?.practiceAvocationDetails}
                                />
                            </Grid>
                        )}
                        <Grid item>
                            <LabeledValue
                                label="Please provide the average number of hours that you were working per week, for three months prior to the date of the claim event"
                                value={otherDetails?.hoursWorkedPerWeek}
                            />
                        </Grid>
                        <Grid item>
                            <LabeledValue
                                label="Were you partially or fully retired at the date of the claim event?"
                                value={otherDetails?.wasRetired}
                            />
                        </Grid>
                        {otherDetails?.wasRetired === 'Yes' && (
                            <Grid item>
                                <LabeledValue
                                    label="Please provide full details"
                                    value={otherDetails?.retirementFullDetails}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

const mapStateToProps = (state: State, ownProps: BaseProps) => ({
    formDetails: ownProps.formDetails || getFormDetails(state),
    disableEditButton: ownProps.disableEditButton || false,
});

const mapDispatchToProps = {
    openModal: openModalAction,
    updateFormDetails: updateFormDetailsAction,
};

export const OtherDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(otherDetailsView));
