import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { State } from '../../redux/root-reducer';
import styles from '../../styles/styles';
import { mapSectionView } from '../common-views/claim-audit-trail-wrapper';
import {
    changeRecordFromAddChangeSet,
    changeRecordFromChangeSet,
    changeRecordFromDeleteChangeSet,
    ChangeFieldSet,
} from '../claim-audit-trail-functions';
import { addAuditTrailDetailsView } from '../common-views/claim-audit-trail-field-details';
import { Dependant, getDependants } from '../../assessment/synopsis/ra-death/dependants/reducer';
import { getDependantName } from '../../shared-functions';
import { capitalCase } from 'change-case';
import { FieldNames } from '../../codes/claim-documents-enums';
interface Props {
    changeSet: Record<string, any>;
    dependents: Dependant[];
}

const liquidAndDistributionAudittrail: React.FunctionComponent<Props> = ({
    changeSet,
    dependents,
}: Props) => {
    const returnView: React.ReactNode[] = [];
    let sectionName: string[] = [];
    let depandantName;
    let record: ChangeFieldSet[] = [];
    if (changeSet != null) {
        Object.keys(changeSet).forEach(key => {
            const changeRecord = changeSet[key];
            if (changeRecord != null) {
                Object.keys(changeRecord).forEach(value => {
                    if (Array.isArray(changeRecord[value])) {
                        const changeDetails = {
                            [value]: [changeRecord[value][0], changeRecord[value][1]],
                        };
                        if (changeDetails.hasOwnProperty('dependent')) {
                            const previoseName = getDependantName(
                                changeRecord[value][0],
                                dependents
                            );
                            const updatedName = getDependantName(
                                changeRecord[value][1],
                                dependents
                            );
                            const changeDetails = {
                                [value]: [previoseName, updatedName],
                            };
                            record = changeRecordFromChangeSet(changeDetails);
                        } else {
                            record = changeRecordFromChangeSet(changeDetails);
                        }

                        sectionName = [
                            `${capitalCase(`Edited distribution`)}`,
                            FieldNames.LiquidAndDistribution,
                        ];
                        if (record.length > 0) {
                            addAuditTrailDetailsView(returnView, record, sectionName);
                        }
                    }
                });
                for (const index in changeRecord) {
                    if (
                        !Array.isArray(changeRecord[index]) &&
                        typeof changeRecord[index] != 'string' &&
                        changeRecord[1] !== 0
                    ) {
                        depandantName = getDependantName(changeRecord[index].dependent, dependents);
                        delete changeRecord[index]['dependent'];
                        sectionName = [
                            `${capitalCase(`Added distribution for`)}  ${depandantName}`,
                            FieldNames.LiquidAndDistribution,
                        ];
                        record = changeRecordFromAddChangeSet(changeRecord[index]);
                        if (record.length > 0) {
                            addAuditTrailDetailsView(returnView, record, sectionName);
                        }
                    }
                    if (
                        !Array.isArray(changeRecord[index]) &&
                        typeof changeRecord[index] != 'string' &&
                        changeRecord[1] === 0
                    ) {
                        depandantName = getDependantName(changeRecord[index].dependent, dependents);
                        delete changeRecord[index]['dependent'];
                        sectionName = [
                            `${capitalCase(`Deleted distribution for`)}  ${depandantName}`,
                            FieldNames.LiquidAndDistribution,
                        ];
                        record = changeRecordFromDeleteChangeSet(changeRecord[index]);
                        if (record.length > 0) {
                            addAuditTrailDetailsView(returnView, record, sectionName);
                        }
                    }
                }
            }
        });
    }
    return mapSectionView(returnView);
};

const mapStateToProps = (state: State) => ({
    dependents: getDependants(state),
});

const mapDispatchToProps = {};

export const LiquidAndDistributionAuditTrail = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(liquidAndDistributionAudittrail));
