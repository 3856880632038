import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect, MapDispatchToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ExpansionPanel } from '../../../../../forms/form-components/expansion-panel/expansion-panel';
import { State } from '../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../styles/styles';
import {
    AddDoctorsConsultation,
    addPreviousDoctorsConsultationThunk,
    DeleteDoctorsConsultation,
    deletePreviousDoctorsConsultationThunk,
    UpdateDoctorsConsultation,
    updatePreviousDoctorsConsultationThunk,
} from '../action/previous-doctors-consultation';
import {
    AddPreviousDoctor,
    addPreviousDoctorThunk,
    DeletePreviousDoctor,
    deletePreviousDoctorThunk,
    UpdatePreviousDoctor,
    updatePreviousDoctorThunk,
} from '../action/previous-medical-doctor';
import { Doctors, getMedicalHistory } from '../reducer';
import { MedicalConsultationView } from './medical-consultation-view';

interface DispatchProps {
    addDoctor: AddPreviousDoctor;
    updateDoctor: UpdatePreviousDoctor;
    deleteDoctor: DeletePreviousDoctor;
    addConsultation: AddDoctorsConsultation;
    updateConsultation: UpdateDoctorsConsultation;
    deleteConsultation: DeleteDoctorsConsultation;
}

interface OwnProps {}

type Props = StyledComponent &
    DispatchProps &
    OwnProps & {
        doctorsDetails: Doctors[];
    };

const previousMedicalConsultationView: React.FunctionComponent<Props> = ({
    addDoctor,
    doctorsDetails,
    updateDoctor,
    deleteDoctor,
    addConsultation,
    updateConsultation,
    deleteConsultation,
}: Props) => {
    return (
        <ExpansionPanel name="Previous Medical Consultations" style={{ marginBottom: '14px' }}>
            <MedicalConsultationView
                doctorsDetails={doctorsDetails}
                addDoctor={addDoctor}
                updateDoctor={updateDoctor}
                deleteDoctor={deleteDoctor}
                addConsultation={addConsultation}
                updateConsultation={updateConsultation}
                deleteConsultation={deleteConsultation}
                prefix="Previous"
            />
        </ExpansionPanel>
    );
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    addDoctor: record => dispatch(addPreviousDoctorThunk(record)),
    updateDoctor: (index, doctors) => dispatch(updatePreviousDoctorThunk(index, doctors)),
    deleteDoctor: index => dispatch(deletePreviousDoctorThunk(index)),
    addConsultation: (record, doctors, index) =>
        dispatch(addPreviousDoctorsConsultationThunk(record, doctors, index)),
    updateConsultation: (consultation, doctors, visitIndex, doctorsIndex) =>
        dispatch(
            updatePreviousDoctorsConsultationThunk(consultation, doctors, visitIndex, doctorsIndex)
        ),
    deleteConsultation: (doctors, consultIndex, doctorsIndex) =>
        dispatch(deletePreviousDoctorsConsultationThunk(doctors, consultIndex, doctorsIndex)),
});

const mapStateToProps = (state: State) => ({
    doctorsDetails: getMedicalHistory(state).previousMedicalHistory,
});

export const PreviousMedicalConsultationView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(previousMedicalConsultationView));
