import React from 'react'; // eslint-disable-line

import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import {
    Condition,
    FormHeading,
    FullWidthCheckBoxField,
    True,
    GenericForm,
    FormErrors,
    WhenFieldChanges,
} from '../../../../../../forms';
import {
    AddressDetails,
    AddressDetailsSubForm,
} from '../../../../../../forms/sub-forms/address-details';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import {
    UpdateAddressDetails,
    updateAddressDetailsAction,
} from '../../actions/update-address-details';
import { connect } from 'react-redux';
import { RADeathClaim } from '../../../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { getClaim } from '../../../../../../claim';
import { State } from '../../../../../../redux/root-reducer';

interface Props extends StyledComponent {
    sameAsResidential?: boolean;
    residentialAddress?: AddressDetails;
    postalAddress?: AddressDetails;
    businessAddress?: AddressDetails;
    updateAddressDetails: UpdateAddressDetails;
}

const AddressDetailsForm = GenericForm<{
    sameAsResidential?: boolean;
    residentialAddress?: AddressDetails;
    postalAddress?: AddressDetails;
    businessAddress?: AddressDetails;
}>();

const addressDetailsEdit: React.FunctionComponent<Props> = ({
    classes,
    sameAsResidential,
    residentialAddress,
    postalAddress,
    businessAddress,
    updateAddressDetails,
    ...rest
}: Props) => {
    return (
        <AddressDetailsForm
            onSubmit={updateAddressDetails}
            initialValues={{
                sameAsResidential,
                residentialAddress,
                postalAddress,
                businessAddress,
            }}
            {...rest}
        >
            <AddressDetailsView classes={classes} />
            <FormErrors />
        </AddressDetailsForm>
    );
};

const AddressDetailsView: React.FunctionComponent<StyledComponent> = ({
    classes,
}: StyledComponent) => {
    return (
        <Paper className={classes.paper}>
            <FormHeading text="Address Details" />
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Typography className={classes.marginTop} variant="subtitle2">
                        Residential Address
                    </Typography>
                </Grid>
                <AddressDetailsSubForm
                    addressPropertyName="residentialAddress"
                    addressType="residential"
                />
                <Grid item xs={12}>
                    <Typography className={classes.marginTop} variant="subtitle2">
                        Postal Address
                    </Typography>
                </Grid>
                <WhenFieldChanges
                    field="residentialAddress.addressType"
                    set="sameAsResidential"
                    to={false}
                />
                <Condition when="residentialAddress.addressType" hasValue>
                    <True>
                        <Grid item xs={12}>
                            <FullWidthCheckBoxField
                                name="sameAsResidential"
                                label="Same as Residential Address"
                            />
                        </Grid>
                    </True>
                </Condition>
                <Condition when="sameAsResidential" is={false}>
                    <True>
                        <AddressDetailsSubForm addressPropertyName="postalAddress" />
                    </True>
                </Condition>
                <Grid item xs={12}>
                    <Typography className={classes.marginTop} variant="subtitle2">
                        Business Address
                    </Typography>
                </Grid>
                <AddressDetailsSubForm addressPropertyName="businessAddress" />
            </Grid>
        </Paper>
    );
};

const mapStateToProps = (state: State) => {
    const claim = getClaim(state);
    const raClaim = claim as RADeathClaim;
    return {
        ...raClaim.lifeAssuredDetails,
    };
};

const mapDispatchToProps = {
    updateAddressDetails: updateAddressDetailsAction,
};

export const AddressDetailsEditView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(addressDetailsEdit));
