import React from 'react'; // eslint-disable-line
import { Grid, Paper, withStyles, Button, Tooltip, Typography } from '@material-ui/core';
import styles, { StyledComponent } from '../../../../styles/styles';
import { LabeledValue } from '../../../../controls/labeled-value';
import { Edit } from '@material-ui/icons';
import { OpenModal, openModalAction } from '../../../../form-modal/actions';
import { FormModalView } from '../../../../form-modal/views/form-modal';
import { connect } from 'react-redux';
import { RAFinancialDetailsEditModal } from './ra-financial-details-edit-modal';
import { State } from '../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../shared-functions';
import { FinancialDetails } from '../../death/claim-event-details/reducer';
import { getRADeathClaim } from '../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { ButtonWrapper } from '../../../../forms';

interface OwnProps {
    readonly isReadOnly: boolean;
}
type Props = StyledComponent &
    OwnProps & {
        openModal: OpenModal;
        financialDetails: FinancialDetails;
    };
const raFinancialDetailsView: React.FunctionComponent<Props> = ({
    classes,
    financialDetails = {},
    openModal,
    isReadOnly,
}: Props) => {
    const edit = () => {
        openModal({
            modalContent: <FormModalView formView={RAFinancialDetailsEditModal} />,
        });
    };
    return (
        <React.Fragment>
            <Grid container justify="flex-start">
                <Grid item xs={11}>
                    <Typography style={{ marginTop: '8px' }} variant="subtitle2">
                        Financial Details
                    </Typography>
                </Grid>
                <ButtonWrapper>
                    <Grid item xs={1} container justify="flex-end">
                        <Tooltip title="Edit Financial Details">
                            <Button
                                id="EditFinancialDetails"
                                color="secondary"
                                onClick={() => edit()}
                            >
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            </Grid>
            <Paper className={classes.paper}>
                <Grid container spacing={0} justify="flex-start">
                    <Grid item xs={10} lg={11}>
                        <LabeledValue
                            label="Has the deceased member ever been insolvent, or are any sequestration proceedings pending or contemplated?"
                            value={financialDetails.previouslyInsolvent}
                        />
                    </Grid>
                    {financialDetails.previouslyInsolvent === 'Yes' && (
                        <React.Fragment>
                            <Grid item xs={10} lg={11}>
                                <LabeledValue
                                    label="Date of occurence"
                                    value={getDisplayDate(financialDetails.previouslyInsolventDate)}
                                />
                            </Grid>
                            <Grid item xs={10} lg={11}>
                                <LabeledValue
                                    label="Reason"
                                    value={financialDetails.previouslyInsolventReason}
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                    <Grid item xs={10} lg={11}>
                        <LabeledValue
                            label="Was the estate deceased member insolvent at the time of death? If 'yes', please provide full details"
                            value={financialDetails.estateInsolvent}
                        />
                    </Grid>
                    {financialDetails.estateInsolvent === 'Yes' && (
                        <React.Fragment>
                            <Grid item xs={10} lg={11}>
                                <LabeledValue
                                    label="Date of occurence"
                                    value={getDisplayDate(financialDetails.estateInsolventDate)}
                                />
                            </Grid>
                            <Grid item xs={10} lg={11}>
                                <LabeledValue
                                    label="Reason"
                                    value={financialDetails.estateInsolventReason}
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </Paper>
        </React.Fragment>
    );
};
const mapStateToProps = (state: State) => ({
    isReadOnly: state.claimSession.readOnly,
    financialDetails:
        getRADeathClaim(state).claimDetails !== null
            ? getRADeathClaim(state).claimDetails.financialDetails || {}
            : {},
});

const mapDispatchToProps = { openModal: openModalAction };
export const RAFinancialDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(raFinancialDetailsView));
