import React from 'react'; // eslint-disable-line
import { Grid, withStyles } from '@material-ui/core';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { getDisplayDate } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { Dependant } from '../reducer';

interface Props extends StyledComponent {
    dependant: Dependant;
}

const dependantViewExSpouse: React.FunctionComponent<Props> = ({ dependant }: Props) => {
    const personalDetails = dependant.personalDetails || {};
    return (
        <Grid item xs={12} container justify="flex-start">
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Age" value={personalDetails.age} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Gender" value={personalDetails.gender} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Relationship to life assured"
                    value={dependant.relationshipDescription}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Date of divorce from life assured"
                    value={getDisplayDate(dependant.dateOfDivorceFromLifeAssured)}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Nature of dependency" value={dependant.natureOfDependency} />
            </Grid>
        </Grid>
    );
};

export const DependantViewExSpouse = withStyles(styles)(dependantViewExSpouse);
