import React from 'react'; // eslint-disable-line

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Theme,
    Typography,
    withStyles,
    Tooltip,
    Grid,
} from '@material-ui/core';
import { ExpansionPanelProps } from '@material-ui/core/ExpansionPanel';
import { createStyles, StyleRules, WithStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getComponentId, nameToLabel } from '../../';
import { Error, Warning } from '@material-ui/icons';

function styles(theme: Theme): StyleRules {
    return createStyles({
        expanded: {
            backgroundColor: '#E6EDFF',
        },
        label: {
            fontSize: theme.typography.pxToRem(15),
            flexShrink: 0,
        },
        secondaryLabel: {
            marginLeft: theme.typography.pxToRem(6),
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
        panelDetails: {
            flexDirection: 'column',
        },
    });
}

type Props = ExpansionPanelProps &
    WithStyles<typeof styles> & {
        name: string;
        id?: string;
        label?: string;
        secondaryLabel?: string;
        children?: React.ReactNode;
        notifier?: string;
        fullheader?: boolean;
        customHeader?: React.ReactNode;
        icon?: any;
    };

const expansionPanel: React.FunctionComponent<Props> = ({
    children,
    name,
    id,
    label,
    secondaryLabel,
    classes,
    notifier,
    fullheader,
    customHeader,
    icon,
    ...rest
}: Props) => {
    return (
        <Accordion {...rest}>
            <AccordionSummary
                classes={{ expanded: classes.expanded }}
                id={getComponentId({ id, name })}
                expandIcon={<ExpandMoreIcon />}
            >
                {customHeader ? (
                    <Grid item xs={12}>
                        {customHeader}
                    </Grid>
                ) : (
                    <React.Fragment>
                        {icon ? icon : null}
                        <Typography className={classes.label}>
                            {label ? label : nameToLabel({ label, name })}
                            {GetWidget(notifier)}
                        </Typography>
                        {secondaryLabel ? (
                            <Typography className={classes.secondaryLabel}>
                                {secondaryLabel}
                            </Typography>
                        ) : undefined}
                    </React.Fragment>
                )}
            </AccordionSummary>
            <AccordionDetails
                className={classes.panelDetails}
                style={{ paddingTop: 0, paddingBottom: 0 }}
            >
                {children}
            </AccordionDetails>
        </Accordion>
    );
};

const GetWidget = (notifier?: string) => {
    switch (notifier) {
        case 'Error':
            return (
                <Tooltip title="There are required fields missing, please complete to continue.">
                    <Error
                        style={{ color: '#bc243c', marginLeft: '8px', marginBottom: '-5px' }}
                        fontSize="default"
                    />
                </Tooltip>
            );
        case 'Warning':
            return (
                <Tooltip title="Please note there are required fields missing.">
                    <Warning
                        style={{ color: '#ff6c35', marginLeft: '8px', marginBottom: '-5px' }}
                        fontSize="default"
                    />
                </Tooltip>
            );
        default:
            return null;
    }
};

export const ExpansionPanel = withStyles(styles)(expansionPanel);
