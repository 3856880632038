import { ReduxAction } from '../../redux/redux-actions';
import { WorkflowScreen } from '../reducers/workflow-screen';
import { DELETE_SCREEN_FROM_WORKFLOW } from '../types';

export type DeleteScreenFromWorkflowAction = ReduxAction<WorkflowScreen>;
export type DeleteScreenFromWorkflow = (
    workflowScreen: WorkflowScreen
) => DeleteScreenFromWorkflowAction;
export const deleteScreenFromWorkflow: DeleteScreenFromWorkflow = (
    workflowScreen: WorkflowScreen
) => {
    if (!workflowScreen) {
        throw new Error('workflowScreen is a required parameter');
    }
    return {
        type: DELETE_SCREEN_FROM_WORKFLOW,
        payload: workflowScreen,
    };
};
