import React from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import { Section } from '../../../../../../controls/section';
import { State } from '../../../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../../../shared-functions';
import styles, { StyledComponent } from '../styles';
import { UnderwritingDisclosure } from './underwriting-disclosure-model';

interface OwnProps {
    disclosures: UnderwritingDisclosure[];
}

interface StateProps {}

interface DispatchProps {}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const underwritingDisclosuresView: React.FunctionComponent<Props> = ({
    disclosures,
    classes,
}: Props) => {
    return (
        <Section title="Disclosures" noChildrenTitle="No Disclosures">
            {disclosures.map((disclosure, index) => (
                <UnderwritingDisclosureView key={index} disclosure={disclosure} classes={classes} />
            ))}
        </Section>
    );
};

interface DisclosureProps extends StyledComponent {
    disclosure: UnderwritingDisclosure;
}

const UnderwritingDisclosureView: React.FunctionComponent<DisclosureProps> = ({
    disclosure,
    classes,
}: DisclosureProps) => {
    return (
        <Grid item container className={classes.separatedGridItem} justify="space-between">
            <Grid item xs={3}>
                <LabeledValue id="date" label="Date" value={getDisplayDate(disclosure.date)} />
            </Grid>
            <Grid item xs={9}>
                <LabeledValue id="details" label="Details" value={disclosure.details} />
            </Grid>
        </Grid>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = () => ({});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {};

export const UnderwritingDisclosuresView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(underwritingDisclosuresView));
