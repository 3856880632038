import { withStyles, Grid, Typography, Paper, Tooltip, Button } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import styles, { StyledComponent } from '../../../../../styles/styles';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { Edit } from '@material-ui/icons';
import { connect } from 'react-redux';
import { State } from '../../../../../redux/root-reducer';
import { FormDetails, getFormDetails } from '../form-details-reducer';
import { getDisplayDate } from '../../../../../shared-functions';
import { OpenModal, openModalAction } from '../../../../../form-modal/actions';
import { editLifeAssureDetails } from './life-assured-edit';
import { editPolicyHolderDetails } from './policy-holder-edit';
import { UpdateFormDetails, updateFormDetailsAction } from '../actions/update-form-details';

interface BaseProps {
    formDetails?: FormDetails;
    disableEditButton?: boolean;
    updateFormDetails?: UpdateFormDetails;
    type: string;
}

interface Props extends BaseProps, StyledComponent {
    readonly openModal: OpenModal;
}

const personalDetailsView: React.FunctionComponent<Props> = ({
    classes,
    openModal,
    formDetails,
    updateFormDetails,
    type,
    disableEditButton,
}: Props) => {
    const edit = () => {
        const details = formDetails || {};
        type === 'LifeAssured'
            ? editLifeAssureDetails(openModal, details, 0, updateFormDetails)
            : editPolicyHolderDetails(openModal, details, 0, updateFormDetails);
    };

    const personalDetails =
        type === 'LifeAssured'
            ? formDetails?.lifeAssured?.personalDetails
            : formDetails?.policyHolder?.individual?.personalDetails;
    const policyHolderType = formDetails?.policyHolder?.policyHolderType;

    return (
        <React.Fragment>
            <Grid container justify="space-between" className={classes.marginTop2}>
                <Grid container item xs={10} alignItems="center">
                    <Typography style={{ padding: 10 }} className={classes.heading} variant="body1">
                        Personal Details
                    </Typography>
                </Grid>
            </Grid>
            <Paper className={classes.paper}>
                <Grid container item xs={12} alignItems="center" justify="flex-end">
                    <Tooltip title="Edit Personal Details">
                        <Button
                            id="EditPersonalDetails"
                            color="secondary"
                            onClick={edit}
                            disabled={!disableEditButton}
                        >
                            <Edit />
                        </Button>
                    </Tooltip>
                </Grid>
                <Grid container justify="flex-start">
                    <Grid item xs={12} container justify="flex-start">
                        <Grid item xs={12} lg={12}>
                            <LabeledValue label="Policyholder type" value={policyHolderType} />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                            <LabeledValue
                                label="Title"
                                value={personalDetails?.title || personalDetails?.lifeAssuredTitle}
                            />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                            <LabeledValue
                                label="First Name"
                                value={
                                    personalDetails?.firstName ||
                                    personalDetails?.lifeAssuredFirstName
                                }
                            />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                            <LabeledValue
                                label="Surname"
                                value={
                                    personalDetails?.surname || personalDetails?.lifeAssuredSurname
                                }
                            />
                        </Grid>
                        {personalDetails?.maiden && (
                            <Grid item xs={4} lg={3}>
                                <LabeledValue label="Maiden Name" value={personalDetails?.maiden} />
                            </Grid>
                        )}
                    </Grid>

                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="Gender"
                            value={personalDetails?.gender || personalDetails?.lifeAssuredGender}
                        />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="Marital Status"
                            value={
                                personalDetails?.maritalStatus ||
                                personalDetails?.lifeAssuredMaritalStatus
                            }
                        />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="SA Resident"
                            value={
                                personalDetails?.isRSAResident ||
                                personalDetails?.lifeAssuredIsRSAResident
                            }
                        />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="ID Number"
                            value={
                                personalDetails?.idNumber || personalDetails?.lifeAssuredIdNumber
                            }
                        />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="Date of Birth"
                            value={
                                getDisplayDate(personalDetails?.dateOfBirth) ||
                                getDisplayDate(personalDetails?.lifeAssuredDateOfBirth)
                            }
                        />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="Place of Birth"
                            value={
                                personalDetails?.placeOfBirth ||
                                personalDetails?.lifeAssuredPlaceOfBirth
                            }
                        />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="Country of Birth"
                            value={
                                personalDetails?.countryOfBirth ||
                                personalDetails?.lifeAssuredCountryOfBirth
                            }
                        />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="Country of residence"
                            value={
                                personalDetails?.countryOfResidence ||
                                personalDetails?.lifeAssuredCountryOfResidence
                            }
                        />
                    </Grid>
                    {(personalDetails?.incomeTaxNumber ||
                        personalDetails?.lifeAssuredIncomeTaxNumber) && (
                        <Grid item xs={4} lg={3}>
                            <LabeledValue
                                label="SA Income tax number"
                                value={
                                    personalDetails?.incomeTaxNumber ||
                                    personalDetails?.lifeAssuredIncomeTaxNumber
                                }
                            />
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

const mapStateToProps = (state: State, ownProps: BaseProps) => ({
    formDetails: ownProps.formDetails ?? getFormDetails(state),
    disableEditButton: ownProps.disableEditButton ?? false,
    updateFormDetails: ownProps.updateFormDetails ?? updateFormDetailsAction,
});

const mapDispatchToProps = {
    openModal: openModalAction,
    updateFormDetails: updateFormDetailsAction,
};

export const PersonalDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(personalDetailsView));
