import React from 'react'; // eslint-disable-line
import _ from 'lodash';
import { DateTime } from 'luxon';
import * as rsaIdParser from 'south-african-id-parser';
import { DocumentName } from './codes/document-name';
import { PolicyStatusCodes } from './codes/policy-status';
import { ReasonForReferral } from './codes/reason-for-referral';
import { AllRelationships } from './codes/relationship-types';
import { Titles } from './codes/titles';
import { Nullable, EntityType } from './shared-types';
import { isEmpty } from './forms/validations';
import { CheckCircle, Error, Cancel } from '@material-ui/icons';
import { Grid, Tooltip } from '@material-ui/core';
import * as saIdParser from 'south-african-id-parser';
import {
    OrganisationTypeSuffixes,
    OrganisationTypeSuffix,
    OrganisationType,
    OrganisationTypes,
    Claimant,
} from './assessment/synopsis/death/contact-details/contact-details-reducer';
import { BankNames } from './codes/bank-names';
import { CommunicationMethods } from './codes/communication-methods';
import { ClaimCategory } from './codes/claim-category';
import { ClaimEvent } from './codes/claim-event';
import { IdDocumentTypes } from './codes/id-document-types';
import { Dependant } from './assessment/synopsis/ra-death/dependants/reducer';
import { MaritalStatus } from './codes/marital-statuses';
import { PayeeTypeCodeList } from './codes/payee-types';
import {
    AllCausesOfDeath,
    NaturalCauseOfDeath,
    UnnaturalCauseOfDeath,
} from './codes/cause-of-claim';
import { FuneralClaimDecisions } from './codes/funeral-claim-decisions';
import { DecisionModel } from './assessment/decisions/ra-death/ra-death-assessment-details-reducer';

export function getDisplayDate(date: Nullable<Date>, format = 'dd/MM/yyyy') {
    if (date) {
        return DateTime.fromJSDate(date).toFormat(format);
    }
    return '';
}

export function parseStringToDate(dateString: string) {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    const formattedDate = date.toLocaleDateString('en-ZA', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
    });
    return formattedDate;
}

export function getDisplayTime(date: Nullable<Date>, format = 'HH:mm:ss') {
    if (date) {
        return DateTime.fromJSDate(date).toFormat(format);
    }
    return '';
}

export function formatCurrency(value?: number) {
    const currencyFormatter = new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    return currencyFormatter.format(value || 0);
}

export function getYesNo(value?: boolean) {
    return value ? 'Yes' : 'No';
}

interface CodeEntry {
    value: string;
    label: string;
}

export function getCodeLabel(codes: CodeEntry[], value: Nullable<string>) {
    if (!value || value === '') {
        return '';
    }
    const code = codes.filter(r => r.value === value)[0];
    return code ? code.label : value;
}

interface NameDetails {
    title?: string;
    firstName?: string;
    secondName?: string;
    lastName?: string;
}
export function getFullName(details?: NameDetails) {
    if (!details) {
        return '';
    }
    const title = getCodeLabel(Titles, details.title);

    return titleCase(
        [title, details.firstName, details.secondName, details.lastName]
            .filter(value => !!value && value !== '')
            .join(' ')
    );
}

export function titleCase(value?: string) {
    if (!value) {
        return '';
    }
    return _.startCase(_.toLower(value));
}

export function getReasonForReferralDescription(value?: string) {
    return getCodeLabel(ReasonForReferral, value);
}

export function getDocumentNameDescription(value?: string) {
    return getCodeLabel(DocumentName, value);
}

export function getPolicyStatusDescription(value?: string) {
    return getCodeLabel(PolicyStatusCodes, value);
}

export function formatPercent(value?: number): string {
    if (typeof value === 'undefined') {
        return '';
    }

    const numberFormatter = new Intl.NumberFormat('en-ZA', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    return numberFormatter.format(value);
}

export function convertToNumber(value?: string): number | undefined {
    if (!value) {
        return undefined;
    }

    const cleanValue = value.replace(/[^\d.]/g, '');

    return parseFloat(cleanValue);
}

export function convertToUpperCase(value?: string) {
    if (!value) {
        return undefined;
    }
    return value.toUpperCase();
}

export function getDifferenceBetweenDates(dateOne?: Date, dateTwo?: Date) {
    if (!dateOne || !dateTwo) {
        return undefined;
    }

    return Math.abs(new Date(dateTwo.getTime() - dateOne.getTime()).getUTCFullYear() - 1970);
}
export const getObjectWarning = (value: any, errors: any) => {
    if (isEmpty(value) && errors === null) {
        return 'Warning';
    } else if (errors !== null) {
        return 'Error';
    }
    return '';
};

export const getDateOfBirthFromRsaId = (idNumber: string) => {
    if (!idNumber || !saIdParser.validate(idNumber)) {
        return null;
    }
    const date = saIdParser.parseDateOfBirth(idNumber);
    return date;
};

export const getGenderFromRsaId = (idNumber: string) => {
    if (!idNumber || !saIdParser.validate(idNumber)) {
        return null;
    }
    const gender = saIdParser.parseIsFemale(idNumber) ? 'Female' : 'Male';
    return gender;
};

export const calculateAge = (birthday: Date): number => {
    const ageDifMs = Date.now() - new Date(birthday).getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export function isAMinor(dateOfBirth?: Date) {
    if (dateOfBirth) {
        return (
            Math.abs(
                new Date(Date.now() - new Date(dateOfBirth).getTime()).getUTCFullYear() - 1970
            ) < 18
        );
    }

    return false;
}

export const getOrganisationTypeSuffixLabel = (suffix?: OrganisationTypeSuffix): string => {
    const foundSuffix = OrganisationTypeSuffixes.find((item: any) => {
        return item.value === suffix;
    });

    return !!foundSuffix ? foundSuffix.label : '';
};

export const getOrganisationTypeLabel = (organisationType?: OrganisationType): string => {
    const foundOrganisationType = OrganisationTypes.find((item: any) => {
        return item.value === organisationType;
    });

    return !!foundOrganisationType ? foundOrganisationType.label : '';
};

export function getValueFromObject(values: any, objectName: string, property: string) {
    if (!values) {
        return undefined;
    }

    const objectProperties = objectName.split('.');
    let _valuesObject = values;
    objectProperties.forEach(objectProperty => {
        _valuesObject = _valuesObject ? _valuesObject[objectProperty] : _valuesObject;
    });

    return _valuesObject ? _valuesObject[property] : undefined;
}

export function getValueFromArrayList(values: any, objectName: string, property: string) {
    if (!values) {
        return undefined;
    }
    const objectProperties = objectName.split('.');
    let _index: any;
    let _valuesObject = values;
    objectProperties.forEach(objectProperty => {
        if (objectProperty.includes('[')) {
            const splitValue = objectProperty.split('[');
            objectProperty = objectProperty.split('[')[0];
            _index = splitValue[1].split(']')[0];
            _valuesObject = _valuesObject ? _valuesObject[objectProperty] : _valuesObject;
        } else {
            _valuesObject = _valuesObject ? _valuesObject[objectProperty] : _valuesObject;
        }
    });
    return _valuesObject ? _valuesObject[_index][property] : undefined;
}

export const GetVerificationWidget = (notifier?: string) => {
    switch (notifier) {
        case 'Verified':
            return (
                <Tooltip title="Verified">
                    <CheckCircle style={{ color: '#36b37e', marginLeft: '8px' }} fontSize="small" />
                </Tooltip>
            );
        default:
            return (
                <Tooltip title="Not Verified">
                    <Error
                        style={{ color: '#bc243c', marginLeft: '8px', marginBottom: '-5px' }}
                        fontSize="default"
                    />
                </Tooltip>
            );
    }
};

export function getCodeDescription(fieldName: string, value?: string) {
    let displayValue = value;

    switch (fieldName.toLowerCase()) {
        case 'bankname':
            displayValue = getLabelFromValue(BankNames);
            break;
        case 'title':
            displayValue = getLabelFromValue(Titles);
            break;
        case 'preferredmethodofcommunication':
            displayValue = getLabelFromValue(CommunicationMethods);
            break;
        case 'documentname':
            displayValue = getLabelFromValue(DocumentName);
            break;
        case 'policystatus':
            displayValue = getLabelFromValue(PolicyStatusCodes);
            break;
        case 'reasonforreferral':
            displayValue = getLabelFromValue(ReasonForReferral);
            break;
        case 'permanentimpairmentclaimsclaimcategory':
            displayValue = getLabelFromValue(ClaimCategory);
            break;
        case 'guaranteedpaymentperiodclaims':
            displayValue = getLabelFromValue(ClaimEvent);
            break;
        case 'idtype':
            displayValue = getLabelFromValue(IdDocumentTypes);
            break;
        case 'relationship':
        case 'relationships':
        case 'naturalrelationship':
        case 'organisationrelationship':
            displayValue = getLabelFromValue(AllRelationships);
            break;
        case 'maritalstatus':
            displayValue = getLabelFromValue(MaritalStatus);
            break;
        case 'payeetype':
            displayValue = getLabelFromValue(PayeeTypeCodeList());
            break;
        case 'causeofdeath':
            displayValue = getLabelFromValue(AllCausesOfDeath);
            break;
        case 'decision':
            displayValue = getLabelFromValue(FuneralClaimDecisions);
            break;
        default:
            break;
    }

    return displayValue;

    function getLabelFromValue(codesList: any[]): string | undefined {
        const reason = codesList.filter(t => t.value === value);
        if (reason && reason.length > 0) {
            return reason[0].label;
        }
        return value;
    }
}

export function getClaimantName(id: string, claimants?: Claimant[]) {
    const claimant =
        (claimants &&
            claimants.find(claimant => {
                return claimant.id === id;
            })) ||
        undefined;

    return getClaimantDisplayName(claimant);
}

export function getClaimantDisplayName(claimant?: Claimant) {
    const claimantDetails = (claimant && claimant.claimantDetails) || {};
    if (
        claimantDetails.claimantType === EntityType.Individual ||
        claimantDetails.claimantType === EntityType.Executor
    ) {
        return getFullName(claimantDetails.personalDetails);
    }

    if (claimantDetails.claimantType === EntityType.Organisation) {
        const organisationDetails = claimantDetails.organisationDetails || {};
        return `${organisationDetails.companyName} (${organisationDetails.entityRegistrationNumber})`;
    }
    return '';
}

export function getDependantName(id: string, dependants?: Dependant[]) {
    const dependant = dependants
        ? dependants.find(dependant => {
              return dependant.id === id;
          })
        : undefined;

    return getDependantDisplayName(dependant);
}

export function getDependantDisplayName(dependant?: Dependant) {
    if (dependant) {
        if (dependant.dependantType === EntityType.Individual) {
            return getFullName(dependant.personalDetails);
        } else if (dependant.dependantType === EntityType.Organisation) {
            const organisationDetails = dependant.organisationDetails || {};
            return `${organisationDetails.companyName} (${organisationDetails.entityRegistrationNumber})`;
        }
    }
    return '';
}

export function getTimeLapsed(dateTo: Date, dateFrom?: Date) {
    if (dateFrom) {
        const age = Math.abs(
            new Date(dateTo.getTime() - dateFrom.getTime()).getUTCFullYear() - 1970
        );
        const toMonth = dateTo.getMonth();
        const fromMonth = dateFrom.getMonth();
        const toDay = dateTo.getDate();
        const fromDay = dateFrom.getDate();
        let months = 0;
        if (fromMonth > toMonth) {
            months = 11 - (fromMonth - toMonth);
            if (toDay > fromDay) {
                months = months + 1;
            }
        }
        if (fromMonth < toMonth) {
            months = toMonth - fromMonth;
            if (fromDay > toDay) {
                months = months - 1;
            }
        }
        if (fromMonth === toMonth && fromDay > toDay) {
            months = 11;
        }

        return `${age}.${months}`;
    }
    return 0;
}

export function defaultDateOfBirth(idNumber: number) {
    const dob = rsaIdParser.parseDateOfBirth(idNumber);
    if (Object.prototype.toString.call(dob) === '[object Date]') {
        return new Date(Date.UTC(dob.getFullYear(), dob.getMonth(), dob.getDate()));
    }
    return dob;
}

export const getCauseOfdeathDescription = (value?: string) => {
    const causeOfDeath = NaturalCauseOfDeath.concat(...UnnaturalCauseOfDeath).find(
        c => c.value === value
    );
    const CauseOfdeathDescription = causeOfDeath ? causeOfDeath.label : null;
    return CauseOfdeathDescription;
};

export function defaultAgeFromDateOfBirth(dateOfBirth: Date) {
    return Math.abs(new Date(Date.now() - dateOfBirth.getTime()).getUTCFullYear() - 1970);
}

export const FormattedCheckCircle = () => {
    return (
        <Grid item xs={2} lg={1}>
            <CheckCircle style={{ color: '#689F38', marginTop: '-10px' }} fontSize="small" />
        </Grid>
    );
};

export const FormattedCancel = () => {
    return (
        <Grid item xs={2} lg={1}>
            <Cancel style={{ color: '#FF0000', marginTop: '-10px' }} fontSize="small" />
        </Grid>
    );
};

export function frequencyMultiplier(frequency?: string) {
    switch (frequency) {
        case 'Annually':
            return 1;
        case 'Bi-annually':
            return 2;
        case 'Quarterly':
            return 4;
        case 'Monthly':
            return 12;
        default:
            return 0;
    }
}

export function getDisplayNameFromAdName(adName: string) {
    if (!adName) {
        return '';
    }

    const splitName = adName.split(', ');
    const reversedSplitName = splitName.reverse();
    const displayName = reversedSplitName.join(' ');

    return displayName;
}

export function totalAllocation(decision: DecisionModel) {
    let sum = 0;
    decision.percentageSplits.reduce((next: any, dec: any) => {
        const { allocation } = dec;
        sum = next + Number(allocation ? allocation : 0);
        return Number(sum.toFixed(2));
    }, 0);
    return sum;
}

export const getFormattedDate = (dateString: string) => {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6); // JavaScript months are 0-indexed
    const day = dateString.substring(6, 8);

    const date = new Date(Number(year), Number(month) - 1, Number(day));

    return date.toLocaleDateString('en-ZA', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
};
