import { AnyAction, Reducer } from 'redux';
import { ReduceCollectionRecordAction } from './collection-record-action';
import { reduceRecord } from './reductions/reduce-record';

export interface Props<T> {
    recordReducer: Reducer<T, AnyAction>;
    reduceActions?: string[];
}

export function collectionRecordReducer<T>({
    reduceActions,
    recordReducer,
}: Props<T>): Reducer<T[], ReduceCollectionRecordAction<AnyAction>> {
    return (state: T[] = [], action: ReduceCollectionRecordAction<AnyAction>): T[] => {
        if (reduceActions && reduceActions.indexOf(action.type) < 0) {
            return state;
        }

        if (recordReducer && action.index >= 0) {
            return reduceRecord(recordReducer, state, action);
        }
        return state;
    };
}
