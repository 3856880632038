import axios from 'axios';
import { UniversalBranch, FundNames } from '..';
import { env } from '../../env';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { CodeList } from '../lookups-reducer';
import { LOAD_BANK_NAMES, LOAD_UNIVERSAL_BRANCHES, LOAD_FUND_NAMES } from './types';

const utilitiesBaseUrl = `${env().CLAIMS_API}/api/Utilities`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LoadReturn = Promise<any>;
export type LoadBankNames = () => LoadReturn;
type LoadBankNamesThunk = () => ReduxThunkAction;
export const loadBankNamesAction: LoadBankNamesThunk = () => {
    return async dispatch => {
        await axios.get<CodeList<string>>(`${utilitiesBaseUrl}/BankNames`).then(response => {
            dispatch({ type: LOAD_BANK_NAMES, bankNames: response.data });
        });
        return;
    };
};

const branchCodesUrl = `${utilitiesBaseUrl}/BankBranchCodes`;
export type LoadUniversalBranches = () => LoadReturn;
type LoadUniversalBranchesThunk = () => ReduxThunkAction;
export const loadUniversalBranchesAction: LoadUniversalBranchesThunk = () => {
    return async dispatch => {
        await axios.get<UniversalBranch[]>(`${branchCodesUrl}/UniversalBranches`).then(response => {
            dispatch({
                type: LOAD_UNIVERSAL_BRANCHES,
                universalBranches: response.data,
            });
        });
        return;
    };
};

const fundNamesUrl = `${utilitiesBaseUrl}/RAFundNames`;
export type LoadRADeathFundNames = () => LoadReturn;
type RADeathNundNamesThunk = () => ReduxThunkAction;
export const loadRAFundNamesAction: RADeathNundNamesThunk = () => {
    return async dispatch => {
        await axios.get<FundNames[]>(`${fundNamesUrl}`).then(response => {
            dispatch({
                type: LOAD_FUND_NAMES,
                fundNames: response.data,
            });
        });
        return;
    };
};

export type LoadBankBranches = (bankName: string) => LoadReturn;
type LoadBankBranchesThunk = (bankName: string) => ReduxThunkAction;
export const loadBankBranchesAction: LoadBankBranchesThunk = (bankName: string) => {
    return async () => {
        try {
            const response = await axios.get<CodeList<string>>(
                `${branchCodesUrl}?bankName=${bankName}`
            );
            return response.data;
        } catch (err) {
            return [];
        }
    };
};

export * from './types';
