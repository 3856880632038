import React from 'react'; // eslint-disable-line

import { Grid, Paper, withStyles } from '@material-ui/core';
import { avocations } from '../../codes/avocations';
import {
    Condition,
    FormHeading,
    FullWidthDatePicker,
    FullWidthRadioGroup,
    FullWidthSearchSelectField,
    FullWidthTextField,
    RadioOption,
    True,
} from '../../forms';
import { conditionalRequired, requiredValidator } from '../../forms/validations';
import { OccupationalDutiesBreakdownTable } from '../../occupation-details';
import { Nullable } from '../../shared-types';
import styles, { StyledComponent } from '../../styles/styles';
import { ClaimType } from '../../claim/claim-types';

interface Props extends StyledComponent {
    claimType?: Nullable<ClaimType>;
    isSynopsis?: boolean;
}
const occupationalDutiesBreakdownView: React.FunctionComponent<Props> = ({
    classes,
    claimType,
    isSynopsis,
}: Props) => {
    return (
        <Paper className={classes.paper}>
            <FormHeading text="Occupational Duties Breakdown" />
            <Grid container spacing={5}>
                <Grid item xs={12} />
                <Grid item xs={12} lg={6}>
                    <OccupationalDutiesBreakdownTable />
                </Grid>
                <Grid item xs={12}>
                    <FullWidthTextField
                        name="dutyBreakdown.occupationDescription"
                        label="Full time occupation description"
                        validate={requiredValidator}
                        multiline
                    />
                </Grid>
                {!isSynopsis && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <FullWidthRadioGroup
                                name="dutyBreakdown.hazard"
                                label="Any hazard with occupation?"
                                validate={requiredValidator}
                            >
                                <RadioOption value="Yes" />
                                <RadioOption value="No" />
                            </FullWidthRadioGroup>
                        </Grid>
                        <Condition when="dutyBreakdown.hazard" is="Yes">
                            <True>
                                <Grid item xs={12}>
                                    <FullWidthTextField
                                        name="dutyBreakdown.hazardDetails"
                                        label="Details"
                                        validate={requiredValidator}
                                        multiline
                                    />
                                </Grid>
                            </True>
                        </Condition>
                        <Grid item xs={12}>
                            <FullWidthRadioGroup
                                name="dutyBreakdown.internationalTravel"
                                label="Travel beyond borders of RSA?"
                                validate={requiredValidator}
                            >
                                <RadioOption value="Yes" />
                                <RadioOption value="No" />
                            </FullWidthRadioGroup>
                        </Grid>
                        <Condition when="dutyBreakdown.internationalTravel" is="Yes">
                            <True>
                                <Grid item xs={12}>
                                    <FullWidthTextField
                                        name="dutyBreakdown.internationalTravelDetails"
                                        label="Details"
                                        validate={requiredValidator}
                                        multiline
                                    />
                                </Grid>
                            </True>
                        </Condition>
                        <Grid item xs={6}>
                            <FullWidthDatePicker
                                name="dutyBreakdown.dateWhenStopped"
                                label="Date when stopped working"
                                maxDateField="dutyBreakdown.dateWhenReturning"
                                validate={requiredValidator}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthDatePicker
                                name="dutyBreakdown.dateWhenReturning"
                                label="Expected date of return to work"
                                minDateField="dutyBreakdown.dateWhenStopped"
                                validate={conditionalRequired(
                                    () => claimType === ClaimType.IncomeProtector
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthSearchSelectField
                                name="dutyBreakdown.avocations"
                                label="Current Avocations"
                                options={avocations}
                                isMulti
                            />
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        </Paper>
    );
};

export const OccupationalDutiesBreakdownView = withStyles(styles)(occupationalDutiesBreakdownView);
