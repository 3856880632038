import React from 'react'; // eslint-disable-line
import { ButtonWrapper } from '../../../../../forms';
import { Grid, withStyles, Typography, Tooltip, Button, Paper, Divider } from '@material-ui/core';
import { AddCircle, Edit, Error } from '@material-ui/icons';
import { connect, MapDispatchToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { v4 as uuid } from 'uuid';
import { ThunkDispatch } from 'redux-thunk';
import { getRADeathClaim } from '../../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { AddFormModal } from '../../../../../form-modal';
import {
    OpenModal,
    openModalAction,
    OpenAddModal,
    openAddModalAction,
} from '../../../../../form-modal/actions';
import { FormModalView } from '../../../../../form-modal/views/form-modal';
import { State } from '../../../../../redux/root-reducer';
import { getDisplayDate, getDependantName } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { AddAndSubmitDisputeRecord, addAndSubmitDisputeRecordThunk } from '../actions/add-dispute';
import { Dependant } from '../../dependants/reducer';
import { Dispute } from '../reducer';
import { DisputeEditModal } from './dispute-edit-modal';
import { DisputeEditView } from './edit-dispute-view';

interface DispatchProps {
    openAddModal: OpenAddModal;
    openModal: OpenModal;
    addDispute: AddAndSubmitDisputeRecord;
}
interface OwnProps {}
type Props = StyledComponent &
    DispatchProps &
    OwnProps & {
        disputes: Dispute[];
        openModal: OpenModal;
        dependants: Dependant[];
        isReadOnly: boolean;
    };
const DisputeAddFormModal = AddFormModal<Dispute>();
const disputeDetailsView: React.FunctionComponent<Props> = ({
    classes,
    disputes,
    addDispute,
    openAddModal,
    openModal,
    dependants,
    isReadOnly,
}: Props) => {
    const editDispute = (disputeIndex: number) => {
        openModal({
            modalContent: <FormModalView formView={DisputeEditModal} />,
            modalData: disputeIndex,
        });
    };
    return (
        <React.Fragment>
            <ButtonWrapper>
                <Grid container spacing={0} justify="flex-end">
                    <Tooltip title="Add Dispute">
                        <Button
                            id="AddDispute"
                            color="secondary"
                            onClick={() =>
                                openAddModal({
                                    modalContent: (
                                        <DisputeAddFormModal
                                            blankRecord={{ id: uuid() }}
                                            recordDetailView={<DisputeEditView />}
                                            addRecord={record => addDispute(record)}
                                        />
                                    ),
                                    modalData: {},
                                })
                            }
                        >
                            <AddCircle />
                        </Button>
                    </Tooltip>
                </Grid>
            </ButtonWrapper>
            <Paper className={classes.paper}>
                {disputes.map((dispute, index) => {
                    return (
                        <Grid container justify="flex-start" key={index}>
                            {index > 0 && (
                                <Grid item xs={12}>
                                    <Divider style={{ margin: '2px -16px 8px -16px' }} />
                                </Grid>
                            )}
                            <Grid container justify="space-between">
                                <Grid item xs={8} lg={10}>
                                    <Typography variant="h5">
                                        Dispute {index + 1} | {dispute.decision} |{' '}
                                        {getDisplayDate(dispute.disputeDate)}
                                    </Typography>
                                </Grid>
                                <ButtonWrapper>
                                    <Grid container item xs={4} lg={2} justify="flex-end">
                                        <Tooltip title="Edit Dispute">
                                            <Button
                                                id="Edit dispute"
                                                color="secondary"
                                                className={classes.button}
                                                onClick={() => editDispute(index)}
                                            >
                                                <Edit />
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                </ButtonWrapper>
                            </Grid>
                            <Grid container justify="flex-start">
                                {dispute.dependantId !== 'Other' && dispute.dependantId !== null && (
                                    <React.Fragment>
                                        <Grid item xs={4} lg={3}>
                                            <LabeledValue
                                                label="Name"
                                                value={getDependantName(
                                                    dispute.dependantId || '',
                                                    dependants
                                                )}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                )}
                                {(dispute.dependantId === 'Other' ||
                                    dispute.dependantId === null) && (
                                    <React.Fragment>
                                        <Grid item xs={4} lg={3}>
                                            <LabeledValue label="Name" value={dispute.otherName} />
                                        </Grid>
                                        <Grid item xs={4} lg={3}>
                                            <LabeledValue
                                                label="Surname"
                                                value={dispute.otherSurname}
                                            />
                                        </Grid>
                                        <Grid item xs={4} lg={3}>
                                            <LabeledValue
                                                label="Relationship To Life Assured"
                                                value={dispute.relationshipToLifeAssured}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                )}
                                {dispute.relationshipToLifeAssured === 'Other' && (
                                    <Grid item xs={4} lg={3}>
                                        <LabeledValue
                                            label="Relationship Description"
                                            value={dispute.relationshipToLifeAssuredDescription}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12} lg={12}>
                                    <LabeledValue
                                        label="Reason for dispute?"
                                        value={dispute.reason}
                                        isUnsafe
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <LabeledValue
                                        label="What proof has been submitted to support the dispute?"
                                        value={dispute.supportingProof}
                                        isUnsafe
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Grid item xs={4} lg={3}>
                                        <span>
                                            <Typography variant="subtitle2">Decision</Typography>
                                            {dispute.decision}
                                            {<DecisionIcon decision={dispute.decision} />}
                                        </span>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <LabeledValue
                                        label="Summary of decision"
                                        value={dispute.summaryOfDecision}
                                        isUnsafe
                                    />
                                </Grid>
                                <Grid item xs={4} lg={3}>
                                    <LabeledValue
                                        label="Decision made by"
                                        value={dispute.decisionMadeBy}
                                    />
                                </Grid>
                                {dispute.decisionMadeBy === 'Trustees' && (
                                    <Grid item xs={4} lg={3}>
                                        <LabeledValue
                                            label="Trustee Meeting Date"
                                            value={getDisplayDate(dispute.trusteeMeetingDate)}
                                        />
                                    </Grid>
                                )}

                                <Grid item xs={4} lg={3}>
                                    <LabeledValue label="Assessor" value={dispute.assessor} />
                                </Grid>
                                <Grid item xs={4} lg={3}>
                                    <LabeledValue
                                        label="Assessment Date"
                                        value={getDisplayDate(dispute.assessmentDate)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                })}
                {disputes.length === 0 && <React.Fragment>No disputes added</React.Fragment>}
            </Paper>
        </React.Fragment>
    );
};

interface IconProps {
    decision?: string;
}
const DecisionIcon: React.FunctionComponent<IconProps> = ({ decision }: IconProps) => {
    const color = decision === 'Decision remains' ? '#84C83A' : '#FFAB00';
    return <Error style={{ color: color, marginBottom: -6, marginLeft: 2 }} />;
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    addDispute: record => dispatch(addAndSubmitDisputeRecordThunk(record)),
    openModal: options => dispatch(openModalAction(options)),
    openAddModal: options => dispatch(openAddModalAction(options)),
});

const mapStateToProps = (state: State) => ({
    disputes: getRADeathClaim(state).disputes || {},
    dependants: getRADeathClaim(state).dependants,
    isReadOnly: state.claimSession.readOnly,
});
export const DisputeDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(disputeDetailsView));
