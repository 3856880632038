import { withStyles, Grid, Typography } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import styles, { StyledComponent } from '../../../../../styles/styles';
import { FormDetails, getFormDetails } from '../form-details-reducer';
import { connect } from 'react-redux';
import { State } from '../../../../../redux/root-reducer';
import {
    FullWidthDatePicker,
    FullWidthSearchSelectField,
    FullWidthTextField,
} from '../../../../../forms';
import {
    AddressTypesLivingLifestyle,
    Provinces,
} from '../../../../../forms/sub-forms/address-details';
import { NumberMask } from '../../../../../forms/input-masks';

interface BaseProps {
    formDetails: FormDetails;
    prefix: string;
}

interface Props extends BaseProps, StyledComponent {}

const doctorPracticeDetailsEdit: React.FunctionComponent<Props> = ({
    classes,
    formDetails,
    prefix,
}: Props) => {
    const doctors = formDetails?.lifeAssured?.medicalInformation?.doctorVisits || [
        {
            doctorsName: null,
            practiceNumber: null,
            doctorsContactDetails: null,
            emailAddress: null,
            addressDetails: {
                addressType: null,
                addressLineTwo: null,
                postalCode: null,
                addressLineOne: null,
                addressSuburb: null,
                country: null,
            },
            occurences: [
                {
                    dateOfAttendance: null,
                    reason: null,
                },
            ],
        },
    ];

    return (
        <React.Fragment>
            {doctors &&
                doctors.map((doctor: any, index: number) => (
                    <Grid item xs={12} key={index}>
                        {/* <ExpansionPanel name={`Doctor ${index + 1}`}> */}
                        <Typography
                            className={classes.heading}
                            style={{ padding: 5 }}
                            variant="body1"
                        >{`Doctor ${index + 1} Details`}</Typography>
                        <Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6} lg={3}>
                                    <FullWidthTextField
                                        label="Doctor's name"
                                        name={`${prefix}.doctorVisits[${index}].doctorsName`}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <FullWidthTextField
                                        label="Practice number"
                                        name={`${prefix}.doctorVisits[${index}].practiceNumber`}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <FullWidthTextField
                                        label="Contact number"
                                        mask={NumberMask}
                                        name={`${prefix}.doctorVisits[${index}].doctorsContactDetails`}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <FullWidthTextField
                                        label="Email address"
                                        name={`${prefix}.doctorVisits[${index}].emailAddress`}
                                    />
                                </Grid>
                            </Grid>
                            <Typography
                                className={classes.heading}
                                style={{ padding: 5 }}
                                variant="body1"
                            >{`Practice Details`}</Typography>

                            <Grid container spacing={2}>
                                <Grid item xs={6} lg={3}>
                                    <FullWidthSearchSelectField
                                        label="Address type"
                                        options={AddressTypesLivingLifestyle}
                                        disabled={true}
                                        name={`${prefix}.doctorVisits[${index}].addressDetails.addressType`}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <FullWidthTextField
                                        label="Address Line 1"
                                        name={`${prefix}.doctorVisits[${index}].addressDetails.addressLineOne`}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <FullWidthTextField
                                        label="Address Line 2"
                                        name={`${prefix}.doctorVisits[${index}].addressDetails.addressLineTwo`}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <FullWidthTextField
                                        label="Town/Suburb"
                                        name={`${prefix}.doctorVisits[${index}].addressDetails.addressSuburb`}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <FullWidthTextField
                                        label="City"
                                        name={`${prefix}.doctorVisits[${index}].addressDetails.addressTown`}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <FullWidthTextField
                                        label="Postal Code"
                                        mask={NumberMask}
                                        name={`${prefix}.doctorVisits[${index}].addressDetails.postalCode`}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <FullWidthSearchSelectField
                                        label="Province"
                                        name={`${prefix}.doctorVisits[${index}].addressDetails.province`}
                                        options={Provinces}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {doctor.occurences &&
                            doctor.occurences.map((occurence: any, indexOcurrences: any) => (
                                <Grid container spacing={2} key={index}>
                                    <Grid item xs={12}>
                                        <Typography
                                            className={classes.heading}
                                            style={{ padding: 5 }}
                                            variant="body1"
                                        >{`Reason for Consultation`}</Typography>
                                    </Grid>

                                    <Grid item xs={4} lg={3}>
                                        <FullWidthDatePicker
                                            label="Date of attendance"
                                            name={`${prefix}.doctorVisits[${index}].occurences[${indexOcurrences}].dateOfAttendance`}
                                        />
                                    </Grid>
                                    <Grid item xs={8} lg={9}>
                                        <FullWidthTextField
                                            label="Reason for consultation"
                                            name={`${prefix}.doctorVisits[${index}].occurences[${indexOcurrences}].reason`}
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                        {/* </ExpansionPanel> */}
                    </Grid>
                ))}
        </React.Fragment>
    );
};

const mapStateToProps = (state: State) => ({
    formDetails: getFormDetails(state),
});

const mapDispatchToProps = {};

export const DoctorPracticeDetailsEdit = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(doctorPracticeDetailsEdit));
