import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ErrorObject } from '../../forms/validations';
import { GetState, State } from '../../redux/root-reducer';
import { Claim, claimReducer } from '../reducers/claim-reducer';
import { claimSubmit } from './claim-submit';

export async function reduceAndSubmitClaim(
    dispatch: ThunkDispatch<State, null, AnyAction>,
    getState: GetState,
    action: AnyAction,
    validate?: (claim: Claim) => ErrorObject
) {
    const errors = await claimSubmit(
        dispatch,
        getState,
        claim => claimReducer(claim, action) as Claim,
        validate
    );
    return errors;
}
