import { Grid, Typography } from '@material-ui/core';
import { Cancel, CheckCircle } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { titleCase } from '../../../../shared-functions';

interface Props {
    status?: string;
}

const StatusIcon = ({ status = '' }: Props) => {
    const activeStatus = ['IN FORCE', 'ACTIVE'];
    if (status && activeStatus.includes(status.toUpperCase())) {
        return <CheckCircle style={{ color: '#689F38' }} fontSize="small" />;
    }

    return <Cancel style={{ color: '#FF0000' }} fontSize="small" />;
};

export const StatusValue = ({ status }: Props) => {
    return (
        <Grid container>
            <Grid item xs={3} lg={1}>
                <StatusIcon status={status} />
            </Grid>
            <Grid item xs={9} lg={11}>
                <Typography style={{ marginTop: '-2px', marginLeft: '8px' }}>
                    {titleCase(status)}
                </Typography>
            </Grid>
        </Grid>
    );
};
