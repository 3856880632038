import { Theme } from '@material-ui/core';
import { createStyles, StyleRules } from '@material-ui/core/styles';
import { CSSProperties, WithStyles } from '@material-ui/core/styles/withStyles';
import globalStyles from '../../../styles/styles';

export type StyledComponent = WithStyles<typeof styles>;

export default function styles(theme: Theme): StyleRules {
    return createStyles({
        ...globalStyles(theme),
        actionHeader: actionHeader(theme),
        actionCell: actionCell(theme),
        cell: cell(theme),
    });
}

function actionHeader(theme: Theme): CSSProperties {
    return {
        ...cell(theme),
        marginRight: theme.spacing(),
        marginLeft: theme.spacing(),
    };
}

function actionCell(theme: Theme): CSSProperties {
    return {
        ...cell(theme),
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center' as const,
    };
}

function cell(theme: Theme): CSSProperties {
    return {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    };
}
