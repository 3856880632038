import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { openDialogAction } from '../../confirmation-dialog';
import { nameToLabel } from '../../forms';
import { hideLoadingAction, showLoadingAction } from '../../forms/loading-screen/actions';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { GetState } from '../../redux/root-reducer';
import { setSubmittingFlag } from './set-submitting-flag';
import { JsonErrorView } from '../views/json-error-view';
import React from 'react'; // eslint-disable-line

export type HandleSubmit = () =>
    | SubmissionErrors
    | Promise<SubmissionErrors | undefined>
    | undefined
    | void;
export type OnSubmitSuccessHandler = () => Promise<undefined> | undefined;

export interface OnSubmitSuccessProps {
    onSubmitSuccess?: OnSubmitSuccessHandler;
}

type SubmitFormReturn = SubmissionErrors | Promise<SubmissionErrors | undefined> | undefined | void;
export type SubmitForm = (
    handleSubmit?: HandleSubmit,
    onSubmitSuccess?: OnSubmitSuccessHandler
) => SubmitFormReturn;
type SubmitFormThunk = (
    handleSubmit?: HandleSubmit,
    onSubmitSuccess?: OnSubmitSuccessHandler
) => ReduxThunkAction<SubmitFormReturn>;
export const submitFormAction: SubmitFormThunk = (
    handleSubmit?: HandleSubmit,
    onSubmitSuccess?: OnSubmitSuccessHandler
) => {
    return async (dispatch, getState) => {
        if (!handleSubmit) {
            return false;
        }

        dispatch(showLoadingAction());
        dispatch(setSubmittingFlag());
        try {
            const errors = await handleSubmit();
            const errorMessage = getErrorMessage(getState, errors);
            if (errorMessage) {
                throw new Error(errorMessage);
            }

            if (onSubmitSuccess) {
                await onSubmitSuccess();
            }

            return errors;
        } catch (error) {
            const errorResponse = getExceptionErrorMessage(error);
            dispatch(
                openDialogAction({
                    content: {
                        title: 'Error',
                        body: React.createElement(JsonErrorView, {
                            errorMessage: errorResponse.errorMessage,
                            jsonString: errorResponse.errorData,
                        } as any),
                        error: true,
                    },
                })
            );
            return false;
        } finally {
            dispatch(setSubmittingFlag(false));
            dispatch(hideLoadingAction());
        }
    };
};

function getErrorMessage(getState: GetState, errors?: Record<string, any> | void) {
    const hasValidationErrors =
        getState().claimSession.formSubmission.hasValidationErrors || (() => false);

    if (hasValidationErrors()) {
        return 'One or more validation errors have occured, please resolve before proceeding';
    }

    if (!!errors && Object.keys(errors).length > 0) {
        const formError = errors[FORM_ERROR];
        if (formError) {
            return formError;
        }

        const frontEndErrors = getFrontEndErrors(errors);
        if (frontEndErrors.length > 0) {
            return frontEndErrors.join('\n');
        }

        return 'An unknown error occured';
    }
}

function getFrontEndErrors(errors: Record<string, any>) {
    const errorMessages = [];
    if (errors.hasOwnProperty('front-end-errors')) {
        const frontEndErrors = errors['front-end-errors'];
        for (const key in frontEndErrors) {
            if (frontEndErrors.hasOwnProperty(key)) {
                const error = frontEndErrors[key];
                errorMessages.push(nameToLabel({ name: key }));
                for (const errorKey in error) {
                    if (error.hasOwnProperty(errorKey)) {
                        const message = nameToLabel({ name: errorKey }) + ': ' + error[errorKey];
                        errorMessages.push(message);
                    }
                }
                errorMessages.push('');
            }
        }
    }
    return errorMessages;
}

function getExceptionErrorMessage(responseError: any) {
    if (responseError.response) {
        if (responseError.response.headers.hasOwnProperty('error-message')) {
            return {
                errorMessage: responseError.response.headers['error-message'],
                errorData: responseError.response.data,
            };
        }

        if (responseError.response.statusText || responseError.response.status === 422) {
            return {
                errorMessage: responseError.response.statusText,
                errorData:
                    responseError.response.status === 422 ? responseError.response.data : null,
            };
        }
    }

    if (responseError.message) {
        return {
            errorMessage: responseError.message,
            errorData: null,
        };
    }

    return {
        errorMessage: 'An unknown error occured',
        errorData: null,
    };
}
