import React from 'react'; // eslint-disable-line

import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import { PolicyBenefitFinalDecisions } from '../../../../retrospective-underwriting-decision/reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { FinalDecisionView } from './final-decision';

type Props = StyledComponent & {
    benefitIndex: number;
    policyBenefitFinalDecisions: PolicyBenefitFinalDecisions;
};
const policyBenefitFinalDecisionsView: React.FunctionComponent<Props> = ({
    classes,
    policyBenefitFinalDecisions,
}: Props) => {
    return (
        <Paper className={classes.paper}>
            <Grid container justify="space-between">
                <Grid item xs={10} lg={11}>
                    <Typography variant="h6">
                        Policy Number - {policyBenefitFinalDecisions.policyNumber}
                    </Typography>
                    <Typography variant="h6">
                        Benefit - {policyBenefitFinalDecisions.benefitName}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5}>
                {policyBenefitFinalDecisions.finalDecisions.map((finalDecisionDetails, index) => (
                    <Grid item xs={12} key={`${finalDecisionDetails.decisionDate}${index}`}>
                        <FinalDecisionView finalDecisionDetails={finalDecisionDetails} />
                    </Grid>
                ))}
            </Grid>
        </Paper>
    );
};

export const PolicyBenefitFinalDecisionsView = withStyles(styles)(policyBenefitFinalDecisionsView);
