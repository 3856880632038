import { reduceAndSubmitClaim } from '../../../../../claim/actions/reduce-and-submit-claim';
import { claimSubmit } from '../../../../../claim';
import { ContactDetails } from '../../../../../contact-details/contact-details';
import { FinancialAdviserContactDetails } from '../../../../../contact-details/financial-adviser/financial-adviser-contact-details';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { getDeathClaimContactDetailsFromClaim, OtherContact } from '../contact-details-reducer';
import { addExecutorRecordActionCreator, addOtherContactRecordActionCreator } from './add-details';
import {
    deleteExecutorRecordActionCreator,
    deleteOtherContactRecordActionCreator,
} from './delete-details';
import {
    updateExecutorRecordActionCreator,
    updateOtherContactRecordActionCreator,
} from './update-details';

/* eslint-disable-next-line */
type SubmitReturn = Promise<any>;

export type AddAndSubmitOtherContactRecord = (otherContact: OtherContact) => SubmitReturn;
type AddAndSubmitOtherContactRecordThunk = (
    otherContact: OtherContact
) => ReduxThunkAction<SubmitReturn>;
export const addAndSubmitOtherContactRecordThunk: AddAndSubmitOtherContactRecordThunk = (
    otherContact: OtherContact
) => (dispatch, getState) =>
    reduceAndSubmitClaim(dispatch, getState, addOtherContactRecordActionCreator(otherContact));

export type UpdateAndSubmitOtherContactRecord = (
    otherContactRecordIndex: number,
    otherContactRecord: OtherContact
) => SubmitReturn;
type UpdateAndSubmitOtherContactRecordThunk = (
    otherContactRecordIndex: number,
    otherContactRecord: OtherContact
) => ReduxThunkAction<SubmitReturn>;
export const updateAndSubmitOtherContactRecordThunk: UpdateAndSubmitOtherContactRecordThunk = (
    otherContactRecordIndex: number,
    otherContactRecord: OtherContact
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        updateOtherContactRecordActionCreator(otherContactRecordIndex, otherContactRecord)
    );

export type DeleteAndSubmitOtherContactRecord = (otherContactRecordIndex: number) => SubmitReturn;
type DeleteAndSubmitOtherContactRecordThunk = (
    otherContactRecordIndex: number
) => ReduxThunkAction<SubmitReturn>;
export const deleteAndSubmitOtherContactRecordThunk: DeleteAndSubmitOtherContactRecordThunk = (
    otherContactRecordIndex: number
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        deleteOtherContactRecordActionCreator(otherContactRecordIndex)
    );

export type AddAndSubmitExecutorRecord = (executor: ContactDetails) => SubmitReturn;
type AddAndSubmitExecutorRecordThunk = (executor: ContactDetails) => ReduxThunkAction<SubmitReturn>;
export const addAndSubmitExecutorRecordThunk: AddAndSubmitExecutorRecordThunk = (
    executor: ContactDetails
) => (dispatch, getState) =>
    reduceAndSubmitClaim(dispatch, getState, addExecutorRecordActionCreator(executor));

export type UpdateAndSubmitExecutorRecord = (
    executorRecordIndex: number,
    executorRecord: ContactDetails
) => SubmitReturn;
type UpdateAndSubmitExecutorRecordThunk = (
    executorRecordIndex: number,
    executorRecord: ContactDetails
) => ReduxThunkAction<SubmitReturn>;
export const updateAndSubmitExecutorRecordThunk: UpdateAndSubmitExecutorRecordThunk = (
    executorRecordIndex: number,
    executorRecord: ContactDetails
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        updateExecutorRecordActionCreator(executorRecordIndex, executorRecord)
    );

export type DeleteAndSubmitExecutorRecord = (executorRecordIndex: number) => SubmitReturn;
type DeleteAndSubmitExecutorRecordThunk = (
    executorRecordIndex: number
) => ReduxThunkAction<SubmitReturn>;
export const deleteAndSubmitExecutorRecordThunk: DeleteAndSubmitExecutorRecordThunk = (
    executorRecordIndex: number
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        deleteExecutorRecordActionCreator(executorRecordIndex)
    );

export type UpdateAndSubmitFinancialAdviser = (
    financialAdviser: FinancialAdviserContactDetails
) => SubmitReturn;
type UpdateAndSubmitFinancialAdviserAction = (
    financialAdviser: FinancialAdviserContactDetails
) => ReduxThunkAction<SubmitReturn>;

export const updateAndSubmitFinancialAdviser: UpdateAndSubmitFinancialAdviserAction = (
    financialAdviser: FinancialAdviserContactDetails
) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, claim => {
            const currentContactDetails = getDeathClaimContactDetailsFromClaim(claim);
            return {
                ...claim,
                contactDetails: {
                    ...currentContactDetails,
                    financialAdviser,
                },
            };
        });
    };
};
