import { ReduxAction } from '../../redux/redux-actions';
import { Doctor } from '../reducer';
import { UPDATE_DOCTOR } from '../types';

export interface UpdateDoctorAction extends ReduxAction<Doctor> {
    index: number;
}
export type UpdateDoctor = (record: Doctor, index: number) => UpdateDoctorAction;
export const updateDoctorAction: UpdateDoctor = (details: Doctor, index: number) => {
    return {
        type: UPDATE_DOCTOR,
        payload: details,
        index,
    };
};
