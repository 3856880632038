import React from 'react'; // eslint-disable-line
import { ButtonWrapper } from '../../../../../forms';
import { Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { useState } from 'react';
import styles, { StyledComponent } from '../../../../../styles/styles';

interface ToggleProps extends StyledComponent {
    expandedView: React.ReactNode;
    minimisedView: React.ReactNode;
}

const ViewToggle: React.FunctionComponent<ToggleProps> = ({
    classes,
    expandedView,
    minimisedView,
}: ToggleProps) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleState = (evt: any) => {
        setIsExpanded(value => !value);
        evt.preventDefault();
        return false;
    };
    const expanderElement = (
        <span onClick={toggleState} style={{ height: '27px', width: '70px' }}>
            {isExpanded ? 'View less' : 'View more'}
        </span>
    );
    const expendedTooltip = !isExpanded ? 'View more' : 'View less';
    const backgroundColor = isExpanded
        ? {
              backgroundColor: '#F0F1F4',
              paddingRight: '8px',
          }
        : { paddingRight: '8px' };
    return (
        <Grid container spacing={0} justify="flex-start" style={backgroundColor}>
            {!isExpanded && (
                <Grid container justify="flex-start">
                    {minimisedView}
                </Grid>
            )}
            {isExpanded && (
                <Grid container justify="flex-start" style={{ marginLeft: '8px' }}>
                    {expandedView}
                </Grid>
            )}
            <ButtonWrapper>
                <Grid item xs={12} container justify="flex-end">
                    <Typography>
                        <a href="/#" className={classes.resultsActionLink}>
                            <Tooltip title={expendedTooltip}>{expanderElement}</Tooltip>
                        </a>
                    </Typography>
                </Grid>
            </ButtonWrapper>
        </Grid>
    );
};

export const DependantViewToggle = withStyles(styles)(ViewToggle);
