import axios from 'axios';
import { claimUpdatedThunk } from '../../../../../claim';
import { openDialogAction } from '../../../../../confirmation-dialog';
import { env } from '../../../../../env';
import { hideLoadingAction, showLoadingAction } from '../../../../../forms/loading-screen/actions';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';

type UpdateLifeAssuredContractsReturn = Promise<any>;
export type UpdateLifeAssuredContracts = () => UpdateLifeAssuredContractsReturn;
type UpdateLifeAssuredContractsAction = () => ReduxThunkAction<UpdateLifeAssuredContractsReturn>;
export const updateLifeAssuredContractsAction: UpdateLifeAssuredContractsAction = () => {
    return async (dispatch, getState) => {
        dispatch(showLoadingAction());
        const claim = getState().claimSession.claim;
        const notificationUri = `${env().CLAIMS_API}/api/Claim/${
            claim.claimId
        }/UpdateLifeAssuredContracts`;
        await axios
            .get(notificationUri)
            .then(response => {
                if (response.data) {
                    dispatch(claimUpdatedThunk(response.data));
                }
            })
            .catch(error => {
                let errorMessage = '';
                if (error.response) {
                    errorMessage = `Error Code ${error.response.status}`;
                } else {
                    errorMessage = error.message;
                }
                showErrorMessage(errorMessage);
            })
            .then(() => dispatch(hideLoadingAction()));

        function showErrorMessage(error: any) {
            const errorMessage = `An error (${error}) occured while updating policy details, please retry later`;
            dispatch(
                openDialogAction({
                    content: {
                        title: 'Error updating policy details',
                        body: errorMessage,
                        error: true,
                    },
                })
            );
        }
    };
};
