import React from 'react'; // eslint-disable-line

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
    withStyles,
} from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { State } from '../redux/root-reducer';
import styles, { StyledComponent } from '../styles/styles';
import { SubmitForm, submitFormAction } from '../workflow-navigation';
import { CloseDialog, closeDialogAction, DialogDetails } from './';
import { DialogAction, DialogContentDetails } from './reducer';

interface OwnProps {}

interface StateProps {
    dialogDetails: DialogDetails;
}

interface DispatchProps {
    closeDialog: CloseDialog;
    submitForm: SubmitForm;
}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const confirmationDialog: React.FunctionComponent<Props> = ({
    dialogDetails,
    closeDialog,
    submitForm,
}: Props) => {
    const executeDialogAction = async (dialogAction?: DialogAction) => {
        closeDialog();
        if (dialogAction) {
            await submitForm(async () => {
                const result = await dialogAction.action();
                return result || {};
            });
        }
    };
    const cancelAction = () => {
        executeDialogAction();
    };

    const noAction = () => {
        executeDialogAction(dialogDetails.noAction);
    };
    const yesAction = () => {
        executeDialogAction(dialogDetails.yesAction);
    };

    const dialogContent: DialogContentDetails = dialogDetails.content;
    return (
        <Dialog open={dialogDetails.isOpen} fullWidth>
            <DialogTitle>{getTitle(dialogContent)}</DialogTitle>
            <DialogContent>
                <Typography variant="subtitle2" style={{ whiteSpace: 'pre-line' }}>
                    {dialogContent.body}
                </Typography>
            </DialogContent>
            <DialogActions>
                {getDialogActions(dialogDetails, cancelAction, noAction, yesAction)}
            </DialogActions>
        </Dialog>
    );
};

const getTitle = (dialogContent: DialogContentDetails) => {
    if (dialogContent.error) {
        return <ErrorTitle />;
    }

    return dialogContent.title;
};

const ErrorTitle = () => (
    <Grid container spacing={0} justify="flex-start">
        <Grid item xs={1}>
            <Error color="error" fontSize="large" />
        </Grid>
        <Grid item xs={11}>
            <Typography variant="h5">...Oops</Typography>
        </Grid>
    </Grid>
);

const getDialogActions = (
    dialogDetails: DialogDetails,
    cancelAction: () => void,
    noAction: () => void,
    yesAction: () => void
) => {
    if (!dialogDetails.yesAction && !dialogDetails.noAction) {
        return (
            <Button id="dialogClose" onClick={cancelAction}>
                {'Close'}
            </Button>
        );
    }

    const noLabel = (dialogDetails.noAction ? dialogDetails.noAction.label : null) || 'No';
    const yesLabel = (dialogDetails.yesAction ? dialogDetails.yesAction.label : null) || 'Yes';
    return (
        <React.Fragment>
            {dialogDetails.canCancel && (
                <Button id="dialogCancel" onClick={cancelAction}>
                    {dialogDetails.cancelLabel || 'Cancel'}
                </Button>
            )}
            <Button id="dialogNo" onClick={noAction}>
                {noLabel}
            </Button>
            <Button id="dialogYes" onClick={yesAction} color="secondary">
                {yesLabel}
            </Button>
        </React.Fragment>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = state => ({
    dialogDetails: state.confirmationDialog,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    closeDialog: () => dispatch(closeDialogAction()),
    submitForm: handleSubmit => dispatch(submitFormAction(handleSubmit)),
});

export const ConfirmationDialog = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(confirmationDialog));
