import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { OpenModal, openModalAction } from '../../../../../form-modal/actions';
import { FormModalView } from '../../../../../form-modal/views/form-modal';
import { ButtonWrapper } from '../../../../../forms';
import { ExpansionPanel } from '../../../../../forms/form-components/expansion-panel/expansion-panel';
import { State } from '../../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { getPoliceReport, PoliceReport } from '../police-report-reducer';
import { PoliceReportEditModal } from './police-report-form';

interface Props extends StyledComponent {
    policeReport: PoliceReport;
    isReadOnly: boolean;
    hasDeferredContracts: boolean;
    openModal: OpenModal;
}

const policeReportDetails = ({
    policeReport,
    isReadOnly,
    hasDeferredContracts,
    openModal,
    classes,
}: Props) => {
    const openEditView = () => {
        openModal({
            modalContent: <FormModalView formView={PoliceReportEditModal} />,
        });
    };

    return (
        <ExpansionPanel name="Police Report">
            <Grid item xs={12} container justify="flex-start" className={classes.marginTop}>
                <Grid item xs={10}>
                    <Typography className={classes.heading} variant="body1">
                        Police Report
                    </Typography>
                </Grid>
                {!hasDeferredContracts && (
                    <ButtonWrapper>
                        <Grid item xs={2} className={classes.rightAlign}>
                            <Tooltip title="Edit Police Report">
                                <Button
                                    id="EditPoliceReport"
                                    color="secondary"
                                    onClick={openEditView}
                                >
                                    <Edit />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </ButtonWrapper>
                )}
                <Grid item xs={6} lg={3}>
                    <LabeledValue label="Case Number" value={policeReport.caseNumber} />
                </Grid>
                <Grid item xs={6} lg={3}>
                    <LabeledValue label="Case Date" value={getDisplayDate(policeReport.caseDate)} />
                </Grid>
                <Grid item xs={6} lg={3}>
                    <LabeledValue label="Police Station" value={policeReport.policeStation} />
                </Grid>
                <Grid item xs={6} lg={3}>
                    <LabeledValue
                        label="Investigating Officer"
                        value={policeReport.investigatingOfficer}
                    />
                </Grid>
                <Grid item xs={6} lg={3}>
                    <LabeledValue label="Telephone Number" value={policeReport.telephoneNumber} />
                </Grid>
            </Grid>
        </ExpansionPanel>
    );
};

const mapStateToProps = (state: State) => ({
    policeReport: getPoliceReport(state),
    isReadOnly: state.claimSession.readOnly,
    hasDeferredContracts: state.claimSession.hasDeferredContracts,
});

const mapDispatchToProps = { openModal: openModalAction };

export const PoliceReportView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(policeReportDetails));
