import { paramCase } from 'change-case';
import { ComponentType } from 'react';

export type ComponentGenerator = () => ComponentType<any>;
export class WorkflowScreen {
    public readonly label: string;
    public readonly parent?: WorkflowScreen;
    public readonly ancestors: WorkflowScreen[];
    public readonly screenHeading?: string;
    public readonly canShow?: () => boolean;

    protected readonly component?: React.ComponentType<any> | ComponentGenerator;
    private _view: React.ComponentType<any> | undefined = undefined;
    private _path: string | undefined;
    private _pathPart: string | undefined;

    public constructor(
        label: string,
        component?: React.ComponentType<any> | ComponentGenerator,
        parent?: WorkflowScreen,
        screenHeading?: string,
        canShow?: () => boolean
    ) {
        this.label = label;
        this.component = component;
        this.parent = parent;
        this.ancestors = Array.from(this.getAncestors());
        this.screenHeading = screenHeading;
        this.canShow = canShow;
    }

    public cloneWithNewParent(parent?: WorkflowScreen) {
        return new WorkflowScreen(
            this.label,
            this.component,
            parent,
            this.screenHeading,
            this.canShow
        );
    }

    public readonly pathPart = () =>
        !this._pathPart ? (this._pathPart = paramCase(this.label)) : this._pathPart;
    public readonly path = () => (!this._path ? (this._path = this.getPath()) : this._path);
    public readonly view = () => (!this._view ? (this._view = this.getView()) : this._view);

    protected getPath(): string {
        if (!this.parent) {
            return '';
        }

        const parentPath = this.parent.path();

        return [parentPath, this.pathPart()].join('/');
    }

    private getView(): ComponentType<any> | undefined {
        if (this.component instanceof Function && this.component.name === 'ComponentGenerator') {
            return (this.component as ComponentGenerator)();
        }

        return this.component as ComponentType<any>;
    }

    private *getAncestors(): IterableIterator<WorkflowScreen> {
        if (this.parent) {
            yield* this.parent.ancestors;
            yield this.parent;
        }
    }
}
