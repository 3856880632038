import { Claim, claimSubmit } from '../../../../../claim';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { getLifeAssuredDetailsFromClaim } from '../../../../../life-assured-details/reducer';

interface UpdateModel {
    claimLifeNotificationsUpdated: string;
}
type UpdateClaimLifeNotificationReturn = Promise<any>;
export type UpdateClaimLifeNotification = (
    values: UpdateModel
) => UpdateClaimLifeNotificationReturn;
type UpdateClaimLifeNotificationAction = (
    values: UpdateModel
) => ReduxThunkAction<UpdateClaimLifeNotificationReturn>;

export const updateClaimLifeNotificationAction: UpdateClaimLifeNotificationAction = (
    values: UpdateModel
) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, getUpdatedClaim(values));
    };
};

function getUpdatedClaim(values: UpdateModel): (claim: Claim) => Claim {
    return (claim: Claim) => {
        const lifeAssuredDetails = getLifeAssuredDetailsFromClaim(claim);
        return {
            ...claim,
            lifeAssuredDetails: {
                ...lifeAssuredDetails,
                ...values,
            },
        };
    };
}
