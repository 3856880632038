import * as Redux from 'redux';
import { Claim, getClaim, getProperty, getPropertyFromClaim } from '../claim/reducers';
import { AddressDetails } from '../forms/sub-forms/address-details';
import {
    addRecordReduction,
    deleteRecordReduction,
    updateRecordReduction,
} from '../redux/reductions';
import { State } from '../redux/root-reducer';
import { Nullable } from '../shared-types';
import { AddPreviousOccupationAction } from './actions/add-previous-occupation';
import { DeletePreviousOccupationAction } from './actions/delete-previous-occupation';
import { UpdatePreviousOccupationAction } from './actions/update-previous-occupation';
import {
    ADD_PREVIOUS_OCCUPATION,
    DELETE_PREVIOUS_OCCUPATION,
    UPDATE_PREVIOUS_OCCUPATION,
} from './types';

// tslint:disable-next-line:no-empty-interface
export interface CurrentOccupationDetails extends AddressDetails {
    employerName?: string;
    dateEmployedFrom?: Date;
    dateEmployedTo?: Date;
    occupationBeforeDisability?: string;
}
export const defaultCurrentOccupationDetails = {};

// tslint:disable-next-line:no-empty-interface
export interface OccupationalDutiesBreakdown {
    admin?: number;
    supervisory?: number;
    manual?: number;
    travel?: number;
    occupationDescription?: string;
    hazard?: string;
    hazardDetails?: string;
    internationalTravel?: string;
    internationalTravelDetails?: string;
    dateWhenStopped?: Date;
    dateWhenReturning?: Date;
    avocations?: string[];
}
export const defaultDutyBreakdown = {};

// tslint:disable-next-line:no-empty-interface
export interface AlternativeOccupationDetails {
    alternativeOccupationOffered?: string;
    alternativeOccupationDetails?: string;
    alternativeOccupationAccepted?: string;
    alternativeOccupationBasis?: 'Full Time' | 'Part Time';
}
export const defaultAlternativeOccupationDetails = {};

// tslint:disable-next-line:no-empty-interface
export interface PreviousOccupation {
    employer?: string;
    natureOfOccupation?: string;
    dateEmployedFrom?: Date;
    dateEmployedTo?: Date;
}

export interface ClaimInformation {
    readonly guaranteedPaymentPeriodClaims: string[];
    readonly permanentImpairmentClaims: string[];
    readonly currentOccupationDetails: CurrentOccupationDetails;
    readonly dutyBreakdown: OccupationalDutiesBreakdown;
    readonly alternativeOccupationDetails: AlternativeOccupationDetails;
    readonly previousOccupations: PreviousOccupation[];
}

export const defaultClaimInformation = {
    guaranteedPaymentPeriodClaims: [],
    permanentImpairmentClaims: [],
    currentOccupationDetails: defaultCurrentOccupationDetails,
    dutyBreakdown: defaultDutyBreakdown,
    alternativeOccupationDetails: defaultAlternativeOccupationDetails,
    previousOccupations: [],
};

type Action =
    | Redux.Action
    | AddPreviousOccupationAction
    | UpdatePreviousOccupationAction
    | DeletePreviousOccupationAction;

export const claimInformationReducer: Redux.Reducer<Nullable<ClaimInformation>, Action> = (
    state: Nullable<ClaimInformation> = null,
    action: Action
) => {
    const stateOrDefault = state || defaultClaimInformation;
    const previousOccupations = stateOrDefault.previousOccupations || [];

    switch (action.type) {
        case ADD_PREVIOUS_OCCUPATION:
            return upatedState(
                stateOrDefault,
                addRecordReduction(
                    previousOccupations,
                    (action as AddPreviousOccupationAction).payload
                )
            );
        case UPDATE_PREVIOUS_OCCUPATION:
            const updatePreviousOccupationAction: UpdatePreviousOccupationAction = action as UpdatePreviousOccupationAction;
            return upatedState(
                stateOrDefault,
                updateRecordReduction(
                    previousOccupations,
                    updatePreviousOccupationAction.payload,
                    updatePreviousOccupationAction.index
                )
            );
        case DELETE_PREVIOUS_OCCUPATION:
            return upatedState(
                stateOrDefault,
                deleteRecordReduction(
                    previousOccupations,
                    (action as DeletePreviousOccupationAction).index
                )
            );
    }
    return state;
};

function upatedState(state: ClaimInformation, previousOccupations: PreviousOccupation[]) {
    return {
        ...state,
        previousOccupations,
    };
}

export function getClaimInformation(state: State) {
    return getClaimInformationFromClaim(getClaim(state));
}

export function getClaimInformationFromClaim(claim: Claim) {
    return getPropertyFromClaim(claim, 'claimInformation', defaultClaimInformation);
}

export function getPreviousOccupationalDuties(state: State) {
    return getProperty(state, 'previousOccupationalDuties', defaultDutyBreakdown);
}
