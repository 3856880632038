import axios from 'axios';
import { showLoadingAction, hideLoadingAction } from '../../forms/loading-screen/actions';
import { env } from '../../env';
import { Claim, claimUpdatedThunk } from '../../claim';
import { openDialogAction } from '../../confirmation-dialog';
import { ReduxThunkAction } from '../../redux/redux-actions';

export interface PaymentRequest {
    beneficiaryId: string;
    paymentId: string;
    comments?: string;
}

type PayLiabilityReturn = Promise<any>;
export type PayLiability = (paymentRequest: PaymentRequest) => PayLiabilityReturn;
type PayLiabilityAction = (paymentRequest: PaymentRequest) => ReduxThunkAction<PayLiabilityReturn>;
export const payLiabilityAction: PayLiabilityAction = (paymentRequest: PaymentRequest) => {
    return async (dispatch, getState) => {
        const claim = getState().claimSession.claim;
        const paymentUri = `${env().CLAIMS_API}/api/Claim/Payment`;
        const request = {
            beneficiaryId: paymentRequest.beneficiaryId,
            claimId: claim.claimId,
            comments: paymentRequest.comments,
            paymentId: paymentRequest.paymentId,
        };
        await submitPayment(dispatch, paymentUri, request);
    };
};

export type PayLiabilities = (beneficiaryId: string) => PayLiabilityReturn;
type PayLiabilitiesAction = (beneficiaryId: string) => ReduxThunkAction<PayLiabilityReturn>;
export const payLiabilitiesAction: PayLiabilitiesAction = (beneficiaryId: string) => {
    return async (dispatch, getState) => {
        const claim = getState().claimSession.claim;
        const paymentUri = `${env().CLAIMS_API}/api/Claim/${
            claim.claimId
        }/Payment?beneficiaryId=${beneficiaryId}`;

        await submitPayment(dispatch, paymentUri, undefined);
    };
};

async function submitPayment(dispatch: any, paymentUri: string, request?: any) {
    dispatch(showLoadingAction());
    await axios
        .post<Claim>(paymentUri, request)
        .then(response => {
            if (response.data) {
                dispatch(claimUpdatedThunk(response.data));
            }
            dispatch(
                openDialogAction({
                    content: {
                        title: 'Payment successful',
                        body: 'payment submitted successfully',
                    },
                })
            );
        })
        .catch(error => {
            let errorMessage = '';
            if (error.response) {
                let code = error.response.status;
                let message = error.response.statusText;
                if (error.response.data) {
                    message = error.response.data;
                }
                if (error.response.data && error.response.data.error) {
                    code = error.response.data.error.errorCode;
                    message = error.response.data.error.errorMessage;
                }
                errorMessage = `Error Code: ${code}\nError Message: ${message}`;
            } else {
                errorMessage = error.message;
            }
            showErrorMessage(errorMessage);
        })
        .then(() => dispatch(hideLoadingAction()));

    function showErrorMessage(errorMessage: any) {
        dispatch(
            openDialogAction({
                content: {
                    title: 'Error submitting payment',
                    body: errorMessage,
                    error: true,
                },
            })
        );
    }
}
