import { getProperty } from '../claim';
import { collectionReducer } from '../redux/collection-reducer/collection-reducer';
import { combineReducersWithDefault } from '../redux/combine-reducers-with-default';
import { State } from '../redux/root-reducer';
import { Nullable } from '../shared-types';
import {
    ADD_INFORMATION_RECEIVED_RECORD,
    DELETE_INFORMATION_RECEIVED_RECORD,
    UPDATE_INFORMATION_RECEIVED_RECORD,
} from './types';

export interface InformationReceivedRecord {
    readonly id?: string;
    readonly documentID?: string;
    readonly documentName?: string;
    readonly documentDescription?: string;
    readonly dateOfReport?: Date;
    readonly assessor?: string;
    readonly dateOfSummary?: Date;
    readonly summary?: string;
    readonly sendToUnderwriter?: boolean;
}

export interface InformationReceivedSummary {
    readonly documents: InformationReceivedRecord[];
}

export const defaultDocuments: InformationReceivedRecord[] = [];
export const defaultInformationReceivedSummary: InformationReceivedSummary = {
    documents: defaultDocuments,
};

export const informationReceivedRecordReducer = collectionReducer<InformationReceivedRecord>({
    addAction: ADD_INFORMATION_RECEIVED_RECORD,
    deleteAction: DELETE_INFORMATION_RECEIVED_RECORD,
    updateAction: UPDATE_INFORMATION_RECEIVED_RECORD,
});

export const informationReceivedSummaryReducer = combineReducersWithDefault<
    Nullable<InformationReceivedSummary>
>(defaultInformationReceivedSummary, {
    documents: informationReceivedRecordReducer,
});

export function getInformationReceivedSummary(state: State): InformationReceivedSummary {
    return getProperty(state, 'informationReceivedSummary', defaultInformationReceivedSummary);
}
