import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { reduceAndSubmitClaim } from '../../../../../claim/actions/reduce-and-submit-claim';
import { UpdateRecordCollectionAction } from '../../../../../redux/collection-reducer';
import { EDIT_DISPUTE_RECORD } from '../types';
import { Dispute } from '../reducer';

type SubmitReturn = Promise<any>;
export type EditAndSubmitDisputeRecord = (dispute: Dispute, disputeIndex: number) => SubmitReturn;
type EditAndSubmitDisputeRecordThunk = (
    dispute: Dispute,
    disputeIndex: number
) => ReduxThunkAction<SubmitReturn>;

export const editAndSubmitDisputeRecordThunk: EditAndSubmitDisputeRecordThunk = (
    dispute: Dispute,
    disputeIndex: number
) => (dispatch, getState) =>
    reduceAndSubmitClaim(dispatch, getState, editDisputeActionCreator(dispute, disputeIndex));

export type EditDisputeRecordAction = UpdateRecordCollectionAction<Dispute>;

export const editDisputeActionCreator = (
    dispute: Dispute,
    disputeIndex: number
): EditDisputeRecordAction => {
    return {
        type: EDIT_DISPUTE_RECORD,
        record: dispute,
        index: disputeIndex,
    };
};
