import React from 'react'; // eslint-disable-line
import { FuneralAssessmentDetailsView } from './funeral-assessment-details-view';
import { AssessmentDecisionAddView } from './assessment-decision-add';

export const FuneralAssessmentView: React.FunctionComponent = () => {
    return (
        <FuneralAssessmentDetailsView
            assessmentDecisionAddView={props => <AssessmentDecisionAddView {...props} />}
        />
    );
};
