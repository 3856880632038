import React from 'react'; // eslint-disable-line
import { Grid, Tooltip, Button, Typography, Divider } from '@material-ui/core';
import { FullWidthTextField, FullWidthSearchSelectField } from '../../../../../forms';
import { requiredValidator } from '../../../../../forms/validations';
import { AddCircle, Delete } from '@material-ui/icons';
import { LiquidAndDistribution } from '../liquid-and-distribution-reducer';
import { CurrencyMask } from '../../../../../forms/input-masks';
import { Dependant } from '../../dependants/reducer';
import { convertToNumber } from '../../../../../shared-functions';

interface ComponentState {
    liquidAndDistribution: LiquidAndDistribution[];
}
interface LiquidAndDistributionProps {
    classes: any;
    liquidAndDistribution: LiquidAndDistribution[];
    posibleDependants: Dependant[];
}
export class UpdateLiquidAndDistributionView extends React.Component<
    LiquidAndDistributionProps,
    ComponentState
> {
    public constructor(props: LiquidAndDistributionProps) {
        super(props);
        if (props.liquidAndDistribution === null) {
            this.setState({
                liquidAndDistribution: [],
            });
        }
    }
    public render() {
        let liquidAndDistribution = this.props.liquidAndDistribution
            ? this.props.liquidAndDistribution
            : [];
        if (liquidAndDistribution.length === 0) {
            liquidAndDistribution = this.state ? this.state.liquidAndDistribution : [];
        }
        const classes = this.props.classes;
        const addEntry = () => {
            liquidAndDistribution.push({
                dependent: '',
                description: '',
                amountPayable: 0,
                lastDateUpdated: new Date(),
            });
            this.setState({
                liquidAndDistribution: liquidAndDistribution,
            });
            this.forceUpdate();
        };
        const deleteEntry = (index: number) => {
            liquidAndDistribution.splice(index, 1);
            this.setState({
                liquidAndDistribution: liquidAndDistribution,
            });
            this.forceUpdate();
        };
        const dependents = getPosibleDependents(this.props.posibleDependants);
        return (
            <React.Fragment>
                <Typography variant="h5">Add L&D Details</Typography>
                <Divider />
                {liquidAndDistribution.map((_pm, index) => {
                    return (
                        <Grid item xs={12} container spacing={5} key={`pm_${index}`}>
                            <Grid item xs={4} lg={4}>
                                <FullWidthSearchSelectField
                                    name={`liquidAndDistribution[${index}].dependent`}
                                    label="Dependant"
                                    validate={requiredValidator}
                                    options={dependents}
                                />
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <FullWidthTextField
                                    id="description"
                                    label="L&D Description"
                                    name={`liquidAndDistribution[${index}].description`}
                                    validate={requiredValidator}
                                />
                            </Grid>
                            <Grid item xs={2} lg={2}>
                                <FullWidthTextField
                                    id="amountPayable"
                                    name={`liquidAndDistribution[${index}].amountPayable`}
                                    label="Amount Payable"
                                    mask={CurrencyMask}
                                    parse={convertToNumber}
                                    validate={requiredValidator}
                                />
                            </Grid>
                            <Grid item xs={2} lg={2}>
                                <Tooltip
                                    title="Delete L&D Details"
                                    style={{
                                        marginTop: '30px',
                                        marginLeft: '35px',
                                    }}
                                >
                                    <Button
                                        id={`Delete_beneficiary_${index}`}
                                        color="secondary"
                                        onClick={() => deleteEntry(index)}
                                    >
                                        <Delete />
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    );
                })}
                <Grid container spacing={0} justify="flex-end" className={classes.marginTop3}>
                    <Tooltip title="Add L&D Details">
                        <Button
                            id="AddAddLiquidDetails"
                            color="secondary"
                            className={classes.button}
                            style={{ textTransform: 'none' }}
                            onClick={() => addEntry()}
                        >
                            <AddCircle />
                            Add L&D Details
                        </Button>
                    </Tooltip>
                </Grid>
            </React.Fragment>
        );
    }
}
const getPosibleDependents = (dependents: Dependant[]) => {
    const dependants: any[] = [];
    dependents.forEach(dependent => {
        const organisationDetails =
            dependent && dependent.organisationDetails ? dependent.organisationDetails : undefined;
        const personalDetails =
            dependent && dependent.personalDetails ? dependent.personalDetails : undefined;
        const authorizedPerson =
            dependent &&
            dependent.organisationDetails &&
            dependent.organisationDetails.authorisedPerson
                ? dependent.organisationDetails.authorisedPerson
                : undefined;
        if (personalDetails !== undefined && dependent.dependantType === 'Individual') {
            dependants.push({
                value: dependent.id,
                label: `${personalDetails.firstName} ${personalDetails.lastName} - ${dependent.relationship}`,
            });
        }
        if (organisationDetails !== undefined && personalDetails !== undefined) {
            dependants.push({
                value: dependent.id,
                label: `${personalDetails.firstName} ${personalDetails.lastName} - ${organisationDetails.relationshipToLifeAssured}`,
            });
        }
        if (organisationDetails !== undefined && authorizedPerson !== undefined) {
            const personalDetails =
                authorizedPerson && authorizedPerson.personalDetails
                    ? authorizedPerson.personalDetails
                    : {};
            dependants.push({
                value: dependent.id,
                label: `${personalDetails.firstName} ${personalDetails.lastName} - ${organisationDetails.relationshipToLifeAssured}`,
            });
        }
    });
    return dependants;
};
