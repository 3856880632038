import React from 'react'; // eslint-disable-line

import { AssessmentDetailsView } from '../../common/views/assessment-details';
import { AssessmentDecisionAddView } from '../../common/views/assessment-decision-add';
import { RetrenchmentAssessmentDecisionFields } from './retrenchment-assessment-details-fields';

export const RetrenchmentAssessmentDetailsView: React.FunctionComponent = () => {
    return (
        <AssessmentDetailsView
            assessmentDecisionAddView={props => (
                <AssessmentDecisionAddView
                    {...props}
                    claimSpecificApproveElements={
                        <RetrenchmentAssessmentDecisionFields {...props} />
                    }
                />
            )}
        />
    );
};
