import { CONTRACTS_LOADED } from '..';
import { ReduxAction } from '../../redux/redux-actions';
import { ClaimType } from '../../claim/claim-types';

export interface Contract {
    clientId: string;
    claimTypes: ClaimType[];
    contractNumber: string;
    inceptionDate: Date;
    status: 'INFORCE' | 'OUTOFFORCE';
}

export type ContractsLoadedAction = ReduxAction<Contract[]>;

export type ContractsLoaded = (results: Contract[]) => ContractsLoadedAction;

export const contractsLoaded: ContractsLoaded = (contracts: Contract[]) => {
    return {
        type: CONTRACTS_LOADED,
        payload: contracts,
    };
};
