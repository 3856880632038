import { PaymentType } from './payment-types';

export enum PayeeTypeEnum {
    Beneficiary = 'Beneficiary',
    InternalTransfer = 'InternalTransfer',
    ExternalTransfer = 'ExternalTransfer',
    IT88 = 'IT88',
    PAYE = 'PAYE',
    Owner = 'Owner',
    Payer = 'Payer',
}

export const PayeeType = [
    {
        payments: [PaymentType.Interest, PaymentType.Claim, PaymentType.Refund],
        value: PayeeTypeEnum.InternalTransfer,
        label: 'Internal Transfer',
    },
    { payments: [PaymentType.Tax], value: PayeeTypeEnum.IT88, label: 'IT88' },
    { payments: [PaymentType.Tax], value: PayeeTypeEnum.PAYE, label: 'PAYE' },
    {
        payments: [PaymentType.Interest, PaymentType.Claim, PaymentType.Refund],
        value: PayeeTypeEnum.ExternalTransfer,
        label: 'External Transfer',
    },
    {
        payments: [PaymentType.Interest, PaymentType.Claim, PaymentType.Refund],
        value: PayeeTypeEnum.Beneficiary,
        label: 'Beneficiary',
    },
];

export const PayeeTypesDreadDisability = [
    { payments: [PaymentType.Tax], value: PayeeTypeEnum.IT88, label: 'IT88' },
    { payments: [PaymentType.Tax], value: PayeeTypeEnum.PAYE, label: 'PAYE' },
    {
        payments: [PaymentType.Interest, PaymentType.Claim, PaymentType.Refund],
        value: PayeeTypeEnum.ExternalTransfer,
        label: 'External Transfer',
    },
    {
        payments: [PaymentType.Interest, PaymentType.Claim, PaymentType.Refund],
        value: PayeeTypeEnum.Beneficiary,
        label: 'Beneficiary',
    },
];

export function PayeeTypeCodeList() {
    return PayeeType.map(payeeType => {
        return {
            value: payeeType.value,
            label: payeeType.label,
        };
    });
}
