import React from 'react'; // eslint-disable-line

import { Button, Grid, Tooltip, withStyles } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { v4 as uuid } from 'uuid';
import { AddFormModal } from '../../../../../form-modal';
import { openAddModalAction, OpenAddModal } from '../../../../../form-modal/actions';
import { State } from '../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../styles/styles';
import {
    AddAndSubmitClaimantRecord,
    addAndSubmitClaimantRecordThunk,
} from '../actions/submit-details';
import { ClaimantContactDetailsEditView } from './claimant-contact-details-edit';
import { Claimant, ClaimantDetails } from '../../contact-details/contact-details-reducer';
import { BankingDetails } from '../../../common/banking-details/banking-details';
import { ButtonWrapper } from '../../../../../forms';

interface OwnProps {}

interface StateProps {
    isReadOnly: boolean;
}
interface DispatchProps {
    addClaimant: AddAndSubmitClaimantRecord;
    openAddModal: OpenAddModal;
}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
/* eslint-disable-next-line */
const ContactDetailsAddFormModal = AddFormModal<any>();
const addClaimantDetails: React.FunctionComponent<Props> = ({
    addClaimant,
    classes,
    openAddModal,
    isReadOnly,
}: Props) => {
    return (
        <Grid container justify="flex-start">
            <ButtonWrapper>
                <Grid item xs={12} className={classes.rightAlign}>
                    <Button id="AddClaimantDetails" onClick={openAddView}>
                        <Tooltip title="Add Claimant Details">
                            <AddCircle color="secondary" />
                        </Tooltip>
                    </Button>
                </Grid>
            </ButtonWrapper>
        </Grid>
    );
    function openAddView() {
        const claimant = getBlankClaimant();
        openAddModal({
            modalContent: (
                <ContactDetailsAddFormModal
                    blankRecord={claimant}
                    recordDetailView={<ClaimantContactDetailsEditView />}
                    addRecord={async value => {
                        await addClaimant(value);
                    }}
                />
            ),
            modalData: {},
        });
    }
};
const getBlankClaimant = () => {
    const bankingDetails: BankingDetails = {
        accountHolderRelationship: undefined,
        accountType: undefined,
        accountHoldersName: '',
        accountNumber: undefined,
        bankName: '',
        bankBranch: '',
        bankAccountStatus: 'Not verified',
        branchCode: '',
    };
    const claimantDetails: ClaimantDetails = {
        deletable: false,
        claimantType: undefined,
        communicationDetails: undefined,
        organisationDetails: undefined,
        guardianDetails: undefined,
        residentialAddress: undefined,
        personalDetails: undefined,
        postalAddress: undefined,
        bankingDetails: bankingDetails,
        claimantIsAMinor: false,
    };
    const claimant: Claimant = {
        id: uuid(),
        claimantDetails: claimantDetails,
        financialAdviser: undefined,
    };
    return claimant;
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    addClaimant: record => dispatch(addAndSubmitClaimantRecordThunk(record)),
    openAddModal: options => dispatch(openAddModalAction(options)),
});

export const AddClaimantDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(addClaimantDetails));
