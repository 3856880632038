export const Conditions = [
    { value: 'Natural', label: 'Natural' },
    { value: 'Accidental fall', label: 'Accidental fall' },
    { value: 'Assault', label: 'Assault' },
    { value: 'Aviation accident', label: 'Aviation accident' },
    { value: 'Bicycle accident', label: 'Bicycle accident' },
    { value: 'Burns', label: 'Burns' },
    { value: 'Drowning', label: 'Drowning' },
    { value: 'Execution', label: 'Execution' },
    { value: 'Helicopter accident', label: 'Helicopter accident' },
    { value: 'Hijacked', label: 'Hijacked' },
    { value: 'Horse riding', label: 'Horse riding' },
    { value: 'Motor bike accident', label: 'Motor bike accident' },
    { value: 'Motor vehicle accident', label: 'Motor vehicle accident' },
    { value: 'Mountain bike accident', label: 'Mountain bike accident' },
    { value: 'Murder', label: 'Murder' },
    { value: 'Negligence', label: 'Negligence' },
    { value: 'Other accident', label: 'Other accident' },
    { value: 'Pedestrian accident', label: 'Pedestrian accident' },
    { value: 'Poisoning', label: 'Poisoning' },
    { value: 'Quad bike accident', label: 'Quad bike accident' },
    { value: 'Slipping', label: 'Slipping' },
    { value: 'Shooting', label: 'Shooting' },
    { value: 'Stabbing', label: 'Stabbing' },
    { value: 'Suicide', label: 'Suicide' },
    { value: 'Terrorism', label: 'Terrorism' },
    { value: 'Under investigation', label: 'Under investigation' },
    { value: 'Unknown', label: 'Unknown' },
    { value: 'War', label: 'War' },
    { value: 'Watercraft accident', label: 'Watercraft accident' },
    { value: 'Wild life attack', label: 'Wild life attack' },
    { value: 'Work related accident', label: 'Work related accident' },
];
