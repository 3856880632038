import React, { PropsWithChildren } from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { GenerateSynopsis } from '../generate-synopsis';
import { RulesRunButton } from '../rules/views/run-button';

interface OwnProps {}

interface StateProps {}

interface DispatchProps {}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const underwritingDecision: React.FunctionComponent<Props> = ({
    children,
}: PropsWithChildren<Props>) => {
    return (
        <React.Fragment>
            {children}
            <Grid container>
                <Grid item xs={6}>
                    <RulesRunButton />
                </Grid>
                <Grid item xs={6}>
                    <GenerateSynopsis />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {};

export const UnderwritingDecision = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(underwritingDecision));
