import { Theme } from '@material-ui/core';
import { createStyles, StyleRules, WithStyles } from '@material-ui/core/styles';

export type StyledComponent = WithStyles<typeof styles>;

export default function styles(theme: Theme): StyleRules {
    return createStyles({
        paper: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
        button: {
            margin: theme.spacing(),
        },
        row: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.background.default,
            },
        },
        totalRow: {
            backgroundColor: theme.palette.grey['400'],
        },
        headerRow: {
            backgroundColor: theme.palette.grey['300'],
            color: theme.palette.common.black,
        },
        formError: {
            textAlign: 'right',
        },
    });
}
