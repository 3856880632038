import React from 'react'; // eslint-disable-line

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import { AppBar, CssBaseline, Icon, IconButton, Toolbar, Typography } from '@material-ui/core';
import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { connect } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import { RouteComponentProps, withRouter } from 'react-router';
import { AuthenticatedView } from '../../auth/authenticated-view';
import { ClaimWorkflow } from '../../claim-workflow';
import { LoadClaimView } from '../../claim/views/load-claim';
import { ConfirmationDialog } from '../../confirmation-dialog';
import { ModalView } from '../../form-modal';
import { LoadingScreenView } from '../../forms/loading-screen';
import { State } from '../../redux/root-reducer';
import { toggleDrawerAction } from '../actions';
import NotificationBar from './notification-bar';
import SideDrawer, { drawerWidth } from './side-drawer';
import {
    LoadBankNames,
    loadBankNamesAction,
    LoadUniversalBranches,
    loadUniversalBranchesAction,
    LoadRADeathFundNames,
    loadRAFundNamesAction,
} from '../../lookups/bank-branch-codes';
import { AssessorsAction, ADUserAction } from '../../assesor-dashboard/actions/assessors-action';
import { ToasterView } from '../../confirmation-dialog/toast-notify/toast';

function styles(theme: Theme): StyleRules {
    return createStyles({
        root: {
            flexGrow: 1,
        },
        appFrame: {
            zIndex: 1,
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            width: '100%',
        },
        appBar: {
            position: 'absolute',
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: drawerWidth,
        },
        toolbar: {
            justifyContent: 'space-between',
            paddingRight: 0,
        },
        menuButton: {
            marginLeft: '12px',
            marginRight: '20px',
        },
        hide: {
            display: 'none',
        },
        content: {
            marginTop: '64px',
            flexGrow: 1,
            height: '93vh',
            overflowY: 'auto' as const,
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        contentLeft: {
            marginLeft: -drawerWidth,
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
    });
}
type Props = WithStyles<typeof styles> & {
    drawerOpen: boolean;
    modalOpen: boolean;
    toggleDrawer: () => void;
    loadBankNames: LoadBankNames;
    loadUniversalBranchCodes: LoadUniversalBranches;
    loadRADeathFundNames: LoadRADeathFundNames;
    loadAssessorLimits: any;
    loadADUsers: any;
};

class MasterPage extends React.Component<Props & RouteComponentProps<any>> {
    componentDidMount() {
        this.props.loadBankNames();
        this.props.loadUniversalBranchCodes();
        this.props.loadAssessorLimits();
        this.props.loadADUsers();
        this.props.loadRADeathFundNames();
    }
    public render() {
        const { classes, drawerOpen, modalOpen, toggleDrawer } = this.props;

        return (
            <div className={classes.root}>
                <CssBaseline />
                <div className={classes.appFrame}>
                    <AppBar
                        className={classNames(classes.appBar, {
                            [classes.appBarShift]: drawerOpen,
                        })}
                    >
                        <Toolbar disableGutters={!drawerOpen} className={classes.toolbar}>
                            <IconButton
                                id="openDrawer"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={toggleDrawer}
                                className={classNames(
                                    classes.menuButton,
                                    drawerOpen && classes.hide
                                )}
                            >
                                <Icon>menu</Icon>
                            </IconButton>

                            <Typography variant="h6" color="inherit" noWrap>
                                Claims Live
                            </Typography>
                            <NotificationBar />
                        </Toolbar>
                    </AppBar>
                    <SideDrawer />
                    <AuthenticatedView>
                        <main
                            className={classNames(classes.content, classes.contentLeft, {
                                [classes.contentShift]: drawerOpen,
                            })}
                        >
                            <ClaimWorkflow />
                            <LoadClaimView />
                            <ModalView />
                            {!modalOpen && (
                                <ReduxToastr
                                    timeOut={5000}
                                    newestOnTop={true}
                                    preventDuplicates
                                    position="top-right"
                                    transitionIn="bounceIn"
                                    transitionOut="fadeOut"
                                    progressBar
                                />
                            )}

                            <ConfirmationDialog />
                            <ToasterView />
                        </main>
                    </AuthenticatedView>
                </div>
                <LoadingScreenView
                    getLoadingScreenState={(state: State) => state.loadingScreenState}
                />
            </div>
        );
    }
}

function mapStateToProps(state: State) {
    return {
        drawerOpen: state.masterPage.drawerOpen,
        modalOpen: !!state.formModal.activeModal,
    };
}

const mapDispatchToProps = {
    toggleDrawer: toggleDrawerAction,
    loadBankNames: loadBankNamesAction,
    loadUniversalBranchCodes: loadUniversalBranchesAction,
    loadAssessorLimits: AssessorsAction,
    loadADUsers: ADUserAction,
    loadRADeathFundNames: loadRAFundNamesAction,
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withStyles(styles, { withTheme: true })(MasterPage))
);
