import React from 'react'; // eslint-disable-line
import {
    OpenAddModal,
    OpenModal,
    openAddModalAction,
    openModalAction,
} from '../../../../form-modal/actions';
import { Button, Paper, withStyles, Grid, Typography, Tooltip } from '@material-ui/core';
import { connect, MapStateToProps } from 'react-redux';
import { FormHeading } from '../../../../forms';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { GenerateSynopsis } from '../../../synopsis/common/generate-synopsis';
import {
    getAssessmentDetails,
    isDisabled,
    mapBenefits,
    PolicyAssessmentDetails,
} from '../../assessment-details-reducer';
import { PolicyAssessmentDetailsView } from './policy-assessment-details';
import { AddAssessmentDecisionProps } from './assessment-decision-add';
import { Nullable } from '../../../../shared-types';
import { ClaimType } from '../../../../claim/claim-types';
import { getClaim } from '../../../../claim';
import { openToastAction } from '../../../../confirmation-dialog';
import { addAssessmentDecisionAction } from '../../actions/add-assessment-decision';
import {
    SendManagerEmailAction,
    SendEmailAction,
} from '../../../../assesor-dashboard/actions/assessors-action';
import ManagerAddComment from '../views/manager-modal';
import { AuthGuard } from '../../../../auth/authGuard';
import { AddFormModal } from '../../../../form-modal';
import { ManagerComment } from '../../../../assesor-dashboard/reducer/assessors-reducer';
import { LabeledValue } from '../../../../controls/labeled-value';
import { getDisplayDate } from '../../../../shared-functions';
const hasManagerAccess = AuthGuard(['AssessorDashboard']);

interface OwnProps {
    readonly assessmentDecisionAddView:
        | React.FunctionComponent<AddAssessmentDecisionProps>
        | React.ComponentClass<AddAssessmentDecisionProps>;
}

interface StateProps {
    readonly assessmentDetails: PolicyAssessmentDetails[];
    readonly assessorLimits?: any;
    readonly auth?: any;
    readonly currentClaimType: Nullable<ClaimType>;
}

interface DispatchProps {
    addApprovalToAssessment: any;
    sendManagerEmail: SendEmailAction;
    openModal: OpenModal;
    openAddModal: OpenAddModal;
}

type Props = StateProps & OwnProps & StyledComponent & DispatchProps;
const CommentAddFormModal = AddFormModal<ManagerComment>();
const AssessmentDetailView: React.FunctionComponent<Props> = ({
    assessmentDetails,
    assessmentDecisionAddView,
    addApprovalToAssessment,
    currentClaimType,
    assessorLimits,
    sendManagerEmail,
    auth,
    classes,
    openAddModal,
}: Props) => {
    const limits = assessorLimits.filter((limits: any) => limits.email === auth.userId);

    const currentBenefitLimit = limits[0]?.assessorLimits.filter(
        (lim: any) =>
            mapBenefits(lim.productType)?.toLowerCase() === currentClaimType?.toLowerCase()
    );
    const sendForApproval = async () => {
        let benefitDetails: any;
        const policyNumbers: Array<string> = [];
        if (Array.isArray(assessmentDetails)) {
            assessmentDetails.forEach(assessment => {
                // eslint-disable-next-line array-callback-return
                policyNumbers.push(assessment.policyNumber);
                assessment.benefits.map(benefit => {
                    if (benefit.decisions.length >= 1) {
                        if (
                            benefit.decisions[benefit.decisions.length - 1]?.isOverLimit &&
                            !benefit.decisions[benefit.decisions.length - 1]?.isSentForApproval &&
                            benefit.decisions[benefit.decisions.length - 1]?.decision !==
                                'NotApplicable'
                        ) {
                            benefit.decisions[benefit.decisions.length - 1].requestStatus =
                                'requested';
                            benefit.decisions[benefit.decisions.length - 1].approvalSummary = '';
                        }
                        benefit.decisions[benefit.decisions.length - 1].requesterEmail =
                            auth.userId;
                        benefit.decisions[benefit.decisions.length - 1].isSentForApproval = true;
                        const details = {
                            policyNumber: assessment.policyNumber,
                            benefitCode: benefit.benefitCode,
                            benefitName: benefit.benefitName,
                            ...benefit.decisions[benefit.decisions.length - 1],
                        };
                        UpdateDescision(details);
                        benefitDetails = {
                            policyNumbers: policyNumbers,
                            assessor: benefit.decisions[benefit.decisions.length - 1].assessor,
                            requesterEmail:
                                benefit.decisions[benefit.decisions.length - 1].requesterEmail,
                        };
                    }
                    return null;
                });
            });
        }
        sendManagerEmail(benefitDetails, false);
    };

    const UpdateDescision = async (assessmentDecision: any) => {
        try {
            await addApprovalToAssessment(assessmentDecision);
        } catch (err) {
            console.log('Failed', err);
        }
    };

    const isSentForApproval = () => {
        let sentForApproval = false;

        if (Array.isArray(assessmentDetails)) {
            assessmentDetails.forEach(assessment => {
                assessment.benefits.map(benefit => {
                    if (benefit.decisions.length >= 1) {
                        if (benefit.decisions[benefit.decisions.length - 1]?.isSentForApproval) {
                            sentForApproval = true;
                            return sentForApproval;
                        }
                    }
                    return assessmentDetails;
                });
            });
            return sentForApproval;
        }
    };

    const isApprovedByManager = () => {
        let approvedByManager = false;
        if (Array.isArray(assessmentDetails)) {
            assessmentDetails.forEach(assessment => {
                assessment.benefits.map(benefit => {
                    if (benefit.decisions.length >= 1) {
                        if (
                            benefit.decisions[benefit.decisions.length - 1]?.requestStatus ===
                            'approved'
                        ) {
                            approvedByManager = true;
                            return approvedByManager;
                        }
                    }
                    return approvedByManager;
                });
            });
            return approvedByManager;
        }
    };

    const isRejectedByManager = () => {
        let rejectedByManager = false;
        if (Array.isArray(assessmentDetails)) {
            assessmentDetails.forEach(assessment => {
                assessment.benefits.map(benefit => {
                    if (benefit.decisions.length >= 1) {
                        if (
                            benefit.decisions[benefit.decisions.length - 1].requestStatus ===
                            'rejected'
                        ) {
                            rejectedByManager = true;
                            return rejectedByManager;
                        }
                    }
                    return assessmentDetails;
                });
            });
            return rejectedByManager;
        }
    };

    const approvalSummary = () => {
        let isCommentFound = false;
        let summary: string | undefined;
        if (Array.isArray(assessmentDetails)) {
            assessmentDetails.forEach(assessment => {
                assessment.benefits.map(benefit => {
                    if (benefit.decisions.length >= 1) {
                        if (
                            (benefit.decisions[benefit.decisions.length - 1]?.requestStatus ===
                                'approved' ||
                                benefit.decisions[benefit.decisions.length - 1]?.requestStatus ===
                                    'rejected') &&
                            benefit.decisions[benefit.decisions.length - 1]?.approvalSummary &&
                            !isCommentFound
                        ) {
                            isCommentFound = true;
                            summary =
                                benefit.decisions[benefit.decisions.length - 1]?.approvalSummary;
                            return summary;
                        }
                    }
                    return assessmentDetails;
                });
            });
            return summary;
        }
    };

    const showComments = () => {
        let isCommentFound = false;
        let comment: string | undefined;
        if (Array.isArray(assessmentDetails)) {
            assessmentDetails.forEach(assessment => {
                assessment.benefits.map(benefit => {
                    if (benefit.decisions.length >= 1) {
                        if (
                            benefit.decisions[benefit.decisions.length - 1]?.requestStatus !==
                                'requested' &&
                            benefit.decisions[benefit.decisions.length - 1]?.approvalSummary &&
                            !isCommentFound
                        ) {
                            isCommentFound = true;
                            comment =
                                benefit.decisions[benefit.decisions.length - 1]?.approvalSummary;
                            return comment;
                        }
                    }
                    return assessmentDetails;
                });
            });
            return comment;
        }
    };

    const approvalDate = () => {
        let isCommentFound = false;
        let assessmentDate: Date | undefined;
        if (Array.isArray(assessmentDetails)) {
            assessmentDetails.forEach(assessment => {
                assessment.benefits.map(benefit => {
                    if (benefit.decisions.length >= 1) {
                        if (
                            (benefit.decisions[benefit.decisions.length - 1]?.requestStatus ===
                                'approved' ||
                                benefit.decisions[benefit.decisions.length - 1]?.requestStatus ===
                                    'rejected') &&
                            benefit.decisions[benefit.decisions.length - 1]?.approvalSummary &&
                            !isCommentFound
                        ) {
                            isCommentFound = true;
                            assessmentDate =
                                benefit.decisions[benefit.decisions.length - 1]?.assessmentDate;
                            return assessmentDate;
                        }
                    }
                    return assessmentDetails;
                });
            });
            return assessmentDate;
        }
    };

    const approvedBy = () => {
        let isCommentFound = false;
        let approvedBy: string | undefined;
        if (Array.isArray(assessmentDetails)) {
            assessmentDetails.forEach(assessment => {
                assessment.benefits.map(benefit => {
                    if (benefit.decisions.length >= 1) {
                        if (
                            (benefit.decisions[benefit.decisions.length - 1]?.requestStatus ===
                                'approved' ||
                                benefit.decisions[benefit.decisions.length - 1]?.requestStatus ===
                                    'rejected') &&
                            benefit.decisions[benefit.decisions.length - 1]?.approvalSummary &&
                            !isCommentFound
                        ) {
                            isCommentFound = true;
                            approvedBy = benefit.decisions[benefit.decisions.length - 1]?.approveBy;
                            return approvedBy;
                        }
                    }
                    return assessmentDetails;
                });
            });
            return approvedBy;
        }
    };

    const onSubmit = (value: any, action: boolean) => {
        let benefitDetails: any = [];
        const policyNumbers: Array<string> = [];
        if (Array.isArray(assessmentDetails)) {
            assessmentDetails.forEach(assessment => {
                // eslint-disable-next-line array-callback-return
                policyNumbers.push(assessment.policyNumber);
                assessment.benefits.map(benefit => {
                    if (benefit.decisions.length >= 1) {
                        if (
                            benefit.decisions[benefit.decisions.length - 1]?.isOverLimit &&
                            benefit.decisions[benefit.decisions.length - 1]?.isSentForApproval
                        ) {
                            if (action) {
                                benefit.decisions[benefit.decisions.length - 1].requestStatus =
                                    'approved';
                            } else {
                                benefit.decisions[benefit.decisions.length - 1].requestStatus =
                                    'rejected';
                            }

                            benefit.decisions[benefit.decisions.length - 1].approveBy =
                                auth.name + '(' + auth.userId + ')';
                            benefit.decisions[benefit.decisions.length - 1].approvalSummary =
                                value.comment;
                            benefit.decisions[
                                benefit.decisions.length - 1
                            ].isSentForApproval = false;
                            UpdateDescision({
                                policyNumber: assessment.policyNumber,
                                benefitCode: benefit.benefitCode,
                                benefitName: benefit.benefitName,
                                ...benefit.decisions[benefit.decisions.length - 1],
                            });
                        }
                        benefitDetails = {
                            policyNumbers: policyNumbers,
                            assessor: benefit.decisions[benefit.decisions.length - 1].assessor,
                            decisionAction: action ? 'Approved' : 'Rejected',
                            comment: value.comment,
                            approverName: auth.name,
                            requesterEmail:
                                benefit.decisions[benefit.decisions.length - 1].requesterEmail,
                        };
                    }
                    return null;
                });
            });
        }
        sendManagerEmail(benefitDetails, true);
    };

    const blankCommentRecord: ManagerComment = {
        comment: '',
    };

    const addAssessor = (action: boolean) => {
        openAddModal({
            modalContent: (
                <CommentAddFormModal
                    blankRecord={blankCommentRecord}
                    hideAddAnother={true}
                    hideSave={true}
                    recordDetailView={
                        <ManagerAddComment
                            assessmentDetails={assessmentDetails}
                            classes={classes}
                            action={action}
                        />
                    }
                    addRecord={managerCommentRecord => onSubmit(managerCommentRecord, action)}
                />
            ),
            modalData: {},
        });
    };

    function onReject() {
        addAssessor(false);
    }

    function onApprove() {
        addAssessor(true);
    }

    return (
        <React.Fragment>
            <Paper className={classes.paper}>
                <FormHeading>Assessment Decisions</FormHeading>
                {assessmentDetails &&
                    assessmentDetails.map((policyAssessmentDetails, index) => (
                        <PolicyAssessmentDetailsView
                            key={policyAssessmentDetails.policyNumber}
                            policyAssessmentDetails={policyAssessmentDetails}
                            assessmentDecisionAddView={assessmentDecisionAddView}
                            policyIndex={index}
                        />
                    ))}

                <Grid container direction="row" justify="flex-end">
                    {!isSentForApproval() && !hasManagerAccess() && !showComments() && (
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={isDisabled(assessmentDetails, currentBenefitLimit)}
                            className={classes.sendForApprovalButton}
                            onClick={sendForApproval}
                        >
                            Send for authorisation
                        </Button>
                    )}
                    {isRejectedByManager() && !hasManagerAccess() && (
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={isDisabled(assessmentDetails, currentBenefitLimit)}
                            className={classes.sendForApprovalButton}
                            onClick={sendForApproval}
                        >
                            Send for authorisation
                        </Button>
                    )}
                    {isSentForApproval() &&
                        !hasManagerAccess() &&
                        !isRejectedByManager() &&
                        !isApprovedByManager() && (
                            <Typography>Awaiting authorisation from manager</Typography>
                        )}
                </Grid>
                {isSentForApproval() && hasManagerAccess() && (
                    <Grid item xs={12} container justify="flex-end">
                        <Tooltip title="Reject">
                            <Button
                                id="reject"
                                color="secondary"
                                style={{
                                    padding: 10,
                                    textTransform: 'none',
                                    border: '2px solid blue',
                                    marginLeft: 10,
                                    height: 5,
                                    backgroundColor: '#4669c4',
                                    color: 'white',
                                }}
                                onClick={() => onReject()}
                            >
                                Reject
                            </Button>
                        </Tooltip>

                        <Tooltip title="Authorise">
                            <Button
                                id="Authorised"
                                color="secondary"
                                style={{
                                    padding: 10,
                                    textTransform: 'none',
                                    border: '2px solid darkblue',
                                    marginLeft: 10,
                                    height: 5,
                                    backgroundColor: '#4669c4',
                                    color: 'white',
                                }}
                                onClick={() => onApprove()}
                            >
                                Authorise
                            </Button>
                        </Tooltip>
                    </Grid>
                )}
                {isApprovedByManager() && (
                    <Grid item xs={12} container justify="flex-end">
                        <Tooltip title="Authorised comment">
                            <Button
                                id="approve"
                                color="secondary"
                                style={{ padding: 10, textTransform: 'none' }}
                            >
                                Request has been authorised
                            </Button>
                        </Tooltip>
                    </Grid>
                )}
                {isRejectedByManager() && (
                    <Grid item xs={12} container justify="flex-end">
                        <Tooltip title="reject comment">
                            <Button
                                id="approve"
                                color="secondary"
                                style={{ padding: 10, textTransform: 'none' }}
                            >
                                Request has been rejected
                            </Button>
                        </Tooltip>
                    </Grid>
                )}
                {showComments() && (
                    <Paper className={classes.paper}>
                        <Grid
                            item
                            xs={12}
                            container
                            justify="flex-end"
                            style={{ width: '50%', height: '10%' }}
                        >
                            <Grid item xs={12}>
                                <LabeledValue label="Comment" value={approvalSummary()} />
                            </Grid>
                            <Grid item xs={8}>
                                <LabeledValue label="Manager" value={approvedBy()} />
                            </Grid>
                            <Grid item xs={4}>
                                <LabeledValue label="Date" value={getDisplayDate(approvalDate())} />
                            </Grid>
                        </Grid>
                    </Paper>
                )}
            </Paper>
            <GenerateSynopsis />
        </React.Fragment>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    assessmentDetails: getAssessmentDetails(state),
    currentClaimType: getClaim(state).claimType,
    assessorLimits: state.assessorDashboard.assessorLimits,
    auth: state.auth,
});

const mapDispatchToProps = {
    openToast: openToastAction,
    addApprovalToAssessment: addAssessmentDecisionAction,
    sendManagerEmail: SendManagerEmailAction,
    openModal: openModalAction,
    openAddModal: openAddModalAction,
};

export const AssessmentDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(AssessmentDetailView));
