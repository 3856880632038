import React from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Section } from '../../../../../../controls/section';
import { FormErrors, FullWidthDatePicker, GenericForm } from '../../../../../../forms';
import { FullWidthReadonlyField } from '../../../../../../forms/form-components/full-width-readonly-field/full-width-readonly-field';
import { requiredValidator } from '../../../../../../forms/validations';
import { State } from '../../../../../../redux/root-reducer';
import { getDisplayDate, getYesNo } from '../../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { OnSubmitSuccessProps } from '../../../../../../workflow-navigation';
import { UnderwritingPolicyDetails } from './underwriting-policy-details-model';

interface OwnProps {
    policyDetails: UnderwritingPolicyDetails;
    requirements: string[];
    updateUnderwritingPolicyDetails: (
        underwritingPolicyDetails: UnderwritingPolicyDetails
    ) => Promise<any>;
}

interface StateProps {}

interface DispatchProps {}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent & OnSubmitSuccessProps;

const Form = GenericForm<UnderwritingPolicyDetails>();
const underwritingPolicyDetailsForm: React.FunctionComponent<Props> = ({
    policyDetails,
    requirements,
    updateUnderwritingPolicyDetails,
    ...rest
}: Props) => {
    const onSubmit = (values: UnderwritingPolicyDetails) => updateUnderwritingPolicyDetails(values);
    const initialValues = {
        ...policyDetails,
        requirements,
    };
    return (
        <Form initialValues={initialValues} onSubmit={onSubmit} {...rest}>
            <Section title="Policy Details">
                <Grid item xs={4}>
                    <FullWidthDatePicker
                        name="newBusinessUploadDate"
                        label="New Business Upload Date"
                        validate={requiredValidator}
                        disableFuture
                    />
                </Grid>
                <Grid item xs={4}>
                    <FullWidthDatePicker
                        name="counterOfferLetterSignedDate"
                        label="Counter Offer Letter Signed Date"
                        disableFuture
                    />
                </Grid>
                <Grid item xs={4}>
                    <FullWidthReadonlyField
                        name="commencementDate"
                        format={date => getDisplayDate(date)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FullWidthReadonlyField
                        name="replacement"
                        label="Policy Replacement"
                        format={getYesNo}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FullWidthReadonlyField
                        name="bigT"
                        label="Big T (Tele Underwriting Q & A)"
                        format={getYesNo}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FullWidthReadonlyField name="requirements" format={reqs => reqs.join(', ')} />
                </Grid>
            </Section>
            <FormErrors />
        </Form>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = () => ({});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {};

export const UnderwritingPolicyDetailsForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(underwritingPolicyDetailsForm));
