import React from 'react'; // eslint-disable-line
import { ModalViewProps, MultiEditFormModal } from '../../../../../form-modal';
import { AddressDetailsEdit } from './address-details-edit';
import { ContactDetailsEdit } from './contact-details-edit';
import { PersonalDetailsEdit } from './personal-details-edit';
import { EmploymentDetailsEdit } from './employment-details-edit';
import {
    PersonalDetails,
    AddressDetails,
    EmploymentDetails,
    ContactDetails,
    FormDetails,
} from '../form-details-reducer';
import { OpenModal } from '../../../../../form-modal/actions';

interface ModalProps {
    personalDetails: PersonalDetails;
    addressDetails: AddressDetails;
    contactDetails: ContactDetails;
    employmentDetails: EmploymentDetails;
}

export const EditModalForm = MultiEditFormModal<ModalProps>();
export const EditModalViews: ModalViewProps[] = [
    {
        name: 'Primary Details',
        view: (
            <PersonalDetailsEdit
                type="PolicyHolder"
                prefix="policyHolder.individual.personalDetails"
                policyHolderPrefix="policyHolder.policyHolderType"
            />
        ),
    },
    {
        name: 'Address Details',
        view: (
            <AddressDetailsEdit
                type="PolicyHolder"
                prefix="policyHolder.individual.addressDetails"
            />
        ),
    },
    {
        name: 'Contact Details',
        view: (
            <ContactDetailsEdit
                prefix="policyHolder.individual.contactDetails"
                type="PolicyHolder"
            />
        ),
    },
    {
        name: 'Employment Details',
        view: <EmploymentDetailsEdit prefix="policyHolder.individual.employmentDetails" />,
    },
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const editPolicyHolderDetails = (
    openModal: OpenModal,
    formDetails: FormDetails,
    screenIndex: number,
    updateFormDetails: any
) => {
    const addressDetails = formDetails.policyHolder?.individual?.addressDetails;
    const newAddressDetails = {
        ...addressDetails,
        sameAsResidential: addressDetails?.sameAsResidentialAddress,
        addressType: 'Street',
    };

    const updatedFormDetails = {
        ...formDetails,
        policyHolder: {
            individual: {
                ...formDetails.policyHolder?.individual,
                addressDetails: newAddressDetails,
            },
        },
    };

    openModal({
        modalContent: (
            <EditModalForm
                heading="Update Form Details | Policyholder"
                modalViews={EditModalViews}
                saveAction={updateFormDetails}
            />
        ),
        modalData: {
            screenIndex: screenIndex,
            initialValues: updatedFormDetails,
        },
    });
};
