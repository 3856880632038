import { Divider, Grid, Typography, withStyles } from '@material-ui/core';
import { startCase } from 'lodash';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { hasExistingClaims } from '..';
import { CopyClaim, copyClaimAction } from '../../claim/actions/copy-claim';
import { LinkClaim, linkClaimAction } from '../../claim/actions/link-claim';
import { LoadClaim, loadClaimAction } from '../../claim/actions/load-claim';
import { OpenDialog, openDialogAction } from '../../confirmation-dialog';
import clipboard from '../../master-layout/views/clipboard.svg';
import eye from '../../master-layout/views/eye.svg';
import link from '../../master-layout/views/link.svg';
import { getDisplayDate } from '../../shared-functions';
import styles, { StyledComponent } from '../../styles/styles';
import { ClientClaim } from '../actions/clients-loaded';

type Props = StyledComponent & {
    clientClaims: ClientClaim[];
    clientId: string;
    copyClaim: CopyClaim;
    linkClaim: LinkClaim;
    loadClaim: LoadClaim;
    openDialog: OpenDialog;
};
const existingClaimsView = ({
    clientClaims,
    clientId,
    classes,
    copyClaim,
    linkClaim,
    loadClaim,
    openDialog,
}: Props) => {
    if (!hasExistingClaims(clientClaims)) {
        return (
            <Grid item xs={12} container className={classes.resultsClaimsTable}>
                <Typography className={classes.resultsTextNone}>
                    There are no existing claims for this Life Assured
                </Typography>
            </Grid>
        );
    }
    const referenceSource = sessionStorage.getItem('referenceSource') || '';
    const referenceId = sessionStorage.getItem('referenceId') || '';
    const creationDate = sessionStorage.getItem('creationDate') || '';

    const onLinkClaim = (
        claimId: string,
        referenceSource: string,
        referenceId: string,
        redirectUrl: string,
        creationDate: string
    ) => {
        if (referenceId) {
            openDialog({
                content: {
                    title: `Link claim to this request number - ${referenceSource}: ${referenceId}`,
                    body: 'Are you sure you want to link the claim',
                },
                yesAction: {
                    action: async () => {
                        await linkClaim(
                            claimId,
                            referenceSource,
                            referenceId,
                            redirectUrl,
                            creationDate
                        );
                    },
                },
                noAction: {
                    action: () => {
                        return;
                    },
                },
            });
        } else {
            openDialog({
                content: {
                    title: `You do not have a request number to link the claim`,
                    body: 'Please get request number and try again.',
                },
                cancelLabel: {
                    action: () => {
                        return;
                    },
                },
            });
        }
    };
    return (
        <React.Fragment>
            <Divider className={classes.resultsSpacer} />
            <Grid container className={classes.resultsClaimsTable}>
                <Grid container>
                    <Grid item xs={2}>
                        <Typography className={classes.resultsLabel}>Date initiated</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography className={classes.resultsLabel}>Initiated by</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography className={classes.resultsLabel}>Claim type</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography className={classes.resultsLabel}>Request number</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography className={classes.resultsLabel}>Policy number</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className={classes.resultsLabel}>Actions</Typography>
                    </Grid>
                </Grid>
                {clientClaims.map((claim, index) => {
                    const references =
                        (claim.references && claim.references.map(ref => ref.referenceId)) || [];
                    if (claim.claimTypes[0] === 'Uninitiated') {
                        return null;
                    }
                    return (
                        <Grid
                            container
                            className={classes.resultsTable}
                            key={`existing-claims-${index}`}
                        >
                            {index !== 0 && (
                                <Grid item xs={12}>
                                    <Divider className={classes.resultsClaimsSpacer} />
                                </Grid>
                            )}
                            <Grid item xs={2}>
                                <Typography className={classes.resultsText}>
                                    {getDisplayDate(claim.initiationDate, 'dd MMMM yyyy')}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.resultsText}>
                                    {claim.initiatedBy}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography className={classes.resultsText}>
                                    {startCase(claim.claimTypes[0])}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography
                                    className={classes.resultsText}
                                    style={{ marginRight: '2px' }}
                                >
                                    {references
                                        .sort((a: any, b: any) => (a > b ? -1 : 1))
                                        .join(', ')}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} className={classes.resultsText}>
                                <Typography>{claim.contractNumbers.join(', ')}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography>
                                    <a
                                        href="#/"
                                        className={classes.resultsActionLink}
                                        onClick={() => {
                                            onLinkClaim(
                                                claim.claimId,
                                                referenceSource,
                                                referenceId,
                                                '/',
                                                creationDate
                                            );
                                        }}
                                    >
                                        <img
                                            src={link}
                                            alt="Link"
                                            className={classes.resultsActionImage}
                                        />
                                        <span style={{ paddingLeft: '10px' }}>Link</span>
                                    </a>
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography>
                                    <a
                                        href="/#"
                                        className={classes.resultsActionLink}
                                        onClick={(evt: any) => {
                                            copyClaim(claim, clientId);
                                            evt.preventDefault();
                                            return false;
                                        }}
                                    >
                                        <img
                                            src={clipboard}
                                            alt="Copy"
                                            className={classes.resultsActionImage}
                                        />
                                        <span style={{ paddingLeft: '10px' }}>Copy</span>
                                    </a>
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography>
                                    <a
                                        href="/#"
                                        className={classes.resultsActionLink}
                                        onClick={(evt: any) => {
                                            loadClaim(
                                                claim.claimId,
                                                undefined,
                                                undefined,
                                                undefined,
                                                '/',
                                                true
                                            );
                                            evt.preventDefault();
                                            return false;
                                        }}
                                    >
                                        <img
                                            src={eye}
                                            alt="View"
                                            className={classes.resultsActionImage}
                                        />
                                        <span style={{ paddingLeft: '10px' }}>View</span>
                                    </a>
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        </React.Fragment>
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    openDialog: openDialogAction,
    loadClaim: loadClaimAction,
    copyClaim: copyClaimAction,
    linkClaim: linkClaimAction,
};

export const ExistingClaimsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(existingClaimsView));
