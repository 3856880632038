import React from 'react'; // eslint-disable-line
import { Grid, Tooltip, Typography } from '@material-ui/core';
import { ReportProblem, CheckCircle, Warning } from '@material-ui/icons';
import { LiabilityStatus } from '../common-types';
import { ClaimLiability } from '../../assessment-details-reducer';
import { DeathClaimDetails } from '../../../synopsis/death/claim-event-details/reducer';
import { FullWidthCheckBoxField, FullWidthDatePicker } from '../../../../forms';
import { ClaimType } from '../../../../claim/claim-types';
import { Form } from 'react-final-form';
interface Props {
    claimLiability?: ClaimLiability;
    claimDetails?: DeathClaimDetails | undefined;
    claimType?: ClaimType;
    policyNumber: string;
    isLiabilitySelected?: boolean;
    benefit?: any;
    isCireRan?: boolean;
    isOverriden?: boolean;
}

export const DeathGroupLiabilityStatusView: React.FunctionComponent<Props> = ({
    claimLiability,
    policyNumber,
    claimDetails,
    benefit,
    claimType,
    isCireRan,
    isOverriden,
}: Props) => {
    if (!claimLiability) {
        return null;
    }
    claimLiability.isLiabilitySelected = false;

    return (
        <Grid item container justify="flex-start" style={{ marginTop: '-5px' }}>
            <Typography style={{ color: '#8b92a7' }}>Liability Request : </Typography>
            <Typography
                style={{
                    marginLeft: '5px',
                    fontWeight: 'bold',
                }}
            >
                {claimLiability.status || 'Pending'}
            </Typography>
            {liabilityStatusIcon(claimLiability.status || 'Pending')}
            {claimLiability.status === 'Failed' && (
                <Grid item xs={12}>
                    <Typography style={{ color: ' #8b92a7' }}>
                        {claimLiability.errorMessage}
                    </Typography>
                </Grid>
            )}
            {claimLiability.status === 'Successful' && (
                <React.Fragment>
                    <Grid container item xs={12} justify="flex-start">
                        <Typography style={{ color: '#8b92a7' }}>Liability status : </Typography>
                        <Typography
                            style={{
                                marginLeft: '5px',
                                fontWeight: 'bold',
                            }}
                        >
                            {claimLiability.movementStatus || 'Pending'}
                        </Typography>
                        {liabilityStatusIcon(claimLiability.movementStatus || 'Pending')}
                    </Grid>
                    {claimLiability.movementStatus === 'Failed' && (
                        <Grid item xs={12}>
                            <Typography style={{ color: '#8b92a7' }}>
                                {claimLiability.movementMessage}
                            </Typography>
                        </Grid>
                    )}
                </React.Fragment>
            )}
            {claimType === ClaimType.Death && (
                <Grid container item xs={12} justify="flex-start">
                    <Typography style={{ color: '#8b92a7' }}>
                        Please ensure that you run the running man before capturing a decision
                    </Typography>
                    <Typography
                        style={{
                            marginLeft: '5px',
                            fontWeight: 'bold',
                        }}
                    >
                        {isCireRan || 'Pending'}
                    </Typography>
                    {checkCire(isCireRan)}
                    <Grid container item xs={12} justify="flex-start">
                        <Typography style={{ color: '#8b92a7' }}>
                            Please ensure that you override CIRE results before capturing a decision
                        </Typography>
                        <Typography
                            style={{
                                marginLeft: '5px',
                                fontWeight: 'bold',
                            }}
                        >
                            {!isOverriden || 'Pending'}
                        </Typography>
                        {checkCire(!isOverriden)}
                    </Grid>
                </Grid>
            )}
            <Grid item container justify="flex-start" style={{ marginTop: '10px' }}>
                <Form
                    // tslint:disable-next-line:no-empty
                    onSubmit={LiabilityToSend}
                    initialValues={{ isLiabilitySelected: claimLiability.isLiabilitySelected }}
                    render={() => (
                        <Grid item xs={12} style={{ marginTop: '-28px' }}>
                            {claimLiability.status === 'Pending' &&
                                canRaiseLiability() &&
                                notIEB(benefit?.benefitCode) && (
                                    <Grid>
                                        <Tooltip title="Select raise liability">
                                            <FullWidthCheckBoxField
                                                onClick={() => LiabilityToSend(claimLiability)}
                                                name="isLiabilitySelected"
                                                label="Select to raise liability"
                                            />
                                        </Tooltip>
                                    </Grid>
                                )}
                            {policyNumber &&
                                !policyNumber.startsWith('5', 0) &&
                                claimLiability.status === 'Pending' &&
                                canRaiseLiability() && (
                                    <Grid item xs={8} style={{ marginTop: '-28px' }}>
                                        <FullWidthDatePicker
                                            onChange={e => UpdateEffectiveDate(claimLiability, e)}
                                            label="Effective Date"
                                            name="effectiveDate"
                                            disableFuture
                                        />
                                    </Grid>
                                )}
                        </Grid>
                    )}
                />
            </Grid>
        </Grid>
    );

    function checkCire(cireStatus?: boolean) {
        if (cireStatus) {
            return <CheckCircle style={{ color: '#36b37e', marginLeft: '8px' }} />;
        } else {
            return <Warning style={{ color: '#ff6c35', marginLeft: '8px' }} />;
        }
    }

    function LiabilityToSend(claimLiability: ClaimLiability) {
        if (claimLiability && !claimLiability.isLiabilitySelected) {
            claimLiability.isLiabilitySelected = true;
        } else {
            claimLiability.isLiabilitySelected = false;
        }
    }

    function UpdateEffectiveDate(claimLiability: ClaimLiability, effectiveDate: any) {
        if (effectiveDate) claimLiability.updatedEffectiveDate = effectiveDate;
    }

    function canRaiseLiability() {
        return policyNumber && claimDetails && claimDetails.dateOfEvent;
    }

    function notIEB(benefitCode: string) {
        return benefitCode !== '18' && benefitCode !== '119';
    }
};

function liabilityStatusIcon(status: LiabilityStatus) {
    switch (status) {
        case 'Failed':
            return (
                <ReportProblem
                    style={{
                        color: '#d11329',
                        marginLeft: '8px',
                    }}
                />
            );
        case 'Successful':
            return <CheckCircle style={{ color: '#36b37e', marginLeft: '8px' }} />;
        case 'Manual':
            return <CheckCircle style={{ color: '#ff6c35', marginLeft: '8px' }} />;
        default:
            return <Warning style={{ color: '#ff6c35', marginLeft: '8px' }} />;
    }
}
