import { AnyAction, Reducer } from 'redux';
import { ReduxAction } from '../../redux/redux-actions';
import { Nullable } from '../../shared-types';
import { SET_CLAIM_TYPE } from '../types';
import { ClaimType } from '../claim-types';

export const initialClaimType = null;

export interface ClientReference {
    readonly clientId?: string;
    readonly clientSource?: string;
    readonly clientSourceId?: string;
}

export type ClaimTypeAction = ReduxAction<ClaimType> | AnyAction;
export const claimTypeReducer: Reducer<Nullable<ClaimType>, ClaimTypeAction> = (
    state: Nullable<ClaimType> = null,
    action: ClaimTypeAction
) => {
    if (action.type === SET_CLAIM_TYPE) {
        return action.payload;
    }
    return state;
};
