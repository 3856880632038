import axios from 'axios';
import { FORM_ERROR } from 'final-form';
import * as saIdParser from 'south-african-id-parser';
import { env } from '../../env';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { EntityIdType } from '../../shared-types';
import { clientsLoadedThunkAction } from './clients-loaded';

const searchUi = `${env().CLAIMS_API}/api/Personality/FindClientsByPersonalDetails`;

export interface FindClientsByPersonalDetailsCriteria {
    idType?: EntityIdType;
    idNumber?: string;
    firstName?: string;
    lastName?: string;
    dateOfBirth?: string;
}

type FindClientsByPersonalDetailsReturn = Promise<any>;
export type FindClientsByPersonalDetails = (
    criteria: FindClientsByPersonalDetailsCriteria
) => FindClientsByPersonalDetailsReturn;
type FindClientsByPersonalDetailsAction = (
    criteria: FindClientsByPersonalDetailsCriteria
) => ReduxThunkAction<FindClientsByPersonalDetailsReturn>;
export const findClientsByPersonalDetailsAction: FindClientsByPersonalDetailsAction = (
    criteria: FindClientsByPersonalDetailsCriteria
) => {
    return async dispatch => {
        const errors = validate(criteria);
        if (Object.keys(errors).length > 0) {
            return errors;
        }
        const response = await axios.get(searchUi, {
            params: {
                idType: criteria.idType,
                idNumber: criteria.idNumber,
                firstName: criteria.firstName,
                lastName: criteria.lastName,
                dateOfBirth: criteria.dateOfBirth,
            },
        });
        if (!response.data || response.data.length <= 0) {
            throw new Error(
                'No clients with valid benefits to continue the claims process were found.'
            );
        }
        dispatch(
            clientsLoadedThunkAction(response.data, { ...criteria, searchBy: 'Life Assured' })
        );
        return errors;
    };
};

function validate(values: FindClientsByPersonalDetailsCriteria) {
    const errors = {} as any;
    const hasAllThreePersonalDetails =
        !!values.firstName && !!values.lastName && !!values.dateOfBirth;
    const hasAnyPersonalDetails = !!values.firstName || !!values.lastName || !!values.dateOfBirth;
    if (values.idNumber && !values.idType) {
        errors.idNumber = 'An ID Type must be provided when specifying an ID Number';
        return errors;
    }
    if (values.idType === 'ID Number' && values.idNumber) {
        const validSaId = saIdParser.validate(values.idNumber);
        if (!validSaId) {
            errors.idNumber = 'Invalid RSA ID Number';
            return errors;
        }
    }
    if (hasAllThreePersonalDetails) {
        return errors;
    }
    if (hasAnyPersonalDetails && !hasAllThreePersonalDetails) {
        errors[FORM_ERROR] =
            'When specifying personal details, all three fields(First name, Last name, Date Of Birth) must be completed.';
        return errors;
    }
    if (!values.idNumber && !hasAnyPersonalDetails) {
        errors[FORM_ERROR] = 'Please provide some search parameters.';
        return errors;
    }
    return errors;
}
