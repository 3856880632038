import React from 'react'; // eslint-disable-line

import { Theme, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import { FieldRenderProps } from 'react-final-form';
import { callAllFunctions, getComponentId, processMetaForErrors, TextFieldTypes } from '../../';

function styles(theme: Theme) {
    return {
        percentageInput: {
            width: '46px',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderRadius: '4px',
            padding: '2px',
        },
        error: {
            borderColor: theme.palette.error.main,
            borderWidth: '2px',
        },
    };
}

type TextFieldProps = FieldRenderProps<any, any> & {
    id?: string;
    onChange?: (values?: any) => void;
    classes: {
        percentageInput: string;
        error: string;
    };
};

const _PercentageTextField: React.FunctionComponent<TextFieldProps> = ({
    id,
    classes,
    input,
    meta,
    onChange,
    ...rest
}: TextFieldProps) => {
    const { name, value } = input;
    const { showError } = processMetaForErrors(meta);
    return (
        <input
            {...rest}
            id={getComponentId({ id, name })}
            className={classNames(classes.percentageInput, { [classes.error]: showError })}
            name={name}
            onChange={callAllFunctions(onChange, input.onChange)}
            value={value}
            onKeyDown={inputKeyPress}
        />
    );
};

const PercentageTextField = withStyles(styles)(_PercentageTextField);

function inputKeyPress(event: any) {
    // Key code reference https://www.cambiaresearch.com/articles/15/javascript-char-codes-key-codes
    const isANumberKey = event.keyCode >= 48 && event.keyCode <= 57;
    const isANumberPadKey = event.keyCode >= 96 && event.keyCode <= 105;
    // backspace, tab, shift, ctrl-a, ctrl-c, ctrl-v
    const otherAllowedKeys = [8, 9, 13, 16, 17, 65, 67, 86];
    if (isANumberKey || isANumberPadKey || otherAllowedKeys.indexOf(event.keyCode) >= 0) {
        return true;
    }
    event.preventDefault();
    return false;
}

interface Props {
    name: string;
    type?: TextFieldTypes;
}

export function PercentageInput({ name, ...rest }: Props & any) {
    return (
        <React.Fragment>
            <Field
                {...rest}
                name={name}
                component={PercentageTextField}
                type="number"
                placeholder="0"
                parse={parsePercentage}
            />
            &nbsp;%
        </React.Fragment>
    );
}

function parsePercentage(value: string): string {
    const percentage = Number(value);
    if (Number.isNaN(percentage)) {
        return '';
    }
    if (percentage > 100) {
        return '100';
    }
    if (percentage < 0) {
        return '0';
    }
    return value;
}
