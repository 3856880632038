export interface Env {
    CLAIMS_API?: string;
    'AzureAD:Instance': string;
    'AzureAD:UiClientId': string;
    'AzureAD:TenantId': string;
    'AzureAD:Scopes': string;
    AppInsightsInstrumentationKey: string;
    LifeClaimsRegistry: string;
    ClaimFormsUrl: string;
    TimeOutWindowInMinutes: number;
    ClaimLoadRetryDelay: number;
    HPCSA: string;
}

export function env(): Env {
    return ((window as any).env || {}) as Env;
}
