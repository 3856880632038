import { AnyAction } from 'redux';
import { getProperty } from '../claim';
import { State } from '../redux/root-reducer';
import { Nullable } from '../shared-types';

export interface MedicalReferral {
    readonly dateReferred?: Date;
    readonly referredBy?: string;
    readonly reasonsForReferral?: string;
    readonly dateFeedbackReceived?: Date;
    readonly feedbackReceived?: string;
    readonly assessorName?: string;
}

export type MedicalOfficerInput = MedicalReferral;

export const defaultMedicalOfficerInput: MedicalOfficerInput = {};

export function medicalOfficerInputReducer(
    state: Nullable<MedicalOfficerInput> = null,
    action: AnyAction
) {
    switch (action.type) {
        default:
            return state;
    }
}

export function getMedicalOfficerInput(state: State): MedicalOfficerInput {
    return getProperty(state, 'medicalOfficerInput', defaultMedicalOfficerInput);
}
