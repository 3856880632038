import { withStyles, Grid, Typography, Paper, Tooltip, Button } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import styles, { StyledComponent } from '../../../../../styles/styles';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { Edit } from '@material-ui/icons';
import { FormDetails, getFormDetails } from '../form-details-reducer';
import { connect } from 'react-redux';
import { State } from '../../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../../shared-functions';
import { OpenModal, openModalAction } from '../../../../../form-modal/actions';
import { editLifeAssureDetails } from './life-assured-edit';
import { UpdateFormDetails, updateFormDetailsAction } from '../actions/update-form-details';

interface BaseProps {
    formDetails?: FormDetails;
    updateFormDetails?: UpdateFormDetails;
    disableEditButton?: boolean;
}

interface Props extends BaseProps, StyledComponent {
    readonly openModal: OpenModal;
}

const medicalInformationDetailsView: React.FunctionComponent<Props> = ({
    classes,
    openModal,
    formDetails,
    updateFormDetails,
    disableEditButton,
}: Props) => {
    const edit = () => {
        const details = formDetails || {};
        editLifeAssureDetails(openModal, details, 3, updateFormDetails);
    };

    const medicalInformation = formDetails?.lifeAssured?.medicalInformation;
    return (
        <React.Fragment>
            <Grid container justify="space-between" className={classes.marginTop2}>
                <Grid container item xs={10} alignItems="center">
                    <Typography style={{ padding: 10 }} className={classes.heading} variant="body1">
                        Medical information
                    </Typography>
                </Grid>
            </Grid>
            <Paper className={classes.paper}>
                <Grid container item xs={12} alignItems="center" justify="flex-end">
                    <Tooltip title="Edit Medical Information">
                        <Button
                            id="EditPersonalDetails"
                            color="secondary"
                            onClick={edit}
                            disabled={!disableEditButton}
                        >
                            <Edit />
                        </Button>
                    </Tooltip>
                </Grid>
                <Grid container justify="flex-start">
                    <Grid item xs={12} container justify="flex-start">
                        <Grid item xs={6} lg={6}>
                            <LabeledValue
                                label="Medical reason for claim being submitted"
                                value={medicalInformation?.lifeAssuredMedicalReason}
                            />
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <LabeledValue
                                label="Date of diagnosis or date that the event took place"
                                value={getDisplayDate(
                                    medicalInformation?.lifeAssuredDateOfDiagnosis
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <LabeledValue
                                label="Currently taking any medication or undergoing treatment"
                                value={medicalInformation?.takingMedicationOrTreatment}
                            />
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <LabeledValue
                                label="Medical condition was acquired due to"
                                value={medicalInformation?.lifeAssuredMedicalCondition}
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <LabeledValue
                                label="More details"
                                value={medicalInformation?.lifeAssuredMoreInformation}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

const mapStateToProps = (state: State, ownProps: BaseProps) => ({
    formDetails: ownProps.formDetails || getFormDetails(state),
    disableEditButton: ownProps.disableEditButton || false,
});

const mapDispatchToProps = {
    openModal: openModalAction,
    updateFormDetails: updateFormDetailsAction,
};

export const MedicalInformationDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(medicalInformationDetailsView));
