import { Paper, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { FormHeading } from '../../../forms';
import { State } from '../../../redux/root-reducer';
import { Nullable } from '../../../shared-types';
import styles, { StyledComponent } from '../../../styles/styles';
import { getIncomeCalculations, IncomeCalculation } from '../reducer';
import { IncomeCalculationView } from './income-calculation';

interface Props extends StyledComponent {
    incomeCalculations: Nullable<IncomeCalculation[]>;
}

function incomeCalculator({ classes, incomeCalculations }: Props) {
    return (
        <Paper className={classes.paper}>
            <FormHeading>Income / Retrenchment Calculator</FormHeading>
            {incomeCalculations &&
                incomeCalculations.map((calculation, index) => (
                    <IncomeCalculationView
                        key={`calculation-view-${index}`}
                        policyCalculation={calculation}
                    />
                ))}
        </Paper>
    );
}

const mapStateToProps = (state: State) => ({
    incomeCalculations: getIncomeCalculations(state),
});

export const IncomeCalculator = connect(mapStateToProps, {})(withStyles(styles)(incomeCalculator));
