import { AxiosRequestConfig } from 'axios';
import { getTokenAfterLogin } from '../auth/authentication-service';

export async function securityInteceptor(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    const token = await getTokenAfterLogin();
    return {
        ...config,
        headers: {
            ...config.headers,
            Authorization: `Bearer ${token.accessToken}`,
        },
    };
}
