import { ReduceCollectionRecordAction } from '../../../../../../../redux/collection-record-reducer';
import { UpdateRecordCollectionAction } from '../../../../../../../redux/collection-reducer';
import { PostUnderwritingChange } from '../post-underwriting-change-model';
import { UPDATE_POST_UNDERWRITING_CHANGE } from './post-underwriting-change-action-types';

export type UpdatePostUnderwritingChangeAction = UpdateRecordCollectionAction<PostUnderwritingChange>;

export type UpdatePolicyPostUnderwritingChangeAction = ReduceCollectionRecordAction<UpdatePostUnderwritingChangeAction>;

export const updatePostUnderwritingChangeActionCreator = (
    policyIndex: number,
    postUnderwritingChangeIndex: number,
    postUnderwritingChange: PostUnderwritingChange
): UpdatePolicyPostUnderwritingChangeAction => {
    return {
        type: UPDATE_POST_UNDERWRITING_CHANGE,
        payload: {
            type: UPDATE_POST_UNDERWRITING_CHANGE,
            record: postUnderwritingChange,
            index: postUnderwritingChangeIndex,
        },
        index: policyIndex,
    };
};
