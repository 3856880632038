import { DeathRequirements } from '../reducer';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { claimSubmit, Claim } from '../../../../../claim';
import { Nullable } from '../../../../../shared-types';

type SubmitReturn = Promise<any>;
export type UpdateRequirements = (deathRequirements: Nullable<DeathRequirements>) => SubmitReturn;
type UpdateRequirementsAction = (
    deathRequirements: Nullable<DeathRequirements>
) => ReduxThunkAction<SubmitReturn>;

export const updateRequirementsAction: UpdateRequirementsAction = (
    deathRequirements: Nullable<DeathRequirements>
) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, getUpdatedClaim(deathRequirements));
    };
};

function getUpdatedClaim(deathRequirements: Nullable<DeathRequirements>): (claim: Claim) => Claim {
    return (claim: Claim) => {
        return {
            ...claim,
            documentsStatus: deathRequirements,
        };
    };
}
