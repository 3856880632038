import { Divider, Grid, Paper, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { FormErrors, FullWidthDatePicker, GenericForm } from '../../../../../../forms';
import { State } from '../../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { OnSubmitSuccessProps } from '../../../../../../workflow-navigation';
import {
    UpdateConsultationDate,
    updateMedicalDetailsAction,
} from '../../actions/update-medical-history';
import { getMedicalDoctorHistoryDetails, MedicalHistory } from '../../reducer';

interface Props extends OnSubmitSuccessProps, StyledComponent {
    medicalHistory: MedicalHistory;
    updateMedicalHistory: UpdateConsultationDate;
}

const MedicalHistoryForm = GenericForm<MedicalHistory>();
const medicalHistoryEdit: React.FunctionComponent<Props> = ({
    classes,
    medicalHistory,
    updateMedicalHistory,
    ...rest
}: Props) => {
    const onSubmit = (medicalHistory: MedicalHistory) => {
        return updateMedicalHistory(medicalHistory.firstConsultationDate);
    };
    return (
        <MedicalHistoryForm onSubmit={onSubmit} initialValues={medicalHistory} {...rest}>
            <Typography variant="h5">Medical History</Typography>
            <Divider />
            <Paper className={classes.paper}>
                <Grid container spacing={5} justify="flex-start">
                    <Grid item xs={8}>
                        <FullWidthDatePicker
                            id="firstConsultationDate"
                            helperText="When did the deceased first consult a doctor for his/her last illness?"
                            name="firstConsultationDate"
                            disableFuture
                        />
                    </Grid>
                </Grid>
            </Paper>
            <FormErrors />
        </MedicalHistoryForm>
    );
};

const mapDispatchToProps = {
    updateMedicalHistoryDetails: updateMedicalDetailsAction,
};

const mapStateToProps = (state: State) => ({
    medicalHistory: getMedicalDoctorHistoryDetails(state),
});

export const MedicalHistoryEdit = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(medicalHistoryEdit));
