import React from 'react'; // eslint-disable-line

import { FormHelperText, Checkbox as MuiCheckbox, FormControlLabel } from '@material-ui/core';
import { FieldRenderProps } from 'react-final-form';

import { callAllFunctions, getComponentId, nameToLabel, processMetaForErrors } from '../../';
import { raspberry } from '../../../styles/colours';

type Props = FieldRenderProps<any, any> & {
    label?: string;
    id?: string;
    onChange?: (values: any) => void;
    hideLabel?: boolean;
    disabled?: boolean;
    helperText?: React.ReactNode;
};

export const Checkbox: React.FunctionComponent<Props> = ({
    input,
    label,
    id,
    meta,
    onChange,
    hideLabel,
    disabled,
    helperText,
    ...rest
}: Props) => {
    const { checked, name, onChange: inputOnChange, ...restInput } = input;

    const { errorMessage, showError } = processMetaForErrors(meta);
    const showErrorColor = (error: boolean) => {
        return error ? raspberry : undefined;
    };

    const showHelperText = showError || !!helperText;

    return (
        <React.Fragment>
            <FormControlLabel
                style={{ color: showErrorColor(showError) }}
                control={
                    <MuiCheckbox
                        {...rest}
                        id={getComponentId({ id, name })}
                        style={{ color: showErrorColor(showError) }}
                        name={name}
                        inputProps={restInput}
                        onChange={callAllFunctions(onChange, inputOnChange)}
                        checked={!!checked}
                        disabled={disabled}
                    />
                }
                label={hideLabel ? '' : nameToLabel({ label, name })}
            />
            {showHelperText && (
                // @ts-ignore
                <FormHelperText error={showError} component="pre" style={{ marginTop: '-2px' }}>
                    {showError ? errorMessage : helperText}
                </FormHelperText>
            )}
        </React.Fragment>
    );
};
