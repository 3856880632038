import { Claim, claimSubmit } from '../../../../../claim';
import { RADeathClaim } from '../../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { BankingDetails } from '../../../common/banking-details/banking-details';

type UpdateBankingDetailsReturn = Promise<any>;
export type UpdateBankingDetails = (bankingDetails: BankingDetails) => UpdateBankingDetailsReturn;
type UpdateBankingDetailsAction = (
    bankingDetails?: BankingDetails
) => ReduxThunkAction<UpdateBankingDetailsReturn>;

export const updateBankingDetailsAction: UpdateBankingDetailsAction = (
    bankingDetails?: BankingDetails
) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, getUpdatedBankingDetails(bankingDetails));
    };
};

function getUpdatedBankingDetails(bankingDetails?: BankingDetails): (claim: Claim) => Claim {
    return (claim: Claim) => {
        if (claim.claimType === 'RADeath') {
            const raClaim = claim as RADeathClaim;
            return {
                ...claim,
                lifeAssuredDetails: {
                    ...raClaim.lifeAssuredDetails,
                    bankingDetails: bankingDetails,
                },
            };
        }

        return claim;
    };
}
