import { DeleteRecordCollectionAction } from '../../../../../redux/collection-reducer';
import { DELETE_CLAIMANT_RECORD } from './types';

export type DeleteClaimantRecordAction = DeleteRecordCollectionAction;

export const deleteClaimantRecordActionCreator = (
    claimantRecordIndex: number
): DeleteClaimantRecordAction => {
    return {
        type: DELETE_CLAIMANT_RECORD,
        index: claimantRecordIndex,
    };
};
