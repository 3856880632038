import React from 'react'; // eslint-disable-line

import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import { Email, Person, PhoneIphone, PhoneRounded } from '@material-ui/icons';
import { FinancialAdviserContactDetails } from '../../../../contact-details/financial-adviser/financial-adviser-contact-details';
import { getFullName } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';

type Props = StyledComponent & {
    contactDetails: FinancialAdviserContactDetails;
    isAdviser?: boolean;
    isFinancialAdviser?: boolean;
};

const ContactDetailsCard: React.FunctionComponent<Props> = ({
    classes,
    contactDetails,
    isAdviser,
    isFinancialAdviser,
}: Props) => {
    const contactDescription = isAdviser ? 'Financial Adviser' : 'Contact person for claim';

    return (
        <Grid item lg={6} xs={12} style={{ marginTop: 0 }}>
            <Paper className={classes.paper}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography>{contactDescription}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Person color="secondary" fontSize="large" />
                    </Grid>
                    <Grid item xs={11}>
                        <Grid container justify="flex-start">
                            <Grid item xs={12}>
                                {contactDetails.companyName && contactDetails.companyName !== '' ? (
                                    <Typography variant="h5">
                                        {contactDetails.companyName}
                                    </Typography>
                                ) : (
                                    <Typography variant="h5">
                                        {getFullName(contactDetails)}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={3} lg={5}>
                                <Typography variant="subtitle2">Telephone Number:</Typography>
                            </Grid>
                            <Grid item xs={9} lg={7}>
                                <Typography style={{ marginTop: 2 }}>
                                    {contactDetails.telephoneNumber}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} lg={5}>
                                <Typography variant="subtitle2">Cellphone:</Typography>
                            </Grid>
                            <Grid item xs={9} lg={7}>
                                <Typography style={{ marginTop: 2 }}>
                                    {contactDetails.cellphoneNumber}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} lg={5}>
                                <Typography variant="subtitle2">Email Address:</Typography>
                            </Grid>
                            <Grid item xs={9} lg={7}>
                                <Typography style={{ marginTop: 2 }}>
                                    {contactDetails.emailAddress}
                                </Typography>
                            </Grid>
                            {isAdviser ? (
                                <React.Fragment>
                                    <Grid item xs={3} lg={5}>
                                        <Typography variant="subtitle2">
                                            Broker consultant code:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} lg={7}>
                                        <Typography style={{ marginTop: 2 }}>
                                            {contactDetails.consultantCode}
                                        </Typography>
                                    </Grid>
                                </React.Fragment>
                            ) : (
                                <Grid item xs={12} lg={12}>
                                    <Typography variant="subtitle2">&nbsp;</Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>
                        {getCommmunicationIcon(contactDetails)}
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant="subtitle2">Preferred contact</Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export function getCommmunicationIcon(
    contactDetails: FinancialAdviserContactDetails,
    size: any = 'large'
) {
    let preferredMethodOfCommunication = contactDetails.preferredMethodOfCommunication;
    if (!preferredMethodOfCommunication) {
        if (contactDetails.cellphoneNumber) {
            preferredMethodOfCommunication = 'Cellphone';
        } else {
            if (contactDetails.emailAddress) {
                preferredMethodOfCommunication = 'Email';
            }
        }
    }
    switch (preferredMethodOfCommunication) {
        case 'Email':
            return <Email color="secondary" fontSize={size} />;
        case 'Cellphone':
            return <PhoneIphone color="secondary" fontSize={size} />;
        default:
            return <PhoneRounded color="secondary" fontSize={size} />;
    }
}

export default withStyles(styles)(ContactDetailsCard);
