import { claimSubmit } from '../../../../claim';
import { Claim } from '../../../../claim/reducers';
import { FuneralClaim } from '../../../../claim/reducers/claim-reducers/funeral-claim-reducer';
import { addRecordReduction, updateRecordReduction } from '../../../../redux/reductions';
import { ReduxThunkAction } from '../../../../redux/redux-actions';
import {
    AssessmentDetails,
    FuneralAssessmentDecisionViewModel,
} from '../funeral-assessment-details-reducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AddFuneralAssessmentDecisionReturn = Promise<any>;
export type AddFuneralAssessmentDecision = (
    funeralAssessmentDecision: FuneralAssessmentDecisionViewModel
) => AddFuneralAssessmentDecisionReturn;
type AddFuneralAssessmentDecisionAction = (
    funeralAssessmentDecision: FuneralAssessmentDecisionViewModel
) => ReduxThunkAction<AddFuneralAssessmentDecisionReturn>;
export const addFuneralAssessmentDecisionAction: AddFuneralAssessmentDecisionAction = (
    funeralAssessmentDecision: FuneralAssessmentDecisionViewModel
) => async (dispatch, getState) => {
    return await claimSubmit(dispatch, getState, claim =>
        getUpdatedClaim(claim, funeralAssessmentDecision)
    );
};

function getUpdatedClaim(claim: Claim, assessmentDecision: FuneralAssessmentDecisionViewModel) {
    const funeralClaim = claim as FuneralClaim;

    const assessmentDetails = getUpdatedAssessmentDetails(
        funeralClaim.assessmentDetails,
        assessmentDecision
    );
    return {
        ...funeralClaim,
        assessmentDetails,
    };
}

function getUpdatedAssessmentDetails(
    assessmentDetails: AssessmentDetails[],
    assessmentDecisionViewModel: FuneralAssessmentDecisionViewModel
): AssessmentDetails[] {
    if (assessmentDetails.length === 0) {
        return assessmentDetails;
    }

    const assessmentDecisionsIndex = assessmentDetails.findIndex(
        pAD => pAD.policyNumber === assessmentDecisionViewModel.policyNumber
    );
    if (assessmentDecisionsIndex === -1) {
        throw new Error('Invalid Assessment Decision: Policy Number not found');
    }
    let policyAssessmentDecisions = assessmentDetails[assessmentDecisionsIndex];

    const benefitAssessmentDecisionsIndex = policyAssessmentDecisions.benefits.findIndex(
        bAD => bAD.benefitName === assessmentDecisionViewModel.benefitName
    );
    if (benefitAssessmentDecisionsIndex === -1) {
        throw new Error('Invalid Assessment Decision: Benefit not found');
    }
    let benefitAssessmentDecisions =
        policyAssessmentDecisions.benefits[benefitAssessmentDecisionsIndex];

    benefitAssessmentDecisions = {
        ...benefitAssessmentDecisions,
        decisions: addRecordReduction(
            benefitAssessmentDecisions.decisions,
            assessmentDecisionViewModel
        ),
    };

    policyAssessmentDecisions = {
        ...policyAssessmentDecisions,
        benefits: updateRecordReduction(
            policyAssessmentDecisions.benefits,
            benefitAssessmentDecisions,
            benefitAssessmentDecisionsIndex
        ),
    };

    return updateRecordReduction(
        assessmentDetails,
        policyAssessmentDecisions,
        assessmentDecisionsIndex
    );
}
