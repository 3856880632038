import { ClaimReferenceAction } from '../reducers/claim-reference-reducer';
import { SET_REFERENCE } from '../types';

export type SetReference = (
    referenceSource: string,
    referenceId: string,
    creationDate: string
) => ClaimReferenceAction;
export const setReferenceAction: SetReference = (
    referenceSource: string,
    referenceId: string,
    creationDate: string
) => {
    sessionStorage.setItem('referenceSource', referenceSource);
    sessionStorage.setItem('referenceId', referenceId);
    sessionStorage.setItem('creationDate', creationDate);

    return {
        type: SET_REFERENCE,
        payload: {
            referenceSource,
            referenceId,
            creationDate,
        },
    };
};
