import React from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import { Section } from '../../../../../../controls/section';
import { State } from '../../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { UnderwritingBenefitExclusionCollection } from './underwriting-benefit-exclusion-collection';
import { UnderwritingExclusion } from './underwriting-exlusion-model';

interface OwnProps {
    benefitExclusions: UnderwritingBenefitExclusionCollection[];
}

interface StateProps {}

interface DispatchProps {}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const underwritingExlusionsView: React.FunctionComponent<Props> = ({
    benefitExclusions,
}: Props) => {
    const exclusions = benefitExclusions
        ? benefitExclusions
              .filter(
                  exclusionCollection =>
                      exclusionCollection.exclusions && exclusionCollection.exclusions.length > 0
              )
              .map(exclusionCollection =>
                  exclusionCollection.exclusions.map(exclusion => ({
                      benefit: exclusionCollection.benefit,
                      exclusion,
                  }))
              )
              .reduce((left, right) => left.concat(right), [])
        : [];

    return (
        <Section title="Exclusions" noChildrenTitle="No Exclusions">
            {exclusions.map((benefitExclusion, exclIndex) => (
                <UnderwritingExlusionView
                    benefit={benefitExclusion.benefit}
                    exclusion={benefitExclusion.exclusion}
                    key={`exclusion${exclIndex}`}
                />
            ))}
        </Section>
    );
};

interface ExclusionProps {
    benefit: string;
    exclusion: UnderwritingExclusion;
}

const UnderwritingExlusionView: React.FunctionComponent<ExclusionProps> = ({
    benefit,
    exclusion,
}: ExclusionProps) => {
    return (
        <React.Fragment>
            <Grid item xs={3}>
                <LabeledValue id="benefit" label="Benefit" value={benefit} />
            </Grid>
            <Grid item xs={3}>
                <LabeledValue id="code" label="Code" value={exclusion.code} />
            </Grid>
            <Grid item xs={6}>
                <LabeledValue id="description" label="Description" value={exclusion.description} />
            </Grid>
        </React.Fragment>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = () => ({});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {};

export const UnderwritingExlusionsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(underwritingExlusionsView));
