import React from 'react'; // eslint-disable-line

import { Button, Grid, Tooltip, withStyles } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { OpenDialog, openDialogAction } from '../../../../../../confirmation-dialog';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import { EditFormModal } from '../../../../../../form-modal';
import { OpenEditModal, openEditModalAction } from '../../../../../../form-modal/actions';
import { State } from '../../../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../../../shared-functions';
import styles, { StyledComponent } from '../styles';
import {
    DeleteAndSubmitUnderwritingNonDisclosure,
    deleteAndSubmitUnderwritingNonDisclosureThunk,
    UpdateAndSubmitUnderwritingNonDisclosure,
    updateAndSubmitUnderwritingNonDisclosureThunk,
} from './actions/submit-underwriting-non-disclosure';
import { UnderwritingNonDisclosureForm } from './underwriting-non-disclosure-form';
import { UnderwritingNonDisclosure } from './underwriting-non-disclosure-model';
import { ButtonWrapper } from '../../../../../../forms';

interface OwnProps {
    policyIndex: number;
    nonDisclosures: UnderwritingNonDisclosure[];
    nonDisclosureIndex: number;
    nonDisclosure: UnderwritingNonDisclosure;
    isUnderWriting?: boolean;
}

interface StateProps {
    isReadOnly: boolean;
}

interface DispatchProps {
    updateAndSubmitUnderwritingNonDisclosure: UpdateAndSubmitUnderwritingNonDisclosure;
    deleteAndSubmitUnderwritingNonDisclosure: DeleteAndSubmitUnderwritingNonDisclosure;
    openEditModal: OpenEditModal;
    openDialog: OpenDialog;
}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const underwritingNonDisclosureView: React.FunctionComponent<Props> = ({
    policyIndex,
    nonDisclosureIndex,
    nonDisclosure,
    nonDisclosures,
    classes,
    isUnderWriting,
    updateAndSubmitUnderwritingNonDisclosure,
    deleteAndSubmitUnderwritingNonDisclosure,
    openEditModal,
    openDialog,
    isReadOnly,
}: Props) => {
    return (
        <Grid item container className={classes.separatedGridItem} justify="space-between">
            <Grid item container xs={10} lg={11}>
                <Grid item xs={3}>
                    <LabeledValue
                        id="date"
                        label="Date"
                        value={getDisplayDate(nonDisclosure.date)}
                    />
                </Grid>
                <Grid item xs={9}>
                    <LabeledValue id="source" label="Source" value={nonDisclosure.source} />
                </Grid>
                <Grid item xs={12}>
                    <LabeledValue id="details" label="Details" value={nonDisclosure.details} />
                </Grid>
            </Grid>
            <ButtonWrapper>
                <Grid item container xs={2} lg={1}>
                    <Grid item container xs={12} justify="flex-end">
                        {editNonDisclosureButton(
                            policyIndex,
                            nonDisclosures,
                            nonDisclosureIndex,
                            updateAndSubmitUnderwritingNonDisclosure,
                            openEditModal,
                            isUnderWriting
                        )}
                    </Grid>
                    <Grid item container xs={12} justify="flex-end">
                        {deleteNonDisclosureButton(
                            policyIndex,
                            nonDisclosureIndex,
                            deleteAndSubmitUnderwritingNonDisclosure,
                            openDialog,
                            isUnderWriting
                        )}
                    </Grid>
                </Grid>
            </ButtonWrapper>
        </Grid>
    );
};

const UnderwritingNonDisclosureEditFormModal = EditFormModal<UnderwritingNonDisclosure>();
const editNonDisclosureButton = (
    policyIndex: number,
    nonDisclosures: UnderwritingNonDisclosure[],
    nonDisclosureIndex: number,
    updateAndSubmitUnderwritingNonDisclosure: UpdateAndSubmitUnderwritingNonDisclosure,
    openEditModal: OpenEditModal,
    isUnderWriting: any
) => {
    const openEditView = () => {
        openEditModal({
            modalContent: (
                <UnderwritingNonDisclosureEditFormModal
                    recordDetailView={<UnderwritingNonDisclosureForm />}
                    records={nonDisclosures}
                    updateRecord={(nonDisclosure, index) =>
                        updateAndSubmitUnderwritingNonDisclosure(policyIndex, index, nonDisclosure)
                    }
                />
            ),
            modalData: {
                recordIndex: nonDisclosureIndex,
            },
        });
    };

    return (
        <Tooltip title="Edit Non-Disclosure">
            <Button
                id="EditNonDisclosure"
                color="secondary"
                disabled={isUnderWriting}
                onClick={openEditView}
            >
                <Edit />
            </Button>
        </Tooltip>
    );
};

const deleteNonDisclosureButton = (
    policyIndex: number,
    nonDisclosureIndex: number,
    deleteAndSubmitUnderwritingNonDisclosure: DeleteAndSubmitUnderwritingNonDisclosure,
    openDialog: OpenDialog,
    isUnderWriting: any
) => {
    const confirmDelete = () => {
        openDialog({
            content: {
                title: 'Delete Non-Disclosure',
                body: 'Are you sure you would like to delete the selected non-disclosure?',
            },
            yesAction: {
                action: () =>
                    deleteAndSubmitUnderwritingNonDisclosure(policyIndex, nonDisclosureIndex),
            },
        });
    };

    return (
        <Tooltip title="Delete Non-Disclosure">
            <Button
                id="DeleteNonDisclosure"
                color="secondary"
                disabled={isUnderWriting}
                onClick={confirmDelete}
            >
                <Delete />
            </Button>
        </Tooltip>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = state => ({
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    updateAndSubmitUnderwritingNonDisclosure: (
        policyIndex: number,
        recordIndex: number,
        nonDisclosure: UnderwritingNonDisclosure
    ) =>
        dispatch(
            updateAndSubmitUnderwritingNonDisclosureThunk(policyIndex, recordIndex, nonDisclosure)
        ),
    deleteAndSubmitUnderwritingNonDisclosure: (policyIndex: number, recordIndex: number) =>
        dispatch(deleteAndSubmitUnderwritingNonDisclosureThunk(policyIndex, recordIndex)),
    openEditModal: options => dispatch(openEditModalAction(options)),
    openDialog: options => dispatch(openDialogAction(options)),
});

export const UnderwritingNonDisclosureView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(underwritingNonDisclosureView));
