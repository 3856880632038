import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect, MapStateToProps } from 'react-redux';
import { getClaim } from '../../../../claim';
import { ClaimType } from '../../../../claim/claim-types';
import { AddFormModal } from '../../../../form-modal';
import { OpenModal, openModalAction } from '../../../../form-modal/actions';
import {
    Condition,
    FormHeading,
    FullWidthRadioGroup,
    FullWidthSelectField,
    RadioOption,
    True,
    When,
    WhenFieldChanges,
} from '../../../../forms';
import { requiredValidator } from '../../../../forms/validations';
import { State } from '../../../../redux/root-reducer';
import { formatCurrency, formatPercent, getDisplayDate } from '../../../../shared-functions';
import { Nullable } from '../../../../shared-types';
import styles, { StyledComponent } from '../../../../styles/styles';
import {
    AddAssessmentDecision,
    addAssessmentDecisionAction,
} from '../../actions/add-assessment-decision';
import { AssessmentDecisionViewModel, mapBenefits } from '../../assessment-details-reducer';
interface TestProps {
    assessmentDescription: string;
    automaticBenefitIncrease?: number;
    benefitName: string;
    causeOfClaim?: string;
    commencementDate?: Date;
    noPartialPayment: boolean;
    policyNumber: string;
    sumAssured?: number;
}

interface OwnProps {
    blankRecord: AssessmentDecisionViewModel;
}

export interface AddAssessmentDecisionProps extends OwnProps, TestProps {}

interface StateProps {
    currentClaimType: Nullable<ClaimType>;
    dateOfEvent?: Date;
    lastDayAtWork?: Date;
    returnToWork?: Date;
    auth?: any;
    assessorLimits?: any;
    currentBenefitLimit?: any;
}

interface DispatchProps {
    addAssessmentDecision: AddAssessmentDecision;
    openModal: OpenModal;
}

interface DecisionProps extends TestProps, StateProps, StyledComponent {
    claimSpecificApproveElements: React.ReactNode;
    currentClaimType: Nullable<ClaimType>;
}

type Props = DecisionProps & OwnProps & StateProps & DispatchProps & StyledComponent;
const AssessmentDecisionAddFormModal = AddFormModal<AssessmentDecisionViewModel>();
const assessmentDecisionAddView: React.FunctionComponent<Props> = ({
    addAssessmentDecision,
    blankRecord,
    claimSpecificApproveElements,
    openModal,
    auth,
    assessorLimits,
    ...detailProps
}: Props) => {
    const limits = assessorLimits.filter((limits: any) => limits.email === auth.userId);
    const currentBenefitLimit = limits[0]?.assessorLimits.filter(
        (lim: any) =>
            mapBenefits(lim.productType)?.toLowerCase() ===
            detailProps?.currentClaimType?.toLowerCase()
    );
    return (
        <AssessmentDecisionAddFormModal
            addRecord={addAssessmentDecision}
            hideAddAnother
            blankRecord={blankRecord}
            recordDetailView={() => (
                <ClaimAssessmentDecisionDetailView
                    {...detailProps}
                    currentBenefitLimit={currentBenefitLimit && currentBenefitLimit[0]}
                    claimSpecificApproveElements={claimSpecificApproveElements}
                />
            )}
        />
    );
};

const ClaimAssessmentDecisionDetailView: React.FunctionComponent<DecisionProps> = ({
    assessmentDescription,
    automaticBenefitIncrease,
    benefitName,
    causeOfClaim,
    claimSpecificApproveElements,
    classes,
    commencementDate,
    noPartialPayment,
    policyNumber,
    sumAssured,
    currentClaimType,
    dateOfEvent,
    lastDayAtWork,
    returnToWork,
    currentBenefitLimit,
}: DecisionProps) => {
    return (
        <React.Fragment>
            <FormHeading>{assessmentDescription} Decision</FormHeading>
            <Grid container spacing={5} justify="space-between" className={classes.marginTop}>
                <Grid item>
                    <Typography variant="body2">Policy Number</Typography>
                    <Typography variant="body2">{policyNumber}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2">Benefit Name</Typography>
                    <Typography variant="body2">{benefitName}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2">Sum Assured</Typography>
                    <Typography variant="body2">{formatCurrency(sumAssured)}</Typography>
                    {sumAssured &&
                        currentBenefitLimit &&
                        sumAssured > parseInt(currentBenefitLimit.amount) && (
                            <Typography variant="body2" style={{ color: 'Red', width: '80%' }}>
                                Decision to pay is over your authorised payment limit, needs manager
                                authorisation.
                            </Typography>
                        )}
                </Grid>
                <Grid item>
                    <Typography variant="body2">Commencement Date</Typography>
                    <Typography variant="body2">{getDisplayDate(commencementDate)}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} justify="space-between" className={classes.marginTop}>
                <Grid item>
                    <Typography variant="body2">Automatic Benefit Increase</Typography>
                    <Typography variant="body2">
                        {formatPercent((automaticBenefitIncrease || 0) / 100)}
                    </Typography>
                </Grid>
            </Grid>
            {causeOfClaim && (
                <Grid container spacing={5} justify="space-between" className={classes.marginTop}>
                    <Grid item>
                        <Typography variant="body2">Claim Event</Typography>
                        <Typography variant="body2">{causeOfClaim}</Typography>
                    </Grid>
                </Grid>
            )}
            <Grid container spacing={5}>
                <Grid item xs={4}>
                    <FullWidthRadioGroup name="mapClaim" validate={requiredValidator}>
                        <RadioOption value="Yes" />
                        <RadioOption value="No" />
                    </FullWidthRadioGroup>
                </Grid>
                <Grid item xs={4}>
                    <FullWidthRadioGroup
                        label="EAIP Claims Booster"
                        name="claimsBooster"
                        validate={requiredValidator}
                    >
                        <RadioOption value="Yes" />
                        <RadioOption value="No" />
                    </FullWidthRadioGroup>
                </Grid>
            </Grid>
            <Grid container spacing={5}>
                <Grid item xs={4}>
                    <FullWidthSelectField
                        name="decision"
                        options={[
                            { value: 'Approve', label: 'Approve' },
                            { value: 'Decline', label: 'Decline' },
                            { value: 'Defer', label: 'Defer' },
                            { value: 'NotApplicable', label: 'Not Applicable' },
                        ]}
                        validate={requiredValidator}
                    />
                    <WhenFieldChanges field="decision" set="claimType" to={currentClaimType} />
                    <WhenFieldChanges field="decision" set="reason" to={null} />
                    <WhenFieldChanges
                        field="decision"
                        set="effectiveDate"
                        to={() =>
                            currentClaimType === ClaimType.Dread ||
                            currentClaimType === ClaimType.Disability
                                ? dateOfEvent
                                : lastDayAtWork
                        }
                    />
                    <WhenFieldChanges field="decision" set="expiryDate" to={returnToWork} />
                    <WhenFieldChanges
                        field="decision"
                        set="percentagePayable"
                        to={() => (noPartialPayment ? '100' : '')}
                    />
                    <WhenFieldChanges
                        field="decision"
                        set="amountPayable"
                        to={() => (noPartialPayment ? sumAssured : '')}
                    />
                    <WhenFieldChanges
                        field="decision"
                        set="isOverLimit"
                        to={() =>
                            sumAssured &&
                            currentBenefitLimit &&
                            sumAssured > parseInt(currentBenefitLimit.amount)
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <Condition when="decision" matchesChild>
                        <When is="Approve">
                            <FullWidthSelectField
                                name="reason"
                                options={[
                                    { value: 'Approve', label: 'Approve' },
                                    {
                                        value: 'New Terms Accepted',
                                        label: 'New Terms Accepted',
                                    },
                                    {
                                        value: 'Business Decision',
                                        label: 'Business Decision',
                                    },
                                    { value: 'Claim Committee', label: 'Claim Committee' },
                                    { value: 'Complaint', label: 'Complaint' },
                                ]}
                                validate={requiredValidator}
                            />
                        </When>
                        <When is="Decline">
                            <FullWidthSelectField
                                name="reason"
                                options={[
                                    {
                                        value: 'Innocent Non-disclosure (I)',
                                        label: 'Innocent Non-disclosure (I)',
                                    },
                                    {
                                        value: 'Deliberate Non-disclosure (D)',
                                        label: 'Deliberate Non-disclosure (D)',
                                    },
                                    { value: 'Fraud', label: 'Fraud' },
                                    {
                                        value: 'Does not meet the criteria',
                                        label: 'Does not meet the criteria',
                                    },
                                    {
                                        value: 'No benefit exists',
                                        label: 'No benefit exists',
                                    },
                                    {
                                        value: 'Benefits expired',
                                        label: 'Benefits expired',
                                    },
                                    { value: 'Exclusion', label: 'Exclusion' },
                                    {
                                        value: 'Condition not covered',
                                        label: 'Condition not covered',
                                    },
                                    { value: 'Policy inactive', label: 'Policy inactive' },
                                    {
                                        value: 'New terms offered - does not qualify for a claim',
                                        label: 'New terms offered - does not qualify for a claim',
                                    },
                                    {
                                        value: 'New terms offered - does qualify for a claim',
                                        label: 'New terms offered - does qualify for a claim',
                                    },
                                    {
                                        value: 'Within waiting period',
                                        label: 'Within waiting period',
                                    },
                                ]}
                                validate={requiredValidator}
                            />
                            {!IsTriggerVisible() && (
                                <FullWidthSelectField
                                    name="claimType"
                                    options={[
                                        { value: 'Dread', label: 'Dread' },
                                        { value: 'Disability', label: 'Disability' },
                                        { value: 'TerminalIllness', label: 'Terminal Illness' },
                                        { value: 'Retrenchment', label: 'Retrenchment' },
                                        { value: 'IncomeProtector', label: 'Income Protector' },
                                        { value: 'EarlyRetirement', label: 'Early Retirement' },
                                    ]}
                                    validate={requiredValidator}
                                />
                            )}
                        </When>
                        <When is="NotApplicable">
                            <FullWidthSelectField
                                name="reason"
                                options={[
                                    {
                                        value: 'Innocent Non-disclosure (I)',
                                        label: 'Innocent Non-disclosure (I)',
                                    },
                                    {
                                        value: 'Deliberate Non-disclosure (D)',
                                        label: 'Deliberate Non-disclosure (D)',
                                    },
                                    { value: 'Fraud', label: 'Fraud' },
                                    {
                                        value: 'Does not meet the criteria',
                                        label: 'Does not meet the criteria',
                                    },
                                    {
                                        value: 'No benefit exists',
                                        label: 'No benefit exists',
                                    },
                                    {
                                        value: 'Benefits expired',
                                        label: 'Benefits expired',
                                    },
                                    { value: 'Exclusion', label: 'Exclusion' },
                                    {
                                        value: 'Condition not covered',
                                        label: 'Condition not covered',
                                    },
                                    { value: 'Policy inactive', label: 'Policy inactive' },
                                    {
                                        value: 'New terms offered - does not qualify for a claim',
                                        label: 'New terms offered - does not qualify for a claim',
                                    },
                                    {
                                        value: 'New terms offered - does qualify for a claim',
                                        label: 'New terms offered - does qualify for a claim',
                                    },
                                    {
                                        value: 'Within waiting period',
                                        label: 'Within waiting period',
                                    },
                                ]}
                                validate={requiredValidator}
                            />
                            {!IsTriggerVisible() && (
                                <FullWidthSelectField
                                    name="claimType"
                                    options={[
                                        { value: 'Dread', label: 'Dread' },
                                        { value: 'Disability', label: 'Disability' },
                                        { value: 'TerminalIllness', label: 'Terminal Illness' },
                                        { value: 'Retrenchment', label: 'Retrenchment' },
                                        { value: 'IncomeProtector', label: 'Income Protector' },
                                        { value: 'EarlyRetirement', label: 'Early Retirement' },
                                    ]}
                                    validate={requiredValidator}
                                />
                            )}
                        </When>
                        <When default>
                            <FullWidthSelectField name="reason" disabled options={[]} />
                            {!IsTriggerVisible() && (
                                <FullWidthSelectField
                                    name="claimType"
                                    options={[
                                        { value: 'Dread', label: 'Dread' },
                                        { value: 'Disability', label: 'Disability' },
                                        { value: 'TerminalIllness', label: 'Terminal Illness' },
                                        { value: 'Retrenchment', label: 'Retrenchment' },
                                        { value: 'IncomeProtector', label: 'Income Protector' },
                                        { value: 'EarlyRetirement', label: 'Early Retirement' },
                                    ]}
                                    validate={requiredValidator}
                                />
                            )}
                        </When>
                    </Condition>
                </Grid>
                <Condition when="decision" is="Approve">
                    <True>{claimSpecificApproveElements}</True>
                </Condition>
                {IsTriggerVisible() && (
                    <Grid item xs={4}>
                        <FullWidthSelectField
                            name="claimType"
                            options={[
                                { value: 'Dread', label: 'Dread' },
                                { value: 'Disability', label: 'Disability' },
                                { value: 'TerminalIllness', label: 'Terminal Illness' },
                                { value: 'Retrenchment', label: 'Retrenchment' },
                                { value: 'IncomeProtector', label: 'Income Protector' },
                                { value: 'EarlyRetirement', label: 'Early Retirement' },
                            ]}
                            validate={requiredValidator}
                        />
                    </Grid>
                )}
            </Grid>
        </React.Fragment>
    );
    function IsTriggerVisible() {
        if (
            currentClaimType === ClaimType.Disability ||
            currentClaimType === ClaimType.IncomeProtector
        ) {
            return false;
        }
        return true;
    }
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    currentClaimType: getClaim(state).claimType,
    assessorLimits: state.assessorDashboard.assessorLimits,
    auth: state.auth,
});

const mapDispatchToProps = {
    addAssessmentDecision: addAssessmentDecisionAction,
    openModal: openModalAction,
};

export const AssessmentDecisionAddView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(assessmentDecisionAddView));
