import React from 'react'; // eslint-disable-line

import { FormHelperText, InputLabel, MenuItem, Select as MuiSelect } from '@material-ui/core';
import { FieldRenderProps } from 'react-final-form';
import { callAllFunctions, getComponentId, nameToLabel, processMetaForErrors } from '../../';

interface DropDownOptionValue {
    value: string;
    label: string;
}

type Props = FieldRenderProps<any, any> & {
    id?: string;
    label?: string;
    helperText?: React.ReactNode;
    options: DropDownOptionValue[];
    sortOptions?: boolean;
    onChange?: (values: any) => void;
};

export const SelectField: React.FunctionComponent<Props> = ({
    input,
    meta,
    id,
    label,
    helperText,
    options,
    sortOptions,
    onChange,
    ...rest
}: Props) => {
    const { name, value, ...restInput } = input;
    const { errorMessage, showError } = processMetaForErrors(meta);
    const sortedOptions = sortOptions ? optionSort(options) : options;
    const itemList = sortedOptions.map(option => (
        <MenuItem key={option.value} value={option.value}>
            {option.label}
        </MenuItem>
    ));
    const showHelperText = showError || !!helperText;
    return (
        <React.Fragment>
            <InputLabel htmlFor={name} error={showError}>
                {nameToLabel({ label, name })}
            </InputLabel>
            <MuiSelect
                {...rest}
                name={name}
                id={getComponentId({ id, name })}
                error={showError}
                onChange={callAllFunctions(onChange, input.onChange)}
                value={value}
                inputProps={restInput}
                MenuProps={{
                    getContentAnchorEl: undefined,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                }}
                fullWidth
            >
                {itemList}
            </MuiSelect>
            {showHelperText && (
                // @ts-ignore
                <FormHelperText error={showError} component="pre">
                    {showError ? errorMessage : helperText}
                </FormHelperText>
            )}
        </React.Fragment>
    );
};

function optionSort(options: DropDownOptionValue[]) {
    return options.sort((a: any, b: any) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
}
