import { Button, Grid, Tooltip, Typography } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { getClaim } from '../../../claim';
import { ClaimType } from '../../../claim/claim-types';
import { EditFormModal } from '../../../form-modal';
import { OpenAddModal, openAddModalAction } from '../../../form-modal/actions';
import { State } from '../../../redux/root-reducer';
import { Nullable } from '../../../shared-types';
import {
    AddAndSubmitPaymentItem,
    addPaymentPaymentItemRecordThunk,
} from '../../actions/submit-payment';
import { Payment, RiskRating } from '../../reducer';
import { PaymentIncomeItemView } from './edit-income-payment-item-view';
import { PaymentItemViewEdit } from './edit-payment-item-view';
import { PaymentFuneralItemView } from './funeral/edit-payment-item-view';
import { UpdatePayment, updatePaymentAction } from '../../actions/update-payment';
import { AuthGuard } from '../../../auth/authGuard';
import { ButtonWrapper } from '../../../forms';
import { HighRiskOverrideView } from '../high-risk-override/add-high-risk-override';
import { FormModalView } from '../../../form-modal/views/form-modal';
const hasOverrideAccess = AuthGuard(['ClaimsOverride']);
interface Props {
    addPayment: AddAndSubmitPaymentItem;
    claimType: Nullable<ClaimType>;
    openAddModal: OpenAddModal;
    payments: Payment[];
    isReadOnly: boolean;
    paymentIndex: number;
    updatePayment: UpdatePayment;
}

const PaymentEditFormModal = EditFormModal<Payment>();
const addPaymentButton = ({
    addPayment,
    claimType,
    openAddModal,
    payments,
    paymentIndex,
    isReadOnly,
    updatePayment,
}: Props) => {
    if (isReadOnly) {
        return null;
    }

    const payment = payments[paymentIndex];
    const highRisk = hasHighRiskRating(payment.riskRating);
    const overrideRiskRating = hasOverrideRiskRating(payment.riskRating);
    const riskRating = payment.riskRating || {};

    const openModal = () => {
        openAddModal({
            modalContent: (
                <PaymentEditFormModal
                    recordDetailView={
                        <AddPaymentView
                            claimType={claimType}
                            beneficiaryId={payment.beneficiaryId || ''}
                        />
                    }
                    records={payments}
                    updateRecord={payment => addPayment(payment, paymentIndex)}
                />
            ),
            modalData: {},
        });
    };

    const openHighRiskOverrideModal = () => {
        openAddModal({
            modalContent: (
                <FormModalView
                    formView={() => <HighRiskOverrideView paymentIndex={paymentIndex} />}
                />
            ),
            modalData: {},
        });
    };

    function isDisabled(payment: Payment): boolean | undefined {
        if (payment.beneficiaryManualScreening?.isBeneficiaryManualScreening) return false;
        if (riskRating.override) return false;
        if (payment.screeningError) return true;
        if (!payment.beneficiaryManualScreening?.isBeneficiaryManualScreening) {
            if (!riskRating.override) {
                if (payment.notScreened) {
                    return true;
                } else {
                    if (highRisk) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        }
    }

    return (
        <React.Fragment>
            <Grid container justify="space-between" style={{ paddingRight: 5 }}>
                <Grid item xs={8}>
                    {highRisk && !riskRating.override && (
                        <Typography style={{ marginTop: 10, color: 'red' }}>
                            As per the beneficiary risk rating process, cannot continue processing
                            payment
                        </Typography>
                    )}
                </Grid>
                <ButtonWrapper>
                    <Grid item xs={4} container justify="flex-end">
                        <Tooltip title="Add Payment">
                            <Button
                                id="AddPaymentItem"
                                color="secondary"
                                style={{ padding: 10, textTransform: 'none' }}
                                disabled={isDisabled(payment)}
                                onClick={openModal}
                            >
                                <AddCircle fontSize="small" />
                                Add Payment
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12} container justify="flex-end">
                        {overrideRiskRating && !riskRating.override && hasOverrideAccess() && (
                            <Tooltip title="Override">
                                <Button
                                    id="UpdatePayment"
                                    color="secondary"
                                    style={{ padding: 10, textTransform: 'none' }}
                                    onClick={openHighRiskOverrideModal}
                                >
                                    <AddCircle fontSize="small" />
                                    Override
                                </Button>
                            </Tooltip>
                        )}
                    </Grid>
                </ButtonWrapper>
            </Grid>
        </React.Fragment>
    );
};

function hasHighRiskRating(riskRating?: RiskRating) {
    if (
        riskRating &&
        riskRating.statusDescription &&
        riskRating.statusDescription.toLowerCase() === 'release'
    ) {
        return false;
    }
    if (!riskRating || !riskRating.riskAbbreviation || riskRating.riskAbbreviation === 'null') {
        return false;
    }

    return riskRating.riskAbbreviation !== 'LOW' && riskRating.riskAbbreviation !== 'MEDIUM';
}

function hasOverrideRiskRating(riskRating?: RiskRating) {
    if (!riskRating || !riskRating.statusDescription) {
        return false;
    }

    return riskRating.statusDescription.toLowerCase() !== 'release';
}

interface AddPaymentViewProps {
    beneficiaryId: string;
    claimType: Nullable<ClaimType>;
}
const AddPaymentView = ({ claimType, beneficiaryId }: AddPaymentViewProps) => {
    if (claimType === ClaimType.IncomeProtector) {
        return <PaymentIncomeItemView beneficiaryId={beneficiaryId} />;
    }

    if (claimType === ClaimType.Funeral) {
        return <PaymentFuneralItemView beneficiaryId={beneficiaryId} />;
    }

    return <PaymentItemViewEdit beneficiaryId={beneficiaryId} />;
};

const mapStateToProps = (state: State) => ({
    claimType: getClaim(state).claimType,
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = {
    openAddModal: openAddModalAction,
    addPayment: addPaymentPaymentItemRecordThunk,
    updatePayment: updatePaymentAction,
};

export const AddPaymentButton = connect(mapStateToProps, mapDispatchToProps)(addPaymentButton);
