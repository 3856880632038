import axios from 'axios';
import { Claim, claimUpdatedThunk } from '../../claim';
import { openToastAction } from '../../confirmation-dialog';
import { env } from '../../env';
import { hideLoadingAction, showLoadingAction } from '../../forms/loading-screen/actions';
import { ReduxThunkAction } from '../../redux/redux-actions';

type ScreenBeneficiaryReturn = Promise<any>;
export type ScreenBeneficiary = () => ScreenBeneficiaryReturn;
type ScreenBeneficiaryAction = () => ReduxThunkAction<ScreenBeneficiaryReturn>;
export const screenBeneficiaryAction: ScreenBeneficiaryAction = () => {
    return async (dispatch, getState) => {
        dispatch(showLoadingAction());
        const claim = getState().claimSession.claim;
        const screeningUri = `${env().CLAIMS_API}/api/Claim/${claim.claimId}`;
        await axios
            .put<Claim>(screeningUri, claim)
            .then(response => {
                if (response.data) {
                    dispatch(claimUpdatedThunk(response.data));
                }
            })
            .catch(error => {
                openToastAction({
                    toastContent: {
                        message: 'Beneficiary screening unsuccessful',
                        type: 'error',
                    },
                });
            })
            .then(() => dispatch(hideLoadingAction()));
    };
};
