import { claimSubmit } from '../../../../claim';
import { Claim } from '../../../../claim/reducers';
import { addRecordReduction, updateRecordReduction } from '../../../../redux/reductions';
import { ReduxThunkAction } from '../../../../redux/redux-actions';
import {
    AssessmentDetails,
    DeathAssessmentDecisionViewModel,
    InvestmentPolicyAssessment,
    RiskPolicyAssessmentDetails,
} from '../death-assessment-details-reducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AddDeathAssessmentDecisionReturn = Promise<any>;
export type AddDeathAssessmentDecision = (
    deathAssessmentDecision: DeathAssessmentDecisionViewModel
) => AddDeathAssessmentDecisionReturn;
type AddDeathAssessmentDecisionAction = (
    deathAssessmentDecision: DeathAssessmentDecisionViewModel
) => ReduxThunkAction<AddDeathAssessmentDecisionReturn>;
export const addDeathAssessmentDecisionAction: AddDeathAssessmentDecisionAction = (
    deathAssessmentDecision: DeathAssessmentDecisionViewModel
) => async (dispatch, getState) => {
    return await claimSubmit(dispatch, getState, claim =>
        getUpdatedClaim(claim, deathAssessmentDecision)
    );
};

function getUpdatedClaim(
    claim: Claim,
    assessmentDecision: DeathAssessmentDecisionViewModel
): Claim {
    if (!('deathAssessmentDetails' in claim)) {
        return claim;
    }

    const assessmentDetails = getUpdatedAssessmentDetails(
        claim.deathAssessmentDetails,
        assessmentDecision
    );
    return {
        ...claim,
        deathAssessmentDetails: assessmentDetails,
    };
}

function getUpdatedAssessmentDetails(
    assessmentDetails: AssessmentDetails,
    assessmentDecisionViewModel: DeathAssessmentDecisionViewModel
): AssessmentDetails {
    if (assessmentDetails == null) {
        return assessmentDetails;
    }

    let riskPolicyAssessmentDetails = assessmentDetails.riskPolicyAssessmentDetails;
    let investmentPolicyAssessments = assessmentDetails.investmentPolicyAssessments;

    const riskPolicyIndex = assessmentDetails.riskPolicyAssessmentDetails.findIndex(
        rAD => rAD.policyNumber === assessmentDecisionViewModel.policyNumber
    );
    const investmentPolicyIndex = assessmentDetails.investmentPolicyAssessments.findIndex(
        rAD => rAD.policyNumber === assessmentDecisionViewModel.policyNumber
    );
    if (riskPolicyIndex === -1 && investmentPolicyIndex === -1) {
        throw new Error('Invalid Assessment Details: Policy Number not found');
    }

    if (riskPolicyIndex > -1) {
        riskPolicyAssessmentDetails = getUpdatedRiskAssessmentDetails(
            riskPolicyIndex,
            assessmentDecisionViewModel,
            riskPolicyAssessmentDetails
        );
    }

    if (investmentPolicyIndex > -1) {
        investmentPolicyAssessments = getUpdatedInvestmentAssessmentDetails(
            investmentPolicyIndex,
            assessmentDecisionViewModel,
            investmentPolicyAssessments
        );
    }

    return {
        investmentPolicyAssessments,
        riskPolicyAssessmentDetails,
    };
}

function getUpdatedRiskAssessmentDetails(
    policyIndex: number,
    assessmentDecision: DeathAssessmentDecisionViewModel,
    riskPolicyAssessmentDetails: RiskPolicyAssessmentDetails[]
) {
    let policyAssessmentDecisions = riskPolicyAssessmentDetails[policyIndex];
    const benefitIndex = policyAssessmentDecisions.benefits.findIndex(
        bAD => bAD.benefitName === assessmentDecision.benefitName
    );
    if (benefitIndex === -1) {
        throw new Error('Invalid Assessment Decision: Benefit not found');
    }
    let benefitAssessment = policyAssessmentDecisions.benefits[benefitIndex];
    benefitAssessment = {
        ...benefitAssessment,
        decisions: addRecordReduction(benefitAssessment.decisions, assessmentDecision),
    };
    policyAssessmentDecisions = {
        ...policyAssessmentDecisions,
        benefits: updateRecordReduction(
            policyAssessmentDecisions.benefits,
            benefitAssessment,
            benefitIndex
        ),
    };
    riskPolicyAssessmentDetails = updateRecordReduction(
        riskPolicyAssessmentDetails,
        policyAssessmentDecisions,
        policyIndex
    );
    return riskPolicyAssessmentDetails;
}

function getUpdatedInvestmentAssessmentDetails(
    policyIndex: number,
    assessmentDecisionViewModel: DeathAssessmentDecisionViewModel,
    investmentPolicyAssessments: InvestmentPolicyAssessment[]
) {
    let policyAssessment = investmentPolicyAssessments[policyIndex];
    policyAssessment = {
        ...policyAssessment,
        decisions: addRecordReduction(policyAssessment.decisions, assessmentDecisionViewModel),
    };
    investmentPolicyAssessments = updateRecordReduction(
        investmentPolicyAssessments,
        policyAssessment,
        policyIndex
    );

    return investmentPolicyAssessments;
}
