import React from 'react'; // eslint-disable-line

import { Grid, Paper, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import {
    FormErrors,
    FormHeading,
    FullWidthDatePicker,
    FullWidthRadioGroup,
    FullWidthTextField,
    GenericForm,
    RadioOption,
    validations,
    Condition,
    True,
    FullWidthSelectField,
    WhenFieldChanges,
} from '../../forms';
import { conditionalRequired } from '../../forms/validations';
import { State } from '../../redux/root-reducer';
import styles, { StyledComponent } from '../../styles/styles';
import { OnSubmitSuccessProps } from '../../workflow-navigation';
import { Submit, submitAction } from '../actions/submit';
import { DisabilityDetails, getDisabilityDetails } from '../reducer';
import { ReturnToWorkReasons } from '../../codes/return-to-work-reasons';

interface Props extends OnSubmitSuccessProps, StyledComponent {
    submit: Submit;
    disabilityDetails?: DisabilityDetails;
}

const Form = GenericForm<DisabilityDetails>();
const disabilityDetailsForm: React.FunctionComponent<Props> = ({
    classes,
    submit,
    disabilityDetails,
    ...rest
}: Props) => {
    return (
        <Form onSubmit={submit} initialValues={disabilityDetails} {...rest}>
            <InnerForm classes={classes} />
            <FormErrors />
        </Form>
    );
};

export const InnerForm: React.FunctionComponent<StyledComponent> = ({
    classes,
}: StyledComponent) => {
    return (
        <Paper className={classes.paper}>
            <FormHeading text="Disability Details" />
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={6}>
                    <FullWidthDatePicker
                        name="lastDayAtWork"
                        validate={conditionalRequired(values => values.returnToWork)}
                        disableFuture
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthRadioGroup name="returnToWorkStatus" label="Returned to work">
                        <RadioOption value="Yes" />
                        <RadioOption value="No" />
                    </FullWidthRadioGroup>
                </Grid>
                <WhenFieldChanges field="returnToWorkStatus" set="returnToWork" to={null} />
                <WhenFieldChanges field="returnToWorkStatus" set="reason" to={null} />
                <Condition when="returnToWorkStatus" is="Yes">
                    <True>
                        <Grid item xs={6}>
                            <FullWidthDatePicker name="returnToWork" minDateField="lastDayAtWork" />
                        </Grid>
                    </True>
                </Condition>
                <Condition when="returnToWorkStatus" is="No">
                    <True>
                        <Grid item xs={6}>
                            <FullWidthSelectField name="reason" options={ReturnToWorkReasons} />
                        </Grid>
                    </True>
                </Condition>
                <Grid item xs={6}>
                    <FullWidthTextField
                        name="jobDescription"
                        validate={validations.requiredValidator}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        name="incomeTaxNumber"
                        validate={validations.composeValidators(
                            validations.requiredValidator,
                            validations.mustBeNumber
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthRadioGroup
                        name="proofOfIncomeAtUnderwriting"
                        label="Proof Of Income At Underwriting"
                    >
                        <RadioOption value="Yes" />
                        <RadioOption value="No" />
                    </FullWidthRadioGroup>
                </Grid>
            </Grid>
        </Paper>
    );
};

const mapStateToProps = (state: State) => ({
    disabilityDetails: getDisabilityDetails(state),
});

const mapDispatchToProps = {
    submit: submitAction,
};

export const DisabilityDetailsForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(disabilityDetailsForm));
