import { Omit } from 'lodash';
import { claimSubmit } from '../../claim/actions';
import { Claim } from '../../claim/reducers';
import { ReduxThunkAction } from '../../redux/redux-actions';
import {
    AlternativeOccupationDetails,
    ClaimInformation,
    getClaimInformationFromClaim,
    OccupationalDutiesBreakdown,
} from '../reducer';

type SubmitPayload = Omit<ClaimInformation, 'previousOccupations'>;
type SubmitReturn = Promise<any>;
export type Submit = (payload: SubmitPayload) => SubmitReturn;
type SubmitAction = (payload: SubmitPayload) => ReduxThunkAction<SubmitReturn>;
export const submitAction: SubmitAction = (payload: SubmitPayload) => (dispatch, getState) => {
    return claimSubmit(dispatch, getState, transformToClaim(payload));
};

function transformToClaim(payload: SubmitPayload) {
    return (claim: Claim): Claim => {
        const claimInformation = getClaimInformationFromClaim(claim);
        const updatedClaim: Claim = {
            ...claim,
            claimInformation: {
                ...claimInformation,
                ...cleanupClaimInformation(payload),
            },
        };

        return updatedClaim;
    };
}

function cleanupClaimInformation(payload: SubmitPayload) {
    const claimInformation: SubmitPayload = {
        ...payload,
        dutyBreakdown: cleanupDutyBreakdown(payload.dutyBreakdown),
        alternativeOccupationDetails: cleanupAlternativeOccupationDetails(
            payload.alternativeOccupationDetails
        ),
    };

    return claimInformation;
}

function cleanupDutyBreakdown(dutyBreakdown: OccupationalDutiesBreakdown) {
    const cleanDutyBreakdown: OccupationalDutiesBreakdown = {
        ...dutyBreakdown,
        hazardDetails: dutyBreakdown.hazard === 'Yes' ? dutyBreakdown.hazardDetails : undefined,
        internationalTravelDetails:
            dutyBreakdown.internationalTravel === 'Yes'
                ? dutyBreakdown.internationalTravelDetails
                : undefined,
    };

    return cleanDutyBreakdown;
}

function cleanupAlternativeOccupationDetails(
    alternativeOccupationDetails: AlternativeOccupationDetails
) {
    const cleanAlternativeOccupationDetails: AlternativeOccupationDetails = {
        ...alternativeOccupationDetails,
        alternativeOccupationDetails: getAlternativeOccupationDetails(alternativeOccupationDetails),
        alternativeOccupationAccepted: getAlternativeOccupationAccepted(
            alternativeOccupationDetails
        ),
        alternativeOccupationBasis: getAlternativeOccupationBasis(alternativeOccupationDetails),
    };

    return cleanAlternativeOccupationDetails;
}

function getAlternativeOccupationDetails(
    alternativeOccupationDetails: AlternativeOccupationDetails
): string | undefined {
    if (alternativeOccupationDetails.alternativeOccupationOffered === 'Yes') {
        return alternativeOccupationDetails.alternativeOccupationDetails;
    }
    return undefined;
}

function getAlternativeOccupationAccepted(
    alternativeOccupationDetails: AlternativeOccupationDetails
): string | undefined {
    if (alternativeOccupationDetails.alternativeOccupationOffered === 'Yes') {
        return alternativeOccupationDetails.alternativeOccupationAccepted;
    }
    return undefined;
}

function getAlternativeOccupationBasis(
    alternativeOccupationDetails: AlternativeOccupationDetails
): 'Full Time' | 'Part Time' | undefined {
    if (
        alternativeOccupationDetails.alternativeOccupationOffered === 'Yes' &&
        alternativeOccupationDetails.alternativeOccupationAccepted === 'Yes'
    ) {
        return alternativeOccupationDetails.alternativeOccupationBasis;
    }
    return undefined;
}
