import { DeleteRecordCollectionAction } from '../../../../../redux/collection-reducer';
import { DELETE_ADDITIONAL_INFORMATION_REQUIRED } from '../types';

export type DeleteAdditionalInformationRequiredAction = DeleteRecordCollectionAction;

export const deleteAdditionalInformationRequiredActionCreator = (
    additionalInformationRequiredIndex: number
): DeleteAdditionalInformationRequiredAction => {
    return {
        type: DELETE_ADDITIONAL_INFORMATION_REQUIRED,
        index: additionalInformationRequiredIndex,
    };
};
