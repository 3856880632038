import { AnyAction, Reducer } from 'redux';
import { getClaim } from '../claim';
import { CodeList } from '../codes/codes';
import { ReduxAction } from '../redux/redux-actions';
import { State } from '../redux/root-reducer';
import { Nullable, UserInfo } from '../shared-types';
import { ClaimType } from '../claim/claim-types';
import { UserTypes } from '../codes/claim-documents-enums';

export interface ClaimChangeSet {
    user: UserInfo;
    timeStamp: Date;
    changesetType?: string;
    change?: any;
}

export interface AuditTrailFilter {
    readonly startDate?: Date;
    readonly endDate?: Date;
    readonly user?: string;
    readonly sectionName?: string;
    readonly search?: string;
}

export type ClaimAuditTrailAction = ReduxAction<ClaimChangeSet[]> | AnyAction;
export const claimAuditTrailReducer: Reducer<Nullable<ClaimChangeSet[]>, ClaimAuditTrailAction> = (
    state: Nullable<ClaimChangeSet[]> = null,
    action: ClaimAuditTrailAction
) => {
    switch (action.type) {
        default:
            return state;
    }
};

export const sectionNames = {
    additionalInformationRequested: 'Additional Information Requested',
    assessmentDetails: 'Assessment Details',
    claimDetails: 'Claim Details',
    claimEventDetails: 'Claim Event Details',
    claimInformation: 'Claim Information',
    contactDetails: 'Contact Details',
    disabilityDetails: 'Disability Details',
    forensicInput: 'Forensic Input',
    informationRecordSummary: 'Information Record Summary',
    lifeAssuredDetails: 'Life Assured Details',
    incomeManagementPlan: 'Management Plan',
    medicalOfficerInput: 'Medical Officer Input',
    reinsuranceInput: 'Reinsurance Input',
    retrospectiveUnderwriting: 'Retrospective Underwriting',
    documentsStatus: 'Requirement Management',
    underwritingDetails: 'Underwriting Details',
    payments: 'Payment Details',
    liquidAndDistribution: 'Liquid And Distribution Details',
    disputes: 'Disputes',
    dependants: 'Possible Dependants',
    deathCertificate: 'Death Certificate',
};

export function defaultAuditTrailFilter(): AuditTrailFilter {
    return {
        startDate: undefined,
        endDate: undefined,
        user: undefined,
        sectionName: undefined,
        search: undefined,
    };
}

export function GetAssessmentDetailsSectionName(state: State): string {
    const claim = getClaim(state);
    if (claim.claimType === ClaimType.Death) return 'Death Assessment Details';
    else return 'Assessment Details';
}

export function getClaimAuditTrail(state: State): Nullable<ClaimChangeSet[]> {
    return getClaim(state).auditTrail;
}

export function getAuditTrailSortedByTimeStamp(state: State): ClaimChangeSet[] {
    const claimAuditTrail = getClaimAuditTrail(state);
    const sectionAudit: ClaimChangeSet[] = [];
    if (claimAuditTrail) {
        claimAuditTrail.forEach(auditTrail => {
            sectionAudit.push({ ...auditTrail });
        });
    }
    return sectionAudit.sort((a, b) =>
        a.timeStamp < b.timeStamp ? 1 : b.timeStamp < a.timeStamp ? -1 : 0
    );
}

export function getClaimAuditUsers(state: State) {
    const claimAuditTrail = getClaimAuditTrail(state);
    const claimAuditUsers: CodeList<string> = [];
    if (claimAuditTrail) {
        claimAuditTrail.forEach(claimAudit => {
            if (!claimAudit.user || claimAudit.user === null) {
                claimAudit.user = { name: UserTypes.System, userId: UserTypes.System };
            } else if (claimAudit.user.userId === null) {
                claimAudit.user.userId = UserTypes.System;
                claimAudit.user.name = UserTypes.System;
            }
            const userFound = claimAuditUsers.find(user => {
                return user.value === claimAudit.user.userId;
            });
            if (!userFound) {
                claimAuditUsers.push({
                    value: claimAudit.user.userId || '',
                    label: claimAudit.user.name || '',
                });
            }
        });
    }

    return claimAuditUsers;
}

export function getAuditTrailSectionNames(assessmentDetailsName: string): CodeList<string> {
    const names: CodeList<string> = [];
    sectionNames.assessmentDetails = assessmentDetailsName;
    Object.keys(sectionNames).forEach(key => {
        names.push({
            value: sectionNames[key],
            label: sectionNames[key],
        });
    });
    return names;
}
