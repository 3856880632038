import { ReduxAction } from '../../redux/redux-actions';
import { Nullable } from '../../shared-types';
import { WorkflowScreen } from '../reducers/workflow-screen';
import { SET_ACTIVE_SCREEN } from '../types';

export type SetActiveScreenAction = ReduxAction<Nullable<WorkflowScreen>>;
export type SetActiveScreen = (activeScreen: Nullable<WorkflowScreen>) => SetActiveScreenAction;
export const setActiveScreenAction: SetActiveScreen = (activeScreen: Nullable<WorkflowScreen>) => ({
    type: SET_ACTIVE_SCREEN,
    payload: activeScreen,
});
