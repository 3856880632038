import { ActionCreator } from 'redux';
import { UnderwritingPolicyDetails } from '..';
import { ReduceCollectionRecordAction } from '../../../../../../../redux/collection-record-reducer';
import { ReduxAction, ReduxThunkAction } from '../../../../../../../redux/redux-actions';
import { UPDATE_UNDERWRITING_POLICY_DETAILS } from './underwriting-policy-details-action-types';
import { reduceAndSubmitClaim } from '../../../../../../../claim/actions/reduce-and-submit-claim';

export type UpdateUnderwritingPolicyDetailsAction = ReduxAction<UnderwritingPolicyDetails>;

export type UpdatePolicyUnderwritingPolicyDetailsAction = ReduceCollectionRecordAction<UpdateUnderwritingPolicyDetailsAction>;

const updateUnderwritingPolicyDetailsActionCreator: ActionCreator<UpdatePolicyUnderwritingPolicyDetailsAction> = (
    policyIndex: number,
    underwritingPolicyDetails: UnderwritingPolicyDetails
) => {
    return {
        type: UPDATE_UNDERWRITING_POLICY_DETAILS,
        payload: {
            type: UPDATE_UNDERWRITING_POLICY_DETAILS,
            payload: underwritingPolicyDetails,
        },
        index: policyIndex,
    };
};

type UpdateReturn = Promise<any>;
export type UpdateUnderwritingPolicyDetails = (
    policyIndex: number,
    underwritingPolicyDetails: UnderwritingPolicyDetails
) => UpdateReturn;
export type UpdateUnderwritingPolicyDetailsThunk = (
    policyIndex: number,
    underwritingPolicyDetails: UnderwritingPolicyDetails
) => ReduxThunkAction<UpdateReturn>;
export const updateUnderwritingPolicyDetailsThunk: UpdateUnderwritingPolicyDetailsThunk = (
    policyIndex: number,
    underwritingPolicyDetails: UnderwritingPolicyDetails
) => {
    return async (dispatch, getState) => {
        const errors = await reduceAndSubmitClaim(
            dispatch,
            getState,
            updateUnderwritingPolicyDetailsActionCreator(policyIndex, underwritingPolicyDetails)
        );

        return errors;
    };
};
