import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { State } from '../../redux/root-reducer';
import styles from '../../styles/styles';
import { mapSectionView } from '../common-views/claim-audit-trail-wrapper';
import {
    changeRecordFromAddChangeSet,
    changeRecordFromChangeSet,
    ChangeFieldSet,
    changeRecordFromDeleteChangeSet,
} from '../claim-audit-trail-functions';
import { addAuditTrailDetailsView } from '../common-views/claim-audit-trail-field-details';
import {
    Dependant,
    getDependants,
    Curator,
    Guardian,
} from '../../assessment/synopsis/ra-death/dependants/reducer';
import { ObjectTypes } from '../../codes/claim-documents-enums';
import { startCase } from 'lodash';
import { OrganisationDetails } from '../../assessment/synopsis/death/contact-details/contact-details-reducer';
import { getFullName } from '../../shared-functions';
interface Props {
    changeSet: Record<string, any>;
    dependents: Dependant[];
}
const dependantsAuditTrailView: React.FunctionComponent<Props> = ({
    changeSet,
    dependents,
}: Props) => {
    const keys = [
        ObjectTypes.PersonalDetails,
        ObjectTypes.Guardian,
        ObjectTypes.ResidentialAddress,
        ObjectTypes.PostalAddress,
        ObjectTypes.BankingDetails,
        ObjectTypes.ContactDetails,
        ObjectTypes.FinancialInformation,
        ObjectTypes.OrganisationDetails,
    ];
    const returnView: React.ReactNode[] = [];
    let sectionName: string[] = [];
    let record: ChangeFieldSet[] = [];
    if (changeSet != null) {
        Object.keys(changeSet).forEach(index => {
            if (index !== '_t') {
                let curatorDetails;
                let changeRecord = changeSet[index];
                if (Array.isArray(changeRecord) && index !== '_0' && parseInt(index) < 1) {
                    changeRecord = changeRecord[index];
                } else if (
                    (Array.isArray(changeRecord) && index.startsWith('_') && index !== '_t') ||
                    (Array.isArray(changeRecord) && index !== '_0' && parseInt(index) > 0)
                ) {
                    changeRecord = changeRecord[0];
                }
                const dependentsList: any[] = [];
                keys.forEach(key => {
                    if (
                        index !== '_t' &&
                        !!changeRecord &&
                        changeRecord.hasOwnProperty(key) &&
                        !!changeRecord[key]
                    ) {
                        dependentsList.push({ key: key, value: changeRecord[key] });
                        delete changeRecord[key];
                    }
                });
                if (
                    !!changeRecord &&
                    changeRecord.hasOwnProperty('parent') &&
                    Array.isArray(changeRecord.parent)
                ) {
                    getParentDetails(changeRecord, dependents, returnView, index);
                } else if (
                    !!changeRecord &&
                    changeRecord.hasOwnProperty('parent') &&
                    !Array.isArray(changeRecord.parent)
                ) {
                    const dependent = dependents.find(c => c.id === changeRecord.parent);
                    if (!!dependent) {
                        const personalDetails = dependent && dependent.personalDetails;
                        const displayName = getFullName(personalDetails);
                        changeRecord['parent'] = displayName;
                    }
                }
                if (!!changeRecord && changeRecord.hasOwnProperty(ObjectTypes.Curator)) {
                    curatorDetails = changeRecord[ObjectTypes.Curator];
                    delete changeRecord.curator;
                }
                if (Array.isArray(changeSet[index]) && !index.startsWith('_') && index !== '_t') {
                    record = changeRecordFromAddChangeSet(changeRecord);
                } else if (!Array.isArray(changeSet[index]) && index !== '_t') {
                    record = changeRecordFromChangeSet(changeRecord);
                } else if (index.startsWith('_') && index !== '_t') {
                    record = changeRecordFromDeleteChangeSet(changeRecord);
                }
                const section =
                    index.startsWith('_') && index !== '_t'
                        ? `Deleted Dependent ${parseInt(index.split('_')[1]) + 1}`
                        : !Array.isArray(changeSet[index])
                        ? `Dependent ${parseInt(index) + 1}`
                        : `Dependent ${parseInt(index) + 1}`;
                sectionName.push(section);

                if (record.length > 0) {
                    addAuditTrailDetailsView(returnView, record, sectionName);
                    sectionName = [];
                }

                if (!!changeRecord) {
                    keys.forEach((key, keyIndex) => {
                        const currentValue =
                            dependentsList.find(x => x.key === key) &&
                            dependentsList.find(x => x.key === key).value;
                        if (key === ObjectTypes.Guardian && !!currentValue) {
                            getGuardianDetails(currentValue, index, key, returnView, changeSet);
                        }

                        if (
                            changeRecord.dependantType === undefined &&
                            !!currentValue &&
                            key === ObjectTypes.OrganisationDetails &&
                            currentValue.hasOwnProperty(ObjectTypes.AuthorisedPerson)
                        ) {
                            getAuthorisedPersonDetails(returnView, currentValue, sectionName);
                        }
                        if (
                            changeRecord.dependantType !== 'Organisation' &&
                            currentValue !== undefined &&
                            key === ObjectTypes.FinancialInformation
                        ) {
                            getFinancialInformation(
                                returnView,
                                currentValue,
                                index,
                                changeSet,
                                sectionName
                            );
                        } else if (
                            changeRecord.dependantType === 'Organisation' &&
                            key === ObjectTypes.OrganisationDetails
                        ) {
                            getOrganisationDetails(returnView, currentValue, index);
                        }
                        if (
                            Array.isArray(changeSet[index]) &&
                            !index.startsWith('_') &&
                            index !== '_t'
                        ) {
                            record = changeRecordFromAddChangeSet(currentValue);
                            if (record.length > 0) {
                                sectionName.push(startCase(key));
                                addAuditTrailDetailsView(returnView, record, sectionName);
                                sectionName = [];
                            }
                        } else if (
                            !!currentValue &&
                            !!currentValue[0] &&
                            currentValue[1] === null &&
                            key === ObjectTypes.BankingDetails
                        ) {
                            record = changeRecordFromDeleteChangeSet(currentValue[0]);
                            if (record.length > 0) {
                                sectionName.push(startCase(key));
                                addAuditTrailDetailsView(returnView, record, sectionName);
                                sectionName = [];
                            }
                        } else if (
                            !!currentValue &&
                            !!currentValue[1] &&
                            currentValue[0] === null &&
                            key === ObjectTypes.BankingDetails
                        ) {
                            record = changeRecordFromAddChangeSet(currentValue[1]);
                            if (record.length > 0) {
                                sectionName.push(startCase(key));
                                addAuditTrailDetailsView(returnView, record, sectionName);
                                sectionName = [];
                            }
                        } else if (!Array.isArray(changeSet[index])) {
                            record = changeRecordFromChangeSet(currentValue);
                            if (record.length > 0) {
                                sectionName.push(startCase(key));
                                addAuditTrailDetailsView(returnView, record, sectionName);
                                sectionName = [];
                            }
                        } else if (index.startsWith('_')) {
                            record = changeRecordFromDeleteChangeSet(currentValue);
                            if (record.length > 0) {
                                sectionName.push(startCase(key));
                                addAuditTrailDetailsView(returnView, record, sectionName);
                                sectionName = [];
                            }
                        }
                    });
                    if (!!curatorDetails) {
                        getCuratorDetails(curatorDetails, index, returnView, changeSet);
                    }
                }
            }
        });
    }
    return mapSectionView(returnView);
};

const mapStateToProps = (state: State) => ({
    dependents: getDependants(state),
});

const mapDispatchToProps = {};

export const DependantsAuditTrailView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(dependantsAuditTrailView));
const getAuthorisedPersonDetails = (
    returnView: React.ReactNode[],
    currentValue: any,
    sectionName: string[]
) => {
    let record: ChangeFieldSet[] = [];
    const objects = [
        ObjectTypes.CommunicationDetails,
        ObjectTypes.PersonalDetails,
        ObjectTypes.ResidentialAddress,
    ];
    objects.forEach(object => {
        const editedValue = currentValue[ObjectTypes.AuthorisedPerson][object];
        if (!!editedValue) {
            record = changeRecordFromChangeSet(editedValue);
            if (record.length > 0) {
                sectionName.push(startCase(object));
                addAuditTrailDetailsView(returnView, record, sectionName);
                sectionName = [];
            }
        }
    });
};
const getOrganisationDetails = (
    returnView: React.ReactNode[],
    organisationDetails: OrganisationDetails,
    index: string
) => {
    const keys = [
        ObjectTypes.CommunicationDetails,
        ObjectTypes.Address,
        ObjectTypes.PersonalDetails,
        ObjectTypes.ResidentialAddress,
    ];
    let sectionName: string[] = [];
    let record: ChangeFieldSet[] = [];
    keys.forEach(key => {
        if (key === ObjectTypes.Address) {
            const details = organisationDetails[key];
            if (!!details) {
                if (index.startsWith('_')) {
                    record = changeRecordFromDeleteChangeSet(details);
                    if (record.length > 0) {
                        sectionName = [];
                        sectionName = [``, startCase(key)];
                        addAuditTrailDetailsView(returnView, record, sectionName);
                    }
                } else {
                    record = changeRecordFromAddChangeSet(details);
                    if (record.length > 0) {
                        sectionName = [];
                        sectionName = [``, startCase(key)];
                        addAuditTrailDetailsView(returnView, record, sectionName);
                    }
                }
            }
        } else if (!!organisationDetails) {
            const details =
                organisationDetails && organisationDetails[ObjectTypes.AuthorisedPerson];
            if (!!details) {
                if (index.startsWith('_')) {
                    record = changeRecordFromDeleteChangeSet(details[key]);
                    if (record.length > 0) {
                        sectionName = [];
                        sectionName = [``, startCase(key)];
                        addAuditTrailDetailsView(returnView, record, sectionName);
                    }
                } else {
                    record = changeRecordFromAddChangeSet(details[key]);
                    if (record.length > 0) {
                        sectionName = [];
                        sectionName = [``, startCase(key)];
                        addAuditTrailDetailsView(returnView, record, sectionName);
                    }
                }
            }
        }
    });
};
const getFinancialInformation = (
    returnView: React.ReactNode[],
    currentValue: Record<string, any>,
    index: string,
    changeSet: Record<string, any>,
    sectionName: string[]
) => {
    let record: ChangeFieldSet[] = [];
    if (!!currentValue && !!currentValue[ObjectTypes.Breakdown]) {
        if (
            Array.isArray(currentValue[ObjectTypes.Breakdown]) &&
            !!currentValue[ObjectTypes.Breakdown][1] &&
            !currentValue[ObjectTypes.Breakdown][0]
        ) {
            const incomeDetails = currentValue[ObjectTypes.Breakdown][1];
            for (const incomeIndex in incomeDetails) {
                record = changeRecordFromAddChangeSet(incomeDetails[incomeIndex]);
                if (record.length > 0) {
                    sectionName.push(`Income Expenditure ${parseInt(incomeIndex) + 1}`);
                    addAuditTrailDetailsView(returnView, record, sectionName);
                    sectionName = [];
                }
            }
        } else if (Array.isArray(currentValue[ObjectTypes.Breakdown])) {
            if (index.startsWith('_') && index !== '_t') {
                for (const incomeIndex in currentValue[ObjectTypes.Breakdown]) {
                    if (!incomeIndex.startsWith('_') && incomeIndex !== '_t') {
                        record = changeRecordFromDeleteChangeSet(
                            currentValue[ObjectTypes.Breakdown][incomeIndex]
                        );
                        if (record.length > 0) {
                            sectionName = [``, `Income Expenditure ${parseInt(incomeIndex) + 1}`];
                            addAuditTrailDetailsView(returnView, record, sectionName);
                        }
                    }
                }
            } else {
                for (const incomeIndex in currentValue[ObjectTypes.Breakdown]) {
                    if (!incomeIndex.startsWith('_') && incomeIndex !== '_t') {
                        record = changeRecordFromAddChangeSet(
                            currentValue[ObjectTypes.Breakdown][incomeIndex]
                        );
                        if (record.length > 0) {
                            sectionName = [``, `Income Expenditure ${parseInt(incomeIndex) + 1}`];
                            addAuditTrailDetailsView(returnView, record, sectionName);
                        }
                    }
                }
            }
        } else {
            const incomeDetails = currentValue[ObjectTypes.Breakdown];
            for (const incomeIndex in incomeDetails) {
                if (Array.isArray(incomeDetails[incomeIndex])) {
                    if (!incomeIndex.startsWith('_') && incomeIndex !== '_t') {
                        record = changeRecordFromAddChangeSet(incomeDetails[incomeIndex][0]);
                        if (record.length > 0) {
                            sectionName.push(`Income Expenditure ${parseInt(incomeIndex) + 1}`);
                            addAuditTrailDetailsView(returnView, record, sectionName);
                            sectionName = [];
                        }
                    }
                }
                if (incomeIndex.startsWith('_') && incomeIndex !== '_t') {
                    record = changeRecordFromDeleteChangeSet(
                        currentValue[ObjectTypes.Breakdown][incomeIndex][0]
                    );
                    if (record.length > 0) {
                        sectionName.push(
                            `Income Expenditure ${parseInt(incomeIndex.split('_')[1]) + 1}`
                        );
                        addAuditTrailDetailsView(returnView, record, sectionName);
                        sectionName = [];
                    }
                } else if (!incomeIndex.startsWith('_') && incomeIndex !== '_t') {
                    record = changeRecordFromChangeSet(
                        currentValue[ObjectTypes.Breakdown][incomeIndex]
                    );
                    if (record.length > 0) {
                        sectionName.push(`Income Expenditure ${parseInt(incomeIndex) + 1}`);
                        addAuditTrailDetailsView(returnView, record, sectionName);
                        sectionName = [];
                    }
                }
            }
        }
        delete currentValue[ObjectTypes.Breakdown];
    }
    if (!!currentValue[ObjectTypes.FinancialAdviser]) {
        if (
            index !== '_0' &&
            Array.isArray(changeSet[index]) &&
            !!currentValue[ObjectTypes.FinancialAdviser]
        ) {
            record = changeRecordFromAddChangeSet(currentValue[ObjectTypes.FinancialAdviser]);
        } else if (index === '_0' || !!currentValue[ObjectTypes.FinancialAdviser][0]) {
            record = changeRecordFromDeleteChangeSet(currentValue[ObjectTypes.FinancialAdviser][0]);
        } else if (!!currentValue[ObjectTypes.FinancialAdviser][1]) {
            record = changeRecordFromAddChangeSet(currentValue[ObjectTypes.FinancialAdviser][1]);
        } else if (!!currentValue[ObjectTypes.FinancialAdviser]) {
            record = changeRecordFromChangeSet(currentValue[ObjectTypes.FinancialAdviser]);
        }
        if (record.length > 0) {
            sectionName = [``, startCase(ObjectTypes.FinancialAdviser)];
            addAuditTrailDetailsView(returnView, record, sectionName);
        }
        delete currentValue[ObjectTypes.FinancialAdviser];
    }
};
const getParentDetails = (
    changeRecord: any,
    dependents: Dependant[],
    returnView: React.ReactNode[],
    index: string
) => {
    let sectionName: string[] = [];
    let record: ChangeFieldSet[] = [];

    let personalDetails;
    const previouseDependent = dependents.find(c => c.id === changeRecord.parent[0]);
    const currentDependent = dependents.find(c => c.id === changeRecord.parent[1]);
    let parentDetails;

    if (!!previouseDependent && !currentDependent) {
        personalDetails = previouseDependent && previouseDependent.personalDetails;
        const displayName = getFullName(personalDetails);
        parentDetails = {
            parentDetails: [displayName !== '' ? displayName : ''],
        };
        record = changeRecordFromDeleteChangeSet(parentDetails);
        if (record.length > 0) {
            sectionName = [`Dependent ${parseInt(index) + 1}`, 'Parent Details'];
            addAuditTrailDetailsView(returnView, record, sectionName);
        }
    } else if (!previouseDependent && !!currentDependent) {
        personalDetails = currentDependent && currentDependent.personalDetails;
        const displayName = getFullName(personalDetails);
        parentDetails = {
            parentDetails: [displayName !== '' ? displayName : ''],
        };
        record = changeRecordFromAddChangeSet(parentDetails);
        if (record.length > 0) {
            sectionName = [`Dependent ${parseInt(index) + 1}`, 'Parent Details'];
            addAuditTrailDetailsView(returnView, record, sectionName);
        }
    } else if (!!previouseDependent && !!currentDependent) {
        const currentDetails = currentDependent && currentDependent.personalDetails;
        const previousDetails = previouseDependent && previouseDependent.personalDetails;
        const currentDisplayName = getFullName(currentDetails);
        const previousDisplayName = getFullName(previousDetails);
        parentDetails = {
            parentDetails: [previousDisplayName, currentDisplayName],
        };
        record = changeRecordFromChangeSet(parentDetails);
        if (record.length > 0) {
            sectionName = [`Dependent ${parseInt(index) + 1}`, 'Parent Details'];
            addAuditTrailDetailsView(returnView, record, sectionName);
        }
    }
    delete changeRecord.parent;
};
const getCuratorDetails = (
    currentValue: Curator,
    index: string,
    returnView: React.ReactNode[],
    changeSet: Record<string, any>
) => {
    const curatorKeys = [
        ObjectTypes.ContactDetails,
        ObjectTypes.PostalAddress,
        ObjectTypes.ResidentialAddress,
    ];
    let sectionName: string[] = [];
    let record: ChangeFieldSet[] = [];
    curatorKeys.forEach(currentKey => {
        const displayName =
            currentKey === ObjectTypes.ContactDetails
                ? `Curator ${ObjectTypes.ContactDetails}`
                : currentKey === ObjectTypes.PostalAddress
                ? `Curator ${ObjectTypes.PostalAddress}`
                : `Curator ${ObjectTypes.ResidentialAddress}`;
        if (!!currentValue) {
            if (
                !index.startsWith('_') &&
                currentValue.hasOwnProperty(currentKey) &&
                !!changeSet &&
                !Array.isArray(changeSet[index])
            ) {
                record = changeRecordFromChangeSet(currentValue[currentKey]);
                delete currentValue[currentKey];
            } else if (!index.startsWith('_') && !!changeSet && Array.isArray(changeSet[index])) {
                if (!!currentValue[currentKey]) {
                    record = changeRecordFromAddChangeSet(currentValue[currentKey]);
                    delete currentValue[currentKey];
                }
            } else if (index.startsWith('_') && index !== '_t') {
                record = changeRecordFromDeleteChangeSet(currentValue[currentKey]);
                delete currentValue[currentKey];
            }
            if (record.length > 0) {
                sectionName = [``, startCase(displayName)];
                addAuditTrailDetailsView(returnView, record, sectionName);
                record = [];
            }
        }
    });
    if (Array.isArray(currentValue) && !!currentValue[0] && !currentValue[1]) {
        record = changeRecordFromDeleteChangeSet(currentValue[0]);
        if (record.length > 0) {
            sectionName = [``, startCase(`${ObjectTypes.Curator} details`)];
            addAuditTrailDetailsView(returnView, record, sectionName);
        }
    } else if (!index.startsWith('_') && !!currentValue && !Array.isArray(changeSet[index])) {
        record = changeRecordFromChangeSet(currentValue);
        if (record.length > 0) {
            sectionName = [``, startCase(`${ObjectTypes.Curator} details`)];
            addAuditTrailDetailsView(returnView, record, sectionName);
        }
    } else if (!index.startsWith('_') && !!currentValue && Array.isArray(changeSet[index])) {
        record = changeRecordFromAddChangeSet(currentValue);
        if (record.length > 0) {
            sectionName = [``, startCase(`${ObjectTypes.Curator} details`)];
            addAuditTrailDetailsView(returnView, record, sectionName);
        }
    } else if (index.startsWith('_') && index !== '_t') {
        record = changeRecordFromDeleteChangeSet(currentValue);
        if (record.length > 0) {
            sectionName = [``, startCase(`${ObjectTypes.Curator} details`)];
            addAuditTrailDetailsView(returnView, record, sectionName);
        }
    }
};
const getGuardianDetails = (
    currentValue: Guardian,
    index: string,
    key: string,
    returnView: React.ReactNode[],
    changeSet: Record<string, any>
) => {
    let sectionName: string[] = [];
    let record: ChangeFieldSet[] = [];
    const guardianKeys = [
        ObjectTypes.ContactDetails,
        ObjectTypes.ResidentialAddress,
        ObjectTypes.PostalAddress,
    ];
    guardianKeys.forEach(thisKey => {
        let displayName;
        if (thisKey === ObjectTypes.ContactDetails) {
            displayName = `Guardian ${ObjectTypes.ContactDetails}`;
        } else if (thisKey === ObjectTypes.ResidentialAddress) {
            displayName = `Guardian ${ObjectTypes.ResidentialAddress}`;
        } else if (thisKey === ObjectTypes.PostalAddress) {
            displayName = `Guardian ${ObjectTypes.PostalAddress}`;
        } else {
            displayName = startCase(key);
        }
        if (index.startsWith('_')) {
            record = changeRecordFromDeleteChangeSet(currentValue[thisKey]);
            if (record.length > 0) {
                sectionName = [``, startCase(displayName)];
                addAuditTrailDetailsView(returnView, record, sectionName);
            }
            delete currentValue[thisKey];
        } else if (!Array.isArray(changeSet[index])) {
            const postalAddress = currentValue && currentValue[ObjectTypes.PostalAddress];
            if (!!postalAddress && thisKey === ObjectTypes.PostalAddress) {
                displayName = `Guardian ${ObjectTypes.PostalAddress}`;
                if (!postalAddress[0] && !!postalAddress[1]) {
                    record = changeRecordFromAddChangeSet(postalAddress[1]);
                } else if (!!postalAddress[0] && !postalAddress[1]) {
                    record = changeRecordFromDeleteChangeSet(postalAddress[0]);
                } else {
                    record = changeRecordFromChangeSet(postalAddress);
                }
            } else {
                record = changeRecordFromChangeSet(currentValue[thisKey]);
            }
            if (record.length > 0) {
                sectionName = [``, startCase(displayName)];
                addAuditTrailDetailsView(returnView, record, sectionName);
            }
            delete currentValue[thisKey];
        } else {
            record = changeRecordFromAddChangeSet(currentValue[thisKey]);
            if (record.length > 0) {
                sectionName = [``, startCase(displayName)];
                addAuditTrailDetailsView(returnView, record, sectionName);
            }
            delete currentValue[thisKey];
        }
    });
};
