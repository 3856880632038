import { AddRecordCollectionAction } from '../../../../../redux/collection-reducer';
import { Dependant, getUpdatedDependant } from '../reducer';
import { ADD_DEPENDANT } from './types';

export type AddDependantAction = AddRecordCollectionAction<Dependant>;

export const addDependantActionCreator = (dependant: Dependant): AddDependantAction => {
    return {
        type: ADD_DEPENDANT,
        record: getUpdatedDependant(dependant),
    };
};
