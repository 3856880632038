import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { LabeledValue } from '../../../../controls/labeled-value';
import { OpenModal, openModalAction } from '../../../../form-modal/actions';
import { ButtonWrapper } from '../../../../forms';
import { DisplayAddress } from '../../../../forms/sub-forms/address-details';
import { State } from '../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import {
    UpdateNoticeOfDeath,
    updateNoticeOfDeathAction,
} from '../../common/notice-of-death/actions/update-notice-of-death';
import {
    getNoticeOfDeath,
    NoticeOfDeath,
} from '../../common/notice-of-death/notice-of-death-reducer';
import { ValidateDoctor } from '../../common/notice-of-death/views';
import { editNoticeOfDeath } from './notice-of-death';

interface Props extends StyledComponent {
    isReadOnly: boolean;
    hasDeferredContracts: boolean;
    noticeOfDeath: NoticeOfDeath;
    openModal: OpenModal;
    updateNoticeOfDeath: UpdateNoticeOfDeath;
}

const indunaDoctorDetailsView = ({
    classes,
    noticeOfDeath,
    isReadOnly,
    hasDeferredContracts,
    ...rest
}: Props) => {
    const edit = () => {
        editNoticeOfDeath({ noticeOfDeath, index: 4, ...rest });
    };

    const doctorIndunaDetails = noticeOfDeath.doctorIndunaDetails || {};
    const addressDetails = doctorIndunaDetails.addressDetails || {};
    const isDoctor = doctorIndunaDetails.doctorInduna === 'Doctor';
    return (
        <Grid item xs={12} container justify="flex-start" className={classes.marginTop}>
            <Grid item xs={12} container>
                <Grid item xs={10} container>
                    <Typography className={classes.heading} variant="body1">
                        Induna / Doctor Details
                    </Typography>
                    {isDoctor && <ValidateDoctor />}
                </Grid>
                {!hasDeferredContracts && (
                    <ButtonWrapper>
                        <Grid item xs={2} className={classes.rightAlign}>
                            <Tooltip title="Edit Induna's / Doctor's Details">
                                <Button
                                    id="EditIndunaDoctorDetails"
                                    color="secondary"
                                    onClick={edit}
                                >
                                    <Edit />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </ButtonWrapper>
                )}
            </Grid>
            {isDoctor && (
                <Grid item xs={6} lg={3}>
                    <LabeledValue
                        label="SAMDC/SANC Registration number"
                        value={doctorIndunaDetails.registrationNumber}
                    />
                </Grid>
            )}
            <Grid item xs={6} lg={3}>
                <LabeledValue label="First Name" value={doctorIndunaDetails.firstName} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Second Name" value={doctorIndunaDetails.secondName} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Surname" value={doctorIndunaDetails.lastName} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Contact number" value={doctorIndunaDetails.telephoneNumber} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <DisplayAddress
                    label={`${isDoctor ? 'Practice ' : ''}Address Details`}
                    address={addressDetails}
                />
            </Grid>
            {isDoctor && (
                <React.Fragment>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Verify Doctor"
                            value={doctorIndunaDetails.verifyDoctor}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Date Doctor Signed"
                            value={getDisplayDate(doctorIndunaDetails.dateDoctorSigned)}
                        />
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    );
};

const mapStateToProps = (state: State) => ({
    noticeOfDeath: getNoticeOfDeath(state),
    isReadOnly: state.claimSession.readOnly,
    hasDeferredContracts: state.claimSession.hasDeferredContracts,
});

const mapDispatchToProps = {
    openModal: openModalAction,
    updateNoticeOfDeath: updateNoticeOfDeathAction,
};

export const IndunaDoctorDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(indunaDoctorDetailsView));
