import React from 'react'; // eslint-disable-line
import { ButtonWrapper } from '../../../../../../forms';
import { Grid, Paper, Typography, withStyles, Tooltip, Button } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { connect } from 'react-redux';
import { getClaim } from '../../../../../../claim';
import { RADeathClaim } from '../../../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { OpenModal, openModalAction } from '../../../../../../form-modal/actions';
import { FormModalView } from '../../../../../../form-modal/views/form-modal';
import { AddressDetails, DisplayAddress } from '../../../../../../forms/sub-forms/address-details';
import { State } from '../../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { AddressDetailsEditView } from './address-details-edit';

interface Props extends StyledComponent {
    businessAddress: AddressDetails;
    postalAddress: AddressDetails;
    residentialAddress: AddressDetails;
    openModal: OpenModal;
    isReadOnly: boolean;
}

const addressDetailsView: React.FunctionComponent<Props> = ({
    businessAddress,
    classes,
    openModal,
    postalAddress,
    residentialAddress,
    isReadOnly,
}: Props) => {
    const edit = () => {
        openModal({
            modalContent: <FormModalView formView={AddressDetailsEditView} />,
        });
    };
    return (
        <React.Fragment>
            <Grid item xs={12} container spacing={0} justify="flex-start">
                <Grid item xs={8} lg={10}>
                    <Typography variant="subtitle2">Address details</Typography>
                </Grid>
                <Grid item xs={4} lg={2} className={classes.rightAlign}>
                    <ButtonWrapper>
                        <Tooltip title="Edit Address Details">
                            <Button
                                id="EditAddressDetails"
                                color="secondary"
                                onClick={() => edit()}
                            >
                                <Edit />
                            </Button>
                        </Tooltip>
                    </ButtonWrapper>
                </Grid>
            </Grid>
            <Paper className={classes.paper}>
                <Grid container justify="flex-start">
                    <Grid item xs={6} lg={3}>
                        <DisplayAddress
                            label="Last residential address"
                            address={residentialAddress}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <DisplayAddress label="Last postal address" address={postalAddress} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <DisplayAddress label="Last business address" address={businessAddress} />
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

const mapStateToProps = (state: State) => {
    const claim = getClaim(state);
    const raClaim = claim as RADeathClaim;
    const businessAddress =
        (raClaim.lifeAssuredDetails && raClaim.lifeAssuredDetails.businessAddress) || {};
    const postalAddress =
        (raClaim.lifeAssuredDetails && raClaim.lifeAssuredDetails.postalAddress) || {};
    const residentialAddress =
        (raClaim.lifeAssuredDetails && raClaim.lifeAssuredDetails.residentialAddress) || {};
    return {
        businessAddress,
        postalAddress,
        residentialAddress,
        isReadOnly: state.claimSession.readOnly,
    };
};

const mapDispatchToProps = {
    openModal: openModalAction,
};

export const AddressDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(addressDetailsView));
