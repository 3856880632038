import { Claim, claimSubmit } from '../../../../../claim';
import {
    getMedicalAidDetails,
    getPersonalDetails,
    validate,
} from '../../../../../life-assured-details/actions/submit';
import { PersonalDetails, MedicalAidDetails } from '../../../../../life-assured-details/reducer';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { getRAClaimDetailsFromClaim } from '../../claim-details/reducer';
import { PreviousMarriage, getRADeathLifeAssuredFromClaim } from '../reducer';

export interface ExtendedPersonalDetails extends PersonalDetails {
    dateOfDeath?: Date;
}
interface UpdatePersonalDetailsModel {
    personalDetails: ExtendedPersonalDetails;
    medicalAidDetails: MedicalAidDetails;
    previousMarriages: PreviousMarriage[];
    previouslyMarried: string;
}

type UpdatePersonalDetailsReturn = Promise<any>;
export type UpdatePersonalDetails = (
    updatePersonalDetailsValues: UpdatePersonalDetailsModel
) => UpdatePersonalDetailsReturn;
type UpdatePersonalDetailsAction = (
    updatePersonalDetailsValues: UpdatePersonalDetailsModel
) => ReduxThunkAction<UpdatePersonalDetailsReturn>;

export const updatePersonalDetailsAction: UpdatePersonalDetailsAction = (
    updatePersonalDetailsValues: UpdatePersonalDetailsModel
) => {
    return async (dispatch, getState) => {
        return claimSubmit(
            dispatch,
            getState,
            getUpdatedClaimPersonalDetails(updatePersonalDetailsValues),
            validate
        );
    };
};

function getUpdatedClaimPersonalDetails(
    updatePersonalDetailsValues: UpdatePersonalDetailsModel
): (claim: Claim) => Claim {
    return (claim: Claim) => {
        const currentLifeAssuredDetails = getRADeathLifeAssuredFromClaim(claim);
        const updatedDateOfEvent = updatePersonalDetailsValues.personalDetails.dateOfDeath;
        const claimDetails = getRAClaimDetailsFromClaim(claim);

        return {
            ...claim,
            lifeAssuredDetails: {
                ...currentLifeAssuredDetails,
                personalDetails: {
                    ...getPersonalDetails(updatePersonalDetailsValues.personalDetails),
                },
                medicalAidDetails: getMedicalAidDetails(
                    updatePersonalDetailsValues.medicalAidDetails
                ),
                previousMarriages: updatePersonalDetailsValues.previousMarriages,
                previouslyMarried: updatePersonalDetailsValues.previouslyMarried,
            },
            claimDetails: {
                ...claimDetails,
                claimEventDetails: {
                    ...claimDetails.claimEventDetails,
                    dateOfEvent: updatedDateOfEvent,
                },
            },
        };
    };
}
