import { Typography, withStyles } from '@material-ui/core';
import React, { ReactNode, PropsWithChildren } from 'react'; // eslint-disable-line
import styles, { StyledComponent } from './styles';

type ComponentProps = StyledComponent & {
    id?: string;
    label: ReactNode;
    value?: React.ReactNode | React.ReactNode[];
    isUnsafe?: boolean;
};
const labeledValue: React.FunctionComponent<ComponentProps> = ({
    classes,
    id,
    label,
    value,
    children,
    isUnsafe = false,
    ...rest
}: PropsWithChildren<ComponentProps>) => {
    const values = getValues(value !== undefined ? value : children);

    return (
        <div className={classes.wrapper}>
            <Typography {...rest} variant="subtitle2" className={classes.label}>
                {label}
            </Typography>
            {isUnsafe ? (
                <Typography
                    id={id}
                    className={classes.value}
                    {...rest}
                    dangerouslySetInnerHTML={{ __html: decodeHtml(value as string) }}
                />
            ) : (
                values.map((val, index) => {
                    return (
                        <Typography {...rest} key={index} id={id} className={classes.value}>
                            {val}
                        </Typography>
                    );
                })
            )}
        </div>
    );
};

function decodeHtml(value: string) {
    if (value && value.includes('&lt') && value.includes('&gt')) {
        const e = document.createElement('div');
        e.innerHTML = value;
        return e.innerHTML.split('\\').join('');
    }
    return value;
}

function getValues(value?: React.ReactNode | React.ReactNode[]): React.ReactNode[] {
    if (Array.isArray(value)) {
        let result: React.ReactNode[] = [];
        value.map(val => tokenizeString(val)).forEach(values => (result = result.concat(values)));

        return result;
    }

    return tokenizeString(value);
}

function tokenizeString(value?: React.ReactNode): React.ReactNode[] {
    if (typeof value === 'string') {
        // replace line breaks with <br /> to ensure it renders properly
        const paragraphs = value.split('\n');
        const markedUpParagraphs = paragraphs.map(item => {
            return item === '' ? <br /> : item;
        });
        return markedUpParagraphs;
    }

    return [value];
}

export const LabeledValue = withStyles(styles)(labeledValue);
