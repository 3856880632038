import { ClaimType } from '../../claim/claim-types';
import { AddRecordCollectionAction } from '../../redux/collection-reducer';
import { Nullable } from '../../shared-types';
import { Payment, PayeeType } from '../reducer';
import { ADD_PAYMENT } from '../types';

export type AddPaymentAction = AddRecordCollectionAction<Payment>;
export const addPaymentActionCreator = (
    payment: any,
    claimType: Nullable<ClaimType>
): AddPaymentAction => {
    return {
        type: ADD_PAYMENT,
        record: {
            beneficiaryId: payment.paymentBeneficiary,
            type:
                claimType === ClaimType.Death || claimType === ClaimType.RADeath
                    ? PayeeType.Claimant
                    : PayeeType.PolicyOwner,
            notScreened: true,
            paymentItems: [],
        },
    };
};
