import React from 'react'; // eslint-disable-line

import { Button, Tooltip, Typography } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import { Theme, withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { LogOut, logOutThunk } from '../../auth/actions/log-out';
import { State } from '../../redux/root-reducer';

function styles(theme: Theme) {
    return {
        root: {
            backgroundColor: theme.palette.grey['200'],
            padding: 8,
        },
        user: {
            padding: 12,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    };
}

interface Props {
    classes: any;
    currentUser: any;
    logOut: LogOut;
}

function NotificationBar({ classes, currentUser, logOut }: Props) {
    return (
        <div className={classes.root}>
            <Tooltip title="Logout">
                <Button
                    style={{ textTransform: 'none' }}
                    className={classes.user}
                    onClick={() => logOut()}
                >
                    <Person color="primary" />
                    <Typography color="textPrimary" style={{ paddingLeft: '8px' }}>
                        {currentUser ? currentUser.name : ''}
                    </Typography>
                </Button>
            </Tooltip>
        </div>
    );
}

const mapStateToProps = (state: State) => ({
    currentUser: state.auth,
});

const mapDispatchToProps = {
    logOut: logOutThunk,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NotificationBar));
