import { Reducer, AnyAction } from 'redux';

export interface VOPD {
    readonly firstName?: string;
    readonly lastName?: string;
    readonly gender?: string;
    readonly maritalStatus?: string;
    readonly dateOfBirth?: Date;
    readonly dateOfDeath?: Date;
    readonly placeOfDeath?: string;
    readonly causeOfDeath?: string;
    readonly deathUnderInvestigation?: boolean;
    readonly deceasedStatus?: string;
}

export const vopdReducer: Reducer = (state: VOPD = {}, action: AnyAction) => {
    return state;
};
