import React from 'react'; // eslint-disable-line

import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { FinancialAdviserContactDetails } from '../../../../../contact-details/financial-adviser/financial-adviser-contact-details';
import { Section } from '../../../../../controls/section';
import { EditFormModal } from '../../../../../form-modal';
import { OpenEditModal, openEditModalAction } from '../../../../../form-modal/actions';
import { State } from '../../../../../redux/root-reducer';
import { getFullName } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { getCommmunicationIcon } from '../../../common/contact-person/contact-details-card';
import {
    UpdateAndSubmitFinancialAdviser,
    updateAndSubmitFinancialAdviser,
} from '../actions/submit-details';
import { getDeathClaimContactDetails } from '../contact-details-reducer';
import { FinancialAdviserDetailsEditView } from './financial-adviser-details-edit';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { ButtonWrapper } from '../../../../../forms';

interface OwnProps {}

interface StateProps {
    readonly financialAdviser: FinancialAdviserContactDetails;
    isReadOnly: boolean;
}

interface DispatchProps {
    openEditModal: OpenEditModal;
    updateFinancialAdviser: UpdateAndSubmitFinancialAdviser;
}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const ClaimantEditFormModal = EditFormModal<FinancialAdviserContactDetails>();
const financialAdviserDetailsView: React.FunctionComponent<Props> = ({
    financialAdviser,
    classes,
    openEditModal,
    updateFinancialAdviser,
}: Props) => {
    return (
        <Section
            title={`Life Assured's Financial Adviser Contact Details`}
            noChildrenTitle="No Life Assured Financial Adviser Contact Details"
        >
            <Grid container spacing={0} item xs={12} justify="space-between">
                <Grid item xs={10}>
                    <Typography variant="h5">
                        {financialAdviser.companyName
                            ? financialAdviser.companyName
                            : getFullName(financialAdviser)}
                    </Typography>
                </Grid>
                <ButtonWrapper>
                    <Grid item xs={4} lg={2} className={classes.rightAlign}>
                        <Tooltip title="Edit Financial Adviser Contact Details">
                            <Button
                                id="EditFinancialAdviserDetails"
                                color="secondary"
                                onClick={() => editFinancialAdviser()}
                            >
                                <Edit fontSize="small" />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            </Grid>
            <Grid item xs={12}>
                <Grid container justify="flex-start">
                    <Grid item xs={4} lg={2}>
                        <LabeledValue
                            label="Consultant code"
                            value={financialAdviser.consultantCode}
                        />
                    </Grid>
                    <Grid item xs={4} lg={2}>
                        <LabeledValue
                            label="Telephone number"
                            value={financialAdviser.telephoneNumber}
                        />
                    </Grid>
                    <Grid item xs={4} lg={2}>
                        <LabeledValue
                            label="Cellphone number"
                            value={financialAdviser.cellphoneNumber}
                        />
                    </Grid>
                    <Grid item xs={8} lg={3}>
                        <LabeledValue label="Email address" value={financialAdviser.emailAddress} />
                    </Grid>
                    <Grid item xs={4} lg={2}>
                        <Typography variant="subtitle2" style={{ marginTop: 16, marginBottom: 8 }}>
                            Preferred contact method
                        </Typography>
                        <Grid container justify="flex-start">
                            {financialAdviser.preferredMethodOfCommunication ? (
                                <React.Fragment>
                                    {getCommmunicationIcon(financialAdviser, 'small')}
                                    <Typography>
                                        {`${financialAdviser.preferredMethodOfCommunication}`}
                                    </Typography>
                                </React.Fragment>
                            ) : (
                                ''
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Section>
    );

    function editFinancialAdviser() {
        openEditModal({
            modalContent: (
                <ClaimantEditFormModal
                    recordDetailView={
                        <FinancialAdviserDetailsEditView
                            classes={classes}
                            communicationMethod
                            required
                        />
                    }
                    records={[financialAdviser]}
                    updateRecord={fa => updateFinancialAdviser(fa)}
                />
            ),
            modalData: {
                recordIndex: 0,
            },
        });
    }
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    financialAdviser: getDeathClaimContactDetails(state).financialAdviser || {},
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    openEditModal: options => dispatch(openEditModalAction(options)),
    updateFinancialAdviser: (financialAdviser: FinancialAdviserContactDetails) =>
        dispatch(updateAndSubmitFinancialAdviser(financialAdviser)),
});

export const FinancialAdviserDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(financialAdviserDetailsView));
