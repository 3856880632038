import { Theme } from '@material-ui/core';
import { createStyles, StyleRules } from '@material-ui/core/styles';
import { CSSProperties, WithStyles } from '@material-ui/core/styles/withStyles';

export type StyledComponent = WithStyles<typeof styles>;

export default function styles(theme: Theme): StyleRules {
    return createStyles({
        cellNoBottomBorder: cellNoBottomBorder(theme),
        cellWithColor: cellWithColor(theme),
        circle: circle(),
        innerCircle: innerCircle(),
        paper: paper(theme),
        ruler: ruler(theme),
    });
}

function cellNoBottomBorder(theme: Theme): CSSProperties {
    return {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        borderBottom: '1px solid #ffffff',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
    };
}

function cellWithColor(theme: Theme): CSSProperties {
    return {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        borderBottom: '1px solid #ffab00',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
    };
}

function circle(): CSSProperties {
    return {
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2)',
        backgroundColor: '#021687',
        borderRadius: '20px',
        marginLeft: '-2.25px',
    };
}

function innerCircle(): CSSProperties {
    return {
        width: '15px',
        height: '15px',
        border: '2.5px solid #FFFFFF',
        borderRadius: '15px',
        marginTop: '2px',
        marginLeft: '2px',
        marginRight: '2px',
        marginBottom: '2px',
    };
}

function paper(theme: Theme) {
    return {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    };
}

function ruler(theme: Theme): CSSProperties {
    return {
        borderLeft: '2px solid #021687',
        height: '90%',
        float: 'left',
        width: '1px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(5),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    };
}
