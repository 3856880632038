import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { formatCurrency, getDisplayDate } from '../../../../shared-functions';
import { Nullable } from '../../../../shared-types';
import styles, { StyledComponent } from '../../../../styles/styles';
import { IncomePayableCalculation } from '../../reducer';

interface Props extends StyledComponent {
    incomePayable: Nullable<IncomePayableCalculation[]>;
}

export const IncomePayableSummary = withStyles(styles)(({ classes, incomePayable }: Props) => {
    return (
        <Grid container spacing={1} justify="flex-start">
            <Grid item className={classes.width4}>
                <Typography className={classes.incomeLabel}>#</Typography>
            </Grid>
            <Grid item className={classes.width12}>
                <Typography className={classes.incomeLabel}>Month</Typography>
            </Grid>
            <Grid item className={classes.width4}>
                <Typography className={classes.incomeLabel}>
                    #<br />
                    of
                    <br />
                    days
                </Typography>
            </Grid>
            <Grid item className={classes.width10}>
                <Typography className={classes.incomeLabel}>
                    Income
                    <br />
                    Protector
                    <br />
                    Premium
                </Typography>
            </Grid>
            <Grid item className={classes.width12}>
                <Typography className={classes.incomeLabel}>
                    Income
                    <br />
                    Protector
                    <br />
                    Sum assured
                </Typography>
            </Grid>
            <Grid item className={classes.width12}>
                <Typography className={classes.incomeLabel}>
                    Pro Rata
                    <br />
                    Sum assured
                </Typography>
            </Grid>
            <Grid item className={classes.width12}>
                <Typography className={classes.incomeLabel}>
                    Pro Rata
                    <br />
                    Top Up
                    <br />
                    Sum assured
                </Typography>
            </Grid>
            <Grid item className={classes.width10}>
                <Typography className={classes.incomeLabel}>
                    Pro Rata
                    <br />
                    premuim
                </Typography>
            </Grid>
            <Grid item className={classes.width10}>
                <Typography className={classes.incomeLabel}>
                    Claim
                    <br />
                    Booster
                    <br />
                    Amount
                </Typography>
            </Grid>
            <Grid item className={classes.width14}>
                <Typography className={classes.incomeLabel}>Total Payable</Typography>
            </Grid>
            {incomePayable &&
                incomePayable.map((income, index) => (
                    <React.Fragment key={index}>
                        <Grid item className={classes.width4}>
                            <Typography className={classes.incomeValue}>{index + 1}</Typography>
                        </Grid>
                        <Grid item className={classes.width12}>
                            <Typography className={classes.incomeValue}>
                                {getDisplayDate(income.month, 'LLLL yyyy')}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.width4}>
                            <Typography className={classes.incomeValue}>
                                {income.numberOfDays}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.width10}>
                            <Typography className={classes.incomeValue}>
                                {formatCurrency(income.incomeProtectorPremium)}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.width12}>
                            <Typography className={classes.incomeValue}>
                                {formatCurrency(income.incomeProtectorSumAssured)}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.width12}>
                            <Typography className={classes.incomeValue}>
                                {formatCurrency(income.proRataIncomeProtectorSumAssured)}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.width12}>
                            <Typography className={classes.incomeValue}>
                                {formatCurrency(income.proRataTopUpSumAssured)}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.width10}>
                            <Typography className={classes.incomeValue}>
                                {formatCurrency(income.proRataIncomeProtectorPremium)}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.width10}>
                            <Typography className={classes.incomeValue}>
                                {formatCurrency(income.claimBoosterAmount)}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.width14}>
                            <Typography className={classes.incomeValue}>
                                {formatCurrency(income.totalPayable)}
                            </Typography>
                        </Grid>
                    </React.Fragment>
                ))}
        </Grid>
    );
});
