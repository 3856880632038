import { Divider, Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import {
    Condition,
    False,
    FullWidthCheckBoxField,
    FullWidthDatePicker,
    FullWidthTextField,
    True,
} from '../../../../../forms';
import { requiredValidator } from '../../../../../forms/validations';
import styles, { StyledComponent } from '../../../../../styles/styles';

type Props = StyledComponent & {
    heading: React.ReactNode;
};

const additionalInformationRequiredEditView: React.FunctionComponent<Props> = ({
    classes,
    heading,
}: Props) => {
    return (
        <React.Fragment>
            <Typography variant="h5" className={classes.spacedTypography}>
                {heading}
            </Typography>
            <Divider />
            <Condition when="hasWaiver">
                <True>
                    <Details disabled />
                </True>
                <False>
                    <Details />
                </False>
            </Condition>
        </React.Fragment>
    );
};

interface DetailsProps {
    disabled?: boolean;
}
const Details: React.FunctionComponent<DetailsProps> = ({ disabled }: DetailsProps) => {
    return (
        <Grid container spacing={5}>
            <Grid item xs={6} lg={6}>
                <FullWidthTextField
                    id="detailsOfInformationRequested"
                    name="detailsOfInformationRequested"
                    multiline
                    validate={requiredValidator}
                    disabled={disabled}
                    autoFocus
                />
            </Grid>
            <Grid item xs={6} lg={6}>
                <FullWidthTextField
                    id="reasonForRequest"
                    name="reasonForRequest"
                    multiline
                    disabled={disabled}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6} lg={6}>
                <FullWidthDatePicker
                    id="dateRequested"
                    name="dateRequested"
                    validate={requiredValidator}
                    disabled={disabled}
                    disableFuture
                />
            </Grid>
            <Grid item xs={6} lg={6}>
                <FullWidthDatePicker
                    id="dateReceived"
                    name="dateReceived"
                    disabled={disabled}
                    disableFuture
                />
            </Grid>
            <Grid item xs={6} lg={6}>
                <FullWidthTextField id="assessedBy" name="assessedBy" multiline disabled />
            </Grid>
            <Grid item xs={6} lg={6}>
                <FullWidthCheckBoxField name="sendToUnderwriter" />
            </Grid>
        </Grid>
    );
};

export const AdditionalInformationRequiredRecordDetailView = withStyles(styles)(
    additionalInformationRequiredEditView
);
