import React from 'react'; // eslint-disable-line

import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

interface Props {
    field: string;
    becomes?: any | (() => any);
    set: string;
    to?: any | (() => any);
    condition?: boolean | ((value?: any) => boolean);
}

export const WhenFieldChanges: React.FunctionComponent<Props> = ({
    field,
    becomes,
    set,
    to,
    condition,
}: Props) => (
    // No subscription. We only use Field to get to the change function
    <Field name={set} subscription={{}}>
        {({ input: { onChange } }) => (
            <OnChange name={field}>
                {(value: any) => {
                    if (
                        (becomes == null || value === evaluate(value, becomes)) &&
                        (condition == null || evaluate(value, condition))
                    ) {
                        setImmediate(() => {
                            const newValue = evaluate(value, to);
                            onChange(newValue);
                        });
                    }
                }}
            </OnChange>
        )}
    </Field>
);

function evaluate(value: any, evaluee: any | ((value?: any) => any)) {
    if (typeof evaluee === 'function') {
        return evaluee(value);
    }

    return evaluee;
}
