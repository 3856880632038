import { createFeatureActionNamer } from '../../../../../redux/action-name';

const getActionName = createFeatureActionNamer('death-contact-details');

export const ADD_OTHER_CONTACT_RECORD = getActionName('ADD_OTHER_CONTACT_RECORD');
export const DELETE_OTHER_CONTACT_RECORD = getActionName('DELETE_OTHER_CONTACT_RECORD');
export const UPDATE_OTHER_CONTACT_RECORD = getActionName('UPDATE_OTHER_CONTACT_RECORD');

export const ADD_EXECUTOR_RECORD = getActionName('ADD_EXECUTOR_RECORD');
export const DELETE_EXECUTOR_RECORD = getActionName('DELETE_EXECUTOR_RECORD');
export const UPDATE_EXECUTOR_RECORD = getActionName('UPDATE_EXECUTOR_RECORD');
