import { AnyAction, Reducer } from 'redux';
import { Claim, getClaim, getPropertyFromClaim } from '../claim';
import { Doctor } from '../doctor-details/reducer';
import { AddressDetails } from '../forms/sub-forms/address-details';
import { CommunicationDetails } from '../forms/sub-forms/communication-details';
import { State } from '../redux/root-reducer';
import { Nullable, YesOrNo, EntityType } from '../shared-types';
import { OrganisationDetails } from '../assessment/synopsis/death/contact-details/contact-details-reducer';
import { BankingDetails } from '../assessment/synopsis/common/banking-details/banking-details';

export interface PersonalDetails {
    readonly clientId?: string;
    readonly compassClientId?: string;
    readonly title?: string;
    readonly initials?: string;
    readonly firstName?: string;
    readonly secondName?: string;
    readonly lastName?: string;
    readonly idType?: string;
    readonly idNumber?: number;
    readonly idIssueDate?: Date;
    readonly idExpiryDate?: Date;
    readonly idCountryOfIssue?: string;
    readonly passportNumber?: string;
    readonly passportIssueDate?: Date;
    readonly passportExpiryDate?: Date;
    readonly passportCountryOfIssue?: string;
    readonly dateOfBirth?: Date;
    readonly age?: number;
    readonly gender?: string;
    readonly taxStatus?: string;
    readonly countryOfResidence?: string;
    readonly taxReferenceNumber?: number;
    readonly lifeAssuredRole?: string;
    readonly maritalStatus?: string;
    readonly dateOfMarriage?: Date;
    readonly dateOfDivorce?: Date;
    readonly isRsaResident?: string;
    readonly sameAsResidential?: boolean;
    readonly hasFinancialAdviser?: string;
    readonly financialAdviceConsent?: string;
    readonly officiallyEmigrated?: string;
    readonly relationshipToOwner?: string;
    readonly relationshipToOwnerDescription?: string;
    readonly relationshipToLifeAssured?: string;
    readonly relationshipToLifeAssuredDescription?: string;
}

export interface MedicalAidDetails {
    readonly isLifeAssuredTheMainMember?: YesOrNo | null;
    readonly mainMembersTitle?: string;
    readonly mainMembersInitials?: string;
    readonly mainMembersFirstName?: string;
    readonly mainMembersLastName?: string;
    readonly mainMembersIdNumber?: number;
    readonly dateJoined?: Date;
    readonly medicalAidName?: string;
    readonly medicalAidNumber?: string;
    readonly medicalAidDescription?: string;
}

export interface NotificationPolicy {
    readonly companyRegistrationNumber?: string;
    readonly inceptionDate?: Date;
    readonly policyMemberNumber?: string;
    readonly policyTypeId?: string;
    readonly schemeName?: string;
    readonly schemeNumber?: string;
}

export interface SymbolSpin {
    readonly id?: string;
    readonly description?: string;
}

export interface NotificationReason {
    readonly reason?: string;
    readonly reasonId?: string;
    readonly spins?: SymbolSpin[];
    readonly symbols?: SymbolSpin[];
}

export interface NotificationNotes {
    readonly date?: Date;
    readonly notes?: string;
    readonly username?: string;
}

export interface Notification {
    readonly bi1663Number?: string;
    readonly claimNumber?: string;
    readonly claimType?: string;
    readonly dateOfEvent: Date;
    readonly deathCertificateNumber?: string;
    readonly friendlyId?: string;
    readonly notificationId: string;
    readonly notificationNotes: NotificationNotes[];
    readonly notificationPolicy: NotificationPolicy;
    readonly notificationReason: NotificationReason[];
    readonly notificationType: string;
    readonly officeText?: string;
    readonly paymentMethod?: string;
    readonly placeOfDeath?: string;
    readonly primaryCauseOfDeath?: string;
    readonly reasonText?: string;
    readonly roleTypeText?: string;
    readonly statusCode?: string;
    readonly statusText?: string;
    readonly referenceNumber?: string;
    readonly registrationDate?: Date;
}

export interface PolicyOwnerDetails {
    readonly idNumber?: string;
    readonly title?: string;
    readonly initials?: string;
    readonly firstName?: string;
    readonly lastName?: string;
    readonly dateOfBirth?: Date;
    readonly incomeTaxNumber?: string;
    readonly ceded?: string;
    readonly cessionaryType?: string;
    readonly cessionaryName?: string;
    readonly addressDetails?: AddressDetails;
    readonly contactDetails: CommunicationDetails;
}

export interface PolicyOwnerOrganizationDetails {
    readonly cessionaryType?: string;
    readonly cessionaryName?: string;
    readonly organizationName?: string;
    readonly organizationRegistrationDate?: Date;
    readonly organizationRegistrationNumber?: string;
    readonly organizationSuffix?: string;
    readonly taxReferenceNumber?: string;
    readonly addressDetails?: AddressDetails;
    readonly contactDetails: CommunicationDetails;
}

export interface PolicyOwners {
    readonly individuals?: PolicyOwnerDetails[];
    readonly organizations?: PolicyOwnerOrganizationDetails[];
}

export interface ContractOwnerOrganisation {
    readonly name: string;
    readonly registrationDate: Date;
    readonly registrationNumber: string;
    readonly suffix: string;
    readonly taxReferenceNumber: string;
}

export interface ContractOwner {
    readonly id: string;
    readonly ownerType: EntityType;
    readonly personalDetails: PersonalDetails;
    readonly organisation: ContractOwnerOrganisation;
    readonly addressDetails?: AddressDetails;
    readonly contactDetails: CommunicationDetails;
}

export enum ContractType {
    Risk = 'Risk',
    Investment = 'Investment',
    RetirementAnnuity = 'RetirementAnnuity',
}

export interface Reassurance {
    readonly reassuranceType?: string;
    readonly reassuranceIndicator?: boolean;
    readonly sumReinsured?: number;
    readonly reinsurerName?: string;
    readonly referToReinsurer?: boolean;
    readonly termsAndConditions?: string;
}

export interface Exclusion {
    readonly code?: string;
    readonly description?: string;
}

export interface Benefit {
    readonly code?: number;
    readonly contributionAmount?: number;
    readonly benefitAmount?: number;
    readonly endDate?: Date;
    readonly name?: string;
    readonly premiumAmount?: number;
    readonly loadPremiumAmount?: number;
    readonly officePremiumAmount?: number;
    readonly originalBenefitAmount?: number;
    readonly startDate?: Date;
    readonly upgradeDate?: Date;
    readonly reassuranceDetails?: Reassurance[];
    readonly exclusions?: Exclusion[];
    readonly isBenefitPaid?: boolean;
    readonly waitingPeriod?: string;
}

export interface Beneficiary {
    readonly id: string;
    readonly beneficiaryType?: string;
    readonly linkToClaimant?: string;
    readonly communicationDetails?: CommunicationDetails;
    readonly personalDetails?: PersonalDetails;
    readonly organisationDetails?: OrganisationDetails;
    readonly residentialAddressDetails?: AddressDetails;
    readonly postalAddressDetails?: AddressDetails;
    readonly documentType?: string;
    readonly countryOfResidence?: string;
    readonly taxStatus?: string;
    readonly taxNumber?: number;
    readonly countryOfTaxResidence?: string;
    readonly percentageSplit?: string;
    readonly communicationPreference?: string;
    readonly bankingDetails?: BankingDetails;
    readonly readyToPay?: boolean;
    readonly nominatedDate?: Date;
}

export interface AssociatedContract {
    contractNumber: string;
}

export interface LifeAssureds {
    readonly personalDetails: PersonalDetails;
    readonly benefits: Benefit[];
}

export interface LifeAssuredContract {
    automaticBenefitIncrease?: number;
    aciPercentage?: number;
    aciAmount?: number;
    aciDesc?: string;
    premiumPatternCode?: string;
    premiumPatternDesc?: string;
    benefits?: Benefit[];
    commencementDate?: Date;
    contractNumber?: string;
    contractType?: ContractType;
    productName?: string;
    conversionDate?: Date;
    associatedContracts: AssociatedContract[];
    deathValue?: number;
    advanceAmount?: number;
    paidToDate?: Date;
    premiumAmount?: number;
    status?: string;
    premiumPayersName?: string;
    ceded?: string;
    cessionaryType?: string;
    cessionaryName?: string;
    policyOwners: ContractOwner[];
    beneficiaries: Beneficiary[];
    premiumPayer?: any;
    lifeAssureds: LifeAssureds[];
}

export interface Payment {
    readonly policyNumber: string;
    readonly benefit: string;
    readonly currentLifeAssured: boolean;
    readonly firstPaymentDate: Date;
    readonly lastPaymentDate?: Date;
    readonly amount: number;
}

export interface LifeAssuredDetails {
    readonly personalDetails: PersonalDetails;
    readonly residentialAddressDetails: AddressDetails;
    readonly postalAddressDetails: AddressDetails;
    readonly contactDetails: CommunicationDetails & {
        readonly workTelephoneNumber?: string;
        readonly homeTelephoneNumber?: string;
    };
    readonly medicalAidDetails: MedicalAidDetails;
    readonly claimLifeNotifications: Notification[];
    readonly claimLifeNotificationsUpdated?: string;
    readonly contracts: LifeAssuredContract[];
    readonly previousPayments: Payment[];
    readonly originalDoctors: Doctor[];
}

export const defaultLifeAssuredDetails: LifeAssuredDetails = {
    personalDetails: {},
    residentialAddressDetails: {},
    postalAddressDetails: {},
    contactDetails: {},
    medicalAidDetails: {},
    claimLifeNotifications: [],
    contracts: [],
    previousPayments: [],
    originalDoctors: [],
};

export const lifeAssuredDetailsReducer: Reducer<Nullable<LifeAssuredDetails>, AnyAction> = (
    state: Nullable<LifeAssuredDetails> = null,
    action: AnyAction
) => {
    switch (action.type) {
        default:
            return state;
    }
};

export function getLifeAssuredDetails(state: State): LifeAssuredDetails {
    return getLifeAssuredDetailsFromClaim(getClaim(state));
}

export function getLifeAssuredDetailsFromClaim(claim: Claim): LifeAssuredDetails {
    return getPropertyFromClaim(claim, 'lifeAssuredDetails', defaultLifeAssuredDetails);
}

export function getLifeAssuredContractIndex(state: State): number {
    return !!state.formModal.activeModal ? state.formModal.activeModal.modalData : -1;
}
