import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { Edit } from '@material-ui/icons';
import { LabeledValue } from '../../../../controls/labeled-value';
import { Section } from '../../../../controls/section';
import { Benefit, LifeAssuredContract } from '../../../../life-assured-details/reducer';
import { State } from '../../../../redux/root-reducer';
import { formatCurrency, getYesNo } from '../../../../shared-functions';
import styles from '../../../../styles/styles';
import { getAssessmentDetails } from '../../../decisions/assessment-details-reducer';
import { FormModalView } from '../../../../form-modal/views/form-modal';
import { OpenModal, openModalAction } from '../../../../form-modal/actions';
import { Button, Tooltip, Grid, withStyles } from '@material-ui/core';
import { EditClaimBooster } from './claim-booster-edit';
import { IncomeCalculation } from '../../reducer';
import { ButtonWrapper } from '../../../../forms';

interface ClaimBoosterProps {
    contract?: LifeAssuredContract;
    contractBenefit?: Benefit;
    incomeCalculation: IncomeCalculation;
    isReadOnly: boolean;
    openModal: OpenModal;
}

const claimBoosterView = ({
    contract,
    contractBenefit,
    incomeCalculation,
    isReadOnly,
    openModal,
}: ClaimBoosterProps) => {
    const openEditView = () => {
        openModal({
            modalContent: (
                <FormModalView
                    formView={() => (
                        <EditClaimBooster
                            incomeCalculation={incomeCalculation}
                            contract={contract}
                            contractBenefit={contractBenefit}
                        />
                    )}
                />
            ),
        });
    };

    const editClaimBoosterButton = (
        <ButtonWrapper>
            <Tooltip title="Edit Claim Booster">
                <Button id="EditPolicyPremiumDetails" color="secondary" onClick={openEditView}>
                    <Edit />
                </Button>
            </Tooltip>
        </ButtonWrapper>
    );

    return (
        <Section
            title="Claim Booster"
            gutterElement={isReadOnly ? undefined : editClaimBoosterButton}
        >
            <Grid item xs={4}>
                <LabeledValue
                    label="Claim Booster %"
                    value={incomeCalculation.claimBoosterPercentage}
                />
            </Grid>
            <Grid item xs={4}>
                <LabeledValue
                    label="Claim Booster Value"
                    value={formatCurrency(incomeCalculation.claimBoosterAmount)}
                />
            </Grid>
            <Grid item xs={4}>
                <LabeledValue
                    label="Include Claim Booster?"
                    value={getYesNo(incomeCalculation.includeClaimBooster)}
                />
            </Grid>
        </Section>
    );
};

const mapStateToProps = (state: State) => ({
    assessment: getAssessmentDetails(state),
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = {
    openModal: openModalAction,
};

export const ClaimBoosterView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(claimBoosterView));
