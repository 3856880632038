import { reduceAndSubmitClaim } from '../../claim/actions/reduce-and-submit-claim';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { ReinsurerReferal } from '../reducer';
import { addReinsurerReferalRecordActionCreator } from './add-reinsurance-input';
import { updateReinsurerReferalRecordActionCreator } from './update-reinsurance-input';

type SubmitReturn = Promise<any>;

export type AddAndSubmitReinsurerReferalRecord = (
    reinsurerReferalRecord: ReinsurerReferal
) => SubmitReturn;
type AddAndSubmitReinsurerReferalRecordThunk = (
    reinsurerReferalRecord: ReinsurerReferal
) => ReduxThunkAction<SubmitReturn>;
export const addAndSubmitReinsurerReferalRecordThunk: AddAndSubmitReinsurerReferalRecordThunk = (
    reinsurerReferalRecord: ReinsurerReferal
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        addReinsurerReferalRecordActionCreator(reinsurerReferalRecord)
    );

export type UpdateAndSubmitReinsurerReferalRecord = (
    reinsurerReferalRecordIndex: number,
    reinsurerReferalRecord: ReinsurerReferal
) => SubmitReturn;
type UpdateAndSubmitReinsurerReferalRecordThunk = (
    reinsurerReferalRecordIndex: number,
    reinsurerReferalRecord: ReinsurerReferal
) => ReduxThunkAction<SubmitReturn>;
export const updateAndSubmitReinsurerReferalRecordThunk: UpdateAndSubmitReinsurerReferalRecordThunk = (
    reinsurerReferalRecordIndex: number,
    reinsurerReferalRecord: ReinsurerReferal
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        updateReinsurerReferalRecordActionCreator(
            reinsurerReferalRecordIndex,
            reinsurerReferalRecord
        )
    );
