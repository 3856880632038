import { BankingDetails } from '../../assessment/synopsis/common/banking-details/banking-details';
import { claimSubmit, Claim } from '../../claim';
import { ClaimType } from '../../claim/claim-types';
import { DeathClaim } from '../../claim/reducers/claim-reducers/death-claim-reducer';
import { FuneralClaim } from '../../claim/reducers/claim-reducers/funeral-claim-reducer';
import { RADeathClaim } from '../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { updateRecordReduction } from '../../redux/reductions';
import { ReduxThunkAction } from '../../redux/redux-actions';

type UpdateBankingDetailsReturn = Promise<any>;
export type UpdateBankingDetails = (
    bankingDetails: BankingDetails,
    id: string
) => UpdateBankingDetailsReturn;
type UpdateBankingDetailsAction = (
    bankingDetails: BankingDetails,
    id: string
) => ReduxThunkAction<UpdateBankingDetailsReturn>;

export const updateBankingDetailsAction: UpdateBankingDetailsAction = (
    bankingDetails: BankingDetails,
    id: string
) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, getUpdatedBankingDetails(bankingDetails, id));
    };
};

function getUpdatedBankingDetails(
    bankingDetails: BankingDetails,
    id: string
): (claim: Claim) => Claim {
    return (claim: Claim) => {
        if (claim.claimType === ClaimType.Death) {
            const deathClaim = claim as DeathClaim;
            return getUpdatedDeathClaim(deathClaim, bankingDetails, id);
        }

        if (claim.claimType === ClaimType.RADeath) {
            const raClaim = claim as RADeathClaim;
            return getUpdatedRaDeathClaim(raClaim, bankingDetails, id);
        }

        if (claim.claimType === ClaimType.Funeral) {
            const funeralClaim = claim as FuneralClaim;
            return getUpdatedFuneralClaim(funeralClaim, bankingDetails, id);
        }

        return claim;
    };
}

function getUpdatedDeathClaim(claim: DeathClaim, bankingDetails: BankingDetails, id: string) {
    const currentClaimants = (claim.contactDetails && claim.contactDetails.claimants) || [];
    const claimantIndex = currentClaimants.findIndex(c => c.id === id);
    const currentClaimant = currentClaimants[claimantIndex];
    if (currentClaimant) {
        const updateClaimant = {
            ...currentClaimant,
            claimantDetails: {
                ...currentClaimant.claimantDetails,
                bankingDetails: bankingDetails,
            },
        };
        return {
            ...claim,
            contactDetails: {
                ...claim.contactDetails,
                claimants: updateRecordReduction(currentClaimants, updateClaimant, claimantIndex),
            },
        };
    }
    return claim;
}

function getUpdatedRaDeathClaim(claim: RADeathClaim, bankingDetails: BankingDetails, id: string) {
    const dependantIndex = claim.dependants.findIndex(c => c.id === id);
    const currentDependant = claim.dependants[dependantIndex];
    if (currentDependant) {
        const updateDependant = {
            ...currentDependant,
            bankingDetails: bankingDetails,
        };
        return {
            ...claim,
            dependants: updateRecordReduction(claim.dependants, updateDependant, dependantIndex),
        };
    }
    return claim;
}

function getUpdatedFuneralClaim(claim: FuneralClaim, bankingDetails: BankingDetails, id: string) {
    if (claim.formDetails) {
        const policyOwner = claim.formDetails.policyOwner;
        if (policyOwner && policyOwner.id === id) {
            return {
                ...claim,
            };
        }
        const claimants = claim.formDetails.claimants;
        const claimantIndex = claimants.findIndex(c => c.id === id);
        if (claimantIndex !== -1) {
            const claimant = claimants[claimantIndex];
            const updatedClaimant = {
                ...claimant,
                bankingDetails,
            };
            return {
                ...claim,
                formDetails: {
                    ...claim.formDetails,
                    claimants: updateRecordReduction(claimants, updatedClaimant, claimantIndex),
                },
            };
        }
    }

    return claim;
}
