import { DateTime } from 'luxon';
import { claimSubmit } from '../../../../../claim';
import { getDeathClaimDetails } from '../../../../../claim/reducers/claim-reducers/death-claim-reducer';
import { isEmpty } from '../../../../../forms/validations';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { DeathClaimDetails, DeathClaimEventDetails } from '../reducer';

type SubmitReturn = Promise<any>;
export type UpdateClaimDetails = (claimEventDetails: DeathClaimDetails) => SubmitReturn;
type UpdateClaimDetailsAction = (
    claimEventDetails: DeathClaimDetails
) => ReduxThunkAction<SubmitReturn>;

export const updateClaimDetailsAction: UpdateClaimDetailsAction = (
    claimEventDetails: DeathClaimDetails
) => {
    return async (dispatch, getState) => {
        return claimSubmit(
            dispatch,
            getState,
            claim => {
                const currentClaimNotes = getDeathClaimDetails(claim);
                return {
                    ...claim,
                    claimEventDetails: {
                        ...currentClaimNotes,
                        deathClaimDetails: claimEventDetails,
                    },
                };
            },
            validate
        );
    };
};
function validate(claim: any) {
    const errors = {} as any;
    if (!('claimEventDetails' in claim) || !claim.claimEventDetails) {
        return errors;
    }
    const details: DeathClaimEventDetails = claim.claimEventDetails;
    const deathClaimDetails = details.deathClaimDetails;
    const claimEventDetails = {} as any;
    if (deathClaimDetails !== undefined) {
        const dateOfEvent = deathClaimDetails.dateOfEvent
            ? DateTime.fromJSDate(deathClaimDetails.dateOfEvent).startOf('day')
            : undefined;
        const dateClaimReported = deathClaimDetails.dateClaimReported
            ? DateTime.fromJSDate(deathClaimDetails.dateClaimReported).startOf('day')
            : undefined;

        if (!!dateClaimReported && !!dateOfEvent && dateOfEvent > dateClaimReported) {
            claimEventDetails.dateClaimReported = 'This must be after Date of event';
            errors.claimEventDetails = claimEventDetails;
        }
        if (!isEmpty(errors)) {
            return {
                'front-end-errors': errors,
            };
        }
    }

    return errors;
}
