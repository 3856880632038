import React from 'react'; // eslint-disable-line

import { connect } from 'react-redux';
import { SetReference, setReferenceAction } from '../claim/actions/set-reference';
import { State } from '../redux/root-reducer';
import { Nullable } from '../shared-types';
import { GetAuthToken, getAuthTokenThunk } from './actions/get-auth-token';
import { AuthState } from './auth-reducer';

interface Props {
    children?: React.ReactNode;
    unauthorizedView?: React.ReactNode;
    disableLogin?: boolean;
    authState: Nullable<AuthState>;
    getAuthToken: GetAuthToken;
    setReference: SetReference;
}

class AuthenticatedViewClass extends React.Component<Props> {
    public componentDidMount() {
        if (this.props.disableLogin) {
            return;
        }
        if (window.location && window.location.pathname === '/claim') {
            const params = new URLSearchParams(window.location.search);
            const referenceSource = params.get('referenceSource') || '';
            const referenceId = params.get('referenceId') || '';
            const creationDate = params.get('creationDate') || '';
            if (referenceSource && referenceId) {
                this.props.setReference(referenceSource, referenceId, creationDate);
            }
        }
        this.props.getAuthToken();
    }

    public render() {
        const { children = null, unauthorizedView = null, authState }: Props = this.props;
        if (!authState) {
            return <React.Fragment>{unauthorizedView}</React.Fragment>;
        }

        return <React.Fragment>{children}</React.Fragment>;
    }
}

const mapStateToProps = (state: State) => ({
    authState: state.auth,
});

const mapDispatchToProps = {
    getAuthToken: getAuthTokenThunk,
    setReference: setReferenceAction,
};

export const AuthenticatedView = connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthenticatedViewClass);
