import { Lookups } from '../../../lookups';

export interface AddressDetails {
    readonly addressType?: string;
    readonly addressLine1?: string;
    readonly addressLine2?: string;
    readonly addressLine3?: string;
    readonly addressLine4?: string;
    readonly suburb?: string;
    readonly postalCode?: string;
    readonly town?: string;
    readonly province?: string;
    readonly country?: string;
}

export enum AddressType {
    Street = 'Street',
    PostBox = 'PostBox',
    PrivateBag = 'PrivateBag',
    Foreign = 'Foreign',
}

export const AddressTypes = [
    { value: AddressType.Street, label: 'Street Address' },
    { value: AddressType.PostBox, label: 'Post Box' },
    { value: AddressType.PrivateBag, label: 'Private Bag' },
    { value: AddressType.Foreign, label: 'Foreign' },
];

export const AddressTypesLivingLifestyle = [
    { value: AddressType.Street, label: 'RSA Street Address' },
    { value: AddressType.PostBox, label: 'Post Box' },
    { value: AddressType.PrivateBag, label: 'Private Bag' },
    { value: AddressType.Foreign, label: 'Foreign' },
];

export const ResidentialAddressTypes = [
    { value: AddressType.Street, label: 'Street Address' },
    { value: AddressType.Foreign, label: 'Foreign' },
];

export const Provinces = [
    { value: 'Eastern Cape', label: 'Eastern Cape' },
    { value: 'Free State', label: 'Free State' },
    { value: 'Gauteng', label: 'Gauteng' },
    { value: 'KwaZulu-Natal', label: 'KwaZulu-Natal' },
    { value: 'Limpopo', label: 'Limpopo' },
    { value: 'Mpumalanga', label: 'Mpumalanga' },
    { value: 'North West', label: 'North West' },
    { value: 'Northern Cape', label: 'Northern Cape' },
    { value: 'Western Cape', label: 'Western Cape' },
];

export function getAddressTypeOptions(addressType?: string) {
    if (addressType === 'street') {
        return [{ value: AddressType.Street, label: 'Street Address' }];
    }
    if (addressType === 'residential') {
        return ResidentialAddressTypes;
    }
    return AddressTypes;
}

function getSuburb(lookups: Lookups, address?: AddressDetails) {
    if (address) {
        const postalCodeDesc = lookups.postalCodesStreet
            .concat(...lookups.postalCodesBox)
            .find(c => c.value === address.postalCode);
        return postalCodeDesc ? postalCodeDesc.label.split(' - ') : [address.suburb, address.town];
    }
    return [];
}

function getAddressLine(addressLine?: string, addressType?: string) {
    if (addressType === AddressType.PostBox || addressType === AddressType.PrivateBag) {
        return `${addressType.split(/(?=[A-Z])/).join(' ')} ${addressLine}`;
    }
    return addressLine;
}

export function getDisplayAddress(lookups: Lookups, address?: AddressDetails) {
    if (!address) {
        return [];
    }
    return [
        getAddressLine(address.addressLine1, address.addressType),
        address.addressLine2,
        address.addressLine3,
        address.addressLine4,
        ...getSuburb(lookups, address),
        address.postalCode,
        address.province,
        address.country,
    ];
}
