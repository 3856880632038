import { Button, Grid, Paper, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { LabeledValue } from '../../../../controls/labeled-value';
import { EditFormModal } from '../../../../form-modal';
import { OpenEditModal, openEditModalAction } from '../../../../form-modal/actions';
import { Beneficiary, PersonalDetails } from '../../../../life-assured-details/reducer';
import { Code } from '../../../../lookups';
import { State } from '../../../../redux/root-reducer';
import { getDisplayDate, getFullName } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import { OrganisationDetails } from '../../death/contact-details/contact-details-reducer';
import { Claimant, getFormDetails } from '../form-details/form-details-reducer';
import { UpdateBeneficiary, updateBeneficiaryAction } from './actions/update-beneficiary-details';
import { BeneficiaryDetailsView } from './beneficiary-details-edit-modal';
import { ButtonWrapper } from '../../../../forms/form-components/button-wrapper/index';
import { ButtonCapture } from '../../../../forms';

interface Props extends StyledComponent {
    beneficiaries: Beneficiary[];
    claimants: Code<string>[];
    contractIndex: number;
    openEditModal: OpenEditModal;
    updateBeneficiary: UpdateBeneficiary;
}

const BeneficiaryEditFormModal = EditFormModal<Beneficiary>();
const beneficiaryDetails: React.FunctionComponent<Props> = ({
    beneficiaries,
    claimants,
    classes,
    contractIndex,
    openEditModal,
    updateBeneficiary,
}: Props) => {
    if (!beneficiaries || beneficiaries.length === 0) {
        return null;
    }

    const editBeneficiary = (beneficiaryIndex: number) => {
        openEditModal({
            modalContent: (
                <BeneficiaryEditFormModal
                    recordDetailView={<BeneficiaryDetailsView claimants={claimants} />}
                    records={beneficiaries}
                    updateRecord={beneficiary =>
                        updateBeneficiary(beneficiary, contractIndex, beneficiaryIndex)
                    }
                />
            ),
            modalData: {
                recordIndex: beneficiaryIndex,
            },
        });
    };

    const getBeneficiary = (linkToClaimant?: string): string => {
        const claimant = claimants.find(c => c.value === linkToClaimant);
        return claimant ? claimant.label : '';
    };

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography variant="subtitle2">Beneficiary Details</Typography>
            </Grid>
            {beneficiaries.map((beneficiary, index) => {
                return (
                    <Paper className={classes.paper} key={`${index}beneficiary`}>
                        <Grid container justify="flex-start">
                            <Grid item xs={10}>
                                <Typography variant="h5">
                                    {getBeneficiaryName(beneficiary)}
                                </Typography>
                            </Grid>
                            <ButtonWrapper>
                                <ButtonCapture>
                                    <Grid item xs={2} style={{ textAlign: 'right' }}>
                                        <Tooltip title="Link to claimant">
                                            <Button
                                                id="linkToClaimant"
                                                color="secondary"
                                                onClick={() => editBeneficiary(index)}
                                            >
                                                <Edit fontSize="small" />
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                </ButtonCapture>
                            </ButtonWrapper>
                            {beneficiary.beneficiaryType === 'Individual' && (
                                <PersonalDetailsView
                                    personalDetails={beneficiary.personalDetails}
                                />
                            )}
                            {beneficiary.beneficiaryType === 'Organisation' && (
                                <OrganisationView organisation={beneficiary.organisationDetails} />
                            )}
                            {beneficiary.beneficiaryType === 'Estate' &&
                                beneficiary.personalDetails && (
                                    <Grid item xs={12}>
                                        <Typography variant="h5">
                                            {beneficiary.personalDetails.firstName}
                                        </Typography>
                                    </Grid>
                                )}
                            <Grid item xs={6} lg={3}>
                                <LabeledValue label="% Split" value={beneficiary.percentageSplit} />
                            </Grid>
                            <Grid item xs={6} lg={3}>
                                <LabeledValue
                                    label="Linked to Claimant"
                                    value={getBeneficiary(beneficiary.linkToClaimant)}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                );
            })}
        </React.Fragment>
    );
};

function getBeneficiaryName(beneficiary: Beneficiary) {
    switch (beneficiary.beneficiaryType) {
        case 'Individual':
            return getFullName(beneficiary.personalDetails);
        case 'Estate':
            return beneficiary.personalDetails && beneficiary.personalDetails.firstName;
        case 'Organisation':
            return beneficiary.organisationDetails && beneficiary.organisationDetails.companyName;
        default:
            return '';
    }
}

interface PersonProps {
    personalDetails?: PersonalDetails;
}

const PersonalDetailsView = ({ personalDetails }: PersonProps) => {
    if (!personalDetails) {
        return null;
    }
    return (
        <React.Fragment>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="ID Number" value={personalDetails.idNumber} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Date of birth"
                    value={getDisplayDate(personalDetails.dateOfBirth, 'dd LLLL yyyy')}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Relationship to owner"
                    value={
                        personalDetails.relationshipToLifeAssured === 'Other'
                            ? personalDetails.relationshipToLifeAssuredDescription
                            : personalDetails.relationshipToLifeAssured
                    }
                />
            </Grid>
        </React.Fragment>
    );
};

interface OrganisationProps {
    organisation?: OrganisationDetails;
}

const OrganisationView = ({ organisation }: OrganisationProps) => {
    if (!organisation) {
        return null;
    }
    return (
        <React.Fragment>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Relationship to owner"
                    value={
                        organisation.relationshipToLifeAssured === 'Other'
                            ? organisation.relationshipToLifeAssuredDescription
                            : organisation.relationshipToLifeAssured
                    }
                />
            </Grid>
        </React.Fragment>
    );
};

const getClaimantsList = (claimants?: Claimant[]) => {
    if (!claimants || claimants.length === 0) {
        return [];
    }
    const claimantList: Code<string>[] = [];
    claimants.forEach(claimant => {
        const name = getFullName(claimant.personalDetails);
        claimantList.push({ value: claimant.id || '', label: name });
    });

    return claimantList;
};

const mapStateToProps = (state: State) => ({
    claimants: getClaimantsList(getFormDetails(state).claimants),
});

const mapDispatchToProps = {
    openEditModal: openEditModalAction,
    updateBeneficiary: updateBeneficiaryAction,
};

export const BeneficiaryDetails = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(beneficiaryDetails));
