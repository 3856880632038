import { ReduxAction } from '../../redux/redux-actions';
import { FinalDecisionDetails } from '../reducer';
import { ADD_FINAL_DECISION } from '../types';

export interface AddFinalDecisionAction extends ReduxAction<FinalDecisionDetails> {
    policyBenefitFinalDecisionsIndex: number;
}
export type AddFinalDecision = (
    finalDecision: FinalDecisionDetails,
    policyBenefitFinalDecisionsIndex: number
) => AddFinalDecisionAction;

export const addFinalDecisionAction: AddFinalDecision = (
    finalDecision: FinalDecisionDetails,
    policyBenefitFinalDecisionsIndex: number
) => {
    return {
        type: ADD_FINAL_DECISION,
        payload: finalDecision,
        policyBenefitFinalDecisionsIndex,
    };
};
