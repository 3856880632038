import { AnyAction, Reducer } from 'redux';
import { getProperty } from '../../../claim/reducers';
import { State } from '../../../redux/root-reducer';
import { Nullable } from '../../../shared-types';
import { ClaimType } from '../../../claim/claim-types';
import { BenefitAssessmentDetails } from '../assessment-details-reducer';

export interface AssessmentDetails {
    readonly policyNumber: string;
    readonly benefits: BenefitAssessmentDetails[];
}

export interface FuneralAssessmentDecisionViewModel {
    readonly id: string;
    readonly final?: boolean;
    readonly assessmentType?: string;
    readonly assessor?: string;
    readonly assessmentDate: Date;
    readonly summary?: string;
    readonly decision?: string;
    readonly reason?: string;
    readonly claimType?: Nullable<ClaimType>;
    readonly effectiveDate?: Date;
    readonly expiryDate?: Date;
    readonly amountPayable?: number;
    readonly policyNumber: string;
    readonly benefitName?: string;
    readonly commencementDate?: Date;
    readonly sumAssured?: number;
    readonly paymentBasis?: string;
}

export const defaultFuneralAssessmentDetails: AssessmentDetails[] = [];

type Action = AnyAction;

export const funeralAssessmentDetailsReducer: Reducer = (
    state: Nullable<AssessmentDetails> = null,
    action: Action
) => {
    return state;
};

export function getFuneralAssessmentDetails(state: State): AssessmentDetails[] {
    return getProperty(state, 'assessmentDetails', defaultFuneralAssessmentDetails);
}
