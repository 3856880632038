import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getClaim } from '../../../../../../claim';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import { Section } from '../../../../../../controls/section';
import { State } from '../../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { ButtonWrapper } from '../../../../../../forms';
import {
    ClaimantRequirements,
    getRequirementsFromClaim,
} from '../../../../common/requirements-management/reducer';
import {
    AddDocumentButton,
    EditDocumentsButton,
    RequiredDocumentsView,
} from '../../../../common/requirements-management/views';
import {
    Dependant,
    getDependantsDisplayName,
    getDependantsFromClaim,
} from '../../../dependants/reducer';
import {
    addAndSubmitDependantsRequirementsAction,
    AddDependantsRequiredDocument,
} from '../../action/add-dependants-requirements';
import { RADeathRequirements } from '../../reducer';
import { DocumentOwnerType } from '../../../../../../shared-types';

interface DispatchProps {
    addRequiredDocument: AddDependantsRequiredDocument;
}
interface StateProps {
    dependants: Dependant[];
    dependantsRequirements: ClaimantRequirements[];
}
interface OwnProps {}
type Props = StyledComponent & OwnProps & DispatchProps & StateProps;
const dependantsRequirementsView: React.FunctionComponent<Props> = ({
    addRequiredDocument,
    dependants,
    dependantsRequirements,
}: Props) => {
    if (!dependantsRequirements || dependantsRequirements.length === 0) {
        return null;
    }

    return (
        <React.Fragment>
            <Grid container item xs={6} alignItems="center">
                <Typography variant="subtitle2">Dependants requirements</Typography>
            </Grid>
            {dependantsRequirements.map((dependantRequirements, index) => {
                const dependant = dependants.find(c => c.id === dependantRequirements.id);
                const dependantsName = getDependantsDisplayName(dependant);
                const documents = dependantRequirements.documents;
                const prefix = `dependants[${index}].documents`;
                return (
                    <Section title="" key={index}>
                        <Grid container justify="flex-start">
                            <Grid item xs={8} lg={10}>
                                <Typography variant="h5">{dependantsName}</Typography>
                            </Grid>
                            <ButtonWrapper>
                                <Grid item xs={4} lg={2} style={{ textAlign: 'right' }}>
                                    <EditDocumentsButton
                                        documents={documents}
                                        name={dependantsName}
                                        prefix={prefix}
                                    />
                                    <AddDocumentButton
                                        addAction={record => addRequiredDocument(record, index)}
                                        documents={documents}
                                        documentOwnerType={DocumentOwnerType.Claimant}
                                    />
                                </Grid>
                            </ButtonWrapper>
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <LabeledValue
                                label="Type"
                                value={(dependant && dependant.dependantType) || ''}
                            />
                        </Grid>
                        <RequiredDocumentsView documents={documents} />
                    </Section>
                );
            })}
        </React.Fragment>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => {
    const claim = getClaim(state);
    return {
        dependants: getDependantsFromClaim(claim),
        dependantsRequirements: (getRequirementsFromClaim(claim) as RADeathRequirements).dependants,
    };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    addRequiredDocument: (options, index) =>
        dispatch(addAndSubmitDependantsRequirementsAction(options, index)),
});

export const DependantsRequirementsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(dependantsRequirementsView));
