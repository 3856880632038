import React from 'react'; // eslint-disable-line
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { ExpansionPanel } from '../../../../forms/form-components/expansion-panel/expansion-panel';
import styles, { StyledComponent } from '../../../../styles/styles';
import { getObjectWarning } from '../../../../shared-functions';
import {
    DeathClaim,
    getDeathClaim,
} from '../../../../claim/reducers/claim-reducers/death-claim-reducer';
import { State } from '../../../../redux/root-reducer';
import { FuneralParlourDetailsView } from './funeral-parlour-view';
import { DeceasedDetailsView } from './deceased-details-view';
import { InformantDetailsView } from './informant-view';
import { DoctorIndunaDetailView } from './doctor-induna-view';
import { FormInfoDetailsView } from './form-info-view';
import { NonRaDeathWrapper } from '../../../../forms';
type Props = StyledComponent & {
    deathClaim: DeathClaim;
};

const noticeOfDeathView: React.FunctionComponent<Props> = ({ deathClaim }: Props) => {
    const notifier = getObjectWarning(deathClaim.noticeOfDeath, null);

    return (
        <ExpansionPanel name="Notice of Death" secondaryLabel="(BI1663/BI1680)" notifier={notifier}>
            <FormInfoDetailsView />
            <DeceasedDetailsView />
            <NonRaDeathWrapper>
                <InformantDetailsView />
                <FuneralParlourDetailsView />
                <DoctorIndunaDetailView />
            </NonRaDeathWrapper>
        </ExpansionPanel>
    );
};

const mapStateToProps = (state: State) => ({
    deathClaim: getDeathClaim(state),
});

const mapDispatchToProps = {};

export const NoticeOfDeathView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(noticeOfDeathView));
