import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getClaim } from '../../../../../../claim';
import { isEmpty } from '../../../../../../forms/validations';
import { State } from '../../../../../../redux/root-reducer';
import { getFullName } from '../../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import {
    ClaimantRequirements,
    getRequirementsFromClaim,
} from '../../../../common/requirements-management/reducer';
import {
    AddDocumentButton,
    EditDocumentsButton,
    RequiredDocumentsView,
} from '../../../../common/requirements-management/views';
import { Claimant, getFormDetailsFromClaim } from '../../../form-details/form-details-reducer';
import {
    AddPolicyOwnerDocument,
    addPolicyOwnerDocumentAction,
} from '../../action/add-policy-owner-documents';
import { FuneralRequirements } from '../../reducer';
import { DocumentOwnerType } from '../../../../../../shared-types';

interface DispatchProps {
    addDocument: AddPolicyOwnerDocument;
}
interface StateProps {
    requirements?: ClaimantRequirements;
    policyOwner?: Claimant;
}

type Props = StyledComponent & DispatchProps & StateProps;
const viewPolicyOwner: React.FunctionComponent<Props> = ({
    addDocument,
    classes,
    requirements,
    policyOwner,
}: Props) => {
    if (!policyOwner || isEmpty(policyOwner)) {
        return null;
    }

    const documents = (requirements && requirements.documents) || [];
    const ownerName = getFullName(policyOwner.personalDetails);
    const prefix = 'policyOwner.documents';

    return (
        <Grid container justify="flex-start" className={classes.marginTop}>
            <Grid item xs={8} lg={10}>
                <Typography className={classes.heading} variant="body1">
                    Policy Owner Requirements | {ownerName}
                </Typography>
            </Grid>
            <Grid item xs={4} lg={2} className={classes.rightAlign}>
                <EditDocumentsButton documents={documents} name={ownerName} prefix={prefix} />
                <AddDocumentButton
                    addAction={addDocument}
                    documentOwnerType={DocumentOwnerType.PolicyOwner}
                    documents={documents}
                />
            </Grid>
            <RequiredDocumentsView documents={documents} />
        </Grid>
    );
};

const mapStateToProps: MapStateToProps<StateProps, Record<string, any>, State> = (state: State) => {
    const claim = getClaim(state);
    return {
        requirements: (getRequirementsFromClaim(claim) as FuneralRequirements).policyOwner,
        policyOwner: getFormDetailsFromClaim(claim).policyOwner,
    };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, Record<string, any>> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    addDocument: options => dispatch(addPolicyOwnerDocumentAction(options)),
});

export const PolicyOwnerRequirementsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(viewPolicyOwner));
