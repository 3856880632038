import { Button, Divider, Grid, Paper, Typography, withStyles } from '@material-ui/core';
import { AddCircle, Cached, CheckCircle, Warning } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { v1 as uuid } from 'uuid';
import { AuthState } from '../../../../auth/auth-reducer';
import { OpenDialog, openDialogAction } from '../../../../confirmation-dialog';
import { getDisabilityDetails } from '../../../../disability-details/reducer';
import {
    OpenAddModal,
    OpenModal,
    openAddModalAction,
    openModalAction,
} from '../../../../form-modal/actions';
import { ButtonWrapper, FullWidthRichTextField, WhenFieldChanges } from '../../../../forms';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import {
    getClaimDetails,
    noCauseOfClaimDetails,
} from '../../../synopsis/common/claim-details/claim-details-reducer';
import { RaiseLiability, raiseLiabilityAction } from '../../actions/raise-liability';
import {
    AssessmentOpinion,
    AssessmentSummary,
    BenefitAssessmentDetails,
} from '../../assessment-details-reducer';
import { AssessmentDecisionView } from './assessment-decision';
import { AddAssessmentDecisionProps } from './assessment-decision-add';
import { BenefitAssessmentSummary } from './benefit-assessment-summary';
import { LiabilityStatusView } from './liability-status-view';
import { getLifeAssuredDetailsFromClaim } from '../../../../life-assured-details/reducer';
import { ModalViewProps } from '../../../../form-modal/views/multi-form-modal';
import { requiredValidator } from '../../../../forms/validations';
import { getDisplayDate, titleCase } from '../../../../shared-functions';
import {
    UpdateBenefitAssessment,
    UpdateBenefitAssessmentAddSummaryOpinion,
    updateBenefitAssessmentAction,
    updateBenefitAssessmentAddSummaryOpinionAction,
} from '../../actions/update-benefit-assessment';
import { LabeledValue } from '../../../../controls/labeled-value';
import { MultiAddFormModal } from '../../../../form-modal/views/multi-form-add-modal';

interface OwnProps {
    assessmentDecisionAddView:
        | React.FunctionComponent<AddAssessmentDecisionProps>
        | React.ComponentClass<AddAssessmentDecisionProps>;
    automaticBenefitIncrease?: number;
    benefitAssessmentDetails: BenefitAssessmentDetails;
    benefitIndex: number;
    policyIndex: number;
    policyNumber: string;
}

interface StateProps {
    causeOfClaim?: string;
    currentUser: AuthState;
    dateOfEvent?: Date;
    isReadOnly: boolean;
    lastDayAtWork?: Date;
    returnToWork?: Date;
    noCauseOfClaimDetails: boolean;
    registry?: string;
    notification?: any;
}

interface DispatchProps {
    openAddModal: OpenAddModal;
    openModal: OpenModal;
    raiseLiability: RaiseLiability;
    openDialog: OpenDialog;
    updateAssessment: UpdateBenefitAssessment;
    updateBenefitAssessmentAddSummaryOpinion: UpdateBenefitAssessmentAddSummaryOpinion;
}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const benefitAssessmentDetailsView: React.FunctionComponent<Props> = ({
    assessmentDecisionAddView: AssessmentDecisionAddView,
    automaticBenefitIncrease,
    benefitAssessmentDetails,
    classes,
    currentUser,
    isReadOnly,
    openAddModal,
    openModal,
    policyNumber,
    raiseLiability,
    openDialog,
    noCauseOfClaimDetails,
    registry,
    notification,
    updateAssessment,
    updateBenefitAssessmentAddSummaryOpinion,
    policyIndex,
    benefitIndex,
    ...rest
}: Props) => {
    interface FieldProps {
        currentUser: AuthState;
        name: string;
    }

    const AddSummaryModalForm = MultiAddFormModal<AssessmentSummary>();
    const AddOpinionModalForm = MultiAddFormModal<AssessmentOpinion>();

    const EditAssessmentField = ({ currentUser, name }: FieldProps) => {
        return (
            <React.Fragment>
                <FullWidthRichTextField
                    name={name}
                    validate={requiredValidator}
                    showOcr={false}
                    hideLabel
                />
                <WhenFieldChanges field={name} set={`${name}By`} to={currentUser.name} />
                <WhenFieldChanges field={name} set={`${name}Date`} to={new Date()} />
            </React.Fragment>
        );
    };

    const EditModalViews: ModalViewProps[] = [
        {
            name: 'Summary',
            view: <EditAssessmentField name="summary" currentUser={currentUser} />,
        },
        {
            name: 'Opinion',
            view: <EditAssessmentField name="opinion" currentUser={currentUser} />,
        },
    ];

    function addSummary(policyIndex: number, benefitIndex: number, screenIndex: number) {
        openModal({
            modalContent: (
                <AddSummaryModalForm
                    heading={`Assessment for Benefit - ${titleCase(
                        benefitAssessmentDetails.benefitName
                    )}`}
                    modalViews={EditModalViews}
                    saveAction={values =>
                        updateBenefitAssessmentAddSummaryOpinion(
                            values,
                            policyIndex,
                            benefitIndex,
                            benefitAssessmentDetails
                        )
                    }
                    key={`Add-Opinion-Summary-${benefitAssessmentDetails.benefitName}`}
                />
            ),
            modalData: {
                screenIndex: screenIndex,
                initialValues: {},
            },
        });
    }

    function addOpinion(policyIndex: number, benefitIndex: number, screenIndex: number) {
        openModal({
            modalContent: (
                <AddOpinionModalForm
                    heading={`Assessment for Benefit - ${titleCase(
                        benefitAssessmentDetails.benefitName
                    )}`}
                    modalViews={EditModalViews}
                    saveAction={values =>
                        updateBenefitAssessmentAddSummaryOpinion(
                            values,
                            policyIndex,
                            benefitIndex,
                            benefitAssessmentDetails
                        )
                    }
                    key={`Add-Opinion-Summary-${benefitAssessmentDetails.benefitName}`}
                />
            ),
            modalData: {
                screenIndex: screenIndex,
                initialValues: {},
            },
        });
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="subtitle2">
                        Benefit - {benefitAssessmentDetails.benefitName}
                    </Typography>
                </Grid>
                <Grid container item xs={12} justify="flex-start">
                    <Typography>
                        Please ensure that you capture the life registry details before adding a
                        decision
                    </Typography>
                    <Typography
                        style={{
                            marginLeft: '5px',
                            fontWeight: 'bold',
                        }}
                    >
                        {isRegistry || ''}
                    </Typography>
                    {checkRegistryEventCaptured(registry === 'Yes' && notification !== null)}
                </Grid>
                <Grid container item xs={12} justify="flex-start">
                    <Typography>
                        Please ensure that you capture the claim event details before adding a
                        decision
                    </Typography>
                    <Typography
                        style={{
                            marginLeft: '5px',
                            fontWeight: 'bold',
                        }}
                    >
                        {!noCauseOfClaimDetails || ''}
                    </Typography>
                    {checkRegistryEventCaptured(!noCauseOfClaimDetails)}
                </Grid>
            </Grid>

            <Paper className={classes.paper}>
                <Grid container>
                    <Grid item xs={10}>
                        <Typography
                            variant="subtitle1"
                            style={{ marginTop: 10, fontWeight: 500 }}
                            className={classes.label}
                        >
                            Summary
                        </Typography>
                    </Grid>
                    <ButtonWrapper>
                        <Grid item xs={2}>
                            <Button
                                id={'Add_Opinion_Summary_' + benefitAssessmentDetails.benefitName}
                                color="secondary"
                                style={{ padding: 10, textTransform: 'none' }}
                                onClick={() => addSummary(policyIndex, benefitIndex, 0)}
                            >
                                <AddCircle style={{ marginRight: 4 }} /> Add Summary
                            </Button>
                        </Grid>
                    </ButtonWrapper>
                </Grid>
                {benefitAssessmentDetails.summaries &&
                    benefitAssessmentDetails.summaries.length > 0 && (
                        <Grid container>
                            {benefitAssessmentDetails.summaries
                                .sort((a, b) => a.summaryDate.getTime() - b.summaryDate.getTime())
                                .map((summary, index) => {
                                    return (
                                        <React.Fragment key={`Summary-${policyIndex}-${index}`}>
                                            <Grid item xs={12}>
                                                <LabeledValue
                                                    label=""
                                                    value={summary.summary}
                                                    isUnsafe
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <LabeledValue
                                                    label={`Summary By`}
                                                    value={summary.summaryBy}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <LabeledValue
                                                    label={`Summary Date`}
                                                    value={getDisplayDate(summary.summaryDate)}
                                                />
                                            </Grid>
                                            <Divider style={{ width: '100%' }} />
                                        </React.Fragment>
                                    );
                                })}
                        </Grid>
                    )}
                {benefitAssessmentDetails.summaryDate && (
                    <BenefitAssessmentSummary
                        {...{
                            ...rest,
                            policyIndex,
                            benefitIndex,
                            benefitAssessmentDetails,
                            currentUser,
                            index: 0,
                        }}
                    />
                )}
                <Divider />

                <Grid container>
                    <Grid item xs={10}>
                        <Typography
                            variant="subtitle1"
                            style={{ marginTop: 10, fontWeight: 500 }}
                            className={classes.label}
                        >
                            Opinion
                        </Typography>
                    </Grid>
                    <ButtonWrapper>
                        <Grid item xs={2}>
                            <Button
                                id={'Add_Opinion_Summary_' + benefitAssessmentDetails.benefitName}
                                color="secondary"
                                style={{ padding: 10, textTransform: 'none' }}
                                onClick={() => addOpinion(policyIndex, benefitIndex, 1)}
                            >
                                <AddCircle style={{ marginRight: 4 }} /> Add Opinion
                            </Button>
                        </Grid>
                    </ButtonWrapper>
                </Grid>
                {benefitAssessmentDetails.opinions && benefitAssessmentDetails.opinions.length > 0 && (
                    <Grid container>
                        {benefitAssessmentDetails.opinions
                            .sort((a, b) => a.opinionDate.getTime() - b.opinionDate.getTime())
                            .map((opinion, index) => {
                                return (
                                    <React.Fragment key={`Opinion-${policyIndex}-${index}`}>
                                        <Grid item xs={12}>
                                            <LabeledValue
                                                label=""
                                                value={opinion.opinion}
                                                isUnsafe
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <LabeledValue
                                                label={`Opinion By`}
                                                value={opinion.opinionBy}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <LabeledValue
                                                label={`Opinion Date`}
                                                value={getDisplayDate(opinion.opinionDate)}
                                            />
                                        </Grid>
                                        <Divider style={{ width: '100%' }} />
                                    </React.Fragment>
                                );
                            })}
                    </Grid>
                )}
                {benefitAssessmentDetails.opinionDate && (
                    <BenefitAssessmentSummary
                        {...{
                            ...rest,
                            policyIndex,
                            benefitIndex,
                            benefitAssessmentDetails,
                            currentUser,
                            index: 1,
                        }}
                    />
                )}
            </Paper>
            <Paper className={classes.paper}>
                <Grid container>
                    <Grid item xs={10}>
                        <Typography variant="subtitle2" style={{ marginTop: 10 }}>
                            Decision
                        </Typography>
                    </Grid>
                    <ButtonWrapper>
                        <Grid item xs={2}>
                            <Button
                                id={'Add_' + benefitAssessmentDetails.benefitName}
                                color="secondary"
                                style={{ padding: 10, textTransform: 'none' }}
                                onClick={addDecision}
                                disabled={isRegistryEventCaptured()}
                            >
                                <AddCircle style={{ marginRight: 4 }} />
                                Add decision
                            </Button>
                        </Grid>
                    </ButtonWrapper>
                </Grid>
                {switchOn() && showRaiseLiability() && !isReadOnly && (
                    <Grid container>
                        <Grid item xs={10}>
                            x{' '}
                            <LiabilityStatusView
                                claimLiability={benefitAssessmentDetails.claimLiability}
                            />
                        </Grid>
                        <ButtonWrapper>
                            <Grid container item xs={2} style={{ marginTop: '-15px' }}>
                                <Button
                                    id="Raise-liability"
                                    color="secondary"
                                    style={{ padding: 10, textTransform: 'none' }}
                                    onClick={liability}
                                >
                                    <Cached style={{ marginRight: 4 }} />
                                    Raise liability
                                </Button>
                            </Grid>
                        </ButtonWrapper>
                    </Grid>
                )}
                <>
                    <BenefitDecisions
                        classes={classes}
                        benefitAssessmentDetails={benefitAssessmentDetails}
                    />
                </>
            </Paper>
        </React.Fragment>
    );

    function isRegistryEventCaptured() {
        return registry !== 'Yes' || noCauseOfClaimDetails || notification === null;
    }

    function isRegistry(registry: string) {
        if (registry === 'Yes') {
            return true;
        } else {
            return false;
        }
    }

    function checkRegistryEventCaptured(registryStatus?: boolean) {
        if (registryStatus) {
            return <CheckCircle style={{ color: '#36b37e', marginLeft: '8px' }} />;
        } else {
            return <Warning style={{ color: '#ff6c35', marginLeft: '8px' }} />;
        }
    }
    function addDecision() {
        openAddModal({
            modalContent: (
                <AssessmentDecisionAddView
                    {...rest}
                    {...benefitAssessmentDetails}
                    assessmentDescription={getAssessmentDescription()}
                    automaticBenefitIncrease={automaticBenefitIncrease}
                    blankRecord={{
                        assessmentDate: new Date(),
                        assessmentType: getAssessmentDescription(),
                        assessor: currentUser.name,
                        benefitCode: benefitAssessmentDetails.benefitCode,
                        benefitName: benefitAssessmentDetails.benefitName,
                        claimType: undefined,
                        final: true,
                        id: uuid(),
                        policyNumber: policyNumber,
                        opinions: [],
                        summaries: [],
                    }}
                    noPartialPayment={shouldDisableAmountPayable()}
                    policyNumber={policyNumber}
                />
            ),
            modalData: {},
        });
    }

    function switchOn() {
        return false;
    }

    function getAssessmentDescription() {
        if (
            decisionsPresent(benefitAssessmentDetails) &&
            benefitAssessmentDetails.decisions.filter(d => !!d.final).length > 0
        ) {
            return 'Reassessment';
        }
        return 'Assessment';
    }

    function liability() {
        raiseLiability({
            policyNumber: policyNumber,
            benefitCode: benefitAssessmentDetails.benefitCode,
        });
    }

    function showRaiseLiability() {
        return (
            policyNumber &&
            benefitAssessmentDetails &&
            benefitAssessmentDetails.benefitCode &&
            benefitAssessmentDetails.decisions.findIndex(d => d.final === true) !== -1 &&
            !(
                benefitAssessmentDetails.claimLiability &&
                benefitAssessmentDetails.claimLiability.status === 'Successful' &&
                benefitAssessmentDetails.decisions.some(
                    d =>
                        d.final === true &&
                        d.id ===
                            (benefitAssessmentDetails.claimLiability &&
                                benefitAssessmentDetails.claimLiability.id) &&
                        benefitAssessmentDetails.decisions.some(
                            d => d.assessmentType === 'Reassessment'
                        ) &&
                        d.assessmentType === 'Reassessment'
                )
            )
        );
    }

    function shouldDisableAmountPayable() {
        return ['20', '21', '52', '53', '54', '58', '75', '77', '78', '111', '112'].includes(
            benefitAssessmentDetails.benefitCode
        );
    }
};

interface BenefitDecisionsProps extends StyledComponent {
    benefitAssessmentDetails: BenefitAssessmentDetails;
    automaticBenefitIncrease?: number;
}
const BenefitDecisions: React.FunctionComponent<BenefitDecisionsProps> = ({
    automaticBenefitIncrease,
    benefitAssessmentDetails,
    classes,
    ...rest
}: BenefitDecisionsProps) => {
    if (!decisionsPresent(benefitAssessmentDetails)) {
        return null;
    }

    let finalDecision = false;
    let isReassessment = false;

    return (
        <React.Fragment>
            {benefitAssessmentDetails.decisions.map((assessmentDecision, assessmentIndex) => {
                if (finalDecision) {
                    isReassessment = true;
                }
                if (!!assessmentDecision.final) {
                    finalDecision = true;
                }
                return (
                    <React.Fragment key={assessmentDecision.id}>
                        <AssessmentDecisionView
                            {...rest}
                            benefitName={benefitAssessmentDetails.benefitName}
                            sumAssured={benefitAssessmentDetails.sumAssured}
                            assessmentDecision={assessmentDecision}
                            reassessment={isReassessment}
                            automaticBenefitIncrease={automaticBenefitIncrease}
                            commencementDate={benefitAssessmentDetails.commencementDate}
                        />
                        {assessmentIndex !== benefitAssessmentDetails.decisions.length - 1 && (
                            <Divider className={classes.topAndBottomMargin} />
                        )}
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

function decisionsPresent(benefitAssessmentDetails: BenefitAssessmentDetails) {
    return benefitAssessmentDetails.decisions && benefitAssessmentDetails.decisions.length > 0;
}

const mapStateToProps = (state: State) => ({
    causeOfClaim: getClaimDetails(state).currentCauseOfClaim,
    currentUser: state.auth as AuthState,
    dateOfEvent: getClaimDetails(state).dateOfEvent,
    isReadOnly: state.claimSession.readOnly,
    lastDayAtWork: getDisabilityDetails(state).lastDayAtWork,
    noCauseOfClaimDetails: noCauseOfClaimDetails(state),
    returnToWork: getDisabilityDetails(state).returnToWork,
    notification: getLifeAssuredDetailsFromClaim(state.claimSession.claim).claimLifeNotifications,
    registry: getLifeAssuredDetailsFromClaim(state.claimSession.claim)
        .claimLifeNotificationsUpdated,
});

const mapDispatchToProps = {
    openAddModal: openAddModalAction,
    openModal: openModalAction,
    raiseLiability: raiseLiabilityAction,
    openDialog: openDialogAction,
    updateAssessment: updateBenefitAssessmentAction,
    updateBenefitAssessmentAddSummaryOpinion: updateBenefitAssessmentAddSummaryOpinionAction,
};

export const BenefitAssessmentDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(benefitAssessmentDetailsView));
