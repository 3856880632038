import { Button, Divider, Grid, Typography, withStyles } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import { FormApi } from 'final-form';
import React from 'react'; // eslint-disable-line
import { FormSpy } from 'react-final-form';
import { connect } from 'react-redux';
import {
    BindFieldToField,
    FormErrors,
    FullWidthDatePicker,
    FullWidthSearchSelectField,
    FullWidthTextField,
    GenericForm,
} from '../../../../../forms';
import { FirstNameMask, LastNameMask } from '../../../../../forms/input-masks';
import { requiredValidator } from '../../../../../forms/validations';
import { State } from '../../../../../redux/root-reducer';
import { defaultDateOfBirth } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { OnSubmitSuccessProps } from '../../../../../workflow-navigation';
import {
    getNoticeOfDeath,
    NoticeOfDeath,
} from '../../../common/notice-of-death/notice-of-death-reducer';
import { FormDetails, getFormDetails } from '../../form-details/form-details-reducer';
import {
    UpdateDeathCertificate,
    updateDeathCertificateAction,
} from '../actions/update-death-certificate';
import { DeathCertificate, getDeathCertificate } from '../death-certification-reducer';

interface Props extends OnSubmitSuccessProps, StyledComponent {
    readonly deathCertificate: DeathCertificate;
    readonly formDetails: FormDetails;
    readonly noticeOfDeath: NoticeOfDeath;
    readonly updateDeathCertificate: UpdateDeathCertificate;
}

const DeathCertificateForm = GenericForm<DeathCertificate>();
const deathCertificateModal: React.FunctionComponent<Props> = ({
    classes,
    deathCertificate,
    formDetails,
    noticeOfDeath,
    updateDeathCertificate,
    ...rest
}: Props) => {
    return (
        <DeathCertificateForm
            onSubmit={updateDeathCertificate}
            initialValues={deathCertificate}
            {...rest}
        >
            <InnerForm {...{ classes, formDetails, noticeOfDeath }} />
            <FormErrors />
        </DeathCertificateForm>
    );
};

type InnerFormProps = StyledComponent & {
    formDetails: FormDetails;
    noticeOfDeath: NoticeOfDeath;
};
const InnerForm: React.FunctionComponent<InnerFormProps> = ({
    formDetails,
    noticeOfDeath,
    classes,
}) => {
    return FormSpy<DeathCertificate>({
        render: ({ form }) => deathCertificateForm({ classes, form, formDetails, noticeOfDeath }),
    });
};

type InnerProps = InnerFormProps & {
    form: FormApi<DeathCertificate>;
};
const deathCertificateForm: React.FunctionComponent<InnerProps> = ({
    classes,
    form,
    formDetails,
    noticeOfDeath,
}: InnerProps) => {
    function populateFromFormDetails() {
        if (!!form) {
            const detailsOfDeath = getDetailsOfDeath(formDetails);
            form.batch(() => {
                form.change('firstName', formDetails.deceasedDetails.firstName);
                form.change('secondName', formDetails.deceasedDetails.secondName);
                form.change('lastName', formDetails.deceasedDetails.lastName);
                form.change('idNumber', formDetails.deceasedDetails.idNumber);
                form.change('dateOfBirth', formDetails.deceasedDetails.dateOfBirth);
                form.change('dateOfDeath', detailsOfDeath.dateOfDeath);
                form.change('placeOfDeath', detailsOfDeath.placeOfDeath);
                form.change('causeOfDeath', getCauseOfDeath(detailsOfDeath.typeOfDeath));
            });
        }
    }

    function populateFromNoticeOfDeath() {
        if (!!form) {
            const deceased = noticeOfDeath.deceased || {};
            const personalDetails = deceased.personalDetails || {};
            form.batch(() => {
                form.change('firstName', personalDetails.firstName);
                form.change('secondName', personalDetails.secondName);
                form.change('lastName', personalDetails.lastName);
                form.change('idNumber', personalDetails.idNumber);
                form.change('dateOfBirth', personalDetails.dateOfBirth);
                form.change('dateOfDeath', deceased.dateOfDeath);
                form.change('placeOfDeath', deceased.placeOfDeath);
                form.change('causeOfDeath', deceased.causeOfDeath);
            });
        }
    }

    return (
        <Grid container spacing={5} justify="flex-start">
            <Grid item xs={12}>
                <Typography variant="h5">Update Death Certificate Details</Typography>
                <Divider style={{ marginTop: '8px' }} />
            </Grid>
            <Grid item xs={12} container justify="flex-end">
                <Button onClick={populateFromFormDetails} className={classes.copyButton}>
                    <FileCopy className={classes.copyIcon} />
                    Copy from form details
                </Button>
                <Button onClick={populateFromNoticeOfDeath} className={classes.copyButton}>
                    <FileCopy className={classes.copyIcon} />
                    Copy from notice of death
                </Button>
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField name="serialNumber" validate={requiredValidator} />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name="firstName"
                    mask={FirstNameMask}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField name="secondName" mask={FirstNameMask} />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name="lastName"
                    label="Surname"
                    mask={LastNameMask}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField name="idNumber" validate={requiredValidator} />
            </Grid>
            <Grid item xs={6}>
                <FullWidthDatePicker
                    name="dateOfBirth"
                    validate={requiredValidator}
                    disableFuture
                />
                <BindFieldToField
                    field="dateOfBirth"
                    toField="idNumber"
                    transform={defaultDateOfBirth}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthDatePicker
                    name="dateOfDeath"
                    validate={requiredValidator}
                    disableFuture
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField name="placeOfDeath" validate={requiredValidator} />
            </Grid>
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    name="causeOfDeath"
                    options={[
                        { value: 'Natural Causes', label: 'Natural Causes' },
                        { value: 'Unnatural Causes', label: 'Unnatural Causes' },
                        { value: 'Under Investigation', label: 'Under Investigation' },
                    ]}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthDatePicker name="issueDate" validate={requiredValidator} disableFuture />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField name="issuedBy" validate={requiredValidator} />
            </Grid>
        </Grid>
    );
};

function getDetailsOfDeath(formDetails: FormDetails) {
    if (formDetails.deceasedDetails.detailsOfDeath) {
        return formDetails.deceasedDetails.detailsOfDeath;
    }
    return {};
}

function getCauseOfDeath(causeOfDeath?: string) {
    switch (causeOfDeath) {
        case 'Natural':
            return 'Natural Causes';
        case 'Unnatural':
            return 'Unnatural Causes';
        default:
            return undefined;
    }
}

const mapStateToProps = (state: State) => ({
    deathCertificate: getDeathCertificate(state),
    formDetails: getFormDetails(state),
    noticeOfDeath: getNoticeOfDeath(state),
});

const mapDispatchToProps = {
    updateDeathCertificate: updateDeathCertificateAction,
};

export const DeathCertificateModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(deathCertificateModal));
