import React from 'react'; // eslint-disable-line
import { Grid, withStyles, Button, Tooltip, Typography, Paper } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { connect } from 'react-redux';
import { LabeledValue } from '../../../../controls/labeled-value';
import { OpenModal, openModalAction } from '../../../../form-modal/actions';
import { FormModalView } from '../../../../form-modal/views/form-modal';
import styles, { StyledComponent } from '../../../../styles/styles';
import { State } from '../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../shared-functions';
import {
    DoctorIndunaDetails,
    getNoticeOfDeath,
} from '../../common/notice-of-death/notice-of-death-reducer';
import { EditDoctorIndunaDetails } from '../../common/notice-of-death/views';
import { NoticeOfDeathForm } from './notice-of-death-edit-form';
import { DisplayAddress } from '../../../../forms/sub-forms/address-details';
import { ButtonWrapper } from '../../../../forms';

type Props = StyledComponent & {
    openModal: OpenModal;
    doctorIndunaDetails: DoctorIndunaDetails;
    isReadOnly: boolean;
};
const doctorIndunaView: React.FunctionComponent<Props> = ({
    classes,
    doctorIndunaDetails,
    openModal,
    isReadOnly,
}: Props) => {
    const edit = () => {
        openModal({
            modalContent: (
                <FormModalView
                    formView={() => (
                        <NoticeOfDeathForm
                            title="Induna / Doctor Details"
                            view={<EditDoctorIndunaDetails isDeath />}
                        />
                    )}
                />
            ),
        });
    };
    return (
        <React.Fragment>
            <Grid container justify="flex-start" style={{ marginTop: '4px' }}>
                <Grid item xs={11}>
                    <Typography style={{ marginTop: '8px' }} variant="subtitle2">
                        Induna / Doctor Details
                    </Typography>
                </Grid>
                <ButtonWrapper>
                    <Grid item xs={1} container justify="flex-end">
                        <Tooltip title="Edit Induna's / Doctor's Details">
                            <Button id="EditIndunaDoctorDetails" color="secondary" onClick={edit}>
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            </Grid>
            <Paper className={classes.paper}>
                <Grid container justify="flex-start">
                    {doctorIndunaDetails.doctorInduna === 'Doctor' && (
                        <Grid item xs={6} lg={3}>
                            <LabeledValue
                                label="SAMDC/SANC Registration number"
                                value={doctorIndunaDetails.registrationNumber}
                            />
                        </Grid>
                    )}
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="First Name" value={doctorIndunaDetails.firstName} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Second Name" value={doctorIndunaDetails.secondName} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Surname" value={doctorIndunaDetails.lastName} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Contact number"
                            value={doctorIndunaDetails.telephoneNumber}
                        />
                    </Grid>
                    {doctorIndunaDetails.addressDetails && (
                        <Grid item xs={6} lg={3}>
                            <DisplayAddress
                                label={`${
                                    doctorIndunaDetails.doctorInduna === 'Doctor' ? 'Practice ' : ''
                                }Address Details`}
                                address={doctorIndunaDetails.addressDetails}
                            />
                        </Grid>
                    )}
                    {doctorIndunaDetails.doctorInduna === 'Doctor' && (
                        <React.Fragment>
                            <Grid item xs={6} lg={3}>
                                <LabeledValue
                                    label="Verify Doctor"
                                    value={doctorIndunaDetails.verifyDoctor}
                                />
                            </Grid>
                            <Grid item xs={6} lg={3}>
                                <LabeledValue
                                    label="Date Doctor Signed"
                                    value={getDisplayDate(doctorIndunaDetails.dateDoctorSigned)}
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

const mapStateToProps = (state: State) => ({
    doctorIndunaDetails: getNoticeOfDeath(state).doctorIndunaDetails || {},
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = { openModal: openModalAction };

export const DoctorIndunaDetailView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(doctorIndunaView));
