import React from 'react'; // eslint-disable-line

import { Grid, Icon, Typography, withStyles } from '@material-ui/core';
import { capitalCase } from 'change-case';
import { formatCurrency, getCodeDescription, getDisplayDate } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import { AssessmentDecision, ClaimLiability } from '../../assessment-details-reducer';
import { LabeledValue } from '../../../../controls/labeled-value';

interface OwnProps {
    benefitName?: string;
    assessmentDecision: AssessmentDecision;
    claimLiability?: ClaimLiability;
}

type Props = OwnProps & StyledComponent;
const funeralAssessmentDecisionView: React.FunctionComponent<Props> = ({
    benefitName,
    claimLiability,
    assessmentDecision,
}: Props) => {
    return (
        <Grid container spacing={2}>
            <Grid item container xs={12} direction="row" alignItems="center">
                {benefitName ? (
                    <Typography variant="subtitle2">
                        {assessmentDecision.assessmentType} for Benefit - {capitalCase(benefitName)}
                        &nbsp;&nbsp;
                    </Typography>
                ) : (
                    <Typography variant="subtitle2">
                        {assessmentDecision.assessmentType}&nbsp;&nbsp;
                    </Typography>
                )}
                {assessmentDecision.final && (
                    <React.Fragment>
                        <DecisionIcon decision={assessmentDecision.decision} />
                        <Typography variant="subtitle1">
                            &nbsp;&nbsp;
                            {getCodeDescription('decision', assessmentDecision.decision)}
                        </Typography>
                    </React.Fragment>
                )}
            </Grid>
            {assessmentDecision.final && (
                <React.Fragment>
                    {assessmentDecision.claimType && (
                        <Grid item xs={3}>
                            <LabeledValue label="Claim Type" value={assessmentDecision.claimType} />
                        </Grid>
                    )}
                    {claimLiability && (
                        <React.Fragment>
                            <Grid item xs={3}>
                                <LabeledValue
                                    label="Quote Amount"
                                    value={formatCurrency(claimLiability.quoteAmount)}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <LabeledValue
                                    label="Claim Amount"
                                    value={formatCurrency(claimLiability.claimAmount)}
                                />
                            </Grid>
                            {benefitName ? (
                                <Grid item xs={3}>
                                    <LabeledValue
                                        label="Account Balance"
                                        value={formatCurrency(claimLiability.accountBalance)}
                                    />
                                </Grid>
                            ) : (
                                <React.Fragment>
                                    <Grid item xs={3}>
                                        <LabeledValue
                                            label="Overpayment Amount"
                                            value={formatCurrency(claimLiability.overpaymentAmount)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <LabeledValue
                                            label="Money in Suspense"
                                            value={formatCurrency(claimLiability.moneyInSuspense)}
                                        />
                                    </Grid>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                    {assessmentDecision.decision === 'Approve' && (
                        <React.Fragment>
                            {assessmentDecision.amountPayable && (
                                <Grid item xs={3}>
                                    <LabeledValue
                                        label="Amount Payable"
                                        value={formatCurrency(assessmentDecision.amountPayable)}
                                    />
                                </Grid>
                            )}
                            {assessmentDecision.effectiveDate && (
                                <Grid item xs={3}>
                                    <LabeledValue
                                        label="Effective Date"
                                        value={getDisplayDate(assessmentDecision.effectiveDate)}
                                    />
                                </Grid>
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
            <Grid item xs={3}>
                <LabeledValue label="Assessor" value={assessmentDecision.assessor} />
            </Grid>
            <Grid item xs={3}>
                <LabeledValue
                    label="Assessment Date"
                    value={getDisplayDate(assessmentDecision.assessmentDate)}
                />
            </Grid>
            <Grid item xs={12}>
                <LabeledValue label="Summary" value={assessmentDecision.summary} isUnsafe />
            </Grid>
        </Grid>
    );
};

interface DecisionIconProps {
    decision?: string;
}

const DecisionIcon: React.FunctionComponent<DecisionIconProps> = ({
    decision,
}: DecisionIconProps) => {
    switch (decision) {
        case 'Approve':
            return (
                <Icon style={{ color: 'green' }} fontSize="small">
                    check_circle
                </Icon>
            );
        default:
            return (
                <Icon
                    style={{
                        color: 'white',
                        backgroundColor: 'red',
                        borderRadius: '50%',
                        fontSize: 17,
                    }}
                    fontSize="small"
                >
                    close_circle
                </Icon>
            );
    }
};

export const FuneralAssessmentDecisionView = withStyles(styles)(funeralAssessmentDecisionView);
