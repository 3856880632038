import { Grid, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { AuthState } from '../../../../auth/auth-reducer';
import { getClaim } from '../../../../claim';
import { PayeeTypeEnum } from '../../../../codes/payee-types';
import { PaymentType } from '../../../../codes/payment-types';
import {
    FormHeading,
    FullWidthSearchSelectField,
    FullWidthTextField,
    WhenFieldChanges,
} from '../../../../forms';
import { CurrencyMask, NumberMask } from '../../../../forms/input-masks';
import { composeValidators, maxValue, requiredValidator } from '../../../../forms/validations';
import { State } from '../../../../redux/root-reducer';
import { convertToNumber } from '../../../../shared-functions';
import { BankingDetailsDisplay } from '../../../../shared/banking-details-display';
import styles, { StyledComponent } from '../../../../styles/styles';
import {
    BeneficiaryDetails,
    ClaimDecision,
    getBeneficiaryEditName,
    getDecisions,
    getPaymentBeneficiaries,
} from '../../../reducer';
import { ClaimReference } from '../../../../claim/reducers/claim-reference-reducer';

interface Props extends StyledComponent {
    beneficiaries: BeneficiaryDetails[];
    beneficiaryId: string;
    currentUser: AuthState;
    decisions: ClaimDecision[];
    edit?: boolean;
    claimReferences?: ClaimReference;
}

const paymentItemView = ({
    beneficiaries,
    beneficiaryId,
    currentUser,
    decisions,
    edit,
    claimReferences,
}: Props) => {
    const beneficiary = beneficiaries.find(b => b.beneficiaryId === beneficiaryId);
    if (!beneficiary) {
        return null;
    }

    return (
        <React.Fragment>
            <FormHeading
                text={`${edit ? 'Edit' : 'Add'} Payment - ${getBeneficiaryEditName(beneficiary)}`}
            />
            <WhenFieldChanges
                field="decisionId"
                set=""
                to={(decisionId: string) => {
                    const decision = decisions.find(d => d.decisionId === decisionId);
                    return {
                        ...decision,
                        assessor: currentUser.name,
                        payeeType: PayeeTypeEnum.Beneficiary,
                        paymentType: PaymentType.Claim,
                    };
                }}
            />
            <Grid container spacing={5} style={{ marginBottom: '120px' }}>
                <Grid item xs={6} lg={6}>
                    <FullWidthSearchSelectField
                        name="decisionId"
                        label="Policy"
                        validate={requiredValidator}
                        options={filterDecisions(decisions)}
                        disabled={edit}
                    />
                </Grid>
                <Field
                    name="."
                    subscription={{ value: true }}
                    component={({ input: { value: values } }) => {
                        setMaximumAmountPayable(beneficiary, values);
                        return null;
                    }}
                />
                <Grid item xs={6} lg={6}>
                    <FullWidthTextField
                        name="assessor"
                        label="Processed By"
                        defaultValue={currentUser.name}
                        disabled
                    />
                </Grid>
                <Grid item xs={6} lg={6}>
                    <Field
                        name="maxValue"
                        subscription={{ value: true }}
                        component={({ input: { value: amountPayable } }) => (
                            <FullWidthTextField
                                name="amountPayable"
                                mask={CurrencyMask}
                                parse={convertToNumber}
                                validate={composeValidators(
                                    requiredValidator,
                                    maxValue(amountPayable, 'maximum amount payable')
                                )}
                            />
                        )}
                    />
                </Grid>
                <Field
                    name="."
                    subscription={{ value: true }}
                    component={({ input: { value: values } }) => {
                        if (values) {
                            let split = ((values.amountPayable || 0) / values.sumAssured) * 100;
                            if (isNaN(Number(split))) {
                                split = 0;
                            }
                            values.split = split.toFixed(2);
                        }
                        return null;
                    }}
                />
                <Grid item xs={6} lg={6}>
                    <FullWidthTextField name="split" validate={requiredValidator} disabled />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField name="maxValue" label="Maximum amount payable" disabled />
                </Grid>
                {!claimReferences && (
                    <Grid item xs={6}>
                        <FullWidthTextField
                            name="caseNumber"
                            label="Case Number"
                            mask={NumberMask}
                            validate={requiredValidator}
                        />
                    </Grid>
                )}
                <BankingDetailsDisplay bankingDetails={beneficiary.bankingDetails} />
            </Grid>
        </React.Fragment>
    );
};

function filterDecisions(decisions: ClaimDecision[]) {
    const filteredDecisions: any = [];
    decisions.forEach(d => {
        filteredDecisions.push({
            value: d.decisionId,
            label: d.decisionLabel,
        });
    });
    return filteredDecisions;
}

function setMaximumAmountPayable(beneficiary: BeneficiaryDetails, values: any) {
    const existingPayments =
        (beneficiary.existingPayments &&
            beneficiary.existingPayments.filter(
                p => p.decisionId === values.decisionId && p.id !== values.id
            )) ||
        [];
    const totalPaymentAmount = existingPayments.map(p => p.amount).reduce((a, b) => a + b, 0);
    const maxAmount = Number((values.sumAssured - totalPaymentAmount).toFixed(2));

    if (
        !isNaN(maxAmount) &&
        maxAmount < values.maximumAmountPayable &&
        !(values.maximumAmountPayable - maxAmount <= 0.01)
    ) {
        values.maxValue = maxAmount.toFixed(2);
    } else {
        values.maxValue = values.maximumAmountPayable;
    }
}

const mapStateToProps = (state: State) => {
    const claim = getClaim(state);

    return {
        beneficiaries: getPaymentBeneficiaries(claim),
        currentUser: state.auth as AuthState,
        decisions: getDecisions(claim),
        claimReference: claim.references,
    };
};

export const PaymentFuneralItemView = connect(
    mapStateToProps,
    {}
)(withStyles(styles)(paymentItemView));
