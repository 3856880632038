import { DeferredContractsAction } from '../reducers/deferred-contracts-reducer';
import { SET_DEFERRED_CONTRACTS } from '../types';

export type SetDeferredContracts = (hasDeferredContracts?: boolean) => DeferredContractsAction;
export const setDeferredContractsAction: SetDeferredContracts = (
    hasDeferredContracts?: boolean
) => {
    return {
        type: SET_DEFERRED_CONTRACTS,
        payload: hasDeferredContracts,
    };
};
