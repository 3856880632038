import { Grid, Paper, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { Code } from '../../../../codes/codes';
import { NaturalRelationships } from '../../../../codes/relationship-types';
import {
    Condition,
    False,
    FormHeading,
    FullWidthDatePicker,
    FullWidthSearchSelectField,
    FullWidthTextField,
    True,
} from '../../../../forms';
import { requiredValidator } from '../../../../forms/validations';
import styles, { StyledComponent } from '../../../../styles/styles';

interface Props extends StyledComponent {
    claimants: Code<string>[];
}
const beneficiaryDetailsView: React.FunctionComponent<Props> = ({ classes, claimants }: Props) => {
    return (
        <Paper className={classes.paper}>
            <FormHeading text="Beneficiary Details" />
            <Grid container spacing={5} justify="flex-start">
                <Condition when="beneficiaryType" is="Estate">
                    <True>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name="personalDetails.firstName"
                                label="Name"
                                disabled
                            />
                        </Grid>
                    </True>
                    <False>
                        <Grid item xs={6}>
                            <FullWidthTextField name="personalDetails.firstName" disabled />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthTextField name="personalDetails.lastName" disabled />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthTextField name="personalDetails.idNumber" disabled />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthDatePicker name="personalDetails.dateOfBirth" disabled />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthSearchSelectField
                                name="personalDetails.relationshipToLifeAssured"
                                label="Relationship to owner"
                                options={NaturalRelationships}
                                disabled
                            />
                        </Grid>
                        <Condition when="personalDetails.relationshipToLifeAssured" is="Other">
                            <True>
                                <Grid item xs={6}>
                                    <FullWidthTextField
                                        name="personalDetails.relationshipToLifeAssuredDescription"
                                        disabled
                                    />
                                </Grid>
                            </True>
                        </Condition>
                    </False>
                </Condition>
                <Grid item xs={6}>
                    <FullWidthTextField name="percentageSplit" label="% split" disabled />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthSearchSelectField
                        name="linkToClaimant"
                        options={claimants}
                        validate={requiredValidator}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export const BeneficiaryDetailsView = withStyles(styles)(beneficiaryDetailsView);
