import React, { PropsWithChildren, ReactElement } from 'react'; // eslint-disable-line

import LuxonUtils from '@date-io/luxon';
import { MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { AnyAction, Store } from 'redux';
import theme from './styles/theme';

interface Props {
    readonly store: Store<any, AnyAction>;
}

export const AppWrapper: React.FunctionComponent<Props> = ({
    children,
    store,
}: PropsWithChildren<Props>): ReactElement => {
    return (
        <AppContainer>
            <Provider store={store}>
                <MuiThemeProvider theme={theme}>
                    <MuiPickersUtilsProvider utils={LuxonUtils}>{children}</MuiPickersUtilsProvider>
                </MuiThemeProvider>
            </Provider>
        </AppContainer>
    );
};
