import React from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import { AddressDetails, DisplayAddress } from '../../../../../forms/sub-forms/address-details';
import { CommunicationDetails } from '../../../../../forms/sub-forms/communication-details';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { LabeledValue } from '../../../../../controls/labeled-value';

type Props = StyledComponent & {
    addressDetails?: AddressDetails;
    contactDetails: CommunicationDetails;
    isOrganization?: boolean;
};
const communicationDetails: React.FunctionComponent<Props> = ({
    addressDetails,
    contactDetails,
    isOrganization,
}: Props) => {
    const orgPrefix = isOrganization ? 'Company ' : '';
    return (
        <React.Fragment>
            {getTelephone(contactDetails.telephoneNumber, orgPrefix)}
            {getCellphone(contactDetails.cellphoneNumber, orgPrefix)}
            {getEmail(contactDetails.emailAddress, orgPrefix)}
            {getAddress(addressDetails, orgPrefix)}
        </React.Fragment>
    );
};

function getAddress(addressDetails?: AddressDetails, orgPrefix?: string) {
    if (!addressDetails || !addressDetails.addressLine1) {
        return null;
    }

    return (
        <Grid item xs={6} lg={3}>
            <DisplayAddress label={`${orgPrefix}Address`} address={addressDetails} />
        </Grid>
    );
}

function getTelephone(telephone?: string, orgPrefix?: string) {
    if (!telephone) {
        return null;
    }

    return (
        <Grid item xs={6} lg={3}>
            <LabeledValue label={`${orgPrefix}Telephone Number`} value={telephone} />
        </Grid>
    );
}

function getCellphone(cellphone?: string, orgPrefix?: string) {
    if (!cellphone) {
        return null;
    }

    return (
        <Grid item xs={6} lg={3}>
            <LabeledValue label={`${orgPrefix}Cellphone Number`} value={cellphone} />
        </Grid>
    );
}

function getEmail(email?: string, orgPrefix?: string) {
    if (!email) {
        return null;
    }

    return (
        <Grid item xs={6} lg={3}>
            <LabeledValue label={`${orgPrefix}Email Address`} value={email} />
        </Grid>
    );
}

export const CommunicationDetailsView = withStyles(styles)(communicationDetails);
