import { Grid, Typography } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { LabeledValue } from '../../../../../controls/labeled-value';
import { formatCurrency, formatPercent } from '../../../../../shared-functions';
import { InformationRecord } from '../reducer';

interface Props {
    informationRecord: InformationRecord;
}
export const ProvenHighestSalary = ({ informationRecord }: Props) => {
    if (informationRecord.documentName !== 'Salary Slip') {
        return null;
    }
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography variant="subtitle2">Proven Highest Salary</Typography>
            </Grid>
            <Grid item xs={4}>
                <LabeledValue
                    label="Basic Salary"
                    value={formatCurrency(informationRecord.basicSalary)}
                />
            </Grid>
            <Grid item xs={4}>
                <LabeledValue label="PAYE" value={formatCurrency(informationRecord.paye)} />
            </Grid>
            <Grid item xs={4}>
                <LabeledValue
                    label="Income After Tax"
                    value={formatCurrency(informationRecord.incomeAfterTax)}
                />
            </Grid>
            <Grid item xs={4}>
                <LabeledValue
                    label="Claim Percentage"
                    value={formatPercent(informationRecord.claimPercentage)}
                />
            </Grid>
            <Grid item xs={4}>
                <LabeledValue
                    label="Claim Amount"
                    value={formatCurrency(informationRecord.claimAmount)}
                />
            </Grid>
        </React.Fragment>
    );
};
