import { Button, Tooltip, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { OpenModal, openModalAction } from '../../../../../form-modal/actions';
import { FormModalView } from '../../../../../form-modal/views/form-modal';
import { GenericForm } from '../../../../../forms';
import { State } from '../../../../../redux/root-reducer';
import { Nullable } from '../../../../../shared-types';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { OnSubmitSuccessProps } from '../../../../../workflow-navigation';
import { RequiredDocument } from '../../../common/requirements-management/reducer';
import { RequiredDocumentsEditView } from '../../../common/requirements-management/views';
import { UpdateRequirements, updateRequirementsAction } from '../actions/update-requirements';
import { DeathRequirements, getDocumentsRequirements } from '../reducer';

interface Props extends OnSubmitSuccessProps, StyledComponent {
    deathRequirements: Nullable<DeathRequirements>;
    documents: RequiredDocument[];
    name?: string;
    prefix?: string;
    updateRequirements: UpdateRequirements;
}
const RequirementsEditForm = GenericForm<DeathRequirements>();
const mapStateToProps = (state: State) => ({
    deathRequirements: getDocumentsRequirements(state),
});

const mapDispatchToProps = {
    updateRequirements: updateRequirementsAction,
};

const RequirementsEditModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withStyles(styles)(
        ({ deathRequirements, documents, name, prefix, updateRequirements, ...rest }: Props) => {
            return (
                <RequirementsEditForm
                    onSubmit={updateRequirements}
                    initialValues={deathRequirements || {}}
                    {...rest}
                >
                    <RequiredDocumentsEditView
                        documents={documents}
                        isDeath
                        name={name}
                        prefix={prefix || ''}
                    />
                </RequirementsEditForm>
            );
        }
    )
);

interface ButtonProps {
    documents: RequiredDocument[];
    isReadOnly: boolean;
    name: string;
    openModal: OpenModal;
    prefix: string;
}
const mapStateProps = (state: State) => ({ isReadOnly: state.claimSession.readOnly });
export const EditDocumentsButton = connect(mapStateProps, { openModal: openModalAction })(
    ({ documents, isReadOnly, name, openModal, prefix }: ButtonProps) => {
        if (isReadOnly) {
            return null;
        }

        const edit = () => {
            openModal({
                modalContent: (
                    <FormModalView
                        formView={() => (
                            <RequirementsEditModal
                                documents={documents}
                                name={name}
                                prefix={prefix}
                            />
                        )}
                    />
                ),
            });
        };

        return (
            <Tooltip title="Manage requested documents">
                <Button id="EditRequiredDocuments" color="secondary" onClick={edit}>
                    <Edit />
                </Button>
            </Tooltip>
        );
    }
);
