import { withStyles, Grid, Typography, Paper, Tooltip, Button } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import styles, { StyledComponent } from '../../../../../styles/styles';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { Edit } from '@material-ui/icons';
import { connect } from 'react-redux';
import { State } from '../../../../../redux/root-reducer';
import { FormDetails, getFormDetails } from '../form-details-reducer';
import { OpenModal, openModalAction } from '../../../../../form-modal/actions';
import { editLifeAssureDetails } from './life-assured-edit';
import { editPolicyHolderDetails } from './policy-holder-edit';
import { UpdateFormDetails, updateFormDetailsAction } from '../actions/update-form-details';

interface BaseProps {
    formDetails?: FormDetails;
    updateFormDetails?: UpdateFormDetails;
    disableEditButton?: boolean;
    type: string;
}
interface Props extends BaseProps, StyledComponent {
    readonly openModal: OpenModal;
}

const addressDetailsView: React.FunctionComponent<Props> = ({
    classes,
    openModal,
    updateFormDetails,
    formDetails,
    type,
    disableEditButton,
}: Props) => {
    const edit = () => {
        type === 'LifeAssured'
            ? editLifeAssureDetails(openModal, formDetails || {}, 1, updateFormDetails)
            : editPolicyHolderDetails(openModal, formDetails || {}, 1, updateFormDetails);
    };

    let addressDetails;
    if (formDetails) {
        addressDetails =
            type === 'LifeAssured'
                ? formDetails.lifeAssured?.addressDetails
                : formDetails.policyHolder?.individual?.addressDetails;
    }

    return (
        <React.Fragment>
            <Grid container justify="space-between" className={classes.marginTop2}>
                <Grid container item xs={10} alignItems="center">
                    <Typography style={{ padding: 10 }} className={classes.heading} variant="body1">
                        Address Details
                    </Typography>
                </Grid>
            </Grid>
            <Paper className={classes.paper}>
                <Grid container item xs={12} alignItems="center" justify="flex-end">
                    <Tooltip title="Edit Address Details">
                        <Button
                            id="EditPersonalDetails"
                            color="secondary"
                            onClick={edit}
                            disabled={!disableEditButton}
                        >
                            <Edit />
                        </Button>
                    </Tooltip>
                </Grid>
                <Grid container justify="flex-start">
                    <Grid item xs={12} container justify="flex-start">
                        <Grid item xs={6} lg={6}>
                            <LabeledValue
                                label="Residential Address"
                                value={`${
                                    !addressDetails || addressDetails?.addressLineOne === null
                                        ? ''
                                        : addressDetails?.addressLineOne
                                } ${'\n'} ${
                                    !addressDetails || addressDetails?.addressLineTwo === null
                                        ? ''
                                        : addressDetails?.addressLineTwo
                                }  ${'\n'} ${
                                    !addressDetails || addressDetails?.addressSuburb === null
                                        ? ''
                                        : addressDetails?.addressSuburb
                                } ${'\n'} ${
                                    !addressDetails || addressDetails?.postalCode === null
                                        ? ''
                                        : addressDetails?.postalCode
                                } ${'\n'} ${
                                    !addressDetails || addressDetails?.country === null
                                        ? ''
                                        : addressDetails?.country
                                }`}
                            />
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <LabeledValue
                                label="Postal Address"
                                value={
                                    addressDetails?.sameAsResidentialAddress
                                        ? 'Same as residential'
                                        : `${
                                              !addressDetails ||
                                              addressDetails?.postalAddressLineOne === null
                                                  ? ''
                                                  : addressDetails?.postalAddressLineOne
                                          } ${'\n'} ${
                                              !addressDetails ||
                                              addressDetails?.postalAddressLineTwo === null
                                                  ? ''
                                                  : addressDetails?.postalAddressLineTwo
                                          } ${'\n'} ${
                                              !addressDetails ||
                                              addressDetails?.postalAddressSuburb === null
                                                  ? ''
                                                  : addressDetails?.postalAddressSuburb
                                          }  ${'\n'} ${
                                              !addressDetails ||
                                              addressDetails?.postalPostalCode === null
                                                  ? ''
                                                  : addressDetails?.postalPostalCode
                                          }  ${'\n'} ${
                                              !addressDetails || addressDetails?.country === null
                                                  ? ''
                                                  : addressDetails?.country
                                          }`
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

const mapStateToProps = (state: State, ownProps: BaseProps) => ({
    formDetails: ownProps.formDetails || getFormDetails(state),
    disableEditButton: ownProps.disableEditButton || false,
});

const mapDispatchToProps = {
    openModal: openModalAction,
    updateFormDetails: updateFormDetailsAction,
};

export const AddressDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(addressDetailsView));
