import { Theme } from '@material-ui/core';
import { createStyles, StyleRules } from '@material-ui/core/styles';
import { CSSProperties, WithStyles } from '@material-ui/core/styles/withStyles';
import globalStyles, { paper as globalPaper } from '../styles/styles';

export type StyledComponent = WithStyles<typeof styles>;

export default function styles(theme: Theme): StyleRules {
    return createStyles({
        ...globalStyles(theme),
        modal: modal(),
        modalItem: modalItem(),
        modalPadding: {
            padding: `0px ${theme.spacing(2)}px 0px ${theme.spacing(2)}px`,
        },
        paper: paper(theme),
        activeName: name(),
        activeView: {
            height: '3px',
            backgroundColor: '#4378FD',
            marginBottom: '8px',
            marginTop: '8px',
        },
        heading: { fontSize: '23px', fontWeight: 500, lineHeight: '41px' },
        headingLine: { marginBottom: '8px', marginTop: '4px' },
        inactiveName: inactiveName(),
        inactiveView: {
            height: '2px',
            marginBottom: '8px',
            marginRight: '1px',
            marginTop: '9px',
        },
        moreLine: {
            height: '2px',
            marginBottom: '8px',
            marginRight: '1px',
            marginTop: '-3px',
        },
        moreText: moreText(),
    });
}

function moreText(): CSSProperties {
    return {
        ...inactiveName(),
        fontSize: '14px',
        fontStyle: 'italic',
        marginTop: '12px',
    };
}

function name(): CSSProperties {
    return {
        textAlign: 'center',
        fontWeight: 500,
        height: '24px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    };
}

function inactiveName(): CSSProperties {
    return {
        ...name(),
        cursor: 'pointer',
    };
}

function modal() {
    return {
        outline: 'none',
    };
}

function modalItem() {
    return {
        position: 'absolute' as const,
        top: '50%',
        transform: 'translate(0%, -50%)',
        outline: 'none',
    };
}

function paper(theme: Theme): CSSProperties {
    return {
        ...globalPaper(theme),
        maxHeight: '90vh',
        overflowY: 'auto',
        overflowX: 'hidden',
    };
}
