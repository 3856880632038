import React from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import { LabeledValue } from '../../../../../controls/labeled-value';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { Dependant } from '../reducer';
import {
    getOrganisationTypeSuffixLabel,
    getOrganisationTypeLabel,
} from '../../../../../shared-functions';

interface MinimisedProps extends StyledComponent {
    dependant: Dependant;
}
const dependantViewOther: React.FunctionComponent<MinimisedProps> = ({
    dependant,
}: MinimisedProps) => {
    const personalDetails = dependant.personalDetails || {};

    if (dependant.dependantType === 'Individual') {
        return (
            <React.Fragment>
                <Grid item xs={6} lg={3}>
                    <LabeledValue label="Age" value={personalDetails.age} />
                </Grid>
                <Grid item xs={6} lg={3}>
                    <LabeledValue label="Gender" value={personalDetails.gender} />
                </Grid>
                <Grid item xs={6} lg={3}>
                    <LabeledValue
                        label="Relationship to life assured"
                        value={dependant.relationshipDescription}
                    />
                </Grid>
                <Grid item xs={6} lg={3}>
                    <LabeledValue label="Currently employed" value={dependant.employed} />
                </Grid>
                {dependant.yearsInRelationship && (
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Years in reletionship"
                            value={dependant.yearsInRelationship}
                        />
                    </Grid>
                )}
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                {dependant.organisationDetails && (
                    <React.Fragment>
                        <Grid item xs={6} lg={3}>
                            <LabeledValue
                                label="Name"
                                value={dependant.organisationDetails.companyName}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <LabeledValue
                                label="Registration Number"
                                value={dependant.organisationDetails.entityRegistrationNumber}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <LabeledValue
                                label="Suffix"
                                value={getOrganisationTypeSuffixLabel(
                                    dependant.organisationDetails.organisationSuffix
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <LabeledValue
                                label="Type"
                                value={getOrganisationTypeLabel(
                                    dependant.organisationDetails.organisationType
                                )}
                            />
                        </Grid>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
};

export const DependantViewOther = withStyles(styles)(dependantViewOther);
