import React from 'react'; // eslint-disable-line

import { Grid, Paper, withStyles } from '@material-ui/core';
import { FinalDecisionDetails } from '../../../../retrospective-underwriting-decision/reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { getDisplayDate } from '../../../../shared-functions';
import { LabeledValue } from '../../../../controls/labeled-value';

type Props = StyledComponent & {
    finalDecisionDetails: FinalDecisionDetails;
};
const finalDecisionView: React.FunctionComponent<Props> = ({
    classes,
    finalDecisionDetails,
}: Props) => {
    return (
        <Paper className={classes.paper}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <LabeledValue label="Policy Number" value={finalDecisionDetails.policyNumber} />
                </Grid>
                <Grid item xs={4}>
                    <LabeledValue label="Benefit" value={finalDecisionDetails.benefitName} />
                </Grid>
                <Grid item xs={4}>
                    <LabeledValue
                        label="Decision Date"
                        value={getDisplayDate(finalDecisionDetails.decisionDate)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <LabeledValue
                        label="Retrospective Underwriting Decision"
                        value={finalDecisionDetails.retrospectiveUnderwritingDecision}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export const FinalDecisionView = withStyles(styles)(finalDecisionView);
