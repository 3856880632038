export enum PaymentType {
    Claim = 'Claim',
    Interest = 'Interest',
    Refund = 'Refund',
    Tax = 'Tax',
}

export const PaymentTypes = [
    { value: PaymentType.Tax, label: 'Tax' },
    { value: PaymentType.Interest, label: 'Interest' },
    { value: PaymentType.Refund, label: 'Refund' },
    { value: PaymentType.Claim, label: 'Claim Payment' },
];

export const PaymentTypesDreadDisability = [
    { value: PaymentType.Interest, label: 'Interest' },
    { value: PaymentType.Refund, label: 'Refund' },
    { value: PaymentType.Claim, label: 'Claim Payment' },
];

export enum PaymentStatus {
    Pending = 'Pending',
    Submitted = 'Submitted',
    Paid = 'Paid',
    Failed = 'Failed',
}
