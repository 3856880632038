import React from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import { CountriesOfIssue } from '../../../../../codes/countries-of-issue';
import { Titles } from '../../../../../codes/titles';
import {
    BindFieldToField,
    Condition,
    False,
    FullWidthDatePicker,
    FullWidthRadioGroup,
    FullWidthSearchSelectField,
    FullWidthTextField,
    RadioOption,
    True,
    WhenFieldChanges,
    When,
} from '../../../../../forms';
import {
    FirstNameMask,
    InitialsMask,
    LastNameMask,
    NumberMask,
} from '../../../../../forms/input-masks';
import {
    composeValidators,
    conditionalRsaIdNumberValidator,
    requiredValidator,
    UserInput,
    ValidationResult,
} from '../../../../../forms/validations';
import styles, { StyledComponent } from '../../../../../styles/styles';
import {
    IdDocumentTypes,
    Passports,
    SouthAfricanIds,
} from '../../../../../codes/id-document-types';
import {
    getValueFromObject,
    getGenderFromRsaId,
    getTimeLapsed,
    defaultDateOfBirth,
} from '../../../../../shared-functions';
import { connect } from 'react-redux';
import { State } from '../../../../../redux/root-reducer';
import { getRAClaimDetailsFromClaim } from '../../claim-details/reducer';
import { getClaim } from '../../../../../claim';

interface Props extends StyledComponent {
    readonly propertyPrefix?: string;
    readonly dateOfDeath?: Date;
}

let DateOfDeath = new Date();
const personalityDetailsView: React.FunctionComponent<Props> = ({
    propertyPrefix = '',
    dateOfDeath,
}: Props) => {
    DateOfDeath = dateOfDeath || new Date();
    const fullPropertyPrefix =
        propertyPrefix && propertyPrefix !== '' ? `${propertyPrefix}.` : propertyPrefix;
    return (
        <React.Fragment>
            <Grid item xs={6}>
                <FullWidthSearchSelectField name={`${fullPropertyPrefix}title`} options={Titles} />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${fullPropertyPrefix}initials`}
                    inputProps={{
                        style: { textTransform: 'uppercase' },
                    }}
                    mask={InitialsMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${fullPropertyPrefix}firstName`}
                    label="Name"
                    validate={requiredValidator}
                    mask={FirstNameMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${fullPropertyPrefix}lastName`}
                    label="Surname"
                    validate={requiredValidator}
                    mask={LastNameMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    name={`${fullPropertyPrefix}idType`}
                    options={IdDocumentTypes}
                />
                <WhenFieldChanges
                    field={`${fullPropertyPrefix}idType`}
                    becomes={'RsaPassport'}
                    set={`${fullPropertyPrefix}passportCountryOfIssue`}
                    to="South Africa"
                />
                <WhenFieldChanges
                    field={`${fullPropertyPrefix}idType`}
                    condition={idType => idType !== 'RsaPassport'}
                    set={`${fullPropertyPrefix}passportCountryOfIssue`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${fullPropertyPrefix}idType`}
                    set={`${fullPropertyPrefix}idNumber`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${fullPropertyPrefix}idNumber`}
                    set={`${fullPropertyPrefix}gender`}
                    to={(idNumber: string, gender: string) => getGenderFromRsaId(idNumber)}
                />
                <WhenFieldChanges
                    field={`${fullPropertyPrefix}idType`}
                    set={`${fullPropertyPrefix}passportNumber`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${fullPropertyPrefix}idType`}
                    set={`${fullPropertyPrefix}passportIssueDate`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${fullPropertyPrefix}idType`}
                    set={`${fullPropertyPrefix}passportExpiryDate`}
                    to={null}
                />
            </Grid>
            <Condition when={`${fullPropertyPrefix}idType`} is={Passports}>
                <True>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            name={`${fullPropertyPrefix}passportNumber`}
                            label="Passport Number"
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthDatePicker
                            name={`${fullPropertyPrefix}passportIssueDate`}
                            validate={requiredValidator}
                            maxDateField={`${fullPropertyPrefix}passportExpiryDate`}
                            disableFuture
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthDatePicker
                            name={`${fullPropertyPrefix}passportExpiryDate`}
                            validate={requiredValidator}
                            minDateField={`${fullPropertyPrefix}passportIssueDate`}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Condition when={`${fullPropertyPrefix}idType`} is="RsaPassport">
                            <True>
                                <FullWidthSearchSelectField
                                    name={`${fullPropertyPrefix}passportCountryOfIssue`}
                                    options={CountriesOfIssue}
                                    disabled
                                />
                            </True>
                            <False>
                                <FullWidthSearchSelectField
                                    name={`${fullPropertyPrefix}passportCountryOfIssue`}
                                    validate={requiredValidator}
                                    options={CountriesOfIssue}
                                />
                            </False>
                        </Condition>
                    </Grid>
                </True>
                <False>
                    <Condition
                        when={`${fullPropertyPrefix}idType`}
                        is={['WorkPermit', 'Asylum', 'RsaIdTemporaryId']}
                    >
                        <False>
                            <Grid item xs={6}>
                                <FullWidthTextField
                                    name={`${fullPropertyPrefix}idNumber`}
                                    label="ID Number"
                                    validate={composeValidators(
                                        requiredValidator,
                                        conditionalRsaIdNumberValidator(values =>
                                            isSouthAfricanId(
                                                getValueFromObject(values, propertyPrefix, 'idType')
                                            )
                                        )
                                    )}
                                    mask={NumberMask}
                                />
                            </Grid>
                        </False>
                    </Condition>
                </False>
            </Condition>
            <Condition when={`${fullPropertyPrefix}idType`} matchesChild>
                <When is="ForeignIdBook">
                    <Grid item xs={6}>
                        <FullWidthSearchSelectField
                            name={`${fullPropertyPrefix}idCountryOfIssue`}
                            validate={requiredValidator}
                            options={CountriesOfIssue}
                        />
                    </Grid>
                </When>
                <When is="RsaIdTemporaryId">
                    <Grid item xs={6}>
                        <FullWidthTextField
                            name={`${fullPropertyPrefix}idNumber`}
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthDatePicker
                            name={`${fullPropertyPrefix}idIssueDate`}
                            validate={requiredValidator}
                            maxDateField={`${fullPropertyPrefix}idExpiryDate`}
                            disableFuture
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthDatePicker
                            name={`${fullPropertyPrefix}idExpiryDate`}
                            validate={requiredValidator}
                            minDateField={`${fullPropertyPrefix}idIssueDate`}
                        />
                    </Grid>
                </When>
                <When is="WorkPermit">
                    <Grid item xs={6}>
                        <FullWidthTextField
                            name={`${fullPropertyPrefix}idNumber`}
                            label="Work permit Number"
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthDatePicker
                            name={`${fullPropertyPrefix}idIssueDate`}
                            label="Work permit issue date"
                            validate={requiredValidator}
                            maxDateField={`${fullPropertyPrefix}idExpiryDate`}
                            disableFuture
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthDatePicker
                            name={`${fullPropertyPrefix}idExpiryDate`}
                            label="Work permit expiry date"
                            validate={requiredValidator}
                            minDateField={`${fullPropertyPrefix}idIssueDate`}
                        />
                    </Grid>
                </When>
                <When is="Asylum">
                    <Grid item xs={6}>
                        <FullWidthTextField
                            name={`${fullPropertyPrefix}idNumber`}
                            label="Asylum Number"
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthDatePicker
                            name={`${fullPropertyPrefix}idIssueDate`}
                            label="Asylum issue date"
                            validate={requiredValidator}
                            maxDateField={`${fullPropertyPrefix}idExpiryDate`}
                            disableFuture
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthDatePicker
                            name={`${fullPropertyPrefix}idExpiryDate`}
                            label="Asylum expiry date"
                            validate={requiredValidator}
                            minDateField={`${fullPropertyPrefix}idIssueDate`}
                        />
                    </Grid>
                </When>
            </Condition>
            <Grid item xs={6}>
                <FullWidthDatePicker
                    name={`${fullPropertyPrefix}dateOfBirth`}
                    validate={composeValidators(
                        requiredValidator,
                        dateOfBirthValidator((values: any) => values, propertyPrefix)
                    )}
                    disableFuture
                />
                <Condition when={`${fullPropertyPrefix}dateOfBirth`} hasValue>
                    <False>
                        <BindFieldToField
                            field={`${fullPropertyPrefix}dateOfBirth`}
                            toField={`${fullPropertyPrefix}idNumber`}
                            transform={defaultDateOfBirth}
                        />
                    </False>
                </Condition>
            </Grid>
            {propertyPrefix !== 'curator' && (
                <Grid item xs={6}>
                    <FullWidthTextField
                        name={`${fullPropertyPrefix}age`}
                        label="Age at death of life assured"
                    />
                    <BindFieldToField
                        field={`${fullPropertyPrefix}age`}
                        toField={`${fullPropertyPrefix}dateOfBirth`}
                        transform={defaultAgeAtDateOfDeath}
                    />
                </Grid>
            )}
            <Grid item xs={6}>
                <FullWidthRadioGroup
                    name={`${fullPropertyPrefix}gender`}
                    validate={requiredValidator}
                >
                    <RadioOption value="Male" />
                    <RadioOption value="Female" />
                </FullWidthRadioGroup>
            </Grid>
        </React.Fragment>
    );
};

function isSouthAfricanId(idType: string) {
    return SouthAfricanIds.includes(idType);
}

function defaultAgeAtDateOfDeath(dateOfBirth: Date) {
    return getTimeLapsed(DateOfDeath, dateOfBirth);
}

const dateOfBirthValidator = (values: any, section: string) => {
    return function validate(value: UserInput, values: any): ValidationResult {
        if (value && values) {
            if (section === 'personalDetails') {
                if (values.relationship === 'MinorChild' && values.personalDetails.age >= 18) {
                    return 'Minor child must be younger than 18';
                }
                if (values.relationship !== 'MinorChild' && values.personalDetails.age < 18) {
                    return 'Dependant must be older than 18';
                }
            }
        }
        return undefined;
    };
};

const mapStateToProps = (state: State) => ({
    dateOfDeath: getRAClaimDetailsFromClaim(getClaim(state)).claimEventDetails.dateOfEvent,
});

export const PersonalDetailsView = connect(
    mapStateToProps,
    null
)(withStyles(styles)(personalityDetailsView));
