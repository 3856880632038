import { Theme } from '@material-ui/core';
import { createStyles, StyleRules } from '@material-ui/core/styles';
import { CSSProperties, WithStyles } from '@material-ui/core/styles/withStyles';

export type StyledComponent = WithStyles<typeof styles>;

export default function styles(theme: Theme): StyleRules {
    return createStyles({
        copyButton: {
            color: theme.palette.secondary.main,
            textTransform: 'none',
            fontStyle: 'italic',
            padding: '0px auto 0px auto',
        },
        copyIcon: { fontSize: 'small', marginRight: '6px' },
        heading: { fontSize: '20px', fontWeight: 500 },
        hyperLink: { textDecoration: 'underline', cursor: 'pointer' },
        incomeLabel: { fontWeight: 500, wordWrap: 'break-word' },
        incomeValue: { wordWrap: 'break-word' },
        width4: { width: '4%' },
        width10: { width: '10%' },
        width12: { width: '12%' },
        width14: { width: '14%' },
        label: label(theme),
        value: value(theme),
        paper: paper(theme),
        resultsClientSpacer: {
            marginTop: '4px',
            marginBottom: '4px',
            backgroundColor: '#afb3c2',
        },
        resultsSpacer: {
            backgroundColor: '#afb3c2',
        },
        resultsPaper: resultsPaper(theme),
        resultsHeader: resultsHeader(theme),
        resultsActionLink: resultsActionLink(theme),
        resultsActionImage: resultsActionImage(theme),
        resultsHeaderSpacer: resultsHeaderSpacer(theme),
        resultsTableSpacer: resultsTableSpacer(theme),
        resultsClaimsSpacer: resultsClaimsSpacer(theme),
        resultsLabel: resultsLabel(theme),
        resultsText: { wordBreak: 'break-word' },
        resultsTextNone: resultsTextNone(theme),
        resultsTable: resultsTable(theme),
        resultsClaimsTable: resultsClaimsTable(theme),
        button: button(theme),
        buttonModalPrimary: buttonModalPrimary(theme),
        buttonModalSecondary: buttonModalSecondary(theme),
        modalContent: modalContent(theme),
        modalContentCounterLabel: modalContentCounterLabel(theme),
        modalContentCounterTime: modalContentCounterTime(theme),
        spacedTypography: spacedTypography(theme),
        pageHeader: pageHeader(theme),
        link: link(theme),
        drawerDivider: drawerDivider(theme),
        marginTop: topMargin(theme),
        marginBottom: bottomMargin(theme),
        topAndBottomMargin: topAndBottomMargin(theme),
        padding: padding(theme),
        rightAlign: rightAlign(theme),
        marginTop3: { marginTop: theme.spacing(3) },
        marginTop4: { marginTop: theme.spacing(4.5) },
        actionHeader: actionHeader(theme),
        actionCell: actionCell(theme),
        cell: cell(theme),
        table: table(),
        errorMessage: {
            color: theme.palette.error.main,
        },
        dropZoneWrapper: dropZoneWrapper(theme),
        dropZoneSection: dropZoneSection(theme),
        dropZoneInner: dropZoneInner(theme),
        dropZoneDiv: dropZoneDiv(theme),
        dropZoneDropHere: dropZoneDropHere(theme),
        dropZoneCloud: dropZoneCloud(theme),
        dropZoneLink: dropZoneLink(theme),
        captionText: captionText(theme),
        helperText: captionText(theme),
        totalPayableLabel: totalPayableLabel(),
        totalPayableValue: totalPayableValue(),
        gridSpaceBetween: gridSpaceBetween(theme),
        paymentButton: {
            textTransform: 'none',
            padding: '8px 8px 4px 8px',
        },
        paymentText: {
            margin: '8px',
            fontSize: '20px',
            fontWeight: 500,
        },
        assessorContainer: { minHeight: '100vh' },
        assessorButtonMargin: { marginTop: '3%' },
        assessorText: {
            marginTop: -10,
            fontSize: '20px',
        },
        assessorButton: {
            padding: 10,
            paddingRight: 30,
            paddingLeft: 30,
            textTransform: 'none',
            backgroundColor: '#002b99',
        },
        assessorAccordions: {
            marginTop: 10,
        },
        assessorFilter: {
            flexDirection: 'row',
        },
        filterTextInactive: {
            fontSize: 14,
            marginRight: 8,
            color: '#737373',
        },
        filterTextActive: {
            fontSize: 14,
            color: '#000000',
            fontWeight: 'bold',
            marginRight: 8,
        },
        accordionIcon: {
            height: 30,
            width: 30,
            borderRadius: '100%',
            backgroundColor: '#002b99',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 10,
            marginTop: -3,
        },
        inputLabelNoShrink: {
            transform: 'translate(32px, 24px) scale(1)',
        },
        addNewAssessorButton: {
            height: 40,
            textTransform: 'none',
            width: 160,
            backgroundColor: '#002b99',
            elevation: 0,
        },
        noUserFoundText: {
            color: '#737373',
        },
        assessorTableText: {
            fontSize: '15px',
        },
        assessorTableTextBold: {
            fontSize: '15px',
            fontWeight: 'bold',
        },
        assessorTableCellPadding: {
            padding: '8px',
        },
        assessorDisabledField: {
            color: '#c0c0c0',
        },
        assessorTableCell: {
            fontWeight: 'bold',
            padding: '8px',
        },
        assessorTableHead: {
            padding: 10,
            borderBottom: '2px solid',
            borderTop: '2px solid',
        },
        assessorTableHeadDisabled: {
            padding: 10,
            borderBottom: '#c0c0c0 2px solid',
            borderTop: '#c0c0c0 2px solid',
        },
        assessorTable: {
            marginTop: '10px',
            marginBottom: '40px',
            borderBottom: '1px solid',
        },
        assessorRadioButton: {
            fontSize: '10px',
            marginLeft: 10,
            marginTop: -10,
        },
        adSearch: {
            margin: '18%',
            alignContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        assesssorForm: {
            margin: 40,
        },
        previewForm: {
            margin: 10,
        },
        assessorEditTable: {
            paddingLeft: 30,
            paddingRight: 30,
            alignContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        sendForApprovalButton: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
        },
        manualScreening: {
            alignContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        boxPaper: {
            boxShadow:
                '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
            borderRadius: '4px',
        },
        adSearchBox: {
            borderWidth: '2px',
        },
    });
}

function gridSpaceBetween(theme: Theme) {
    return {
        marginTop: '12px',
    };
}

function captionText(theme: Theme) {
    return {
        height: '27px',
        fontSize: '14px',
        fontWeight: 'normal' as const,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.93',
        letterSpacing: 'normal',
        color: '#8b92a7',
    };
}

function dropZoneWrapper(theme: Theme) {
    return {
        width: '368px',
        height: '140px',
        borderRadius: '6px',
        border: '2px dashed #bbc2d8',
        backgroundColor: '#f0f1f4',
        margin: 'auto',
        marginTop: '10px',
        marginBottom: '10px',
    };
}

function dropZoneSection(theme: Theme) {
    return { height: '140px', margin: 'auto' };
}

function dropZoneInner(theme: Theme) {
    return {
        cursor: 'pointer',
        height: '140px',
        paddingTop: '15px',
    };
}

function dropZoneDiv(theme: Theme) {
    return {
        height: '33.33%',
        textAlign: 'center' as const,
    };
}

function dropZoneCloud(theme: Theme) {
    return {
        margin: 'auto',
        width: '38.8px',
        height: '24.6px',
    };
}

function dropZoneDropHere(theme: Theme) {
    return {
        width: '186px',
        height: '27px',
        fontSize: '16px',
        lineHeight: 1.69,
        color: '#0d1325',
        margin: 'auto',
    };
}

function dropZoneLink(theme: Theme) {
    return {
        textDecoration: 'underline',
        color: '-webkit-link',
        margin: 'auto',
    };
}

function topMargin(theme: Theme) {
    return {
        marginTop: theme.spacing(2),
    };
}

function buttonModalPrimary(theme: Theme) {
    return {
        width: '172px',
        height: '48px',
        borderRadius: '5px',
        backgroundColor: '#4378fd',
        fontSize: '16px',
        color: '#ffffff',
    };
}

function modalContentCounterLabel(theme: Theme) {
    return {
        fontSize: '18px',
        color: '#0d1325',
    };
}

function modalContentCounterTime(theme: Theme) {
    return {
        fontSize: '50px',
        color: '#002b99',
    };
}

function modalContent(theme: Theme) {
    return {
        fontSize: '16px',
        color: '#0d1325',
    };
}

function buttonModalSecondary(theme: Theme) {
    return {
        width: '172px',
        height: '48px',
        borderRadius: '5px',
        backgroundColor: '#ffffff',
        border: 'solid 1.5px #4378fd',
        fontSize: '16px',
        color: '#4378fd',
    };
}
function bottomMargin(theme: Theme) {
    return {
        marginBottom: theme.spacing(2),
    };
}

function topAndBottomMargin(theme: Theme) {
    return {
        ...topMargin(theme),
        ...bottomMargin(theme),
    };
}

function padding(theme: Theme) {
    return {
        padding: theme.spacing(2),
    };
}

export function paper(theme: Theme) {
    return {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        borderStyle: 'solid',
        borderColor: 'lightgrey',
        borderWidth: 'thin',
    };
}

export function resultsTable(theme: Theme) {
    return {
        paddingTop: '4px',
        paddingBottom: '4px',
    };
}

export function resultsClaimsTable(theme: Theme) {
    return {
        paddingLeft: '20px',
        paddingBottom: '4px',
        marginBottom: '-4px',
        backgroundColor: '#f0f1f5',
    };
}

export function resultsActionImage(theme: Theme) {
    return {
        verticalAlign: 'text-top',
    };
}

export function resultsActionLink(theme: Theme) {
    return {
        fontSize: '16px',
        color: '#4378fd',
        textDecoration: 'none !important',
    };
}

export function resultsPaper(theme: Theme) {
    return {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    };
}

export function resultsLabel(theme: Theme) {
    return {
        fontSize: '16px',
        fontWeight: 'normal' as const,
        color: '#8b92a7',
        lineHeight: 1.69,
        paddingTop: '8px',
        marginRight: '2px',
    };
}

export function resultsTextNone(theme: Theme) {
    return {
        fontSize: '16px',
        fontWeight: 'normal' as const,
        color: '#8b92a7',
        lineHeight: 1.69,
        paddingTop: '8px',
    };
}

export function resultsHeader(theme: Theme) {
    return {
        fontSize: '20px',
        fontWeight: 'bold' as const,
    };
}

export function resultsHeaderSpacer(theme: Theme) {
    return {
        marginTop: '8px',
        backgroundColor: '#e2e4e9',
        height: '1px',
        border: 0,
    };
}

export function resultsClaimsSpacer(theme: Theme) {
    return {
        backgroundColor: '#c5c7d2',
        marginBottom: '8px',
        height: '1px',
        border: 0,
    };
}

export function resultsTableSpacer(theme: Theme) {
    return {
        backgroundColor: '#afb3c2',
        height: '1px',
        border: 0,
        marginBottom: '0px',
    };
}

export function button(theme: Theme) {
    return {
        margin: theme.spacing(),
    };
}

export function spacedTypography(theme: Theme) {
    return {
        marginBottom: theme.spacing(2),
    };
}

export function pageHeader(theme: Theme) {
    return {
        marginBottom: theme.spacing(2),
        fontSize: '24px',
        color: '#000000',
    };
}

export function link(theme: Theme) {
    return {
        color: theme.palette.common.white,
    };
}

export function drawerDivider(theme: Theme): CSSProperties {
    return {
        borderColor: theme.palette.common.white,
        backgroundColor: theme.palette.common.white,
    };
}

export function rightAlign(theme: Theme): CSSProperties {
    return {
        textAlign: 'right',
    };
}

function actionHeader(theme: Theme): CSSProperties {
    return {
        ...cell(theme),
        marginRight: theme.spacing(),
        marginLeft: theme.spacing(),
    };
}

function actionCell(theme: Theme): CSSProperties {
    return {
        ...cell(theme),
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center' as const,
    };
}

function cell(theme: Theme): CSSProperties {
    return {
        padding: 0,
    };
}

function table(): CSSProperties {
    return {
        tableLayout: 'fixed',
    };
}

function totalPayableLabel() {
    return {
        fontFamily: 'Roboto',
        height: '27px',
        width: '125px',
        color: '#8B92A7 !important',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '27px',
        paddingTop: '12px',
        paddingBottom: '12px',
    };
}

function totalPayableValue() {
    return {
        height: '41px',
        width: '145px',
        color: '#002B99 !important',
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '41px',
        paddingTop: '12px',
    };
}

function label(theme: Theme): CSSProperties {
    return { ...scaledMarginBottom(theme, 0.75), wordBreak: 'break-word' };
}

function value(theme: Theme): CSSProperties {
    return {
        ...scaledMarginBottom(theme, 0),
        '&:last-child': scaledMarginBottom(theme, 0.75),
        wordBreak: 'break-word',
    };
}

function scaledMarginBottom(theme: Theme, scale: number) {
    return {
        marginBottom: theme.spacing(scale),
    };
}
