import { AnyAction, Reducer } from 'redux';
import { Claim, getClaim, getPropertyFromClaim } from '../../../../claim';
import { State } from '../../../../redux/root-reducer';

export interface RequiredDocument {
    id?: string;
    name?: string;
    status?: string;
    reason?: string;
    dateRequested?: Date;
}

export interface CorrespondenceType {
    claimantId?: string;
    assessor?: string;
    claimantName?: string;
    claimantPolicy?: string;
    date?: Date;
    to?: string;
    requiredDocuments?: string;
    ccs?: Array<string>;
    isInitialCommsSent?: boolean;
    claimantSurname?: string;
}
export interface Correspondence {
    communicationType?: string;
    date?: string;
    allocation?: CorrespondenceType[];
    missingInformation?: CorrespondenceType[];
    underInvestigation?: CorrespondenceType[];
}

export interface RequirementDocuments {
    documents: RequiredDocument[];
}

export interface Requirements {
    lifeAssured: RequirementDocuments;
}

const defaultRequirements: Requirements = {
    lifeAssured: { documents: [] },
};

export interface ClaimantRequirements extends RequirementDocuments {
    id?: string;
    formId?: string;
}

export function getRequirements(state: State): Requirements {
    return getRequirementsFromClaim(getClaim(state));
}

export function getRequirementsFromClaim(claim: Claim): Requirements {
    return getPropertyFromClaim(claim, 'requirements', defaultRequirements);
}

export function getCorrespondence(state: State): Correspondence {
    return getCorrespondenceFromClaim(getClaim(state));
}

export function getCorrespondenceFromClaim(claim: Claim): Correspondence {
    return getPropertyFromClaim(claim, 'correspondence', {});
}

export const correspondenceReducer: Reducer<Correspondence | undefined, AnyAction> = (
    state = {},
    action: AnyAction
) => {
    switch (action.type) {
        default:
            return state;
    }
};
