/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { BankingDetailsView } from '../../../assessment/synopsis/common/banking-details/views/edit-banking-details';
import { AuthState } from '../../../auth/auth-reducer';
import { getClaim } from '../../../claim';
import { ClaimType } from '../../../claim/claim-types';
import { InternalAccounts } from '../../../codes/internal-accounts';
import { PayeeType, PayeeTypesDreadDisability } from '../../../codes/payee-types';
import { PaymentTypes, PaymentTypesDreadDisability } from '../../../codes/payment-types';
import {
    Condition,
    FormHeading,
    FullWidthSearchSelectField,
    FullWidthTextField,
    True,
    When,
    WhenFieldChanges,
} from '../../../forms';
import { CurrencyMask } from '../../../forms/input-masks';
import { composeValidators, maxValue, requiredValidator } from '../../../forms/validations';
import { State } from '../../../redux/root-reducer';
import { BankingDetailsDisplay } from '../../../shared/banking-details-display';
import { convertToNumber } from '../../../shared-functions';
import { Nullable } from '../../../shared-types';
import styles, { StyledComponent } from '../../../styles/styles';
import {
    BeneficiaryDetails,
    ClaimDecision,
    getBeneficiaryEditName,
    getDecisions,
    getInternalAccount,
    getPaymentBeneficiaries,
} from '../../reducer';

type PaymentProp = StyledComponent & {
    beneficiaries: BeneficiaryDetails[];
    beneficiaryId: string;
    claimType: Nullable<ClaimType>;
    currentUser: AuthState;
    decisions: ClaimDecision[];
    edit?: boolean;
};

const paymentItemView: React.FunctionComponent<PaymentProp> = ({
    beneficiaries,
    beneficiaryId,
    claimType,
    currentUser,
    decisions,
    edit,
}: PaymentProp) => {
    const beneficiary = beneficiaries.find(b => b.beneficiaryId === beneficiaryId);
    if (!beneficiary) {
        return null;
    }
    const { payeeTypeOptions, paymentTypeOptions } = getDropdownOptions(claimType);
    return (
        <React.Fragment>
            <FormHeading
                text={`${edit ? 'Edit' : 'Add'} Payment - ${getBeneficiaryEditName(beneficiary)}`}
            />
            <WhenFieldChanges
                field="decisionId"
                set=""
                to={(decisionId: string) => {
                    const decision = decisions.find(d => d.decisionId === decisionId);
                    return {
                        ...decision,
                        assessor: currentUser.name,
                    };
                }}
            />
            <Grid container spacing={5} style={{ marginBottom: '120px' }}>
                <Grid item xs={6} lg={6}>
                    <FullWidthSearchSelectField
                        name="decisionId"
                        label="Policy"
                        validate={requiredValidator}
                        options={filterDecisions(decisions, beneficiary.policyNumber)}
                        disabled={edit}
                    />
                </Grid>
                <Field
                    name="."
                    subscription={{ value: true }}
                    component={({ input: { value: values } }) => {
                        setMaximumAmountPayable(beneficiary, values);
                        return null;
                    }}
                />
                <Grid item xs={6} lg={6}>
                    <FullWidthTextField
                        name="assessor"
                        label="Processed By"
                        defaultValue={currentUser.name}
                        disabled
                    />
                </Grid>
                <Grid item xs={6} lg={6}>
                    <FullWidthSearchSelectField
                        name="paymentType"
                        validate={requiredValidator}
                        options={paymentTypeOptions}
                    />
                    <WhenFieldChanges field="paymentType" set="payeeType" to={null} />
                </Grid>
                <WhenFieldChanges field="payeeType" set="bankingDetails" to={null} />
                <WhenFieldChanges field="payeeType" set="internalAccountId" to={null} />
                <WhenFieldChanges
                    field="payeeType"
                    set="bankingDetails.bankAccountStatus"
                    becomes="ExternalTransfer"
                    to="Not verified"
                />
                <WhenFieldChanges
                    field="paymentType"
                    becomes="Refund"
                    set="payeeType"
                    to="Beneficiary"
                />
                <Field
                    name="paymentType"
                    subscription={{ value: true }}
                    component={({ input: { value: paymentType } }) => {
                        const options = payeeTypeOptions.filter((x: any) =>
                            x.payments.find((p: any) => p === paymentType)
                        );
                        return (
                            <Grid item xs={6} lg={6}>
                                <FullWidthSearchSelectField
                                    name="payeeType"
                                    validate={requiredValidator}
                                    options={options}
                                />
                            </Grid>
                        );
                    }}
                />
                <Condition when="payeeType" is="InternalTransfer">
                    <True>
                        <Grid item xs={6} lg={6}>
                            <FullWidthSearchSelectField
                                label="Account"
                                name="internalAccountId"
                                validate={requiredValidator}
                                options={InternalAccounts}
                                sortOptions
                            />
                        </Grid>
                    </True>
                </Condition>
                <Grid item xs={6} lg={6}>
                    <Field
                        name="maxValue"
                        subscription={{ value: true }}
                        component={({ input: { value: amountPayable } }) => (
                            <FullWidthTextField
                                name="amountPayable"
                                mask={CurrencyMask}
                                parse={convertToNumber}
                                validate={composeValidators(
                                    requiredValidator,
                                    maxValue(amountPayable, 'maximum amount payable')
                                )}
                            />
                        )}
                    />
                </Grid>
                <Field
                    name="."
                    subscription={{ value: true }}
                    component={({ input: { value: values } }) => {
                        if (values) {
                            let split =
                                ((values.amountPayable || 0) /
                                    getAmountPayable(values, claimType)) *
                                100;
                            if (isNaN(Number(split))) {
                                split = 0;
                            }
                            values.split = split.toFixed(2);
                        }
                        return null;
                    }}
                />
                <Grid item xs={6} lg={6}>
                    <FullWidthTextField name="split" validate={requiredValidator} disabled />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField name="maxValue" label="Maximum amount payable" disabled />
                </Grid>
                <Condition when="payeeType" is={['IT88', 'PAYE']}>
                    <True>
                        <Grid item xs={6} lg={6}>
                            <FullWidthTextField name="taxDirectiveNumber" />
                        </Grid>
                    </True>
                </Condition>
                <Condition when="payeeType" matchesChild>
                    <When is="ExternalTransfer">
                        <BankingDetailsView propertyPrefix="bankingDetails" isAnOrganisation />
                    </When>
                    <When is="Beneficiary">
                        <BankingDetailsDisplay bankingDetails={beneficiary.bankingDetails} />
                    </When>
                </Condition>
                <Condition when={`internalAccountId`} hasValue>
                    <True>
                        <Field
                            name={`internalAccountId`}
                            subscription={{ value: true }}
                            component={({ input: { value: internalAccountId } }) => (
                                <BankingDetailsDisplay
                                    bankingDetails={getInternalAccount(internalAccountId)}
                                />
                            )}
                        />
                    </True>
                </Condition>
            </Grid>
        </React.Fragment>
    );
};

function setMaximumAmountPayable(beneficiary: BeneficiaryDetails, values: any) {
    const existingPayments =
        (beneficiary.existingPayments &&
            beneficiary.existingPayments.filter(
                p => p.decisionId === values.decisionId && p.id !== values.id
            )) ||
        [];
    const totalPaymentAmount = existingPayments.map(p => p.amount).reduce((a, b) => a + b, 0);
    const maxAmount = Number(
        ((values.sumAssured * beneficiary.allocation) / 100 - totalPaymentAmount).toFixed(2)
    );

    if (
        !isNaN(maxAmount) &&
        maxAmount < values.maximumAmountPayable &&
        !(values.maximumAmountPayable - maxAmount <= 0.01)
    ) {
        values.maxValue = maxAmount.toFixed(2);
    } else {
        values.maxValue = values.maximumAmountPayable;
    }
}

function getAmountPayable(values: any, claimType: Nullable<ClaimType>) {
    if (claimType === ClaimType.Disability || claimType === ClaimType.Dread) {
        return values.decisionAmount || 0;
    }
    return values.sumAssured || 0;
}

function getDropdownOptions(claimType: Nullable<ClaimType>) {
    switch (claimType) {
        case ClaimType.Death:
        case ClaimType.RADeath:
            return { payeeTypeOptions: PayeeType, paymentTypeOptions: PaymentTypes };
        case ClaimType.Disability:
        case ClaimType.Dread:
            return {
                payeeTypeOptions: PayeeTypesDreadDisability,
                paymentTypeOptions: PaymentTypesDreadDisability,
            };
    }
    return { payeeTypeOptions: [], paymentTypeOptions: [] };
}

function filterDecisions(decisions: ClaimDecision[], policyNumber: string) {
    const filteredDecisions: any = [];
    decisions.forEach(d => {
        if (d.policyNumber === policyNumber || policyNumber === 'All') {
            filteredDecisions.push({
                value: d.decisionId,
                label: d.decisionLabel,
            });
        }
    });
    return filteredDecisions;
}

const mapStateToProps = (state: State) => {
    const claim = getClaim(state);
    return {
        beneficiaries: getPaymentBeneficiaries(claim),
        claimType: claim.claimType,
        currentUser: state.auth as AuthState,
        decisions: getDecisions(claim),
    };
};

const mapDispatchToProps = {};

export const PaymentItemViewEdit = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(paymentItemView));
