import { Grid } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { FormHeading, FullWidthTextField } from '../../../../../forms';
import { requiredValidator } from '../../../../../forms/validations';

export const CommentsViewEdit = () => {
    return (
        <Grid container spacing={2} justify="flex-start">
            <Grid item xs={12}>
                <FormHeading text="Add Comment" />
            </Grid>
            <Grid item xs={12}>
                <FullWidthTextField name="comment" validate={requiredValidator} multiline />
            </Grid>
        </Grid>
    );
};
