const appName = 'claims';

export const noFeatureNameError = 'Feature name cannot be blank';

type GetActionName = (featureName: string) => string;

export function createFeatureActionNamer(featureName = ''): GetActionName {
    const trimmedFeatureName = featureName.toString().trim();
    if (!trimmedFeatureName) {
        throw new Error(noFeatureNameError);
    }
    return function _createActionName(actionName = ''): string {
        const trimmedActionName = actionName.toString().trim();
        if (!trimmedActionName) {
            throw new Error(noActionNameError(trimmedFeatureName));
        }
        return appName + '/' + trimmedFeatureName + '/' + trimmedActionName;
    };
}

export function noActionNameError(featureName: string): string {
    return `Action name for "${featureName}" cannot be blank`;
}
