import { AnyAction } from 'redux';
import { Claim, getClaim, getPropertyFromClaim } from '../../../../claim';
import { State } from '../../../../redux/root-reducer';
import { Nullable } from '../../../../shared-types';
import {
    LifeAssuredDetails,
    defaultLifeAssuredDetails,
} from '../../../../life-assured-details/reducer';
import { combineReducersWithDefault } from '../../../../redux/combine-reducers-with-default';
import { simplePropertyReducer } from '../../../../redux/simple-property-reducer';
import { ADD_EXTERNAL_POLICY, UPDATE_EXTERNAL_POLICY, DELETE_EXTERNAL_POLICY } from './types';
import { collectionReducer } from '../../../../redux/collection-reducer';
import { AddressDetails } from '../../../../forms/sub-forms/address-details';
import { BankingDetails } from '../../common/banking-details/banking-details';

export interface Beneficiary {
    readonly firstName?: string;
    readonly lastName?: string;
}

export interface ExternalPolicy {
    readonly id?: string;
    readonly insurerName?: string;
    readonly policyNumber?: string;
    readonly sumAssured?: number;
    readonly issueDate?: Date;
    readonly productType?: string;
    readonly beneficiaries: Beneficiary[];
}

export interface CurrentOccupation {
    readonly occupationDescription?: string;
    readonly admin?: number;
    readonly supervisory?: number;
    readonly manual?: number;
    readonly travel?: number;
}

export interface PreviousMarriage {
    readonly dateOfMarriage?: Date;
    readonly dateOfDivorce?: Date;
}

export interface RADeathLifeAssured extends LifeAssuredDetails {
    readonly externalPolicies: ExternalPolicy[];
    readonly occupationDetails: CurrentOccupation;
    readonly previouslyMarried: string;
    readonly previousMarriages: PreviousMarriage[];
    readonly sameAsResidential: boolean;
    readonly residentialAddress: AddressDetails;
    readonly postalAddress: AddressDetails;
    readonly businessAddress: AddressDetails;
    readonly bankingDetails: BankingDetails;
}

export const defaultRADeathLifeAssured: RADeathLifeAssured = {
    ...defaultLifeAssuredDetails,
    occupationDetails: {},
    externalPolicies: [],
    previouslyMarried: '',
    previousMarriages: [],
    sameAsResidential: false,
    residentialAddress: {},
    postalAddress: {},
    businessAddress: {},
    bankingDetails: {},
};

export function previousMarriageReducer(state: PreviousMarriage[] = [], action: AnyAction) {
    switch (action.type) {
        default:
            return state;
    }
}

function prevouslyMarriedReducer(state = '', action: AnyAction) {
    switch (action.type) {
        default:
            return state;
    }
}

export const externalPolicyReducer = collectionReducer<ExternalPolicy>({
    addAction: ADD_EXTERNAL_POLICY,
    updateAction: UPDATE_EXTERNAL_POLICY,
    deleteAction: DELETE_EXTERNAL_POLICY,
});

export const raDeathLifeAssuredDetailsReducer = combineReducersWithDefault<
    Nullable<RADeathLifeAssured>
>(defaultRADeathLifeAssured, {
    personalDetails: simplePropertyReducer(defaultRADeathLifeAssured.personalDetails),
    residentialAddressDetails: simplePropertyReducer(
        defaultRADeathLifeAssured.residentialAddressDetails
    ),
    postalAddressDetails: simplePropertyReducer(defaultRADeathLifeAssured.postalAddressDetails),
    contactDetails: simplePropertyReducer(defaultRADeathLifeAssured.contactDetails),
    medicalAidDetails: simplePropertyReducer(defaultRADeathLifeAssured.medicalAidDetails),
    claimLifeNotifications: simplePropertyReducer(defaultRADeathLifeAssured.claimLifeNotifications),
    contracts: simplePropertyReducer(defaultRADeathLifeAssured.contracts),
    previousPayments: simplePropertyReducer(defaultRADeathLifeAssured.previousPayments),
    originalDoctors: simplePropertyReducer(defaultRADeathLifeAssured.originalDoctors),
    occupationDetails: simplePropertyReducer(defaultRADeathLifeAssured.occupationDetails),
    externalPolicies: externalPolicyReducer,
    previouslyMarried: prevouslyMarriedReducer,
    previousMarriages: previousMarriageReducer,
    sameAsResidential: simplePropertyReducer(defaultRADeathLifeAssured.sameAsResidential),
    residentialAddress: simplePropertyReducer(defaultRADeathLifeAssured.residentialAddress),
    postalAddress: simplePropertyReducer(defaultRADeathLifeAssured.postalAddress),
    businessAddress: simplePropertyReducer(defaultRADeathLifeAssured.businessAddress),
    bankingDetails: simplePropertyReducer(defaultRADeathLifeAssured.bankingDetails),
});

export function getExternalPolicy(state: State) {
    return getRADeathLifeAssuredFromClaim(getClaim(state)).externalPolicies || {};
}
export function getOccupationDetails(state: State): CurrentOccupation {
    return getRADeathLifeAssuredFromClaim(getClaim(state)).occupationDetails || {};
}

export function getRADeathLifeAssured(state: State): RADeathLifeAssured {
    return getRADeathLifeAssuredFromClaim(getClaim(state));
}

export function getRADeathLifeAssuredFromClaim(claim: Claim): RADeathLifeAssured {
    return getPropertyFromClaim(claim, 'lifeAssuredDetails', defaultRADeathLifeAssured);
}
