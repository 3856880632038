import { Button, Grid, Tooltip, Typography, withStyles, Paper } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { getClaim } from '../../../../../claim';
import { getDeathClaimEventDetails } from '../../../../../claim/reducers/claim-reducers/death-claim-reducer';
import { Titles } from '../../../../../codes/titles';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { OpenModal, openModalAction } from '../../../../../form-modal/actions';
import { FormModalView } from '../../../../../form-modal/views/form-modal';
import { ButtonWrapper, NonRaDeathWrapper } from '../../../../../forms';
import {
    getLifeAssuredDetails,
    PersonalDetails,
    MedicalAidDetails,
} from '../../../../../life-assured-details/reducer';
import { State } from '../../../../../redux/root-reducer';
import { getDisplayDate, getFullName, getCodeLabel } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { PersonalDetailsEditModal } from './personal-details-edit-modal';
import {
    FormDetails,
    getFormDetails,
} from '../../../living-lifestyle/form-details/form-details-reducer';

type InnerProps = StyledComponent & {
    dateOfDeath?: Date;
    isDeath: boolean;
    openModal: OpenModal;
    personalDetails: PersonalDetails;
    medicalAidInfoDetails: MedicalAidDetails;
    formDetails?: FormDetails;
    isReadOnly: boolean;
    isUnderWriting?: boolean;
};
const personalDetailsView: React.FunctionComponent<InnerProps> = ({
    classes,
    dateOfDeath,
    isDeath,
    formDetails,
    openModal,
    personalDetails,
    medicalAidInfoDetails,
    isUnderWriting,
}: InnerProps) => {
    const edit = () => {
        openModal({
            modalContent: <FormModalView formView={PersonalDetailsEditModal} />,
        });
    };

    let formMedicalAidDetails;

    if (formDetails?.lifeAssured) {
        formMedicalAidDetails = {
            isLifeAssuredTheMainMember:
                formDetails?.lifeAssured.medicalAidDetails?.isLifeAssuredMainMember,
            mainMembersTitle: formDetails?.lifeAssured.medicalAidDetails?.lifeAssuredTitle,
            mainMembersInitials: null,
            mainMembersFirstName: formDetails?.lifeAssured.medicalAidDetails?.lifeAssuredName,
            mainMembersLastName: formDetails?.lifeAssured.medicalAidDetails?.lifeAssuredSurname,
            mainMembersIdNumber: formDetails?.lifeAssured.medicalAidDetails?.lifeAssuredIdNumber,
            dateJoined: formDetails?.lifeAssured.medicalAidDetails?.lifeAssuredMedicalDateJoined,
            medicalAidName: formDetails?.lifeAssured.medicalAidDetails?.lifeAssuredMedicalAidName,
            medicalAidNumber:
                formDetails?.lifeAssured.medicalAidDetails?.lifeAssuredMedicalAidNumber,
            medicalAidDescription: null,
        };
    }
    const medicalAidDetails =
        medicalAidInfoDetails?.isLifeAssuredTheMainMember !== null
            ? medicalAidInfoDetails
            : formMedicalAidDetails;

    return (
        <React.Fragment>
            <Grid container justify="space-between" className={classes.marginTop2}>
                <Grid container item xs={10} alignItems="center">
                    <Typography variant="subtitle2">Personality details</Typography>
                </Grid>
                <ButtonWrapper>
                    <NonRaDeathWrapper>
                        <Grid container item xs={2} alignItems="center" justify="flex-end">
                            <Tooltip title="Edit Life Assured Details">
                                <Button
                                    id="EditPersonalDetails"
                                    color="secondary"
                                    disabled={isUnderWriting}
                                    onClick={edit}
                                >
                                    <Edit />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </NonRaDeathWrapper>
                </ButtonWrapper>
            </Grid>
            <Paper className={classes.paper}>
                <Grid container justify="flex-start">
                    <Grid item xs={12}>
                        <Typography variant="h5">{getFullName(personalDetails)}</Typography>
                    </Grid>
                    {personalDetails.passportNumber && (
                        <Grid item xs={12} container justify="flex-start">
                            <Grid item xs={4} lg={2}>
                                <LabeledValue
                                    label="Passport"
                                    value={personalDetails.passportNumber}
                                />
                            </Grid>
                            <Grid item xs={4} lg={2}>
                                <LabeledValue
                                    label="Country Of Issue"
                                    value={personalDetails.passportCountryOfIssue}
                                />
                            </Grid>
                            <Grid item xs={4} lg={2}>
                                <LabeledValue
                                    label="Date of issue"
                                    value={getDisplayDate(personalDetails.passportIssueDate)}
                                />
                            </Grid>
                            <Grid item xs={4} lg={2}>
                                <LabeledValue
                                    label="Expiry Date"
                                    value={getDisplayDate(personalDetails.passportExpiryDate)}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {personalDetails.idNumber && (
                        <Grid item xs={4} lg={3}>
                            <LabeledValue label="ID Number" value={personalDetails.idNumber} />
                        </Grid>
                    )}
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="Date of Birth"
                            value={getDisplayDate(personalDetails.dateOfBirth, 'dd LLLL yyyy')}
                        />
                    </Grid>
                    {isDeath && (
                        <Grid item xs={4} lg={3}>
                            <LabeledValue
                                label="Date of Death"
                                value={getDisplayDate(dateOfDeath, 'dd LLLL yyyy')}
                            />
                        </Grid>
                    )}
                    <NonRaDeathWrapper>
                        <Grid item xs={4} lg={3}>
                            <LabeledValue label="PDB ID" value={personalDetails.clientId} />
                        </Grid>
                    </NonRaDeathWrapper>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label={isDeath ? 'Age at Death' : 'Age'}
                            value={personalDetails.age}
                        />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue label="Gender" value={personalDetails.gender} />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="Marital Status"
                            value={personalDetails.maritalStatus}
                        />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="Tax number"
                            value={personalDetails.taxReferenceNumber}
                        />
                    </Grid>
                    <NonRaDeathWrapper>
                        <Grid item xs={12} lg={12}>
                            <Typography variant="subtitle2" style={{ marginTop: 8 }}>
                                Medical Aid Details
                            </Typography>
                        </Grid>
                        <Grid item xs={4} lg={3}>
                            <LabeledValue
                                label="Name"
                                value={
                                    medicalAidDetails?.medicalAidName
                                        ? medicalAidDetails.medicalAidName
                                        : medicalAidDetails?.medicalAidNumber
                                }
                            />
                        </Grid>
                        {medicalAidDetails?.medicalAidName &&
                            medicalAidDetails?.medicalAidName === 'Other' && (
                                <Grid item xs={4} lg={3}>
                                    <LabeledValue
                                        label="Medical Aid Description"
                                        value={
                                            medicalAidDetails?.medicalAidDescription &&
                                            medicalAidDetails?.medicalAidDescription
                                        }
                                    />
                                </Grid>
                            )}
                        <Grid item xs={4} lg={3}>
                            <LabeledValue
                                label="Number"
                                value={medicalAidDetails?.medicalAidNumber}
                            />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                            <LabeledValue
                                label="Date Joined"
                                value={getDisplayDate(medicalAidDetails?.dateJoined)}
                            />
                        </Grid>
                        {medicalAidDetails?.isLifeAssuredTheMainMember === 'No' && (
                            <React.Fragment>
                                <Grid item xs={4} lg={3}>
                                    <LabeledValue
                                        label="Main member's Tittle"
                                        value={getCodeLabel(
                                            Titles,
                                            medicalAidDetails?.mainMembersTitle
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4} lg={3}>
                                    <LabeledValue
                                        label="Main member's name"
                                        value={medicalAidDetails.mainMembersFirstName}
                                    />
                                </Grid>
                                <Grid item xs={4} lg={3}>
                                    <LabeledValue
                                        label="Main member's surname"
                                        value={medicalAidDetails.mainMembersLastName}
                                    />
                                </Grid>
                                <Grid item xs={4} lg={3}>
                                    <LabeledValue
                                        label="Main member's ID number"
                                        value={medicalAidDetails.mainMembersIdNumber}
                                    />
                                </Grid>
                            </React.Fragment>
                        )}
                    </NonRaDeathWrapper>
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

const mapStateToProps = (state: State) => {
    const claim = getClaim(state);
    let dateOfDeath = undefined;
    let isDeath = false;
    if (claim.claimType === 'Death') {
        isDeath = true;
        const claimEventDetails = getDeathClaimEventDetails(state);
        dateOfDeath =
            claimEventDetails &&
            claimEventDetails.deathClaimDetails &&
            claimEventDetails.deathClaimDetails.dateOfEvent;
    }

    return {
        personalDetails: getLifeAssuredDetails(state).personalDetails,
        medicalAidInfoDetails: getLifeAssuredDetails(state).medicalAidDetails,
        formDetails: getFormDetails(state),
        dateOfDeath: dateOfDeath,
        isDeath: isDeath,
        isReadOnly: state.claimSession.readOnly,
    };
};

const mapDispatchToProps = {
    openModal: openModalAction,
};

export const PersonalDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(personalDetailsView));
