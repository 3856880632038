import { Grid, withStyles } from '@material-ui/core';
import { RouterState } from 'connected-react-router';
import { Location } from 'history';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { getFormSubmissionState, getWorkflowState } from '../../claim/reducers';
import { State } from '../../redux/root-reducer';
import styles, { StyledComponent } from '../../styles/styles';
import { FormNavigation, WorkflowView } from '../../workflow-navigation';
import { WorkflowState } from '../../workflow-navigation/reducers/workflow-reducer';
import { WorkflowProvider } from './workflow-provider';

interface Props extends StyledComponent {
    workflowState: WorkflowState;
    location: Location;
}
interface ClaimWorkflowViewState {
    event?: React.KeyboardEvent<HTMLElement>;
}

class ClaimWorkflowClass extends React.Component<Props, ClaimWorkflowViewState> {
    public componentDidUpdate(prevProps: Props, prevState: ClaimWorkflowViewState) {
        if (this.state && this.state.event && prevState && this.state.event === prevState.event) {
            this.setEvent();
        }
    }

    public render() {
        const { location } = this.props;
        return (
            <WorkflowProvider>
                <Grid container spacing={0} justify="center">
                    <Grid item xs={12} lg={12} style={{ overflow: 'hidden' }}>
                        <WorkflowView
                            getWorkflowState={getWorkflowState}
                            getFormSubmissionState={getFormSubmissionState}
                            location={location}
                        />
                    </Grid>
                </Grid>
                <FormNavigation
                    getWorkflowState={getWorkflowState}
                    getFormSubmissionState={getFormSubmissionState}
                />
            </WorkflowProvider>
        );
    }

    private setEvent(event?: React.KeyboardEvent<HTMLElement>) {
        this.setState({ event });
    }
}

const mapStateToProps = (state: State) => ({
    workflowState: state.claimSession.workflow,
    location: (state.router as RouterState).location,
});

export const ClaimWorkflow = connect(mapStateToProps)(withStyles(styles)(ClaimWorkflowClass));
