import { createStyles, Grid, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { StyleRules, WithStyles } from '@material-ui/core/styles';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { getClaim } from '../../claim';
import { getReferences, ClaimReference } from '../../claim/reducers/claim-reference-reducer';
import { State } from '../../redux/root-reducer';
import globalStyles from '../../styles/styles';
import { Nullable, VersionInfo } from '../../shared-types';

function styles(theme: Theme): StyleRules {
    return createStyles({
        ...globalStyles(theme),
        claimReferenceDetails: {
            marginTop: 'auto',
            paddingBottom: '20px',
            paddingLeft: '10px',
        },
    });
}

type Props = WithStyles<typeof styles> & {
    references: ClaimReference[];
    contractIds: string[];
    readOnly: boolean;
    version: Nullable<VersionInfo>;
};
const claimReferenceDetails: React.FunctionComponent<Props> = ({
    classes,
    contractIds,
    readOnly,
    references,
    version,
}: Props) => {
    const versionNumber = version ? version.createdInVersion || '1.1.0.0' : '1.1.0.0';
    return (
        <Grid container className={classes.claimReferenceDetails}>
            {readOnly && (
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.link} noWrap>
                        Read Only Mode
                    </Typography>
                </Grid>
            )}
            {contractIds.length > 0 && (
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.link} noWrap>
                        Version: {versionNumber}
                    </Typography>
                </Grid>
            )}
            {references.length > 0 && (
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.link} noWrap>
                        Cases:
                    </Typography>
                </Grid>
            )}
            {references.map(reference => (
                <Grid item xs={12} key={reference.referenceSource + reference.referenceId}>
                    <Tooltip title={`${reference.referenceSource}: ${reference.referenceId}`}>
                        <Typography
                            variant="subtitle2"
                            className={classes.link}
                            style={{ paddingLeft: '10px' }}
                            noWrap
                        >
                            {`${reference.referenceSource}: ${reference.referenceId}`}
                        </Typography>
                    </Tooltip>
                </Grid>
            ))}
            {contractIds.length > 0 && (
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.link} noWrap>
                        Policy Numbers:
                    </Typography>
                </Grid>
            )}
            {contractIds.map(id => (
                <Grid item xs={12} key={id}>
                    <Tooltip title={id}>
                        <Typography
                            variant="subtitle2"
                            className={classes.link}
                            style={{ paddingLeft: '10px' }}
                            noWrap
                        >
                            {id}
                        </Typography>
                    </Tooltip>
                </Grid>
            ))}
        </Grid>
    );
};

function mapStateToProps(state: State) {
    const claim = getClaim(state);
    const references = getReferences(state);
    return {
        claimId: claim.claimId,
        references: references,
        contractIds: (claim.contracts || [])
            .filter(c => c.isLinkedToClaim && c.contractNumber)
            .map(c => c.contractNumber as string),
        version: claim.version,
        readOnly: state.claimSession.readOnly,
    };
}

const mapDispatchToProps = {};

export const ClaimReferenceDetails = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(claimReferenceDetails));
