import { Reducer } from 'redux';
import {
    AddRecordCollectionAction,
    DeleteRecordCollectionAction,
    UpdateRecordCollectionAction,
} from './collection-action';
import { addRecord, deleteRecord, updateRecord } from './reductions';

export interface Props {
    addAction?: string;
    updateAction?: string;
    deleteAction?: string;
}

export type Action<T> =
    | AddRecordCollectionAction<T>
    | UpdateRecordCollectionAction<T>
    | DeleteRecordCollectionAction;

export function collectionReducer<T>({
    addAction,
    updateAction,
    deleteAction,
}: Props): Reducer<T[], Action<T>> {
    return (state: T[] = [], action: Action<T>): T[] => {
        state = state || [];
        const isAddAction = isActionType(action, addAction);
        if (isAddAction) {
            return addRecord(state, action.record);
        }

        const isUpdateAction = isActionType(action, updateAction);
        if (isUpdateAction) {
            return updateRecord(state, action.record, action.index);
        }

        const isDeleteAction = isActionType(action, deleteAction);
        if (isDeleteAction) {
            return deleteRecord(state, action.index);
        }

        return state;
    };
}

function isActionType<T>(action: Action<T>, actionType?: string): boolean {
    return !!actionType && action.type === actionType;
}
