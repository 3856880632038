import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { LabeledValue } from '../../../../controls/labeled-value';
import { LifeAssuredContract } from '../../../../life-assured-details/reducer';
import { formatCurrency, getDisplayDate } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import { CessionTypeValue } from '../cession-type';
import { StatusValue } from '../policy-status';

type Props = StyledComponent & {
    contract: LifeAssuredContract;
};
const policyDetailsView: React.FunctionComponent<Props> = ({ classes, contract }: Props) => {
    return (
        <React.Fragment>
            <Grid container justify="space-between">
                <Grid item xs={2} lg={2}>
                    <Typography variant="subtitle2" className={classes.marginTop}>
                        Policy Details
                    </Typography>
                </Grid>
            </Grid>
            <Paper className={classes.paper}>
                <Grid container justify="flex-start">
                    <Grid item xs={4} lg={3}>
                        <LabeledValue label="Policy Number" value={contract.contractNumber} />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="Commencement Date"
                            value={getDisplayDate(contract.commencementDate)}
                        />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="Policy Premium Amount"
                            value={formatCurrency(contract.premiumAmount)}
                        />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="Automatic Benefit Increase"
                            value={`${contract.automaticBenefitIncrease || 0}%`}
                        />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue label="Status" />
                        <StatusValue status={contract.status} />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="Paid to Date"
                            value={getDisplayDate(contract.paidToDate, 'dd LLLL yyyy')}
                        />
                    </Grid>
                    {contract.ceded === 'Yes' && (
                        <React.Fragment>
                            <Grid item xs={4} lg={3}>
                                <LabeledValue label="Cession Type" />
                                <CessionTypeValue cessionaryType={contract.cessionaryType} />
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Cessionary Name"
                                    value={contract.cessionaryName}
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

export const PolicyDetailsView = withStyles(styles)(policyDetailsView);
