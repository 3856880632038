import { Button, Grid, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { Section } from '../../../controls/section';
import { ButtonWrapper } from '../../../forms';
import { ExpansionPanel } from '../../../forms/form-components/expansion-panel/expansion-panel';
import { getLifeAssuredDetails, LifeAssuredContract } from '../../../life-assured-details/reducer';
import { State } from '../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../styles/styles';
import {
    getAssessmentDetails,
    PolicyAssessmentDetails,
} from '../../decisions/assessment-details-reducer';
import {
    IncomeCalculationModel,
    UpdateIncomePayableCalculation,
    updateIncomePayableCalculationAction,
} from '../actions/update-income-calculation-summary';
import { getIncomeCalculations, IncomeCalculation } from '../reducer';
import { ClaimBoosterView } from './claim-booster/claim-booster-view';
import { IncomePayableSummary } from './income-payable-summary/income-payable';
import { OverheadExpenseBenefit } from './overhead-expense/overhead-expense-benefit';
import { PolicyDetails } from './policy-details/view-policy-details';
import { TopUpBenefit } from './top-up/top-up-benefit';

interface Props {
    assessment: PolicyAssessmentDetails[];
    contracts: LifeAssuredContract[];
    policyCalculation: IncomeCalculation;
    incomeCalculations: IncomeCalculation[];
}

interface DispatchProps {
    updateIncomePayableCalculation: UpdateIncomePayableCalculation;
}

interface IncomeCalculationResults {
    incomeProtectorSumAssured: number | undefined;
    oebSumAssured: number | undefined;
    topUpAmount: number | undefined;
    premiumAmount: number | undefined;
    topUpPremium: number | undefined;
    sumAssured: number | undefined;
    topUpPercentage: number | undefined;
}

const incomeCalculationResults: IncomeCalculationResults = {
    incomeProtectorSumAssured: 0,
    oebSumAssured: 0,
    topUpAmount: 0,
    premiumAmount: 0,
    topUpPremium: 0,
    sumAssured: 0,
    topUpPercentage: 0,
};

const incomeCalculationView = ({
    assessment,
    contracts,
    policyCalculation,
    incomeCalculations,
    updateIncomePayableCalculation,
}: Props & StyledComponent & DispatchProps) => {
    const policyAssessment = assessment.find(
        pa => pa.policyNumber === policyCalculation.policyNumber
    );
    const contract = contracts.find(c => c.contractNumber === policyCalculation.policyNumber);
    const contractBenefit =
        contract &&
        contract.benefits &&
        contract.benefits.find(b => b.code && b.code.toString() === policyCalculation.benefitCode);

    const assessmentBenefit =
        policyAssessment &&
        policyAssessment.benefits.find(b => b.benefitCode === policyCalculation.benefitCode);

    const panelName = `${policyCalculation.policyNumber} - ${
        contractBenefit && contractBenefit.name
    }`;
    const oebCode = ['43', '72', '103'];
    const topUpCode = ['59'];
    const topUpMatch = topUpCode.includes(policyCalculation.benefitCode);
    const oebCalculation = oebCode.includes(policyCalculation.benefitCode);
    const finalDecision =
        policyAssessment &&
        policyAssessment.benefits.filter(b =>
            b.decisions.find(
                d => d.id === policyCalculation.decisionId && d.final && d.final === true
            )
        );
    const decision =
        assessmentBenefit &&
        assessmentBenefit.decisions.find(d => d.id === policyCalculation.decisionId);
    incomeCalculationResults.sumAssured = decision && decision.amountPayable;

    const currentBenefit =
        contract &&
        contract.benefits &&
        contract.benefits.find(c => c.code === Number(policyCalculation.benefitCode));
    if (oebCalculation && finalDecision) {
        incomeCalculationResults.oebSumAssured = decision && decision.amountPayable;
        incomeCalculationResults.sumAssured = 0;
    }

    incomeCalculationResults.incomeProtectorSumAssured = decision && decision.amountPayable;

    if (topUpMatch && finalDecision) {
        const benefitInceptionDate = currentBenefit && currentBenefit.startDate;
        const benefitInceptionDatePrior = new Date('Oct 01 2008');
        const benefitInceptionDateAfter = new Date('Oct 31 2008');

        incomeCalculationResults.topUpPercentage =
            benefitInceptionDate && benefitInceptionDate < benefitInceptionDatePrior
                ? 20
                : benefitInceptionDate && benefitInceptionDate > benefitInceptionDateAfter
                ? 33.33
                : 0;

        const officePremium = (currentBenefit && currentBenefit.officePremiumAmount) || 0;
        const loadPremium = (currentBenefit && currentBenefit.loadPremiumAmount) || 0;
        const benefitAmount = (currentBenefit && currentBenefit.benefitAmount) || 0;

        incomeCalculationResults.topUpPremium = officePremium + loadPremium;
        incomeCalculationResults.topUpAmount =
            benefitAmount * (incomeCalculationResults.topUpPercentage / 100);
    }

    return (
        <ExpansionPanel
            name={panelName}
            label={panelName}
            style={{ marginLeft: -24, marginRight: -24 }}
        >
            <PolicyDetails
                benefitCode={policyCalculation.benefitCode}
                contract={contract}
                contractBenefit={contractBenefit}
                decisionId={policyCalculation.decisionId}
                policyAssessment={policyAssessment}
            />
            {topUpMatch && (
                <TopUpBenefit
                    topUpPercentage={incomeCalculationResults.topUpPercentage}
                    topUpPremium={incomeCalculationResults.topUpPremium}
                    topUpAmount={incomeCalculationResults.topUpAmount}
                />
            )}
            {oebCalculation && finalDecision && (
                <OverheadExpenseBenefit
                    decision={decision}
                    oebSumAssured={incomeCalculationResults.oebSumAssured}
                />
            )}
            <ClaimBoosterView
                incomeCalculation={policyCalculation}
                contract={contract}
                contractBenefit={contractBenefit}
            />
            <Section title="Income payable summary">
                <IncomePayableSummary incomePayable={policyCalculation.incomePayableCalculations} />
            </Section>
            <ButtonWrapper>
                <Grid
                    container
                    item
                    xs={12}
                    justify="flex-end"
                    style={{ paddingRight: 5, marginTop: '-15px' }}
                >
                    <Button
                        id="Calculate-Payable-Income"
                        color="secondary"
                        style={{ padding: 10, textTransform: 'none' }}
                        onClick={IncomePayableCalcButton}
                    >
                        Calculate
                    </Button>
                </Grid>
            </ButtonWrapper>
        </ExpansionPanel>
    );

    function IncomePayableCalcButton() {
        const incomeCalculationModel: IncomeCalculationModel = {
            effectiveDate: decision && decision.effectiveDate,
            expiryDate: decision && decision.expiryDate,
            topUpAmount: incomeCalculationResults.topUpAmount,
            sumAssured: incomeCalculationResults.sumAssured,
            topUpPremium: incomeCalculationResults.topUpPremium,
            claimBoosterAmount: policyCalculation.claimBoosterAmount,
            incomeProtectorSumAssured: incomeCalculationResults.incomeProtectorSumAssured,
            incomeProtectorPremium: contractBenefit && contractBenefit.premiumAmount,
            index: incomeCalculations.findIndex(i => i.decisionId === policyCalculation.decisionId),
        };

        updateIncomePayableCalculation(incomeCalculationModel);
    }
};

const mapStateToProps = (state: State) => ({
    contracts: getLifeAssuredDetails(state).contracts,
    assessment: getAssessmentDetails(state),
    incomeCalculations: getIncomeCalculations(state),
});

const mapDispatchToProps = {
    updateIncomePayableCalculation: updateIncomePayableCalculationAction,
};

export const IncomeCalculationView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(incomeCalculationView));
