import { DeleteRecordCollectionAction } from '../../../../../../../redux/collection-reducer';
import { DELETE_UNDERWRITING_REFERRAL } from './underwriting-referral-action-types';

export type DeleteUnderwritingReferralAction = DeleteRecordCollectionAction;

export const deleteUnderwritingReferralActionCreator = (
    underwritingReferralIndex: number
): DeleteRecordCollectionAction => {
    return {
        type: DELETE_UNDERWRITING_REFERRAL,
        index: underwritingReferralIndex,
    };
};
