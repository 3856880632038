import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { v4 as uuid } from 'uuid';
import { getClaim } from '../../../../claim';
import { ClaimType } from '../../../../claim/claim-types';
import { AddFormModal } from '../../../../form-modal';
import { OpenAddModal, openAddModalAction } from '../../../../form-modal/actions';
import { ButtonWrapper } from '../../../../forms';
import { ExpansionPanel } from '../../../../forms/form-components/expansion-panel/expansion-panel';
import { isEmpty } from '../../../../forms/validations';
import {
    getLifeAssuredDetails,
    LifeAssuredContract,
} from '../../../../life-assured-details/reducer';
import { State } from '../../../../redux/root-reducer';
import {
    AddAndSubmitReinsurerReferalRecord,
    addAndSubmitReinsurerReferalRecordThunk,
} from '../../../../reinsurance-input/actions/submit-reinsurance-input';
import {
    getReinsuranceInputDetails,
    ReinsurerReferal,
} from '../../../../reinsurance-input/reducer';
import { ReinsurerReferalDetailsView } from '../../../../reinsurance-input/views/reinsurer-referal-details';
import { calculateAge } from '../../../../shared-functions';
import { Nullable } from '../../../../shared-types';
import styles, { StyledComponent } from '../../../../styles/styles';
import { ReinsurerReferralView } from './reinsurer-referral';

interface OwnProps {}
interface StateProps {
    claimType: Nullable<ClaimType>;
    contracts: LifeAssuredContract[];
    reinsurerReferrals: ReinsurerReferal[];
    isReadOnly: boolean;
}
interface DispatchProps {
    openAddModal: OpenAddModal;
    addRecord: AddAndSubmitReinsurerReferalRecord;
}

type Props = DispatchProps & OwnProps & StateProps & StyledComponent;

const ReinsurerReferalAddFormModal = AddFormModal<ReinsurerReferal>();
const reinsuranceInputView: React.FunctionComponent<Props> = ({
    addRecord,
    claimType,
    classes,
    contracts,
    openAddModal,
    reinsurerReferrals,
}: Props) => {
    const add = () => {
        openAddModal({
            modalContent: (
                <ReinsurerReferalAddFormModal
                    blankRecord={{ uniqueId: uuid() }}
                    recordDetailView={<ReinsurerReferalDetailsView />}
                    addRecord={addRecord}
                />
            ),
            modalData: {},
        });
    };
    const notifier =
        showWarning(claimType, contracts) && isEmpty(reinsurerReferrals) ? 'Warning' : '';
    return (
        <ExpansionPanel name="ReinsuranceInput" notifier={notifier}>
            <Grid container spacing={0} justify="space-between">
                <Grid item xs={10} lg={11}>
                    <Typography variant="subtitle2">Reinsurance Input Details</Typography>
                </Grid>
                <ButtonWrapper>
                    <Grid item xs={2} lg={1} className={classes.rightAlign}>
                        <Tooltip title="Add Reinsurer Referral">
                            <Button id="addReinsurerReferral" color="secondary" onClick={add}>
                                <AddCircle />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            </Grid>
            {reinsurerReferrals.map((reinsurerReferral, index) => (
                <ReinsurerReferralView
                    classes={classes}
                    index={index}
                    key={index}
                    reinsurerReferral={reinsurerReferral}
                />
            ))}
        </ExpansionPanel>
    );
};

const RGASA = [
    'RGASA',
    'SALLQ',
    'ACDIQ',
    'ACDIS',
    'ACDQ1',
    'ACDS1',
    'SADIQ',
    'SADIS',
    'SADQ1',
    'SADS1',
    'TBADQ',
    'TBADS',
    'TBSDQ',
    'TBSDS',
    'TBLLQ',
];
const SwissRe = ['SWISS', 'VICTO', 'RPBQS', 'RPBSU'];
const MunichRe = [
    'MUNIC',
    'IPPQS',
    'IPPSU',
    'RPBQS',
    'RPBSU',
    'RPBQS',
    'RPBSU',
    'IPPQ1',
    'IPPSU',
    'MORTQ',
    'MORTS',
    'MORQ1',
    'MORS1',
    'SPECQ',
    'TBMQS',
    'TBMSU',
];

function showWarning(claimType: Nullable<ClaimType>, contracts: LifeAssuredContract[]) {
    let showWarning = false;
    if (contracts !== null && contracts !== undefined && contracts.length > 0) {
        contracts.forEach(contract => {
            if (contract.benefits) {
                contract.benefits.forEach(benefit => {
                    if (benefit.startDate) {
                        const age = calculateAge(benefit.startDate);
                        if (benefit.reassuranceDetails && age < 1) {
                            benefit.reassuranceDetails.forEach(rd => {
                                // RGA SA
                                if (RGASA.includes(rd.reinsurerName || '')) {
                                    showWarning = true;
                                }
                                // Swiss Re
                                if (SwissRe.includes(rd.reinsurerName || '')) {
                                    if (claimType === ClaimType.Death) {
                                        if (rd.reassuranceType === 'FAC') {
                                            if (greaterThan(5000000)) {
                                                showWarning = true;
                                            }
                                        } else {
                                            if (greaterThan(2500000, benefit.benefitAmount)) {
                                                showWarning = true;
                                            }
                                        }
                                    }
                                    if (claimType === ClaimType.IncomeProtector) {
                                        if (rd.reassuranceType === 'FAC') {
                                            showWarning = true;
                                        }
                                        if (greaterThan(50000, benefit.benefitAmount)) {
                                            showWarning = true;
                                        }
                                    }
                                }
                                // Munich Re
                                if (MunichRe.includes(rd.reinsurerName || '')) {
                                    if (claimType === ClaimType.Death) {
                                        if (rd.reassuranceType === 'FAC') {
                                            if (greaterThan(10000000, benefit.benefitAmount)) {
                                                showWarning = true;
                                            }
                                        } else {
                                            if (greaterThan(12000000, benefit.benefitAmount)) {
                                                showWarning = true;
                                            }
                                        }
                                    } else {
                                        if (rd.reassuranceType === 'FAC') {
                                            showWarning = true;
                                        } else {
                                            if (
                                                claimType === ClaimType.Disability &&
                                                greaterThan(10000000, benefit.benefitAmount)
                                            ) {
                                                showWarning = true;
                                            }
                                            if (
                                                claimType === ClaimType.Dread &&
                                                greaterThan(2500000, benefit.benefitAmount)
                                            ) {
                                                showWarning = true;
                                            }
                                            if (
                                                claimType === ClaimType.IncomeProtector &&
                                                greaterThan(30000, benefit.benefitAmount)
                                            ) {
                                                showWarning = true;
                                            }
                                        }
                                    }
                                }
                                // SCOR SE
                                if (['SCOR1', 'SCOR2'].includes(rd.reinsurerName || '')) {
                                    if (rd.reassuranceType === 'FAC') {
                                        showWarning = true;
                                    } else {
                                        if (
                                            claimType === ClaimType.Death &&
                                            greaterThan(5000000, benefit.benefitAmount)
                                        ) {
                                            showWarning = true;
                                        }
                                        if (
                                            claimType === ClaimType.Disability &&
                                            greaterThan(5000000, benefit.benefitAmount)
                                        ) {
                                            showWarning = true;
                                        }
                                        if (
                                            claimType === ClaimType.Dread &&
                                            greaterThan(3000000, benefit.benefitAmount)
                                        ) {
                                            showWarning = true;
                                        }
                                        if (
                                            claimType === ClaimType.IncomeProtector &&
                                            greaterThan(30000, benefit.benefitAmount)
                                        ) {
                                            showWarning = true;
                                        }
                                    }
                                }
                                // Hannover Re
                                if (rd.reinsurerName === 'HOLLA') {
                                    if (rd.reassuranceType === 'FAC') {
                                        showWarning = true;
                                    } else {
                                        if (claimType === ClaimType.Death) {
                                        }
                                        if (
                                            claimType === ClaimType.Dread &&
                                            greaterThan(1000000, benefit.benefitAmount)
                                        ) {
                                            showWarning = true;
                                        }
                                    }
                                }
                            });
                        }
                    }
                });
            }
        });
    }

    return showWarning;
}

function greaterThan(comparer: number, amount?: number) {
    return amount ? amount > comparer : false;
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    claimType: getClaim(state).claimType,
    contracts: getLifeAssuredDetails(state).contracts,
    reinsurerReferrals: getReinsuranceInputDetails(state).reinsurerReferrals,
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    openAddModal: options => dispatch(openAddModalAction(options)),
    addRecord: (reinsurerReferral: ReinsurerReferal) =>
        dispatch(addAndSubmitReinsurerReferalRecordThunk(reinsurerReferral)),
});

export const ReinsuranceInputView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(reinsuranceInputView));
