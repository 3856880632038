import { isEqual } from 'lodash';
import React from 'react'; // eslint-disable-line
import { Field } from 'react-final-form';

interface Props {
    field: string;
    toField: string;
    transform?: (value: any) => any;
}

export const BindFieldToField: React.FunctionComponent<Props> = ({
    field,
    toField,
    transform = value => value,
}: Props) => (
    <Field name={toField} subscription={{ value: true }}>
        {({ input: { value } }) => (
            <Field name={field} subscription={{ value: true }}>
                {({ input: { onChange, value: originalVaue } }) => {
                    const transformedValue = transform(value);
                    return (
                        <UpdateComponent
                            originalValue={originalVaue}
                            value={transformedValue}
                            onChange={onChange}
                        />
                    );
                }}
            </Field>
        )}
    </Field>
);

interface UpdateComponentProps {
    originalValue: any;
    value: any;
    onChange: <T>(event: React.ChangeEvent<T> | any) => void;
}
class UpdateComponent extends React.Component<UpdateComponentProps> {
    public componentDidMount() {
        this.updateValues();
    }

    public componentDidUpdate() {
        this.updateValues();
    }

    public render() {
        return null;
    }

    private updateValues() {
        const { onChange, originalValue, value }: UpdateComponentProps = this.props;
        if (!isEqual(value, originalValue)) {
            onChange(value);
        }
    }
}
