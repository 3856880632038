import { AuthGuard } from '../../auth/authGuard';
import { ClaimTypeSelectionView } from '../../initiate-claim/views/claim-type-selection';
import { ClientSearchView } from '../../initiate-claim/views/client-search';
import { ClientSelectionView } from '../../initiate-claim/views/clients-selection';
import { DetermineFlowView } from '../../initiate-claim/views/determine-flow';
import { FuneralClientSelectionView } from '../../initiate-claim/views/funeral-flow/funeral-clients-selection';
import { FuneralClientSearchView } from '../../initiate-claim/views/funeral-flow/funeral-client-search';
import { WorkflowScreen } from '../../workflow-navigation/reducers/workflow-screen';

export const DevTeamGuard = AuthGuard(['DevTeam']);
export const AgentGuard = AuthGuard(['Agent']);
export const ScreenGuard = AuthGuard(['Agent', 'CentralPayments']);

export const ScreenIndex = {
    InitiateClaim: {
        ClaimProcess: new WorkflowScreen('Claim Process', DetermineFlowView),
        ClientSearch: new WorkflowScreen('Client Search', ClientSearchView),
        FuneralClientSearch: new WorkflowScreen('Client Search', FuneralClientSearchView),
        ClientSelection: new WorkflowScreen('Client Selection', ClientSelectionView),
        FuneralClientSelection: new WorkflowScreen('Client Selection', FuneralClientSelectionView),
        ClaimTypeSelection: new WorkflowScreen('Claim Type Selection', ClaimTypeSelectionView),
    },
};
