import axios from 'axios';
import { env } from '../../env';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { clientsLoadedThunkAction } from './clients-loaded';
import { FindClientsByPolicyCriteria } from './find-clients-by-policy';

const searchUi = `${env().CLAIMS_API}/api/Personality/FindFuneralClients`;

type FindFuneralClientsReturn = Promise<any>;
export type FindFuneralClients = (
    criteria: FindClientsByPolicyCriteria
) => FindFuneralClientsReturn;
type FindFuneralClientsAction = (
    criteria: FindClientsByPolicyCriteria
) => ReduxThunkAction<FindFuneralClientsReturn>;
export const findFuneralClientsAction: FindFuneralClientsAction = (
    criteria: FindClientsByPolicyCriteria
) => {
    return async dispatch => {
        const response = await axios.get(searchUi, {
            params: { policyNumber: criteria.policyNumber },
        });
        if (!response.data || response.data.length <= 0) {
            throw new Error(
                'No clients with valid benefits to continue the claims process were found.'
            );
        }
        dispatch(
            clientsLoadedThunkAction(
                response.data,
                { ...criteria, searchBy: 'Policy Number' },
                true
            )
        );
        return;
    };
};
