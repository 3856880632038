import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { GenericForm } from '../../../../forms';
import {
    SaveClaim,
    saveClaimAction,
} from '../../../../retrospective-underwriting-decision/actions/submit';
import FinalDecisionDetailsGridView from '../../../../retrospective-underwriting-decision/views/final-decision-details-grid';
import styles, { StyledComponent } from '../../../../styles/styles';
import { OnSubmitSuccessProps } from '../../../../workflow-navigation';
import { RetrospectiveUnderwritingDecision } from '../../../../retrospective-underwriting-decision/reducer';

type Props = OnSubmitSuccessProps &
    StyledComponent & {
        saveClaim: SaveClaim;
    };

const RetrospectiveUnderwritingDecisionForm = GenericForm<RetrospectiveUnderwritingDecision>();
const retrospectiveUnderwritingDecisionView: React.FunctionComponent<Props> = ({
    classes,
    saveClaim,
    ...rest
}: Props) => {
    return (
        <RetrospectiveUnderwritingDecisionForm onSubmit={() => saveClaim()} {...rest}>
            <FinalDecisionDetailsGridView classes={classes} />
        </RetrospectiveUnderwritingDecisionForm>
    );
};

const mapDispatchToProps = {
    saveClaim: saveClaimAction,
};

export const FinalUnderwritingDecisionForm = connect(
    () => ({}),
    mapDispatchToProps
)(withStyles(styles)(retrospectiveUnderwritingDecisionView));
