import { AddRecordCollectionAction } from '../../../../../redux/collection-reducer';
import { InformationRecord } from '../reducer';
import { ADD_INFORMATION_RECORD } from '../types';

export type AddInformationRecordAction = AddRecordCollectionAction<InformationRecord>;

export const addInformationRecordActionCreator = (
    addInformationRecord: InformationRecord
): AddInformationRecordAction => {
    return {
        type: ADD_INFORMATION_RECORD,
        record: addInformationRecord,
    };
};
