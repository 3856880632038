/* eslint-disable react/prop-types */
import { withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { ExpansionPanel } from '../../../../../../forms/form-components/expansion-panel/expansion-panel';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { PersonalDetailsView } from '../../details/personal-detail';
import { AddressDetailsView } from '../../details/address-details';
import { ContactDetailsView } from '../../details/contact-details';
import { MedicalInformationDetailsView } from '../../details/medical-information';
import { DoctorPracticeDetailsView } from '../../details/doctor-practice-details';
import { MedicalAidDetailsView } from '../../details/medical-aid-details';
import { OtherDetailsView } from '../../details/other-details';
import { FormDetails } from '../../form-details-reducer';

interface BaseProps {
    formDetails?: FormDetails;
    disableEditButton?: boolean;
}

interface Props extends StyledComponent, BaseProps {}

const lifeAssuredDetailsView: React.FunctionComponent<Props> = ({
    formDetails,
    disableEditButton,
}) => {
    return (
        <ExpansionPanel name="Life Assured details" style={{ marginLeft: -24, marginRight: -24 }}>
            <React.Fragment>
                <PersonalDetailsView
                    type="LifeAssured"
                    formDetails={formDetails}
                    disableEditButton={disableEditButton}
                />
                <AddressDetailsView
                    type="LifeAssured"
                    formDetails={formDetails}
                    disableEditButton={disableEditButton}
                />
                <ContactDetailsView
                    type="LifeAssured"
                    formDetails={formDetails}
                    disableEditButton={disableEditButton}
                />
                <MedicalInformationDetailsView
                    formDetails={formDetails}
                    disableEditButton={disableEditButton}
                />
                <DoctorPracticeDetailsView
                    formDetails={formDetails}
                    disableEditButton={disableEditButton}
                />
                <MedicalAidDetailsView
                    formDetails={formDetails}
                    disableEditButton={disableEditButton}
                />
                <OtherDetailsView formDetails={formDetails} disableEditButton={disableEditButton} />
            </React.Fragment>
        </ExpansionPanel>
    );
};

export const LifeAssuredDetailsView = withStyles(styles)(lifeAssuredDetailsView);
