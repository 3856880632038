import { Grid } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { Gender } from '../../../../../codes/gender';
import { MaritalStatus } from '../../../../../codes/marital-statuses';
import { Titles } from '../../../../../codes/titles';
import {
    BindFieldToField,
    Condition,
    False,
    FullWidthDatePicker,
    FullWidthSearchSelectField,
    FullWidthTextField,
    True,
} from '../../../../../forms';
import {
    FirstNameMask,
    InitialsMask,
    LastNameMask,
    NumberMask,
} from '../../../../../forms/input-masks';
import { AddressDetailsSubForm } from '../../../../../forms/sub-forms/address-details';
import {
    composeValidators,
    conditionalRequired,
    requiredValidator,
    rsaIdNumberValidator,
} from '../../../../../forms/validations';
import {
    convertToUpperCase,
    defaultAgeFromDateOfBirth,
    defaultDateOfBirth,
    getDifferenceBetweenDates,
} from '../../../../../shared-functions';

export const PersonalDetailsEditView = () => {
    return (
        <Grid container spacing={5} justify="flex-start">
            <Grid item xs={6}>
                <FullWidthSearchSelectField name="personalDetails.title" options={Titles} />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name="personalDetails.initials"
                    mask={InitialsMask}
                    parse={convertToUpperCase}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name="personalDetails.firstName"
                    validate={requiredValidator}
                    mask={FirstNameMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField name="personalDetails.secondName" mask={FirstNameMask} />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name="personalDetails.lastName"
                    label="Surname"
                    validate={requiredValidator}
                    mask={LastNameMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name="personalDetails.idNumber"
                    label="ID Number"
                    validate={composeValidators(
                        conditionalRequired(
                            values => values.personalDetails.idType === 'ID Number'
                        ),
                        rsaIdNumberValidator
                    )}
                    mask={NumberMask}
                />
            </Grid>
            <Grid item xs={4}>
                <FullWidthDatePicker
                    name="personalDetails.dateOfBirth"
                    validate={requiredValidator}
                    disableFuture
                />
                <Condition when="personalDetails.dateOfBirth" hasValue>
                    <False>
                        <BindFieldToField
                            field="personalDetails.dateOfBirth"
                            toField="personalDetails.idNumber"
                            transform={defaultDateOfBirth}
                        />
                        <BindFieldToField
                            field="personalDetails.age"
                            toField="personalDetails.dateOfBirth"
                            transform={undefined}
                        />
                    </False>
                    <True>
                        <Condition when="personalDetails.dateOfDeath" hasValue>
                            <True>
                                <BindFieldToField
                                    field="personalDetails.age"
                                    toField="personalDetails"
                                    transform={values => {
                                        if (values) {
                                            return getDifferenceBetweenDates(
                                                values.dateOfBirth,
                                                values.dateOfDeath
                                            );
                                        }
                                    }}
                                />
                            </True>
                            <False>
                                <BindFieldToField
                                    field="personalDetails.age"
                                    toField="personalDetails.dateOfBirth"
                                    transform={defaultAgeFromDateOfBirth}
                                />
                            </False>
                        </Condition>
                    </True>
                </Condition>
            </Grid>
            <Grid item xs={2}>
                <FullWidthTextField label="Age at death" name="personalDetails.age" disabled />
            </Grid>
            <Grid item xs={6}>
                <FullWidthDatePicker
                    name="personalDetails.dateOfDeath"
                    validate={requiredValidator}
                    label="Date of Death"
                    disableFuture
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    name="personalDetails.gender"
                    validate={requiredValidator}
                    options={Gender}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    name="personalDetails.maritalStatus"
                    options={MaritalStatus}
                    label="Marital Status"
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name="personalDetails.clientId"
                    label="PDB ID"
                    validate={requiredValidator}
                    mask={NumberMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name="personalDetails.compassClientId"
                    validate={requiredValidator}
                    mask={NumberMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField name="personalDetails.lifeAssuredRole" disabled />
            </Grid>
        </Grid>
    );
};

export const AddressEditView = () => {
    return (
        <Grid container spacing={5}>
            <AddressDetailsSubForm addressPropertyName="addressDetails" addressType="street" />
        </Grid>
    );
};
