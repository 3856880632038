import {
    assessmentDetailsReducer,
    PolicyAssessmentDetails,
} from '../../../assessment/decisions/assessment-details-reducer';
import {
    IncomeCalculation,
    incomeCalculationsReducer,
} from '../../../assessment/income-calculator/reducer';
import {
    ClaimDetails,
    claimDetailsReducer,
} from '../../../assessment/synopsis/common/claim-details/claim-details-reducer';
import { ClaimInformation, claimInformationReducer } from '../../../claim-information/reducer';
import { ClaimContactDetails, contactDetailsReducer } from '../../../contact-details/reducer';
import { DisabilityDetails, disabilityDetailsReducer } from '../../../disability-details/reducer';
import { doctorDetailsReducer, DoctorsDetails } from '../../../doctor-details/reducer';
import {
    ForensicInput,
    forensicInputReducer,
} from '../../../assessment/synopsis/common/forensic-input/reducer';
import {
    IncomeManagementPlan,
    incomeManagementPlanReducer,
} from '../../../income-management-plan/reducer';
import {
    LifeAssuredDetails,
    lifeAssuredDetailsReducer,
    PolicyOwnerDetails,
    PolicyOwnerOrganizationDetails,
} from '../../../life-assured-details/reducer';
import {
    MedicalOfficerInput,
    medicalOfficerInputReducer,
} from '../../../medical-officer-input/reducer';
import {
    policyOwnerDetailsReducer,
    policyOwnerOrganizationDetailsReducer,
} from '../../../assessment/synopsis/common/policy-owner/policy-owner-reducer';
import { combineReducersWithDefault } from '../../../redux/combine-reducers-with-default';
import {
    ReinsuranceInputDetails,
    reinsuranceInputDetailsReducer,
} from '../../../reinsurance-input/reducer';
import {
    RetrospectiveUnderwritingDecision,
    retrospectiveUnderwritingDecisionReducer,
} from '../../../retrospective-underwriting-decision/reducer';
import { Nullable } from '../../../shared-types';
import { BaseClaim, baseClaimReducers, initialBaseClaim } from './base-claim-reducer';
import {
    underwritingReducer,
    Underwriting,
} from '../../../assessment/synopsis/common/underwriting/underwriting-reducer';
import {
    InformationRecordSummary,
    informationRecordSummaryReducer,
} from '../../../assessment/synopsis/common/information-record/reducer';
import {
    InformationReceivedSummary,
    informationReceivedSummaryReducer,
} from '../../../information-received-summary/reducer';
import {
    CommonMedicalHistory,
    commonMedicalHistoryReducer,
} from '../../../assessment/synopsis/common/medical-consultation/reducer';
import {
    AdditionalInformationRequired,
    additionalInformationRequiredReducer,
} from '../../../assessment/synopsis/common/additional-information-requested/reducer';

export interface CommonClaim extends BaseClaim {
    readonly contactDetails?: Nullable<ClaimContactDetails>;
    readonly policyOwners: PolicyOwnerDetails[];
    readonly policyOwnerOrganizations: PolicyOwnerOrganizationDetails[];
    readonly claimDetails?: Nullable<ClaimDetails>;
    readonly doctorDetails?: Nullable<DoctorsDetails>;
    readonly disabilityDetails?: Nullable<DisabilityDetails>;
    readonly informationRecordSummary?: Nullable<InformationRecordSummary>;
    readonly additionalInformationRequired?: Nullable<AdditionalInformationRequired>;
    readonly informationReceivedSummary?: Nullable<InformationReceivedSummary>;
    readonly incomeManagementPlan?: Nullable<IncomeManagementPlan>;
    readonly reinsuranceInputDetails?: Nullable<ReinsuranceInputDetails>;
    readonly retrospectiveUnderwritingDecision?: Nullable<RetrospectiveUnderwritingDecision>;
    readonly assessmentDetails: PolicyAssessmentDetails[];
    readonly lifeAssuredDetails?: Nullable<LifeAssuredDetails>;
    readonly medicalOfficerInput?: Nullable<MedicalOfficerInput>;
    readonly forensicInput?: Nullable<ForensicInput>;
    readonly claimInformation?: Nullable<ClaimInformation>;
    readonly underwriting?: Nullable<Underwriting>;
    readonly commonMedicalHistory?: Nullable<CommonMedicalHistory>;
}

export interface RetrenchmentClaim extends CommonClaim {
    incomeCalculations?: Nullable<IncomeCalculation[]>;
}

export const initialCommonClaim: CommonClaim = {
    ...initialBaseClaim,
    policyOwners: [],
    policyOwnerOrganizations: [],
    assessmentDetails: [],
};

export const commonClaimReducers = {
    ...baseClaimReducers,
    contactDetails: contactDetailsReducer,
    policyOwners: policyOwnerDetailsReducer,
    policyOwnerOrganizations: policyOwnerOrganizationDetailsReducer,
    claimDetails: claimDetailsReducer,
    doctorDetails: doctorDetailsReducer,
    disabilityDetails: disabilityDetailsReducer,
    informationRecordSummary: informationRecordSummaryReducer,
    additionalInformationRequired: additionalInformationRequiredReducer,
    informationReceivedSummary: informationReceivedSummaryReducer,
    incomeManagementPlan: incomeManagementPlanReducer,
    reinsuranceInputDetails: reinsuranceInputDetailsReducer,
    retrospectiveUnderwritingDecision: retrospectiveUnderwritingDecisionReducer,
    assessmentDetails: assessmentDetailsReducer,
    lifeAssuredDetails: lifeAssuredDetailsReducer,
    medicalOfficerInput: medicalOfficerInputReducer,
    forensicInput: forensicInputReducer,
    claimInformation: claimInformationReducer,
    underwriting: underwritingReducer,
    commonMedicalHistory: commonMedicalHistoryReducer,
};

export const retrenchmentClaimReducer = combineReducersWithDefault<RetrenchmentClaim>(
    initialCommonClaim,
    { ...commonClaimReducers, incomeCalculations: incomeCalculationsReducer }
);
