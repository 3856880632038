import { WorkflowStep } from '../../workflow-navigation/reducers/workflow-step';
import { ScreenGuard } from './screen-index';
import { WorkflowScreen } from '../../workflow-navigation/reducers/workflow-screen';
import { ClaimWorkflow } from './claim-workflow';
import { ClaimType } from '../../claim/claim-types';
import { FuneralSynopsis } from '../../assessment/synopsis/funeral/funeral-synopsis';
import { WorkflowUtilityScreen } from '../../workflow-navigation/reducers/workflow-utility-screen';
import { WorkflowAssessorScreen } from '../../workflow-navigation/reducers/workflow-assessor';
import { ClaimAuditTrailView } from '../../claim-audit-trail';
import { PaymentsView } from '../../payment';
import { DashboardFlowView } from '../../assesor-dashboard/views/dashboard/assessor-dashboard';
import { FuneralAssessmentView } from '../../assessment/decisions/funeral/views/funeral-assessment-view';
import { AuthGuard } from '../../auth/authGuard';

const screenGuardCapture = AuthGuard(['Agent', 'CentralPayments']);
const assessorDashboardGuard = AuthGuard(['AssessorDashboard']);

export const funeralWorkflow = new ClaimWorkflow(
    'Funeral',
    [
        new WorkflowStep('Initiate Claim', []),
        new WorkflowStep('Assessment', [
            new WorkflowScreen('Synopsis', FuneralSynopsis, undefined, undefined, ScreenGuard),
            new WorkflowScreen(
                'Decisions',
                FuneralAssessmentView,
                undefined,
                undefined,
                screenGuardCapture
            ),
        ]),
        new WorkflowStep('Payments', [
            new WorkflowScreen('Payments', PaymentsView, undefined, undefined, screenGuardCapture),
        ]),
        new WorkflowAssessorScreen(
            'Assessor List',
            'Assessor limits dashboard',
            DashboardFlowView,
            undefined,
            'Assessor limits dashboard',
            assessorDashboardGuard
        ),
        new WorkflowUtilityScreen(
            'Claim Audit Trail',
            'list',
            ClaimAuditTrailView,
            undefined,
            undefined,
            screenGuardCapture
        ),
    ],
    claim => claim.claimType === ClaimType.Funeral,
    undefined,
    ScreenGuard
);
