import { maskArray } from 'react-text-mask';

//const digitRegex = /^\d*\.?\d+$/;
const digitRegex = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/;
export const NumberMask: (value: string) => maskArray = (value: string) => {
    const numberMask: maskArray = [digitRegex];
    return numberMask.concat(
        value
            .split('')
            .slice(1)
            .filter(c => digitRegex.test(c))
            .map(c => digitRegex)
    );
};
