import { Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { FormHeadingStyledComponent, formHeadingStyles } from './form-heading-styles';

interface Props extends FormHeadingStyledComponent {
    text?: string;
    children?: React.ReactNode;
}

const formHeading: React.FunctionComponent<Props> = ({ classes, text, children }: Props) => {
    return (
        <React.Fragment>
            <Typography variant="h6" className={classes.formHeading}>
                {text || children}
            </Typography>
        </React.Fragment>
    );
};

export const FormHeading = withStyles(formHeadingStyles)(formHeading);
