import React, { useState, useEffect } from 'react'; // eslint-disable-line
import { Grid, Button, Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import SearchIcon from '@material-ui/icons/Search';
import { ExpansionPanel } from '../../../forms/form-components/expansion-panel/expansion-panel';
import { AssessorExpandedFormView } from './assessor-expanded-details';
import { StyledComponent } from '../../../styles/styles';
import { Assessors } from '../../reducer/assessors-reducer';
import { IconTextField } from '../../../forms/form-components/icon-text-field/icon-text-field';
import { OpenModal } from '../../../form-modal/actions';

import { FormModalView } from '../../../form-modal/views/form-modal';
import AssessorDashboardASSearch from './assessor-dashord-ad-search';

interface OwnProps {
    assessorLimits: Array<Assessors>;
    assessorUpdated: boolean;
    classes: any;
    openModal: OpenModal;
    editAssessor?: any;
}

type Props = OwnProps & StyledComponent;

const AssessorDashboardWithDetail: React.FunctionComponent<Props> = ({
    classes,
    assessorLimits,
    assessorUpdated,
    openModal,
    editAssessor,
}: Props) => {
    const [isMounted, setIsMounted] = useState(false);
    function addAssessor() {
        editAssessor();
        setIsMounted(false);
        openModal({
            modalContent: (
                <FormModalView
                    formStyle={classes.adSearch}
                    isButtonsHidden={true}
                    formView={() => <AssessorDashboardASSearch classes={classes} />}
                />
            ),
        });
    }

    const [index, setIndex] = useState(1);
    const [filteredData, setFilteredData] = useState<Assessors[] | []>([]);
    const [isFiltered, setIsFiltered] = useState(false);
    const [isSearch, setIsSearch] = useState(false);

    useEffect(() => {
        if (!isMounted) {
            filterData(2, true);
        } else {
            filterData(index, isFiltered);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assessorUpdated]);

    const filterData = (idx: number, filter: boolean) => {
        setIsMounted(true);
        setIndex(idx);
        setIsFiltered(filter);

        if (idx === 2) {
            const UpdatedAssessors = assessorLimits.filter(
                assessor => assessor.status.toLowerCase() === 'active'
            );
            setFilteredData(UpdatedAssessors);
        } else if (idx === 3) {
            const UpdatedAssessors = assessorLimits.filter(
                assessor => assessor.status.toLowerCase() === 'inactive'
            );
            setFilteredData(UpdatedAssessors);
        }
    };

    const onChange = (value: string) => {
        if (value === '') {
            setIsSearch(false);
            filterData(index, index === 1 ? false : true);
        } else {
            setIsSearch(true);
            setIsFiltered(true);

            const UpdatedAssessors: any = [];
            const lowCaseValue = value.toLowerCase();

            assessorLimits.map(assessor => {
                const fullName: string = assessor.name.trimStart() + ' ' + assessor.surname;

                if (
                    assessor.name.toLowerCase().startsWith(lowCaseValue) ||
                    assessor.surname.toLowerCase().startsWith(lowCaseValue) ||
                    fullName.toLowerCase().startsWith(lowCaseValue)
                ) {
                    UpdatedAssessors.push(assessor);
                }
                return UpdatedAssessors;
            });

            setFilteredData(UpdatedAssessors);
        }
    };

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignContent="center"
            style={{ marginBottom: 40 }}
        >
            <Grid container item xs={12}>
                <Grid container item xs={6} justify="flex-start">
                    <IconTextField
                        classes={classes}
                        label="Search assessors"
                        textStyle={{ height: 80, width: '65%' }}
                        onChange={onChange}
                        iconStart={<SearchIcon fontSize="small" color="disabled" />}
                    />
                </Grid>
                <Grid container item xs={6} justify="flex-end">
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.addNewAssessorButton}
                        onClick={addAssessor}
                    >
                        Add New Assessor
                    </Button>
                </Grid>
            </Grid>
            {!isSearch && (
                <Grid container className={classes.assessorFilter}>
                    <Link
                        onClick={() => {
                            filterData(1, false);
                        }}
                        underline="none"
                        component="button"
                        className={
                            index === 1 ? classes.filterTextActive : classes.filterTextInactive
                        }
                    >
                        All
                    </Link>
                    <Link
                        onClick={() => {
                            filterData(2, true);
                        }}
                        underline="none"
                        component="button"
                        className={
                            index === 2 ? classes.filterTextActive : classes.filterTextInactive
                        }
                    >
                        Active
                    </Link>
                    <Link
                        onClick={() => {
                            filterData(3, true);
                        }}
                        underline="none"
                        component="button"
                        className={
                            index === 3 ? classes.filterTextActive : classes.filterTextInactive
                        }
                    >
                        Inactive
                    </Link>
                </Grid>
            )}
            <Grid item xs={12} className={classes.assessorAccordions}>
                {isFiltered && filteredData.length === 0 && (
                    <Typography className={classes.noUserFoundText}>No results found</Typography>
                )}
                {(isFiltered ? filteredData : assessorLimits).map((assessor: any) => (
                    <ExpansionPanel
                        key={assessor._id}
                        name={`${assessor.name} ${assessor.surname}`}
                        label={`${assessor.name} ${assessor.surname}`}
                        icon={<Icon classes={classes} assessor={assessor} />}
                    >
                        <AssessorExpandedFormView
                            classes={classes}
                            assessor={assessor}
                            openModal={openModal}
                            assessorUpdated={assessorUpdated}
                        />
                    </ExpansionPanel>
                ))}
            </Grid>
        </Grid>
    );
};
interface IconProps {
    assessor: Assessors;
    classes: any;
}

const Icon = ({ classes, assessor }: IconProps) => {
    const name = assessor.name.trimStart().charAt(0);
    const surname = assessor.surname.trimStart().charAt(0);
    const initials = `${name}${surname}`;

    return (
        <Grid container className={classes.accordionIcon}>
            <Typography
                style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    fontSize: 12,
                }}
            >
                {initials}
            </Typography>
        </Grid>
    );
};

export default AssessorDashboardWithDetail;
