import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { ButtonWrapper } from '../../../../../forms';
import { State } from '../../../../../redux/root-reducer';
import styles from '../../../../../styles/styles';
import { VerifyAccount, verifyAccountAction } from '../actions/verify-account';
import { BankResponse } from '../banking-details';

interface BankResponseProps {
    bankResponse?: BankResponse;
    beneficiaryId?: string;
    isReadOnly: boolean;
    verifyAccount: VerifyAccount;
}

const viewBankResponse = ({
    bankResponse,
    beneficiaryId,
    isReadOnly,
    verifyAccount,
}: BankResponseProps) => {
    return (
        <React.Fragment>
            <Grid item xs={12} container justify="flex-start">
                <Typography variant="subtitle1" style={{ fontSize: '20px', marginTop: '1px' }}>
                    Banking details - AVS
                </Typography>
                <ButtonWrapper>
                    <Tooltip title="Verify bank account">
                        <Button
                            id="VerifyAccount"
                            color="secondary"
                            style={{ textTransform: 'none' }}
                            onClick={() => verifyAccount(beneficiaryId)}
                        >
                            <Refresh />
                            &nbsp;Verify bank account
                        </Button>
                    </Tooltip>
                </ButtonWrapper>
            </Grid>
            {bankResponse && (
                <React.Fragment>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="surname/company Name" value={bankResponse.nameMatch} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Initials" value={bankResponse.initialsMatch} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Identity/Registration number"
                            value={bankResponse.idRegistrationMatch}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Account open for > 3 months"
                            value={bankResponse.accountOpen3Months}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Account found" value={bankResponse.accountFound} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Account type" value={bankResponse.accountTypeMatch} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Account open" value={bankResponse.accountOpen} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Accepts debits (collections)"
                            value={bankResponse.acceptsDebits}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Accepts credits (payments)"
                            value={bankResponse.acceptsCredits}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Cellphone number"
                            value={bankResponse.cellPhoneNumberMatch}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Email address"
                            value={bankResponse.emailAddressMatch}
                        />
                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state: State) => ({
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = { verifyAccount: verifyAccountAction };

export const ViewBankResponse = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(viewBankResponse));
