import React from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import {
    Condition,
    FullWidthCheckBoxField,
    RaDeathWrapper,
    True,
    WhenFieldChanges,
} from '../../../../../forms';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { BankingDetailsView } from '../../../common/banking-details/views/edit-banking-details';

interface BankProps extends StyledComponent {
    readonly propertyPrefix?: string;
    readonly isAnOrganisation?: boolean;
}

const bankDetails: React.FunctionComponent<BankProps> = ({
    propertyPrefix,
    isAnOrganisation,
}: BankProps) => {
    return (
        <Grid container spacing={5} justify="flex-start">
            <React.Fragment>
                <RaDeathWrapper>
                    <Grid item xs={12}>
                        <FullWidthCheckBoxField
                            name="hasBankingDetails"
                            label="Add banking details"
                        />

                        <WhenFieldChanges
                            field="hasBankingDetails"
                            set={`${propertyPrefix}`}
                            to={null}
                        />
                        <WhenFieldChanges
                            field="hasBankingDetails"
                            set={`${propertyPrefix}.bankAccountStatus`}
                            becomes={true}
                            to="Not verified"
                        />
                    </Grid>
                    <Condition when="hasBankingDetails" is={true}>
                        <True>
                            <Grid item xs={12}>
                                <BankingDetailsView
                                    propertyPrefix={propertyPrefix}
                                    isAnOrganisation={isAnOrganisation}
                                />
                            </Grid>
                        </True>
                    </Condition>
                </RaDeathWrapper>
            </React.Fragment>
        </Grid>
    );
};

export const BankDetailsEditView = withStyles(styles)(bankDetails);
