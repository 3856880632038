import { collectionReducer } from '../../../../../../redux/collection-reducer/collection-reducer';
import {
    ADD_UNDERWRITING_REFERRAL,
    DELETE_UNDERWRITING_REFERRAL,
    UPDATE_UNDERWRITING_REFERRAL,
} from './actions/underwriting-referral-action-types';
import { UnderwritingReferral } from './underwriting-referral-model';

export const underwritingReferralsReducer = collectionReducer<UnderwritingReferral>({
    addAction: ADD_UNDERWRITING_REFERRAL,
    updateAction: UPDATE_UNDERWRITING_REFERRAL,
    deleteAction: DELETE_UNDERWRITING_REFERRAL,
});
