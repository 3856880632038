import { Grid, withStyles, Typography, Tooltip, Button, Paper } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { connect, MapDispatchToProps } from 'react-redux';
import { State } from '../../../../../../redux/root-reducer';
import {
    OpenEditModal,
    openEditModalAction,
    OpenAddModal,
    openAddModalAction,
} from '../../../../../../form-modal/actions';
import { Edit, Delete, AddCircle } from '@material-ui/icons';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { EditFormModal, AddFormModal } from '../../../../../../form-modal';

import {
    DeleteAndSubmitDoctorRecord,
    deleteAndSubmitDoctorRecordThunk,
    AddAndSubmitDoctorRecord,
    addAndSubmitDoctorRecordThunk,
    UpdateAndSubmitDoctorRecord,
    updateAndSubmitDoctorRecordThunk,
} from '../../actions/update-medical-history';
import { OpenDialog, openDialogAction } from '../../../../../../confirmation-dialog';
import { ExpansionPanel } from '../../../../../../forms/form-components/expansion-panel/expansion-panel';
import { DoctorsConsultationView } from './doctors-consultation-view';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import { MedicalHistoryDoctorsEdit } from './medical-history-doctors-edit';
import { Doctor, getMedicalDoctorHistoryDetails, MedicalHistory } from '../../reducer';
import { DisplayAddress } from '../../../../../../forms/sub-forms/address-details';
import { ButtonWrapper } from '../../../../../../forms';

interface DispatchProps {
    addDoctorAction: AddAndSubmitDoctorRecord;
    openAddModal: OpenAddModal;
    openDialog: OpenDialog;
    openEditModal: OpenEditModal;
    updateDoctorAction: UpdateAndSubmitDoctorRecord;
    deleteDoctorAction: DeleteAndSubmitDoctorRecord;
}

interface OwnProps {}

type Props = StyledComponent &
    OwnProps &
    DispatchProps & {
        medicalHistory: MedicalHistory;
        isReadOnly: boolean;
    };

const EditDoctorFormModal = EditFormModal<Doctor>();
const AddDoctorFormModal = AddFormModal<Doctor>();
const medicalHistoryDoctorsView: React.FunctionComponent<Props> = ({
    addDoctorAction: addDoctor,
    classes,
    medicalHistory,
    openEditModal,
    updateDoctorAction,
    deleteDoctorAction,
    openDialog,
    openAddModal,
    isReadOnly,
}: Props) => {
    function add() {
        openAddModal({
            modalContent: (
                <AddDoctorFormModal
                    recordDetailView={<MedicalHistoryDoctorsEdit add />}
                    addRecord={record => addDoctor(record)}
                />
            ),
            modalData: {},
        });
    }
    return (
        <React.Fragment>
            <Grid container justify="space-between" className={classes.marginTop2}>
                <Grid container item xs={10} alignItems="center">
                    <Typography variant="subtitle2">Doctors Details</Typography>
                </Grid>
                <ButtonWrapper>
                    <Grid container item xs={2} alignItems="center" justify="flex-end">
                        <Tooltip title="Add Doctors Details">
                            <Button id="AddMedicalHistory" color="secondary" onClick={add}>
                                <AddCircle />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            </Grid>
            <Paper className={classes.paper} style={{ paddingBottom: '16px' }}>
                {medicalHistory.doctorsDetails.length === 0 ? (
                    <Typography>No Medical History Details</Typography>
                ) : (
                    medicalHistory.doctorsDetails.map((doctor, index) => {
                        return (
                            <ExpansionPanel
                                name={`${doctor.initials} ${doctor.surname}`}
                                key={index}
                                style={{ textTransform: 'capitalize' }}
                            >
                                <Grid container justify="space-between">
                                    <Grid item xs={2}>
                                        <LabeledValue label="Name" value={doctor.name} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <LabeledValue label="Surname" value={doctor.surname} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <LabeledValue
                                            label="Contact number"
                                            value={doctor.telephoneNumber}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <DisplayAddress label="Address" address={doctor} />
                                    </Grid>
                                    <ButtonWrapper>
                                        <Grid item xs={2} className={classes.rightAlign}>
                                            <Tooltip title="Edit Doctors Details">
                                                <Button
                                                    id="EditDoctorsDetails"
                                                    color="secondary"
                                                    onClick={editMedicalHistory}
                                                >
                                                    <Edit />
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="Delete Medical Details">
                                                <Button
                                                    id="DeleteDoctorsDetails"
                                                    color="secondary"
                                                    onClick={deleteDoctor}
                                                >
                                                    <Delete />
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                    </ButtonWrapper>
                                </Grid>
                                <DoctorsConsultationView doctor={doctor} index={index} />
                            </ExpansionPanel>
                        );
                        function editMedicalHistory() {
                            openEditModal({
                                modalContent: (
                                    <EditDoctorFormModal
                                        recordDetailView={<MedicalHistoryDoctorsEdit />}
                                        records={medicalHistory.doctorsDetails}
                                        updateRecord={(doctor, ind) =>
                                            updateDoctorAction(ind, doctor)
                                        }
                                    />
                                ),
                                modalData: {
                                    recordIndex: index,
                                },
                            });
                        }
                        function deleteDoctor() {
                            openDialog({
                                content: {
                                    title: `Delete Medical Doctor's Record`,
                                    body:
                                        'Are you sure you would like to delete the selected medical doctor?',
                                },
                                yesAction: {
                                    action: () => deleteDoctorAction(index),
                                },
                            });
                        }
                    })
                )}
            </Paper>
        </React.Fragment>
    );
};

const mapStateToProps = (state: State) => ({
    medicalHistory: getMedicalDoctorHistoryDetails(state),
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    addDoctorAction: doctor => dispatch(addAndSubmitDoctorRecordThunk(doctor)),
    openEditModal: options => dispatch(openEditModalAction(options)),
    updateDoctorAction: (doctorIndex: number, doctor: Doctor) =>
        dispatch(updateAndSubmitDoctorRecordThunk(doctorIndex, doctor)),
    deleteDoctorAction: (doctorIndex: number) =>
        dispatch(deleteAndSubmitDoctorRecordThunk(doctorIndex)),
    openDialog: options => dispatch(openDialogAction(options)),
    openAddModal: options => dispatch(openAddModalAction(options)),
});

export const MedicalHistoryDoctorsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(medicalHistoryDoctorsView));
