import { Grid } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { FullWidthDatePicker, FullWidthTextField, WhenFieldChanges } from '../../../../forms';
import { CurrencyMask } from '../../../../forms/input-masks';
import { PercentageMask } from '../../../../forms/input-masks/percentage-mask';
import { composeValidators, maxValue, requiredValidator } from '../../../../forms/validations';
import { convertToNumber } from '../../../../shared-functions';
import {
    AddAssessmentDecisionProps,
    AssessmentDecisionAddView,
} from '../../common/views/assessment-decision-add';

export const TerminalIllnessAssessmentDecisionDetailView: React.FunctionComponent<AddAssessmentDecisionProps> = props => {
    return (
        <AssessmentDecisionAddView
            {...props}
            claimSpecificApproveElements={<TerminalIllnessApproveElements {...props} />}
        />
    );
};

interface Props {
    noPartialPayment: boolean;
    sumAssured?: number;
}

export function TerminalIllnessApproveElements({
    noPartialPayment,
    sumAssured,
}: Props): React.ReactElement<Props> {
    return (
        <React.Fragment>
            <Grid item xs={4}>
                <FullWidthDatePicker name="effectiveDate" validate={requiredValidator} />
            </Grid>
            <Grid item xs={4}>
                <FullWidthTextField
                    name="percentagePayable"
                    mask={PercentageMask}
                    validate={requiredValidator}
                    parse={convertToNumber}
                    disabled={noPartialPayment}
                />
            </Grid>
            <Grid item xs={4}>
                <FullWidthTextField
                    name="amountPayable"
                    mask={CurrencyMask}
                    validate={composeValidators(
                        requiredValidator,
                        maxValue(sumAssured || 0, 'sum assured')
                    )}
                    parse={convertToNumber}
                    disabled={noPartialPayment}
                />
            </Grid>
            <WhenFieldChanges
                field="percentagePayable"
                set="amountPayable"
                to={(percent: number) => (!sumAssured ? undefined : sumAssured * (percent / 100))}
            />
        </React.Fragment>
    );
}
