import React from 'react'; // eslint-disable-line

import { Paper, withStyles } from '@material-ui/core';
import { connect, MapStateToProps } from 'react-redux';
import { FormHeading } from '../../../../forms';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { GenerateSynopsis } from '../../../synopsis/common/generate-synopsis';
import { getDeathAssessmentDetails } from '../death-assessment-details-reducer';
import { AssessmentDetails } from '../death-assessment-details-reducer';
import { AddDeathAssessmentDecisionProps } from './risk-assessment-decision-add';
import { AddInvestmentAssessmentDecisionProps } from './investment-assessment-decision-add';
import { RiskPolicyAssessmentDetailsView } from './risk-policy-assessment-details';
import { GroupLiabilityButtonView } from './group-liability';
import { InvestmentPolicyAssessmentDetailsView } from './investment-policy-assessment-details';

interface OwnProps {
    readonly deathAssessmentDecisionAddView:
        | React.FunctionComponent<AddDeathAssessmentDecisionProps>
        | React.ComponentClass<AddDeathAssessmentDecisionProps>;
    readonly investmentAssessmentDecisionAddView:
        | React.FunctionComponent<AddInvestmentAssessmentDecisionProps>
        | React.ComponentClass<AddInvestmentAssessmentDecisionProps>;
}

interface StateProps {
    readonly assessmentDetails: AssessmentDetails;
}

type Props = StateProps & OwnProps & StyledComponent;
const deathAssessmentDetailsView: React.FunctionComponent<Props> = ({
    assessmentDetails,
    deathAssessmentDecisionAddView,
    investmentAssessmentDecisionAddView,
    classes,
}: Props) => {
    return (
        <React.Fragment>
            <Paper className={classes.paper}>
                <FormHeading>Assessment Decisions</FormHeading>
                {assessmentDetails && (
                    <RiskPolicyAssessmentDetailsView
                        riskPolicyAssessmentDetails={assessmentDetails.riskPolicyAssessmentDetails}
                        deathAssessmentDecisionAddView={deathAssessmentDecisionAddView}
                    />
                )}
                {assessmentDetails &&
                    assessmentDetails.investmentPolicyAssessments.map(
                        investmentPolicyAssessment => (
                            <InvestmentPolicyAssessmentDetailsView
                                key={investmentPolicyAssessment.policyNumber}
                                investmentPolicyAssessment={investmentPolicyAssessment}
                                investmentAssessmentDecisionAddView={
                                    investmentAssessmentDecisionAddView
                                }
                            />
                        )
                    )}
            </Paper>
            <GroupLiabilityButtonView
                GroupPolicies={assessmentDetails.riskPolicyAssessmentDetails}
                investmentPolicyAssessments={assessmentDetails.investmentPolicyAssessments}
            />
            <GenerateSynopsis />
        </React.Fragment>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    assessmentDetails: getDeathAssessmentDetails(state),
});

const mapDispatchToProps = {};

export const DeathAssessmentDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(deathAssessmentDetailsView));
