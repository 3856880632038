/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'; // eslint-disable-line

import { Button, Grid, Paper, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Delete, Edit, CheckCircle, Warning } from '@material-ui/icons';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { OpenDialog, openDialogAction } from '../../../../../confirmation-dialog';
import { EditFormModal } from '../../../../../form-modal';
import { OpenEditModal, openEditModalAction } from '../../../../../form-modal/actions';
import InformationReceivedRecordDetailView from './information-received-record-detail';
import { State } from '../../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { InformationRecord, getInformationRecord } from '../reducer';
import {
    UpdateAndSubmitInformationRecord,
    updateAndSubmitInformationRecordThunk,
    deleteAndSubmitInformationRecordThunk,
    DeleteAndSubmitInformationRecord,
} from '../actions/submit-information-record';

import { SelfEmployedIncomeView } from './self-employed-income';
import { ProvenHighestSalary } from './proven-highest-salary-view';
import { ButtonWrapper } from '../../../../../forms';

interface OwnProps {
    index: number;
    informationRecord: InformationRecord;
}

interface StateProps {
    informationRecordSummary: InformationRecord[];
    isReadOnly: boolean;
}

interface DispatchProps {
    updateRecord: UpdateAndSubmitInformationRecord;
    deleteRecord: DeleteAndSubmitInformationRecord;
    openEditModal: OpenEditModal;
    openDialog: OpenDialog;
}

type Props = DispatchProps & OwnProps & StateProps & StyledComponent;

const informationRecordDetailsView: React.FunctionComponent<Props> = ({
    classes,
    deleteRecord,
    index,
    informationRecord,
    informationRecordSummary,
    openEditModal,
    openDialog,
    updateRecord,
    isReadOnly,
}: Props) => {
    const documentName = 'Record ' + (index + 1);
    const receivedOrAdditional = informationRecord.receivedOrAdditional;
    const dateReceived = informationRecord.dateReceived;
    type Predicate = (value?: any) => boolean;
    const HasValue: Predicate = (value?: any) => !!value;

    return (
        <Paper className={classes.paper} key={index}>
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={12} lg={12} className={classes.marginTop3}>
                    <Grid container justify="space-between">
                        <Grid item xs={8} lg={10}>
                            {receivedOrAdditional === 'Received' && (
                                <Typography variant="subtitle2">
                                    {documentName} | Information Received <FormattedCheckCircle />
                                </Typography>
                            )}
                            {receivedOrAdditional !== 'Received' && (
                                <Typography variant="subtitle2">
                                    {HasValue(dateReceived) ? (
                                        <React.Fragment>
                                            {documentName} | Additional Information Received
                                            {<FormattedCheckCircle />}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {documentName} | Additional Information Requested
                                            {<FormattedWarning />}
                                        </React.Fragment>
                                    )}
                                </Typography>
                            )}
                        </Grid>
                        <ButtonWrapper>
                            <Grid item container xs={4} lg={2} justify="flex-end">
                                {editInformationRecordButton(
                                    informationRecordSummary,
                                    index,
                                    updateRecord,
                                    openEditModal
                                )}
                                {deleteInformationRecordButton(index, deleteRecord, openDialog)}
                            </Grid>
                        </ButtonWrapper>
                    </Grid>
                </Grid>

                {receivedOrAdditional !== 'Received' && (
                    <Grid item xs={12} container justify="flex-start">
                        <Grid item xs={4}>
                            <Grid item xs={6} lg={8}>
                                <LabeledValue
                                    label="Date requested"
                                    value={getDisplayDate(informationRecord.dateRequested)}
                                />
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <LabeledValue
                                    label="Date received"
                                    value={getDisplayDate(informationRecord.dateReceived)}
                                />
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <LabeledValue
                                    label="Document name"
                                    value={
                                        informationRecord.documentDescription
                                            ? informationRecord.documentDescription
                                            : informationRecord.documentName
                                    }
                                />
                            </Grid>
                            <Grid item xs={4} lg={4}>
                                <LabeledValue
                                    label="Assessor"
                                    value={informationRecord.assessedBy}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid item xs={12} lg={12}>
                                <LabeledValue
                                    label="Details of information requested"
                                    value={informationRecord.detailsOfInformationRequested}
                                />
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <LabeledValue
                                    label="Reason for requested"
                                    value={informationRecord.reasonForRequest}
                                />
                            </Grid>
                        </Grid>

                        {HasValue(dateReceived) && (
                            <Grid item xs={12} container justify="flex-start">
                                <Grid item xs={4}>
                                    <Grid item xs={4} lg={4}>
                                        <LabeledValue
                                            label="Document ID"
                                            value={informationRecord.documentID}
                                        />
                                    </Grid>

                                    <Grid item xs={4} lg={4}>
                                        <LabeledValue
                                            label="Document name"
                                            value={
                                                informationRecord.documentDescription
                                                    ? informationRecord.documentDescription
                                                    : informationRecord.documentName
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <LabeledValue
                                            label="Date of report"
                                            value={getDisplayDate(informationRecord.dateOfReport)}
                                        />
                                    </Grid>
                                    <Grid item xs={4} lg={4}>
                                        <LabeledValue
                                            label="Assessor"
                                            value={informationRecord.assessor}
                                        />
                                    </Grid>
                                    <Grid item xs={4} lg={4}>
                                        <LabeledValue
                                            label="Refer to underwriting"
                                            value={
                                                informationRecord.sendToUnderwriter ? 'Yes' : 'No'
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={8}>
                                    <Grid item xs={12} lg={12}>
                                        <LabeledValue
                                            label="Summary"
                                            value={informationRecord.summary}
                                            isUnsafe
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                )}

                {receivedOrAdditional === 'Received' && (
                    <Grid item xs={12} container justify="flex-start">
                        <Grid item xs={4} lg={4}>
                            <LabeledValue
                                label="Document ID"
                                value={informationRecord.documentID}
                            />
                        </Grid>
                        <Grid item xs={4} lg={4}>
                            <LabeledValue
                                label="Document name"
                                value={informationRecord.documentName}
                            />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                            <LabeledValue
                                label="Date of report"
                                value={getDisplayDate(informationRecord.dateOfReport)}
                            />
                        </Grid>
                        <Grid item xs={4} lg={4}>
                            <LabeledValue label="Assessor" value={informationRecord.assessor} />
                        </Grid>
                        <Grid item xs={4} lg={4}>
                            <LabeledValue
                                label="Refer to underwriting"
                                value={informationRecord.sendToUnderwriter ? 'Yes' : 'No'}
                            />
                        </Grid>
                        <SelfEmployedIncomeView
                            selfEmployedIncome={informationRecord.selfEmployedIncome}
                        />
                        <ProvenHighestSalary informationRecord={informationRecord} />
                        <Grid item xs={8}>
                            <Grid item xs={12} lg={12}>
                                <LabeledValue
                                    label="Summary"
                                    value={informationRecord.summary}
                                    isUnsafe
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
};

const InformationRecordEditFormModal = EditFormModal<InformationRecord>();
const editInformationRecordButton = (
    informationRecords: InformationRecord[],
    informationRecordIndex: number,
    updateRecord: UpdateAndSubmitInformationRecord,
    openEditModal: OpenEditModal
) => {
    const openEditView = () => {
        const nonBusinessRelatedExpenses =
            informationRecords[informationRecordIndex] &&
            informationRecords[informationRecordIndex].selfEmployedIncome &&
            informationRecords[informationRecordIndex].selfEmployedIncome
                ?.nonBusinessRelatedExpenses;

        openEditModal({
            modalContent: (
                <InformationRecordEditFormModal
                    recordDetailView={
                        <InformationReceivedRecordDetailView
                            modalType="Edit"
                            heading="View/Edit Information Record"
                            nonBusinessRelatedExpenses={nonBusinessRelatedExpenses}
                        />
                    }
                    records={informationRecords}
                    updateRecord={(informationRecord, index) =>
                        updateRecord(index, informationRecord)
                    }
                />
            ),
            modalData: {
                recordIndex: informationRecordIndex,
            },
        });
    };

    return (
        <ButtonWrapper>
            <Tooltip title="Edit Information Record">
                <Button id="EditInformationReceived" color="secondary" onClick={openEditView}>
                    <Edit />
                </Button>
            </Tooltip>
        </ButtonWrapper>
    );
};

const deleteInformationRecordButton = (
    informationRecordIndex: number,
    deleteRecord: DeleteAndSubmitInformationRecord,
    openDialog: OpenDialog
) => {
    const confirmDelete = () => {
        openDialog({
            content: {
                title: 'Delete Information Record',
                body: 'Are you sure you would like to delete the selected information received?',
            },
            yesAction: {
                action: () => deleteRecord(informationRecordIndex),
            },
        });
    };

    return (
        <ButtonWrapper>
            <Tooltip title="Delete Information Record">
                <Button id="DeleteInformationReceived" color="secondary" onClick={confirmDelete}>
                    <Delete />
                </Button>
            </Tooltip>
        </ButtonWrapper>
    );
};

const FormattedCheckCircle = () => {
    return <CheckCircle style={{ color: '#689F38', marginBottom: '-5px' }} fontSize="small" />;
};

const FormattedWarning = () => {
    return <Warning style={{ color: '#FF6700', marginBottom: '-5px' }} fontSize="small" />;
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state, ownProps) => ({
    informationRecordSummary: getInformationRecord(state).records,
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    updateRecord: (index: number, informationRecord: InformationRecord) =>
        dispatch(updateAndSubmitInformationRecordThunk(index, informationRecord)),
    deleteRecord: (index: number) => dispatch(deleteAndSubmitInformationRecordThunk(index)),
    openEditModal: options => dispatch(openEditModalAction(options)),
    openDialog: options => dispatch(openDialogAction(options)),
});

export const InformationRecordDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(informationRecordDetailsView));
