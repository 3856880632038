import axios from 'axios';
import { push as setLocation } from 'connected-react-router';
import { Claim, claimUpdatedThunk } from '..';
import { openDialogAction } from '../../confirmation-dialog';
import { env } from '../../env';
import { hideLoadingAction, showLoadingAction } from '../../forms/loading-screen/actions';
import { ClientClaim } from '../../initiate-claim';
import { ReduxThunkAction } from '../../redux/redux-actions';

const initiateClaimsUri = `${env().CLAIMS_API}/api/Claim/CopyClaim`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CopyClaimReturn = Promise<any>;
export type CopyClaim = (claim: ClientClaim, clientId: string) => CopyClaimReturn;
type CopyClaimAction = (claim: ClientClaim, clientId: string) => ReduxThunkAction<CopyClaimReturn>;
export const copyClaimAction: CopyClaimAction = (claim: ClientClaim, clientId: string) => {
    return async dispatch => {
        dispatch(showLoadingAction());
        const referenceSource = sessionStorage.getItem('referenceSource');
        const referenceId = sessionStorage.getItem('referenceId');
        const creationDate = sessionStorage.getItem('creationDate');
        let reference = undefined;
        if (referenceSource && referenceId) {
            reference = {
                referenceId: referenceId,
                referenceSource: referenceSource,
                creationDate: creationDate ? creationDate : undefined,
            };
        }

        const initiateClaimRequest = {
            claimId: claim.claimId,
            reference: reference,
            claimType: claim.claimTypes[0],
            lifeAssuredEntityIds: [clientId],
        };

        await axios
            .post<Claim>(initiateClaimsUri, initiateClaimRequest)
            .then(response => {
                dispatch(claimUpdatedThunk(response.data));
                dispatch(setLocation('/assessment/synopsis'));
            })
            .catch(error => {
                dispatch(
                    openDialogAction({
                        content: {
                            title: 'Error copying claim',
                            body: error.message,
                            error: true,
                        },
                    })
                );
            })
            .then(() => dispatch(hideLoadingAction()));

        return {};
    };
};
