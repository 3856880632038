import { withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { ExpansionPanel } from '../../../../forms/form-components/expansion-panel/expansion-panel';
import {
    getMedicalOfficerInput,
    MedicalOfficerInput,
} from '../../../../medical-officer-input/reducer';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { MedicalReferralView } from './medical-referral';

type Props = StyledComponent & {
    medicalOfficerInput: MedicalOfficerInput;
};
const medicalOfficerInputView: React.FunctionComponent<Props> = ({
    medicalOfficerInput,
}: Props) => {
    return (
        <ExpansionPanel name="Medical Officer Input">
            <MedicalReferralView medicalReferral={medicalOfficerInput} />
        </ExpansionPanel>
    );
};

const mapStateToProps = (state: State) => ({
    medicalOfficerInput: getMedicalOfficerInput(state),
});

const mapDispatchToProps = {};

export const MedicalOfficerInputView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(medicalOfficerInputView));
