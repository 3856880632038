import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { LabeledValue } from '../../../../controls/labeled-value';
import { LifeAssuredContract } from '../../../../life-assured-details/reducer';
import { formatCurrency, getDisplayDate } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import { CessionTypeValue } from '../../common/cession-type';
import { StatusValue } from '../../common/policy-status';

type Props = StyledComponent & {
    contract: LifeAssuredContract;
};
const investmentPolicyDetailsView: React.FunctionComponent<Props> = ({
    classes,
    contract,
}: Props) => {
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.marginTop}>
                    Policy Details
                </Typography>
            </Grid>
            <Paper className={classes.paper}>
                <Grid container justify="flex-start">
                    <Grid item xs={3}>
                        <LabeledValue label="Policy Number" value={contract.contractNumber} />
                    </Grid>
                    <Grid item xs={3}>
                        <LabeledValue
                            label="Commencement Date"
                            value={getDisplayDate(contract.commencementDate)}
                        />
                    </Grid>
                    {contract.conversionDate && (
                        <Grid item xs={3}>
                            <LabeledValue
                                label="Conversion Date"
                                value={getDisplayDate(contract.conversionDate)}
                            />
                        </Grid>
                    )}
                    {contract.associatedContracts && contract.associatedContracts.length > 0 && (
                        <Grid item xs={3}>
                            <LabeledValue
                                label="Associated Contract"
                                value={contract.associatedContracts
                                    .map(item => item.contractNumber)
                                    .join(', ')}
                            />
                        </Grid>
                    )}
                    <Grid item xs={3}>
                        <LabeledValue
                            label="Death Value"
                            value={formatCurrency(contract.deathValue)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <LabeledValue
                            label="Premium Payer Name"
                            value={contract.premiumPayersName}
                        />
                    </Grid>
                    <Grid container justify="flex-start" item xs={12}>
                        <Grid item xs={3}>
                            <LabeledValue label="Cession Type" />
                            <CessionTypeValue cessionaryType={contract.cessionaryType} />
                        </Grid>
                        <Grid item xs={3}>
                            <LabeledValue label="Cessionary Name" value={contract.cessionaryName} />
                        </Grid>
                        <Grid item xs={3}>
                            <LabeledValue label="Status" />
                            <StatusValue status={contract.status} />
                        </Grid>
                        <Grid item xs={3}>
                            <LabeledValue
                                label="Paid To Date"
                                value={getDisplayDate(contract.paidToDate, 'dd LLLL yyyy')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

export const InvestmentPolicyDetailsView = withStyles(styles)(investmentPolicyDetailsView);
