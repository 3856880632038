import { getProperty, Claim } from '../claim';
import { collectionReducer } from '../redux/collection-reducer/collection-reducer';
import { State } from '../redux/root-reducer';
import { ADD_PAYMENT, DELETE_PAYMENT, UPDATE_PAYMENT } from './types';
import {
    BankingDetails,
    PremiumPayerDetails,
} from '../assessment/synopsis/common/banking-details/banking-details';
import { AssessmentDecision } from '../assessment/decisions/assessment-details-reducer';
import { ClaimType } from '../claim/claim-types';
import { DeathClaim } from '../claim/reducers/claim-reducers/death-claim-reducer';
import { RADeathClaim } from '../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { OrganisationDetails } from '../assessment/synopsis/death/contact-details/contact-details-reducer';
import { EntityType } from '../shared-types';
import { getClaimantDisplayName, getDependantDisplayName, getFullName } from '../shared-functions';
import {
    PersonalDetails,
    LifeAssuredContract,
    ContractOwnerOrganisation,
} from '../life-assured-details/reducer';
import { AssessmentDetails } from '../assessment/decisions/death/death-assessment-details-reducer';
import { AccountsLookup } from '../codes/accounts-lookup';
import { Passports } from '../codes/id-document-types';
import { CommonClaim } from '../claim/reducers/claim-reducers/retrenchment-claim-reducer';
import { FuneralClaim } from '../claim/reducers/claim-reducers/funeral-claim-reducer';
import { isEmpty } from '../forms/validations';

export interface PolicyDetails {
    label: string;
    decisionId: string;
    assessmentDate: Date;
    decision: string;
    policyNumber: string;
    benefitCode: string;
}

export interface PaymentBeneficiary {
    beneficiaryId?: string;
    type: string;
}

export interface PaymentItem {
    id?: string;
    internalAccountId?: string;
    decisionId?: string;
    amountPayable: number;
    paymentType: string;
    payeeType: string;
    status: string;
    taxDirectiveNumber?: string;
    assessor?: string;
    capturedDate?: Date;
    requestNo?: string;
    policyNumber?: string;
    benefitCode?: string;
    benefitName?: string;
    sumAssured?: number;
    split?: number;
    reference?: string;
    paymentMethod?: string;
    bankingDetails?: BankingDetails;
    caseNumber?: number;
}

export interface HighRiskOverride {
    reasonForOverride?: string;
    overridingAssessor?: string;
    dateOfOverride?: Date;
}

export interface RiskRating {
    finalRiskShortText?: string;
    riskAbbreviation?: string;
    statusDetails?: string;
    status?: string;
    statusCode?: string;
    statusDescription?: string;
    override?: boolean;
    overrideDescription?: string;
    highRiskOverride?: HighRiskOverride;
}

export interface BeneficiaryManualScreening {
    beneficiaryManualScreeningComment?: string;
    isBeneficiaryManualScreeningConfirmed?: boolean;
    isBeneficiaryManualScreening?: boolean;
    assessor?: string;
    beneficiaryManualScreeningDate: Date;
}

export interface Payment {
    id?: string;
    beneficiaryId?: string;
    type: string;
    notScreened: boolean;
    screeningDate?: Date;
    riskRating?: RiskRating;
    paymentItems: PaymentItem[];
    screeningError?: string;
    beneficiaryManualScreening?: BeneficiaryManualScreening;
}

export enum PayeeType {
    Claimant = 'Claimant',
    PolicyOwner = 'PolicyOwner',
}

export interface BeneficiaryType {
    claimantId?: string;
    claimantType: PayeeType;
    policyNumber: string;
}

export const defaultPayments: Payment[] = [];

export const paymentReducer = collectionReducer<Payment>({
    addAction: ADD_PAYMENT,
    deleteAction: DELETE_PAYMENT,
    updateAction: UPDATE_PAYMENT,
});

export function getPayments(state: State): Payment[] {
    return getProperty(state, 'payments', defaultPayments);
}

export interface BeneficiaryOption {
    value: string;
    label: string;
    title?: string;
    email?: string;
    surname?: string;
}

export function getBeneficiaryOptions(claim: Claim) {
    const beneficiaries: BeneficiaryOption[] = [];

    if (claim.claimType === ClaimType.Death) {
        const deathClaim = claim as DeathClaim;
        if (deathClaim.contactDetails) {
            deathClaim.contactDetails.claimants.forEach(claimant => {
                beneficiaries.push({
                    value: claimant.id || '',
                    label: getClaimantDisplayName(claimant),
                });
            });
        }
    }

    if (claim.claimType === ClaimType.RADeath) {
        const raClaim = claim as RADeathClaim;
        const finalDecision = raClaim.assessmentDetails.decisions.find(
            d => d.assessmentType === 'Final Decision'
        );
        if (finalDecision) {
            finalDecision.recommendations.forEach(r => {
                if (r.allocation > 0) {
                    const dependant = raClaim.dependants.find(d => d.id === r.dependantId);
                    if (dependant) {
                        beneficiaries.push({
                            value: dependant.id,
                            label: getDependantDisplayName(dependant),
                        });
                    }
                }
            });
        }
    }

    if (isCommonClaim(claim)) {
        const commonClaim = claim as CommonClaim;
        const lifeAssured = commonClaim.lifeAssuredDetails;
        const contracts = lifeAssured && lifeAssured.contracts;
        if (contracts) {
            contracts.forEach(contract => {
                contract.policyOwners.forEach(owner => {
                    let fullName = '';
                    if (owner.ownerType === EntityType.Organisation) {
                        fullName = owner.organisation.name;
                    } else if (owner.ownerType === EntityType.Individual) {
                        fullName = getFullName(owner.personalDetails);
                    }
                    beneficiaries.push({
                        value: owner.id || '',
                        label: `${fullName} | ${contract.contractNumber}`,
                    });
                });
            });
        }
    }

    if (claim.claimType === ClaimType.Funeral) {
        const funeralClaim = claim as FuneralClaim;
        if (funeralClaim.formDetails) {
            const policyOwner = funeralClaim.formDetails.policyOwner;
            if (policyOwner && !isEmpty(policyOwner)) {
                if (policyOwner.id) {
                    beneficiaries.push({
                        value: policyOwner.id,
                        label: getFullName(policyOwner.personalDetails),
                    });
                }
            }
            const claimants = funeralClaim.formDetails.claimants;
            if (claimants) {
                claimants.forEach(claimant => {
                    if (claimant.id) {
                        beneficiaries.push({
                            value: claimant.id || '',
                            label: getFullName(claimant.personalDetails),
                        });
                    }
                });
            }
        }
    }

    return beneficiaries;
}

interface ExistingPayment {
    id?: string;
    decisionId: string;
    amount: number;
}

export interface BeneficiaryDetails {
    allocation: number;
    beneficiaryId: string;
    policyNumber: string;
    personalDetails?: PersonalDetails;
    organisationDetails?: OrganisationDetails;
    bankingDetails?: BankingDetails;
    beneficiaryManualScreening?: BeneficiaryManualScreening;
    existingPayments?: ExistingPayment[];
    riskStatus?: string;
    screeningDate?: Date;
    screeningError?: string;
}

export function getPaymentBeneficiaries(claim: Claim) {
    const beneficiaryDetails: BeneficiaryDetails[] = [];

    if (claim.payments.length > 0) {
        if (claim.claimType === ClaimType.Death) {
            const deathClaim = claim as DeathClaim;
            deathClaim.payments.forEach(p => {
                if (deathClaim.contactDetails) {
                    const beneficiary = deathClaim.contactDetails.claimants.find(
                        c => c.id === p.beneficiaryId
                    );
                    if (beneficiary) {
                        const claimantDetails = beneficiary.claimantDetails || {};
                        beneficiaryDetails.push({
                            allocation: 100,
                            beneficiaryId: beneficiary.id || '',
                            policyNumber: 'All',
                            personalDetails: claimantDetails.personalDetails,
                            organisationDetails: claimantDetails.organisationDetails,
                            bankingDetails: claimantDetails.bankingDetails,
                            riskStatus: p.riskRating?.riskAbbreviation,
                            screeningDate: p.screeningDate,
                            screeningError: p.screeningError,
                            beneficiaryManualScreening: p.beneficiaryManualScreening,
                            existingPayments:
                                p.paymentItems &&
                                p.paymentItems.map(i => {
                                    return {
                                        id: i.id,
                                        decisionId: i.decisionId || '',
                                        amount: i.amountPayable || 0,
                                    };
                                }),
                        });
                    }
                }
            });
        }

        if (isCommonClaim(claim)) {
            const commonClaim = claim as CommonClaim;
            const lifeAssuredDetails = commonClaim.lifeAssuredDetails;
            const contracts = lifeAssuredDetails && lifeAssuredDetails.contracts;
            commonClaim.payments.forEach(p => {
                if (contracts) {
                    contracts.forEach(c => {
                        const owner = c.policyOwners.find(po => po.id === p.beneficiaryId);
                        if (owner) {
                            beneficiaryDetails.push({
                                allocation: 100,
                                beneficiaryId: owner.id,
                                policyNumber: c.contractNumber || '',
                                personalDetails: owner.personalDetails,
                                organisationDetails:
                                    owner.organisation &&
                                    getOrganisationDetails(owner.organisation),
                                bankingDetails: getBankingDetails(c.premiumPayer),
                                existingPayments:
                                    p.paymentItems &&
                                    p.paymentItems.map(i => {
                                        return {
                                            id: i.id,
                                            decisionId: i.decisionId || '',
                                            amount: i.amountPayable || 0,
                                        };
                                    }),
                            });
                        }
                    });
                }
            });
        }

        if (claim.claimType === ClaimType.RADeath) {
            const raClaim = claim as RADeathClaim;
            const decision = raClaim.assessmentDetails.decisions.filter(
                d => d.assessmentType === 'Final Decision'
            );
            raClaim.payments.forEach(p => {
                const beneficiary = raClaim.dependants.find(d => d.id === p.beneficiaryId);
                const decisionBeneficiary =
                    decision[0] &&
                    decision[0].recommendations.find(r => r.dependantId === p.beneficiaryId);
                if (beneficiary) {
                    beneficiaryDetails.push({
                        allocation: (decisionBeneficiary && decisionBeneficiary.allocation) || 0,
                        beneficiaryId: beneficiary.id || '',
                        policyNumber: 'All',
                        personalDetails: beneficiary.personalDetails,
                        organisationDetails: beneficiary.organisationDetails,
                        bankingDetails: beneficiary.bankingDetails,
                        existingPayments:
                            p.paymentItems &&
                            p.paymentItems.map(i => {
                                return {
                                    id: i.id,
                                    decisionId: i.decisionId || '',
                                    amount: i.amountPayable || 0,
                                };
                            }),
                    });
                }
            });
        }

        if (claim.claimType === ClaimType.Funeral) {
            const funeralClaim = claim as FuneralClaim;
            funeralClaim.payments.forEach(p => {
                if (funeralClaim.formDetails) {
                    if (
                        funeralClaim.formDetails.policyOwner &&
                        funeralClaim.formDetails.policyOwner.id === p.beneficiaryId
                    ) {
                        const policyOwner = funeralClaim.formDetails.policyOwner;
                        if (policyOwner) {
                            beneficiaryDetails.push({
                                allocation: 100,
                                beneficiaryId: policyOwner.id || '',
                                policyNumber: 'All',
                                personalDetails: policyOwner.personalDetails,
                                bankingDetails: policyOwner.bankingDetails,
                                riskStatus: p.riskRating?.riskAbbreviation,
                                screeningDate: p.screeningDate,
                                screeningError: p.screeningError,
                                beneficiaryManualScreening: p.beneficiaryManualScreening,
                                existingPayments:
                                    p.paymentItems &&
                                    p.paymentItems.map(i => {
                                        return {
                                            id: i.id,
                                            decisionId: i.decisionId || '',
                                            amount: i.amountPayable || 0,
                                        };
                                    }),
                            });
                        }
                    }

                    const claimant = funeralClaim.formDetails.claimants.find(
                        c => c.id === p.beneficiaryId
                    );
                    if (claimant) {
                        beneficiaryDetails.push({
                            allocation: 100,
                            beneficiaryId: claimant.id || '',
                            policyNumber: 'All',
                            personalDetails: claimant.personalDetails,
                            bankingDetails: claimant.bankingDetails,
                            riskStatus: p.riskRating?.riskAbbreviation,
                            screeningDate: p.screeningDate,
                            screeningError: p.screeningError,
                            beneficiaryManualScreening: p.beneficiaryManualScreening,
                            existingPayments:
                                p.paymentItems &&
                                p.paymentItems.map(i => {
                                    return {
                                        id: i.id,
                                        decisionId: i.decisionId || '',
                                        amount: i.amountPayable || 0,
                                    };
                                }),
                        });
                    }
                }
            });
        }
    }

    return beneficiaryDetails;
}

const getOrganisationDetails = (organisation: ContractOwnerOrganisation) => {
    const organisationDetails: OrganisationDetails = {
        companyName: organisation.name,
        companyTaxNumber: parseInt(organisation.taxReferenceNumber),
        entityRegistrationNumber: organisation.registrationNumber,
    };
    return organisationDetails;
};

const getBankingDetails = (premiumPayer: PremiumPayerDetails) => {
    const bankAccountNumber =
        (premiumPayer.bankAccountNumber && premiumPayer.bankAccountNumber.toString()) || '';
    return {
        bankName: premiumPayer.bankName,
        accountHoldersInitials: premiumPayer.initials,
        accountHoldersName: premiumPayer.lastName,
        bankBranch: premiumPayer.branchName,
        accountNumber: parseInt(bankAccountNumber),
        accountType: premiumPayer.bankAccountType,
        branchCode: premiumPayer.branchCode,
        bankAccountStatus: premiumPayer.bankAccountStatus,
    };
};

export interface ClaimDecision {
    decisionId: string;
    decisionLabel: string;
    maximumAmountPayable?: number;
    policyNumber?: string;
    benefitCode?: string;
    benefitName?: string;
    sumAssured?: number;
    decisionAmount?: number;
}

export function getDecisions(claim: Claim) {
    const claimDecisions: ClaimDecision[] = [];

    if (claim.claimType === ClaimType.Death) {
        const deathClaim = claim as DeathClaim;
        const contracts = deathClaim.lifeAssuredDetails && deathClaim.lifeAssuredDetails.contracts;
        return getFromDeathAssessmentDetails(deathClaim.deathAssessmentDetails, contracts || []);
    }

    if (claim.claimType === ClaimType.RADeath) {
        const raClaim = claim as RADeathClaim;
        const decision = raClaim.assessmentDetails.decisions.find(
            d => d.assessmentType === 'Final Decision'
        );
        if (decision) {
            raClaim.assessmentDetails.assessmentPolicies.forEach(p => {
                if (p.claimLiability && p.claimLiability.id) {
                    claimDecisions.push({
                        decisionId: p.claimLiability.id,
                        decisionLabel: `${p.policyNumber} ${p.benefitName || p.productName}`,
                        policyNumber: p.policyNumber,
                        benefitCode: p.benefitCode,
                        benefitName: p.benefitName || p.productName,
                        sumAssured: p.claimLiability.claimAmount || 0,
                        maximumAmountPayable: p.unallocatedClaimAmount,
                    });
                }
            });
        }
    }

    if (isCommonClaim(claim)) {
        const commonClaim = claim as CommonClaim;
        const assessmentDetails = commonClaim.assessmentDetails
            ? commonClaim.assessmentDetails
            : [];

        assessmentDetails.forEach(policy => {
            policy.benefits.forEach(benefit => {
                const finalDecision = getFinalDecisions(benefit.decisions);
                if (finalDecision) {
                    claimDecisions.push({
                        decisionId: finalDecision.id,
                        decisionLabel: `${policy.policyNumber} ${benefit.benefitName}`,
                        policyNumber: policy.policyNumber,
                        benefitCode: benefit.benefitCode,
                        benefitName: benefit.benefitName,
                        sumAssured: benefit.sumAssured || 0,
                        decisionAmount: finalDecision.amountPayable,
                        maximumAmountPayable: finalDecision.unallocatedClaimAmount,
                    });
                }
            });
        });
    }

    if (claim.claimType === ClaimType.Funeral) {
        const funeralClaim = claim as FuneralClaim;
        if (funeralClaim.assessmentDetails) {
            funeralClaim.assessmentDetails.forEach(assessmentDetails =>
                assessmentDetails.benefits.forEach(benefit => {
                    const finalDecision = getFinalDecisions(benefit.decisions);
                    if (finalDecision) {
                        claimDecisions.push({
                            decisionId: finalDecision.id,
                            decisionLabel: `${assessmentDetails.policyNumber} ${benefit.benefitName}`,
                            policyNumber: assessmentDetails.policyNumber,
                            benefitCode: benefit.benefitCode,
                            benefitName: benefit.benefitName,
                            sumAssured: finalDecision.amountPayable || 0,
                            maximumAmountPayable: finalDecision.unallocatedClaimAmount || 0,
                        });
                    }
                })
            );
        }
    }

    return claimDecisions;
}

export const getFromDeathAssessmentDetails = (
    assessmentDetails: AssessmentDetails,
    contracts: LifeAssuredContract[] = []
) => {
    const policyDecisions: ClaimDecision[] = [];
    assessmentDetails.riskPolicyAssessmentDetails.forEach(riskAssessmentDetails =>
        riskAssessmentDetails.benefits.forEach(benefit => {
            const finalDecision = getFinalDecisions(benefit.decisions);
            if (finalDecision) {
                policyDecisions.push({
                    decisionId: finalDecision.id,
                    decisionLabel: `${riskAssessmentDetails.policyNumber} ${benefit.benefitName}`,
                    policyNumber: riskAssessmentDetails.policyNumber,
                    benefitCode: benefit.benefitCode,
                    benefitName: benefit.benefitName,
                    sumAssured: finalDecision.amountPayable || 0,
                    maximumAmountPayable: finalDecision.unallocatedClaimAmount,
                });
            }
        })
    );

    assessmentDetails.investmentPolicyAssessments.forEach(investmentAssessmentDetails => {
        const finalDecision = getFinalDecisions(investmentAssessmentDetails.decisions);
        if (finalDecision) {
            const contract = contracts.find(
                c => c.contractNumber === investmentAssessmentDetails.policyNumber
            );
            policyDecisions.push({
                decisionId: finalDecision.id,
                decisionLabel: `${investmentAssessmentDetails.policyNumber} ${
                    contract && contract.productName
                }`,
                policyNumber: investmentAssessmentDetails.policyNumber,
                benefitCode: '',
                benefitName: contract && contract.productName,
                sumAssured: finalDecision.amountPayable || 0,
                maximumAmountPayable: finalDecision.unallocatedClaimAmount,
            });
        }
    });

    return policyDecisions;
};

function getFinalDecisions(decisions: AssessmentDecision[]) {
    const finalDecisions = decisions
        .filter(d => d.final)
        .sort((a, b) =>
            a.assessmentDate < b.assessmentDate ? 1 : b.assessmentDate < a.assessmentDate ? -1 : 0
        );

    if (finalDecisions && finalDecisions.length > 0 && finalDecisions[0].decision === 'Approve') {
        return finalDecisions[0];
    }

    return undefined;
}

export function getBeneficiaryEditName(beneficiary: BeneficiaryDetails) {
    if (beneficiary.organisationDetails) {
        return `${beneficiary.organisationDetails.companyName || ''} | ${
            beneficiary.organisationDetails.entityRegistrationNumber || ''
        }`;
    }
    if (beneficiary.personalDetails) {
        const idNumber = Passports.includes(beneficiary.personalDetails.idType || '')
            ? beneficiary.personalDetails.passportNumber
            : idNumberFormatter(beneficiary.personalDetails.idNumber);
        return `${getFullName(beneficiary.personalDetails)} | ${idNumber}`;
    }
    return '';
}

export const idNumberFormatter = (idNumber?: number) => {
    if (!idNumber) {
        return '';
    }

    const displayIdNumber = idNumber.toString();
    if (displayIdNumber.length === 13) {
        return `${displayIdNumber.substring(0, 6)} ${displayIdNumber.substring(
            6,
            10
        )} ${displayIdNumber.substring(10, 12)} ${displayIdNumber.substring(12)}`;
    }
    return idNumber.toString();
};

export const amountToSplit = (amountPayable: number, sumAmount: number) => {
    return !amountPayable ? 0 : (amountPayable / (sumAmount || 0)) * 100;
};

export const getInternalAccount = (account?: string) => {
    const result = AccountsLookup.filter(lookup => lookup.account === account).find(acc => acc);

    return (
        result && {
            bankName: result.bankingDetails.bankName,
            accountHoldersName: result.bankingDetails.accountHoldersName,
            branchCode: result.bankingDetails.branchCode,
            accountNumber: result.bankingDetails.accountNumber,
        }
    );
};

export const getIdType = (idType?: string) => {
    let passportType = false;
    if (idType === 'ForeignPassport' || idType === 'RsaPassport') {
        passportType = true;
    }
    return passportType;
};

function isCommonClaim(claim: Claim) {
    return (
        claim.claimType === ClaimType.Disability ||
        claim.claimType === ClaimType.Dread ||
        claim.claimType === ClaimType.IncomeProtector
    );
}
