import { Divider, Grid, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import {
    getIncomeCalculations,
    IncomeCalculation,
} from '../../../assessment/income-calculator/reducer';
import { IncomePayableSummary } from '../../../assessment/income-calculator/views/income-payable-summary/income-payable';
import { State } from '../../../redux/root-reducer';
import { Nullable } from '../../../shared-types';
import styles, { StyledComponent } from '../../../styles/styles';

interface Props extends StyledComponent {
    calculations: Nullable<IncomeCalculation[]>;
    decisionId?: string;
}

const paymentIncomeView: React.FunctionComponent<Props> = ({
    calculations,
    classes,
    decisionId,
}: Props) => {
    if (!calculations || calculations.length < 1) {
        return null;
    }
    const calculation = calculations.find(x => x.decisionId === decisionId);
    const incomePayable = calculation && calculation.incomePayableCalculations;
    return (
        <React.Fragment>
            <Divider className={classes.resultsTableSpacer} />
            <Grid container style={{ padding: '4px', backgroundColor: '#f0f1f5' }}>
                <IncomePayableSummary incomePayable={incomePayable} />
            </Grid>
        </React.Fragment>
    );
};

const mapStateToProps = (state: State) => ({
    calculations: getIncomeCalculations(state),
});

const mapDispatchToProps = () => ({});

export const PaymentIncomePayableView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(paymentIncomeView));
