import axios from 'axios';
import { FORM_ERROR } from 'final-form';
import { Claim, claimUpdatedThunk } from '../../claim';
import { env } from '../../env';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { ClaimType } from '../../claim/claim-types';
import { setActiveWorkflowAction } from '../../workflow-navigation';
import { funeralWorkflow } from '../../claim-workflow/workflow-provider/funeral-workflow';
import { hideLoadingAction, showLoadingAction } from '../../forms/loading-screen/actions';
import { Client } from './clients-loaded';
import { DeferrableProducts } from '../../codes/deferrable-products';
import { openDialogAction } from '../../confirmation-dialog';
import { setDeferredContractsAction } from '../../claim/actions/set-deferred-contracts';

const initiateClaimsUri = `${env().CLAIMS_API}/api/Claim/Initiate`;
const initiateFuneralClaimUri = `${env().CLAIMS_API}/api/Claim/InitiateFuneral`;
const initiateContractUri = `${env().CLAIMS_API}/api/Claim/InitiateContract`;

export interface InitiateClaimDetails {
    claimType: ClaimType;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type InitiateClaimReturn = Promise<any>;
export type InitiateClaim = (criteria: InitiateClaimDetails) => InitiateClaimReturn;
type InitiateClaimAction = (
    criteria: InitiateClaimDetails
) => ReduxThunkAction<InitiateClaimReturn>;
export const initiateClaimAction: InitiateClaimAction = (criteria: InitiateClaimDetails) => {
    return async (dispatch, getState) => {
        const errors = validate(criteria);
        if (Object.keys(errors).length > 0) {
            return errors;
        }

        const currentClaim = getState().claimSession.claim;
        const selectedClients = getState().initiateClaim.selectedClients || [];

        const reference = currentClaim.references ? currentClaim.references[0] : null;

        const initiateClaimRequest = {
            claimId: currentClaim.claimId,
            reference: reference,
            claimType: criteria.claimType,
            lifeAssuredEntityIds: selectedClients.map(client => client.clientId),
        };

        const response = await axios.post<Claim>(initiateClaimsUri, initiateClaimRequest);

        dispatch(claimUpdatedThunk(response.data));

        return {};
    };
};

type InitiateFuneralClaimAction = (selectedClientIds: string[]) => ReduxThunkAction<Promise<any>>;
export type InitiateFuneralClaim = (selectedClientIds: string[]) => InitiateClaimReturn;
export const initiateFuneralClaim: InitiateFuneralClaimAction = (selectedClientIds: string[]) => {
    return async (dispatch, getState) => {
        dispatch(showLoadingAction);
        const currentClaim = getState().claimSession.claim;
        const reference = currentClaim.references ? currentClaim.references[0] : null;

        const initiateClaimRequest = {
            claimId: currentClaim.claimId,
            reference: reference,
            claimType: ClaimType.Funeral,
            lifeAssuredEntityIds: selectedClientIds,
        };

        await axios
            .post<any>(initiateFuneralClaimUri, initiateClaimRequest)
            .then(response => {
                if (
                    response.data &&
                    response.data.lifeAssuredDetails &&
                    response.data.lifeAssuredDetails.contracts
                ) {
                    if (response.data.lifeAssuredDetails.contracts.length > 0) {
                        let isAllDeferred = true;
                        const contracts = response.data.lifeAssuredDetails.contracts;

                        for (let i = 0; i < contracts.length; i++) {
                            if (!containsDeferredContracts(contracts[i].productName)) {
                                isAllDeferred = false;
                                break;
                            }
                        }

                        if (isAllDeferred) {
                            dispatch(
                                openDialogAction({
                                    content: {
                                        title: '',
                                        body:
                                            'There are only FNB policies and the claim needs to be referred to FNB, please notify the claimant to refer the claim to FNB',
                                        error: true,
                                    },
                                })
                            );
                            dispatch(setDeferredContractsAction(true));
                        } else {
                            dispatch(setDeferredContractsAction(false));
                        }
                    }
                }

                dispatch(claimUpdatedThunk(response.data));
                dispatch(setActiveWorkflowAction(funeralWorkflow));
            })
            .catch(error => {
                if (error.response && error.response.status === 422) {
                    throw new Error(
                        'The client you have selected has no valid funeral contracts or has no funeral benefits to continue with the claims process'
                    );
                } else {
                    throw new Error(error);
                }
            })
            .then(() => dispatch(hideLoadingAction));

        return {};
    };
};

type InitiateContractAction = (client: Client) => ReduxThunkAction<Promise<any>>;
export type InitiateContract = (client: Client) => InitiateClaimReturn;
export const initiateContract: InitiateContractAction = (client: Client) => {
    return async (dispatch, getState) => {
        dispatch(showLoadingAction);
        const currentClaim = getState().claimSession.claim;
        const reference = currentClaim.references ? currentClaim.references[0] : null;
        const initiateClaimRequest = {
            claimId: currentClaim.claimId,
            reference: reference,
            claimType: ClaimType.Funeral,
            contractNumber: client.contractNumber,
            client: {
                firstName: client.firstName,
                lastName: client.lastName,
                dateOfBirth: client.dateOfBirth,
            },
        };

        await axios
            .post<any>(initiateContractUri, initiateClaimRequest)
            .then(response => {
                dispatch(claimUpdatedThunk(response.data));
                dispatch(setActiveWorkflowAction(funeralWorkflow));
            })
            .catch(error => {
                if (error.response && error.response.status === 422) {
                    throw new Error(
                        'The client you have selected has no valid funeral contracts or has no funeral benefits to continue with the claims process'
                    );
                } else {
                    throw new Error(error);
                }
            })
            .then(() => dispatch(hideLoadingAction));

        return;
    };
};

function validate(criteria: InitiateClaimDetails) {
    if (!criteria.claimType) {
        return {
            [FORM_ERROR]: 'Please select a Claim Type',
        };
    }
    return {};
}

export default function containsDeferredContracts(product?: string): boolean {
    if (!product) return false;

    if (DeferrableProducts.includes(product)) return true;
    return false;
}
