import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { Nullable } from '../../shared-types';
import styles from '../../styles/styles';
import {
    ChangeFieldSet,
    changeRecordFromAddChangeSet,
    changeRecordFromChangeSet,
    changeRecordFromDeleteChangeSet,
} from '../claim-audit-trail-functions';
import { addAuditTrailDetailsView } from '../common-views/claim-audit-trail-field-details';
import { mapSectionView } from '../common-views/claim-audit-trail-wrapper';

interface Props {
    changeSet: Record<string, any>;
}

const auditTrailContactDetailsView: React.FunctionComponent<Props> = ({ changeSet }: Props) => {
    const returnView: React.ReactNode[] = [];
    const contactPersonKey = 'contactPerson';
    const ContactPersonKey = 'ContactPerson';
    const contactPersonSection = 'Contact Person for Claim';
    const financialAdviserKey = 'financialAdviser';
    const FinancialAdviserKey = 'FinancialAdviser';
    const financialAdviserSection = 'Financial Adviser';

    if (Array.isArray(changeSet) && changeSet[0] === null) {
        const contactPerson = changeSet[1][contactPersonKey] || changeSet[1][ContactPersonKey];
        const financialAdviser =
            changeSet[1][financialAdviserKey] || changeSet[1][FinancialAdviserKey];
        if (contactPerson) {
            addAuditTrailDetailsView(returnView, changeRecordFromAddChangeSet(contactPerson), [
                contactPersonSection,
            ]);
        }
        if (financialAdviser) {
            addAuditTrailDetailsView(returnView, changeRecordFromAddChangeSet(financialAdviser), [
                financialAdviserSection,
            ]);
        }
    } else {
        const contactPerson: Nullable<Record<string, any>> =
            changeSet[contactPersonKey] || changeSet[ContactPersonKey];
        const financialAdviser: Nullable<Record<string, any>> =
            changeSet[financialAdviserKey] || changeSet[FinancialAdviserKey];
        if (contactPerson) {
            getView(contactPerson, returnView, contactPersonSection);
        }
        if (financialAdviser) {
            getView(financialAdviser, returnView, financialAdviserSection);
        }
    }

    return mapSectionView(returnView);
};

function getView(
    changeObject: Record<string, any>,
    returnView: React.ReactNode[],
    subSection: string
) {
    let record: ChangeFieldSet[] = [];
    if (Array.isArray(changeObject) && changeObject[0] === null) {
        record = changeRecordFromAddChangeSet(changeObject[1]);
    } else if (Array.isArray(changeObject) && changeObject[1] === null) {
        record = changeRecordFromDeleteChangeSet(changeObject[0]);
    } else {
        record = changeRecordFromChangeSet(changeObject);
    }
    if (record.length > 0) {
        addAuditTrailDetailsView(returnView, record, [subSection]);
    }
}

export const AuditTrailContactDetailsView = withStyles(styles)(auditTrailContactDetailsView);
