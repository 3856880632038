import React from 'react'; // eslint-disable-line

import { Grid, Paper, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import {
    Condition,
    FormErrors,
    FormHeading,
    FullWidthDatePicker,
    FullWidthTextField,
    GenericForm,
    True,
} from '../../forms';
import { conditionalRequired } from '../../forms/validations';
import { State } from '../../redux/root-reducer';
import styles, { StyledComponent } from '../../styles/styles';
import { OnSubmitSuccessProps } from '../../workflow-navigation';
import { Submit, submitAction } from '../actions/submit';
import { getMedicalOfficerInput, MedicalOfficerInput } from '../reducer';

type Props = OnSubmitSuccessProps & {
    medicalOfficerInput: MedicalOfficerInput;
    submit: Submit;
};

const MedicalOfficerForm = GenericForm<MedicalOfficerInput>();
function medicalOfficerInputView({
    classes,
    medicalOfficerInput,
    submit,
    ...rest
}: StyledComponent & Props) {
    return (
        <MedicalOfficerForm onSubmit={submit} initialValues={medicalOfficerInput} {...rest}>
            <InnerForm classes={classes} />
            <FormErrors />
        </MedicalOfficerForm>
    );
}

const InnerForm: React.FunctionComponent<StyledComponent> = ({ classes }: StyledComponent) => {
    return (
        <Paper className={classes.paper}>
            <FormHeading text="Medical Officer Input" />
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={6}>
                    <FullWidthDatePicker
                        name="dateReferred"
                        validate={conditionalRequired(
                            values =>
                                values.referredBy ||
                                values.reasonsForReferral ||
                                !!values.dateFeedbackReceived ||
                                !!values.feedbackReceived
                        )}
                        disableFuture
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        name="referredBy"
                        validate={conditionalRequired(values => values.reasonsForReferral)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FullWidthTextField name="reasonsForReferral" multiline />
                </Grid>
                <Condition when="dateReferred" hasValue>
                    <True>
                        <Grid item xs={6}>
                            <FullWidthDatePicker
                                name="dateFeedbackReceived"
                                validate={conditionalRequired(
                                    values => values.feedbackReceived || values.assessorName
                                )}
                                minDateField="dateReferred"
                                disableFuture
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name="assessorName"
                                label="Assessor"
                                validate={conditionalRequired(
                                    values => values.feedbackReceived || values.dateFeedbackReceived
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FullWidthTextField
                                name="feedbackReceived"
                                multiline
                                validate={conditionalRequired(
                                    values => !!values.dateFeedbackReceived || values.assessorName
                                )}
                            />
                        </Grid>
                    </True>
                </Condition>
            </Grid>
        </Paper>
    );
};

const mapStateToProps = (state: State) => ({
    medicalOfficerInput: getMedicalOfficerInput(state),
});

const mapDispatchToProps = {
    submit: submitAction,
};

export const MedicalOfficerInputView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(medicalOfficerInputView));
