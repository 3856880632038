import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import {
    FullWidthTextField,
    FullWidthSearchSelectField,
    Condition,
    True,
    WhenFieldChanges,
} from '../../../../../forms';
import { NumberMask, TelephoneNumberMask } from '../../../../../forms/input-masks';
import { AddressDetailsSubForm } from '../../../../../forms/sub-forms/address-details';
import { NaturalRelationships } from '../../../../../codes/relationship-types';
import { requiredValidator, rsaIdNumberValidator } from '../../../../../forms/validations';
import styles, { StyledComponent } from '../../../../../styles/styles';

interface Props extends StyledComponent {
    isDeath?: boolean;
}

const informantEditView: React.FunctionComponent<Props> = ({ classes, isDeath }: Props) => {
    const prefix = 'informantDetails.';
    const addressType = isDeath ? 'residential' : 'street';
    return (
        <Grid container spacing={5} justify="flex-start">
            <Grid item xs={6}>
                <FullWidthTextField name={`${prefix}firstName`} />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField name={`${prefix}secondName`} />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField name={`${prefix}lastName`} label="Surname" />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${prefix}idNumber`}
                    label="ID Number"
                    validate={rsaIdNumberValidator}
                    mask={NumberMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField name={`${prefix}telephoneNumber`} mask={TelephoneNumberMask} />
            </Grid>
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    name={`${prefix}relationshipToLifeAssured`}
                    label="Relationship to deceased"
                    options={NaturalRelationships}
                />
            </Grid>
            <WhenFieldChanges
                field={`${prefix}relationshipToLifeAssured`}
                set={`${prefix}relationshipToLifeAssuredDescription`}
                to={null}
            />
            <Condition when={`${prefix}relationshipToLifeAssured`} is="Other">
                <True>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            name={`${prefix}relationshipToLifeAssuredDescription`}
                            label="Relationship to deceased description"
                            validate={requiredValidator}
                        />
                    </Grid>
                </True>
            </Condition>
            <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.marginTop}>
                    Residential Address
                </Typography>
            </Grid>
            <AddressDetailsSubForm
                addressPropertyName={`${prefix}addressDetails`}
                addressType={addressType}
            />
        </Grid>
    );
};

export const EditInformantView = withStyles(styles)(informantEditView);
