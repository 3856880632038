import { getProperty } from '../../../../claim';
import { combineReducersWithDefault } from '../../../../redux/combine-reducers-with-default';
import { State } from '../../../../redux/root-reducer';
import { Nullable } from '../../../../shared-types';
import { UnderwritingDetails } from './components/underwriting-details';
import { underwritingDetailsCollectionReducer } from './components/underwriting-details/underwriting-details-reducer';
import { UnderwritingReferral } from './components/underwriting-referrals';
import { underwritingReferralsReducer } from './components/underwriting-referrals/underwriting-referrals-reducer';

export interface Underwriting {
    readonly details: UnderwritingDetails[];
    readonly referrals: UnderwritingReferral[];
}

export const defaultUnderwriting: Underwriting = {
    details: [],
    referrals: [],
};

export const underwritingReducer = combineReducersWithDefault<Nullable<Underwriting>>(
    defaultUnderwriting,
    {
        details: underwritingDetailsCollectionReducer,
        referrals: underwritingReferralsReducer,
    }
);

export function getUnderwriting(state: State): Underwriting {
    return getProperty(state, 'underwriting', defaultUnderwriting);
}
