import React, { useState } from 'react'; // eslint-disable-line
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

interface Props {
    iconStart?: JSX.Element;
    iconEnd?: JSX.Element;
    InputProps?: any;
    label?: string;
    classes?: any;
    textStyle?: any;
    onChange: (values: string) => void;
}

export const IconTextField: React.FunctionComponent<Props> = ({
    iconStart,
    iconEnd,
    InputProps,
    onChange,
    classes,
    textStyle,
    ...props
}: Props) => {
    const [value, setValue] = useState('');
    const shrink = value.length > 0;

    return (
        <TextField
            {...props}
            size="small"
            variant="outlined"
            InputLabelProps={{
                shrink: shrink,
                className: shrink ? undefined : classes.inputLabelNoShrink,
                style: { fontSize: '0.8rem', marginLeft: shrink ? 5 : 30 },
            }}
            onChange={event => {
                onChange(event.target.value);
                setValue(event.target.value);
            }}
            style={textStyle}
            InputProps={{
                ...InputProps,
                startAdornment: iconStart ? (
                    <InputAdornment position="start">{iconStart}</InputAdornment>
                ) : null,
                endAdornment: iconEnd ? (
                    <InputAdornment position="end">{iconEnd}</InputAdornment>
                ) : null,
            }}
        />
    );
};
