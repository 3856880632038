import React, { useEffect } from 'react'; // eslint-disable-line
import { PoliceReportView } from '../common/police-report/views/police-report-view';
import { RulesResultsView } from '../common/rules/views/results-view';
import { Synopsis } from '../common/synopsis/synopsis';
import { CommentsView } from './comments/views/comments';
import { DeathCertificationView } from './death-certification/views/death-certification';
import { FormDetailsView } from './form-details/views/form-details';
import { LifeAssuredView } from './life-assured-details/views/life-assured-details';
import { ManageRequirementsView } from './manage-requirements/views/manage-requirements-view';
import { NoticeOfDeathView } from './notice-of-death/notice-of-death';
import { PolicySummaryView } from './policy-details/policy-summary';
import { ButtonCapture } from '../../../forms';
import { CommunicationsFormModalView } from './manage-requirements/views/communications/communications-form-view';
import { CommunicationEditView } from './manage-requirements/views/communications/communications-edit-modal';
import { AssessorAllocationContent } from './manage-requirements/views/communications/assessor-allocation-content';
import {
    OpenAddModalOptions,
    openAddModalAction,
} from '../../../form-modal/actions/add-modal-actions';
import { updateIntiatedClaimAction } from '../funeral/manage-requirements/action/communication-action';
import { Claim, getClaim } from '../../../claim';
import { State } from '../../../redux/root-reducer';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';

import { ThunkDispatch } from 'redux-thunk';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
interface Props {
    openAddModal?: any;
    updateClaim?: any;
    claim?: Claim;
}
const FuneralSynopsisView = ({ openAddModal, updateClaim, claim }: Props) => {
    useEffect(() => {
        if (!claim?.isClaimInitiated) {
            updateClaim();
            openAddModal({
                modalContent: (
                    <CommunicationsFormModalView
                        title="E-mail Correspondence (assessor allocation)"
                        previewScreen={() => <AssessorAllocationContent />}
                        formView={() => (
                            <CommunicationEditView isShowDocumentRequirements={false} />
                        )}
                    />
                ),
                modalData: {},
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Synopsis>
            <ManageRequirementsView />
            <LifeAssuredView />
            <PolicySummaryView />
            <FormDetailsView />
            <DeathCertificationView />
            <NoticeOfDeathView />
            <PoliceReportView />
            <RulesResultsView />
            <ButtonCapture>
                <CommentsView />
            </ButtonCapture>
        </Synopsis>
    );
};

const mapDispatchToProps: any = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    openAddModal: (options: OpenAddModalOptions) => dispatch(openAddModalAction(options)),
    updateClaim: () => dispatch(updateIntiatedClaimAction()),
});

const mapStateToProps = (state: State) => ({
    claim: getClaim(state),
});

export const FuneralSynopsis = connect(mapStateToProps, mapDispatchToProps)(FuneralSynopsisView);
