import { Theme } from '@material-ui/core';
import { createStyles, StyleRules } from '@material-ui/core/styles';
import { CSSProperties, WithStyles } from '@material-ui/core/styles/withStyles';
import globalStyles, { StyledComponent as GlobalStyledComponent } from '../../styles/styles';

export type StyledComponent = GlobalStyledComponent & WithStyles<typeof styles>;

export default function styles(theme: Theme): StyleRules {
    return createStyles({
        ...globalStyles(theme),
        claimStepper: claimStepper(theme),
        listItemIcon: listItemIcon(theme),
        menuItemSelected: menuItemSelected(),
    });
}

function claimStepper(theme: Theme): CSSProperties {
    return {
        width: '60vw',
        backgroundColor: 'transparent',
    };
}

function listItemIcon(theme: Theme): CSSProperties {
    return {
        marginRight: '5px',
    };
}

function menuItemSelected(): CSSProperties {
    return {
        background: 'linear-gradient(to right, #FBAB18 2%, #011F3C 2.1%)',
    };
}
