import React from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import { CountriesOfIssue } from '../../../../../codes/countries-of-issue';
import {
    Condition,
    FullWidthSearchSelectField,
    FullWidthTextField,
    FullWidthDatePicker,
    True,
    WhenFieldChanges,
    FullWidthRadioGroup,
    RadioOption,
} from '../../../../../forms';
import {
    OrganisationTypes,
    OrganisationTypeSuffixes,
} from '../../contact-details/contact-details-reducer';
import { requiredValidator } from '../../../../../forms/validations';
import styles from '../../../../../styles/styles';
import { RelationshipDetailsView } from './edit-relationship-details';
interface Props {}
const organisationDetailsView: React.FunctionComponent<Props> = () => {
    return (
        <React.Fragment>
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    name="claimantDetails.organisationDetails.organisationType"
                    options={OrganisationTypes}
                    sortOptions
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    name="claimantDetails.organisationDetails.organisationSuffix"
                    options={OrganisationTypeSuffixes}
                    sortOptions
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name="claimantDetails.organisationDetails.companyName"
                    label="Name"
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name="claimantDetails.organisationDetails.entityRegistrationNumber"
                    label="Registration Number"
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthDatePicker
                    name="claimantDetails.organisationDetails.dateOfIncorporation"
                    validate={requiredValidator}
                    disableFuture
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    name="claimantDetails.organisationDetails.countryOfIncorporation"
                    options={CountriesOfIssue}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthRadioGroup
                    name="claimantDetails.organisationDetails.taxStatus"
                    validate={requiredValidator}
                >
                    <RadioOption value="Tax Paying" />
                    <RadioOption value="Non Tax Paying" />
                </FullWidthRadioGroup>
                <WhenFieldChanges
                    field="claimantDetails.organisationDetails.taxStatus"
                    set="claimantDetails.organisationDetails.companyTaxNumber"
                    to={null}
                />
            </Grid>
            <Condition when="claimantDetails.organisationDetails.taxStatus" is="Tax Paying">
                <True>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            name="claimantDetails.organisationDetails.companyTaxNumber"
                            validate={requiredValidator}
                        />
                    </Grid>
                </True>
            </Condition>
            <RelationshipDetailsView
                propertyPrefix="claimantDetails.organisationDetails"
                isAnOrganisation
            />
        </React.Fragment>
    );
};

export const OrganisationDetailsView = withStyles(styles)(organisationDetailsView);
