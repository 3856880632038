import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { LabeledValue } from '../../../../controls/labeled-value';
import { OpenModal, openModalAction } from '../../../../form-modal/actions';
import { ButtonWrapper } from '../../../../forms';
import { DisplayAddress } from '../../../../forms/sub-forms/address-details';
import { State } from '../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import {
    UpdateNoticeOfDeath,
    updateNoticeOfDeathAction,
} from '../../common/notice-of-death/actions/update-notice-of-death';
import {
    getNoticeOfDeath,
    NoticeOfDeath,
} from '../../common/notice-of-death/notice-of-death-reducer';
import { editNoticeOfDeath } from './notice-of-death';

interface Props extends StyledComponent {
    isReadOnly: boolean;
    hasDeferredContracts: boolean;
    noticeOfDeath: NoticeOfDeath;
    openModal: OpenModal;
    updateNoticeOfDeath: UpdateNoticeOfDeath;
}

const funeralParlourDetailsView = ({
    classes,
    noticeOfDeath,
    isReadOnly,
    hasDeferredContracts,
    ...rest
}: Props) => {
    const edit = () => {
        editNoticeOfDeath({ noticeOfDeath, index: 3, ...rest });
    };
    const funeralParlourDetails = noticeOfDeath.funeralParlour || {};
    const addressDetails = funeralParlourDetails.addressDetails || {};
    return (
        <Grid item xs={12} container justify="flex-start" className={classes.marginTop}>
            <Grid item xs={12} container>
                <Grid item xs={10}>
                    <Typography className={classes.heading} variant="body1">
                        Funeral Parlour Details
                    </Typography>
                </Grid>
                {!hasDeferredContracts && (
                    <ButtonWrapper>
                        <Grid item xs={2} className={classes.rightAlign}>
                            <Tooltip title="Edit Parlour Details Details">
                                <Button id="EditParlourDetails" color="secondary" onClick={edit}>
                                    <Edit />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </ButtonWrapper>
                )}
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Designation Number"
                    value={funeralParlourDetails.designationNumber}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Date of Funeral"
                    value={getDisplayDate(funeralParlourDetails.dateOfFuneral)}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Name" value={funeralParlourDetails.name} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Telephone Number"
                    value={funeralParlourDetails.telephoneNumber}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <DisplayAddress label="Residential Address" address={addressDetails} />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state: State) => ({
    noticeOfDeath: getNoticeOfDeath(state),
    isReadOnly: state.claimSession.readOnly,
    hasDeferredContracts: state.claimSession.hasDeferredContracts,
});

const mapDispatchToProps = {
    openModal: openModalAction,
    updateNoticeOfDeath: updateNoticeOfDeathAction,
};

export const FuneralParlourDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(funeralParlourDetailsView));
