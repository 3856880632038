import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { claimSubmit } from '../../../../../claim';
import { PoliceReport } from '../police-report-reducer';

type SubmitPoliceReportReturn = Promise<any>;
export type SubmitPoliceReport = (PoliceReport: PoliceReport) => SubmitPoliceReportReturn;
type SubmitPoliceReportAction = (
    policeReport: PoliceReport
) => ReduxThunkAction<SubmitPoliceReportReturn>;
export const submitPoliceReportAction: SubmitPoliceReportAction = (policeReport: PoliceReport) => {
    return async (dispatch, getState) => {
        return await claimSubmit(dispatch, getState, claim => ({ ...claim, policeReport }));
    };
};
