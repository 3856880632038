import { BeneficiaryManualScreening, Payment } from '../reducer';
import { Claim } from '../../claim/reducers';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { claimSubmit } from '../../claim';

type BeneficiaryManualScreeningReturn = Promise<any>;
export type BeneficiaryManualScreeningAction = (
    paymentIndex: number,
    beneficiaryManualScreening: BeneficiaryManualScreening
) => ReduxThunkAction<BeneficiaryManualScreeningReturn>;
export const manualScreenAction: BeneficiaryManualScreeningAction = (
    paymentIndex: number,
    beneficiaryManualScreening: BeneficiaryManualScreening
) => async (dispatch, getState) => {
    return await claimSubmit(dispatch, getState, claim =>
        getUpdatedClaim(claim, paymentIndex, beneficiaryManualScreening)
    );
};

function getUpdatedClaim(
    claim: Claim,
    paymentIndex: number,
    beneficiaryManualScreening: BeneficiaryManualScreening
): Claim {
    return {
        ...claim,
        payments: getUpdatedPayments(claim.payments, paymentIndex, beneficiaryManualScreening),
    };
}

function getUpdatedPayments(
    payments: Payment[],
    paymentIndex: number,
    beneficiaryManualScreening: BeneficiaryManualScreening
): Payment[] {
    payments[paymentIndex].beneficiaryManualScreening = {
        beneficiaryManualScreeningComment:
            beneficiaryManualScreening.beneficiaryManualScreeningComment,
        isBeneficiaryManualScreeningConfirmed:
            beneficiaryManualScreening.isBeneficiaryManualScreeningConfirmed,
        isBeneficiaryManualScreening: beneficiaryManualScreening.isBeneficiaryManualScreening,
        assessor: beneficiaryManualScreening.assessor,
        beneficiaryManualScreeningDate: new Date(),
    };

    payments[paymentIndex].screeningError = undefined;
    return payments;
}
