import { getAccount } from './authentication-service';

const rolesPropertyName = 'roles';
const authGuard = (roles?: string[]): (() => boolean) => () => {
    const user = getAccount();

    if (!!user && user.idToken.hasOwnProperty(rolesPropertyName)) {
        const userRoles: string[] = (user.idToken[rolesPropertyName] as any) as [];
        if (!roles || roles.some(role => userRoles.includes(role))) {
            return true;
        }
    }

    return false;
};

export const AuthGuard = authGuard;
