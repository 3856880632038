import { isNil } from 'lodash';

interface Options {
    stripBeforeLastDot?: boolean;
}
export default function parseName(name: string, { stripBeforeLastDot = true }: Options = {}) {
    const noNameError = 'A name must be provided when calling nameToLabel';
    if (isNil(name)) {
        throw new Error(noNameError);
    }
    let _name = name.trim();
    if (_name === '') {
        throw new Error(noNameError);
    }
    if (!stripBeforeLastDot) {
        return _name;
    }
    if (_name.indexOf('.') >= 0) {
        const parts = _name.split('.');
        _name = parts[parts.length - 1];
    }
    return _name;
}
