import React from 'react'; // eslint-disable-line
import { GenericForm, FormErrors } from '../../../../forms';
import { StyledComponent } from '../../../../claim-audit-trail/styles';
import {
    updateRAClaimDetailsAction,
    UpdateRAClaimDetails,
} from '../claim-details/actions/update-ra-claim-details';
import { OnSubmitSuccessProps } from '../../../../workflow-navigation';
import { withStyles } from '@material-ui/styles';
import styles from '../../../../styles/styles';
import { State } from '../../../../redux/root-reducer';
import { connect } from 'react-redux';
import { getRaDeathClaimDetails, RAClaimDetails } from './reducer';
import { RAClaimDetailsEditView } from './ra-claim-details-edit-view';
import { DeathClaimDetails } from '../../death/claim-event-details/reducer';

interface Props extends OnSubmitSuccessProps, StyledComponent {
    updateRAClaimDetails: UpdateRAClaimDetails;
    raClaimDetails: RAClaimDetails;
}

const RAClaimDetailsForm = GenericForm<DeathClaimDetails>();
const raClaimDetailsEditModal: React.FunctionComponent<Props> = ({
    raClaimDetails,
    updateRAClaimDetails,
    ...rest
}: Props) => {
    const onSubmit = (submissionDetails: DeathClaimDetails) => {
        return updateRAClaimDetails(submissionDetails);
    };
    return (
        <RAClaimDetailsForm
            onSubmit={onSubmit}
            initialValues={raClaimDetails.claimEventDetails}
            {...rest}
        >
            <RAClaimDetailsEditView />
            <FormErrors />
        </RAClaimDetailsForm>
    );
};
const mapDispatchToProps = {
    updateRAClaimDetails: updateRAClaimDetailsAction,
};
const mapStateToProps = (state: State) => ({
    raClaimDetails: getRaDeathClaimDetails(state) || {},
});
export const RAClaimDetailsEditModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(raClaimDetailsEditModal));
