import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { ExpansionPanel } from '../../../../../forms/form-components/expansion-panel/expansion-panel';
import { Nullable } from '../../../../../shared-types';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { AstuteInformation } from '../../../common/life-assured/views/astute-information';
import { OriginalDoctorsDetailsView } from '../../../common/life-assured/views/original-doctors-details';
import { PreviousPaymentsView } from '../../../common/life-assured/views/previous-payments';
import { PersonalDetailsView } from '../../../common/life-assured/views/personal-details';
import { OccupationDetailsView } from './occupation/occupation-details';
import { MedicalHistoryView } from './medical-history/medical-history-view';
import { getObjectWarning } from '../../../../../shared-functions';
import { connect } from 'react-redux';
import { State } from '../../../../../redux/root-reducer';
import { getDeathClaim } from '../../../../../claim/reducers/claim-reducers/death-claim-reducer';
import { defaultLifeAssuredDetails } from '../../../../../life-assured-details/reducer';
interface RiskProps {
    hasRisk?: Nullable<boolean>;
    hasLifeCoverUnder3Years: Nullable<boolean>;
}
interface Props extends StyledComponent, RiskProps {
    validationErrors: string;
    claimLifeNotificationsUpdated?: string;
}
const lifeAssuredDetailsView = ({
    validationErrors,
    claimLifeNotificationsUpdated,
    ...rest
}: Props) => {
    let notifier;
    if (validationErrors && validationErrors.includes('LifeAssuredDetails')) {
        notifier = getObjectWarning(validationErrors, validationErrors);
    } else if (claimLifeNotificationsUpdated === 'No') {
        notifier = 'Warning';
    }
    return (
        <ExpansionPanel name="Life Assured Details" notifier={notifier}>
            <PersonalDetailsView />
            <PreviousPaymentsView />
            <RiskLifeAssuredDetailsView {...rest} />
        </ExpansionPanel>
    );
};

const RiskLifeAssuredDetailsView = ({ hasLifeCoverUnder3Years, hasRisk }: RiskProps) => {
    if (hasRisk && hasLifeCoverUnder3Years) {
        return (
            <React.Fragment>
                <AstuteInformation />
                <OriginalDoctorsDetailsView />
                <MedicalHistoryView />
                <OccupationDetailsView />
            </React.Fragment>
        );
    }

    return null;
};

const mapStateToProps = (state: State) => {
    const claim = getDeathClaim(state);
    const lifeAssured = claim.lifeAssuredDetails || defaultLifeAssuredDetails;
    return {
        validationErrors: claim.validationErrors,
        hasLifeCoverUnder3Years: claim.hasLifeCoverUnder3Years || false,
        hasRisk: claim.hasRisk || false,
        claimLifeNotificationsUpdated: lifeAssured.claimLifeNotificationsUpdated,
    };
};

export const LifeAssuredDetailsView = connect(
    mapStateToProps,
    {}
)(withStyles(styles)(lifeAssuredDetailsView));
