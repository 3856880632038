import React from 'react'; // eslint-disable-line

import { Button, Grid, Paper, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { State } from '../../redux/root-reducer';
import { CloseModal, closeModalAction } from '../actions';
import { HandleSubmit } from '../../workflow-navigation';
import styles, { StyledComponent } from '../styles';

type Props = StyledComponent & {
    readonly formView: React.ComponentType<any>;
    readonly onSubmit?: HandleSubmit;
    readonly closeModal: CloseModal;
    readonly closeButton?: boolean;
    readonly formStyle?: any;
    readonly isButtonsHidden?: boolean;
};
const formModalView: React.FunctionComponent<Props> = ({
    classes,
    formView,
    onSubmit,
    closeModal,
    closeButton,
    formStyle,
    isButtonsHidden,
}: Props) => {
    const FormView = formView;
    return (
        <Paper className={`${formStyle ? formStyle : null} ${classes.paper}`}>
            <FormView onSubmitSuccess={() => closeModal()} />
            {isButtonsHidden ? null : (
                <Grid container spacing={0} justify="flex-end">
                    <Button
                        id="Cancel"
                        variant="contained"
                        className={classes.button}
                        onClick={closeModal}
                    >
                        {closeButton ? 'Close' : 'Cancel'}
                    </Button>
                    {!closeButton && (
                        <Button
                            id="Save"
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={submit}
                        >
                            Save
                        </Button>
                    )}
                </Grid>
            )}
        </Paper>
    );

    async function submit() {
        if (onSubmit) {
            const result = await onSubmit();
            if (!result) {
                return;
            }
        }
        closeModal();
    }
};

const mapStateToProps = (state: State) => ({
    onSubmit: state.claimSession.formSubmission.onSubmit,
});

const mapDispatchToProps = {
    closeModal: closeModalAction,
};

export const FormModalView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(formModalView));
