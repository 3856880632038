import React from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import { CommunicationMethods } from '../../../codes/communication-methods';
import styles, { StyledComponent } from '../../../styles/styles';
import { FullWidthSearchSelectField, FullWidthTextField } from '../../form-components';
import { TelephoneNumberMask } from '../../input-masks';
import { EmailMask } from '../../input-masks/email-mask';
import {
    composeValidators,
    conditionalCustomRequired,
    emailFormatValidator,
    requiredValidator,
    SyncValidator,
    conditionalRequired,
} from '../../validations';
import { getPropertyValueFunction } from '../utility-functions';
import { CommunicationDetails } from './model';

interface DisplayOptions {
    readonly telephoneNumber?: boolean;
    readonly cellphoneNumber?: boolean;
    readonly emailAddress?: boolean;
    readonly faxNumber?: boolean;
    readonly preferredMethodOfCommunication?: boolean;
}

interface FieldRequiredValidationOptions {
    readonly telephoneNumber?: boolean;
    readonly cellphoneNumber?: boolean;
    readonly emailAddress?: boolean;
    readonly faxNumber?: boolean;
    readonly preferredMethodOfCommunication?: boolean;
}

const defaultDisplayOptions = {
    telephoneNumber: true,
    cellphoneNumber: true,
    emailAddress: true,
    faxNumber: false,
    preferredMethodOfCommunication: true,
};

const defaultValidationOptions = {
    telephoneNumber: false,
    cellphoneNumber: true,
    emailAddress: true,
    faxNumber: false,
    preferredMethodOfCommunication: false,
};

type FieldsRequired = 'All' | 'None' | 'Any';

interface Props extends StyledComponent {
    readonly communicationDetailsPropertyName?: string;
    readonly idBaseName?: string;
    readonly required?: FieldsRequired;
    readonly displayOptions?: DisplayOptions;
    readonly disabled?: boolean;
    readonly requiredOptions?: FieldRequiredValidationOptions;
}

const communicationDetailsSubForm: React.FunctionComponent<Props> = ({
    communicationDetailsPropertyName,
    idBaseName,
    required = 'None',
    displayOptions = defaultDisplayOptions,
    requiredOptions = defaultValidationOptions,
    disabled,
}: Props) => {
    const propertyPrefix = !!communicationDetailsPropertyName
        ? communicationDetailsPropertyName + '.'
        : '';

    const commDetailsRequiredValidator = getRequiredValidator(
        communicationDetailsPropertyName,
        required
    );

    return (
        <React.Fragment>
            {displayOptions.telephoneNumber &&
                telephoneNumber(
                    !!requiredOptions.telephoneNumber,
                    commDetailsRequiredValidator,
                    propertyPrefix,
                    idBaseName,
                    disabled
                )}
            {displayOptions.cellphoneNumber &&
                cellphoneNumber(
                    !!requiredOptions.cellphoneNumber,
                    commDetailsRequiredValidator,
                    propertyPrefix,
                    idBaseName,
                    disabled
                )}
            {displayOptions.emailAddress &&
                emailAddress(
                    !!requiredOptions.emailAddress,
                    commDetailsRequiredValidator,
                    propertyPrefix,
                    idBaseName,
                    disabled
                )}
            {displayOptions.faxNumber &&
                faxNumber(!!requiredOptions.faxNumber, propertyPrefix, idBaseName, disabled)}
            {displayOptions.preferredMethodOfCommunication &&
                preferredMethodOfCommunication(
                    propertyPrefix,
                    idBaseName,
                    disabled,
                    requiredOptions.preferredMethodOfCommunication
                )}
        </React.Fragment>
    );
};

function telephoneNumber(
    validateRequired: boolean,
    commDetailsRequiredValidator: SyncValidator,
    propertyPrefix?: string,
    idBaseName?: string,
    disabled?: boolean
) {
    return (
        <Grid item xs={6} lg={6}>
            <FullWidthTextField
                name={`${propertyPrefix}telephoneNumber`}
                id={`${idBaseName || propertyPrefix}TelephoneNumber`}
                label="Telephone Number"
                mask={TelephoneNumberMask}
                validate={
                    validateRequired
                        ? composeValidators(
                              requiredValidator,
                              getTelephoneConditionalRequired(propertyPrefix)
                          )
                        : getTelephoneConditionalRequired(propertyPrefix)
                }
                disabled={disabled}
            />
        </Grid>
    );
}
function cellphoneNumber(
    validateRequired: boolean,
    commDetailsRequiredValidator: SyncValidator,
    propertyPrefix?: string,
    idBaseName?: string,
    disabled?: boolean
) {
    return (
        <Grid item xs={6} lg={6}>
            <FullWidthTextField
                name={`${propertyPrefix}cellphoneNumber`}
                id={`${idBaseName || propertyPrefix}CellphoneNumber`}
                label="Cellphone Number"
                mask={TelephoneNumberMask}
                validate={
                    validateRequired
                        ? composeValidators(
                              commDetailsRequiredValidator,
                              getCellphoneConditionalRequired(propertyPrefix)
                          )
                        : getCellphoneConditionalRequired(propertyPrefix)
                }
                disabled={disabled}
            />
        </Grid>
    );
}
function faxNumber(
    validateRequired: boolean,
    propertyPrefix?: string,
    idBaseName?: string,
    disabled?: boolean
) {
    return (
        <Grid item xs={6} lg={6}>
            <FullWidthTextField
                name={`${propertyPrefix}faxNumber`}
                id={`${idBaseName || propertyPrefix}faxNumber`}
                label="Fax Number"
                mask={TelephoneNumberMask}
                validate={validateRequired ? requiredValidator : undefined}
                disabled={disabled}
            />
        </Grid>
    );
}
function emailAddress(
    validateRequired: boolean,
    commDetailsRequiredValidator: SyncValidator,
    propertyPrefix?: string,
    idBaseName?: string,
    disabled?: boolean
) {
    return (
        <Grid item xs={6} lg={6}>
            <FullWidthTextField
                name={`${propertyPrefix}emailAddress`}
                id={`${idBaseName || propertyPrefix}EmailAddress`}
                label="Email Address"
                validate={
                    validateRequired
                        ? composeValidators(
                              emailFormatValidator,
                              commDetailsRequiredValidator,
                              getEmailConditionalRequired(propertyPrefix)
                          )
                        : composeValidators(
                              emailFormatValidator,
                              getEmailConditionalRequired(propertyPrefix)
                          )
                }
                mask={EmailMask}
                disabled={disabled}
            />
        </Grid>
    );
}
function preferredMethodOfCommunication(
    propertyPrefix?: string,
    idBaseName?: string,
    disabled?: boolean,
    isRequired?: boolean
) {
    return (
        <Grid item xs={6} lg={6}>
            <FullWidthSearchSelectField
                name={`${propertyPrefix}preferredMethodOfCommunication`}
                id={`${idBaseName || propertyPrefix}PreferredMethodOfCommunication`}
                label="Preferred Method Of Communication"
                options={CommunicationMethods}
                disabled={disabled}
                validate={conditionalRequired(() => !!isRequired)}
            />
        </Grid>
    );
}

function getRequiredValidator(
    communicationDetailsPropertyName?: string,
    fieldRequired?: FieldsRequired
): SyncValidator {
    switch (fieldRequired) {
        case 'All':
            return requiredValidator;
        case 'Any':
            return getAnyRequiredValidator(communicationDetailsPropertyName);
        case 'None':
        default:
            return () => undefined;
    }
}

const requiredMessage = 'At least 1 valid method of communication is required';
function getAnyRequiredValidator(communicationDetailsPropertyName?: string) {
    const propValue = getPropertyValueFunction<CommunicationDetails>(
        communicationDetailsPropertyName
    );

    return conditionalCustomRequired(
        requiredMessage,
        values => !propValue(values, 'cellphoneNumber') && !propValue(values, 'emailAddress')
    );
}

function getEmailConditionalRequired(propertyPrefix?: string) {
    const propValue = getPropertyValueFunction<CommunicationDetails>(propertyPrefix);
    return conditionalRequired(
        values =>
            propValue(values, 'preferredMethodOfCommunication') === 'Email' &&
            !propValue(values, 'emailAddress')
    );
}

function getCellphoneConditionalRequired(propertyPrefix?: string) {
    const propValue = getPropertyValueFunction<CommunicationDetails>(propertyPrefix);
    return conditionalRequired(
        values =>
            propValue(values, 'preferredMethodOfCommunication') === 'Cellphone' &&
            !propValue(values, 'cellphoneNumber')
    );
}

function getTelephoneConditionalRequired(propertyPrefix?: string) {
    const propValue = getPropertyValueFunction<CommunicationDetails>(propertyPrefix);
    return conditionalRequired(
        values =>
            propValue(values, 'preferredMethodOfCommunication') === 'Telephone' &&
            !propValue(values, 'telephoneNumber')
    );
}

export const CommunicationDetailsSubForm = withStyles(styles)(communicationDetailsSubForm);
