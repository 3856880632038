export const ClaimantDocumentNameList = [
    { value: 'Company Members ID Documents', label: 'Company Members ID Documents' },
    { value: 'ID/Passport of Claimant', label: 'ID/Passport of Claimant' },
    {
        value: 'Letter from doctor confirming gestation period for stillbirth',
        label: 'Letter from doctor confirming gestation period for stillbirth',
    },
    { value: 'Marriage certificate', label: 'Marriage certificate' },
    { value: 'Proof of bank account', label: 'Proof of bank account' },
    { value: 'Proof of address', label: 'Proof of address' },
    { value: 'Proof of executorship', label: 'Proof of executorship' },
    { value: 'Unabridged birth certificate', label: 'Unabridged birth certificate' },
    { value: 'Proof of Studies', label: 'Proof of Studies' },
    { value: 'Claim form', label: 'Claim form' },
    { value: 'Employer ID', label: 'Employer ID' },
    { value: 'Other', label: 'Other' },
];

export const PolicyOwnerDocumentNameList = [
    { value: 'Company Members ID Documents', label: 'Company Members ID Documents' },
    { value: 'ID/Passport of Claimant', label: 'ID/Passport of Claimant' },
    {
        value: 'Letter from doctor confirming gestation period for stillbirth',
        label: 'Letter from doctor confirming gestation period for stillbirth',
    },
    { value: 'Marriage certificate', label: 'Marriage certificate' },
    { value: 'Proof of address', label: 'Proof of address' },
    { value: 'Proof of bank account', label: 'Proof of bank account' },
    { value: 'Proof of executorship', label: 'Proof of executorship' },
    { value: 'Claim form', label: 'Claim form' },
    { value: 'Employer ID', label: 'Employer ID' },
    { value: 'Other', label: 'Other' },
];

export const LifeAssuredDocumentNameList = [
    { value: 'Unabridged birth certificate', label: 'Unabridged birth certificate' },
    { value: 'BI 1663/Notice of death', label: 'BI 1663/Notice of death' },
    { value: 'BI 1680 notice of death', label: 'BI 1680/Notice of death' },
    { value: 'Death certificate', label: 'Death certificate' },
    { value: 'ID/Passport of Deceased', label: 'ID/Passport of Deceased' },
    { value: 'Letter of authority', label: 'Letter of authority' },
    { value: 'Parlour invoice', label: 'Parlour invoice' },
    { value: 'Police statement', label: 'Police statement' },
    { value: 'Proof of executorship', label: 'Proof of executorship' },
    { value: 'Will and Testament or Affidavit', label: 'Will and Testament or Affidavit' },
    { value: 'Other', label: 'Other' },
];

export const FullDocumentNameList = [
    { value: 'Unabridged birth certificate', label: 'Unabridged birth certificate' },
    { value: 'CK1 Document', label: 'CK1 Document' },
    { value: 'Company Resolution', label: 'Company Resolution' },
    { value: 'Trust resolution', label: 'Trust resolution' },
    { value: 'Trust deed', label: 'Trust deed' },
    { value: 'Trust Resolution/Trust Deed', label: 'Trust Resolution/Trust Deed' },
    { value: 'Letter of authority', label: 'Letter of authority' },
    { value: 'Company Members ID Documents', label: 'Company members ID documents' },
    { value: 'ID / Passport', label: 'ID / Passport' },
    { value: 'ID of Claimant', label: 'ID of Claimant' },
    { value: 'ID of Deceased', label: 'ID of Deceased' },
    { value: 'BI 1663/Notice of death', label: 'BI 1663/Notice of death' },
    { value: 'Police statement', label: 'Police statement' },
    { value: 'Extract of medical report', label: 'Extract of medical report' },
    { value: 'Death certificate', label: 'Death certificate' },
    { value: 'Exchange Form', label: 'Exchange Form' },
    { value: 'Proof of address', label: 'Proof of address' },
    { value: 'Proof of guardianship', label: 'Proof of guardianship' },
    { value: 'Proof of residence', label: 'Proof of residence' },
    { value: 'Proof of bank account', label: 'Proof of bank account' },
    { value: 'Proof of executorship', label: 'Proof of executorship' },
    { value: 'Proof of Studies', label: 'Proof of Studies' },
    { value: 'Proof of dependency', label: 'Proof of dependency' },
    { value: 'Will and Testament or Affidavit', label: 'Will and Testament or Affidavit' },
    { value: 'Accident Report', label: 'Accident Report' },
    { value: 'Divorce decree', label: 'Divorce decree' },
    { value: 'Letter of curatorship', label: 'Letter of curatorship' },
    { value: 'Marriage certificate', label: 'Marriage certificate' },
    { value: 'Tax compliance status pin', label: 'Tax compliance status pin' },
    { value: 'Declaration of dependency', label: 'Declaration of dependency' },
    {
        value: 'Liquidation and distribution account',
        label: 'Liquidation and distribution account',
    },
    { value: 'BI 1680 notice of death', label: 'BI 1680/Notice of death' },
    { value: 'Parlour invoice', label: 'Parlour invoice' },
    {
        value: 'Letter from doctor confirming gestation period for stillbirth',
        label: 'Letter from doctor confirming gestation period for stillbirth',
    },
    { value: 'Income Tax Reference Number', label: 'Income Tax Reference Number' },
    { value: 'Other', label: 'Other' },
];
