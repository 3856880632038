import { withStyles, Grid, Typography, Paper, Button, Tooltip } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import styles, { StyledComponent } from '../../../../../styles/styles';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { Edit } from '@material-ui/icons';
import { connect } from 'react-redux';
import { State } from '../../../../../redux/root-reducer';
import { FormDetails, getFormDetails } from '../form-details-reducer';
import { OpenModal, openModalAction } from '../../../../../form-modal/actions';
import { editLifeAssureDetails } from './life-assured-edit';
import { editPolicyHolderDetails } from './policy-holder-edit';
import { UpdateFormDetails, updateFormDetailsAction } from '../actions/update-form-details';
interface BaseProps {
    formDetails?: FormDetails;
    updateFormDetails?: UpdateFormDetails;
    type: string;
    disableEditButton?: boolean;
}

interface Props extends BaseProps, StyledComponent {
    readonly openModal: OpenModal;
}

const contactDetailsView: React.FunctionComponent<Props> = ({
    classes,
    openModal,
    formDetails,
    updateFormDetails,
    type,
    disableEditButton,
}: Props) => {
    const edit = () => {
        const details = formDetails || {};
        type === 'LifeAssured'
            ? editLifeAssureDetails(openModal, details, 2, updateFormDetails)
            : editPolicyHolderDetails(openModal, details, 2, updateFormDetails);
    };

    const contactDetails =
        type === 'LifeAssured'
            ? formDetails?.lifeAssured?.contactDetails
            : formDetails?.policyHolder && formDetails.policyHolder?.individual?.contactDetails;

    return (
        <React.Fragment>
            <Grid container justify="space-between" className={classes.marginTop2}>
                <Grid container item xs={10} alignItems="center">
                    <Typography style={{ padding: 10 }} className={classes.heading} variant="body1">
                        Contact Details
                    </Typography>
                </Grid>
            </Grid>
            <Paper className={classes.paper}>
                <Grid container item xs={12} alignItems="center" justify="flex-end">
                    <Tooltip title="Edit Contact Details">
                        <Button
                            id="EditPersonalDetails"
                            color="secondary"
                            onClick={edit}
                            disabled={!disableEditButton}
                        >
                            <Edit />
                        </Button>
                    </Tooltip>
                </Grid>
                <Grid container justify="flex-start">
                    <Grid item xs={12} container justify="flex-start">
                        {contactDetails?.homeTelephoneNumber && (
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Home telephone number"
                                    value={contactDetails?.homeTelephoneNumber}
                                />
                            </Grid>
                        )}
                        {contactDetails?.workTelephoneNumber && (
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Work telephone"
                                    value={contactDetails?.workTelephoneNumber}
                                />
                            </Grid>
                        )}
                        <Grid item xs={4} lg={3}>
                            <LabeledValue
                                label="Cellphone Number"
                                value={contactDetails?.cellphoneNumber}
                            />
                        </Grid>
                        {contactDetails?.faxNumber && (
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Fax Number"
                                    value={contactDetails?.faxNumber}
                                />
                            </Grid>
                        )}
                        <Grid item xs={4} lg={3}>
                            <LabeledValue
                                label="Email Address"
                                value={contactDetails?.emailAddress}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

const mapStateToProps = (state: State, ownProps: BaseProps) => ({
    formDetails: ownProps.formDetails || getFormDetails(state),
    disableEditButton: ownProps.disableEditButton || false,
});

const mapDispatchToProps = {
    openModal: openModalAction,
    updateFormDetails: updateFormDetailsAction,
};

export const ContactDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(contactDetailsView));
