import { env } from '../../env';
import axios from 'axios';
import { hideLoadingAction, showLoadingAction } from '../../forms/loading-screen/actions';
import {
    LOAD_AD_USERS,
    LOAD_ASSESSORS,
    UPDATE_ASSESSOR,
    EDIT_ASSESSOR,
} from '../types/assessors-types';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { Assessors, ADUser, AssessorDetails } from '../reducer/assessors-reducer';
import { ClaimReference, getReferences } from '../../claim/reducers/claim-reference-reducer';
import { openDialogAction, openToastAction } from '../../confirmation-dialog';

type AssessorLimitsThunk = () => ReduxThunkAction;
export const AssessorsAction: AssessorLimitsThunk = () => {
    return async dispatch => {
        dispatch(showLoadingAction());
        const assessorsUri = `${env().CLAIMS_API}/api/assessorDashboard/GetAssessors`;

        await axios
            .get<Assessors>(assessorsUri)
            .then(response => {
                if (response.data) {
                    const assessor = response.data.reverse();
                    dispatch({ type: LOAD_ASSESSORS, assessorLimits: assessor });
                    dispatch(hideLoadingAction());
                }
            })
            .catch(e => {
                console.log(e);
                dispatch(hideLoadingAction());
            });
        return;
    };
};

export const ADUserAction: AssessorLimitsThunk = () => {
    return async dispatch => {
        dispatch(showLoadingAction());
        const adUserUri = `${env().CLAIMS_API}/api/utilities/GetUsers`;

        await axios
            .get<ADUser>(adUserUri)
            .then(response => {
                if (response.data) {
                    dispatch({ type: LOAD_AD_USERS, adUsers: response.data });
                    dispatch(hideLoadingAction());
                }
            })
            .catch(e => {
                console.log(e);
                dispatch(hideLoadingAction());
            });
        return;
    };
};

type EditAssessorReturn = Promise<any>;
type EditAssessorsAction = (assessor: Assessors) => ReduxThunkAction<EditAssessorReturn>;

export const UpdateAssessorAction: EditAssessorsAction = (assessor: Assessors) => {
    return async (dispatch, getState) => {
        dispatch(showLoadingAction());
        const assessors = getState().assessorDashboard.assessorLimits;
        const editAssessorUri = `${env().CLAIMS_API}/api/assessorDashboard/EditAssessor`;
        await axios
            .put<Assessors>(editAssessorUri, assessor)
            .then(response => {
                if (response.data) {
                    assessors.map((assessor: Assessors) => {
                        if (assessor._id === response.data._id) {
                            return (assessors[assessors.indexOf(assessor)] = response.data);
                        }
                        return assessor;
                    });

                    dispatch({ type: UPDATE_ASSESSOR, assessorLimits: assessors });
                    dispatch(hideLoadingAction());
                }
            })
            .catch(e => {
                console.log(e);
                dispatch(hideLoadingAction());
            });
        return;
    };
};

type SendEmailReturn = Promise<any>;
export type SendEmailAction = (
    EmailDetails: AssessorDetails,
    isFromManager: boolean
) => SendEmailReturn;
type SendManagerEmail = (
    EmailDetails: AssessorDetails,
    isFromManager: boolean
) => ReduxThunkAction<SendEmailReturn>;
export const SendManagerEmailAction: SendManagerEmail = (
    EmailDetails: AssessorDetails,
    isFromManager: boolean
) => {
    return async (dispatch, getState) => {
        const state = getState();
        const references = getReferences(state);
        const assessorEmail = isFromManager ? EmailDetails.requesterEmail : null;
        const message = isFromManager
            ? buildAssessorHtml(EmailDetails, references)
            : buildManagerHtml(EmailDetails, references);
        dispatch(showLoadingAction());
        const sendEmailUri = `${env().CLAIMS_API}/api/assessorDashboard/SendEmail`;
        await axios
            .post<Assessors>(sendEmailUri, { message: message, toEmail: assessorEmail })
            .then(response => {
                if (response.data) {
                    dispatch(
                        openToastAction({
                            toastContent: {
                                message: isFromManager
                                    ? 'Feedback has been sent'
                                    : 'Request has been sent',
                                type: 'success',
                            },
                        })
                    );
                } else {
                    errorDialog(dispatch, 'There was an error sending email');
                }
                dispatch(hideLoadingAction());
            })
            .catch(e => {
                dispatch(hideLoadingAction());
                errorDialog(dispatch, 'There was an error sending email');
            });
    };
};

const errorDialog = (dispatch: any, message: string) => {
    dispatch(
        openDialogAction({
            content: {
                title: '',
                body: message,
                error: true,
            },
        })
    );
};

export const EditAssessorAction: EditAssessorsAction = () => {
    return async dispatch => {
        dispatch({ type: EDIT_ASSESSOR });
    };
};

export const reseverseName = (name: string) => {
    if (name.includes(',')) {
        const removedComma = name.replace(',', '');
        const newName = removedComma.split(' ').reverse().join(' ');
        return newName;
    }
    return name;
};

const buildManagerHtml = (EmailDetails: AssessorDetails, references: ClaimReference[]) => {
    return `<html>
        <head>
        <style>
        p.spacing {
           margin-bottom:25px;
        }
        p {
           line-height:12px;
        }
        </style>
        </head>
        
        <div><p class="spacing">Dear Manager,</p><p>You have received a claim requesting authorisation from ${reseverseName(
            EmailDetails.assessor
        )}.</p><p>Policy number of the claim:</p>
        ${EmailDetails.policyNumbers
            .map(policyNumber => `<p>${policyNumber}</p>`)
            .join('')}<p class="spacing">Horizon case number: ${
        references[0]?.referenceId
    }</p><p >Kind Regards,</p><p>Liberty Group Limited</p></div>
        </html>`;
};

const buildAssessorHtml = (EmailDetails: AssessorDetails, references: ClaimReference[]) => {
    if (EmailDetails.decisionAction === 'Approved') {
        EmailDetails.decisionAction = 'Authorised';
    }
    return `<html>
        <head>
        <style>
        p.spacing {
           margin-bottom:25px;
        }
        p {
           line-height:12px;
        }
        </style>
        </head>
        
        <div><p class="spacing">Dear ${reseverseName(
            EmailDetails.assessor
        )},</p><p>Policy number of the claim:</p>
        ${EmailDetails.policyNumbers.map(policyNumber => `<p>${policyNumber}</p>`).join('')}
            <p>Horizon case number: ${references[0]?.referenceId}</p><p>Decision: ${
        EmailDetails.decisionAction
    }</p><p class="spacing">Comments: ${
        EmailDetails.comment
    }</p><p >Kind Regards,</p><p>${reseverseName(EmailDetails.approverName)}</p></div>
        </html>`;
};
