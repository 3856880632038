import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { getRADeathClaim } from '../../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { ExpansionPanel } from '../../../../../forms/form-components/expansion-panel/expansion-panel';
import { State } from '../../../../../redux/root-reducer';
import { getObjectWarning } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { DependantsRequirementsView } from './dependants/dependants-requirements-view';
import { LifeAssuredRequirementsView } from './life-assured-requirements/life-assured-requirements-view';

interface OwnProps {
    isReadOnly: boolean;
}
type Props = StyledComponent &
    OwnProps & {
        validationErrors: string;
    };
const manageRequirementsView: React.FunctionComponent<Props> = ({
    validationErrors,
    isReadOnly,
}: Props) => {
    let notifier;
    if (validationErrors && validationErrors.includes('documentsStatus')) {
        notifier = getObjectWarning(validationErrors, validationErrors);
    }
    return (
        <ExpansionPanel name="Requirement management" notifier={notifier}>
            <LifeAssuredRequirementsView />
            <DependantsRequirementsView />
        </ExpansionPanel>
    );
};

const mapStateToProps = (state: State) => ({
    validationErrors: getRADeathClaim(state).validationErrors,
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = {};

export const ManageRequirementsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(manageRequirementsView));
