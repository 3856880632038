import { reduceAndSubmitClaim } from '../../../../../claim/actions/reduce-and-submit-claim';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { addDependantActionCreator } from './add-details';
import { deleteDependantActionCreator } from './delete-details';
import { updateDependantActionCreator } from './update-details';
import { Dependant } from '../reducer';

/* eslint-disable-next-line */
type SubmitReturn = Promise<any>;

export type AddAndSubmitDependant = (executor: Dependant) => SubmitReturn;
type AddAndSubmitDependantThunk = (executor: Dependant) => ReduxThunkAction<SubmitReturn>;
export const addAndSubmitDependantThunk: AddAndSubmitDependantThunk = (executor: Dependant) => (
    dispatch,
    getState
) => reduceAndSubmitClaim(dispatch, getState, addDependantActionCreator(executor));

export type UpdateAndSubmitDependant = (index: number, dependant: Dependant) => SubmitReturn;
type UpdateAndSubmitDependantThunk = (
    index: number,
    dependant: Dependant
) => ReduxThunkAction<SubmitReturn>;
export const updateAndSubmitDependantThunk: UpdateAndSubmitDependantThunk = (
    index: number,
    dependant: Dependant
) => (dispatch, getState) =>
    reduceAndSubmitClaim(dispatch, getState, updateDependantActionCreator(index, dependant));

export type DeleteAndSubmitDependant = (index: number) => SubmitReturn;
type DeleteAndSubmitDependantThunk = (index: number) => ReduxThunkAction<SubmitReturn>;
export const deleteAndSubmitDependantThunk: DeleteAndSubmitDependantThunk = (index: number) => (
    dispatch,
    getState
) => reduceAndSubmitClaim(dispatch, getState, deleteDependantActionCreator(index));
