import { formatCurrency } from '../../shared-functions';

export function getRandValues() {
    const list = [
        'Amount payable',
        'Unallocated claim amount',
        'Claim amount',
        'Sum assured',
        'Income expenditure',
        'Maintenance',
    ];

    return list;
}

export function getPercentageFields() {
    const list = ['Percentage payable', 'Split'];

    return list;
}

export function GetDecoratedAuditTrailValue(fieldName: any, value: any): string {
    if (isNaN(value)) {
        return value;
    }

    const randValueWhiteList = getRandValues();
    const percentageWhiteList = getPercentageFields();

    const containsRandValue = randValueWhiteList.indexOf(fieldName.toString()) > -1 ? true : false;
    const containsPercentageValue =
        fieldName && percentageWhiteList.indexOf(fieldName.toString()) > -1 ? true : false;

    let valueToUse = value;
    if (value) {
        if (containsRandValue) {
            valueToUse = formatCurrency(value);
        } else if (containsPercentageValue) {
            valueToUse = `${value}%`;
        }
    }

    return valueToUse;
}
