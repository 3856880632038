import React from 'react'; // eslint-disable-line

import { Button, Icon, Omit, withStyles } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { OpenAddModal, openAddModalAction } from '../../../form-modal/actions';
import styles, { StyledComponent } from './styles';

export interface RecordInfo {
    maxRecords: number;
    recordCount: number;
}

interface Props extends Omit<ButtonProps, 'classes'>, StyledComponent {
    label: string;
    recordInfo?: RecordInfo;
    addView: React.ReactNode;
    openAddModal: OpenAddModal;
}

const addButton: React.FunctionComponent<Props> = ({
    classes,
    label,
    recordInfo,
    addView,
    openAddModal,
    ...rest
}: Props) => {
    const addNew = () => {
        openAddModal({
            modalContent: addView,
            modalData: {
                recordCount: !!recordInfo ? recordInfo.recordCount : undefined,
            },
        });
    };

    return (
        <Button
            id="AddNew"
            variant="contained"
            color="secondary"
            className={classes.addButton}
            onClick={() => addNew()}
            disabled={!!recordInfo && recordInfo.recordCount >= recordInfo.maxRecords}
            {...rest}
        >
            <Icon>add_circle</Icon> {'\u00A0'} {label}
        </Button>
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    openAddModal: openAddModalAction,
};

export const AddButton = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(addButton));
