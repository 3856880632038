import { AnyAction } from 'redux';
import { getProperty } from '../claim';
import { AddressDetails } from '../forms/sub-forms/address-details';
import {
    addRecordReduction,
    deleteRecordReduction,
    updateRecordReduction,
} from '../redux/reductions';
import { State } from '../redux/root-reducer';
import { Nullable } from '../shared-types';
import { AddDoctorAction } from './actions/add-doctor';
import { DeleteDoctorAction } from './actions/delete-doctor';
import { UpdateDoctorAction } from './actions/update-doctor';
import { ADD_DOCTOR, DELETE_DOCTOR, UPDATE_DOCTOR } from './types';

export interface Doctor extends AddressDetails {
    readonly id?: string;
    readonly initials?: string;
    readonly name?: string;
    readonly surname?: string;
    readonly telephoneNumber?: string;
    readonly dateOfConsultation?: Nullable<Date>;
    readonly reasonForConsultation?: string;
}

export interface DoctorsDetails {
    readonly doctors: Doctor[];
}
export const defaultDoctorsDetails = {
    doctors: [],
};
export function doctorDetailsReducer(state: Nullable<DoctorsDetails> = null, action: AnyAction) {
    let records = (state || defaultDoctorsDetails).doctors || [];
    switch (action.type) {
        case ADD_DOCTOR:
            records = addRecordReduction(records, (action as AddDoctorAction).payload);
            break;
        case UPDATE_DOCTOR:
            records = updateRecordReduction(
                records,
                (action as UpdateDoctorAction).payload,
                action.index
            );
            break;
        case DELETE_DOCTOR:
            records = deleteRecordReduction(records, (action as DeleteDoctorAction).index);
            break;
        default:
            return state;
    }

    const updatedState: DoctorsDetails = {
        ...state,
        doctors: records,
    };

    return updatedState;
}
export function getDoctorDetails(state: State): DoctorsDetails {
    return getProperty(state, 'doctorDetails', defaultDoctorsDetails);
}
