import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { State } from '../../redux/root-reducer';
import styles from '../../styles/styles';
import {
    changeRecordFromAddChangeSet,
    changeRecordFromDeleteChangeSet,
    changeRecordFromChangeSet,
    ChangeFieldSet,
} from '../claim-audit-trail-functions';
import { addAuditTrailDetailsView } from '../common-views/claim-audit-trail-field-details';
import { mapSectionView } from '../common-views/claim-audit-trail-wrapper';
import { capitalCase } from 'change-case';
import { FieldNames } from '../../codes/claim-documents-enums';
import {
    getClaimantDisplayName,
    getDependantDisplayName,
    getFullName,
} from '../../shared-functions';
import { Claim, getClaim } from '../../claim/reducers';
import { ClaimType } from '../../claim/claim-types';
import { LifeAssuredContract } from '../../life-assured-details/reducer';
import { CommonClaim } from '../../claim/reducers/claim-reducers/retrenchment-claim-reducer';
import { DeathClaim } from '../../claim/reducers/claim-reducers/death-claim-reducer';
import { RADeathClaim } from '../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { EntityType, Nullable } from '../../shared-types';
import { Claimant } from '../../assessment/synopsis/death/contact-details/contact-details-reducer';
import { Dependant } from '../../assessment/synopsis/ra-death/dependants/reducer';

interface Props {
    changeSet: Record<string, any>;
    claim: Claim;
}

const paymentsAuditTrail: React.FunctionComponent<Props> = ({ claim, changeSet }: Props) => {
    const returnView: React.ReactNode[] = [];
    if (changeSet != null) {
        Object.keys(changeSet).forEach(key => {
            const changeRecord = changeSet[key];
            if (changeRecord !== null && key !== '_t') {
                const {
                    beneficiaryName,
                    laContract,
                    dependant,
                    claimant,
                } = getPaymentBeneficiaryName(claim, key);
                Object.keys(changeRecord).forEach(value => {
                    if (
                        changeRecord[value] !== undefined &&
                        changeRecord[value].hasOwnProperty('paymentBeneficiary')
                    ) {
                        getBeneficiaryAuditTrail(
                            returnView,
                            claim.claimType,
                            claimant,
                            laContract,
                            dependant,
                            beneficiaryName
                        );
                    }
                    if (value === 'paymentItems') {
                        Object.keys(changeRecord[value]).forEach(index => {
                            if (!isNaN(Number(index))) {
                                if (
                                    changeRecord[value] !== undefined &&
                                    !Array.isArray(changeRecord[value][index])
                                ) {
                                    editPayment(
                                        changeRecord,
                                        value,
                                        index,
                                        returnView,
                                        beneficiaryName
                                    );
                                } else {
                                    addPayment(
                                        changeRecord,
                                        value,
                                        index,
                                        returnView,
                                        beneficiaryName
                                    );
                                }
                            } else if (isNaN(Number(index))) {
                                detelePayment(
                                    changeRecord,
                                    value,
                                    index,
                                    returnView,
                                    beneficiaryName
                                );
                            }
                        });
                    } else if (value === 'beneficiaryManualScreening') {
                        Object.keys(changeRecord[value]).forEach(index => {
                            beneficiaryManualScreening(changeRecord, value, index, returnView);
                        });
                    }
                });
            }
        });
    }
    return mapSectionView(returnView);
};

const getBeneficiaryAuditTrail = (
    returnView: React.ReactNode[],
    claimType: Nullable<ClaimType>,
    claimant: Claimant,
    contract: LifeAssuredContract,
    dependant: Dependant,
    displayName: string
) => {
    const sectionNames = ['', FieldNames.BankingDetails];
    let bankingDetails: any = undefined;

    if (claimType === ClaimType.Disability || claimType === ClaimType.Dread) {
        bankingDetails = contract.premiumPayer;
        sectionNames[0] = `${capitalCase(
            `Policy Owner ${bankingDetails.initials || ''} ${bankingDetails.lastName}`
        )}`;
    } else if (claimType === ClaimType.Death) {
        if (claimant && claimant.claimantDetails) {
            bankingDetails = claimant.claimantDetails.bankingDetails;
            sectionNames[0] = `${capitalCase(`Claimant ${displayName}`)}`;
        }
    } else if (claimType === ClaimType.RADeath) {
        if (dependant) {
            bankingDetails = dependant.bankingDetails;
            sectionNames[0] = `${capitalCase(`Dependant ${displayName}`)}`;
        }
    }

    if (bankingDetails) {
        delete bankingDetails.pdbId;
        const record = changeRecordFromAddChangeSet(bankingDetails);
        if (record.length > 0) {
            addAuditTrailDetailsView(returnView, record, sectionNames);
        }
    }
};

const addPayment = (
    changeRecord: Record<string, any>,
    value: string,
    index: string,
    returnView: React.ReactNode[],
    displayName: string
) => {
    if (changeRecord[value] && Array.isArray(changeRecord[value][index])) {
        let bankingDetails: ChangeFieldSet[] = [];
        let section: string[] = [];
        const paymentItem = changeRecord[value][index][0];
        delete paymentItem['beneficiaryId'];
        delete paymentItem['decisionId'];
        if (paymentItem.hasOwnProperty('split')) {
            paymentItem.split = paymentItem.split.toFixed(2);
        }
        if (paymentItem.hasOwnProperty('payeeType')) {
            if (
                paymentItem.payeeType === 'Beneficiary' ||
                paymentItem.payeeType === 'InternalTransfer' ||
                paymentItem.payeeType === 'IT88' ||
                paymentItem.payeeType === 'PAYE'
            ) {
                delete paymentItem['bankingDetails'];
            }
            if (paymentItem.payeeType === 'ExternalTransfer') {
                bankingDetails = changeRecordFromAddChangeSet(paymentItem.bankingDetails);
                section = [`${capitalCase(`Banking Details`)}`];
                delete paymentItem['bankingDetails'];
            }
        }
        const paymentItemAuditTrail = changeRecordFromAddChangeSet(paymentItem);
        const sectionName = [
            `${capitalCase(`Added payment for`)} ${displayName} `,
            FieldNames.Payment,
        ];
        if (paymentItemAuditTrail.length > 0) {
            addAuditTrailDetailsView(returnView, paymentItemAuditTrail, sectionName);
        }
        if (bankingDetails.length > 0) {
            addAuditTrailDetailsView(returnView, bankingDetails, section);
        }
    }
};

const editPayment = (
    changeRecord: Record<string, any>,
    value: string,
    index: string,
    returnView: React.ReactNode[],
    displayName: string
) => {
    const paymentItem = changeRecord[value][index];
    let bankingDetails = [];
    let record;
    if (paymentItem.hasOwnProperty('split')) {
        paymentItem.split = [paymentItem.split[0].toFixed(2), paymentItem.split[1].toFixed(2)];
    }
    if (paymentItem.hasOwnProperty('bankingDetails') && Array.isArray(paymentItem.bankingDetails)) {
        bankingDetails = paymentItem.bankingDetails;
        delete paymentItem.bankingDetails;
    }
    record = changeRecordFromChangeSet(paymentItem);
    let sectionName = [`${capitalCase(`Edited payment For ${displayName}`)}`, FieldNames.Payment];

    if (record.length > 0) {
        addAuditTrailDetailsView(returnView, record, sectionName);
    }
    sectionName = ['Banking Details'];
    if (bankingDetails && !bankingDetails[1] && bankingDetails[0]) {
        record = changeRecordFromDeleteChangeSet(bankingDetails[0]);
        if (record.length > 0) {
            addAuditTrailDetailsView(returnView, record, sectionName);
        }
    } else if (bankingDetails && !bankingDetails[0] && bankingDetails[1]) {
        record = changeRecordFromAddChangeSet(bankingDetails[1]);
        if (record.length > 0) {
            addAuditTrailDetailsView(returnView, record, sectionName);
        }
    } else if (!Array.isArray(paymentItem.bankingDetails)) {
        sectionName = ['Edit Payment', 'Banking Details'];
        record = changeRecordFromChangeSet(paymentItem.bankingDetails);
        if (record.length > 0) {
            addAuditTrailDetailsView(returnView, record, sectionName);
        }
    }
};

const detelePayment = (
    changeRecord: Record<string, any>,
    value: string,
    index: string,
    returnView: React.ReactNode[],
    displayName: string
) => {
    if ((index === '_0' || index === '_1') && (value !== undefined || value !== null)) {
        let bankingDetails: ChangeFieldSet[] = [];
        let section: string[] = [];
        const deletedRecord = changeRecord[value][index][0];
        delete deletedRecord['beneficiaryId'];
        delete deletedRecord['decisionId'];
        if (deletedRecord.hasOwnProperty('split')) {
            deletedRecord.split = deletedRecord.split.toFixed(2);
        }
        if (deletedRecord.hasOwnProperty('payeeType')) {
            if (
                deletedRecord.payeeType === 'Beneficiary' ||
                deletedRecord.payeeType === 'InternalTransfer' ||
                deletedRecord.payeeType === 'IT88' ||
                deletedRecord.payeeType === 'PAYE'
            ) {
                delete deletedRecord['bankingDetails'];
            }
            if (deletedRecord.payeeType === 'ExternalTransfer') {
                bankingDetails = changeRecordFromDeleteChangeSet(deletedRecord.bankingDetails);
                section = [`${capitalCase(`Banking Details`)}`];
                delete deletedRecord['bankingDetails'];
            }
        }
        const record = changeRecordFromDeleteChangeSet(deletedRecord);
        const sectionName = [
            `${capitalCase(`Deleted payment for`)} ${displayName}`,
            FieldNames.Payment,
        ];
        if (record.length > 0) {
            addAuditTrailDetailsView(returnView, record, sectionName);
        }
        if (bankingDetails.length > 0) {
            addAuditTrailDetailsView(returnView, bankingDetails, section);
        }
    }
};

const beneficiaryManualScreening = (
    changeRecord: Record<string, any>,
    value: string,
    index: string,
    returnView: React.ReactNode[]
) => {
    if (changeRecord[value]) {
        const sectionName = [`${capitalCase(`Beneficiary Manual Screening`)}`];
        const record = changeRecordFromAddChangeSet(changeRecord[value][index]);
        if (record.length > 0) {
            addAuditTrailDetailsView(returnView, record, sectionName);
        }
    }
};

function getPaymentBeneficiaryName(claim: Claim, key: string) {
    const beneficiaryId = claim.payments[key] && claim.payments[key].beneficiaryId;
    let beneficiaryName: any = '';
    let laContract: LifeAssuredContract = {
        policyOwners: [],
        associatedContracts: [],
        beneficiaries: [],
        lifeAssureds: [],
    };
    let claimant: Claimant = {};
    let dependant: Dependant = {
        id: '',
        isDependantSelected: false,
        percentageSplit: '',
    };
    if (claim.claimType === ClaimType.Disability || claim.claimType === ClaimType.Dread) {
        const commonClaim = claim as CommonClaim;
        const lifeAssured = commonClaim && commonClaim.lifeAssuredDetails;
        lifeAssured &&
            lifeAssured.contracts.forEach(contract => {
                const owner = contract.policyOwners.find(o => o.id === beneficiaryId);
                if (owner) {
                    laContract = contract;
                    if (owner.ownerType === EntityType.Organisation) {
                        beneficiaryName = capitalCase(owner.organisation.name);
                    } else {
                        beneficiaryName = `${getFullName(owner.personalDetails)}`;
                    }
                }
            });
    } else if (claim.claimType === ClaimType.Death) {
        const deathClaim = claim as DeathClaim;
        if (deathClaim.contactDetails) {
            const claimants = deathClaim.contactDetails.claimants || [];
            claimant = claimants.find(c => c.id === beneficiaryId) || claimant;
            beneficiaryName = getClaimantDisplayName(claimant);
        }
    } else if (claim.claimType === ClaimType.RADeath) {
        const raDeathClaim = claim as RADeathClaim;
        dependant = raDeathClaim.dependants.find(d => d.id === beneficiaryId) || dependant;
        beneficiaryName = getDependantDisplayName(dependant);
    }

    return { beneficiaryName, claimant, dependant, laContract };
}

const mapStateToProps = (state: State) => ({
    claim: getClaim(state),
});

const mapDispatchToProps = {};

export const PaymentsAuditTrail = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(paymentsAuditTrail));
