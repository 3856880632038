import { Divider, Grid, Paper, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { useSelector } from 'react-redux';
import { LabeledValue } from '../../../../controls/labeled-value';
import { Benefit, LifeAssureds, PersonalDetails } from '../../../../life-assured-details/reducer';
import { State } from '../../../../redux/root-reducer';
import {
    formatCurrency,
    FormattedCancel,
    FormattedCheckCircle,
    getDisplayDate,
    getFullName,
} from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import { getFormDetails } from '../form-details/form-details-reducer';

interface Props extends StyledComponent {
    lifeAssureds: LifeAssureds[];
}

const lifeAssureds = ({ classes, lifeAssureds }: Props) => {
    if (!lifeAssureds || lifeAssureds.length === 0) {
        return null;
    }

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.marginTop}>
                    Life Assureds Details
                </Typography>
            </Grid>
            {lifeAssureds.map((lifeAssured, index) => (
                <Paper className={classes.paper} key={`la-${index}`}>
                    <Grid container justify="flex-start">
                        <PersonView person={lifeAssured.personalDetails} />
                        <BenefitsView benefits={lifeAssured.benefits} />
                    </Grid>
                </Paper>
            ))}
        </React.Fragment>
    );
};

interface PersonProps {
    person: PersonalDetails;
}

const PersonView = ({ person }: PersonProps) => {
    if (!person) {
        return null;
    }
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography variant="h5">{getFullName(person)}</Typography>
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="ID number" value={person.idNumber} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Date of birth"
                    value={getDisplayDate(person.dateOfBirth, 'dd LLLL yyyy')}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Role" value={person.lifeAssuredRole} />
            </Grid>
        </React.Fragment>
    );
};

interface BenefitsProps {
    benefits: Benefit[];
}

const BenefitsView = ({ benefits }: BenefitsProps) => {
    if (!benefits || benefits.length === 0) {
        return null;
    }
    return (
        <React.Fragment>
            <Grid item xs={12} style={{ marginTop: '16px', marginBottom: '16px' }}>
                <Typography variant="body1">Benefit Details</Typography>
            </Grid>
            {benefits.map((benefit, index) => (
                <React.Fragment key={`la-benefit-${index}`}>
                    {index > 0 && (
                        <Grid item xs={12}>
                            <Divider style={{ marginTop: '16px', marginBottom: '16px' }} />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{benefit.name || 'UNKNOWN'}</Typography>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Commencement date"
                            value={getDisplayDate(benefit.startDate)}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Benefit sum assured"
                            value={formatCurrency(benefit.benefitAmount)}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Waiting period expired" />
                        <WaitingPeriod startDate={benefit.startDate} />
                    </Grid>
                </React.Fragment>
            ))}
        </React.Fragment>
    );
};

interface PeriodProps {
    startDate?: Date;
}
const WaitingPeriod = ({ startDate }: PeriodProps) => {
    let periodLapsed = false;
    const formDetails = useSelector((state: State) => getFormDetails(state));
    if (startDate) {
        const detailsOfDeath =
            formDetails.deceasedDetails && formDetails.deceasedDetails.detailsOfDeath;
        if (detailsOfDeath && detailsOfDeath.dateOfDeath) {
            const date = new Date(detailsOfDeath.dateOfDeath);
            periodLapsed = startDate <= new Date(date.setMonth(date.getMonth() - 6));
        }
    }
    return (
        <Grid item xs={12} container style={{ paddingTop: '5px' }}>
            {periodLapsed ? <FormattedCheckCircle /> : <FormattedCancel />}
            <Grid item xs={9} lg={5}>
                <Typography style={{ marginTop: '-7px', marginLeft: '8px' }}>
                    {periodLapsed ? 'Yes' : 'No'}
                </Typography>
            </Grid>
        </Grid>
    );
};

export const LifeAssuredsView = withStyles(styles)(lifeAssureds);
