import React, { useState } from 'react'; // eslint-disable-line
import { Assessors } from '../../reducer/assessors-reducer';
import styles, { StyledComponent } from '../../../styles/styles';
import { withStyles } from '@material-ui/core';
import { Grid, Button, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { FormApi } from 'final-form';
import { connect } from 'react-redux';
import { GenericForm } from '../../../forms';
import { OnSubmitSuccessProps } from '../../../workflow-navigation';
import { FormSpy } from 'react-final-form';
import { SetClaimFlow, setClaimFlowAction } from '../../../initiate-claim/actions/set-claim-flow';
import { EditAssessorAction, UpdateAssessorAction } from '../../actions/assessors-action';
import { closeModalAction, OpenModal } from '../../../form-modal/actions';
import { FormModalView } from '../../../form-modal/views/form-modal';
import AssessorTable, { validate } from './assessorTable';
type FormProps = OnSubmitSuccessProps &
    StyledComponent & {
        isActive?: string;
        setClaimFlow: SetClaimFlow;
        assessor: Assessors;
        openModal: OpenModal;
        updateAssessorAction: any;
        editAssessorAction: any;
        closeModal: any;
        assessorUpdated: boolean;
    };

const AssessorExpandedViewForm = GenericForm<Assessors>();
const assessorExpandedFormView: React.FunctionComponent<FormProps> = ({
    classes,
    setClaimFlow,
    assessor,
    openModal,
    updateAssessorAction,
    editAssessorAction,
    closeModal,
    assessorUpdated,
    ...rest
}: FormProps) => {
    const onSubmit = (values: any) => setClaimFlow(values.isActive);
    return (
        <AssessorExpandedViewForm onSubmit={onSubmit} initialValues={assessor} {...rest}>
            {() =>
                FormSpy<string>({
                    render: ({ form }) =>
                        AssessorExpandedView({
                            classes,
                            assessor,
                            form,
                            openModal,
                            editAssessorAction,
                            updateAssessorAction,
                            closeModal,
                            assessorUpdated,
                        }),
                })
            }
        </AssessorExpandedViewForm>
    );
};

type EditFormProps = OnSubmitSuccessProps &
    StyledComponent & {
        isActive?: string;
        assessor: Assessors;
        isEdit: boolean;
        updateAssessorAction: any;
        closeModal: any;
    };

const AssessorEditForm = GenericForm<Assessors>();
const AssessorEditFormView: React.FunctionComponent<EditFormProps> = ({
    classes,
    updateAssessorAction,
    closeModal,
    assessor,
    isEdit,
    ...rest
}: EditFormProps) => {
    const [error, setError] = useState([]);

    const onSubmit = (assessor: Assessors) => {
        const err = validate(assessor);
        setError(err);
        if (err.length === 0) {
            updateAssessorAction(assessor);
            closeModal();
            setError([]);
        }
    };
    return (
        <AssessorEditForm onSubmit={onSubmit} initialValues={assessor} {...rest}>
            {() =>
                FormSpy<string>({
                    render: ({ form }) => AssessorTable({ isEdit, classes, assessor, error }),
                })
            }
        </AssessorEditForm>
    );
};

interface OwnProps {
    classes: any;
    assessor: Assessors;
    form: FormApi<string>;
    openModal: OpenModal;
    editAssessorAction: any;
    updateAssessorAction: any;
    closeModal: any;
    assessorUpdated: boolean;
}

type Props = OwnProps & StyledComponent;
const AssessorExpandedView: React.FunctionComponent<Props> = ({
    classes,
    assessor,
    form,
    openModal,
    editAssessorAction,
    updateAssessorAction,
    assessorUpdated,
    closeModal,
}: Props) => {
    function editAssessor() {
        editAssessorAction();
        openModal({
            modalContent: (
                <FormModalView
                    formStyle={classes.assesssorForm}
                    formView={() => (
                        <AssessorEditFormView
                            isEdit={true}
                            classes={classes}
                            assessor={assessor}
                            updateAssessorAction={updateAssessorAction}
                            closeModal={closeModal}
                        />
                    )}
                />
            ),
            modalData: {},
        });
    }

    return (
        <Grid>
            <Grid container item xs={12} justify="flex-end">
                <Button
                    style={{ marginTop: 10 }}
                    id="EditPolicyPremiumDetails"
                    color="secondary"
                    onClick={editAssessor}
                >
                    <Edit />
                </Button>
            </Grid>
            <Grid container item direction="row">
                <Grid>
                    <Typography className={classes.assessorTableTextBold}>Email: </Typography>
                    <Typography className={classes.assessorTableTextBold} style={{ marginTop: 16 }}>
                        Status:
                    </Typography>
                </Grid>
                <Grid>
                    <Typography className={classes.assessorTableText} style={{ marginLeft: 5 }}>
                        {assessor.email}
                    </Typography>
                    <Typography
                        className={classes.assessorTableText}
                        style={{ marginTop: 16, marginLeft: 5 }}
                    >
                        {assessor.status.charAt(0).toUpperCase() +
                            assessor.status.slice(1).toLowerCase()}
                    </Typography>
                </Grid>
                <AssessorTable
                    classes={classes}
                    assessor={assessor}
                    assessorUpdated={assessorUpdated}
                />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    setClaimFlow: setClaimFlowAction,
    editAssessorAction: EditAssessorAction,
    updateAssessorAction: UpdateAssessorAction,
    closeModal: closeModalAction,
};

export const AssessorExpandedFormView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(assessorExpandedFormView));
