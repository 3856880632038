import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { ExpansionPanel } from '../../../../forms/form-components/expansion-panel/expansion-panel';
import {
    ContractType,
    getLifeAssuredDetails,
    LifeAssuredContract,
    LifeAssuredDetails,
} from '../../../../life-assured-details/reducer';
import { State } from '../../../../redux/root-reducer';
import { titleCase } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import { PolicyOwnersView } from '../../common/policy-owner';
import { BeneficiaryDetails } from './beneficiary-details-view';
import { DeathBenefitDetails } from './death-benefit-details';
import { InvestmentPolicyDetailsView } from './investment-policy-details';
import { RiskPolicyDetailsView } from './risk-policy-details-view';
import { getClaim } from '../../../../claim';
import { ClaimType } from '../../../../claim/claim-types';

interface Props extends StyledComponent {
    lifeAssuredDetails: LifeAssuredDetails;
    isReadOnly: boolean;
    claimType: any;
}
const deathPolicySummaryView: React.FunctionComponent<Props> = ({
    classes,
    lifeAssuredDetails,
    isReadOnly,
    claimType,
}: Props) => {
    if (!lifeAssuredDetails.contracts) {
        return null;
    }
    return (
        <ExpansionPanel name="Policy Details">
            {lifeAssuredDetails.contracts.map((contract, contractIndex) => {
                const panelName = `Policy ${contractIndex + 1} - ${contract.contractNumber}`;
                let productName = contract && contract.productName ? contract.productName : '';
                if (productName.indexOf('(') > -1) {
                    const shortProductName = productName.substring(productName.indexOf('('));
                    const actualProductName = productName.substring(0, productName.indexOf('('));
                    productName = `${titleCase(actualProductName)} ${shortProductName}`;
                } else {
                    productName = titleCase(productName);
                }
                return (
                    <ExpansionPanel
                        name={panelName}
                        label={panelName}
                        secondaryLabel={productName}
                        key={`policy${contractIndex}`}
                        style={{ marginLeft: -24, marginRight: -24 }}
                    >
                        {contract.contractType === ContractType.Risk
                            ? RiskPolicyDetails(
                                  classes,
                                  contract,
                                  contractIndex,
                                  isReadOnly,
                                  claimType
                              )
                            : InvestmentPolicyDetails(classes, contract, contractIndex, claimType)}
                    </ExpansionPanel>
                );
            })}
        </ExpansionPanel>
    );
};

const RiskPolicyDetails = (
    classes: Record<string, string>,
    contract: LifeAssuredContract,
    contractIndex: number,
    isReadOnly: boolean,
    claimType: ClaimType
) => {
    return (
        <React.Fragment>
            <RiskPolicyDetailsView classes={classes} contract={contract} />
            <PolicyOwnersView classes={classes} contract={contract} />
            <DeathBenefitDetails
                classes={classes}
                benefits={contract.benefits || []}
                contractIndex={contractIndex}
            />
            <BeneficiaryDetails
                contract={contract}
                contractIndex={contractIndex}
                claimType={claimType}
            />
        </React.Fragment>
    );
};

const InvestmentPolicyDetails = (
    classes: Record<string, string>,
    contract: LifeAssuredContract,
    contractIndex: number,
    claimType: ClaimType
) => {
    return (
        <React.Fragment>
            <InvestmentPolicyDetailsView classes={classes} contract={contract} />
            <PolicyOwnersView classes={classes} contract={contract} />
            <BeneficiaryDetails
                contract={contract}
                contractIndex={contractIndex}
                claimType={claimType}
            />
        </React.Fragment>
    );
};

const mapStateToProps = (state: State) => ({
    claimType: getClaim(state).claimType,
    lifeAssuredDetails: getLifeAssuredDetails(state),
    isReadOnly: state.claimSession.readOnly,
});

export const DeathPolicySummaryView = connect(
    mapStateToProps,
    {}
)(withStyles(styles)(deathPolicySummaryView));
