import React from 'react'; // eslint-disable-line

import { getComponentId } from '../../index';

interface Props {
    children: React.ReactNode;
    name: string;
    id?: string;
}

export function addNameToChildren({ children, name, id }: Props) {
    const _id = getComponentId({ id, name });
    let counter = 0;
    return React.Children.map(children, addName);

    function addName(child: any): React.ReactNode {
        counter++;
        const childId = getComponentId({ id: child.props.id, name: `${_id}${counter}` });
        return React.cloneElement(child, { name, id: childId });
    }
}
