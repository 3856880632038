import { env } from '../../env';
import axios from 'axios';
import { hideLoadingAction, showLoadingAction } from '../../forms/loading-screen/actions';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { Assessors } from '../reducer/assessors-reducer';
import { openToastAction } from '../../confirmation-dialog';
import { SAVE_ASSESSOR } from '../types/assessors-types';
import { ADUserAction } from './assessors-action';

export interface adUserRequest {
    firstName: string;
    lastName: string;
    mail?: string;
}

type SaveADUserReturn = Promise<any>;
export type SaveADUser = (request: adUserRequest) => SaveADUserReturn;
type SaveADUserAction = (request: adUserRequest) => ReduxThunkAction<SaveADUserReturn>;

export const saveADUserAction: SaveADUserAction = (request: any) => {
    return async (dispatch, getState) => {
        dispatch(showLoadingAction());
        const assessors = getState().assessorDashboard.assessorLimits;

        const adUserUri = `${env().CLAIMS_API}/api/AssessorDashboard/SaveAssessor`;
        const assessorRequest = {
            name: request.firstName,
            surname: request.lastName,
            email: request.mail,
            status: 'Active',
            typeId: 'Assessor',
            AssessorLimits: [
                {
                    canApproved: false,
                    productType: 'Early Death Claims',
                    amount: '0',
                },
                {
                    canApproved: false,
                    productType: 'Retrenchment Protector',
                    amount: '0',
                },
                {
                    canApproved: false,
                    productType: 'Retrenchment Waiver',
                    amount: '0',
                },
                {
                    canApproved: false,
                    productType: 'Living Lifestyle Benefits (incl. Child LL, Female, dependant)',
                    amount: '0',
                },
                {
                    canApproved: false,
                    productType: 'Terminal Illness benefit',
                    amount: '0',
                },
                {
                    canApproved: false,
                    productType: 'Disability/Impairment/Waiver',
                    amount: '0',
                },
                {
                    canApproved: false,
                    productType:
                        'All Income Benefits Incl child (to be reviewed with new tax laws)',
                    amount: '0',
                },
                {
                    canApproved: false,
                    productType: 'Maternity Pause',
                    amount: '0',
                },
            ],
        };
        await axios
            .post<Assessors>(adUserUri, assessorRequest)
            .then(response => {
                if (response.data) {
                    dispatch(
                        openToastAction({
                            toastContent: {
                                message: 'Assessor Successfully Added',
                                type: 'success',
                            },
                        })
                    );
                    dispatch(hideLoadingAction());
                    assessors.unshift(response.data);
                    dispatch(ADUserAction());
                    dispatch({ type: SAVE_ASSESSOR, assessorLimits: assessors });
                }
            })
            .catch(e => {
                console.log(e);
                dispatch(hideLoadingAction());
            });
    };
};
