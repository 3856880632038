import { Grid, Paper, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { Titles } from '../../codes/titles';
import {
    Condition,
    False,
    FormHeading,
    FullWidthDatePicker,
    FullWidthRadioGroup,
    FullWidthSearchSelectField,
    FullWidthTextField,
    RadioOption,
    True,
    WhenFieldChanges,
} from '../../forms';
import { FirstNameMask, InitialsMask, LastNameMask, NumberMask } from '../../forms/input-masks';
import styles, { StyledComponent } from '../../styles/styles';
import { conditionalRequired, rsaIdNumberValidator } from '../../forms/validations';
import { MedicalAidSchemes } from '../../codes/medical-aid-schemes';

const medicalAidDetailsView: React.FunctionComponent<StyledComponent> = ({
    classes,
}: StyledComponent) => {
    return (
        <Paper className={classes.paper}>
            <FormHeading text="Medical Aid Details" />
            <Grid container spacing={5} justify="flex-start">
                <Condition when="medicalAidDetails.isLifeAssuredTheMainMember" is={'No'}>
                    <False>
                        <Grid item xs={12}>
                            <FullWidthRadioGroup
                                name="medicalAidDetails.isLifeAssuredTheMainMember"
                                label="Is Life Assured The Main Member"
                            >
                                <RadioOption value="Yes" />
                                <RadioOption value="No" />
                            </FullWidthRadioGroup>
                        </Grid>
                    </False>
                    <True>
                        <Grid item xs={6}>
                            <FullWidthRadioGroup
                                name="medicalAidDetails.isLifeAssuredTheMainMember"
                                label="Is Life Assured The Main Member"
                            >
                                <RadioOption value="Yes" />
                                <RadioOption value="No" />
                            </FullWidthRadioGroup>
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthSearchSelectField
                                name="medicalAidDetails.mainMembersTitle"
                                options={Titles}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name="medicalAidDetails.mainMembersInitials"
                                inputProps={{
                                    style: { textTransform: 'uppercase' },
                                }}
                                mask={InitialsMask}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name="medicalAidDetails.mainMembersFirstName"
                                label="Main Member's Name"
                                mask={FirstNameMask}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name="medicalAidDetails.mainMembersLastName"
                                label="Main Member's Surname"
                                mask={LastNameMask}
                                validate={conditionalRequired(
                                    values =>
                                        values &&
                                        (values.medicalAidDetails.mainMembersInitials ||
                                            values.medicalAidDetails.mainMembersTitle ||
                                            values.medicalAidDetails.mainMembersLastName ||
                                            values.medicalAidDetails.mainMembersIdNumber ||
                                            values.medicalAidDetails.mainMembersFirstName)
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name="medicalAidDetails.mainMembersIdNumber"
                                label="Main Member's ID Number"
                                validate={rsaIdNumberValidator}
                                mask={NumberMask}
                            />
                        </Grid>
                    </True>
                </Condition>
                <Grid item xs={6}>
                    <FullWidthSearchSelectField
                        name="medicalAidDetails.medicalAidName"
                        options={MedicalAidSchemes}
                    />
                </Grid>
                <WhenFieldChanges
                    field="medicalAidDetails.medicalAidName"
                    set="medicalAidDetails.medicalAidDescription"
                    to={null}
                />
                <WhenFieldChanges
                    field="medicalAidDetails.isLifeAssuredTheMainMember"
                    becomes="Yes"
                    set="medicalAidDetails.mainMembersTitle"
                    to={null}
                />
                <WhenFieldChanges
                    field="medicalAidDetails.isLifeAssuredTheMainMember"
                    becomes="Yes"
                    set="medicalAidDetails.mainMembersInitials"
                    to={null}
                />
                <WhenFieldChanges
                    field="medicalAidDetails.isLifeAssuredTheMainMember"
                    becomes="Yes"
                    set="medicalAidDetails.mainMembersFirstName"
                    to={null}
                />
                <WhenFieldChanges
                    field="medicalAidDetails.isLifeAssuredTheMainMember"
                    becomes="Yes"
                    set="medicalAidDetails.mainMembersLastName"
                    to={null}
                />
                <WhenFieldChanges
                    field="medicalAidDetails.isLifeAssuredTheMainMember"
                    becomes="Yes"
                    set="medicalAidDetails.mainMembersIdNumber"
                    to={null}
                />
                <Condition when="medicalAidDetails.medicalAidName" is={'Other'}>
                    <True>
                        <Grid item xs={6}>
                            <FullWidthTextField name="medicalAidDetails.medicalAidDescription" />
                        </Grid>
                    </True>
                </Condition>
                <Grid item xs={6}>
                    <FullWidthTextField name="medicalAidDetails.medicalAidNumber" />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthDatePicker name="medicalAidDetails.dateJoined" disableFuture />
                </Grid>
            </Grid>
        </Paper>
    );
};
export const MedicalAidDetailsView = withStyles(styles)(medicalAidDetailsView);
