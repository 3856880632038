import React from 'react'; // eslint-disable-line
import { Grid, Paper, Divider, Typography, withStyles } from '@material-ui/core';
import {
    FullWidthTextField,
    FullWidthDatePicker,
    Condition,
    True,
    WhenFieldChanges,
    FullWidthRadioGroup,
    RadioOption,
} from '../../../../forms';
import styles, { StyledComponent } from '../../../../styles/styles';
import { requiredValidator } from '../../../../forms/validations';

const raFinancialDetailsEditView: React.FunctionComponent<StyledComponent> = ({
    classes,
}: StyledComponent) => {
    return (
        <React.Fragment>
            <Typography variant="h5">Financial Details</Typography>
            <Divider></Divider>
            <Paper className={classes.paper}>
                <Grid container justify="flex-start">
                    <Grid xs={12} lg={12}>
                        <FullWidthRadioGroup
                            label="Has the deceased member ever been insolvent, or are any sequestration proceedings pending or contemplated?"
                            name="previouslyInsolvent"
                            validate={requiredValidator}
                            id="previouslyInsolvent"
                        >
                            <RadioOption value="Yes" />
                            <RadioOption value="No" />
                            <RadioOption value={"Don't know"} label="Don't know" />
                        </FullWidthRadioGroup>
                        <WhenFieldChanges
                            field="previouslyInsolvent"
                            set="previouslyInsolventDate"
                            to={null}
                        />
                        <WhenFieldChanges
                            field="previouslyInsolvent"
                            set="previouslyInsolventReason"
                            to={null}
                        />
                    </Grid>
                    <Condition when="previouslyInsolvent" is="Yes">
                        <True>
                            <Grid item xs={10} lg={11}>
                                <FullWidthDatePicker
                                    id="previouslyInsolventDate"
                                    name="previouslyInsolventDate"
                                    label="Date of occurence"
                                    validate={requiredValidator}
                                    disableFuture
                                />
                            </Grid>
                            <Grid item xs={10} lg={11}>
                                <FullWidthTextField
                                    id="previouslyInsolventReason"
                                    name="previouslyInsolventReason"
                                    label="Reason"
                                    validate={requiredValidator}
                                />
                            </Grid>
                        </True>
                    </Condition>

                    <Grid item xs={10} lg={11}>
                        <FullWidthRadioGroup
                            label="Was the estate deceased member insolvent at the time of death? If 'yes', please provide full details"
                            name="estateInsolvent"
                            validate={requiredValidator}
                            id="estateInsolvent"
                        >
                            <RadioOption value="Yes" />
                            <RadioOption value="No" />
                            <RadioOption value={"Don't know"} label="Don't know" />
                        </FullWidthRadioGroup>
                        <WhenFieldChanges
                            field="estateInsolvent"
                            set="estateInsolventDate"
                            to={null}
                        />
                        <WhenFieldChanges
                            field="estateInsolvent"
                            set="estateInsolventReason"
                            to={null}
                        />
                    </Grid>
                    <Condition when="estateInsolvent" is="Yes">
                        <True>
                            <Grid item xs={10} lg={11}>
                                <FullWidthDatePicker
                                    id="estateInsolventDate"
                                    name="estateInsolventDate"
                                    label="Date of occurence"
                                    validate={requiredValidator}
                                    disableFuture
                                />
                            </Grid>
                            <Grid item xs={10} lg={11}>
                                <FullWidthTextField
                                    id="estateInsolventReason"
                                    name="estateInsolventReason"
                                    label="Reason"
                                    validate={requiredValidator}
                                />
                            </Grid>
                        </True>
                    </Condition>
                </Grid>
            </Paper>
        </React.Fragment>
    );
};
export const RAFinancialDetailsEditView = withStyles(styles)(raFinancialDetailsEditView);
