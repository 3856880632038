import { Action } from 'redux';
import { ReduxAction } from '../../redux/redux-actions';
import { CLOSE_MODAL, OPEN_MODAL, UPDATE_CURRENT_MODAL_DATA } from '../types';

export type SaveAction<TValues> = (
    values: TValues
) => void | Promise<void> | Record<string, any> | Promise<Record<string, any>>;
export type RecordView = () => React.ReactNode;

export interface OpenModalOptions {
    readonly modalContent: React.ReactNode;
    readonly modalData?: any;
}

export type OpenModalAction = ReduxAction<OpenModalOptions>;
export type OpenModal = (options: OpenModalOptions) => OpenModalAction;
export const openModalAction: OpenModal = (options: OpenModalOptions) => {
    return {
        type: OPEN_MODAL,
        payload: {
            modalContent: options.modalContent,
            modalData: options.modalData,
        },
    };
};

export interface UpdateModalDataPayload {
    readonly modalData?: any;
}

export type UpdateCurrentModalDataAction = ReduxAction<UpdateModalDataPayload>;
export type UpdateCurrentModalData = (modalData: any) => UpdateCurrentModalDataAction;
export const updateCurrentModalDataAction: UpdateCurrentModalData = (modalData: any) => {
    return {
        type: UPDATE_CURRENT_MODAL_DATA,
        payload: {
            modalData,
        },
    };
};

export interface CloseModalPayload {
    modalId: string;
}

export type CloseModalAction = Action;
export type CloseModal = () => CloseModalAction;
export const closeModalAction: CloseModal = () => {
    return {
        type: CLOSE_MODAL,
    };
};
