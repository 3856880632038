import { Divider, Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { Field } from 'react-final-form';
import { FullWidthSearchSelectField, FullWidthTextField } from '../../../../../forms';
import { CurrencyMask, NumberMask } from '../../../../../forms/input-masks';
import { PercentageMask } from '../../../../../forms/input-masks/percentage-mask';
import { requiredValidator } from '../../../../../forms/validations';
import { convertToNumber } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { NonBusinessRelatedExpense } from '../reducer';
import { NonBusinessRelatedExpensesView } from './non-business-related-expenses-detail';

interface ComponentState {
    nonBusinessRelatedExpenses?: NonBusinessRelatedExpense[];
}

interface Props extends ComponentState, StyledComponent {}

const selfEmployedIncomeDetailView: React.FunctionComponent<Props> = ({
    classes,
    nonBusinessRelatedExpenses,
}: Props) => {
    const OEBPercentages = [{ value: 0.75, label: '75%' }];

    return (
        <Grid container spacing={5} justify="flex-start">
            <Grid item xs={12}>
                <Typography variant="h5" className={classes.marginTop}>
                    Self Employed Income
                </Typography>
                <Divider className={classes.marginTop} />
            </Grid>
            <Divider />
            <Grid item xs={6}>
                <FullWidthTextField
                    name="selfEmployedIncome.sales"
                    mask={CurrencyMask}
                    validate={requiredValidator}
                    parse={convertToNumber}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name="selfEmployedIncome.costOfSales"
                    mask={CurrencyMask}
                    validate={requiredValidator}
                    parse={convertToNumber}
                />
            </Grid>
            <Grid item xs={12}>
                <NonBusinessRelatedExpensesView
                    nonBusinessRelatedExpenses={nonBusinessRelatedExpenses || []}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name="selfEmployedIncome.financialStatementPeriod"
                    mask={NumberMask}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    name="selfEmployedIncome.oebPercentage"
                    options={OEBPercentages}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name="selfEmployedIncome.businessPercentageShare"
                    mask={PercentageMask}
                    validate={requiredValidator}
                    parse={convertToNumber}
                />
            </Grid>
            <Field
                name="."
                subscription={{ value: true }}
                component={({ input: { value: values } }) => {
                    if (values) {
                        getIncome(values);
                        getIncomeDefined(values);
                        getTotalNonBusinessRelatedExpenses(values);
                        getOverHeadTotal(values);
                        getAverageMonthlyEarnings(values);
                        getOEB(values);
                        getPartnership(values);

                        if (values.selfEmployedIncome) {
                            values.selfEmployedIncome.estimatedAfterTaxIncome =
                                values.selfEmployedIncome.averageMonthlyEarnings;
                        }
                    }
                    return null;
                }}
            />
        </Grid>
    );
};

function getIncome(values: any) {
    if (values.selfEmployedIncome) {
        values.selfEmployedIncome.income =
            values.selfEmployedIncome.sales - values.selfEmployedIncome.costOfSales;
    }
}

function getIncomeDefined(values: any) {
    if (values.selfEmployedIncome) {
        values.selfEmployedIncome.definedIncome =
            values.selfEmployedIncome.income - values.selfEmployedIncome.overheadTotal;
    }
}

function getTotalNonBusinessRelatedExpenses(values: any) {
    if (values.selfEmployedIncome && values.selfEmployedIncome.nonBusinessRelatedExpenses) {
        let total = 0;

        for (const x of values.selfEmployedIncome.nonBusinessRelatedExpenses) {
            total += x.nonBusinessRelatedExpenseValue;
        }
        values.selfEmployedIncome.nonBusinessExpensesTotal = total;
    }
}

function getOverHeadTotal(values: any) {
    if (values.selfEmployedIncome) {
        values.selfEmployedIncome.overheadTotal =
            values.selfEmployedIncome.expenses - values.selfEmployedIncome.nonBusinessExpensesTotal;
    }
}

function getAverageMonthlyEarnings(values: any) {
    if (values.selfEmployedIncome) {
        values.selfEmployedIncome.averageMonthlyEarnings =
            values.selfEmployedIncome.income / values.selfEmployedIncome.financialStatementPeriod;
    }
}

function getOEB(values: any) {
    if (values.selfEmployedIncome) {
        values.selfEmployedIncome.percentageOfOEB =
            (values.selfEmployedIncome.overheadTotal /
                values.selfEmployedIncome.financialStatementPeriod) *
            values.selfEmployedIncome.oebPercentage;
    }
}

function getPartnership(values: any) {
    if (values.selfEmployedIncome) {
        values.selfEmployedIncome.partnership =
            (values.selfEmployedIncome.income / values.selfEmployedIncome.businessPercentageShare) *
            100;
    }
}

export const SelfEmployedIncomeDetailView = withStyles(styles)(selfEmployedIncomeDetailView);
