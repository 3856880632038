import { SEARCH_RESULTS_LOADED } from '..';
import { ReduxAction, ReduxThunkAction } from '../../redux/redux-actions';
import { addScreenToWorkflow } from '../../workflow-navigation/actions/add-screen-to-workflow';
import { ClientSearchCriteria } from './client-search';
import { Contract } from './contracts-loaded';
import { ClaimType } from '../../claim/claim-types';
import { ScreenIndex } from '../../claim-workflow/workflow-provider/screen-index';
import { ClaimReference } from '../../claim/reducers/claim-reference-reducer';

export interface ClientClaim {
    claimId: string;
    contractNumbers: string[];
    claimTypes: ClaimType[];
    initiationDate: Date;
    initiatedBy: string;
    references: ClaimReference[];
}

export interface Address {
    line1?: string;
    line2?: string;
    line3?: string;
    line4?: string;
    postalCode?: string;
}

export interface Client {
    contract?: boolean;
    contractNumber?: string;
    clientId: string;
    idType: string;
    idNumber: string;
    firstName: string;
    lastName: string;
    dateOfBirth: Date;
    contracts?: Contract[];
    existingClaims: ClientClaim[];
    clientSource: string;
    clientSourceId: string;
    initials?: string;
    title?: string;
    roleDescription?: string;
}

export interface ClientsLoadedActionPayload {
    clients: Client[];
    criteria: ClientSearchCriteria;
    isFuneral?: boolean;
}

type ClientsLoadedReturn = Promise<any>;
export type ClientsLoaded = (
    clients: Client[],
    criteria: ClientSearchCriteria,
    isFuneral?: boolean
) => ClientsLoadedReturn;
type ClientsLoadedThunkAction = (
    clients: Client[],
    criteria: ClientSearchCriteria,
    isFuneral?: boolean
) => ReduxThunkAction<ClientsLoadedReturn>;

export const clientsLoadedThunkAction: ClientsLoadedThunkAction = (
    clients: Client[],
    criteria: ClientSearchCriteria,
    isFuneral?: boolean
) => {
    return async dispatch => {
        dispatch(clientsLoadedAction(clients, criteria));

        if (isFuneral) {
            dispatch(addScreenToWorkflow(ScreenIndex.InitiateClaim.FuneralClientSelection));
        } else {
            dispatch(addScreenToWorkflow(ScreenIndex.InitiateClaim.ClientSelection));
        }
    };
};

export type ClientsLoadedAction = (
    clients: Client[],
    criteria: ClientSearchCriteria
) => ReduxAction<ClientsLoadedActionPayload>;
export const clientsLoadedAction: ClientsLoadedAction = (
    clients: Client[],
    criteria: ClientSearchCriteria
) => ({
    type: SEARCH_RESULTS_LOADED,
    payload: {
        clients,
        criteria,
    },
});
