import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { LiquidAndDistribution } from '../liquid-and-distribution-reducer';
import { claimSubmit, Claim } from '../../../../../claim';

export interface LiquidAndDistributionModel {
    liquidAndDistribution: LiquidAndDistribution[];
}

type SubmitReturn = Promise<any>;
export type UpdateRALiquidAndDistribution = (values: LiquidAndDistributionModel) => SubmitReturn;
type UpdateRALiquidAndDistributionAction = (
    values: LiquidAndDistributionModel
) => ReduxThunkAction<SubmitReturn>;
export const updateRALiquidAndDistributionAction: UpdateRALiquidAndDistributionAction = (
    values: LiquidAndDistributionModel
) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, claim => {
            return getClaim(claim, values);
        });
    };
};

const getClaim = (claim: Claim, values: LiquidAndDistributionModel) => {
    const newClaim = {
        ...claim,
        liquidAndDistribution: values.liquidAndDistribution,
    };
    return newClaim;
};
