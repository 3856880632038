import React from 'react'; // eslint-disable-line

import { Grid, Paper, withStyles } from '@material-ui/core';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { FullWidthDatePicker, FullWidthTextField, FormHeading } from '../../../../../../forms';
import { AddressDetailsSubForm } from '../../../../../../forms/sub-forms/address-details/view';
import {
    InitialsMask,
    FirstNameMask,
    TelephoneNumberMask,
} from '../../../../../../forms/input-masks';
import { requiredValidator } from '../../../../../../forms/validations';

interface Props extends StyledComponent {
    add?: boolean;
}

const addDoctorsDetailsView: React.FunctionComponent<Props> = ({ classes, add }: Props) => {
    return (
        <React.Fragment>
            <Paper className={classes.paper}>
                <FormHeading text="Add doctor's details" />
                <Grid container spacing={5} justify="flex-start">
                    <Grid item xs={6}>
                        <FullWidthTextField
                            label="Doctor's initials"
                            name="initials"
                            mask={InitialsMask}
                            inputProps={{
                                style: { textTransform: 'uppercase' },
                            }}
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            label="Doctor's name"
                            name="name"
                            mask={FirstNameMask}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            label="Doctor's surname"
                            name="surname"
                            mask={FirstNameMask}
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            label="Contact number"
                            name="telephoneNumber"
                            validate={requiredValidator}
                            mask={TelephoneNumberMask}
                        />
                    </Grid>
                    <AddressDetailsSubForm required />
                </Grid>
            </Paper>
            <DoctorsConsultation classes={classes} add={add} />
        </React.Fragment>
    );
};

const DoctorsConsultation = ({ classes, add }: Props) => {
    if (!add) {
        return null;
    }
    return (
        <Paper className={classes.paper}>
            <FormHeading text="Reason for doctor's visit" />
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={6}>
                    <FullWidthDatePicker
                        label="Date of attendance"
                        name="doctorsVisits[0].date"
                        validate={requiredValidator}
                        disableFuture
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        multiline
                        label="Reason"
                        name="doctorsVisits[0].reason"
                        validate={requiredValidator}
                        multiple
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export const MedicalHistoryDoctorsEdit = withStyles(styles)(addDoctorsDetailsView);
