import React from 'react'; // eslint-disable-line
import { isEmpty } from '../../forms/validations';
import {
    ChangeFieldSet,
    changeRecordFromAddChangeSet,
    changeRecordFromChangeSet,
    getDisplayName,
} from '../claim-audit-trail-functions';
import { addAuditTrailDetailsView } from '../common-views/claim-audit-trail-field-details';
import { mapSectionView } from '../common-views/claim-audit-trail-wrapper';

interface Props {
    changeSet: Record<string, any>;
}

export const AuditTrailFormDetailsView: React.FunctionComponent<Props> = ({ changeSet }: Props) => {
    const returnView: React.ReactNode[] = [];
    const keys = [
        'deceasedDetails',
        'declaration',
        'funeralParlour',
        'policyOwner',
        'claimants',
        'claimant',
        'lifeAssured',
        'payment',
        'policyHolder',
    ];

    keys.forEach(key => {
        if (Array.isArray(changeSet) && changeSet[0] === null) {
            const details = changeSet[1][key];
            if (details) {
                let sectionNames: any[] = [getDisplayName(key)];
                const {
                    personalDetails,
                    contactDetails,
                    addressDetails,
                    bankingDetails,
                    detailsOfDeath,
                    occupation,
                } = getObjects(details);
                let record: ChangeFieldSet[] = [];
                if (details && !isEmpty(details)) {
                    record = changeRecordFromAddChangeSet(details);
                    addAuditTrailDetailsView(returnView, record, sectionNames);
                    sectionNames = [];
                }
                if (personalDetails && !isEmpty(personalDetails)) {
                    sectionNames.push('Personal Details');
                    record = changeRecordFromAddChangeSet(personalDetails);
                    addAuditTrailDetailsView(returnView, record, sectionNames);
                    sectionNames = [];
                }
                if (contactDetails && !isEmpty(contactDetails)) {
                    sectionNames.push('Contact Details');
                    record = changeRecordFromAddChangeSet(contactDetails);
                    addAuditTrailDetailsView(returnView, record, sectionNames);
                    sectionNames = [];
                }
                if (addressDetails && !isEmpty(addressDetails)) {
                    sectionNames.push('Address Details');
                    record = changeRecordFromAddChangeSet(addressDetails);
                    addAuditTrailDetailsView(returnView, record, sectionNames);
                    sectionNames = [];
                }
                if (bankingDetails && !isEmpty(bankingDetails)) {
                    const response = bankingDetails.bankResponse;
                    delete bankingDetails['bankResponse'];
                    if (bankingDetails && !isEmpty(bankingDetails)) {
                        sectionNames.push('Payee Details');
                        record = changeRecordFromAddChangeSet(bankingDetails);
                        addAuditTrailDetailsView(returnView, record, sectionNames);
                        sectionNames = [];
                    }
                    if (response && !isEmpty(response)) {
                        sectionNames.push('AVS');
                        record = changeRecordFromAddChangeSet(response);
                        addAuditTrailDetailsView(returnView, record, sectionNames);
                        sectionNames = [];
                    }
                }
                if (detailsOfDeath && !isEmpty(detailsOfDeath)) {
                    sectionNames.push('Details of Death');
                    record = changeRecordFromAddChangeSet(detailsOfDeath);
                    addAuditTrailDetailsView(returnView, record, sectionNames);
                    sectionNames = [];
                }
                if (occupation && !isEmpty(occupation)) {
                    sectionNames.push('Occupation Details');
                    const addressDetails = occupation['addressDetails'];
                    delete occupation['addressDetails'];
                    const rootRecord = changeRecordFromAddChangeSet(occupation);
                    if (addressDetails && !isEmpty(addressDetails)) {
                        record = rootRecord.concat(changeRecordFromAddChangeSet(addressDetails));
                    }
                    addAuditTrailDetailsView(returnView, record, sectionNames);
                }
            }
        } else {
            if (changeSet.hasOwnProperty(key)) {
                processSection(changeSet, returnView, key);
            }
        }
    });

    return mapSectionView(returnView);
};

function processSection(
    changeObject: Record<string, any>,
    returnView: React.ReactNode[],
    section: string
) {
    const details = changeObject[section];
    if (details) {
        const {
            personalDetails,
            contactDetails,
            addressDetails,
            bankingDetails,
            detailsOfDeath,
            occupation,
            payment,
            policyHolder,
            otherDetails,
        } = getObjects(details);
        let sectionNames = [];
        if (details && !isEmpty(details)) {
            sectionNames.push(getDisplayName(section));
            getView(details, returnView, sectionNames);
            sectionNames = [];
        }
        if (payment && !isEmpty(payment)) {
            sectionNames.push('Payment Details');
            getView(payment, returnView, sectionNames);
            sectionNames = [];
        }

        if (otherDetails) {
            sectionNames.push('Other Details');
            getView(otherDetails, returnView, sectionNames);
            sectionNames = [];
        }

        if (policyHolder && !isEmpty(policyHolder)) {
            sectionNames.push('Policyholder Details');
            policyHolder.personalDetails &&
                getView(policyHolder.personalDetails, returnView, sectionNames);
            policyHolder.addressDetails &&
                getView(policyHolder.addressDetails, returnView, sectionNames);
            policyHolder.employmentDetails &&
                getView(policyHolder.employmentDetails, returnView, sectionNames);
            policyHolder.contactDetails &&
                getView(policyHolder.contactDetails, returnView, sectionNames);
            sectionNames = [];
        }
        if (personalDetails && !isEmpty(personalDetails)) {
            sectionNames.push('Personal Details');
            getView(personalDetails, returnView, sectionNames);
            sectionNames = [];
        }
        if (contactDetails && !isEmpty(contactDetails)) {
            sectionNames.push('Contact Details');
            getView(contactDetails, returnView, sectionNames);
            sectionNames = [];
        }
        if (addressDetails) {
            sectionNames.push('Address Details');
            getView(addressDetails, returnView, sectionNames);
            sectionNames = [];
        }
        if (bankingDetails && !isEmpty(bankingDetails)) {
            const response = bankingDetails.bankResponse;
            sectionNames.push(getDisplayName(section));
            delete bankingDetails['bankResponse'];
            if (bankingDetails && !isEmpty(bankingDetails)) {
                sectionNames.push('Payee Details');
                getView(bankingDetails, returnView, sectionNames);
                sectionNames = [];
            }
            if (response && !isEmpty(response)) {
                sectionNames.push('AVS');
                getView(response, returnView, sectionNames);
                sectionNames = [];
            }
        }
        if (detailsOfDeath) {
            sectionNames.push('Details of Death');
            getView(detailsOfDeath, returnView, sectionNames);
            sectionNames = [];
        }
        if (occupation) {
            sectionNames.push('Occupation Details');
            getView(occupation, returnView, sectionNames);
        }
    }
}

function getView(
    changeObject: Record<string, any>,
    returnView: React.ReactNode[],
    section: string[]
) {
    let record: ChangeFieldSet[] = [];
    if (Array.isArray(changeObject) && changeObject[0] === null) {
        const address = changeObject[1]['addressDetails'];
        delete changeObject[1]['addressDetails'];
        if (changeObject[1] && !isEmpty(changeObject)) {
            const rootRecords = changeRecordFromAddChangeSet(changeObject[1]);
            if (address && !isEmpty(address)) {
                record = rootRecords.concat(changeRecordFromAddChangeSet(address));
            } else {
                record = rootRecords;
            }
            addAuditTrailDetailsView(returnView, record, section);
        }
    } else {
        let address;
        if (changeObject) {
            address = changeObject['addressDetails'];
            delete changeObject['addressDetails'];
        }
        const rootRecords = changeRecordFromAddChangeSet(changeObject);
        if (address && !isEmpty(address)) {
            record = rootRecords.concat(changeRecordFromAddChangeSet(address));
        } else {
            record = rootRecords;
        }
        record = changeRecordFromChangeSet(changeObject);
        addAuditTrailDetailsView(returnView, record, section);
    }
}

function getObjects(details: any) {
    const addressDetails = details['addressDetails'];
    delete details['addressDetails'];
    const detailsOfDeath = details['detailsOfDeath'];
    delete details['detailsOfDeath'];
    const occupation = details['occupationDetails'];
    delete details['occupationDetails'];
    const personalDetails = details['personalDetails'];
    delete details['personalDetails'];
    const contactDetails = details['contactDetails'];
    delete details['contactDetails'];
    const bankingDetails = details['bankingDetails'];
    delete details['bankingDetails'];
    const payment = details['information'];
    delete details['information'];
    const policyHolder = details['individual'];
    delete details['individual'];
    const otherDetails = details['otherDetails'];
    delete details['otherDetails'];
    return {
        personalDetails,
        contactDetails,
        addressDetails,
        bankingDetails,
        detailsOfDeath,
        occupation,
        payment,
        policyHolder,
        otherDetails,
    };
}
