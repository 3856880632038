import React from 'react'; // eslint-disable-line

import { Grid, Typography, withStyles, Divider } from '@material-ui/core';
import { FinancialAdviserContactDetails } from '../../../../../contact-details/financial-adviser/financial-adviser-contact-details';
import { LabeledValue } from '../../../../../controls/labeled-value';
import styles from '../../../../../styles/styles';
import { formatCurrency, frequencyMultiplier, getFullName } from '../../../../../shared-functions';
import { FinancialInformation, FinancialBreakdown } from '../reducer';
import { SubSectionHeading } from './dependant-view-expanded';

interface InfoProps {
    financialInformation: FinancialInformation;
    relationship?: string;
}

const dependantViewFinancialInformation: React.FunctionComponent<InfoProps> = ({
    financialInformation,
    relationship,
}: InfoProps) => {
    const financialAdviser = financialInformation.financialAdviser || {};
    return (
        <React.Fragment>
            <SubSectionHeading heading="Financial details" />
            <Grid item xs={12}>
                <LabeledValue
                    value={financialInformation.dependentOnLifeAssured}
                    label="Was dependent on life assured"
                />
            </Grid>
            <InformationBreakdownView
                financialInformation={financialInformation}
                relationship={relationship}
            />
            <SubSectionHeading heading="Financial adviser details" />
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    value={financialInformation.hasFinancialAdviser}
                    label="Has financial adviser"
                />
            </Grid>
            {financialInformation.hasFinancialAdviser === 'Yes' && (
                <Grid item xs={12} container justify="flex-start">
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            value={getFinancialAdviserName(financialAdviser)}
                            label="Name"
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            value={financialAdviser.telephoneNumber}
                            label="Telephone number"
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            value={financialAdviser.cellphoneNumber}
                            label="Cellphone number"
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue value={financialAdviser.emailAddress} label="Email address" />
                    </Grid>
                </Grid>
            )}
            {financialInformation.hasFinancialAdviser === 'No' && (
                <Grid item xs={6} lg={3}>
                    <LabeledValue
                        value={financialInformation.financialAdviceConsent}
                        label="Financial advice consent"
                    />
                </Grid>
            )}
        </React.Fragment>
    );
};

const InformationBreakdownView: React.FunctionComponent<InfoProps> = ({
    financialInformation,
    relationship,
}: InfoProps) => {
    const breakdown = financialInformation.breakdown || [];
    switch (relationship) {
        case 'MinorChild':
            return <MinorBreakdownView breakdown={breakdown} />;
        default:
            return <DefaultBreakdownView breakdown={breakdown} />;
    }
};

interface BreakdownProps {
    breakdown: FinancialBreakdown[];
}

const DefaultBreakdownView: React.FunctionComponent<BreakdownProps> = ({
    breakdown,
}: BreakdownProps) => {
    let totalIncome = 0;
    let totalExpenditure = 0;
    let totalContribution = 0;
    return (
        <Grid container justify="space-between">
            {breakdown.length > 0 && (
                <React.Fragment>
                    <Grid item xs={2}>
                        <Typography variant="subtitle2">Description</Typography>
                    </Grid>
                    <Grid item xs={2} container justify="flex-end">
                        <Grid item xs={12} container justify="flex-end">
                            <Typography variant="subtitle2">Income</Typography>
                        </Grid>
                        <Grid item xs={12} container justify="flex-end">
                            <Typography variant="subtitle2">(in Rands per month)</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} container justify="flex-end">
                        <Grid item xs={12} container justify="flex-end">
                            <Typography variant="subtitle2">Expenditure</Typography>
                        </Grid>
                        <Grid item xs={12} container justify="flex-end">
                            <Typography variant="subtitle2">(in Rands per month)</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Grid item xs={12} container justify="flex-end">
                            <Typography variant="subtitle2">{`Life assured's`}</Typography>
                        </Grid>
                        <Grid item xs={12} container justify="flex-end">
                            <Typography variant="subtitle2">contribution</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} style={{ paddingLeft: '28px' }}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">Frequency of</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">contribution</Typography>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )}
            {breakdown.map((info, index) => {
                const income =
                    info.incomeExpenditureType === 'Income'
                        ? formatCurrency(info.incomeExpenditure)
                        : '';
                const expense =
                    info.incomeExpenditureType === 'Expense'
                        ? formatCurrency(info.incomeExpenditure)
                        : '';
                const contribution =
                    (info.maintenance || 0) > 0 ? formatCurrency(info.maintenance) : '';
                if (info.incomeExpenditureType === 'Income') {
                    totalIncome += info.incomeExpenditure || 0;
                }
                if (info.incomeExpenditureType === 'Expense') {
                    totalExpenditure += info.incomeExpenditure || 0;
                }
                totalContribution +=
                    ((info.maintenance || 0) * frequencyMultiplier(info.maintenanceFrequency)) / 12;
                return (
                    <React.Fragment key={`breakdown_${index}`}>
                        <Grid item xs={2}>
                            <Typography>
                                {info.incomeExpenditureDescription === 'Other'
                                    ? info.incomeExpenditureDetails
                                    : info.incomeExpenditureDescription}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} container justify="flex-end">
                            <Typography>{income}</Typography>
                        </Grid>
                        <Grid item xs={2} container justify="flex-end">
                            <Typography>{expense}</Typography>
                        </Grid>
                        <Grid item xs={2} container justify="flex-end">
                            <Typography>{contribution}</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ paddingLeft: '28px' }}>
                            <Typography>{info.maintenanceFrequency}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />
                        </Grid>
                    </React.Fragment>
                );
            })}
            {breakdown.length > 0 && (
                <React.Fragment>
                    <Grid item xs={2}>
                        <Typography style={{ fontWeight: 500 }}>Totals</Typography>
                    </Grid>
                    <Grid item xs={2} container justify="flex-end">
                        <Typography style={{ fontWeight: 500 }}>
                            {formatCurrency(totalIncome * 12)}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} container justify="flex-end">
                        <Typography style={{ fontWeight: 500 }}>
                            {formatCurrency(totalExpenditure * 12)}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} container justify="flex-end">
                        <Typography style={{ fontWeight: 500 }}>
                            {formatCurrency(totalContribution * 12)}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} style={{ paddingLeft: '28px' }}>
                        <Typography style={{ fontWeight: 500 }}>Annually</Typography>
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    );
};

const MinorBreakdownView: React.FunctionComponent<BreakdownProps> = ({
    breakdown,
}: BreakdownProps) => {
    let totalMaintenance = 0;
    return (
        <Grid container justify="space-between">
            {breakdown.length > 0 && (
                <React.Fragment>
                    <Grid item xs={2}>
                        <Typography variant="subtitle2">Nature of dependency</Typography>
                    </Grid>
                    <Grid item xs={2} container justify="flex-end">
                        <Typography variant="subtitle2">Amount (in Rands)</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="subtitle2">Frequency</Typography>
                    </Grid>
                </React.Fragment>
            )}
            {breakdown.map((info, index) => {
                const maintenance =
                    (info.maintenance || 0) > 0 ? formatCurrency(info.maintenance) : '';
                totalMaintenance +=
                    ((info.maintenance || 0) * frequencyMultiplier(info.maintenanceFrequency)) / 12;
                return (
                    <Grid item xs={12} container justify="space-between" key={`breakdown_${index}`}>
                        <Grid item xs={2}>
                            <Typography>
                                {info.incomeExpenditureDescription === 'Other'
                                    ? info.incomeExpenditureDetails
                                    : info.incomeExpenditureDescription}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} container justify="flex-end">
                            <Typography>{maintenance}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>{info.maintenanceFrequency}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />
                        </Grid>
                    </Grid>
                );
            })}
            {breakdown.length > 0 && (
                <React.Fragment>
                    <Grid item xs={2}>
                        <Typography style={{ fontWeight: 500 }}>Totals</Typography>
                    </Grid>
                    <Grid item xs={2} container justify="flex-end">
                        <Typography style={{ fontWeight: 500 }}>
                            {formatCurrency(totalMaintenance * 12)}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{ fontWeight: 500 }}>Annually</Typography>
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    );
};

function getFinancialAdviserName(financialAdviser?: FinancialAdviserContactDetails) {
    if (financialAdviser && financialAdviser.companyName && financialAdviser.companyName !== '') {
        return financialAdviser.companyName;
    }

    return getFullName(financialAdviser);
}

export const DependantViewFinancialInformation = withStyles(styles)(
    dependantViewFinancialInformation
);
