import { createFeatureActionNamer } from '../redux/action-name';

const getActionName = createFeatureActionNamer('workflow-navigation');

export const SET_SUBMISSION_CALLBACKS = getActionName('SET_SUBMISSION_CALLBACKS');
export const SET_SUBMITTING_FLAG = getActionName('SET_SUBMITTING_FLAG');
export const SET_SUBMIT_SUCCESS_FLAG = getActionName('SET_SUBMIT_SUCCESS_FLAG');
export const CLEAR_SUBMISSION_FLAGS = getActionName('CLEAR_SUBMISSION_FLAGS');

export const SET_ACTIVE_WORKFLOW = getActionName('SET_ACTIVE_WORKFLOW');
export const ADD_SCREEN_TO_WORKFLOW = getActionName('ADD_SCREEN_TO_WORKFLOW');
export const DELETE_SCREEN_FROM_WORKFLOW = getActionName('DELETE_SCREEN_FROM_WORKFLOW');
export const SET_ACTIVE_SCREEN = getActionName('SET_ACTIVE_SCREEN');
