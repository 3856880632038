import { ADD_DISPUTE_RECORD, EDIT_DISPUTE_RECORD } from './types';
import { collectionReducer } from '../../../../redux/collection-reducer/collection-reducer';
import { State } from '../../../../redux/root-reducer';
import { Dependant } from '../dependants/reducer';
import { getDependantName } from '../../../../shared-functions';
import { AnyAction } from 'redux';

export interface Dispute {
    readonly id?: string;
    readonly dependantId?: string;
    readonly otherName?: string;
    readonly otherSurname?: string;
    readonly relationshipToLifeAssured?: string;
    readonly relationshipToLifeAssuredDescription?: string;
    readonly reason?: string;
    readonly supportingProof?: string;
    readonly decision?: string;
    readonly summaryOfDecision?: string;
    readonly decisionMadeBy?: string;
    readonly assessor?: string;
    readonly assessmentDate?: Date;
    readonly trusteeMeetingDate?: Date;
    readonly disputeDate?: Date;
}

export const defaultDisputesReducerReducer: Dispute[] = [];

export const disputesReducer = collectionReducer<Dispute>({
    addAction: ADD_DISPUTE_RECORD,
    updateAction: EDIT_DISPUTE_RECORD,
});

export function defaultDisputesReducer(state: Dispute[] = [], action: AnyAction) {
    switch (action.type) {
        default:
            return state;
    }
}

export function getIndex(state: State): number {
    return !!state.formModal.activeModal ? state.formModal.activeModal.modalData : -1;
}

export const getDependantOptions = (dependants: Dependant[]) => {
    // return current dependants and the 'Other' option
    const allDependants = dependants.map(dependant => {
        return { value: dependant.id, label: getDependantName(dependant.id || '', dependants) };
    });

    allDependants.push({ value: 'Other', label: 'Other' });

    return allDependants;
};
