import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { ButtonWrapper } from '../../../../../forms';
import { State } from '../../../../../redux/root-reducer';
import { getClaimantDisplayName } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import {
    AddDocumentButton,
    RequiredDocumentsView,
} from '../../../common/requirements-management/views';
import { Claimant, getClaimants } from '../../contact-details/contact-details-reducer';
import {
    addAndSubmitClaimantDocumentAction,
    AddClaimantDocumentStatusRecord,
} from '../actions/add-claimants-required-document';
import { ClaimantRequirementsStatus, getDocumentsRequirements } from '../reducer';
import { EditDocumentsButton } from './requirements-edit-modal';
import { DocumentOwnerType } from '../../../../../shared-types';

interface DispatchProps {
    addRequiredDocument: AddClaimantDocumentStatusRecord;
}
interface StateProps {
    claimants: Claimant[];
    claimantsRequirements: ClaimantRequirementsStatus[];
}
interface OwnProps {}
type Props = StyledComponent & OwnProps & DispatchProps & StateProps;
const claimantRequirementView: React.FunctionComponent<Props> = ({
    addRequiredDocument,
    claimants,
    claimantsRequirements,
    classes,
}: Props) => {
    if (claimantsRequirements.length === 0) {
        return null;
    }

    return (
        <React.Fragment>
            <Grid container item xs={6} alignItems="center">
                <Typography variant="subtitle2">Claimant requirements</Typography>
            </Grid>
            {claimantsRequirements.map((requirement, claimantIndex) => {
                const prefix = `claimants[${claimantIndex}].documents`;
                const claimant = claimants.find(c => c.id === requirement.claimantId) || {};
                const claimantName = getClaimantDisplayName(claimant);
                const documents = requirement.documents || [];
                return (
                    <Paper className={classes.paper} key={claimantIndex}>
                        <Grid container justify="flex-start">
                            <Grid item xs={8} lg={10}>
                                <Typography variant="h5">{claimantName}</Typography>
                            </Grid>
                            <ButtonWrapper>
                                <Grid item xs={4} lg={2} style={{ textAlign: 'right' }}>
                                    <EditDocumentsButton
                                        documents={documents}
                                        name={claimantName}
                                        prefix={prefix}
                                    />
                                    <AddDocumentButton
                                        addAction={record =>
                                            addRequiredDocument(record, claimantIndex)
                                        }
                                        documents={documents}
                                        documentOwnerType={DocumentOwnerType.Claimant}
                                        claimantId={requirement.claimantId}
                                        isDeath
                                    />
                                </Grid>
                            </ButtonWrapper>
                        </Grid>
                        <Grid container justify="flex-start">
                            <Grid item xs={6} lg={4}>
                                <LabeledValue
                                    label="Type"
                                    value={
                                        (claimant.claimantDetails &&
                                            claimant.claimantDetails.claimantType) ||
                                        ''
                                    }
                                />
                            </Grid>
                            <RequiredDocumentsView documents={documents} />
                        </Grid>
                    </Paper>
                );
            })}
        </React.Fragment>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => {
    const requirements = getDocumentsRequirements(state) || {};
    return {
        claimants: getClaimants(state),
        claimantsRequirements: requirements.claimants || [],
    };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    addRequiredDocument: (options, claimantIndex: number) =>
        dispatch(addAndSubmitClaimantDocumentAction(options, claimantIndex)),
});

export const ClaimantRequirementView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(claimantRequirementView));
