import { UpdateRecordCollectionAction } from '../../redux/collection-reducer';
import { ReinsurerReferal } from '../reducer';
import { UPDATE_REINSURER_REFERAL_RECORD } from '../types';

export type UpdateReinsurerReferalRecordAction = UpdateRecordCollectionAction<ReinsurerReferal>;

export const updateReinsurerReferalRecordActionCreator = (
    reinsurerReferalRecordIndex: number,
    reinsurerReferalRecord: ReinsurerReferal
): UpdateReinsurerReferalRecordAction => {
    return {
        type: UPDATE_REINSURER_REFERAL_RECORD,
        record: reinsurerReferalRecord,
        index: reinsurerReferalRecordIndex,
    };
};
