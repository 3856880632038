import { telephoneNumber } from '../../claim-schema/shared-regex';
import { SyncValidator, UserInput } from './';

export function regexValidator(regex: string, message: string): SyncValidator {
    return function validateRegex(value: UserInput) {
        if (value === undefined || value === null) {
            return undefined;
        }
        if (typeof value !== 'string') {
            return message;
        }

        const _value: string = value.trim();
        if (_value.match(regex)) {
            return undefined;
        }

        return message;
    };
}

export const emailFormatValidator = regexValidator(
    '([a-zA-Z0-9_\\-\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([a-zA-Z0-9\\-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})',
    'Please enter a valid email address'
);

export const phoneFormatValidator = regexValidator(
    telephoneNumber(),
    'Please enter a valid phone number'
);
