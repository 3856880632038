import { reduceAndSubmitClaim } from '../../../../../../../claim/actions/reduce-and-submit-claim';
import { ReduxThunkAction } from '../../../../../../../redux/redux-actions';
import { UnderwritingNonDisclosure } from '../underwriting-non-disclosure-model';
import { addUnderwritingNonDisclosureActionCreator } from './add-underwriting-non-disclosure';
import { deleteUnderwritingNonDisclosureActionCreator } from './delete-underwriting-non-disclosure';
import { updateUnderwritingNonDisclosureActionCreator } from './update-underwriting-non-disclosure';

type SubmitReturn = Promise<any>;

export type AddAndSubmitUnderwritingNonDisclosure = (
    policyIndex: number,
    underwritingNonDisclosure: UnderwritingNonDisclosure
) => SubmitReturn;
type AddAndSubmitUnderwritingNonDisclosureThunk = (
    policyIndex: number,
    underwritingNonDisclosure: UnderwritingNonDisclosure
) => ReduxThunkAction<SubmitReturn>;
export const addAndSubmitUnderwritingNonDisclosureThunk: AddAndSubmitUnderwritingNonDisclosureThunk = (
    policyIndex: number,
    underwritingNonDisclosure: UnderwritingNonDisclosure
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        addUnderwritingNonDisclosureActionCreator(policyIndex, underwritingNonDisclosure)
    );

export type UpdateAndSubmitUnderwritingNonDisclosure = (
    policyIndex: number,
    underwritingNonDisclosureIndex: number,
    underwritingNonDisclosure: UnderwritingNonDisclosure
) => SubmitReturn;
type UpdateAndSubmitUnderwritingNonDisclosureThunk = (
    policyIndex: number,
    underwritingNonDisclosureIndex: number,
    underwritingNonDisclosure: UnderwritingNonDisclosure
) => ReduxThunkAction<SubmitReturn>;
export const updateAndSubmitUnderwritingNonDisclosureThunk: UpdateAndSubmitUnderwritingNonDisclosureThunk = (
    policyIndex: number,
    underwritingNonDisclosureIndex: number,
    underwritingNonDisclosure: UnderwritingNonDisclosure
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        updateUnderwritingNonDisclosureActionCreator(
            policyIndex,
            underwritingNonDisclosureIndex,
            underwritingNonDisclosure
        )
    );

export type DeleteAndSubmitUnderwritingNonDisclosure = (
    policyIndex: number,
    underwritingNonDisclosureIndex: number
) => SubmitReturn;
type DeleteAndSubmitUnderwritingNonDisclosureThunk = (
    policyIndex: number,
    underwritingNonDisclosureIndex: number
) => ReduxThunkAction<SubmitReturn>;
export const deleteAndSubmitUnderwritingNonDisclosureThunk: DeleteAndSubmitUnderwritingNonDisclosureThunk = (
    policyIndex: number,
    underwritingNonDisclosureIndex: number
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        deleteUnderwritingNonDisclosureActionCreator(policyIndex, underwritingNonDisclosureIndex)
    );
