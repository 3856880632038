import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { AddFormModal } from '../../../../form-modal';
import {
    Condition,
    FormHeading,
    FullWidthDatePicker,
    FullWidthRadioGroup,
    FullWidthSelectField,
    FullWidthTextField,
    RadioOption,
    True,
    When,
    WhenFieldChanges,
} from '../../../../forms';
import { CurrencyMask } from '../../../../forms/input-masks';
import { PercentageMask } from '../../../../forms/input-masks/percentage-mask';
import { composeValidators, requiredValidator } from '../../../../forms/validations';
import { convertToNumber, formatPercent, getDisplayDate } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import { ClaimLiability } from '../../assessment-details-reducer';
import { v1 as uuid } from 'uuid';
import { AuthState } from '../../../../auth/auth-reducer';
import { ERBAssessmentDecisionViewModel } from '../early-retirement-reducer';
import { connect } from 'react-redux';
import { State } from '../../../../redux/root-reducer';
import {
    AddERBAssessmentDecision,
    addERBAssessmentDecisionAction,
} from '../actions/add-erb-assessment-decision';
import { ClaimType } from '../../../../claim/claim-types';
export interface AddERBPolicyAssessmentDecisionProps {
    policyNumber: string;
    commencementDate?: Date;
    dateOfEvent?: Date;
    causeOfClaim?: string;
    assessmentDescription: string;
    automaticBenefitIncrease: number;
    claimLiability: ClaimLiability;
}

interface StateProps {
    currentUser: AuthState;
}

interface DispatchProps {
    addAssessmentDecision: AddERBAssessmentDecision;
}
interface DecisionProps extends AddERBPolicyAssessmentDecisionProps, StyledComponent {
    currentClaimType?: string;
}

type Props = DecisionProps & StyledComponent & DispatchProps & StateProps;
const ERBAssessmentDecisionAddFormModal = AddFormModal<ERBAssessmentDecisionViewModel>();
const erbAssessmentDecisionAddView: React.FunctionComponent<Props> = ({
    classes,
    currentUser,
    addAssessmentDecision,
    claimLiability,
    ...detailProps
}: Props) => {
    return (
        <ERBAssessmentDecisionAddFormModal
            recordDetailView={() => (
                <ERBClaimAssessmentDecisionDetailView
                    {...detailProps}
                    classes={classes}
                    claimLiability={claimLiability}
                />
            )}
            addRecord={record => addAssessmentDecision(record)}
            blankRecord={getBlankAssessmentDecision()}
        />
    );

    function getBlankAssessmentDecision(): ERBAssessmentDecisionViewModel {
        return {
            assessmentDate: new Date(),
            assessmentType: detailProps.assessmentDescription,
            final: true,
            id: uuid(),
            assessor: currentUser.name,
            claimType: ClaimType.EarlyRetirement,
            policyNumber: detailProps.policyNumber,
            commencementDate: detailProps.commencementDate,
            automaticBenefitIncrease: detailProps.automaticBenefitIncrease,
        };
    }
};

const ERBClaimAssessmentDecisionDetailView: React.FunctionComponent<DecisionProps> = ({
    assessmentDescription,
    commencementDate,
    currentClaimType,
    claimLiability,
    classes,
    dateOfEvent,
    causeOfClaim,
    policyNumber,
    automaticBenefitIncrease,
}: DecisionProps) => {
    return (
        <React.Fragment>
            <FormHeading>{assessmentDescription} Decision</FormHeading>
            <Grid container spacing={5} justify="space-between" className={classes.marginTop}>
                <Grid item>
                    <Typography variant="body2">Policy Number</Typography>
                    <Typography variant="body2">{policyNumber}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2">Commencement Date</Typography>
                    <Typography variant="body2">{getDisplayDate(commencementDate)}</Typography>
                </Grid>
            </Grid>
            <Grid spacing={5} justify="space-between" className={classes.marginTop}>
                <Grid item xs={3}>
                    <Typography variant="body2">Automatic Benefit Increase</Typography>
                    <Typography variant="body2">
                        {formatPercent((automaticBenefitIncrease || 0) / 100)}
                    </Typography>
                </Grid>
            </Grid>
            {causeOfClaim && (
                <Grid container spacing={5} justify="space-between" className={classes.marginTop}>
                    <Grid item>
                        <Typography variant="body2">Claim Event</Typography>
                        <Typography variant="body2">{causeOfClaim}</Typography>
                    </Grid>
                </Grid>
            )}
            <Grid container spacing={5}>
                <Grid item xs={4}>
                    <FullWidthRadioGroup name="mapClaim" validate={requiredValidator}>
                        <RadioOption value="Yes" />
                        <RadioOption value="No" />
                    </FullWidthRadioGroup>
                </Grid>
                <Grid item xs={4}>
                    <FullWidthRadioGroup
                        label="EAIP Claims Booster"
                        name="claimsBooster"
                        validate={requiredValidator}
                    >
                        <RadioOption value="Yes" />
                        <RadioOption value="No" />
                    </FullWidthRadioGroup>
                </Grid>
            </Grid>
            <Grid container spacing={5}>
                <Grid item xs={4}>
                    <FullWidthSelectField
                        name="decision"
                        options={[
                            { value: 'Approve', label: 'Approve' },
                            { value: 'Decline', label: 'Decline' },
                            { value: 'Defer', label: 'Defer' },
                        ]}
                        validate={requiredValidator}
                    />
                    <WhenFieldChanges field="decision" set="reason" to={null} />
                    <WhenFieldChanges field="decision" set="effectiveDate" to={dateOfEvent} />
                </Grid>
                <Grid item xs={4}>
                    <Condition when="decision" matchesChild>
                        <When is="Approve">
                            <FullWidthSelectField
                                name="reason"
                                options={[
                                    { value: 'Approve', label: 'Approve' },
                                    {
                                        value: 'New Terms Accepted',
                                        label: 'New Terms Accepted',
                                    },
                                    {
                                        value: 'Business Decision',
                                        label: 'Business Decision',
                                    },
                                    { value: 'Claim Committee', label: 'Claim Committee' },
                                    { value: 'Complaint', label: 'Complaint' },
                                ]}
                                validate={requiredValidator}
                            />
                        </When>
                        <When is="Decline">
                            <FullWidthSelectField
                                name="reason"
                                options={[
                                    {
                                        value: 'Innocent Non-disclosure (I)',
                                        label: 'Innocent Non-disclosure (I)',
                                    },
                                    {
                                        value: 'Deliberate Non-disclosure (D)',
                                        label: 'Deliberate Non-disclosure (D)',
                                    },
                                    { value: 'Fraud', label: 'Fraud' },
                                    {
                                        value: 'Does not meet the criteria',
                                        label: 'Does not meet the criteria',
                                    },
                                    {
                                        value: 'No benefit exists',
                                        label: 'No benefit exists',
                                    },
                                    {
                                        value: 'Benefits expired',
                                        label: 'Benefits expired',
                                    },
                                    { value: 'Exclusion', label: 'Exclusion' },
                                    {
                                        value: 'Condition not covered',
                                        label: 'Condition not covered',
                                    },
                                    { value: 'Policy inactive', label: 'Policy inactive' },
                                    {
                                        value: 'New terms offered - does not qualify for a claim',
                                        label: 'New terms offered - does not qualify for a claim',
                                    },
                                    {
                                        value: 'New terms offered - does qualify for a claim',
                                        label: 'New terms offered - does qualify for a claim',
                                    },
                                    {
                                        value: 'Within waiting period',
                                        label: 'Within waiting period',
                                    },
                                ]}
                                validate={requiredValidator}
                            />
                        </When>
                        <When default>
                            <FullWidthSelectField name="reason" disabled options={[]} />
                        </When>
                    </Condition>
                </Grid>
                <Condition when="decision" is="Approve">
                    <True>
                        <React.Fragment>
                            <Grid item xs={4}>
                                <FullWidthDatePicker
                                    name="effectiveDate"
                                    validate={requiredValidator}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FullWidthTextField
                                    name="percentagePayable"
                                    mask={PercentageMask}
                                    validate={requiredValidator}
                                    parse={convertToNumber}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FullWidthTextField
                                    name="amountPayable"
                                    mask={CurrencyMask}
                                    validate={composeValidators(requiredValidator)}
                                    parse={convertToNumber}
                                />
                            </Grid>
                        </React.Fragment>
                    </True>
                </Condition>
                <Grid item xs={4}>
                    <FullWidthSelectField
                        name="claimType"
                        options={[
                            { value: 'Dread', label: 'Dread' },
                            { value: 'Disability', label: 'Disability' },
                            { value: 'TerminalIllness', label: 'Terminal Illness' },
                            { value: 'Retrenchment', label: 'Retrenchment' },
                            { value: 'IncomeProtector', label: 'Income Protector' },
                            { value: 'EarlyRetirement', label: 'Early Retirement' },
                        ]}
                        validate={requiredValidator}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const mapStateToProps = (state: State) => ({
    currentUser: state.auth as AuthState,
});

const mapDispatchToProps = {
    addAssessmentDecision: addERBAssessmentDecisionAction,
};

export const ERBAssessmentDecisionAddView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(erbAssessmentDecisionAddView));
