import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getClaim } from '../../../../../../claim';
import { Section } from '../../../../../../controls/section';
import { ButtonWrapper } from '../../../../../../forms';
import { getLifeAssuredDetailsFromClaim } from '../../../../../../life-assured-details/reducer';
import { State } from '../../../../../../redux/root-reducer';
import { getFullName } from '../../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { getRequirementsFromClaim } from '../../../../common/requirements-management/reducer';
import {
    AddDocumentButton,
    EditDocumentsButton,
    RequiredDocumentsView,
} from '../../../../common/requirements-management/views';
import { RADeathLifeAssured } from '../../../life-assured/reducer';
import {
    addAndSubmitLifeAssureDocumentAction,
    AddLifeAssuredDocumentRecord,
} from '../../action/add-life-assured-documents';
import { RADeathRequirements } from '../../reducer';
import { DocumentOwnerType } from '../../../../../../shared-types';

interface DispatchProps {
    addDocument: AddLifeAssuredDocumentRecord;
}
interface StateProps {
    documentRequirements: RADeathRequirements;
    lifeAssured: RADeathLifeAssured;
}

type Props = StyledComponent & DispatchProps & StateProps;
const lifeAssuredRequirementsView: React.FunctionComponent<Props> = ({
    addDocument,
    classes,
    documentRequirements,
    lifeAssured,
}: Props) => {
    const documents =
        (documentRequirements.lifeAssured && documentRequirements.lifeAssured.documents) || [];
    const laName = getFullName(lifeAssured.personalDetails);
    const prefix = 'lifeAssured.documents';

    return (
        <Section title="Life Assured requirements">
            <Grid item xs={12} container spacing={0} justify="space-between">
                <Grid item xs={8} lg={10}>
                    <Typography variant="h5">{laName}</Typography>
                </Grid>
                <ButtonWrapper>
                    <Grid item xs={4} lg={2} className={classes.rightAlign}>
                        <EditDocumentsButton documents={documents} name={laName} prefix={prefix} />
                        <AddDocumentButton
                            addAction={addDocument}
                            documentOwnerType={DocumentOwnerType.LifeAssured}
                            documents={documents}
                        />
                    </Grid>
                </ButtonWrapper>
            </Grid>
            <RequiredDocumentsView documents={documents} />
        </Section>
    );
};

const mapStateToProps: MapStateToProps<StateProps, Record<string, any>, State> = (state: State) => {
    const claim = getClaim(state);
    return {
        documentRequirements: getRequirementsFromClaim(claim) as RADeathRequirements,
        lifeAssured: getLifeAssuredDetailsFromClaim(claim) as RADeathLifeAssured,
    };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, Record<string, any>> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    addDocument: options => dispatch(addAndSubmitLifeAssureDocumentAction(options)),
});

export const LifeAssuredRequirementsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(lifeAssuredRequirementsView));
