import React from 'react'; // eslint-disable-line

import { TextFieldProps } from '@material-ui/core/TextField';
import { Field, FieldProps } from 'react-final-form';

import { FieldSet, TextFieldTypes } from '../../';
import { MaskedInputProps } from '../text-field';
import { NumberCounter } from '../number-counter';

type Props = FieldProps<any, any> &
    TextFieldProps &
    MaskedInputProps & {
        type?: TextFieldTypes;
    };

export const NumberCounterText: React.FunctionComponent<Props> = ({
    name,
    fullWidth = true,
    type = 'text',
    ...rest
}: Props) => {
    return (
        <FieldSet>
            <Field
                {...rest}
                name={name}
                fullWidth={fullWidth}
                component={NumberCounter}
                type={type}
            />
        </FieldSet>
    );
};
