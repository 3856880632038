import React from 'react'; // eslint-disable-line
import { TextFieldProps } from '@material-ui/core/TextField';
import { IconTextField } from './index';
import { FieldSet, TextFieldTypes } from '../../';
import { MaskedInputProps } from '../text-field';
import { Field, FieldProps } from 'react-final-form';

type Props = FieldProps<any, any> &
    TextFieldProps &
    MaskedInputProps & {
        type?: TextFieldTypes;
    };

export const FormIconTextField: React.FunctionComponent<Props> = ({
    name,
    fullWidth = true,
    type = 'text',
    ...rest
}: Props) => {
    return (
        <FieldSet>
            <Field
                {...rest}
                name={name}
                fullWidth={fullWidth}
                component={IconTextField}
                type={type}
            />
        </FieldSet>
    );
};
