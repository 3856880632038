import React from 'react'; // eslint-disable-line
import {
    Decision,
    DecisionModel,
    getBlankAssessmentRecord,
} from '../../../../decisions/ra-death/ra-death-assessment-details-reducer';
import { RaDeathDecisionEditView } from './ra-death-assessment-percentage-split-edit';
import { AddModifiedFormModal } from '../../../../../form-modal/views/add-modified-form-modal';
import { CloseModal } from '../../../../../form-modal/actions';
import { Dependant } from '../reducer';

const AddAssessmentDecisionFormModal = AddModifiedFormModal<DecisionModel>();

async function beneficiarySplit(
    decisions: Decision[],
    dependants: Dependant[],
    closeModal: CloseModal,
    openAddModal: any,
    addRecord: any
) {
    const decision = getBlankAssessmentRecord(decisions, dependants, 'currentUser');
    decision.percentageSplits.map((percentageSplit, i) => {
        const finalPercentage = (percentageSplit.allocation = 100 / dependants.length);
        percentageSplit.allocation = Number(percentageSplit.allocation.toFixed(2));
        if (i + 1 === dependants.length) {
            const remainder = Number(percentageSplit.allocation * dependants.length) - 100;

            percentageSplit.allocation =
                percentageSplit.allocation * 100 > 100
                    ? Number((percentageSplit.allocation - remainder).toFixed(2))
                    : Number((percentageSplit.allocation + remainder).toFixed(2));
        }
        return finalPercentage;
    });
    await closeModal();
    openBeneficiarySplitModal(decision, decisions, dependants, closeModal, openAddModal, addRecord);
    return null;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function openBeneficiarySplitModal(
    decision: DecisionModel | null,
    decisions: Decision[],
    dependants: Dependant[],
    closeModal: CloseModal,
    openAddModal?: any,
    addRecord?: any
) {
    const blankDecision = getBlankAssessmentRecord(decisions, dependants, 'currentUser');
    openAddModal({
        modalContent: (
            <AddAssessmentDecisionFormModal
                recordDetailView={<RaDeathDecisionEditView />}
                addRecord={record => addRecord(record)}
                blankRecord={decision ? decision : blankDecision}
                additionalButtonLabel="Split Percentage Equally"
                additionalButtonAction={() =>
                    beneficiarySplit(decisions, dependants, closeModal, openAddModal, addRecord)
                }
            />
        ),
        modalData: {},
    });
}
