import { Divider, Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { FullWidthTextField } from '../../../../../forms';
import styles, { StyledComponent } from '../../../../../styles/styles';

type Props = StyledComponent & {
    heading: React.ReactNode;
};
const claimNotesForm: React.FunctionComponent<Props> = ({ heading }: Props) => {
    return (
        <React.Fragment>
            <Typography variant="h5">{`${heading}`} </Typography>
            <Divider></Divider>
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={12}>
                    <FullWidthTextField name="notesSummary" label="Tele Claim Notes" multiline />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export const ClaimNotesForm = withStyles(styles)(claimNotesForm);
