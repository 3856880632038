import { AnyAction, Reducer } from 'redux';
import { getProperty } from '../../../../claim';
import { State } from '../../../../redux/root-reducer';
import {
    PolicyOwnerDetails,
    PolicyOwnerOrganizationDetails,
} from '../../../../life-assured-details/reducer';

export const defaultPolicyOwnerDetails: PolicyOwnerDetails = {
    contactDetails: {},
};

export const policyOwnerDetailsReducer: Reducer<PolicyOwnerDetails[], AnyAction> = (
    state: PolicyOwnerDetails[] = [],
    action: AnyAction
) => {
    switch (action.type) {
        default:
            return state;
    }
};

export const policyOwnerOrganizationDetailsReducer: Reducer<
    PolicyOwnerOrganizationDetails[],
    AnyAction
> = (state: PolicyOwnerOrganizationDetails[] = [], action: AnyAction) => {
    switch (action.type) {
        default:
            return state;
    }
};

export function getPolicyOwners(state: State): PolicyOwnerDetails[] {
    return getProperty(state, 'policyOwners', []);
}
export function getPolicyOwnerOrganizations(state: State): PolicyOwnerOrganizationDetails[] {
    return getProperty(state, 'policyOwnerOrganizations', []);
}
