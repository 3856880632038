import React from 'react'; // eslint-disable-line

import {
    Button,
    Grid,
    Icon,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    withStyles,
} from '@material-ui/core';
import { connect } from 'react-redux';
import {
    DialogContentDetails,
    OpenDialog,
    openDialogAction,
    EmptyDialogContent,
} from '../../../confirmation-dialog';
import { OpenEditModal, openEditModalAction } from '../../../form-modal/actions';
import styles, { StyledComponent } from './styles';

export interface GridColumn {
    key: string;
    value?: (record: any) => React.ReactNode;
    label: React.ReactNode;
    format?: (value?: any) => React.ReactNode;
    numeric?: boolean;
    text?: boolean;
}

interface Props {
    readonly tableHeading: React.ReactNode;
    readonly records: any[];
    readonly columns: GridColumn[];
    readonly canAdd?: (record: any) => boolean;
    readonly editView?: React.ReactNode;
    readonly deleteRecord?: (
        index: number
    ) => void | Promise<void> | Record<string, any> | Promise<Record<string, any>>;
    readonly deleteDialogDetails?: DialogContentDetails;
    readonly openDialog: OpenDialog;
    readonly openEditModal: OpenEditModal;
}

const _RecordGrid: React.FunctionComponent<StyledComponent & Props> = ({
    classes,
    tableHeading,
    records,
    columns,
    canAdd,
    editView,
    deleteRecord,
    deleteDialogDetails,
    openDialog,
    openEditModal,
}: StyledComponent & Props) => {
    const edit = (recordIndex: number) => {
        openEditModal({
            modalContent: editView,
            modalData: {
                recordIndex,
            },
        });
    };

    const confirmDelete = (recordIndex: number) => {
        if (deleteRecord) {
            openDialog({
                content: deleteDialogDetails || EmptyDialogContent,
                yesAction: { action: () => deleteRecord(recordIndex) },
            });
        }
    };

    const editIcon = (record: any) => {
        if (canAdd && canAdd(record)) {
            return 'add_circle';
        }
        return 'edit';
    };

    const editToolTip = (record: any) => {
        if (canAdd && canAdd(record)) {
            return 'Add';
        }
        return 'View/Edit';
    };
    const deleteIcon = () => {
        if (canAdd) {
            return 'remove_circle';
        }
        return 'delete';
    };

    const deleteTooltip = () => {
        if (canAdd) {
            return 'Remove';
        }
        return 'Delete';
    };

    const hasActions = !!editView || !!deleteRecord;
    const getValue = (column: GridColumn, record: any) => {
        const value = column.value ? column.value(record) : record[column.key];
        return column.format ? column.format(value) : value;
    };
    return (
        <React.Fragment>
            {records.length > 0 && (
                <React.Fragment>
                    <Typography variant="subtitle1">{tableHeading}</Typography>
                    <Grid item lg={12} style={{ overflowX: 'auto' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columns.map(column => {
                                        return (
                                            <TableCell
                                                key={column.key}
                                                align={column.numeric ? 'right' : undefined}
                                                className={classes.cell}
                                            >
                                                {column.label}
                                            </TableCell>
                                        );
                                    })}
                                    {hasActions && (
                                        <TableCell className={classes.actionCell}>
                                            Actions
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            {records && (
                                <TableBody>
                                    {records.map((row, entryIndex) => {
                                        return (
                                            <TableRow key={entryIndex}>
                                                {columns.map(column => {
                                                    return (
                                                        <TableCell
                                                            key={column.key}
                                                            align={
                                                                column.numeric ? 'right' : undefined
                                                            }
                                                            className={classes.cell}
                                                            style={
                                                                column.text
                                                                    ? { wordBreak: 'break-word' }
                                                                    : undefined
                                                            }
                                                        >
                                                            {getValue(column, row)}
                                                        </TableCell>
                                                    );
                                                })}
                                                {hasActions && (
                                                    <TableCell className={classes.actionCell}>
                                                        {editView && (
                                                            <Tooltip title={editToolTip(row)}>
                                                                <Button
                                                                    id={'ViewEdit_' + entryIndex}
                                                                    color="secondary"
                                                                    className={classes.button}
                                                                    onClick={() => edit(entryIndex)}
                                                                >
                                                                    <Icon>{editIcon(row)}</Icon>
                                                                </Button>
                                                            </Tooltip>
                                                        )}
                                                        {deleteRecord && !(canAdd && canAdd(row)) && (
                                                            <Tooltip title={deleteTooltip()}>
                                                                <Button
                                                                    id={'Delete_' + entryIndex}
                                                                    color="secondary"
                                                                    className={classes.button}
                                                                    onClick={() =>
                                                                        confirmDelete(entryIndex)
                                                                    }
                                                                >
                                                                    <Icon>{deleteIcon()}</Icon>
                                                                </Button>
                                                            </Tooltip>
                                                        )}
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            )}
                        </Table>
                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    openDialog: openDialogAction,
    openEditModal: openEditModalAction,
};

export const RecordGrid = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(_RecordGrid));
