import { Grid, Paper, withStyles } from '@material-ui/core';
import React, { useState } from 'react'; // eslint-disable-line
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import {
    Condition,
    FormHeading,
    FullWidthRadioGroup,
    FullWidthTextField,
    RadioOption,
    True,
    VirtualizedSearchSelectField,
    WhenFieldChanges,
} from '../../../../../forms';
import { InitialsMask, NumberMask } from '../../../../../forms/input-masks';
import {
    composeValidators,
    conditionalRsaIdNumberValidator,
    maximumLength,
    requiredValidator,
} from '../../../../../forms/validations';
import { CodeList, UniversalBranch } from '../../../../../lookups';
import { LoadBankBranches, loadBankBranchesAction } from '../../../../../lookups/bank-branch-codes';
import { State } from '../../../../../redux/root-reducer';
import { getValueFromObject } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';

interface BankingProps {
    readonly isAnOrganisation?: boolean;
    readonly propertyPrefix?: string;
}

const bankingDetailsView: React.FunctionComponent<BankingProps & StyledComponent> = ({
    classes,
    ...rest
}: BankingProps & StyledComponent) => {
    return (
        <Paper className={classes.paper}>
            <FormHeading text="Banking Details" />
            <BankingDetailsEdit {...rest} />
        </Paper>
    );
};

interface EditBankingProp extends BankingProps {
    readonly bankNames: CodeList<string>;
    readonly loadBankBranches: LoadBankBranches;
    readonly universalBranches: UniversalBranch[];
}

const defaultBranches: UniversalBranch[] = [];

const mapStateToProps = (state: State) => ({
    bankNames: state.claimSession.lookups.bankNames,
    universalBranches: state.claimSession.lookups.universalBranches,
});

const mapDispatchToProps = {
    loadBankBranches: loadBankBranchesAction,
};

export const BankingDetailsEdit = connect(
    mapStateToProps,
    mapDispatchToProps
)(
    ({
        isAnOrganisation,
        propertyPrefix = '',
        bankNames,
        universalBranches,
        loadBankBranches,
    }: EditBankingProp) => {
        const [branchCodes, setBranchCodes] = useState(defaultBranches);
        const [loadBranches, setLoadBranches] = useState(false);
        const [loadingBranches, setLoadingBranches] = useState(false);
        const getBranches = (bank: string) => {
            if (loadBranches && !loadingBranches) {
                setLoadingBranches(true);
                loadBankBranches(bank)
                    .then(result => {
                        setBranchCodes(result);
                    })
                    .finally(() => {
                        setLoadBranches(false);
                        setLoadingBranches(false);
                    });
            }
        };
        const fullPropertyPrefix =
            propertyPrefix && propertyPrefix !== '' ? `${propertyPrefix}.` : propertyPrefix;

        return (
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={6}>
                    <FullWidthTextField
                        name={`${fullPropertyPrefix}accountHoldersInitials`}
                        validate={maximumLength(5)}
                        mask={InitialsMask}
                    />
                </Grid>
                <ResetStatus prefix={fullPropertyPrefix} property={'accountHoldersInitials'} />
                <Grid item xs={6}>
                    <FullWidthTextField
                        name={`${fullPropertyPrefix}accountHoldersName`}
                        label="Account Holder's surname/company name"
                        validate={composeValidators(requiredValidator, maximumLength(60))}
                    />
                </Grid>
                <ResetStatus prefix={fullPropertyPrefix} property={'accountHoldersName'} />
                <Grid item xs={6}>
                    <FullWidthRadioGroup
                        label="Account holder's identity type"
                        name={`${fullPropertyPrefix}accountHoldersIdType`}
                        validate={requiredValidator}
                    >
                        <RadioOption value="ID" />
                        <RadioOption value="Passport" />
                        <RadioOption value="Registration" label="Registration Number" />
                    </FullWidthRadioGroup>
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        label="Account holder's identity number"
                        name={`${fullPropertyPrefix}accountHoldersIdNumber`}
                        validate={composeValidators(
                            requiredValidator,
                            conditionalRsaIdNumberValidator(
                                values =>
                                    getValueFromObject(
                                        values,
                                        propertyPrefix,
                                        'accountHoldersIdType'
                                    ) === 'ID'
                            )
                        )}
                    />
                </Grid>
                <ResetStatus prefix={fullPropertyPrefix} property={'accountHoldersIdNumber'} />
                <Grid item xs={6}>
                    <VirtualizedSearchSelectField
                        name={`${fullPropertyPrefix}bankName`}
                        validate={requiredValidator}
                        options={bankNames}
                        matchPos="start"
                    />
                </Grid>
                <ResetStatus prefix={fullPropertyPrefix} property={'bankName'} />
                <Grid item xs={6}>
                    <FullWidthTextField
                        name={`${fullPropertyPrefix}accountNumber`}
                        validate={requiredValidator}
                        mask={NumberMask}
                    />
                </Grid>
                <ResetStatus prefix={fullPropertyPrefix} property={'accountNumber'} />
                <Field
                    name={`${fullPropertyPrefix}bankName`}
                    subscription={{ value: true }}
                    component={({ input: { value: bankName } }) => {
                        const universalBranch = universalBranches.find(
                            uc => uc.bankName === bankName
                        );
                        if (universalBranch) {
                            const currentBranch =
                                branchCodes && branchCodes.find(cb => cb.bankName === bankName);
                            if (!currentBranch) {
                                setBranchCodes([universalBranch]);
                            }
                        } else {
                            getBranches(bankName);
                        }
                        return (
                            <Grid item xs={6}>
                                <VirtualizedSearchSelectField
                                    name={`${fullPropertyPrefix}branchCode`}
                                    options={branchCodes}
                                    label="Branch Name"
                                    validate={requiredValidator}
                                    disabled={universalBranch && branchCodes.length === 1}
                                    matchPos="start"
                                />
                            </Grid>
                        );
                    }}
                />
                <WhenFieldChanges
                    field={`${fullPropertyPrefix}bankName`}
                    set={`${fullPropertyPrefix}branchCode`}
                    to={(bankName: string) => {
                        const universalBranch = universalBranches.find(
                            uc => uc.bankName === bankName
                        );
                        if (universalBranch) {
                            return universalBranch.value;
                        }
                        setLoadBranches(true);
                        return null;
                    }}
                />
                <Grid item xs={6}>
                    <FullWidthTextField
                        name={`${fullPropertyPrefix}branchCode`}
                        validate={requiredValidator}
                        mask={NumberMask}
                        disabled
                    />
                </Grid>
                <ResetStatus prefix={fullPropertyPrefix} property={'branchCode'} />
                <Grid item xs={6}>
                    <FullWidthTextField
                        name={`${fullPropertyPrefix}specialInstructions`}
                        label="Special Instructions"
                        multiline
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthRadioGroup
                        name={`${fullPropertyPrefix}accountType`}
                        validate={requiredValidator}
                    >
                        <RadioOption value="Current" />
                        <RadioOption value="Savings" />
                        <RadioOption value="Transmission" />
                    </FullWidthRadioGroup>
                </Grid>
                <ResetStatus prefix={fullPropertyPrefix} property={'accountType'} />
                {!isAnOrganisation && (
                    <React.Fragment>
                        <Grid item xs={6}>
                            <FullWidthRadioGroup
                                name={`${fullPropertyPrefix}accountHolderRelationship`}
                                validate={requiredValidator}
                            >
                                <RadioOption value="Own" />
                                <RadioOption value="Joint" />
                                <RadioOption value="Other" />
                            </FullWidthRadioGroup>
                        </Grid>
                        <WhenFieldChanges
                            field={`${fullPropertyPrefix}accountHolderRelationship`}
                            condition={value => value !== 'Other'}
                            set={`${fullPropertyPrefix}accountHolderRelationshipDescription`}
                            to={null}
                        />
                        <Condition
                            when={`${fullPropertyPrefix}accountHolderRelationship`}
                            is="Other"
                        >
                            <True>
                                <Grid item xs={6}>
                                    <FullWidthTextField
                                        name={`${fullPropertyPrefix}accountHolderRelationshipDescription`}
                                        validate={requiredValidator}
                                    />
                                </Grid>
                            </True>
                        </Condition>
                    </React.Fragment>
                )}
                <Grid item xs={6}>
                    <FullWidthRadioGroup name={`${fullPropertyPrefix}bankAccountStatus`}>
                        <RadioOption value="Verified" disabled />
                        <RadioOption value="Not verified" disabled />
                    </FullWidthRadioGroup>
                </Grid>
            </Grid>
        );
    }
);

interface ResetProps {
    prefix: string;
    property: string;
}

const ResetStatus = ({ property, prefix }: ResetProps) => {
    return (
        <React.Fragment>
            <WhenFieldChanges
                field={`${prefix}${property}`}
                set={`${prefix}bankAccountStatus`}
                to="Not verified"
            />
            <WhenFieldChanges
                field={`${prefix}${property}`}
                set={`${prefix}bankResponse`}
                to={null}
            />
        </React.Fragment>
    );
};

export const BankingDetailsView = withStyles(styles)(bankingDetailsView);
