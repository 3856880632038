import React from 'react'; // eslint-disable-line

import { Grid, Theme, Typography, withStyles } from '@material-ui/core';

import {
    Condition,
    False,
    FullWidthDatePicker,
    FullWidthRadioGroup,
    FullWidthTextField,
    RadioOption,
    True,
} from '../../forms/';

interface Props {
    classes: {
        spacer: string;
    };
}
function styles(theme: Theme) {
    return {
        spacer: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
    };
}
const lifeAssuredCriteria: React.FunctionComponent<Props> = ({ classes }: Props) => {
    return (
        <React.Fragment>
            <Typography variant="body2" className={classes.spacer}>
                <i>
                    Note: You can search for a policy by RSA ID/Passport or a combination of the
                    below options.
                </i>
            </Typography>
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={6}>
                    <FullWidthRadioGroup name="idType" label="Life Assured Details">
                        <RadioOption value="ID Number" />
                        <RadioOption value="Passport Number" />
                    </FullWidthRadioGroup>
                </Grid>
                <Grid item xs={6}>
                    <Condition when="idType">
                        <True>
                            <FullWidthTextField
                                name="idNumber"
                                label={
                                    <Condition when="idType" is="Passport Number">
                                        <True>Passport Number</True>
                                        <False>ID Number</False>
                                    </Condition>
                                }
                            />
                        </True>
                    </Condition>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2">AND / OR</Typography>
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField name="firstName" label="Name" />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField name="lastName" label="Surname" />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthDatePicker name="dateOfBirth" disableFuture />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export const LifeAssuredCriteria = withStyles(styles)(lifeAssuredCriteria);
