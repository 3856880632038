import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getClaim } from '../../../../../../claim';
import {
    getLifeAssuredDetailsFromClaim,
    LifeAssuredDetails,
} from '../../../../../../life-assured-details/reducer';
import { State } from '../../../../../../redux/root-reducer';
import { getFullName } from '../../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { getRequirementsFromClaim } from '../../../../common/requirements-management/reducer';
import {
    AddDocumentButton,
    EditDocumentsButton,
    RequiredDocumentsView,
} from '../../../../common/requirements-management/views';
import {
    addAndSubmitLifeAssureDocumentAction,
    AddLifeAssureDocumentRecord,
} from '../../action/add-life-assured-documents';
import { FuneralRequirements } from '../../reducer';
import { DocumentOwnerType } from '../../../../../../shared-types';

interface DispatchProps {
    addDocument: AddLifeAssureDocumentRecord;
}
interface StateProps {
    requirements: FuneralRequirements;
    lifeAssured: LifeAssuredDetails;
}

type Props = StyledComponent & DispatchProps & StateProps;
const lifeAssuredRequirementsView: React.FunctionComponent<Props> = ({
    addDocument,
    classes,
    requirements,
    lifeAssured,
}: Props) => {
    const documents = (requirements.lifeAssured && requirements.lifeAssured.documents) || [];
    const laName = getFullName(lifeAssured.personalDetails);
    const prefix = 'lifeAssured.documents';

    return (
        <Grid container justify="flex-start" className={classes.marginTop}>
            <Grid item xs={8} lg={10}>
                <Typography className={classes.heading} variant="body1">
                    Life Assured requirements | {laName}
                </Typography>
            </Grid>
            <Grid item xs={4} lg={2} className={classes.rightAlign}>
                <EditDocumentsButton documents={documents} name={laName} prefix={prefix} />
                <AddDocumentButton
                    addAction={addDocument}
                    documentOwnerType={DocumentOwnerType.LifeAssured}
                    documents={documents}
                />
            </Grid>
            <RequiredDocumentsView documents={documents} />
        </Grid>
    );
};

const mapStateToProps: MapStateToProps<StateProps, Record<string, any>, State> = (state: State) => {
    const claim = getClaim(state);
    return {
        requirements: getRequirementsFromClaim(claim),
        lifeAssured: getLifeAssuredDetailsFromClaim(claim),
    };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, Record<string, any>> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    addDocument: options => dispatch(addAndSubmitLifeAssureDocumentAction(options)),
});

export const LifeAssuredRequirementsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(lifeAssuredRequirementsView));
