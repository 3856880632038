import { push as setLocation } from 'connected-react-router';
import { openDialogAction } from '../../confirmation-dialog';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { FormSubmissionState } from '../reducers/fom-submission-reducer';
import { WorkflowScreen } from '../reducers/workflow-screen';

type NavigateToScreenReturn = any;
export type NavigateToScreen = (
    screen: WorkflowScreen,
    formSubmissionState: FormSubmissionState,
    force?: boolean
) => NavigateToScreenReturn;
export type NavigateToScreenThunk = (
    screen: WorkflowScreen,
    formSubmissionState: FormSubmissionState,
    force?: boolean
) => ReduxThunkAction<NavigateToScreenReturn>;
export const navigateToScreenThunk: NavigateToScreenThunk = (
    screen: WorkflowScreen,
    formSubmissionState: FormSubmissionState,
    force?: boolean
) => {
    return dispatch => {
        if (!screen) {
            return;
        }

        const navigate = () => dispatch(setLocation(screen.path()));
        if (
            force ||
            !formSubmissionState ||
            !formSubmissionState.onSubmit ||
            !formSubmissionState.isPristine ||
            formSubmissionState.isPristine()
        ) {
            navigate();
            return;
        }

        const saveAndNavigate = async () => {
            const shouldNavigate =
                !!formSubmissionState &&
                !!formSubmissionState.onSubmit &&
                !(await formSubmissionState.onSubmit());
            if (shouldNavigate) {
                navigate();
            }
        };

        dispatch(
            openDialogAction({
                content: {
                    title: 'Warning Unsaved Changes',
                    body: 'Current changes will be lost, would you like to save them?',
                },
                yesAction: { action: saveAndNavigate },
                noAction: { action: navigate },
                canCancel: true,
            })
        );
    };
};
