import React from 'react'; // eslint-disable-line
import { Grid, Paper, withStyles } from '@material-ui/core';
import {
    FormHeading,
    FullWidthTextField,
    Condition,
    True,
    FullWidthSearchSelectField,
    False,
    FullWidthDatePicker,
} from '../../../../forms';
import styles, { StyledComponent } from '../../../../styles/styles';
import { BeneficiaryTypes } from '../../../../codes/beneficiary-types';
import { Code } from '../../../../codes/codes';
import { requiredValidator } from '../../../../forms/validations';
import {
    NaturalRelationships,
    OrganisationRelationships,
} from '../../../../codes/relationship-types';

interface Props extends StyledComponent {
    claimants: Code<string>[];
}
const beneficiaryDetailsView: React.FunctionComponent<Props> = ({ classes, claimants }: Props) => {
    return (
        <Paper className={classes.paper}>
            <FormHeading text="Beneficiary Details" />
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={6}>
                    <FullWidthSearchSelectField
                        name="beneficiaryType"
                        options={BeneficiaryTypes}
                        disabled
                    />
                </Grid>
                <Condition when="beneficiaryType" is="Organisation">
                    <True>
                        <Grid item xs={6}>
                            <FullWidthSearchSelectField
                                name="linkToClaimant"
                                options={claimants}
                                validate={requiredValidator}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name={`organisationDetails.companyName`}
                                label="Entity Name"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthSearchSelectField
                                name={`organisationDetails.relationshipToLifeAssured`}
                                options={OrganisationRelationships}
                                disabled
                            />
                        </Grid>
                        <Condition
                            when={`organisationDetails.relationshipToLifeAssured`}
                            is="Other"
                        >
                            <True>
                                <Grid item xs={6}>
                                    <FullWidthTextField
                                        name={`organisationDetails.relationshipToLifeAssuredDescription`}
                                        disabled
                                    />
                                </Grid>
                            </True>
                        </Condition>
                    </True>
                    <False>
                        <Grid item xs={6}>
                            <FullWidthSearchSelectField
                                name="linkToClaimant"
                                options={claimants}
                                validate={requiredValidator}
                            />
                        </Grid>
                        <Condition when="beneficiaryType" is="Estate">
                            <True>
                                <Grid item xs={6}>
                                    <FullWidthTextField
                                        name="personalDetails.firstName"
                                        label="Name"
                                        disabled
                                    />
                                </Grid>
                            </True>
                            <False>
                                <Grid item xs={6}>
                                    <FullWidthTextField name="personalDetails.firstName" disabled />
                                </Grid>
                                <Grid item xs={6}>
                                    <FullWidthSearchSelectField
                                        name="personalDetails.relationshipToLifeAssured"
                                        options={NaturalRelationships}
                                        disabled
                                    />
                                </Grid>
                                <Condition
                                    when="personalDetails.relationshipToLifeAssured"
                                    is="Other"
                                >
                                    <True>
                                        <Grid item xs={6}>
                                            <FullWidthTextField
                                                name="personalDetails.relationshipToLifeAssuredDescription"
                                                disabled
                                            />
                                        </Grid>
                                    </True>
                                </Condition>
                            </False>
                        </Condition>
                    </False>
                </Condition>
                <Grid item xs={6}>
                    <FullWidthTextField name="percentageSplit" label="% split" disabled />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthDatePicker name="nominatedDate" disableFuture />
                </Grid>
            </Grid>
        </Paper>
    );
};

export const BeneficiaryDetailsView = withStyles(styles)(beneficiaryDetailsView);
