import React from 'react'; // eslint-disable-line

import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    withStyles,
} from '@material-ui/core';
import { ChangeFieldSet } from '../claim-audit-trail-functions';
import styles, { StyledComponent } from '../styles';
import { GetDecoratedAuditTrailValue } from '../shared/field-whitelist';

interface Props extends StyledComponent {
    readonly auditTrailRecords: ChangeFieldSet[];
    readonly subSection?: string[];
}

interface CellRecord {
    readonly current?: string;
    readonly name?: React.ReactNode;
    readonly previous?: string;
}

const auditTrailDetails: React.FunctionComponent<Props> = ({
    auditTrailRecords,
    classes,
    subSection,
}: StyledComponent & Props) => {
    const cellHeadings: CellRecord = {
        name: 'Field',
        current: 'Latest Update',
        previous: 'Previous',
    };

    return (
        <Grid item xs={12} style={{ overflow: 'auto' }}>
            {subSection && subSection.length > 0 ? (
                <React.Fragment>
                    {subSection.map((heading, index) => {
                        return (
                            <Typography variant="subtitle2" key={`${heading}${index}`}>
                                {heading}
                            </Typography>
                        );
                    })}
                </React.Fragment>
            ) : (
                ''
            )}
            <Table style={{ tableLayout: 'fixed' }}>
                <TableHead>{renderTableRow(classes.cellWithColor, cellHeadings)}</TableHead>
                <TableBody>
                    {auditTrailRecords.map((auditRow, index) => {
                        const auditRecord: CellRecord = {
                            name: auditRow.fieldName,
                            current: GetDecoratedAuditTrailValue(
                                auditRow.fieldName,
                                auditRow.currentValue
                            ),
                            previous: GetDecoratedAuditTrailValue(
                                auditRow.fieldName,
                                auditRow.previousValue
                            ),
                        };
                        const useClass =
                            index === auditTrailRecords.length - 1
                                ? classes.cellNoBottomBorder
                                : classes.cellWithColor;
                        return (
                            <React.Fragment key={index}>
                                {renderTableRow(useClass, auditRecord)}
                            </React.Fragment>
                        );
                    })}
                </TableBody>
            </Table>
        </Grid>
    );
};

function renderTableRow(cellClass: string, cellRecord: CellRecord) {
    return (
        <TableRow>
            <TableCell className={cellClass}>{cellRecord.name}</TableCell>
            <TableCell
                className={cellClass}
                dangerouslySetInnerHTML={{
                    __html: cellRecord.previous ? cellRecord.previous : '-',
                }}
            ></TableCell>
            <TableCell
                className={cellClass}
                dangerouslySetInnerHTML={{ __html: cellRecord.current ? cellRecord.current : '-' }}
            ></TableCell>
        </TableRow>
    );
}

export function addAuditTrailDetailsView(
    returnView: React.ReactNode[],
    record: ChangeFieldSet[],
    section?: string[]
) {
    if (record) {
        returnView.push(<AuditTrailDetails subSection={section} auditTrailRecords={record} />);
    }
}

export const AuditTrailDetails = withStyles(styles)(auditTrailDetails);
