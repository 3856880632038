import { Reducer, AnyAction } from 'redux';
import { Nullable } from '../../../../shared-types';
import { combineReducersWithDefault } from '../../../../redux/combine-reducers-with-default';
import { getPropertyFromClaim, Claim, getClaim } from '../../../../claim';
import {
    DeathClaimDetails,
    FinancialDetails,
    DeathClaimNotes,
    claimNotesReducer,
} from '../../death/claim-event-details/reducer';
import { State } from '../../../../redux/root-reducer';
import { VOPD, vopdReducer } from '../../common/vopd/reducer';

export interface RAClaimDetails {
    readonly claimEventDetails: DeathClaimDetails;
    readonly financialDetails: FinancialDetails;
    readonly claimNotes: DeathClaimNotes[];
    readonly vopd: VOPD;
}

export const defaultRAClaimDetails: RAClaimDetails = {
    claimEventDetails: {},
    financialDetails: {},
    claimNotes: [],
    vopd: {},
};

export const claimEventDetailsReducer: Reducer = (
    state: Nullable<DeathClaimDetails> = null,
    action: AnyAction
) => {
    return state;
};

export const financialDetailsReducer: Reducer = (
    state: Nullable<FinancialDetails> = null,
    action: AnyAction
) => {
    return state;
};

export const raClaimDetailsReducer = combineReducersWithDefault<RAClaimDetails>(
    defaultRAClaimDetails,
    {
        claimEventDetails: claimEventDetailsReducer,
        financialDetails: financialDetailsReducer,
        claimNotes: claimNotesReducer,
        vopd: vopdReducer,
    }
);

export function getRAClaimDetailsFromClaim(claim: Claim): RAClaimDetails {
    return getPropertyFromClaim(claim, 'claimDetails', defaultRAClaimDetails);
}

export function getRaDeathClaimDetails(state: State) {
    return getPropertyFromClaim(getClaim(state), 'claimDetails', defaultRAClaimDetails);
}
