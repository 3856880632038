import { Claim, getClaim, getPropertyFromClaim } from '../../../../claim';
import { collectionReducer } from '../../../../redux/collection-reducer';
import { combineReducersWithDefault } from '../../../../redux/combine-reducers-with-default';
import { State } from '../../../../redux/root-reducer';
import { Nullable } from '../../../../shared-types';
import { DeathClaimNotes } from '../../death/claim-event-details/reducer';
import { Doctor } from '../../death/life-assured-details/reducer';
import {
    ADD_CURRENT_DOCTOR_RECORD,
    ADD_NOTES_RECORD,
    ADD_PREVIOUS_DOCTOR_RECORD,
    DELETE_CURRENT_DOCTOR_RECORD,
    DELETE_PREVIOUS_DOCTOR_RECORD,
    UPDATE_CURRENT_DOCTOR_RECORD,
    UPDATE_NOTES_RECORD,
    UPDATE_PREVIOUS_DOCTOR_RECORD,
} from './types';
export interface Doctors extends Doctor {
    readonly dateOfConsultation?: Date;
    readonly reasonForConsultation?: string;
}
export interface CommonMedicalHistory {
    currentMedicalHistory: Doctors[];
    previousMedicalHistory: Doctors[];
    claimNotes: DeathClaimNotes[];
}

export const defaultMedicalHistoryDetails: CommonMedicalHistory = {
    currentMedicalHistory: [],
    previousMedicalHistory: [],
    claimNotes: [],
};

export const currentMedicalConsultationReducer = collectionReducer<Doctors>({
    addAction: ADD_CURRENT_DOCTOR_RECORD,
    updateAction: UPDATE_CURRENT_DOCTOR_RECORD,
    deleteAction: DELETE_CURRENT_DOCTOR_RECORD,
});
export const previousMedicalConsultationReducer = collectionReducer<Doctors>({
    addAction: ADD_PREVIOUS_DOCTOR_RECORD,
    updateAction: UPDATE_PREVIOUS_DOCTOR_RECORD,
    deleteAction: DELETE_PREVIOUS_DOCTOR_RECORD,
});

export const claimNotesReducer = collectionReducer<DeathClaimNotes>({
    addAction: ADD_NOTES_RECORD,
    updateAction: UPDATE_NOTES_RECORD,
});

export const commonMedicalHistoryReducer = combineReducersWithDefault<
    Nullable<CommonMedicalHistory>
>(defaultMedicalHistoryDetails, {
    currentMedicalHistory: currentMedicalConsultationReducer,
    previousMedicalHistory: previousMedicalConsultationReducer,
    claimNotes: claimNotesReducer,
});
export function getMedicalHistory(state: State): CommonMedicalHistory {
    return getMedicalHistoryFromClaim(getClaim(state));
}

export function getMedicalHistoryFromClaim(claim: Claim): CommonMedicalHistory {
    return getPropertyFromClaim(claim, 'commonMedicalHistory', defaultMedicalHistoryDetails);
}
