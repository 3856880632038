import React from 'react'; // eslint-disable-line

import { Button, Grid, Paper, Tooltip, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { EditFormModal } from '../../../../form-modal';
import { OpenEditModal, openEditModalAction } from '../../../../form-modal/actions';
import { State } from '../../../../redux/root-reducer';
import {
    UpdateAndSubmitReinsurerReferalRecord,
    updateAndSubmitReinsurerReferalRecordThunk,
} from '../../../../reinsurance-input/actions/submit-reinsurance-input';
import {
    getReinsuranceInputDetails,
    ReinsuranceInputDetails,
    ReinsurerReferal,
} from '../../../../reinsurance-input/reducer';
import { ReinsurerReferalDetailsView } from '../../../../reinsurance-input/views/reinsurer-referal-details';
import styles, { StyledComponent } from '../../../../styles/styles';
import { getDisplayDate, getReasonForReferralDescription } from '../../../../shared-functions';
import { FormSpy, FormSpyRenderProps } from 'react-final-form';
import { LabeledValue } from '../../../../controls/labeled-value';
import { ButtonWrapper } from '../../../../forms';

interface DispatchProps {
    openEditModal: OpenEditModal;
    updateRecord: UpdateAndSubmitReinsurerReferalRecord;
}
interface OwnProps {
    index?: number;
    reinsurerReferral: ReinsurerReferal;
}
interface StateProps {
    reinsuranceInputDetails: ReinsuranceInputDetails;
    isReadOnly: boolean;
}

type Props = DispatchProps & OwnProps & StateProps & StyledComponent;

const ReinsurerReferalEditFormModal = EditFormModal<ReinsurerReferal>();
const reinsurerReferralView: React.FunctionComponent<Props> = ({
    classes,
    index,
    openEditModal,
    reinsuranceInputDetails,
    reinsurerReferral,
    updateRecord,
}: Props) => {
    return (
        <Paper className={classes.paper}>
            <Grid container spacing={2} justify="flex-start">
                <Grid item xs={12}>
                    <Grid container spacing={0} justify="space-between">
                        <Grid container spacing={0} item xs={10} lg={11}>
                            <Grid item xs={3} lg={2}>
                                <LabeledValue
                                    label="Date Referred"
                                    value={getDisplayDate(reinsurerReferral.dateReferred)}
                                />
                            </Grid>
                            <Grid item xs={9} lg={10}>
                                <LabeledValue
                                    label="Reason for Referral"
                                    value={getReasonForReferralDescription(
                                        reinsurerReferral.reasonForReferral
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <ButtonWrapper>
                            <Grid item xs={2} lg={1} className={classes.rightAlign}>
                                <Tooltip title="Edit Reinsurer Referral">
                                    <Button
                                        id="EditReinsurerReferral"
                                        color="secondary"
                                        onClick={() => addEdit(index)}
                                    >
                                        <Edit fontSize="small" />
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </ButtonWrapper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={0} justify="space-between">
                        <Grid container spacing={0} item xs={10} lg={11}>
                            <Grid item xs={3} lg={2}>
                                <LabeledValue
                                    label="Decision Date"
                                    value={getDisplayDate(reinsurerReferral.dateOfDecision)}
                                />
                            </Grid>
                            <Grid item xs={9} lg={10}>
                                <LabeledValue
                                    label="Reinsurance Decision"
                                    value={reinsurerReferral.reinsurersDecision}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );

    function addEdit(editIndex?: number) {
        const recordIndex = editIndex || 0;
        openEditModal({
            modalContent: (
                <ReinsurerReferalEditFormModal
                    recordDetailView={() => FormSpy<ReinsurerReferal>({ render: RecordDetailView })}
                    records={reinsuranceInputDetails.reinsurerReferrals}
                    updateRecord={(record, index) => updateRecord(index, record)}
                    canUpdateRecord={canUpdateReinsurerReferal}
                />
            ),
            modalData: {
                recordIndex,
            },
        });
    }

    function RecordDetailView({ initialValues }: FormSpyRenderProps<ReinsurerReferal>) {
        return <ReinsurerReferalDetailsView initialValues={initialValues} />;
    }

    function canUpdateReinsurerReferal(reinsurerReferal: ReinsurerReferal) {
        return (
            !reinsurerReferal.dateReferred ||
            !reinsurerReferal.dateOfDecision ||
            !reinsurerReferal.reinsurersDecision
        );
    }
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    reinsuranceInputDetails: getReinsuranceInputDetails(state),
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    updateRecord: (index: number, reinsurerReferral: ReinsurerReferal) =>
        dispatch(updateAndSubmitReinsurerReferalRecordThunk(index, reinsurerReferral)),
    openEditModal: options => dispatch(openEditModalAction(options)),
});

export const ReinsurerReferralView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(reinsurerReferralView));
