import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { FullWidthRadioGroup, FullWidthTextField, RadioOption } from '../../../../../../forms';
import styles, { StyledComponent } from '../../../../../../styles/styles';

const occupationUpdateDetailsView: React.FunctionComponent<StyledComponent> = ({
    classes,
}: StyledComponent) => {
    return (
        <React.Fragment>
            <Typography variant="subtitle2">Occupation Details</Typography>
            <Paper className={classes.paper}>
                <Grid container spacing={5} justify="flex-start">
                    <Grid item xs={6}>
                        <FullWidthTextField
                            label="Occupation at application stage"
                            name="occupationAtApplication"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthRadioGroup
                            label="Was the deceased employed at the date of death?"
                            name="isDeceasedEmployed"
                        >
                            <RadioOption value="Yes" />
                            <RadioOption value="No" />
                        </FullWidthRadioGroup>
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            label="Please state the deceased occupation at date of death"
                            name="deceasedOccupation"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            label="Please state the deceased employer at date of death"
                            name="deceasedEmployer"
                        />
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    );
};
export const OccupationUpdateDetailsView = withStyles(styles)(occupationUpdateDetailsView);
