import { withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { GenericForm } from '../../../../../../forms';
import { State } from '../../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { OnSubmitSuccessProps } from '../../../../../../workflow-navigation';
import {
    UpdateOccupationDetails,
    updateOccupationDetailsAction,
} from '../../actions/update-occupation-details';
import { getOccupationDetails, OccupationDetails } from '../../reducer';
import { OccupationUpdateDetailsView } from './occupation-update-details';

interface Props extends OnSubmitSuccessProps, StyledComponent {
    readonly occupationDetails: OccupationDetails;
    updateOccupationDetails: UpdateOccupationDetails;
}

const OccupationDetailsForm = GenericForm<OccupationDetails>();
const occupationDetailsEditModal: React.FunctionComponent<Props> = ({
    occupationDetails,
    updateOccupationDetails,
    ...rest
}: Props) => {
    const onSubmit = (occupationDetails: OccupationDetails) => {
        return updateOccupationDetails(occupationDetails);
    };
    return (
        <OccupationDetailsForm onSubmit={onSubmit} initialValues={occupationDetails} {...rest}>
            <OccupationUpdateDetailsView />
        </OccupationDetailsForm>
    );
};

const mapStateToProps = (state: State) => ({
    occupationDetails: getOccupationDetails(state),
});

const mapDispatchToProps = {
    updateOccupationDetails: updateOccupationDetailsAction,
};

export const OccupationDetailsEditModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(occupationDetailsEditModal));
