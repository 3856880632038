import { Claim, claimSubmit } from '../../../../../claim';
import { reduceAndSubmitClaim } from '../../../../../claim/actions/reduce-and-submit-claim';
import { DeleteRecordCollectionAction } from '../../../../../redux/collection-reducer';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { FormDetails } from '../form-details-reducer';
import { DELETE_FUNERAL_CLAIMANT } from './types';

type UpdateFormDetailsReturn = Promise<any>;
export type UpdateFormDetails = (formDetails: FormDetails) => UpdateFormDetailsReturn;
type UpdateFormDetailsAction = (
    formDetails: FormDetails
) => ReduxThunkAction<UpdateFormDetailsReturn>;

export const updateFormDetailsAction: UpdateFormDetailsAction = (formDetails: FormDetails) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, getUpdatedClaim(formDetails));
    };
};

function getUpdatedClaim(formDetails: FormDetails): (claim: Claim) => Claim {
    return (claim: Claim) => {
        return {
            ...claim,
            formDetails,
        };
    };
}

export type DeleteClaimantAction = DeleteRecordCollectionAction;
export const deleteClaimantActionCreator = (claimantIndex: number): DeleteClaimantAction => {
    return {
        type: DELETE_FUNERAL_CLAIMANT,
        index: claimantIndex,
    };
};
/* eslint-disable-next-line */
type SubmitReturn = Promise<any>;
export type DeleteClaimant = (claimantIndex: number) => SubmitReturn;
type DeleteClaimantThunk = (ClaimantIndex: number) => ReduxThunkAction<SubmitReturn>;
export const deleteClaimantThunk: DeleteClaimantThunk = (claimantIndex: number) => (
    dispatch,
    getState
) => reduceAndSubmitClaim(dispatch, getState, deleteClaimantActionCreator(claimantIndex));
