export const IdDocumentTypes = [
    { value: 'RsaId', label: 'South African barcoded green ID book' },
    { value: 'RsaIdSmartCard', label: 'South African ID Smart Card' },
    { value: 'RsaIdDrivingLicence', label: 'South African Driving Licence' },
    { value: 'RsaIdTemporaryId', label: 'South African Temporary ID' },
    { value: 'RsaIdBirthCertificate', label: 'South African Birth Certificate' },
    { value: 'RsaPassport', label: 'South African Passport' },
    { value: 'ForeignPassport', label: 'Foreign Passport' },
    { value: 'ForeignIdBook', label: 'Foreign Identity Book' },
];

export const Passports = ['Passport', 'RsaPassport', 'ForeignPassport'];
export const TemporaryIds = ['RsaIdTemporaryId', 'WorkPermit', 'Asylum'];

export const SouthAfricanIds = [
    'RsaId',
    'RsaIdSmartCard',
    'RsaIdDrivingLicence',
    'RsaIdTemporaryId',
    'RsaIdBirthCertificate',
];
