import { AnyAction } from 'redux';
import { ClaimantRequirements, Requirements } from '../../common/requirements-management/reducer';

export interface FuneralRequirements extends Requirements {
    policyOwner?: ClaimantRequirements;
    claimants?: ClaimantRequirements[];
}

export const defaultFuneralRequirements: FuneralRequirements = {
    lifeAssured: {
        documents: [],
    },
};

export function funeralRequirementsReducer(
    state: FuneralRequirements = defaultFuneralRequirements,
    action: AnyAction
) {
    switch (action.type) {
        default:
            return state;
    }
}
