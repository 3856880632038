import React from 'react'; // eslint-disable-line

import { Typography, withStyles } from '@material-ui/core';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { AssessmentDetails } from '../funeral-assessment-details-reducer';
import { AddFuneralAssessmentDecisionProps } from './assessment-decision-add';
import { BenefitAssessmentDetailsView } from './benefit-assessment-details';

interface OwnProps {
    assessmentDetails: AssessmentDetails[];
    assessmentDecisionAddView:
        | React.FunctionComponent<AddFuneralAssessmentDecisionProps>
        | React.ComponentClass<AddFuneralAssessmentDecisionProps>;
}

interface StateProps {}

type Props = OwnProps & StateProps & StyledComponent;
const assessmentDetailsView: React.FunctionComponent<Props> = ({
    assessmentDetails,
    classes,
    assessmentDecisionAddView,
}: Props) => {
    return (
        <React.Fragment>
            {assessmentDetails &&
                assessmentDetails.map(assessmentDetail => (
                    <React.Fragment key={assessmentDetail.policyNumber}>
                        <Typography
                            variant="subtitle2"
                            className={`${classes.marginTop} ${classes.marginBottom}`}
                        >
                            Policy Number - {assessmentDetail.policyNumber}
                        </Typography>

                        {assessmentDetail.benefits &&
                            assessmentDetail.benefits.map(benefitAssessmentDetails => (
                                <BenefitAssessmentDetailsView
                                    key={benefitAssessmentDetails.benefitName}
                                    policyNumber={assessmentDetail.policyNumber}
                                    assessmentDecisionAddView={assessmentDecisionAddView}
                                    benefit={benefitAssessmentDetails}
                                />
                            ))}
                    </React.Fragment>
                ))}
        </React.Fragment>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = () => ({});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps: MapDispatchToProps<Record<string, any>, OwnProps> = {};

export const AssessmentDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(assessmentDetailsView));
