import * as React from 'react';  // eslint-disable-line
import { withStyles, Modal, Grid, Typography, Button } from '@material-ui/core';
import { connect, MapDispatchToProps } from 'react-redux';
import { State } from '../../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import libertyLogo from '../../../../../../resources/Liberty_Logo_Horizontal_White.svg';
import { AuthState } from '../../../../../../auth/auth-reducer';
import { viewModalAction } from '../../../../../../confirmation-dialog';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getDisplayNameFromAdName } from '../../../../../../shared-functions';
interface DispatchProps {
    closeModal?: any;
}

type Props = StyledComponent &
    DispatchProps & {
        modal?: any;
        currentUser?: any;
        classes: any;
        modalBody: any;
    };

const communicationsPreviewEmailView: React.FunctionComponent<Props> = ({
    modal,
    currentUser,
    classes,
    closeModal,
    modalBody,
}: Props) => {
    return (
        <div>
            <Modal
                open={modal.isOpenModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Grid
                    item
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        padding: 10,
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        backgroundColor: '#ffffff',
                        height: '95vh',
                        minHeight: 400,
                        overflowX: 'hidden',
                        overflowY: 'scroll',
                        maxHeight: 750,
                    }}
                >
                    <Grid container>
                        <Grid
                            container
                            item
                            xs={12}
                            style={{ backgroundColor: '#0D1832', marginBottom: 40, height: 100 }}
                        >
                            <Grid
                                direction="row"
                                justify="flex-end"
                                item
                                container
                                style={{ paddingTop: 20, paddingRight: 20 }}
                            >
                                <img
                                    src={libertyLogo}
                                    height={60}
                                    alt="liberty-flame-logo"
                                    className={classes.logo}
                                />
                            </Grid>
                        </Grid>
                        {modalBody()}
                        <Typography>
                            <br />
                            We are always #Initwithyou
                            <br /> <br />
                            Kind Regards
                            <br></br>
                            {getDisplayNameFromAdName(currentUser.name)}
                            <br></br>
                            Funeral claims department
                            <br></br>
                            Liberty Group Limited
                        </Typography>
                    </Grid>
                    <Grid container spacing={0} justify="flex-end">
                        <Button
                            id="Close"
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={() => closeModal({ isOpen: false, form: modal.form })}
                        >
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </Modal>
        </div>
    );
};

const mapDispatchToProps: MapDispatchToProps<any, any> = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    closeModal: (options: any) => dispatch(viewModalAction(options)),
});
const mapStateToProps = (state: State) => ({
    modal: state.confirmationDialog,
    currentUser: state.auth as AuthState,
});

export const CommunicationsPreviewEmail = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(communicationsPreviewEmailView));
