import React from 'react'; // eslint-disable-line
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { getClaim } from '../../../../../claim';
import { FormErrors, GenericForm } from '../../../../../forms';
import {
    ExtendedPersonalDetails,
    UpdatePersonalDetails,
    updatePersonalDetailsAction,
} from '../../../../../life-assured-details/actions/update-personal-details';
import {
    getLifeAssuredDetails,
    PersonalDetails,
    MedicalAidDetails,
} from '../../../../../life-assured-details/reducer';
import { MedicalAidDetailsView } from '../../../../../life-assured-details/views/life-assured-medical-aid-details';
import { PersonalDetailsView } from '../../../../../life-assured-details/views/life-assured-personal-details';
import { State } from '../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { OnSubmitSuccessProps } from '../../../../../workflow-navigation';
import { getDeathClaimEventDetailsFromClaim } from '../../../death/claim-event-details/reducer';
import { getFormDetails } from '../../../funeral/form-details/form-details-reducer';
import { FormDetails } from '../../../living-lifestyle/form-details/form-details-reducer';

interface Props extends OnSubmitSuccessProps, StyledComponent {
    readonly dateOfDeath?: Date;
    readonly formDetails?: FormDetails;
    readonly medicalAidDetails: MedicalAidDetails;
    readonly personalDetails: PersonalDetails;
    readonly updatePersonalDetails: UpdatePersonalDetails;
}

const PersonalDetailsForm = GenericForm<{
    medicalAidDetails: any; //MedicalAidDetails;
    personalDetails: ExtendedPersonalDetails;
}>();

const personalDetailsEditModal: React.FunctionComponent<Props> = ({
    dateOfDeath,
    formDetails,
    medicalAidDetails,
    personalDetails,
    updatePersonalDetails,
    ...rest
}: Props) => {
    const extendedPersonalDetails = {
        ...personalDetails,
        dateOfDeath: dateOfDeath,
    };
    let formMedicalAidDetails;

    if (formDetails?.lifeAssured) {
        formMedicalAidDetails = {
            isLifeAssuredTheMainMember:
                formDetails?.lifeAssured.medicalAidDetails?.isLifeAssuredMainMember,
            mainMembersTitle: formDetails?.lifeAssured.medicalAidDetails?.lifeAssuredTitle,
            mainMembersFirstName: formDetails?.lifeAssured.medicalAidDetails?.lifeAssuredName,
            mainMembersLastName: formDetails?.lifeAssured.medicalAidDetails?.lifeAssuredSurname,
            mainMembersIdNumber: formDetails?.lifeAssured.medicalAidDetails?.lifeAssuredIdNumber,
            dateJoined: formDetails?.lifeAssured.medicalAidDetails?.lifeAssuredMedicalDateJoined,
            medicalAidName: formDetails?.lifeAssured.medicalAidDetails?.lifeAssuredMedicalAidName,
            medicalAidNumber:
                formDetails?.lifeAssured.medicalAidDetails?.lifeAssuredMedicalAidNumber,
            medicalAidDescription:
                formDetails?.lifeAssured.medicalAidDetails?.lifeAssuredMedicalAidDescription,
        };
    }

    return (
        <PersonalDetailsForm
            onSubmit={updatePersonalDetails}
            initialValues={{
                medicalAidDetails:
                    medicalAidDetails.isLifeAssuredTheMainMember !== null
                        ? medicalAidDetails
                        : formMedicalAidDetails,
                personalDetails: extendedPersonalDetails,
            }}
            {...rest}
        >
            <PersonalDetailsView />
            <MedicalAidDetailsView />
            <FormErrors />
        </PersonalDetailsForm>
    );
};

const mapStateToProps = (state: State) => {
    const claim = getClaim(state);
    let dateOfDeath = undefined;
    if (claim.claimType === 'Death') {
        const claimEventDetails = getDeathClaimEventDetailsFromClaim(claim);
        dateOfDeath =
            claimEventDetails &&
            claimEventDetails.deathClaimDetails &&
            claimEventDetails.deathClaimDetails.dateOfEvent;
    }

    return {
        dateOfDeath: dateOfDeath,
        medicalAidDetails: getLifeAssuredDetails(state).medicalAidDetails,
        formDetails: getFormDetails(state),
        personalDetails: getLifeAssuredDetails(state).personalDetails,
    };
};

const mapDispatchToProps = {
    updatePersonalDetails: updatePersonalDetailsAction,
};

export const PersonalDetailsEditModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(personalDetailsEditModal));
