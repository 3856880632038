import { AnyAction, Reducer } from 'redux';
import { ReduxAction } from '../../redux/redux-actions';
import { SET_DEFERRED_CONTRACTS } from '../types';

export type DeferredContractsAction = ReduxAction<boolean> | AnyAction;
export const deferredContractsReducer: Reducer<boolean, DeferredContractsAction> = (
    state = true,
    action: DeferredContractsAction
) => {
    if (action.type === SET_DEFERRED_CONTRACTS) {
        return action.payload;
    }
    return state;
};
