export const Titles = [
    { value: 'MR', label: 'Mr' },
    { value: 'MRS', label: 'Mrs' },
    { value: 'MISS', label: 'Miss' },
    { value: 'DR', label: 'Dr' },
    { value: 'PROF', label: 'Prof' },
    { value: 'MS', label: 'Ms' },
    { value: 'ADJUTANT', label: 'Adjutant' },
    { value: 'ADJUTANT GENERAL', label: 'Adjutant General' },
    { value: 'ADMIRAL', label: 'Admiral' },
    { value: 'ADVOCATE', label: 'Advocate' },
    { value: 'BROTHER', label: 'Brother' },
    { value: 'BRIGADIER', label: 'Brigadier' },
    { value: 'BRIGADIER GENERAL', label: 'Brigadier General' },
    { value: 'CAPTAIN', label: 'Captain' },
    { value: 'THE HONOURABLE CHIEF JUSTICE', label: 'The Honourable Chief Justice' },
    { value: 'COMMANDER', label: 'Commander' },
    { value: 'COLONEL', label: 'Colonel' },
    { value: 'COMMANDANT', label: 'Commandant' },
    { value: 'DIRECTOR GENERAL', label: 'Director General' },
    { value: 'HIS EXCELLENCY', label: 'His Excellency' },
    { value: 'HER EXCELLENCY', label: 'Her Excellency' },
    { value: 'FIELD-MARSHALL', label: 'Field-Marshall' },
    { value: 'GENERAL', label: 'General' },
    { value: 'GOVERNOR', label: 'Governor' },
    { value: 'HIS EMINENCE', label: 'His Eminence' },
    { value: 'HIS HIGHNESS', label: 'His Highness' },
    { value: 'HER ROYAL HIGHNESS', label: 'Her Royal Highness' },
    { value: 'HIS ROYAL HIGHNESS', label: 'His Royal Highness' },
    { value: 'HER SERENE HIGHNESS', label: 'Her Serene Highness' },
    { value: 'HIS SERENE HIGHNESS', label: 'His Serene Highness' },
    { value: 'INSPECTOR', label: 'Inspector' },
    { value: 'LANCE-CORPORAL', label: 'Lance-Corporal' },
    { value: 'LIEUTENANT', label: 'Lieutenant' },
    { value: 'LIEUTENANT-COLONEL', label: 'Lieutenant-Colonel' },
    { value: 'LIEUTENANT-COMMANDER', label: 'Lieutenant-Commander' },
    { value: 'LIEUTENANT-GENERAL', label: 'Lieutenant-General' },
    { value: 'LORD', label: 'Lord' },
    { value: 'MAJOR', label: 'Major' },
    { value: 'MAJOR-GENERAL', label: 'Major-General' },
    { value: 'MESDAMES', label: 'Mesdames' },
    { value: 'MESSIEURS', label: 'Messieurs' },
    { value: 'MONSIGNOR', label: 'Monsignor' },
    { value: 'MINISTER', label: 'Minister' },
    { value: 'QUARTERMASTER-GENERAL', label: 'Quartermaster-General' },
    { value: 'QUARTERMASTER-SERGEANT', label: 'Quartermaster-Sergeant' },
    { value: 'RABBI', label: 'Rabbi' },
    { value: 'REAR-ADMIRAL', label: 'Rear-Admiral' },
    { value: 'REVEREND', label: 'Reverend' },
    { value: 'THE RIGHT REVEREND', label: 'The Right Reverend' },
    { value: 'REGIMENTAL SERGEANT-MAJOR', label: 'Regimental Sergeant-Major' },
    { value: 'THE RIGHT HONOURABLE', label: 'The Right Honourable' },
    { value: 'SENATOR', label: 'Senator' },
    { value: 'SERGEANT', label: 'Sergeant' },
    { value: 'SERGEANT-MAJOR', label: 'Sergeant-Major' },
    { value: 'SIR', label: 'Sir' },
    { value: 'THEIR ROYAL HIGHNESSES', label: 'Their Royal Highnesses' },
    { value: 'FATHER', label: 'Father' },
    { value: 'DOCTORS', label: 'Doctors' },
    { value: 'MASTER', label: 'Master' },
    { value: 'SISTER', label: 'Sister' },
    { value: 'REVEREND', label: 'Reverend' },
    { value: 'PASTOR', label: 'Pastor' },
    { value: 'THE HONOURABLE', label: 'The Honourable' },
    { value: 'SUB-LIEUTENANT', label: 'Sub-Lieutenant' },
    { value: 'LADY', label: 'Lady' },
    { value: 'BISHOP', label: 'Bishop' },
    { value: 'COUNT', label: 'Count' },
    { value: 'COUNTESS', label: 'Countess' },
    { value: 'CHIEF', label: 'Chief' },
    { value: 'PARSON', label: 'Parson' },
    { value: 'JUDGE', label: 'Judge' },
    { value: 'BARON', label: 'Baron' },
    { value: 'PARSON', label: 'Parson' },
    { value: 'MISTER & MISSUS', label: 'Mister & Missus' },
    { value: 'WARRANT OFFICER', label: 'Warrant Officer' },
    { value: 'BARONESS', label: 'Baroness' },
    { value: 'VISCOUNT', label: 'Viscount' },
    { value: 'VISCOUNTESS', label: 'Viscountess' },
    { value: 'COMMISSIONER', label: 'Commissioner' },
];
