import { Theme } from '@material-ui/core';

export default function styles(theme: Theme) {
    return {
        paper: {
            padding: theme.spacing(2),
        },
        button: {
            margin: theme.spacing(),
        },
        card: {
            marginBottom: theme.spacing(3),
        },
    };
}

export interface StyledComponent {
    classes: {
        paper: string;
        button: string;
        card: string;
    };
}
