import { AnyAction } from 'redux';
import { ClaimantRequirements, Requirements } from '../../common/requirements-management/reducer';

export interface RADeathRequirements extends Requirements {
    dependants: ClaimantRequirements[];
}

export const defaultRADeathRequirements: RADeathRequirements = {
    lifeAssured: {
        documents: [],
    },
    dependants: [],
};

export function raDeathRequirementsReducer(
    state: RADeathRequirements = defaultRADeathRequirements,
    action: AnyAction
) {
    switch (action.type) {
        default:
            return state;
    }
}
