import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { getClaim } from '../../../../../claim/reducers';
import { State } from '../../../../../redux/root-reducer';
import { ContractDetails } from '../../../../../shared-types';
import styles, { StyledComponent } from '../../../../../styles/styles';

type Props = StyledComponent & {
    contracts: ContractDetails[];
};

const lifeAssuredPortfolio: React.FunctionComponent<Props> = ({ classes, contracts }: Props) => {
    if (!contracts || contracts.length === 0) {
        return null;
    }
    return (
        <Grid container className={classes.marginTop2}>
            <Grid container item xs={12} alignContent="center">
                <Typography variant="subtitle2">Life Assured&apos;s Porfolio</Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">
                            Policy Numbers Linked to Life Assured
                        </Typography>
                    </Grid>
                    {contracts.map((contract, index) => {
                        return (
                            <React.Fragment key={index}>
                                <Typography>
                                    {contract.contractNumber} {' - '}
                                    {contract.role}
                                </Typography>
                            </React.Fragment>
                        );
                    })}
                </Paper>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state: State) => ({
    contracts: getClaim(state).contracts,
});

const mapDispatchToProps = {};

export const LifeAssuredPortfolio = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(lifeAssuredPortfolio));
