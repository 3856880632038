import React from 'react'; // eslint-disable-line

import { Grid, Paper, withStyles } from '@material-ui/core';
import { FormApi } from 'final-form';
import { connect } from 'react-redux';
import {
    Condition,
    FormErrors,
    FormHeading,
    FullWidthRadioGroup,
    GenericForm,
    RadioOption,
    When,
} from '../../forms';
import { requiredValidator } from '../../forms/validations';
import { State } from '../../redux/root-reducer';
import styles, { StyledComponent } from '../../styles/styles';
import { OnSubmitSuccessProps } from '../../workflow-navigation';
import { ClientSearchCriteria } from '../actions/client-search';
import {
    FindClientsByPersonalDetails,
    findClientsByPersonalDetailsAction,
} from '../actions/find-clients-by-personal-details';
import { FindClientsByPolicy, findClientsByPolicyAction } from '../actions/find-clients-by-policy';
import { LifeAssuredCriteria } from './life-assured-criteria';
import { PolicyCriteria } from './policy-criteria';
import { FormSpy } from 'react-final-form';

type Props = OnSubmitSuccessProps &
    StyledComponent & {
        findClientsByPersonalDetails: FindClientsByPersonalDetails;
        findClientsByPolicy: FindClientsByPolicy;
        criteria?: ClientSearchCriteria;
    };

const ClientSearchForm = GenericForm<ClientSearchCriteria>();
const clientSearchView: React.FunctionComponent<Props> = ({
    classes,
    findClientsByPersonalDetails,
    findClientsByPolicy,
    criteria,
    ...rest
}: Props) => {
    const onSubmit = (values: any) => {
        if (values.searchBy === 'Life Assured') {
            return findClientsByPersonalDetails(values);
        }
        if (values.searchBy === 'Policy Number') {
            return findClientsByPolicy(values);
        }
    };
    return (
        <ClientSearchForm onSubmit={onSubmit} initialValues={criteria} {...rest}>
            {() =>
                FormSpy<ClientSearchCriteria>({
                    render: ({ form }) => InnerForm({ classes, form }),
                })
            }
        </ClientSearchForm>
    );
};
type InnerProps = StyledComponent & {
    form: FormApi<ClientSearchCriteria>;
};

const InnerForm: React.FunctionComponent<InnerProps> = ({ classes, form }: InnerProps) => {
    const reset = form ? form.reset : () => null;
    return (
        <Paper className={classes.paper}>
            <FormHeading text="Portfolio Search" />
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={12} lg={12}>
                    <FullWidthRadioGroup
                        name="searchBy"
                        label="Search By:"
                        validate={requiredValidator}
                    >
                        <RadioOption value="Life Assured" onClick={reset} />
                        <RadioOption value="Policy Number" onClick={reset} />
                    </FullWidthRadioGroup>
                    <Condition when="searchBy" matchesChild>
                        <When is="Life Assured">
                            <LifeAssuredCriteria />
                        </When>
                        <When is="Policy Number">
                            <PolicyCriteria />
                        </When>
                        <When is="">{null}</When>
                    </Condition>
                    <FormErrors />
                </Grid>
            </Grid>
        </Paper>
    );
};

const mapStateToProps = (state: State) => ({
    criteria: state.initiateClaim.searchCriteria,
});

const mapDispatchToProps = {
    findClientsByPersonalDetails: findClientsByPersonalDetailsAction,
    findClientsByPolicy: findClientsByPolicyAction,
};

export const ClientSearchView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(clientSearchView));
