import { reduceAndSubmitClaim } from '../../../../../claim/actions/reduce-and-submit-claim';
import { AddRecordCollectionAction } from '../../../../../redux/collection-reducer';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { Dispute } from '../reducer';
import { ADD_DISPUTE_RECORD } from '../types';

type SubmitReturn = Promise<any>;
export type AddAndSubmitDisputeRecord = (dispute: Dispute) => SubmitReturn;
type AddAndSubmitDisputeRecordThunk = (dispute: Dispute) => ReduxThunkAction<SubmitReturn>;

export const addAndSubmitDisputeRecordThunk: AddAndSubmitDisputeRecordThunk = (
    dispute: Dispute
) => (dispatch, getState) =>
    reduceAndSubmitClaim(dispatch, getState, addDisputeActionCreator(dispute));

export type AddDisputeRecordAction = AddRecordCollectionAction<Dispute>;

export const addDisputeActionCreator = (dispute: Dispute): AddDisputeRecordAction => {
    return {
        type: ADD_DISPUTE_RECORD,
        record: dispute,
    };
};
