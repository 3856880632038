export function updateRecord<T>(array: T[], record: T, index: number): T[] {
    if (!(record && index >= 0)) {
        return array;
    }

    return [
        ...array.slice(0, index),
        {
            ...(record as any),
        },
        ...array.slice(index + 1),
    ];
}
