import { Button, Grid, Tooltip, withStyles } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { ButtonCapture, ButtonWrapper } from '../../../../forms';
import { ExpansionPanel } from '../../../../forms/form-components/expansion-panel/expansion-panel';
import {
    getLifeAssuredDetails,
    LifeAssuredContract,
} from '../../../../life-assured-details/reducer';
import { State } from '../../../../redux/root-reducer';
import { titleCase } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import { PolicyOwnersView } from '../../common/policy-owner';
import {
    UpdateLifeAssuredContracts,
    updateLifeAssuredContractsAction,
} from './actions/update-life-assured-contracts';
import { BeneficiaryDetails } from './beneficiary-details-view';
import { LifeAssuredsView } from './life-assureds-view';
import { PolicyDetailsView } from './policy-details-view';

interface Props extends StyledComponent {
    contracts: LifeAssuredContract[];
    updateLifeAssuredContracts: UpdateLifeAssuredContracts;
}
const policySummaryView: React.FunctionComponent<Props> = ({
    contracts,
    updateLifeAssuredContracts,
}: Props) => {
    if (!contracts || contracts.length === 0) {
        return null;
    }
    return (
        <ExpansionPanel name="Policy Details">
            <ButtonWrapper>
                <Grid
                    container
                    item
                    xs={12}
                    justify="flex-end"
                    style={{ margin: '8px auto 8px auto' }}
                >
                    <ButtonCapture>
                        <Tooltip title="Refresh Policy Details">
                            <Button
                                id="refreshPolicyDetails"
                                color="secondary"
                                onClick={updateLifeAssuredContracts}
                                style={{ textTransform: 'none' }}
                            >
                                <Refresh style={{ marginRight: '4px' }} />
                                Refresh Policy Details
                            </Button>
                        </Tooltip>
                    </ButtonCapture>
                </Grid>
            </ButtonWrapper>
            {contracts.map((contract, contractIndex) => {
                const panelName = `Policy ${contractIndex + 1} - ${contract.contractNumber}`;
                let productName = (contract && contract.productName) || '';
                if (productName.indexOf('(') > -1) {
                    const shortProductName = productName.substring(productName.indexOf('('));
                    const actualProductName = productName.substring(0, productName.indexOf('('));
                    productName = `${titleCase(actualProductName)} ${shortProductName}`;
                } else {
                    productName = titleCase(productName);
                }
                return (
                    <ExpansionPanel
                        name={panelName}
                        label={panelName}
                        secondaryLabel={productName}
                        key={`policy${contractIndex}`}
                        style={{ marginLeft: -24, marginRight: -24 }}
                    >
                        <PolicyDetailsView contract={contract} />
                        <PolicyOwnersView contract={contract} hideTaxNo />
                        <LifeAssuredsView lifeAssureds={contract.lifeAssureds} />
                        <BeneficiaryDetails
                            beneficiaries={contract.beneficiaries}
                            contractIndex={contractIndex}
                        />
                    </ExpansionPanel>
                );
            })}
        </ExpansionPanel>
    );
};

const mapStateToProps = (state: State) => ({
    contracts: getLifeAssuredDetails(state).contracts,
});

const mapDispatchToProps = {
    updateLifeAssuredContracts: updateLifeAssuredContractsAction,
};

export const PolicySummaryView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(policySummaryView));
