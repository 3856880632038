import React from 'react'; // eslint-disable-line
import { connect, MapStateToProps } from 'react-redux';
import { SetActiveScreen, setActiveScreenAction } from '../actions';
import {
    GetFormSubmissionState,
    GetWorkflowState,
    NavigateToNextScreen,
    navigateToNextScreenThunk,
} from '../actions/navigate-to-next-screen';
import { WorkflowState } from '../reducers/workflow-reducer';
import { WorkflowScreen } from '../reducers/workflow-screen';
import { WorkflowScreenContainer } from '../reducers/workflow-screen-container';

interface OwnProps {
    workflowScreen: WorkflowScreen;
    getWorkflowState: GetWorkflowState;
    getFormSubmissionState: GetFormSubmissionState;
}

interface StateProps {
    workflowState: WorkflowState;
}

interface DispatchProps {
    setActiveScreen: SetActiveScreen;
    navigateToNextScreen: NavigateToNextScreen;
}

type Props = OwnProps & StateProps & DispatchProps;

class WorkflowScreenViewClass extends React.PureComponent<Props> {
    public componentDidMount() {
        if (this.props.workflowScreen instanceof WorkflowScreenContainer) {
            return;
        }
        if (!this.props.workflowState.activeScreen) {
            this.props.setActiveScreen(this.props.workflowScreen);
            return;
        }
        const currentPath = this.props.workflowState.activeScreen.path();
        const newScreenPath = this.props.workflowScreen.path();
        if (currentPath === newScreenPath) {
            return;
        }

        this.props.setActiveScreen(this.props.workflowScreen);
    }

    public render() {
        const ScreenView = this.props.workflowScreen.view();
        if (!!ScreenView) {
            return (
                <ScreenView
                    onSubmitSuccess={() =>
                        this.props.navigateToNextScreen(
                            this.props.getWorkflowState,
                            this.props.getFormSubmissionState
                        )
                    }
                />
            );
        }

        return null;
    }
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, any> = (
    state: any,
    props: OwnProps
) => ({
    workflowState: props.getWorkflowState(state),
});

const mapDispatchToProps = {
    setActiveScreen: setActiveScreenAction,
    navigateToNextScreen: navigateToNextScreenThunk,
};

export const WorkflowScreenView = connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkflowScreenViewClass);
