import React from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { ReasonForReferral } from '../../codes/reason-for-referral';
import {
    FormHeading,
    FullWidthDatePicker,
    FullWidthSearchSelectField,
    FullWidthTextField,
} from '../../forms';
import { conditionalRequired, requiredValidator } from '../../forms/validations';
import styles from '../../styles/styles';
import { ReinsurerReferal } from '../reducer';

interface Props {
    initialValues?: ReinsurerReferal;
}
const reinsurerReferalDetailsView: React.FunctionComponent<Props> = ({
    initialValues = {},
}: Props) => {
    return (
        <React.Fragment>
            <FormHeading text="Reinsurer Referal" />
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={6} lg={6}>
                    <FullWidthSearchSelectField
                        name="reasonForReferral"
                        label="Reason For Referral"
                        validate={requiredValidator}
                        options={ReasonForReferral}
                        disabled={!!initialValues.reasonForReferral}
                    />
                </Grid>
                <Grid item xs={6} lg={6}>
                    <FullWidthDatePicker
                        name="dateReferred"
                        maxDateField="dateOfDecision"
                        validate={conditionalRequired(
                            values => !!values.reinsurersDecision || !!values.dateOfDecision
                        )}
                        disableFuture
                        disabled={!!initialValues.dateReferred}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={6} lg={6}>
                    <FullWidthDatePicker
                        name="dateOfDecision"
                        minDateField="dateReferred"
                        validate={conditionalRequired(values => !!values.reinsurersDecision)}
                        disabled={!!initialValues.dateOfDecision}
                        disableFuture
                    />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <FullWidthTextField
                        name="reinsurersDecision"
                        validate={conditionalRequired(values => !!values.dateOfDecision)}
                        disabled={!!initialValues.reinsurersDecision}
                        multiline
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export const ReinsurerReferalDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(reinsurerReferalDetailsView));
