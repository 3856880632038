import React from 'react'; // eslint-disable-line
import styles, { StyledComponent } from '../../../../../styles/styles';
import { State } from '../../../../../redux/root-reducer';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { OpenModal, openModalAction } from '../../../../../form-modal/actions';
import { Grid, Divider, Typography } from '@material-ui/core';
import { LiquidAndDistribution, DistributionDictionary } from '../liquid-and-distribution-reducer';
import { RADeathLifeAssured } from '../../life-assured/reducer';
import { Nullable } from '../../../../../shared-types';
import { getRADeathClaim } from '../../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { formatCurrency } from '../../../../../shared-functions';
import { Dependant, getDependants } from '../../dependants/reducer';
interface DispatchProps {
    openModal: OpenModal;
}
interface OwnProps {}

type Props = StyledComponent &
    DispatchProps &
    OwnProps & {
        liquidAndDistribution: LiquidAndDistribution;
        lifeAssuredDetails: Nullable<RADeathLifeAssured>;
        dependents: Dependant[];
    };

const distributionNormalView: React.FunctionComponent<Props> = ({
    classes,
    liquidAndDistribution,
    lifeAssuredDetails,
    dependents,
    openModal,
}: Props) => {
    const liquidAndDistributionDtails: any[] = [];
    liquidAndDistributionDtails.push(liquidAndDistribution);
    let policyLiquidAndDistribution: DistributionDictionary[] = [];
    if (Array.isArray(liquidAndDistribution)) {
        policyLiquidAndDistribution = getDependentDistributions(liquidAndDistribution);
    }
    return (
        <Grid container spacing={0} justify="flex-end" className={classes.marginTop}>
            {policyLiquidAndDistribution &&
                policyLiquidAndDistribution.length > 0 &&
                policyLiquidAndDistribution.map((record, index) => {
                    let dependentStatus;
                    const displayName = getPosibleDependentName(record.id, dependents);
                    const dependent = dependents.find(c => c.id === record.id);
                    const age =
                        dependent && dependent.personalDetails ? dependent.personalDetails.age : 0;
                    const marginSize = index > 0 ? '30px' : '0px';
                    if (dependent !== undefined) {
                        dependentStatus = getDependentStatus(dependent);
                    }
                    return (
                        <React.Fragment key={index}>
                            <Grid item xs={12} container spacing={2} justify="flex-start">
                                <Grid item xs={12} style={{ marginTop: marginSize }}>
                                    <Typography
                                        variant="h6"
                                        style={{ fontSize: '1rem' }}
                                    >{`Dependant ${index + 1} | ${displayName}`}</Typography>
                                    <Typography>{`${dependentStatus} | Age ${age}`}</Typography>
                                </Grid>
                            </Grid>
                            {record.details.length > 0 &&
                                record.details.map((currentRecord, index) => {
                                    const description = index === 0 ? 'Description' : '';
                                    const amountPayable = index === 0 ? 'Amount payable' : '';
                                    return (
                                        <React.Fragment key={index}>
                                            <Grid
                                                item
                                                xs={12}
                                                container
                                                spacing={2}
                                                justify="flex-start"
                                            >
                                                <Grid
                                                    item
                                                    xs={9}
                                                    lg={9}
                                                    style={{ marginTop: '12px' }}
                                                >
                                                    <Typography
                                                        variant="h6"
                                                        style={{
                                                            fontSize: '1rem',
                                                            lineHeight: '2.5',
                                                        }}
                                                    >
                                                        {description}
                                                    </Typography>
                                                    <Typography
                                                        style={{
                                                            lineHeight: '0.8',
                                                        }}
                                                    >
                                                        {currentRecord.description}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} lg={2}>
                                                    <Typography
                                                        variant="h6"
                                                        style={{
                                                            marginTop: '12px',
                                                            fontSize: '1rem',
                                                            lineHeight: '2.5',
                                                        }}
                                                    >
                                                        {amountPayable}
                                                    </Typography>
                                                    <Typography style={{ lineHeight: '0.8' }}>
                                                        {formatCurrency(currentRecord.amount)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            {record.details.length === index + 1 && (
                                                <React.Fragment>
                                                    <Grid item xs={12}>
                                                        <Divider
                                                            style={{
                                                                marginTop: '15px',
                                                                height: '2px',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        container
                                                        spacing={2}
                                                        justify="flex-start"
                                                    >
                                                        <Grid item xs={9} lg={9}>
                                                            <Typography variant="h6">{`Total`}</Typography>
                                                        </Grid>
                                                        <Grid item xs={2} lg={2}>
                                                            <Typography variant="h6">
                                                                {formatCurrency(record.totalAmount)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Divider style={{ height: '2px' }} />
                                                    </Grid>
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                        </React.Fragment>
                    );
                })}
        </Grid>
    );
};
const mapStateToProps = (state: State) => ({
    lifeAssuredDetails: getRADeathClaim(state).lifeAssuredDetails,
    dependents: getDependants(state),
});

const mapDispatchToProps = { openModal: openModalAction };
export const DistributionNormalView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(distributionNormalView));
export const getPosibleDependentName = (dependentId: string, dependents: Dependant[]) => {
    let displayName = '';
    const dependent = dependents.find(c => c.id === dependentId);
    const organisationDetails =
        dependent && dependent.organisationDetails ? dependent.organisationDetails : undefined;
    const personalDetails =
        dependent && dependent.personalDetails ? dependent.personalDetails : undefined;
    if (personalDetails !== undefined && dependent !== undefined) {
        displayName = `${personalDetails.firstName} ${personalDetails.lastName} - ${dependent.relationship}`;
    }
    if (
        organisationDetails !== undefined &&
        personalDetails !== undefined &&
        dependent !== undefined
    ) {
        displayName = `${personalDetails.firstName} ${personalDetails.lastName} - ${organisationDetails.relationshipToLifeAssured}`;
    }
    return displayName;
};
export const getDependentDistributions = (liquidAndDistribution: LiquidAndDistribution[]) => {
    const dependentPolicies: DistributionDictionary[] = [];
    liquidAndDistribution.forEach(record => {
        const recordIndex = dependentPolicies.findIndex(occ => occ.id === record.dependent);
        if (recordIndex !== -1) {
            dependentPolicies[recordIndex].totalAmount += record.amountPayable;
            dependentPolicies[recordIndex].lastDateUpdated = record.lastDateUpdated;
            dependentPolicies[recordIndex].details.push({
                dependent: record.dependent,
                amount: record.amountPayable,
                description: record.description,
            });
        } else {
            dependentPolicies.push({
                id: record.dependent,
                totalAmount: record.amountPayable,
                details: [
                    {
                        dependent: record.dependent,
                        amount: record.amountPayable,
                        description: record.description,
                    },
                ],
                lastDateUpdated: record.lastDateUpdated,
            });
        }
    });
    return dependentPolicies;
};
export const getDependentStatus = (dependent: Dependant) => {
    let dependentStatus = 'Not Stated';
    if (dependent.employed === 'Yes') {
        dependentStatus = 'Employed';
    } else if (dependent.employed === 'No') {
        dependentStatus = 'Un-Employed';
    }
    return dependentStatus;
};
