import { Grid, Paper, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { FormErrors, FormHeading, FullWidthTextField, GenericForm } from '../../../forms';
import { requiredValidator } from '../../../forms/validations';
import { State } from '../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../styles/styles';
import { OnSubmitSuccessProps } from '../../../workflow-navigation';
import { FindClientsByPolicyCriteria } from '../../actions/find-clients-by-policy';
import { FindFuneralClients, findFuneralClientsAction } from '../../actions/find-funeral-clients';

type Props = OnSubmitSuccessProps &
    StyledComponent & {
        findClients: FindFuneralClients;
        criteria?: FindClientsByPolicyCriteria;
    };

const FuneralClientSearchForm = GenericForm<FindClientsByPolicyCriteria>();
const funeralClientSearchView: React.FunctionComponent<Props> = ({
    classes,
    findClients,
    criteria,
    ...rest
}: Props) => {
    return (
        <FuneralClientSearchForm onSubmit={findClients} initialValues={criteria} {...rest}>
            <Paper className={classes.paper}>
                <FormHeading text="Policy Search" />
                <Grid item xs={12} lg={12} container spacing={5} justify="flex-start">
                    <Grid item xs={6}>
                        <FullWidthTextField name="policyNumber" validate={requiredValidator} />
                    </Grid>
                    <FormErrors />
                </Grid>
            </Paper>
        </FuneralClientSearchForm>
    );
};

const mapStateToProps = (state: State) => ({
    criteria: state.initiateClaim.searchCriteria,
});

const mapDispatchToProps = {
    findClients: findFuneralClientsAction,
};

export const FuneralClientSearchView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(funeralClientSearchView));
