import { createFeatureActionNamer } from '../../../../redux/action-name';

const getActionName = createFeatureActionNamer('medical-consultation');

export const ADD_CURRENT_DOCTOR_RECORD = getActionName('ADD_CURRENT_DOCTOR_RECORD');
export const UPDATE_CURRENT_DOCTOR_RECORD = getActionName('UPDATE_CURRENT_DOCTOR_RECORD');
export const DELETE_CURRENT_DOCTOR_RECORD = getActionName('DELETE_CURRENT_DOCTOR_RECORD');

export const ADD_PREVIOUS_DOCTOR_RECORD = getActionName('ADD_PREVIOUS_DOCTOR_RECORD');
export const UPDATE_PREVIOUS_DOCTOR_RECORD = getActionName('UPDATE_PREVIOUS_DOCTOR_RECORD');
export const DELETE_PREVIOUS_DOCTOR_RECORD = getActionName('DELETE_PREVIOUS_DOCTOR_RECORD');

export const ADD_NOTES_RECORD = getActionName('ADD_NOTES_RECORD');
export const UPDATE_NOTES_RECORD = getActionName('UPDATE_NOTES_RECORD');
export const DELETE_NOTES_RECORD = getActionName('DELETE_NOTES_RECORD');
