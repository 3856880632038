import { claimSubmit } from '../../claim';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { MedicalOfficerInput } from '../reducer';

type SubmitReturn = Promise<any>;
export type Submit = (medicalOfficerInput: MedicalOfficerInput) => SubmitReturn;
type SubmitAction = (medicalOfficerInput: MedicalOfficerInput) => ReduxThunkAction<SubmitReturn>;
export const submitAction: SubmitAction = (medicalOfficerInput: MedicalOfficerInput) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, claim => ({
            ...claim,
            medicalOfficerInput,
        }));
    };
};
