import axios from 'axios';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { claimUpdatedThunk, Claim } from '../../../claim';
import { ClaimType } from '../../../claim/claim-types';
import { openDialogAction } from '../../../confirmation-dialog';
import { env } from '../../../env';
import { hideLoadingAction, showLoadingAction } from '../../../forms/loading-screen/actions';
import { getLifeAssuredDetailsFromClaim } from '../../../life-assured-details/reducer';
import { ReduxThunkAction } from '../../../redux/redux-actions';
import { State } from '../../../redux/root-reducer';
import {
    BenefitAssessmentDetails,
    getAssessmentDetailsFromClaim,
} from '../assessment-details-reducer';
import {
    InvestmentPolicyAssessment,
    RiskPolicyAssessmentDetails,
} from '../death/death-assessment-details-reducer';

export interface DeathDetails {
    policies: RiskPolicyAssessmentDetails[];
    investmentPolicyAssessments: InvestmentPolicyAssessment[];
    causeOfDeath?: string;
    dateOfDeath?: Date;
}

export interface liabilityRequest {
    claimType: any;
    compassClientId?: string;
    clientId?: string;
    contractType?: string;
    contractNumber?: string;
    benefitCode?: string;
    valueIndicator: 'Amount';
    claimAmount?: any;
    effectiveDate?: Date;
    causeOfDeath: any;
}

type RaiseLiabilityReturn = Promise<any>;
export type DeathRaiseLiability = (request: DeathDetails) => RaiseLiabilityReturn;
type DeathRaiseLiabilityAction = (request: DeathDetails) => ReduxThunkAction<RaiseLiabilityReturn>;

export const deathRaiseLiabilityAction: DeathRaiseLiabilityAction = (request: DeathDetails) => {
    return async (dispatch, getState) => {
        dispatch(showLoadingAction());
        const claim = getState().claimSession.claim;
        const raiseLiabilityUri = `${env().CLAIMS_API}/api/Claim/${claim.claimId}/RaiseLiability`;

        const claimDecisions: liabilityRequest[] = [];
        const lifeAssured = getLifeAssuredDetailsFromClaim(claim);

        request.investmentPolicyAssessments.forEach(compassPolicy => {
            const policyNumber = compassPolicy.policyNumber;
            const contract = lifeAssured.contracts.find(
                c => c.contractNumber === compassPolicy.policyNumber
            );

            if (
                compassPolicy.claimLiability?.status === 'Pending' &&
                compassPolicy.claimLiability?.isLiabilitySelected
            ) {
                claimDecisions.push({
                    claimType: claim.claimType,
                    compassClientId: lifeAssured.personalDetails.compassClientId,
                    clientId: lifeAssured.personalDetails.clientId,
                    contractType: contract && contract.contractType,
                    contractNumber: policyNumber,
                    benefitCode: compassPolicy.productName,
                    valueIndicator: 'Amount',
                    effectiveDate: compassPolicy.claimLiability.updatedEffectiveDate
                        ? compassPolicy.claimLiability.updatedEffectiveDate
                        : request.dateOfDeath,
                    causeOfDeath: request.causeOfDeath,
                });
            }
        });

        request.policies.forEach(benefit => {
            const policyNumber = benefit.policyNumber;
            const contract = lifeAssured.contracts.find(
                c => c.contractNumber === benefit.policyNumber
            );

            benefit.benefits.forEach(benefit => {
                const decision = getDecision(claim, benefit, policyNumber);

                if (
                    benefit.claimLiability?.status === 'Pending' &&
                    benefit.claimLiability?.isLiabilitySelected
                ) {
                    claimDecisions.push({
                        claimType: claim.claimType,
                        compassClientId: lifeAssured.personalDetails.compassClientId,
                        clientId: lifeAssured.personalDetails.clientId,
                        contractType: contract && contract.contractType,
                        contractNumber: policyNumber,
                        benefitCode: benefit.benefitCode,
                        valueIndicator: 'Amount',
                        claimAmount: decision && decision.amountPayable,
                        effectiveDate:
                            request.dateOfDeath ||
                            (decision && getEffectiveDate(decision.effectiveDate)),
                        causeOfDeath: request.causeOfDeath,
                    });
                }
            });
        });

        try {
            for (const claimDecision of claimDecisions) {
                await raiseLiabilities(raiseLiabilityUri, claimDecision, dispatch);
            }
        } catch (error: any) {
            let errorMessage = '';
            if (error.response) {
                errorMessage = `Error Code ${error.response.status}`;
            } else {
                errorMessage = error.message;
            }

            const message = `An error (${errorMessage}) occured while raising liabilities, please retry later`;
            showErrorMessage(message, dispatch);
        } finally {
            dispatch(hideLoadingAction());
        }
    };
};

function getDecision(claim: Claim, request: BenefitAssessmentDetails, policyNumber: string) {
    if (claim.claimType === ClaimType.Death || claim.claimType === ClaimType.RADeath) {
        return;
    }

    const assessmentContract = getAssessmentDetailsFromClaim(claim).find(
        c => c.policyNumber === policyNumber
    );

    const assessmentBenefit =
        assessmentContract &&
        assessmentContract.benefits.find(b => b.benefitCode === request.benefitCode);
    return (
        assessmentBenefit &&
        assessmentBenefit.decisions
            .sort((a, b) =>
                (a.assessmentDate || new Date()) < (b.assessmentDate || new Date())
                    ? 1
                    : (b.assessmentDate || new Date()) < (a.assessmentDate || new Date())
                    ? -1
                    : 0
            )
            .find(d => d.final === true)
    );
}

function showErrorMessage(errorMessage: any, dispatch: ThunkDispatch<State, null, AnyAction>) {
    dispatch(
        openDialogAction({
            content: {
                title: 'Error raising liability',
                body: errorMessage,
                error: true,
            },
        })
    );
}

async function raiseLiabilities(
    raiseLiabilityUri: string,
    claimDecision: liabilityRequest,
    dispatch: ThunkDispatch<State, null, AnyAction>
) {
    await axios
        .post<Claim>(raiseLiabilityUri, claimDecision)
        .then(resp => {
            if (resp.data) {
                dispatch(claimUpdatedThunk(resp.data));
            }
        })
        .catch(error => {
            let errorMessage = '';
            if (error.response) {
                errorMessage = `Error Code ${error.response.status}`;
            } else {
                errorMessage = error.message;
            }
            const message = `An error (${errorMessage}) occured while raising liability for for contract ${claimDecision.contractNumber}}, please retry later`;
            showErrorMessage(message, dispatch);
        });
}

function getEffectiveDate(date?: Date) {
    if (date && date.getDate() !== 1) {
        date.setMonth(date.getMonth() + 1);
        date.setDate(1);
    }

    return date;
}
