export const MedicalAidSchemes = [
    { value: 'AECI Medical Aid Society', label: 'AECI Medical Aid Society' },
    { value: 'Alliance-Midmed Medical Scheme', label: 'Alliance-Midmed Medical Scheme' },
    { value: 'Anglo Medical Scheme', label: 'Anglo Medical Scheme' },
    { value: 'Anglovaal Group Medical Scheme', label: 'Anglovaal Group Medical Scheme' },
    { value: 'Bankmed', label: 'Bankmed' },
    { value: 'Barloworld Medical Scheme', label: 'Barloworld Medical Scheme' },
    { value: 'Bestmed Medical Scheme', label: 'Bestmed Medical Scheme' },
    { value: 'BMW Employees Medical Aid Society', label: 'BMW Employees Medical Aid Society' },
    { value: 'Bonitas Medical Fund', label: 'Bonitas Medical Fund' },
    { value: 'Bonitas Medical Fund/Liberty', label: 'Bonitas Medical Fund/Liberty' },
    { value: 'BP Medical Aid Society', label: 'BP Medical Aid Society' },
    {
        value: 'Building & Construction Industry Medical Aid Fund',
        label: 'Building & Construction Industry Medical Aid Fund',
    },
    {
        value: 'Chartered Accountants (SA) Medical Aid Fund (CAMAF)',
        label: 'Chartered Accountants (SA) Medical Aid Fund (CAMAF)',
    },
    { value: 'Compcare Wellness Medical Scheme', label: 'Compcare Wellness Medical Scheme' },
    { value: 'De Beers Benefit Society', label: 'De Beers Benefit Society' },
    { value: 'Discovery Health Medical Scheme', label: 'Discovery Health Medical Scheme' },
    { value: 'Engen Medical Benefit Fund', label: 'Engen Medical Benefit Fund' },

    { value: 'Fedhealth Medical Scheme', label: 'Fedhealth Medical Scheme' },
    {
        value: 'Fishing Industry Medical Scheme (Fishmed)',
        label: 'Fishing Industry Medical Scheme (Fishmed)',
    },
    { value: 'Food Workers Medical Benefit Fund', label: 'Food Workers Medical Benefit Fund' },
    { value: 'Genesis Medical Scheme', label: 'Genesis Medical Scheme' },
    { value: 'Glencore Medical Scheme', label: 'Glencore Medical Scheme' },
    {
        value: 'Golden Arrow Employees Medical Benefit Fund',
        label: 'Golden Arrow Employees Medical Benefit Fund',
    },
    {
        value: 'Government Employees Medical Scheme (GEMS)',
        label: 'Government Employees Medical Scheme (GEMS)',
    },
    {
        value: 'Grintek Electronics Medical Aid Scheme',
        label: 'Grintek Electronics Medical Aid Scheme',
    },
    { value: 'Horizon Medical Scheme', label: 'Horizon Medical Scheme' },
    { value: 'Hosmed Medical Aid Scheme', label: 'Hosmed Medical Aid Scheme' },
    { value: 'Impala Medical Plan', label: 'Impala Medical Plan' },
    { value: 'Imperial Group Medical Scheme', label: 'Imperial Group Medical Scheme' },
    { value: 'Keyhealth', label: 'Keyhealth' },
    { value: 'LA-Health Medical Scheme', label: 'LA-Health Medical Scheme' },
    { value: 'Libcare Medical Scheme', label: 'Libcare Medical Scheme' },
    { value: 'Lonmin Medical Scheme', label: 'Lonmin Medical Scheme' },
    { value: 'Makoti Medical Scheme', label: 'Makoti Medical Scheme' },
    { value: 'Malcor Medical Scheme', label: 'Malcor Medical Scheme' },
    { value: 'Massmart Health Plan', label: 'Massmart Health Plan' },
    { value: 'MBMed Medical Aid Fund', label: 'MBMed Medical Aid Fund' },
    { value: 'Medihelp', label: 'Medihelp' },
    { value: 'Medimed Medical Scheme', label: 'Medimed Medical Scheme' },
    { value: 'Medipos Medical Scheme', label: 'Medipos Medical Scheme' },
    { value: 'Medshield Medical Scheme', label: 'Medshield Medical Scheme' },
    { value: 'Metropolitan Medical Scheme', label: 'Metropolitan Medical Scheme' },
    { value: 'Momentum Health', label: 'Momentum Health' },
    { value: 'Motohealth Care', label: 'Motohealth Care' },
    { value: 'Naspers Medical Fund', label: 'Naspers Medical Fund' },
    { value: 'Nedgroup Medical Aid Scheme', label: 'Nedgroup Medical Aid Scheme' },
    { value: 'Netcare Medical Scheme', label: 'Netcare Medical Scheme' },
    { value: 'Old Mutual Staff Medical Aid Fund', label: 'Old Mutual Staff Medical Aid Fund' },
    { value: 'Parmed Medical Aid Scheme', label: 'Parmed Medical Aid Scheme' },
    { value: 'PG Group Medical Scheme', label: 'PG Group Medical Scheme' },
    { value: 'Pick n Pay Medical Scheme', label: 'Pick n Pay Medical Scheme' },
    { value: 'Platinum Health', label: 'Platinum Health' },
    { value: 'Profmed', label: 'Profmed' },
    { value: 'Quantum Medical Aid Society', label: 'Quantum Medical Aid Society' },
    { value: 'Rand Water Medical Scheme', label: 'Rand Water Medical Scheme' },
    { value: 'Remedi Medical Aid Scheme', label: 'Remedi Medical Aid Scheme' },
    { value: 'Resolution Health Medical Scheme', label: 'Resolution Health Medical Scheme' },
    { value: 'Retail Medical Scheme', label: 'Retail Medical Scheme' },
    { value: 'Rhodes University Medical Scheme', label: 'Rhodes University Medical Scheme' },
    { value: 'SABC Medical Aid Scheme', label: 'SABC Medical Aid Scheme' },

    { value: 'SAMWUMed', label: 'SAMWUMed' },
    { value: 'Sasolmed', label: 'Sasolmed' },
    { value: 'Sedmed', label: 'Sedmed' },
    { value: 'Selfmed Medical Scheme', label: 'Selfmed Medical Scheme' },
    { value: 'Sisonke Health Medical Scheme', label: 'Sisonke Health Medical Scheme' },
    { value: 'Sizwe Medical Fund', label: 'Sizwe Medical Fund' },
    {
        value: 'South African Breweries Medical Aid Scheme (SABMAS)',
        label: 'South African Breweries Medical Aid Scheme (SABMAS)',
    },
    {
        value: 'South African Police Service Medical Scheme (POLMED)',
        label: 'South African Police Service Medical Scheme (POLMED)',
    },
    { value: 'Spectramed', label: 'Spectramed' },
    { value: 'Suremed Health', label: 'Suremed Health' },

    { value: 'TFG Medical Aid Scheme', label: 'TFG Medical Aid Scheme' },
    { value: 'Thebemed', label: 'Thebemed' },
    { value: 'Tiger Brands Medical Scheme', label: 'Tiger Brands Medical Scheme' },
    { value: 'Topmed Medical Scheme', label: 'Topmed Medical Scheme' },
    { value: 'Transmed Medical Fund', label: 'Transmed Medical Fund' },
    { value: 'Tsogo Sun Group Medical Scheme', label: 'Tsogo Sun Group Medical Scheme' },
    { value: 'Umvuzo Health Medical Scheme', label: 'Umvuzo Health Medical Scheme' },
    {
        value: 'University of Kwa-Zulu Natal Medical Scheme',
        label: 'University of Kwa-Zulu Natal Medical Scheme',
    },
    {
        value: 'University of the Witwatersrand, Johannesburg Staff Medical Aid Fund',
        label: 'University of the Witwatersrand, Johannesburg Staff Medical Aid Fund',
    },
    {
        value: 'Witbank Coalfields Medical Aid Scheme',
        label: 'Witbank Coalfields Medical Aid Scheme',
    },
    { value: 'Wooltru Healthcare Fund', label: 'Wooltru Healthcare Fund' },
    { value: 'Other', label: 'Other' },
];
