import { AnyAction, Reducer } from 'redux';
import { Claim, getClaim, getPropertyFromClaim } from '../../../../claim';
import { AddressDetails } from '../../../../forms/sub-forms/address-details';
import { PersonalDetails } from '../../../../life-assured-details/reducer';
import { State } from '../../../../redux/root-reducer';

export interface FuneralParlour {
    designationNumber?: string;
    dateOfFuneral?: Date;
    name?: string;
    telephoneNumber?: string;
    addressDetails?: AddressDetails;
}

export interface NoticeOfDeathDeceased {
    stillBirth?: string;
    foetusAge?: string;
    medicalReport?: string;
    bodyIdentifiable?: string;
    personalDetails?: PersonalDetails;
    addressDetails?: AddressDetails;
    dateOfDeath?: Date;
    placeOfDeath?: string;
    hospital?: string;
    policeReport?: string;
    placeOfDeathReg?: string;
    causeOfDeath?: string;
    naturalDeath?: string;
}

export interface FuneralParlourDetails extends FuneralParlour {
    designationNumber?: string;
}

export interface DoctorIndunaDetails {
    registrationNumber?: string;
    firstName?: string;
    secondName?: string;
    lastName?: string;
    telephoneNumber?: string;
    doctorInduna?: string;
    addressDetails?: AddressDetails;
    verifyDoctor?: string;
    dateDoctorSigned?: Date;
}

export interface Informant {
    idNumber?: string;
    lastName?: string;
    secondName?: string;
    firstName?: string;
    relationshipToLifeAssured?: string;
    relationshipToLifeAssuredDescription?: string;
    telephoneNumber?: string;
    addressDetails?: AddressDetails;
}

export interface NoticeOfDeath {
    serialNumber?: string;
    serialNumberConsistent?: string;
    dateSigned?: Date;
    deceased?: NoticeOfDeathDeceased;
    informantDetails?: Informant;
    funeralParlour?: FuneralParlourDetails;
    doctorIndunaDetails?: DoctorIndunaDetails;
}

export const defaultNoticeOfDeath = {};

export const noticeOfDeathReducer: Reducer = (state = {}, action: AnyAction) => {
    switch (action.type) {
        default:
            return state;
    }
};

export function getNoticeOfDeath(state: State): NoticeOfDeath {
    return getNoticeOfDeathFromClaim(getClaim(state));
}

export function getNoticeOfDeathFromClaim(claim: Claim): NoticeOfDeath {
    return getPropertyFromClaim(claim, 'noticeOfDeath', defaultNoticeOfDeath);
}
