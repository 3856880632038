import { withStyles, Grid, Typography, Tooltip, Button } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import styles, { StyledComponent } from '../../../../../styles/styles';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { ExpansionPanel } from '../../../../../forms/form-components/expansion-panel/expansion-panel';
import { Edit } from '@material-ui/icons';
import { FormDetails, getFormDetails } from '../form-details-reducer';
import { connect } from 'react-redux';
import { State } from '../../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../../shared-functions';
import { OpenModal, openModalAction } from '../../../../../form-modal/actions';
import { editLifeAssureDetails } from './life-assured-edit';
import { UpdateFormDetails, updateFormDetailsAction } from '../actions/update-form-details';
import { Nullable } from '../../../../../shared-types';

interface BaseProps {
    formDetails?: FormDetails;
    updateFormDetails?: UpdateFormDetails;
    disableEditButton?: boolean;
}

interface Props extends BaseProps, StyledComponent {
    readonly openModal: OpenModal;
}

const doctorPracticeDetailsView: React.FunctionComponent<Props> = ({
    classes,
    openModal,
    updateFormDetails,
    formDetails,
    disableEditButton,
}: Props) => {
    const edit = () => {
        const details = formDetails || {};
        editLifeAssureDetails(openModal, details, 4, updateFormDetails);
    };

    const doctors = formDetails?.lifeAssured?.medicalInformation?.doctorVisits || [
        {
            doctorsName: null,
            doctorsContactDetails: null,
            practiceNumber: null,
            emailAddress: null,
            addressDetails: {
                addressType: null,
                addressLineTwo: null,
                postalCode: null,
                addressLineOne: null,
                addressSuburb: null,
                country: null,
            },
            occurences: [
                {
                    dateOfAttendance: null,
                    reason: null,
                },
            ],
        },
    ];

    return (
        <React.Fragment>
            <Grid container justify="space-between" className={classes.marginTop2}>
                <Grid container item xs={10} alignItems="center">
                    <Typography style={{ padding: 10 }} className={classes.heading} variant="body1">
                        Doctor and practice details
                    </Typography>
                </Grid>
            </Grid>
            {doctors.map((doctor: any, index: number) => (
                <Grid key={index}>
                    <ExpansionPanel name={`Doctor ${index + 1}`}>
                        <Grid container justify="flex-start">
                            <Grid container item xs={12} alignItems="center" justify="flex-end">
                                <Tooltip title="Edit Doctor and Practice Details">
                                    <Button
                                        id="EditPersonalDetails"
                                        color="secondary"
                                        onClick={edit}
                                        disabled={!disableEditButton}
                                    >
                                        <Edit />
                                    </Button>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} container justify="flex-start">
                                <Grid item xs={4} lg={3}>
                                    <LabeledValue
                                        label="Doctor's name"
                                        value={doctor.doctorsName}
                                    />
                                </Grid>
                                <Grid item xs={4} lg={3}>
                                    <LabeledValue
                                        label="Practice number"
                                        value={doctor.practiceNumber}
                                    />
                                </Grid>
                                <Grid item xs={4} lg={3}>
                                    <LabeledValue
                                        label="Contact number"
                                        value={doctor.doctorsContactDetails}
                                    />
                                </Grid>
                                <Grid item xs={4} lg={3}>
                                    <LabeledValue
                                        label="Email address"
                                        value={doctor.emailAddress}
                                    />
                                </Grid>
                                <Grid item xs={4} lg={3}>
                                    <LabeledValue
                                        label="Address type"
                                        value={doctor.addressDetails?.addressType}
                                    />
                                </Grid>

                                <Grid item xs={4} lg={3}>
                                    <LabeledValue
                                        label="Address"
                                        value={`${doctor.addressDetails?.addressLineOne} ${'\n'} ${
                                            doctor?.addressDetails?.addressLineTwo === null
                                                ? ''
                                                : doctor.addressDetails?.addressLineTwo
                                        }  ${'\n'} ${
                                            doctor.addressDetails?.addressSuburb === null
                                                ? ''
                                                : doctor.addressDetails?.addressSuburb
                                        }
                                        ${'\n'} ${
                                            doctor.addressDetails?.addressTown === null
                                                ? ''
                                                : doctor.addressDetails?.addressTown
                                        }
                                        ${'\n'} ${
                                            doctor.addressDetails?.postalCode === null
                                                ? ''
                                                : doctor.addressDetails?.postalCode
                                        } ${'\n'} ${doctor.addressDetails?.country}`}
                                    />
                                </Grid>
                            </Grid>
                            {doctor.occurences.length > 0 &&
                                doctor.occurences.map(
                                    (
                                        occurence: {
                                            dateOfAttendance: Nullable<Date>;
                                            reason:
                                                | boolean
                                                | React.ReactChild
                                                | React.ReactFragment
                                                | React.ReactPortal
                                                | React.ReactNode[]
                                                | null
                                                | undefined;
                                        },
                                        index: React.Key | null | undefined
                                    ) => (
                                        <Grid item container xs={12} key={index}>
                                            <Grid item xs={4} lg={3}>
                                                <LabeledValue
                                                    label="Date of attendance"
                                                    value={getDisplayDate(
                                                        occurence.dateOfAttendance
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={4} lg={3}>
                                                <LabeledValue
                                                    label="Reason for consultation"
                                                    value={occurence.reason}
                                                />
                                            </Grid>
                                        </Grid>
                                    )
                                )}
                        </Grid>
                    </ExpansionPanel>
                </Grid>
            ))}
        </React.Fragment>
    );
};

const mapStateToProps = (state: State, ownProps: BaseProps) => ({
    formDetails: ownProps.formDetails || getFormDetails(state),
    disableEditButton: ownProps.disableEditButton || false,
});

const mapDispatchToProps = {
    openModal: openModalAction,
    updateFormDetails: updateFormDetailsAction,
};

export const DoctorPracticeDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(doctorPracticeDetailsView));
