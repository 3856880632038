import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { getDependants, Dependant } from '../../assessment/synopsis/ra-death/dependants/reducer';
import { getDependantName } from '../../shared-functions';
import styles from '../../styles/styles';
import { State } from '../../redux/root-reducer';
import {
    changeRecordFromAddChangeSet,
    changeRecordFromChangeSet,
} from '../claim-audit-trail-functions';
import { addAuditTrailDetailsView } from '../common-views/claim-audit-trail-field-details';
import { mapSectionView } from '../common-views/claim-audit-trail-wrapper';

interface Props {
    changeSet: Record<string, any>;
    dependents: Dependant[];
}

const auditTrailRADisputesView: React.FunctionComponent<Props> = ({
    changeSet,
    dependents,
}: Props) => {
    const returnView: React.ReactNode[] = [];
    const disputeLabel = 'Dispute';

    if (changeSet) {
        Object.keys(changeSet).forEach(disputeIndex => {
            const record = changeSet[disputeIndex];
            if (record !== null && disputeIndex !== '_t') {
                if (!record.length) {
                    if (record.hasOwnProperty('dependantId')) {
                        const previousName = getDependantName(
                            changeSet[disputeIndex].dependantId[0],
                            dependents
                        );
                        const updatedName = getDependantName(
                            changeSet[disputeIndex].dependantId[1],
                            dependents
                        );

                        const name = [
                            previousName && previousName.split(' ')[0],
                            updatedName && updatedName.split(' ')[0],
                        ];

                        const surname = [
                            previousName && previousName.split(' ')[1],
                            updatedName && updatedName.split(' ')[1],
                        ];
                        changeSet[disputeIndex].name = name;
                        changeSet[disputeIndex].surname = surname;
                    }
                    delete changeSet[disputeIndex].dependantId;
                    addAuditTrailDetailsView(
                        returnView,
                        changeRecordFromChangeSet(changeSet[disputeIndex]),
                        disputeHeading(disputeLabel, parseInt(disputeIndex) + 1)
                    );
                } else {
                    Object.keys(record).forEach(index => {
                        const recordSet = record[index];
                        if (!isNaN(parseInt(index))) {
                            if (
                                record[index].hasOwnProperty('dependantId') &&
                                record[index].dependantId !== 'Other'
                            ) {
                                const name = getDependantName(
                                    record[index].dependantId,
                                    dependents
                                );
                                recordSet.Name = name && name.split(' ')[0];
                                recordSet.Surname = name && name.split(' ')[1];
                            }
                            delete recordSet.dependantId;
                            addAuditTrailDetailsView(
                                returnView,
                                changeRecordFromAddChangeSet(recordSet),
                                disputeHeading(disputeLabel, parseInt(disputeIndex) + 1)
                            );
                        }
                    });
                }
            }
        });
    }

    return mapSectionView(returnView);
};

const disputeHeading = (disputeLabel: string, disputeIndex: any) => {
    return [`${disputeLabel} ${parseInt(disputeIndex)}`];
};

const mapStateToProps = (state: State) => ({
    dependents: getDependants(state),
});

const mapDispatchToProps = {};

export const AuditTrailRADisputesView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(auditTrailRADisputesView));
