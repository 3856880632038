import React from 'react'; // eslint-disable-line

import { Button, Grid, Tooltip, withStyles } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { DateTime } from 'luxon';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AuthState } from '../../../../auth/auth-reducer';
import { AddFormModal } from '../../../../form-modal';
import { OpenAddModal, openAddModalAction } from '../../../../form-modal/actions';
import { ExpansionPanel } from '../../../../forms/form-components/expansion-panel/expansion-panel';
import {
    AddAndSubmitInformationReceivedRecord,
    addAndSubmitInformationReceivedRecordThunk,
} from '../../../../information-received-summary/actions/submit-information-received';
import {
    getInformationReceivedSummary,
    InformationReceivedRecord,
} from '../../../../information-received-summary/reducer';
import InformationReceivedRecordDetailView from '../../../../information-received-summary/views/information-received-record-detail';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { InformationReceivedRecordView } from './information-received-record';
import { getClaim } from '../../../../claim';
import { ButtonWrapper } from '../../../../forms';

interface OwnProps {}

interface StateProps {
    currentUser: AuthState;
    documents: InformationReceivedRecord[];
    versionNumber: string;
    isReadOnly: boolean;
}

interface DispatchProps {
    addRecord: AddAndSubmitInformationReceivedRecord;
    openAddModal: OpenAddModal;
}

type Props = DispatchProps & OwnProps & StateProps & StyledComponent;

const informationReceivedView: React.FunctionComponent<Props> = ({
    addRecord,
    classes,
    currentUser,
    documents,
    openAddModal,
    versionNumber,
    isReadOnly,
}: Props) => {
    if (versionNumber > '1.1.0.0') {
        return null;
    }
    return (
        <ExpansionPanel name="Information Received Summary">
            <ButtonWrapper>
                <Grid container spacing={5} justify="flex-start">
                    <Grid item xs={12} lg={12} className={classes.rightAlign}>
                        {addInformationReceivedRecordButton(addRecord, currentUser, openAddModal)}
                    </Grid>
                </Grid>
            </ButtonWrapper>
            {documents.map((document, index) => {
                return (
                    <InformationReceivedRecordView
                        classes={classes}
                        index={index}
                        key={`Summary-${index}`}
                        informationReceivedRecord={document}
                    />
                );
            })}
        </ExpansionPanel>
    );
};

const InformationReceivedAddFormModal = AddFormModal<InformationReceivedRecord>();
const addInformationReceivedRecordButton = (
    addAndSubmitInformationReceived: AddAndSubmitInformationReceivedRecord,
    currentUser: AuthState,
    openAddModal: OpenAddModal
) => {
    let assessedBy = 'Unknown';
    if (currentUser) {
        assessedBy = `${currentUser.name} (${currentUser.userId})`;
    }
    const blankInformationReceivedRecord: InformationReceivedRecord = {
        documentID: '',
        documentName: '',
        dateOfReport: undefined,
        assessor: assessedBy,
        dateOfSummary: DateTime.local().toJSDate(),
        summary: '',
    };
    const openAddView = () => {
        openAddModal({
            modalContent: (
                <InformationReceivedAddFormModal
                    blankRecord={blankInformationReceivedRecord}
                    recordDetailView={
                        <InformationReceivedRecordDetailView
                            heading={'Add Information Received Record'}
                        />
                    }
                    addRecord={informationReceived =>
                        addAndSubmitInformationReceived(informationReceived)
                    }
                />
            ),
            modalData: {},
        });
    };

    return (
        <ButtonWrapper>
            <Tooltip title="Add Information Received Record">
                <Button id="AddInformationReceived" color="secondary" onClick={openAddView}>
                    <AddCircle />
                </Button>
            </Tooltip>
        </ButtonWrapper>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => {
    const claim = getClaim(state);
    const versionNumber = claim.version ? claim.version.createdInVersion || '1.1.0.0' : '1.1.0.0';
    return {
        documents: getInformationReceivedSummary(state).documents,
        currentUser: state.auth as AuthState,
        versionNumber: versionNumber,
        isReadOnly: state.claimSession.readOnly,
    };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    addRecord: (informationReceived: InformationReceivedRecord) =>
        dispatch(addAndSubmitInformationReceivedRecordThunk(informationReceived)),
    openAddModal: options => dispatch(openAddModalAction(options)),
});

export const InformationReceivedView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(informationReceivedView));
