import { reduceAndSubmitClaim } from '../../claim/actions/reduce-and-submit-claim';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { InformationReceivedRecord } from '../reducer';
import { addInformationReceivedRecordActionCreator } from './add-information-received';
import { deleteInformationReceivedRecordActionCreator } from './delete-information-received';
import { updateInformationReceivedRecordActionCreator } from './update-information-received';

type SubmitReturn = Promise<any>;

export type AddAndSubmitInformationReceivedRecord = (
    informationReceivedRecord: InformationReceivedRecord
) => SubmitReturn;
type AddAndSubmitInformationReceivedRecordThunk = (
    informationReceivedRecord: InformationReceivedRecord
) => ReduxThunkAction<SubmitReturn>;
export const addAndSubmitInformationReceivedRecordThunk: AddAndSubmitInformationReceivedRecordThunk = (
    informationReceivedRecord: InformationReceivedRecord
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        addInformationReceivedRecordActionCreator(informationReceivedRecord)
    );

export type UpdateAndSubmitInformationReceivedRecord = (
    informationReceivedRecordIndex: number,
    informationReceivedRecord: InformationReceivedRecord
) => SubmitReturn;
type UpdateAndSubmitInformationReceivedRecordThunk = (
    informationReceivedRecordIndex: number,
    informationReceivedRecord: InformationReceivedRecord
) => ReduxThunkAction<SubmitReturn>;

export const updateAndSubmitInformationReceivedRecordThunk: UpdateAndSubmitInformationReceivedRecordThunk = (
    informationReceivedRecordIndex: number,
    informationReceivedRecord: InformationReceivedRecord
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        updateInformationReceivedRecordActionCreator(
            informationReceivedRecordIndex,
            informationReceivedRecord
        )
    );

export type DeleteAndSubmitInformationReceivedRecord = (
    informationReceivedRecordIndex: number
) => SubmitReturn;
type DeleteAndSubmitInformationReceivedRecordThunk = (
    informationReceivedRecordIndex: number
) => ReduxThunkAction<SubmitReturn>;
export const deleteAndSubmitInformationReceivedRecordThunk: DeleteAndSubmitInformationReceivedRecordThunk = (
    informationReceivedRecordIndex: number
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        deleteInformationReceivedRecordActionCreator(informationReceivedRecordIndex)
    );
