import { reduceAndSubmitClaim } from '../../../../../claim/actions/reduce-and-submit-claim';
import {
    AddRecordCollectionAction,
    UpdateRecordCollectionAction,
} from '../../../../../redux/collection-reducer';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { DeathClaimNotes } from '../../../death/claim-event-details/reducer';
import { ADD_NOTES_RECORD, UPDATE_NOTES_RECORD } from '../types';

type SubmitReturn = Promise<any>;
export type AddAndSubmitClaimNotes = (claimNotes: DeathClaimNotes) => SubmitReturn;
type AddAndSubmitClaimNotesThunk = (claimNotes: DeathClaimNotes) => ReduxThunkAction<SubmitReturn>;

export const addAndSubmitClaimNotesThunk: AddAndSubmitClaimNotesThunk = (
    claimNotes: DeathClaimNotes
) => (dispatch, getState) =>
    reduceAndSubmitClaim(dispatch, getState, addClaimNotesActionCreator(claimNotes));

export type AddClaimNotesRecordAction = AddRecordCollectionAction<DeathClaimNotes>;

export const addClaimNotesActionCreator = (
    claimNotes: DeathClaimNotes
): AddClaimNotesRecordAction => {
    claimNotes = {
        author: claimNotes.author,
        dateCaptured: new Date(),
        notesSummary: claimNotes.notesSummary,
    };
    return {
        type: ADD_NOTES_RECORD,
        record: claimNotes,
    };
};

export type UpdateAndSubmitClaimNotesRecord = (
    claimNotesIndex: number,
    claimNotes: DeathClaimNotes
) => SubmitReturn;
type UpdateAndSubmitClaimNotesThunk = (
    claimNotesIndex: number,
    claimNotes: DeathClaimNotes
) => ReduxThunkAction<SubmitReturn>;

export const updateAndSubmitClaimNotesThunk: UpdateAndSubmitClaimNotesThunk = (
    claimNotesIndex: number,
    claimNotes: DeathClaimNotes
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        updateClaimNotesActionCreator(claimNotesIndex, claimNotes)
    );
export type UpdateClaimNotesAction = UpdateRecordCollectionAction<DeathClaimNotes>;
export const updateClaimNotesActionCreator = (
    claimNotesIndex: number,
    claimNotes: DeathClaimNotes
): UpdateClaimNotesAction => {
    return {
        type: UPDATE_NOTES_RECORD,
        record: claimNotes,
        index: claimNotesIndex,
    };
};
