import { Claim, claimSubmit } from '../../../../../claim';
import { addRecordReduction } from '../../../../../redux/reductions';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { RequiredDocument } from '../../../common/requirements-management/reducer';
import { getDocumentsRequirementsFromClaim } from '../reducer';

type SubmitReturn = Promise<any>;
export type AddAndDocumentStatusRecord = (document: RequiredDocument) => SubmitReturn;
type AddAndDocumentStatusRecordThunk = (document: RequiredDocument) => ReduxThunkAction;

export const addAndSubmitDocumentStatusAction: AddAndDocumentStatusRecordThunk = (
    document: RequiredDocument
) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, getUpdatedClaim(document));
    };
};

function getUpdatedClaim(document: RequiredDocument): (claim: Claim) => Claim {
    return (claim: Claim) => {
        const current = getDocumentsRequirementsFromClaim(claim);
        const currentDocuments: RequiredDocument[] = current
            ? current.deceased
                ? current.deceased
                : []
            : [];
        return {
            ...claim,
            documentsStatus: {
                ...current,
                deceased: addRecordReduction(currentDocuments, document),
            },
        };
    };
}
