import { Button, Grid, Paper, withStyles } from '@material-ui/core';
import { FormApi } from 'final-form';
import React from 'react'; // eslint-disable-line
import { FormSpy } from 'react-final-form';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { GenericForm } from '../../forms';
import { State } from '../../redux/root-reducer';
import { OnSubmitSuccessProps, submitFormAction } from '../../workflow-navigation';
import {
    CloseModal,
    closeModalAction,
    OpenAddModal,
    openAddModalAction,
    RecordView,
} from '../actions';
import { getRecordCount } from '../reducer';
import styles, { StyledComponent } from '../styles';
import { RequiredDocumentEditView } from '../../assessment/synopsis/common/requirements-management/views';
import { ClaimType } from '../../claim/claim-types';
import { DocumentOwnerType, Nullable } from '../../shared-types';
import { Claim, getClaim } from '../../claim';
import { DeathClaim } from '../../claim/reducers/claim-reducers/death-claim-reducer';
import { RADeathClaim } from '../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { DeathRequirements } from '../../assessment/synopsis/death/manage-requirements/reducer';
import { RADeathRequirements } from '../../assessment/synopsis/ra-death/manage-requirements/reducer';

interface ComponentState {
    readonly recordCount: number;
    readonly initialValues?: any;
}

interface InnerProps<RequiredDocument> extends ComponentState, StyledComponent {
    recordDetailView: React.ReactNode | RecordView;
    addRecord: (
        values: RequiredDocument
    ) => void | Promise<void> | Record<string, any> | Promise<Record<string, any>>;
    maxRecords?: number;
    hideAddAnother?: boolean;
    isSkipClose?: boolean;
    closeModal: CloseModal;
    hideSave?: boolean;
    openAddModal: OpenAddModal;
    documentOwnerType: DocumentOwnerType;
    claimantId?: string;
}

interface Props<RequiredDocument> extends OnSubmitSuccessProps, InnerProps<RequiredDocument> {
    blankRecord?: RequiredDocument;
    validate?: (values: RequiredDocument) => Record<string, any> | Promise<Record<string, any>>;
    claim: Claim;
}

interface FormProps<RequiredDocument> {
    readonly form: FormApi<RequiredDocument>;
    claim: Claim;
}

type InnerFormProps<RequiredDocument> = InnerProps<RequiredDocument> & FormProps<RequiredDocument>;

class AddFormModalFormWrapper<RequiredDocument> extends React.Component<
    Props<RequiredDocument>,
    ComponentState
> {
    public constructor(props: Props<RequiredDocument>) {
        super(props);
        this.state = {
            recordCount: this.props.recordCount,
            initialValues: this.props.blankRecord,
        };
    }

    public render() {
        const Form = GenericForm<RequiredDocument>();
        const {
            validate,
            classes,
            recordDetailView,
            addRecord,
            hideAddAnother,
            isSkipClose,
            hideSave,
            maxRecords,
            closeModal,
            openAddModal,
            documentOwnerType,
            claimantId,
            claim,
            ...rest
        }: Props<RequiredDocument> = this.props;
        const recordCount = this.state.recordCount;
        const initialValues = this.state.initialValues;
        return (
            <Form
                initialValues={initialValues}
                onSubmit={this.onSubmit}
                validate={validate}
                {...rest}
            >
                {() =>
                    FormSpy<RequiredDocument>({
                        render: ({ form }) =>
                            this.addFormModal({
                                classes,
                                recordDetailView,
                                addRecord,
                                maxRecords,
                                hideAddAnother,
                                hideSave,
                                isSkipClose,
                                recordCount,
                                closeModal,
                                openAddModal,
                                documentOwnerType,
                                form,
                                claim,
                                claimantId,
                                initialValues,
                            }),
                    })
                }
            </Form>
        );
    }

    private addFormModal: React.FunctionComponent<InnerFormProps<RequiredDocument>> = ({
        classes,
        recordDetailView,
        addRecord,
        maxRecords,
        hideAddAnother,
        hideSave,
        isSkipClose,
        recordCount,
        closeModal,
        form,
        openAddModal,
        documentOwnerType,
        claim,
        claimantId,
        initialValues,
    }: InnerFormProps<RequiredDocument>) => {
        const setSubmitFunction = (
            func: (
                record: RequiredDocument
            ) => void | Promise<void> | Record<string, any> | Promise<Record<string, any>>
        ) => {
            this.submitFunction = func;
            return undefined;
        };

        const save = async (record: RequiredDocument) => {
            const result = await addRecord(record);
            return result;
        };

        const saveAndClose = async (record: RequiredDocument) => {
            const result = await addRecord(record);
            if ((!result || Object.keys(result).length === 0) && !isSkipClose) {
                closeModal();
            }
            return result;
        };

        const RequiredOtherDocumentForm = AddOtherDocumentFormModal<RequiredDocument>();

        const saveAndAddAction = async (record: RequiredDocument) => {
            const result = await addRecord(record);
            if (!result || Object.keys(result).length === 0) {
                const id = uuid();
                this.setState({
                    recordCount: ++recordCount,
                    initialValues: { ...initialValues, id: id, uniqueId: id },
                });
                setTimeout(form.reset, 0);
                closeModal();

                let documentRequirements: any[] = [];

                const nonRaDeathClaim = claim as DeathClaim;
                const raDeathClaim = claim as RADeathClaim;
                let nonRaDeathRequirements: Nullable<DeathRequirements> = null;
                let raDeathRequirements: RADeathRequirements = raDeathClaim.requirements;

                if (claim.claimType === ClaimType.Death) {
                    nonRaDeathRequirements = nonRaDeathClaim.documentsStatus;
                } else if (claim.claimType === ClaimType.RADeath) {
                    raDeathRequirements = raDeathClaim.requirements;
                }

                switch (documentOwnerType) {
                    case DocumentOwnerType.Claimant: {
                        if (claim.claimType === ClaimType.Death) {
                            const claimantRequirements = nonRaDeathRequirements?.claimants || [];
                            if (claimantRequirements && claimantId) {
                                const requirementsToFind = claimantRequirements.findIndex(
                                    item => item.claimantId
                                );
                                documentRequirements =
                                    claimantRequirements[requirementsToFind].documents || [];
                            }
                        } else if (claim.claimType === ClaimType.RADeath) {
                            const claimantRequirements = raDeathRequirements.dependants || [];
                            if (claimantRequirements && claimantId) {
                                const requirementsToFind = claimantRequirements.findIndex(
                                    item => item.id
                                );
                                documentRequirements =
                                    claimantRequirements[requirementsToFind].documents || [];
                            }
                        }

                        break;
                    }
                    case DocumentOwnerType.LifeAssured: {
                        if (claim.claimType === ClaimType.Death) {
                            documentRequirements = nonRaDeathRequirements?.deceased || [];
                        } else if (claim.claimType === ClaimType.RADeath) {
                            documentRequirements = raDeathRequirements.lifeAssured.documents;
                        }

                        break;
                    }
                    default:
                        documentRequirements = [];
                }

                setTimeout(
                    () =>
                        openAddModal({
                            modalContent: (
                                <RequiredOtherDocumentForm
                                    blankRecord={
                                        ({
                                            id: uuid(),
                                            dateRequested: new Date(),
                                        } as unknown) as RequiredDocument
                                    }
                                    recordDetailView={
                                        <RequiredDocumentEditView
                                            isDeath={false}
                                            documentOwnerType={documentOwnerType}
                                            documents={documentRequirements}
                                            claimType={ClaimType.Funeral}
                                        />
                                    }
                                    addRecord={addRecord}
                                    documentOwnerType={documentOwnerType}
                                    claimantId={claimantId}
                                />
                            ),
                            modalData: {},
                        }),
                    75
                );
            }
            return result;
        };

        const detailView =
            typeof recordDetailView === 'function'
                ? (recordDetailView as RecordView)()
                : recordDetailView;

        return (
            <Paper className={classes.paper}>
                {detailView}
                <Grid container spacing={0} justify="flex-end">
                    {!hideSave && (
                        <Button
                            id="Save"
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={() => {
                                setSubmitFunction(save);
                                form.submit();
                            }}
                        >
                            Save
                        </Button>
                    )}
                    <Button
                        id="SaveClose"
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={() => {
                            setSubmitFunction(saveAndClose);
                            form.submit();
                        }}
                    >
                        {'Save & Close'}
                    </Button>
                    {!hideAddAnother && (
                        <Button
                            id="SaveAddAnother"
                            variant="contained"
                            color="secondary"
                            disabled={!!maxRecords && recordCount >= maxRecords - 1}
                            className={classes.button}
                            onClick={() => {
                                setSubmitFunction(saveAndAddAction);
                                form.submit();
                            }}
                        >
                            {'Save & Add Another'}
                        </Button>
                    )}
                    <Button
                        id="Cancel"
                        variant="contained"
                        className={classes.button}
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Paper>
        );
    };

    private submitFunction: (
        values: RequiredDocument
    ) => void | Promise<void> | Record<string, any> | Promise<Record<string, any>> = () => {};

    private onSubmit = (values?: RequiredDocument) => {
        if (values) {
            return this.submitFunction(values);
        }
    };
}

const mapStateToProps = (state: State) => ({
    recordCount: getRecordCount(state),
    claim: getClaim(state),
});

const mapDispatchToProps = {
    closeModal: closeModalAction,
    submitForm: submitFormAction,
    openAddModal: openAddModalAction,
};

export function AddOtherDocumentFormModal<RequiredDocument>() {
    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        withStyles(styles)(
            AddFormModalFormWrapper as new (
                props: Props<RequiredDocument>
            ) => AddFormModalFormWrapper<RequiredDocument>
        )
    );
}
