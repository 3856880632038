import { AddRecordCollectionAction } from '../../../../../../../redux/collection-reducer';
import { UnderwritingReferral } from '../underwriting-referral-model';
import { ADD_UNDERWRITING_REFERRAL } from './underwriting-referral-action-types';

export type AddUnderwritingReferralAction = AddRecordCollectionAction<UnderwritingReferral>;

export const addUnderwritingReferralActionCreator = (
    underwritingReferral: UnderwritingReferral
): AddUnderwritingReferralAction => {
    return {
        type: ADD_UNDERWRITING_REFERRAL,
        record: underwritingReferral,
    };
};
