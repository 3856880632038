import { DeleteRecordCollectionAction } from '../../../../../redux/collection-reducer';
import { DELETE_EXECUTOR_RECORD, DELETE_OTHER_CONTACT_RECORD } from './types';

export type DeleteOtherContactRecordAction = DeleteRecordCollectionAction;

export const deleteOtherContactRecordActionCreator = (
    otherContactRecordIndex: number
): DeleteOtherContactRecordAction => {
    return {
        type: DELETE_OTHER_CONTACT_RECORD,
        index: otherContactRecordIndex,
    };
};

export type DeleteExecutorRecordAction = DeleteRecordCollectionAction;

export const deleteExecutorRecordActionCreator = (
    executorRecordIndex: number
): DeleteExecutorRecordAction => {
    return {
        type: DELETE_EXECUTOR_RECORD,
        index: executorRecordIndex,
    };
};
