import { reduceAndSubmitClaim } from '../../../../../../../claim/actions/reduce-and-submit-claim';
import { ReduxThunkAction } from '../../../../../../../redux/redux-actions';
import { UnderwritingReferral } from '../underwriting-referral-model';
import { addUnderwritingReferralActionCreator } from './add-underwriting-referral';
import { deleteUnderwritingReferralActionCreator } from './delete-underwriting-referral';
import { updateUnderwritingReferralActionCreator } from './update-underwriting-referral';

type SubmitReturn = Promise<any>;

export type AddAndSubmitUnderwritingReferral = (
    underwritingReferral: UnderwritingReferral
) => SubmitReturn;
type AddAndSubmitUnderwritingReferralThunk = (
    underwritingReferral: UnderwritingReferral
) => ReduxThunkAction<SubmitReturn>;
export const addAndSubmitUnderwritingReferralThunk: AddAndSubmitUnderwritingReferralThunk = (
    underwritingReferral: UnderwritingReferral
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        addUnderwritingReferralActionCreator(underwritingReferral)
    );

export type UpdateAndSubmitUnderwritingReferral = (
    underwritingReferralIndex: number,
    underwritingReferral: UnderwritingReferral
) => SubmitReturn;
type UpdateAndSubmitUnderwritingReferralThunk = (
    underwritingReferralIndex: number,
    underwritingReferral: UnderwritingReferral
) => ReduxThunkAction<SubmitReturn>;
export const updateAndSubmitUnderwritingReferralThunk: UpdateAndSubmitUnderwritingReferralThunk = (
    underwritingReferralIndex: number,
    underwritingReferral: UnderwritingReferral
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        updateUnderwritingReferralActionCreator(underwritingReferralIndex, underwritingReferral)
    );

export type DeleteAndSubmitUnderwritingReferral = (
    underwritingReferralIndex: number
) => SubmitReturn;
type DeleteAndSubmitUnderwritingReferralThunk = (
    underwritingReferralIndex: number
) => ReduxThunkAction<SubmitReturn>;
export const deleteAndSubmitUnderwritingReferralThunk: DeleteAndSubmitUnderwritingReferralThunk = (
    underwritingReferralIndex: number
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        deleteUnderwritingReferralActionCreator(underwritingReferralIndex)
    );
