/* eslint-disable @typescript-eslint/no-inferrable-types */
import React from 'react'; // eslint-disable-line
import { Divider, Grid, Typography, withStyles } from '@material-ui/core';
import { Dependant, getDependants, getPossibleParents } from '../reducer';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { Code } from '../../../../../lookups';
import {
    FullWidthReadonlyField,
    FullWidthTextField,
    FullWidthRichTextField,
} from '../../../../../forms';
import {
    UserInput,
    ValidationResult,
    composeValidators,
    mustBeNumber,
    requiredValidator,
} from '../../../../../forms/validations';
import { formatPercent } from '../../../../../shared-functions';
import {
    DecisionModel,
    RecommendationModel,
} from '../../../../decisions/ra-death/ra-death-assessment-details-reducer';
import { State } from '../../../../../redux/root-reducer';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';

interface DecisionProps extends StyledComponent {
    dependants: Dependant[];
    possibleParents: Code<string>[];
}
const raDeathDecisionEditView: React.FunctionComponent<DecisionProps> = ({
    classes,
    dependants,
    possibleParents,
}: DecisionProps) => {
    const totalValue = (values: any) => {
        let total = 0;
        if (Array.isArray(values)) {
            values.forEach(value => {
                if (value.allocation) {
                    total = total + Number(value.allocation);
                }
            });
        }
        return formatPercent(total / 100);
    };

    return (
        <Grid container xs={12} justify="flex-start">
            <Grid item spacing={5} container justify="flex-start" className={classes.marginTop}>
                <Grid item xs={4}>
                    <Typography>Dependant</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography>Age</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Grid item xs={12}>
                        <Typography>Relationship to life assured</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Typography>Allocation</Typography>
                </Grid>
            </Grid>
            {dependants.map((dependant, index) => {
                const backgroundColor = index % 2 === 0 ? { backgroundColor: '#F0F1F4' } : {};
                const title = dependant.personalDetails?.title || '';
                return (
                    <Grid
                        item
                        container
                        justify="flex-start"
                        style={{ marginRight: -80 }}
                        key={`recommendation_${index}`}
                    >
                        <Grid
                            item
                            container
                            spacing={5}
                            justify="flex-start"
                            style={{ ...backgroundColor, marginRight: -80 }}
                        >
                            <Grid item xs={12} style={{ marginLeft: -20 }}>
                                <Divider style={{ backgroundColor: '#8B92A7', marginRight: -40 }} />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography style={{ paddingTop: 15 }}>
                                    {title +
                                        ' ' +
                                        dependant.personalDetails?.firstName +
                                        ' ' +
                                        dependant.personalDetails?.lastName}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={{ paddingTop: 15 }}>
                                    {dependant.personalDetails?.age}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography style={{ paddingTop: 15 }}>
                                    {dependant.relationshipDescription}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <FullWidthTextField
                                    name={`percentageSplits[${index}].allocation`}
                                    validate={composeValidators(
                                        requiredValidator,
                                        mustBeNumber,
                                        allocationValidator((values: any) => values)
                                    )}
                                    hideLabel
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                );
            })}
            <Grid item xs={12} style={{ marginLeft: -20 }}>
                <Divider style={{ backgroundColor: '#8B92A7', marginRight: -40 }} />
            </Grid>
            <Grid container xs={12} justify="flex-start">
                <Grid item spacing={5} container justify="flex-start" className={classes.marginTop}>
                    <Grid container item xs={10} justify="flex-start">
                        <Typography style={{ marginTop: 20, marginLeft: 0, fontWeight: 'bold' }}>
                            Total
                        </Typography>
                    </Grid>
                    <Grid container item xs={2} justify="flex-end">
                        <Grid
                            item
                            xs={12}
                            justify="flex-start"
                            style={{ marginRight: 40, marginTop: 10 }}
                        >
                            <Field name="percentageSplits">
                                {props => (
                                    <div>
                                        <FullWidthReadonlyField
                                            name="totalAllocation"
                                            format={formatPercent}
                                            hideLabel
                                            value={totalValue(props.input.value)}
                                            style={{ fontWeight: 'bold' }}
                                        />
                                    </div>
                                )}
                            </Field>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginLeft: -20 }}>
                <Divider style={{ backgroundColor: '#8B92A7', marginRight: -40 }} />
            </Grid>
            <Grid container xs={12} justify="flex-start">
                <FullWidthRichTextField
                    name="allocationReason"
                    id="allocationReason"
                    label="Reason(s) For Allocation"
                    validate={requiredValidator}
                    showOcr={false}
                    style={{ marginTop: 10 }}
                />
            </Grid>
        </Grid>
    );
};

const allocationValidator = (values: any) => {
    return function validate(value: UserInput, values: DecisionModel): ValidationResult {
        let totalAllocation = 0;

        if (value && values.percentageSplits) {
            if (Array.isArray(values.percentageSplits)) {
                values.percentageSplits.forEach((percentageSplit: RecommendationModel) => {
                    if (percentageSplit.allocation) {
                        totalAllocation =
                            Number(totalAllocation.toFixed(2)) + Number(percentageSplit.allocation);
                    }
                });
                if (totalAllocation !== 100) {
                    return 'Total allocation should equal 100%';
                }
            }
        }
        return undefined;
    };
};

const mapStateToProps = (state: State) => ({
    dependants: getDependants(state),
    possibleParents: getPossibleParents(state),
});

const mapDispatchToProps = {};
export const RaDeathDecisionEditView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(raDeathDecisionEditView));
