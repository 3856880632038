import { AnyAction } from 'redux';
import {
    AssessmentDecision,
    ClaimLiability,
} from '../../../assessment/decisions/assessment-details-reducer';
import {
    ClaimDetails,
    claimDetailsReducer,
} from '../../../assessment/synopsis/common/claim-details/claim-details-reducer';
import { ClaimInformation, claimInformationReducer } from '../../../claim-information/reducer';
import { ClaimContactDetails, contactDetailsReducer } from '../../../contact-details/reducer';
import { DisabilityDetails, disabilityDetailsReducer } from '../../../disability-details/reducer';
import { doctorDetailsReducer, DoctorsDetails } from '../../../doctor-details/reducer';
import {
    ForensicInput,
    forensicInputReducer,
} from '../../../assessment/synopsis/common/forensic-input/reducer';
import {
    LifeAssuredDetails,
    lifeAssuredDetailsReducer,
} from '../../../life-assured-details/reducer';
import {
    MedicalOfficerInput,
    medicalOfficerInputReducer,
} from '../../../medical-officer-input/reducer';
import { combineReducersWithDefault } from '../../../redux/combine-reducers-with-default';
import {
    ReinsuranceInputDetails,
    reinsuranceInputDetailsReducer,
} from '../../../reinsurance-input/reducer';
import { Nullable } from '../../../shared-types';
import { BaseClaim, baseClaimReducers, initialBaseClaim } from './base-claim-reducer';
import {
    InformationRecordSummary,
    informationRecordSummaryReducer,
} from '../../../assessment/synopsis/common/information-record/reducer';
import {
    CommonMedicalHistory,
    commonMedicalHistoryReducer,
} from '../../../assessment/synopsis/common/medical-consultation/reducer';
import { Claim, getClaim, getPropertyFromClaim } from '../claim-reducer';
import { State } from '../../../redux/root-reducer';

export interface ERBPolicyAssessment {
    readonly productName: string;
    readonly policyNumber: string;
    readonly commencementDate?: Date;
    readonly decisions: AssessmentDecision[];
    readonly claimLiability: ClaimLiability;
    readonly automaticBenefitIncrease: number;
}
export interface EarlyRetirementClaim extends BaseClaim {
    readonly contactDetails?: Nullable<ClaimContactDetails>;
    readonly claimDetails?: Nullable<ClaimDetails>;
    readonly doctorDetails?: Nullable<DoctorsDetails>;
    readonly disabilityDetails?: Nullable<DisabilityDetails>;
    readonly informationRecordSummary?: Nullable<InformationRecordSummary>;
    readonly reinsuranceInputDetails?: Nullable<ReinsuranceInputDetails>;
    readonly assessmentDetails: ERBPolicyAssessment[];
    readonly lifeAssuredDetails?: Nullable<LifeAssuredDetails>;
    readonly medicalOfficerInput?: Nullable<MedicalOfficerInput>;
    readonly forensicInput?: Nullable<ForensicInput>;
    readonly claimInformation?: Nullable<ClaimInformation>;
    readonly commonMedicalHistory?: Nullable<CommonMedicalHistory>;
}

export const initialEarlyRetirementClaim: EarlyRetirementClaim = {
    ...initialBaseClaim,
    assessmentDetails: [],
};

export const erbAssessmentDetailsReducer = (
    state: ERBPolicyAssessment[] = [],
    action: AnyAction
) => {
    return state;
};

export const earlyRetirementClaimReducers = {
    ...baseClaimReducers,
    contactDetails: contactDetailsReducer,
    claimDetails: claimDetailsReducer,
    doctorDetails: doctorDetailsReducer,
    disabilityDetails: disabilityDetailsReducer,
    informationRecordSummary: informationRecordSummaryReducer,
    reinsuranceInputDetails: reinsuranceInputDetailsReducer,
    assessmentDetails: erbAssessmentDetailsReducer,
    lifeAssuredDetails: lifeAssuredDetailsReducer,
    medicalOfficerInput: medicalOfficerInputReducer,
    forensicInput: forensicInputReducer,
    claimInformation: claimInformationReducer,
    commonMedicalHistory: commonMedicalHistoryReducer,
};

export const earlyRetirementClaimReducer = combineReducersWithDefault<EarlyRetirementClaim>(
    initialEarlyRetirementClaim,
    { ...earlyRetirementClaimReducers }
);

export function getERBAssessmentDetailsFromClaim(claim: Claim) {
    return getPropertyFromClaim(
        claim,
        'assessmentDetails',
        initialEarlyRetirementClaim.assessmentDetails
    );
}

export function getERBAssessmentDetails(state: State) {
    return getERBAssessmentDetailsFromClaim(getClaim(state));
}
