import { ComponentGenerator, WorkflowScreen } from './workflow-screen';

export class WorkflowUtilityScreen extends WorkflowScreen {
    public readonly icon: string;
    public constructor(
        label: string,
        icon: string,
        component?: React.ComponentType<any> | ComponentGenerator,
        parent?: WorkflowScreen,
        screenHeading?: string,
        canShow?: () => boolean
    ) {
        super(label, component, parent, screenHeading, canShow);
        this.icon = icon;
    }

    public cloneWithNewParent(parent?: WorkflowScreen) {
        return new WorkflowUtilityScreen(
            this.label,
            this.icon,
            this.component,
            parent,
            this.screenHeading,
            this.canShow
        );
    }
}
