import React from 'react'; // eslint-disable-line

import { Grid, withStyles, Tooltip, Button, Typography } from '@material-ui/core';
import { AddCircle, Delete, Edit } from '@material-ui/icons';
import { connect, MapDispatchToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AddFormModal, EditFormModal } from '../../../../../form-modal';
import {
    OpenAddModal,
    openAddModalAction,
    OpenEditModal,
    openEditModalAction,
} from '../../../../../form-modal/actions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { State } from '../../../../../redux/root-reducer';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { getDisplayDate } from '../../../../../shared-functions';
import { OpenDialog, openDialogAction } from '../../../../../confirmation-dialog';
import { DoctorsVisitForm } from './add-doctors-visit-form';
import { Doctors } from '../reducer';
import { Consultation } from '../../../death/life-assured-details/reducer';
import {
    DeleteDoctorsConsultation,
    AddDoctorsConsultation,
    UpdateDoctorsConsultation,
} from '../action/current-doctors-consultation';
import { ButtonWrapper } from '../../../../../forms';

interface DispatchProps {
    openAddModal: OpenAddModal;
    openEditModal: OpenEditModal;
    openDialog: OpenDialog;
}

interface OwnProps {
    addConsultation: AddDoctorsConsultation;
    deleteConsultation: DeleteDoctorsConsultation;
    doctor: Doctors;
    doctorIndex: number;
    updateConsultation: UpdateDoctorsConsultation;
}

type Props = DispatchProps &
    StyledComponent &
    OwnProps & {
        isReadOnly: boolean;
    };

const AddConsultationFormModal = AddFormModal<Consultation>();
const EditConsultationFormModal = EditFormModal<Consultation>();
function doctorsConsultations({
    addConsultation,
    classes,
    deleteConsultation,
    doctorIndex,
    doctor,
    isReadOnly,
    openDialog,
    openAddModal,
    updateConsultation,
    openEditModal,
}: Props) {
    return (
        <React.Fragment>
            <Grid item xs={12} container justify="flex-end">
                <AddConsultationButton
                    addConsultation={addConsultation}
                    doctor={doctor}
                    doctorIndex={doctorIndex}
                    isReadOnly={isReadOnly}
                    openAddModal={openAddModal}
                />
            </Grid>
            {doctor.doctorsVisits &&
                doctor.doctorsVisits.map((consultation, index) => {
                    return (
                        <Grid item xs={12} key={`cur-cons-${index}`} container justify="flex-start">
                            {index !== 0 && (
                                <Grid item xs={12}>
                                    <hr />
                                </Grid>
                            )}
                            <Grid item xs={8}>
                                <Typography
                                    style={{
                                        marginTop: '6px',
                                        fontWeight: 500,
                                        fontSize: '1.2rem',
                                    }}
                                >
                                    {`Consultation ${index + 1}`}
                                </Typography>
                            </Grid>
                            <ButtonWrapper>
                                <Grid item xs={4} className={classes.rightAlign}>
                                    <EditConsultationButton
                                        consultIndex={index}
                                        doctor={doctor}
                                        doctorIndex={doctorIndex}
                                        isReadOnly={isReadOnly}
                                        openEditModal={openEditModal}
                                        updateConsultation={updateConsultation}
                                    />
                                    <DeleteConsultationButton
                                        consultIndex={index}
                                        doctor={doctor}
                                        doctorIndex={doctorIndex}
                                        isReadOnly={isReadOnly}
                                        openDialog={openDialog}
                                        deleteConsultation={deleteConsultation}
                                    />
                                </Grid>
                            </ButtonWrapper>
                            <Grid item xs={12}>
                                <LabeledValue
                                    label="Date Of Consultation"
                                    value={getDisplayDate(consultation.date)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LabeledValue
                                    label="Reason For Consultation"
                                    value={consultation.reason}
                                    isUnsafe
                                />
                            </Grid>
                        </Grid>
                    );
                })}
        </React.Fragment>
    );
}

interface AddProps {
    addConsultation: AddDoctorsConsultation;
    doctor: Doctors;
    doctorIndex: number;
    isReadOnly: boolean;
    openAddModal: OpenAddModal;
}
function AddConsultationButton({
    addConsultation,
    doctor,
    doctorIndex,
    isReadOnly,
    openAddModal,
}: AddProps) {
    if (isReadOnly) {
        return null;
    }

    return (
        <ButtonWrapper>
            <Tooltip title="Add Doctor's Consultation">
                <Button
                    id="AddDoctorsConsultation"
                    color="secondary"
                    onClick={() =>
                        openAddModal({
                            modalContent: (
                                <AddConsultationFormModal
                                    recordDetailView={
                                        <DoctorsVisitForm heading="Add Doctor's Consultation" />
                                    }
                                    addRecord={record =>
                                        addConsultation(record, doctor, doctorIndex)
                                    }
                                />
                            ),
                            modalData: {},
                        })
                    }
                >
                    <AddCircle />
                </Button>
            </Tooltip>
        </ButtonWrapper>
    );
}

interface EditProps {
    consultIndex: number;
    doctor: Doctors;
    doctorIndex: number;
    isReadOnly: boolean;
    openEditModal: OpenEditModal;
    updateConsultation: UpdateDoctorsConsultation;
}

function EditConsultationButton({
    consultIndex,
    doctor,
    doctorIndex,
    isReadOnly,
    openEditModal,
    updateConsultation,
}: EditProps) {
    if (isReadOnly) {
        return null;
    }

    return (
        <Tooltip title="Edit Doctor's Consultation">
            <Button
                id="EditConsultationDetails"
                color="secondary"
                onClick={() =>
                    openEditModal({
                        modalContent: (
                            <EditConsultationFormModal
                                recordDetailView={
                                    <DoctorsVisitForm heading="Edit Doctor's Consultation" />
                                }
                                records={doctor.doctorsVisits || []}
                                updateRecord={(consultation, index) =>
                                    updateConsultation(consultation, doctor, index, doctorIndex)
                                }
                            />
                        ),
                        modalData: {
                            recordIndex: consultIndex,
                        },
                    })
                }
            >
                <Edit />
            </Button>
        </Tooltip>
    );
}

interface DeleteProps {
    consultIndex: number;
    doctor: Doctors;
    doctorIndex: number;
    isReadOnly: boolean;
    openDialog: OpenDialog;
    deleteConsultation: DeleteDoctorsConsultation;
}

function DeleteConsultationButton({
    consultIndex,
    doctor,
    doctorIndex,
    isReadOnly,
    openDialog,
    deleteConsultation,
}: DeleteProps) {
    if (isReadOnly) {
        return null;
    }

    return (
        <Tooltip title="Delete Doctor's Consultation">
            <Button
                id="DeleteDoctorsConsultation"
                color="secondary"
                onClick={() =>
                    openDialog({
                        content: {
                            title: 'Delete Doctors consultation',
                            body: `Are you sure you would like to delete doctor's consultation ${
                                consultIndex + 1
                            }?`,
                        },
                        yesAction: {
                            action: () => deleteConsultation(doctor, consultIndex, doctorIndex),
                        },
                    })
                }
            >
                <Delete />
            </Button>
        </Tooltip>
    );
}

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    openAddModal: record => dispatch(openAddModalAction(record)),
    openEditModal: record => dispatch(openEditModalAction(record)),
    openDialog: options => dispatch(openDialogAction(options)),
});

const mapStateToProps = (state: State) => ({
    isReadOnly: state.claimSession.readOnly,
});

export const DoctorsConsultations = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(doctorsConsultations));
