import { withStyles, Grid, Typography, Paper, Tooltip, Button } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import styles, { StyledComponent } from '../../../../../styles/styles';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { Edit } from '@material-ui/icons';
import { FormDetails, getFormDetails } from '../form-details-reducer';
import { connect } from 'react-redux';
import { State } from '../../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../../shared-functions';
import { OpenModal, openModalAction } from '../../../../../form-modal/actions';
import { editLifeAssureDetails } from './life-assured-edit';
import { UpdateFormDetails, updateFormDetailsAction } from '../actions/update-form-details';

interface BaseProps {
    formDetails?: FormDetails;
    updateFormDetails?: UpdateFormDetails;
    disableEditButton?: boolean;
}
interface Props extends BaseProps, StyledComponent {
    readonly openModal: OpenModal;
}

const medicalAidDetailsView: React.FunctionComponent<Props> = ({
    classes,
    formDetails,
    updateFormDetails,
    openModal,
    disableEditButton,
}: Props) => {
    const edit = () => {
        const details = formDetails || {};
        editLifeAssureDetails(openModal, details, 5, updateFormDetails);
    };
    const medicalAidDetails = formDetails?.lifeAssured?.medicalAidDetails;

    return (
        <React.Fragment>
            <Grid container justify="space-between" className={classes.marginTop2}>
                <Grid container item xs={10} alignItems="center">
                    <Typography style={{ padding: 10 }} className={classes.heading} variant="body1">
                        Medical Aid details
                    </Typography>
                </Grid>
            </Grid>
            <Paper className={classes.paper}>
                <Grid container item xs={12} alignItems="center" justify="flex-end">
                    <Tooltip title="Edit Medical Aid Details">
                        <Button
                            id="EditPersonalDetails"
                            color="secondary"
                            onClick={edit}
                            disabled={!disableEditButton}
                        >
                            <Edit />
                        </Button>
                    </Tooltip>
                </Grid>
                <Grid container justify="flex-start">
                    <Grid item xs={12} container justify="flex-start">
                        <Grid item xs={4} lg={12}>
                            <LabeledValue
                                label="Life Assured has medical aid?"
                                value={medicalAidDetails?.hasMedicalAid}
                            />
                        </Grid>
                        {medicalAidDetails?.hasMedicalAid === 'Yes' && (
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Medical aid number"
                                    value={medicalAidDetails?.lifeAssuredMedicalAidNumber}
                                />
                            </Grid>
                        )}
                        {medicalAidDetails?.hasMedicalAid === 'Yes' && (
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Medical aid name"
                                    value={medicalAidDetails?.lifeAssuredMedicalAidName}
                                />
                            </Grid>
                        )}
                        {medicalAidDetails?.hasMedicalAid === 'Yes' && (
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Date Joined"
                                    value={getDisplayDate(
                                        medicalAidDetails?.lifeAssuredMedicalDateJoined
                                    )}
                                />
                            </Grid>
                        )}
                        {medicalAidDetails?.hasMedicalAid === 'Yes' && (
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Is the life assured a main member"
                                    value={medicalAidDetails?.isLifeAssuredMainMember}
                                />
                            </Grid>
                        )}
                    </Grid>
                    {medicalAidDetails?.isLifeAssuredMainMember === 'No' && (
                        <Grid item xs={4} lg={3}>
                            <LabeledValue
                                label="Main member Title"
                                value={medicalAidDetails?.lifeAssuredTitle}
                            />
                        </Grid>
                    )}
                    {medicalAidDetails?.isLifeAssuredMainMember === 'No' && (
                        <Grid item xs={4} lg={3}>
                            <LabeledValue
                                label="Main member full name"
                                value={medicalAidDetails?.lifeAssuredName}
                            />
                        </Grid>
                    )}
                    {medicalAidDetails?.isLifeAssuredMainMember === 'No' && (
                        <Grid item xs={4} lg={3}>
                            <LabeledValue
                                label="Main member surname"
                                value={medicalAidDetails?.lifeAssuredSurname}
                            />
                        </Grid>
                    )}
                    {medicalAidDetails?.isLifeAssuredMainMember === 'No' && (
                        <Grid item xs={4} lg={3}>
                            <LabeledValue
                                label="Main member ID"
                                value={medicalAidDetails?.lifeAssuredIdNumber}
                            />
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

const mapStateToProps = (state: State, ownProps: BaseProps) => ({
    formDetails: ownProps.formDetails || getFormDetails(state),
    disableEditButton: ownProps.disableEditButton || false,
});

const mapDispatchToProps = {
    openModal: openModalAction,
    updateFormDetails: updateFormDetailsAction,
};

export const MedicalAidDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(medicalAidDetailsView));
