import React from 'react'; // eslint-disable-line

import { Button, Divider, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { OpenDialog, openDialogAction } from '../../../../../confirmation-dialog';
import { Section } from '../../../../../controls/section';
import { EditFormModal } from '../../../../../form-modal';
import { OpenEditModal, openEditModalAction } from '../../../../../form-modal/actions';
import { State } from '../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../styles/styles';
import {
    DeleteAndSubmitExecutorRecord,
    deleteAndSubmitExecutorRecordThunk,
    UpdateAndSubmitExecutorRecord,
    updateAndSubmitExecutorRecordThunk,
} from '../actions/submit-details';
import { ContactType, Executor, getDeathClaimContactDetails } from '../contact-details-reducer';
import { OtherContactDetailsEditView } from './other-contact-details-edit';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { ButtonWrapper } from '../../../../../forms';

interface OwnProps {}

interface StateProps {
    readonly executors: Executor[];
    isReadOnly: boolean;
}

interface DispatchProps {
    deleteExecutor: DeleteAndSubmitExecutorRecord;
    openDialog: OpenDialog;
    openEditModal: OpenEditModal;
    updateExecutor: UpdateAndSubmitExecutorRecord;
}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const ClaimantEditFormModal = EditFormModal<Executor>();
const executorDetailsView: React.FunctionComponent<Props> = ({
    executors,
    classes,
    deleteExecutor,
    openDialog,
    openEditModal,
    updateExecutor,
}: Props) => {
    if (executors.length === 0) {
        return null;
    }

    return (
        <Section title={`Executors' Contact Details`}>
            {executors.map((executor, index) => {
                return (
                    <React.Fragment key={`executor-contact-${index}`}>
                        {index > 0 ? (
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        ) : (
                            ''
                        )}
                        <Grid item xs={12}>
                            <Grid container spacing={0} justify="space-between">
                                <Grid container spacing={0} item xs={8} lg={10}>
                                    <Typography variant="subtitle2">
                                        Executor {index + 1}
                                    </Typography>
                                </Grid>
                                <ButtonWrapper>
                                    <Grid item xs={4} lg={2} className={classes.rightAlign}>
                                        <Tooltip title="Edit Executors' Contact Details">
                                            <Button
                                                id="EditExecutorDetails"
                                                color="secondary"
                                                onClick={() => editExecutor(index)}
                                            >
                                                <Edit fontSize="small" />
                                            </Button>
                                        </Tooltip>
                                        {deleteExecutorButton(
                                            index,
                                            deleteExecutor,
                                            openDialog,
                                            executor.id
                                        )}
                                    </Grid>
                                </ButtonWrapper>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify="flex-start">
                                <Grid item xs={3} lg={2}>
                                    <LabeledValue label="Name" value={executor.firstName} />
                                </Grid>
                                <Grid item xs={3} lg={2}>
                                    <LabeledValue label="Surname" value={executor.lastName} />
                                </Grid>
                                <Grid item xs={3} lg={2}>
                                    <LabeledValue
                                        label="Telephone number"
                                        value={executor.telephoneNumber}
                                    />
                                </Grid>
                                <Grid item xs={3} lg={2}>
                                    <LabeledValue
                                        label="Cellphone number"
                                        value={executor.cellphoneNumber}
                                    />
                                </Grid>
                                <Grid item xs={3} lg={2}>
                                    <LabeledValue
                                        label="Email address"
                                        value={executor.emailAddress}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                );
            })}
        </Section>
    );

    function editExecutor(index: number) {
        const recordIndex = index || 0;
        openEditModal({
            modalContent: (
                <ClaimantEditFormModal
                    recordDetailView={
                        <OtherContactDetailsEditView contactType={ContactType.Executor} isEdit />
                    }
                    records={executors}
                    updateRecord={(record, index) => updateExecutor(index, record)}
                />
            ),
            modalData: {
                recordIndex,
            },
        });
    }
};

const deleteExecutorButton = (
    index: number,
    deleteExecutor: DeleteAndSubmitExecutorRecord,
    openDialog: OpenDialog,
    id: any
) => {
    const confirmDelete = () => {
        openDialog({
            content: {
                title: 'Delete Executor Contact',
                body: 'Are you sure you would like to delete the selected Executor Contact?',
            },
            yesAction: {
                action: async () => {
                    await deleteExecutor(index);
                },
            },
        });
    };

    return (
        <Tooltip title="Delete Executor Contact Details">
            <Button id="DeleteExecutorDetails" color="secondary" onClick={confirmDelete}>
                <Delete />
            </Button>
        </Tooltip>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    executors: getDeathClaimContactDetails(state).executors,
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    deleteExecutor: (index: number) => dispatch(deleteAndSubmitExecutorRecordThunk(index)),
    openDialog: options => dispatch(openDialogAction(options)),
    openEditModal: options => dispatch(openEditModalAction(options)),
    updateExecutor: (index: number, executor: Executor) =>
        dispatch(updateAndSubmitExecutorRecordThunk(index, executor)),
});

export const ExecutorDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(executorDetailsView));
