export const CauseOfClaim = [
    { value: 'Death - IEB', label: 'Death - IEB' },
    { value: 'Death - Funeral Cover', label: 'Death - Funeral Cover' },
    { value: 'Death - Life Cover', label: 'Death - Life Cover' },
    { value: 'Death - Contribution Waiver', label: 'Death - Contribution Waiver' },
];
export const NaturalCauseOfDeath = [
    { value: '12', label: 'Tuberculosis' },
    { value: '47', label: 'Meningitis' },
    { value: '62', label: 'Encephalitis' },
    { value: '70', label: 'Hepatitis' },
    { value: '99', label: 'Venereal Disease' },
    { value: '140', label: 'Cancer Of The Lip' },
    { value: '141', label: 'Cancer Of The Tongue' },
    { value: '142', label: 'Cancer Of The Salivary Glands' },
    { value: '143', label: 'Cancer Of The Gum' },
    { value: '145', label: 'Cancer Of The Mouth' },
    { value: '149', label: 'Cancer Of The Pharynx' },
    { value: '150', label: 'Cancer Of The Oesophagus' },
    { value: '151', label: 'Cancer Of  The Stomach' },
    { value: '152', label: 'Cancer Of The Small Intestine' },
    { value: '153', label: 'Cancer Of The Colon' },
    { value: '154', label: 'Cancer Of The Rectum' },
    { value: '155', label: 'Cancer Of The Liver' },
    { value: '156', label: 'Cancer Of The Gall-Bladder' },
    { value: '157', label: 'Cancer Of The Pancreas' },
    { value: '160', label: 'Cancer Of The Nasal Cavities Or Sinus' },
    { value: '161', label: 'Cancer Of The Larynx' },
    { value: '164', label: 'Cancer Of The Thymus' },
    { value: '165', label: 'Cancer Of The Lung' },
    { value: '166', label: 'Cancer Of The Trachea' },
    { value: '170', label: 'Cancer Of The Bone' },
    { value: '171', label: 'Cancer Of The Connective Soft Tissue' },
    { value: '172', label: 'Cancer Of The Skin' },
    { value: '174', label: 'Cancer Of The Breast' },
    { value: '179', label: 'Cancer Of The Uterus' },
    { value: '180', label: 'Cancer Of The Cervix' },
    { value: '181', label: 'Cancer Of The Placenta' },
    { value: '183', label: 'Cancer Of  The Ovary' },
    { value: '185', label: 'Cancer Of The Prostate' },
    { value: '186', label: 'Cancer Of The Testis' },
    { value: '187', label: 'Cancer Of The Penis' },
    { value: '188', label: 'Cancer Of The Bladder' },
    { value: '189', label: 'Cancer Of The Kidney' },
    { value: '190', label: 'Cancer Of The Eye' },
    { value: '191', label: 'Cancer Of The Brain' },
    { value: '193', label: 'Cancer Of The Thyroid' },
    { value: '196', label: 'Cancer Of The Lymph Nodes' },
    { value: '208', label: 'Leukaemia' },
    { value: '234', label: 'Cancer' },
    { value: '243', label: 'Hypothyroidism' },
    { value: '246', label: 'Disorders Of Thyroid' },
    { value: '250', label: 'Diabetes' },
    { value: '251', label: 'Disorders Of Pancreatic' },
    { value: '269', label: 'Malnutrition' },
    { value: '285', label: 'Anaemia' },
    { value: '311', label: 'Depression' },
    { value: '319', label: 'Mental Retardation' },
    { value: '331', label: 'Dementia / Alzheimers' },
    { value: '343', label: 'Cerebral Palsy' },
    { value: '344', label: 'Paralysis' },
    { value: '348', label: 'Brain Tumour' },
    { value: '359', label: 'Muscular Dystrophy' },
    { value: '398', label: 'Rheumatic Fever' },
    { value: '401', label: 'Hypertension' },
    { value: '410', label: 'Heart Attack / Myocardial Infarction' },
    { value: '414', label: 'Ischaemic Heart Disease' },
    { value: '428', label: 'Heart Failure / Cardiac Failure' },
    { value: '437', label: 'Stroke / Cerebral Infarction' },
    { value: '467', label: 'COVID-19' },
    { value: '486', label: 'Pneumonia' },
    { value: '496', label: 'Chronic Airways Obstruction' },
    { value: '508', label: 'Respiratory Failure' },
    { value: '536', label: 'Disorders Of The Stomach / Ulcers' },
    { value: '542', label: 'Appendicitis' },
    { value: '550', label: 'Hernia' },
    { value: '571', label: 'Liver Disease & Cirrhosis' },
    { value: '586', label: 'Renal Failure' },
    { value: '593', label: 'Kidney Failure' },
    { value: '646', label: 'Complications Of Pregnancy' },
    { value: '714', label: 'Rheumatoid Arthritis' },
    { value: '715', label: 'Osteoarthrosis' },
    { value: '799', label: 'Other' },
];
export const UnnaturalCauseOfDeath = [
    { value: '798', label: 'Other' },
    { value: '806', label: 'Train Accident' },
    { value: '811', label: 'Motor Vehicle Accident' },
    { value: '826', label: 'Bicycle Accident' },
    { value: '829', label: 'Motor Bike Accident' },
    { value: '835', label: 'Drowning' },
    { value: '837', label: 'Burning' },
    { value: '844', label: 'Aeroplane, Helicopter Accident' },
    { value: '849', label: 'Mine Accident' },
    { value: '858', label: 'Drug Overdose' },
    { value: '860', label: 'Alchohol Overdose' },
    { value: '880', label: 'Falling Down Stairs' },
    { value: '889', label: 'Murder / Homicide' },
    { value: '899', label: 'Accident Caused By Fire' },
    { value: '907', label: 'Struck By Lightening' },
    { value: '910', label: 'Accidental Drowning' },
    { value: '959', label: 'Suicide / Self Inflicted Injury' },
    { value: '960', label: 'Assault / Rape' },
    { value: '963', label: 'Strangulation' },
];

export const AllCausesOfDeath = [...NaturalCauseOfDeath, ...UnnaturalCauseOfDeath];
