import { createStyles, Theme, WithStyles } from '@material-ui/core';

export type StyledComponent = WithStyles<typeof styles>;

export default function styles(theme: Theme) {
    return createStyles({
        paper: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
        button: {
            margin: theme.spacing(),
        },
        spacedTypography: {
            marginBottom: theme.spacing(2),
        },
    });
}
