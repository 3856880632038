import React from 'react'; // eslint-disable-line

import { Grid, Typography, withStyles } from '@material-ui/core';
import { getFullName, getDisplayDate } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { PersonalDetails } from '../../../../../life-assured-details/reducer';

type InnerProps = StyledComponent & {
    hideTaxno?: boolean;
    policyOwnerDetails: PersonalDetails;
};

const personalDetailsView: React.FunctionComponent<InnerProps> = ({
    classes,
    hideTaxno,
    policyOwnerDetails,
}: InnerProps) => {
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography variant="h5">{getFullName(policyOwnerDetails)}</Typography>
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="ID Number" value={policyOwnerDetails.idNumber} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Date of Birth"
                    value={getDisplayDate(policyOwnerDetails.dateOfBirth, 'dd LLLL yyyy')}
                />
            </Grid>
            {!hideTaxno && (
                <Grid item xs={6} lg={3}>
                    <LabeledValue
                        label="Income Tax Number"
                        value={policyOwnerDetails.taxReferenceNumber}
                    />
                </Grid>
            )}
        </React.Fragment>
    );
};

export const PersonalDetailsView = withStyles(styles)(personalDetailsView);
