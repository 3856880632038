import { isNil } from 'lodash';
import * as rsaIdParser from 'south-african-id-parser';

interface ValueObject {
    // eslint-disable-next-line
    value: UserInput;
}

export type UserInput = string | boolean | ValueObject | Date | null | typeof undefined;
export type ValidationResult = string | typeof undefined;
export type SyncValidator = (value: UserInput, values?: any) => ValidationResult;
export type AsyncValidator = (value: UserInput) => Promise<ValidationResult>;
export type Validator = SyncValidator | AsyncValidator;

export * from './utility-functions';
export * from './conditional-validator';
export * from './regex-validator';
export * from './error-object';
export * from './validate-any';
export * from './validate-all-or-none';

export const REQUIRED_VALIDATION_MESSAGE = 'This is a required field';
export const RSA_ID_VALIDATION_MESSAGE = 'Invalid RSA ID Number';
export const requiredValidator = customRequired(REQUIRED_VALIDATION_MESSAGE);
export const conditionalRequired = (condition: (values: any) => boolean) =>
    conditionalCustomRequired(REQUIRED_VALIDATION_MESSAGE, condition);

export function customRequired(message: string): SyncValidator {
    return function validateRequired(value: UserInput): ValidationResult {
        let _value = value;
        if (typeof _value === 'boolean') {
            return undefined;
        }
        if (typeof _value === 'string') {
            _value = _value.trim();
        }
        if (typeof _value === 'object' && _value !== null) {
            if (_value instanceof Date) {
                return undefined;
            }
            if (!isNil(_value.value)) {
                _value = _value.value;
            } else {
                return message;
            }
        }
        return _value ? undefined : message;
    };
}

export function conditionalCustomRequired(
    message: string,
    condition: (values: any) => boolean
): SyncValidator {
    return function validateRequired(value: UserInput, values: any): ValidationResult {
        if (condition(values)) {
            return customRequired(message)(value);
        }

        return undefined;
    };
}

export const rsaIdNumberValidator = rsaIdValidation(RSA_ID_VALIDATION_MESSAGE);
export const conditionalRsaIdNumberValidator = (condition: (values: any) => boolean) =>
    conditionalIdNumberValidation(RSA_ID_VALIDATION_MESSAGE, condition);
function rsaIdValidation(message: string): SyncValidator {
    return function validateRsaIdNumber(value: UserInput): ValidationResult {
        const _value = value;
        let validRsaId = false;
        if (!_value) {
            return undefined;
        }
        if (_value && typeof _value === 'string') {
            validRsaId = rsaIdParser.validate(_value);
        }

        return validRsaId ? undefined : message;
    };
}

function conditionalIdNumberValidation(
    message: string,
    condition: (values: any) => boolean
): SyncValidator {
    return function validateRsaIdNumber(value: UserInput, values: any): ValidationResult {
        if (condition(values)) {
            return rsaIdValidation(message)(value);
        }
        return undefined;
    };
}

const validInitialsRegex = /^([A-Z][ .])*$|^([A-Z])*$|^([a-z][ .])*$|^([a-z])*$/;
const validNameRegex = /^[a-zA-Z]+(([' -][a-zA-Z ])?[a-zA-Z]*)*$/;
const invalidNameMessage = 'Field contains invalid characters';

export function isName(value: UserInput): ValidationResult {
    return validateRegex(validNameRegex, value);
}

export function isInitials(value: UserInput): ValidationResult {
    return validateRegex(validInitialsRegex, value);
}

function validateRegex(regex: RegExp, value: UserInput): ValidationResult {
    if (value === undefined || isNil(value)) {
        return undefined;
    }

    if (typeof value === 'string') {
        value = value.trim();
        if (value === '') {
            return undefined;
        }
    }
    const _value = value.toString();
    return regex.test(_value) ? undefined : invalidNameMessage;
}

export function mustBeNumber(value: UserInput): ValidationResult {
    const error = 'Must be a number';
    if (value === undefined || value === null) {
        return undefined;
    }
    if (isNil(value)) {
        return error;
    }
    if (typeof value === 'boolean') {
        return error;
    }
    if (typeof value === 'string') {
        value = value.trim();
        if (value === '') {
            return error;
        }
    }
    const _value = Number(value);
    return isNaN(_value) ? error : undefined;
}

export function minValue(min: number, property = 'minimum amount'): SyncValidator {
    return function validateMinValue(value: UserInput) {
        const _value = Number(value);
        if (isNaN(_value)) {
            return mustBeNumber(value);
        }
        return _value >= min
            ? undefined
            : `Amount should be greater or equal to the ${property}: ${min}`;
    };
}

export function maxValue(max: number, property = 'maximum amount'): SyncValidator {
    return function validateMaxValue(value: UserInput) {
        const _value = Number(value);
        if (isNaN(_value)) {
            return mustBeNumber(value);
        }
        return _value <= max
            ? undefined
            : `Amount should be less or equal to the ${property}: ${max}`;
    };
}

export function maximumNumber(max: number, message = ''): SyncValidator {
    return function validateMaximumNumber(value: UserInput) {
        const _value = Number(value);
        if (isNaN(_value)) {
            return mustBeNumber(value);
        }
        return _value <= max ? undefined : `${message} ${max}`;
    };
}

export function maximumLength(maxLength: number): SyncValidator {
    return function validateMaxLength(value: UserInput) {
        if (typeof value === 'string') {
            if (value.length > maxLength) {
                return `This field should not exceed ${maxLength} characters`;
            }
        }
        return undefined;
    };
}

export function composeValidators(...validators: SyncValidator[]): SyncValidator {
    return function validateAll(value: UserInput, values?: any): ValidationResult {
        const errors = [];
        for (const validator of validators) {
            const error = validator(value, values);
            if (error) {
                errors.push(error);
            }
        }
        return errors.join('\n');
    };
}
