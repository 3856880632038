import { getDeathClaimEventDetails } from '../../../../../claim/reducers/claim-reducers/death-claim-reducer';
import React from 'react'; // eslint-disable-line
import { GenericForm, FormErrors } from '../../../../../forms';
import { StyledComponent } from '../../../../../claim-audit-trail/styles';
import { updateClaimDetailsAction, UpdateClaimDetails } from '../actions/update-claims-details';
import { OnSubmitSuccessProps } from '../../../../../workflow-navigation';
import { withStyles } from '@material-ui/styles';
import styles from '../../../../../styles/styles';
import { State } from '../../../../../redux/root-reducer';
import { connect } from 'react-redux';
import { ClaimsDetailsViewEdit } from './claim-details-view-edit';
import { DeathClaimDetails } from '../reducer';

interface Props extends OnSubmitSuccessProps, StyledComponent {
    updateClaimDetails: UpdateClaimDetails;
    claimsEventDetails: DeathClaimDetails;
}

const ClaimsDetailsForm = GenericForm<DeathClaimDetails>();
const claimDetailsEditModal: React.FunctionComponent<Props> = ({
    claimsEventDetails,
    updateClaimDetails,
    ...rest
}: Props) => {
    const onSubmit = (claimsEventDetails: DeathClaimDetails) => {
        return updateClaimDetails(claimsEventDetails);
    };
    return (
        <ClaimsDetailsForm onSubmit={onSubmit} initialValues={claimsEventDetails} {...rest}>
            <ClaimsDetailsViewEdit />
            <FormErrors />
        </ClaimsDetailsForm>
    );
};
const mapDispatchToProps = {
    updateClaimDetails: updateClaimDetailsAction,
};
const mapStateToProps = (state: State) => ({
    claimsEventDetails: getDeathClaimEventDetails(state).deathClaimDetails || {},
});
export const ClaimDetailsEditModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(claimDetailsEditModal));
