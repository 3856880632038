import React from 'react'; // eslint-disable-line

import { Typography, withStyles } from '@material-ui/core';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { getDeathClaimEventDetails } from '../../../../claim/reducers/claim-reducers/death-claim-reducer';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { RiskPolicyAssessmentDetails } from '../death-assessment-details-reducer';
import { AddDeathAssessmentDecisionProps } from './risk-assessment-decision-add';
import { RiskBenefitAssessmentDetailsView } from './risk-benefit-assessment-details';
import { DeathClaimDetails } from '../../../synopsis/death/claim-event-details/reducer';

interface OwnProps {
    riskPolicyAssessmentDetails: RiskPolicyAssessmentDetails[];
    claimDetails?: DeathClaimDetails | undefined;
    deathAssessmentDecisionAddView:
        | React.FunctionComponent<AddDeathAssessmentDecisionProps>
        | React.ComponentClass<AddDeathAssessmentDecisionProps>;
}

interface StateProps {
    dateOfDeath?: Date;
}

type Props = OwnProps & StateProps & StyledComponent;
const riskPolicyAssessmentDetailsView: React.FunctionComponent<Props> = ({
    riskPolicyAssessmentDetails,
    classes,
    dateOfDeath,
    deathAssessmentDecisionAddView,
}: Props) => {
    return (
        <React.Fragment>
            {riskPolicyAssessmentDetails &&
                riskPolicyAssessmentDetails.map(riskAssessmentDetails => (
                    <React.Fragment key={riskAssessmentDetails.policyNumber}>
                        <Typography
                            variant="subtitle2"
                            className={`${classes.marginTop} ${classes.marginBottom}`}
                        >
                            Policy Number - {riskAssessmentDetails.policyNumber}
                        </Typography>

                        {riskAssessmentDetails.benefits &&
                            riskAssessmentDetails.benefits.map(benefitAssessmentDetails => (
                                <RiskBenefitAssessmentDetailsView
                                    key={benefitAssessmentDetails.benefitName}
                                    policyNumber={riskAssessmentDetails.policyNumber}
                                    deathAssessmentDecisionAddView={deathAssessmentDecisionAddView}
                                    benefit={benefitAssessmentDetails}
                                />
                            ))}
                    </React.Fragment>
                ))}
        </React.Fragment>
    );
};

function getDateOfDeath(claimEventDetails?: DeathClaimDetails) {
    return claimEventDetails ? claimEventDetails.dateOfEvent : undefined;
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    dateOfDeath: getDateOfDeath(getDeathClaimEventDetails(state).deathClaimDetails),
    claimDetails: getDeathClaimEventDetails(state).deathClaimDetails,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps: MapDispatchToProps<Record<string, any>, OwnProps> = {};

export const RiskPolicyAssessmentDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(riskPolicyAssessmentDetailsView));
