import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import _ from 'lodash';
import { connect } from 'react-redux';
import { State } from '../../redux/root-reducer';
import styles from '../../styles/styles';
import { changeRecordFromChangeSet } from '../claim-audit-trail-functions';
import { addAuditTrailDetailsView } from '../common-views/claim-audit-trail-field-details';
import { mapSectionView } from '../common-views/claim-audit-trail-wrapper';
import { getViewFromList } from './claim-audit-trail-lists-view';
import {
    getUnderwriting,
    Underwriting,
} from '../../assessment/synopsis/common/underwriting/underwriting-reducer';

interface Props {
    changeSet: any;
    underwriting: Underwriting;
}

const auditTrailUnderwritingDetailsView: React.FunctionComponent<Props> = ({
    changeSet,
    underwriting,
}: Props) => {
    const returnView: React.ReactNode[] = [];
    const detailsKey = 'details';
    const details: any[] = changeSet[detailsKey];
    if (details) {
        Object.keys(details).forEach(policyIndex => {
            if (!isNaN(Number(policyIndex))) {
                const detailsObjectKey = 'details';
                const policyName = `Policy Number - ${underwriting[detailsObjectKey][policyIndex].policyDetails.policyNumber}`;
                const postUnderwritingChangesKey = 'postUnderwritingChanges';
                const PostUnderwritingChangesKey = 'PostUnderwritingChanges';
                const postUnderwritingChanges =
                    details[policyIndex][postUnderwritingChangesKey] ||
                    details[policyIndex][PostUnderwritingChangesKey];
                if (postUnderwritingChanges) {
                    getViewFromList(
                        postUnderwritingChanges,
                        returnView,
                        'Post Underwriting Changes',
                        [policyName]
                    );
                }

                const nonDisclosuresKey = 'nonDisclosures';
                const NonDisclosuresKey = 'NonDisclosures';
                const nonDisclosures =
                    details[policyIndex][nonDisclosuresKey] ||
                    details[policyIndex][NonDisclosuresKey];
                if (nonDisclosures) {
                    getViewFromList(nonDisclosures, returnView, 'Non-Disclosures', [policyName]);
                }

                const policyDetailsKey = 'policyDetails';
                const PolicyDetailsKey = 'PolicyDetails';
                const policyDetails =
                    details[policyIndex][policyDetailsKey] ||
                    details[policyIndex][PolicyDetailsKey];
                if (policyDetails) {
                    addAuditTrailDetailsView(returnView, changeRecordFromChangeSet(policyDetails), [
                        policyName,
                        'Policy Details',
                    ]);
                }
            }
        });
    }

    const referralKey = 'referrals';
    const ReferralKey = 'Referrals';
    const referralChangeSet = changeSet[referralKey] || changeSet[ReferralKey];
    if (!_.isEmpty(referralChangeSet)) {
        getViewFromList(referralChangeSet, returnView, 'Referral Details');
    }

    return mapSectionView(returnView);
};

const mapStateToProps = (state: State) => ({
    underwriting: getUnderwriting(state),
});

export const AuditTrailUnderwritingDetailsView = connect(
    mapStateToProps,
    {}
)(withStyles(styles)(auditTrailUnderwritingDetailsView));
