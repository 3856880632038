import { DateTime } from 'luxon';
import { Transform } from './transform';

export class DateTransform extends Transform {
    public handles(object: any): boolean {
        // @ts-ignore
        const dateValue = DateTime.fromISO(object);
        return typeof object === 'string' && object.length > 9 && dateValue.isValid;
    }

    public transform(object: any) {
        if (isNaN(Date.parse(object))) {
            return object;
        }
        return new Date(Date.parse(object));
    }
}
