import { ReduceCollectionRecordAction } from '../../../../../../../redux/collection-record-reducer';
import { DeleteRecordCollectionAction } from '../../../../../../../redux/collection-reducer';
import { DELETE_UNDERWRITING_NON_DISCLOSURE } from './underwriting-non-disclosure-action-types';

export type DeleteUnderwritingNonDisclosureAction = DeleteRecordCollectionAction;

export type DeletePolicyUnderwritingNonDisclosureAction = ReduceCollectionRecordAction<DeleteUnderwritingNonDisclosureAction>;

export const deleteUnderwritingNonDisclosureActionCreator = (
    policyIndex: number,
    underwritingNonDisclosureIndex: number
): DeletePolicyUnderwritingNonDisclosureAction => {
    return {
        type: DELETE_UNDERWRITING_NON_DISCLOSURE,
        payload: {
            type: DELETE_UNDERWRITING_NON_DISCLOSURE,
            index: underwritingNonDisclosureIndex,
        },
        index: policyIndex,
    };
};
