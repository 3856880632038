import React from 'react'; // eslint-disable-line

import { Grid, Button, Tooltip, Typography } from '@material-ui/core';
import { CloudDownloadOutlined, CloudUploadOutlined } from '@material-ui/icons';
import axios from 'axios';
import { openDialogAction } from '../../../../confirmation-dialog';
import { FormModalView } from '../../../../form-modal/views/form-modal';
import { hideLoadingAction, showLoadingAction } from '../../../../forms/loading-screen/actions';
import { env } from '../../../../env';
import { openModalAction } from '../../../../form-modal/actions';
import { Claim } from '../../../../claim';
import { AssessmentPolicy } from '../../../decisions/ra-death/ra-death-assessment-details-reducer';
import { FundNames } from '../../../../lookups';

export function askSynopsisType(
    assessmentPolicies: AssessmentPolicy[],
    claim: Claim,
    fundNames: FundNames[]
) {
    assessmentPolicies.map(a => {
        fundNames.map(fund => {
            if (
                fund.products.some(d =>
                    d.description
                        .toLowerCase()
                        .includes(a.productName.split('(')[0].trim().toLowerCase())
                )
            ) {
                a.fundName = fund.fundName;
            }
            return null;
        });
        return null;
    });

    assessmentPolicies = assessmentPolicies.sort(function (obj1, obj2) {
        return obj1.fundName.localeCompare(obj2.fundName);
    });

    return async (dispatch: any) => {
        dispatch(
            openModalAction({
                modalContent: (
                    <FormModalView
                        formView={() => (
                            <SelectFundView
                                dispatch={dispatch}
                                assessmentPolicies={assessmentPolicies}
                                claimId={claim.claimId}
                            />
                        )}
                        closeButton
                    />
                ),
            })
        );
    };
}

export function trusteeResolution(claimId: string) {
    return async (dispatch: any) => {
        await getSynopsisLink(claimId, dispatch, false, '', true);
    };
}
export function generateDecisionLetterAction() {
    return async (dispatch: any) => {
        await getSynopsisLink('02edd3c0-110b-40f2-b8c4-d2d5f9f74a4f', dispatch, false, '', true);
    };
}
async function getSynopsisLink(
    claimId: string,
    dispatch: any,
    forUnderwriting: boolean,
    policyNumber: any,
    isTrusteeResolution?: boolean
) {
    dispatch(showLoadingAction());
    const generateSynopsisUri = `${
        env().CLAIMS_API
    }/api/Claim/${claimId}/synopsis?policyNumber=${policyNumber}`;
    await axios
        .get(generateSynopsisUri)
        .then(response => {
            if (response.status === 200) {
                dispatch(
                    openModalAction({
                        modalContent: (
                            <FormModalView
                                formView={() => (
                                    <SynopsisPdfView
                                        claimId={claimId}
                                        dispatch={dispatch}
                                        url={response.data}
                                        underwriting={forUnderwriting || false}
                                        isTrusteeResolution={isTrusteeResolution}
                                    />
                                )}
                                closeButton
                            />
                        ),
                    })
                );
            } else {
                showErrorMessage(`Status Code ${response.status}`);
            }
        })
        .catch(error => {
            let errorMessage = '';
            if (error.response) {
                errorMessage = `Error Code ${error.response.status}`;
            } else {
                errorMessage = error.message;
            }
            showErrorMessage(errorMessage);
        })
        .then(() => {
            dispatch(hideLoadingAction());
        });

    function showErrorMessage(error: any) {
        const errorMessage = `Synopsis could not be generated: ${error}`;
        dispatch(
            openDialogAction({
                content: {
                    title: 'Error generating synopsis',
                    body: errorMessage,
                    error: true,
                },
            })
        );
    }
}

interface Props {
    claimId: string;
    dispatch: any;
    url: string;
    underwriting: boolean;
    isTrusteeResolution?: boolean;
}

const SynopsisPdfView: React.FunctionComponent<Props> = ({
    claimId,
    dispatch,
    isTrusteeResolution,
    url,
    underwriting,
}: Props) => {
    const documentName = 'Decision Letter';
    return (
        <Grid container justify="space-between">
            <Grid item xs={6}>
                <Tooltip title="Download pdf">
                    <Button
                        href={url}
                        id="downloadPdf"
                        color="secondary"
                        style={{ padding: 5, textTransform: 'none' }}
                    >
                        <CloudDownloadOutlined style={{ marginRight: 8 }} />
                        Download {documentName}
                    </Button>
                </Tooltip>
            </Grid>
            <Grid item xs={6}>
                <Tooltip title="Upload to Horizon">
                    <Button
                        id="uploadPdf"
                        color="secondary"
                        style={{ padding: 0, textTransform: 'none' }}
                        onClick={() =>
                            dispatch(
                                uploadDocumentAction({
                                    claimId: claimId,
                                    url: url,
                                    underwriting: underwriting,
                                })
                            )
                        }
                    >
                        <CloudUploadOutlined style={{ marginRight: 8 }} />
                        Upload {documentName}
                    </Button>
                </Tooltip>
            </Grid>
        </Grid>
    );
};

interface letterProps {
    assessmentPolicies?: AssessmentPolicy[];
    dispatch: any;
    claimId: string;
}

const SelectFundView: React.FunctionComponent<letterProps> = ({
    assessmentPolicies,
    dispatch,
    claimId,
}: letterProps) => {
    return (
        <Grid container justify="space-between">
            <Grid item xs={12}>
                <Typography
                    variant="subtitle1"
                    style={{ marginTop: '-4px', color: '#000000', fontSize: '20px' }}
                >
                    Select Fund to generate decision letter
                </Typography>

                {assessmentPolicies?.map((assessmentPolicy, assessmentPolicyIndex) => (
                    <Grid item xs={12} key={assessmentPolicyIndex}>
                        <Tooltip title="Click to generate">
                            <Button
                                id="uploadPdf"
                                color="secondary"
                                style={{
                                    margin: '10',
                                    textTransform: 'none',
                                    width: '100%',
                                    justifyContent: 'left',
                                    fontSize: '12px',
                                }}
                                onClick={() =>
                                    dispatch(
                                        async () =>
                                            await getSynopsisLink(
                                                claimId,
                                                dispatch,
                                                false,
                                                assessmentPolicy.policyNumber,
                                                false
                                            )
                                    )
                                }
                            >
                                Fund {assessmentPolicyIndex + 1} - {assessmentPolicy.policyNumber} -{' '}
                                {assessmentPolicy.fundName
                                    ? assessmentPolicy.fundName?.toUpperCase()
                                    : assessmentPolicy.productName?.toUpperCase()}
                            </Button>
                        </Tooltip>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

interface UploadDocumentRequest {
    claimId: string;
    url: string;
    underwriting: boolean;
}

function uploadDocumentAction(request: UploadDocumentRequest) {
    return async (dispatch: any) => {
        dispatch(showLoadingAction());
        const uploadSynopsisUri = `${env().CLAIMS_API}/api/Claim/${
            request.claimId
        }/UploadSynopsis?underwriting=${request.underwriting ? 'true' : 'false'}`;

        await axios
            .post(uploadSynopsisUri, getUploadRequest(request))
            .then(response => {
                if (response.status === 200) {
                    dispatch(
                        openDialogAction({
                            content: {
                                title: 'Synopsis uploaded successfully',
                                body: `Document ID: ${response.data.documentId}`,
                            },
                        })
                    );
                } else {
                    showErrorMessage(`Status Code ${response.status}`);
                }
            })
            .catch(error => {
                let errorMessage = '';
                if (error.response) {
                    errorMessage = `Error Code ${error.response.status}`;
                } else {
                    errorMessage = error.message;
                }
                showErrorMessage(errorMessage);
            })
            .then(() => dispatch(hideLoadingAction()));

        function showErrorMessage(error: any) {
            const errorMessage = `An error (${error}) occured while uploading synopsis, please retry later`;
            dispatch(
                openDialogAction({
                    content: {
                        title: 'Error uploading synopsis',
                        body: errorMessage,
                        error: true,
                    },
                })
            );
        }
    };
}

function getUploadRequest(request: UploadDocumentRequest) {
    const name = request.url.substring(request.url.lastIndexOf('/') + 1, request.url.indexOf('?'));
    return {
        name,
        url: request.url,
        underwriting: request.underwriting,
    };
}
