import { ReduceCollectionRecordAction } from '../../../../../../../redux/collection-record-reducer';
import { AddRecordCollectionAction } from '../../../../../../../redux/collection-reducer';
import { PostUnderwritingChange } from '../post-underwriting-change-model';
import { ADD_POST_UNDERWRITING_CHANGE } from './post-underwriting-change-action-types';

export type AddPostUnderwritingChangeAction = AddRecordCollectionAction<PostUnderwritingChange>;

export type AddPolicyPostUnderwritingChangeAction = ReduceCollectionRecordAction<AddPostUnderwritingChangeAction>;

export const addPostUnderwritingChangeActionCreator = (
    policyIndex: number,
    postUnderwritingChange: PostUnderwritingChange
): AddPolicyPostUnderwritingChangeAction => {
    return {
        type: ADD_POST_UNDERWRITING_CHANGE,
        payload: {
            type: ADD_POST_UNDERWRITING_CHANGE,
            record: postUnderwritingChange,
        },
        index: policyIndex,
    };
};
