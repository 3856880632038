import { createStyles, Theme } from '@material-ui/core';
import { StyleRules, WithStyles } from '@material-ui/core/styles/withStyles';

export type FormHeadingStyledComponent = WithStyles<typeof formHeadingStyles>;
export function formHeadingStyles(theme: Theme): StyleRules {
    return createStyles({
        formHeading: {
            margin: -16,
            marginBottom: theme.spacing(2) - 16,
            padding: 16,
            borderBottomStyle: 'solid',
            borderBottomColor: 'lightgrey',
            borderBottomWidth: 'medium',
        },
    });
}
