import React from 'react'; // eslint-disable-line

import { Grid, Theme, withStyles } from '@material-ui/core';

import { FullWidthTextField } from '../../forms/';
import { requiredValidator } from '../../forms/validations';

interface Props {
    classes: {
        spacer: string;
    };
}

function styles(theme: Theme) {
    return {
        spacer: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
    };
}

const policyCriteria: React.FunctionComponent<Props> = ({ classes }: Props) => {
    return (
        <React.Fragment>
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={6}>
                    <FullWidthTextField name="policyNumber" validate={requiredValidator} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export const PolicyCriteria = withStyles(styles)(policyCriteria);
