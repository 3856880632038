import { Grid, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { AddressDetailsSubForm } from '../../../../../../forms/sub-forms/address-details';
import styles from '../../../../../../styles/styles';

interface Props {
    prefix?: string;
}
const addressDetails = ({ prefix }: Props) => {
    const fullPrefix = !!prefix && prefix !== '' ? `${prefix}.` : '';
    return (
        <Grid container spacing={5}>
            <AddressDetailsSubForm
                addressPropertyName={`${fullPrefix}addressDetails`}
                addressType="street"
            />
        </Grid>
    );
};

export const AddressDetailsEdit = withStyles(styles)(addressDetails);
