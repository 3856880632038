import { Grid, Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { titleCase } from '../../../../shared-functions';

interface Props {
    cessionaryType?: string;
}
export const CessionTypeValue = ({ cessionaryType }: Props) => {
    return (
        <Grid container>
            <Grid item xs={2} lg={1}>
                <Warning style={{ color: '#ff6700' }} fontSize="small" />
            </Grid>
            <Grid item xs={10} lg={11}>
                <Typography style={{ marginTop: '-2px', marginLeft: '8px' }}>
                    {titleCase(cessionaryType)}
                </Typography>
            </Grid>
        </Grid>
    );
};
