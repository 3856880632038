import { Divider, Grid, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react'; // eslint-disable-line
import { LabeledValue } from '../../../../../controls/labeled-value';
import { Doctor } from '../../../../../doctor-details/reducer';
import styles, { StyledComponent } from '../../../../../styles/styles';

type InnerProps = StyledComponent & {
    doctors: Doctor[];
};

const doctorsView: React.FunctionComponent<InnerProps> = ({ classes, doctors }: InnerProps) => {
    return (
        <React.Fragment>
            {doctors.map((doctor, index) => {
                return (
                    <React.Fragment key={index}>
                        {index !== 0 && (
                            <Divider
                                className={classNames(classes.marginTop, classes.marginBottom)}
                            />
                        )}
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">Doctor {index + 1}</Typography>
                            </Grid>
                            {(doctor.name || !doctor.initials) && (
                                <Grid item xs={4}>
                                    <LabeledValue label="Name" value={doctor.name} />
                                </Grid>
                            )}
                            {!doctor.name && doctor.initials && (
                                <Grid item xs={4}>
                                    <LabeledValue label="Initials" value={doctor.initials} />
                                </Grid>
                            )}
                            <Grid item xs={4}>
                                <LabeledValue label="Surname" value={doctor.surname} />
                            </Grid>
                            <Grid item xs={4}>
                                <LabeledValue
                                    label="Telephone Number"
                                    value={doctor.telephoneNumber}
                                />
                            </Grid>
                        </Grid>
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

export const DoctorsView = withStyles(styles)(doctorsView);
