import { Grid, Paper, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import {
    Condition,
    False,
    FormHeading,
    FullWidthSearchSelectField,
    True,
    When,
    WhenFieldChanges,
} from '../../../../../forms';
import { CommunicationDetailsSubForm } from '../../../../../forms/sub-forms/communication-details';
import { requiredValidator } from '../../../../../forms/validations';
import { isAMinor } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { BankingDetailsView } from '../../../common/banking-details/views/edit-banking-details';
import { AddressDetailsView } from '../../common/views/edit-address-details';
import { GuardianDetailsEditView } from '../../common/views/edit-guardian-details';
import { OrganisationDetailsView } from '../../common/views/edit-organisation-details';
import { PersonalityDetailsView } from '../../common/views/edit-personality-details';
import { ClaimantTypes } from '../../contact-details/contact-details-reducer';
import { FinancialAdviserDetailsEditView } from '../../contact-details/views/financial-adviser-details-edit';

interface ClaimantProps extends StyledComponent {
    readonly isEdit?: boolean;
}

const ClaimantDetails: React.FunctionComponent<ClaimantProps> = ({
    classes,
    isEdit,
}: ClaimantProps) => {
    return (
        <React.Fragment>
            <Paper className={classes.paper}>
                <FormHeading text="Claimant Details" />
                <Grid container spacing={5} justify="flex-start">
                    <Grid item xs={6}>
                        <FullWidthSearchSelectField
                            name="claimantDetails.claimantType"
                            label="Claimant Type"
                            options={ClaimantTypes}
                            validate={requiredValidator}
                            disabled={isEdit}
                        />
                        <WhenFieldChanges
                            field="claimantDetails.claimantType"
                            set="claimantDetails.personalDetails"
                            to={null}
                        />
                        <WhenFieldChanges
                            field="claimantDetails.claimantType"
                            set="claimantDetails.organisationDetails"
                            to={null}
                        />
                        <WhenFieldChanges
                            field="claimantDetails.claimantType"
                            becomes="Executor"
                            set="claimantDetails.personalDetails.relationshipToLifeAssured"
                            to="Executor"
                        />
                    </Grid>
                    <Condition when="claimantDetails.claimantType" matchesChild>
                        <When is="Individual">
                            <PersonalityDetailsView
                                propertyPrefix="claimantDetails.personalDetails"
                                hideFields={['ownerRelationship']}
                            />
                        </When>
                        <When is="Executor">
                            <PersonalityDetailsView
                                propertyPrefix="claimantDetails.personalDetails"
                                hideFields={[
                                    'ownerRelationship',
                                    'officiallyEmigrated',
                                    'hasFinancialAdviser',
                                    'financialAdviceConsent',
                                    'taxNumber',
                                    'maritalStatus',
                                    'isRsaResident',
                                ]}
                            />
                        </When>
                        <When is="Organisation">
                            <OrganisationDetailsView />
                        </When>
                    </Condition>
                </Grid>
            </Paper>
            <Condition
                when={`claimantDetails.personalDetails.dateOfBirth`}
                passes={dob => isAMinor(dob)}
            >
                <True>
                    <GuardianDetailsEditView />
                </True>
            </Condition>
            <ContactDetails classes={classes} />
            <Condition when="claimantDetails.claimantType" is={['Individual', 'Executor']}>
                <True>
                    <AddressDetailsView classes={classes} propertyPrefix="claimantDetails" />
                    <BankingDetailsView propertyPrefix="claimantDetails.bankingDetails" />
                </True>
                <False>
                    <AddressDetailsView
                        classes={classes}
                        isAnOrganisation
                        propertyPrefix="claimantDetails"
                    />
                    <BankingDetailsView
                        propertyPrefix="claimantDetails.bankingDetails"
                        isAnOrganisation
                    />
                </False>
            </Condition>
            <Condition when="claimantDetails.personalDetails.hasFinancialAdviser" is="Yes">
                <True>
                    <FinancialAdviserDetailsEditView
                        classes={classes}
                        propertyName="financialAdviser"
                    />
                </True>
                <False>
                    <Condition when="claimantDetails.guardianDetails.hasFinancialAdviser" is="Yes">
                        <True>
                            <FinancialAdviserDetailsEditView
                                classes={classes}
                                propertyName="financialAdviser"
                            />
                        </True>
                    </Condition>
                </False>
            </Condition>
        </React.Fragment>
    );
};

const ContactDetails: React.FunctionComponent<StyledComponent> = ({ classes }: StyledComponent) => {
    return (
        <Paper className={classes.paper}>
            <FormHeading text="Contact Details" />
            <Grid container spacing={5} justify="flex-start">
                <CommunicationDetailsSubForm
                    communicationDetailsPropertyName="claimantDetails.communicationDetails"
                    displayOptions={{
                        telephoneNumber: true,
                        cellphoneNumber: true,
                        emailAddress: true,
                    }}
                    required="Any"
                    requiredOptions={{
                        cellphoneNumber: true,
                        emailAddress: true,
                    }}
                />
            </Grid>
        </Paper>
    );
};

export const ClaimantContactDetailsEditView = withStyles(styles)(ClaimantDetails);
