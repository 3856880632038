import { getProperty } from '../claim';
import { collectionReducer } from '../redux/collection-reducer/collection-reducer';
import { combineReducersWithDefault } from '../redux/combine-reducers-with-default';
import { State } from '../redux/root-reducer';
import { Nullable } from '../shared-types';
import { ADD_REINSURER_REFERAL_RECORD, UPDATE_REINSURER_REFERAL_RECORD } from './types';

export interface ReinsurerReferal {
    readonly uniqueId?: string;
    readonly reasonForReferral?: string;
    readonly dateReferred?: Date;
    readonly dateOfDecision?: Date;
    readonly reinsurersDecision?: string;
}

export interface ReinsuranceInputDetails {
    reinsurerReferrals: ReinsurerReferal[];
}

export const defaultReinsuranceInputDetails: ReinsuranceInputDetails = {
    reinsurerReferrals: [],
};

const reinsurerReferralsRecordReducer = collectionReducer<ReinsurerReferal>({
    addAction: ADD_REINSURER_REFERAL_RECORD,
    updateAction: UPDATE_REINSURER_REFERAL_RECORD,
});

export const reinsuranceInputDetailsReducer = combineReducersWithDefault<
    Nullable<ReinsuranceInputDetails>
>(defaultReinsuranceInputDetails, {
    reinsurerReferrals: reinsurerReferralsRecordReducer,
});

export function getReinsuranceInputDetails(state: State): ReinsuranceInputDetails {
    return getProperty(state, 'reinsuranceInputDetails', defaultReinsuranceInputDetails);
}
