import React from 'react'; // eslint-disable-line
import { Grid, Paper, withStyles, Button, Tooltip, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { connect } from 'react-redux';
import { LabeledValue } from '../../../../controls/labeled-value';
import { OpenModal, openModalAction } from '../../../../form-modal/actions';
import { FormModalView } from '../../../../form-modal/views/form-modal';
import styles, { StyledComponent } from '../../../../styles/styles';
import { State } from '../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../shared-functions';
import {
    FuneralParlour,
    getNoticeOfDeath,
} from '../../common/notice-of-death/notice-of-death-reducer';
import { NoticeOfDeathForm } from './notice-of-death-edit-form';
import { EditFuneralParlourView } from '../../common/notice-of-death/views';
import { DisplayAddress } from '../../../../forms/sub-forms/address-details';
import { ButtonWrapper } from '../../../../forms';

type Props = StyledComponent & {
    openModal: OpenModal;
    funeralParlour: FuneralParlour;
    isReadOnly: boolean;
};
const funeralParlourView: React.FunctionComponent<Props> = ({
    classes,
    funeralParlour,
    openModal,
    isReadOnly,
}: Props) => {
    const edit = () => {
        openModal({
            modalContent: (
                <FormModalView
                    formView={() => (
                        <NoticeOfDeathForm
                            title="Funeral Parlour Details"
                            view={<EditFuneralParlourView isDeath />}
                        />
                    )}
                />
            ),
        });
    };
    return (
        <React.Fragment>
            <Grid container justify="flex-start" style={{ marginTop: '4px' }}>
                <Grid item xs={11}>
                    <Typography style={{ marginTop: '8px' }} variant="subtitle2">
                        Funeral Parlour Details
                    </Typography>
                </Grid>
                <ButtonWrapper>
                    <Grid item xs={1} container justify="flex-end">
                        <Tooltip title="Edit Funeral Parour">
                            <Button id="EditFuneralParlour" color="secondary" onClick={edit}>
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            </Grid>
            <Paper className={classes.paper}>
                <Grid container justify="flex-start">
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Designation Number"
                            value={funeralParlour.designationNumber}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Date of Funeral"
                            value={getDisplayDate(funeralParlour.dateOfFuneral)}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Name" value={funeralParlour.name} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Telephone Number"
                            value={funeralParlour.telephoneNumber}
                        />
                    </Grid>
                    {funeralParlour.addressDetails && (
                        <Grid item xs={6} lg={3}>
                            <DisplayAddress
                                label="Address"
                                address={funeralParlour.addressDetails}
                            />
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

const mapStateToProps = (state: State) => ({
    funeralParlour: getNoticeOfDeath(state).funeralParlour || {},
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = { openModal: openModalAction };

export const FuneralParlourDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(funeralParlourView));
