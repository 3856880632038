import axios from 'axios';
import { env } from '../../env';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { logout } from '../authentication-service';
import { setActiveUserActionCreator } from './set-active-user';

const logoutUri = `${env().CLAIMS_API}/api/Logout`;

type LogOutReturn = void;
export type LogOut = () => LogOutReturn;
type LogOutThunk = () => ReduxThunkAction<LogOutReturn>;
export const logOutThunk: LogOutThunk = () => {
    return async dispatch => {
        dispatch(setActiveUserActionCreator());
        try {
            await axios.post(logoutUri);
        } catch (err) {
            console.error(err);
        }
        logout();
    };
};
