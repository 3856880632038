import { AddRecordCollectionAction } from '../../../../../redux/collection-reducer';
import { OtherContact, Executor } from '../contact-details-reducer';
import { ADD_EXECUTOR_RECORD, ADD_OTHER_CONTACT_RECORD } from './types';

export type AddOtherContactRecordAction = AddRecordCollectionAction<OtherContact>;

export const addOtherContactRecordActionCreator = (
    otherContactRecord: OtherContact
): AddOtherContactRecordAction => {
    return {
        type: ADD_OTHER_CONTACT_RECORD,
        record: otherContactRecord,
    };
};

export type AddExecutorRecordAction = AddRecordCollectionAction<Executor>;

export const addExecutorRecordActionCreator = (
    executorRecord: Executor
): AddExecutorRecordAction => {
    return {
        type: ADD_EXECUTOR_RECORD,
        record: executorRecord,
    };
};
