import { Paper, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { AddButton, ButtonWrapper, FormHeading, RecordGrid } from '../../forms';
import { getDisplayAddress } from '../../forms/sub-forms/address-details';
import { Lookups } from '../../lookups';
import { State } from '../../redux/root-reducer';
import { getDisplayDate } from '../../shared-functions';
import styles, { StyledComponent } from '../../styles/styles';
import { Doctor } from '../reducer';
import { DoctorDetailsAddModal } from './doctor-details-add-modal';
import { DoctorDetailsEditModal } from './doctor-details-edit-modal';

type Props = StyledComponent & {
    formHeading: string;
    lookups: Lookups;
    maxRecords?: number;
    records: Doctor[];
    addRecord: (
        doctor: Doctor
    ) => void | Promise<void> | Record<string, any> | Promise<Record<string, any>>;
    updateRecord: (
        doctor: Doctor,
        index: number
    ) => void | Promise<void> | Record<string, any> | Promise<Record<string, any>>;
    deleteRecord: (
        index: number
    ) => void | Promise<void> | Record<string, any> | Promise<Record<string, any>>;
};

const doctorsDetailsGridView: React.FunctionComponent<Props> = ({
    classes,
    formHeading,
    lookups,
    maxRecords,
    records,
    addRecord,
    updateRecord,
    deleteRecord,
}: Props & StyledComponent) => {
    const recordInfo =
        (maxRecords || 0) > 0
            ? {
                  maxRecords: maxRecords || 0,
                  recordCount: records.length,
              }
            : undefined;

    const columns = [
        {
            key: 'name',
            label: "Dr's Name and Surname",
            value: (doctor: Doctor) =>
                !!doctor.surname ? `${doctor.name} ${doctor.surname}` : doctor.name,
            text: true,
        },
        {
            key: 'telephoneNumber',
            label: 'Telephone Number',
        },
        {
            key: 'address',
            label: 'Address',
            value: (doctor: Doctor) => getDisplayAddress(lookups, doctor),
            text: true,
        },
        {
            key: 'dateOfConsultation',
            label: 'Date Of Consultation',
            value: (date?: Date) => getDisplayDate(date),
            text: true,
        },
        {
            key: 'reasonForConsultation',
            label: 'Reason For Consultation',
            text: true,
        },
    ];
    const addView = (
        <DoctorDetailsAddModal
            maxRecords={maxRecords}
            addRecord={record => {
                addRecord(record);
            }}
        />
    );
    const editView = (
        <DoctorDetailsEditModal
            records={records}
            updateRecord={updateRecord}
            deleteRecord={deleteRecord}
        />
    );
    return (
        <Paper className={classes.paper}>
            <ButtonWrapper>
                <FormHeading text={formHeading} />
                <AddButton label="Add Doctor" recordInfo={recordInfo} addView={addView} />
                <RecordGrid
                    tableHeading="List of Doctors"
                    records={records}
                    columns={columns}
                    editView={editView}
                    deleteRecord={index => {
                        deleteRecord(index);
                    }}
                    deleteDialogDetails={{
                        title: "Delete Doctor's Details",
                        body:
                            "Are you sure you would like to delete the selected doctor's details?",
                    }}
                />
            </ButtonWrapper>
        </Paper>
    );
};

const mapStateToProps = (state: State) => ({ lookups: state.claimSession.lookups });

const mapDispatchToProps = {};

export const DoctorsDetailsGridView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(doctorsDetailsGridView));
