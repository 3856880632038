import { Button, Grid, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { getClaim } from '../../../claim';
import { ClaimType } from '../../../claim/claim-types';
import { ButtonWrapper, NonRaDeathWrapper } from '../../../forms';
import { State } from '../../../redux/root-reducer';
import styles from '../../../styles/styles';
import {
    getBlankAssessmentRecord,
    getRaDeathAssessmentDetails,
} from '../../decisions/ra-death/ra-death-assessment-details-reducer';
import {
    askSynopsisType,
    generateDecisionLetterAction,
} from './generate-decision-letter/get-decision-letter-file';
import { getRADeathClaim } from '../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { totalAllocation } from '../../../shared-functions';

function generateLetter({
    classes,
    claimType,
    generateSynopsisAndGetUrl,
    claim,
    assessmentPolicies,
    fundNames,
    decision,
}: any) {
    return (
        <NonRaDeathWrapper>
            <ButtonWrapper>
                <Grid container justify="flex-end" className={classes.marginTop3}>
                    {claimType === ClaimType.RADeath && (
                        <Button
                            id="GenerateSynopsis"
                            color="secondary"
                            variant="contained"
                            className={classes.button}
                            disabled={totalAllocation(decision) !== 100}
                            onClick={() =>
                                generateSynopsisAndGetUrl(assessmentPolicies, claim, fundNames)
                            }
                        >
                            Generate decision letter
                        </Button>
                    )}
                </Grid>
            </ButtonWrapper>
        </NonRaDeathWrapper>
    );
}

const mapStateToProps = (state: State) => {
    const claim = getClaim(state);
    return {
        assessmentPolicies: getRaDeathAssessmentDetails(state).assessmentPolicies,
        claimId: claim.claimId,
        claimType: claim.claimType,
        claim: claim,
        fundNames: state.claimSession.lookups.fundNames,
        decision: getBlankAssessmentRecord(
            getRADeathClaim(state).assessmentDetails.decisions,
            getRADeathClaim(state).dependants,
            state.auth?.name
        ),
    };
};

const mapDispatchToProps = {
    generateSynopsisAndGetUrl: askSynopsisType,
    generateTrusteeResolution: generateDecisionLetterAction,
};

export const GenerateLetter = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(generateLetter));
