import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import { ModalViewProps, MultiEditFormModal } from '../../../../../../form-modal';
import { OpenModal, openModalAction } from '../../../../../../form-modal/actions';
import { ButtonWrapper } from '../../../../../../forms';
import { DisplayAddress } from '../../../../../../forms/sub-forms/address-details';
import { State } from '../../../../../../redux/root-reducer';
import { getCodeDescription, getDisplayDate } from '../../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { UpdateFormDetails, updateFormDetailsAction } from '../../actions/update-form-details';
import { FormDetails, getFormDetails } from '../../form-details-reducer';
import { AddressDetailsEdit } from '../common/edit-address-details';
import { DetailsOfDeathEdit } from './edit-details-of-death';
import { OccupationDetailsEdit } from './edit-occupation-details';
import { DeceasedDetailsEdit } from './edit-personal-details';

const EditModalForm = MultiEditFormModal<FormDetails>();
const EditModalViews: ModalViewProps[] = [
    { name: 'Deceased Details', view: <DeceasedDetailsEdit /> },
    {
        name: 'Deceased Address',
        view: <AddressDetailsEdit prefix="deceasedDetails" />,
    },
    { name: 'Details of Death', view: <DetailsOfDeathEdit /> },
    { name: 'Deceased Occupation', view: <OccupationDetailsEdit /> },
];

interface BaseProps {
    formDetails: FormDetails;
    openModal: OpenModal;
    updateFormDetails: UpdateFormDetails;
}

interface EditProps extends BaseProps {
    index: number;
}

interface Props extends BaseProps, StyledComponent {
    hasDeferredContracts: boolean;
}

const deceasedDetails = ({
    classes,
    formDetails,
    hasDeferredContracts,
    openModal,
    updateFormDetails,
}: Props) => {
    const deceasedDetails = formDetails.deceasedDetails || {};
    const addressDetails = deceasedDetails.addressDetails || {};
    const detailsOfDeath = deceasedDetails.detailsOfDeath || {};
    const edit = () => {
        editDetails({ formDetails, index: 0, openModal, updateFormDetails });
    };
    return (
        <Grid item xs={12} container justify="flex-start" className={classes.marginTop}>
            <Grid item xs={10}>
                <Typography className={classes.heading} variant="body1">
                    Deceased Details
                </Typography>
            </Grid>
            {!hasDeferredContracts && (
                <ButtonWrapper>
                    <Grid item xs={2} className={classes.rightAlign}>
                        <Tooltip title="Edit Deceased's Details">
                            <Button id="EditDeceasedDetails" color="secondary" onClick={edit}>
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            )}
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Title"
                    value={getCodeDescription('title', deceasedDetails.title)}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Initials" value={deceasedDetails.initials} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="First Name" value={deceasedDetails.firstName} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Second Name" value={deceasedDetails.secondName} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Surname" value={deceasedDetails.lastName} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="ID Number" value={deceasedDetails.idNumber} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Date of Birth"
                    value={getDisplayDate(deceasedDetails.dateOfBirth)}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Date of Death"
                    value={getDisplayDate(detailsOfDeath.dateOfDeath)}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Place of Death" value={detailsOfDeath.placeOfDeath} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Cause of Death" value={detailsOfDeath.typeOfDeath} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Condition"
                    value={getCauseOfDeath(
                        detailsOfDeath.causeOfDeath,
                        detailsOfDeath.causeOfDeathDescription
                    )}
                />
            </Grid>
            {detailsOfDeath.typeOfDeath === 'Unnatural' && (
                <React.Fragment>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Reported to Police"
                            value={detailsOfDeath.reportedToPolice}
                        />
                    </Grid>
                    {detailsOfDeath.reportedToPolice === 'Yes' && (
                        <React.Fragment>
                            <Grid item xs={6} lg={3}>
                                <LabeledValue
                                    label="Police Station"
                                    value={detailsOfDeath.policeStation}
                                />
                            </Grid>
                            <Grid item xs={6} lg={3}>
                                <LabeledValue
                                    label="Case Number"
                                    value={detailsOfDeath.caseNumber}
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
            <Grid item xs={6} lg={3}>
                <DisplayAddress label="Deceased's Address" address={addressDetails} />
            </Grid>
        </Grid>
    );
};

function getCauseOfDeath(causeOfDeath?: string, description?: string) {
    if (causeOfDeath === '799' || causeOfDeath === '798') {
        return description;
    }
    return getCodeDescription('causeofdeath', causeOfDeath);
}

export function editDetails({ formDetails, index, openModal, updateFormDetails }: EditProps) {
    openModal({
        modalContent: (
            <EditModalForm
                heading="Update Form Details"
                modalViews={EditModalViews}
                saveAction={updateFormDetails}
            />
        ),
        modalData: {
            screenIndex: index || 0,
            initialValues: formDetails,
        },
    });
}

const mapStateToProps = (state: State) => ({
    formDetails: getFormDetails(state),
    hasDeferredContracts: state.claimSession.hasDeferredContracts,
});

const mapDispatchToProps = {
    openModal: openModalAction,
    updateFormDetails: updateFormDetailsAction,
};

export const DeceasedDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(deceasedDetails));
