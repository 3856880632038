import React from 'react'; // eslint-disable-line
import { DisabilityAssessmentDecisionFields } from '../../income-disability/views/disability-assessment-decision-fields';
import { Grid } from '@material-ui/core';

interface Props {
    noPartialPayment: boolean;
    sumAssured?: number;
}
export const DreadAssessmentDecisionFields: React.FunctionComponent<Props> = ({
    noPartialPayment,
    sumAssured,
}: Props) => {
    return (
        <React.Fragment>
            <Grid item xs={4} />
            <DisabilityAssessmentDecisionFields
                noPartialPayment={noPartialPayment}
                sumAssured={sumAssured}
            />
        </React.Fragment>
    );
};
