import { Button, Grid, Icon, Paper, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { openDialogAction } from '../../confirmation-dialog';
import { GenericForm } from '../../forms';
import { State } from '../../redux/root-reducer';
import { OnSubmitSuccessProps } from '../../workflow-navigation';
import {
    CloseModal,
    closeModalAction,
    RecordView,
    UpdateCurrentModalData,
    updateCurrentModalDataAction,
} from '../actions';
import { getModalData } from '../reducer';
import styles, { StyledComponent } from '../styles';

interface CompoonentState<TValues> {
    readonly records: TValues[];
    readonly recordIndex: number;
}

interface InnerProps<TValues> extends StyledComponent, CompoonentState<TValues> {
    readonly recordDetailView: React.ReactNode | RecordView;
    readonly updateCurrentModalData: UpdateCurrentModalData;
    readonly closeModal: CloseModal;
}

interface Props<TValues> extends OnSubmitSuccessProps, InnerProps<TValues> {
    validate?: (values: TValues) => Record<string, any> | Promise<Record<string, any>>;
}

class ViewFormModalFormWrapper<TValues> extends React.Component<
    Props<TValues>,
    CompoonentState<TValues>
> {
    public constructor(props: Props<TValues>) {
        super(props);
        this.state = {
            records: this.props.records,
            recordIndex: this.props.recordIndex,
        };
    }

    public componentDidUpdate() {
        if (this.state.records.length === 0) {
            this.props.closeModal();
        }
    }

    public render() {
        const Form = GenericForm<TValues>();
        const records = this.state.records;
        const {
            closeModal,
            classes,
            recordDetailView,
            recordIndex,
            updateCurrentModalData,
            ...rest
        }: Props<TValues> = this.props;
        if (records.length === 0) {
            return null;
        }

        let index = this.state.recordIndex;
        if (this.state.recordIndex < 0) {
            index = 0;
        }
        if (this.state.recordIndex >= records.length) {
            index = records.length - 1;
        }

        const initialValue = records[index];

        return (
            <Form initialValues={initialValue} onSubmit={() => undefined} {...rest}>
                {this.viewFormModal({
                    classes,
                    records,
                    recordDetailView,
                    recordIndex,
                    updateCurrentModalData,
                    closeModal,
                })}
            </Form>
        );
    }

    private viewFormModal: React.FunctionComponent<InnerProps<TValues>> = ({
        classes,
        records,
        recordDetailView,
        recordIndex,
        updateCurrentModalData,
        closeModal,
    }: InnerProps<TValues>) => {
        const move = (newIndex: number) => {
            if (newIndex >= 0 && newIndex < records.length) {
                this.setState({
                    recordIndex: newIndex,
                });
                updateCurrentModalData({
                    recordIndex: newIndex,
                });
            }
        };

        const canMovePrevious = () => {
            const newIndex = recordIndex - 1;
            return newIndex >= 0;
        };
        const movePrevious = () => {
            const newIndex = recordIndex - 1;
            move(newIndex);
        };

        const canMoveNext = () => {
            const newIndex = recordIndex + 1;
            return newIndex < records.length;
        };
        const moveNext = () => {
            const newIndex = recordIndex + 1;
            move(newIndex);
        };

        /* eslint-disable indent */
        const detailView =
            typeof recordDetailView === 'function'
                ? (recordDetailView as RecordView)()
                : recordDetailView;
        /* eslint-enable indent */

        return (
            <Paper className={classes.paper}>
                {detailView}
                <Grid container spacing={0} justify="flex-end">
                    <Button
                        id="Close"
                        variant="contained"
                        className={classes.button}
                        onClick={closeModal}
                    >
                        Close
                    </Button>
                    <Button
                        id="MovePrevious"
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        disabled={!canMovePrevious()}
                        onClick={movePrevious}
                    >
                        <Icon>keyboard_arrow_left</Icon>
                    </Button>
                    <Button
                        id="MoveNext"
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        disabled={!canMoveNext()}
                        onClick={moveNext}
                    >
                        <Icon>keyboard_arrow_right</Icon>
                    </Button>
                </Grid>
            </Paper>
        );
    };
}

const mapStateToProps = (state: State) => {
    const modalData = getModalData(state);
    return {
        records: modalData.records || [],
        recordIndex: modalData.recordIndex || 0,
    };
};

const mapDispatchToProps = {
    openDialog: openDialogAction,
    updateCurrentModalData: updateCurrentModalDataAction,
    closeModal: closeModalAction,
};

export function ViewFormModal<TValues>() {
    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        withStyles(styles)(
            ViewFormModalFormWrapper as new (
                props: Props<TValues>
            ) => ViewFormModalFormWrapper<TValues>
        )
    );
}
