import React from 'react'; // eslint-disable-line

import { Button, Grid, Tooltip, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import { Section } from '../../../../../../controls/section';
import { OpenModal, openModalAction } from '../../../../../../form-modal/actions';
import { FormModalView } from '../../../../../../form-modal/views/form-modal';
import { State } from '../../../../../../redux/root-reducer';
import { getDisplayDate, getYesNo } from '../../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { UpdateUnderwritingPolicyDetails, updateUnderwritingPolicyDetailsThunk } from './actions';
import { UnderwritingPolicyDetailsForm } from './underwriting-policy-details-form';
import { UnderwritingPolicyDetails } from './underwriting-policy-details-model';
import { ButtonWrapper } from '../../../../../../forms';

interface OwnProps {
    policyIndex: number;
    policyDetails: UnderwritingPolicyDetails;
    requirements: string[];
    isUnderWriting?: boolean;
}

interface StateProps {
    isReadOnly: boolean;
}

interface DispatchProps {
    updateUnderwritingPolicyDetails: UpdateUnderwritingPolicyDetails;
    openModal: OpenModal;
}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const underwritingPolicyDetailsView: React.FunctionComponent<Props> = ({
    isReadOnly,
    policyIndex,
    policyDetails,
    requirements,
    isUnderWriting,
    updateUnderwritingPolicyDetails,
    openModal,
}: Props) => {
    const EditView = () => (
        <UnderwritingPolicyDetailsForm
            policyDetails={policyDetails}
            requirements={requirements}
            updateUnderwritingPolicyDetails={underwritingPolicyDetails =>
                updateUnderwritingPolicyDetails(policyIndex, underwritingPolicyDetails)
            }
        />
    );
    const openEditView = () => {
        openModal({
            modalContent: <FormModalView formView={EditView} />,
        });
    };
    const editPolicyDetailsButton = (
        <ButtonWrapper>
            <Tooltip title="Edit Policy Details">
                <Button
                    id="EditPolicyDetails"
                    color="secondary"
                    disabled={isUnderWriting}
                    onClick={openEditView}
                >
                    <Edit />
                </Button>
            </Tooltip>
        </ButtonWrapper>
    );
    return (
        <Section
            title="Policy Details"
            gutterElement={isReadOnly ? undefined : editPolicyDetailsButton}
        >
            <Grid item xs={4}>
                <LabeledValue
                    id="newBusinessUploadDate"
                    label="New Business Upload Date"
                    value={getDisplayDate(policyDetails.newBusinessUploadDate)}
                />
            </Grid>
            <Grid item xs={4}>
                <LabeledValue
                    id="counterOfferLetterSignedDate"
                    label="Counter Offer Letter Signed Date"
                    value={getDisplayDate(policyDetails.counterOfferLetterSignedDate)}
                />
            </Grid>
            <Grid item xs={4}>
                <LabeledValue
                    id="commencementDate"
                    label="Commencement Date"
                    value={getDisplayDate(policyDetails.commencementDate)}
                />
            </Grid>
            <Grid item xs={4}>
                <LabeledValue
                    id="replacement"
                    label="Policy Replacement"
                    value={getYesNo(policyDetails.replacement)}
                />
            </Grid>
            <Grid item xs={4}>
                <LabeledValue
                    id="bigT"
                    label="Big T (Tele Underwriting Q & A)"
                    value={getYesNo(policyDetails.bigT)}
                />
            </Grid>
            <Grid item xs={12}>
                <LabeledValue
                    id="requirements"
                    label="Requirements"
                    value={requirements.filter(requirement => requirement).join(', ')}
                />
            </Grid>
        </Section>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    updateUnderwritingPolicyDetails: (
        policyIndex: number,
        underwritingPolicyDetails: UnderwritingPolicyDetails
    ) => dispatch(updateUnderwritingPolicyDetailsThunk(policyIndex, underwritingPolicyDetails)),
    openModal: options => dispatch(openModalAction(options)),
});

export const UnderwritingPolicyDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(underwritingPolicyDetailsView));
