import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import styles, { StyledComponent } from '../../../../../styles/styles';
import { ResultException } from '../rules-reducer';

interface Props extends StyledComponent {
    exceptions: ResultException[];
}

const exceptionsView = ({ classes, exceptions }: Props) => {
    if (!exceptions || exceptions.length < 1) {
        return null;
    }

    return (
        <Grid container className={classes.topAndBottomMargin}>
            <Grid item xs={12}>
                <Typography variant="body1" className={classes.heading}>
                    Exceptions
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="subtitle2" className={classes.label}>
                    Category
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="subtitle2" className={classes.label}>
                    Name
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="subtitle2" className={classes.label}>
                    Reason
                </Typography>
            </Grid>
            {exceptions.map((exception, excpIndex) => {
                return (
                    <React.Fragment key={`exception${excpIndex}`}>
                        <Grid item xs={3}>
                            <Typography className={classes.value}>{exception.category}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography className={classes.value}>{exception.name}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.value}>{exception.reason}</Typography>
                        </Grid>
                    </React.Fragment>
                );
            })}
        </Grid>
    );
};

export const ResultsExceptionsView = withStyles(styles)(exceptionsView);
