import { Button, Grid, Paper, Tooltip, Typography, withStyles } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect, MapDispatchToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { v4 as uuid } from 'uuid';
import { getDeathClaimEventDetails } from '../../../../../claim/reducers/claim-reducers/death-claim-reducer';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { AddFormModal } from '../../../../../form-modal';
import {
    OpenAddModal,
    openAddModalAction,
    OpenModal,
    openModalAction,
} from '../../../../../form-modal/actions';
import { ButtonWrapper, NonRaDeathWrapper } from '../../../../../forms';
import { State } from '../../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import {
    addAndSubmitClaimDetailsRecordThunk,
    AddAndSubmitClaimNotesRecord,
} from '../actions/add-new-notes';
import { DeathClaimNotes } from '../reducer';
import { ClaimNotesViewEdit } from './claim-notes-view-edit';

interface DispatchProps {
    openAddModal: OpenAddModal;
    openModal: OpenModal;
    addClaimNotes: AddAndSubmitClaimNotesRecord;
}
interface OwnProps {}
interface StateProps {
    isReadOnly: boolean;
}
type Props = StateProps &
    StyledComponent &
    DispatchProps &
    OwnProps & {
        claimNotes: DeathClaimNotes[];
    };
const NotesAddFormModal = AddFormModal<DeathClaimNotes>();
const claimDetailsNotes: React.FunctionComponent<Props> = ({
    classes,
    claimNotes,
    addClaimNotes,
    openAddModal,
    isReadOnly,
}: Props) => {
    return (
        <React.Fragment>
            <Grid container justify="flex-start">
                <Grid item xs={11}>
                    <Typography style={{ marginTop: '8px' }} variant="subtitle2">
                        Assesor&apos;s Notes
                    </Typography>
                </Grid>
                <ButtonWrapper>
                    <NonRaDeathWrapper>
                        <Grid item xs={1} container justify="flex-end">
                            <Tooltip title="Add Assesor's Notes">
                                <Button
                                    id="AddAssesorsNotes"
                                    color="secondary"
                                    onClick={() =>
                                        openAddModal({
                                            modalContent: (
                                                <NotesAddFormModal
                                                    blankRecord={{
                                                        id: uuid(),
                                                        dateCaptured: new Date(),
                                                    }}
                                                    recordDetailView={<ClaimNotesViewEdit />}
                                                    addRecord={record => addClaimNotes(record)}
                                                />
                                            ),
                                            modalData: {},
                                        })
                                    }
                                >
                                    <AddCircle />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </NonRaDeathWrapper>
                </ButtonWrapper>
            </Grid>
            {claimNotes && claimNotes.length > 0 && (
                <Paper className={classes.paper}>
                    <Grid container justify="flex-start">
                        {claimNotes.map((note, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <Grid item xs={12} lg={12}>
                                        <LabeledValue
                                            value={getDisplayDate(
                                                note.dateCaptured,
                                                'dd/MM/yyyy HH:mm'
                                            )}
                                            label={false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={12} style={{ marginTop: '-24px' }}>
                                        <LabeledValue
                                            value={note.notesSummary}
                                            label={false}
                                            isUnsafe={true}
                                        />
                                    </Grid>
                                </React.Fragment>
                            );
                        })}
                    </Grid>
                </Paper>
            )}
        </React.Fragment>
    );
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    addClaimNotes: record => dispatch(addAndSubmitClaimDetailsRecordThunk(record)),
    openModal: options => dispatch(openModalAction(options)),
    openAddModal: options => dispatch(openAddModalAction(options)),
});

const mapStateToProps = (state: State) => ({
    claimNotes: getDeathClaimEventDetails(state).claimNotes || [],
    isReadOnly: state.claimSession.readOnly,
});

export const ClaimNotesView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(claimDetailsNotes));
