import { claimSubmit } from '../../../claim';
import { Claim, getClaim } from '../../../claim/reducers';
import { DisabilityClaim } from '../../../claim/reducers/claim-reducers/disability-claim-reducer';
import { DreadClaim } from '../../../claim/reducers/claim-reducers/dread-claim-reducer';
import { updateRecordReduction } from '../../../redux/reductions';
import { ReduxThunkAction } from '../../../redux/redux-actions';
import { State } from '../../../redux/root-reducer';
import {
    BenefitAssessmentDetails,
    PolicyAssessmentDetails,
    AssessmentSummary,
    AssessmentOpinion,
} from '../assessment-details-reducer';

type UpdateBenefitAssessmentReturn = Promise<any>;
export type UpdateBenefitAssessment = (
    benefitAssessment: BenefitAssessmentDetails,
    policyIndex: number,
    benefitIndex: number
) => UpdateBenefitAssessmentReturn;
type UpdateBenefitAssessmentAction = (
    benefitAssessment: BenefitAssessmentDetails,
    policyIndex: number,
    benefitIndex: number
) => ReduxThunkAction<UpdateBenefitAssessmentReturn>;
export const updateBenefitAssessmentAction: UpdateBenefitAssessmentAction = (
    benefitAssessment: BenefitAssessmentDetails,
    policyIndex: number,
    benefitIndex: number
) => async (dispatch, getState) => {
    return await claimSubmit(dispatch, getState, claim =>
        getUpdatedClaim(claim, benefitAssessment, policyIndex, benefitIndex)
    );
};

type UpdateBenefitAssessmentAddSummaryOpinionReturn = Promise<any>;
export type UpdateBenefitAssessmentAddSummaryOpinion = (
    summaryOpinion: any,
    policyIndex: number,
    benefitIndex: number,
    benefitAssessmentDetails: BenefitAssessmentDetails
) => UpdateBenefitAssessmentAddSummaryOpinionReturn;
type UpdateBenefitAssessmentAddSummaryAction = (
    summaryOpinion: any,
    policyIndex: number,
    benefitIndex: number,
    benefitAssessmentDetails: BenefitAssessmentDetails
) => ReduxThunkAction<UpdateBenefitAssessmentAddSummaryOpinionReturn>;
export const updateBenefitAssessmentAddSummaryOpinionAction: UpdateBenefitAssessmentAddSummaryAction = (
    summaryOpinion: any,
    policyIndex: number,
    benefitIndex: number,
    benefitAssessmentDetails: BenefitAssessmentDetails
) => async (dispatch, getState) => {
    const state = getState();
    return await claimSubmit(dispatch, getState, claim =>
        getUpdatedClaim(
            claim,
            getUpdatedBenefitWithSummaryOpinion(
                summaryOpinion,
                state,
                benefitAssessmentDetails,
                policyIndex,
                benefitIndex
            ),
            policyIndex,
            benefitIndex
        )
    );
};

function getUpdatedClaim(
    claim: Claim,
    benefitAssessment: BenefitAssessmentDetails,
    policyIndex: number,
    benefitIndex: number
): Claim {
    if (!('assessmentDetails' in claim)) {
        return claim;
    }

    const assessmentDetails = getUpdatedAssessmentDetails(
        claim.assessmentDetails as PolicyAssessmentDetails[],
        benefitAssessment,
        policyIndex,
        benefitIndex
    );

    return {
        ...claim,
        assessmentDetails,
    };
}

function getUpdatedAssessmentDetails(
    assessmentDetails: PolicyAssessmentDetails[],
    benefitAssessment: BenefitAssessmentDetails,
    policyIndex: number,
    benefitIndex: number
): PolicyAssessmentDetails[] {
    const policyAssessmentDetails = {
        ...assessmentDetails[policyIndex],
        benefits: updateRecordReduction(
            assessmentDetails[policyIndex].benefits,
            benefitAssessment,
            benefitIndex
        ),
    };

    return updateRecordReduction(assessmentDetails, policyAssessmentDetails, policyIndex);
}

function getUpdatedBenefitWithSummaryOpinion(
    summaryOpinion: any,
    state: State,
    benefitAssessment: BenefitAssessmentDetails,
    policyIndex: number,
    benefitIndex: number
): BenefitAssessmentDetails {
    const modalData = state.formModal.activeModal?.modalData;
    const isSummary = modalData.screenIndex === 0;

    const claim = getClaim(state);

    const ourClaim = claim as DisabilityClaim | DreadClaim;

    const assessmentDetails = ourClaim.assessmentDetails;

    const benefitAssessmentDetails = assessmentDetails[policyIndex].benefits[benefitIndex];

    const summaries: AssessmentSummary[] = benefitAssessmentDetails.summaries || [];
    const opinions: AssessmentOpinion[] = benefitAssessmentDetails.opinions || [];

    if (isSummary) {
        summaries.push(summaryOpinion as AssessmentSummary);
    } else {
        opinions.push(summaryOpinion as AssessmentOpinion);
    }

    return {
        ...benefitAssessment,
        summaries: summaries,
        opinions: opinions,
    };
}
