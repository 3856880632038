import { Lazy } from '../../shared-types';
import { WorkflowScreen } from './workflow-screen';

export class WorkflowScreenContainer extends WorkflowScreen {
    private readonly _children: Lazy<WorkflowScreen[]>;
    private readonly _descendants: Lazy<WorkflowScreen[]>;
    private readonly _descendantScreens: Lazy<WorkflowScreen[]>;

    public constructor(
        label: string,
        children: WorkflowScreen[],
        parent?: WorkflowScreen,
        screenHeading?: string,
        canShow?: () => boolean
    ) {
        super(label, undefined, parent, screenHeading, canShow);

        this._children = new Lazy<WorkflowScreen[]>(() =>
            children
                .filter(child => !child.canShow || child.canShow())
                .map(workflowScreen => workflowScreen.cloneWithNewParent(this))
        );

        this._descendants = new Lazy<WorkflowScreen[]>(() => Array.from(this.getDescendants()));

        this._descendantScreens = new Lazy<WorkflowScreen[]>(() =>
            this.descendants().filter(
                descendant => !(descendant instanceof WorkflowScreenContainer)
            )
        );
    }

    public children = () => this._children.value();
    public descendants = () => this._descendants.value();
    public descendantScreens = () => this._descendantScreens.value();

    public cloneWithNewParent(parent?: WorkflowScreen) {
        return new WorkflowScreenContainer(
            this.label,
            this.children(),
            parent,
            this.screenHeading,
            this.canShow
        );
    }

    private *getDescendants(): IterableIterator<WorkflowScreen> {
        for (const child of this.children()) {
            yield child;

            if (child instanceof WorkflowScreenContainer) {
                yield* child.descendants();
            }
        }
    }
}
