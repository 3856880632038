import React from 'react'; // eslint-disable-line

import {
    AddAssessmentDecisionProps,
    AssessmentDecisionAddView,
} from '../../common/views/assessment-decision-add';
import {
    Condition,
    When,
    FullWidthSelectField,
    WhenFieldChanges,
} from '../../../../forms/form-components';
import { ClaimType } from '../../../../claim/claim-types';
import { IncomeAssessmentDecisionFields } from './income-assessment-decision-fields';
import { DisabilityAssessmentDecisionFields } from './disability-assessment-decision-fields';
import { Grid } from '@material-ui/core';
import { requiredValidator } from '../../../../forms/validations';
import { DreadAssessmentDecisionFields } from './dread-assessment-decision-fields';

export const IncomeDisabilityAssessmentDecisionDetailView: React.FunctionComponent<AddAssessmentDecisionProps> = props => {
    return (
        <AssessmentDecisionAddView
            {...props}
            claimSpecificApproveElements={<IncomeDisabilityApproveElements {...props} />}
        />
    );
};

interface Props {
    noPartialPayment: boolean;
    sumAssured?: number;
}

export function IncomeDisabilityApproveElements({
    noPartialPayment,
    sumAssured,
}: Props): React.ReactElement<Props> {
    return (
        <React.Fragment>
            <Grid item xs={4}>
                <FullWidthSelectField
                    name="claimType"
                    options={[
                        { value: ClaimType.IncomeProtector, label: 'Income Protector' },
                        { value: ClaimType.Disability, label: 'Disability' },
                        { value: ClaimType.TerminalIllness, label: 'Terminal Illness' },
                        { value: ClaimType.Retrenchment, label: 'Retrenchment' },
                        { value: ClaimType.EarlyRetirement, label: 'Early Retirement' },
                        { value: ClaimType.Dread, label: 'Dread' },
                    ]}
                    validate={requiredValidator}
                />
                <WhenFieldChanges field="decision" set="claimType" to={null} />
                <WhenFieldChanges field="claimType" set="effectiveDate" to={null} />
                <WhenFieldChanges field="claimType" set="expiryDate" to={null} />
                <WhenFieldChanges field="claimType" set="paymentBasis" to={null} />
                <WhenFieldChanges
                    field="claimType"
                    set="amountPayable"
                    to={(claimType: ClaimType) =>
                        noPartialPayment && claimType === ClaimType.Disability ? sumAssured : null
                    }
                />
                <WhenFieldChanges
                    field="claimType"
                    set="percentagePayable"
                    to={(claimType: ClaimType) =>
                        noPartialPayment && claimType === ClaimType.Disability ? 100 : null
                    }
                />
            </Grid>
            <Condition when="claimType" matchesChild>
                <When is={ClaimType.IncomeProtector}>
                    <IncomeAssessmentDecisionFields sumAssured={sumAssured} />
                </When>
                <When is={ClaimType.Disability}>
                    <DisabilityAssessmentDecisionFields
                        sumAssured={sumAssured}
                        noPartialPayment={noPartialPayment}
                    />
                </When>
                <When is={ClaimType.Dread}>
                    <DreadAssessmentDecisionFields
                        sumAssured={sumAssured}
                        noPartialPayment={noPartialPayment}
                    />
                </When>
            </Condition>
        </React.Fragment>
    );
}
