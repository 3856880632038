import { claimSubmit } from '../../../../../claim';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { NoticeOfDeath } from '../notice-of-death-reducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UpdateNoticeOfDeathReturn = Promise<any>;
export type UpdateNoticeOfDeath = (noticeOfDeath: NoticeOfDeath) => UpdateNoticeOfDeathReturn;
type UpdateNoticeOfDeathAction = (
    noticeOfDeath: NoticeOfDeath
) => ReduxThunkAction<UpdateNoticeOfDeathReturn>;

export const updateNoticeOfDeathAction: UpdateNoticeOfDeathAction = (
    noticeOfDeath: NoticeOfDeath
) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, claim => ({ ...claim, noticeOfDeath }));
    };
};
