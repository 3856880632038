import React from 'react'; // eslint-disable-line

import { FormControlLabel, Radio as MuiRadio } from '@material-ui/core';
import { FieldRenderProps } from 'react-final-form';
import { callAllFunctions, getComponentId, nameToLabel, processMetaForErrors } from '../../';
import { raspberry } from '../../../styles/colours';

type Props = FieldRenderProps<any, any> & {
    label?: React.ReactNode;
    id?: string;
    onChange?: (values?: any) => void;
};

export const Radio: React.FunctionComponent<Props> = ({
    input,
    label,
    id,
    onChange,
    meta,
    ...rest
}: Props) => {
    const { checked, name, ...restInput } = input;
    const { showError } = processMetaForErrors(meta);
    const showErrorColor = (error: boolean) => {
        return error ? raspberry : undefined;
    };
    return (
        <FormControlLabel
            style={{ color: showErrorColor(showError) }}
            control={
                <MuiRadio
                    {...rest}
                    style={{ color: showErrorColor(showError) }}
                    id={getComponentId({ id, name })}
                    name={name}
                    inputProps={restInput}
                    checked={!!checked}
                    onChange={callAllFunctions(onChange, input.onChange)}
                />
            }
            label={nameToLabel({ label, name })}
        />
    );
};
