import { Grid } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { LabeledValue } from '../../../../../controls/labeled-value';
import { DisplayAddress } from '../../../../../forms/sub-forms/address-details';
import { Doctors } from '../reducer';

type Props = {
    doctorsDetails: Doctors;
};
export const MedicalDoctorView: React.FunctionComponent<Props> = ({ doctorsDetails }: Props) => {
    return (
        <Grid container justify="flex-start">
            <Grid item xs={3}>
                <LabeledValue label="Initials" value={doctorsDetails.initials} />
            </Grid>
            <Grid item xs={3}>
                <LabeledValue label="First Name" value={doctorsDetails.name} />
            </Grid>
            <Grid item xs={3}>
                <LabeledValue label="Last Name" value={doctorsDetails.surname} />
            </Grid>
            <Grid item xs={3}>
                <LabeledValue label="Telephone Number" value={doctorsDetails.telephoneNumber} />
            </Grid>
            <Grid item xs={3}>
                <LabeledValue
                    label="Registration Number"
                    value={
                        doctorsDetails.registrationNumber === '0' ||
                        doctorsDetails.registrationNumber === '' ||
                        doctorsDetails.registrationNumber === null
                            ? ''
                            : doctorsDetails.registrationNumber
                    }
                />
            </Grid>
            <Grid item xs={3}>
                <DisplayAddress label="Address" address={doctorsDetails} />
            </Grid>
        </Grid>
    );
};
