import { Divider, Grid, Typography } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { FullWidthRichTextField } from '../../../../../forms';
import { requiredValidator } from '../../../../../forms/validations';

export const ClaimNotesViewEdit = () => {
    return (
        <React.Fragment>
            <Typography variant="h5">Claim Event Note</Typography>
            <Divider></Divider>
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={12}>
                    <FullWidthRichTextField
                        id="notes"
                        name="notesSummary"
                        validate={requiredValidator}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};
