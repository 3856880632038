import React from 'react'; // eslint-disable-line

import { Grid, withStyles, Typography } from '@material-ui/core';
import { Condition, False, FullWidthCheckBoxField } from '../../../../../forms';
import { AddressDetailsSubForm } from '../../../../../forms/sub-forms/address-details';
import styles, { StyledComponent } from '../../../../../styles/styles';

interface AddressProps extends StyledComponent {
    readonly propertyPrefix?: string;
    readonly isAnOrganisation?: boolean;
}

const addressDetails: React.FunctionComponent<AddressProps> = ({
    classes,
    isAnOrganisation,
    propertyPrefix = '',
}: AddressProps) => {
    const fullPropertyPrefix = propertyPrefix && propertyPrefix !== '' ? propertyPrefix + '.' : '';
    return (
        <Grid container spacing={5} justify="flex-start">
            {isAnOrganisation ? (
                <AddressDetailsSubForm
                    addressPropertyName={`${fullPropertyPrefix}organisationDetails.address`}
                    required
                />
            ) : (
                <React.Fragment>
                    <Grid item xs={12}>
                        <Typography className={classes.marginTop} variant="subtitle2">
                            Residential Address
                        </Typography>
                    </Grid>
                    <AddressDetailsSubForm
                        addressPropertyName={`${fullPropertyPrefix}residentialAddress`}
                        addressType="residential"
                        required
                    />
                    <Grid item xs={12}>
                        <Typography className={classes.marginTop} variant="subtitle2">
                            Postal Address
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FullWidthCheckBoxField name={`${fullPropertyPrefix}sameAsResidential`} />
                    </Grid>
                    <Condition when={`${fullPropertyPrefix}sameAsResidential`} is={true}>
                        <False>
                            <AddressDetailsSubForm
                                addressPropertyName={`${fullPropertyPrefix}postalAddress`}
                            />
                        </False>
                    </Condition>
                </React.Fragment>
            )}
        </Grid>
    );
};

export const AddressDetailsEditView = withStyles(styles)(addressDetails);
