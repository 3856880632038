import { Paper, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import {
    ERBPolicyAssessment,
    getERBAssessmentDetails,
} from '../../../../claim/reducers/claim-reducers/early-retirement-claim-reducer';
import { FormHeading } from '../../../../forms';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { GenerateSynopsis } from '../../../synopsis/common/generate-synopsis';
import { AddERBPolicyAssessmentDecisionProps } from './early-retirement-assessment-decision-add';
import { ERBPolicyAssessmentDetailsView } from './early-retirement-policy-assessment-details';

interface OwnProps {
    readonly investmentAssessmentDecisionAddView:
        | React.FunctionComponent<AddERBPolicyAssessmentDecisionProps>
        | React.ComponentClass<AddERBPolicyAssessmentDecisionProps>;
}

interface StateProps {
    readonly assessmentDetails: ERBPolicyAssessment[];
}

type Props = StateProps & OwnProps & StyledComponent;
const erbAssessmentDetailsView: React.FunctionComponent<Props> = ({
    assessmentDetails,
    investmentAssessmentDecisionAddView,
    classes,
}: Props) => {
    return (
        <React.Fragment>
            <Paper className={classes.paper}>
                <FormHeading>Assessment Decisions</FormHeading>
                {assessmentDetails.map((investmentPolicyAssessment, policyIndex) => (
                    <ERBPolicyAssessmentDetailsView
                        key={investmentPolicyAssessment.policyNumber}
                        investmentPolicyAssessment={investmentPolicyAssessment}
                        investmentAssessmentDecisionAddView={investmentAssessmentDecisionAddView}
                        policyIndex={policyIndex}
                    />
                ))}
            </Paper>
            <GenerateSynopsis />
        </React.Fragment>
    );
};

const mapStateToProps = (state: State) => ({
    assessmentDetails: getERBAssessmentDetails(state),
});

const mapDispatchToProps = {};

export const ERBAssessmentDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(erbAssessmentDetailsView));
