import { AnyAction, Reducer } from 'redux';
import { Claim, getClaim, getPropertyFromClaim } from '../../../../claim';
import { AddressDetails } from '../../../../forms/sub-forms/address-details';
import { CommunicationDetails } from '../../../../forms/sub-forms/communication-details';
import { PersonalDetails } from '../../../../life-assured-details/reducer';
import { collectionReducer } from '../../../../redux/collection-reducer';
import { combineReducersWithDefault } from '../../../../redux/combine-reducers-with-default';
import { State } from '../../../../redux/root-reducer';
import { Nullable } from '../../../../shared-types';
import { BankingDetails } from '../../common/banking-details/banking-details';
import {
    ADD_FUNERAL_CLAIMANT,
    DELETE_FUNERAL_CLAIMANT,
    UPDATE_FUNERAL_CLAIMANT,
} from './actions/types';

export interface OccupationDetails {
    status?: string;
    occupation?: string;
    studentNumber?: string;
    institution?: string;
    contactPerson?: string;
    telephoneNumber?: string;
    addressDetails?: AddressDetails;
}

export interface DeathDetails {
    dateOfDeath?: Date;
    placeOfDeath?: string;
    postalCode?: string;
    reportedToPolice?: string;
    policeStation?: string;
    caseNumber?: string;
    causeOfDeath?: string;
    causeOfDeathDescription?: string;
    typeOfDeath?: string;
}

export interface DeceasedDetails extends PersonalDetails {
    addressDetails?: AddressDetails;
    detailsOfDeath?: DeathDetails;
    occupationDetails?: OccupationDetails;
}

export interface Declaration {
    certifiedBy?: string;
    firstName?: string;
    secondName?: string;
    lastName?: string;
    idNumber?: string;
    telephoneNumber?: string;
    hospitalName?: string;
    hospitalContactNo?: string;
    addressDetails?: AddressDetails;
}

export interface FuneralParlour {
    dateOfFuneral?: Date;
    name?: string;
    telephoneNumber?: string;
    addressDetails?: AddressDetails;
}

export interface ContactDetails extends CommunicationDetails {
    workTelephoneNumber?: string;
    homeTelephoneNumber?: string;
}

export interface GuardianDetails extends PersonalDetails {
    relationshipToMinor: string;
    relationshipToMinorDescription: string;
    detailsMatchesID: string;
}
export interface Claimant {
    id?: string;
    deletable?: boolean;
    beneficiary?: string;
    employed?: string;
    employer?: string;
    relationshipToLifeAssured?: string;
    relationshipToLifeAssuredDescription?: string;
    personalDetails?: PersonalDetails;
    contactDetails?: ContactDetails;
    bankingDetails?: BankingDetails;
    addressDetails?: AddressDetails;
    guardianDetails?: GuardianDetails;
}

export const defaultClaimant: Claimant = {
    personalDetails: {},
};

export interface FormDetails {
    deceasedDetails: DeceasedDetails;
    declaration: Declaration;
    funeralParlour: FuneralParlour;
    policyOwner?: Claimant;
    claimants: Claimant[];
}

export const defaultFormDetails: FormDetails = {
    deceasedDetails: {},
    declaration: {},
    funeralParlour: {},
    claimants: [],
};

const deceasedReducer: Reducer<DeceasedDetails, AnyAction> = (
    state: DeceasedDetails = {},
    action: AnyAction
) => {
    switch (action.type) {
        default:
            return state;
    }
};

const declarationReducer: Reducer<Declaration, AnyAction> = (
    state: Declaration = {},
    action: AnyAction
) => {
    switch (action.type) {
        default:
            return state;
    }
};

const funeralParlourReducer: Reducer<FuneralParlour, AnyAction> = (
    state: FuneralParlour = {},
    action: AnyAction
) => {
    switch (action.type) {
        default:
            return state;
    }
};

const policyOwnerReducer: Reducer<Claimant | undefined, AnyAction> = (
    state = {},
    action: AnyAction
) => {
    switch (action.type) {
        default:
            return state;
    }
};

export const claimantsReducer = collectionReducer<Claimant>({
    addAction: ADD_FUNERAL_CLAIMANT,
    deleteAction: DELETE_FUNERAL_CLAIMANT,
    updateAction: UPDATE_FUNERAL_CLAIMANT,
});

export const formDetailsReducer = combineReducersWithDefault<Nullable<FormDetails>>(
    defaultFormDetails,
    {
        deceasedDetails: deceasedReducer,
        declaration: declarationReducer,
        funeralParlour: funeralParlourReducer,
        policyOwner: policyOwnerReducer,
        claimants: claimantsReducer,
    }
);

export function getFormDetails(state: State): FormDetails {
    return getFormDetailsFromClaim(getClaim(state));
}

export function getFormDetailsFromClaim(claim: Claim): FormDetails {
    return getPropertyFromClaim(claim, 'formDetails', defaultFormDetails);
}

export function getDetailsOfDeath(claim: Claim): DeathDetails {
    const formDetails = getFormDetailsFromClaim(claim);
    const deceasedDetails = formDetails.deceasedDetails || {};
    return deceasedDetails.detailsOfDeath || {};
}
