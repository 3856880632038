import {
    IncomeCalculation,
    incomeCalculationsReducer,
} from '../../../assessment/income-calculator/reducer';
import { OccupationalDutiesBreakdown } from '../../../claim-information/reducer';
import { combineReducersWithDefault } from '../../../redux/combine-reducers-with-default';
import { simplePropertyReducer } from '../../../redux/simple-property-reducer';
import { Nullable } from '../../../shared-types';
import { initialCommonClaim, CommonClaim, commonClaimReducers } from './retrenchment-claim-reducer';

export interface IncomeClaim extends CommonClaim {
    readonly previousOccupationalDuties?: Nullable<OccupationalDutiesBreakdown>;
    incomeCalculations?: Nullable<IncomeCalculation[]>;
}

export const initialIncomeClaim: IncomeClaim = initialCommonClaim;

export const incomeClaimReducer = combineReducersWithDefault<IncomeClaim>(initialIncomeClaim, {
    ...commonClaimReducers,
    previousOccupationalDuties: simplePropertyReducer(null),
    incomeCalculations: incomeCalculationsReducer,
});
