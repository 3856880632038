import React from 'react'; // eslint-disable-line

import { Config, FormSubscription } from 'final-form';
import { Form } from 'react-final-form';
import { FormRenderProps } from 'react-final-form';
import { connect } from 'react-redux';
import {
    OnSubmitSuccessHandler,
    SubmitForm,
    submitFormAction,
    FormSubmitter,
} from '../../../workflow-navigation';

export type SubmitFunction<TValues> = (
    values?: TValues
) => void | Promise<void> | Record<string, any> | Promise<Record<string, any>>;

interface Props<TValues> extends Config<TValues> {
    subscription?: FormSubscription;
    decorators?: any;
    onSubmitSuccess?: OnSubmitSuccessHandler;
    submitForm: SubmitForm;
    id?: string;
    children?: React.ReactNode;
}

class GenericFormClass<TValues> extends React.Component<Props<TValues>> {
    public render() {
        const {
            id,
            subscription,
            submitForm,
            initialValues,
            onSubmitSuccess,
            onSubmit,
            children,
            ...rest
        } = this.props;

        return (
            <Form
                subscription={{
                    ...subscription,
                    pristine: true,
                    hasValidationErrors: true,
                }}
                render={this.innerFormRender(children, id)}
                initialValues={initialValues}
                onSubmit={(v, f, cb) =>
                    submitForm(async () => await onSubmit(v, f, cb), onSubmitSuccess)
                }
                {...rest}
            />
        );
    }

    private innerFormRender(children: React.ReactNode, id?: string) {
        const Children = typeof children === 'function' ? children : () => children;
        return (renderProps: FormRenderProps<TValues>) => {
            return (
                <form onSubmit={renderProps.handleSubmit} id={id}>
                    <Children />
                    <FormSubmitter
                        handleSubmit={renderProps.handleSubmit}
                        pristine={renderProps.pristine}
                        hasValidationErrors={renderProps.pristine}
                    />
                    <input type="submit" style={{ display: 'none' }} />
                </form>
            );
        };
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    submitForm: submitFormAction,
};

export function GenericForm<TValues>() {
    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(GenericFormClass as new (props: Props<TValues>) => GenericFormClass<TValues>);
}
