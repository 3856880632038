import React from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import { CountriesOfIssue } from '../../../../../codes/countries-of-issue';
import { Titles } from '../../../../../codes/titles';
import {
    BindFieldToField,
    Condition,
    False,
    FullWidthDatePicker,
    FullWidthRadioGroup,
    FullWidthSearchSelectField,
    FullWidthTextField,
    RadioOption,
    True,
    WhenFieldChanges,
} from '../../../../../forms';
import {
    FirstNameMask,
    InitialsMask,
    LastNameMask,
    NumberMask,
} from '../../../../../forms/input-masks';
import {
    composeValidators,
    conditionalRsaIdNumberValidator,
    requiredValidator,
} from '../../../../../forms/validations';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { MaritalStatus } from '../../../../../codes/marital-statuses';
import {
    IdDocumentTypes,
    Passports,
    SouthAfricanIds,
} from '../../../../../codes/id-document-types';
import { getValueFromObject, isAMinor, defaultDateOfBirth } from '../../../../../shared-functions';
import { RelationshipDetailsView } from './edit-relationship-details';

interface Props extends StyledComponent {
    readonly hideFields?: string[];
    readonly propertyPrefix?: string;
}

const personalityDetailsView: React.FunctionComponent<Props> = ({
    hideFields,
    propertyPrefix = '',
}: Props) => {
    const hideOwnerRelation = Array.isArray(hideFields) && hideFields.includes('ownerRelationship');
    const hideOfficiallyMigrated =
        Array.isArray(hideFields) && hideFields.includes('officiallyEmigrated');
    const hideFinancialAdviser =
        Array.isArray(hideFields) && hideFields.includes('hasFinancialAdviser');
    const hideFinancialAdviceConsent =
        Array.isArray(hideFields) && hideFields.includes('financialAdviceConsent');
    const hideTaxNumber = Array.isArray(hideFields) && hideFields.includes('taxNumber');
    const hideMaritalStatus = Array.isArray(hideFields) && hideFields.includes('maritalStatus');
    const hideIsRsaResident = Array.isArray(hideFields) && hideFields.includes('isRsaResident');
    const fullPropertyPrefix =
        propertyPrefix && propertyPrefix !== '' ? `${propertyPrefix}.` : propertyPrefix;
    return (
        <React.Fragment>
            <Grid item xs={6}>
                <FullWidthSearchSelectField name={`${fullPropertyPrefix}title`} options={Titles} />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${fullPropertyPrefix}initials`}
                    inputProps={{
                        style: { textTransform: 'uppercase' },
                    }}
                    mask={InitialsMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${fullPropertyPrefix}firstName`}
                    label="Name"
                    validate={requiredValidator}
                    mask={FirstNameMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${fullPropertyPrefix}lastName`}
                    label="Surname"
                    validate={requiredValidator}
                    mask={LastNameMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    name={`${fullPropertyPrefix}idType`}
                    options={IdDocumentTypes}
                    validate={requiredValidator}
                />
                <WhenFieldChanges
                    field={`${fullPropertyPrefix}idType`}
                    becomes={'RsaPassport'}
                    set={`${fullPropertyPrefix}passportCountryOfIssue`}
                    to="South Africa"
                />
                <WhenFieldChanges
                    field={`${fullPropertyPrefix}idType`}
                    condition={idType => idType !== 'RsaPassport'}
                    set={`${fullPropertyPrefix}passportCountryOfIssue`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${fullPropertyPrefix}idType`}
                    set={`${fullPropertyPrefix}idNumber`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${fullPropertyPrefix}idType`}
                    set={`${fullPropertyPrefix}passportNumber`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${fullPropertyPrefix}idType`}
                    set={`${fullPropertyPrefix}passportIssueDate`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${fullPropertyPrefix}idType`}
                    set={`${fullPropertyPrefix}passportExpiryDate`}
                    to={null}
                />
            </Grid>
            <Condition when={`${fullPropertyPrefix}idType`} is={Passports}>
                <True>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            name={`${fullPropertyPrefix}passportNumber`}
                            label="Passport Number"
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthDatePicker
                            name={`${fullPropertyPrefix}passportIssueDate`}
                            validate={requiredValidator}
                            maxDateField={`${fullPropertyPrefix}passportExpiryDate`}
                            disableFuture
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthDatePicker
                            name={`${fullPropertyPrefix}passportExpiryDate`}
                            validate={requiredValidator}
                            minDateField={`${fullPropertyPrefix}passportIssueDate`}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Condition when={`${fullPropertyPrefix}idType`} is="RsaPassport">
                            <True>
                                <FullWidthSearchSelectField
                                    name={`${fullPropertyPrefix}passportCountryOfIssue`}
                                    options={CountriesOfIssue}
                                    disabled
                                />
                            </True>
                            <False>
                                <FullWidthSearchSelectField
                                    name={`${fullPropertyPrefix}passportCountryOfIssue`}
                                    validate={requiredValidator}
                                    options={CountriesOfIssue}
                                />
                            </False>
                        </Condition>
                    </Grid>
                </True>
                <False>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            name={`${fullPropertyPrefix}idNumber`}
                            label="ID Number"
                            validate={composeValidators(
                                requiredValidator,
                                conditionalRsaIdNumberValidator(values =>
                                    isSouthAfricanId(
                                        getValueFromObject(values, propertyPrefix, 'idType')
                                    )
                                )
                            )}
                            mask={NumberMask}
                        />
                    </Grid>
                </False>
            </Condition>
            <Grid item xs={6}>
                <FullWidthDatePicker
                    name={`${fullPropertyPrefix}dateOfBirth`}
                    validate={requiredValidator}
                    disableFuture
                />
                <Condition when={`${fullPropertyPrefix}dateOfBirth`} hasValue>
                    <False>
                        <BindFieldToField
                            field={`${fullPropertyPrefix}dateOfBirth`}
                            toField={`${fullPropertyPrefix}idNumber`}
                            transform={defaultDateOfBirth}
                        />
                    </False>
                </Condition>
            </Grid>
            <Condition
                when={`${fullPropertyPrefix}dateOfBirth`}
                passes={dob => {
                    return !isAMinor(dob);
                }}
            >
                <True>
                    {!hideMaritalStatus && (
                        <React.Fragment>
                            <Grid item xs={6}>
                                <FullWidthSearchSelectField
                                    name={`${fullPropertyPrefix}maritalStatus`}
                                    options={MaritalStatus}
                                    label="Marital Status"
                                />
                                <WhenFieldChanges
                                    field={`${fullPropertyPrefix}maritalStatus`}
                                    set={`${fullPropertyPrefix}dateOfMarriage`}
                                    to={null}
                                />
                                <WhenFieldChanges
                                    field={`${fullPropertyPrefix}maritalStatus`}
                                    set={`${fullPropertyPrefix}dateOfDivorce`}
                                    to={null}
                                />
                            </Grid>
                            <Condition when={`${fullPropertyPrefix}maritalStatus`} is="Married">
                                <True>
                                    <Grid item xs={6}>
                                        <FullWidthDatePicker
                                            name={`${fullPropertyPrefix}dateOfMarriage`}
                                            label="Date of Marriage"
                                            disableFuture
                                            validate={requiredValidator}
                                        />
                                    </Grid>
                                </True>
                            </Condition>
                            <Condition when={`${fullPropertyPrefix}maritalStatus`} is="Divorced">
                                <True>
                                    <Grid item xs={6}>
                                        <FullWidthDatePicker
                                            name={`${fullPropertyPrefix}dateOfDivorce`}
                                            label="Date of Divorce"
                                            disableFuture
                                            validate={requiredValidator}
                                        />
                                    </Grid>
                                </True>
                            </Condition>
                        </React.Fragment>
                    )}
                    {!hideTaxNumber && (
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name={`${fullPropertyPrefix}taxReferenceNumber`}
                                mask={NumberMask}
                            />
                        </Grid>
                    )}
                    {!hideOfficiallyMigrated && (
                        <Grid item xs={6}>
                            <FullWidthRadioGroup
                                name={`${fullPropertyPrefix}.officiallyEmigrated`}
                                validate={requiredValidator}
                            >
                                <RadioOption value="Yes" />
                                <RadioOption value="No" />
                            </FullWidthRadioGroup>
                        </Grid>
                    )}
                    {!hideIsRsaResident && (
                        <React.Fragment>
                            <Grid item xs={6}>
                                <FullWidthRadioGroup
                                    name={`${fullPropertyPrefix}.isRsaResident`}
                                    validate={requiredValidator}
                                >
                                    <RadioOption value="Yes" />
                                    <RadioOption value="No" />
                                </FullWidthRadioGroup>
                            </Grid>
                            <Condition when={`${fullPropertyPrefix}.isRsaResident`} is="No">
                                <True>
                                    <Grid item xs={6}>
                                        <FullWidthSearchSelectField
                                            name={`${fullPropertyPrefix}.countryOfResidence`}
                                            options={CountriesOfIssue}
                                            validate={requiredValidator}
                                        />
                                    </Grid>
                                </True>
                            </Condition>
                        </React.Fragment>
                    )}
                    {!hideFinancialAdviser && (
                        <Grid item xs={6}>
                            <FullWidthRadioGroup
                                name={`${fullPropertyPrefix}hasFinancialAdviser`}
                                validate={requiredValidator}
                            >
                                <RadioOption value="Yes" />
                                <RadioOption value="No" />
                            </FullWidthRadioGroup>
                        </Grid>
                    )}
                    {!hideFinancialAdviceConsent && (
                        <Condition when={`${fullPropertyPrefix}hasFinancialAdviser`} is="No">
                            <True>
                                <Grid item xs={6}>
                                    <FullWidthRadioGroup
                                        name={`${fullPropertyPrefix}financialAdviceConsent`}
                                        validate={requiredValidator}
                                    >
                                        <RadioOption value="Yes" />
                                        <RadioOption value="No" />
                                    </FullWidthRadioGroup>
                                </Grid>
                            </True>
                        </Condition>
                    )}
                </True>
            </Condition>
            <RelationshipDetailsView
                propertyPrefix={propertyPrefix}
                hideOwnerRelationship={hideOwnerRelation}
            />
        </React.Fragment>
    );
};

function isSouthAfricanId(idType: string) {
    return SouthAfricanIds.includes(idType);
}

export const PersonalityDetailsView = withStyles(styles)(personalityDetailsView);
