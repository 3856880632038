import { withStyles, Grid } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import styles, { StyledComponent } from '../../../../../styles/styles';
import { FormDetails, getFormDetails } from '../form-details-reducer';
import { connect } from 'react-redux';
import { State } from '../../../../../redux/root-reducer';
import {
    Condition,
    FullWidthDatePicker,
    FullWidthSearchSelectField,
    FullWidthTextField,
    True,
    FullWidthRadioGroup,
    RadioOption,
} from '../../../../../forms';
import { Titles } from '../../../../../codes/titles';
import { MedicalAidSchemes } from '../../../../../codes/medical-aid-schemes';
import { requiredValidator } from '../../../../../forms/validations';
import { NumberMask } from '../../../../../forms/input-masks';

interface BaseProps {
    formDetails: FormDetails;
    prefix: string;
}

interface Props extends BaseProps, StyledComponent {}
const medicalAidDetailsEdit: React.FunctionComponent<Props> = ({ prefix, formDetails }: Props) => {
    return (
        <Grid container spacing={5} justify="flex-start">
            <Grid item xs={6}>
                <FullWidthRadioGroup
                    label="Life Assured has medical aid?"
                    name={`${prefix}.hasMedicalAid`}
                >
                    <RadioOption value="Yes" label="Yes" />
                    <RadioOption value="No" label="No" />
                </FullWidthRadioGroup>
            </Grid>
            <Condition when={`${prefix}.hasMedicalAid`} is={'Yes'}>
                <True>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            label="Medical aid number"
                            validate={requiredValidator}
                            mask={NumberMask}
                            name={`${prefix}.lifeAssuredMedicalAidNumber`}
                        />
                    </Grid>
                </True>
            </Condition>
            <Condition when={`${prefix}.hasMedicalAid`} is={'Yes'}>
                <True>
                    <Grid item xs={6}>
                        <FullWidthSearchSelectField
                            name={`${prefix}.lifeAssuredMedicalAidName`}
                            options={MedicalAidSchemes}
                            validate={requiredValidator}
                        />
                    </Grid>
                </True>
            </Condition>
            <Condition when="medicalAidDetails.medicalAidName" is={'Other'}>
                <True>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            name="medicalAidDetails.medicalAidDescription"
                            validate={requiredValidator}
                        />
                    </Grid>
                </True>
            </Condition>
            <Condition when={`${prefix}.hasMedicalAid`} is={'Yes'}>
                <True>
                    <Grid item xs={6}>
                        <FullWidthDatePicker
                            label="Date Joined"
                            name={`${prefix}.lifeAssuredMedicalDateJoined`}
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthRadioGroup
                            label="Is the life assured a main member?"
                            name={`${prefix}.isLifeAssuredMainMember`}
                            validate={requiredValidator}
                        >
                            <RadioOption value="Yes" label="Yes" />
                            <RadioOption value="No" label="No" />
                        </FullWidthRadioGroup>
                    </Grid>
                </True>
            </Condition>
            <Condition when={`${prefix}.isLifeAssuredMainMember`} is={'No'}>
                <True>
                    <Grid item xs={6}>
                        <FullWidthSearchSelectField
                            label="Main member Title"
                            name={`${prefix}.lifeAssuredTitle`}
                            options={Titles}
                        />
                    </Grid>
                </True>
            </Condition>

            <Grid item xs={6}>
                <Condition when={`${prefix}.isLifeAssuredMainMember`} is={'No'}>
                    <True>
                        <FullWidthTextField
                            label="Main member full name"
                            name={`${prefix}.lifeAssuredName`}
                        />{' '}
                    </True>
                </Condition>
            </Grid>
            <Grid item xs={6}>
                <Condition when={`${prefix}.isLifeAssuredMainMember`} is={'No'}>
                    <True>
                        <FullWidthTextField
                            label="Main member surname"
                            name={`${prefix}.lifeAssuredSurname`}
                        />{' '}
                    </True>
                </Condition>
            </Grid>
            <Condition when={`${prefix}.isLifeAssuredMainMember`} is={'No'}>
                <True>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            label="Main member ID"
                            name={`${prefix}.lifeAssuredIdNumber`}
                        />
                    </Grid>
                </True>
            </Condition>
        </Grid>
    );
};

const mapStateToProps = (state: State) => ({
    formDetails: getFormDetails(state),
});

const mapDispatchToProps = {};

export const MedicalAidDetailsEdit = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(medicalAidDetailsEdit));
