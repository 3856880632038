import { Grid, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import {
    FormErrors,
    FormHeading,
    FullWidthRadioGroup,
    GenericForm,
    RadioOption,
} from '../../../../../forms';
import { requiredValidator } from '../../../../../forms/validations';
import { getLifeAssuredDetails } from '../../../../../life-assured-details/reducer';
import { State } from '../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { OnSubmitSuccessProps } from '../../../../../workflow-navigation';
import {
    UpdateClaimLifeNotification,
    updateClaimLifeNotificationAction,
} from '../actions/update-claim-life-notification';

interface Props extends StyledComponent, OnSubmitSuccessProps {
    claimLifeNotificationsUpdated?: string;
    updateClaimLifeNotification: UpdateClaimLifeNotification;
}

const ClaimLifeNotificationForm = GenericForm<{ claimLifeNotificationsUpdated: string }>();
const claimLifeNotificationEditModal: React.FunctionComponent<Props> = ({
    claimLifeNotificationsUpdated,
    updateClaimLifeNotification,
    ...rest
}: Props) => {
    return (
        <ClaimLifeNotificationForm
            onSubmit={updateClaimLifeNotification}
            initialValues={{ claimLifeNotificationsUpdated }}
            {...rest}
        >
            <Grid container justify="flex-start">
                <Grid item xs={12}>
                    <FormHeading text="Life and Claims Registry Declaration" />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthRadioGroup
                        name="claimLifeNotificationsUpdated"
                        label="Life and Claims Registry updated"
                        validate={requiredValidator}
                    >
                        <RadioOption value="Yes" />
                        <RadioOption value="No" />
                    </FullWidthRadioGroup>
                </Grid>
            </Grid>
            <FormErrors />
        </ClaimLifeNotificationForm>
    );
};

const mapStateToProps = (state: State) => ({
    claimLifeNotificationsUpdated: getLifeAssuredDetails(state).claimLifeNotificationsUpdated,
});

const mapDispatchToProps = {
    updateClaimLifeNotification: updateClaimLifeNotificationAction,
};

export const ClaimLifeNotificationEditView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(claimLifeNotificationEditModal));
