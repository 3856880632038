import { Divider, Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { formatCurrency, getCodeDescription, getDisplayDate } from '../../../shared-functions';
import styles, { StyledComponent } from '../../../styles/styles';
import { getInternalAccount, PaymentItem } from '../../reducer';

interface Props extends StyledComponent {
    paymentItem: PaymentItem;
}

const expandedView: React.FunctionComponent<Props> = ({ classes, paymentItem }: Props) => {
    return (
        <React.Fragment>
            <Divider className={classes.resultsTableSpacer} />
            <Grid container className={classes.resultsClaimsTable}>
                <Grid container className={classes.resultsTable}>
                    <Grid item xs={6} lg={2}>
                        <Typography className={classes.resultsLabel}>Payment ID</Typography>
                        <Typography>{paymentItem.id}</Typography>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <Typography className={classes.resultsLabel}>Sum Assured</Typography>
                        <Typography>{formatCurrency(paymentItem.sumAssured)}</Typography>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <Typography className={classes.resultsLabel}>Request No</Typography>
                        <Typography>{paymentItem.requestNo}</Typography>
                    </Grid>
                    <Grid item xs={6} lg={2}>
                        <Typography className={classes.resultsLabel}>Captured Date</Typography>
                        <Typography>{getDisplayDate(paymentItem.capturedDate)}</Typography>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                        <Typography className={classes.resultsLabel}>Processed By</Typography>
                        <Typography>{paymentItem.assessor}</Typography>
                    </Grid>
                    <BankingDetailsView classes={classes} paymentItem={paymentItem} />
                    <InternalAccountView classes={classes} paymentItem={paymentItem} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const BankingDetailsView = ({ paymentItem, classes }: Props) => {
    if (!paymentItem.bankingDetails) {
        return null;
    }
    const bankingDetails = paymentItem.bankingDetails;
    return (
        <React.Fragment>
            <Grid item xs={6} lg={2}>
                <Typography className={classes.resultsLabel}>Account Number</Typography>
                <Typography>{bankingDetails.accountNumber}</Typography>
            </Grid>
            <Grid item xs={6} lg={2}>
                <Typography className={classes.resultsLabel}>Account Name</Typography>
                <Typography>{bankingDetails.accountHoldersName}</Typography>
            </Grid>
            <Grid item xs={6} lg={2}>
                <Typography className={classes.resultsLabel}>Bank Name</Typography>
                <Typography>{getCodeDescription('bankName', bankingDetails.bankName)}</Typography>
            </Grid>
            <Grid item xs={6} lg={2}>
                <Typography className={classes.resultsLabel}>Branch Code</Typography>
                <Typography>{bankingDetails.branchCode}</Typography>
            </Grid>
            <Grid item xs={6} lg={4}>
                <Typography className={classes.resultsLabel}>Branch Name</Typography>
                <Typography>{bankingDetails.bankBranch}</Typography>
            </Grid>
            <Grid item xs={6} lg={2}>
                <Typography className={classes.resultsLabel}>Account Type</Typography>
                <Typography>{bankingDetails.accountType}</Typography>
            </Grid>
            <Grid item xs={6} lg={2}>
                <Typography className={classes.resultsLabel}>Payment Type</Typography>
                <Typography>{paymentItem.paymentMethod || 'EFT'}</Typography>
            </Grid>
            <Grid item xs={6} lg={2}>
                <Typography className={classes.resultsLabel}>Reference</Typography>
                <Typography>{paymentItem.reference}</Typography>
            </Grid>
        </React.Fragment>
    );
};

const InternalAccountView = ({ classes, paymentItem }: Props) => {
    const internalAccount = getInternalAccount(paymentItem.internalAccountId);
    if (!internalAccount) {
        return null;
    }
    return (
        <React.Fragment>
            <Grid item xs={6} lg={2}>
                <Typography className={classes.resultsLabel}>Account Number</Typography>
                <Typography>{internalAccount.accountNumber}</Typography>
            </Grid>
            <Grid item xs={6} lg={2}>
                <Typography className={classes.resultsLabel}>Account Name</Typography>
                <Typography>{`${internalAccount.accountHoldersName} (${paymentItem.internalAccountId})`}</Typography>
            </Grid>
            <Grid item xs={6} lg={2}>
                <Typography className={classes.resultsLabel}>Bank Name</Typography>
                <Typography>{getCodeDescription('bankName', internalAccount.bankName)}</Typography>
            </Grid>
            <Grid item xs={6} lg={2}>
                <Typography className={classes.resultsLabel}>Branch Code</Typography>
                <Typography>{internalAccount.branchCode}</Typography>
            </Grid>
            <Grid item xs={6} lg={2}>
                <Typography className={classes.resultsLabel}>Payment Type</Typography>
                <Typography>{'EFT'}</Typography>
            </Grid>
        </React.Fragment>
    );
};

export const PaymentItemExpandedView = withStyles(styles)(expandedView);
