import React from 'react'; // eslint-disable-line
import { Grid, Paper, Typography, withStyles, Tooltip, Button, Divider } from '@material-ui/core';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import {
    OpenAddModal,
    OpenModal,
    openModalAction,
    openAddModalAction,
    OpenEditModal,
    openEditModalAction,
} from '../../../../../../form-modal/actions';
import { MapDispatchToProps, connect } from 'react-redux';
import { State } from '../../../../../../redux/root-reducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { v4 as uuid } from 'uuid';
import { ExternalPolicy, getExternalPolicy } from '../../reducer';
import { AddFormModal, EditFormModal } from '../../../../../../form-modal';
import { AddCircle, Edit, Delete } from '@material-ui/icons';

import { AddExternalPolicy } from './add-external-policy';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import { getDisplayDate, formatCurrency } from '../../../../../../shared-functions';
import {
    addExternalPoliciesRecordThunk,
    AddExternalPoliciesRecord,
} from '../../actions/external-policy/add-external-policy';
import { UpdateExternalPolicyView } from './update-external-policy';
import {
    updateAndSubmitExternalPolicyRecordThunk,
    UpdateAndSubmitExternalPolicyRecord,
    DeleteAndSubmitExternalPolicyRecord,
    deleteAndSubmitClaimantRecordThunk,
} from '../../actions/external-policy/update-external-policy';
import { OpenDialog, openDialogAction } from '../../../../../../confirmation-dialog';
import { ButtonWrapper } from '../../../../../../forms';
interface DispatchProps {
    openAddModal: OpenAddModal;
    openModal: OpenModal;
    addExternalPolicy: AddExternalPoliciesRecord;
    openEditModal: OpenEditModal;
    updateExternalPolicy: UpdateAndSubmitExternalPolicyRecord;
    deleteExternalPolicy: DeleteAndSubmitExternalPolicyRecord;
    openDialog: OpenDialog;
}
interface OwnProps {}
interface StateProps {
    externalPolicyDetails: ExternalPolicy[];
    isReadOnly: boolean;
}
type Props = StyledComponent & DispatchProps & OwnProps & StateProps;
const ExternalPoliciesFormModal = AddFormModal<ExternalPolicy>();
const EditExternalPoliciesFormModal = EditFormModal<ExternalPolicy>();
export const externalPoliciesView: React.FunctionComponent<Props> = ({
    classes,
    openAddModal,
    addExternalPolicy,
    externalPolicyDetails,
    openEditModal,
    openModal,
    updateExternalPolicy,
    deleteExternalPolicy,
    openDialog,
    isReadOnly,
}: Props) => {
    function editExternalPolicy(index: number) {
        const recordIndex = index || 0;
        openEditModal({
            modalContent: (
                <EditExternalPoliciesFormModal
                    recordDetailView={
                        <UpdateExternalPolicyView
                            beneficiaries={externalPolicyDetails[index].beneficiaries}
                        />
                    }
                    records={externalPolicyDetails}
                    updateRecord={(record, index) => updateExternalPolicy(index, record)}
                />
            ),
            modalData: {
                recordIndex,
            },
        });
    }
    const deleteClaimantButton = (
        index: number,
        deleteExternalPolicy: DeleteAndSubmitExternalPolicyRecord,
        openDialog: OpenDialog
    ) => {
        const confirmDelete = () => {
            openDialog({
                content: {
                    title: 'Delete External Policy',
                    body: 'Are you sure you would like to delete the selected External Policy?',
                },
                yesAction: {
                    action: () => {
                        deleteExternalPolicy(index);
                    },
                },
            });
        };

        return (
            <React.Fragment>
                <Tooltip title="Delete External Policy">
                    <Button id="DeleteExternalPolicy" color="secondary" onClick={confirmDelete}>
                        <Delete />
                    </Button>
                </Tooltip>
            </React.Fragment>
        );
    };
    return (
        <React.Fragment>
            <Grid container item xs={6} alignItems="center">
                <Typography variant="subtitle2">External policy details</Typography>
            </Grid>
            <ButtonWrapper>
                <Grid container spacing={0} justify="flex-end" className={classes.marginTop}>
                    <Tooltip title="Add External Policy">
                        <Button
                            id="AddExternalPolicy"
                            color="secondary"
                            className={classes.button}
                            style={{ marginTop: '-48px', textTransform: 'none' }}
                            onClick={() =>
                                openAddModal({
                                    modalContent: (
                                        <ExternalPoliciesFormModal
                                            blankRecord={{ id: uuid(), beneficiaries: [] }}
                                            recordDetailView={<AddExternalPolicy />}
                                            addRecord={record => addExternalPolicy(record)}
                                        />
                                    ),
                                    modalData: {},
                                })
                            }
                        >
                            <AddCircle />
                            Add External Policy
                        </Button>
                    </Tooltip>
                </Grid>
            </ButtonWrapper>

            {externalPolicyDetails.length > 0 && (
                <Paper className={classes.paper}>
                    {externalPolicyDetails.map((record, index) => {
                        const beneficiaries =
                            record && record.beneficiaries ? record.beneficiaries : undefined;

                        return (
                            <React.Fragment key={index}>
                                <Grid item xs={12} container spacing={2} justify="flex-start">
                                    <Grid item xs={8} lg={9}>
                                        <Typography
                                            className={classes.marginTop}
                                            variant="h5"
                                        >{`${'Insurer'} ${index + 1} | ${
                                            record.insurerName
                                        }`}</Typography>
                                    </Grid>
                                    <ButtonWrapper>
                                        <Grid item xs={4} lg={3} className={classes.rightAlign}>
                                            <Tooltip title="Edit External Policy">
                                                <Button
                                                    id="EditExternalPolicyDetails"
                                                    color="secondary"
                                                    onClick={() => editExternalPolicy(index)}
                                                >
                                                    <Edit />
                                                </Button>
                                            </Tooltip>
                                            {deleteClaimantButton(
                                                index,
                                                deleteExternalPolicy,
                                                openDialog
                                            )}
                                        </Grid>
                                    </ButtonWrapper>
                                    <Grid item xs={4} lg={3}>
                                        <LabeledValue
                                            label="Policy Number"
                                            value={record.policyNumber}
                                        />
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <LabeledValue
                                            label="Product type"
                                            value={record.productType}
                                        />
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <LabeledValue
                                            label="Sum assured"
                                            value={formatCurrency(record.sumAssured)}
                                        />
                                    </Grid>
                                    <Grid item xs={4} lg={3}>
                                        <LabeledValue
                                            label="Issue date"
                                            value={getDisplayDate(record.issueDate)}
                                        />
                                    </Grid>
                                    {beneficiaries !== undefined && beneficiaries.length !== 0 && (
                                        <React.Fragment>
                                            <Grid item container spacing={5}>
                                                <Grid item xs={4} lg={3}>
                                                    <LabeledValue label="Beneficiary name" />
                                                </Grid>
                                                <Grid item xs={4} lg={3}>
                                                    <LabeledValue label="Beneficiary surname" />
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    )}
                                    {beneficiaries !== undefined &&
                                        beneficiaries.length !== 0 &&
                                        beneficiaries.map((beneficiary, index) => {
                                            return (
                                                <React.Fragment key={`Beneficiary_${index}`}>
                                                    <Grid
                                                        item
                                                        container
                                                        spacing={5}
                                                        style={{ marginTop: '-29px' }}
                                                    >
                                                        <Grid item xs={4} lg={3}>
                                                            <LabeledValue
                                                                label=""
                                                                value={beneficiary.firstName}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4} lg={3}>
                                                            <LabeledValue
                                                                label=""
                                                                value={beneficiary.lastName}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    {index !== beneficiaries.length - 1 && (
                                                        <Divider
                                                            style={{
                                                                width: '100%',
                                                                marginTop: '-15px',
                                                            }}
                                                        />
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                </Grid>
                                {index !== externalPolicyDetails.length - 1 && (
                                    <hr style={{ height: '1px', background: '#6495ED' }} />
                                )}
                            </React.Fragment>
                        );
                    })}
                </Paper>
            )}
        </React.Fragment>
    );
};
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    addExternalPolicy: record => dispatch(addExternalPoliciesRecordThunk(record)),
    updateExternalPolicy: (index: number, record: ExternalPolicy) =>
        dispatch(updateAndSubmitExternalPolicyRecordThunk(index, record)),
    openModal: options => dispatch(openModalAction(options)),
    openAddModal: options => dispatch(openAddModalAction(options)),
    openEditModal: options => dispatch(openEditModalAction(options)),
    openDialog: options => dispatch(openDialogAction(options)),
    deleteExternalPolicy: (index: number) => dispatch(deleteAndSubmitClaimantRecordThunk(index)),
});

const mapStateToProps = (state: State) => ({
    externalPolicyDetails: getExternalPolicy(state),
    isReadOnly: state.claimSession.readOnly,
});

export const ExternalPoliciesView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(externalPoliciesView));
