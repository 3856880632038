import React from 'react'; // eslint-disable-line
import { Grid, Paper, withStyles, Button, Tooltip, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { connect } from 'react-redux';
import { LabeledValue } from '../../../../controls/labeled-value';
import { OpenModal, openModalAction } from '../../../../form-modal/actions';
import { FormModalView } from '../../../../form-modal/views/form-modal';
import styles, { StyledComponent } from '../../../../styles/styles';
import { State } from '../../../../redux/root-reducer';
import { getRelationship } from '../../../../codes/relationship-types';
import { getNoticeOfDeath, Informant } from '../../common/notice-of-death/notice-of-death-reducer';
import { NoticeOfDeathForm } from './notice-of-death-edit-form';
import { EditInformantView } from '../../common/notice-of-death/views';
import { DisplayAddress } from '../../../../forms/sub-forms/address-details';
import { ButtonWrapper } from '../../../../forms';

type Props = StyledComponent & {
    openModal: OpenModal;
    informantDetails: Informant;
};
const informantView: React.FunctionComponent<Props> = ({
    classes,
    informantDetails,
    openModal,
}: Props) => {
    const edit = () => {
        openModal({
            modalContent: (
                <FormModalView
                    formView={() => (
                        <NoticeOfDeathForm
                            title="Informant Details"
                            view={<EditInformantView isDeath />}
                        />
                    )}
                />
            ),
        });
    };
    return (
        <React.Fragment>
            <Grid container justify="flex-start" style={{ marginTop: '4px' }}>
                <Grid item xs={11}>
                    <Typography style={{ marginTop: '8px' }} variant="subtitle2">
                        Informant Details
                    </Typography>
                </Grid>
                <ButtonWrapper>
                    <Grid item xs={1} container justify="flex-end">
                        <Tooltip title="Edit Informant">
                            <Button id="EditInformant" color="secondary" onClick={edit}>
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            </Grid>
            <Paper className={classes.paper}>
                <Grid container justify="flex-start">
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="First Name" value={informantDetails.firstName} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Second Name" value={informantDetails.secondName} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Surname" value={informantDetails.lastName} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="ID Number" value={informantDetails.idNumber} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Relationship to Deceased"
                            value={getRelationship(
                                informantDetails.relationshipToLifeAssured,
                                informantDetails.relationshipToLifeAssuredDescription
                            )}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Telephone Number"
                            value={informantDetails.telephoneNumber}
                        />
                    </Grid>
                    {informantDetails.addressDetails && (
                        <Grid item xs={6} lg={3}>
                            <DisplayAddress
                                label="Informant Address"
                                address={informantDetails.addressDetails}
                            />
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

const mapStateToProps = (state: State) => ({
    informantDetails: getNoticeOfDeath(state).informantDetails || {},
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = { openModal: openModalAction };

export const InformantDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(informantView));
