import { AnyAction } from 'redux';
import { Claim, getClaim, getPropertyFromClaim } from '../claim';
import { addRecordReduction, updateRecordReduction } from '../redux/reductions';
import { State } from '../redux/root-reducer';
import { Nullable } from '../shared-types';
import { AddFinalDecisionAction } from './actions/add-final-decision';
import { ADD_FINAL_DECISION } from './types';

export interface RetrospectiveUnderwritingDecisionDetails {
    readonly fullDisclosureAtApplicationStage?: string;
    readonly additionalInformation?: string;
    readonly chiefMedicalOfficerComments?: string;
    readonly retrospectiveUnderwriter?: string;
}

export interface FinalDecisionDetails {
    readonly id?: string;
    readonly policyNumber?: string;
    readonly benefitName?: string;
    readonly retrospectiveUnderwritingDecision?: string;
    readonly decisionDate?: Date;
}

export interface PolicyBenefitFinalDecisions {
    readonly id?: string;
    readonly policyNumber?: string;
    readonly benefitName?: string;
    readonly finalDecisions: FinalDecisionDetails[];
}

export interface RetrospectiveUnderwritingDecision {
    readonly decisionDetails: RetrospectiveUnderwritingDecisionDetails;
    readonly policyBenefitFinalDecisions: PolicyBenefitFinalDecisions[];
}

const defaultPolicyBenefitFinalDecisions: PolicyBenefitFinalDecisions[] = [];
export const defaultRetrospectiveUnderwritingDecision: RetrospectiveUnderwritingDecision = {
    decisionDetails: {},
    policyBenefitFinalDecisions: defaultPolicyBenefitFinalDecisions,
};

type Action = AddFinalDecisionAction | AnyAction;

export function retrospectiveUnderwritingDecisionReducer(
    state: Nullable<RetrospectiveUnderwritingDecision> = null,
    action: Action
) {
    let records =
        (state || defaultRetrospectiveUnderwritingDecision).policyBenefitFinalDecisions || [];
    switch (action.type) {
        case ADD_FINAL_DECISION: {
            const addFinalDecisionAction = action as AddFinalDecisionAction;
            let policyBenefitFinalDecisions =
                records[addFinalDecisionAction.policyBenefitFinalDecisionsIndex];

            policyBenefitFinalDecisions = {
                ...policyBenefitFinalDecisions,
                finalDecisions: addRecordReduction(
                    policyBenefitFinalDecisions.finalDecisions,
                    addFinalDecisionAction.payload
                ),
            };
            records = updateRecordReduction(
                records,
                policyBenefitFinalDecisions,
                addFinalDecisionAction.policyBenefitFinalDecisionsIndex
            );
            break;
        }
        default:
            return state;
    }

    return {
        ...defaultRetrospectiveUnderwritingDecision,
        ...state,
        policyBenefitFinalDecisions: records,
    };
}

export function getRetrospectiveUnderwritingDecision(
    state: State
): RetrospectiveUnderwritingDecision {
    return getRetrospectiveUnderwritingDecisionFromClaim(getClaim(state));
}

export function getRetrospectiveUnderwritingDecisionFromClaim(
    claim: Claim
): RetrospectiveUnderwritingDecision {
    return getPropertyFromClaim(
        claim,
        'retrospectiveUnderwritingDecision',
        defaultRetrospectiveUnderwritingDecision
    );
}
