import React from 'react'; // eslint-disable-line

import { Typography, withStyles } from '@material-ui/core';
import styles, { StyledComponent } from '../../../styles/styles';
import { FormSpy, FormSpyRenderProps } from 'react-final-form';
import { Nullable } from '../../../shared-types';

type Props = StyledComponent;

const formErrors: React.FunctionComponent<Props> = ({ classes }: Props) => {
    return FormSpy({
        subscription: { submitError: true },
        render: ({ submitError }: FormSpyRenderProps) => render({ submitError, classes }),
    });
};

interface RenderProps extends StyledComponent {
    submitError: Nullable<string>;
}
const render: React.FunctionComponent<RenderProps> = ({ submitError, classes }: RenderProps) => {
    if (!submitError) {
        return null;
    }

    return (
        <div>
            <Typography variant="subtitle2" className={classes.errorMessage}>
                {submitError}
            </Typography>
        </div>
    );
};

export const FormErrors = withStyles(styles)(formErrors);
