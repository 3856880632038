import { Grid, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { Condition, FullWidthTextField, True } from '../../../../../../forms';
import { TelephoneNumberMask } from '../../../../../../forms/input-masks';
import { EmailMask } from '../../../../../../forms/input-masks/email-mask';
import { conditionalCustomRequired } from '../../../../../../forms/validations';
import styles from '../../../../../../styles/styles';

interface Props {
    prefix?: string;
}
const contactDetailsEdit = ({ prefix = '' }: Props) => {
    const fullPrefix = !!prefix && prefix !== '' ? `${prefix}.` : '';
    const oneRequiredMessage = 'At least one has to be filled in';
    return (
        <Grid container spacing={5}>
            <Condition when={`${fullPrefix}employed`} is="Yes">
                <True>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            label="Work Number"
                            name={`${fullPrefix}contactDetails.workTelephoneNumber`}
                            mask={TelephoneNumberMask}
                            validate={conditionalCustomRequired(oneRequiredMessage, values => {
                                if (allPropertiesEmpty(values, prefix)) return true;
                                else return false;
                            })}
                        />
                    </Grid>
                </True>
            </Condition>
            <Grid item xs={6}>
                <FullWidthTextField
                    label="Cellphone Number"
                    name={`${fullPrefix}contactDetails.cellphoneNumber`}
                    mask={TelephoneNumberMask}
                    validate={conditionalCustomRequired(oneRequiredMessage, values => {
                        if (allPropertiesEmpty(values, prefix)) return true;
                        else return false;
                    })}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    label="Home Telephone Number"
                    name={`${fullPrefix}contactDetails.homeTelephoneNumber`}
                    mask={TelephoneNumberMask}
                    validate={conditionalCustomRequired(oneRequiredMessage, values => {
                        if (allPropertiesEmpty(values, prefix)) return true;
                        else return false;
                    })}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    label="E-mail Address"
                    name={`${fullPrefix}contactDetails.emailAddress`}
                    mask={EmailMask}
                    validate={conditionalCustomRequired(oneRequiredMessage, values => {
                        if (allPropertiesEmpty(values, prefix)) return true;
                        else return false;
                    })}
                />
            </Grid>
        </Grid>
    );
};

const allPropertiesEmpty = (values: any, prefix: string) => {
    let obj;
    if (!!prefix && prefix !== '') {
        if (prefix.indexOf('[') !== -1) {
            const indexArray = prefix.match(/\d+/g);
            const index = indexArray && indexArray[0];
            const property = prefix.substr(0, prefix.indexOf('['));
            if (index) {
                obj =
                    values &&
                    values[property] &&
                    values[property][index] &&
                    values[property][index].contactDetails;
            }
        } else {
            obj = values && values[prefix] && values[prefix].contactDetails;
        }
    } else {
        obj = values && values.contactDetails;
    }

    for (const key in obj) {
        if (obj[key] && obj[key] !== '') return false;
    }

    return true;
};

export const ContactDetailsEdit = withStyles(styles)(contactDetailsEdit);
