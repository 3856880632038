import { Grid, withStyles, Tooltip, Button } from '@material-ui/core';
import { AddCircle, Delete, Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect, MapDispatchToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { OpenDialog, openDialogAction } from '../../../../../../confirmation-dialog';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import { AddFormModal, EditFormModal } from '../../../../../../form-modal';
import {
    OpenAddModal,
    openAddModalAction,
    OpenEditModal,
    openEditModalAction,
    OpenModal,
    openModalAction,
} from '../../../../../../form-modal/actions';
import { ButtonWrapper } from '../../../../../../forms';
import { ExpansionPanel } from '../../../../../../forms/form-components/expansion-panel/expansion-panel';
import { State } from '../../../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import {
    AddAndSubmitConsultationRecord,
    addAndSubmitDoctorConsultationThunk,
    DeleteAndSubmitDoctorVisit,
    deleteAndSubmitDoctorVisitThunk,
    updateAndSubmitDoctorsVisit,
    UpdateAndSubmitDoctorsVisit,
} from '../../actions/update-consultations';
import { Consultation, Doctor } from '../../reducer';
import { DoctorsConsultationEdit } from './doctors-consultation-edit';

interface OwnProps {
    doctor: Doctor;
    index: number;
}
interface StateProps {
    isReadOnly: boolean;
}

interface DispatchProps {
    addConsultation: AddAndSubmitConsultationRecord;
    openAddModal: OpenAddModal;
    openModal: OpenModal;
    openEditModal: OpenEditModal;
    updateConsultation: UpdateAndSubmitDoctorsVisit;
    openDialog: OpenDialog;
    deleteConsultation: DeleteAndSubmitDoctorVisit;
}
type Props = StyledComponent & DispatchProps & OwnProps & StateProps;
const EditConsultationFormModal = EditFormModal<Consultation>();
const AddConsultationFormModal = AddFormModal<Consultation>();
const doctorsConsultationView: React.FunctionComponent<Props> = ({
    addConsultation,
    classes,
    index,
    doctor,
    openEditModal,
    updateConsultation,
    openAddModal,
    openDialog,
    deleteConsultation,
}: Props) => {
    const doctorsVisits = doctor.doctorsVisits
        ? doctor.doctorsVisits.filter(medicalDoctor => medicalDoctor)
        : [];

    function add() {
        openAddModal({
            modalContent: (
                <AddConsultationFormModal
                    recordDetailView={<DoctorsConsultationEdit />}
                    addRecord={record => addConsultation(record, doctor, index)}
                />
            ),
            modalData: {},
        });
    }

    function edit() {
        openEditModal({
            modalContent: (
                <EditConsultationFormModal
                    recordDetailView={<DoctorsConsultationEdit />}
                    records={doctor.doctorsVisits || []}
                    updateRecord={(consultation, ind) =>
                        updateConsultation(ind, consultation, doctor)
                    }
                />
            ),
            modalData: {
                recordIndex: index,
            },
        });
    }

    function deleteVisit() {
        openDialog({
            content: {
                title: `Delete Doctor's Visit`,
                body: `Are you sure you would like to delete the selected doctor's visit ?`,
            },
            yesAction: {
                action: () => deleteConsultation(index, doctor),
            },
        });
    }
    return (
        <React.Fragment>
            <ButtonWrapper>
                <Grid container item xs={12} justify="flex-end">
                    <Tooltip title="Add Doctor's visit">
                        <Button id="AddConsultation" color="secondary" onClick={add}>
                            <AddCircle />
                        </Button>
                    </Tooltip>
                </Grid>
            </ButtonWrapper>
            <Grid style={{ paddingBottom: '16px' }}>
                {doctorsVisits.map((consultation, index) => {
                    return (
                        <ExpansionPanel
                            name={`Details of visit ${index + 1}`}
                            style={{ marginTop: '9px' }}
                            key={`visit${index}`}
                        >
                            <Grid container spacing={0} justify="flex-end">
                                <Grid container spacing={0} justify="space-between">
                                    <Grid item xs={10} lg={10}>
                                        <LabeledValue
                                            label="Date"
                                            value={getDisplayDate(consultation.date)}
                                        />
                                    </Grid>
                                    <ButtonWrapper>
                                        <Grid item xs={2} lg={2} className={classes.rightAlign}>
                                            <Tooltip title="Edit Doctors Visit">
                                                <Button
                                                    id="EditDoctorsVisits"
                                                    color="secondary"
                                                    onClick={edit}
                                                >
                                                    <Edit />
                                                </Button>
                                            </Tooltip>
                                            {doctorsVisits.length > 1 && (
                                                <Tooltip title="Delete Doctors Visit">
                                                    <Button
                                                        id="DeleteDoctorsVisits"
                                                        color="secondary"
                                                        onClick={deleteVisit}
                                                    >
                                                        <Delete />
                                                    </Button>
                                                </Tooltip>
                                            )}
                                        </Grid>
                                    </ButtonWrapper>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <LabeledValue label="Reason" value={consultation.reason} />
                                </Grid>
                            </Grid>
                        </ExpansionPanel>
                    );
                })}
            </Grid>
        </React.Fragment>
    );
};

const mapStateToProps = (state: State) => ({
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    addConsultation: (record, medicalHistory, index) =>
        dispatch(addAndSubmitDoctorConsultationThunk(record, medicalHistory, index)),
    openModal: options => dispatch(openModalAction(options)),
    openAddModal: options => dispatch(openAddModalAction(options)),
    openEditModal: options => dispatch(openEditModalAction(options)),
    updateConsultation: (visitIndex: number, visit: Consultation, medicalDoctor: Doctor) =>
        dispatch(updateAndSubmitDoctorsVisit(visitIndex, visit, medicalDoctor)),
    deleteConsultation: (visitIndex: number, medicalDoctor: Doctor) =>
        dispatch(deleteAndSubmitDoctorVisitThunk(visitIndex, medicalDoctor)),
    openDialog: options => dispatch(openDialogAction(options)),
});

export const DoctorsConsultationView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(doctorsConsultationView));
