import { Grid, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { getCodeDescription, getDisplayDate } from '../../../../../../shared-functions';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import styles from '../../../../../../styles/styles';
import { Claimant } from '../../form-details-reducer';
import { getRelationship } from '../../../../../../codes/relationship-types';

interface Props {
    claimantDetails?: Claimant;
}

const personalDetailsView = ({ claimantDetails }: Props) => {
    if (!claimantDetails) {
        return null;
    }
    const personalDetails = claimantDetails.personalDetails || {};
    return (
        <React.Fragment>
            <DisplayValue label="Beneficiary on Record" value={claimantDetails.beneficiary} />
            <DisplayValue
                label="Relationship to life assured"
                value={getRelationship(
                    claimantDetails.relationshipToLifeAssured,
                    claimantDetails.relationshipToLifeAssuredDescription
                )}
            />
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Title"
                    value={getCodeDescription('title', personalDetails.title)}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Initials" value={personalDetails.initials} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="First Name" value={personalDetails.firstName} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Second Name" value={personalDetails.secondName} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Surname" value={personalDetails.lastName} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="ID Number" value={personalDetails.idNumber} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Date of Birth"
                    value={getDisplayDate(personalDetails.dateOfBirth)}
                />
            </Grid>
            <DisplayValue label="Employed" value={claimantDetails.employed} />
            <DisplayValue label="Employer" value={claimantDetails.employer} />
        </React.Fragment>
    );
};

interface DisplayProps {
    label: string;
    value?: string;
}

const DisplayValue = ({ label, value }: DisplayProps) => {
    if (!value) {
        return null;
    }
    return (
        <Grid item xs={6} lg={3}>
            <LabeledValue label={label} value={value} />
        </Grid>
    );
};

export const PersonalDetailsView = withStyles(styles)(personalDetailsView);
