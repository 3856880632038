import { withStyles, Grid } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import styles, { StyledComponent } from '../../../../../styles/styles';
import { connect } from 'react-redux';
import { State } from '../../../../../redux/root-reducer';
import { FormDetails, getFormDetails } from '../form-details-reducer';
import {
    FullWidthTextField,
    Condition,
    FullWidthRadioGroup,
    RadioOption,
    True,
} from '../../../../../forms';
import { requiredValidator } from '../../../../../forms/validations';
import { NumberMask } from '../../../../../forms/input-masks';

interface BaseProps {
    formDetails: FormDetails;
    prefix: string;
}

interface Props extends BaseProps, StyledComponent {}

const employmentDetailsEdit: React.FunctionComponent<Props> = ({
    classes,
    formDetails,
    prefix,
}: Props) => {
    return (
        <Grid container spacing={5} justify="flex-start">
            <Grid item xs={6}>
                <FullWidthRadioGroup name={`${prefix}.isPolicyHolderEmployed`} label="Employed?">
                    <RadioOption value="Yes" label="Yes" />
                    <RadioOption value="No" label="No" />
                </FullWidthRadioGroup>
            </Grid>
            <Condition when={`${prefix}.isPolicyHolderEmployed`} is={'Yes'}>
                <True>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            label="Employer Name"
                            name={`${prefix}.employerName`}
                            validate={requiredValidator}
                        />
                    </Grid>
                </True>
            </Condition>
            <Condition when={`${prefix}.isPolicyHolderEmployed`} is={'Yes'}>
                <True>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            label="Nominated Occupation"
                            name={`${prefix}.nominatedOccupation`}
                            validate={requiredValidator}
                        />
                    </Grid>
                </True>
            </Condition>
            <Condition when={`${prefix}.isPolicyHolderEmployed`} is={'Yes'}>
                <True>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            label="Nominated Industry"
                            name={`${prefix}.industryOfOccupation`}
                            validate={requiredValidator}
                        />
                    </Grid>
                </True>
            </Condition>
            <Grid item xs={6}>
                <FullWidthTextField
                    label="SA Income reference tax number"
                    name={`${prefix}.saTaxReferenceNumber`}
                    fullLabel
                    mask={NumberMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField label="tax office" name={`${prefix}.taxOffice`} />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state: State) => ({
    formDetails: getFormDetails(state),
});

const mapDispatchToProps = {};

export const EmploymentDetailsEdit = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(employmentDetailsEdit));
