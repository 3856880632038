import React from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import { Section } from '../../../../../../controls/section';
import { State } from '../../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { UnderwritingSpecialOffer } from './underwriting-special-offer-model';

interface OwnProps {
    specialOffers: UnderwritingSpecialOffer[];
}

interface StateProps {}

interface DispatchProps {}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const underwritingSpecialOffersView: React.FunctionComponent<Props> = ({
    specialOffers,
}: Props) => {
    return (
        <Section title="Special Offers" noChildrenTitle="No Special Offers">
            {specialOffers.map((specialOffer, index) => (
                <UnderwritingSpecialOfferView specialOffer={specialOffer} key={index} />
            ))}
        </Section>
    );
};

interface SpecialOfferProps {
    specialOffer: UnderwritingSpecialOffer;
}

const UnderwritingSpecialOfferView: React.FunctionComponent<SpecialOfferProps> = ({
    specialOffer,
}: SpecialOfferProps) => {
    return (
        <React.Fragment>
            <Grid item xs={3}>
                <LabeledValue id="code" label="Code" value={specialOffer.code} />
            </Grid>
            <Grid item xs={9}>
                <LabeledValue
                    id="description"
                    label="Description"
                    value={specialOffer.description}
                />
            </Grid>
        </React.Fragment>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = () => ({});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {};

export const UnderwritingSpecialOffersView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(underwritingSpecialOffersView));
