import React from 'react'; // eslint-disable-line
import { OnSubmitSuccessProps } from '../../../../../workflow-navigation';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { GenericForm, FormErrors } from '../../../../../forms';
import {
    LiquidAndDistribution,
    getRALiquidAndDistributionDetails,
} from '../liquid-and-distribution-reducer';
import {
    UpdateRALiquidAndDistribution,
    updateRALiquidAndDistributionAction,
    LiquidAndDistributionModel,
} from '../actions/add-liquidation-details';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { State } from '../../../../../redux/root-reducer';
import { UpdateLiquidAndDistributionView } from './liquidation-dynamic-add';
import { isArray } from 'util';
import { getDependants, Dependant } from '../../dependants/reducer';

interface Props extends OnSubmitSuccessProps, StyledComponent {
    updateRALiquidAndDistribution: UpdateRALiquidAndDistribution;
    raLiquidAndDistribution: LiquidAndDistribution;
    posibleDependants: Dependant[];
}
let liquidAndDistributionValues: LiquidAndDistributionModel = { liquidAndDistribution: [] };
const RALiquidAndDistributionForm = GenericForm<LiquidAndDistributionModel>();
const raLiquidAndDistributionAddEditModal: React.FunctionComponent<Props> = ({
    raLiquidAndDistribution,
    updateRALiquidAndDistribution,
    classes,
    posibleDependants,
    ...rest
}: Props) => {
    if (isArray(raLiquidAndDistribution) && raLiquidAndDistribution.length > 0) {
        liquidAndDistributionValues = {
            liquidAndDistribution: raLiquidAndDistribution,
        };
    }
    const onSubmit = (liquidAndDistribution: LiquidAndDistributionModel) => {
        return updateRALiquidAndDistribution(liquidAndDistribution);
    };

    return (
        <RALiquidAndDistributionForm
            onSubmit={onSubmit}
            initialValues={liquidAndDistributionValues}
            {...rest}
        >
            <UpdateLiquidAndDistributionView
                classes={classes}
                liquidAndDistribution={liquidAndDistributionValues.liquidAndDistribution}
                posibleDependants={posibleDependants}
            />
            <FormErrors />
        </RALiquidAndDistributionForm>
    );
};
const mapDispatchToProps = {
    updateRALiquidAndDistribution: updateRALiquidAndDistributionAction,
};
const mapStateToProps = (state: State) => ({
    raLiquidAndDistribution: getRALiquidAndDistributionDetails(state),
    posibleDependants: getDependants(state),
});
export const RALiquidAndDistributionAddEditModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(raLiquidAndDistributionAddEditModal));
