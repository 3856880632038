export const DetailedClaimConditionsDread = [
    {
        value: 'Accidental HIV - Proof of accidental contraction HIV as per policy document',
        label: 'Accidental HIV - Proof of accidental contraction HIV as per policy document',
    },
    {
        value: 'AIDS - Proof of clinical manifestation of AIDS as per policy document',
        label: 'AIDS - Proof of clinical manifestation of AIDS as per policy document',
    },
    {
        value: `Alzheimer's disease and progressive dementia - On diagnosis of Alzheimer's disease or progressive dementia in accordance with the latest version of the DSM criteria.`,
        label: `Alzheimer's disease and progressive dementia - On diagnosis of Alzheimer's disease or progressive dementia in accordance with the latest version of the DSM criteria.`,
    },
    {
        value:
            'Aneurysm - Endovascular surgery or stent to repair any aneurysm of the following branches of the aorta: subclavian, brachiocephalic, splenic, renal, iliac and ileofemoral arteries',
        label:
            'Aneurysm - Endovascular surgery or stent to repair any aneurysm of the following branches of the aorta: subclavian, brachiocephalic, splenic, renal, iliac and ileofemoral arteries',
    },
    {
        value: 'Aneurysm - Endovascular surgery or stent to repair any aortic aneurysm',
        label: 'Aneurysm - Endovascular surgery or stent to repair any aortic aneurysm',
    },
    {
        value:
            'Aneurysm - Surgery (including by-pass graft or keyhole surgery) to repair an aneurysm, dissection or a narrowing of the thoracic or abdominal aorta',
        label:
            'Aneurysm - Surgery (including by-pass graft or keyhole surgery) to repair an aneurysm, dissection or a narrowing of the thoracic or abdominal aorta',
    },
    {
        value:
            'Aneurysm - Surgical repairs (including bypass graft or keyhole surgery) of an ileo-femoral aneurysm',
        label:
            'Aneurysm - Surgical repairs (including bypass graft or keyhole surgery) of an ileo-femoral aneurysm',
    },
    {
        value:
            'Aneurysm - Surgical repairs (including bypass graft or keyhole surgery) of any aneurysm of the following branches of the aorta: subclavian, brachiocephalic, splenic, renal and iliac arteries',
        label:
            'Aneurysm - Surgical repairs (including bypass graft or keyhole surgery) of any aneurysm of the following branches of the aorta: subclavian, brachiocephalic, splenic, renal and iliac arteries',
    },
    {
        value: 'Aplastic anaemia and other blood disorders - A bone marrow transplantation',
        label: 'Aplastic anaemia and other blood disorders - A bone marrow transplantation',
    },
    {
        value:
            'Aplastic anaemia and other blood disorders - Aplastic anaemia and other blood disorders requiring repeated blood or blood product transfusion (at least four units of blood or blood products per month for at least 3 months) with at least one month of the schedule already adhered to.',
        label:
            'Aplastic anaemia and other blood disorders - Aplastic anaemia and other blood disorders requiring repeated blood or blood product transfusion (at least four units of blood or blood products per month for at least 3 months) with at least one month of the schedule already adhered to.',
    },
    {
        value:
            'Aplastic anaemia and other blood disorders - Confirmed diagnosis of aplastic anaemia/bone marrow aplasia',
        label:
            'Aplastic anaemia and other blood disorders - Confirmed diagnosis of aplastic anaemia/bone marrow aplasia',
    },
    {
        value:
            'Aplastic anaemia and other blood disorders - Myelodyplastic syndrome coring 2.5 or higher on International Prognostic Scoring System (IPPS)',
        label:
            'Aplastic anaemia and other blood disorders - Myelodyplastic syndrome coring 2.5 or higher on International Prognostic Scoring System (IPPS)',
    },
    {
        value:
            'Aplastic anaemia and other blood disorders - Myelodyplastic syndrome requiring bone marrow transplant',
        label:
            'Aplastic anaemia and other blood disorders - Myelodyplastic syndrome requiring bone marrow transplant',
    },
    {
        value:
            'Aplastic anaemia and other blood disorders - On diagnosis of Myeloproliferitive disorder (Polycythemia vera, chronic myelogenous leukaemia, essential thrombocythemia, or myelofibrosis)',
        label:
            'Aplastic anaemia and other blood disorders - On diagnosis of Myeloproliferitive disorder (Polycythemia vera, chronic myelogenous leukaemia, essential thrombocythemia, or myelofibrosis)',
    },
    {
        value:
            'Aplastic anaemia and other blood disorders - Polycythemia vera with major organ failure',
        label:
            'Aplastic anaemia and other blood disorders - Polycythemia vera with major organ failure',
    },
    {
        value: 'Arrhythmia - Arrhythmia having undergone a defibrillator insertion',
        label: 'Arrhythmia - Arrhythmia having undergone a defibrillator insertion',
    },
    {
        value: 'Arrhythmia - Arrhythmia having undergone a permanent pacemaker insertion',
        label: 'Arrhythmia - Arrhythmia having undergone a permanent pacemaker insertion',
    },
    {
        value: 'Arrhythmia - Arrhythmia having undergone pathway ablation',
        label: 'Arrhythmia - Arrhythmia having undergone pathway ablation',
    },
    {
        value:
            'Arrhythmia - Intravenous inotropic support for more than 2 days. (Note that, for this benefit, claims may also be accepted under the Myocardial Infarction, Cardiomyopathy or Pericardial diseases benefit categories instead of the Arrhythmias benefit category)',
        label:
            'Arrhythmia - Intravenous inotropic support for more than 2 days. (Note that, for this benefit, claims may also be accepted under the Myocardial Infarction, Cardiomyopathy or Pericardial diseases benefit categories instead of the Arrhythmias benefit category)',
    },
    {
        value: 'Arrhythmia - Permanent atrial fibrillation on optimal treatment',
        label: 'Arrhythmia - Permanent atrial fibrillation on optimal treatment',
    },
    {
        value: 'Cancer and leukaemia - Acute Lymphoblastic Leukaemia',
        label: 'Cancer and leukaemia - Acute Lymphoblastic Leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Acute Myeloblastic Leukaemia',
        label: 'Cancer and leukaemia - Acute Myeloblastic Leukaemia',
    },
    {
        value:
            'Cancer and leukaemia - Chronic Lymphocytic Leukaemia: Stage 0 or I on the Rai classification system',
        label:
            'Cancer and leukaemia - Chronic Lymphocytic Leukaemia: Stage 0 or I on the Rai classification system',
    },
    {
        value:
            'Cancer and leukaemia - Chronic Lymphocytic Leukaemia: Stage II on the Rai classification system',
        label:
            'Cancer and leukaemia - Chronic Lymphocytic Leukaemia: Stage II on the Rai classification system',
    },
    {
        value:
            'Cancer and leukaemia - Chronic Lymphocytic Leukaemia: Stage III or IV on the Rai classification system',
        label:
            'Cancer and leukaemia - Chronic Lymphocytic Leukaemia: Stage III or IV on the Rai classification system',
    },
    {
        value: 'Cancer and leukaemia - Chronic Myeloid Leukaemia (no bone marrow transplantation)',
        label: 'Cancer and leukaemia - Chronic Myeloid Leukaemia (no bone marrow transplantation)',
    },
    {
        value:
            'Cancer and leukaemia - Chronic Myeloid Leukaemia: having undergone bone marrow transplant',
        label:
            'Cancer and leukaemia - Chronic Myeloid Leukaemia: having undergone bone marrow transplant',
    },
    {
        value: 'Cancer and leukaemia - Hairy cell leukaemia',
        label: 'Cancer and leukaemia - Hairy cell leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Multiple myeloma',
        label: 'Cancer and leukaemia - Multiple myeloma',
    },
    {
        value: 'Cancer and leukaemia - Multiple myeloma: Stage I or II on the Durie-Salmon scale',
        label: 'Cancer and leukaemia - Multiple myeloma: Stage I or II on the Durie-Salmon scale',
    },
    { value: 'Cancer and leukaemia - Stage I', label: 'Cancer and leukaemia - Stage I' },
    { value: 'Cancer and leukaemia - Stage II', label: 'Cancer and leukaemia - Stage II' },
    {
        value: 'Cancer and leukaemia - Stage III or IV',
        label: 'Cancer and leukaemia - Stage III or IV',
    },
    {
        value: 'Cancer and leukaemia - Brain tumour: Grade 2 on WHO classification',
        label: 'Cancer and leukaemia - Brain tumour: Grade 2 on WHO classification',
    },
    {
        value: 'Cancer and leukaemia - Brain tumour: Grade 3 or 4 on WHO classification',
        label: 'Cancer and leukaemia - Brain tumour: Grade 3 or 4 on WHO classification',
    },
    {
        value:
            'Cancer and leukaemia - Partial mastectomy for ductal or lobular carcinoma-in-situ of at least 50% of affected breast tissue in one breast. Excludes lumpectomy and adrantectomy.',
        label:
            'Cancer and leukaemia - Partial mastectomy for ductal or lobular carcinoma-in-situ of at least 50% of affected breast tissue in one breast. Excludes lumpectomy and adrantectomy.',
    },
    {
        value:
            'Cancer and leukaemia - Total mastectomy for ductal or lobular carcinoma-in-situ of the breast',
        label:
            'Cancer and leukaemia - Total mastectomy for ductal or lobular carcinoma-in-situ of the breast',
    },
    {
        value:
            'Cancer and leukaemia - Hodgkins lymphoma (Stage I on Ann Arbor classification system)',
        label:
            'Cancer and leukaemia - Hodgkins lymphoma (Stage I on Ann Arbor classification system)',
    },
    {
        value:
            'Cancer and leukaemia - Hodgkins lymphoma (Stage II on Ann Arbor classification system)',
        label:
            'Cancer and leukaemia - Hodgkins lymphoma (Stage II on Ann Arbor classification system)',
    },
    {
        value:
            'Cancer and leukaemia - Hodgkins lymphoma (Stage III or IV on Ann Arbor classification system)',
        label:
            'Cancer and leukaemia - Hodgkins lymphoma (Stage III or IV on Ann Arbor classification system)',
    },
    {
        value:
            'Cancer and leukaemia - Non-Hodgkins lymphoma (Stage I on Ann Arbor classification system)',
        label:
            'Cancer and leukaemia - Non-Hodgkins lymphoma (Stage I on Ann Arbor classification system)',
    },
    {
        value:
            'Cancer and leukaemia - Non-Hodgkins lymphoma (Stage II on Ann Arbor classification system)',
        label:
            'Cancer and leukaemia - Non-Hodgkins lymphoma (Stage II on Ann Arbor classification system)',
    },
    {
        value:
            'Cancer and leukaemia - Non-Hodgkins lymphoma (Stage III or IV on Ann Arbor classification system)',
        label:
            'Cancer and leukaemia - Non-Hodgkins lymphoma (Stage III or IV on Ann Arbor classification system)',
    },
    {
        value: 'Cancer and leukaemia - Carcinoid syndrome/tumour',
        label: 'Cancer and leukaemia - Carcinoid syndrome/tumour',
    },
    {
        value: 'Cancer and leukaemia - Prostate - T1NOMO',
        label: 'Cancer and leukaemia - Prostate - T1NOMO',
    },
    {
        value: 'Cancer and leukaemia - Prostate - T2NOMO (any grade)',
        label: 'Cancer and leukaemia - Prostate - T2NOMO (any grade)',
    },
    {
        value: 'Cancer and leukaemia - Prostate - T3NOMO (any grade)',
        label: 'Cancer and leukaemia - Prostate - T3NOMO (any grade)',
    },
    {
        value:
            'Cancer and leukaemia - Prostate - T4NOMO (any grade) or Prostate Cancer with lymph node involvement or metastases (at least N1 in TNM classification system)',
        label:
            'Cancer and leukaemia - Prostate - T4NOMO (any grade) or Prostate Cancer with lymph node involvement or metastases (at least N1 in TNM classification system)',
    },
    {
        value:
            'Cancer and leukaemia - Basal cell skin carcinoma (Stage I or II) having undergone a skin graft or skin flap',
        label:
            'Cancer and leukaemia - Basal cell skin carcinoma (Stage I or II) having undergone a skin graft or skin flap',
    },
    {
        value: 'Cancer and leukaemia - Basal cell skin carcinoma (Stage III)',
        label: 'Cancer and leukaemia - Basal cell skin carcinoma (Stage III)',
    },
    {
        value: 'Cancer and leukaemia - Basal cell skin carcinoma (Stage IV)',
        label: 'Cancer and leukaemia - Basal cell skin carcinoma (Stage IV)',
    },
    {
        value:
            'Cancer and leukaemia - Malignant melanoma with invasion beyond the epidermis or with a Breslow thickness of more than 1mm',
        label:
            'Cancer and leukaemia - Malignant melanoma with invasion beyond the epidermis or with a Breslow thickness of more than 1mm',
    },
    {
        value:
            'Cancer and leukaemia - Squamous cell skin carcinoma (Stage I or II) having undergone a skin graft or skin flap',
        label:
            'Cancer and leukaemia - Squamous cell skin carcinoma (Stage I or II) having undergone a skin graft or skin flap',
    },
    {
        value: 'Cancer and leukaemia - Squamous cell skin carcinoma (Stage III)',
        label: 'Cancer and leukaemia - Squamous cell skin carcinoma (Stage III)',
    },
    {
        value: 'Cancer and leukaemia - Squamous cell skin carcinoma (Stage IV)',
        label: 'Cancer and leukaemia - Squamous cell skin carcinoma (Stage IV)',
    },
    {
        value: 'Cancer and leukaemia - Metastatic cancer',
        label: 'Cancer and leukaemia - Metastatic cancer',
    },
    { value: 'Cardiomyopathy - A heart transplant', label: 'Cardiomyopathy - A heart transplant' },
    {
        value: 'Cardiomyopathy - But still NYHA class 3 (or METS < 5) and EF < 50% ',
        label: 'Cardiomyopathy - But still NYHA class 3 (or METS < 5) and EF < 50% ',
    },
    {
        value: 'Cardiomyopathy - But still NYHA class 4 (or METS < 5) and EF < 50% ',
        label: 'Cardiomyopathy - But still NYHA class 4 (or METS < 5) and EF < 50% ',
    },
    {
        value: 'Cardiomyopathy - But still NYHA class 4 (or METS < 3) and EF < 40% ',
        label: 'Cardiomyopathy - But still NYHA class 4 (or METS < 3) and EF < 40% ',
    },
    {
        value:
            'Cardio-pulmonary dysfunction - Cardio-pulmonary dysfunction resulting from or presenting as confirmed irreversible Cor Pulmonale',
        label:
            'Cardio-pulmonary dysfunction - Cardio-pulmonary dysfunction resulting from or presenting as confirmed irreversible Cor Pulmonale',
    },
    {
        value:
            'Cardio-pulmonary dysfunction - Cardio-pulmonary dysfunction resulting from or presenting as primary pulmonary hypertension',
        label:
            'Cardio-pulmonary dysfunction - Cardio-pulmonary dysfunction resulting from or presenting as primary pulmonary hypertension',
    },
    {
        value:
            'Cardio-pulmonary dysfunction - Cardio-pulmonary dysfunction resulting from or presenting as pulmonary venous occlusive disease',
        label:
            'Cardio-pulmonary dysfunction - Cardio-pulmonary dysfunction resulting from or presenting as pulmonary venous occlusive disease',
    },
    {
        value:
            'Carotid arterial disease - Angioplasty with or without stenting in both carotid arteries',
        label:
            'Carotid arterial disease - Angioplasty with or without stenting in both carotid arteries',
    },
    {
        value:
            'Carotid arterial disease - Angioplasty with or without stenting in one carotid artery',
        label:
            'Carotid arterial disease - Angioplasty with or without stenting in one carotid artery',
    },
    {
        value:
            'Carotid arterial disease - By-pass graft or endarterectomy in both carotid arteries',
        label:
            'Carotid arterial disease - By-pass graft or endarterectomy in both carotid arteries',
    },
    {
        value: 'Carotid arterial disease - By-pass graft or endarterectomy in one carotid artery',
        label: 'Carotid arterial disease - By-pass graft or endarterectomy in one carotid artery',
    },
    {
        value:
            'Cerebrovascular incident (Stoke) - A stroke where there are residual symptoms or signs and a full recovery is not made',
        label:
            'Cerebrovascular incident (Stoke) - A stroke where there are residual symptoms or signs and a full recovery is not made',
    },
    {
        value:
            'Cerebrovascular incident (Stoke) - On diagnosis of a stroke as defined in the policy document',
        label:
            'Cerebrovascular incident (Stoke) - On diagnosis of a stroke as defined in the policy document',
    },
    {
        value: 'Cerebrovascular incident (Stoke) - Stroke resulting in full recovery',
        label: 'Cerebrovascular incident (Stoke) - Stroke resulting in full recovery',
    },
    {
        value:
            'Cerebrovascular incident (Stoke) - Measured by a Whole Person Impairment of 11% to 20%',
        label:
            'Cerebrovascular incident (Stoke) - Measured by a Whole Person Impairment of 11% to 20%',
    },
    {
        value:
            'Cerebrovascular incident (Stoke) - Measured by a Whole Person Impairment of 21% to 35%',
        label:
            'Cerebrovascular incident (Stoke) - Measured by a Whole Person Impairment of 21% to 35%',
    },
    {
        value:
            'Cerebrovascular incident (Stoke) - Measured by a Whole Person Impairment of greater than 35%',
        label:
            'Cerebrovascular incident (Stoke) - Measured by a Whole Person Impairment of greater than 35%',
    },
    {
        value:
            'Cerebrovascular incident (Stoke) - Measured by the permanent inability to independently perform activities of daily living, scoring 1 major or 4 moderate categories, according to the Living Lifestyle Activities of Daily Living Table',
        label:
            'Cerebrovascular incident (Stoke) - Measured by the permanent inability to independently perform activities of daily living, scoring 1 major or 4 moderate categories, according to the Living Lifestyle Activities of Daily Living Table',
    },
    {
        value:
            'Cerebrovascular incident (Stoke) - Measured by the permanent inability to independently perform activities of daily living, scoring 2 moderate, or 1 moderate and 3 mild categories, according to the Living Lifestyle Activities of Daily Living Table ',
        label:
            'Cerebrovascular incident (Stoke) - Measured by the permanent inability to independently perform activities of daily living, scoring 2 moderate, or 1 moderate and 3 mild categories, according to the Living Lifestyle Activities of Daily Living Table ',
    },
    {
        value:
            'Cerebrovascular incident (Stoke) - Measured by The permanent inability to independently perform activities of daily living, scoring 3 moderate categories, according to the Living Lifestyle Activities of Daily Living Table',
        label:
            'Cerebrovascular incident (Stoke) - Measured by The permanent inability to independently perform activities of daily living, scoring 3 moderate categories, according to the Living Lifestyle Activities of Daily Living Table',
    },
    {
        value: 'Cerebrovascular incident (Stoke) - Subarachnoid haemorrhage',
        label: 'Cerebrovascular incident (Stoke) - Subarachnoid haemorrhage',
    },
    {
        value: 'Chronic liver disorders - A liver transplant or total hepatectomy',
        label: 'Chronic liver disorders - A liver transplant or total hepatectomy',
    },
    {
        value: 'Chronic liver disorders - End-stage liver failure',
        label: 'Chronic liver disorders - End-stage liver failure',
    },
    {
        value: 'Chronic liver disorders - Fulminant hepatic necrosis',
        label: 'Chronic liver disorders - Fulminant hepatic necrosis',
    },
    {
        value: 'Chronic liver disorders - Liver dysfunction classified as Child-Pugh class A',
        label: 'Chronic liver disorders - Liver dysfunction classified as Child-Pugh class A',
    },
    {
        value: 'Chronic liver disorders - Liver dysfunction classified as Child-Pugh class B',
        label: 'Chronic liver disorders - Liver dysfunction classified as Child-Pugh class B',
    },
    {
        value: 'Chronic liver disorders - Liver dysfunction classified as Child-Pugh class C',
        label: 'Chronic liver disorders - Liver dysfunction classified as Child-Pugh class C',
    },
    {
        value:
            'Chronic liver disorders - On confirmed diagnosis of primary amyloidosis of the liver or spleen',
        label:
            'Chronic liver disorders - On confirmed diagnosis of primary amyloidosis of the liver or spleen',
    },
    {
        value: 'Chronic liver disorders - Partial hepatectomy due to disease',
        label: 'Chronic liver disorders - Partial hepatectomy due to disease',
    },
    {
        value: 'Chronic liver disorders - Portal hypertesion',
        label: 'Chronic liver disorders - Portal hypertesion',
    },
    {
        value:
            'Coma - Scoring 1 major or 4 moderate categories according to the Living Lifestyle Activities of Daily Living Table',
        label:
            'Coma - Scoring 1 major or 4 moderate categories according to the Living Lifestyle Activities of Daily Living Table',
    },
    {
        value:
            'Coma - Scoring 2 moderate, or 1 moderate and 3 mild categories according to the Living Lifestyle Activities of Daily Living Table',
        label:
            'Coma - Scoring 2 moderate, or 1 moderate and 3 mild categories according to the Living Lifestyle Activities of Daily Living Table',
    },
    {
        value:
            'Coma - Scoring 3 moderate categories according to the Living Lifestyle Activities of Daily Living Table',
        label:
            'Coma - Scoring 3 moderate categories according to the Living Lifestyle Activities of Daily Living Table',
    },
    {
        value:
            'Coma - Coma meeting the description in the policy document and the comatose state is persistent for longer than 96 hours',
        label:
            'Coma - Coma meeting the description in the policy document and the comatose state is persistent for longer than 96 hours',
    },
    {
        value: 'Coronary artery disease - Angioplasty with or without stenting',
        label: 'Coronary artery disease - Angioplasty with or without stenting',
    },
    {
        value: 'Coronary artery disease - A heart transplant',
        label: 'Coronary artery disease - A heart transplant',
    },
    {
        value:
            'Coronary artery disease - Surgery to correct the narrowing of, or blockage to, any one coronary artery by means of a by-pass graft',
        label:
            'Coronary artery disease - Surgery to correct the narrowing of, or blockage to, any one coronary artery by means of a by-pass graft',
    },
    {
        value:
            'Coronary artery disease - Surgery to correct the narrowing of, or blockage to, three or more coronary arteries by means of a by-pass graft',
        label:
            'Coronary artery disease - Surgery to correct the narrowing of, or blockage to, three or more coronary arteries by means of a by-pass graft',
    },
    {
        value:
            'Coronary artery disease - Surgery to correct the narrowing of, or blockage to, two coronary arteries by means of a by-pass graft',
        label:
            'Coronary artery disease - Surgery to correct the narrowing of, or blockage to, two coronary arteries by means of a by-pass graft',
    },
    {
        value:
            'Coronary artery disease - Unstable angina requiring intravenous thrombolytic therapy (excluding anticoagulant and anti-platelet agents)',
        label:
            'Coronary artery disease - Unstable angina requiring intravenous thrombolytic therapy (excluding anticoagulant and anti-platelet agents)',
    },
    {
        value: 'Ear, nose and throat disorders - Recipient of cochlear or middle ear implant',
        label: 'Ear, nose and throat disorders - Recipient of cochlear or middle ear implant',
    },
    {
        value:
            'Ear, nose and throat disorders - Pure tone average hearing loss in both ears of 90 decibels or more',
        label:
            'Ear, nose and throat disorders - Pure tone average hearing loss in both ears of 90 decibels or more',
    },
    {
        value:
            'Ear, nose and throat disorders - Pure tone average hearing loss in one ear of 61-69 decibels',
        label:
            'Ear, nose and throat disorders - Pure tone average hearing loss in one ear of 61-69 decibels',
    },
    {
        value:
            'Ear, nose and throat disorders - Pure tone average hearing loss in one ear of 70 decibels or more',
        label:
            'Ear, nose and throat disorders - Pure tone average hearing loss in one ear of 70 decibels or more',
    },
    {
        value:
            'Ear, nose and throat disorders - Pure tone average hearing loss in one ear of 70-89 decibels',
        label:
            'Ear, nose and throat disorders - Pure tone average hearing loss in one ear of 70-89 decibels',
    },
    {
        value:
            'Ear, nose and throat disorders - Pure tone average hearing loss in one ear of 90 decibels or more',
        label:
            'Ear, nose and throat disorders - Pure tone average hearing loss in one ear of 90 decibels or more',
    },
    {
        value: 'Ear, nose and throat disorders - On diagnosis of mastoiditis',
        label: 'Ear, nose and throat disorders - On diagnosis of mastoiditis',
    },
    {
        value:
            'Ear, nose and throat disorders - Nasal bone destruction as a result of a disease process requiring reconstruction (trauma and cosmetic-related problems are specifically excluded)',
        label:
            'Ear, nose and throat disorders - Nasal bone destruction as a result of a disease process requiring reconstruction (trauma and cosmetic-related problems are specifically excluded)',
    },
    {
        value: 'Ear, nose and throat disorders - Otosclerosis resulting in hearing loss',
        label: 'Ear, nose and throat disorders - Otosclerosis resulting in hearing loss',
    },
    {
        value: 'Ear, nose and throat disorders - Tympanosclerosis resulting in hearing loss',
        label: 'Ear, nose and throat disorders - Tympanosclerosis resulting in hearing loss',
    },
    {
        value:
            'Endocrine conditions - On diagnosis of the following endocrine disorder: acromegaly',
        label:
            'Endocrine conditions - On diagnosis of the following endocrine disorder: acromegaly',
    },
    {
        value:
            'Endocrine conditions - On diagnosis of the following endocrine disorder: addisonian crisis',
        label:
            'Endocrine conditions - On diagnosis of the following endocrine disorder: addisonian crisis',
    },
    {
        value: `Endocrine conditions - On diagnosis of the following endocrine disorder: Conn's syndrome`,
        label: `Endocrine conditions - On diagnosis of the following endocrine disorder: Conn's syndrome`,
    },
    {
        value: `Endocrine conditions - On diagnosis of the following endocrine disorder: Cushing's syndrome`,
        label: `Endocrine conditions - On diagnosis of the following endocrine disorder: Cushing's syndrome`,
    },
    {
        value:
            'Endocrine conditions - On diagnosis of the following endocrine disorder: diabetes insipidus',
        label:
            'Endocrine conditions - On diagnosis of the following endocrine disorder: diabetes insipidus',
    },
    {
        value:
            'Endocrine conditions - Endocrine tumours having undergone surgical excision including: adrenal adenoma (or adrenalectomy), phaeochromocytoma, carcinoid tumour and thyroid adenoma, pituitary tumour having undergone excision.',
        label:
            'Endocrine conditions - Endocrine tumours having undergone surgical excision including: adrenal adenoma (or adrenalectomy), phaeochromocytoma, carcinoid tumour and thyroid adenoma, pituitary tumour having undergone excision.',
    },
    {
        value:
            'Endocrine conditions - Having undergone daily injectable insulin treatment for a continuous 6-month period for Type II diabetes mellitus that was deemed medically necessary. This must be confirmed with appropriate evidence such as blood tests.',
        label:
            'Endocrine conditions - Having undergone daily injectable insulin treatment for a continuous 6-month period for Type II diabetes mellitus that was deemed medically necessary. This must be confirmed with appropriate evidence such as blood tests.',
    },
    {
        value:
            'Endocrine conditions - On diagnosis of the following endocrine disorder: parathyroid tetany',
        label:
            'Endocrine conditions - On diagnosis of the following endocrine disorder: parathyroid tetany',
    },
    {
        value: `Endocrine conditions - On diagnosis of the following endocrine disorder: sheehan's syndrome`,
        label: `Endocrine conditions - On diagnosis of the following endocrine disorder: sheehan's syndrome`,
    },
    {
        value: `Endocrine conditions - On diagnosis of the following endocrine disorder: Simmond's disease`,
        label: `Endocrine conditions - On diagnosis of the following endocrine disorder: Simmond's disease`,
    },
    {
        value:
            'Endocrine conditions - On diagnosis of the following endocrine disorder: thyrotoxic crisis',
        label:
            'Endocrine conditions - On diagnosis of the following endocrine disorder: thyrotoxic crisis',
    },
    {
        value: 'Endocrine conditions - On diagnosis of Type I diabetes',
        label: 'Endocrine conditions - On diagnosis of Type I diabetes',
    },
    {
        value: 'Eye disorders - Chronic corneal ulcer of 3 months or longer duration',
        label: 'Eye disorders - Chronic corneal ulcer of 3 months or longer duration',
    },
    { value: 'Eye disorders - Corneal transplant', label: 'Eye disorders - Corneal transplant' },
    {
        value: 'Eye disorders - Irreversible diabetic retinopathy (Grade III)',
        label: 'Eye disorders - Irreversible diabetic retinopathy (Grade III)',
    },
    {
        value: 'Eye disorders - Irreversible diabetic retinopathy (Grade IV)',
        label: 'Eye disorders - Irreversible diabetic retinopathy (Grade IV)',
    },
    {
        value: 'Eye disorders - Irreversible hemianopia in both eyes',
        label: 'Eye disorders - Irreversible hemianopia in both eyes',
    },
    {
        value: 'Eye disorders - Irreversible hemianopia in one eye',
        label: 'Eye disorders - Irreversible hemianopia in one eye',
    },
    {
        value:
            'Eye disorders - Irreversible loss of sight in both eyes with best corrected bilateral visual acuity of 6/24 or worse',
        label:
            'Eye disorders - Irreversible loss of sight in both eyes with best corrected bilateral visual acuity of 6/24 or worse',
    },
    {
        value:
            'Eye disorders - Irreversible loss of sight in both eyes with best corrected bilateral visual acuity of 6/30 or worse',
        label:
            'Eye disorders - Irreversible loss of sight in both eyes with best corrected bilateral visual acuity of 6/30 or worse',
    },
    {
        value:
            'Eye disorders - Irreversible loss of sight in one eye where the visual activity is 6/30 or worse in the affected eye',
        label:
            'Eye disorders - Irreversible loss of sight in one eye where the visual activity is 6/30 or worse in the affected eye',
    },
    { value: 'Eye disorders - Optic neuritis', label: 'Eye disorders - Optic neuritis' },
    {
        value:
            'Eye disorders - Orbital abscess treated with intravenous antibiotics or surgical drainage',
        label:
            'Eye disorders - Orbital abscess treated with intravenous antibiotics or surgical drainage',
    },
    { value: 'Eye disorders - Retinal detachment', label: 'Eye disorders - Retinal detachment' },
    {
        value: 'Gastrointestinal disease - Having a permanent colostomy or ileostomy in place',
        label: 'Gastrointestinal disease - Having a permanent colostomy or ileostomy in place',
    },
    {
        value:
            'Gastrointestinal disease - Having undergone recurrent surgery (at least a second surgery to colon or small intestine)',
        label:
            'Gastrointestinal disease - Having undergone recurrent surgery (at least a second surgery to colon or small intestine)',
    },
    {
        value:
            'Gastrointestinal disease - Having undergone treatment for 4 continuous months with immunomodulators to control systems or having developed corticosteroid dependency.',
        label:
            'Gastrointestinal disease - Having undergone treatment for 4 continuous months with immunomodulators to control systems or having developed corticosteroid dependency.',
    },
    {
        value: 'Gastrointestinal disease - Rectal fistula having undergone surgery',
        label: 'Gastrointestinal disease - Rectal fistula having undergone surgery',
    },
    {
        value:
            'Gastrointestinal disease - Total colectomy (removal or the ascending, descending and transverse colon)',
        label:
            'Gastrointestinal disease - Total colectomy (removal or the ascending, descending and transverse colon)',
    },
    {
        value: 'Gastrointestinal disease - Trachael oesophageal fistula having undergone surgery',
        label: 'Gastrointestinal disease - Trachael oesophageal fistula having undergone surgery',
    },
    {
        value: 'Heart transplant - A heart transplant',
        label: 'Heart transplant - A heart transplant',
    },
    {
        value: `Living Lifestyle catch-all - Diagnosis of a terminal illness which is reasonably expected, in the opinion of Liberty's Chief Medical Officer, to reduce the Life Assured's life expectancy to a period of 12 months or less`,
        label: `Living Lifestyle catch-all - Diagnosis of a terminal illness which is reasonably expected, in the opinion of Liberty's Chief Medical Officer, to reduce the Life Assured's life expectancy to a period of 12 months or less`,
    },
    {
        value:
            'Living Lifestyle catch-all - Scoring 1 major, or 4 moderate categories according to the Living Lifestyle Activities of Daily Living Table',
        label:
            'Living Lifestyle catch-all - Scoring 1 major, or 4 moderate categories according to the Living Lifestyle Activities of Daily Living Table',
    },
    {
        value:
            'Living Lifestyle catch-all - Scoring 2 moderate, or 1 moderate and 3 mild categories according to the Living Lifestyle Activities of Daily Living Table',
        label:
            'Living Lifestyle catch-all - Scoring 2 moderate, or 1 moderate and 3 mild categories according to the Living Lifestyle Activities of Daily Living Table',
    },
    {
        value: `Living Lifestyle catch-all - The degree of impairment results in a Whole Person Impairment of at least 35% and meets the class 4 impairment criteria specified for the relevant system(s) in the AMA guide, in the opinion of Liberty's Chief Medical Officer`,
        label: `Living Lifestyle catch-all - The degree of impairment results in a Whole Person Impairment of at least 35% and meets the class 4 impairment criteria specified for the relevant system(s) in the AMA guide, in the opinion of Liberty's Chief Medical Officer`,
    },
    {
        value: 'Loss of and loss of use of limbs - Loss of use of or loss of 1 foot',
        label: 'Loss of and loss of use of limbs - Loss of use of or loss of 1 foot',
    },
    {
        value: 'Loss of and loss of use of limbs - Loss of use of or loss of 1 hand',
        label: 'Loss of and loss of use of limbs - Loss of use of or loss of 1 hand',
    },
    {
        value: 'Loss of and loss of use of limbs - Loss of use of or loss of 1 hand and one foot',
        label: 'Loss of and loss of use of limbs - Loss of use of or loss of 1 hand and one foot',
    },
    {
        value: 'Loss of and loss of use of limbs - Loss of use of or loss of 1 limb',
        label: 'Loss of and loss of use of limbs - Loss of use of or loss of 1 limb',
    },
    {
        value: 'Loss of and loss of use of limbs - Loss of use of or loss of 2 feet',
        label: 'Loss of and loss of use of limbs - Loss of use of or loss of 2 feet',
    },
    {
        value: 'Loss of and loss of use of limbs - Loss of use of or loss of 2 hands',
        label: 'Loss of and loss of use of limbs - Loss of use of or loss of 2 hands',
    },
    {
        value:
            'Loss of and loss of use of limbs - Loss of use of or loss of 3 or more fingers on the same hand',
        label:
            'Loss of and loss of use of limbs - Loss of use of or loss of 3 or more fingers on the same hand',
    },
    {
        value: 'Loss of and loss of use of limbs - Loss of use of or loss of a thumb',
        label: 'Loss of and loss of use of limbs - Loss of use of or loss of a thumb',
    },
    {
        value: 'Loss of and loss of use of limbs - Loss of use of or loss of more than one limb',
        label: 'Loss of and loss of use of limbs - Loss of use of or loss of more than one limb',
    },
    {
        value: 'Loss of and loss of use of limbs - Major joint replacement',
        label: 'Loss of and loss of use of limbs - Major joint replacement',
    },
    {
        value:
            'Loss of and loss of use of limbs - Surgical repair of major motor nerve after complete severance',
        label:
            'Loss of and loss of use of limbs - Surgical repair of major motor nerve after complete severance',
    },
    {
        value:
            'Major burns - Involving more than 10% but less than or equal to 20% of the body surface area',
        label:
            'Major burns - Involving more than 10% but less than or equal to 20% of the body surface area',
    },
    {
        value:
            'Major burns - Involving more than 20% but less than or equal to 30% of the body surface area',
        label:
            'Major burns - Involving more than 20% but less than or equal to 30% of the body surface area',
    },
    {
        value: 'Major burns - Involving more than 30% of the body surface area',
        label: 'Major burns - Involving more than 30% of the body surface area',
    },
    { value: 'Major burns - Involving one hand', label: 'Major burns - Involving one hand' },
    {
        value: 'Major burns - Involving the face, the airways, or both hands',
        label: 'Major burns - Involving the face, the airways, or both hands',
    },
    {
        value: 'Major burns - Involving more than 15% fo the body',
        label: 'Major burns - Involving more than 15% fo the body',
    },
    {
        value:
            'Motor neuron disease - On diagnosis of motor neurone disease by an appropriate specialist',
        label:
            'Motor neuron disease - On diagnosis of motor neurone disease by an appropriate specialist',
    },
    {
        value: 'Multiple sclerosis - On diagnosis of multiple sclerosis',
        label: 'Multiple sclerosis - On diagnosis of multiple sclerosis',
    },
    {
        value: 'Multiple sclerosis - On diagnosis of progressive multiple sclerosis',
        label: 'Multiple sclerosis - On diagnosis of progressive multiple sclerosis',
    },
    {
        value:
            'Multiple sclerosis - Optic neuritis where 2 or more plaques are seen to be demyelinating on an MRI',
        label:
            'Multiple sclerosis - Optic neuritis where 2 or more plaques are seen to be demyelinating on an MRI',
    },
    {
        value:
            'Multiple sclerosis - Scoring 1 major or 4 moderate categories according to the Living Lifestyle Activities of Daily Living Table',
        label:
            'Multiple sclerosis - Scoring 1 major or 4 moderate categories according to the Living Lifestyle Activities of Daily Living Table',
    },
    {
        value:
            'Multiple sclerosis - Scoring 2 moderate, or 1 moderate and 3 mild categories according to the Living Lifestyle Activities of Daily Living Table',
        label:
            'Multiple sclerosis - Scoring 2 moderate, or 1 moderate and 3 mild categories according to the Living Lifestyle Activities of Daily Living Table',
    },
    {
        value:
            'Multiple sclerosis - Scoring 3 moderate categories according to the Living Lifestyle Activities of Daily Living Table',
        label:
            'Multiple sclerosis - Scoring 3 moderate categories according to the Living Lifestyle Activities of Daily Living Table',
    },
    {
        value:
            'Muscular dystrophy - Scoring 1 major under either the Posture and Motion category and the Movement and Self Care category according to the Living Lifestyle Activities of Daily Living Table',
        label:
            'Muscular dystrophy - Scoring 1 major under either the Posture and Motion category and the Movement and Self Care category according to the Living Lifestyle Activities of Daily Living Table',
    },
    {
        value:
            'Muscular dystrophy - Scoring 1 moderate under both the Posture and Motion category and the Movement and Self Care category according to the Living Lifestyle Activities of Daily Living Table',
        label:
            'Muscular dystrophy - Scoring 1 moderate under both the Posture and Motion category and the Movement and Self Care category according to the Living Lifestyle Activities of Daily Living Table',
    },
    {
        value: 'Muscular dystrophy - On diagnosis of muscular dystrophy',
        label: 'Muscular dystrophy - On diagnosis of muscular dystrophy',
    },
    {
        value:
            'Myasthenia gravis - Scoring 1 major or 4 moderate categories according to the Living Lifestyle Activities of Daily Living Table',
        label:
            'Myasthenia gravis - Scoring 1 major or 4 moderate categories according to the Living Lifestyle Activities of Daily Living Table',
    },
    {
        value:
            'Myasthenia gravis - Scoring 2 moderate, or 1 moderate and 3 mild categories according to the Living Lifestyle Activities of Daily Living Table ',
        label:
            'Myasthenia gravis - Scoring 2 moderate, or 1 moderate and 3 mild categories according to the Living Lifestyle Activities of Daily Living Table ',
    },
    {
        value:
            'Myasthenia gravis - Scoring 3 moderate categories according to the Living Lifestyle Activities of Daily Living Table',
        label:
            'Myasthenia gravis - Scoring 3 moderate categories according to the Living Lifestyle Activities of Daily Living Table',
    },
    {
        value: 'Myasthenia gravis - On diagnosis of myasthenia gravis',
        label: 'Myasthenia gravis - On diagnosis of myasthenia gravis',
    },
    {
        value:
            'Myocardial infarction (heart attack) - Acute heart failure as diagnosed by an appropriate specialist and EF < 45 % at time of diagnosis',
        label:
            'Myocardial infarction (heart attack) - Acute heart failure as diagnosed by an appropriate specialist and EF < 45 % at time of diagnosis',
    },
    {
        value: 'Myocardial infarction (heart attack) - A heart transplant',
        label: 'Myocardial infarction (heart attack) - A heart transplant',
    },
    {
        value:
            'Myocardial infarction (heart attack) - Myocardial infarction as defined in the policy document, but Trop T > 1,0 ng/ml or Trop I > 0,5 ng/ml',
        label:
            'Myocardial infarction (heart attack) - Myocardial infarction as defined in the policy document, but Trop T > 1,0 ng/ml or Trop I > 0,5 ng/ml',
    },
    {
        value:
            'Myocardial infarction (heart attack) - Myocardial infarction resulting in at least 2 of the following: 1. New pathological Q-waves on ECG; or 2. Compatible clinical symptoms; or 3. Raised cardiac markers: • Trop T > 1,0 ng/ml or Trop I > 0,5 ng/ml; or • Raised CK-MB mass: More than 2 times normal values in acute presentation phase; or more than 4 times normal values post-intervention; or • Total CPK elevation of more than 2x normal values, with at least 6% being CK-MB',
        label:
            'Myocardial infarction (heart attack) - Myocardial infarction resulting in at least 2 of the following: 1. New pathological Q-waves on ECG; or 2. Compatible clinical symptoms; or 3. Raised cardiac markers: • Trop T > 1,0 ng/ml or Trop I > 0,5 ng/ml; or • Raised CK-MB mass: More than 2 times normal values in acute presentation phase; or more than 4 times normal values post-intervention; or • Total CPK elevation of more than 2x normal values, with at least 6% being CK-MB',
    },
    {
        value:
            'Myocardial infarction (heart attack) - On diagnosis of a myocardial infarction as specified at the beginning of benefit category 2.3 myocardial Infarction',
        label:
            'Myocardial infarction (heart attack) - On diagnosis of a myocardial infarction as specified at the beginning of benefit category 2.3 myocardial Infarction',
    },
    {
        value:
            'Myocardial infarction (heart attack) - But still NYHA class 3 (or METS < 5) and EF < 50% as measured 6 weeks after the myocardial infarction',
        label:
            'Myocardial infarction (heart attack) - But still NYHA class 3 (or METS < 5) and EF < 50% as measured 6 weeks after the myocardial infarction',
    },
    {
        value:
            'Myocardial infarction (heart attack) - But still NYHA class 4 (or METS < 3) and EF < 40% as measured 6 weeks after the myocardial infarction',
        label:
            'Myocardial infarction (heart attack) - But still NYHA class 4 (or METS < 3) and EF < 40% as measured 6 weeks after the myocardial infarction',
    },
    {
        value:
            'Myocardial infarction (heart attack) - But still NYHA class 4 (or METS < 5) and EF < 50% as measured 6 weeks after the myocardial infarction',
        label:
            'Myocardial infarction (heart attack) - But still NYHA class 4 (or METS < 5) and EF < 50% as measured 6 weeks after the myocardial infarction',
    },
    {
        value:
            'Other autoimmune disorders - Arteritis or endarteritis diagnosed on biopsy and having been hospitalised for more than 7 days',
        label:
            'Other autoimmune disorders - Arteritis or endarteritis diagnosed on biopsy and having been hospitalised for more than 7 days',
    },
    {
        value:
            'Other autoimmune disorders - Complications involving the musculoskeletal system having undergone surgery including joint replacement, joint reconstruction and joint fixation',
        label:
            'Other autoimmune disorders - Complications involving the musculoskeletal system having undergone surgery including joint replacement, joint reconstruction and joint fixation',
    },
    {
        value:
            'Other autoimmune disorders - Other autoimmune disorders involving one major organ excluding the skin',
        label:
            'Other autoimmune disorders - Other autoimmune disorders involving one major organ excluding the skin',
    },
    {
        value:
            'Other autoimmune disorders - Other autoimmune disorders resulting in the failure of one or more major organs despite optimal treatment as defined in the relevant benefit categories, excluding the skin',
        label:
            'Other autoimmune disorders - Other autoimmune disorders resulting in the failure of one or more major organs despite optimal treatment as defined in the relevant benefit categories, excluding the skin',
    },
    {
        value:
            'Other autoimmune disorders - Other autoimmune disorders producing symptoms and involving three or more of the following major organs: lung, heart, brain, liver and kidneys',
        label:
            'Other autoimmune disorders - Other autoimmune disorders producing symptoms and involving three or more of the following major organs: lung, heart, brain, liver and kidneys',
    },
    {
        value:
            'Other autoimmune disorders - Other autoimmune disorders producing symptoms and involving two or more of the following major organs: lung, heart, brain, liver and kidneys',
        label:
            'Other autoimmune disorders - Other autoimmune disorders producing symptoms and involving two or more of the following major organs: lung, heart, brain, liver and kidneys',
    },
    {
        value:
            'Other autoimmune disorders - Other autoimmune disorders resulting in permanent inability to independently perform Activities of Daily Living, scoring 1 major under either the Posture and Motion category and the Movement and Self Care category according to the Living Lifestyle Activities of Daily Living Table',
        label:
            'Other autoimmune disorders - Other autoimmune disorders resulting in permanent inability to independently perform Activities of Daily Living, scoring 1 major under either the Posture and Motion category and the Movement and Self Care category according to the Living Lifestyle Activities of Daily Living Table',
    },
    {
        value:
            'Other autoimmune disorders - Other autoimmune disorders resulting in permanent inability to independently perform Activities of Daily Living, scoring 1 moderate under both the Posture and Motion category and the Movement and Self Care category according to the Living Lifestyle Activities of Daily Living Table',
        label:
            'Other autoimmune disorders - Other autoimmune disorders resulting in permanent inability to independently perform Activities of Daily Living, scoring 1 moderate under both the Posture and Motion category and the Movement and Self Care category according to the Living Lifestyle Activities of Daily Living Table',
    },
    {
        value:
            'Other autoimmune disorders - Disorders resulting in the failure of one or more major organs despite optimal treatment as defined in the relevant benefit categories, excluding the skin',
        label:
            'Other autoimmune disorders - Disorders resulting in the failure of one or more major organs despite optimal treatment as defined in the relevant benefit categories, excluding the skin',
    },
    {
        value:
            'Other central nervous system disorders - Any condition or brain abscess having undergone open brain surgery',
        label:
            'Other central nervous system disorders - Any condition or brain abscess having undergone open brain surgery',
    },
    {
        value:
            'Other central nervous system disorders - Benign brain tumour treated using radiosurgery or stereostactic surgery',
        label:
            'Other central nervous system disorders - Benign brain tumour treated using radiosurgery or stereostactic surgery',
    },
    {
        value:
            'Other central nervous system disorders - Scoring 1 major or 4 moderate categories, according to the Living Lifestyle Activities of Daily Living Table',
        label:
            'Other central nervous system disorders - Scoring 1 major or 4 moderate categories, according to the Living Lifestyle Activities of Daily Living Table',
    },
    {
        value:
            'Other central nervous system disorders - Scoring 2 moderate, or 1 moderate and 3 mild categories, according to the Living Lifestyle Activities of Daily Living Table',
        label:
            'Other central nervous system disorders - Scoring 2 moderate, or 1 moderate and 3 mild categories, according to the Living Lifestyle Activities of Daily Living Table',
    },
    {
        value:
            'Other central nervous system disorders - Scoring 3 moderate categories, according to the Living Lifestyle Activities of Daily Living Table',
        label:
            'Other central nervous system disorders - Scoring 3 moderate categories, according to the Living Lifestyle Activities of Daily Living Table',
    },
    {
        value:
            'Other central nervous system disorders - Brain abscess having undergone surgical drainage',
        label:
            'Other central nervous system disorders - Brain abscess having undergone surgical drainage',
    },
    {
        value: 'Other central nervous system disorders - Cerebral malaria',
        label: 'Other central nervous system disorders - Cerebral malaria',
    },
    {
        value:
            'Other central nervous system disorders - Cerebral oedema having undergone in-hospital treatment with specific therapeutic interventions',
        label:
            'Other central nervous system disorders - Cerebral oedema having undergone in-hospital treatment with specific therapeutic interventions',
    },
    {
        value:
            'Other central nervous system disorders - Conditions having undergone open brain surgery excluding arterio-venous malformation or intracranial aneurysm',
        label:
            'Other central nervous system disorders - Conditions having undergone open brain surgery excluding arterio-venous malformation or intracranial aneurysm',
    },
    {
        value:
            'Other central nervous system disorders - With mechanical ventilation for more than 60 consecutive days',
        label:
            'Other central nervous system disorders - With mechanical ventilation for more than 60 consecutive days',
    },
    {
        value:
            'Other central nervous system disorders - With neurological deficit for a continuous period of 6 months',
        label:
            'Other central nervous system disorders - With neurological deficit for a continuous period of 6 months',
    },
    {
        value:
            'Other central nervous system disorders - The first occurrence of hydrocephalus having undergone a shunt',
        label:
            'Other central nervous system disorders - The first occurrence of hydrocephalus having undergone a shunt',
    },
    {
        value:
            'Other central nervous system disorders - Intracranial aneurysm or an arterio-venous malformation having undergone endoscopic or stereotactic surgical intervention',
        label:
            'Other central nervous system disorders - Intracranial aneurysm or an arterio-venous malformation having undergone endoscopic or stereotactic surgical intervention',
    },
    {
        value:
            'Other central nervous system disorders - Intracranial aneurysm or an arterio-venous malformation having undergone open brain surgery',
        label:
            'Other central nervous system disorders - Intracranial aneurysm or an arterio-venous malformation having undergone open brain surgery',
    },
    {
        value:
            'Other central nervous system disorders - Permanent and complete inability to communicate or comprehend language symbols',
        label:
            'Other central nervous system disorders - Permanent and complete inability to communicate or comprehend language symbols',
    },
    {
        value:
            'Other central nervous system disorders - Raised intracranial pressure causing symptoms',
        label:
            'Other central nervous system disorders - Raised intracranial pressure causing symptoms',
    },
    {
        value:
            'Other central nervous system disorders - Recurrent benign brain tumour showing symptoms',
        label:
            'Other central nervous system disorders - Recurrent benign brain tumour showing symptoms',
    },
    {
        value: 'Pancreatic disorders - A pancreas transplant',
        label: 'Pancreatic disorders - A pancreas transplant',
    },
    {
        value:
            'Pancreatic disorders - Causing impaired glucose tolerance resulting in treatment with injectable insulin',
        label:
            'Pancreatic disorders - Causing impaired glucose tolerance resulting in treatment with injectable insulin',
    },
    {
        value: 'Pancreatic disorders - Causing malabsorption syndrome',
        label: 'Pancreatic disorders - Causing malabsorption syndrome',
    },
    {
        value: 'Pancreatic disorders - Complete pancreatectomy',
        label: 'Pancreatic disorders - Complete pancreatectomy',
    },
    {
        value: 'Pancreatic disorders - Pancreatic abscess having undergone surgical drainage',
        label: 'Pancreatic disorders - Pancreatic abscess having undergone surgical drainage',
    },
    {
        value: 'Pancreatic disorders - Partial pancreatectomy due to disease',
        label: 'Pancreatic disorders - Partial pancreatectomy due to disease',
    },
    { value: 'Paralysis - Diplegia', label: 'Paralysis - Diplegia' },
    { value: 'Paralysis - Hemiplegia', label: 'Paralysis - Hemiplegia' },
    { value: 'Paralysis - Paraplegia', label: 'Paralysis - Paraplegia' },
    { value: 'Paralysis - Quadriplegia', label: 'Paralysis - Quadriplegia' },
    {
        value: `Parkinson's disease - On diagnosis of Parkinson’s disease`,
        label: `Parkinson's disease - On diagnosis of Parkinson’s disease`,
    },
    {
        value: `Parkinson's disease - Scoring 1 major or 4 moderate categories according to the Living Lifestyle Activities of Daily Living Table`,
        label: `Parkinson's disease - Scoring 1 major or 4 moderate categories according to the Living Lifestyle Activities of Daily Living Table`,
    },
    {
        value: `Parkinson's disease - Scoring 2 moderate, or 1 moderate and 3 mild categories according to the Living Lifestyle Activities of Daily Living Table`,
        label: `Parkinson's disease - Scoring 2 moderate, or 1 moderate and 3 mild categories according to the Living Lifestyle Activities of Daily Living Table`,
    },
    {
        value: `Parkinson's disease - Scoring 3 moderate categories according to the Living Lifestyle Activities of Daily Living Table`,
        label: `Parkinson's disease - Scoring 3 moderate categories according to the Living Lifestyle Activities of Daily Living Table`,
    },
    {
        value:
            'Pericardial disease - Acute or chronic pericarditis as seen on special investigation',
        label:
            'Pericardial disease - Acute or chronic pericarditis as seen on special investigation',
    },
    {
        value: 'Pericardial disease - Keyhole pericardiectomy',
        label: 'Pericardial disease - Keyhole pericardiectomy',
    },
    {
        value: 'Pericardial disease - Open-heart pericardiectomy',
        label: 'Pericardial disease - Open-heart pericardiectomy',
    },
    {
        value: 'Pericardial disease - Pericardiectomy done endoscopically',
        label: 'Pericardial disease - Pericardiectomy done endoscopically',
    },
    {
        value: 'Pericardial disease - But still NYHA class 3 (or METS < 5) and EF < 50%',
        label: 'Pericardial disease - But still NYHA class 3 (or METS < 5) and EF < 50%',
    },
    {
        value: 'Pericardial disease - But still NYHA class 4 (or METS < 3) and EF < 40%',
        label: 'Pericardial disease - But still NYHA class 4 (or METS < 3) and EF < 40%',
    },
    {
        value: 'Pericardial disease - But still NYHA class 4 (or METS < 5) and EF < 50%',
        label: 'Pericardial disease - But still NYHA class 4 (or METS < 5) and EF < 50%',
    },
    {
        value:
            'Peripheral arterial disease - Gangrene or loss of limb due to peripheral arterial disease',
        label:
            'Peripheral arterial disease - Gangrene or loss of limb due to peripheral arterial disease',
    },
    {
        value:
            'Peripheral arterial disease - Occlusion of major artery (50% or more) as evidenced by positive imaging and clinical findings',
        label:
            'Peripheral arterial disease - Occlusion of major artery (50% or more) as evidenced by positive imaging and clinical findings',
    },
    {
        value:
            'Peripheral arterial disease - On diagnosis of a peripheral arterial disease as specified at the beginning of benefit category 2.7 Peripheral arterial disease; and angioplasty with 2 or more stents in a single vessel or in separate vessels',
        label:
            'Peripheral arterial disease - On diagnosis of a peripheral arterial disease as specified at the beginning of benefit category 2.7 Peripheral arterial disease; and angioplasty with 2 or more stents in a single vessel or in separate vessels',
    },
    {
        value:
            'Peripheral arterial disease - On diagnosis of a peripheral arterial disease as specified at the beginning of benefit category 2.7 Peripheral arterial disesse; and angioplasty with or without stenting of a peripheral artery',
        label:
            'Peripheral arterial disease - On diagnosis of a peripheral arterial disease as specified at the beginning of benefit category 2.7 Peripheral arterial disesse; and angioplasty with or without stenting of a peripheral artery',
    },
    {
        value:
            'Peripheral arterial disease - Receiving optimal treatment (whether that is surgical or medical) for peripheral arterial disease, but still experiencing persistent claudication and leg ulcer/s, dystrophic changes or colour changes',
        label:
            'Peripheral arterial disease - Receiving optimal treatment (whether that is surgical or medical) for peripheral arterial disease, but still experiencing persistent claudication and leg ulcer/s, dystrophic changes or colour changes',
    },
    {
        value:
            'Peripheral arterial disease - Surgery to correct the narrowing of, or blockage to, a peripheral artery / arteries by means of a by-pass graft',
        label:
            'Peripheral arterial disease - Surgery to correct the narrowing of, or blockage to, a peripheral artery / arteries by means of a by-pass graft',
    },
    {
        value: 'Renal disorders - Chronic nephrotic syndrome',
        label: 'Renal disorders - Chronic nephrotic syndrome',
    },
    {
        value: 'Renal disorders - Chronic tubulointerstitial disease',
        label: 'Renal disorders - Chronic tubulointerstitial disease',
    },
    {
        value:
            'Renal disorders - Chronic, irreversible kidney failure requiring and already having undergone regular dialysis treatment',
        label:
            'Renal disorders - Chronic, irreversible kidney failure requiring and already having undergone regular dialysis treatment',
    },
    {
        value:
            'Renal disorders - Endoscopic or minimally invasive renal surgery (excluding biopsy or any surgery for renal stones)',
        label:
            'Renal disorders - Endoscopic or minimally invasive renal surgery (excluding biopsy or any surgery for renal stones)',
    },
    {
        value: 'Renal disorders - A kidney transplant',
        label: 'Renal disorders - A kidney transplant',
    },
    {
        value: 'Renal disorders - Nephrotic syndrome with renal artery or renal vein thrombosis',
        label: 'Renal disorders - Nephrotic syndrome with renal artery or renal vein thrombosis',
    },
    {
        value: 'Renal disorders - Open kidney surgery not for diagnostic purposes',
        label: 'Renal disorders - Open kidney surgery not for diagnostic purposes',
    },
    {
        value: 'Renal disorders - Partial or total nephrectomy',
        label: 'Renal disorders - Partial or total nephrectomy',
    },
    {
        value: 'Renal disorders - Primary amyloidosis of the kidney',
        label: 'Renal disorders - Primary amyloidosis of the kidney',
    },
    {
        value:
            'Renal disorders - With the last GFR of 15ml/min or less (using the most accurate GFR test) with persistent proteinuria (1+ or more on Dipstick) and a documented decline in the GFR of greater than 5ml/min within the last 12 months despite optimal treatment by a nephrologist or a specialist physician.',
        label:
            'Renal disorders - With the last GFR of 15ml/min or less (using the most accurate GFR test) with persistent proteinuria (1+ or more on Dipstick) and a documented decline in the GFR of greater than 5ml/min within the last 12 months despite optimal treatment by a nephrologist or a specialist physician.',
    },
    {
        value:
            'Renal disorders - With the last GFR of 30ml/min or less (using the most accurate GFR test) with persistent proteinuria (1+ or more on Dipstick) and a documented decline in the GFR of greater than 5ml/min within the last 12 months despite optimal treatment by a nephrologist or a specialist physician.',
        label:
            'Renal disorders - With the last GFR of 30ml/min or less (using the most accurate GFR test) with persistent proteinuria (1+ or more on Dipstick) and a documented decline in the GFR of greater than 5ml/min within the last 12 months despite optimal treatment by a nephrologist or a specialist physician.',
    },
    {
        value:
            'Renal disorders - With the last GFR of 55ml/min or less (using the most accurate GFR test) with persistent proteinuria (1+ or more on Dipstick) and a documented decline in the GFR of greater than 5ml/min within the last 12 months despite optimal treatment by a nephrologist or a specialist physician.',
        label:
            'Renal disorders - With the last GFR of 55ml/min or less (using the most accurate GFR test) with persistent proteinuria (1+ or more on Dipstick) and a documented decline in the GFR of greater than 5ml/min within the last 12 months despite optimal treatment by a nephrologist or a specialist physician.',
    },
    {
        value: 'Renal disorders - Having received intravenous antibiotics for 48 hours',
        label: 'Renal disorders - Having received intravenous antibiotics for 48 hours',
    },
    {
        value: 'Renal disorders - Having undergone percutaneous drainage',
        label: 'Renal disorders - Having undergone percutaneous drainage',
    },
    {
        value: 'Renal disorders - Renal cortical necrosis',
        label: 'Renal disorders - Renal cortical necrosis',
    },
    {
        value: 'Respiratory disorders - On confirmed diagnosis of bronchietasis',
        label: 'Respiratory disorders - On confirmed diagnosis of bronchietasis',
    },
    {
        value: 'Respiratory disorders - Bronchopleural fistula having undergone surgery',
        label: 'Respiratory disorders - Bronchopleural fistula having undergone surgery',
    },
    {
        value:
            'Respiratory disorders - Chronic irreversible lung diseases including fibrosing alveolitis, pneumoconiosis, bronchiectasis, interstitial lung diseases or chronic obstructive lung diseases resulting in FEV1 of less than 40% in obstructive diseases, FVC of less than 40% in restrictive diseases, or DCO (diffusion of carbon monoxide) of less than 40%.',
        label:
            'Respiratory disorders - Chronic irreversible lung diseases including fibrosing alveolitis, pneumoconiosis, bronchiectasis, interstitial lung diseases or chronic obstructive lung diseases resulting in FEV1 of less than 40% in obstructive diseases, FVC of less than 40% in restrictive diseases, or DCO (diffusion of carbon monoxide) of less than 40%.',
    },
    {
        value:
            'Respiratory disorders - Chronic irreversible lung diseases including fibrosing alveolitis, pneumoconiosis, bronchiectasis, interstitial lung diseases or chronic obstructive lung diseases resulting in FEV1 of less than 50% in obstructive diseases, FVC of less than 50% in restrictive diseases, or DCO (diffusion of carbon monoxide) of less than 50%.',
        label:
            'Respiratory disorders - Chronic irreversible lung diseases including fibrosing alveolitis, pneumoconiosis, bronchiectasis, interstitial lung diseases or chronic obstructive lung diseases resulting in FEV1 of less than 50% in obstructive diseases, FVC of less than 50% in restrictive diseases, or DCO (diffusion of carbon monoxide) of less than 50%.',
    },
    {
        value:
            'Respiratory disorders - Having undergone the following surgical procedure: decortication',
        label:
            'Respiratory disorders - Having undergone the following surgical procedure: decortication',
    },
    {
        value:
            'Respiratory disorders - Having undergone the following surgical procedure: drainage of empyema or lung abscess or pleural effusion requiring drainage',
        label:
            'Respiratory disorders - Having undergone the following surgical procedure: drainage of empyema or lung abscess or pleural effusion requiring drainage',
    },
    {
        value: 'Respiratory disorders - On confirmed diagnosis of fibrosing alveolitis',
        label: 'Respiratory disorders - On confirmed diagnosis of fibrosing alveolitis',
    },
    {
        value: 'Respiratory disorders - A heart-lung transplant',
        label: 'Respiratory disorders - A heart-lung transplant',
    },
    {
        value: 'Respiratory disorders - Insertion of veno-caval filter',
        label: 'Respiratory disorders - Insertion of veno-caval filter',
    },
    {
        value: 'Respiratory disorders - On confirmed diagnosis of interstitial lung diseases',
        label: 'Respiratory disorders - On confirmed diagnosis of interstitial lung diseases',
    },
    {
        value: 'Respiratory disorders - A lung transplant',
        label: 'Respiratory disorders - A lung transplant',
    },
    {
        value: 'Respiratory disorders - Other open lung surgery not for diagnostic purposes',
        label: 'Respiratory disorders - Other open lung surgery not for diagnostic purposes',
    },
    {
        value:
            'Respiratory disorders - Having undergone the following surgical procedure: pleurectomy',
        label:
            'Respiratory disorders - Having undergone the following surgical procedure: pleurectomy',
    },
    {
        value: 'Respiratory disorders - On confirmed diagnosis of pneumoconiosis',
        label: 'Respiratory disorders - On confirmed diagnosis of pneumoconiosis',
    },
    {
        value: 'Respiratory disorders - Pulmonary emboli as seen on special investigations',
        label: 'Respiratory disorders - Pulmonary emboli as seen on special investigations',
    },
    {
        value:
            'Respiratory disorders - Recurrent pulmonary embolism despite optimal treatment with associated pulmonary hypertension (mean pulmonary artery pressure) > 40mmHg',
        label:
            'Respiratory disorders - Recurrent pulmonary embolism despite optimal treatment with associated pulmonary hypertension (mean pulmonary artery pressure) > 40mmHg',
    },
    {
        value: 'Respiratory disorders - Removal of a lung',
        label: 'Respiratory disorders - Removal of a lung',
    },
    {
        value: 'Respiratory disorders - Removal of one lobe of the lungs',
        label: 'Respiratory disorders - Removal of one lobe of the lungs',
    },
    {
        value:
            'Respiratory disorders - Status asthmaticus having undergone intubation and ICU admission',
        label:
            'Respiratory disorders - Status asthmaticus having undergone intubation and ICU admission',
    },
    {
        value:
            'Rheumatoid arthritis - Musculoskeletal involvement having undergone surgery including joint replacement, joint reconstruction or joint fixation',
        label:
            'Rheumatoid arthritis - Musculoskeletal involvement having undergone surgery including joint replacement, joint reconstruction or joint fixation',
    },
    {
        value:
            'Rheumatoid arthritis - Musculoskeletal involvement of the major joints with evidence of ongoing therapy',
        label:
            'Rheumatoid arthritis - Musculoskeletal involvement of the major joints with evidence of ongoing therapy',
    },
    {
        value:
            'Rheumatoid arthritis - Progressive systemic involvement of the heart or lungs or vasculitis despite optimal therapy',
        label:
            'Rheumatoid arthritis - Progressive systemic involvement of the heart or lungs or vasculitis despite optimal therapy',
    },
    {
        value:
            'Rheumatoid arthritis - Scoring 1 major under either the Posture and Motion category and the Movement and Self Care category according to the Living Lifestyle Activities of Daily Living Table',
        label:
            'Rheumatoid arthritis - Scoring 1 major under either the Posture and Motion category and the Movement and Self Care category according to the Living Lifestyle Activities of Daily Living Table',
    },
    {
        value:
            'Rheumatoid arthritis - Scoring 1 moderate under both the Posture and Motion category and the Movement and Self Care category according to the Living Lifestyle Activities of Daily Living Table',
        label:
            'Rheumatoid arthritis - Scoring 1 moderate under both the Posture and Motion category and the Movement and Self Care category according to the Living Lifestyle Activities of Daily Living Table',
    },
    {
        value:
            'Speech disorders - Permanent aphasia (the inability of the brain to understand or express language)',
        label:
            'Speech disorders - Permanent aphasia (the inability of the brain to understand or express language)',
    },
    {
        value:
            'Speech disorders - Permanent dysarthria (the inability to produce sounds so they can form comprehensive words)',
        label:
            'Speech disorders - Permanent dysarthria (the inability to produce sounds so they can form comprehensive words)',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Admittance to a recognised physical or mental rehabilitation institution for more than a continuous period of 30 days',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Admittance to a recognised physical or mental rehabilitation institution for more than a continuous period of 30 days',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Having undergone a decompression laminectomy',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Having undergone a decompression laminectomy',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Having undergone drainage via burrhole',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Having undergone drainage via burrhole',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Emergency tracheostomy or cricothyrotomy',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Emergency tracheostomy or cricothyrotomy',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Head injury having undergone emergency surgery, craniotomy or drainage of cerebrospinal fluid',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Head injury having undergone emergency surgery, craniotomy or drainage of cerebrospinal fluid',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - ICU admission with mechanical ventilation for at least 96 hours',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - ICU admission with mechanical ventilation for at least 96 hours',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Le Fort II and III facial injuries',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Le Fort II and III facial injuries',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Having undergone major surgical reconstruction that was medically necessary for multiple facial fractures involving more than one anatomical area. This must be medically necessary and exclude elective cosmetic surgery',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Having undergone major surgical reconstruction that was medically necessary for multiple facial fractures involving more than one anatomical area. This must be medically necessary and exclude elective cosmetic surgery',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Orbital fracture',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Orbital fracture',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Having undergone spinal fusion',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Having undergone spinal fusion',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Spinal injury resulting in paraplegia, diplegia, hemiplegia, quadriplegia and cauda equina syndrome',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Spinal injury resulting in paraplegia, diplegia, hemiplegia, quadriplegia and cauda equina syndrome',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Scoring 1 major or 4 moderate categories according to the Living Lifestyle Activities of Daily Living Table',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Scoring 1 major or 4 moderate categories according to the Living Lifestyle Activities of Daily Living Table',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Scoring 2 moderate, or 1 moderate and 3 mild categories according to the Living Lifestyle Activities of Daily Living Table',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Scoring 2 moderate, or 1 moderate and 3 mild categories according to the Living Lifestyle Activities of Daily Living Table',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Scoring 3 moderate categories according to the Living Lifestyle Activities of Daily Living Table',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Scoring 3 moderate categories according to the Living Lifestyle Activities of Daily Living Table',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Traumatic injuries meeting the policy document description and the comatose state is persistent for longer than 96 hours',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Traumatic injuries meeting the policy document description and the comatose state is persistent for longer than 96 hours',
    },
    {
        value: 'Urogenital disorders - Partial amputation of the penis',
        label: 'Urogenital disorders - Partial amputation of the penis',
    },
    {
        value: 'Urogenital disorders - Partial cystectomy (removal of at least 50% of the bladder)',
        label: 'Urogenital disorders - Partial cystectomy (removal of at least 50% of the bladder)',
    },
    {
        value:
            'Urogenital disorders - Radical cystectomy resulting in a need for an external bag or catheterisation',
        label:
            'Urogenital disorders - Radical cystectomy resulting in a need for an external bag or catheterisation',
    },
    {
        value: 'Urogenital disorders - Total amputation of the penis',
        label: 'Urogenital disorders - Total amputation of the penis',
    },
    {
        value: 'Urogenital disorders - Unilateral or bilateral orchidectomy',
        label: 'Urogenital disorders - Unilateral or bilateral orchidectomy',
    },
    {
        value: 'Urogenital disorders - Vesico-vaginal fistula having undergone surgery',
        label: 'Urogenital disorders - Vesico-vaginal fistula having undergone surgery',
    },
    {
        value:
            'Valvular and septal diseases of the heart - Endocarditis (as indicated by appropriate tests)',
        label:
            'Valvular and septal diseases of the heart - Endocarditis (as indicated by appropriate tests)',
    },
    {
        value: 'Valvular and septal diseases of the heart - A heart transplant',
        label: 'Valvular and septal diseases of the heart - A heart transplant',
    },
    {
        value:
            'Valvular and septal diseases of the heart - Keyhole valvular surgery via the thoracic wall',
        label:
            'Valvular and septal diseases of the heart - Keyhole valvular surgery via the thoracic wall',
    },
    {
        value:
            'Valvular and septal diseases of the heart - Open heart surgery for valvular conditions',
        label:
            'Valvular and septal diseases of the heart - Open heart surgery for valvular conditions',
    },
    {
        value:
            'Valvular and septal diseases of the heart - But still NYHA class 2 (or METS < 7) and EF < 55%',
        label:
            'Valvular and septal diseases of the heart - But still NYHA class 2 (or METS < 7) and EF < 55%',
    },
    {
        value:
            'Valvular and septal diseases of the heart - But still NYHA class 3 (or METS < 5) and EF < 50%',
        label:
            'Valvular and septal diseases of the heart - But still NYHA class 3 (or METS < 5) and EF < 50%',
    },
    {
        value:
            'Valvular and septal diseases of the heart - But still NYHA class 3 (or METS < 7) and EF < 55%',
        label:
            'Valvular and septal diseases of the heart - But still NYHA class 3 (or METS < 7) and EF < 55%',
    },
    {
        value:
            'Valvular and septal diseases of the heart - But still NYHA class 4 (or METS < 3) and EF < 40%',
        label:
            'Valvular and septal diseases of the heart - But still NYHA class 4 (or METS < 3) and EF < 40%',
    },
    {
        value:
            'Valvular and septal diseases of the heart - But still NYHA class 4 (or METS < 7) and EF < 55%',
        label:
            'Valvular and septal diseases of the heart - But still NYHA class 4 (or METS < 7) and EF < 55%',
    },
    {
        value:
            'Valvular and septal diseases of the heart - Surgical valvular procedure done endoscopically (including catheter-based procedures)',
        label:
            'Valvular and septal diseases of the heart - Surgical valvular procedure done endoscopically (including catheter-based procedures)',
    },
    {
        value:
            'Valvular and septal diseases of the heart - Symptomatic atrial or ventricular septal defect having undergone surgical closure',
        label:
            'Valvular and septal diseases of the heart - Symptomatic atrial or ventricular septal defect having undergone surgical closure',
    },
    { value: 'Inability to work', label: 'Inability to work' },
    { value: 'Other', label: 'Other' },
];
