import { withStyles, Grid } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { FormDetails, getFormDetails } from '../../form-details-reducer';
import { connect } from 'react-redux';
import { State } from '../../../../../../redux/root-reducer';
import { FormHeading, GenericForm } from '../../../../../../forms';
import { UpdateFormDetails, updateFormDetailsAction } from '../../actions/update-form-details';
import { BankingDetailsPayment } from '../../../../common/banking-details/views/edit-banking-details-payment';

interface BaseProps {
    formDetails: FormDetails;
    updateFormDetails: UpdateFormDetails;
}

interface Props extends BaseProps, StyledComponent {}

const PaymentsForm = GenericForm<FormDetails>();

const paymentDetailsEdit: React.FunctionComponent<Props> = ({
    classes,
    formDetails,
    updateFormDetails,
    ...rest
}: Props) => {
    return (
        <PaymentsForm initialValues={formDetails} onSubmit={updateFormDetails} {...rest}>
            <FormHeading text="Update Form Details | Payment Details" />
            <Grid container spacing={5} justify="flex-start">
                <BankingDetailsPayment formDetails={formDetails} prefix={'payment.information.'} />
            </Grid>
        </PaymentsForm>
    );
};

const mapStateToProps = (state: State) => ({
    formDetails: getFormDetails(state),
});

const mapDispatchToProps = {
    updateFormDetails: updateFormDetailsAction,
};

export const PaymentDetailsEdit = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(paymentDetailsEdit));
