import { getPropertyFromClaim, Claim, getClaim } from '../../../../claim';
import { State } from '../../../../redux/root-reducer';
import { AnyAction } from 'redux';

export interface LiquidAndDistribution {
    readonly dependent: string;
    readonly description: string;
    readonly amountPayable: number;
    readonly lastDateUpdated: Date;
}
export interface DistributionDetails {
    dependent: string;
    description: string;
    amount: number;
}
export interface DistributionDictionary {
    id: string;
    totalAmount: number;
    details: DistributionDetails[];
    lastDateUpdated: Date;
}
export const defaultRALiquidAndDistribution: LiquidAndDistribution = {
    dependent: '',
    description: '',
    amountPayable: 0,
    lastDateUpdated: new Date(),
};
export const defaultRALiquidAndDistributionReducer: LiquidAndDistribution[] = [];

export function liquidAndDistributionReducer(
    state: LiquidAndDistribution[] = [],
    action: AnyAction
) {
    switch (action.type) {
        default:
            return state;
    }
}

export function getRALiquidAndDistributionDetails(state: State) {
    return getRALiquidDetailsFromClaim(getClaim(state)) || [];
}
export function getRALiquidDetailsFromClaim(claim: Claim): LiquidAndDistribution {
    return getPropertyFromClaim(claim, 'liquidAndDistribution', defaultRALiquidAndDistribution);
}
export function getRALiquidDetailsFromClaimReducer(claim: Claim): LiquidAndDistribution[] {
    return getPropertyFromClaim(
        claim,
        'liquidAndDistribution',
        defaultRALiquidAndDistributionReducer
    );
}
