import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { DeathCertifiers } from '../../../../../../codes/death-certifiers';
import {
    Condition,
    FullWidthSearchSelectField,
    FullWidthTextField,
    True,
    WhenFieldChanges,
} from '../../../../../../forms';
import {
    FirstNameMask,
    LastNameMask,
    NumberMask,
    TelephoneNumberMask,
} from '../../../../../../forms/input-masks';
import { AddressDetailsSubForm } from '../../../../../../forms/sub-forms/address-details';
import { requiredValidator, rsaIdNumberValidator } from '../../../../../../forms/validations';
import styles from '../../../../../../styles/styles';

const declaration = ({ classes }: any) => {
    const prefix = 'declaration.';
    return (
        <Grid container spacing={5}>
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    name={`${prefix}certifiedBy`}
                    options={DeathCertifiers}
                    validate={requiredValidator}
                />
                <WhenFieldChanges
                    field={`${prefix}certifiedBy`}
                    set={`${prefix}hospitalName`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}certifiedBy`}
                    set={`${prefix}hospitalContactNo`}
                    to={null}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${prefix}firstName`}
                    validate={requiredValidator}
                    mask={FirstNameMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField name={`${prefix}secondName`} mask={FirstNameMask} />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${prefix}lastName`}
                    validate={requiredValidator}
                    mask={LastNameMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${prefix}idNumber`}
                    validate={rsaIdNumberValidator}
                    mask={NumberMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${prefix}telephoneNumber`}
                    validate={requiredValidator}
                    mask={TelephoneNumberMask}
                />
            </Grid>
            <Condition when={`${prefix}certifiedBy`} is="Doctor">
                <True>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" className={classes.marginTop}>
                            Hospital Details
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthTextField name={`${prefix}hospitalName`} mask={LastNameMask} />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            name={`${prefix}hospitalContactNo`}
                            label="Hospital Contact Number"
                            mask={TelephoneNumberMask}
                        />
                    </Grid>
                </True>
            </Condition>
            <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.marginTop}>
                    Address Details
                </Typography>
            </Grid>
            <AddressDetailsSubForm
                addressPropertyName={`${prefix}addressDetails`}
                addressType="street"
            />
        </Grid>
    );
};

export const DeclarationEdit = withStyles(styles)(declaration);
