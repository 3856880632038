import {
    DeathClaimContactDetails,
    deathClaimContactDetailsReducer,
} from '../../../assessment/synopsis/death/contact-details/contact-details-reducer';
import {
    ForensicInput,
    forensicInputReducer,
} from '../../../assessment/synopsis/common/forensic-input/reducer';
import {
    MedicalOfficerInput,
    medicalOfficerInputReducer,
} from '../../../medical-officer-input/reducer';
import { combineReducersWithDefault } from '../../../redux/combine-reducers-with-default';
import {
    ReinsuranceInputDetails,
    reinsuranceInputDetailsReducer,
} from '../../../reinsurance-input/reducer';
import {
    RetrospectiveUnderwritingDecision,
    retrospectiveUnderwritingDecisionReducer,
} from '../../../retrospective-underwriting-decision/reducer';
import { Nullable } from '../../../shared-types';
import { BaseClaim, baseClaimReducers, initialBaseClaim } from './base-claim-reducer';
import {
    underwritingReducer,
    Underwriting,
} from '../../../assessment/synopsis/common/underwriting/underwriting-reducer';
import {
    deathClaimLifeAssuredDetailsReducer,
    DeathClaimLifeAssured,
} from '../../../assessment/synopsis/death/life-assured-details/reducer';
import { getPropertyFromClaim, getClaim, Claim } from '..';
import { State } from '../../../redux/root-reducer';
import {
    DeathClaimEventDetails,
    deathClaimEventDetailsReducer,
} from '../../../assessment/synopsis/death/claim-event-details/reducer';
import { simplePropertyReducer } from '../../../redux/simple-property-reducer';
import {
    InformationRecordSummary,
    informationRecordSummaryReducer,
} from '../../../assessment/synopsis/common/information-record/reducer';
import { assessmentDetailsReducer } from '../../../assessment/decisions/assessment-details-reducer';
import {
    deathAssessmentDetailsReducer,
    AssessmentDetails,
} from '../../../assessment/decisions/death/death-assessment-details-reducer';
import {
    documentsStatusReducer,
    DeathRequirements,
} from '../../../assessment/synopsis/death/manage-requirements/reducer';
import {
    PoliceReport,
    policeReportReducer,
} from '../../../assessment/synopsis/common/police-report/police-report-reducer';
import {
    NoticeOfDeath,
    noticeOfDeathReducer,
} from '../../../assessment/synopsis/common/notice-of-death/notice-of-death-reducer';

export interface DeathClaim extends BaseClaim {
    readonly hasLifeCoverUnder3Years?: Nullable<boolean>;
    readonly contactDetails?: Nullable<DeathClaimContactDetails>;
    readonly underwriting?: Nullable<Underwriting>;
    readonly informationRecordSummary?: Nullable<InformationRecordSummary>;
    readonly medicalOfficerInput?: Nullable<MedicalOfficerInput>;
    readonly retrospectiveUnderwritingDecision?: Nullable<RetrospectiveUnderwritingDecision>;
    readonly forensicInput?: Nullable<ForensicInput>;
    readonly reinsuranceInputDetails?: Nullable<ReinsuranceInputDetails>;
    readonly lifeAssuredDetails?: Nullable<DeathClaimLifeAssured>;
    readonly claimEventDetails: DeathClaimEventDetails;
    readonly deathAssessmentDetails: AssessmentDetails;
    readonly documentsStatus?: Nullable<DeathRequirements>;
    readonly noticeOfDeath?: Nullable<NoticeOfDeath>;
    readonly policeReport?: Nullable<PoliceReport>;
}

export const initialDeathClaim: DeathClaim = {
    ...initialBaseClaim,
    claimEventDetails: { claimNotes: [], vopd: {} },
    deathAssessmentDetails: {
        riskPolicyAssessmentDetails: [],
        investmentPolicyAssessments: [],
    },
};

export const defaultDeathClaimDetails: DeathClaimEventDetails = { claimNotes: [], vopd: {} };

const deathClaimReducers = {
    ...baseClaimReducers,
    hasLifeCoverUnder3Years: simplePropertyReducer(null),
    contactDetails: deathClaimContactDetailsReducer,
    underwriting: underwritingReducer,
    informationRecordSummary: informationRecordSummaryReducer,
    medicalOfficerInput: medicalOfficerInputReducer,
    retrospectiveUnderwritingDecision: retrospectiveUnderwritingDecisionReducer,
    forensicInput: forensicInputReducer,
    reinsuranceInputDetails: reinsuranceInputDetailsReducer,
    lifeAssuredDetails: deathClaimLifeAssuredDetailsReducer,
    claimEventDetails: deathClaimEventDetailsReducer,
    assessmentDetails: assessmentDetailsReducer,
    deathAssessmentDetails: deathAssessmentDetailsReducer,
    documentsStatus: documentsStatusReducer,
    noticeOfDeath: noticeOfDeathReducer,
    policeReport: policeReportReducer,
};

export const deathClaimReducer = combineReducersWithDefault<DeathClaim>(
    initialDeathClaim,
    deathClaimReducers
);

export function getDeathClaimEventDetails(state: State) {
    return getDeathClaimDetails(getClaim(state)) || {};
}

export function getDeathClaimDetails(claim: Claim) {
    return getPropertyFromClaim(claim, 'claimEventDetails', defaultDeathClaimDetails);
}

export function getDeathClaim(state: State) {
    return getClaim(state) as DeathClaim;
}
