import 'babel-polyfill';
import 'react-app-polyfill/ie11';

import React from 'react'; // eslint-disable-line
import * as ReactDOM from 'react-dom';

import App from './app';

import { configureAxios } from './axios';
import * as serviceWorker from './serviceWorker';
//import { configureAppInsights } from './app-insights/app-insights';

function registerServiceWorker() {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: http://bit.ly/CRA-PWA
    serviceWorker.unregister();
}

function render() {
    const root = document.getElementById('root') as HTMLElement;
    if (root == null) {
        throw new Error('no root element');
    }
    ReactDOM.render(<App />, root);
    registerServiceWorker();
}

async function start() {
    //configureAppInsights();
    configureAxios();
    render();
}

start();
