import axios from 'axios';
import { env } from '../../env';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { CodeList } from '../lookups-reducer';
import { LOAD_POSTAL_CODES_BOX, LOAD_POSTAL_CODES_STREET } from './types';

const postalCodesUri = `${env().CLAIMS_API}/api/Utilities/PostalCodes?addressType`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LoadReturn = Promise<any>;
export type LoadPostalCodes = () => LoadReturn;
type LoadPostalCodesThunk = () => ReduxThunkAction;
export const loadPostalCodesAction: LoadPostalCodesThunk = () => {
    return async (dispatch, getState) => {
        const lookups = getState().claimSession.lookups;

        //load box postal codes
        if (lookups.postalCodesBox.length === 0) {
            axios.get<CodeList<string>>(`${postalCodesUri}=box`).then(response => {
                dispatch({ type: LOAD_POSTAL_CODES_BOX, postalCodesBox: response.data });
            });
        }

        //load street postal codes
        if (lookups.postalCodesStreet.length === 0) {
            axios.get<CodeList<string>>(`${postalCodesUri}=street`).then(response => {
                dispatch({ type: LOAD_POSTAL_CODES_STREET, postalCodesStreet: response.data });
            });
        }

        return;
    };
};

export * from './types';
