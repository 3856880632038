import React from 'react'; // eslint-disable-line

import { Button, Grid, Tooltip, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { connect } from 'react-redux';
import { DisabilityDetailsForm } from '../../../../disability-details';
import { DisabilityDetails, getDisabilityDetails } from '../../../../disability-details/reducer';
import { OpenModal, openModalAction } from '../../../../form-modal/actions';
import { FormModalView } from '../../../../form-modal/views/form-modal';
import { ExpansionPanel } from '../../../../forms/form-components/expansion-panel/expansion-panel';
import { State } from '../../../../redux/root-reducer';
import { getDisplayDate, getObjectWarning } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import { LabeledValue } from '../../../../controls/labeled-value';
import { Claim, getClaim } from '../../../../claim';
import { ButtonWrapper } from '../../../../forms';

interface Props extends StyledComponent {
    readonly disabilityDetails: DisabilityDetails;
    readonly openModal: OpenModal;
    readonly claim: Claim;
    readonly isReadOnly: boolean;
}

const disabilityDetailsView: React.FunctionComponent<Props> = ({
    classes,
    disabilityDetails,
    openModal,
    claim,
}: Props) => {
    const edit = () => {
        openModal({
            modalContent: <FormModalView formView={DisabilityDetailsForm} />,
        });
    };
    const notifier = getObjectWarning(disabilityDetails, null);
    return (
        <ExpansionPanel name="DisabilityDetails" notifier={notifier}>
            <Grid container spacing={2} justify="flex-start">
                <ButtonWrapper>
                    <Grid item xs={12} className={classes.rightAlign}>
                        <Tooltip title="Edit Disability Details">
                            <Button
                                id="EditDisabilityDetails"
                                color="secondary"
                                onClick={() => edit()}
                            >
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container justify="flex-start">
                            <Grid item xs={3}>
                                <LabeledValue
                                    label="Last Day at Work"
                                    value={getDisplayDate(disabilityDetails.lastDayAtWork)}
                                />
                            </Grid>
                            {disabilityDetails.returnToWorkStatus === 'No' && (
                                <Grid item xs={3}>
                                    <LabeledValue label="Reason" value={disabilityDetails.reason} />
                                </Grid>
                            )}
                            {disabilityDetails.returnToWorkStatus === 'Yes' && (
                                <Grid item xs={3}>
                                    <LabeledValue
                                        label="Date Returned to Work"
                                        value={getDisplayDate(disabilityDetails.returnToWork)}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={3}>
                                <LabeledValue
                                    label="Income Tax Number"
                                    value={disabilityDetails.incomeTaxNumber}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <LabeledValue
                                    label="Proof of Income at Underwriting"
                                    value={disabilityDetails.proofOfIncomeAtUnderwriting}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <LabeledValue
                                label="Job Description"
                                value={disabilityDetails.jobDescription}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ExpansionPanel>
    );
};

const mapStateToProps = (state: State) => ({
    disabilityDetails: getDisabilityDetails(state),
    claim: getClaim(state),
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = {
    openModal: openModalAction,
};

export const DisabilityDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(disabilityDetailsView));
