import { ClaimType } from '../claim/claim-types';
import { Nullable } from '../shared-types';

export function getClaimType(forms: Nullable<string[]>) {
    if (forms && forms.length > 0) {
        const firstForm = JSON.parse(forms[0]);
        switch (firstForm.claimType) {
            case 'Death Claims - Non-Retirement Products':
                return ClaimType.Death;
            case 'RA Death Claims - Retirement Products':
                return ClaimType.RADeath;
            case 'Funeral Claims':
                return ClaimType.Funeral;
            default:
                return undefined;
        }
    }
    return undefined;
}
