import React, { useState } from 'react'; // eslint-disable-line
import { Button, Typography, Grid, Tooltip, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { connect } from 'react-redux';
import { getRADeathClaim } from '../../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { FormModalView } from '../../../../../form-modal/views/form-modal';
import { OpenModal, openModalAction } from '../../../../../form-modal/actions';
import claimopener from '../../../../../master-layout/views/claimopener.svg';
import { State } from '../../../../../redux/root-reducer';
import { getFullName, getDisplayDate } from '../../../../../shared-functions';
import { Nullable } from '../../../../../shared-types';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { RADeathLifeAssured } from '../../life-assured/reducer';
import {
    LiquidAndDistribution,
    getRALiquidAndDistributionDetails,
} from '../liquid-and-distribution-reducer';
import { RALiquidAndDistributionAddEditModal } from './add-edit-liquidation-details';
import { DistributionNormalView } from './distribution-normal-view';
import { DistributionTableView } from './distribution-table-view';
import { ButtonWrapper } from '../../../../../forms';

type Props = StyledComponent & {
    isReadOnly: boolean;
    lifeAssuredDetails: Nullable<RADeathLifeAssured>;
    liquidAndDistribution: LiquidAndDistribution;
    openModal: OpenModal;
};

export const LiquidationAndDistributionDetailsView: React.FunctionComponent<Props> = ({
    classes,
    isReadOnly,
    lifeAssuredDetails,
    liquidAndDistribution,
    openModal,
}: Props) => {
    const personalDetails =
        lifeAssuredDetails && lifeAssuredDetails.personalDetails
            ? lifeAssuredDetails.personalDetails
            : undefined;
    const liquidAndDistributionDtails: any[] = [];
    liquidAndDistributionDtails.push(liquidAndDistribution);
    const edit = () => {
        openModal({
            modalContent: <FormModalView formView={RALiquidAndDistributionAddEditModal} />,
        });
    };
    const [isExpanded, setIsExpanded] = useState(true);
    const toggleState = (evt: any) => {
        setIsExpanded((value: boolean) => !value);
        evt.preventDefault();
        return false;
    };
    const expanderElement = (
        <span onClick={toggleState}>{isExpanded ? 'Table View' : 'Normal View'}</span>
    );
    let lastUpdatedDate = null;
    if (Array.isArray(liquidAndDistribution) && liquidAndDistribution.length > 0)
        lastUpdatedDate = liquidAndDistribution.find(x => x).lastDateUpdated;
    const expendedTooltip = !isExpanded ? 'Normal View' : 'Table View';
    return (
        <React.Fragment>
            <Grid container spacing={0} justify="flex-end" className={classes.marginTop}>
                <Grid container spacing={0} item xs={10} lg={11}>
                    <Grid item xs={6} lg={6} style={{ margin: '14px' }}>
                        <Typography variant="h6">{`Liquidation & Distribution | ${getFullName(
                            personalDetails
                        )}`}</Typography>
                        <Typography>{getDisplayDate(lastUpdatedDate)}</Typography>
                        <ButtonWrapper>
                            <Typography>
                                <a href="/#" className={classes.resultsActionLink}>
                                    <img
                                        src={claimopener}
                                        alt="Table View"
                                        className={classes.resultsActionImage}
                                        onClick={toggleState}
                                    />
                                    <Tooltip title={expendedTooltip}>{expanderElement}</Tooltip>
                                </a>
                            </Typography>
                        </ButtonWrapper>
                    </Grid>
                </Grid>
                <ButtonWrapper>
                    <Grid item xs={2} lg={1} style={{ textAlign: 'right' }}>
                        <Tooltip title="Add L&D Details">
                            <Button
                                id="EditLiquidDetails"
                                color="secondary"
                                className={classes.button}
                                onClick={() => edit()}
                            >
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
                {isExpanded && (
                    <DistributionNormalView liquidAndDistribution={liquidAndDistribution} />
                )}
                {!isExpanded && (
                    <DistributionTableView liquidAndDistribution={liquidAndDistribution} />
                )}
            </Grid>
        </React.Fragment>
    );
};

const mapStateToProps = (state: State) => ({
    lifeAssuredDetails: getRADeathClaim(state).lifeAssuredDetails,
    liquidAndDistribution: getRALiquidAndDistributionDetails(state),
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = { openModal: openModalAction };

export const LiquidationAndDistributionDetails = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(LiquidationAndDistributionDetailsView));
