import { UpdateRecordCollectionAction } from '../../redux/collection-reducer';
import { InformationReceivedRecord } from '../reducer';
import { UPDATE_INFORMATION_RECEIVED_RECORD } from '../types';

export type UpdateInformationReceivedRecordAction = UpdateRecordCollectionAction<InformationReceivedRecord>;

export const updateInformationReceivedRecordActionCreator = (
    informationReceivedRecordIndex: number,
    informationReceivedRecord: InformationReceivedRecord
): UpdateInformationReceivedRecordAction => {
    return {
        type: UPDATE_INFORMATION_RECEIVED_RECORD,
        record: informationReceivedRecord,
        index: informationReceivedRecordIndex,
    };
};
