import { createFeatureActionNamer } from '../redux/action-name';

const getActionName = createFeatureActionNamer('information-received-summary');

export const ADD_INFORMATION_RECEIVED_RECORD = getActionName('ADD_INFORMATION_RECEIVED_RECORD');
export const UPDATE_INFORMATION_RECEIVED_RECORD = getActionName(
    'UPDATE_INFORMATION_RECEIVED_RECORD'
);
export const DELETE_INFORMATION_RECEIVED_RECORD = getActionName(
    'DELETE_INFORMATION_RECEIVED_RECORD'
);
export const SUBMIT = getActionName('SUBMIT');
