import React from 'react'; // eslint-disable-line
import { ExpansionPanel } from '../../../../../forms/form-components/expansion-panel/expansion-panel';
import { AddContactDetailsView } from './add-contact-details-view';
import { ExecutorDetailsView } from './executor-details';
import { FinancialAdviserDetailsView } from './financial-adviser-details';
import { OtherContactDetailsView } from './other-contact-details';

export const ContactDetailsView = () => {
    return (
        <ExpansionPanel name="Contact Details">
            <ExecutorDetailsView />
            <FinancialAdviserDetailsView />
            <OtherContactDetailsView />
            <AddContactDetailsView />
        </ExpansionPanel>
    );
};
