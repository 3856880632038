import React from 'react'; // eslint-disable-line

import { Grid, Typography, withStyles, Checkbox } from '@material-ui/core';
import { Reassurance } from '../../../../life-assured-details/reducer';
import { formatCurrency } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import { LabeledValue } from '../../../../controls/labeled-value';

type Props = StyledComponent & {
    reassuranceDetails: Reassurance[];
};
const reassuranceDetailsView: React.FunctionComponent<Props> = ({
    classes,
    reassuranceDetails,
}: Props) => {
    if (reassuranceDetails.length === 0) {
        return null;
    }
    return (
        <React.Fragment>
            <Grid item xs={12} container justify="flex-start">
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.marginTop}>
                        Reinsurance Details
                    </Typography>
                </Grid>
                <Grid item xs={3} lg={3}>
                    <Typography variant="subtitle2" className={classes.marginTop}>
                        Reinsurer
                    </Typography>
                </Grid>
                <Grid item xs={3} lg={3}>
                    <Typography variant="subtitle2" className={classes.marginTop}>
                        Sum Insured
                    </Typography>
                </Grid>
                <Grid item xs={3} lg={3}>
                    <Typography variant="subtitle2" className={classes.marginTop}>
                        Reinsurance Type
                    </Typography>
                </Grid>
                <Grid item xs={3} lg={3}>
                    <Typography variant="subtitle2" className={classes.marginTop}>
                        Refer to Reinsurer
                    </Typography>
                </Grid>
                {reassuranceDetails.map((reassurance, raIndex) => {
                    return (
                        <React.Fragment key={`reassurance${raIndex}`}>
                            <Grid item xs={3} lg={3}>
                                <Typography>{reassurance.reinsurerName}</Typography>
                            </Grid>
                            <Grid item xs={3} lg={3}>
                                <Typography>{formatCurrency(reassurance.sumReinsured)}</Typography>
                            </Grid>
                            <Grid item xs={3} lg={3}>
                                <Typography>{reassurance.reassuranceType}</Typography>
                            </Grid>
                            <Grid item xs={3} lg={3}>
                                <Checkbox
                                    checked={Boolean(reassurance.referToReinsurer)}
                                    disabled
                                    style={{ margin: 0, padding: 0 }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <LabeledValue
                                    value={reassurance.termsAndConditions}
                                    label={'Terms & conditions'}
                                    isUnsafe={true}
                                />
                            </Grid>
                        </React.Fragment>
                    );
                })}
            </Grid>
        </React.Fragment>
    );
};

export const ReassuranceDetailsView = withStyles(styles)(reassuranceDetailsView);
