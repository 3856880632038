import { createFeatureActionNamer } from '../../../../../redux/action-name';

const getActionName = createFeatureActionNamer('doctor-details');

export const ADD_DOCTOR_RECORD = getActionName('ADD_DOCTOR_RECORD');
export const UPDATE_DOCTOR_RECORD = getActionName('UPDATE_DOCTOR_RECORD');
export const DELETE_DOCTOR_RECORD = getActionName('DELETE_DOCTOR_RECORD');
export const ADD_CONSULTATION_RECORD = getActionName('ADD_CONSULTATION_RECORD');
export const UPDATE_CONSULTATION_RECORD = getActionName('UPDATE_CONSULTATION_RECORD');
export const DELETE_CONSULTATION_RECORD = getActionName('UPDATE_CONSULTATION_RECORD');
