import { withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { ExpansionPanel } from '../../../../../forms/form-components/expansion-panel/expansion-panel';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { ClaimantsView } from './claimants/claimants-view';
import { DeceasedDetailsView } from './deceased-details/deceased-details-view';
import { DeceasedOccupationView } from './deceased-details/deceased-occupation-view';
import { DeclarationView } from './declaration/declaration-view';
import { FuneralParlourView } from './funeral-parlour/funeral-parlour-view';
import { PolicyOwnerView } from './policy-owner/policy-owner-view';

interface Props extends StyledComponent {}

const formDetailsView: React.FunctionComponent<Props> = () => {
    return (
        <ExpansionPanel name="Form Details">
            <DeceasedDetailsView />
            <DeceasedOccupationView />
            <PolicyOwnerView />
            <FuneralParlourView />
            <DeclarationView />
            <ClaimantsView />
        </ExpansionPanel>
    );
};

export const FormDetailsView = withStyles(styles)(formDetailsView);
