import { HighRiskOverride, Payment } from '../reducer';
import { Claim } from '../../claim/reducers';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { claimSubmit } from '../../claim';

type AddHighRiskOverrideReturn = Promise<any>;
export type AddHighRiskOverride = (
    highRiskOverride: HighRiskOverride,
    paymentIndex: number
) => AddHighRiskOverrideReturn;
type AddHighRiskOverrideAction = (
    highRiskOverride: HighRiskOverride,
    paymentIndex: number
) => ReduxThunkAction<AddHighRiskOverrideReturn>;
export const addHighRiskOverrideAction: AddHighRiskOverrideAction = (
    highRiskOverride: HighRiskOverride,
    paymentIndex: number
) => async (dispatch, getState) => {
    return await claimSubmit(dispatch, getState, claim =>
        getUpdatedClaim(claim, paymentIndex, highRiskOverride)
    );
};

function getUpdatedClaim(
    claim: Claim,
    paymentIndex: number,
    highRiskOverride: HighRiskOverride
): Claim {
    return {
        ...claim,
        payments: getUpdatedPayments(claim.payments, paymentIndex, highRiskOverride),
    };
}

function getUpdatedPayments(
    payments: Payment[],
    paymentIndex: number,
    highRiskOverride: HighRiskOverride
): Payment[] {
    const riskRating = payments[paymentIndex].riskRating;
    payments[paymentIndex].riskRating = {
        ...riskRating,
        highRiskOverride: {
            ...highRiskOverride,
        },
        override: true,
    };
    return payments;
}
