export const ReasonForRequest = [
    { value: 'Outstanding', label: 'Outstanding' },
    { value: 'Incorrect document', label: 'Incorrect document' },
    { value: 'Illegible document', label: 'Illegible document' },
    { value: 'Incomplete document', label: 'Incomplete document' },
    { value: 'Additional document required', label: 'Additional document required' },
    { value: 'No longer required', label: 'No longer required' },
];

export const RequirementReasons = [
    { value: 'Outstanding', label: 'Outstanding' },
    { value: 'IncorrectDocument', label: 'Incorrect document' },
    { value: 'IllegibleDocument', label: 'Illegible document' },
    { value: 'IncompleteDocument', label: 'Incomplete document' },
    { value: 'AdditionalDocumentRequired', label: 'Additional document required' },
    { value: 'NoLongerRequired', label: 'No longer required' },
];
