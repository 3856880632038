import React from 'react'; // eslint-disable-line

import { Divider, Grid, Typography, withStyles } from '@material-ui/core';
import { DocumentName } from '../../codes/document-name';
import {
    Condition,
    FullWidthCheckBoxField,
    FullWidthDatePicker,
    FullWidthSearchSelectField,
    FullWidthTextField,
    True,
} from '../../forms';
import { conditionalRequired, requiredValidator } from '../../forms/validations';
import styles, { StyledComponent } from '../../styles/styles';

type Props = StyledComponent & {
    heading: React.ReactNode;
};

const InformationReceivedRecordDetailView: React.FunctionComponent<Props> = ({
    classes,
    heading,
}: Props) => {
    return (
        <React.Fragment>
            <Typography variant="h5" className={classes.spacedTypography}>
                {heading}
            </Typography>
            <Divider />
            <Grid container spacing={5}>
                <Grid item xs={6} lg={6}>
                    <FullWidthTextField
                        id="documentID"
                        name="documentID"
                        validate={requiredValidator}
                        // eslint-disable-next-line
                        autoFocus
                    />
                </Grid>
                <Grid item xs={6} lg={6}>
                    <FullWidthSearchSelectField
                        id="documentName"
                        name="documentName"
                        validate={requiredValidator}
                        options={DocumentName}
                    />
                </Grid>
                <Condition when="documentName" is="Other">
                    <True>
                        <Grid item xs={12} lg={12}>
                            <FullWidthTextField
                                name="documentDescription"
                                id="documentDescription"
                                validate={conditionalRequired(
                                    values => values.documentName === 'Other'
                                )}
                            />
                        </Grid>
                    </True>
                </Condition>
                <Grid item xs={6} lg={6}>
                    <FullWidthDatePicker
                        id="dateOfReport"
                        name="dateOfReport"
                        validate={requiredValidator}
                    />
                </Grid>
                <Grid item xs={6} lg={6}>
                    <FullWidthTextField id="assessor" name="assessor" disabled />
                </Grid>
                <Grid item xs={6} lg={6}>
                    <FullWidthDatePicker
                        id="dateOfSummary"
                        name="dateOfSummary"
                        validate={requiredValidator}
                        disabled
                    />
                </Grid>
                <Grid item xs={6} lg={6}>
                    <FullWidthTextField
                        id="summary"
                        name="summary"
                        multiline
                        validate={requiredValidator}
                    />
                </Grid>
                <Grid item xs={6} lg={6}>
                    <FullWidthCheckBoxField name="sendToUnderwriter" />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default withStyles(styles)(InformationReceivedRecordDetailView);
