import { AnyAction, Reducer } from 'redux';
import { updateRecord } from '../../collection-reducer/reductions/update-record';
import { ReduceCollectionRecordAction } from '../collection-record-action';

export function reduceRecord<T>(
    recordReducer: Reducer<T, AnyAction>,
    array: T[],
    action: ReduceCollectionRecordAction<AnyAction>
): T[] {
    if (!(action.index >= 0) || !action.payload || !action.payload.type) {
        return array;
    }

    let record = array[action.index];
    if (!record) {
        return array;
    }

    record = recordReducer(record, action.payload);

    return updateRecord(array, record, action.index);
}
