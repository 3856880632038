import React from 'react'; // eslint-disable-line
import { GenericForm, FormErrors } from '../../../../../forms';
import { withStyles } from '@material-ui/styles';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { OnSubmitSuccessProps } from '../../../../../workflow-navigation';
import { getIndex, Dispute } from '../reducer';
import { connect } from 'react-redux';
import { State } from '../../../../../redux/root-reducer';
import { getRADeathClaim } from '../../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import {
    EditAndSubmitDisputeRecord,
    editAndSubmitDisputeRecordThunk,
} from '../actions/edit-dispute';
import { DisputeEditView } from './edit-dispute-view';

interface Props extends OnSubmitSuccessProps, StyledComponent {
    updateDispute: EditAndSubmitDisputeRecord;
    disputeIndex: number;
    disputes: Dispute[];
}
const DisputeEditForm = GenericForm<Dispute>();
const disputeEditModal: React.FunctionComponent<Props> = ({
    updateDispute,
    disputeIndex,
    disputes,
    ...rest
}: Props) => {
    const onSubmit = (dispute: Dispute) => {
        return updateDispute(dispute, disputeIndex);
    };
    return (
        <DisputeEditForm onSubmit={onSubmit} initialValues={disputes[disputeIndex] || {}} {...rest}>
            <DisputeEditView />
            <FormErrors />
        </DisputeEditForm>
    );
};
const mapStateToProps = (state: State) => ({
    disputes: getRADeathClaim(state).disputes || {},
    disputeIndex: getIndex(state),
});

const mapDispatchToProps = {
    updateDispute: editAndSubmitDisputeRecordThunk,
};

export const DisputeEditModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(disputeEditModal));
