import { Claim, claimSubmit } from '../../../../claim';
import { ClaimType } from '../../../../claim/claim-types';
import {
    EarlyRetirementClaim,
    ERBPolicyAssessment,
} from '../../../../claim/reducers/claim-reducers/early-retirement-claim-reducer';
import { updateRecordReduction } from '../../../../redux/reductions';
import { ReduxThunkAction } from '../../../../redux/redux-actions';

type UpdateERBAssessmentReturn = Promise<any>;
export type UpdateERBAssessment = (
    benefitAssessment: ERBPolicyAssessment,
    policyIndex: number
) => UpdateERBAssessmentReturn;
type UpdateERBAssessmentAction = (
    erbAssessment: ERBPolicyAssessment,
    policyIndex: number
) => ReduxThunkAction<UpdateERBAssessmentReturn>;
export const updateERBAssessmentAction: UpdateERBAssessmentAction = (
    erbAssessment: ERBPolicyAssessment,
    policyIndex: number
) => async (dispatch, getState) => {
    return await claimSubmit(dispatch, getState, claim =>
        getUpdatedClaim(claim, erbAssessment, policyIndex)
    );
};

function getUpdatedClaim(
    claim: Claim,
    benefitAssessment: ERBPolicyAssessment,
    policyIndex: number
): Claim {
    if (claim.claimType === ClaimType.EarlyRetirement) {
        const erbClaim = claim as EarlyRetirementClaim;

        const assessmentDetails = updateRecordReduction(
            erbClaim.assessmentDetails,
            benefitAssessment,
            policyIndex
        );

        return {
            ...claim,
            assessmentDetails,
        };
    }
    return claim;
}
