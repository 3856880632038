// import { BeneficiaryManualScreening, Payment } from '../reducer';
import { Claim, claimUpdatedThunk } from '../../../../../claim';
import { openDialogAction, openToastAction } from '../../../../../confirmation-dialog';
import { env } from '../../../../../env';
import { hideLoadingAction, showLoadingAction } from '../../../../../forms/loading-screen/actions';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { Correspondence } from '../../../common/requirements-management/reducer';
import axios from 'axios';

type CorrespondenceReturn = Promise<any>;
export type CommunicationAction = (
    correspondence: Correspondence
) => ReduxThunkAction<CorrespondenceReturn>;
export type CommunicationsAction = (documents: Correspondence) => CorrespondenceReturn;
export const communicationAction: CommunicationAction = (correspondence: any) => async (
    dispatch,
    getState
) => {
    dispatch(showLoadingAction());
    const uri = `${env().CLAIMS_API}/api/Claim/correspondence`;
    const claim = getState().claimSession.claim;
    const correspondenceHistory = getState().claimSession.claim.correspondence;

    const corr = {
        communicationType: correspondence.communicationType,
        date: correspondence.claimDate,
        missingInformation:
            correspondence.communicationType === 'MISSING INFORMATION'
                ? (correspondenceHistory?.missingInformation || []).concat(correspondence)
                : claim.correspondence?.missingInformation || [],
        allocation:
            correspondence.communicationType !== 'MISSING INFORMATION' &&
            correspondence.communicationType !== 'UNDER INVESTIGATION'
                ? (correspondenceHistory?.allocation || []).concat(correspondence)
                : claim.correspondence?.allocation || [],
        underInvestigation:
            correspondence.communicationType === 'UNDER INVESTIGATION'
                ? (correspondenceHistory?.underInvestigation || []).concat(correspondence)
                : claim.correspondence?.underInvestigation || [],
    };

    await axios
        .post<Claim>(uri, getUpdatedClaim(claim, corr))
        .then(response => {
            if (response.data) {
                dispatch(claimUpdatedThunk(response.data));
                dispatch(
                    openToastAction({
                        toastContent: {
                            message:
                                correspondence.communicationType === 'MISSING INFORMATION'
                                    ? 'Document request email sent'
                                    : correspondence.communicationType === 'UNDER INVESTIGATION'
                                    ? 'Policy under investigation email sent'
                                    : 'Assessor allocation email sent',
                            type: 'success',
                        },
                    })
                );
                dispatch(hideLoadingAction());
            }
        })
        .catch(error => {
            let errorMessage = '';
            if (error.response) {
                errorMessage = `Error Code ${error.response.status}`;
            } else {
                errorMessage = error.message;
            }
            dispatch(
                openDialogAction({
                    content: {
                        title: 'Error sending email correspondence',
                        body: errorMessage,
                        error: true,
                    },
                })
            );
        })
        .then(() => dispatch(hideLoadingAction()));
};

function getUpdatedClaim(claim: Claim, correspondence: Correspondence): Claim {
    return {
        ...claim,
        correspondence,
    };
}

export type UpdateIntiatedClaim = () => Claim;
type UpdateIntiatedClaimThunk = () => ReduxThunkAction;

export const updateIntiatedClaimAction: UpdateIntiatedClaimThunk = () => {
    return async (dispatch, getState) => {
        const claim = getState().claimSession.claim;
        const claimsUri = `${env().CLAIMS_API}/api/Claim/`;
        const updateClaimUri = `${claimsUri}${claim.claimId}/UpdateInitiateStatus`;

        const requestClaim: Claim = getUpdatedIniatedClaim(claim);

        const response = await axios.put<Claim>(updateClaimUri, requestClaim);

        const responseClaim: Claim = response.data;

        dispatch(claimUpdatedThunk(responseClaim));
        return {};
    };
};

function getUpdatedIniatedClaim(claim: Claim): any {
    return {
        ...claim,
        isClaimInitiated: true,
    };
}
