import { withStyles, Grid, Typography, Paper, Button, Tooltip } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { ExpansionPanel } from '../../../../../../forms/form-components/expansion-panel/expansion-panel';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import { Edit } from '@material-ui/icons';
import { FormDetails, getFormDetails } from '../../form-details-reducer';
import { connect } from 'react-redux';
import { State } from '../../../../../../redux/root-reducer';
import { OpenModal, openModalAction } from '../../../../../../form-modal/actions';
import { FormModalView } from '../../../../../../form-modal/views/form-modal';
import { PaymentDetailsEdit } from './payment-details-edit';

interface BaseProps {
    formDetails?: FormDetails;
    disableEditButton?: boolean;
}
interface Props extends BaseProps, StyledComponent {
    readonly openModal: OpenModal;
}

const paymentDetailsView: React.FunctionComponent<Props> = ({
    classes,
    formDetails,
    openModal,
    disableEditButton,
}: Props) => {
    const edit = () => {
        openModal({
            modalContent: <FormModalView formView={() => <PaymentDetailsEdit />} />,
        });
    };
    const paymentDetails = formDetails?.payment?.information || {};

    return (
        <ExpansionPanel name="Payment details" style={{ marginLeft: -24, marginRight: -24 }}>
            <React.Fragment>
                <Grid container justify="space-between" className={classes.marginTop2}>
                    <Grid container item xs={10} alignItems="center">
                        <Typography className={classes.heading} variant="body1">
                            Payee Details
                        </Typography>
                    </Grid>
                </Grid>
                <Paper className={classes.paper}>
                    <Grid container item xs={12} alignItems="center" justify="flex-end">
                        <Tooltip title="Edit Payment Details">
                            <Button
                                id="EditPersonalDetails"
                                color="secondary"
                                onClick={edit}
                                disabled={!disableEditButton}
                            >
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid container justify="flex-start">
                        <Grid item xs={12} container justify="flex-start">
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Payment must be made into the account of"
                                    value={paymentDetails.paymentBeneficiary}
                                />
                            </Grid>
                            {paymentDetails.bankName && (
                                <Grid item xs={4} lg={3}>
                                    <LabeledValue
                                        label="Bank Name"
                                        value={paymentDetails.bankName}
                                    />
                                </Grid>
                            )}
                            {paymentDetails.branchCode && (
                                <Grid item xs={4} lg={3}>
                                    <LabeledValue
                                        label="Branch Code"
                                        value={paymentDetails.branchCode}
                                    />
                                </Grid>
                            )}
                            {paymentDetails.accountNumber && (
                                <Grid item xs={4} lg={3}>
                                    <LabeledValue
                                        label="Account Number"
                                        value={paymentDetails.accountNumber}
                                    />
                                </Grid>
                            )}
                            {paymentDetails.accountType && (
                                <Grid item xs={4} lg={3}>
                                    <LabeledValue
                                        label="Account Type"
                                        value={paymentDetails.accountType}
                                    />
                                </Grid>
                            )}
                            {paymentDetails.branchName && (
                                <Grid item xs={4} lg={3}>
                                    <LabeledValue
                                        label="Branch Name"
                                        value={paymentDetails.branchName}
                                    />
                                </Grid>
                            )}
                            {paymentDetails.relationshipToAccountHolder && (
                                <Grid item xs={4} lg={3}>
                                    <LabeledValue
                                        label="Account holder relationship"
                                        value={paymentDetails.relationshipToAccountHolder}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </React.Fragment>
        </ExpansionPanel>
    );
};

const mapStateToProps = (state: State, ownProps: BaseProps) => ({
    formDetails: ownProps.formDetails || getFormDetails(state),
    disableEditButton: ownProps.disableEditButton || false,
});

const mapDispatchToProps = {
    openModal: openModalAction,
};

export const PaymentDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(paymentDetailsView));
