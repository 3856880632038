import { Button, Grid, Paper, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { DoctorsDetails, getDoctorDetails } from '../../../../doctor-details/reducer';
import { DoctorsDetailsView } from '../../../../doctor-details/views';
import { OpenModal, openModalAction } from '../../../../form-modal/actions';
import { FormModalView } from '../../../../form-modal/views/form-modal';
import { ButtonWrapper } from '../../../../forms';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { DoctorsView } from '../../common/life-assured/views/doctors-view';

type Props = StyledComponent & {
    openModal: OpenModal;
    doctorsDetails: DoctorsDetails;
    isReadOnly: boolean;
};

const currentDoctorsDetailsView: React.FunctionComponent<Props> = ({
    classes,
    doctorsDetails,
    openModal,
    isReadOnly,
}: Props) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.marginTop3}>
                    {'Current Doctors Details'}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <ButtonWrapper>
                        <Grid item xs={12} className={classes.rightAlign}>
                            <Tooltip title="Edit">
                                <Button
                                    id="EditCurrentDoctors"
                                    color="secondary"
                                    onClick={() => edit()}
                                >
                                    <Edit />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </ButtonWrapper>
                    {doctorsDetails.doctors && doctorsDetails.doctors.length > 0 && (
                        <DoctorsView classes={classes} doctors={doctorsDetails.doctors} />
                    )}
                </Paper>
            </Grid>
        </Grid>
    );

    function edit() {
        openModal({
            modalContent: <FormModalView formView={DoctorsDetailsView} />,
        });
    }
};

const mapStateToProps = (state: State) => ({
    doctorsDetails: getDoctorDetails(state),
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = {
    openModal: openModalAction,
};

export const CurrentDoctorsDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(currentDoctorsDetailsView));
