import { AnyAction, Reducer } from 'redux';
import { ReduxAction } from '../../redux/redux-actions';
import { SET_READ_ONLY } from '../types';

export type ReadOnlyAction = ReduxAction<boolean> | AnyAction;
export const claimReadOnlyReducer: Reducer<boolean, ReadOnlyAction> = (
    state = false,
    action: ReadOnlyAction
) => {
    if (action.type === SET_READ_ONLY) {
        return action.payload;
    }
    return state;
};
