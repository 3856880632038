import { Account } from 'msal';
import { Nullable } from '../../shared-types';
import { SET_ACTIVE_USER } from '../action-types';
import { AuthState, SetActiveUserAction } from '../auth-reducer';

const rolesPropertyName = 'roles';

export type SetActiveUserActionCreator = (user?: Nullable<Account>) => SetActiveUserAction;
export const setActiveUserActionCreator: SetActiveUserActionCreator = (
    user?: Nullable<Account>
) => {
    const payload: Nullable<AuthState> = !user
        ? null
        : {
              userId: user.userName,
              name: user.name,
              roles: user.idToken.hasOwnProperty(rolesPropertyName)
                  ? ((user.idToken[rolesPropertyName] as any) as [])
                  : [],
          };

    return {
        type: SET_ACTIVE_USER,
        payload,
    };
};
