import React from 'react'; // eslint-disable-line
import { getDisplayNameFromAdName, getFormattedDate } from '../../../../../../shared-functions';
import { Typography, withStyles } from '@material-ui/core';
import { State } from '../../../../../../redux/root-reducer';
import { connect } from 'react-redux';
import styles from '../../../../../../styles/styles';
import { AuthState } from '../../../../../../auth/auth-reducer';

const assessorAllocationContent = ({ modal, currentUser }: any) => {
    const creationDate = sessionStorage.getItem('creationDate') || '';

    const date = getFormattedDate(creationDate);

    const claimantName = `${
        modal.form?.communicationsTitle ? modal.form?.communicationsTitle.toLowerCase() : ''
    } ${modal.form?.claimantSurname ? modal.form?.claimantSurname : ''}`;

    return (
        <>
            <Typography variant="body1" style={{ marginBottom: 10, whiteSpace: 'pre-line' }}>
                {`Dear ${claimantName}`} <br />
                <br />
                Policy number:{' '}
                {`${
                    modal?.form?.communicationsPolicy ? modal?.form?.communicationsPolicy : ''
                }`}{' '}
                <br /> <br /> Thank you for submitting you request on {`${date}`}. <br /> <br />
            </Typography>
            <Typography>
                {`We would like to inform you that ${getDisplayNameFromAdName(
                    currentUser.name
                )} is the assessor allocated to your
                claim. We have already started the process of assessing your claim and will be in
                contact with you in the next 48 hours.`}
                <br /> <br />
            </Typography>{' '}
            <Typography>
                For further information please mail us at info@liberty.co.za or Contact us: 0860 456
                789/ +27(0)11 558 4871.
            </Typography>
        </>
    );
};

const mapStateToProps = (state: State) => ({
    modal: state.confirmationDialog,
    currentUser: state.auth as AuthState,
});

export const AssessorAllocationContent = connect(
    mapStateToProps,
    {}
)(withStyles(styles)(assessorAllocationContent));
