import { maskArray } from 'react-text-mask';

const digitRegex = /\d/;
const numberRegEx = /^0*(\d{0,3})%$/g;
export const PercentageMask: (value: string) => maskArray = (value: string) => {
    const percentageMask: maskArray = [digitRegex];

    value = value.replace(numberRegEx, '$1');
    if (value.length === 0) {
        return percentageMask;
    }

    if (value.length === 1) {
        return [digitRegex, '%'];
    }

    if (value.length === 2 || (value.length >= 3 && value !== '100')) {
        return [digitRegex, digitRegex, '%'];
    }

    return [/1/, /0/, /0/, '%'];
};
