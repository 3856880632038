import { maskArray } from 'react-text-mask';

const charRegex = /[A-Za-z '`-]/;
export const FirstNameMask: (value: string) => maskArray = (value: string) => {
    const nameMask: maskArray = [charRegex];
    return nameMask.concat(
        value
            .split('')
            .slice(1)
            .filter(c => charRegex.test(c))
            .map(c => charRegex)
    );
};
