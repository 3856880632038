import { Button, Tooltip } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { OpenModal, openModalAction } from '../../../../../form-modal/actions';
import { FormModalView } from '../../../../../form-modal/views/form-modal';
import { ButtonWrapper, GenericForm } from '../../../../../forms';
import { State } from '../../../../../redux/root-reducer';
import { OnSubmitSuccessProps } from '../../../../../workflow-navigation';
import { UpdateRequirements, updateRequirementsAction } from '../actions/update-requirements';
import { getRequirements, RequiredDocument, Requirements } from '../reducer';
import { RequiredDocumentsEditView } from './edit-required-documents-view';

interface Props extends OnSubmitSuccessProps {
    documents: RequiredDocument[];
    name?: string;
    prefix?: string;
    requirements: Requirements;
    updateRequirements: UpdateRequirements;
}
const RequirementsEditForm = GenericForm<Requirements>();
const requirementsEditModal: React.FunctionComponent<Props> = ({
    documents,
    name,
    prefix,
    requirements,
    updateRequirements,
    ...rest
}: Props) => {
    return (
        <RequirementsEditForm onSubmit={updateRequirements} initialValues={requirements} {...rest}>
            <RequiredDocumentsEditView documents={documents} name={name} prefix={prefix || ''} />
        </RequirementsEditForm>
    );
};

const mapStateToProps = (state: State) => ({
    requirements: getRequirements(state),
});

const mapDispatchToProps = {
    updateRequirements: updateRequirementsAction,
};

export const RequirementsEditView = connect(
    mapStateToProps,
    mapDispatchToProps
)(requirementsEditModal);

interface ButtonProps {
    documents: RequiredDocument[];
    isReadOnly: boolean;
    hasDeferredContracts: boolean;
    name: string;
    openModal: OpenModal;
    prefix: string;
}
const mapStateProps = (state: State) => ({
    isReadOnly: state.claimSession.readOnly,
    hasDeferredContracts: state.claimSession.hasDeferredContracts,
});
export const EditDocumentsButton = connect(mapStateProps, { openModal: openModalAction })(
    function editButton({
        documents,
        isReadOnly,
        hasDeferredContracts,
        name,
        openModal,
        prefix,
    }: ButtonProps) {
        if (isReadOnly || hasDeferredContracts) {
            return null;
        }

        const edit = () => {
            openModal({
                modalContent: (
                    <FormModalView
                        formView={() => (
                            <RequirementsEditView
                                documents={documents}
                                name={name}
                                prefix={prefix}
                            />
                        )}
                    />
                ),
            });
        };

        return (
            <ButtonWrapper>
                <Tooltip title="Manage requested documents">
                    <Button id="EditRequiredDocuments" color="secondary" onClick={edit}>
                        <Edit />
                    </Button>
                </Tooltip>
            </ButtonWrapper>
        );
    }
);
