import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { FileCopy, VerifiedUser } from '@material-ui/icons';
import { FormApi } from 'final-form';
import React from 'react'; // eslint-disable-line
import { FormSpy } from 'react-final-form';
import { connect } from 'react-redux';
import { env } from '../../../../../env';
import {
    ButtonCapture,
    Condition,
    FullWidthDatePicker,
    FullWidthRadioGroup,
    FullWidthTextField,
    RadioOption,
    True,
    WhenFieldChanges,
} from '../../../../../forms';
import {
    FirstNameMask,
    LastNameMask,
    NumberMask,
    TelephoneNumberMask,
} from '../../../../../forms/input-masks';
import { AddressDetailsSubForm } from '../../../../../forms/sub-forms/address-details';
import { requiredValidator } from '../../../../../forms/validations';
import { State } from '../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { FormDetails, getFormDetails } from '../../../funeral/form-details/form-details-reducer';
import { NoticeOfDeath } from '../notice-of-death-reducer';

interface Props extends StyledComponent {
    formDetails: FormDetails;
    isDeath?: boolean;
}

interface InnerProps extends Props {
    form: FormApi<NoticeOfDeath>;
}

const doctorIndunaEditView: React.FunctionComponent<Props> = ({
    formDetails,
    isDeath,
    classes,
}) => {
    return FormSpy<NoticeOfDeath>({
        render: ({ form }) =>
            doctorIndunaEditViewForm({
                classes,
                form,
                formDetails,
                isDeath,
            }),
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const doctorIndunaEditViewForm = ({ classes, form, formDetails, isDeath }: InnerProps) => {
    const prefix = 'doctorIndunaDetails.';
    const addressType = isDeath ? 'residential' : 'street';
    function populateFromFormDetails() {
        if (!!form) {
            const currentValues = form.getState().values.doctorIndunaDetails;
            form.batch(() => {
                form.change('doctorIndunaDetails', {
                    ...currentValues,
                    ...formDetails.declaration,
                    doctorInduna: formDetails.declaration.certifiedBy,
                });
            });
        }
    }
    return (
        <Grid container spacing={5} justify="flex-start">
            {!isDeath && (
                <Grid item xs={12} container justify="flex-end">
                    <Button onClick={populateFromFormDetails} className={classes.copyButton}>
                        <FileCopy className={classes.copyIcon} />
                        Copy from form details
                    </Button>
                </Grid>
            )}
            <WhenFieldChanges
                field={`${prefix}doctorInduna`}
                becomes="Induna"
                set={`${prefix}verifyDoctor`}
                to={null}
            />
            <WhenFieldChanges
                field={`${prefix}doctorInduna`}
                becomes="Induna"
                set={`${prefix}dateDoctorSigned`}
                to={null}
            />
            <WhenFieldChanges
                field={`${prefix}doctorInduna`}
                becomes="Induna"
                set={`${prefix}registrationNumber`}
                to={null}
            />
            <Grid item xs={6}>
                <FullWidthRadioGroup
                    name={`${prefix}doctorInduna`}
                    label="Select to capture Doctor's or Induna's Details"
                    validate={requiredValidator}
                >
                    <RadioOption value="Doctor" />
                    <RadioOption value="Induna" />
                </FullWidthRadioGroup>
            </Grid>
            <Condition when={`${prefix}doctorInduna`} is="Doctor">
                <True>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            label="SAMDC/SANC Registration number"
                            name={`${prefix}registrationNumber`}
                            validate={requiredValidator}
                            mask={NumberMask}
                        />
                    </Grid>
                </True>
            </Condition>
            <Grid item xs={6}>
                <FullWidthTextField
                    label="First Name"
                    name={`${prefix}firstName`}
                    mask={FirstNameMask}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    label="Second Name"
                    name={`${prefix}secondName`}
                    mask={FirstNameMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    label="Surname"
                    name={`${prefix}lastName`}
                    mask={LastNameMask}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    label="Telephone Number"
                    name={`${prefix}telephoneNumber`}
                    validate={requiredValidator}
                    mask={TelephoneNumberMask}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.marginTop}>
                    <Condition when={`${prefix}doctorInduna`} is="Induna">
                        <True>Address Details</True>
                    </Condition>
                    <Condition when={`${prefix}doctorInduna`} is="Doctor">
                        <True>Practice Address Details</True>
                    </Condition>
                </Typography>
            </Grid>
            <AddressDetailsSubForm
                addressPropertyName={`${prefix}addressDetails`}
                addressType={addressType}
            />
            <ButtonCapture>
                <Condition when={`${prefix}doctorInduna`} is="Doctor">
                    <True>
                        <Grid item xs={6} container justify="space-between">
                            <Grid item xs={7}>
                                <FullWidthRadioGroup
                                    name={`${prefix}verifyDoctor`}
                                    label="Verify Doctor"
                                    validate={requiredValidator}
                                >
                                    <RadioOption value="Yes" />
                                    <RadioOption value="No" />
                                </FullWidthRadioGroup>
                            </Grid>
                            <Grid item xs={5} style={{ marginTop: '32px' }}>
                                <ValidateDoctor />
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthDatePicker
                                label="Date Doctor Signed"
                                name={`${prefix}dateDoctorSigned`}
                                validate={requiredValidator}
                                disableFuture
                            />
                        </Grid>
                    </True>
                </Condition>
            </ButtonCapture>
        </Grid>
    );
};

export const ValidateDoctor = () => {
    const openHPCSA = () => {
        window.open(env().HPCSA);
    };
    return (
        <ButtonCapture>
            <Tooltip title="Open HPCSA website to validate the doctor">
                <Button
                    id="OpenHPCSA"
                    color="secondary"
                    style={{
                        textTransform: 'none',
                        marginLeft: '4px',
                        marginTop: '-4px',
                    }}
                    onClick={openHPCSA}
                >
                    <VerifiedUser style={{ marginRight: '4px' }} /> Validate Doctor
                </Button>
            </Tooltip>
        </ButtonCapture>
    );
};

const mapStateToProps = (state: State) => ({
    formDetails: getFormDetails(state),
});

export const EditDoctorIndunaDetails = connect(
    mapStateToProps,
    {}
)(withStyles(styles)(doctorIndunaEditView));
