import React from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import { FormRenderProps } from 'react-final-form';
import { FormHeading, FullWidthDatePicker, FullWidthTextField } from '../../forms';
import { FirstNameMask, LastNameMask } from '../../forms/input-masks';
import { AddressDetailsSubForm } from '../../forms/sub-forms/address-details';
import { CommunicationDetailsSubForm } from '../../forms/sub-forms/communication-details';
import { conditionalCustomRequired } from '../../forms/validations';
import styles, { StyledComponent } from './styles';

type Props = StyledComponent & FormRenderProps;

const nameRequiredMessage = 'At least 1 of Name and Surname is required';

const DoctorsDetailsView: React.FunctionComponent<Props> = () => {
    return (
        <React.Fragment>
            <FormHeading text="Doctor Details" />
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={6}>
                    <FullWidthTextField
                        name={'name'}
                        label="Name"
                        validate={conditionalCustomRequired(
                            nameRequiredMessage,
                            values => !values.surname
                        )}
                        mask={FirstNameMask}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        name={'surname'}
                        label="Surname"
                        validate={conditionalCustomRequired(
                            nameRequiredMessage,
                            values => !values.name
                        )}
                        mask={LastNameMask}
                    />
                </Grid>
                <AddressDetailsSubForm />
                <CommunicationDetailsSubForm displayOptions={{ telephoneNumber: true }} />
                <Grid item xs={6}>
                    <FullWidthDatePicker name={'dateOfConsultation'} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default withStyles(styles)(DoctorsDetailsView);
