import { AnyAction, Reducer } from 'redux';
import { getProperty } from '../../../claim/reducers';
import { State } from '../../../redux/root-reducer';
import { Nullable } from '../../../shared-types';
import { ClaimLiability } from '../assessment-details-reducer';
import { collectionReducer } from '../../../redux/collection-reducer';
import { combineReducersWithDefault } from '../../../redux/combine-reducers-with-default';
import { ADD_RA_DEATH_DECISION } from './actions/types';
import { v4 as uuid } from 'uuid';
import { Dependant } from '../../synopsis/ra-death/dependants/reducer';
import { getFullName } from '../../../shared-functions';

export interface Recommendation {
    allocation: number;
    dependantId: string;
    reason: string;
    reasonDescription?: string;
}

export interface AssessmentPolicy {
    readonly benefitCode: string;
    readonly benefitName: string;
    readonly claimLiability: ClaimLiability;
    readonly commencementDate: Date;
    readonly productName: string;
    readonly policyNumber: string;
    readonly unallocatedClaimAmount: number;
    fundName: string;
}

export interface Decision {
    id: string;
    assessmentType: string;
    assessmentDate: Date;
    assessmentSummary: string;
    briefSummary?: string;
    assessor: string;
    recommendations: Recommendation[];
    percentageSplits: Recommendation[];
    trusteeMeetingDate?: Date;
    trusteeSummary?: string;
    allocationReason?: string;
}

export interface RecommendationModel {
    age: string;
    allocation?: number;
    dependantId: string;
    name: string;
    reason?: string;
    reasonDescription?: string;
    relationship: string;
}

export interface Assessment {
    readonly assessmentPolicies: AssessmentPolicy[];
    readonly decisions: Decision[];
}

export interface DecisionModel {
    id: string;
    assessmentType: string;
    assessmentDate: Date;
    assessmentSummary: string;
    assessor: string;
    recommendations: RecommendationModel[];
    percentageSplits: RecommendationModel[];
    trusteeMeetingDate?: Date;
    trusteeSummary?: string;
    allocationReason?: string;
}

export const defaultRaDeathAssessmentDetails: Assessment = {
    assessmentPolicies: [],
    decisions: [],
};

export const assessmentDecisionsReducer = collectionReducer<Decision>({
    addAction: ADD_RA_DEATH_DECISION,
});

export const assessmentPoliciesReducer: Reducer = (
    state: Nullable<Decision> = null,
    action: AnyAction
) => {
    return state;
};

export const raDeathAssessmentDetailsReducer = combineReducersWithDefault<Assessment>(
    defaultRaDeathAssessmentDetails,
    {
        assessmentPolicies: assessmentPoliciesReducer,
        decisions: assessmentDecisionsReducer,
    }
);

export function getRaDeathAssessmentDetails(state: State): Assessment {
    return getProperty(state, 'assessmentDetails', defaultRaDeathAssessmentDetails);
}

export function getRaDeathAssessmentDecisions(state: State): Decision[] {
    const decisions = getProperty(state, 'assessmentDetails', defaultRaDeathAssessmentDetails)
        .decisions;
    return decisions.sort((a, b) =>
        a.assessmentDate < b.assessmentDate ? 1 : b.assessmentDate < a.assessmentDate ? -1 : 0
    );
}

export function getBlankAssessmentRecord(
    decisions: Decision[],
    dependants: Dependant[],
    currentUser?: any
) {
    const blankDecision: DecisionModel = {
        id: uuid(),
        assessmentType: '',
        assessmentDate: new Date(),
        assessmentSummary: '',
        assessor: currentUser ? currentUser.name : 'Claimslive',
        recommendations: getDependantsRecommendations(),
        percentageSplits: getDependantsPercentage(),
        allocationReason: getAllocationReason() || '',
    };

    function getDependantsRecommendations(): RecommendationModel[] {
        const currentDecision = decisions[0];
        return dependants.map(dependant => {
            const dependantRecommendation =
                currentDecision &&
                currentDecision.recommendations.find(r => r.dependantId === dependant.id);
            if (dependant.dependantType === 'Organisation') {
                const organisationDetails = dependant.organisationDetails || {};
                return {
                    dependantId: dependant.id,
                    name: organisationDetails.companyName || '',
                    age: '-',
                    relationship: organisationDetails.relationshipToLifeAssured || '',
                    allocation: dependantRecommendation && dependantRecommendation.allocation,
                };
            }
            const personalDetails = dependant.personalDetails || {};
            return {
                dependantId: dependant.id,
                name: getFullName(personalDetails),
                age: personalDetails.age ? personalDetails.age.toString() : '-',
                relationship: dependant.relationshipDescription || '',
                allocation: dependantRecommendation && dependantRecommendation.allocation,
            };
        });
    }
    function getDependantsPercentage(): RecommendationModel[] {
        const currentDecision = decisions[0];

        return dependants.map(dependant => {
            const personalDetails = dependant.personalDetails || {};
            if (currentDecision && currentDecision.percentageSplits === null) {
                const defaultDecision = getDefaultDecision(dependants).percentageSplits[0];
                return {
                    dependantId: defaultDecision.dependantId,
                    name: getFullName(personalDetails),
                    age: personalDetails.age ? personalDetails.age.toString() : '-',
                    relationship: dependant.relationshipDescription || '',
                    allocation: defaultDecision.allocation,
                };
            }
            const dependantRecommendation =
                currentDecision &&
                currentDecision.percentageSplits?.find(r => r.dependantId === dependant.id);
            if (dependant.dependantType === 'Organisation') {
                const organisationDetails = dependant.organisationDetails || {};
                return {
                    dependantId: dependant.id,
                    name: organisationDetails.companyName || '',
                    age: '-',
                    relationship: organisationDetails.relationshipToLifeAssured || '',
                    allocation: dependantRecommendation && dependantRecommendation.allocation,
                };
            }
            return {
                dependantId: dependant.id,
                name: getFullName(personalDetails),
                age: personalDetails.age ? personalDetails.age.toString() : '-',
                relationship: dependant.relationshipDescription || '',
                allocation: dependantRecommendation && dependantRecommendation.allocation,
            };
        });
    }

    function getAllocationReason(): string | null {
        const currentDecision = decisions[0];

        if (currentDecision && currentDecision.allocationReason !== undefined) {
            return currentDecision.allocationReason;
        } else {
            return null;
        }
    }

    return blankDecision;
}

function getDefaultDecision(dependants: Dependant[]) {
    return {
        id: '',
        assessmentType: '',
        assessmentDate: new Date(),
        assessmentSummary: '',
        assessor: '',
        recommendations: dependants.map(dependant => {
            return {
                dependantId: dependant.id,
                reason: '',
                allocation: 0,
            };
        }),
        percentageSplits: dependants.map(dependant => {
            return {
                dependantId: dependant.id,
                reason: '',
                allocation: 0,
            };
        }),
    };
}
