import { AnyAction, Reducer } from 'redux';
import { ReduxAction } from '../../redux/redux-actions';
import { State } from '../../redux/root-reducer';
import { Nullable } from '../../shared-types';
import { SET_REFERENCE } from '../types';
import { getProperty } from './claim-reducer';

export interface ClaimReference {
    referenceSource: string;
    referenceId: string;
    creationDate: string;
}

export type ClaimReferenceAction = ReduxAction<ClaimReference[]> | AnyAction;
export const claimReferenceReducer: Reducer<Nullable<ClaimReference[]>, ClaimReferenceAction> = (
    state: Nullable<ClaimReference[]> = null,
    action: ClaimReferenceAction
) => {
    if (action.type === SET_REFERENCE) {
        return [action.payload];
    }
    return state;
};

export function getReferences(state: State): ClaimReference[] {
    return getProperty(state, 'references', []);
}
