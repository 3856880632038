import axios from 'axios';
import { claimUpdatedThunk } from '../../../../../claim';
import { openDialogAction } from '../../../../../confirmation-dialog';
import { env } from '../../../../../env';
import { hideLoadingAction, showLoadingAction } from '../../../../../forms/loading-screen/actions';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';

type RunRulesReturn = Promise<any>;
export type RunRules = () => RunRulesReturn;
type RunRulesAction = () => ReduxThunkAction<RunRulesReturn>;
export const runRulesAction: RunRulesAction = () => {
    return async (dispatch, getState) => {
        dispatch(showLoadingAction());
        const claim = getState().claimSession.claim;
        const rulesUri = `${env().CLAIMS_API}/api/Claim/${claim.claimId}/Rules`;
        await axios
            .post(rulesUri)
            .then(response => {
                if (response.data) {
                    dispatch(claimUpdatedThunk(response.data));
                }
            })
            .catch(error => {
                let errorMessage = '';
                if (error.response) {
                    errorMessage = `Error Code ${error.response.status}`;
                } else {
                    errorMessage = error.message;
                }
                showErrorMessage(errorMessage);
            })
            .then(() => dispatch(hideLoadingAction()));

        function showErrorMessage(error: any) {
            const errorMessage = `An error (${error}) occured while running rules, please retry later`;
            dispatch(
                openDialogAction({
                    content: {
                        title: 'Error running rules',
                        body: errorMessage,
                        error: true,
                    },
                })
            );
        }
    };
};
