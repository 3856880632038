import React from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { getDisplayDate } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { Dependant } from '../reducer';

interface SpouseProps extends StyledComponent {
    dependant: Dependant;
}

const dependantViewSpouse: React.FunctionComponent<SpouseProps> = ({ dependant }: SpouseProps) => {
    const personalDetails = dependant.personalDetails || {};
    return (
        <React.Fragment>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Age" value={personalDetails.age} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Gender" value={personalDetails.gender} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Relationship to life assured"
                    value={dependant.relationshipDescription}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Date of marriage"
                    value={getDisplayDate(personalDetails.dateOfMarriage)}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Currently employed" value={dependant.employed} />
            </Grid>
        </React.Fragment>
    );
};

export const DependantViewSpouse = withStyles(styles)(dependantViewSpouse);
