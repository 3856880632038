import React from 'react'; // eslint-disable-line
import { Grid, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import {
    Condition,
    False,
    FormHeading,
    FullWidthRadioGroup,
    FullWidthSearchSelectField,
    FullWidthTextField,
    GenericForm,
    RadioOption,
    True,
} from '../../../../../../forms';
import { requiredValidator } from '../../../../../../forms/validations';
import { State } from '../../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { FormDetails, getFormDetails } from '../../form-details-reducer';
import {
    FirstNameMask,
    LastNameMask,
    NumberMask,
    TelephoneNumberMask,
} from '../../../../../../forms/input-masks';
import { EmailMask } from '../../../../../../forms/input-masks/email-mask';
import { Titles } from '../../../../../../codes/titles';
import { UpdateFormDetails, updateFormDetailsAction } from '../../actions/update-form-details';
import { CommunicationMethods } from '../../../../../../codes/communication-methods';

interface ClaimantProps extends StyledComponent {
    formDetails?: FormDetails;
    updateFormDetails: UpdateFormDetails;
}

const ClaimantForm = GenericForm<FormDetails>();

const claimantDetailsForm: React.FunctionComponent<ClaimantProps> = ({
    classes,
    formDetails,
    updateFormDetails,
    ...rest
}: ClaimantProps) => {
    const prefix = 'claimant.personalDetails';
    const contactPrefix = 'claimant.contactDetails';

    return (
        <ClaimantForm initialValues={formDetails} onSubmit={updateFormDetails} {...rest}>
            <FormHeading text="Update Form Details | Claimant Details" />
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={6}>
                    <Condition when="claimant.policyDetails.isOwner" is={'Yes'}>
                        <True>
                            <FullWidthRadioGroup name={'claimant.policyDetails.isOwner'}>
                                <RadioOption value="Yes" label="Policy Holder/Relative" />
                                <RadioOption value="No" label="Financial Advisor" />
                            </FullWidthRadioGroup>
                        </True>
                        <False>
                            <FullWidthRadioGroup name={'claimant.policyDetails.claimingCapacity'}>
                                <RadioOption value={'Relative'} label="Policy Holder/Relative" />
                                <RadioOption value="Financial Advisor" label="Financial Advisor" />
                            </FullWidthRadioGroup>
                        </False>
                    </Condition>
                </Grid>
                <Grid item xs={6}>
                    <FullWidthSearchSelectField
                        label="Title"
                        name={`${prefix}.title`}
                        validate={requiredValidator}
                        options={Titles}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        label="First Name"
                        name={`${prefix}.firstName`}
                        mask={FirstNameMask}
                        validate={requiredValidator}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        label="Last Name"
                        name={`${prefix}.surname`}
                        mask={LastNameMask}
                        validate={requiredValidator}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        label="Telephone Number"
                        name={`${contactPrefix}.telephoneNumber`}
                        mask={TelephoneNumberMask}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        label="Cellphone"
                        name={`${contactPrefix}.cellphoneNumber`}
                        mask={TelephoneNumberMask}
                        validate={requiredValidator}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        label="Email Address"
                        name={`${contactPrefix}.emailAddress`}
                        mask={EmailMask}
                        validate={requiredValidator}
                    />
                </Grid>
                <Condition when="claimant.policyDetails.claimingCapacity" is={'Financial Advisor'}>
                    <True>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                label="Company Name"
                                name={`${prefix}.companyName`}
                                validate={requiredValidator}
                            />
                        </Grid>
                    </True>
                </Condition>
                <Condition when="claimant.policyDetails.claimingCapacity" is={'Financial Advisor'}>
                    <True>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                label="Broker Consultation code"
                                name={`${prefix}.consultantCode`}
                                mask={NumberMask}
                                validate={requiredValidator}
                            />
                        </Grid>
                    </True>
                </Condition>

                <Grid item xs={6}>
                    <FullWidthSearchSelectField
                        name={`${contactPrefix}.preferredMethodOfCommunication`}
                        label="Preferred Method Of Communication"
                        options={CommunicationMethods}
                    />
                </Grid>
            </Grid>
        </ClaimantForm>
    );
};

const mapStateToProps = (state: State) => ({
    formDetails: getFormDetails(state),
});

const mapDispatchToProps = {
    updateFormDetails: updateFormDetailsAction,
};

export const ClaimantDetailsForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(claimantDetailsForm));
