import { ContactDetails } from '../../../../../contact-details/contact-details';
import { UpdateRecordCollectionAction } from '../../../../../redux/collection-reducer';
import { OtherContact } from '../contact-details-reducer';
import { UPDATE_EXECUTOR_RECORD, UPDATE_OTHER_CONTACT_RECORD } from './types';

export type UpdateOtherContactRecordAction = UpdateRecordCollectionAction<OtherContact>;

export const updateOtherContactRecordActionCreator = (
    otherContactRecordIndex: number,
    otherContactRecord: OtherContact
): UpdateOtherContactRecordAction => {
    return {
        type: UPDATE_OTHER_CONTACT_RECORD,
        record: otherContactRecord,
        index: otherContactRecordIndex,
    };
};

export type UpdateExecutorRecordAction = UpdateRecordCollectionAction<ContactDetails>;

export const updateExecutorRecordActionCreator = (
    executorRecordIndex: number,
    executorRecord: ContactDetails
): UpdateExecutorRecordAction => {
    return {
        type: UPDATE_EXECUTOR_RECORD,
        record: executorRecord,
        index: executorRecordIndex,
    };
};
