import React from 'react'; // eslint-disable-line

import { Grid, Paper, withStyles } from '@material-ui/core';
import { FormHeading, GenericForm, FormErrors, FullWidthTextField } from '../../../../../../forms';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { connect } from 'react-redux';
import { RADeathClaim } from '../../../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { getClaim } from '../../../../../../claim';
import { State } from '../../../../../../redux/root-reducer';
import { CommunicationDetailsSubForm } from '../../../../../../forms/sub-forms/communication-details';
import { TelephoneNumberMask } from '../../../../../../forms/input-masks';
import {
    UpdateContactDetails,
    LifeAssuredContactDetails,
    updateContactDetailsAction,
} from '../../actions/update-contact-details';

interface Props extends StyledComponent {
    contactDetails?: LifeAssuredContactDetails;
    updateContactDetails: UpdateContactDetails;
}

const AddressDetailsForm = GenericForm<LifeAssuredContactDetails>();

const contactDetailsEdit: React.FunctionComponent<Props> = ({
    classes,
    contactDetails,
    updateContactDetails,
    ...rest
}: Props) => {
    return (
        <AddressDetailsForm
            onSubmit={updateContactDetails}
            initialValues={{
                ...contactDetails,
            }}
            {...rest}
        >
            <ContactDetailsView classes={classes} />
            <FormErrors />
        </AddressDetailsForm>
    );
};

const ContactDetailsView: React.FunctionComponent<StyledComponent> = ({
    classes,
}: StyledComponent) => {
    return (
        <Paper className={classes.paper}>
            <FormHeading text="Contact Details" />
            <Grid container spacing={5}>
                <Grid item xs={6} lg={6}>
                    <FullWidthTextField name="homeTelephoneNumber" mask={TelephoneNumberMask} />
                </Grid>
                <Grid item xs={6} lg={6}>
                    <FullWidthTextField name="workTelephoneNumber" mask={TelephoneNumberMask} />
                </Grid>
                <CommunicationDetailsSubForm
                    displayOptions={{
                        emailAddress: true,
                        cellphoneNumber: true,
                        faxNumber: true,
                    }}
                    required="Any"
                />
            </Grid>
        </Paper>
    );
};

const mapStateToProps = (state: State) => {
    const claim = getClaim(state);
    const raClaim = claim as RADeathClaim;
    return {
        ...raClaim.lifeAssuredDetails,
    };
};

const mapDispatchToProps = {
    updateContactDetails: updateContactDetailsAction,
};

export const ContactDetailsEditView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(contactDetailsEdit));
