import React from 'react'; // eslint-disable-line

import { Grid, Paper, withStyles } from '@material-ui/core';
import { FormApi } from 'final-form';
import { connect } from 'react-redux';
import {
    FormErrors,
    FormHeading,
    FullWidthRadioGroup,
    GenericForm,
    RadioOption,
} from '../../forms';
import { requiredValidator } from '../../forms/validations';
import styles, { StyledComponent } from '../../styles/styles';
import { OnSubmitSuccessProps } from '../../workflow-navigation';
import { FormSpy } from 'react-final-form';
import { SetClaimFlow, setClaimFlowAction } from '../actions/set-claim-flow';

type Props = OnSubmitSuccessProps &
    StyledComponent & {
        isFuneral?: string;
        setClaimFlow: SetClaimFlow;
    };

const DetermineFlowForm = GenericForm<string>();
const determineFlowView: React.FunctionComponent<Props> = ({
    classes,
    isFuneral,
    setClaimFlow,
    ...rest
}: Props) => {
    const onSubmit = (values: any) => setClaimFlow(values.isFuneral);
    return (
        <DetermineFlowForm onSubmit={onSubmit} initialValues={isFuneral} {...rest}>
            {() =>
                FormSpy<string>({
                    render: ({ form }) => InnerForm({ classes, form }),
                })
            }
        </DetermineFlowForm>
    );
};
type InnerProps = StyledComponent & {
    form: FormApi<string>;
};

const InnerForm: React.FunctionComponent<InnerProps> = ({ classes, form }: InnerProps) => {
    const reset = form ? form.reset : () => null;
    return (
        <Paper className={classes.paper}>
            <FormHeading text="Claim Process" />
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={12} lg={12}>
                    <FullWidthRadioGroup
                        name="isFuneral"
                        label="Is this a funeral claim?:"
                        validate={requiredValidator}
                    >
                        <RadioOption value="Yes" onClick={reset} />
                        <RadioOption value="No" onClick={reset} />
                    </FullWidthRadioGroup>
                    <FormErrors />
                </Grid>
            </Grid>
        </Paper>
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    setClaimFlow: setClaimFlowAction,
};

export const DetermineFlowView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(determineFlowView));
