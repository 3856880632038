import { claimSubmit, Claim } from '../../claim';
import { updateRecordReduction } from '../../redux/reductions';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { PaymentItem } from '../reducer';

type SubmitReturn = Promise<any>;
export interface UpdatePaymentItemModel extends PaymentItem {
    decisionAmount?: number;
    maximumAmountPayable?: number;
}

export type UpdatePaymentItem = (
    payment: UpdatePaymentItemModel,
    paymentIndex: number,
    paymentItemIndex: number
) => SubmitReturn;
type UpdatePaymentItemAction = (
    payment: UpdatePaymentItemModel,
    paymentIndex: number,
    paymentItemIndex: number
) => ReduxThunkAction<SubmitReturn>;

export const updatePaymentItemAction: UpdatePaymentItemAction = (
    payment: UpdatePaymentItemModel,
    paymentIndex: number,
    paymentItemIndex: number
) => {
    return async (dispatch, getState) => {
        return claimSubmit(
            dispatch,
            getState,
            getUpdatedClaim(payment, paymentIndex, paymentItemIndex)
        );
    };
};

function getUpdatedClaim(
    payment: UpdatePaymentItemModel,
    paymentIndex: number,
    paymentItemIndex: number
): (claim: Claim) => Claim {
    return (claim: Claim) => {
        const currentPayments = (claim.payments && claim.payments) || [];
        const currentPayment = currentPayments[paymentIndex];
        const updatedPaymentItem: PaymentItem = {
            ...payment,
        };

        const updatedPayment = {
            ...currentPayment,
            paymentItems: updateRecordReduction(
                currentPayment.paymentItems,
                updatedPaymentItem,
                paymentItemIndex
            ),
        };

        return {
            ...claim,
            payments: updateRecordReduction(currentPayments, updatedPayment, paymentIndex),
        };
    };
}
