import { AnyAction, Reducer } from 'redux';
import { Claim, getPropertyFromClaim } from '../../../../claim';
import { Nullable } from '../../../../shared-types';

export interface ResultException {
    category: string;
    name: string;
    reason: string;
}

export interface Column {
    name: string;
    value: string;
}

export interface RuleRow {
    columns: Column[];
}

export interface Rule {
    rows?: RuleRow[];
    description?: string;
    passed?: string;
    rating?: string;
    reason?: string;
    scoreValue?: string;
    override?: boolean;
    comment?: string;
    name?: string;
}

export interface RulesResult {
    contractNumber?: string;
    exceptions: ResultException[];
    rating?: string;
    reason?: string;
    rules: Rule[];
    scoreValue?: string;
    isCireRan?: boolean;
}

export const initialRulesResult: RulesResult = { exceptions: [], rules: [] };
export const rulesReducer: Reducer = (state: Nullable<RulesResult[]> = null, action: AnyAction) => {
    return state;
};

const defaultResults: RulesResult[] = [];

export function getResultsFromClaim(claim: Claim) {
    return getPropertyFromClaim(claim, 'results', defaultResults);
}
