import { reduceAndSubmitClaim } from '../../../../../claim/actions/reduce-and-submit-claim';
import {
    AddRecordCollectionAction,
    DeleteRecordCollectionAction,
    UpdateRecordCollectionAction,
} from '../../../../../redux/collection-reducer';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { Doctors } from '../reducer';
import {
    ADD_CURRENT_DOCTOR_RECORD,
    DELETE_CURRENT_DOCTOR_RECORD,
    UPDATE_CURRENT_DOCTOR_RECORD,
} from '../types';

type SubmitReturn = Promise<any>;
export type AddCurrentDoctor = (doctors: Doctors) => SubmitReturn;
type AddCurrentDoctorThunk = (doctors: Doctors) => ReduxThunkAction<SubmitReturn>;
export const addCurrentDoctorThunk: AddCurrentDoctorThunk = (doctors: Doctors) => (
    dispatch,
    getState
) => reduceAndSubmitClaim(dispatch, getState, addCurrentDoctorActionCreator(doctors));
export type AddCurrentDoctorRecordAction = AddRecordCollectionAction<Doctors>;
export const addCurrentDoctorActionCreator = (doctors: Doctors): AddCurrentDoctorRecordAction => {
    return {
        type: ADD_CURRENT_DOCTOR_RECORD,
        record: doctors,
    };
};

export type UpdateCurrentDoctor = (doctorIndex: number, doctors: Doctors) => SubmitReturn;
type UpdateCurrentDoctorThunk = (
    doctorIndex: number,
    doctors: Doctors
) => ReduxThunkAction<SubmitReturn>;
export const updateCurrentDoctorThunk: UpdateCurrentDoctorThunk = (
    doctorIndex: number,
    doctors: Doctors
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        updateCurrentDoctorActionCreator(doctorIndex, doctors)
    );
export type UpdateCurrentDoctorAction = UpdateRecordCollectionAction<Doctors>;
export const updateCurrentDoctorActionCreator = (
    doctorIndex: number,
    doctors: Doctors
): UpdateCurrentDoctorAction => {
    return {
        type: UPDATE_CURRENT_DOCTOR_RECORD,
        record: doctors,
        index: doctorIndex,
    };
};

export type DeleteCurrentDoctor = (doctorIndex: number) => SubmitReturn;
type DeleteCurrentDoctorThunk = (doctorIndex: number) => ReduxThunkAction<SubmitReturn>;
export const deleteCurrentDoctorThunk: DeleteCurrentDoctorThunk = (doctorIndex: number) => (
    dispatch,
    getState
) => reduceAndSubmitClaim(dispatch, getState, deleteCurrentDoctorActionCreator(doctorIndex));
export type DeleteCurrentDoctorAction = DeleteRecordCollectionAction;
export const deleteCurrentDoctorActionCreator = (
    doctorIndex: number
): DeleteCurrentDoctorAction => {
    return {
        type: DELETE_CURRENT_DOCTOR_RECORD,
        index: doctorIndex,
    };
};
