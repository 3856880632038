import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { Location } from 'history';
import { connect, MapStateToProps } from 'react-redux';
import { Route, Switch } from 'react-router';
import { NavigateToScreen, navigateToScreenThunk } from '../actions';
import { GetFormSubmissionState, GetWorkflowState } from '../actions/navigate-to-next-screen';
import { FormSubmissionState } from '../reducers/fom-submission-reducer';
import { WorkflowState } from '../reducers/workflow-reducer';
import { WorkflowScreen } from '../reducers/workflow-screen';
import styles, { StyledComponent } from './styles';
import { WorkflowStepView } from './workflow-step';

interface OwnProps {
    getWorkflowState: GetWorkflowState;
    getFormSubmissionState: GetFormSubmissionState;
    location: Location;
}

interface StateProps {
    workflowState: WorkflowState;
    formSubmissionState: FormSubmissionState;
}

interface DispatchProps {
    navigateToScreen: NavigateToScreen;
}

type Props = StyledComponent & OwnProps & StateProps & DispatchProps;

class WorkflowViewClass extends React.PureComponent<Props> {
    public componentDidMount() {
        this.navigateToFirstScreen();
    }

    public componentDidUpdate() {
        this.navigateToFirstScreen();
    }

    public render() {
        const steps = this.props.workflowState.activeWorkflow.descendants();
        const component = (step: WorkflowScreen) => () => {
            return (
                <WorkflowStepView
                    screen={step}
                    getWorkflowState={this.props.getWorkflowState}
                    getFormSubmissionState={this.props.getFormSubmissionState}
                />
            );
        };
        return (
            <Switch location={this.props.location}>
                {steps.map(step => {
                    return (
                        <Route exact key={step.label} path={step.path()} render={component(step)} />
                    );
                })}
            </Switch>
        );
    }

    private navigateToFirstScreen() {
        if (this.props.location.pathname === '/') {
            this.props.navigateToScreen(
                this.props.workflowState.activeWorkflow.descendantScreens()[0],
                this.props.formSubmissionState
            );
        }
    }
}
const mapStateToProps: MapStateToProps<StateProps, OwnProps, any> = (
    state: any,
    props: OwnProps
) => ({
    workflowState: props.getWorkflowState(state),
    formSubmissionState: props.getFormSubmissionState(state),
});

const mapDispatchToProps = {
    navigateToScreen: navigateToScreenThunk,
};

export const WorkflowView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(WorkflowViewClass));
