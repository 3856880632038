import { Workflow } from '../../workflow-navigation/reducers/workflow';
import { WorkflowStep } from '../../workflow-navigation/reducers/workflow-step';
import { WorkflowUtilityScreen } from '../../workflow-navigation/reducers/workflow-utility-screen';
import { WorkflowAssessorScreen } from '../../workflow-navigation/reducers/workflow-assessor';

import { WorkflowScreen } from '../../workflow-navigation/reducers/workflow-screen';
import { Claim } from '../../claim';

export class ClaimWorkflow extends Workflow {
    public constructor(
        label: string,
        children: (WorkflowStep | WorkflowUtilityScreen | WorkflowAssessorScreen)[],
        handlesClaim: (claim: Claim) => boolean,
        screenHeading?: string,
        canShow?: () => boolean
    ) {
        super(label, children, screenHeading, canShow);
        this.handlesClaim = handlesClaim;
    }

    public cloneWithNewParent(parent?: WorkflowScreen) {
        return new Workflow(
            this.label,
            this.children() as (WorkflowStep | WorkflowUtilityScreen | WorkflowAssessorScreen)[],
            this.screenHeading,
            this.canShow
        );
    }

    public steps = () => this.children().filter(child => child instanceof WorkflowStep);
    public utilityScreens = () =>
        this.children().filter(child => child instanceof WorkflowUtilityScreen);
    public assessorScreens = () =>
        this.children().filter(child => child instanceof WorkflowAssessorScreen);
    public handlesClaim: (claim: Claim) => boolean;
}
