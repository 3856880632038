import React from 'react'; // eslint-disable-line

import { SelectProps } from '@material-ui/core/Select';
import { Field } from 'react-final-form';

import { FieldSet, SelectField } from '../../';

interface Props {
    id?: string;
    name: string;
    label?: string;
    options?: any;
    sortOptions?: boolean;
}
export const FullWidthSelectField: React.FunctionComponent<Props & SelectProps & any> = ({
    name,
    options,
    ...rest
}: Props & SelectProps & any) => {
    return (
        <FieldSet>
            <Field {...rest} name={name} component={SelectField} options={options} />
        </FieldSet>
    );
};
