import React from 'react'; // eslint-disable-line
import { ButtonWrapper } from '../../../../../../forms';
import { Button, Grid, Tooltip, Typography, withStyles, Paper } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { connect } from 'react-redux';
import { getRADeathClaim } from '../../../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { Titles } from '../../../../../../codes/titles';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import { OpenModal, openModalAction } from '../../../../../../form-modal/actions';
import { FormModalView } from '../../../../../../form-modal/views/form-modal';
import { State } from '../../../../../../redux/root-reducer';
import { getDisplayDate, getFullName, getCodeLabel } from '../../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { getRADeathLifeAssured, RADeathLifeAssured } from '../../reducer';
import { PersonalDetailsEditModal } from './personal-details-edit-modal';

type InnerProps = StyledComponent & {
    dateOfDeath?: Date;
    openModal: OpenModal;
    lifeAssuredDetails: RADeathLifeAssured;
    isReadOnly: boolean;
};
const personalDetailsView: React.FunctionComponent<InnerProps> = ({
    classes,
    dateOfDeath,
    lifeAssuredDetails,
    openModal,
    isReadOnly,
}: InnerProps) => {
    const personalDetails = lifeAssuredDetails.personalDetails;
    const medicalAidDetails = lifeAssuredDetails.medicalAidDetails;
    const previousMarriages = lifeAssuredDetails.previousMarriages;
    const edit = () => {
        openModal({
            modalContent: <FormModalView formView={PersonalDetailsEditModal} />,
        });
    };
    return (
        <React.Fragment>
            <Grid container justify="space-between">
                <Grid item xs={10} lg={11}>
                    <Typography variant="subtitle2">Personality details</Typography>
                </Grid>
                <ButtonWrapper>
                    <Grid item xs={2} lg={1} className={classes.rightAlign}>
                        <Tooltip title="Edit Life Assured Details">
                            <Button
                                id="EditPersonalDetails"
                                color="secondary"
                                onClick={() => edit()}
                            >
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            </Grid>
            <Paper className={classes.paper}>
                <Grid item xs={12} container spacing={2} justify="flex-start">
                    <Grid item xs={12}>
                        <Typography className={classes.marginTop} variant="h5">
                            {getFullName(personalDetails)}
                        </Typography>
                    </Grid>
                    {personalDetails.passportNumber && (
                        <Grid item xs={12} container justify="flex-start">
                            <Grid item xs={4} lg={2}>
                                <LabeledValue
                                    label="Passport"
                                    value={personalDetails.passportNumber}
                                />
                            </Grid>
                            <Grid item xs={4} lg={2}>
                                <LabeledValue
                                    label="Country Of Issue"
                                    value={personalDetails.passportCountryOfIssue}
                                />
                            </Grid>
                            <Grid item xs={4} lg={2}>
                                <LabeledValue
                                    label="Date of issue"
                                    value={getDisplayDate(personalDetails.passportIssueDate)}
                                />
                            </Grid>
                            <Grid item xs={4} lg={2}>
                                <LabeledValue
                                    label="Expiry Date"
                                    value={getDisplayDate(personalDetails.passportExpiryDate)}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {personalDetails.idNumber && (
                        <Grid item xs={4} lg={3}>
                            <LabeledValue label="ID Number" value={personalDetails.idNumber} />
                        </Grid>
                    )}
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="Date of Birth"
                            value={getDisplayDate(personalDetails.dateOfBirth, 'dd LLLL yyyy')}
                        />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="Date of Death"
                            value={getDisplayDate(dateOfDeath, 'dd LLLL yyyy')}
                        />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue label="PDB ID" value={personalDetails.clientId} />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue label="Age at Death" value={personalDetails.age} />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue label="Gender" value={personalDetails.gender} />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="Marital Status"
                            value={personalDetails.maritalStatus}
                        />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="Tax number"
                            value={personalDetails.taxReferenceNumber}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LabeledValue
                            label="Previously married"
                            value={lifeAssuredDetails.previouslyMarried}
                        />
                    </Grid>
                    {previousMarriages && previousMarriages.length > 0 && (
                        <React.Fragment>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" style={{ marginBottom: -32 }}>
                                    Previous marriages
                                </Typography>
                            </Grid>
                            {previousMarriages.map((marriage, index) => {
                                return (
                                    <React.Fragment key={`marriage-${index}`}>
                                        <Grid item xs={6} lg={3}>
                                            <LabeledValue
                                                label="Date of previous marriage"
                                                value={getDisplayDate(marriage.dateOfMarriage)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} lg={3}>
                                            <LabeledValue
                                                label="Date of divorce"
                                                value={getDisplayDate(marriage.dateOfDivorce)}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                );
                            })}
                        </React.Fragment>
                    )}
                    <Grid item xs={12} lg={12}>
                        <Typography variant="subtitle2" style={{ marginBottom: -32 }}>
                            Medical Aid Details
                        </Typography>
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue label="Name" value={medicalAidDetails.medicalAidName} />
                    </Grid>
                    {medicalAidDetails.medicalAidName === 'Other' && (
                        <Grid item xs={4} lg={3}>
                            <LabeledValue
                                label="Medical Aid Description"
                                value={medicalAidDetails.medicalAidDescription}
                            />
                        </Grid>
                    )}
                    <Grid item xs={4} lg={3}>
                        <LabeledValue label="Number" value={medicalAidDetails.medicalAidNumber} />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="Date Joined"
                            value={getDisplayDate(medicalAidDetails.dateJoined)}
                        />
                    </Grid>
                    {medicalAidDetails.isLifeAssuredTheMainMember === 'No' && (
                        <React.Fragment>
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Main member's Tittle"
                                    value={getCodeLabel(Titles, medicalAidDetails.mainMembersTitle)}
                                />
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Main member's name"
                                    value={medicalAidDetails.mainMembersFirstName}
                                />
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Main member's surname"
                                    value={medicalAidDetails.mainMembersLastName}
                                />
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Main member's ID number"
                                    value={medicalAidDetails.mainMembersIdNumber}
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

const mapStateToProps = (state: State) => {
    let dateOfDeath = undefined;
    const claimDetails = getRADeathClaim(state).claimDetails;
    dateOfDeath =
        claimDetails &&
        claimDetails.claimEventDetails &&
        claimDetails.claimEventDetails.dateOfEvent;

    return {
        lifeAssuredDetails: getRADeathLifeAssured(state),
        dateOfDeath: dateOfDeath,
        isReadOnly: state.claimSession.readOnly,
    };
};

const mapDispatchToProps = {
    openModal: openModalAction,
};

export const PersonalDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(personalDetailsView));
