import { claimSubmit } from '../../../../../claim';
import { reduceAndSubmitClaim } from '../../../../../claim/actions/reduce-and-submit-claim';
import {
    AddRecordCollectionAction,
    DeleteRecordCollectionAction,
    UpdateRecordCollectionAction,
} from '../../../../../redux/collection-reducer';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { Nullable } from '../../../../../shared-types';
import { Doctor, getDeathClaimLifeAssuredDetailsFromClaim } from '../reducer';
import { ADD_DOCTOR_RECORD, DELETE_DOCTOR_RECORD, UPDATE_DOCTOR_RECORD } from './types';

/* eslint-disable-next-line */
type SubmitReturn = Promise<any>;
export type UpdateConsultationDate = (firstConsultationDate: Nullable<Date>) => SubmitReturn;
type UpdateConsultationDateAction = (
    firstConsultationDate: Nullable<Date>
) => ReduxThunkAction<SubmitReturn>;

export const updateMedicalDetailsAction: UpdateConsultationDateAction = (
    firstConsultationDate: Nullable<Date>
) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, claim => {
            const currentlifeAssuredDetails = getDeathClaimLifeAssuredDetailsFromClaim(claim);
            return {
                ...claim,
                lifeAssuredDetails: {
                    ...currentlifeAssuredDetails,
                    medicalHistory: {
                        ...currentlifeAssuredDetails.medicalHistory,
                        firstConsultationDate: firstConsultationDate,
                    },
                },
            };
        });
    };
};

export type AddDoctorRecordAction = AddRecordCollectionAction<Doctor>;
export const addDoctorRecordActionCreator = (doctor: Doctor): AddDoctorRecordAction => {
    return {
        type: ADD_DOCTOR_RECORD,
        record: doctor,
    };
};
export type AddAndSubmitDoctorRecord = (doctor: Doctor) => SubmitReturn;
type AddAndSubmitDoctorRecordThunk = (doctor: Doctor) => ReduxThunkAction<SubmitReturn>;
export const addAndSubmitDoctorRecordThunk: AddAndSubmitDoctorRecordThunk = (doctor: Doctor) => (
    dispatch,
    getState
) => reduceAndSubmitClaim(dispatch, getState, addDoctorRecordActionCreator(doctor));

export type UpdateDoctorRecordAction = UpdateRecordCollectionAction<Doctor>;
export const updateDoctorRecordActionCreator = (
    doctorIndex: number,
    doctor: Doctor
): UpdateDoctorRecordAction => {
    return {
        type: UPDATE_DOCTOR_RECORD,
        record: doctor,
        index: doctorIndex,
    };
};

export type UpdateAndSubmitDoctorRecord = (doctorIndex: number, doctor: Doctor) => SubmitReturn;
type UpdateAndSubmitDoctorRecordThunk = (
    doctorIndex: number,
    doctor: Doctor
) => ReduxThunkAction<SubmitReturn>;
export const updateAndSubmitDoctorRecordThunk: UpdateAndSubmitDoctorRecordThunk = (
    doctorIndex: number,
    doctor: Doctor
) => (dispatch, getState) =>
    reduceAndSubmitClaim(dispatch, getState, updateDoctorRecordActionCreator(doctorIndex, doctor));

export type DeleteDoctorRecordAction = DeleteRecordCollectionAction;
export const deleteDoctorRecordActionCreator = (doctorIndex: number): DeleteDoctorRecordAction => {
    return {
        type: DELETE_DOCTOR_RECORD,
        index: doctorIndex,
    };
};
export type DeleteAndSubmitDoctorRecord = (doctorIndex: number) => SubmitReturn;
type DeleteAndSubmitDoctorRecordThunk = (doctorIndex: number) => ReduxThunkAction<SubmitReturn>;
export const deleteAndSubmitDoctorRecordThunk: DeleteAndSubmitDoctorRecordThunk = (
    doctorIndex: number
) => (dispatch, getState) =>
    reduceAndSubmitClaim(dispatch, getState, deleteDoctorRecordActionCreator(doctorIndex));
