import React from 'react'; // eslint-disable-line
import { getFormattedDate } from '../../../../../../shared-functions';
import { Typography, withStyles } from '@material-ui/core';
import { State } from '../../../../../../redux/root-reducer';
import { connect } from 'react-redux';
import styles from '../../../../../../styles/styles';
import { AuthState } from '../../../../../../auth/auth-reducer';

const resultUnderInvestigationContent = ({ modal, currentUser }: any) => {
    const creationDate = sessionStorage.getItem('creationDate') || '';

    const date = getFormattedDate(creationDate);

    const claimantName = `${
        modal.form?.communicationsTitle ? modal.form?.communicationsTitle.toLowerCase() : ''
    } ${modal.form?.claimantSurname ? modal.form?.claimantSurname : ''}`;

    return (
        <>
            <Typography variant="body1" style={{ marginBottom: 10, whiteSpace: 'pre-line' }}>
                {`Dear ${claimantName}`} <br />
                <br />
                Policy number:{' '}
                {`${
                    modal?.form?.communicationsPolicy ? modal?.form?.communicationsPolicy : ''
                }`}{' '}
                <br /> <br /> Thank you for submitting you request on {`${date}`}. <br /> <br />
            </Typography>
            <Typography>
                {`Please note that your claim request has gone for further assessment and an assesor
                  will be in contact with you regarding the assessment. During this time please expect
                  a delay in the claim process.
                  .`}
                <br /> <br />
            </Typography>{' '}
            <Typography>
                For further information please mail us at info@liberty.co.za or Contact us: 0860 456
                789/ +27(0)11 558 4871.
            </Typography>
        </>
    );
};

const mapStateToProps = (state: State) => ({
    modal: state.confirmationDialog,
    currentUser: state.auth as AuthState,
});

export const ResultUnderInvestigationContent = connect(
    mapStateToProps,
    {}
)(withStyles(styles)(resultUnderInvestigationContent));
