import React from 'react'; // eslint-disable-line
import { ModalViewProps, MultiEditFormModal } from '../../../../../form-modal';
import { AddressDetailsEdit } from './address-details-edit';
import { ContactDetailsEdit } from './contact-details-edit';
import { PersonalDetailsEdit } from './personal-details-edit';
import { MedicalAidDetailsEdit } from './medical-aid-details-edit';
import { MedicalInformationDetailsEdit } from './medical-information-edit';
import { DoctorPracticeDetailsEdit } from './doctor-practice-details-edit';
import {
    ContactDetails,
    FormDetails,
    PersonalDetails,
    AddressDetails,
    MedicalInformaion,
    MedicalAidDetails,
} from '../form-details-reducer';
import { OpenModal } from '../../../../../form-modal/actions';
import { OtherDetailsEdit } from './other-details-edit';

interface ModalProps {
    personalDetails: PersonalDetails;
    addressDetails: AddressDetails;
    contactDetails: ContactDetails;
    medicalInformation: MedicalInformaion;
    medicalAidDetails: MedicalAidDetails;
}

export const EditModalForm = MultiEditFormModal<ModalProps>();
export const EditModalViews: ModalViewProps[] = [
    {
        name: 'Primary Details',
        view: (
            <PersonalDetailsEdit
                type="LifeAssured"
                prefix="lifeAssured.personalDetails"
                policyHolderPrefix="lifeAssured.personalDetails.Title"
            />
        ),
    },
    {
        name: 'Address Details',
        view: <AddressDetailsEdit prefix="lifeAssured.addressDetails" type="LifeAssured" />,
    },
    {
        name: 'Contact Details',
        view: <ContactDetailsEdit type="LifeAssured" prefix="lifeAssured.contactDetails" />,
    },
    {
        name: 'Medical Information',
        view: <MedicalInformationDetailsEdit prefix="lifeAssured.medicalInformation" />,
    },
    {
        name: 'Doctor Details',
        view: <DoctorPracticeDetailsEdit prefix="lifeAssured.medicalInformation" />,
    },
    {
        name: 'Medical Aid Details',
        view: <MedicalAidDetailsEdit prefix="lifeAssured.medicalAidDetails" />,
    },
    {
        name: 'Other Details',
        view: <OtherDetailsEdit prefix="lifeAssured.otherDetails" />,
    },
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const editLifeAssureDetails = (
    openModal: OpenModal,
    formDetails: FormDetails,
    screenIndex: number,
    updateFormDetails: any
) => {
    const addressDetails = formDetails.lifeAssured?.addressDetails;
    const newAddressDetails = {
        ...addressDetails,
        sameAsResidential: addressDetails?.sameAsResidentialAddress,
        addressType: 'Street',
    };

    const updatedFormDetails = {
        ...formDetails,
        lifeAssured: {
            ...formDetails.lifeAssured,
            addressDetails: newAddressDetails,
        },
    };

    openModal({
        modalContent: (
            <EditModalForm
                heading="Update Form Details | Life Assured"
                modalViews={EditModalViews}
                saveAction={updateFormDetails}
            />
        ),
        modalData: {
            screenIndex: screenIndex,
            initialValues: updatedFormDetails,
        },
    });
};
