import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { Field } from 'react-final-form';
import {
    Condition,
    FullWidthSearchSelectField,
    FullWidthTextField,
    True,
    WhenFieldChanges,
} from '../../../../../../forms';
import { TelephoneNumberMask } from '../../../../../../forms/input-masks';
import { AddressDetailsSubForm } from '../../../../../../forms/sub-forms/address-details';
import { requiredValidator } from '../../../../../../forms/validations';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { getLabels } from './deceased-occupation-view';

const occupationDetails = ({ classes }: StyledComponent) => {
    const prefix = 'deceasedDetails.occupationDetails.';
    return (
        <Grid container spacing={5} justify="flex-start">
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    name={`${prefix}status`}
                    label="Occupation Status"
                    options={[
                        { value: 'Employed', label: 'Employed' },
                        { value: 'Scholar', label: 'Scholar' },
                        { value: 'Student', label: 'Student' },
                        { value: 'Unemployed', label: 'Unemployed' },
                    ]}
                />
                <WhenFieldChanges
                    field={`${prefix}status`}
                    becomes="Unemployed"
                    set="deceasedDetails.occupationDetails"
                    to={(status: any) => ({ status })}
                />
            </Grid>
            <Condition when={`${prefix}status`} is={['Employed', 'Scholar', 'Student']}>
                <True>
                    <Field
                        name={`${prefix}status`}
                        subscription={{ value: true }}
                        component={({ input: { value: status } }) => {
                            const {
                                institution,
                                studentNumber,
                                contactPerson,
                                contactNumber,
                                address,
                            } = getLabels(status);
                            return (
                                <React.Fragment>
                                    <Grid item xs={6}>
                                        <FullWidthTextField
                                            name={`${prefix}institution`}
                                            label={institution}
                                            validate={requiredValidator}
                                        />
                                    </Grid>
                                    <Condition when={`${prefix}status`} is="Employed">
                                        <True>
                                            <Grid item xs={6}>
                                                <FullWidthTextField
                                                    name={`${prefix}occupation`}
                                                    validate={requiredValidator}
                                                />
                                            </Grid>
                                        </True>
                                    </Condition>
                                    <Condition when={`${prefix}status`} is={['Student', 'Scholar']}>
                                        <True>
                                            <Grid item xs={6}>
                                                <FullWidthTextField
                                                    name={`${prefix}studentNumber`}
                                                    label={studentNumber}
                                                />
                                            </Grid>
                                        </True>
                                    </Condition>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="subtitle2"
                                            className={classes.marginTop}
                                        >
                                            Contact Details
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FullWidthTextField
                                            name={`${prefix}contactPerson`}
                                            label={contactPerson}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FullWidthTextField
                                            name={`${prefix}telephoneNumber`}
                                            label={contactNumber}
                                            mask={TelephoneNumberMask}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="subtitle2"
                                            className={classes.marginTop}
                                        >
                                            {address}
                                        </Typography>
                                    </Grid>
                                    <AddressDetailsSubForm
                                        addressPropertyName={`${prefix}addressDetails`}
                                        addressType="street"
                                    />
                                </React.Fragment>
                            );
                        }}
                    />
                </True>
            </Condition>
        </Grid>
    );
};

export const OccupationDetailsEdit = withStyles(styles)(occupationDetails);
