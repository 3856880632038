import React from 'react'; // eslint-disable-line
import { Synopsis } from '../common/synopsis/synopsis';
import { DeathPolicySummaryView } from '../death/policy-details';
import { LifeAssuredDetailsView } from './life-assured/views/life-assured-details';
import { RaClaimEventDetailsView } from './claim-details/ra-claim-event-details-view';
import { DisputesView } from './disputes/views/disputes-view';
import { PossibleDependantsView } from './dependants/views/dependants';
import { LiquidationAndDistributionView } from './liquid-and-distribution/views/liquidation-and-distribution';
import { ManageRequirementsView } from './manage-requirements/views/manage-requirements-view';
import { DependencySummaryView } from './dependency-summary/dependency-summary';
import { RaDeathWrapper } from '../../../forms';
import { GenerateLetter } from './generate-decision-letter';

export const RADeathSynopsis = () => {
    return (
        <Synopsis>
            <ManageRequirementsView />
            <LifeAssuredDetailsView />
            <RaDeathWrapper>
                <DeathPolicySummaryView />
            </RaDeathWrapper>
            <RaClaimEventDetailsView />
            <PossibleDependantsView />
            <RaDeathWrapper>
                <DependencySummaryView />
                <LiquidationAndDistributionView />
                <DisputesView />
            </RaDeathWrapper>
            <GenerateLetter />
        </Synopsis>
    );
};
