import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import cashBlue from '../../../../master-layout/views/cash.svg';
import cashGrey from '../../../../master-layout/views/cash-grey.svg';
import { formatCurrency } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import { PayLiabilities, payLiabilitiesAction } from '../../../actions/pay-liability';
import { PaymentItem } from '../../../reducer';
import { PaymentItemView } from './payment-item-view';
import { ButtonWrapper } from '../../../../forms/form-components/button-wrapper/index';

interface Props extends StyledComponent {
    beneficiaryId: string;
    payLiabilities: PayLiabilities;
    paymentIndex: number;
    paymentItems: PaymentItem[];
}
const paymentItems: React.FunctionComponent<Props> = ({
    beneficiaryId,
    classes,
    payLiabilities,
    paymentIndex,
    paymentItems,
}: Props) => {
    if (!paymentItems || paymentItems.length === 0) {
        return null;
    }
    let totalPayable = 0;
    const pay = () => {
        payLiabilities(beneficiaryId);
    };

    let pending = false;
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography variant="h6" className={classes.title}>
                    Payment Details
                </Typography>
            </Grid>
            {paymentItems.map((paymentItem, itemIndex) => {
                totalPayable += paymentItem.amountPayable;
                pending = ['Pending', 'Failed', 'Rejected'].includes(paymentItem.status);
                return (
                    <PaymentItemView
                        itemIndex={itemIndex}
                        paymentIndex={paymentIndex}
                        paymentItem={paymentItem}
                        key={`item_${paymentItem.id}${itemIndex}`}
                    />
                );
            })}
            <Grid container justify="flex-end">
                <Grid item xs={3}>
                    <Typography className={classes.totalPayableLabel}>Total payable</Typography>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography className={classes.totalPayableValue}>
                                {formatCurrency(totalPayable)}
                            </Typography>
                        </Grid>
                        <ButtonWrapper>
                            <Grid item xs={4}>
                                <Tooltip title="Submit All Pending Payments">
                                    <Button
                                        id="SubmitPayment"
                                        color="secondary"
                                        className={classes.paymentButton}
                                        disabled={!pending}
                                        onClick={pay}
                                    >
                                        <img alt="submit" src={pending ? cashBlue : cashGrey} />
                                        <Typography className={classes.paymentText}>Pay</Typography>
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </ButtonWrapper>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const mapDispatchToProps = { payLiabilities: payLiabilitiesAction };

export const FuneralPaymentItems = connect(
    () => ({}),
    mapDispatchToProps
)(withStyles(styles)(paymentItems));
