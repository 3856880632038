export const MajorClaimCause = [
    { value: 'Accidental', label: 'Accidental' },
    { value: 'Activities of daily living', label: 'Activities of daily living' },
    { value: 'Auto-immune disorders', label: 'Auto-immune disorders' },
    { value: 'Blood disorders', label: 'Blood disorders' },
    { value: 'Cancer', label: 'Cancer' },
    { value: 'Cardiovascular disorders', label: 'Cardiovascular disorders' },
    { value: 'Cerebrovascular disorders', label: 'Cerebrovascular disorders' },
    { value: 'Central nervous system disorders', label: 'Central nervous system disorders' },
    {
        value: 'HIV/AIDS',
        label: 'HIV/AIDS',
    },
    { value: 'Ear, nose and throat disorders', label: 'Ear, nose and throat disorders' },
    { value: 'Endocrine disorders', label: 'Endocrine disorders' },
    { value: 'Gastrointestinal disorders', label: 'Gastrointestinal disorders' },
    { value: 'Genitourinary disorders', label: 'Genitourinary disorders' },
    { value: 'Major burns', label: 'Major burns' },
    { value: 'Mental disorders', label: 'Mental disorders' },
    { value: 'Musculo-skeletal disorders', label: 'Musculo-skeletal disorders' },
    {
        value: 'Respiratory disorders',
        label: 'Respiratory disorders',
    },
    { value: 'Skin disorders', label: 'Skin disorders' },
    { value: 'The visual system', label: 'The visual system' },
    { value: 'Trauma', label: 'Trauma' },
    { value: 'Renal disorders', label: 'Renal disorders' },
];
