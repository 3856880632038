import { Grid, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { BankingDetailsView } from '../../../assessment/synopsis/common/banking-details/views/edit-banking-details';
import { AuthState } from '../../../auth/auth-reducer';
import { getClaim } from '../../../claim';
import { InternalAccounts } from '../../../codes/internal-accounts';
import { PayeeTypesDreadDisability } from '../../../codes/payee-types';
import { PaymentTypesDreadDisability } from '../../../codes/payment-types';
import {
    Condition,
    FormHeading,
    FullWidthSearchSelectField,
    FullWidthTextField,
    True,
    When,
    WhenFieldChanges,
} from '../../../forms';
import { requiredValidator } from '../../../forms/validations';
import { State } from '../../../redux/root-reducer';
import { BankingDetailsDisplay } from '../../../shared/banking-details-display';
import styles, { StyledComponent } from '../../../styles/styles';
import {
    BeneficiaryDetails,
    ClaimDecision,
    getBeneficiaryEditName,
    getDecisions,
    getInternalAccount,
    getPaymentBeneficiaries,
} from '../../reducer';

type PaymentProp = StyledComponent & {
    beneficiaries: BeneficiaryDetails[];
    beneficiaryId: string;
    currentUser: AuthState;
    decisions: ClaimDecision[];
    edit?: boolean;
};

const paymentIncomeItemView: React.FunctionComponent<PaymentProp> = ({
    beneficiaries,
    beneficiaryId,
    currentUser,
    decisions,
    edit,
}: PaymentProp) => {
    const beneficiary = beneficiaries.find(b => b.beneficiaryId === beneficiaryId);
    if (!beneficiary) {
        return null;
    }

    return (
        <React.Fragment>
            <FormHeading
                text={`${edit ? 'Edit' : 'Add'} Payment - ${getBeneficiaryEditName(beneficiary)}`}
            />
            <WhenFieldChanges
                field="decisionId"
                set=""
                to={(decisionId: string) => {
                    const decision = decisions.find(d => d.decisionId === decisionId);
                    return {
                        ...decision,
                        assessor: currentUser.name,
                        split: 100,
                    };
                }}
            />
            <Grid container spacing={5} style={{ marginBottom: '120px' }}>
                <Grid item xs={6} lg={6}>
                    <FullWidthSearchSelectField
                        name="decisionId"
                        label="Policy"
                        validate={requiredValidator}
                        options={filterDecisions(decisions, beneficiary.policyNumber)}
                        disabled={edit}
                    />
                </Grid>

                <Grid item xs={6} lg={6}>
                    <FullWidthTextField
                        name="assessor"
                        label="Processed By"
                        defaultValue={currentUser.name}
                        disabled
                    />
                </Grid>
                <Grid item xs={6} lg={6}>
                    <FullWidthSearchSelectField
                        name="paymentType"
                        validate={requiredValidator}
                        options={PaymentTypesDreadDisability}
                    />
                    <WhenFieldChanges field="paymentType" set="payeeType" to={null} />
                </Grid>
                <WhenFieldChanges field="payeeType" set="bankingDetails" to={null} />
                <WhenFieldChanges field="payeeType" set="internalAccountId" to={null} />
                <WhenFieldChanges
                    field="payeeType"
                    set="bankingDetails.bankAccountStatus"
                    becomes="ExternalTransfer"
                    to="Not verified"
                />
                <WhenFieldChanges
                    field="paymentType"
                    becomes="Refund"
                    set="payeeType"
                    to="Beneficiary"
                />
                <Field
                    name="paymentType"
                    subscription={{ value: true }}
                    component={({ input: { value: paymentType } }) => {
                        const options = PayeeTypesDreadDisability.filter(x =>
                            x.payments.find(p => p === paymentType)
                        );
                        return (
                            <Grid item xs={6} lg={6}>
                                <FullWidthSearchSelectField
                                    name="payeeType"
                                    validate={requiredValidator}
                                    options={options}
                                />
                            </Grid>
                        );
                    }}
                />
                <Condition when="payeeType" is="InternalTransfer">
                    <True>
                        <Grid item xs={6} lg={6}>
                            <FullWidthSearchSelectField
                                label="Account"
                                name="internalAccountId"
                                validate={requiredValidator}
                                options={InternalAccounts}
                                sortOptions
                            />
                        </Grid>
                    </True>
                </Condition>
                <Grid item xs={6} lg={6}>
                    <FullWidthTextField name="split" validate={requiredValidator} disabled />
                </Grid>

                <Condition when="payeeType" is={['IT88', 'PAYE']}>
                    <True>
                        <Grid item xs={6} lg={6}>
                            <FullWidthTextField name="taxDirectiveNumber" />
                        </Grid>
                    </True>
                </Condition>
                <Condition when="payeeType" matchesChild>
                    <When is="ExternalTransfer">
                        <BankingDetailsView propertyPrefix="bankingDetails" isAnOrganisation />
                    </When>
                    <When is="Beneficiary">
                        <BankingDetailsDisplay bankingDetails={beneficiary.bankingDetails} />
                    </When>
                </Condition>
                <Condition when="internalAccountId" hasValue>
                    <True>
                        <Field
                            name="internalAccountId"
                            subscription={{ value: true }}
                            component={({ input: { value: internalAccountId } }) => (
                                <BankingDetailsDisplay
                                    bankingDetails={getInternalAccount(internalAccountId)}
                                />
                            )}
                        />
                    </True>
                </Condition>
            </Grid>
        </React.Fragment>
    );
};

function filterDecisions(decisions: ClaimDecision[], policyNumber: string) {
    const filteredDecisions: any = [];
    decisions.forEach(d => {
        if (d.policyNumber === policyNumber || policyNumber === 'All') {
            filteredDecisions.push({
                value: d.decisionId,
                label: d.decisionLabel,
            });
        }
    });
    return filteredDecisions;
}

const mapStateToProps = (state: State) => {
    const claim = getClaim(state);
    return {
        beneficiaries: getPaymentBeneficiaries(claim),
        currentUser: state.auth as AuthState,
        decisions: getDecisions(claim),
    };
};

const mapDispatchToProps = {};

export const PaymentIncomeItemView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(paymentIncomeItemView));
