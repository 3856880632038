/* eslint-disable react/prop-types */
import { withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { ExpansionPanel } from '../../../../../../forms/form-components/expansion-panel/expansion-panel';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { PersonalDetailsView } from '../../details/personal-detail';
import { AddressDetailsView } from '../../details/address-details';
import { ContactDetailsView } from '../../details/contact-details';
import { EmploymentDetailsView } from '../../details/employment-details';
import { FormDetails } from '../../form-details-reducer';

interface BaseProps {
    formDetails?: FormDetails;
    disableEditButton?: boolean;
}

interface Props extends StyledComponent, BaseProps {}

const policyHolderDetailsView: React.FunctionComponent<Props> = ({
    formDetails,
    disableEditButton,
}) => {
    return (
        <ExpansionPanel name="Policyholder details" style={{ marginLeft: -24, marginRight: -24 }}>
            <React.Fragment>
                <PersonalDetailsView
                    type="PolicyHolder"
                    formDetails={formDetails}
                    disableEditButton={disableEditButton}
                />
                <AddressDetailsView
                    type="PolicyHolder"
                    formDetails={formDetails}
                    disableEditButton={disableEditButton}
                />
                <ContactDetailsView
                    type="PolicyHolder"
                    formDetails={formDetails}
                    disableEditButton={disableEditButton}
                />
                <EmploymentDetailsView
                    formDetails={formDetails}
                    disableEditButton={disableEditButton}
                />
            </React.Fragment>
        </ExpansionPanel>
    );
};

export const PolicyHolderDetailsView = withStyles(styles)(policyHolderDetailsView);
