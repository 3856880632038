import { AsyncValidator, UserInput, Validator } from './';

export function conditionalValidator(
    validate: Validator,
    condition: () => boolean
): AsyncValidator {
    return async function condtionallyValidate(value: UserInput) {
        if (!condition()) {
            return undefined;
        }

        return await validate(value);
    };
}
