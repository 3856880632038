import { ReduxThunkAction } from '../../redux/redux-actions';
import { claimSubmit, Claim } from '../../claim';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SubmitReturn = Promise<any>;
export type DeletePaymentItem = (paymentItemIndex: number, claimantIndex: number) => SubmitReturn;
type DeletePaymentItemRecordThunk = (
    paymentItemIndex: number,
    claimantIndex: number
) => ReduxThunkAction;

export const deletePaymentItemRecordThunk: DeletePaymentItemRecordThunk = (
    paymentItemIndex: number,
    claimantIndex: number
) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, deletePayment(paymentItemIndex, claimantIndex));
    };
};

function deletePayment(paymentItemIndex: number, claimantIndex: number): (claim: Claim) => Claim {
    return (claim: Claim) => {
        const currentPaymentItem = claim.payments[claimantIndex].paymentItems;
        if (currentPaymentItem !== undefined) {
            currentPaymentItem.splice(paymentItemIndex, 1);
        }
        return claim;
    };
}
