export const InternalAccounts = [
    {
        label: 'Evolve',
        value: 'Evolve',
    },
    {
        label: 'Bold',
        value: 'Bold',
    },
    {
        label: 'Gateway',
        value: 'Gateway',
    },
    {
        label: 'Linked life',
        value: 'Linked life',
    },

    {
        label: 'LPB Product',
        value: 'LPB Product',
    },
];
