import { Claim, claimSubmit } from '../../claim';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { getLifeAssuredDetailsFromClaim, LifeAssuredContract } from '../reducer';

type UpdateReferToReinsuranceReturn = Promise<any>;
export type UpdateReferToReinsurance = (
    contracts: LifeAssuredContract[]
) => UpdateReferToReinsuranceReturn;
type UpdateReferToReinsuranceAction = (
    contracts: LifeAssuredContract[]
) => ReduxThunkAction<UpdateReferToReinsuranceReturn>;

export const updateReferToReinsuranceAction: UpdateReferToReinsuranceAction = (
    contracts: LifeAssuredContract[]
) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, getUpdatedReferToReinsurance(contracts));
    };
};

function getUpdatedReferToReinsurance(contracts: LifeAssuredContract[]): (claim: Claim) => Claim {
    return (claim: Claim) => {
        const currentLifeAssuredDetails = getLifeAssuredDetailsFromClaim(claim);
        return {
            ...claim,
            lifeAssuredDetails: {
                ...currentLifeAssuredDetails,
                contracts,
            },
        };
    };
}
