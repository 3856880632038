import { reduceAndSubmitClaim } from '../../../../../claim/actions/reduce-and-submit-claim';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { addClaimantRecordActionCreator } from './add-details';
import { deleteClaimantRecordActionCreator } from './delete-details';
import { updateClaimantRecordActionCreator } from './update-details';
import { Claimant } from '../../contact-details/contact-details-reducer';

/* eslint-disable-next-line */
type SubmitReturn = Promise<any>;

export type AddAndSubmitClaimantRecord = (claimant: Claimant) => SubmitReturn;
type AddAndSubmitClaimantRecordThunk = (claimant: Claimant) => ReduxThunkAction<SubmitReturn>;
export const addAndSubmitClaimantRecordThunk: AddAndSubmitClaimantRecordThunk = (
    claimant: Claimant
) => (dispatch, getState) =>
    reduceAndSubmitClaim(dispatch, getState, addClaimantRecordActionCreator(claimant));

export type UpdateAndSubmitClaimantRecord = (
    claimantRecordIndex: number,
    claimantRecord: Claimant
) => SubmitReturn;
type UpdateAndSubmitClaimantRecordThunk = (
    claimantRecordIndex: number,
    claimantRecord: Claimant
) => ReduxThunkAction<SubmitReturn>;
export const updateAndSubmitClaimantRecordThunk: UpdateAndSubmitClaimantRecordThunk = (
    claimantRecordIndex: number,
    claimantRecord: Claimant
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        updateClaimantRecordActionCreator(claimantRecordIndex, claimantRecord)
    );

export type DeleteAndSubmitClaimantRecord = (claimantRecordIndex: number) => SubmitReturn;
type DeleteAndSubmitClaimantRecordThunk = (
    ClaimantRecordIndex: number
) => ReduxThunkAction<SubmitReturn>;
export const deleteAndSubmitClaimantRecordThunk: DeleteAndSubmitClaimantRecordThunk = (
    claimantRecordIndex: number
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        deleteClaimantRecordActionCreator(claimantRecordIndex)
    );
