import { ReduxAction } from '../../redux/redux-actions';
import { Workflow } from '../reducers/workflow';
import { SET_ACTIVE_WORKFLOW } from '../types';

export type SetActiveWorkflowAction = ReduxAction<Workflow>;
export type SetActiveWorkflow = (workflow: Workflow) => SetActiveWorkflowAction;
export const setActiveWorkflowAction: SetActiveWorkflow = (workflow: Workflow) => ({
    type: SET_ACTIVE_WORKFLOW,
    payload: workflow,
});
