import { withStyles, Grid } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import styles, { StyledComponent } from '../../../../../styles/styles';
import { FormDetails, getFormDetails } from '../form-details-reducer';
import { connect } from 'react-redux';
import { State } from '../../../../../redux/root-reducer';
import {
    FullWidthTextField,
    FullWidthDatePicker,
    FullWidthRadioGroup,
    RadioOption,
} from '../../../../../forms';

interface BaseProps {
    formDetails: FormDetails;
    prefix: string;
}

interface Props extends BaseProps, StyledComponent {}
const medicalInformationDetailsEdit: React.FunctionComponent<Props> = ({
    classes,
    formDetails,
    prefix,
}: Props) => {
    return (
        <Grid container spacing={5} justify="flex-start">
            <Grid item xs={6} lg={6}>
                <FullWidthTextField
                    label="Medical reason for claim being submitted"
                    name={`${prefix}.lifeAssuredMedicalReason`}
                />
            </Grid>
            <Grid item xs={6} lg={6}>
                <FullWidthDatePicker
                    label="Date of diagnosis or date that the event took place"
                    name={`${prefix}.lifeAssuredDateOfDiagnosis`}
                />
            </Grid>
            <Grid item xs={6} lg={6}>
                <FullWidthRadioGroup
                    label="Currently taking any medication or undergoing treatment"
                    name={`${prefix}.takingMedicationOrTreatment`}
                >
                    <RadioOption value="Yes" label="Yes" />
                    <RadioOption value="No" label="No" />
                </FullWidthRadioGroup>
            </Grid>
            <Grid item xs={6} lg={6}>
                <FullWidthRadioGroup
                    name={`${prefix}.lifeAssuredMedicalCondition`}
                    label="Medical condition was acquired due to"
                >
                    <RadioOption value="Accident" label="Accident" />
                    <RadioOption value="Disease" label="Disease" />
                </FullWidthRadioGroup>
            </Grid>
            <Grid item xs={12} lg={12}>
                <FullWidthTextField
                    label="More details"
                    name={`${prefix}.lifeAssuredMoreInformation`}
                />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state: State) => ({
    formDetails: getFormDetails(state),
});

const mapDispatchToProps = {};

export const MedicalInformationDetailsEdit = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(medicalInformationDetailsEdit));
