import { Theme } from '@material-ui/core';
import { createStyles, StyleRules } from '@material-ui/core/styles';
import { CSSProperties, WithStyles } from '@material-ui/core/styles/withStyles';

export type StyledComponent = WithStyles<typeof styles>;

export default function styles(theme: Theme): StyleRules {
    return createStyles({
        wrapper: wrapper(theme),
        label: label(theme),
        value: value(theme),
    });
}

function wrapper(theme: Theme): CSSProperties {
    return {
        ...marginTop(theme, 0.75),
        ...marginBottom(theme, 1),
    };
}

function label(theme: Theme): CSSProperties {
    return { ...marginBottom(theme, 0.75), wordBreak: 'break-word' };
}
function value(theme: Theme): CSSProperties {
    return {
        ...marginBottom(theme, 0),
        '&:last-child': marginBottom(theme, 0.75),
        wordBreak: 'break-word',
    };
}

function marginTop(theme: Theme, scale: number) {
    return {
        marginTop: theme.spacing(scale),
    };
}
function marginBottom(theme: Theme, scale: number) {
    return {
        marginBottom: theme.spacing(scale),
    };
}
