import React from 'react'; // eslint-disable-line

import { IncomeAssessmentDecisionFields } from '../../income-disability/views/income-assessment-decision-fields';
import { Grid } from '@material-ui/core';
interface Props {
    sumAssured?: number;
}

export const RetrenchmentAssessmentDecisionFields: React.FunctionComponent<Props> = ({
    sumAssured,
}: Props) => {
    return (
        <React.Fragment>
            <Grid item xs={4} />
            <IncomeAssessmentDecisionFields sumAssured={sumAssured} />
        </React.Fragment>
    );
};
