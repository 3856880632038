import { BeneficiaryDetails } from '../../reducer';

export const beneficiaryScreeningNotResolved = (
    beneficiaryDetails: BeneficiaryDetails
): boolean => {
    if (beneficiaryDetails.riskStatus) return false;

    if (beneficiaryDetails.beneficiaryManualScreening?.isBeneficiaryManualScreening) return false;

    if (beneficiaryDetails.screeningError) return true;

    return true;
};
