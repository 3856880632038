import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { getRADeathClaim } from '../../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { ExpansionPanel } from '../../../../../forms/form-components/expansion-panel/expansion-panel';
import { State } from '../../../../../redux/root-reducer';
import { getObjectWarning } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { LiquidationAndDistributionDetails } from './liquidation-and-distribution-details';

type Props = StyledComponent & {
    validationErrors: string;
};

const liquidationAndDistributionView: React.FunctionComponent<Props> = ({
    validationErrors,
}: Props) => {
    let notifier;
    if (validationErrors && validationErrors.includes('liquidAndDistribution')) {
        notifier = getObjectWarning(validationErrors, validationErrors);
    }
    return (
        <ExpansionPanel name="Liquidation and Distribution" notifier={notifier}>
            <LiquidationAndDistributionDetails />
        </ExpansionPanel>
    );
};

const mapStateToProps = (state: State) => ({
    validationErrors: getRADeathClaim(state).validationErrors,
});

const mapDispatchToProps = {};

export const LiquidationAndDistributionView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(liquidationAndDistributionView));
