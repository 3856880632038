import convertRegexToJsonSchemaString from './convert-regex-to-json-schema-string';

export const alpha = getRegexStringFn(/[a-zA-Z]/);
export const alphaAndSpace = getRegexStringFn(/[a-zA-Z ]/);
export const alphaAndDot = getRegexStringFn(/[a-zA-Z.]/);
export const telephoneNumber = getRegexStringFn(/[0-9+]/);
export const numeric = getRegexStringFn(/[0-9]/);
export const alphaNumeric = getRegexStringFn(/[0-9a-zA-Z]/);
export const alphaNumericAndSpace = getRegexStringFn(/[0-9a-zA-Z ]/);

interface Options {
    nullable: boolean;
}
type RegexStringFunction = (options?: Options) => string;

function getRegexStringFn(regex: RegExp): RegexStringFunction {
    return function getRegexString(options: Options = { nullable: false }): string {
        const stringRegex = convertRegexToJsonSchemaString(regex);
        if (options.nullable) {
            return `^${stringRegex}*$`;
        }
        return `^${stringRegex}+$`;
    };
}

// RK: maybe a function with options? Like:
/*
{
    upperCaseLetters: true,
    lowerCaseLetters: true,
    numbers: true,
    whitespace: none | space | all,
    symbols: all | specified
    specifiedSymbols: [],
    size: oneOrMore | zeroOrMore| specified
    minChars: 1
    maxChars: 3
}

symbolMap:{
 '.' : '\.'
}

discuss with team
*/
