import { combineReducers } from 'redux';

import { connectRouter, RouterState } from 'connected-react-router';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { authReducer, AuthState } from '../auth/auth-reducer';
import { ClaimSession, claimSessionReducer, initialClaimSession } from '../claim';
import {
    confirmationDialogReducer,
    DialogDetails,
    initialDialogDetails,
} from '../confirmation-dialog';
import { formModalReducer, initialModalState, ModalState } from '../form-modal/reducer';
import {
    initialLoadingScreenState,
    loadingScreenReducer,
    LoadingScreenState,
} from '../forms/loading-screen/loading-screen-reducer';
import history from '../history';
import { InitiateClaim, initiateClaimReducer } from '../initiate-claim';
import masterPageReducer, { MasterPageState } from '../master-layout/reducer';
import {
    assessorLimitsReducer,
    initialAssessorLimitsState,
} from '../assesor-dashboard/reducer/assessors-reducer';

import { Nullable } from '../shared-types';

export interface State {
    readonly assessorDashboard: any;
    readonly formModal: ModalState;
    readonly confirmationDialog: DialogDetails;
    readonly masterPage: MasterPageState;
    readonly initiateClaim: InitiateClaim;
    readonly claimSession: ClaimSession;
    readonly router?: RouterState;
    readonly auth: Nullable<AuthState>;
    readonly loadingScreenState: LoadingScreenState;
}

export const initialState: State = {
    formModal: initialModalState,
    confirmationDialog: initialDialogDetails,
    masterPage: { drawerOpen: true },
    initiateClaim: {},
    claimSession: initialClaimSession,
    auth: null,
    loadingScreenState: initialLoadingScreenState,
    assessorDashboard: initialAssessorLimitsState,
};

export type GetState = () => State;

const rootReducer = combineReducers({
    toastr: toastrReducer,
    masterPage: masterPageReducer,
    confirmationDialog: confirmationDialogReducer,
    formModal: formModalReducer,
    initiateClaim: initiateClaimReducer,
    claimSession: claimSessionReducer,
    router: connectRouter(history),
    auth: authReducer,
    loadingScreenState: loadingScreenReducer,
    assessorDashboard: assessorLimitsReducer,
});

export default rootReducer;
