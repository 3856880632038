import { FormHelperText } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { Field, FieldRenderProps } from 'react-final-form';
import Select from 'react-virtualized-select';
import { raspberry } from '../../../styles/colours';
import {
    callAllFunctions,
    FieldSet,
    getComponentId,
    nameToLabel,
    processMetaForErrors,
} from '../..';
import './style.css';

interface SelectOption {
    value: string;
    label: string;
}

type SelectProps = FieldRenderProps<any, any> & {
    id?: string;
    label?: string;
    disabled?: boolean;
    helperText?: React.ReactNode;
    options: SelectOption[];
    matchPos?: string;
    matchProp?: string;
    sortOptions?: boolean;
    hideLabel?: boolean;
    onChange?: (values?: any) => void;
};

function SearchSelectField(props: SelectProps) {
    const {
        input,
        meta,
        id,
        label,
        helperText,
        options,
        matchPos,
        matchProp = 'label',
        onChange,
        disabled,
        sortOptions = true,
        hideLabel,
        ...rest
    } = props;
    const { name, value } = input;
    const { errorMessage, showError } = processMetaForErrors(meta);
    const sortedOptions = sortOptions ? optionSort(options) : options;
    const labelDesc = nameToLabel({ name, label });
    const color = showError ? raspberry : 'rgba(0, 0, 0, 0.54)';
    const offsetLabel = value && value !== '' ? {} : { marginTop: '12px' };

    return (
        <React.Fragment>
            <label style={{ fontSize: '12px', lineHeight: '1', color }}>
                {value && value !== '' ? labelDesc : ' '}
            </label>
            <Select
                {...rest}
                style={offsetLabel}
                id={getComponentId({ id, name })}
                name={name}
                options={sortedOptions}
                matchPos={matchPos}
                matchProp={matchProp}
                value={transformFromValue(sortedOptions, value)}
                onChange={op => callAllFunctions(onChange, input.onChange)(transformToValue(op))}
                placeholder={<span style={{ color, marginTop: '9px' }}>{labelDesc}</span>}
                disabled={disabled}
            />
            {(showError || !!helperText) && (
                // @ts-ignore
                <FormHelperText error={showError} component="pre">
                    {showError ? errorMessage : helperText}
                </FormHelperText>
            )}
        </React.Fragment>
    );
}

function transformFromValue(options: SelectOption[], value: any): SelectOption {
    if (!options) {
        return options;
    }
    return options.filter(op => op.value === value)[0];
}

function transformToValue(option?: SelectOption | any): any {
    return (option || { value: undefined }).value;
}

function sortWithLastItemAs(lastItem: string) {
    return function specialSorter(a: { label: string }, b: { label: string }) {
        if (a.label === lastItem) return 1;
        if (b.label === lastItem) return -1;
        return a.label.localeCompare(b.label);
    };
}

function optionSort(options: SelectOption[]) {
    if (!options) {
        return options;
    }

    if (options.findIndex(item => item.label === 'Other') > 0)
        return options.sort(sortWithLastItemAs('Other'));

    if (options.findIndex(item => item.label === 'None') > 0)
        return options.sort(sortWithLastItemAs('None'));
    else return options.sort(sortWithLastItemAs(''));
}

interface Props {
    name: string;
    options: any;
}

export const VirtualizedSearchSelectField: React.FunctionComponent<Props & any> = ({
    name,
    options,
    ...rest
}: Props & any) => {
    return (
        <FieldSet>
            <Field {...rest} name={name} component={SearchSelectField} options={options} />
        </FieldSet>
    );
};
