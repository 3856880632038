import {
    Divider,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    withStyles,
} from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import styles, { StyledComponent } from '../../../../../styles/styles';
import { Column, Rule, RuleRow } from '../rules-reducer';

interface Props extends StyledComponent {
    readonly rule: Rule;
}

const resultsRulesConditionModel: React.FunctionComponent<Props> = ({ classes, rule }: Props) => {
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography variant="h5">Result Details</Typography>
                <Divider style={{ marginBottom: '16px', marginTop: '16px' }} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1" className={classes.label} color="secondary">
                    Rule: {rule.name}
                </Typography>
            </Grid>
            <Grid container justify="flex-start" item xs={12}>
                <Grid item xs={2}>
                    <Typography variant="caption" className={classes.value}>
                        Description:
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant="caption" className={classes.value}>
                        {rule.description}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justify="flex-start" item xs={12}>
                <Grid item xs={2}>
                    <Typography variant="caption" className={classes.value}>
                        Rating Result:
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant="caption" className={classes.value}>
                        {rule.rating}
                    </Typography>
                </Grid>
            </Grid>
            <RuleRowsView rows={rule.rows} />
        </Grid>
    );
};
interface RowProps {
    rows?: RuleRow[];
}
const RuleRowsView = ({ rows }: RowProps) => {
    if (!rows || rows.length < 1) {
        return null;
    }
    const headings: string[] = [];
    rows.forEach(row => {
        row.columns.forEach(column => {
            if (!headings.includes(column.name)) {
                headings.push(column.name);
            }
        });
    });

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography style={{ margin: '8px 0px 8px 0px' }} variant="subtitle2">
                    Matches
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headings.map((heading, hIndex) => {
                                    return (
                                        <TableCell key={`heading-${hIndex}`}>{heading}</TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, rIndex) => {
                                return <ColumnsView key={`row-${rIndex}`} columns={row.columns} />;
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </React.Fragment>
    );
};

interface ColumnProps {
    columns?: Column[];
}

const ColumnsView = ({ columns }: ColumnProps) => {
    if (!columns) {
        return null;
    }
    return (
        <TableRow>
            {columns.map((column, hIndex) => {
                return <TableCell key={`heading-${hIndex}`}>{column.value}</TableCell>;
            })}
        </TableRow>
    );
};

export const ResultsRulesPopup = withStyles(styles)(resultsRulesConditionModel);
