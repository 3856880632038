import { Grid } from '@material-ui/core';
import React from "react"; // eslint-disable-line
import { LabeledValue } from '../../../../controls/labeled-value';
import { Section } from '../../../../controls/section';
import { formatCurrency } from '../../../../shared-functions';

interface TopUpBenefitProps {
    topUpPercentage?: number;
    topUpPremium?: number;
    topUpAmount?: number;
}

export const TopUpBenefit = ({ topUpPercentage, topUpPremium, topUpAmount }: TopUpBenefitProps) => {
    return (
        <Section title="Top Up Benefits">
            <Grid item xs={4}>
                <LabeledValue label="Top Up %" value={topUpPercentage} />
            </Grid>
            <Grid item xs={4}>
                <LabeledValue label="Top Up Premium" value={formatCurrency(topUpPremium)} />
            </Grid>
            <Grid item xs={4}>
                <LabeledValue label="Top Up Amount" value={formatCurrency(topUpAmount)} />
            </Grid>
        </Section>
    );
};
