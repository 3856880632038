import { Button, Grid, Tooltip } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { ReasonForRequest, RequirementReasons } from '../../../../../codes/reason-for-request';
import { OpenAddModal, openAddModalAction } from '../../../../../form-modal/actions';
import {
    Condition,
    False,
    FormHeading,
    FullWidthRadioGroup,
    FullWidthTextField,
    FullWidthSearchSelectField,
    RadioOption,
    True,
    WhenFieldChanges,
    ButtonWrapper,
} from '../../../../../forms';
import { requiredValidator, conditionalRequired } from '../../../../../forms/validations';
import { State } from '../../../../../redux/root-reducer';
import { RequiredDocument } from '../reducer';
import { DocumentOwnerType, Nullable } from '../../../../../shared-types';
import {
    ClaimantDocumentNameList,
    FullDocumentNameList,
    LifeAssuredDocumentNameList,
    PolicyOwnerDocumentNameList,
} from '../../../../../codes/document-name-list';
import { ClaimType } from '../../../../../claim/claim-types';
import { getClaim } from '../../../../../claim';
import { AddFuneralDocumentFormModal } from '../../../../../form-modal/views/add-funeral-documents-form-modal';
import { AddOtherDocumentFormModal } from '../../../../../form-modal/views/add-other-documents-form-modal';

interface Props {
    isDeath?: boolean;
    documentOwnerType: DocumentOwnerType;
    documents: RequiredDocument[];
    claimType: Nullable<ClaimType>;
}
export const RequiredDocumentEditView: React.FunctionComponent<Props> = ({
    isDeath,
    documentOwnerType,
    documents,
    claimType,
}: Props) => {
    const documentList = getDocumentListFromType(documentOwnerType, claimType);

    documents.forEach(function (itemToRemove) {
        if (itemToRemove && itemToRemove.name) {
            const indexToRemove = documentList.findIndex(
                item => item.value.trim().toLowerCase() === itemToRemove?.name?.toLowerCase()
            );
            if (indexToRemove > -1) {
                documentList.splice(indexToRemove, 1);
            }
        }
    });

    const reasonOptions = isDeath ? ReasonForRequest : RequirementReasons;
    return (
        <React.Fragment>
            <FormHeading text="Add Required Document" />
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={6}>
                    <FullWidthSearchSelectField
                        name="name"
                        label="Document name"
                        options={documentList}
                        validate={requiredValidator}
                        maxMenuHeight="8em"
                    />
                    <WhenFieldChanges field="status" set="reason" to={null} />
                    <Condition when="name" is="Other">
                        <True>
                            <Grid item xs={12} lg={12}>
                                <FullWidthTextField
                                    name="description"
                                    validate={conditionalRequired(
                                        values => values.documentNameList === 'Other'
                                    )}
                                />
                            </Grid>
                        </True>
                    </Condition>
                </Grid>
                <Grid item xs={6}>
                    <FullWidthRadioGroup
                        name="status"
                        label="Document Status"
                        validate={requiredValidator}
                    >
                        <RadioOption value="Received" />
                        <RadioOption value="Verified" />
                        <RadioOption value="Requested" />
                        <RadioOption value="Waived" />
                    </FullWidthRadioGroup>
                </Grid>
                <Condition when="status" is="Requested">
                    <True>
                        <Grid item xs={6}>
                            <FullWidthSearchSelectField
                                name="reason"
                                label="Reason for request"
                                options={reasonOptions}
                                validate={requiredValidator}
                                maxMenuHeight="7em"
                            />
                        </Grid>
                    </True>
                    <False>
                        <Grid item xs={6}>
                            <FullWidthSearchSelectField
                                name="reason"
                                label="Reason for request"
                                options={reasonOptions}
                                disabled
                            />
                        </Grid>
                    </False>
                </Condition>
            </Grid>
        </React.Fragment>
    );
};

const getDocumentListFromType = (owner: DocumentOwnerType, claimType: Nullable<ClaimType>) => {
    if (claimType !== ClaimType.Funeral) return FullDocumentNameList;

    switch (owner) {
        case DocumentOwnerType.Claimant: {
            return ClaimantDocumentNameList;
        }
        case DocumentOwnerType.LifeAssured: {
            return LifeAssuredDocumentNameList;
        }
        case DocumentOwnerType.PolicyOwner: {
            return PolicyOwnerDocumentNameList;
        }
        default:
            return FullDocumentNameList;
    }
};

interface AddProps {
    addAction: (
        values: RequiredDocument
    ) => void | Promise<void> | Record<string, any> | Promise<Record<string, any>>;
    isDeath?: boolean;
    isReadOnly: boolean;
    hasDeferredContracts: boolean;
    openAddModal: OpenAddModal;
    documentOwnerType: DocumentOwnerType;
    documents: RequiredDocument[];
    claimType: Nullable<ClaimType>;
    claimantId?: string;
}
const mapStateProps = (state: State) => ({
    isReadOnly: state.claimSession.readOnly,
    hasDeferredContracts: state.claimSession.hasDeferredContracts,
    claimType: getClaim(state).claimType,
});
const mapDispatch = { openAddModal: openAddModalAction };
const RequiredOtherDocumentForm = AddOtherDocumentFormModal<RequiredDocument>();
const RequiredFuneralDocumentForm = AddFuneralDocumentFormModal<RequiredDocument>();
export const AddDocumentButton = connect(
    mapStateProps,
    mapDispatch
)(function addButton({
    addAction,
    isDeath,
    isReadOnly,
    hasDeferredContracts,
    openAddModal,
    documentOwnerType,
    documents,
    claimType,
    claimantId,
}: AddProps) {
    if (isReadOnly || hasDeferredContracts) {
        return null;
    }

    const add = () => {
        openAddModal({
            modalContent: (
                <React.Fragment>
                    {claimType === ClaimType.Funeral && (
                        <RequiredFuneralDocumentForm
                            blankRecord={{
                                id: uuid(),
                                dateRequested: new Date(),
                            }}
                            recordDetailView={
                                <RequiredDocumentEditView
                                    isDeath={isDeath}
                                    documentOwnerType={documentOwnerType}
                                    documents={documents}
                                    claimType={claimType}
                                />
                            }
                            addRecord={addAction}
                            documentOwnerType={documentOwnerType}
                            claimantId={claimantId}
                        />
                    )}
                    {claimType !== ClaimType.Funeral && (
                        <RequiredOtherDocumentForm
                            blankRecord={{
                                id: uuid(),
                                dateRequested: new Date(),
                            }}
                            recordDetailView={
                                <RequiredDocumentEditView
                                    isDeath={isDeath}
                                    documentOwnerType={documentOwnerType}
                                    documents={documents}
                                    claimType={claimType}
                                />
                            }
                            addRecord={addAction}
                            documentOwnerType={documentOwnerType}
                            claimantId={claimantId}
                        />
                    )}
                </React.Fragment>
            ),
            modalData: {},
        });
    };

    return (
        <ButtonWrapper>
            <Tooltip title="Request a document">
                <Button id="AddRequiredDocument" color="secondary" onClick={add}>
                    <AddCircle fontSize="small" />
                </Button>
            </Tooltip>
        </ButtonWrapper>
    );
});
