import { Claim, claimSubmit } from '../../claim';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { getLifeAssuredDetailsFromClaim, Payment } from '../reducer';

interface UpdateModel {
    previousPayments: Payment[];
}
type UpdatePreviousPaymentsReturn = Promise<any>;
export type UpdatePreviousPayments = (values: UpdateModel) => UpdatePreviousPaymentsReturn;
type UpdatePreviousPaymentsAction = (
    values: UpdateModel
) => ReduxThunkAction<UpdatePreviousPaymentsReturn>;

export const updatePreviousPaymentsAction: UpdatePreviousPaymentsAction = (values: UpdateModel) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, getUpdatedClaimPreviousPayments(values));
    };
};

function getUpdatedClaimPreviousPayments(values: UpdateModel): (claim: Claim) => Claim {
    return (claim: Claim) => {
        const currentLifeAssuredDetails = getLifeAssuredDetailsFromClaim(claim);
        return {
            ...claim,
            lifeAssuredDetails: {
                ...currentLifeAssuredDetails,
                ...values,
            },
        };
    };
}
