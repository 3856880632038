import React from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import styles from '../../../../../styles/styles';
import {
    FullWidthTextField,
    FullWidthSearchSelectField,
    Condition,
    True,
    WhenFieldChanges,
} from '../../../../../forms';
import { requiredValidator } from '../../../../../forms/validations';
import {
    NaturalRelationships,
    OrganisationRelationships,
} from '../../../../../codes/relationship-types';

interface Props {
    readonly propertyPrefix: string;
    readonly hideOwnerRelationship?: boolean;
    readonly isAnOrganisation?: boolean;
}

const relationshipDetailsView: React.FunctionComponent<Props> = ({
    propertyPrefix,
    hideOwnerRelationship,
    isAnOrganisation,
}: Props) => {
    const fullPropertyPrefix =
        propertyPrefix && propertyPrefix !== '' ? `${propertyPrefix}.` : propertyPrefix;
    const relationshipOptions = isAnOrganisation ? OrganisationRelationships : NaturalRelationships;
    return (
        <React.Fragment>
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    name={`${fullPropertyPrefix}relationshipToLifeAssured`}
                    options={relationshipOptions}
                    validate={requiredValidator}
                    sortOptions
                />
                <WhenFieldChanges
                    field={`${fullPropertyPrefix}relationshipToLifeAssured`}
                    set={`${fullPropertyPrefix}relationshipToLifeAssuredDescription`}
                    to={null}
                />
            </Grid>
            {!hideOwnerRelationship && (
                <Grid item xs={6}>
                    <FullWidthSearchSelectField
                        name={`${fullPropertyPrefix}relationshipToOwner`}
                        options={relationshipOptions}
                        validate={requiredValidator}
                        sortOptions
                    />
                    <WhenFieldChanges
                        field={`${fullPropertyPrefix}relationshipToOwner`}
                        set={`${fullPropertyPrefix}relationshipToOwnerDescription`}
                        to={null}
                    />
                </Grid>
            )}
            <Grid item xs={6}>
                <Condition when={`${fullPropertyPrefix}relationshipToLifeAssured`} is="Other">
                    <True>
                        <FullWidthTextField
                            name={`${fullPropertyPrefix}relationshipToLifeAssuredDescription`}
                            validate={requiredValidator}
                        />
                    </True>
                </Condition>
            </Grid>
            {!hideOwnerRelationship && (
                <Grid item xs={6}>
                    <Condition when={`${fullPropertyPrefix}relationshipToOwner`} is="Other">
                        <True>
                            <FullWidthTextField
                                name={`${fullPropertyPrefix}relationshipToOwnerDescription`}
                                validate={requiredValidator}
                            />
                        </True>
                    </Condition>
                </Grid>
            )}
        </React.Fragment>
    );
};

export const RelationshipDetailsView = withStyles(styles)(relationshipDetailsView);
