import React from 'react'; // eslint-disable-line
import { withStyles, Grid, Typography, Divider } from '@material-ui/core';
import { BankingDetails } from '../assessment/synopsis/common/banking-details/banking-details';
import { LabeledValue } from '../controls/labeled-value';
import styles, { StyledComponent } from '../styles/styles';
import classNames from 'classnames';
import { getCodeDescription } from '../shared-functions';

type Props = StyledComponent & { bankingDetails?: BankingDetails };
const bankingDetailsDisplay: React.FunctionComponent<Props> = ({
    bankingDetails,
    classes,
}: Props) => {
    if (!bankingDetails) {
        return null;
    }
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Divider className={classNames(classes.marginTop, classes.marginBottom)} />
            </Grid>
            <Grid item xs={12}>
                <Typography
                    variant="subtitle1"
                    style={{ fontSize: '20px', marginBottom: '-8px', marginTop: '8px' }}
                >
                    Banking Details
                </Typography>
            </Grid>
            <Grid item xs={12} container justify="flex-start">
                <Grid item xs={4} lg={2}>
                    <LabeledValue
                        label="Account holder name"
                        value={bankingDetails.accountHoldersName}
                    />
                </Grid>
                <Grid item xs={4} lg={2}>
                    <LabeledValue
                        label="Bank Name"
                        value={getCodeDescription('bankName', bankingDetails.bankName)}
                    />
                </Grid>
                <Grid item xs={4} lg={2}>
                    <LabeledValue
                        label="Bank Account Number"
                        value={bankingDetails.accountNumber}
                    />
                </Grid>
                <Grid item xs={4} lg={2}>
                    <LabeledValue label="Branch Name" value={bankingDetails.bankBranch} />
                </Grid>
                <Grid item xs={4} lg={2}>
                    <LabeledValue label="Account Type" value={bankingDetails.accountType} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export const BankingDetailsDisplay = withStyles(styles)(bankingDetailsDisplay);
