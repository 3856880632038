import { Reducer } from 'redux';
import { UpdateUnderwritingPolicyDetailsAction } from './actions';
import { UPDATE_UNDERWRITING_POLICY_DETAILS } from './actions/underwriting-policy-details-action-types';
import { UnderwritingPolicyDetails } from './underwriting-policy-details-model';

const defaultUnderwritingPolicyDetails: UnderwritingPolicyDetails = {
    policyNumber: '',
    newBusinessUploadDate: undefined,
    counterOfferLetterSignedDate: undefined,
    commencementDate: undefined,
    bigT: false,
    replacementPolicyNumbers: [],
    replacement: false,
};

export const underwritingPolicyDetailsReducer: Reducer<
    UnderwritingPolicyDetails,
    UpdateUnderwritingPolicyDetailsAction
> = (state = defaultUnderwritingPolicyDetails, action) => {
    if (action.type !== UPDATE_UNDERWRITING_POLICY_DETAILS) {
        return state;
    }

    return {
        ...state,
        newBusinessUploadDate: action.payload.newBusinessUploadDate,
        counterOfferLetterSignedDate: action.payload.counterOfferLetterSignedDate,
    };
};
