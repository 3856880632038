import { LOAD_POSTAL_CODES_BOX, LOAD_POSTAL_CODES_STREET } from './postal-codes';
import { LOAD_BANK_NAMES, LOAD_UNIVERSAL_BRANCHES, LOAD_FUND_NAMES } from './bank-branch-codes';

export interface Code<T> {
    value: T;
    label: string;
}
export interface FundNamesCode<T> {
    code: T;
    description: string;
}
export interface UniversalBranch {
    bankName: string;
    label: string;
    value: string;
}
export type FundNamesCodeList<T> = FundNamesCode<T>[];
export interface FundNames {
    fundName: string;
    products: FundNamesCodeList<string>;
}

export type CodeList<T> = Code<T>[];
export interface Lookups {
    postalCodesBox: CodeList<string>;
    postalCodesStreet: CodeList<string>;
    bankNames: CodeList<string>;
    universalBranches: UniversalBranch[];
    fundNames: FundNames[];
}

export const initialLookups: Lookups = {
    postalCodesBox: [],
    postalCodesStreet: [],
    bankNames: [],
    universalBranches: [],
    fundNames: [],
};

export function lookupReducer(state = initialLookups, action: any) {
    switch (action.type) {
        case LOAD_POSTAL_CODES_BOX:
            return {
                ...state,
                postalCodesBox: action.postalCodesBox,
            };
        case LOAD_POSTAL_CODES_STREET:
            return {
                ...state,
                postalCodesStreet: action.postalCodesStreet,
            };
        case LOAD_BANK_NAMES:
            return {
                ...state,
                bankNames: action.bankNames,
            };
        case LOAD_UNIVERSAL_BRANCHES:
            return {
                ...state,
                universalBranches: action.universalBranches,
            };
        case LOAD_FUND_NAMES:
            return {
                ...state,
                fundNames: action.fundNames,
            };

        default:
            return state;
    }
}
