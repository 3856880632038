import React from 'react'; // eslint-disable-line

import { Button, Grid, Tooltip } from '@material-ui/core';
import { Delete, AddCircle } from '@material-ui/icons';

import { FullWidthSearchSelectField } from '../../../../../forms/form-components/full-width-search-select-field/index';
import { NonBusinessRelatedExpense } from '../reducer';
import { FullWidthTextField } from '../../../../../forms';
import { CurrencyMask } from '../../../../../forms/input-masks/currency-mask';
import { requiredValidator } from '../../../../../forms/validations';
import { convertToNumber } from '../../../../../shared-functions';

interface ComponentState {
    nonBusinessRelatedExpenses: NonBusinessRelatedExpense[];
}

interface Props extends ComponentState {}

class NonBusinessRelatedExpensesClass extends React.Component<Props, ComponentState> {
    public constructor(props: Props) {
        super(props);
    }

    public componentDidMount() {
        this.setState({
            nonBusinessRelatedExpenses: this.props.nonBusinessRelatedExpenses,
        });
    }

    public render() {
        const nonBusinessRelatedExpenses = this.state
            ? this.state.nonBusinessRelatedExpenses
            : this.props.nonBusinessRelatedExpenses;

        const addEntry = () => {
            nonBusinessRelatedExpenses.push({});
            this.setState({
                nonBusinessRelatedExpenses: nonBusinessRelatedExpenses,
            });
        };
        const deleteEntry = (index: number) => {
            nonBusinessRelatedExpenses.splice(index, 1);
            this.setState({
                nonBusinessRelatedExpenses: nonBusinessRelatedExpenses,
            });
        };
        const NonBusinessRelatedExpenseNames = [
            { value: 'Depreciation', label: 'Depreciation' },
            { value: 'Locum', label: 'Locum' },
            { value: 'Entertainment', label: 'Entertainment' },
            { value: "Director's Remuneration", label: "Director's Remuneration" },
            { value: 'Travel costs', label: 'Travel costs' },
        ];
        return (
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={6}>
                    <FullWidthTextField
                        name="selfEmployedIncome.expenses"
                        mask={CurrencyMask}
                        parse={convertToNumber}
                    />
                </Grid>

                <Grid item xs={1}>
                    <Tooltip
                        title="Add Non Business Related Expenses"
                        style={{ marginTop: '30px' }}
                    >
                        <Button
                            id={'Add_Non_Business_Related_Expenses'}
                            color="secondary"
                            onClick={() => addEntry()}
                        >
                            <AddCircle />
                        </Button>
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    {nonBusinessRelatedExpenses.map((sei, index) => {
                        return (
                            <Grid container spacing={5} key={`sei_${index}`}>
                                <Grid item xs={6}>
                                    <FullWidthSearchSelectField
                                        name={`selfEmployedIncome.nonBusinessRelatedExpenses[${index}].nonBusinessRelatedExpenseName`}
                                        label="Non Business Related Expense"
                                        options={NonBusinessRelatedExpenseNames}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <FullWidthTextField
                                        name={`selfEmployedIncome.nonBusinessRelatedExpenses[${index}].nonBusinessRelatedExpenseValue`}
                                        label="Amount"
                                        mask={CurrencyMask}
                                        validate={requiredValidator}
                                        parse={convertToNumber}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Tooltip
                                        title="Delete_Non_Business_Related_Expenses"
                                        style={{
                                            marginTop: '30px',
                                            marginLeft: '-30px',
                                        }}
                                    >
                                        <Button
                                            id={`Delete_previous_marriage_${index}`}
                                            color="secondary"
                                            onClick={() => deleteEntry(index)}
                                        >
                                            <Delete />
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        );
    }
}

export const NonBusinessRelatedExpensesView = NonBusinessRelatedExpensesClass;
