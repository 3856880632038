import { some } from 'lodash';
import { SyncValidator } from '.';

export function ValidateAllOrNone(
    validator: SyncValidator,
    ...fields: string[]
): (value: any, values: any) => string | undefined {
    return (value: any, values: any) => {
        if (some(fields, field => !validator(values[field]))) {
            return validator(value);
        }
        return undefined;
    };
}
