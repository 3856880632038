import { Claim, claimSubmit } from '../../../claim';
import { IncomeClaim } from '../../../claim/reducers/claim-reducers/income-claim-reducer';
import { ReduxThunkAction } from '../../../redux/redux-actions';
import { IncomePayableCalculation } from '../reducer';

export interface IncomeCalculationModel {
    sumAssured?: number;
    topUpAmount?: number;
    topUpPremium?: number;
    claimBoosterAmount?: number;
    incomeProtectorPremium?: number;
    incomeProtectorSumAssured?: number;
    effectiveDate?: Date;
    expiryDate?: Date;
    index: number | null;
}

interface MonthRange {
    startDate: Date;
    endDate: Date;
    nrOfDays: number;
}

function compareDate(endDate: Date, startDate: Date): boolean {
    let sameYear = startDate.getFullYear() === endDate.getFullYear();
    let samemonth = startDate.getMonth() <= endDate.getMonth();
    if (!sameYear) {
        sameYear = true;
        samemonth = endDate.getMonth() <= startDate.getMonth();
    }

    if (sameYear && samemonth) return true;
    else return false;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UpdateIncomePayableCalculationReturn = Promise<any>;
export type UpdateIncomePayableCalculation = (
    incomeCalculationModel: IncomeCalculationModel
) => UpdateIncomePayableCalculationReturn;
type UpdateIncomePayableCalculationAction = (
    incomeCalculationModel: IncomeCalculationModel
) => ReduxThunkAction<UpdateIncomePayableCalculationReturn>;

export const updateIncomePayableCalculationAction: UpdateIncomePayableCalculationAction = (
    incomeCalculationModel: IncomeCalculationModel
) => {
    return async (dispatch, getState) => {
        return claimSubmit(
            dispatch,
            getState,
            getUpdatedPayableCalculationDetails(incomeCalculationModel)
        );
    };
};

function getUpdatedPayableCalculationDetails(
    incomeCalculationModel: IncomeCalculationModel
): (claim: Claim) => Claim {
    return (claim: Claim) => {
        const listOfMonthsRange: MonthRange[] = [];
        const incomeClaim = claim as IncomeClaim;

        let startDate = incomeCalculationModel.effectiveDate;
        const endDate = incomeCalculationModel.expiryDate;

        if (startDate && endDate) {
            while (compareDate(endDate, startDate)) {
                const lastDayOfTheMonth = new Date(
                    startDate.getFullYear(),
                    startDate.getMonth() + 1,
                    0
                );
                if (
                    startDate.getMonth() === endDate.getMonth() &&
                    startDate.getFullYear() === endDate.getFullYear()
                ) {
                    listOfMonthsRange.push({
                        startDate: startDate,
                        endDate: lastDayOfTheMonth,
                        nrOfDays: endDate.getDate() - startDate.getDate() + 1,
                    });
                } else {
                    listOfMonthsRange.push({
                        startDate: startDate,
                        endDate: lastDayOfTheMonth,
                        nrOfDays: lastDayOfTheMonth.getDate() - startDate.getDate() + 1,
                    });
                }

                startDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1, 0);
            }
        }

        const values: IncomePayableCalculation[] = [];

        const incomeCalculations = incomeClaim.incomeCalculations || [];
        // eslint-disable-next-line array-callback-return
        listOfMonthsRange.map(month => {
            const incomePayableCalculation: IncomePayableCalculation = {
                month: month.startDate,
                numberOfDays: month.nrOfDays,
                claimBoosterAmount: incomeCalculationModel.claimBoosterAmount
                    ? (incomeCalculationModel.claimBoosterAmount * month.nrOfDays) /
                      month.endDate.getDate()
                    : 0,
                proRataIncomeProtectorPremium: incomeCalculationModel.incomeProtectorPremium
                    ? (incomeCalculationModel.incomeProtectorPremium * month.nrOfDays) /
                      month.endDate.getDate()
                    : 0,
                proRataIncomeProtectorSumAssured: incomeCalculationModel.incomeProtectorSumAssured
                    ? (incomeCalculationModel.incomeProtectorSumAssured * month.nrOfDays) /
                      month.endDate.getDate()
                    : 0,
                incomeProtectorPremium: incomeCalculationModel.incomeProtectorPremium,
                incomeProtectorSumAssured: incomeCalculationModel.incomeProtectorSumAssured,
                proRataSumAssured: incomeCalculationModel.sumAssured
                    ? (incomeCalculationModel.sumAssured * month.nrOfDays) / month.endDate.getDate()
                    : 0,
                proRataTopUpSumAssured: incomeCalculationModel.topUpAmount
                    ? (incomeCalculationModel.topUpAmount * month.nrOfDays) /
                      month.endDate.getDate()
                    : 0,
                totalPayable:
                    (incomeCalculationModel.incomeProtectorPremium
                        ? (incomeCalculationModel.incomeProtectorPremium * month.nrOfDays) /
                          month.endDate.getDate()
                        : 0) +
                    (incomeCalculationModel.topUpPremium
                        ? (incomeCalculationModel.topUpPremium * month.nrOfDays) /
                          month.endDate.getDate()
                        : 0) +
                    (incomeCalculationModel.incomeProtectorSumAssured
                        ? (incomeCalculationModel.incomeProtectorSumAssured * month.nrOfDays) /
                          month.endDate.getDate()
                        : 0) +
                    (incomeCalculationModel.claimBoosterAmount
                        ? incomeCalculationModel.claimBoosterAmount
                        : 0),
            };

            values.push(incomePayableCalculation);
        });
        incomeCalculations[incomeCalculationModel.index ?? 0].incomePayableCalculations = values;
        return {
            ...claim,
            incomeCalculations,
        };
    };
}
