import React, { useState, useEffect } from 'react'; // eslint-disable-line
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    InputAdornment,
    Typography,
} from '@material-ui/core';
import { Assessors } from '../../reducer/assessors-reducer';
import {
    FormHeading,
    FullWidthRadioGroup,
    FullWidthTextField,
    RadioOption,
    WhenFieldChanges,
} from '../../../forms';
interface TableProps {
    assessor: Assessors;
    classes: any;
    isEdit?: boolean;
    assessorUpdated?: boolean;
    error?: any;
}

const AssessorTable = ({ classes, assessor, isEdit, error }: TableProps) => {
    const [isTableDisabled, setIsTableDisabled] = useState<boolean>(
        isEdit ? assessor.status !== 'Active' : false
    );

    useEffect(() => {
        if (isEdit && error.length <= 0) {
            assessor.assessorLimits.map((a, i) => updateStatus(i, a.canApproved));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit, error]);

    const [statuses, setStatus] = useState<any>(assessor.assessorLimits);

    const onStatusToggle = (canApprove: boolean, index: number) => {
        updateStatus(index, canApprove);
        return canApprove;
    };

    const updateStatus = (index: number, canApprove: boolean) => {
        let updatedStatuses = [] as any;
        updatedStatuses = statuses;
        updatedStatuses[index].isDisabled = !canApprove;
        setStatus(updatedStatuses);
    };

    const onToggle = (value: boolean) => {
        setIsTableDisabled(value);
    };

    const isTextBoxDisabled = (hIndex: number, column: any) => {
        if (statuses[hIndex]?.isDisabled !== undefined) {
            return statuses[hIndex].isDisabled;
        } else {
            return !column.canApproved;
        }
    };

    return (
        <>
            {isEdit && (
                <FormHeading text={assessor.name + ' ' + assessor.surname + "'s Permissions"} />
            )}
            <Grid item className={isEdit ? classes.assessorEditTable : null} xs={12}>
                <Grid>
                    {isEdit && (
                        <>
                            <FullWidthRadioGroup
                                className={classes.assessorRadioButton}
                                style={{ marginTop: -10, marginLeft: 5 }}
                                name="status"
                                noLabel={true}
                            >
                                <RadioOption value="Active" label="Activate user" />
                                <RadioOption value="InActive" label="Deactivate user" />
                            </FullWidthRadioGroup>
                            <WhenFieldChanges
                                field={`status`}
                                becomes={'Active'}
                                set={`test`}
                                to={() => onToggle(false)}
                            />
                            <WhenFieldChanges
                                field={`status`}
                                becomes={'InActive'}
                                set={`test`}
                                to={() => onToggle(true)}
                            />
                        </>
                    )}
                </Grid>

                <Table className={`${classes.assessorTable}`}>
                    <TableHead
                        className={
                            isTableDisabled
                                ? classes.assessorTableHeadDisabled
                                : classes.assessorTableHead
                        }
                    >
                        <TableRow>
                            <TableCell
                                padding="none"
                                className={`${classes.assessorTableCell}${
                                    isTableDisabled ? classes.assessorDisabledField : null
                                }`}
                            >
                                Claim type
                            </TableCell>
                            <TableCell
                                padding="none"
                                align="center"
                                className={`${classes.assessorTableCell}${
                                    isTableDisabled ? classes.assessorDisabledField : null
                                }`}
                            >
                                Permission to pay
                            </TableCell>
                            <TableCell
                                padding="none"
                                className={`${classes.assessorTableCell}${
                                    isTableDisabled ? classes.assessorDisabledField : null
                                }`}
                            >
                                Maximum payout
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {assessor.assessorLimits.map((column: any, hIndex: number) => {
                            return (
                                <TableRow key={`heading-${hIndex}`}>
                                    <TableCell
                                        padding="none"
                                        style={{ width: '45%' }}
                                        className={`${classes.assessorTableCellPadding}${
                                            isTableDisabled ? classes.assessorDisabledField : null
                                        }`}
                                    >
                                        {column.productType}
                                    </TableCell>
                                    <TableCell
                                        padding="none"
                                        align={isEdit ? 'left' : 'center'}
                                        width={230}
                                        className={classes.assessorTableCellPadding}
                                    >
                                        {isEdit ? (
                                            <>
                                                <FullWidthRadioGroup
                                                    className={classes.assessorRadioButton}
                                                    style={{ marginTop: -10, marginLeft: 5 }}
                                                    name={`assessorLimits[${hIndex}].canApproved`}
                                                    noLabel={true}
                                                >
                                                    <RadioOption
                                                        disabled={isTableDisabled}
                                                        value={true}
                                                        label="Yes"
                                                    />
                                                    <RadioOption
                                                        disabled={isTableDisabled}
                                                        value={false}
                                                        label="No"
                                                    />
                                                </FullWidthRadioGroup>

                                                <WhenFieldChanges
                                                    field={`assessorLimits[${hIndex}].canApproved`}
                                                    becomes={'true'}
                                                    set={`assessorLimits[${hIndex}].canApproved`}
                                                    to={() => onStatusToggle(true, hIndex)}
                                                />
                                                <WhenFieldChanges
                                                    field={`assessorLimits[${hIndex}].canApproved`}
                                                    becomes={'true'}
                                                    set={`assessorLimits[${hIndex}].amount`}
                                                    to={''}
                                                />
                                                <WhenFieldChanges
                                                    field={`assessorLimits[${hIndex}].canApproved`}
                                                    becomes={'false'}
                                                    set={`assessorLimits[${hIndex}].canApproved`}
                                                    to={() => onStatusToggle(false, hIndex)}
                                                />
                                                <WhenFieldChanges
                                                    field={`assessorLimits[${hIndex}].canApproved`}
                                                    becomes={'false'}
                                                    set={`assessorLimits[${hIndex}].amount`}
                                                    to={0}
                                                />
                                            </>
                                        ) : column.canApproved ? (
                                            'Yes'
                                        ) : (
                                            'No'
                                        )}
                                    </TableCell>
                                    <TableCell
                                        padding="none"
                                        className={classes.assessorTableCellPadding}
                                    >
                                        {isEdit ? (
                                            <Grid container direction="row">
                                                <>
                                                    {!isTableDisabled ? (
                                                        <FullWidthTextField
                                                            name={`assessorLimits[${hIndex}].amount`}
                                                            set={`assessorLimits[${hIndex}].amount`}
                                                            disabled={isTextBoxDisabled(
                                                                hIndex,
                                                                column
                                                            )}
                                                            type="number"
                                                            startadornment={
                                                                <InputAdornment position="start">
                                                                    R
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    ) : (
                                                        <FullWidthTextField
                                                            name={`assessorLimits[${hIndex}].amount`}
                                                            set={`assessorLimits[${hIndex}].amount`}
                                                            disabled={isTableDisabled}
                                                            startadornment={
                                                                <InputAdornment position="start">
                                                                    R
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    )}
                                                </>
                                                {error &&
                                                    error.map((err: any) => {
                                                        return (
                                                            <Typography
                                                                key={err.index}
                                                                style={{
                                                                    fontSize: 11,
                                                                    color: 'red',
                                                                }}
                                                            >
                                                                {err.index === hIndex
                                                                    ? err.errorMessage
                                                                    : null}
                                                            </Typography>
                                                        );
                                                    })}

                                                <WhenFieldChanges
                                                    field={`assessorLimits[${hIndex}].canApproved`}
                                                    becomes={false}
                                                    set={`assessorLimits[${hIndex}].amount`}
                                                    to={0}
                                                />
                                            </Grid>
                                        ) : (
                                            parseFloat(column.amount).toLocaleString('en-ZA', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                style: 'currency',
                                                currency: 'ZAR',
                                            })
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Grid>
        </>
    );
};

export function validate(values: any) {
    const errors = [] as any;
    values.assessorLimits.map((limits: any, index: number) => {
        if (limits.canApproved === true && (limits.amount <= 0 || !isNumber(limits.amount))) {
            const errorMessage = 'Please ensure this value is greater than zero and numeric.';
            errors.push({ index, errorMessage });
        }
        return errors;
    });
    return errors;
}

function isNumber(n: string) {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
}

export default AssessorTable;
