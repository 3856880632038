import { FormSubmissionAction } from '../reducers/fom-submission-reducer';
import { SET_SUBMISSION_CALLBACKS } from '../types';
import { HandleSubmit } from './submit-form';

export type SetSubmissionCallbacks = (
    onSubmit?: HandleSubmit,
    isPristine?: () => boolean,
    hasValidationErrors?: () => boolean
) => FormSubmissionAction;
export const setSubmissionCallbacks: SetSubmissionCallbacks = (
    onSubmit?: HandleSubmit,
    isPristine?: () => boolean,
    hasValidationErrors?: () => boolean
) => {
    return {
        type: SET_SUBMISSION_CALLBACKS,
        payload: {
            onSubmit,
            isPristine,
            hasValidationErrors,
            isSubmitting: false,
        },
    };
};
