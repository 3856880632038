import React from 'react'; // eslint-disable-line

import { Grid, Paper, withStyles } from '@material-ui/core';
import { FormHeading } from '../../../../../forms';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { PersonalityDetailsView } from './edit-personality-details';

const guardianDetailsEditView: React.FunctionComponent<StyledComponent> = ({
    classes,
}: StyledComponent) => {
    return (
        <Paper className={classes.paper}>
            <FormHeading text="Guardian Details" />
            <Grid container spacing={5} justify="flex-start">
                <PersonalityDetailsView propertyPrefix="claimantDetails.guardianDetails" />
            </Grid>
        </Paper>
    );
};

export const GuardianDetailsEditView = withStyles(styles)(guardianDetailsEditView);
