import { Claim, claimSubmit } from '../../../../../claim';
import { RADeathClaim } from '../../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { AddressDetails } from '../../../../../forms/sub-forms/address-details';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';

interface UpdateAddressDetailsModel {
    residentialAddress?: AddressDetails;
    postalAddress?: AddressDetails;
    businessAddress?: AddressDetails;
    sameAsResidential?: boolean;
}

type UpdateAddressDetailsReturn = Promise<any>;
export type UpdateAddressDetails = (
    addressDetails: UpdateAddressDetailsModel
) => UpdateAddressDetailsReturn;
type UpdateAddressDetailsAction = (
    addressDetails: UpdateAddressDetailsModel
) => ReduxThunkAction<UpdateAddressDetailsReturn>;

export const updateAddressDetailsAction: UpdateAddressDetailsAction = (
    addressDetails: UpdateAddressDetailsModel
) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, getUpdatedAddressDetails(addressDetails));
    };
};

function getUpdatedAddressDetails(
    addressDetails: UpdateAddressDetailsModel
): (claim: Claim) => Claim {
    return (claim: Claim) => {
        if (claim.claimType === 'RADeath') {
            const raClaim = claim as RADeathClaim;
            return {
                ...claim,
                lifeAssuredDetails: {
                    ...raClaim.lifeAssuredDetails,
                    sameAsResidential: addressDetails.sameAsResidential,
                    residentialAddress: addressDetails.residentialAddress,
                    postalAddress: addressDetails.sameAsResidential
                        ? addressDetails.residentialAddress
                        : addressDetails.postalAddress,
                    businessAddress: addressDetails.businessAddress,
                },
            };
        }

        return claim;
    };
}
