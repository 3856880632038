import { UpdateRecordCollectionAction } from '../../../../../redux/collection-reducer';
import { InformationRecord } from '../reducer';
import { UPDATE_INFORMATION_RECORD } from '../types';

export type UpdateInformationRecordAction = UpdateRecordCollectionAction<InformationRecord>;

export const updateInformationRecordActionCreator = (
    informationRecordIndex: number,
    informationRecord: InformationRecord
): UpdateInformationRecordAction => {
    return {
        type: UPDATE_INFORMATION_RECORD,
        record: informationRecord,
        index: informationRecordIndex,
    };
};
