import React from 'react'; // eslint-disable-line

import { Button, Divider, Grid, Paper, Typography, withStyles } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { connect } from 'react-redux';
import { getDeathClaimEventDetails } from '../../../../claim/reducers/claim-reducers/death-claim-reducer';
import { OpenAddModal, openAddModalAction } from '../../../../form-modal/actions';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { DeathClaimDetails } from '../../../synopsis/death/claim-event-details/reducer';
import { BenefitAssessmentDetails } from '../../assessment-details-reducer';
import { AddDeathAssessmentDecisionProps } from './risk-assessment-decision-add';
import { DeathAssessmentDecisionView } from './death-assessment-decision';
import { OpenDialog, openDialogAction } from '../../../../confirmation-dialog';
import { ButtonWrapper } from '../../../../forms';
import { ClaimType } from '../../../../claim/claim-types';
import { DeathGroupLiabilityStatusView } from '../../common/views/death-liability-status-view';
import { Nullable } from '../../../../shared-types';
import { RulesResult } from '../../../synopsis/common/rules/rules-reducer';

interface OwnProps {
    policyNumber: string;
    benefit: BenefitAssessmentDetails;
    deathAssessmentDecisionAddView:
        | React.FunctionComponent<AddDeathAssessmentDecisionProps>
        | React.ComponentClass<AddDeathAssessmentDecisionProps>;
}

interface StateProps {
    claimDetails?: DeathClaimDetails;
    isReadOnly: boolean;
    cireResults?: Nullable<RulesResult[]>;
}

interface DispatchProps {
    openAddModal: OpenAddModal;
    openDialog: OpenDialog;
}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const riskBenefitAssessmentDetailsView: React.FunctionComponent<Props> = ({
    benefit,
    claimDetails,
    classes,
    deathAssessmentDecisionAddView: DeathAssessmentDecisionAddView,
    openAddModal,
    policyNumber,
    openDialog,
    isReadOnly,
    cireResults,
}: Props) => {
    const claimLiability = benefit.claimLiability || {};

    function isDisabled() {
        if (cireResults && cireResults[0].isCireRan) {
            let isDisabled = false;
            for (const rule of cireResults[0].rules) {
                if (rule.override || rule.rating === 'Reject') {
                    isDisabled = false;
                    continue;
                } else {
                    isDisabled = true;
                    break;
                }
            }
            return isDisabled;
        }
        return true;
    }

    const isCireRan = !!cireResults && !!cireResults[0] && cireResults[0].isCireRan;

    return (
        <React.Fragment>
            <Grid container spacing={5} justify="space-between">
                <Grid item container justify="flex-start">
                    <Grid item xs={10}>
                        <Typography variant="subtitle2">Benefit - {benefit.benefitName}</Typography>
                    </Grid>
                    <ButtonWrapper>
                        <Grid
                            container
                            item
                            xs={2}
                            justify="flex-end"
                            style={{ paddingRight: 5, marginTop: '-15px' }}
                        >
                            <Button
                                id={'Add_' + benefit.benefitName}
                                color="secondary"
                                style={{ padding: 10, textTransform: 'none' }}
                                onClick={() => addDecision()}
                                disabled={isDisabled()}
                            >
                                <AddCircle />
                                Add decision
                            </Button>
                        </Grid>
                    </ButtonWrapper>
                </Grid>
                {switchOn() && (
                    <Grid item container>
                        <Grid item xs={10}>
                            <DeathGroupLiabilityStatusView
                                claimDetails={claimDetails}
                                claimLiability={claimLiability}
                                claimType={ClaimType.Death}
                                policyNumber={policyNumber}
                                benefit={benefit}
                                isCireRan={isCireRan}
                                isOverriden={isDisabled()}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <DecisionsView benefit={benefit} classes={classes} />
        </React.Fragment>
    );

    function addDecision() {
        if (!claimDetails) {
            openDialog({
                content: {
                    title: 'Error adding decision',
                    body: 'Please capture claim event details before adding a decision',
                },
            });

            return;
        }

        openAddModal({
            modalContent: (
                <DeathAssessmentDecisionAddView
                    {...benefit}
                    {...claimLiability}
                    policyNumber={policyNumber}
                    assessmentDescription={
                        decisionsPresent(benefit) &&
                        benefit.decisions.filter(d => !!d.final).length > 0
                            ? 'Reassessment'
                            : 'Assessment'
                    }
                />
            ),
            modalData: {},
        });
    }

    function switchOn() {
        return true;
    }
};
interface DecisionsProps extends StyledComponent {
    benefit: BenefitAssessmentDetails;
}

const DecisionsView: React.FunctionComponent<DecisionsProps> = ({
    benefit,
    classes,
}: DecisionsProps) => {
    if (!decisionsPresent(benefit)) {
        return <Divider className={classes.marginBottom} />;
    }

    return (
        <Paper className={classes.paper} style={{ marginTop: 2 }}>
            {benefit.decisions.map((assessmentDecision, assessmentIndex) => {
                return (
                    <React.Fragment key={assessmentDecision.id}>
                        <DeathAssessmentDecisionView
                            benefitName={benefit.benefitName}
                            assessmentDecision={assessmentDecision}
                            claimLiability={benefit.claimLiability}
                        />
                        {assessmentIndex !== benefit.decisions.length - 1 && (
                            <Divider
                                key={`divider_${assessmentDecision.id}`}
                                className={classes.topAndBottomMargin}
                            />
                        )}
                    </React.Fragment>
                );
            })}
        </Paper>
    );
};

function decisionsPresent(benefit: BenefitAssessmentDetails) {
    return benefit.decisions && benefit.decisions.length > 0;
}

const mapStateToProps = (state: State) => ({
    claimDetails: getDeathClaimEventDetails(state).deathClaimDetails,
    isReadOnly: state.claimSession.readOnly,
    cireResults: state.claimSession.claim.results,
});

const mapDispatchToProps = {
    openAddModal: openAddModalAction,
    openDialog: openDialogAction,
};

export const RiskBenefitAssessmentDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(riskBenefitAssessmentDetailsView));
