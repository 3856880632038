import React from 'react'; // eslint-disable-line

import { ConnectedRouter } from 'connected-react-router';
import { AppWrapper } from './app-wrapper';
import history from './history';
import MasterPage from './master-layout/views/master-layout';
import store from './store';

const App: React.FunctionComponent = () => {
    return (
        <AppWrapper store={store}>
            <ConnectedRouter history={history}>
                <MasterPage />
            </ConnectedRouter>
        </AppWrapper>
    );
};

export default App;
