import React from 'react'; // eslint-disable-line
import { ButtonWrapper } from '../../../../../../forms';
import { Grid, Paper, withStyles, Tooltip, Button } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { connect } from 'react-redux';
import { RADeathClaim } from '../../../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { getClaim } from '../../../../../../claim';
import { OpenModal, openModalAction } from '../../../../../../form-modal/actions';
import { FormModalView } from '../../../../../../form-modal/views/form-modal';
import { State } from '../../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { BankingDetails } from '../../../../common/banking-details/banking-details';
import { BankingDetailsEditView } from './banking-details-edit';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import { getCodeDescription } from '../../../../../../shared-functions';

interface Props extends StyledComponent {
    bankingDetails: BankingDetails;
    openModal: OpenModal;
    isReadOnly: boolean;
}

export const bankingDetailsView: React.FunctionComponent<Props> = ({
    bankingDetails,
    classes,
    openModal,
    isReadOnly,
}: Props) => {
    const edit = () => {
        openModal({
            modalContent: <FormModalView formView={BankingDetailsEditView} />,
        });
    };
    return (
        <React.Fragment>
            <Grid container justify="flex-start">
                <ButtonWrapper>
                    <ButtonWrapper>
                        <Grid item xs={4} lg={2} className={classes.rightAlign}>
                            <Tooltip title="Edit Banking Details">
                                <Button
                                    id="EditBankingDetails"
                                    color="secondary"
                                    onClick={() => edit()}
                                >
                                    <Edit />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </ButtonWrapper>
                </ButtonWrapper>
            </Grid>
            <Paper className={classes.paper}>
                <Grid container justify="flex-start">
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Bank name"
                            value={getCodeDescription('bankName', bankingDetails.bankName)}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Account number" value={bankingDetails.accountNumber} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Branch name" value={bankingDetails.bankBranch} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Branch code" value={bankingDetails.branchCode} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Account type" value={bankingDetails.accountType} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Account holder relationship"
                            value={bankingDetails.accountHolderRelationship}
                        />
                    </Grid>
                    {bankingDetails.accountHolderRelationship === 'Other' && (
                        <Grid item xs={6} lg={3}>
                            <LabeledValue
                                label="Account holder relationship description"
                                value={bankingDetails.accountHolderRelationshipDescription}
                            />
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </React.Fragment>
    );
};
const mapStateToProps = (state: State) => {
    const claim = getClaim(state);
    const raClaim = claim as RADeathClaim;
    const bankingDetails =
        (raClaim.lifeAssuredDetails && raClaim.lifeAssuredDetails.bankingDetails) || {};
    return {
        bankingDetails,
        isReadOnly: state.claimSession.readOnly,
    };
};

const mapDispatchToProps = {
    openModal: openModalAction,
};

export const BankingDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(bankingDetailsView));
