import { DateTime } from 'luxon';
import { ClaimDetails, defaultClaimDetails } from '../claim-details-reducer';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { claimSubmit, Claim } from '../../../../../claim';
import { isEmpty } from '../../../../../forms/validations';
import { CommonClaim } from '../../../../../claim/reducers/claim-reducers/retrenchment-claim-reducer';

type SubmitClaimDetailsReturn = Promise<any>;
export type SubmitClaimDetails = (claimDetails: ClaimDetails) => SubmitClaimDetailsReturn;
type SubmitClaimDetailsAction = (
    claimDetails: ClaimDetails
) => ReduxThunkAction<SubmitClaimDetailsReturn>;
export const submitClaimDetailsAction: SubmitClaimDetailsAction = (claimDetails: ClaimDetails) => {
    return async (dispatch, getState) => {
        return await claimSubmit(
            dispatch,
            getState,
            claim => ({
                ...claim,
                claimDetails,
            }),
            validate
        );
    };
};

function validate(claim: Claim) {
    const errors = {} as any;
    if (!('claimDetails' in claim)) {
        return errors;
    }

    let details: ClaimDetails = defaultClaimDetails;
    if (claim.claimType !== 'RADeath') {
        const commonClaim = claim as CommonClaim;
        details = (commonClaim && commonClaim.claimDetails) || defaultClaimDetails;
    }
    if (!details.dateClaimReported || !details.dateOfEvent) {
        return errors;
    }
    const claimDetails = {} as any;
    const claimReportedDate = DateTime.fromJSDate(details.dateClaimReported).startOf('day');
    const eventDate = DateTime.fromJSDate(details.dateOfEvent).startOf('day');

    if (claimReportedDate < eventDate) {
        const errorMessage = 'Date of the event must be before the date the claim was reported';
        claimDetails.dateClaimReported = errorMessage;
        claimDetails.dateOfEvent = errorMessage;
        errors.claimDetails = claimDetails;
    }
    if (!isEmpty(errors)) {
        return {
            'front-end-errors': errors,
        };
    }
    return errors;
}
