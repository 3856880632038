/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Claim, getProperty, getPropertyFromClaim } from '../../claim';
import { State } from '../../redux/root-reducer';
import { Nullable } from '../../shared-types';

export interface IncomePayableCalculation {
    month: Date;
    numberOfDays: number;
    incomeProtectorPremium?: number;
    incomeProtectorSumAssured?: number;
    proRataSumAssured?: number;
    proRataTopUpSumAssured?: number;
    proRataIncomeProtectorPremium?: number;
    proRataIncomeProtectorSumAssured?: number;
    claimBoosterAmount?: number;
    totalPayable: number;
}

export interface IncomeCalculation {
    policyNumber: string;
    benefitCode: string;
    decisionId: string;
    claimBoosterPercentage?: number;
    claimBoosterAmount?: number;
    includeClaimBooster?: boolean;
    incomePayableCalculations?: IncomePayableCalculation[];
}

export const incomeCalculationsReducer = (state: Nullable<IncomeCalculation[]> = null) => {
    return state;
};

export function getIncomeCalculations(state: State) {
    return getProperty(state, 'incomeCalculations', []);
}

export function getIncomeCalculationsFromClaim(claim: Claim) {
    return getPropertyFromClaim(claim, 'incomeCalculations', []);
}
