import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { getClaim } from '../../../../../../claim';
import { State } from '../../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import {
    ClaimantRequirements,
    Correspondence,
    getCorrespondenceFromClaim,
    getRequirementsFromClaim,
} from '../../../../common/requirements-management/reducer';
import { FuneralRequirements } from '../../reducer';
import { Edit } from '@material-ui/icons';
import { OpenAddModal, openAddModalAction } from '../../../../../../form-modal/actions';
import { CommunicationEditView } from './communications-edit-modal';
import { CommunicationsFormModalView } from './communications-form-view';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import { getDisplayDate, getDisplayTime } from '../../../../../../shared-functions';
import { AssessorAllocationContent } from './assessor-allocation-content';
interface DispatchProps {
    openAddModal: OpenAddModal;
}
interface StateProps {
    claimantRequirements: ClaimantRequirements[];
    correspondence: Correspondence;
}

type Props = StyledComponent & DispatchProps & StateProps;
const emailCorrespondenceAllocationView: React.FunctionComponent<Props> = ({
    classes,
    claimantRequirements,
    correspondence,
    openAddModal,
}: Props) => {
    const openCommunicationsModal = () => {
        openAddModal({
            modalContent: (
                <CommunicationsFormModalView
                    title="E-mail Correspondence (assessor allocation)"
                    previewScreen={() => <AssessorAllocationContent />}
                    formView={() => <CommunicationEditView isShowDocumentRequirements={false} />}
                />
            ),
            modalData: {},
        });
    };

    return (
        <Grid container justify="flex-start" className={classes.marginTop}>
            <Grid container justify="flex-start" className={classes.marginTop}>
                <Grid item xs={8} lg={10}>
                    <Typography className={classes.heading} variant="body1">
                        E-mail Correspondence (assessor allocation)
                    </Typography>
                </Grid>
                <Grid item xs={4} lg={2} className={classes.rightAlign}>
                    <Tooltip title="Edit Correspondence">
                        <Button
                            id="EditEmailCorrespondence"
                            color="secondary"
                            onClick={() => {
                                openCommunicationsModal();
                            }}
                        >
                            <Edit />
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.marginTop} container justify="flex-start">
                {correspondence &&
                    correspondence.allocation &&
                    correspondence.allocation.map((allocationItem, index) => (
                        <React.Fragment key={index}>
                            <Grid item md={4} xs={12}>
                                <LabeledValue
                                    label="Claimant Name"
                                    value={allocationItem.claimantName}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <LabeledValue
                                    label="Email Address sent to"
                                    value={allocationItem.to}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <LabeledValue
                                    label="Additional Recipients"
                                    value={allocationItem.ccs}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <LabeledValue
                                    label="Latest Email sent date"
                                    value={getDisplayDate(allocationItem.date)}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <LabeledValue
                                    label="Time email sent "
                                    value={getDisplayTime(allocationItem.date)}
                                />
                            </Grid>
                        </React.Fragment>
                    ))}
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state: State) => ({
    claimantRequirements:
        (getRequirementsFromClaim(getClaim(state)) as FuneralRequirements).claimants || [],
    correspondence: (getCorrespondenceFromClaim(getClaim(state)) as Correspondence) || {},
});

const mapDispatchToProps = {
    openAddModal: openAddModalAction,
};

export const EmailCorrespondenceAllocationView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(emailCorrespondenceAllocationView));
