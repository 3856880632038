import React from 'react'; // eslint-disable-line

import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { connect } from 'react-redux';
import { OpenModal, openModalAction } from '../../../../form-modal/actions';
import { FormModalView } from '../../../../form-modal/views/form-modal';
import { ExpansionPanel } from '../../../../forms/form-components/expansion-panel/expansion-panel';
import { IncomeManagementPlanForm } from '../../../../income-management-plan';
import {
    getIncomeManagementPlan,
    IncomeManagementPlan,
} from '../../../../income-management-plan/reducer';
import { State } from '../../../../redux/root-reducer';
import { getDisplayDate, getPolicyStatusDescription } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import { LabeledValue } from '../../../../controls/labeled-value';
import { ButtonWrapper } from '../../../../forms';

type Props = StyledComponent & {
    managementPlan: IncomeManagementPlan;
    openModal: OpenModal;
    isReadOnly: boolean;
};
const incomeManagementPlanView: React.FunctionComponent<Props> = ({
    classes,
    managementPlan,
    openModal,
    isReadOnly,
}: Props) => {
    return (
        <ExpansionPanel name="Management Plan">
            <Grid container spacing={2}>
                <Grid item xs={12} container>
                    <Grid item xs={10} lg={11}>
                        <Typography variant="subtitle2" className={classes.marginTop}>
                            Income Details
                        </Typography>
                    </Grid>
                    <ButtonWrapper>
                        <Grid item xs={2} lg={1} className={classes.rightAlign}>
                            <Tooltip title="Edit Management Plan">
                                <Button id="EditManagementPlan" color="secondary" onClick={edit}>
                                    <Edit />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </ButtonWrapper>
                </Grid>
                <Grid item xs={3}>
                    <LabeledValue label="Policy Number" value={managementPlan.policyNumber} />
                </Grid>
                <Grid item xs={3}>
                    <LabeledValue
                        label="Policy Status"
                        value={getPolicyStatusDescription(managementPlan.policyStatus)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LabeledValue
                        label="Cease Date"
                        value={getDisplayDate(managementPlan.ceaseDate)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LabeledValue
                        label="Next Review Date"
                        value={getDisplayDate(managementPlan.nextReviewDate)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <LabeledValue
                        label="Review Requirements"
                        value={managementPlan.reviewRequirements}
                    />
                </Grid>
            </Grid>
        </ExpansionPanel>
    );

    function edit() {
        openModal({
            modalContent: <FormModalView formView={IncomeManagementPlanForm} />,
        });
    }
};

const mapStateToProps = (state: State) => ({
    managementPlan: getIncomeManagementPlan(state),
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = {
    openModal: openModalAction,
};

export const IncomeManagementPlanView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(incomeManagementPlanView));
