import { reduceAndSubmitClaim } from '../../../../../claim/actions/reduce-and-submit-claim';
import {
    AddRecordCollectionAction,
    DeleteRecordCollectionAction,
} from '../../../../../redux/collection-reducer';
import { ReduxAction, ReduxThunkAction } from '../../../../../redux/redux-actions';
import { Consultation, Doctor } from '../reducer';
import {
    UPDATE_CONSULTATION_RECORD,
    DELETE_CONSULTATION_RECORD,
    UPDATE_DOCTOR_RECORD,
} from './types';
import { UpdateDoctorRecordAction } from './update-medical-history';

type SubmitReturn = Promise<any>;
export type AddAndSubmitConsultationRecord = (
    consultation: Consultation,
    doctor: Doctor,
    index: number
) => SubmitReturn;
type AddAndSubmitDoctorConsultationThunk = (
    consultation: Consultation,
    doctor: Doctor,
    index: number
) => ReduxThunkAction<SubmitReturn>;
export const addAndSubmitDoctorConsultationThunk: AddAndSubmitDoctorConsultationThunk = (
    consultation: Consultation,
    doctor: Doctor,
    index: number
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        addConsultationActionCreator(consultation, doctor, index)
    );

export type AddDoctorConsultationRecordAction = AddRecordCollectionAction<Consultation>;
export const addConsultationActionCreator = (
    consultation: Consultation,
    doctor: Doctor,
    index: number
): UpdateDoctorRecordAction => {
    if (doctor && doctor.doctorsVisits) {
        doctor.doctorsVisits.push(consultation);
    }
    return {
        type: UPDATE_DOCTOR_RECORD,
        record: doctor,
        index: index,
    };
};

export type UpdateAndSubmitDoctorsVisit = (
    index: number,
    consultation: Consultation,
    doctor: Doctor
) => SubmitReturn;
type UpdateAndSubmitDoctorsVisitAction = (
    index: number,
    consultation: Consultation,
    doctor: Doctor
) => ReduxThunkAction<SubmitReturn>;

export const updateAndSubmitDoctorsVisit: UpdateAndSubmitDoctorsVisitAction = (
    index: number,
    consultation: Consultation,
    doctor: Doctor
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        updateConsultationActionCreator(index, consultation, doctor)
    );

export interface UpdateDoctorConsultationRecordAction extends ReduxAction<Doctor> {
    doctorIndex: number;
}
export type UpdateDoctorsVisit = (
    doctorIndex: number,
    consultation: Consultation,
    doctor: Doctor
) => UpdateDoctorConsultationRecordAction;
export const updateConsultationActionCreator: UpdateDoctorsVisit = (
    doctorIndex: number,
    consultation: Consultation,
    doctor: Doctor
): UpdateDoctorConsultationRecordAction => {
    if (doctor && doctor.doctorsVisits) {
        doctor.doctorsVisits[doctorIndex] = consultation;
    }
    return {
        type: UPDATE_CONSULTATION_RECORD,
        payload: doctor,
        doctorIndex: doctorIndex,
    };
};

export type DeleteAndSubmitDoctorVisit = (index: number, doctor: Doctor) => SubmitReturn;
type DeleteAndSubmitDoctorVisitThunk = (
    index: number,
    doctor: Doctor
) => ReduxThunkAction<SubmitReturn>;
export const deleteAndSubmitDoctorVisitThunk: DeleteAndSubmitDoctorVisitThunk = (
    index: number,
    doctor: Doctor
) => (dispatch, getState) =>
    reduceAndSubmitClaim(dispatch, getState, deleteConsultationActionCreator(index, doctor));

export type DeleteDoctorVisitAction = DeleteRecordCollectionAction;
export const deleteConsultationActionCreator = (
    index: number,
    doctor: Doctor
): DeleteDoctorVisitAction => {
    if (doctor && doctor.doctorsVisits) {
        doctor.doctorsVisits.splice(index, 1);
    }
    return {
        type: DELETE_CONSULTATION_RECORD,
        record: doctor,
        index: index,
    };
};
