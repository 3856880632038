import React from 'react'; // eslint-disable-line

import { Divider, Grid, Paper, Typography, withStyles } from '@material-ui/core';
import { Field } from 'react-final-form';
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { getClaim } from '../../claim';
import { FullWidthCheckBoxField, FullWidthSearchSelectField, WhenFieldChanges } from '../../forms';
import { FormHeading } from '../../forms/form-components/form-heading';
import { FullWidthReadonlyField } from '../../forms/form-components/full-width-readonly-field/full-width-readonly-field';
import { conditionalRequired } from '../../forms/validations';
import { State } from '../../redux/root-reducer';
import { formatCurrency, getDisplayDate } from '../../shared-functions';
import styles, { StyledComponent } from '../../styles/styles';
import { getLifeAssuredDetails } from '../reducer';

interface PolicyBenefits {
    policyNumber: string;
    benefits: { value: string; label: string }[];
}

interface OwnProps {}

interface StateProps {
    policyBenefits: PolicyBenefits[];
}

interface DispatchProps {}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const previousPaymentsView: React.FunctionComponent<Props> = ({
    policyBenefits,
    classes,
}: Props) => (
    <FieldArray name="previousPayments">
        {arrayRenderProps => (
            <Paper className={classes.paper}>
                <FormHeading text="Previous Payments" />
                {arrayRenderProps.fields.length === 0 ? (
                    <Typography>There are no previous payments</Typography>
                ) : (
                    <PreviousPaymentsList
                        policyBenefits={policyBenefits}
                        {...arrayRenderProps}
                        classes={classes}
                    />
                )}
            </Paper>
        )}
    </FieldArray>
);

type PreviousPaymentsListProps = FieldArrayRenderProps<any, any> &
    StyledComponent & {
        policyBenefits: PolicyBenefits[];
    };
const PreviousPaymentsList: React.FunctionComponent<PreviousPaymentsListProps> = ({
    policyBenefits,
    fields: payments,
    classes,
}: PreviousPaymentsListProps) => (
    <React.Fragment>
        {payments &&
            payments.map((name, index) => (
                <Grid container spacing={3} key={name}>
                    {index !== 0 && (
                        <Grid item xs={12}>
                            <Divider className={classes.marginBottom} />
                        </Grid>
                    )}
                    <Grid container spacing={3} item xs={10} lg={10} alignItems="flex-end">
                        <Grid item xs={6}>
                            <FullWidthReadonlyField name={`${name}.policyNumber`} />
                        </Grid>
                        <Grid item xs={6}>
                            <Field name={`${name}.policyNumber`}>
                                {({ input: { value: policyNumber } }) => {
                                    const benefits = policyBenefits.find(
                                        pb => pb.policyNumber === policyNumber
                                    );
                                    const benefitOptions = !benefits ? [] : benefits.benefits;
                                    return (
                                        <FullWidthSearchSelectField
                                            name={`${name}.benefit`}
                                            options={benefitOptions}
                                            validate={conditionalRequired(
                                                values =>
                                                    !!values['previousPayments'][index]
                                                        .currentLifeAssured
                                            )}
                                        />
                                    );
                                }}
                            </Field>
                            <WhenFieldChanges
                                field={`${name}.currentLifeAssured`}
                                becomes={false}
                                set={`${name}.benefit`}
                                to={null}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Field name={`${name}`}>
                                {({ input: { value: previousPayment } }) => {
                                    let paymentDate = getDisplayDate(
                                        previousPayment.firstPaymentDate
                                    );
                                    if (previousPayment.lastPaymentDate) {
                                        paymentDate += ` - ${getDisplayDate(
                                            previousPayment.lastPaymentDate
                                        )}`;
                                    }
                                    return (
                                        <FullWidthReadonlyField
                                            name={`${name}.paymentDate`}
                                            value={paymentDate}
                                        />
                                    );
                                }}
                            </Field>
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthReadonlyField
                                name={`${name}.amount`}
                                format={formatCurrency}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={2} lg={2}>
                        <FullWidthCheckBoxField
                            name={`${name}.currentLifeAssured`}
                            validate={conditionalRequired(
                                values => values['previousPayments'][index].benefit
                            )}
                        />
                        <WhenFieldChanges
                            field={`${name}.benefit`}
                            condition={benefit => !!benefit}
                            set={`${name}.currentLifeAssured`}
                            to={true}
                        />
                    </Grid>
                </Grid>
            ))}
    </React.Fragment>
);

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => {
    const lifeAssuredContracts = getLifeAssuredDetails(state).contracts;
    const contracts = getClaim(state).contracts;
    const policyBenefits: PolicyBenefits[] = lifeAssuredContracts
        .filter(contract => contract.contractNumber)
        .map(contract => ({
            policyNumber: contract.contractNumber as string,
            benefits:
                contract && contract.benefits
                    ? contract.benefits.map(benefit => ({
                          value: benefit.name as string,
                          label: benefit.name as string,
                      }))
                    : [],
        }));

    contracts.forEach(contract => {
        const conIndex = policyBenefits.findIndex(
            pol => pol.policyNumber === contract.contractNumber
        );
        if (conIndex !== -1) {
            contract.benefits.forEach(benefit => {
                const benIndex = policyBenefits[conIndex].benefits.findIndex(
                    ben => ben.value === benefit.name
                );
                if (benIndex === -1) {
                    policyBenefits[conIndex].benefits.push({
                        value: benefit.name,
                        label: benefit.name,
                    });
                }
            });
        } else {
            policyBenefits.push({
                policyNumber: contract.contractNumber as string,
                benefits: contract.benefits.map(benefit => ({
                    value: benefit.name,
                    label: benefit.name,
                })),
            });
        }
    });

    return {
        policyBenefits,
    };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {};

export const PreviousPaymentsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(previousPaymentsView));
