import { ReduceCollectionRecordAction } from '../../../../../../../redux/collection-record-reducer';
import { UpdateRecordCollectionAction } from '../../../../../../../redux/collection-reducer';
import { UnderwritingNonDisclosure } from '../underwriting-non-disclosure-model';
import { UPDATE_UNDERWRITING_NON_DISCLOSURE } from './underwriting-non-disclosure-action-types';

export type UpdateUnderwritingNonDisclosureAction = UpdateRecordCollectionAction<UnderwritingNonDisclosure>;

export type UpdatePolicyUnderwritingNonDisclosureAction = ReduceCollectionRecordAction<UpdateUnderwritingNonDisclosureAction>;

export const updateUnderwritingNonDisclosureActionCreator = (
    policyIndex: number,
    underwritingNonDisclosureIndex: number,
    underwritingNonDisclosure: UnderwritingNonDisclosure
): UpdatePolicyUnderwritingNonDisclosureAction => {
    return {
        type: UPDATE_UNDERWRITING_NON_DISCLOSURE,
        payload: {
            type: UPDATE_UNDERWRITING_NON_DISCLOSURE,
            record: underwritingNonDisclosure,
            index: underwritingNonDisclosureIndex,
        },
        index: policyIndex,
    };
};
