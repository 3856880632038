import React from 'react'; // eslint-disable-line

import { Grid, Paper, Typography, withStyles, Button, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { State } from '../../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { Edit } from '@material-ui/icons';
import { FormModalView } from '../../../../../../form-modal/views/form-modal';
import { OpenModal, openModalAction } from '../../../../../../form-modal/actions';
import { OccupationDetailsEditModal } from './occupation-edit-details';
import { OccupationDetails, getOccupationDetails } from '../../reducer';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import { ButtonWrapper } from '../../../../../../forms';

type Props = StyledComponent & {
    isReadOnly: boolean;
    openModal: OpenModal;
    occupationDetails: OccupationDetails;
};

const occupationDetailsView: React.FunctionComponent<Props> = ({
    classes,
    openModal,
    occupationDetails,
}: Props) => {
    const edit = () => {
        openModal({
            modalContent: <FormModalView formView={OccupationDetailsEditModal} />,
        });
    };
    return (
        <React.Fragment>
            <Grid container justify="space-between" className={classes.marginTop2}>
                <Grid container item xs={10} alignItems="center">
                    <Typography variant="subtitle2">Occupation Details</Typography>
                </Grid>
                <ButtonWrapper>
                    <Grid container item xs={2} alignItems="center" justify="flex-end">
                        <Tooltip title="Edit Occupation Details">
                            <Button id="EditOccupationDetails" color="secondary" onClick={edit}>
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            </Grid>
            <Paper className={classes.paper}>
                <Grid container justify="flex-start">
                    <Grid item xs={6}>
                        <LabeledValue
                            label="Occupation at application stage"
                            value={occupationDetails.occupationAtApplication}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <LabeledValue
                            label="Was the deceased employed at the date of death?"
                            value={occupationDetails.isDeceasedEmployed}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <LabeledValue
                            label="Please state the deceased occupation at date of death"
                            value={occupationDetails.deceasedOccupation}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <LabeledValue
                            label="Please state the deceased employer at date of death"
                            value={occupationDetails.deceasedEmployer}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    );
};
const mapStateToProps = (state: State) => ({
    occupationDetails: getOccupationDetails(state),
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = { openModal: openModalAction };

export const OccupationDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(occupationDetailsView));
