import {
    combineValidationErrors,
    customRequired,
    emailFormatValidator,
    ErrorObject,
    phoneFormatValidator,
    requiredValidator,
    ValidateAny,
} from '../../forms/validations';
import { ContactDetails } from '../contact-details';

const commRequired = customRequired('At least 1 valid method of communication is required');

export default function validateContactDetails(
    contactDetails: ContactDetails,
    validateCommunication = true
): ErrorObject {
    let errors: ErrorObject = {};

    errors.firstName = contactDetails.lastName
        ? requiredValidator(contactDetails.firstName)
        : undefined;
    errors.lastName = contactDetails.firstName
        ? requiredValidator(contactDetails.lastName)
        : undefined;

    const commErrors = validateCommunication
        ? ValidateAny(commRequired, contactDetails, 'cellphoneNumber', 'emailAddress')
        : undefined;
    errors = {
        ...errors,
        ...commErrors,
    };

    if (contactDetails.telephoneNumber) {
        errors.telephoneNumber = phoneFormatValidator(contactDetails.telephoneNumber);
    }
    if (contactDetails.cellphoneNumber) {
        errors.cellphoneNumber = phoneFormatValidator(contactDetails.cellphoneNumber);
    }

    errors.emailAddress = combineValidationErrors(
        errors.emailAddress,
        emailFormatValidator(contactDetails.emailAddress)
    );
    return errors;
}
