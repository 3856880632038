// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react';
import { withStyles, Grid } from '@material-ui/core';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { FormDetails, getFormDetails } from '../form-details-reducer';
import { connect } from 'react-redux';
import { State } from '../../../../../redux/root-reducer';
import {
    Condition,
    FullWidthTextField,
    True,
    FullWidthRadioGroup,
    RadioOption,
    WhenFieldChanges,
} from '../../../../../forms';
import { requiredValidator } from '../../../../../forms/validations';
import { NumberCounterText } from '../../../../../forms/form-components/number-input';
import { NumberMask } from '../../../../../forms/input-masks';

interface BaseProps {
    formDetails: FormDetails;
    prefix: string;
}

interface Props extends BaseProps, StyledComponent {}

const otherDetailsEdit: React.FunctionComponent<Props> = ({
    prefix,
    formDetails,
    classes,
}: Props) => {
    const onValueChange = (e: any) => {
        return e;
    };

    return (
        <Grid container spacing={4} alignItems="flex-start" className={classes.root}>
            <Grid item xs={12} sm={6}>
                <FullWidthTextField
                    label="Highest Qualification"
                    validate={requiredValidator}
                    name={`${prefix}.highestQualification`}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FullWidthRadioGroup
                    label="Insolvency Declaration?"
                    name={`${prefix}.hasInsolvency`}
                >
                    <RadioOption value="Yes" label="Yes" />
                    <RadioOption value="No" label="No" />
                </FullWidthRadioGroup>
            </Grid>

            <Grid item xs={12} sm={6}>
                <FullWidthRadioGroup
                    label="Do you consume alcohol?"
                    name={`${prefix}.consumesAlcohol`}
                >
                    <RadioOption value="Yes" label="Yes" />
                    <RadioOption value="No" label="No" />
                </FullWidthRadioGroup>
                <WhenFieldChanges
                    field={`${prefix}.consumesAlcohol`}
                    set={`${prefix}.totCount`}
                    to={''}
                />
                <WhenFieldChanges
                    field={`${prefix}.consumesAlcohol`}
                    set={`${prefix}.wineGlassCount`}
                    to={''}
                />
                <WhenFieldChanges
                    field={`${prefix}.consumesAlcohol`}
                    set={`${prefix}.beerCanOrCider`}
                    to={''}
                />
                <Condition when={`${prefix}.consumesAlcohol`} is="Yes">
                    <True>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                                <NumberCounterText
                                    name={`${prefix}.totCount`}
                                    label="Spirits/Tots per week"
                                    validate={requiredValidator}
                                    onChange={onValueChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <NumberCounterText
                                    name={`${prefix}.wineGlassCount`}
                                    label="Wines/Glass per week"
                                    validate={requiredValidator}
                                    onChange={onValueChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <NumberCounterText
                                    name={`${prefix}.beerCanOrCider`}
                                    label="Beers/Ciders per week"
                                    validate={requiredValidator}
                                    onChange={onValueChange}
                                />
                            </Grid>
                        </Grid>
                    </True>
                </Condition>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FullWidthTextField
                    name={`${prefix}.hoursWorkedPerWeek`}
                    label="Average Weekly Work Hours (pre-claim)"
                    mask={NumberMask}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FullWidthRadioGroup
                    label="Advice to Reduce Alcohol Consumption?"
                    name={`${prefix}.receivedAdviceToReduceAlcohol`}
                >
                    <RadioOption value="Yes" label="Yes" />
                    <RadioOption value="No" label="No" />
                </FullWidthRadioGroup>
                <WhenFieldChanges
                    field={`${prefix}.receivedAdviceToReduceAlcohol`}
                    set={`${prefix}.alcoholAdviceDetails`}
                    to={''}
                />
                <Condition when={`${prefix}.receivedAdviceToReduceAlcohol`} is="Yes">
                    <True>
                        <FullWidthTextField
                            name={`${prefix}.alcoholAdviceDetails`}
                            label="Provide Details"
                            validate={requiredValidator}
                            multiline
                        />
                    </True>
                </Condition>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FullWidthRadioGroup
                    label="Practice any Avocations?"
                    name={`${prefix}.practiceAvocation`}
                >
                    <RadioOption value="Yes" label="Yes" />
                    <RadioOption value="No" label="No" />
                </FullWidthRadioGroup>
                <WhenFieldChanges
                    field={`${prefix}.practiceAvocation`}
                    set={`${prefix}.practiceAvocationDetails`}
                    to={''}
                />
                <Condition when={`${prefix}.practiceAvocation`} is="Yes">
                    <True>
                        <FullWidthTextField
                            name={`${prefix}.practiceAvocationDetails`}
                            label="Provide Details"
                            validate={requiredValidator}
                            multiline
                        />
                    </True>
                </Condition>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FullWidthRadioGroup label="Retired at Claim Date?" name={`${prefix}.wasRetired`}>
                    <RadioOption value="Yes" label="Yes" />
                    <RadioOption value="No" label="No" />
                </FullWidthRadioGroup>
                <WhenFieldChanges
                    field={`${prefix}.wasRetired`}
                    set={`${prefix}.retirementFullDetails`}
                    to={''}
                />
                <Condition when={`${prefix}.wasRetired`} is="Yes">
                    <True>
                        <FullWidthTextField
                            name={`${prefix}.retirementFullDetails`}
                            label="Provide Details"
                            validate={requiredValidator}
                            multiline
                        />
                    </True>
                </Condition>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state: State) => ({
    formDetails: getFormDetails(state),
});

const mapDispatchToProps = {};

export const OtherDetailsEdit = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(otherDetailsEdit));
