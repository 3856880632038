import { claimSubmit, Claim } from '../../claim';
import { updateRecordReduction } from '../../redux/reductions';
import { ReduxThunkAction } from '../../redux/redux-actions';

type SubmitReturn = Promise<any>;

export type UpdatePayment = (paymentIndex: number) => SubmitReturn;
type UpdatePaymentAction = (paymentIndex: number) => ReduxThunkAction<SubmitReturn>;

export const updatePaymentAction: UpdatePaymentAction = (paymentIndex: number) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, getUpdatedClaim(paymentIndex));
    };
};

function getUpdatedClaim(paymentIndex: number): (claim: Claim) => Claim {
    return (claim: Claim) => {
        const currentPayments = (claim.payments && claim.payments) || [];
        const currentPayment = currentPayments[paymentIndex];

        const updatedPayment = {
            ...currentPayment,
            riskRating: {
                ...currentPayment.riskRating,
                override: true,
            },
        };

        return {
            ...claim,
            payments: updateRecordReduction(currentPayments, updatedPayment, paymentIndex),
        };
    };
}
