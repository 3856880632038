import React from 'react'; // eslint-disable-line

import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { AddFormModal } from '../../form-modal';
import { getRecordIndex } from '../../form-modal/reducer';
import { State } from '../../redux/root-reducer';
import { AddFinalDecision, addFinalDecisionAction } from '../actions/add-final-decision';
import {
    FinalDecisionDetails,
    getRetrospectiveUnderwritingDecision,
    PolicyBenefitFinalDecisions,
} from '../reducer';
import { FinalDecisionDetailsView } from './final-decision-details';

interface Props {
    policyBenefitFinalDecisions: PolicyBenefitFinalDecisions[];
    policyBenefitFinalDecisionsIndex: number;
    addFinalDecision: AddFinalDecision;
}

const FinalDecisionDetailsAddFormModal = AddFormModal<FinalDecisionDetails>();
const finalDecisionDetailsAddModal: React.FunctionComponent<Props> = ({
    policyBenefitFinalDecisions,
    policyBenefitFinalDecisionsIndex,
    addFinalDecision,
}: Props) => {
    const blankDecision = getBlankDecison(
        policyBenefitFinalDecisions,
        policyBenefitFinalDecisionsIndex
    );
    return (
        <FinalDecisionDetailsAddFormModal
            recordDetailView={() => <FinalDecisionDetailsView />}
            addRecord={(values: FinalDecisionDetails) => {
                addFinalDecision(values, policyBenefitFinalDecisionsIndex);
            }}
            blankRecord={blankDecision}
        />
    );
};

function getBlankDecison(
    policyBenefitFinalDecisions: PolicyBenefitFinalDecisions[],
    policyBenefitFinalDecisionsIndex: number
): FinalDecisionDetails {
    const policyBenefitFinalDecision =
        policyBenefitFinalDecisions[policyBenefitFinalDecisionsIndex];
    return {
        id: uuid(),
        policyNumber: policyBenefitFinalDecision.policyNumber,
        benefitName: policyBenefitFinalDecision.benefitName,
    };
}

const mapStateToProps = (state: State) => ({
    policyBenefitFinalDecisions: getRetrospectiveUnderwritingDecision(state)
        .policyBenefitFinalDecisions,
    policyBenefitFinalDecisionsIndex: getRecordIndex(state),
});

const mapDispatchToProps = {
    addFinalDecision: addFinalDecisionAction,
};

export const FinalDecisionDetailsAddModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(finalDecisionDetailsAddModal);
