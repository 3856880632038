import { Divider, Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { Field } from 'react-final-form';
import {
    FullWidthRadioGroup,
    FullWidthTextField,
    RadioOption,
    WhenFieldChanges,
} from '../../../../../forms';
import { CurrencyMask } from '../../../../../forms/input-masks';
import { composeValidators, maxValue, requiredValidator } from '../../../../../forms/validations';
import { convertToNumber } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';

const retrenchmentClaimCalculatorView = ({ classes }: StyledComponent) => {
    const nameRequiredMessage = 'PAYE can not be greater than basic salary';
    return (
        <Grid item container spacing={5}>
            <Grid item xs={12}>
                <Typography variant="h5" className={classes.marginTop}>
                    Proven Highest Salary
                </Typography>
                <Divider className={classes.marginTop} />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name="basicSalary"
                    validate={requiredValidator}
                    mask={CurrencyMask}
                    parse={convertToNumber}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    name="basicSalary"
                    subscription={{ value: true }}
                    component={({ input: { value: basicSalary } }) => (
                        <FullWidthTextField
                            name="paye"
                            mask={CurrencyMask}
                            parse={convertToNumber}
                            validate={composeValidators(
                                requiredValidator,
                                maxValue(basicSalary, nameRequiredMessage)
                            )}
                        />
                    )}
                />
            </Grid>
            <Field
                name="basicSalary"
                subscription={{ value: true }}
                component={({ input: { value: _basicSalary } }) => (
                    <WhenFieldChanges
                        field="paye"
                        set="incomeAfterTax"
                        to={(paye: number) => (_basicSalary - paye).toFixed(2)}
                    />
                )}
            />
            <Field
                name="paye"
                subscription={{ value: true }}
                component={({ input: { value: _paye } }) => (
                    <WhenFieldChanges
                        field="basicSalary"
                        set="incomeAfterTax"
                        to={(basicSalary: number) => (basicSalary - _paye).toFixed(2)}
                    />
                )}
            />
            <Grid item xs={6}>
                <FullWidthTextField
                    name="incomeAfterTax"
                    mask={CurrencyMask}
                    parse={convertToNumber}
                    disabled
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthRadioGroup name="claimPercentage" validate={requiredValidator}>
                    <RadioOption value="0.75" label="75%" />
                    <RadioOption value="1" label="100%" />
                </FullWidthRadioGroup>
            </Grid>
            <Field
                name="incomeAfterTax"
                subscription={{ value: true }}
                component={({ input: { value: _incomeAfterTax } }) => (
                    <WhenFieldChanges
                        field="claimPercentage"
                        set="claimAmount"
                        to={(perc: number) => (_incomeAfterTax * perc).toFixed(2)}
                    />
                )}
            />
            <Field
                name="claimPercentage"
                subscription={{ value: true }}
                component={({ input: { value: _perc } }) => (
                    <WhenFieldChanges
                        field="incomeAfterTax"
                        set="claimAmount"
                        to={(incomeAfterTax: number) => (incomeAfterTax * _perc).toFixed(2)}
                    />
                )}
            />
            <Grid item xs={6}>
                <FullWidthTextField
                    name="claimAmount"
                    mask={CurrencyMask}
                    parse={convertToNumber}
                    disabled
                />
            </Grid>
        </Grid>
    );
};

export const RetrenchmentClaimCalculatorView = withStyles(styles)(retrenchmentClaimCalculatorView);
