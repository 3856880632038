import axios from 'axios';
import { env } from '../../env';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { clientsLoadedThunkAction } from './clients-loaded';

const searchUi = `${env().CLAIMS_API}/api/Personality/FindClientsByPolicy`;

export interface FindClientsByPolicyCriteria {
    policyNumber?: string;
}

type FindClientsByPolicyReturn = Promise<any>;
export type FindClientsByPolicy = (
    criteria: FindClientsByPolicyCriteria,
    isFuneral?: boolean
) => FindClientsByPolicyReturn;
type FindClientsByPolicyAction = (
    criteria: FindClientsByPolicyCriteria,
    isFuneral?: boolean
) => ReduxThunkAction<FindClientsByPolicyReturn>;
export const findClientsByPolicyAction: FindClientsByPolicyAction = (
    criteria: FindClientsByPolicyCriteria,
    isFuneral?: boolean
) => {
    return async dispatch => {
        const response = await axios.get(searchUi, {
            params: { policyNumber: criteria.policyNumber },
        });
        if (!response.data || response.data.length <= 0) {
            throw new Error(
                'No clients with valid benefits to continue the claims process were found.'
            );
        }
        dispatch(
            clientsLoadedThunkAction(
                response.data,
                { ...criteria, searchBy: 'Policy Number' },
                isFuneral
            )
        );
        return {};
    };
};
