import React from 'react'; // eslint-disable-line
import styles, { StyledComponent } from '../../../../../styles/styles';
import { Grid, withStyles, Typography, Divider } from '@material-ui/core';
import { State } from '../../../../../redux/root-reducer';
import { connect } from 'react-redux';
import { LiquidAndDistribution, DistributionDictionary } from '../liquid-and-distribution-reducer';
import { OpenModal, openModalAction } from '../../../../../form-modal/actions';
import { Dependant, getDependants } from '../../dependants/reducer';
import { getRADeathClaim } from '../../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { formatCurrency } from '../../../../../shared-functions';
import { LabeledValue } from '../../../../../controls/labeled-value';
import {
    getPosibleDependentName,
    getDependentDistributions,
    getDependentStatus,
} from './distribution-normal-view';
interface DispatchProps {
    openModal: OpenModal;
}
interface OwnProps {}

type Props = StyledComponent &
    DispatchProps &
    OwnProps & {
        liquidAndDistribution: LiquidAndDistribution;
        dependents: Dependant[];
    };
const distributionTableView: React.FunctionComponent<Props> = ({
    classes,
    liquidAndDistribution,
    dependents,
}: Props) => {
    const liquidAndDistributionDtails: any[] = [];
    liquidAndDistributionDtails.push(liquidAndDistribution);
    let policyLiquidAndDistribution: DistributionDictionary[] = [];
    if (Array.isArray(liquidAndDistribution)) {
        policyLiquidAndDistribution = getDependentDistributions(liquidAndDistribution);
    }
    return (
        <Grid container spacing={0} justify="flex-end" className={classes.marginTop}>
            {policyLiquidAndDistribution &&
                policyLiquidAndDistribution.length > 0 &&
                policyLiquidAndDistribution.map((record, index) => {
                    let dependentStatus;
                    const displayName = getPosibleDependentName(record.id, dependents);
                    const dependent = dependents.find(c => c.id === record.id);
                    if (dependent !== undefined) {
                        dependentStatus = getDependentStatus(dependent);
                    }
                    const marginSize = index > 0 ? '30px' : '0px';
                    const age =
                        dependent && dependent.personalDetails ? dependent.personalDetails.age : 0;
                    return (
                        <Grid container justify="flex-start" style={{ margin: '5px' }} key={index}>
                            <Grid item xs={3} lg={3} style={{ marginTop: marginSize }}>
                                <Typography
                                    variant="h6"
                                    style={{ fontSize: '1rem' }}
                                >{`${displayName}`}</Typography>
                                <Typography>{`${dependentStatus} | Age ${age}`}</Typography>
                            </Grid>

                            <Grid
                                item
                                xs={9}
                                container
                                justify="flex-start"
                                style={{ marginTop: marginSize }}
                            >
                                {record.details.length > 0 &&
                                    record.details.map((currentRecord, index) => {
                                        const description = index === 0 ? '' : '';
                                        const amountPayable = index === 0 ? '' : '';
                                        return (
                                            <Grid item xs={3} key={index}>
                                                <Grid item xs={4} lg={4}>
                                                    <LabeledValue
                                                        label={description}
                                                        value={currentRecord.description}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} lg={4}>
                                                    <LabeledValue
                                                        label={amountPayable}
                                                        value={formatCurrency(currentRecord.amount)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                            <Grid item xs={12}>
                                <Divider style={{ height: '2px' }} />
                            </Grid>
                            <Grid item xs={12} container spacing={0} justify="flex-start">
                                <Grid item xs={10} lg={10}>
                                    <Typography variant="h6">Total</Typography>
                                </Grid>
                                <Grid item xs={2} lg={2} style={{ marginLeft: '-22px' }}>
                                    <Typography variant="h6">
                                        {formatCurrency(record.totalAmount)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider style={{ height: '2px' }} />
                            </Grid>
                        </Grid>
                    );
                })}
        </Grid>
    );
};
const mapStateToProps = (state: State) => ({
    lifeAssuredDetails: getRADeathClaim(state).lifeAssuredDetails,
    dependents: getDependants(state),
});

const mapDispatchToProps = { openModal: openModalAction };

export const DistributionTableView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(distributionTableView));
