import axios from 'axios';
import React from 'react'; // eslint-disable-line
import { claimUpdatedThunk } from '../../../../../claim';
import { openDialogAction } from '../../../../../confirmation-dialog';
import { env } from '../../../../../env';
import { hideLoadingAction, showLoadingAction } from '../../../../../forms/loading-screen/actions';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { JsonErrorView } from '../../../../../workflow-navigation/views/json-error-view';

type VerifyAccountReturn = Promise<any>;
export type VerifyAccount = (beneficiaryId?: string) => VerifyAccountReturn;
type VerifyAccountAction = (beneficiaryId?: string) => ReduxThunkAction<VerifyAccountReturn>;

export const verifyAccountAction: VerifyAccountAction = (beneficiaryId?: string) => {
    return async (dispatch, getState) => {
        dispatch(showLoadingAction());
        const claim = getState().claimSession.claim;
        const verifyUri = `${env().CLAIMS_API}/api/Claim/${
            claim.claimId
        }/VerifyAccount?beneficiaryId=${beneficiaryId}`;
        await axios
            .get(verifyUri)
            .then(response => {
                if (response.data) {
                    dispatch(claimUpdatedThunk(response.data));
                }
            })
            .catch(error => {
                const errorResponse = getExceptionErrorMessage(error);
                dispatch(
                    openDialogAction({
                        content: {
                            title: 'Error verify bank account',
                            body: React.createElement(JsonErrorView, {
                                errorMessage: errorResponse.errorMessage,
                                jsonString: errorResponse.errorData,
                            } as any),
                            error: true,
                        },
                    })
                );
            })
            .then(() => dispatch(hideLoadingAction()));
    };
};

function getExceptionErrorMessage(responseError: any) {
    if (responseError.response) {
        if (
            responseError.response.headers &&
            responseError.response.headers.hasOwnProperty('error-message')
        ) {
            return {
                errorMessage: responseError.response.headers['error-message'],
                errorData: responseError.response.data,
            };
        }

        if (responseError.response.statusText) {
            return {
                errorMessage: responseError.response.statusText,
                errorData:
                    responseError.response.status === 422 ? responseError.response.data : null,
            };
        }
    }

    if (responseError.message) {
        return {
            errorMessage: responseError.message,
            errorData: null,
        };
    }

    return {
        errorMessage: 'An unknown error occured while verifying bank account',
        errorData: null,
    };
}
