import React from 'react'; // eslint-disable-line

import { Grid, Paper, withStyles, Typography } from '@material-ui/core';
import { Condition, False, FormHeading, FullWidthCheckBoxField } from '../../../../../forms';
import { AddressDetailsSubForm } from '../../../../../forms/sub-forms/address-details';
import styles, { StyledComponent } from '../../../../../styles/styles';

interface AddressProps extends StyledComponent {
    readonly propertyPrefix?: string;
    readonly isAnOrganisation?: boolean;
}

const addressDetails: React.FunctionComponent<AddressProps> = ({
    classes,
    isAnOrganisation,
    propertyPrefix = '',
}: AddressProps) => {
    const fullPropertyPrefix = propertyPrefix && propertyPrefix !== '' ? propertyPrefix + '.' : '';
    return (
        <Paper className={classes.paper}>
            <FormHeading text="Address Details" />
            <Grid container spacing={5} justify="flex-start">
                {isAnOrganisation ? (
                    <AddressDetailsSubForm
                        addressPropertyName={`${fullPropertyPrefix}organisationDetails.address`}
                        required
                    />
                ) : (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Typography className={classes.marginTop} variant="subtitle2">
                                Residential Address
                            </Typography>
                        </Grid>
                        <AddressDetailsSubForm
                            addressPropertyName={`${fullPropertyPrefix}residentialAddress`}
                            required
                            addressType="residential"
                        />
                        <Grid item xs={12}>
                            <Typography className={classes.marginTop} variant="subtitle2">
                                Postal Address
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FullWidthCheckBoxField
                                name={`${fullPropertyPrefix}personalDetails.sameAsResidential`}
                            />
                        </Grid>
                        <Condition
                            when={`${fullPropertyPrefix}personalDetails.sameAsResidential`}
                            is={true}
                        >
                            <False>
                                <AddressDetailsSubForm
                                    addressPropertyName="claimantDetails.postalAddress"
                                    required
                                />
                            </False>
                        </Condition>
                    </React.Fragment>
                )}
            </Grid>
        </Paper>
    );
};

export const AddressDetailsView = withStyles(styles)(addressDetails);
