import { Button, Divider, Grid, Paper, Typography, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { FormApi } from 'final-form';
import React from 'react'; // eslint-disable-line
import { FormSpy } from 'react-final-form';
import { connect } from 'react-redux';
import { DialogContentDetails, OpenDialog, openDialogAction } from '../../confirmation-dialog';
import { GenericForm } from '../../forms';
import { State } from '../../redux/root-reducer';
import { OnSubmitSuccessProps } from '../../workflow-navigation';
import {
    CloseModal,
    closeModalAction,
    RecordView,
    SaveAction,
    UpdateCurrentModalData,
    updateCurrentModalDataAction,
} from '../actions';
import { getModalData } from '../reducer';
import styles, { StyledComponent } from '../styles';

type GridSizes = 3 | 4 | 5 | 6 | 12;

export interface ModalViewProps {
    name: string;
    view: React.ReactNode | RecordView;
}

const defaultSaveDialogDetails: DialogContentDetails = {
    body: 'Current changes will be lost, would you like to save them?',
    title: 'Warning Unsaved Changes',
};

interface ComponentState {
    readonly screenIndex: number;
}

interface InnerProps<TValues> extends ComponentState, StyledComponent {
    closeModal: CloseModal;
    heading: string;
    modalViews: ModalViewProps[];
    openDialog: OpenDialog;
    saveAction: SaveAction<TValues>;
    showSave?: boolean;
    updateModalData: UpdateCurrentModalData;
}

interface Props<TValues> extends OnSubmitSuccessProps, InnerProps<TValues> {
    validate?: (values: TValues) => Record<string, any> | Promise<Record<string, any>>;
}

interface FormProps<TValues> {
    readonly pristine: boolean;
    readonly form: FormApi<TValues | undefined>;
}

type InnerFormProps<TValues> = InnerProps<TValues> & FormProps<TValues>;

class MultiFormModalWrapper<TValues> extends React.Component<Props<TValues>, ComponentState> {
    public constructor(props: Props<TValues>) {
        super(props);
        this.state = {
            screenIndex: this.props.screenIndex,
        };
    }

    public render() {
        const Form = GenericForm<TValues>();
        const {
            validate,
            classes,
            heading,
            modalViews,
            showSave = false,
            saveAction,
            openDialog,
            closeModal,
            updateModalData,
            ...rest
        }: Props<TValues> = this.props;
        const screenIndex = this.state.screenIndex;

        return (
            <Form onSubmit={this.onSubmit} validate={validate} {...rest}>
                {() =>
                    FormSpy<TValues | undefined>({
                        subscription: { pristine: true },
                        render: ({ form, pristine }) =>
                            this.addFormModal({
                                classes,
                                closeModal,
                                form,
                                heading,

                                modalViews,
                                openDialog,
                                pristine,
                                saveAction,
                                screenIndex,
                                showSave,
                                updateModalData,
                            }),
                    })
                }
            </Form>
        );
    }

    private submitFunction: SaveAction<TValues> = () => {};
    private onSubmit = (values?: TValues) => {
        if (values) {
            return this.submitFunction(values);
        }
    };

    private addFormModal: React.FunctionComponent<InnerFormProps<TValues>> = ({
        classes,
        closeModal,
        form,
        heading,
        modalViews,
        openDialog,
        pristine,
        saveAction,
        screenIndex,
        showSave,
        updateModalData,
    }: InnerFormProps<TValues>) => {
        const openSaveDialog = (saveAction: () => void, dontSaveAction: () => void) => {
            openDialog({
                canCancel: true,
                content: defaultSaveDialogDetails,
                noAction: { action: dontSaveAction },
                yesAction: { action: saveAction },
            });
        };

        const save = async (values: TValues) => {
            return await saveAction(values);
        };

        const saveAndClose = async (values: TValues) => {
            const result = await save(values);
            if (!result || Object.keys(result).length === 0) {
                closeModal();
            }
            return result;
        };

        const saveAndMove = async (values: TValues, index: number) => {
            await save(values);
            move(index);
        };

        const move = (newIndex: number) => {
            if (newIndex >= 0 && newIndex < modalViews.length) {
                this.setState({ screenIndex: newIndex });
                updateModalData({ screenIndex: newIndex });
            }
        };

        const confirmMove = (values: TValues, newIndex: number) => {
            if (pristine) {
                move(newIndex);
            } else {
                openSaveDialog(
                    async () => {
                        await saveAction(values);
                        move(newIndex);
                    },
                    () => move(newIndex)
                );
            }
        };

        const canMoveBack = () => {
            return screenIndex > 0;
        };

        const canMoveNext = () => {
            return screenIndex + 1 < modalViews.length;
        };

        const moveToIndex = (index: number) => {
            submitForm(values => confirmMove(values, index));
        };

        const submitForm = (func: SaveAction<TValues>) => {
            this.submitFunction = func;
            form.submit();
        };

        const currentView =
            typeof modalViews[screenIndex].view === 'function'
                ? (modalViews[screenIndex].view as RecordView)()
                : modalViews[screenIndex].view;

        return (
            <Paper className={classes.paper} style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Grid container>
                    <Grid item xs={12} className={classes.modalPadding}>
                        <Typography className={classes.heading}>{heading}</Typography>
                        <Divider className={classes.headingLine} />
                    </Grid>
                    <ModalViewNames
                        modalViews={modalViews}
                        moveToIndex={moveToIndex}
                        screenIndex={screenIndex}
                    />
                </Grid>
                <Grid container className={classes.modalPadding}>
                    {currentView}
                </Grid>
                <Grid container justify="flex-end" className={classes.padding}>
                    <Button
                        id="MoveBack"
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        disabled={!canMoveBack()}
                        onClick={() => moveToIndex(screenIndex - 1)}
                    >
                        Back
                    </Button>
                    {showSave && (
                        <Button
                            id="Save"
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={() => submitForm(save)}
                        >
                            Save
                        </Button>
                    )}
                    <Button
                        id="SaveNext"
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        disabled={!canMoveNext()}
                        onClick={() => submitForm(values => saveAndMove(values, screenIndex + 1))}
                    >
                        Save &amp; Next
                    </Button>
                    <Button
                        id="SaveClose"
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={() => submitForm(saveAndClose)}
                    >
                        Save &amp; Close
                    </Button>
                    <Button
                        id="Cancel"
                        variant="contained"
                        className={classes.button}
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Paper>
        );
    };
}

interface ViewNamesProps extends StyledComponent {
    modalViews: ModalViewProps[];
    moveToIndex: (index: number) => void;
    screenIndex: number;
}
const ModalViewNames = withStyles(styles)(function ({
    classes,
    modalViews,
    moveToIndex,
    screenIndex,
}: ViewNamesProps) {
    const length = modalViews.length;
    return (
        <React.Fragment>
            {modalViews.map((modalView, index) => {
                const { line, text } = getClasses(classes, index, screenIndex);
                let gridWidth: GridSizes = getWidth(length);
                if (length > 4) {
                    if (screenIndex !== index) {
                        if (index === 0 && screenIndex !== 1) {
                            return (
                                <ViewMore
                                    key="backward-more"
                                    classes={classes}
                                    showMore={() => moveToIndex(screenIndex - 2)}
                                    text="< more..."
                                />
                            );
                        }
                        if (index === length - 1 && screenIndex !== length - 2) {
                            return (
                                <ViewMore
                                    key="forward-more"
                                    classes={classes}
                                    showMore={() => moveToIndex(screenIndex + 2)}
                                    text="more... >"
                                />
                            );
                        }
                        if (
                            (screenIndex === length - 1 && index < screenIndex - 2) ||
                            (screenIndex === 0 && index > screenIndex + 2)
                        ) {
                            return null;
                        }
                        if (screenIndex !== 0 && screenIndex !== length - 1) {
                            if (screenIndex !== 1 && screenIndex !== length - 2) {
                                if (index < screenIndex - 1 || index > screenIndex + 1) {
                                    return null;
                                }
                            }
                            if (
                                (screenIndex === 1 && index > screenIndex + 1) ||
                                (screenIndex === length - 2 && index < screenIndex - 1)
                            ) {
                                return null;
                            }
                        }
                    } else {
                        if (screenIndex < 2 || screenIndex > length - 3) {
                            gridWidth = 5;
                        } else {
                            gridWidth = 4;
                        }
                    }
                }
                return (
                    <Grid item xs={gridWidth} key={`record-view-${index}`}>
                        <Typography onClick={() => moveToIndex(index)} className={text}>
                            {modalView.name}
                        </Typography>
                        <Divider className={line} />
                    </Grid>
                );
            })}
        </React.Fragment>
    );
});

interface MoreProps {
    classes: ClassNameMap<string>;
    showMore: () => void;
    text: string;
}
const ViewMore = ({ classes, showMore, text }: MoreProps) => {
    return (
        <Grid item xs={1}>
            <Typography className={classes.moreText} onClick={showMore}>
                {text}
            </Typography>
            <Divider className={classes.moreLine} />
        </Grid>
    );
};

function getClasses(classes: ClassNameMap<string>, index: number, screenIndex: number) {
    if (index === screenIndex) {
        return {
            line: classes.activeView,
            text: classes.activeName,
        };
    }
    return {
        line: classes.inactiveView,
        text: classes.inactiveName,
    };
}

function getWidth(length: number) {
    switch (length) {
        case 1:
            return 12;
        case 2:
            return 6;
        case 3:
            return 4;
        default:
            return 3;
    }
}

const mapStateToProps = (state: State) => {
    const modalData = getModalData(state);
    return {
        initialValues: modalData.initialValues,
        screenIndex: modalData.screenIndex,
    };
};

const mapDispatchToProps = {
    closeModal: closeModalAction,
    openDialog: openDialogAction,
    updateModalData: updateCurrentModalDataAction,
};

export function MultiAddFormModal<TValues>() {
    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        withStyles(styles)(
            MultiFormModalWrapper as new (props: Props<TValues>) => MultiFormModalWrapper<TValues>
        )
    );
}
