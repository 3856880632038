import React from 'react'; // eslint-disable-line
import { Grid, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { State } from '../../../redux/root-reducer';
import styles from '../../../styles/styles';
import AssessorDashboardWithoutDetail from './assessor-dashboard-empty';
import AssessorDashboardWithDetail from './assessor-dashboard-detail';
import { StyledComponent } from '../../../styles/styles';
import { Assessors } from '../../reducer/assessors-reducer';
import { OpenModal, openModalAction } from '../../../form-modal/actions';
import { saveADUserAction } from '../../actions/assessors-save-action';
import { EditAssessorAction } from '../../actions/assessors-action';

interface OwnProps {
    assessorLimits?: Assessors;
    assessorUpdated: boolean;
    classes: any;
    OpenModal: OpenModal;
    editAssessor: any;
}

type Props = OwnProps & StyledComponent;
const DashboardWorkFlow: React.FunctionComponent<Props> = ({
    classes,
    assessorLimits,
    assessorUpdated,
    openModal,
    editAssessor,
}: any) => {
    return (
        <Grid container className={classes.assessorContainer}>
            {assessorLimits?.length > 0 ? (
                <Grid item xs={12} style={{ marginTop: '2%' }}>
                    <AssessorDashboardWithDetail
                        openModal={openModal}
                        classes={classes}
                        assessorLimits={assessorLimits}
                        assessorUpdated={assessorUpdated}
                        editAssessor={editAssessor}
                    />
                </Grid>
            ) : (
                <Grid container spacing={8} alignItems="center" direction="column" justify="center">
                    {' '}
                    <AssessorDashboardWithoutDetail classes={classes} />
                </Grid>
            )}
        </Grid>
    );
};

const mapStateToProps = (state: State) => ({
    workflowState: state.claimSession.workflow,
    assessorLimits: state.assessorDashboard.assessorLimits,
    assessorUpdated: state.assessorDashboard.assessorUpdated,
});

const mapDispatchToProps = {
    openModal: openModalAction,
    editAssessor: EditAssessorAction,
    saveADUser: saveADUserAction,
};

export const DashboardFlowView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(DashboardWorkFlow));
