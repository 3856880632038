import { isEmpty } from '../../forms/validations';
import validateContactDetails from '../actions/validate-contact-details';
import { FinancialAdviserContactDetails } from './financial-adviser-contact-details';

export function validateFinancialAdviserContactDetails(
    financialAdviserContactDetails?: FinancialAdviserContactDetails
) {
    if (!financialAdviserContactDetails || isEmpty(financialAdviserContactDetails)) {
        return {};
    }
    const errors = validateContactDetails(financialAdviserContactDetails, false);
    return errors;
}
