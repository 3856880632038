import React from 'react'; // eslint-disable-line
import { Grid, withStyles } from '@material-ui/core';
import { FormHeading, FullWidthTextField, GenericForm } from '../../../forms';
import { requiredValidator } from '../../../forms/validations';
import { connect } from 'react-redux';
import styles, { StyledComponent } from '../../../styles/styles';
import { HighRiskOverride } from '../../reducer';
import {
    AddHighRiskOverride,
    addHighRiskOverrideAction,
} from '../../actions/add-high-risk-override';
import { AuthState } from '../../../auth/auth-reducer';
import { State } from '../../../redux/root-reducer';
import { getDisplayNameFromAdName } from '../../../shared-functions';

type Props = StyledComponent & {
    highRiskOverride?: HighRiskOverride;
    paymentIndex: number;
    addHighRiskOverride: AddHighRiskOverride;
    currentUser: AuthState;
};

const HighRiskOverrideForm = GenericForm<HighRiskOverride>();

const highRiskOverrideView: React.FunctionComponent<Props> = ({
    highRiskOverride,
    paymentIndex,
    addHighRiskOverride,
    currentUser,
    ...rest
}: Props) => {
    const onSubmit = (values: HighRiskOverride) => addHighRiskOverride(values, paymentIndex);
    const initialValues = {
        reasonForOverride: '',
        overridingAssessor: `${getDisplayNameFromAdName(currentUser.name)} (${currentUser.userId})`,
        dateOfOverride: new Date(),
    };

    return (
        <HighRiskOverrideForm initialValues={initialValues} onSubmit={onSubmit} {...rest}>
            <FormHeading text="Add Comment" />
            <Grid container spacing={5} style={{ marginBottom: '120px' }}>
                <Grid item xs={6} lg={6}>
                    <FullWidthTextField
                        name="reasonForOverride"
                        label="Comment"
                        validate={requiredValidator}
                    />
                </Grid>
            </Grid>
        </HighRiskOverrideForm>
    );
};

const mapStateToProps = (state: State) => ({
    currentUser: state.auth as AuthState,
});

const mapDispatchToProps = {
    addHighRiskOverride: addHighRiskOverrideAction,
};

export const HighRiskOverrideView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(highRiskOverrideView));
