import { claimSubmit } from '../../../../claim';
import { ClaimType } from '../../../../claim/claim-types';
import { Claim } from '../../../../claim/reducers';
import {
    EarlyRetirementClaim,
    ERBPolicyAssessment,
} from '../../../../claim/reducers/claim-reducers/early-retirement-claim-reducer';
import { addRecordReduction, updateRecordReduction } from '../../../../redux/reductions';
import { ReduxThunkAction } from '../../../../redux/redux-actions';
import { ERBAssessmentDecisionViewModel } from '../early-retirement-reducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AddERBAssessmentDecisionReturn = Promise<any>;
export type AddERBAssessmentDecision = (
    erbAssessmentDecision: ERBAssessmentDecisionViewModel
) => AddERBAssessmentDecisionReturn;
type AddERBAssessmentDecisionAction = (
    erbAssessmentDecision: ERBAssessmentDecisionViewModel
) => ReduxThunkAction<AddERBAssessmentDecisionReturn>;
export const addERBAssessmentDecisionAction: AddERBAssessmentDecisionAction = (
    erbAssessmentDecision: ERBAssessmentDecisionViewModel
) => async (dispatch, getState) => {
    return await claimSubmit(dispatch, getState, claim =>
        getUpdatedClaim(claim, erbAssessmentDecision)
    );
};

function getUpdatedClaim(claim: Claim, assessmentDecision: ERBAssessmentDecisionViewModel): Claim {
    if (claim.claimType === ClaimType.EarlyRetirement) {
        const erbClaim = claim as EarlyRetirementClaim;
        const assessmentDetails = getUpdatedAssessmentDetails(
            erbClaim.assessmentDetails,
            assessmentDecision
        );
        const newClaim = { ...erbClaim, assessmentDetails: assessmentDetails };
        return newClaim;
    }
    return claim;
}

function getUpdatedAssessmentDetails(
    assessmentDetails: ERBPolicyAssessment[],
    assessmentDecisionViewModel: ERBAssessmentDecisionViewModel
) {
    if (assessmentDetails == null) {
        return assessmentDetails;
    }

    const investmentPolicyIndex = assessmentDetails.findIndex(
        rAD => rAD.policyNumber === assessmentDecisionViewModel.policyNumber
    );
    if (investmentPolicyIndex === -1) {
        throw new Error('Invalid Assessment Details: Policy Number not found');
    }

    if (investmentPolicyIndex > -1) {
        assessmentDetails = getUpdatedInvestmentAssessmentDetails(
            investmentPolicyIndex,
            assessmentDecisionViewModel,
            assessmentDetails
        );
    }

    return assessmentDetails;
}

function getUpdatedInvestmentAssessmentDetails(
    policyIndex: number,
    assessmentDecisionViewModel: ERBAssessmentDecisionViewModel,
    investmentPolicyAssessments: ERBPolicyAssessment[]
) {
    let policyAssessment = investmentPolicyAssessments[policyIndex];
    policyAssessment = {
        ...policyAssessment,
        decisions: addRecordReduction(policyAssessment.decisions, assessmentDecisionViewModel),
    };
    investmentPolicyAssessments = updateRecordReduction(
        investmentPolicyAssessments,
        policyAssessment,
        policyIndex
    );

    return investmentPolicyAssessments;
}
