import { ReduceCollectionRecordAction } from '../../../../../../../redux/collection-record-reducer';
import { DeleteRecordCollectionAction } from '../../../../../../../redux/collection-reducer';
import { DELETE_POST_UNDERWRITING_CHANGE } from './post-underwriting-change-action-types';

export type DeletePostUnderwritingChangeAction = DeleteRecordCollectionAction;

export type DeletePolicyPostUnderwritingChangeAction = ReduceCollectionRecordAction<DeletePostUnderwritingChangeAction>;

export const deletePostUnderwritingChangeActionCreator = (
    policyIndex: number,
    postUnderwritingChangeIndex: number
): DeletePolicyPostUnderwritingChangeAction => {
    return {
        type: DELETE_POST_UNDERWRITING_CHANGE,
        payload: {
            type: DELETE_POST_UNDERWRITING_CHANGE,
            index: postUnderwritingChangeIndex,
        },
        index: policyIndex,
    };
};
