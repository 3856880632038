import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect, MapDispatchToProps } from 'react-redux';
import { ExpansionPanel } from '../../../../../forms/form-components/expansion-panel/expansion-panel';
import { State } from '../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../styles/styles';

import { getObjectWarning } from '../../../../../shared-functions';
import { getRADeathClaim } from '../../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { Dispute } from '../reducer';
import { DisputeDetailsView } from './dispute-details-view';

interface OwnProps {}

interface StateProps {}

interface DispatchProps {}

type Props = OwnProps &
    StateProps &
    DispatchProps &
    StyledComponent & {
        disputes: Dispute[];
    };
const disputesView: React.FunctionComponent<Props> = disputes => {
    const notifier = getObjectWarning(disputes, null);
    return (
        <ExpansionPanel name="Disputes" notifier={notifier}>
            <DisputeDetailsView />
        </ExpansionPanel>
    );
};

const mapStateToProps = (state: State) => ({
    disputes: getRADeathClaim(state).disputes,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {};

export const DisputesView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(disputesView));
