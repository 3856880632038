import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit, Refresh } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { getClaim } from '../../../../../claim';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { ModalViewProps, MultiEditFormModal } from '../../../../../form-modal';
import { OpenModal, openModalAction } from '../../../../../form-modal/actions';
import { ButtonWrapper } from '../../../../../forms';
import { AddressDetails, DisplayAddress } from '../../../../../forms/sub-forms/address-details';
import {
    getLifeAssuredDetails,
    getLifeAssuredDetailsFromClaim,
    LifeAssuredContract,
    PersonalDetails,
} from '../../../../../life-assured-details/reducer';
import { State } from '../../../../../redux/root-reducer';
import { getDisplayDate, getFullName } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { getDetailsOfDeath } from '../../form-details/form-details-reducer';
import {
    ExtendedPersonalDetails,
    UpdatePersonalDetails,
    updatePersonalDetailsAction,
} from '../actions/update-personal-details';
import { AddressEditView, PersonalDetailsEditView } from './personal-details-edit-modal';
import {
    UpdateLifeAssuredContracts,
    updateLifeAssuredContractsAction,
} from '../../policy-details/actions/update-life-assured-contracts';

type InnerProps = StyledComponent & {
    dateOfDeath?: Date;
    openModal: OpenModal;
    updatePersonalDetails: UpdatePersonalDetails;
    personalDetails: PersonalDetails;
    addressDetails: AddressDetails;
    isReadOnly: boolean;
    hasDeferredContracts: boolean;
    updateLifeAssuredContracts: UpdateLifeAssuredContracts;
    contracts: LifeAssuredContract[];
};

interface ModalProps {
    personalDetails: ExtendedPersonalDetails;
    addressDetails: AddressDetails;
}

const EditModalForm = MultiEditFormModal<ModalProps>();
const EditModalViews: ModalViewProps[] = [
    { name: 'Personal Details', view: <PersonalDetailsEditView /> },
    { name: 'Address Details', view: <AddressEditView /> },
];

const personalDetailsView: React.FunctionComponent<InnerProps> = ({
    addressDetails,
    classes,
    dateOfDeath,
    updatePersonalDetails,
    openModal,
    personalDetails,
    isReadOnly,
    hasDeferredContracts,
    updateLifeAssuredContracts,
}: InnerProps) => {
    const edit = () => {
        openModal({
            modalContent: (
                <EditModalForm
                    heading="Update Life Assured Details"
                    modalViews={EditModalViews}
                    saveAction={updatePersonalDetails}
                />
            ),
            modalData: {
                screenIndex: 0,
                initialValues: {
                    personalDetails: {
                        ...personalDetails,
                        dateOfDeath,
                    },
                    addressDetails,
                },
            },
        });
    };
    if (personalDetails === null) {
        return (
            <ButtonWrapper>
                <Grid
                    container
                    item
                    xs={12}
                    justify="flex-end"
                    style={{ margin: '8px auto 8px auto' }}
                >
                    <Tooltip title="Refresh Policy Details">
                        <Button
                            id="refreshPolicyDetails"
                            color="secondary"
                            onClick={updateLifeAssuredContracts}
                            style={{ textTransform: 'none' }}
                        >
                            <Refresh style={{ marginRight: '4px' }} />
                        </Button>
                    </Tooltip>
                </Grid>
                <Grid item xs={2} className={classes.rightAlign}>
                    <Tooltip title="Edit Life Assured Details">
                        <Button id="EditPersonalDetails" color="secondary" onClick={edit}>
                            <Edit />
                        </Button>
                    </Tooltip>
                </Grid>
            </ButtonWrapper>
        );
    }
    return (
        <Grid item xs={12} container justify="flex-start" className={classes.marginTop}>
            <Grid item xs={10}>
                <Typography variant="body1" className={classes.heading}>
                    {getFullName(personalDetails)} | {personalDetails.lifeAssuredRole}
                </Typography>
            </Grid>
            {!hasDeferredContracts && (
                <ButtonWrapper>
                    <Grid item xs={2} className={classes.rightAlign}>
                        <Tooltip title="Edit Life Assured Details">
                            <Button id="EditPersonalDetails" color="secondary" onClick={edit}>
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        justify="flex-end"
                        style={{ margin: '8px auto 8px auto' }}
                    >
                        <Tooltip title="Refresh Policy Details">
                            <Button
                                id="refreshPolicyDetails"
                                color="secondary"
                                onClick={updateLifeAssuredContracts}
                                style={{ textTransform: 'none' }}
                            >
                                <Refresh style={{ marginRight: '4px' }} />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            )}
            <Grid item xs={6} lg={3}>
                <LabeledValue label="ID Number" value={personalDetails.idNumber} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Date of Birth"
                    value={getDisplayDate(personalDetails.dateOfBirth, 'dd LLLL yyyy')}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Date of Death"
                    value={getDisplayDate(dateOfDeath, 'dd LLLL yyyy')}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Age at Death" value={personalDetails.age} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Gender" value={personalDetails.gender} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Marital Status" value={personalDetails.maritalStatus} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="PDB ID" value={personalDetails.clientId} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Compass Client ID" value={personalDetails.compassClientId} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <DisplayAddress label="Address" address={addressDetails} />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state: State) => {
    const claim = getClaim(state);
    const lifeAssured = getLifeAssuredDetailsFromClaim(claim);
    const detailsOfDeath = getDetailsOfDeath(claim);
    return {
        personalDetails: lifeAssured.personalDetails,
        addressDetails: lifeAssured.residentialAddressDetails || {},
        dateOfDeath: detailsOfDeath.dateOfDeath,
        isReadOnly: state.claimSession.readOnly,
        hasDeferredContracts: state.claimSession.hasDeferredContracts,
        contracts: getLifeAssuredDetails(state).contracts,
    };
};

const mapDispatchToProps = {
    updatePersonalDetails: updatePersonalDetailsAction,
    openModal: openModalAction,
    updateLifeAssuredContracts: updateLifeAssuredContractsAction,
};

export const PersonalDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(personalDetailsView));
