import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { getCodeDescription, getDisplayDate } from '../../../../../../shared-functions';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { GuardianDetails } from '../../form-details-reducer';

interface Props extends StyledComponent {
    gaurdianDetails?: GuardianDetails;
}

const guardianDetailsView = ({ gaurdianDetails, classes }: Props) => {
    if (!gaurdianDetails) {
        return null;
    }
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography variant="body1" className={classes.heading}>
                    {'Guardian Details'}
                </Typography>
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Relationship To Minor"
                    value={gaurdianDetails.relationshipToMinor}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Title"
                    value={getCodeDescription('title', gaurdianDetails.title)}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Initials" value={gaurdianDetails.initials} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="First Name" value={gaurdianDetails.firstName} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Second Name" value={gaurdianDetails.secondName} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Surname" value={gaurdianDetails.lastName} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="ID Number" value={gaurdianDetails.idNumber} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Date of Birth"
                    value={getDisplayDate(gaurdianDetails.dateOfBirth)}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Details Matches ID" value={gaurdianDetails.detailsMatchesID} />
            </Grid>
        </React.Fragment>
    );
};

export const GuardianDetailsView = withStyles(styles)(guardianDetailsView);
