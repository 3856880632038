import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { getRADeathClaim } from '../../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { ExpansionPanel } from '../../../../../forms/form-components/expansion-panel/expansion-panel';
import { State } from '../../../../../redux/root-reducer';
import { getObjectWarning } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { LifeAssuredRequirementsView } from './life-assured-requirements/life-assured-requirements-view';
import { PolicyOwnerRequirementsView } from './policy-owner/policy-owner-requirements-view';
import { ClaimantRequirementsView } from './claimants/claimants-requirements-view';
import { EmailCorrespondenceView } from './communications/email-correspondence-view';
import { EmailCorrespondenceAllocationView } from './communications/email-correspondence-allocation-view';

interface OwnProps {}
type Props = StyledComponent &
    OwnProps & {
        validationErrors: string;
    };
const manageRequirementsView: React.FunctionComponent<Props> = ({ validationErrors }: Props) => {
    let notifier;
    if (validationErrors && validationErrors.includes('documentsStatus')) {
        notifier = getObjectWarning(validationErrors, validationErrors);
    }
    return (
        <ExpansionPanel name="Requirement management" notifier={notifier}>
            <LifeAssuredRequirementsView />
            <PolicyOwnerRequirementsView />
            <ClaimantRequirementsView />
            <EmailCorrespondenceView />
            <EmailCorrespondenceAllocationView />
        </ExpansionPanel>
    );
};

const mapStateToProps = (state: State) => ({
    validationErrors: getRADeathClaim(state).validationErrors,
});

export const ManageRequirementsView = connect(
    mapStateToProps,
    {}
)(withStyles(styles)(manageRequirementsView));
