import { AddRecordCollectionAction } from '../../../../../redux/collection-reducer';
import { ADD_CLAIMANT_RECORD } from './types';
import { Claimant } from '../../contact-details/contact-details-reducer';
export type AddClaimantRecordAction = AddRecordCollectionAction<Claimant>;

export const addClaimantRecordActionCreator = (
    claimantRecord: Claimant
): AddClaimantRecordAction => {
    return {
        type: ADD_CLAIMANT_RECORD,
        record: claimantRecord,
    };
};
