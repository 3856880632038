import { ModalAction } from '../reducer';
import { OPEN_MODAL, UPDATE_CURRENT_MODAL_DATA } from '../types';

export interface AddModalData {
    readonly recordCount?: number;
}

export interface OpenAddModalOptions {
    readonly modalContent: React.ReactNode;
    readonly modalData: AddModalData;
}

export type OpenAddModal = (options: OpenAddModalOptions) => ModalAction;
export const openAddModalAction: OpenAddModal = (options: OpenAddModalOptions) => {
    return {
        type: OPEN_MODAL,
        payload: {
            modalOpen: true,
            modalContent: options.modalContent,
            modalData: options.modalData,
        },
    };
};

export type UpdateAddModalData = (modalData: AddModalData) => ModalAction;
export const updateAddModalData: UpdateAddModalData = (modalData: any) => {
    return {
        type: UPDATE_CURRENT_MODAL_DATA,
        payload: {
            modalData,
        },
    };
};
