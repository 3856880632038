import React from 'react'; // eslint-disable-line
import { Divider, Drawer, IconButton } from '@material-ui/core';
import { createStyles, StyleRules, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { ChevronLeft } from '@material-ui/icons';
import { connect } from 'react-redux';
import { AuthenticatedView } from '../../auth/authenticated-view';
import { State } from '../../redux/root-reducer';
import { Workflow } from '../../workflow-navigation/reducers/workflow';
import { toggleDrawerAction } from '../actions';
import { ClaimReferenceDetails } from './claim-reference-details';
import { ClaimStepsDrawer } from './claim-steps-drawer';
import { ClaimUtilsDrawer } from './claim-utils-drawer';
import { AssessorDashboardDrawer } from './assessors-dashoard-drawer';

import libertyLogo from './LibertyLogoWhite.png';
import { DigitalFormDrawer } from '../../digital-form';

export const drawerWidth = 240;
export function styles(theme: Theme): StyleRules {
    return createStyles({
        logo: logo(),
        drawerPaper: paper(theme),
        drawerHeader: header(theme),
        link: link(theme),
    });
}

function logo(): CSSProperties {
    return {
        width: '60%',
        margin: '10px',
        marginLeft: '0px',
    };
}

function paper(theme: Theme): CSSProperties {
    return {
        backgroundColor: theme.palette.primary.main,
        position: 'relative',
        width: drawerWidth,
        height: '100vh',
    };
}

function header(theme: Theme): CSSProperties {
    return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    };
}

function link(theme: Theme): CSSProperties {
    return {
        color: theme.palette.common.white,
    };
}

interface Props extends WithStyles<typeof styles> {
    drawerOpen: boolean;
    activeWorkflow: Workflow;
    toggleDrawer: () => void;
}

class SideDrawer extends React.Component<Props> {
    public render() {
        const { classes, drawerOpen, toggleDrawer } = this.props;
        const anchor = 'left';
        return (
            <Drawer
                variant="persistent"
                anchor={anchor}
                open={drawerOpen}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <img src={libertyLogo} alt="liberty-flame-logo" className={classes.logo} />
                    <IconButton id="closeDrawer" onClick={() => toggleDrawer()}>
                        <ChevronLeft className={classes.link} />
                    </IconButton>
                </div>
                <Divider />
                <AuthenticatedView disableLogin={true}>
                    <div style={{ overflow: 'auto' }}>
                        <Divider />
                        <ClaimStepsDrawer />
                        <Divider />
                        <AssessorDashboardDrawer />
                    </div>
                    <Divider />
                    <DigitalFormDrawer />
                    <Divider />
                    <ClaimReferenceDetails />
                    <Divider />
                    <ClaimUtilsDrawer />
                    <Divider />
                </AuthenticatedView>
            </Drawer>
        );
    }
}

const mapStateToProps = (state: State) => ({
    drawerOpen: state.masterPage.drawerOpen,
    activeWorkflow: state.claimSession.workflow.activeWorkflow,
});

const mapDispatchToProps = {
    toggleDrawer: toggleDrawerAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SideDrawer));
