import React, { PropsWithChildren } from 'react'; // eslint-disable-line
import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import styles, { StyledComponent } from '../../styles/styles';

interface Props extends StyledComponent {
    readonly title: React.ReactNode;
    readonly noChildrenTitle?: React.ReactNode;
    readonly gutterElement?: React.ReactElement<any>;
    readonly noChildrenGutterElement?: React.ReactElement<any>;
}

const section: React.FunctionComponent<Props> = ({
    children,
    title,
    noChildrenTitle,
    gutterElement,
    noChildrenGutterElement,
    classes,
}: PropsWithChildren<Props>) => {
    if (React.Children.count(children) > 0) {
        return (
            <React.Fragment>
                <Grid container spacing={2} justify="space-between">
                    <Grid item style={{ display: 'flex', flexBasis: 'row', alignItems: 'center' }}>
                        <Typography variant="subtitle2">{title}</Typography>
                    </Grid>
                    <Grid item style={{ display: 'flex', flexBasis: 'row', alignItems: 'center' }}>
                        {gutterElement}
                    </Grid>
                </Grid>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        {children}
                    </Grid>
                </Paper>
            </React.Fragment>
        );
    }

    return (
        <Grid container spacing={2} justify="space-between">
            <Grid item style={{ display: 'flex', flexBasis: 'row', alignItems: 'center' }}>
                <Typography variant="subtitle2">{noChildrenTitle || title}</Typography>
            </Grid>
            <Grid item style={{ display: 'flex', flexBasis: 'row', alignItems: 'center' }}>
                {noChildrenGutterElement || gutterElement}
            </Grid>
        </Grid>
    );
};

export const Section = withStyles(styles)(section);
