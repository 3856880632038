import { collectionReducer } from '../../../../../../redux/collection-reducer';
import {
    ADD_UNDERWRITING_NON_DISCLOSURE,
    DELETE_UNDERWRITING_NON_DISCLOSURE,
    UPDATE_UNDERWRITING_NON_DISCLOSURE,
} from './actions/underwriting-non-disclosure-action-types';
import { UnderwritingNonDisclosure } from './underwriting-non-disclosure-model';

export const underwritingNonDisclosuresReducer = collectionReducer<UnderwritingNonDisclosure>({
    addAction: ADD_UNDERWRITING_NON_DISCLOSURE,
    updateAction: UPDATE_UNDERWRITING_NON_DISCLOSURE,
    deleteAction: DELETE_UNDERWRITING_NON_DISCLOSURE,
});
