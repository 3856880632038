import React from 'react'; // eslint-disable-line
import { Grid, Paper, Divider, Typography, withStyles } from '@material-ui/core';
import {
    FullWidthTextField,
    FullWidthDatePicker,
    FullWidthCheckBoxField,
    Condition,
    True,
    WhenFieldChanges,
    FullWidthSearchSelectField,
    FullWidthRadioGroup,
    RadioOption,
} from '../../../../forms';
import styles, { StyledComponent } from '../../../../styles/styles';
import { NumberMask } from '../../../../forms/input-masks';
import { requiredValidator } from '../../../../forms/validations';
import { NaturalCauseOfDeath, UnnaturalCauseOfDeath } from '../../../../codes/cause-of-claim';
import { LateSubmissionReason } from '../../../../codes/late-submission-reason';
import { Field } from 'react-final-form';

function calculateMonths(dateReported: Date, dateOfEvent: Date): boolean {
    const startDate = new Date(dateOfEvent);
    const endDate = new Date(dateReported);
    const totalDays = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);

    return totalDays > 365 || (totalDays === 365 && startDate.getDate() === endDate.getDate());
}

const raClaimDetailsEditView: React.FunctionComponent<StyledComponent> = ({
    classes,
}: StyledComponent) => {
    let dateOfEvent: Date;
    let dateClaimReported: Date;
    return (
        <React.Fragment>
            <Typography variant="h5">Claim Event Details</Typography>
            <Divider></Divider>
            <Paper className={classes.paper}>
                <Grid container spacing={5} justify="flex-start">
                    <Grid item xs={7} lg={6}>
                        <FullWidthDatePicker
                            id="dateOfEvent"
                            name="dateOfEvent"
                            validate={requiredValidator}
                            disableFuture
                        />
                    </Grid>
                    <Grid item xs={7} lg={6}>
                        <FullWidthTextField
                            id="placeOfDeath"
                            name="placeOfDeath"
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={7} lg={6}>
                        <FullWidthTextField id="postalCode" name="postalCode" mask={NumberMask} />
                    </Grid>
                    <Condition when="dateOfEvent" hasValue>
                        <True>
                            <Grid item xs={7} lg={6}>
                                <FullWidthDatePicker
                                    id="dateClaimReported"
                                    name="dateClaimReported"
                                    validate={requiredValidator}
                                    minDateField="dateOfEvent"
                                    disableFuture
                                    helperText="Date can not be before date of event"
                                />
                            </Grid>
                        </True>
                    </Condition>
                    <Grid item xs={6}>
                        <Grid item xs={6}></Grid>
                        <FullWidthRadioGroup
                            label="Cause of death"
                            name="typeOfDeath"
                            validate={requiredValidator}
                            id="typeOfDeath"
                        >
                            <RadioOption value="Natural" />
                            <RadioOption value="Unnatural" />
                        </FullWidthRadioGroup>
                        <WhenFieldChanges field="typeOfDeath" set="causeOfDeath" to={null} />
                        <WhenFieldChanges field="typeOfDeath" set="reportedToPolice" to={false} />
                    </Grid>
                    <Condition when="typeOfDeath" is="Natural">
                        <True>
                            <Grid item xs={7} lg={6}>
                                <FullWidthSearchSelectField
                                    label="Cause of death"
                                    id="causeOfDeath"
                                    name="causeOfDeath"
                                    options={NaturalCauseOfDeath}
                                    validate={requiredValidator}
                                />
                                <WhenFieldChanges
                                    field="causeOfDeath"
                                    set="causeOfDeathDescription"
                                    to={null}
                                />
                            </Grid>
                        </True>
                    </Condition>
                    <Condition when="typeOfDeath" is="Unnatural">
                        <True>
                            <Grid item xs={7} lg={6}>
                                <FullWidthSearchSelectField
                                    id="causeOfDeath"
                                    name="causeOfDeath"
                                    label="Cause of death"
                                    options={UnnaturalCauseOfDeath}
                                    validate={requiredValidator}
                                />
                                <WhenFieldChanges
                                    field="causeOfDeath"
                                    set="causeOfDeathDescription"
                                    to={null}
                                />
                            </Grid>
                            <Grid item xs={7} lg={6}>
                                <FullWidthCheckBoxField name="reportedToPolice" />
                                <WhenFieldChanges
                                    field="reportedToPolice"
                                    set="caseNumber"
                                    to={null}
                                />
                            </Grid>
                            <Condition when="reportedToPolice">
                                <True>
                                    <Grid item xs={7} lg={6}>
                                        <FullWidthTextField
                                            id="caseNumber"
                                            name="caseNumber"
                                            validate={requiredValidator}
                                        />
                                    </Grid>
                                </True>
                            </Condition>
                        </True>
                    </Condition>
                    <Condition when="causeOfDeath" is={['799', '798']}>
                        <True>
                            <Grid item xs={7} lg={6}>
                                <FullWidthTextField
                                    id="causeOfDeathDescription"
                                    name="causeOfDeathDescription"
                                    validate={requiredValidator}
                                />
                            </Grid>
                        </True>
                    </Condition>
                    <Grid item xs={6}>
                        <FullWidthDatePicker
                            id="dateOfWill"
                            name="dateOfWill"
                            maxDateField="dateOfEvent"
                            disableFuture
                            helperText="Date can not be after date of event"
                        />
                    </Grid>
                    <Field
                        name="dateClaimReported"
                        subscription={{ value: true }}
                        component={({ input: { value: reportedDate } }) => (
                            <Condition
                                when="dateOfEvent"
                                passes={(value: Date) => {
                                    dateOfEvent = value;
                                    return calculateMonths(reportedDate, dateOfEvent);
                                }}
                            >
                                <True>
                                    <Grid item xs={6}>
                                        <FullWidthSearchSelectField
                                            id="lateSubmissionReason"
                                            name="lateSubmissionReason"
                                            options={LateSubmissionReason}
                                        />
                                    </Grid>
                                </True>
                            </Condition>
                        )}
                    />

                    <Condition
                        when="dateOfEvent"
                        passes={(value: Date) => {
                            dateOfEvent = value;
                            return calculateMonths(dateClaimReported, dateOfEvent);
                        }}
                    >
                        <True>
                            <Grid item xs={6}>
                                <FullWidthSearchSelectField
                                    id="lateSubmissionReason"
                                    name="lateSubmissionReason"
                                    options={LateSubmissionReason}
                                />
                            </Grid>
                        </True>
                    </Condition>

                    <Condition when="lateSubmissionReason" is="Other">
                        <True>
                            <Grid item xs={6}>
                                <FullWidthTextField
                                    id="lateSubmissionReasonDescription"
                                    name="lateSubmissionReasonDescription"
                                    label="Specify other"
                                />
                            </Grid>
                        </True>
                    </Condition>
                    <Grid item xs={6}>
                        <FullWidthTextField id="bi1663" name="bi1663" label="BI-1663 number" />
                    </Grid>

                    <WhenFieldChanges
                        field="lateSubmissionReason"
                        condition={(value: string) => {
                            return value !== 'Other';
                        }}
                        set="lateSubmissionReasonDescription"
                        to={null}
                    />

                    <WhenFieldChanges
                        field="dateOfEvent"
                        condition={(value: Date) => {
                            dateOfEvent = value;
                            return calculateMonths(dateClaimReported, dateOfEvent) === false;
                        }}
                        set="lateSubmissionReason"
                        to={null}
                    />

                    <WhenFieldChanges
                        field="dateClaimReported"
                        condition={(value: Date) => {
                            dateClaimReported = value;
                            return calculateMonths(dateClaimReported, dateOfEvent) === false;
                        }}
                        set="lateSubmissionReason"
                        to={null}
                    />
                </Grid>
            </Paper>
        </React.Fragment>
    );
};
export const RAClaimDetailsEditView = withStyles(styles)(raClaimDetailsEditView);
