import { ScreenIndex } from '../../claim-workflow/workflow-provider/screen-index';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { addScreenToWorkflow } from '../../workflow-navigation/actions/add-screen-to-workflow';
import { deleteScreenFromWorkflow } from '../../workflow-navigation/actions/delete-screen-from-workflow';

type SetClaimFlowReturn = Promise<any>;
export type SetClaimFlow = (isFuneral: string) => SetClaimFlowReturn;
type SetClaimFlowAction = (isFuneral: string) => ReduxThunkAction<SetClaimFlowReturn>;
export const setClaimFlowAction: SetClaimFlowAction = (isFuneral: string) => {
    return async dispatch => {
        if (isFuneral === 'Yes') {
            dispatch(deleteScreenFromWorkflow(ScreenIndex.InitiateClaim.ClaimTypeSelection));
            dispatch(deleteScreenFromWorkflow(ScreenIndex.InitiateClaim.ClientSelection));
            dispatch(deleteScreenFromWorkflow(ScreenIndex.InitiateClaim.ClientSearch));
            dispatch(addScreenToWorkflow(ScreenIndex.InitiateClaim.FuneralClientSearch));
        } else {
            dispatch(deleteScreenFromWorkflow(ScreenIndex.InitiateClaim.FuneralClientSelection));
            dispatch(deleteScreenFromWorkflow(ScreenIndex.InitiateClaim.FuneralClientSearch));
            dispatch(addScreenToWorkflow(ScreenIndex.InitiateClaim.ClientSearch));
        }
    };
};
