import React from 'react'; // eslint-disable-line

import { Grid, Paper, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { Code } from '../../../../../codes/codes';
import { CountriesOfIssue } from '../../../../../codes/countries-of-issue';
import { MaritalStatus } from '../../../../../codes/marital-statuses';
import {
    EntityAssigneeRelationships,
    Relationships,
    RelationshipsChildren,
    RelationshipsExSpouses,
    RelationshipsOther,
    RelationshipsParents,
    RelationshipsPartner,
    RelationshipsRelatives,
    RelationshipsSpouses,
} from '../../../../../codes/relationship-types';
import {
    Condition,
    False,
    FormHeading,
    FullWidthCheckBoxField,
    FullWidthDatePicker,
    FullWidthRadioGroup,
    FullWidthSearchSelectField,
    FullWidthTextField,
    RaDeathWrapper,
    RadioOption,
    True,
    When,
    WhenFieldChanges,
} from '../../../../../forms';
import { TelephoneNumberMask, NumberMask } from '../../../../../forms/input-masks';
import { CommunicationDetailsSubForm } from '../../../../../forms/sub-forms/communication-details';
import { requiredValidator } from '../../../../../forms/validations';
import { State } from '../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../styles/styles';
import {
    OrganisationTypes,
    OrganisationTypeSuffixes,
} from '../../../death/contact-details/contact-details-reducer';
import { FinancialBreakdown, getPossibleParents } from '../reducer';
import { AddressDetailsEditView } from './edit-address-details';
import { BankDetailsEditView } from './edit-bank-details';
import { CuratorEditView } from './edit-curator-details';
import { FinancialInformationEditView } from './edit-financial-information';
import { GuardianEditView } from './edit-guardian-details';
import { PersonalDetailsView } from './edit-personal-details';

interface Props extends StyledComponent {
    readonly breakdown: FinancialBreakdown[];
    readonly isEdit?: boolean;
    readonly possibleParents: Code<string>[];
}

const dependantEditView: React.FunctionComponent<Props> = ({
    breakdown,
    classes,
    isEdit,
    possibleParents,
}: Props) => {
    return (
        <React.Fragment>
            <Paper className={classes.paper}>
                <FormHeading text="Personal details" />
                <Grid container spacing={5} justify="flex-start">
                    <Grid item xs={6}>
                        <Tooltip title="Select Dependant">
                            <FullWidthCheckBoxField
                                name="isDependantSelected"
                                label="Select Dependant"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthRadioGroup
                            name="statementCompleted"
                            label="Claimant statement completed"
                            validate={requiredValidator}
                        >
                            <RadioOption value="Yes" />
                            <RadioOption value="No" />
                        </FullWidthRadioGroup>
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthRadioGroup
                            name="dependantType"
                            validate={requiredValidator}
                            disabled={isEdit}
                        >
                            <RadioOption value="Organisation" />
                            <RadioOption value="Individual" />
                        </FullWidthRadioGroup>
                        <WhenDependantTypeChanges />
                    </Grid>
                    <Condition when="dependantType" matchesChild>
                        <When is="Individual">
                            <Grid item xs={6}>
                                <FullWidthSearchSelectField
                                    name="relationship"
                                    label="Relationship to life assured"
                                    options={Relationships}
                                    sortOptions
                                    validate={requiredValidator}
                                />
                                <WhenFieldChanges
                                    field="relationship"
                                    condition={value =>
                                        value === 'Spouse' || value === 'EstrangedSpouse'
                                    }
                                    set="personalDetails.maritalStatus"
                                    to="Married"
                                />
                                <WhenFieldChanges
                                    field="relationship"
                                    condition={value =>
                                        value !== 'Spouse' && value !== 'EstrangedSpouse'
                                    }
                                    set="personalDetails.maritalStatus"
                                    to={null}
                                />
                                <WhenFieldChanges
                                    field="relationship"
                                    set="relationshipDescription"
                                    to={null}
                                />
                                <WhenFieldChanges field="relationship" set="guardian" to={null} />
                                <WhenFieldChanges field="relationship" set="parent" to={null} />
                            </Grid>
                            <Field
                                name="relationship"
                                subscription={{ value: true }}
                                component={({ input: { value: relationship } }) => (
                                    <Grid item xs={6}>
                                        <FullWidthSearchSelectField
                                            name="relationshipDescription"
                                            label="Description of relationship to life assured"
                                            options={relationshipDescriptions(relationship)}
                                            sortOptions
                                            validate={requiredValidator}
                                        />
                                    </Grid>
                                )}
                            />
                            <Grid item xs={6}>
                                <FullWidthRadioGroup
                                    name="claimCapacity"
                                    label="Claiming in which capacity"
                                    validate={requiredValidator}
                                >
                                    <RadioOption value="Beneficiary" />
                                    <RadioOption value="Dependant" />
                                    <RadioOption value="Both" />
                                </FullWidthRadioGroup>
                            </Grid>
                            <Grid item xs={6}>
                                <FullWidthRadioGroup name="beneficiaryOfWill">
                                    <RadioOption value="Yes" />
                                    <RadioOption value="No" />
                                    <RadioOption value={"Don't know"} label="Don't know" />
                                </FullWidthRadioGroup>
                            </Grid>
                            <WhenFieldChanges
                                field="relationship"
                                becomes="MinorChild"
                                set="isExecutor"
                                to={null}
                            />
                            <Condition when="relationship" is="MinorChild">
                                <True>
                                    <Grid item xs={6}>
                                        <FullWidthSearchSelectField
                                            name="parent"
                                            label="Child of"
                                            options={possibleParents}
                                            sortOptions
                                        />
                                    </Grid>
                                </True>
                                <False>
                                    <Grid item xs={6}>
                                        <FullWidthRadioGroup
                                            name="isExecutor"
                                            label="Executor of the estate"
                                            validate={requiredValidator}
                                        >
                                            <RadioOption value="Yes" />
                                            <RadioOption value="No" />
                                            <RadioOption value="Not applicable" />
                                        </FullWidthRadioGroup>
                                    </Grid>
                                </False>
                            </Condition>
                            <PersonalDetailsView propertyPrefix="personalDetails" />
                            <RelationshipViews />
                            <WhenFieldChanges
                                field="relationship"
                                becomes="MinorChild"
                                set="personalDetails.taxReferenceNumber"
                                to={null}
                            />
                            <Condition when="relationship" is="MinorChild">
                                <False>
                                    <Grid item xs={6}>
                                        <FullWidthTextField
                                            name="personalDetails.taxReferenceNumber"
                                            mask={NumberMask}
                                        />
                                    </Grid>
                                </False>
                            </Condition>
                            <Grid item xs={6}>
                                <FullWidthRadioGroup
                                    name="personalDetails.isRsaResident"
                                    validate={requiredValidator}
                                >
                                    <RadioOption value="Yes" />
                                    <RadioOption value="No" />
                                </FullWidthRadioGroup>
                                <WhenFieldChanges
                                    field="personalDetails.isRsaResident"
                                    becomes="Yes"
                                    set="personalDetails.countryOfResidence"
                                    to={null}
                                />
                                <WhenFieldChanges
                                    field="personalDetails.isRsaResident"
                                    set="personalDetails.officiallyEmigrated"
                                    to={null}
                                />
                            </Grid>
                            <Condition when={`personalDetails.isRsaResident`} is="No">
                                <True>
                                    <Grid item xs={6}>
                                        <FullWidthSearchSelectField
                                            name="personalDetails.countryOfResidence"
                                            options={CountriesOfIssue}
                                        />
                                    </Grid>
                                </True>
                            </Condition>
                            <Condition when="personalDetails.isRsaResident" is="No">
                                <True>
                                    <Grid item xs={6}>
                                        <FullWidthRadioGroup name="personalDetails.officiallyEmigrated">
                                            <RadioOption value="Yes" />
                                            <RadioOption value="No" />
                                        </FullWidthRadioGroup>
                                    </Grid>
                                </True>
                            </Condition>
                            <Grid item xs={6}>
                                <FullWidthRadioGroup
                                    name="disability"
                                    label="Has disability"
                                    validate={requiredValidator}
                                >
                                    <RadioOption value="Yes" />
                                    <RadioOption value="No" />
                                </FullWidthRadioGroup>
                                <WhenFieldChanges
                                    field="disability"
                                    becomes="No"
                                    set="disabilityDetails"
                                    to={null}
                                />
                            </Grid>
                            <Condition when="disability" is="Yes">
                                <True>
                                    <Grid item xs={6}>
                                        <FullWidthTextField
                                            name="disabilityDetails"
                                            label="Details of disability"
                                            validate={requiredValidator}
                                        />
                                    </Grid>
                                </True>
                            </Condition>
                            <WhenFieldChanges
                                field="relationship"
                                becomes="MinorChild"
                                set="underCuratorship"
                                to={null}
                            />
                            <Condition when="relationship" is="MinorChild">
                                <False>
                                    <Grid item xs={6}>
                                        <FullWidthRadioGroup
                                            name="underCuratorship"
                                            validate={requiredValidator}
                                        >
                                            <RadioOption value="Yes" />
                                            <RadioOption value="No" />
                                        </FullWidthRadioGroup>
                                        <WhenFieldChanges
                                            field="underCuratorship"
                                            becomes="No"
                                            set="curator"
                                            to={null}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FullWidthRadioGroup
                                            name="employed"
                                            label="Currently employed"
                                            validate={requiredValidator}
                                        >
                                            <RadioOption value="Yes" />
                                            <RadioOption value="No" />
                                        </FullWidthRadioGroup>
                                        <WhenFieldChanges
                                            field="employed"
                                            becomes="No"
                                            set="occupation"
                                            to={null}
                                        />
                                        <WhenFieldChanges
                                            field="employed"
                                            becomes="No"
                                            set="company"
                                            to={null}
                                        />
                                    </Grid>
                                    <Condition when="employed" is="Yes">
                                        <True>
                                            <Grid item xs={6}>
                                                <FullWidthTextField
                                                    name="occupation"
                                                    validate={requiredValidator}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FullWidthTextField
                                                    name="company"
                                                    validate={requiredValidator}
                                                />
                                            </Grid>
                                        </True>
                                    </Condition>
                                    <Condition when="relationship" is="MajorChild">
                                        <True>
                                            <StudentOrScholarView />
                                        </True>
                                    </Condition>
                                </False>
                            </Condition>
                        </When>
                        <When is="Organisation">
                            <WhenFieldChanges
                                field="dependantType"
                                set="organisationDetails"
                                to={null}
                            />
                            <Grid item xs={6}>
                                <FullWidthSearchSelectField
                                    name={`organisationDetails.relationshipToLifeAssured`}
                                    options={EntityAssigneeRelationships}
                                    validate={requiredValidator}
                                    sortOptions
                                />
                                <WhenFieldChanges
                                    field={`organisationDetails.relationshipToLifeAssured`}
                                    set={`organisationDetails.relationshipToLifeAssuredDescription`}
                                    to={null}
                                />
                            </Grid>
                            <Condition
                                when={`organisationDetails.relationshipToLifeAssured`}
                                is="Other"
                            >
                                <True>
                                    <Grid item xs={6}>
                                        <FullWidthTextField
                                            name={`organisationDetails.relationshipToLifeAssuredDescription`}
                                            validate={requiredValidator}
                                        />
                                    </Grid>
                                </True>
                            </Condition>
                            <Grid item xs={6}>
                                <FullWidthRadioGroup
                                    name="claimCapacity"
                                    validate={requiredValidator}
                                    label="Claiming in which capacity"
                                >
                                    <RadioOption value="Beneficiary" />
                                    <RadioOption value="Dependant" />
                                </FullWidthRadioGroup>
                            </Grid>
                            <Grid item xs={6}>
                                <FullWidthSearchSelectField
                                    name="organisationDetails.organisationType"
                                    label="Type"
                                    options={OrganisationTypes}
                                    sortOptions
                                    validate={requiredValidator}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FullWidthSearchSelectField
                                    name="organisationDetails.organisationSuffix"
                                    label="Suffix"
                                    options={OrganisationTypeSuffixes}
                                    sortOptions
                                    validate={requiredValidator}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FullWidthTextField
                                    name="organisationDetails.companyName"
                                    label="Entity Name"
                                    validate={requiredValidator}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FullWidthTextField
                                    name="organisationDetails.entityRegistrationNumber"
                                    label="Registration Number"
                                    validate={requiredValidator}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FullWidthDatePicker
                                    name="organisationDetails.dateOfIncorporation"
                                    validate={requiredValidator}
                                    disableFuture
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FullWidthSearchSelectField
                                    name="organisationDetails.countryOfIncorporation"
                                    options={CountriesOfIssue}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FullWidthRadioGroup
                                    name="organisationDetails.taxStatus"
                                    label="Tax Status"
                                >
                                    <RadioOption value="Non Tax Paying" />
                                    <RadioOption value="Tax Paying" />
                                </FullWidthRadioGroup>
                                <WhenFieldChanges
                                    field="organisationDetails.taxStatus"
                                    set="organisationDetails.companyTaxNumber"
                                    to={null}
                                />
                                <WhenFieldChanges
                                    field="organisationDetails.taxStatus"
                                    set="organisationDetails.countryOfTaxResidence"
                                    to={null}
                                />
                            </Grid>
                            <Condition when="organisationDetails.taxStatus" is="Tax Paying">
                                <True>
                                    <Grid item xs={6}>
                                        <FullWidthSearchSelectField
                                            name="organisationDetails.countryOfTaxResidence"
                                            options={CountriesOfIssue}
                                        />
                                    </Grid>
                                    <Condition
                                        when="organisationDetails.countryOfTaxResidence"
                                        is="South Africa"
                                    >
                                        <True>
                                            <Grid item xs={6}>
                                                <FullWidthTextField
                                                    name="organisationDetails.companyTaxNumber"
                                                    label="RSA Tax Number"
                                                />
                                            </Grid>
                                        </True>
                                    </Condition>
                                    <Condition
                                        when="organisationDetails.countryOfTaxResidence"
                                        is={['South Africa', null, undefined]}
                                    >
                                        <False>
                                            <Grid item xs={6}>
                                                <FullWidthTextField
                                                    name="organisationDetails.companyTaxNumber"
                                                    label="Alternative tax number"
                                                />
                                            </Grid>
                                        </False>
                                    </Condition>
                                </True>
                            </Condition>
                        </When>
                    </Condition>
                </Grid>
            </Paper>
            <Condition when="dependantType" matchesChild>
                <When is="Individual">
                    <FinancialInformationEditView breakdown={breakdown} />
                    <ContactDetails classes={classes} />
                    <Paper className={classes.paper}>
                        <FormHeading text="Address details" />
                        <AddressDetailsEditView />
                    </Paper>

                    <BankDetailsEditView propertyPrefix="bankingDetails" isAnOrganisation={false} />

                    <Condition when="underCuratorship" is="Yes">
                        <True>
                            <CuratorEditView />
                        </True>
                    </Condition>
                    <Condition when="relationship" is="MinorChild">
                        <True>
                            <GuardianEditView />
                        </True>
                    </Condition>
                </When>
                <When is="Organisation">
                    <Paper className={classes.paper}>
                        <FormHeading text="Address details" />
                        <AddressDetailsEditView isAnOrganisation />
                    </Paper>
                    <Paper className={classes.paper}>
                        <FormHeading text="Contact details" />
                        <Grid container spacing={5} justify="flex-start">
                            <CommunicationDetailsSubForm
                                communicationDetailsPropertyName={`contactDetails`}
                                displayOptions={{
                                    faxNumber: true,
                                    telephoneNumber: true,
                                    cellphoneNumber: true,
                                    emailAddress: true,
                                    preferredMethodOfCommunication: true,
                                }}
                                required={'Any'}
                                requiredOptions={{
                                    cellphoneNumber: true,
                                    emailAddress: true,
                                    preferredMethodOfCommunication: true,
                                }}
                            />
                        </Grid>
                    </Paper>
                    <RaDeathWrapper>
                        <BankDetailsEditView
                            propertyPrefix="bankingDetails"
                            isAnOrganisation={true}
                        />
                    </RaDeathWrapper>
                    <AuthorisedPersonView />
                </When>
            </Condition>
        </React.Fragment>
    );
};

const RelationshipViews = () => {
    return (
        <React.Fragment>
            <Condition when="relationship" matchesChild>
                <When is="Spouse">
                    <SpouseView />
                </When>
                <When is="EstrangedSpouse">
                    <SpouseView />
                </When>
                <When is="ExSpouse">
                    <ExSpouseView />
                </When>
                <When is="Partner">
                    <PartnerView />
                </When>
                <When is="MinorChild">
                    <StudentOrScholarView />
                </When>
            </Condition>
            <Condition
                when="relationship"
                is={['Spouse', 'EstrangedSpouse', 'ExSpouse', 'Partner', 'MinorChild']}
            >
                <False>
                    <OtherRelationshipsView />
                </False>
            </Condition>
        </React.Fragment>
    );
};

const SpouseView = () => {
    return (
        <Condition when="relationship" is={['Spouse', 'EstrangedSpouse']}>
            <True>
                <Grid item xs={6}>
                    <FullWidthSearchSelectField
                        name={`personalDetails.maritalStatus`}
                        options={MaritalStatus}
                        label="Marital Status"
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthDatePicker
                        name={`personalDetails.dateOfMarriage`}
                        label="Date of Marriage to life assured"
                        disableFuture
                    />
                </Grid>
                <Condition when="relationship" is="EstrangedSpouse">
                    <True>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name="yearsEstranged"
                                validate={requiredValidator}
                                mask={NumberMask}
                            />
                        </Grid>
                    </True>
                </Condition>
            </True>
        </Condition>
    );
};

const ExSpouseView = () => {
    return (
        <Condition when="relationship" is="ExSpouse">
            <True>
                <Grid item xs={6}>
                    <FullWidthDatePicker name="dateOfMarriageToLifeAssured" disableFuture />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthDatePicker name="dateOfDivorceFromLifeAssured" disableFuture />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthRadioGroup
                        name="divorceSettlement"
                        label="Settlement in the divorce"
                        validate={requiredValidator}
                    >
                        <RadioOption value="Yes" />
                        <RadioOption value="No" />
                    </FullWidthRadioGroup>
                </Grid>
                <Grid item xs={6}>
                    <FullWidthRadioGroup name="remarried" validate={requiredValidator}>
                        <RadioOption value="Yes" />
                        <RadioOption value="No" />
                    </FullWidthRadioGroup>
                    <WhenFieldChanges
                        field="remarried"
                        becomes="Yes"
                        set="personalDetails.maritalStatus"
                        to="Married"
                    />
                    <WhenFieldChanges
                        field="remarried"
                        becomes="No"
                        set="personalDetails.maritalStatus"
                        to={null}
                    />
                    <WhenFieldChanges
                        field="remarried"
                        becomes="No"
                        set="personalDetails.dateOfMarriage"
                        to={null}
                    />
                </Grid>
                <Condition when="remarried" is="Yes">
                    <True>
                        <Grid item xs={6}>
                            <FullWidthSearchSelectField
                                name={`personalDetails.maritalStatus`}
                                options={MaritalStatus}
                                label="Current Marital Status"
                            />
                        </Grid>
                        <Condition when={`personalDetails.maritalStatus`} is="Married">
                            <True>
                                <Grid item xs={6}>
                                    <FullWidthDatePicker
                                        name={`personalDetails.dateOfMarriage`}
                                        label="Date of Current Marriage"
                                        disableFuture
                                        validate={requiredValidator}
                                    />
                                </Grid>
                            </True>
                        </Condition>
                    </True>
                </Condition>
                <Grid item xs={6}>
                    <FullWidthTextField name="natureOfDependency" />
                </Grid>
            </True>
        </Condition>
    );
};

const PartnerView = () => {
    return (
        <Condition when="relationship" is="Partner">
            <True>
                <Grid item xs={6}>
                    <FullWidthTextField
                        name="yearsInRelationship"
                        validate={requiredValidator}
                        mask={NumberMask}
                    />
                </Grid>
            </True>
        </Condition>
    );
};

const StudentOrScholarView = () => {
    return (
        <React.Fragment>
            <Grid item xs={6}>
                <Condition when="relationship" is="MajorChild">
                    <True>
                        <FullWidthRadioGroup name="studentOrScholar" validate={requiredValidator}>
                            <RadioOption value="Student" />
                            <RadioOption value="Scholar" />
                            <RadioOption value="None" />
                        </FullWidthRadioGroup>
                    </True>
                    <False>
                        <FullWidthRadioGroup name="studentOrScholar">
                            <RadioOption value="Student" />
                            <RadioOption value="Scholar" />
                        </FullWidthRadioGroup>
                    </False>
                </Condition>
                <WhenFieldChanges field="studentOrScholar" set="institution" to={null} />
                <WhenFieldChanges field="studentOrScholar" set="courseType" to={null} />
                <WhenFieldChanges field="studentOrScholar" set="courseName" to={null} />
                <WhenFieldChanges field="studentOrScholar" set="schoolYearGrade" to={null} />
                <WhenFieldChanges field="studentOrScholar" set="schoolYearsLeft" to={null} />
            </Grid>
            <Condition when="studentOrScholar" matchesChild>
                <When is="Student">
                    <Grid item xs={6}>
                        <FullWidthTextField
                            name="schoolYearGrade"
                            label="Current year of study"
                            mask={NumberMask}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            name="schoolYearsLeft"
                            label="Number of years left"
                            validate={requiredValidator}
                            mask={NumberMask}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthTextField name="institution" validate={requiredValidator} />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthTextField name="courseType" validate={requiredValidator} />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthTextField name="courseName" validate={requiredValidator} />
                    </Grid>
                </When>
                <When is="Scholar">
                    <Grid item xs={6}>
                        <FullWidthTextField
                            name="schoolYearGrade"
                            label="Current grade"
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            name="schoolYearsLeft"
                            label="Number of years left"
                            validate={requiredValidator}
                            mask={NumberMask}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            name="institution"
                            label="Name of school"
                            validate={requiredValidator}
                        />
                    </Grid>
                </When>
            </Condition>
        </React.Fragment>
    );
};

const OtherRelationshipsView = () => {
    return (
        <React.Fragment>
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    name={`personalDetails.maritalStatus`}
                    options={MaritalStatus}
                />
                <WhenFieldChanges
                    field="personalDetails.maritalStatus"
                    set="personalDetails.dateOfMarriage"
                    to={null}
                />
                <WhenFieldChanges
                    field="personalDetails.maritalStatus"
                    set="personalDetails.dateOfDivorce"
                    to={null}
                />
            </Grid>
            <Condition when={`personalDetails.maritalStatus`} matchesChild>
                <When is="Married">
                    <Grid item xs={6}>
                        <FullWidthDatePicker
                            name={`personalDetails.dateOfMarriage`}
                            label="Date of Current Marriage"
                            disableFuture
                            validate={requiredValidator}
                        />
                    </Grid>
                </When>
                <When is="Divorced">
                    <Grid item xs={6}>
                        <FullWidthDatePicker
                            name={`personalDetails.dateOfDivorce`}
                            disableFuture
                            validate={requiredValidator}
                        />
                    </Grid>
                </When>
            </Condition>
        </React.Fragment>
    );
};

const authorisedPersonView: React.FunctionComponent<StyledComponent> = ({
    classes,
}: StyledComponent) => {
    return (
        <Paper className={classes.paper}>
            <FormHeading text="Authorised Person" />
            <Grid container spacing={5} justify="flex-start">
                <PersonalDetailsView propertyPrefix="organisationDetails.authorisedPerson.personalDetails" />
                <Grid item xs={6}>
                    <FullWidthSearchSelectField
                        name="organisationDetails.authorisedPerson.personalDetails.maritalStatus"
                        options={MaritalStatus}
                        label="Marital Status"
                    />
                    <WhenFieldChanges
                        field="organisationDetails.authorisedPerson.personalDetails.maritalStatus"
                        set="organisationDetails.authorisedPerson.personalDetails.dateOfMarriage"
                        to={null}
                    />
                    <WhenFieldChanges
                        field="organisationDetails.authorisedPerson.personalDetails.maritalStatus"
                        set="organisationDetails.authorisedPerson.personalDetails.dateOfDivorce"
                        to={null}
                    />
                </Grid>
                <Condition
                    when="organisationDetails.authorisedPerson.personalDetails.maritalStatus"
                    is="Married"
                >
                    <True>
                        <Grid item xs={6}>
                            <FullWidthDatePicker
                                name="organisationDetails.authorisedPerson.personalDetails.dateOfMarriage"
                                label="Date of Marriage"
                                disableFuture
                                validate={requiredValidator}
                            />
                        </Grid>
                    </True>
                </Condition>
                <Condition
                    when="organisationDetails.authorisedPerson.personalDetails.maritalStatus"
                    is="Divorced"
                >
                    <True>
                        <Grid item xs={6}>
                            <FullWidthDatePicker
                                name="organisationDetails.authorisedPerson.personalDetails.dateOfDivorce"
                                label="Date of Divorce"
                                disableFuture
                                validate={requiredValidator}
                            />
                        </Grid>
                    </True>
                </Condition>
            </Grid>
            <Typography variant="subtitle2" className={classes.marginTop}>
                {`Authorised person's address details`}
            </Typography>
            <AddressDetailsEditView propertyPrefix="organisationDetails.authorisedPerson" />
        </Paper>
    );
};

export const AuthorisedPersonView = withStyles(styles)(authorisedPersonView);

interface ContactDetailsProps {
    readonly propertyPrefix?: string;
}

const ContactDetails: React.FunctionComponent<StyledComponent & ContactDetailsProps> = ({
    classes,
    propertyPrefix = '',
}: StyledComponent & ContactDetailsProps) => {
    const fullPropertyPrefix =
        propertyPrefix && propertyPrefix !== '' ? `${propertyPrefix}.` : propertyPrefix;

    return (
        <Paper className={classes.paper}>
            <FormHeading text="Contact details" />
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={6}>
                    <FullWidthTextField
                        name={`${fullPropertyPrefix}contactDetails.workTelephoneNumber`}
                        mask={TelephoneNumberMask}
                    />
                </Grid>
                <Condition when="relationship" is="MinorChild">
                    <True>
                        <CommunicationDetailsSubForm
                            communicationDetailsPropertyName={`${fullPropertyPrefix}contactDetails`}
                            displayOptions={{
                                telephoneNumber: true,
                                cellphoneNumber: true,
                                emailAddress: true,
                                preferredMethodOfCommunication: true,
                            }}
                            required="None"
                        />
                    </True>
                    <False>
                        <CommunicationDetailsSubForm
                            communicationDetailsPropertyName={`${fullPropertyPrefix}contactDetails`}
                            displayOptions={{
                                telephoneNumber: true,
                                cellphoneNumber: true,
                                emailAddress: true,
                                preferredMethodOfCommunication: true,
                            }}
                            required="Any"
                            requiredOptions={{
                                cellphoneNumber: true,
                                emailAddress: true,
                                preferredMethodOfCommunication: true,
                            }}
                        />
                    </False>
                </Condition>
            </Grid>
        </Paper>
    );
};

interface WhenTypeChangesProps {}
const WhenDependantTypeChanges: React.FunctionComponent<WhenTypeChangesProps> = () => {
    return (
        <React.Fragment>
            <WhenFieldChanges field="dependantType" set="relationship" to={null} />
            <WhenFieldChanges field="dependantType" set="relationshipDescription" to={null} />
            <WhenFieldChanges field="dependantType" set="claimCapacity" to={null} />
            <WhenFieldChanges field="dependantType" set="linkedToBeneficiary" to={null} />
            <WhenFieldChanges field="dependantType" set="isExecutor" to={null} />
            <WhenFieldChanges field="dependantType" set="disability" to={null} />
            <WhenFieldChanges field="dependantType" set="disabilityDetails" to={null} />
            <WhenFieldChanges field="dependantType" set="underCuratorship" to={null} />
            <WhenFieldChanges field="dependantType" set="employed" to={null} />
            <WhenFieldChanges field="dependantType" set="occupation" to={null} />
            <WhenFieldChanges field="dependantType" set="company" to={null} />
            <WhenFieldChanges field="dependantType" set="personalDetails" to={null} />
            <WhenFieldChanges field="dependantType" set="curator" to={null} />
            <WhenFieldChanges field="dependantType" set="guardian" to={null} />
            <WhenFieldChanges field="dependantType" set="organisationDetails" to={null} />
        </React.Fragment>
    );
};

function relationshipDescriptions(relationship: string) {
    switch (relationship) {
        case 'MinorChild':
            return RelationshipsChildren;
        case 'MajorChild':
            return RelationshipsChildren;
        case 'Spouse':
            return RelationshipsSpouses;
        case 'ExSpouse':
            return RelationshipsExSpouses;
        case 'EstrangedSpouse':
            return RelationshipsSpouses;
        case 'LifePartner':
            return RelationshipsPartner;
        case 'Relative':
            return RelationshipsRelatives;
        case 'Parent':
            return RelationshipsParents;
        case 'Other':
            return RelationshipsOther;
    }
}

const mapStateToProps = (state: State) => ({
    possibleParents: getPossibleParents(state),
});

export const DependantEditView = connect(
    mapStateToProps,
    {}
)(withStyles(styles)(dependantEditView));
