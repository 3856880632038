import { Grid, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { CountriesOfIssue } from '../../../codes/countries-of-issue';
import styles, { StyledComponent } from '../../../styles/styles';
import {
    Condition,
    False,
    FullWidthSearchSelectField,
    FullWidthTextField,
    True,
    When,
    WhenFieldChanges,
} from '../../form-components';
import { NumberMask } from '../../input-masks';
import { conditionalRequired } from '../../validations';
import { getPropertyValueFunction } from '../utility-functions';
import { AddressDetails, AddressType, getAddressTypeOptions, Provinces } from './model';

interface Props extends StyledComponent {
    readonly addressPropertyName?: string;
    readonly addressType?: string;
    readonly disabled?: boolean;
    readonly required?: boolean;
}

const addressDetailsSubForm: React.FunctionComponent<Props> = ({
    addressPropertyName,
    addressType,
    disabled,
    required,
}: Props) => {
    const addressTypeOptions = getAddressTypeOptions(addressType);
    const propertyPrefix = !!addressPropertyName ? addressPropertyName + '.' : '';
    const propValue = getPropertyValueFunction<AddressDetails>(addressPropertyName);
    return (
        <React.Fragment>
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    name={`${propertyPrefix}addressType`}
                    options={addressTypeOptions}
                    validate={conditionalRequired(
                        values =>
                            required ||
                            !!propValue(values, 'addressLine1') ||
                            !!propValue(values, 'suburb') ||
                            !!propValue(values, 'postalCode') ||
                            !!propValue(values, 'town') ||
                            !!propValue(values, 'province') ||
                            !!propValue(values, 'country')
                    )}
                    disabled={disabled}
                />
            </Grid>
            <WhenFieldChanges
                field={`${propertyPrefix}addressType`}
                becomes={AddressType.Foreign}
                set={`${propertyPrefix}suburb`}
                to={null}
            />
            <WhenFieldChanges
                field={`${propertyPrefix}addressType`}
                becomes={AddressType.Foreign}
                set={`${propertyPrefix}town`}
                to={null}
            />
            <WhenFieldChanges
                field={`${propertyPrefix}addressType`}
                becomes={AddressType.Foreign}
                set={`${propertyPrefix}postalCode`}
                to={null}
            />
            <WhenFieldChanges
                field={`${propertyPrefix}addressType`}
                condition={addressType => addressType !== AddressType.Foreign}
                set={`${propertyPrefix}country`}
                to="South Africa"
            />
            <WhenFieldChanges
                field={`${propertyPrefix}addressType`}
                condition={addressType => !!!addressType}
                set={`${propertyPrefix}country`}
                to={null}
            />
            <WhenFieldChanges
                field={`${propertyPrefix}addressType`}
                becomes={AddressType.Foreign}
                set={`${propertyPrefix}province`}
                to={null}
            />
            <WhenFieldChanges
                field={`${propertyPrefix}addressType`}
                condition={isBoxOrBag}
                set={`${propertyPrefix}addressLine1`}
                to={null}
            />
            <WhenFieldChanges
                field={`${propertyPrefix}addressType`}
                condition={isBoxOrBag}
                set={`${propertyPrefix}addressLine2`}
                to={null}
            />
            <WhenFieldChanges
                field={`${propertyPrefix}addressType`}
                condition={isBoxOrBag}
                set={`${propertyPrefix}addressLine3`}
                to={null}
            />
            <Condition when={`${propertyPrefix}addressType`} matchesChild>
                <When is={AddressType.PostBox}>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            name={`${propertyPrefix}addressLine1`}
                            label="PO Box"
                            validate={conditionalRequired(
                                values =>
                                    required ||
                                    !!propValue(values, 'addressType') ||
                                    !!propValue(values, 'addressLine2') ||
                                    !!propValue(values, 'addressLine3') ||
                                    !!propValue(values, 'addressLine4') ||
                                    !!propValue(values, 'suburb') ||
                                    !!propValue(values, 'postalCode') ||
                                    !!propValue(values, 'town') ||
                                    !!propValue(values, 'province') ||
                                    !!propValue(values, 'country')
                            )}
                            mask={NumberMask}
                            disabled={disabled}
                        />
                    </Grid>
                </When>
                <When is={AddressType.PrivateBag}>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            name={`${propertyPrefix}addressLine1`}
                            label="Private Bag"
                            validate={conditionalRequired(
                                values =>
                                    required ||
                                    !!propValue(values, 'addressType') ||
                                    !!propValue(values, 'addressLine2') ||
                                    !!propValue(values, 'addressLine3') ||
                                    !!propValue(values, 'addressLine4') ||
                                    !!propValue(values, 'suburb') ||
                                    !!propValue(values, 'postalCode') ||
                                    !!propValue(values, 'town') ||
                                    !!propValue(values, 'province') ||
                                    !!propValue(values, 'country')
                            )}
                            disabled={disabled}
                        />
                    </Grid>
                </When>
            </Condition>
            <Condition
                when={`${propertyPrefix}addressType`}
                is={[AddressType.Street, AddressType.Foreign]}
            >
                <True>
                    <React.Fragment>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name={`${propertyPrefix}addressLine1`}
                                id={`${propertyPrefix}addressLine1`}
                                validate={conditionalRequired(
                                    values =>
                                        required ||
                                        !!propValue(values, 'addressType') ||
                                        !!propValue(values, 'addressLine2') ||
                                        !!propValue(values, 'addressLine3') ||
                                        !!propValue(values, 'addressLine4') ||
                                        !!propValue(values, 'suburb') ||
                                        !!propValue(values, 'postalCode') ||
                                        !!propValue(values, 'town') ||
                                        !!propValue(values, 'province') ||
                                        !!propValue(values, 'country')
                                )}
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name={`${propertyPrefix}addressLine2`}
                                validate={conditionalRequired(
                                    values => !!propValue(values, 'addressLine3')
                                )}
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name={`${propertyPrefix}addressLine3`}
                                disabled={disabled}
                            />
                        </Grid>
                    </React.Fragment>
                </True>
            </Condition>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${propertyPrefix}suburb`}
                    label="Town/Suburb"
                    validate={conditionalRequired(
                        values =>
                            required ||
                            !!propValue(values, 'addressType') ||
                            !!propValue(values, 'addressLine1') ||
                            !!propValue(values, 'postalCode') ||
                            !!propValue(values, 'town') ||
                            !!propValue(values, 'province') ||
                            !!propValue(values, 'country')
                    )}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${propertyPrefix}town`}
                    label="City"
                    validate={conditionalRequired(
                        values =>
                            required ||
                            !!propValue(values, 'addressType') ||
                            !!propValue(values, 'addressLine1') ||
                            !!propValue(values, 'suburb') ||
                            !!propValue(values, 'postalCode') ||
                            !!propValue(values, 'province') ||
                            !!propValue(values, 'country')
                    )}
                    disabled={disabled}
                />
            </Grid>
            <Condition when={`${propertyPrefix}addressType`} is={AddressType.Foreign}>
                <False>
                    <Condition when={`${propertyPrefix}addressType`} hasValue>
                        <True>
                            <Grid item xs={6}>
                                <FullWidthTextField
                                    name={`${propertyPrefix}postalCode`}
                                    id={`${propertyPrefix}postalCode`}
                                    validate={conditionalRequired(
                                        values =>
                                            required ||
                                            !!propValue(values, 'addressType') ||
                                            !!propValue(values, 'addressLine1') ||
                                            !!propValue(values, 'suburb') ||
                                            !!propValue(values, 'town') ||
                                            !!propValue(values, 'province') ||
                                            !!propValue(values, 'country')
                                    )}
                                    mask={NumberMask}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FullWidthSearchSelectField
                                    name={`${propertyPrefix}province`}
                                    options={Provinces}
                                    validate={conditionalRequired(
                                        values =>
                                            required ||
                                            !!propValue(values, 'addressType') ||
                                            !!propValue(values, 'addressLine1') ||
                                            !!propValue(values, 'suburb') ||
                                            !!propValue(values, 'postalCode') ||
                                            !!propValue(values, 'town') ||
                                            !!propValue(values, 'country')
                                    )}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FullWidthSearchSelectField
                                    name={`${propertyPrefix}country`}
                                    options={CountriesOfIssue}
                                    validate={conditionalRequired(
                                        values =>
                                            required ||
                                            !!propValue(values, 'addressType') ||
                                            !!propValue(values, 'addressLine1') ||
                                            !!propValue(values, 'suburb') ||
                                            !!propValue(values, 'postalCode') ||
                                            !!propValue(values, 'town') ||
                                            !!propValue(values, 'province')
                                    )}
                                    disabled={true}
                                />
                            </Grid>
                        </True>
                    </Condition>
                </False>
                <True>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            name={`${propertyPrefix}postalCode`}
                            label="Foreign Postal Code"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthSearchSelectField
                            name={`${propertyPrefix}country`}
                            options={CountriesOfIssue}
                            validate={conditionalRequired(
                                values =>
                                    required ||
                                    !!propValue(values, 'addressType') ||
                                    !!propValue(values, 'addressLine1') ||
                                    !!propValue(values, 'suburb') ||
                                    !!propValue(values, 'postalCode') ||
                                    !!propValue(values, 'town') ||
                                    !!propValue(values, 'province')
                            )}
                            disabled={disabled}
                        />
                    </Grid>
                </True>
            </Condition>
        </React.Fragment>
    );
};

function isBoxOrBag(addressType: AddressType) {
    return addressType === AddressType.PostBox || addressType === AddressType.PrivateBag;
}

export const AddressDetailsSubForm = withStyles(styles)(addressDetailsSubForm);
