import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { State } from '../../redux/root-reducer';
import { Nullable } from '../../shared-types';
import { FormSubmissionState } from '../../workflow-navigation/reducers/fom-submission-reducer';
import { Workflow } from '../../workflow-navigation/reducers/workflow';
import { WorkflowScreen } from '../../workflow-navigation/reducers/workflow-screen';
import { WorkflowSideDrawer } from '../../workflow-navigation/views/workflow-side-drawer';

interface Props {
    activeWorkflow: Workflow;
    activeScreen: Nullable<WorkflowScreen>;
    formSubmissionState: FormSubmissionState;
}

const assessorDashboardDrawer: React.FunctionComponent<Props> = ({
    activeWorkflow,
    activeScreen,
    formSubmissionState,
}: Props) => {
    const assessorDashboard = !!activeWorkflow ? activeWorkflow.assessorScreens() : [];
    return (
        <WorkflowSideDrawer
            screens={assessorDashboard}
            activeScreen={activeScreen}
            formSubmissionState={formSubmissionState}
        />
    );
};

const mapStateToProps = (state: State) => ({
    activeWorkflow: state.claimSession.workflow.activeWorkflow,
    activeScreen: state.claimSession.workflow.activeScreen,
    formSubmissionState: state.claimSession.formSubmission,
});

const mapDispatchToProps = {};

export const AssessorDashboardDrawer = connect(
    mapStateToProps,
    mapDispatchToProps
)(assessorDashboardDrawer);
