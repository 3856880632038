import { every } from 'lodash';
import { Nullable } from '../../shared-types';

export function dateBefore(leftDate?: Date, rightDate?: Date): boolean {
    if (!leftDate || !rightDate) {
        return false;
    }
    return leftDate < rightDate;
}
export function isEmpty(value?: any) {
    if (!value) {
        return true;
    }

    for (const key in value) {
        if (value.hasOwnProperty(key)) {
            const propValue = value[key];
            if (propValue === undefined || propValue === null) {
                continue;
            }

            if (Array.isArray(propValue) && propValue.length === 0) {
                continue;
            }

            if (typeof propValue === 'object' && every(Object.values(propValue), isEmpty)) {
                continue;
            }

            return false;
        }
    }
    return true;
}

export function combineValidationErrors(...errors: Nullable<string>[]): string | undefined {
    const _errors: string[] = [];
    for (const error of errors) {
        if (error) {
            _errors.push(error);
        }
    }
    if (_errors.length <= 0) {
        return undefined;
    }
    return _errors.join('\n');
}

export const nameof = <T>(name: keyof T) => name;
