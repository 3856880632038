import { Reducer } from 'redux';
import {
    CLEAR_SUBMISSION_FLAGS,
    SET_SUBMISSION_CALLBACKS,
    SET_SUBMIT_SUCCESS_FLAG,
    SET_SUBMITTING_FLAG,
} from '../';
import { ReduxAction } from '../../redux/redux-actions';
import { HandleSubmit } from '../actions';

export interface FormSubmissionState {
    onSubmit?: HandleSubmit;
    isPristine?: () => boolean;
    hasValidationErrors?: () => boolean;
    isSubmitting: boolean;
}

export const initialFormSubmissionState = {
    isSubmitting: false,
};

export type FormSubmissionAction = ReduxAction<FormSubmissionState>;
export const formSubmissionReducer: Reducer<FormSubmissionState, FormSubmissionAction> = (
    state = initialFormSubmissionState,
    action
) => {
    switch (action.type) {
        case SET_SUBMISSION_CALLBACKS:
        case SET_SUBMITTING_FLAG:
        case SET_SUBMIT_SUCCESS_FLAG:
        case CLEAR_SUBMISSION_FLAGS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
