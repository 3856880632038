import React from 'react'; // eslint-disable-line
import { Grid, Paper, withStyles, Button, Tooltip, Typography } from '@material-ui/core';
import styles, { StyledComponent } from '../../../../styles/styles';
import { LabeledValue } from '../../../../controls/labeled-value';
import { Edit } from '@material-ui/icons';
import { OpenModal, openModalAction } from '../../../../form-modal/actions';
import { FormModalView } from '../../../../form-modal/views/form-modal';
import { connect } from 'react-redux';
import { RAClaimDetailsEditModal } from './ra-claim-details-edit-modal';
import { State } from '../../../../redux/root-reducer';
import { getClaim } from '../../../../claim';
import { getCauseOfdeathDescription, getDisplayDate } from '../../../../shared-functions';
import { getRAClaimDetailsFromClaim } from './reducer';
import { DeathClaimDetails } from '../../death/claim-event-details/reducer';
import { ButtonWrapper } from '../../../../forms';

type Props = StyledComponent & {
    openModal: OpenModal;
    deathClaimDetails: DeathClaimDetails;
    isReadOnly: boolean;
};
const raClaimDetailsView: React.FunctionComponent<Props> = ({
    classes,
    deathClaimDetails,
    openModal,
    isReadOnly,
}: Props) => {
    const isReportedToPolice = deathClaimDetails.reportedToPolice ? 'Yes' : 'No';
    const edit = () => {
        openModal({
            modalContent: <FormModalView formView={RAClaimDetailsEditModal} />,
        });
    };
    return (
        <React.Fragment>
            <Grid container justify="flex-start" style={{ marginTop: '4px' }}>
                <Grid item xs={11}>
                    <Typography style={{ marginTop: '8px' }} variant="subtitle2">
                        Claim Details
                    </Typography>
                </Grid>
                <ButtonWrapper>
                    <Grid item xs={1} container justify="flex-end">
                        <Tooltip title="Edit Claim Details">
                            <Button
                                id="EditDeathClaimDetails"
                                color="secondary"
                                onClick={() => edit()}
                            >
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            </Grid>
            <Paper className={classes.paper}>
                <Grid container spacing={0} justify="flex-start">
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="Date of event"
                            value={getDisplayDate(deathClaimDetails.dateOfEvent)}
                        />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="Place of death"
                            value={deathClaimDetails.placeOfDeath}
                        />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="Place of death postal code"
                            value={deathClaimDetails.postalCode}
                        />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue
                            label="Date claim reported"
                            value={getDisplayDate(deathClaimDetails.dateClaimReported)}
                        />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                        <LabeledValue label="Type of death" value={deathClaimDetails.typeOfDeath} />
                    </Grid>
                    {deathClaimDetails.causeOfDeath !== '799' &&
                        deathClaimDetails.causeOfDeath !== '798' && (
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Cause of death"
                                    value={getCauseOfdeathDescription(
                                        deathClaimDetails.causeOfDeath
                                    )}
                                />
                            </Grid>
                        )}
                    {(deathClaimDetails.causeOfDeath === '799' ||
                        deathClaimDetails.causeOfDeath === '798') && (
                        <Grid item xs={4} lg={3}>
                            <LabeledValue
                                label="Cause of death"
                                value={deathClaimDetails.causeOfDeathDescription}
                            />
                        </Grid>
                    )}
                    <Grid item xs={4} lg={3}>
                        <LabeledValue label="Reported to the police?" value={isReportedToPolice} />
                    </Grid>
                    {isReportedToPolice === 'Yes' && (
                        <Grid item xs={4} lg={3}>
                            <LabeledValue
                                label="Case number"
                                value={deathClaimDetails.caseNumber}
                            />
                        </Grid>
                    )}

                    {deathClaimDetails.dateOfWill !== null && (
                        <Grid item xs={4} lg={3}>
                            <LabeledValue
                                label="Date of will"
                                value={getDisplayDate(deathClaimDetails.dateOfWill)}
                            />
                        </Grid>
                    )}

                    {deathClaimDetails.lateSubmissionReason !== null && (
                        <Grid item xs={4} lg={3}>
                            <LabeledValue
                                label="Reason for late submission"
                                value={deathClaimDetails.lateSubmissionReason}
                            />
                        </Grid>
                    )}
                    {deathClaimDetails.lateSubmissionReasonDescription && (
                        <Grid item xs={4} lg={3}>
                            <LabeledValue
                                label="Reason for late submission description"
                                value={deathClaimDetails.lateSubmissionReasonDescription}
                            />
                        </Grid>
                    )}

                    {deathClaimDetails.bi1663 !== null && (
                        <Grid item xs={4} lg={3}>
                            <LabeledValue label="BI-1663 number" value={deathClaimDetails.bi1663} />
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </React.Fragment>
    );
};
const mapStateToProps = (state: State) => ({
    deathClaimDetails: getRAClaimDetailsFromClaim(getClaim(state)).claimEventDetails || {},
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = { openModal: openModalAction };
export const RAClaimDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(raClaimDetailsView));
