import React from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { getCodeDescription, getFullName } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { Curator } from '../reducer';
import { SubSectionHeading } from './dependant-view-expanded';

interface Props extends StyledComponent {
    curator?: Curator;
}

const dependantViewCurator: React.FunctionComponent<Props> = ({ curator }: Props) => {
    if (!!!curator) {
        return null;
    }
    return (
        <React.Fragment>
            <SubSectionHeading heading="Curator's details" />
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Name" value={getFullName(curator)} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Cellphone number"
                    value={curator.contactDetails && curator.contactDetails.cellphoneNumber}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Email"
                    value={curator.contactDetails && curator.contactDetails.emailAddress}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Communication preference"
                    value={getCodeDescription(
                        'preferredmethodofcommunication',
                        curator.contactDetails &&
                            curator.contactDetails.preferredMethodOfCommunication
                    )}
                />
            </Grid>
        </React.Fragment>
    );
};

export const DependantViewCurator = withStyles(styles)(dependantViewCurator);
