import React from 'react'; // eslint-disable-line

import { Button, Divider, Grid, Paper, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import classNames from 'classnames';
import { some } from 'lodash';
import { connect } from 'react-redux';
import {
    getClaimInformation,
    getPreviousOccupationalDuties,
    OccupationalDutiesBreakdown,
} from '../../../../../claim-information/reducer';
import { OpenModal, openModalAction } from '../../../../../form-modal/actions';
import { FormModalView } from '../../../../../form-modal/views/form-modal';
import { State } from '../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../styles/styles';
import OccupationDetailsEditModal from './breakdown-of-duties-edit-modal';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { ButtonWrapper } from '../../../../../forms';

type Props = StyledComponent & {
    currentOccupation: OccupationalDutiesBreakdown;
    openModal: OpenModal;
    previousOccupation: OccupationalDutiesBreakdown;
    isReadOnly: boolean;
    isUnderWriting?: boolean;
};

const breakdownOfDuties: React.FunctionComponent<Props> = ({
    classes,
    currentOccupation,
    openModal,
    previousOccupation,
    isUnderWriting,
    isReadOnly,
}: Props) => {
    const edit = () => {
        openModal({
            modalContent: <FormModalView formView={OccupationDetailsEditModal} />,
        });
    };

    return (
        <Grid container className={classes.marginTop2}>
            <Grid container item xs={12} alignItems="center">
                <Typography variant="subtitle2">Breakdown of Duties</Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    {some(previousOccupation) && (
                        <React.Fragment>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">
                                        Occupation at Application Stage
                                    </Typography>
                                </Grid>
                                <OccupationDetailsView occupation={previousOccupation} />
                            </Grid>
                            <Divider
                                className={classNames(classes.marginTop, classes.marginBottom)}
                            />
                        </React.Fragment>
                    )}
                    <Grid container spacing={1}>
                        <Grid container justify="flex-start">
                            <Grid item xs={10}>
                                <Typography variant="subtitle2">
                                    Occupation at Claim Stage
                                </Typography>
                            </Grid>
                            <ButtonWrapper>
                                <Grid item xs={2} className={classes.rightAlign}>
                                    <Tooltip title="Edit Occupation">
                                        <Button
                                            id="EditOccupationDetails"
                                            color="secondary"
                                            disabled={isUnderWriting}
                                            onClick={edit}
                                        >
                                            <Edit />
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            </ButtonWrapper>
                        </Grid>
                        {some(currentOccupation) && (
                            <OccupationDetailsView occupation={currentOccupation} />
                        )}
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

type InnerProps = {
    occupation: OccupationalDutiesBreakdown;
};

const OccupationDetailsView: React.FunctionComponent<InnerProps> = ({ occupation }: InnerProps) => {
    return (
        <Grid item xs={12}>
            <Grid container>
                <Grid item xs={12}>
                    <LabeledValue
                        label="Full Time Occupation Description"
                        value={occupation.occupationDescription}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LabeledValue
                        label="Administrative Duties"
                        value={`${occupation.admin || 0}%`}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LabeledValue
                        label="Supervisory Duties"
                        value={`${occupation.supervisory || 0}%`}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LabeledValue label="Manual Duties" value={`${occupation.manual || 0}%`} />
                </Grid>
                <Grid item xs={3}>
                    <LabeledValue label="Travel Duties" value={`${occupation.travel || 0}%`} />
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state: State) => ({
    currentOccupation: getClaimInformation(state).dutyBreakdown,
    previousOccupation: getPreviousOccupationalDuties(state),
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = {
    openModal: openModalAction,
};

export const BreakdownOfDuties = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(breakdownOfDuties));
