import React, { useState } from 'react'; // eslint-disable-line
import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { OpenModal, openModalAction } from '../../../form-modal/actions';
import { FormModalView } from '../../../form-modal/views/form-modal';
import downarrow from '../../../master-layout/views/downarrow.svg';
import uparrow from '../../../master-layout/views/uparrow.svg';
import { State } from '../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../styles/styles';
import { BeneficiaryDetails, getPaymentBeneficiaries } from '../../reducer';
import { BankingDetailsEditView } from './edit-banking-details-modal';
import { PaymentBankingDetailsView } from './payment-banking-details';
import { Nullable } from '../../../shared-types';
import { ClaimType } from '../../../claim/claim-types';
import { getClaim } from '../../../claim';
import { ButtonWrapper } from '../../../forms';

interface DispatchProps {
    openModal: OpenModal;
}
interface OwnProps {
    beneficiaryId: string;
}

interface StateProps {
    beneficiaries: BeneficiaryDetails[];
    claimType: Nullable<ClaimType>;
    isReadOnly: boolean;
}

type Props = StyledComponent & DispatchProps & OwnProps & StateProps;
const BankingDetailsExpansion: React.FunctionComponent<Props> = ({
    beneficiaries,
    beneficiaryId,
    classes,
    claimType,
    openModal,
    isReadOnly,
}: Props) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const toggleState = (evt: any) => {
        setIsExpanded(value => !value);
        evt.preventDefault();
        return false;
    };
    const claimantIndex = beneficiaries.findIndex(c => c.beneficiaryId === beneficiaryId);
    if (claimantIndex === -1) {
        return null;
    }
    const beneficiary = beneficiaries[claimantIndex] || {};
    const bankingDetails = beneficiary.bankingDetails || {};
    const editBankDetails = () => {
        openModal({
            modalContent: (
                <FormModalView
                    formView={() => (
                        <BankingDetailsEditView
                            bankingDetails={bankingDetails}
                            beneficiaryId={beneficiaryId}
                        />
                    )}
                />
            ),
        });
    };
    const expanderElement = (
        <a href="/#" onClick={toggleState}>
            {isExpanded ? (
                <img alt="collapse" src={uparrow} />
            ) : (
                <img alt="expand" src={downarrow} />
            )}
        </a>
    );
    const expendedTooltip = !isExpanded ? 'Open' : 'Close';
    return (
        <Grid container spacing={0} justify="flex-start">
            <Grid container spacing={0} item xs={12}>
                <Grid item xs={11} container>
                    <Grid item xs={2} lg={2}>
                        <Typography className={classes.marginTop} variant="h6">
                            Bank Details
                        </Typography>
                    </Grid>
                    <ButtonWrapper>
                        {isEditable(claimType) && (
                            <Grid item xs={2} lg={2}>
                                <Tooltip title="Edit Bank Details">
                                    <Button
                                        id="EditBankDetails"
                                        color="secondary"
                                        className={classes.button}
                                        style={{ margin: '12px' }}
                                        onClick={() => editBankDetails()}
                                    >
                                        <Edit />
                                    </Button>
                                </Tooltip>
                            </Grid>
                        )}
                    </ButtonWrapper>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                lg={12}
                style={{
                    textAlign: 'right',
                    marginTop: '-39px',
                    marginLeft: '-20px',
                }}
            >
                <Tooltip title={expendedTooltip}>{expanderElement}</Tooltip>
            </Grid>
            <React.Fragment>
                {isExpanded && (
                    <PaymentBankingDetailsView
                        bankingDetails={bankingDetails}
                        riskStatus={beneficiary.riskStatus}
                        screeningDate={beneficiary.screeningDate}
                    />
                )}
            </React.Fragment>
        </Grid>
    );
};

function isEditable(claimType: Nullable<ClaimType>) {
    if (claimType === ClaimType.Dread || claimType === ClaimType.Disability) {
        return false;
    }
    return true;
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => {
    const claim = getClaim(state);
    return {
        beneficiaries: getPaymentBeneficiaries(claim),
        claimType: claim.claimType,
        isReadOnly: state.claimSession.readOnly,
    };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    openModal: options => dispatch(openModalAction(options)),
});

export const BankingDetailsExpansionView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(BankingDetailsExpansion));
