import React from 'react'; // eslint-disable-line
import { withStyles, Grid, Typography } from '@material-ui/core';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { connect } from 'react-redux';
import { State } from '../../../../../redux/root-reducer';
import { getFormDetails } from '../form-details-reducer';
import {
    Condition,
    FullWidthCheckBoxField,
    FullWidthSearchSelectField,
    FullWidthTextField,
    True,
    WhenFieldChanges,
} from '../../../../../forms';
import {
    AddressTypesLivingLifestyle,
    Provinces,
} from '../../../../../forms/sub-forms/address-details';
import { requiredValidator } from '../../../../../forms/validations';
import { NumberMask } from '../../../../../forms/input-masks';

interface BaseProps {
    formDetails?: any;
    type: string;
    prefix: string;
}

interface Props extends BaseProps, StyledComponent {}

const addressDetailsEdit: React.FunctionComponent<Props> = ({
    classes,
    formDetails,
    type,
    prefix,
}: Props) => {
    let addressDetails;
    if (formDetails) {
        addressDetails =
            type === 'LifeAssured'
                ? formDetails.lifeAssured?.addressDetails
                : formDetails.policyHolder?.individual?.addressDetails;
    }
    return (
        <Grid container spacing={5} justify="flex-start">
            <Grid container justify="space-between" className={classes.marginTop2}>
                <Grid container item xs={10} alignItems="center">
                    <Typography style={{ padding: 10 }} className={classes.heading} variant="body1">
                        Residential Address
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    label="Address type"
                    name={`${prefix}.addressType`}
                    options={AddressTypesLivingLifestyle}
                    validate={requiredValidator}
                    disabled={true}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    label="Address Line 1"
                    name={`${prefix}.addressLineOne`}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField label="Address Line 2" name={`${prefix}.addressLineTwo`} />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    label="Town/Surburb"
                    name={`${prefix}.addressSuburb`}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    label="City"
                    name={`${prefix}.addressTown`}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    label="Postal Code"
                    name={`${prefix}.postalCode`}
                    validate={requiredValidator}
                    mask={NumberMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    label="Province"
                    name={`${prefix}.province`}
                    options={Provinces}
                    validate={requiredValidator}
                />
            </Grid>

            <Grid container justify="space-between" className={classes.marginTop2}>
                <Grid container item xs={10} alignItems="center">
                    <Typography style={{ padding: 10 }} className={classes.heading} variant="body1">
                        Postal Address
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={12}>
                <FullWidthCheckBoxField
                    label="Details same as residential address"
                    name={`${prefix}.sameAsResidentialAddress`}
                />
                <WhenFieldChanges
                    field={`${prefix}.sameAsResidentialAddress`}
                    becomes={true}
                    set={`${prefix}.postalAddressLineOne`}
                    to={addressDetails?.addressLineOne}
                />
                <WhenFieldChanges
                    field={`${prefix}.sameAsResidentialAddress`}
                    becomes={true}
                    set={`${prefix}.postalAddressLineTwo`}
                    to={addressDetails?.addressLineTwo}
                />
                <WhenFieldChanges
                    field={`${prefix}.sameAsResidentialAddress`}
                    becomes={true}
                    set={`${prefix}.postalAddressSuburb`}
                    to={addressDetails?.addressSuburb}
                />
                <WhenFieldChanges
                    field={`${prefix}.sameAsResidentialAddress`}
                    becomes={true}
                    set={`${prefix}.postalAddressTown`}
                    to={addressDetails?.addressTown}
                />
                <WhenFieldChanges
                    field={`${prefix}.sameAsResidentialAddress`}
                    becomes={true}
                    set={`${prefix}.postalPostalCode`}
                    to={addressDetails?.postalCode}
                />
                <WhenFieldChanges
                    field={`${prefix}.sameAsResidentialAddress`}
                    becomes={true}
                    set={`${prefix}.province`}
                    to={addressDetails?.province}
                />
                <WhenFieldChanges
                    field={`${prefix}.sameAsResidentialAddress`}
                    becomes={false}
                    set={`${prefix}.postalAddressLineOne`}
                    to={addressDetails?.postalAddressLineOne}
                />
                <WhenFieldChanges
                    field={`${prefix}.sameAsResidentialAddress`}
                    becomes={false}
                    set={`${prefix}.postalAddressLineTwo`}
                    to={addressDetails?.postalAddressLineTwo}
                />
                <WhenFieldChanges
                    field={`${prefix}.sameAsResidentialAddress`}
                    becomes={false}
                    set={`${prefix}.postalAddressSuburb`}
                    to={addressDetails?.postalAddressSuburb}
                />
                <WhenFieldChanges
                    field={`${prefix}.sameAsResidentialAddress`}
                    becomes={false}
                    set={`${prefix}.postalAddressTown`}
                    to={addressDetails?.postalAddressTown}
                />
                <WhenFieldChanges
                    field={`${prefix}.sameAsResidentialAddress`}
                    becomes={false}
                    set={`${prefix}.postalPostalCode`}
                    to={addressDetails?.postalPostalCode}
                />
                <WhenFieldChanges
                    field={`${prefix}.sameAsResidentialAddress`}
                    becomes={false}
                    set={`${prefix}.province`}
                    to={addressDetails?.province}
                />
            </Grid>
            <Condition when={`${prefix}.sameAsResidentialAddress`} is={false}>
                <True>
                    <Grid item xs={6}>
                        <FullWidthSearchSelectField
                            label="Address type"
                            name={`${prefix}.addressType`}
                            options={AddressTypesLivingLifestyle}
                            validate={requiredValidator}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            label="Address Line 1"
                            name={`${prefix}.postalAddressLineOne`}
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            label="Address Line 2"
                            name={`${prefix}.postalAddressLineTwo`}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            label="Town/Surburb"
                            name={`${prefix}.postalAddressSuburb`}
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            label="City"
                            name={`${prefix}.postalAddressTown`}
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            label="Postal Code"
                            name={`${prefix}.postalPostalCode`}
                            validate={requiredValidator}
                            mask={NumberMask}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthSearchSelectField
                            label="Province"
                            name={`${prefix}.postalProvince`}
                            options={Provinces}
                            validate={requiredValidator}
                        />
                    </Grid>
                </True>
            </Condition>
        </Grid>
    );
};

const mapStateToProps = (state: State) => ({
    formDetails: getFormDetails(state),
});

const mapDispatchToProps = {};

export const AddressDetailsEdit = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(addressDetailsEdit));
