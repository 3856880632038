import React from 'react'; // eslint-disable-line

import { Grid, Typography, withStyles } from '@material-ui/core';
import { Section } from '../../../../../../controls/section';
import styles, { StyledComponent } from '../styles';

interface OwnProps {
    decisions: string[];
}

type Props = OwnProps & StyledComponent;
const underwritingDecisionsView: React.FunctionComponent<Props> = ({
    decisions,
    classes,
}: Props) => {
    return (
        <Section title="Decisions" noChildrenTitle="No Decisions">
            {decisions
                .filter(val => !!val && !!val.trim())
                .map((decision, index) => (
                    <Grid item xs={12} className={classes.separatedGridItem} key={index}>
                        <Typography>{decision}</Typography>
                    </Grid>
                ))}
        </Section>
    );
};

export const UnderwritingDecisionsView = withStyles(styles)(underwritingDecisionsView);
