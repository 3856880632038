import { Omit } from 'lodash';
import { AnyAction, Reducer } from 'redux';
import { getProperty, getPropertyFromClaim, Claim } from '../../claim/reducers';
import { State } from '../../redux/root-reducer';
import { Nullable } from '../../shared-types';
import { ClaimType } from '../../claim/claim-types';
import { LiabilityStatus } from './common/common-types';

export interface AssessmentDecision {
    readonly id: string;
    readonly final?: boolean;
    readonly isOverLimit?: boolean;
    readonly assessmentType?: string;
    readonly assessor?: string;
    readonly assessmentDate: Date;
    readonly summary?: string;
    readonly decision?: string;
    readonly reason?: string;
    readonly claimType?: Nullable<ClaimType>;
    readonly effectiveDate?: Date;
    readonly expiryDate?: Date;
    readonly paymentBasis?: string;
    readonly percentagePayable?: number;
    readonly amountPayable?: number;
    readonly unallocatedClaimAmount?: number;
    readonly mapClaim?: string;
    readonly claimsBooster?: string;
    requesterEmail?: string;
    isSentForApproval?: boolean;
    approvalSummary?: string;
    requestStatus?: string;
    approveBy?: string;
}

export interface ClaimLiability {
    readonly id?: string;
    readonly status?: LiabilityStatus;
    readonly errorMessage?: string;
    readonly claimAmount?: number;
    readonly quoteAmount?: number;
    readonly overpaymentAmount?: number;
    readonly moneyInSuspense?: number;
    readonly accountBalance?: number;
    readonly effectiveDate?: Date;
    readonly movementStatus?: LiabilityStatus;
    readonly movementMessage?: string;
    isLiabilitySelected?: boolean;
    updatedEffectiveDate?: Date;
}

export interface AssessmentOpinion {
    readonly opinion?: string;
    readonly opinionBy: string;
    readonly opinionDate: Date;
}

export interface AssessmentSummary {
    readonly summary?: string;
    readonly summaryBy: string;
    readonly summaryDate: Date;
}

export interface BenefitAssessmentDetails {
    readonly benefitCode: string;
    readonly benefitName: string;
    readonly commencementDate?: Date;
    readonly sumAssured?: number;
    readonly summary?: string;
    readonly summaryBy?: string;
    readonly summaryDate?: Date;
    readonly opinion?: string;
    readonly opinionBy?: string;
    readonly opinionDate?: Date;
    readonly decisions: AssessmentDecision[];
    readonly claimLiability?: ClaimLiability;
    readonly summaries: AssessmentSummary[];
    readonly opinions: AssessmentOpinion[];
}

export interface PolicyAssessmentDetails {
    readonly policyNumber: string;
    readonly automaticBenefitIncrease?: number;
    readonly benefits: BenefitAssessmentDetails[];
}

export const defaultClaimLiability: ClaimLiability = {
    status: 'Pending',
};

export interface AssessmentDecisionViewModel
    extends Omit<PolicyAssessmentDetails, 'benefits'>,
        Omit<BenefitAssessmentDetails, 'decisions'>,
        AssessmentDecision {}

export const defaultAssessmentDetails: PolicyAssessmentDetails[] = [];

type Action = AnyAction;

export const assessmentDetailsReducer: Reducer = (
    state: Nullable<PolicyAssessmentDetails[]> = null,
    action: Action
) => {
    return state;
};

export function getExtendedBenefitAmountPayable(
    policyAssessmentDecisions: PolicyAssessmentDetails[],
    decisionId: string
) {
    let amount = 0;
    policyAssessmentDecisions.forEach(item => {
        const extendedBenefits = item.benefits.filter(benefit => benefit.benefitCode === '104');
        extendedBenefits.forEach(item => {
            const decision = item.decisions.find(d => d.id === decisionId);
            if (decision) {
                amount = decision.amountPayable || 0;
            }
        });
    });

    return amount;
}

export function getAssessmentDetails(state: State): PolicyAssessmentDetails[] {
    return getProperty(state, 'assessmentDetails', []);
}

export function getAssessmentDetailsFromClaim(claim: Claim): PolicyAssessmentDetails[] {
    return getPropertyFromClaim(claim, 'assessmentDetails', []);
}

export function mapBenefits(value: string) {
    let currBenefit;
    switch (value) {
        case 'Living Lifestyle Benefits (incl. Child LL, Female, dependant)':
            currBenefit = 'Dread';
            break;
        case 'Retrenchment Protector':
            currBenefit = 'Retrenchment';
            break;
        case 'Retrenchment Waiver':
            currBenefit = 'Retrenchment Waiver';
            break;
        case 'Terminal Illness benefit':
            currBenefit = 'TerminalIllness';
            break;
        case 'Disability/Impairment/Waiver':
            currBenefit = 'Disability';
            break;
        case 'Maternity Pause':
            currBenefit = 'MaternityPause';
            break;
        case 'All Income Benefits Incl child (to be reviewed with new tax laws)':
            currBenefit = 'IncomeProtector';
            break;
        case 'Early Death Claims':
            currBenefit = 'RADeath';
            break;
    }
    return currBenefit;
}

export const isDisabled = (assessmentDetails: any, currentBenefitLimit: any) => {
    let isDecisionAdded;
    let isOverLimit = false;
    let isNotApplicable = false;

    const notApplicable = (decisions: any) => {
        if (decisions.length > 0) {
            isNotApplicable = decisions[decisions.length - 1].decision !== 'NotApplicable';
            return isNotApplicable;
        }
    };

    if (Array.isArray(assessmentDetails)) {
        assessmentDetails.forEach((ad: any) => {
            if (Array.isArray(ad.benefits && currentBenefitLimit)) {
                // eslint-disable-next-line array-callback-return
                ad.benefits.map((benefit: any) => {
                    if (
                        benefit.sumAssured &&
                        benefit?.sumAssured > parseInt(currentBenefitLimit[0].amount) &&
                        notApplicable(benefit.decisions)
                    ) {
                        isOverLimit = true;
                        return isOverLimit;
                    }
                });

                isDecisionAdded = ad.benefits.every(
                    (benefit: { decisions: string | any[] }) => benefit.decisions.length > 0
                );
                return isDecisionAdded;
            }
        });
    }

    if (!isDecisionAdded) {
        return true; // If All Decisions are not made - Disable is true
    } else if (!isOverLimit && isDecisionAdded) {
        return true; // If All Decisions are made and Non are over the limit - Disable is true
    } else if (isOverLimit && isDecisionAdded) {
        return false; // If All Decisions are made and all or one decision is over the limit - Disable is false
    } else {
        return isOverLimit && isDecisionAdded;
    }
};
