import { DeleteRecordCollectionAction } from '../../../../../redux/collection-reducer';
import { DELETE_DEPENDANT } from './types';

export type DeleteDependantAction = DeleteRecordCollectionAction;

export const deleteDependantActionCreator = (index: number): DeleteDependantAction => {
    return {
        type: DELETE_DEPENDANT,
        index: index,
    };
};
