import axios from 'axios';
import { push as setLocation } from 'connected-react-router';
import { claimUpdatedThunk } from '../';
import { env } from '../../env';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { Claim } from '../reducers';
import { setReferenceAction } from './set-reference';
import { ClaimReference } from '../reducers/claim-reference-reducer';

const claimsUri = `${env().CLAIMS_API}/api/Claim`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LinkReturn = Promise<any>;
export type LinkClaim = (
    claimId?: string,
    referenceSource?: string,
    referenceId?: string,
    redirectUrl?: string,
    creationDate?: string
) => LinkReturn;
type LinkClaimThunk = (
    claimId?: string,
    referenceSource?: string,
    referenceId?: string,
    redirectUrl?: string,
    creationDate?: string
) => ReduxThunkAction;
export const linkClaimAction: LinkClaimThunk = (
    claimId?: string,
    referenceSource?: string,
    referenceId?: string,
    redirectUrl?: string,
    creationDate?: string
) => {
    return async dispatch => {
        const reference = {
            referenceSource: referenceSource || '',
            referenceId: referenceId || '',
            creationDate: creationDate || '',
        };
        const loadClaimUri = getLoadClaimUri(claimId, reference);
        const response = await axios.post<Claim>(loadClaimUri, reference);
        if (response && response.data) {
            const responseClaim: Claim = response.data;
            dispatch(claimUpdatedThunk(responseClaim));
            if (responseClaim.claimType === 'Uninitiated') {
                dispatch(setLocation('/initiate-claim/client-search'));
            }
        } else if (claimId) {
            throw new Error(`Claim with id ${claimId} not found`);
        } else if (referenceSource && referenceId && creationDate) {
            dispatch(setReferenceAction(referenceSource, referenceId, creationDate));
        }
        if (redirectUrl) {
            dispatch(setLocation(redirectUrl));
        }
        return {};
    };
};
function getLoadClaimUri(claimId?: string, reference?: ClaimReference) {
    if (claimId) {
        return `${claimsUri}/${claimId}`;
    }
    throw new Error('Either Claim ID or both Reference Source or Reference ID are required');
}
