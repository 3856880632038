import React from 'react'; // eslint-disable-line
import { withStyles, Grid, Typography, Tooltip, Button } from '@material-ui/core';
import { LabeledValue } from '../../../controls/labeled-value';
import { getDisplayDate } from '../../../shared-functions';
import styles, { StyledComponent } from '../../../styles/styles';
import { connect, MapStateToProps } from 'react-redux';
import { getClaim } from '../../../claim';
import { BeneficiaryDetails, getPaymentBeneficiaries } from '../../reducer';
import { State } from '../../../redux/root-reducer';
import { ButtonWrapper } from '../../../forms';
import {
    ScreenBeneficiary,
    screenBeneficiaryAction,
} from '../../../common/actions/screen-beneficiary';
import { ManualScreeningView } from './manual-screening';
import { FormModalView } from '../../../form-modal/views/form-modal';
import { OpenAddModal, openModalAction } from '../../../form-modal/actions';
import { Warning } from '@material-ui/icons';
import { beneficiaryScreeningNotResolved } from './shared-functions';

interface OwnProps {
    beneficiaryId: string;
}

interface StateProps {
    beneficiaries: BeneficiaryDetails[];
}

type Props = StyledComponent &
    OwnProps &
    StateProps & {
        screenBeneficiary: ScreenBeneficiary;
        openAddModal: OpenAddModal;
        paymentIndex: number;
    };

const beneficiatyRiskRatingDetailsView: React.FunctionComponent<Props> = ({
    beneficiaries,
    beneficiaryId,
    classes,
    screenBeneficiary,
    openAddModal,
    paymentIndex,
}: Props) => {
    const claimantIndex = beneficiaries.findIndex(c => c.beneficiaryId === beneficiaryId);
    if (claimantIndex === -1) {
        return null;
    }

    const beneficiary = beneficiaries[claimantIndex] || {};
    if (!beneficiary) {
        return null;
    }

    const openManualScreeningModal = () => {
        openAddModal({
            modalContent: (
                <FormModalView
                    formStyle={classes.manualScreening}
                    formView={() => (
                        <ManualScreeningView classes={classes} paymentIndex={paymentIndex} />
                    )}
                />
            ),
            modalData: {},
        });
    };

    return (
        <Grid container spacing={0} item xs={12}>
            <Grid item xs={12}>
                <Typography className={classes.marginTop} variant="h6">
                    Beneficiary Risk Rating Details
                    {beneficiaryScreeningNotResolved(beneficiary) && (
                        <Tooltip title="Beneficiary screening failed">
                            <Warning
                                style={{ color: '#FF6700', marginLeft: '8px' }}
                                fontSize="small"
                            />
                        </Tooltip>
                    )}
                </Typography>
            </Grid>

            <React.Fragment>
                {!beneficiary.beneficiaryManualScreening?.isBeneficiaryManualScreening ? (
                    <Grid item xs={12} className={classes.marginTop} container justify="flex-start">
                        {beneficiary.riskStatus && (
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Beneficiary Risk Rating"
                                    value={'Successful'}
                                />
                            </Grid>
                        )}
                        {beneficiary.riskStatus && (
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Screening Date"
                                    value={getDisplayDate(beneficiary.screeningDate)}
                                />
                            </Grid>
                        )}
                        {beneficiary.screeningError && !beneficiary.riskStatus && (
                            <React.Fragment>
                                <Grid item xs={6} lg={6}>
                                    <Typography style={{ marginTop: 10, color: 'red' }}>
                                        Screening unsuccessful, please try again or manually screen
                                        the beneficiary. Reason below:
                                    </Typography>
                                    {beneficiary.screeningError}
                                </Grid>
                                <Grid item xs={6} container direction="row" justify="flex-end">
                                    <ButtonWrapper>
                                        <Tooltip title="Beneficiary manual screening">
                                            <Button
                                                id="BeneficiaryManualScreening"
                                                color="secondary"
                                                variant="contained"
                                                className={classes.button}
                                                style={{ padding: 10, width: 300 }}
                                                onClick={() => openManualScreeningModal()}
                                            >
                                                Beneficiary manual screening
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Retry Screening">
                                            <Button
                                                id="RetryScreening"
                                                color="secondary"
                                                variant="contained"
                                                className={classes.button}
                                                style={{ padding: 10, width: 300 }}
                                                onClick={() => screenBeneficiary()}
                                            >
                                                Retry
                                            </Button>
                                        </Tooltip>
                                    </ButtonWrapper>
                                </Grid>
                            </React.Fragment>
                        )}
                    </Grid>
                ) : (
                    <Grid item xs={12} className={classes.marginTop} container justify="flex-start">
                        {beneficiary.beneficiaryManualScreening && (
                            <Grid item xs={12}>
                                <LabeledValue
                                    label="Beneficiary Manual Screening"
                                    value={'Successful'}
                                />
                            </Grid>
                        )}
                        {beneficiary.beneficiaryManualScreening && (
                            <Grid item xs={12}>
                                <LabeledValue
                                    label="Comments"
                                    value={
                                        beneficiary.beneficiaryManualScreening
                                            ?.beneficiaryManualScreeningComment
                                    }
                                />
                            </Grid>
                        )}
                        {beneficiary.beneficiaryManualScreening && (
                            <Grid item xs={12}>
                                <LabeledValue
                                    label="Assessor Name"
                                    value={beneficiary.beneficiaryManualScreening?.assessor}
                                />
                            </Grid>
                        )}
                        {beneficiary.beneficiaryManualScreening && (
                            <Grid item xs={12}>
                                <LabeledValue
                                    label="Manual Screening Date"
                                    value={getDisplayDate(
                                        beneficiary.beneficiaryManualScreening
                                            ?.beneficiaryManualScreeningDate
                                    )}
                                />
                            </Grid>
                        )}
                    </Grid>
                )}
            </React.Fragment>
        </Grid>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => {
    const claim = getClaim(state);
    return {
        beneficiaries: getPaymentBeneficiaries(claim),
    };
};

const mapDispatchToProps = {
    screenBeneficiary: screenBeneficiaryAction,
    openAddModal: openModalAction,
};

export const BeneficiatyRiskRatingDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(beneficiatyRiskRatingDetailsView));
