import React from 'react'; // eslint-disable-line

import { Grid, Paper, withStyles, Typography } from '@material-ui/core';
import { MaritalStatus } from '../../../../../codes/marital-statuses';
import {
    Condition,
    FormHeading,
    FullWidthSearchSelectField,
    FullWidthTextField,
    FullWidthRadioGroup,
    RadioOption,
    True,
    WhenFieldChanges,
} from '../../../../../forms';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { AddressDetailsEditView } from './edit-address-details';
import { ContactDetailsEditView } from './edit-contact-details';
import { PersonalDetailsView } from './edit-personal-details';
import { CountriesOfIssue } from '../../../../../codes/countries-of-issue';
import { requiredValidator } from '../../../../../forms/validations';

const curatorEditView: React.FunctionComponent<StyledComponent> = ({
    classes,
}: StyledComponent) => {
    return (
        <Paper className={classes.paper}>
            <FormHeading text="Curator's personal details" />
            <Grid container spacing={5} justify="flex-start">
                <PersonalDetailsView propertyPrefix="curator" />
                <Grid item xs={6}>
                    <FullWidthSearchSelectField
                        name={`curator.maritalStatus`}
                        options={MaritalStatus}
                        validate={requiredValidator}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthRadioGroup name="curator.taxStatus">
                        <RadioOption value="Tax Paying" />
                        <RadioOption value="Non Tax Paying" />
                    </FullWidthRadioGroup>
                    <WhenFieldChanges
                        field="curator.taxStatus"
                        becomes="No"
                        set="curator.countryOfResidence"
                        to={null}
                    />
                    <WhenFieldChanges
                        field="curator.taxStatus"
                        becomes="No"
                        set="curator.taxReferenceNumber"
                        to={null}
                    />
                </Grid>
                <Condition when="curator.taxStatus" is="Tax Paying">
                    <True>
                        <Grid item xs={6}>
                            <FullWidthSearchSelectField
                                name="curator.countryOfResidence"
                                options={CountriesOfIssue}
                                label="Country of tax residence"
                                validate={requiredValidator}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthTextField name="curator.taxReferenceNumber" />
                        </Grid>
                    </True>
                </Condition>
            </Grid>
            <ContactDetailsEditView
                propertyPrefix="curator.contactDetails"
                heading="Curator's contact details"
            />
            <Typography variant="subtitle2" className={classes.marginTop}>
                {`Curator's address details`}
            </Typography>
            <AddressDetailsEditView propertyPrefix="curator" />
        </Paper>
    );
};

export const CuratorEditView = withStyles(styles)(curatorEditView);
