import { InformationRecord } from '../reducer';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { addInformationRecordActionCreator } from './add-information-record';
import { reduceAndSubmitClaim } from '../../../../../claim/actions/reduce-and-submit-claim';
import { updateInformationRecordActionCreator } from './update-information-record';
import { deleteInformationRecordActionCreator } from './delete-information-records';

type SubmitReturn = Promise<any>;

export type AddAndSubmitInformationRecord = (informationRecord: InformationRecord) => SubmitReturn;

type AddAndSubmitInformationRecordThunk = (
    informationRecord: InformationRecord
) => ReduxThunkAction<SubmitReturn>;

export const addAndSubmitInformationRecordThunk: AddAndSubmitInformationRecordThunk = (
    addInformationRecord: InformationRecord
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        addInformationRecordActionCreator(addInformationRecord)
    );

export type UpdateAndSubmitInformationRecord = (
    informationRecordIndex: number,
    informationRecord: InformationRecord
) => SubmitReturn;
type UpdateAndSubmitInformationRecordThunk = (
    informationRecordIndex: number,
    informationRecord: InformationRecord
) => ReduxThunkAction<SubmitReturn>;

export const updateAndSubmitInformationRecordThunk: UpdateAndSubmitInformationRecordThunk = (
    informationRecordIndex: number,
    informationRecord: InformationRecord
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        updateInformationRecordActionCreator(informationRecordIndex, informationRecord)
    );

export type DeleteAndSubmitInformationRecord = (informationRecordIndex: number) => SubmitReturn;

type DeleteAndSubmitInformationRecordThunk = (
    informationRecordIndex: number
) => ReduxThunkAction<SubmitReturn>;
export const deleteAndSubmitInformationRecordThunk: DeleteAndSubmitInformationRecordThunk = (
    informationRecordIndex: number
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        deleteInformationRecordActionCreator(informationRecordIndex)
    );
