import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { BankingDetails } from '../../../assessment/synopsis/common/banking-details/banking-details';
import { BankingDetailsView } from '../../../assessment/synopsis/common/banking-details/views/edit-banking-details';
import { GenericForm, FormErrors } from '../../../forms';
import styles, { StyledComponent } from '../../../styles/styles';
import {
    UpdateBankingDetails,
    updateBankingDetailsAction,
} from '../../actions/update-banking-details';

interface Props extends StyledComponent {
    bankingDetails: BankingDetails;
    beneficiaryId: string;
    updateBankingDetails: UpdateBankingDetails;
}

const EditBankingDetailsForm = GenericForm<BankingDetails>();
const bankingDetailsEdit: React.FunctionComponent<Props> = ({
    bankingDetails,
    beneficiaryId,
    updateBankingDetails,
    ...rest
}: Props) => {
    return (
        <EditBankingDetailsForm
            onSubmit={values => updateBankingDetails(values, beneficiaryId)}
            initialValues={bankingDetails}
            {...rest}
        >
            <BankingDetailsView />
            <FormErrors />
        </EditBankingDetailsForm>
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    updateBankingDetails: updateBankingDetailsAction,
};

export const BankingDetailsEditView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(bankingDetailsEdit));
