import React from 'react'; // eslint-disable-line

import { Grid, withStyles, Typography, Tooltip, Button, Paper } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { connect, MapDispatchToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { v4 as uuid } from 'uuid';
import { LabeledValue } from '../../../../controls/labeled-value';
import { AddFormModal } from '../../../../form-modal';
import {
    OpenModal,
    openModalAction,
    OpenAddModal,
    openAddModalAction,
} from '../../../../form-modal/actions';
import { State } from '../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import {
    AddAndSubmitClaimNotesRecord,
    addAndSubmitClaimDetailsRecordThunk,
} from '../../death/claim-event-details/actions/add-new-notes';
import { ClaimNotesViewEdit } from '../../death/claim-event-details/views/claim-notes-view-edit';
import { DeathClaimNotes } from '../../death/claim-event-details/reducer';
import { getRADeathClaim } from '../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { AuthState } from '../../../../auth/auth-reducer';
import { ButtonWrapper } from '../../../../forms';

interface DispatchProps {
    openAddModal: OpenAddModal;
    openModal: OpenModal;
    addClaimNotes: AddAndSubmitClaimNotesRecord;
}
interface OwnProps {}
type Props = StyledComponent &
    DispatchProps &
    OwnProps & {
        claimNotes: DeathClaimNotes[];
        currentUser: AuthState;
        isReadOnly: boolean;
    };
const RANotesAddFormModal = AddFormModal<DeathClaimNotes>();
const claimDetailsNotes: React.FunctionComponent<Props> = ({
    classes,
    claimNotes,
    currentUser,
    addClaimNotes,
    openAddModal,
    isReadOnly,
}: Props) => {
    return (
        <React.Fragment>
            <Grid container justify="flex-start">
                <Grid item xs={11}>
                    <Typography style={{ marginTop: '8px' }} variant="subtitle2">
                        Assessor&apos;s Notes
                    </Typography>
                </Grid>
                <ButtonWrapper>
                    <Grid item xs={1} container justify="flex-end">
                        <Tooltip title="Add Notes">
                            <Button
                                id="AddMedicalHistory"
                                color="secondary"
                                onClick={() =>
                                    openAddModal({
                                        modalContent: (
                                            <RANotesAddFormModal
                                                blankRecord={{
                                                    id: uuid(),
                                                    dateCaptured: new Date(),
                                                }}
                                                recordDetailView={<ClaimNotesViewEdit />}
                                                addRecord={record =>
                                                    addClaimNotes({
                                                        ...record,
                                                        author: currentUser.name,
                                                    })
                                                }
                                            />
                                        ),
                                        modalData: {},
                                    })
                                }
                            >
                                <AddCircle />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            </Grid>
            {claimNotes && claimNotes.length > 0 && (
                <Paper className={classes.paper}>
                    <Grid container justify="flex-start">
                        {claimNotes.map((note, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <Grid item xs={12} lg={12}>
                                        <LabeledValue
                                            value={`${getDisplayDate(
                                                note.dateCaptured,
                                                'dd/MM/yyyy HH:mm'
                                            )} | ${note.author}`}
                                            label={false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={12} style={{ marginTop: '-24px' }}>
                                        <LabeledValue
                                            value={note.notesSummary}
                                            label={false}
                                            isUnsafe={true}
                                        />
                                    </Grid>
                                </React.Fragment>
                            );
                        })}
                    </Grid>
                </Paper>
            )}
        </React.Fragment>
    );
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    addClaimNotes: record => dispatch(addAndSubmitClaimDetailsRecordThunk(record)),
    openModal: options => dispatch(openModalAction(options)),
    openAddModal: options => dispatch(openAddModalAction(options)),
});
const mapStateToProps = (state: State) => ({
    claimNotes:
        getRADeathClaim(state).claimDetails !== null
            ? getRADeathClaim(state).claimDetails.claimNotes
            : [],
    currentUser: state.auth as AuthState,
    isReadOnly: state.claimSession.readOnly,
});
export const RAClaimDetailsNotes = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(claimDetailsNotes));
