import React from 'react'; // eslint-disable-line

import { Divider, Grid, Typography, withStyles } from '@material-ui/core';
import { FullWidthDatePicker, FullWidthTextField } from '../../forms';
import { requiredValidator } from '../../forms/validations';
import styles, { StyledComponent } from '../../styles/styles';
import { FinalDecisionDetails } from '../reducer';

type Props = StyledComponent & {
    initialValues?: FinalDecisionDetails;
};

const finalDecisionDetailsView: React.FunctionComponent<Props> = ({
    classes,
    initialValues = {},
}: Props) => {
    return (
        <React.Fragment>
            <Typography variant="h5" className={classes.spacedTypography}>
                Final Decision
            </Typography>
            <Divider />
            <Grid container spacing={5}>
                <Grid item xs={6} lg={6}>
                    <FullWidthTextField id="policyNumber" name="policyNumber" disabled />
                </Grid>
                <Grid item xs={6} lg={6}>
                    <FullWidthTextField id="benefitName" name="benefitName" disabled />
                </Grid>
                <Grid item xs={6} lg={6}>
                    <FullWidthDatePicker
                        id="decisionDate"
                        name="decisionDate"
                        validate={requiredValidator}
                        disabled={
                            !!initialValues.decisionDate ||
                            !!initialValues.retrospectiveUnderwritingDecision
                        }
                    />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <FullWidthTextField
                        id="retrospectiveUnderwritingDecision"
                        name="retrospectiveUnderwritingDecision"
                        validate={requiredValidator}
                        disabled={
                            !!initialValues.decisionDate ||
                            !!initialValues.retrospectiveUnderwritingDecision
                        }
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export const FinalDecisionDetailsView = withStyles(styles)(finalDecisionDetailsView);
