import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { reduceAndSubmitClaim } from '../../../../../claim/actions/reduce-and-submit-claim';
import { AddRecordCollectionAction } from '../../../../../redux/collection-reducer';
import { ADD_CLAIM_DETAILS_RECORD } from '../types';
import { DeathClaimNotes } from '../reducer';

type SubmitReturn = Promise<any>;
export type AddAndSubmitClaimNotesRecord = (executor: DeathClaimNotes) => SubmitReturn;
type AddAndSubmitClaimDetailsRecordThunk = (
    notes: DeathClaimNotes
) => ReduxThunkAction<SubmitReturn>;

export const addAndSubmitClaimDetailsRecordThunk: AddAndSubmitClaimDetailsRecordThunk = (
    notes: DeathClaimNotes
) => (dispatch, getState) =>
    reduceAndSubmitClaim(dispatch, getState, addClaimDetailsActionCreator(notes));

export type AddClaimDetailsRecordAction = AddRecordCollectionAction<DeathClaimNotes>;

export const addClaimDetailsActionCreator = (
    claimNotes: DeathClaimNotes
): AddClaimDetailsRecordAction => {
    return {
        type: ADD_CLAIM_DETAILS_RECORD,
        record: claimNotes,
    };
};
