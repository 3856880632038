import { reduceAndSubmitClaim } from '../../../../../claim/actions/reduce-and-submit-claim';
import { UpdateRecordCollectionAction } from '../../../../../redux/collection-reducer';
import { addRecordReduction, updateRecordReduction } from '../../../../../redux/reductions';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { Consultation } from '../../../death/life-assured-details/reducer';
import { Doctors } from '../reducer';
import { UPDATE_PREVIOUS_DOCTOR_RECORD } from '../types';

type SubmitReturn = Promise<any>;
export type AddDoctorsConsultation = (
    consultation: Consultation,
    doctors: Doctors,
    doctorsIndex: number
) => SubmitReturn;
type AddPreviousDoctorsConsultationThunk = (
    consultation: Consultation,
    doctors: Doctors,
    doctorsIndex: number
) => ReduxThunkAction<SubmitReturn>;

export const addPreviousDoctorsConsultationThunk: AddPreviousDoctorsConsultationThunk = (
    consultation: Consultation,
    doctors: Doctors,
    doctorsIndex: number
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        addPreviousDoctorsConsultationActionCreator(consultation, doctors, doctorsIndex)
    );
export type AddPreviousDoctorsConsultationAction = UpdateRecordCollectionAction<Doctors>;
export const addPreviousDoctorsConsultationActionCreator = (
    consultation: Consultation,
    doctors: Doctors,
    doctorsIndex: number
): AddPreviousDoctorsConsultationAction => {
    doctors.doctorsVisits = addRecordReduction(doctors.doctorsVisits || [], consultation);
    return {
        type: UPDATE_PREVIOUS_DOCTOR_RECORD,
        record: doctors,
        index: doctorsIndex,
    };
};

export type UpdateDoctorsConsultation = (
    consultation: Consultation,
    doctors: Doctors,
    visitIndex: number,
    doctorsIndex: number
) => SubmitReturn;
type UpdatePreviousDoctorsConsultationThunk = (
    consultation: Consultation,
    doctors: Doctors,
    visitIndex: number,
    doctorsIndex: number
) => ReduxThunkAction<SubmitReturn>;

export const updatePreviousDoctorsConsultationThunk: UpdatePreviousDoctorsConsultationThunk = (
    consultation: Consultation,
    doctors: Doctors,
    consultIndex: number,
    doctorsIndex: number
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        updatePreviousDoctorsConsultationActionCreator(
            consultation,
            doctors,
            consultIndex,
            doctorsIndex
        )
    );
export type UpdatePreviousDoctorsConsultationAction = UpdateRecordCollectionAction<Doctors>;
export const updatePreviousDoctorsConsultationActionCreator = (
    consultation: Consultation,
    doctor: Doctors,
    consultIndex: number,
    doctorsIndex: number
): UpdatePreviousDoctorsConsultationAction => {
    const updatedConsultations = updateRecordReduction(
        doctor.doctorsVisits || [],
        consultation,
        consultIndex
    );
    const updatedDoctor = {
        ...doctor,
        doctorsVisits: updatedConsultations,
    };
    return {
        type: UPDATE_PREVIOUS_DOCTOR_RECORD,
        record: updatedDoctor,
        index: doctorsIndex,
    };
};

export type DeleteDoctorsConsultation = (
    doctors: Doctors,
    consultIndex: number,
    doctorsIndex: number
) => SubmitReturn;
type DeletePreviousDoctorsConsultationThunk = (
    doctor: Doctors,
    consultIndex: number,
    doctorsIndex: number
) => ReduxThunkAction<SubmitReturn>;

export const deletePreviousDoctorsConsultationThunk: DeletePreviousDoctorsConsultationThunk = (
    doctor: Doctors,
    consultIndex: number,
    doctorsIndex
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        deletePreviousDoctorsConsultationActionCreator(doctor, consultIndex, doctorsIndex)
    );
export type DeletePreviousDoctorsConsultationAction = UpdateRecordCollectionAction<Doctors>;
export const deletePreviousDoctorsConsultationActionCreator = (
    doctor: Doctors,
    consultIndex: number,
    doctorsIndex: number
): DeletePreviousDoctorsConsultationAction => {
    if (doctor && doctor.doctorsVisits) {
        doctor.doctorsVisits.splice(consultIndex, 1);
    }
    return {
        type: UPDATE_PREVIOUS_DOCTOR_RECORD,
        record: doctor,
        index: doctorsIndex,
    };
};
