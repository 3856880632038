import { Reducer } from 'redux';
import { State } from '../../redux/root-reducer';
import { ClaimUpdatedAction } from '../actions';
import { CLAIM_UPDATED } from '../types';
import { BaseClaim, baseClaimReducer, initialBaseClaim } from './claim-reducers/base-claim-reducer';
import { DisabilityClaim, disabilityClaimReducer } from './claim-reducers/disability-claim-reducer';
import { IncomeClaim, incomeClaimReducer } from './claim-reducers/income-claim-reducer';
import {
    RetrenchmentClaim,
    retrenchmentClaimReducer,
} from './claim-reducers/retrenchment-claim-reducer';
import { deathClaimReducer, DeathClaim } from './claim-reducers/death-claim-reducer';
import { ClaimType } from '../claim-types';
import { DreadClaim, dreadClaimReducer } from './claim-reducers/dread-claim-reducer';
import { RADeathClaim, raDeathClaimReducer } from './claim-reducers/ra-death-claim-reducer';
import { FuneralClaim, funeralClaimReducer } from './claim-reducers/funeral-claim-reducer';
import {
    TerminalIllnessClaim,
    terminalIllnessClaimReducer,
} from './claim-reducers/terminal-illness-claim-reducer';
import {
    EarlyRetirementClaim,
    earlyRetirementClaimReducer,
} from './claim-reducers/early-retirement-claim-reducer';

export type Claim =
    | BaseClaim
    | DisabilityClaim
    | IncomeClaim
    | RetrenchmentClaim
    | DeathClaim
    | DreadClaim
    | RADeathClaim
    | FuneralClaim
    | TerminalIllnessClaim
    | EarlyRetirementClaim;

export const initialClaim = initialBaseClaim;

export const claimReducer: Reducer<Claim> = (state: Claim = initialClaim, action) => {
    if (action.type === CLAIM_UPDATED) {
        return (action as ClaimUpdatedAction).payload;
    }
    if (!state) {
        return state;
    }

    switch (state.claimType) {
        case ClaimType.Disability:
            return disabilityClaimReducer(state as DisabilityClaim, action);
        case ClaimType.IncomeProtector:
            return incomeClaimReducer(state as IncomeClaim, action);
        case ClaimType.Retrenchment:
            return retrenchmentClaimReducer(state as RetrenchmentClaim, action);
        case ClaimType.Death:
            return deathClaimReducer(state as DeathClaim, action);
        case ClaimType.Dread:
            return dreadClaimReducer(state as DreadClaim, action);
        case ClaimType.RADeath:
            return raDeathClaimReducer(state as RADeathClaim, action);
        case ClaimType.Funeral:
            return funeralClaimReducer(state as FuneralClaim, action);
        case ClaimType.TerminalIllness:
            return terminalIllnessClaimReducer(state as TerminalIllnessClaim, action);
        case ClaimType.EarlyRetirement:
            return earlyRetirementClaimReducer(state as EarlyRetirementClaim, action);
    }

    return baseClaimReducer(state, action);
};

export function getClaim(state: State): Claim {
    return state.claimSession.claim || initialBaseClaim;
}

export function getProperty<T>(state: State, propertyName: string, defaultValue: T): T {
    return getPropertyFromClaim(getClaim(state), propertyName, defaultValue);
}

export function getPropertyFromClaim<T>(claim: Claim, propertyName: string, defaultValue: T): T {
    if (propertyName in claim) {
        return claim[propertyName] || defaultValue;
    }
    return defaultValue;
}
