import React from 'react'; // eslint-disable-line
import { Grid, Paper, withStyles, Button, Tooltip, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { connect } from 'react-redux';
import { LabeledValue } from '../../../../controls/labeled-value';
import { OpenModal, openModalAction } from '../../../../form-modal/actions';
import { FormModalView } from '../../../../form-modal/views/form-modal';
import styles, { StyledComponent } from '../../../../styles/styles';
import { State } from '../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../shared-functions';
import {
    getNoticeOfDeath,
    NoticeOfDeath,
} from '../../common/notice-of-death/notice-of-death-reducer';
import { NoticeOfDeathForm } from './notice-of-death-edit-form';
import { EditFormInfoDetails } from '../../common/notice-of-death/views';
import { ButtonWrapper } from '../../../../forms';

type Props = StyledComponent & {
    openModal: OpenModal;
    noticeOfDeath: NoticeOfDeath;
};
const formInfoView: React.FunctionComponent<Props> = ({
    classes,
    noticeOfDeath,
    openModal,
}: Props) => {
    const edit = () => {
        openModal({
            modalContent: (
                <FormModalView
                    formView={() => (
                        <NoticeOfDeathForm
                            title="Document Details"
                            view={<EditFormInfoDetails />}
                        />
                    )}
                />
            ),
        });
    };
    return (
        <React.Fragment>
            <Grid container justify="flex-start" style={{ marginTop: '4px' }}>
                <Grid item xs={11}>
                    <Typography style={{ marginTop: '8px' }} variant="subtitle2">
                        Document Details
                    </Typography>
                </Grid>
                <ButtonWrapper>
                    <Grid item xs={1} container justify="flex-end">
                        <Tooltip title="Edit Document Details">
                            <Button id="EditDocumentDetails" color="secondary" onClick={edit}>
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            </Grid>
            <Paper className={classes.paper}>
                <Grid container justify="flex-start">
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Serial Number" value={noticeOfDeath.serialNumber} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Serial Number is Consistent"
                            value={noticeOfDeath.serialNumberConsistent}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Date Signed"
                            value={getDisplayDate(noticeOfDeath.dateSigned)}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

const mapStateToProps = (state: State) => ({
    noticeOfDeath: getNoticeOfDeath(state) || {},
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = { openModal: openModalAction };

export const FormInfoDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(formInfoView));
