/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'; // eslint-disable-line
import { Button, Grid, Paper, withStyles } from '@material-ui/core';
import { connect, MapDispatchToProps } from 'react-redux';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { CloseModal, closeModalAction } from '../../../../../../form-modal/actions';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { HandleSubmit } from '../../../../../../workflow-navigation';
import { State } from '../../../../../../redux/root-reducer';
import { FormHeading } from '../../../../../../forms';
import { CommunicationsPreviewEmail } from './communications-preview-modal';
import { viewModalAction } from '../../../../../../confirmation-dialog';
import {
    ClaimantRequirements,
    getRequirementsFromClaim,
} from '../../../../common/requirements-management/reducer';
import { Claim, getClaim } from '../../../../../../claim';
import { FuneralRequirements } from '../../reducer';
import { getClaimantOptions } from './communications-edit-modal';

interface DispatchProps {
    readonly closeModal: CloseModal;
    readonly openViewModal: any;
}

type Props = StyledComponent &
    DispatchProps & {
        readonly formView: React.ComponentType<any>;
        readonly onSubmit?: HandleSubmit;
        readonly closeButton?: boolean;
        readonly formStyle?: any;
        readonly modal?: any;
        readonly isButtonsHidden?: boolean;
        claimantRequirements: ClaimantRequirements[];
        lifeAssuredRequirements?: FuneralRequirements;
        title: string;
        claim: Claim;
        previewScreen: any;
    };

interface OwnProps {}

const communicationsFormModalView: React.FunctionComponent<Props> = ({
    classes,
    formView,
    onSubmit,
    closeModal,
    openViewModal,
    formStyle,
    modal,
    isButtonsHidden,
    claim,
    claimantRequirements,
    lifeAssuredRequirements,
    previewScreen,
    title,
}: Props) => {
    const FormView = formView;

    // eslint-disable-next-line react-hooks/rules-of-hooks

    return (
        <Grid container direction="row" alignContent="center" justify="center">
            <Paper
                style={{
                    height: '95vh',
                    minHeight: 400,
                    maxHeight: 650,
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    width: '90%',
                }}
                className={`${formStyle ? formStyle : null} ${classes.paper}`}
            >
                <FormHeading text={title} />
                <FormView onSubmitSuccess={() => closeModal()} />
                {isButtonsHidden ? null : (
                    <Grid id="button-group" container spacing={0} justify="flex-end">
                        <Button
                            id="Cancel"
                            variant="contained"
                            className={classes.button}
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            id="Preview"
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={preview}
                        >
                            Preview E-mail
                        </Button>
                        <Button
                            id="Save"
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={() => submit()}
                        >
                            Send E-mail
                        </Button>
                    </Grid>
                )}
            </Paper>
            <CommunicationsPreviewEmail modalBody={previewScreen} />
        </Grid>
    );

    async function submit() {
        if (onSubmit) {
            const result = await onSubmit();
            if (!result) {
                return;
            }
        }
        closeModal();
    }

    function preview() {
        let requirements: any = [];
        let documents: any = [];
        requirements = claimantRequirements.find(
            (item: any) => item.id === modal.form?.communicationsClaimant
        );

        documents =
            requirements && requirements.documents
                ? requirements.documents.filter((document: any) => document.status === 'Requested')
                : [];

        const deseasedDocs = lifeAssuredRequirements?.lifeAssured.documents.filter(
            (document: any) => document.status === 'Requested'
        );
        let isLifeAssuredDocRequested = false;
        if (deseasedDocs && deseasedDocs.length > 0) {
            isLifeAssuredDocRequested = true;
        }
        const claimants = getClaimantOptions(claim, false, isLifeAssuredDocRequested, documents);

        const claimant = claimants.find(
            (item: any) => item.value === modal.form?.communicationsClaimant
        );

        const item = lifeAssuredRequirements?.lifeAssured.documents.forEach(document => {
            if (document.status === 'Requested') {
                documents.push({
                    id: document.id,
                    status: document.status,
                    reason: document.reason,
                    dateRequested: document.dateRequested,
                    name: document.name,
                });
            }
        });

        openViewModal({
            isOpen: true,
            form: {
                ...modal.form,
                claimantSurname: claimant?.surname,
                communicationsTitle: claimant?.title,
                documents,
            },
        });
    }

    function onCancel() {
        closeModal();
        openViewModal({
            isOpen: false,
            form: {},
        });
    }
};

const mapStateToProps = (state: State) => ({
    modal: state.confirmationDialog,
    claim: getClaim(state),
    claimantRequirements:
        (getRequirementsFromClaim(getClaim(state)) as FuneralRequirements).claimants || [],
    onSubmit: state.claimSession.formSubmission.onSubmit,
    lifeAssuredRequirements:
        (getRequirementsFromClaim(getClaim(state)) as FuneralRequirements) || [],
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    closeModal: () => dispatch(closeModalAction()),
    openViewModal: (options: any) => dispatch(viewModalAction(options)),
});

export const CommunicationsFormModalView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(communicationsFormModalView));
