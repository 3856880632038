import React from 'react'; // eslint-disable-line
import { Grid, Button, Typography } from '@material-ui/core';
import People from '../../../resources/claims_live_people.svg';
import { StyledComponent } from '../../../styles/styles';
interface OwnProps {
    classes: any;
}

type Props = OwnProps & StyledComponent;
const AssessorDashboardWithoutDetail: React.FunctionComponent<Props> = ({ classes }: any) => {
    return (
        <Grid container direction="row" alignItems="center" justify="center" spacing={10}>
            <Grid container direction="column" alignItems="center" justify="center">
                <img width={120} alt="add-assessor" src={People} />
                <Typography className={classes.assessorText}> No assessors to show </Typography>
            </Grid>
            <Grid item className={classes.assessorButtonMargin}>
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.assessorButton}
                    onClick={() => {}}
                >
                    Add Assessor
                </Button>
            </Grid>
        </Grid>
    );
};

export default AssessorDashboardWithoutDetail;
