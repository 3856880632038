import { Claim, claimSubmit } from '../../../../../claim';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { getDeathClaimLifeAssuredDetailsFromClaim } from '../reducer';
import { OccupationDetails } from '../reducer';
// eslint-disable-next-line
type UpdateOccupationDetailsReturn = Promise<any>;
export type UpdateOccupationDetails = (
    occupationDetails: OccupationDetails
) => UpdateOccupationDetailsReturn;
type UpdateOccupationDetailsAction = (
    occupationDetails: OccupationDetails
) => ReduxThunkAction<UpdateOccupationDetailsReturn>;

export const updateOccupationDetailsAction: UpdateOccupationDetailsAction = (
    occupationDetails: OccupationDetails
) => {
    return async (dispatch, getState) => {
        return claimSubmit(
            dispatch,
            getState,
            getUpdatedDeathClaimOccupationDetails(occupationDetails)
        );
    };
};

function getUpdatedDeathClaimOccupationDetails(
    occupationDetails: OccupationDetails
): (claim: Claim) => Claim {
    return (claim: Claim) => {
        const currentLifeAssuredDetails = getDeathClaimLifeAssuredDetailsFromClaim(claim);

        return {
            ...claim,
            lifeAssuredDetails: {
                ...currentLifeAssuredDetails,
                occupationDetails,
            },
        };
    };
}
