import React from 'react'; // eslint-disable-line

import { Grid, Paper, withStyles } from '@material-ui/core';
import { CountriesOfIssue } from '../../../../../../codes/countries-of-issue';
import { Gender } from '../../../../../../codes/gender';
import { MaritalStatus } from '../../../../../../codes/marital-statuses';
import { Titles } from '../../../../../../codes/titles';
import {
    BindFieldToField,
    Condition,
    False,
    FormHeading,
    FullWidthDatePicker,
    FullWidthRadioGroup,
    FullWidthSearchSelectField,
    FullWidthTextField,
    RadioOption,
    True,
    WhenFieldChanges,
} from '../../../../../../forms';
import {
    FirstNameMask,
    InitialsMask,
    LastNameMask,
    NumberMask,
} from '../../../../../../forms/input-masks';
import {
    conditionalRequired,
    requiredValidator,
    rsaIdNumberValidator,
    composeValidators,
    UserInput,
    ValidationResult,
} from '../../../../../../forms/validations';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { getDifferenceBetweenDates, defaultDateOfBirth } from '../../../../../../shared-functions';
import { PreviousMarriagesView } from './previous-marriages';

const personalDetailsEditView: React.FunctionComponent<StyledComponent> = ({
    classes,
}: StyledComponent) => {
    return (
        <Paper className={classes.paper}>
            <FormHeading text="Life Assured Details" />
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={6}>
                    <FullWidthSearchSelectField
                        name="personalDetails.title"
                        validate={requiredValidator}
                        options={Titles}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        name="personalDetails.initials"
                        inputProps={{
                            style: { textTransform: 'uppercase' },
                        }}
                        mask={InitialsMask}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        name="personalDetails.firstName"
                        label="Name"
                        validate={requiredValidator}
                        mask={FirstNameMask}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        name="personalDetails.lastName"
                        label="Surname"
                        validate={requiredValidator}
                        mask={LastNameMask}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthRadioGroup name="personalDetails.idType" label="ID Type">
                        <RadioOption value="ID Number" />
                        <RadioOption value="Passport Number" />
                    </FullWidthRadioGroup>
                </Grid>
                <Condition when="personalDetails.idType" is="Passport Number">
                    <True>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name="personalDetails.passportNumber"
                                label="Passport Number"
                                validate={conditionalRequired(
                                    values => values.personalDetails.idType === 'Passport Number'
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthDatePicker
                                name="personalDetails.passportIssueDate"
                                validate={conditionalRequired(
                                    values => values.personalDetails.idType === 'Passport Number'
                                )}
                                maxDateField="personalDetails.passportExpiryDate"
                                disableFuture
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthDatePicker
                                name="personalDetails.passportExpiryDate"
                                validate={conditionalRequired(
                                    values => values.personalDetails.idType === 'Passport Number'
                                )}
                                minDateField="personalDetails.passportIssueDate"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FullWidthSearchSelectField
                                name="personalDetails.passportCountryOfIssue"
                                validate={conditionalRequired(
                                    values => values.personalDetails.idType === 'Passport Number'
                                )}
                                options={CountriesOfIssue}
                            />
                        </Grid>
                    </True>
                    <False>
                        <Grid item xs={6}>
                            <FullWidthTextField
                                name="personalDetails.idNumber"
                                label="ID Number"
                                validate={composeValidators(
                                    conditionalRequired(
                                        values => values.personalDetails.idType === 'ID Number'
                                    ),
                                    rsaIdNumberValidator
                                )}
                                mask={NumberMask}
                            />
                        </Grid>
                    </False>
                </Condition>
                <Grid item xs={4}>
                    <FullWidthDatePicker
                        name="personalDetails.dateOfBirth"
                        validate={requiredValidator}
                        disableFuture
                    />
                    <Condition when="personalDetails.dateOfBirth" hasValue>
                        <False>
                            <BindFieldToField
                                field="personalDetails.dateOfBirth"
                                toField="personalDetails.idNumber"
                                transform={defaultDateOfBirth}
                            />
                            <BindFieldToField
                                field="personalDetails.age"
                                toField="personalDetails.dateOfBirth"
                                transform={() => {
                                    return undefined;
                                }}
                            />
                        </False>
                        <True>
                            <Condition when="personalDetails.dateOfDeath" hasValue>
                                <True>
                                    <BindFieldToField
                                        field="personalDetails.age"
                                        toField="personalDetails"
                                        transform={values => {
                                            if (values) {
                                                return getDifferenceBetweenDates(
                                                    values.dateOfBirth,
                                                    values.dateOfDeath
                                                );
                                            }
                                        }}
                                    />
                                </True>
                                <False>
                                    <BindFieldToField
                                        field="personalDetails.age"
                                        toField="personalDetails.dateOfBirth"
                                        transform={defaultAgeFromDateOfBirth}
                                    />
                                </False>
                            </Condition>
                        </True>
                    </Condition>
                </Grid>
                <Grid item xs={2}>
                    <FullWidthTextField label="Age at death" name="personalDetails.age" disabled />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthDatePicker
                        name="personalDetails.dateOfDeath"
                        validate={composeValidators(
                            requiredValidator,
                            afterDateOfBirthValidator(
                                values =>
                                    values.personalDetails.dateOfDeath &&
                                    values.personalDetails.dateOfDeath <
                                        values.personalDetails.dateOfBirth
                            )
                        )}
                        label="Date of Death"
                        disableFuture
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthSearchSelectField
                        name="personalDetails.gender"
                        validate={requiredValidator}
                        options={Gender}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthSearchSelectField
                        name="personalDetails.maritalStatus"
                        options={MaritalStatus}
                        label="Marital Status"
                    />
                    <WhenFieldChanges
                        field="personalDetails.maritalStatus"
                        set="personalDetails.dateOfMarriage"
                        to={null}
                    />
                    <WhenFieldChanges
                        field="personalDetails.maritalStatus"
                        set="personalDetails.dateOfDivorce"
                        to={null}
                    />
                </Grid>
                <Condition when="personalDetails.maritalStatus" is="Married">
                    <True>
                        <Grid item xs={6}>
                            <FullWidthDatePicker
                                name="personalDetails.dateOfMarriage"
                                label="Date of Marriage"
                                disableFuture
                                validate={composeValidators(
                                    conditionalRequired(
                                        values => values.personalDetails.maritalStatus === 'Married'
                                    ),
                                    afterDateOfBirthValidator(
                                        values =>
                                            values.personalDetails.dateOfMarriage &&
                                            values.personalDetails.dateOfMarriage <
                                                values.personalDetails.dateOfBirth
                                    ),
                                    beforeDeathValidator(
                                        values =>
                                            values.personalDetails.dateOfMarriage &&
                                            values.personalDetails.dateOfMarriage >
                                                values.personalDetails.dateOfDeath
                                    )
                                )}
                            />
                        </Grid>
                    </True>
                </Condition>
                <Condition when="personalDetails.maritalStatus" is="Divorced">
                    <True>
                        <Grid item xs={6}>
                            <FullWidthDatePicker
                                name="personalDetails.dateOfDivorce"
                                label="Date of Divorce"
                                disableFuture
                                validate={conditionalRequired(
                                    values => values.personalDetails.maritalStatus === 'Divorced'
                                )}
                            />
                        </Grid>
                    </True>
                </Condition>
                <PreviousMarriagesView />
                <Grid item xs={6}>
                    <FullWidthTextField name="personalDetails.taxReferenceNumber" />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField name="personalDetails.lifeAssuredRole" disabled />
                </Grid>
            </Grid>
        </Paper>
    );
};

export const afterDateOfBirthValidator = (condition: (values: any) => boolean) => {
    return function validate(value: UserInput, values: any): ValidationResult {
        if (condition(values)) {
            return 'Date must be after date of birth';
        }
        return undefined;
    };
};

export const beforeDeathValidator = (condition: (values: any) => boolean) => {
    return function validate(value: UserInput, values: any): ValidationResult {
        if (condition(values)) {
            return 'Date must be before date of death';
        }
        return undefined;
    };
};

function defaultAgeFromDateOfBirth(dateOfBirth: Date) {
    return Math.abs(new Date(Date.now() - dateOfBirth.getTime()).getUTCFullYear() - 1970);
}

export const PersonalDetailsEditView = withStyles(styles)(personalDetailsEditView);
