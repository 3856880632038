import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Section } from '../../../../../controls/section';
import { State } from '../../../../../redux/root-reducer';
import { getFullName } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import {
    AddDocumentButton,
    RequiredDocumentsView,
} from '../../../common/requirements-management/views';
import { Executor, getExecutors } from '../../contact-details/contact-details-reducer';
import { AddClaimantDocumentStatusRecord } from '../actions/add-claimants-required-document';
import { addAndSubmitExecutorDocumentAction } from '../actions/add-executors-required-document';
import { ExecutorRequirementsStatus, getDocumentsRequirements } from '../reducer';
import { EditDocumentsButton } from './requirements-edit-modal';
import { DocumentOwnerType } from '../../../../../shared-types';

interface DispatchProps {
    addDocumentStatus: AddClaimantDocumentStatusRecord;
}
interface StateProps {
    executorRequirements: ExecutorRequirementsStatus[];
    executors: Executor[];
}
interface OwnProps {}
type Props = StyledComponent & OwnProps & DispatchProps & StateProps;
const executorRequirementView: React.FunctionComponent<Props> = ({
    addDocumentStatus,
    executorRequirements,
    executors,
}: Props) => {
    if (executorRequirements.length === 0) {
        return null;
    }

    return (
        <Section title="Executor requirements">
            {executorRequirements.map((requirement, index) => {
                const prefix = `executors[${index}].documents`;
                const executor = executors.find(c => c.id === requirement.id) || {};
                const executorName = getFullName(executor);
                const documents = requirement.documents || [];
                return (
                    <React.Fragment key={index}>
                        <Grid item xs={8} lg={10}>
                            <Typography variant="h5">{executorName}</Typography>
                        </Grid>
                        <Grid item xs={4} lg={2} style={{ textAlign: 'right' }}>
                            <EditDocumentsButton
                                documents={documents}
                                name={executorName}
                                prefix={prefix}
                            />
                            <AddDocumentButton
                                addAction={record => addDocumentStatus(record, index)}
                                documentOwnerType={DocumentOwnerType.Executor}
                                documents={documents}
                                isDeath
                            />
                        </Grid>
                        <RequiredDocumentsView documents={documents} />
                    </React.Fragment>
                );
            })}
        </Section>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => {
    const requirements = getDocumentsRequirements(state) || {};
    return {
        executors: getExecutors(state),
        executorRequirements: requirements.executors || [],
    };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    addDocumentStatus: (options, executorIndex: number) =>
        dispatch(addAndSubmitExecutorDocumentAction(options, executorIndex)),
});

export const ExecutorRequirementView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(executorRequirementView));
