import { claimSubmit } from '../../claim';
import { ReduxThunkAction } from '../../redux/redux-actions';
import {
    getRetrospectiveUnderwritingDecisionFromClaim,
    RetrospectiveUnderwritingDecisionDetails,
} from '../reducer';

type SubmitReturn = Promise<any>;
export type Submit = (decisionDetails: RetrospectiveUnderwritingDecisionDetails) => SubmitReturn;
type SubmitAction = (
    decisionDetails: RetrospectiveUnderwritingDecisionDetails
) => ReduxThunkAction<SubmitReturn>;

export const submitAction: SubmitAction = (
    decisionDetails: RetrospectiveUnderwritingDecisionDetails
) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, claim => {
            const originalRetrospectiveUnderwritingDecision = getRetrospectiveUnderwritingDecisionFromClaim(
                claim
            );
            return {
                ...claim,
                retrospectiveUnderwritingDecision: {
                    ...originalRetrospectiveUnderwritingDecision,
                    decisionDetails,
                },
            };
        });
    };
};

export type SaveClaim = () => SubmitReturn;
type SaveClaimAction = () => ReduxThunkAction<SubmitReturn>;
export const saveClaimAction: SaveClaimAction = () => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState);
    };
};
