import { withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { LabeledValue } from '../../../controls/labeled-value';
import { Lookups } from '../../../lookups';
import { State } from '../../../redux/root-reducer';
import styles from '../../../styles/styles';
import { AddressDetails, getDisplayAddress } from './model';

interface Props {
    address?: AddressDetails;
    label: string;
    lookups: Lookups;
}

const displayAddress = ({ address, label, lookups }: Props) => {
    return <LabeledValue label={label} value={getDisplayAddress(lookups, address)} />;
};

const mapStateToProps = (state: State) => ({ lookups: state.claimSession.lookups });

export const DisplayAddress = connect(mapStateToProps, {})(withStyles(styles)(displayAddress));
