// see https://material-ui.com/style/color/#color-tool for more info

export const black = '#000000';
export const dustygray = 'rgba(0,0,0,0.54)';
export const caribbeangreen = '#00D9B1';
export const jewelpie = '#fbab18';
export const shiraz = '#C60F30';
export const bluetang = '#4669c4';
export const shamrock = '#37DB9B';
export const raspberry = '#cd003d';
export const white = '#F2F2F2';
export const deepocean = '#01123e';
export const theriddler = '#36b37e';
export const gray = '#8b92a7';
