import { ReduceCollectionRecordAction } from '../../../../../../../redux/collection-record-reducer';
import { AddRecordCollectionAction } from '../../../../../../../redux/collection-reducer';
import { UnderwritingNonDisclosure } from '../underwriting-non-disclosure-model';
import { ADD_UNDERWRITING_NON_DISCLOSURE } from './underwriting-non-disclosure-action-types';

export type AddUnderwritingNonDisclosureAction = AddRecordCollectionAction<UnderwritingNonDisclosure>;

export type AddPolicyUnderwritingNonDisclosureAction = ReduceCollectionRecordAction<AddUnderwritingNonDisclosureAction>;

export const addUnderwritingNonDisclosureActionCreator = (
    policyIndex: number,
    underwritingNonDisclosure: UnderwritingNonDisclosure
): AddPolicyUnderwritingNonDisclosureAction => {
    return {
        type: ADD_UNDERWRITING_NON_DISCLOSURE,
        payload: {
            type: ADD_UNDERWRITING_NON_DISCLOSURE,
            record: underwritingNonDisclosure,
        },
        index: policyIndex,
    };
};
