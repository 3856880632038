import React from 'react'; // eslint-disable-line

import { isNil } from 'lodash';
import { Field, FieldRenderProps } from 'react-final-form';

type PassesFunction = (value: any) => boolean;

interface ConditionProps {
    readonly when: string;
    readonly children: React.ReactNode;
    readonly is?: any;
    readonly hasValue?: boolean;
    readonly passes?: PassesFunction;
    readonly matchesChild?: boolean;
}

interface ConditionChildProps {
    readonly children: React.ReactNode;
}

export const True: React.FunctionComponent<ConditionChildProps> = ({
    children,
}: ConditionChildProps) => {
    return <React.Fragment>{children}</React.Fragment>;
};
True.displayName = 'True';

export const False: React.FunctionComponent<ConditionChildProps> = ({
    children,
}: ConditionChildProps) => {
    return <React.Fragment>{children}</React.Fragment>;
};
False.displayName = 'False';

interface WhenProps {
    readonly is?: string;
    readonly default?: boolean;
    readonly children: React.ReactNode;
}
export const When: React.FunctionComponent<WhenProps> = ({ children }: WhenProps) => {
    return <React.Fragment>{children}</React.Fragment>;
};
When.displayName = 'When';

export const Condition: React.FunctionComponent<ConditionProps> = (props: ConditionProps) => {
    const { when, is, children, passes } = props;
    const evaulationPropertiesMap = {
        is: renderIs,
        hasValue: renderHasValue,
        passes: renderPasses,
        matchesChild: renderMatchesChild,
    };
    const propertiesToRenderDirectly = ['matchesChild'];
    const allowedChildrenTypes = [True, False, When];
    const allowedChildrenTypeNames = allowedChildrenTypes.map(type => getTypeName(type));
    const evaulationProperties = Object.keys(evaulationPropertiesMap);
    let evaluationPropertiesSpecified = 0;
    let selectedEvaluationProperty = '';
    for (const evaluationProperty of evaulationProperties) {
        if (!isNil(props[evaluationProperty])) {
            evaluationPropertiesSpecified++;
            selectedEvaluationProperty = evaluationProperty;
        }
    }
    if (evaluationPropertiesSpecified > 1) {
        throw new Error(
            `You must specify only one evaluation property when using the "Condition" component.\n
            Currently specified "${evaluationPropertiesSpecified}"\n
            Available evaluation properties: ${JSON.stringify(evaulationProperties)}`
        );
    }

    return (
        <Field name={when} subscription={{ value: true }}>
            {render}
        </Field>
    );

    function render({ input: { value } }: FieldRenderProps<any, any>): React.ReactNode {
        validateChildren();

        if (propertiesToRenderDirectly.indexOf(selectedEvaluationProperty) >= 0) {
            return evaulationPropertiesMap[selectedEvaluationProperty](value);
        }
        const isTrue = !selectedEvaluationProperty
            ? value
            : evaulationPropertiesMap[selectedEvaluationProperty](value);
        if (isTrue) {
            return React.Children.map(children, truthyChildren);
        }
        return React.Children.map(children, falsyChildren);
    }

    function validateChildren() {
        React.Children.forEach(children, validateChild);
        function validateChild(child: any): void {
            const type = getChildTypeName(child);
            if (type === 'string') {
                throw new Error(
                    `When using the <Conditional /> control, the direct children should not just be text, perhapse you ment to wrap the text in one of the following controls?\n${JSON.stringify(
                        allowedChildrenTypeNames
                    )}\nText provided: "${child}"`
                );
            }
            if (allowedChildrenTypeNames.indexOf(type) < 0) {
                throw new Error(
                    `Invalid direct child element of type "${type}" for Condition, must be one of ${JSON.stringify(
                        allowedChildrenTypeNames
                    )}`
                );
            }
            // todo if child is when but not allowed to be.
            // tood if child is true/false but should be when.
        }
    }

    function getChildTypeName(child: any): string {
        if (typeof child === 'string') {
            return 'string';
        }
        return getTypeName(child.type);
    }

    function getTypeName(type: any): string {
        if (!type) {
            throw new Error(`type was falsy\nChild: ${type}`);
        }
        if (typeof type === 'string') {
            return type;
        }
        if (typeof type === 'symbol') {
            return type.toString();
        }
        if (!type.displayName || typeof type.displayName !== 'string') {
            throw new Error(
                `child.type.displayName was not a valid string\nChild: ${JSON.stringify(type)}`
            );
        }
        return type.displayName || type.name;
    }

    function renderIs(value: any): boolean {
        if (Array.isArray(is)) {
            return is.indexOf(value) > -1;
        }
        return value === is;
    }
    function renderHasValue(value: any): boolean {
        return !!value;
    }

    function renderPasses(value: any): boolean {
        if (isNil(passes)) {
            throw new Error('Passes can not be null');
        }
        return passes(value);
    }

    function renderMatchesChild(value: any): React.ReactNode {
        const matchedChildren = React.Children.map(children, matchingChildren);
        if (matchedChildren && matchedChildren.filter(c => c !== null).length === 0 && !!children) {
            return (
                React.Children.toArray(children).find(
                    (child: any) => childIsOfType(child, When) && child.props.default
                ) || null
            );
        }

        return matchedChildren;
        function matchingChildren(child: any): React.ReactNode {
            if (
                childIsOfType(child, When) &&
                child.props.is !== undefined &&
                child.props.is !== null &&
                child.props.is === value
            ) {
                return child;
            }
            return null;
        }
    }

    function truthyChildren(child: any): React.ReactNode {
        if (childIsOfType(child, True)) {
            return child;
        }
        return null;
    }

    function falsyChildren(child: any): React.ReactNode {
        if (childIsOfType(child, False)) {
            return child;
        }
        return null;
    }

    function childIsOfType(child: any, typeToCheck: any): boolean {
        return (
            getChildTypeName(child).toLocaleLowerCase() ===
            getTypeName(typeToCheck).toLocaleLowerCase()
        );
    }
};
