import React from 'react'; // eslint-disable-line

import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import { Field } from 'react-final-form';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { FormHeading, FullWidthRadioGroup, GenericForm, RadioOption } from '../../forms';
import { ExpansionPanel } from '../../forms/form-components/expansion-panel/expansion-panel';
import { State } from '../../redux/root-reducer';
import styles, { StyledComponent } from '../../styles/styles';
import { OnSubmitSuccessProps } from '../../workflow-navigation';
import { Client } from '../actions';
import { Contract } from '../actions/contracts-loaded';
import {
    InitiateClaim,
    initiateClaimAction,
    InitiateClaimDetails,
} from '../actions/initiate-claim';
import { ClaimType } from '../../claim/claim-types';

interface OwnProps {
    contracts: Contract[];
}

interface StateProps {}

interface DispatchProps {
    initiateClaim: InitiateClaim;
}

type Props = OnSubmitSuccessProps & OwnProps & StateProps & DispatchProps & StyledComponent;
const ClaimTypeSelectionForm = GenericForm<Props>();
const claimTypeSelectionView: React.FunctionComponent<Props> = ({
    classes,
    initiateClaim,
    contracts,
    ...rest
}: Props) => {
    const onSubmit = (values: any) => {
        return initiateClaim(values);
    };
    const claimTypes = getClaimTypes(contracts);
    return (
        <ClaimTypeSelectionForm onSubmit={onSubmit} {...rest}>
            <Paper className={classes.paper}>
                <FormHeading text="Choose Claim Type" />
                <Grid container spacing={5} justify="flex-start">
                    <Grid item xs={12} lg={12}>
                        <FullWidthRadioGroup name="claimType" label="&nbsp;">
                            {claimTypes
                                .filter(ct => ct !== ClaimType.Funeral)
                                .map(ct => (
                                    <RadioOption value={ct} key={ct} />
                                ))}
                        </FullWidthRadioGroup>
                        <Field
                            name="claimType"
                            subscription={{ value: true }}
                            component={({ input: { value: claimType } }) => (
                                <React.Fragment>
                                    {contracts
                                        .filter(contract => contract.claimTypes.includes(claimType))
                                        .map((contract, index) => (
                                            <ContractView
                                                key={index}
                                                classes={classes}
                                                contract={contract}
                                            />
                                        ))}
                                </React.Fragment>
                            )}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </ClaimTypeSelectionForm>
    );
};

type ContractProps = StyledComponent & { contract: Contract };
const ContractView: React.FunctionComponent<ContractProps> = ({
    classes,
    contract,
}: ContractProps) => {
    return (
        <ExpansionPanel
            name={`Policy Number - ${contract.contractNumber}`}
            key={contract.contractNumber}
        >
            <Paper className={classes.paper}>
                {contract.claimTypes.map((claimType, index) => (
                    <Typography key={claimType + index} variant="subtitle1">
                        <strong>{claimType}</strong>
                    </Typography>
                ))}
            </Paper>
        </ExpansionPanel>
    );
};

function getClaimTypes(contracts: Contract[]) {
    return contracts
        .map(c => c.claimTypes)
        .reduce((allClaimTypes: ClaimType[], claimTypes: ClaimType[]) =>
            allClaimTypes.concat(claimTypes)
        )
        .reduce((claimTypes: ClaimType[], claimType: ClaimType) => {
            if (claimTypes.indexOf(claimType) >= 0) {
                return claimTypes;
            }
            return claimTypes.concat(claimType);
        }, [])
        .sort();
}
function getContracts(clients: Client[]): Contract[] {
    return clients.reduce(
        (contracts: Contract[], client: Client) => contracts.concat(client.contracts || []),
        []
    );
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    contracts: getContracts(state.initiateClaim.selectedClients || []),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    initiateClaim: (criteria: InitiateClaimDetails) => dispatch(initiateClaimAction(criteria)),
});

export const ClaimTypeSelectionView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(claimTypeSelectionView));
