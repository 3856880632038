import React from 'react'; // eslint-disable-line

import { Grid, Paper, withStyles } from '@material-ui/core';
import { connect, MapStateToProps } from 'react-redux';
import { AuthState } from '../../../../../auth/auth-reducer';
import { getRADeathClaim } from '../../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import {
    Condition,
    FormHeading,
    FullWidthDatePicker,
    FullWidthRadioGroup,
    FullWidthRichTextField,
    FullWidthSearchSelectField,
    FullWidthTextField,
    RadioOption,
    True,
    WhenFieldChanges,
} from '../../../../../forms';
import { requiredValidator } from '../../../../../forms/validations';
import { State } from '../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { RelationshipDetailsView } from '../../../death/common/views/edit-relationship-details';
import { Dependant } from '../../dependants/reducer';
import { getDependantOptions } from '../reducer';

interface StateProps {
    dependants: Dependant[];
    currentUser: AuthState;
}

interface OwnProps {}

type Props = OwnProps & StateProps & StyledComponent;

const disputeEditView: React.FunctionComponent<Props> = ({
    dependants,
    currentUser,
    classes,
}: Props) => {
    return (
        <Paper className={classes.paper}>
            <FormHeading text="Dispute" />
            <Grid container justify="flex-start" spacing={5}>
                <Grid item xs={6} lg={6}>
                    <FullWidthSearchSelectField
                        id="dependantId"
                        name="dependantId"
                        label="Select dependant"
                        validate={requiredValidator}
                        options={getDependantOptions(dependants)}
                    />
                </Grid>
                <WhenFieldChanges field="dependantId" set="otherName" to={null} />
                <WhenFieldChanges field="dependantId" set="otherSurname" to={null} />
                <WhenFieldChanges field="dependantId" set="relationshipToLifeAssured" to={null} />
                <WhenFieldChanges
                    field="dependantId"
                    set="relationshipToLifeAssuredDescription"
                    to={null}
                />
                <Grid item xs={6}>
                    <FullWidthDatePicker
                        name="disputeDate"
                        validate={requiredValidator}
                        disableFuture
                    />
                </Grid>
                <Condition when="dependantId" is="Other">
                    <True>
                        <Grid item xs={6} lg={6}>
                            <FullWidthTextField
                                id="otherName"
                                name="otherName"
                                label="Name"
                                validate={requiredValidator}
                            />
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <FullWidthTextField
                                id="otherSurname"
                                name="otherSurname"
                                label="Surname"
                                validate={requiredValidator}
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <RelationshipDetailsView
                                hideOwnerRelationship={true}
                                isAnOrganisation={false}
                                propertyPrefix=""
                            />
                        </Grid>
                    </True>
                </Condition>
                <Grid item xs={12} lg={12}>
                    <FullWidthRichTextField
                        multiline
                        label="Reason for the dispute?"
                        id="reason"
                        name="reason"
                        validate={requiredValidator}
                    />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <FullWidthRichTextField
                        multiline
                        id="supportingProof"
                        name="supportingProof"
                        label="Supporting proof"
                        validate={requiredValidator}
                    />
                </Grid>
                <Grid item xs={6} lg={6}>
                    <FullWidthRadioGroup
                        label="decision"
                        name="decision"
                        validate={requiredValidator}
                        id="decision"
                    >
                        <RadioOption value="Decision remains" />
                        <RadioOption value="Amended decision" />
                    </FullWidthRadioGroup>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <FullWidthRichTextField
                        multiline
                        id="summaryOfDecision"
                        name="summaryOfDecision"
                        label="Summary of decision"
                        validate={requiredValidator}
                    />
                </Grid>
                <Grid item xs={6} lg={6}>
                    <FullWidthSearchSelectField
                        id="decisionMadeBy"
                        name="decisionMadeBy"
                        label="Decision Made By"
                        validate={requiredValidator}
                        options={[
                            { value: 'Assessor', label: 'Assessor' },
                            { value: 'Trustees', label: 'Trustees' },
                        ]}
                    />
                    <WhenFieldChanges field="decisionMadeBy" set="trusteeMeetingDate" to={null} />
                </Grid>
                <Condition when="decisionMadeBy" is="Trustees">
                    <True>
                        <Grid item xs={6} lg={6}>
                            <FullWidthDatePicker
                                name="trusteeMeetingDate"
                                validate={requiredValidator}
                                disableFuture
                            />
                        </Grid>
                    </True>
                </Condition>
                <Grid item xs={6} lg={6}>
                    <FullWidthTextField
                        multiline
                        id="assessor"
                        name="assessor"
                        label="Assessor"
                        validate={requiredValidator}
                        defaultValue={currentUser.name}
                        disabled
                    />
                </Grid>
                <Grid item xs={6} lg={6}>
                    <FullWidthDatePicker
                        name="assessmentDate"
                        validate={requiredValidator}
                        maxDateField="assessmentDate"
                        defaultValue={new Date()}
                        disableFuture
                        disabled
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    dependants: getRADeathClaim(state).dependants,
    currentUser: state.auth as AuthState,
});

const mapDispatchToProps = {};

export const DisputeEditView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(disputeEditView));
