import React, { useEffect } from 'react'; // eslint-disable-line
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

import { connect } from 'react-redux';
import { State } from '../../redux/root-reducer';
import { withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import { closeToastAction } from '../actions';
import { CheckCircle } from '@material-ui/icons';
interface Props {
    openToast: any;
    closeToast: any;
}

const Toaster: React.FunctionComponent<Props> = ({ openToast, closeToast }: Props) => {
    const notify = () => {
        switch (openToast.toastContent.type) {
            case 'success':
                return toast.success(openToast.toastContent.message, {
                    icon: (
                        <CheckCircle
                            style={{
                                color: '#002b99',
                                marginRight: 10,
                            }}
                        />
                    ),
                });
            case 'error':
                return toast.error(openToast.toastContent.message, {
                    icon: (
                        <CheckCircle
                            style={{
                                color: '#FFFFFF',
                                marginRight: 10,
                            }}
                        />
                    ),
                });
            default:
                break;
        }
    };

    useEffect(() => {
        toast.onChange(c => closeToast());

        if (openToast.isToastVisible) notify();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openToast.isToastVisible]);

    return (
        <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            theme="colored"
        />
    );
};

const mapDispatchToProps = {
    closeToast: closeToastAction,
};

const mapStateToProps = (state: State) => ({
    openToast: state.confirmationDialog,
});

export const ToasterView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Toaster));
