import { ReduxAction } from '../redux/redux-actions';
import { CLOSE_DIALOG, CLOSE_TOAST, OPEN_DIALOG, OPEN_TOAST, VIEW_MODAL } from './';

export interface DialogContentDetails {
    readonly title: React.ReactNode;
    readonly body: React.ReactNode;
    readonly error?: boolean;
}

export const EmptyDialogContent: DialogContentDetails = {
    title: '',
    body: null,
};

export interface DialogAction {
    readonly label?: React.ReactNode;
    readonly action: () =>
        | void
        | Promise<void>
        | Record<string, any>
        | Promise<Record<string, any>>;
}

export interface DialogDetails {
    readonly isOpen: boolean;
    readonly content: DialogContentDetails;
    readonly toastContent?: any;
    readonly yesAction?: DialogAction;
    readonly noAction?: DialogAction;
    readonly canCancel?: boolean;
    readonly cancelLabel?: React.ReactNode;
    readonly isToastVisible?: boolean;
    readonly isOpenModal?: boolean;
    readonly form?: any;
}

export const initialDialogDetails: DialogDetails = {
    isOpen: false,
    content: EmptyDialogContent,
    isToastVisible: false,
    toastContent: [],
    isOpenModal: false,
};

export function confirmationDialogReducer(
    state: DialogDetails = initialDialogDetails,
    action: ReduxAction<DialogDetails>
) {
    switch (action.type) {
        case OPEN_DIALOG:
            return action.payload;
        case CLOSE_DIALOG:
            return {
                ...state,
                ...action.payload,
            };
        case OPEN_TOAST:
            return {
                ...state,
                ...action.payload,
            };
        case CLOSE_TOAST:
            return {
                ...state,
                ...action.payload,
            };
        case VIEW_MODAL:
            return {
                ...state,
                ...action.payload,
            };

        default:
            return state;
    }
}
