import { collectionReducer } from '../../../../redux/collection-reducer';
import { ADD_CLAIM_DETAILS_RECORD } from './types';
import { Reducer, AnyAction } from 'redux';
import { Nullable } from '../../../../shared-types';
import { combineReducersWithDefault } from '../../../../redux/combine-reducers-with-default';
import { defaultDeathClaimDetails } from '../../../../claim/reducers/claim-reducers/death-claim-reducer';
import { getPropertyFromClaim, Claim } from '../../../../claim';
import { VOPD, vopdReducer } from '../../common/vopd/reducer';

export interface DeathClaimDetails {
    readonly dateOfEvent?: Date;
    readonly dateClaimReported?: Date;
    readonly placeOfDeath?: string;
    readonly postalCode?: number;
    readonly reportedToPolice?: boolean;
    readonly caseNumber?: string;
    readonly causeOfDeath?: string;
    readonly typeOfDeath?: string;
    readonly causeOfDeathDescription?: string;
    readonly dateOfWill?: Date;
    readonly lateSubmissionReason?: string;
    readonly lateSubmissionReasonDescription?: string;
    readonly bi1663?: string;
}

export interface FinancialDetails {
    readonly previouslyInsolvent?: string;
    readonly previouslyInsolventDate?: Date;
    readonly previouslyInsolventReason?: string;
    readonly estateInsolvent?: string;
    readonly estateInsolventDate?: Date;
    readonly estateInsolventReason?: string;
}

export interface DeathClaimNotes {
    readonly id?: string;
    readonly dateCaptured?: Date;
    readonly notesSummary?: string;
    readonly author?: string;
}

export interface DeathClaimEventDetails {
    readonly claimNotes: DeathClaimNotes[];
    readonly deathClaimDetails?: DeathClaimDetails;
    readonly vopd: VOPD;
}

export const defaultDeathClaimEventDetails: DeathClaimEventDetails = {
    claimNotes: [],
    deathClaimDetails: {},
    vopd: {},
};

export const claimNotesReducer = collectionReducer<DeathClaimNotes>({
    addAction: ADD_CLAIM_DETAILS_RECORD,
});

export const deathClaimDetailsReducer: Reducer = (
    state: Nullable<DeathClaimDetails> = null,
    action: AnyAction
) => {
    return state;
};

export const deathClaimEventDetailsReducer = combineReducersWithDefault<DeathClaimEventDetails>(
    defaultDeathClaimDetails,
    {
        claimNotes: claimNotesReducer,
        deathClaimDetails: deathClaimDetailsReducer,
        vopd: vopdReducer,
    }
);

export function getDeathClaimEventDetailsFromClaim(claim: Claim): DeathClaimEventDetails {
    return getPropertyFromClaim(claim, 'claimEventDetails', defaultDeathClaimEventDetails);
}
