import React from 'react'; // eslint-disable-line

import { AssessmentDetailsView } from '../../common/views/assessment-details';
import { AssessmentDecisionAddView } from '../../common/views/assessment-decision-add';
import { DreadAssessmentDecisionFields } from './dread-assessment-decision-fields';

interface Props {
    noPartialPayment: boolean;
}
export const DreadAssessmentDetailsView: React.FunctionComponent<Props> = ({
    noPartialPayment,
}: Props) => {
    return (
        <AssessmentDetailsView
            assessmentDecisionAddView={props => (
                <AssessmentDecisionAddView
                    {...props}
                    claimSpecificApproveElements={
                        <DreadAssessmentDecisionFields
                            noPartialPayment={noPartialPayment}
                            sumAssured={props.sumAssured}
                        />
                    }
                />
            )}
        />
    );
};
