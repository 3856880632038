import { DateTime } from 'luxon';
import { ClaimCategory } from '../codes/claim-category';
import { ClaimEvent } from '../codes/claim-event';
import { CommunicationMethods } from '../codes/communication-methods';
import { DocumentName } from '../codes/document-name';
import { IdDocumentTypes } from '../codes/id-document-types';
import { PolicyStatusCodes } from '../codes/policy-status';
import { ReasonForReferral } from '../codes/reason-for-referral';
import { Titles } from '../codes/titles';
import { nameToLabel } from '../forms';
import { getDisplayDate } from '../shared-functions';
import { AuditTrailFilter, ClaimChangeSet } from './claim-audit-trail-reducer';
import { BankNames } from '../codes/bank-names';
import { NaturalCauseOfDeath, UnnaturalCauseOfDeath } from '../codes/cause-of-claim';
import { Code } from '../codes/codes';
import { startCase } from 'lodash';

export interface ChangeFieldSet {
    fieldName?: React.ReactNode;
    currentValue?: string;
    previousValue?: string;
}

let beneficiaries: Code<string>[] = [];

export function getCustomChangeRecord(
    changeRecord: Record<string, any>,
    claimants: Code<string>[]
) {
    beneficiaries = claimants;
    return changeRecordFromChangeSet(changeRecord);
}

export function changeRecordFromChangeSet(change: Record<string, any>) {
    let records: ChangeFieldSet[] = [];
    if (change) {
        Object.keys(change).forEach(changeKey => {
            const fields = change[changeKey];
            if (changeKey.toLowerCase() === 'waiver') {
                if (Array.isArray(fields)) {
                    if (fields[0] === null) {
                        records = records.concat(changeRecordFromAddChangeSet(fields[1]));
                    }
                    if (fields[1] === null) {
                        records = records.concat(changeRecordFromDeleteChangeSet(fields[0]));
                    }
                } else {
                    records = records.concat(changeRecordFromChangeSet(fields));
                }
            } else {
                if (fields) {
                    pushToRecords(changeKey, records, fields[0], fields[1]);
                }
            }
        });
    }

    return records;
}

export function changeRecordFromAddChangeSet(change: Record<string, any>, showEmpty = false) {
    let records: ChangeFieldSet[] = [];
    if (change) {
        Object.keys(change).forEach(changeKey => {
            if (isDeepObject(change, changeKey)) {
                records = records.concat(changeRecordFromAddChangeSet(change[changeKey]));
            } else {
                pushToRecords(changeKey, records, undefined, change[changeKey], showEmpty);
            }
        });
    }

    return records;
}

function isDeepObject(change: Record<string, any>, changeKey: string) {
    return (
        change[changeKey] &&
        (changeKey.toLowerCase() === 'waiver' ||
            changeKey.toLowerCase() === 'bankingdetails' ||
            changeKey.toLowerCase() === 'personaldetails' ||
            changeKey.toLowerCase() === 'residentialaddressdetails' ||
            changeKey.toLowerCase() === 'postaladdressdetails' ||
            changeKey.toLowerCase() === 'guardiandetails' ||
            changeKey.toLowerCase() === 'communicationdetails' ||
            changeKey.toLowerCase() === 'contactdetails' ||
            changeKey.toLowerCase() === 'organisationdetails' ||
            changeKey.toLowerCase() === 'financialinformation' ||
            changeKey.toLowerCase() === 'guardian' ||
            changeKey.toLowerCase() === 'postaladdress' ||
            changeKey.toLowerCase() === 'residentialaddress' ||
            changeKey.toLowerCase() === 'authorisedperson' ||
            changeKey.toLowerCase() === 'address')
    );
}

export function changeRecordFromDeleteChangeSet(change: Record<string, any>) {
    let records: ChangeFieldSet[] = [];
    if (change) {
        Object.keys(change).forEach(changeKey => {
            if (isDeepObject(change, changeKey)) {
                records = records.concat(changeRecordFromDeleteChangeSet(change[changeKey]));
            } else {
                pushToRecords(changeKey, records, change[changeKey], undefined);
            }
        });
    }

    return records;
}

function pushToRecords(
    changeKey: string,
    records: ChangeFieldSet[],
    previousValue?: string,
    currentValue?: string,
    showEmpty = false
) {
    const lowerCaseChangeKey = changeKey.toLowerCase();
    if (
        (previousValue || currentValue || showEmpty) &&
        lowerCaseChangeKey !== 'uniqueid' &&
        lowerCaseChangeKey !== 'id' &&
        lowerCaseChangeKey !== 'files'
    ) {
        records.push({
            fieldName: nameToLabel({ name: changeKey }),
            currentValue: getDisplayValue(changeKey, currentValue),
            previousValue: getDisplayValue(changeKey, previousValue),
        });
    }
}

export function getDisplayValue(fieldName: string, value?: string) {
    let displayValue = value;

    switch (fieldName.toLowerCase()) {
        case 'bankname':
            displayValue = getCodeDescription(BankNames);
            break;
        case 'title':
            displayValue = getCodeDescription(Titles);
            break;
        case 'preferredmethodofcommunication':
            displayValue = getCodeDescription(CommunicationMethods);
            break;
        case 'documentname':
            displayValue = getCodeDescription(DocumentName);
            break;
        case 'policystatus':
            displayValue = getCodeDescription(PolicyStatusCodes);
            break;
        case 'reasonforreferral':
            displayValue = getCodeDescription(ReasonForReferral);
            break;
        case 'permanentimpairmentclaimsclaimcategory':
            displayValue = getCodeDescription(ClaimCategory);
            break;
        case 'guaranteedpaymentperiodclaims':
            displayValue = getCodeDescription(ClaimEvent);
            break;
        case 'idtype':
            displayValue = getCodeDescription(IdDocumentTypes);
            break;
        case 'causeofdeath':
            displayValue = getCodeDescription(UnnaturalCauseOfDeath.concat(NaturalCauseOfDeath));
            break;
        case 'linktoclaimant':
            if (value) {
                const claimant = beneficiaries.find(b => b.value === value);
                displayValue = (claimant && claimant.label) || 'Unknown (deleted)';
            }
            break;
        default: {
            // @ts-ignore
            const dateValue = DateTime.fromISO(value);
            if (typeof value === 'string' && value.length > 9 && dateValue.isValid) {
                displayValue = getDisplayDate(new Date(value));
            } else if (typeof value === 'boolean') {
                displayValue = value ? 'Yes' : 'No';
            }
            break;
        }
    }

    return displayValue;

    function getCodeDescription(codesList: any[]): string | undefined {
        const reason = codesList.filter(t => t.value === value);
        if (reason && reason.length > 0) {
            return reason[0].label;
        }
        return value;
    }
}

export function showAuditTrail(
    auditTrailFilter: AuditTrailFilter,
    sectionName: string,
    auditTrail: ClaimChangeSet
) {
    if (
        auditTrailFilter &&
        auditTrailFilter.sectionName !== undefined &&
        sectionName !== undefined &&
        auditTrailFilter.sectionName.toLowerCase() !== sectionName.toLowerCase()
    ) {
        return false;
    }

    if (auditTrail.timeStamp) {
        const timeStamp = new Date(auditTrail.timeStamp);
        const startDate = auditTrailFilter.startDate
            ? new Date(auditTrailFilter.startDate)
            : undefined;
        const endDate = auditTrailFilter.endDate ? new Date(auditTrailFilter.endDate) : undefined;
        if (startDate && startDate.setHours(0, 0, 0, 0) > timeStamp.setHours(0, 0, 0, 0)) {
            return false;
        }
        if (endDate && endDate.setHours(0, 0, 0, 0) < timeStamp.setHours(0, 0, 0, 0)) {
            return false;
        }
    }

    if (
        auditTrail.user.userId &&
        auditTrailFilter.user &&
        auditTrail.user.userId !== auditTrailFilter.user
    ) {
        return false;
    }

    return true;
}

export function getDisplayName(value: string) {
    return startCase(value).split(' ').join(' ');
}
