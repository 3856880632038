import { AnyAction, Reducer } from 'redux';
import { getProperty } from '../../../claim/reducers';
import { State } from '../../../redux/root-reducer';
import { Nullable } from '../../../shared-types';
import { ClaimType } from '../../../claim/claim-types';
import {
    AssessmentDecision,
    BenefitAssessmentDetails,
    ClaimLiability,
} from '../assessment-details-reducer';

export interface RiskPolicyAssessmentDetails {
    readonly policyNumber: string;
    readonly automaticBenefitIncrease?: number;
    readonly benefits: BenefitAssessmentDetails[];
}

export interface InvestmentPolicyAssessment {
    readonly productName: string;
    readonly policyNumber: string;
    readonly commencementDate?: Date;
    readonly decisions: AssessmentDecision[];
    readonly claimLiability: ClaimLiability;
}

export interface AssessmentDetails {
    readonly riskPolicyAssessmentDetails: RiskPolicyAssessmentDetails[];
    readonly investmentPolicyAssessments: InvestmentPolicyAssessment[];
}

export interface DeathAssessmentDecisionViewModel {
    readonly id: string;
    readonly final?: boolean;
    readonly assessmentType?: string;
    readonly assessor?: string;
    readonly assessmentDate: Date;
    readonly summary?: string;
    readonly decision?: string;
    readonly reason?: string;
    readonly claimType?: Nullable<ClaimType>;
    readonly effectiveDate?: Date;
    readonly expiryDate?: Date;
    readonly amountPayable?: number;
    readonly policyNumber: string;
    readonly automaticBenefitIncrease?: number;
    readonly benefitName?: string;
    readonly commencementDate?: Date;
    readonly sumAssured?: number;
    readonly paymentBasis?: string;
    readonly isOverLimit?: boolean;
}

export const defaultDeathAssessmentDetails: AssessmentDetails = {
    investmentPolicyAssessments: [],
    riskPolicyAssessmentDetails: [],
};

type Action = AnyAction;

export const deathAssessmentDetailsReducer: Reducer = (
    state: Nullable<AssessmentDetails> = null,
    action: Action
) => {
    return state;
};

export function getDeathAssessmentDetails(state: State): AssessmentDetails {
    return getProperty(state, 'deathAssessmentDetails', defaultDeathAssessmentDetails);
}
