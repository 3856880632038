import { FORM_ERROR } from 'final-form';
import { omit } from 'lodash';
import { Claim, claimSubmit } from '../../claim';
import { isEmpty } from '../../forms/validations';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { ContactPersonDetails } from '../contact-person/contact-person-details';
import { ClaimContactDetails } from '../reducer';
import {
    getFormDetailsContactDetails,
    getFormDetailsFromClaim,
    getFormDetailsListFromClaim,
} from '../../assessment/synopsis/living-lifestyle/form-details/form-details-reducer';

type SubmitReturn = Promise<any>;
export type Submit = (claim: ClaimContactDetails) => SubmitReturn;
type SubmitAction = (claim: ClaimContactDetails) => ReduxThunkAction<SubmitReturn>;
export const submitAction: SubmitAction = (claimContactDetails: ClaimContactDetails) => {
    return async (dispatch, getState) => {
        return claimSubmit(
            dispatch,
            getState,
            getClaimToBeSubmitted(claimContactDetails),
            validateClaim
        );
    };
};

function getClaimToBeSubmitted(claimContactDetails: ClaimContactDetails): (claim: Claim) => Claim {
    const financialAdviser = isEmpty(claimContactDetails.financialAdviser)
        ? undefined
        : claimContactDetails.financialAdviser;
    const contactPerson = getContactPersonToBeSubmitted(claimContactDetails.contactPerson);

    return claim => ({
        ...claim,
        formDetails: {
            ...getFormDetailsFromClaim(claim),
            ...getFormDetailsContactDetails(claim, financialAdviser, contactPerson),
            ...getFormDetailsListFromClaim(claim),
        },
        contactDetails: {
            financialAdviser,
            contactPerson,
        },
    });
}

function getContactPersonToBeSubmitted(
    contactPerson?: ContactPersonDetails
): ContactPersonDetails | undefined {
    if (!!contactPerson && contactPerson.sameDetailsAsFa) {
        return {
            sameDetailsAsFa: contactPerson.sameDetailsAsFa,
        };
    }

    if (isEmpty(omit(contactPerson, 'sameDetailsAsFa'))) {
        return undefined;
    }

    return contactPerson;
}

function validateClaim(claim: Claim) {
    if (!('contactDetails' in claim) || !claim.contactDetails) {
        return {};
    }

    const contactDetails: ClaimContactDetails = claim.contactDetails;
    return validate(contactDetails);
}

export function validate(contactDetails: ClaimContactDetails) {
    if (
        isEmpty(contactDetails.financialAdviser) &&
        isEmpty(omit(contactDetails.contactPerson, 'sameDetailsAsFa'))
    ) {
        return {
            [FORM_ERROR]:
                'Please capture at least a contact person for the claim or the broker consultant contact details',
        };
    }
    return {};
}
