import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { LabeledValue } from '../../../../controls/labeled-value';
import { OpenModal, openModalAction } from '../../../../form-modal/actions';
import { ButtonWrapper } from '../../../../forms';
import { State } from '../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import {
    UpdateNoticeOfDeath,
    updateNoticeOfDeathAction,
} from '../../common/notice-of-death/actions/update-notice-of-death';
import {
    getNoticeOfDeath,
    NoticeOfDeath,
} from '../../common/notice-of-death/notice-of-death-reducer';
import { editNoticeOfDeath } from './notice-of-death';

interface Props extends StyledComponent {
    isReadOnly: boolean;
    hasDeferredContracts: boolean;
    noticeOfDeath: NoticeOfDeath;
    openModal: OpenModal;
    updateNoticeOfDeath: UpdateNoticeOfDeath;
}

const formInfoDetailsView = ({
    classes,
    noticeOfDeath,
    isReadOnly,
    hasDeferredContracts,
    ...rest
}: Props) => {
    const edit = () => {
        editNoticeOfDeath({ noticeOfDeath, index: 0, ...rest });
    };
    return (
        <Grid container justify="flex-start" className={classes.marginTop}>
            <Grid item xs={12} container>
                <Grid item xs={10}>
                    <Typography variant="body1" className={classes.heading}>
                        Document Details
                    </Typography>
                </Grid>
                {!hasDeferredContracts && (
                    <ButtonWrapper>
                        <Grid item xs={2} container justify="flex-end">
                            <Tooltip title="Edit Document Details">
                                <Button
                                    id="EditFormInformationDetails"
                                    color="secondary"
                                    onClick={edit}
                                >
                                    <Edit />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </ButtonWrapper>
                )}
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Serial Number" value={noticeOfDeath.serialNumber} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Serial Number is Consistent"
                    value={noticeOfDeath.serialNumberConsistent}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Date Signed"
                    value={getDisplayDate(noticeOfDeath.dateSigned)}
                />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state: State) => ({
    noticeOfDeath: getNoticeOfDeath(state),
    isReadOnly: state.claimSession.readOnly,
    hasDeferredContracts: state.claimSession.hasDeferredContracts,
});

const mapDispatchToProps = {
    openModal: openModalAction,
    updateNoticeOfDeath: updateNoticeOfDeathAction,
};

export const FormInfoDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(formInfoDetailsView));
