import axios from 'axios';
import { claimUpdatedThunk } from '../../claim';
import { openDialogAction } from '../../confirmation-dialog';
import { env } from '../../env';
import { hideLoadingAction, showLoadingAction } from '../../forms/loading-screen/actions';
import { Nullable } from '../../shared-types';
import { getClaimType } from '../reducer';

export function reuploadDocumentsAction(
    referenceId: string,
    referenceSource: string,
    forms: Nullable<string[]>
) {
    return async (dispatch: any) => {
        dispatch(showLoadingAction());
        const uri = `${env().CLAIMS_API}/api/Claim/ReuploadFormDocuments`;
        await axios
            .put(uri, getUploadRequest(referenceId, referenceSource, forms))
            .then(response => {
                if (response.status === 200) {
                    if (response.data) {
                        dispatch(claimUpdatedThunk(response.data));
                    }
                    dispatch(
                        openDialogAction({
                            content: {
                                title: 'Form documents uploaded successfully',
                                body: '',
                            },
                        })
                    );
                } else {
                    showErrorMessage(`Status Code ${response.status}`);
                }
            })
            .catch(error => {
                let errorMessage = '';
                if (error.response) {
                    errorMessage = `Error Code ${error.response.status}`;
                } else {
                    errorMessage = error.message;
                }
                showErrorMessage(errorMessage);
            })
            .then(() => dispatch(hideLoadingAction()));

        function showErrorMessage(error: any) {
            const errorMessage = `An error (${error}) occured while uploading documents, please retry later`;
            dispatch(
                openDialogAction({
                    content: {
                        title: 'Error uploading form documents',
                        body: errorMessage,
                        error: true,
                    },
                })
            );
        }
    };
}

function getUploadRequest(referenceId: string, referenceSource: string, forms: Nullable<string[]>) {
    return {
        referenceId,
        referenceSource,
        claimType: getClaimType(forms),
    };
}
