import { Claim, claimSubmit } from '../../../../../claim';
import { RADeathClaim } from '../../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { CommunicationDetails } from '../../../../../forms/sub-forms/communication-details';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';

export interface LifeAssuredContactDetails extends CommunicationDetails {
    workTelephoneNumber?: string;
    homeTelephoneNumber?: string;
}

type UpdateContactDetailsReturn = Promise<any>;
export type UpdateContactDetails = (
    contactDetails: LifeAssuredContactDetails
) => UpdateContactDetailsReturn;
type UpdateContactDetailsAction = (
    contactDetails: LifeAssuredContactDetails
) => ReduxThunkAction<UpdateContactDetailsReturn>;

export const updateContactDetailsAction: UpdateContactDetailsAction = (
    contactDetails: LifeAssuredContactDetails
) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, getUpdatedContactDetails(contactDetails));
    };
};

function getUpdatedContactDetails(
    contactDetails: LifeAssuredContactDetails
): (claim: Claim) => Claim {
    return (claim: Claim) => {
        if (claim.claimType === 'RADeath') {
            const raClaim = claim as RADeathClaim;
            return {
                ...claim,
                lifeAssuredDetails: {
                    ...raClaim.lifeAssuredDetails,
                    contactDetails: contactDetails,
                },
            };
        }

        return claim;
    };
}
