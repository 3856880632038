import { AnyAction, Reducer } from 'redux';
import { Nullable } from '../shared-types';
import { SET_ACTIVE_USER } from './action-types';

export interface AuthState {
    userId: string;
    name: string;
    roles: string[];
}

export interface SetActiveUserAction extends AnyAction {
    payload: Nullable<AuthState>;
}

export type LogOutAction = AnyAction;

export const authReducer: Reducer<Nullable<AuthState>, AnyAction> = (
    state: Nullable<AuthState> = null,
    action: AnyAction
) => {
    switch (action.type) {
        case SET_ACTIVE_USER:
            return action.payload;
        default:
            return state;
    }
};
