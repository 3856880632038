import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import {
    changeRecordFromAddChangeSet,
    changeRecordFromChangeSet,
    changeRecordFromDeleteChangeSet,
    getDisplayName,
} from '../claim-audit-trail-functions';
import { addAuditTrailDetailsView } from '../common-views/claim-audit-trail-field-details';
import { mapSectionView } from '../common-views/claim-audit-trail-wrapper';
import { isEmpty } from '../../forms/validations';

interface Props {
    changeSet: Record<string, any>;
}

const auditTrailSimpleView: React.FunctionComponent<Props> = ({ changeSet }: Props) => {
    const returnView: React.ReactNode[] = [];
    const keys = ['deathCertificate', 'vopd'];
    if (Array.isArray(changeSet)) {
        if (changeSet[1] !== null) {
            keys.forEach(key => {
                const change = changeSet[1][key];
                if (!isEmpty(change)) {
                    addAuditTrailDetailsView(
                        returnView,
                        changeRecordFromAddChangeSet(changeSet[1][key]),
                        [getDisplayName(key)]
                    );
                }
            });
        }
    } else {
        keys.forEach(key => {
            if (changeSet.hasOwnProperty(key)) {
                const details = changeSet[key];
                if (details) {
                    addViews(returnView, details, [getDisplayName(key)]);
                }
            }
        });
    }
    return mapSectionView(returnView);
};

function addViews(returnView: React.ReactNode[], changeSet: any, subSection?: string[]) {
    if (Array.isArray(changeSet) && changeSet[0] === null) {
        addAuditTrailDetailsView(
            returnView,
            changeRecordFromAddChangeSet(changeSet[1]),
            subSection
        );
    } else if (Array.isArray(changeSet) && changeSet[1] === null) {
        addAuditTrailDetailsView(
            returnView,
            changeRecordFromDeleteChangeSet(changeSet[0]),
            subSection
        );
    } else {
        addAuditTrailDetailsView(returnView, changeRecordFromChangeSet(changeSet), subSection);
    }
}

export const AuditTrailFuneralClaimDetails = withStyles(styles)(auditTrailSimpleView);
