import { Grid, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { Titles } from '../../../../../../codes/titles';
import {
    BindFieldToField,
    Condition,
    False,
    FullWidthDatePicker,
    FullWidthRadioGroup,
    FullWidthSearchSelectField,
    FullWidthTextField,
    RadioOption,
    True,
    WhenFieldChanges,
} from '../../../../../../forms';
import {
    FirstNameMask,
    InitialsMask,
    LastNameMask,
    NumberMask,
} from '../../../../../../forms/input-masks';
import {
    composeValidators,
    requiredValidator,
    rsaIdNumberValidator,
} from '../../../../../../forms/validations';
import { convertToUpperCase, defaultDateOfBirth } from '../../../../../../shared-functions';
import styles from '../../../../../../styles/styles';

import { isAMinor } from '../../../../../../shared-functions';
import { GuardianDetailsEdit } from './edit-guardian-details';
import { NaturalRelationships } from '../../../../../../codes/relationship-types';

interface Props {
    beneficiary?: boolean;
    prefix: string;
}
const personalDetailsEdit = ({ beneficiary, prefix }: Props) => {
    const fullPrefix = !!prefix && prefix !== '' ? `${prefix}.` : '';
    return (
        <Grid container spacing={5} justify="flex-start">
            {beneficiary && (
                <Grid item xs={6}>
                    <FullWidthRadioGroup
                        name={`${fullPrefix}beneficiary`}
                        label="Beneficiary on record?"
                        validate={requiredValidator}
                    >
                        <RadioOption value="Yes" />
                        <RadioOption value="No" />
                    </FullWidthRadioGroup>
                </Grid>
            )}
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    name={`${fullPrefix}relationshipToLifeAssured`}
                    options={NaturalRelationships}
                    validate={requiredValidator}
                />
                <WhenFieldChanges
                    field={`${fullPrefix}relationshipToLifeAssured`}
                    set={`${fullPrefix}relationshipToLifeAssuredDescription`}
                    to={null}
                />
            </Grid>
            <Condition when={`${fullPrefix}relationshipToLifeAssured`} is="Other">
                <True>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            name={`${fullPrefix}relationshipToLifeAssuredDescription`}
                            validate={requiredValidator}
                        />
                    </Grid>
                </True>
            </Condition>
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    name={`${fullPrefix}personalDetails.title`}
                    options={Titles}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${fullPrefix}personalDetails.initials`}
                    mask={InitialsMask}
                    parse={convertToUpperCase}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${fullPrefix}personalDetails.firstName`}
                    validate={requiredValidator}
                    mask={FirstNameMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${fullPrefix}personalDetails.secondName`}
                    mask={FirstNameMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${fullPrefix}personalDetails.lastName`}
                    label="Surname"
                    validate={requiredValidator}
                    mask={LastNameMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${fullPrefix}personalDetails.idNumber`}
                    validate={composeValidators(requiredValidator, rsaIdNumberValidator)}
                    mask={NumberMask}
                />
                <WhenFieldChanges
                    field={`${fullPrefix}personalDetails.idNumber`}
                    set={`${fullPrefix}personalDetails.idType`}
                    to="RsaId"
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthDatePicker
                    name={`${fullPrefix}personalDetails.dateOfBirth`}
                    validate={requiredValidator}
                    disableFuture
                />
                <Condition when={`${fullPrefix}personalDetails.dateOfBirth`} hasValue>
                    <False>
                        <BindFieldToField
                            field={`${fullPrefix}personalDetails.dateOfBirth`}
                            toField={`${fullPrefix}personalDetails.idNumber`}
                            transform={defaultDateOfBirth}
                        />
                    </False>
                </Condition>
                <WhenFieldChanges
                    field={`${fullPrefix}personalDetails.dateOfBirth`}
                    condition={(dob: Date) => !isAMinor(dob)}
                    set={`${prefix}.guardianDetails`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${fullPrefix}personalDetails.dateOfBirth`}
                    condition={(dob: Date) => isAMinor(dob)}
                    set={`${fullPrefix}employed`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${fullPrefix}personalDetails.dateOfBirth`}
                    condition={(dob: Date) => isAMinor(dob)}
                    set={`${fullPrefix}employer`}
                    to={null}
                />
            </Grid>
            <Condition
                when={`${fullPrefix}personalDetails.dateOfBirth`}
                passes={dob => {
                    return isAMinor(dob);
                }}
            >
                <False>
                    <Grid item xs={6}>
                        <FullWidthRadioGroup
                            name={`${fullPrefix}employed`}
                            validate={requiredValidator}
                        >
                            <RadioOption value="Yes" />
                            <RadioOption value="No" />
                        </FullWidthRadioGroup>
                        <WhenFieldChanges
                            field={`${fullPrefix}employed`}
                            becomes="No"
                            set={`${fullPrefix}employer`}
                            to={null}
                        />
                    </Grid>
                    <Condition when={`${fullPrefix}employed`} is="Yes">
                        <True>
                            <Grid item xs={6}>
                                <FullWidthTextField
                                    name={`${fullPrefix}employer`}
                                    validate={requiredValidator}
                                    mask={LastNameMask}
                                />
                            </Grid>
                        </True>
                    </Condition>
                </False>
                <True>
                    <GuardianDetailsEdit prefix={prefix} />
                </True>
            </Condition>
        </Grid>
    );
};

export const PersonalDetailsEdit = withStyles(styles)(personalDetailsEdit);
