import React from 'react'; // eslint-disable-line

import { Button, Grid, Tooltip } from '@material-ui/core';
import { Delete, AddCircle } from '@material-ui/icons';
import { connect } from 'react-redux';
import {
    FullWidthDatePicker,
    FullWidthRadioGroup,
    RadioOption,
    WhenFieldChanges,
    Condition,
    True,
    ButtonWrapper,
} from '../../../../../../forms';
import {
    requiredValidator,
    ValidationResult,
    UserInput,
    composeValidators,
} from '../../../../../../forms/validations';
import { State } from '../../../../../../redux/root-reducer';
import { PreviousMarriage, getRADeathLifeAssured } from '../../reducer';
import { afterDateOfBirthValidator, beforeDeathValidator } from './personal-details-edit-view';

interface ComponentState {
    previousMarriages: PreviousMarriage[];
}

interface Props extends ComponentState {}

class PreviousMarriagesViewClass extends React.Component<Props, ComponentState> {
    public constructor(props: Props) {
        super(props);
    }

    public componentDidMount() {
        this.setState({
            previousMarriages: this.props.previousMarriages,
        });
    }

    public render() {
        const previousMarriages = this.state
            ? this.state.previousMarriages
            : this.props.previousMarriages;
        const clearState = () => {
            this.setState({
                previousMarriages: [],
            });
        };
        const addEntry = () => {
            previousMarriages.push({});
            this.setState({
                previousMarriages: previousMarriages,
            });
        };
        const deleteEntry = (index: number) => {
            previousMarriages.splice(index, 1);
            this.setState({
                previousMarriages: previousMarriages,
            });
        };
        return (
            <React.Fragment>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={10}>
                            <FullWidthRadioGroup
                                name="previouslyMarried"
                                validate={composeValidators(
                                    requiredValidator,
                                    previousMarriageValidator(
                                        values =>
                                            values.previouslyMarried === 'Yes' &&
                                            (!values.previousMarriages ||
                                                values.previousMarriages.length === 0)
                                    )
                                )}
                            >
                                <RadioOption value="Yes" />
                                <RadioOption value="No" />
                            </FullWidthRadioGroup>
                            <WhenFieldChanges
                                field="previouslyMarried"
                                becomes="No"
                                set="previousMarriages"
                                to={() => {
                                    clearState();
                                    return [];
                                }}
                            />
                        </Grid>
                        <Condition when="previouslyMarried" is="Yes">
                            <True>
                                <ButtonWrapper>
                                    <Grid item xs={1}>
                                        <Tooltip
                                            title="Add Previous Marriage"
                                            style={{ marginTop: '30px' }}
                                        >
                                            <Button
                                                id={'Add_Previous_Marriage'}
                                                color="secondary"
                                                onClick={() => addEntry()}
                                            >
                                                <AddCircle />
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                </ButtonWrapper>
                            </True>
                        </Condition>
                    </Grid>
                </Grid>
                <Condition when="previouslyMarried" is="Yes">
                    <True>
                        {previousMarriages.map((pm, index) => {
                            return (
                                <Grid item container spacing={5} key={`pm_${index}`}>
                                    <Grid item xs={6}>
                                        <FullWidthDatePicker
                                            name={`previousMarriages[${index}].dateOfMarriage`}
                                            label="Date of previous marriage"
                                            validate={composeValidators(
                                                requiredValidator,
                                                dateOfPreviousMarriageValidator(
                                                    (values: any) => values
                                                ),
                                                afterDateOfBirthValidator(
                                                    values =>
                                                        values.personalDetails.dateOfMarriage <
                                                        values.personalDetails.dateOfBirth
                                                ),
                                                beforeDeathValidator(
                                                    values =>
                                                        values.personalDetails.dateOfMarriage >
                                                        values.personalDetails.dateOfDeath
                                                )
                                            )}
                                            disableFuture
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <FullWidthDatePicker
                                            name={`previousMarriages[${index}].dateOfDivorce`}
                                            validate={composeValidators(
                                                requiredValidator,
                                                dateOfPreviousDivorceValidator(
                                                    (values: any) => values
                                                )
                                            )}
                                            minDateField={`previousMarriages[${index}].dateOfMarriage`}
                                            disableFuture
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <ButtonWrapper>
                                            <Tooltip
                                                title="Delete previous marriage"
                                                style={{
                                                    marginTop: '30px',
                                                    marginLeft: '-30px',
                                                }}
                                            >
                                                <Button
                                                    id={`Delete_previous_marriage_${index}`}
                                                    color="secondary"
                                                    onClick={() => deleteEntry(index)}
                                                >
                                                    <Delete />
                                                </Button>
                                            </Tooltip>
                                        </ButtonWrapper>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </True>
                </Condition>
            </React.Fragment>
        );
    }
}

const previousMarriageValidator = (condition: (values: any) => boolean) => {
    return function validate(value: UserInput, values: any): ValidationResult {
        if (condition(values)) {
            return 'At least one previous marriage must be added';
        }
        return undefined;
    };
};

const dateOfPreviousMarriageValidator = (values: any) => {
    return function validate(value: UserInput, values: any): ValidationResult {
        if (value && values.personalDetails && values.personalDetails.dateOfMarriage) {
            if (value >= values.personalDetails.dateOfMarriage) {
                return 'Date of previous marriage must be before current marriage';
            }
        }
        return undefined;
    };
};

const dateOfPreviousDivorceValidator = (values: any) => {
    return function validate(value: UserInput, values: any): ValidationResult {
        if (value && values.personalDetails && values.personalDetails.dateOfMarriage) {
            if (value >= values.personalDetails.dateOfMarriage) {
                return 'Date of divorce must be before current marriage';
            }
        }
        return undefined;
    };
};

const mapStateToProps = (state: State) => ({
    previousMarriages: getRADeathLifeAssured(state).previousMarriages || [],
});

export const PreviousMarriagesView = connect(mapStateToProps, {})(PreviousMarriagesViewClass);
