import { WorkflowStep } from '../../workflow-navigation/reducers/workflow-step';
import { ScreenGuard, ScreenIndex } from './screen-index';
import { ClaimWorkflow } from './claim-workflow';
import { DashboardFlowView } from '../../assesor-dashboard/views/dashboard/assessor-dashboard';
import { WorkflowAssessorScreen } from '../../workflow-navigation/reducers/workflow-assessor';
import { AuthGuard } from '../../auth/authGuard';

const assessorDashboardGuard = AuthGuard(['AssessorDashboard']);

export const initiateClaimWorkflow = new ClaimWorkflow(
    '',
    [
        new WorkflowStep('Initiate Claim', [ScreenIndex.InitiateClaim.ClaimProcess]),
        new WorkflowStep('Assessment', []),
        new WorkflowAssessorScreen(
            'Assessor List',
            'Assessor limits dashboard',
            DashboardFlowView,
            undefined,
            'Assessor limits dashboard',
            assessorDashboardGuard
        ),
    ],
    _ => true,
    undefined,
    ScreenGuard
);
