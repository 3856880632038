import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect, MapStateToProps } from 'react-redux';
import { FormErrors } from '../../../../forms/form-components/form-submission-error';
import { GenericForm } from '../../../../forms/form-components/generic-form';
import {
    UpdateReferToReinsurance,
    updateReferToReinsuranceAction,
} from '../../../../life-assured-details/actions/update-refer-to-reinsurance';
import {
    getLifeAssuredContractIndex,
    getLifeAssuredDetails,
    LifeAssuredContract,
} from '../../../../life-assured-details/reducer';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { OnSubmitSuccessProps } from '../../../../workflow-navigation/actions/submit-form';
import { ReassuranceReferView } from './reassurance-refer-view';

interface OwnProps {}

interface StateProps {
    contractIndex: number;
    contracts: LifeAssuredContract[];
}

interface DispatchProps {
    updateReassurance: UpdateReferToReinsurance;
}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent & OnSubmitSuccessProps;

const ReassuranceForm = GenericForm<{ contracts: LifeAssuredContract[] }>();
const reassuranceEditModal: React.FunctionComponent<Props> = ({
    classes,
    contracts,
    contractIndex,
    updateReassurance,
    ...rest
}: Props) => {
    const onSubmit = (values: any) => {
        return updateReassurance(values.contracts);
    };

    return (
        <ReassuranceForm
            onSubmit={onSubmit}
            initialValues={{
                contracts,
            }}
            {...rest}
        >
            <ReassuranceReferView
                contract={contracts[contractIndex]}
                contractIndex={contractIndex}
            />
            <FormErrors />
        </ReassuranceForm>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    contractIndex: getLifeAssuredContractIndex(state),
    contracts: getLifeAssuredDetails(state).contracts,
});

const mapDispatchToProps = {
    updateReassurance: updateReferToReinsuranceAction,
};

export const ReassuranceEditModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(reassuranceEditModal));
