import React from 'react'; // eslint-disable-line

import { MenuList } from '@material-ui/core';
import { Nullable } from '../../shared-types';
import { FormSubmissionState } from '../reducers/fom-submission-reducer';
import { WorkflowScreen } from '../reducers/workflow-screen';
import { WorkflowScreenlinkListItem } from './workflow-screen-link-list-item';

interface Props {
    screens: WorkflowScreen[];
    activeScreen: Nullable<WorkflowScreen>;
    formSubmissionState: FormSubmissionState;
}

export const WorkflowSideDrawer: React.FunctionComponent<Props> = ({
    screens,
    activeScreen,
    formSubmissionState,
}: Props) => {
    return (
        <React.Fragment>
            <MenuList dense disablePadding>
                {screens
                    .filter(screen => !screen.canShow || screen.canShow())
                    .map(screen => (
                        <WorkflowScreenlinkListItem
                            key={screen.label}
                            screen={screen}
                            formSubmissionState={formSubmissionState}
                            activeScreen={activeScreen}
                        />
                    ))}
            </MenuList>
        </React.Fragment>
    );
};
