import { Claim, claimSubmit } from '../../claim';
import { dateBefore, isEmpty } from '../../forms/validations';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { DisabilityDetails } from '../reducer';

type SubmitReturn = Promise<any>;
export type Submit = (disabilityDetails: DisabilityDetails) => SubmitReturn;
type SubmitAction = (disabilityDetails: DisabilityDetails) => ReduxThunkAction;
export const submitAction: SubmitAction = (disabilityDetails: DisabilityDetails) => {
    return async (dispatch, getState) => {
        return claimSubmit(
            dispatch,
            getState,
            claim => ({
                ...claim,
                disabilityDetails,
            }),
            validate
        );
    };
};

function validate(claim: Claim) {
    const errors = {} as any;
    if (!('disabilityDetails' in claim) || !claim.disabilityDetails) {
        return errors;
    }

    const details = claim.disabilityDetails;
    const disabilityDetails = {} as any;
    if (details.returnToWork && details.lastDayAtWork) {
        if (dateBefore(details.returnToWork, details.lastDayAtWork)) {
            disabilityDetails.returnToWork = 'Cannot be before last day at work';
            errors.disabilityDetails = disabilityDetails;
        }
    }
    if (!isEmpty(errors)) {
        return {
            'front-end-errors': errors,
        };
    }

    return errors;
}
