import React from 'react'; // eslint-disable-line

import { Grid, Paper, Typography, withStyles, Button, Tooltip } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { OpenModal, openModalAction } from '../../../../../../form-modal/actions';
import { FormModalView } from '../../../../../../form-modal/views/form-modal';
import { State } from '../../../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import {
    UpdateConsultationDate,
    updateMedicalDetailsAction,
} from '../../actions/update-medical-history';
import { MedicalHistoryEdit } from './medical-history-details-edit';
import { MedicalHistoryDoctorsView } from './medical-history-doctors-view';
import { getMedicalDoctorHistoryDetails, MedicalHistory } from '../../reducer';

interface DispatchProps {
    openModal: OpenModal;
    updateMedicalHistoryDetails: UpdateConsultationDate;
}
interface OwnProps {}
interface StateProps {
    medicalHistory: MedicalHistory;
    isReadOnly: boolean;
}
type Props = DispatchProps & StyledComponent & StateProps;
const medicalHistoryView: React.FunctionComponent<Props> = ({
    classes,
    openModal,
    medicalHistory,
    isReadOnly,
}: Props) => {
    const edit = () => {
        openModal({
            modalContent: <FormModalView formView={MedicalHistoryEdit} />,
        });
    };
    return (
        <React.Fragment>
            <Grid container justify="space-between" className={classes.marginTop2}>
                <Grid container item xs={10} alignItems="center">
                    <Typography variant="subtitle2">Medical history</Typography>
                </Grid>
                {!isReadOnly && (
                    <Grid container item xs={2} alignItems="center" justify="flex-end">
                        <Tooltip title="Doctors Details">
                            <Button id="EditDoctorsDetails" color="secondary" onClick={edit}>
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                )}
            </Grid>
            <Paper className={classes.paper}>
                <Grid container justify="space-between">
                    <Grid item xs={6}>
                        <Typography variant="subtitle2">
                            When did the deceased first consult a doctor for his/her last illness?
                        </Typography>
                        <Typography>
                            {getDisplayDate(medicalHistory.firstConsultationDate)}
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
            <MedicalHistoryDoctorsView />
        </React.Fragment>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    medicalHistory: getMedicalDoctorHistoryDetails(state) || {},
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    openModal: options => dispatch(openModalAction(options)),
    updateMedicalHistoryDetails: options => dispatch(updateMedicalDetailsAction(options)),
});

export const MedicalHistoryView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(medicalHistoryView));
