import React from 'react'; // eslint-disable-line

import { Button, Tooltip, withStyles } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Section } from '../../../../../../controls/section';
import { AddFormModal } from '../../../../../../form-modal';
import { OpenAddModal, openAddModalAction } from '../../../../../../form-modal/actions';
import { State } from '../../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import {
    AddAndSubmitUnderwritingNonDisclosure,
    addAndSubmitUnderwritingNonDisclosureThunk,
} from './actions/submit-underwriting-non-disclosure';
import { UnderwritingNonDisclosureForm } from './underwriting-non-disclosure-form';
import { UnderwritingNonDisclosure } from './underwriting-non-disclosure-model';
import { UnderwritingNonDisclosureView } from './underwriting-non-disclosure-view';
import { ButtonWrapper } from '../../../../../../forms';

interface OwnProps {
    policyIndex: number;
    nonDisclosures: UnderwritingNonDisclosure[];
    isUnderWriting?: boolean;
}

interface StateProps {
    isReadOnly: boolean;
}

interface DispatchProps {
    addAndSubmitUnderwritingNonDisclosure: AddAndSubmitUnderwritingNonDisclosure;
    openAddModal: OpenAddModal;
}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const underwritingNonDisclosuresView: React.FunctionComponent<Props> = ({
    isReadOnly,
    policyIndex,
    nonDisclosures,
    isUnderWriting,
    addAndSubmitUnderwritingNonDisclosure,
    openAddModal,
}: Props) => {
    return (
        <Section
            title="Non-Disclosures"
            noChildrenTitle="No Non-Disclosures"
            gutterElement={
                isReadOnly
                    ? undefined
                    : addNonDisclosureButton(
                          policyIndex,
                          addAndSubmitUnderwritingNonDisclosure,
                          openAddModal,
                          isUnderWriting
                      )
            }
        >
            {nonDisclosures.map((nonDisclosure, nonDisclosureIndex) => (
                <UnderwritingNonDisclosureView
                    policyIndex={policyIndex}
                    nonDisclosures={nonDisclosures}
                    nonDisclosureIndex={nonDisclosureIndex}
                    nonDisclosure={nonDisclosure}
                    isUnderWriting={isUnderWriting}
                    key={`non-disclosure-${nonDisclosureIndex}}`}
                />
            ))}
        </Section>
    );
};

const UnderwritingNonDisclosureAddFormModal = AddFormModal<UnderwritingNonDisclosure>();
const addNonDisclosureButton = (
    policyIndex: number,
    addAndSubmitUnderwritingNonDisclosure: AddAndSubmitUnderwritingNonDisclosure,
    openAddModal: OpenAddModal,
    isUnderWriting: any
) => {
    const openAddView = () => {
        openAddModal({
            modalContent: (
                <UnderwritingNonDisclosureAddFormModal
                    recordDetailView={<UnderwritingNonDisclosureForm />}
                    addRecord={nonDisclosure =>
                        addAndSubmitUnderwritingNonDisclosure(policyIndex, nonDisclosure)
                    }
                />
            ),
            modalData: {},
        });
    };

    return (
        <ButtonWrapper>
            <Tooltip title="Add Non-Disclosure">
                <Button
                    disabled={isUnderWriting}
                    id="AddNonDisclosure"
                    color="secondary"
                    onClick={openAddView}
                >
                    <AddCircle />
                </Button>
            </Tooltip>
        </ButtonWrapper>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    addAndSubmitUnderwritingNonDisclosure: (
        policyIndex: number,
        nonDisclosure: UnderwritingNonDisclosure
    ) => dispatch(addAndSubmitUnderwritingNonDisclosureThunk(policyIndex, nonDisclosure)),
    openAddModal: options => dispatch(openAddModalAction(options)),
});

export const UnderwritingNonDisclosuresView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(underwritingNonDisclosuresView));
