import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import {
    changeRecordFromAddChangeSet,
    changeRecordFromChangeSet,
} from '../claim-audit-trail-functions';
import { mapSectionView } from '../common-views/claim-audit-trail-wrapper';
import { addAuditTrailDetailsView } from '../common-views/claim-audit-trail-field-details';

interface Props {
    changeSet: Record<string, any>;
}

const auditTrailRaEvenDetailsView: React.FunctionComponent<Props> = ({ changeSet }: Props) => {
    const returnView: React.ReactNode[] = [];
    if (changeSet) {
        if (changeSet.hasOwnProperty('vopd')) {
            const sectionName = ['Claim Details - VOPD'];
            const vopdChange = changeSet['vopd'];
            if (vopdChange) {
                if (Array.isArray(vopdChange) && vopdChange[0] === null) {
                    addAuditTrailDetailsView(
                        returnView,
                        changeRecordFromAddChangeSet(vopdChange[1]),
                        sectionName
                    );
                } else {
                    addAuditTrailDetailsView(
                        returnView,
                        changeRecordFromChangeSet(vopdChange),
                        sectionName
                    );
                }
            }
        }

        if (changeSet.hasOwnProperty('claimEventDetails')) {
            addAuditTrailDetailsView(
                returnView,
                changeRecordFromChangeSet(changeSet['claimEventDetails']),
                ['Claim Details']
            );
        } else if (changeSet[1] !== undefined && changeSet[1].hasOwnProperty('claimEventDetails')) {
            addAuditTrailDetailsView(
                returnView,
                changeRecordFromAddChangeSet(changeSet[1]['claimEventDetails']),
                ['Claim Details']
            );
        }
        if (changeSet.hasOwnProperty('financialDetails')) {
            addAuditTrailDetailsView(
                returnView,
                changeRecordFromChangeSet(changeSet['financialDetails']),
                ['Financial Details']
            );
        }
        if (changeSet.hasOwnProperty('claimNotes')) {
            for (const index in changeSet['claimNotes']) {
                if (Array.isArray(changeSet['claimNotes'][index])) {
                    const notes = changeSet['claimNotes'][index][0];
                    addAuditTrailDetailsView(returnView, changeRecordFromAddChangeSet(notes), [
                        'Claim Notes',
                    ]);
                }
            }
        }
    }

    return mapSectionView(returnView);
};

export const AuditTrailRaEventDetailsView = withStyles(styles)(auditTrailRaEvenDetailsView);
