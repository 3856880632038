import React from 'react'; // eslint-disable-line
import { ModalViewProps, MultiEditFormModal } from '../../../../../form-modal';
import { OpenModal } from '../../../../../form-modal/actions';
import { BankingDetailsEdit } from '../../../common/banking-details/views/edit-banking-details';
import { UpdateFormDetails } from '../actions/update-form-details';
import { FormDetails } from '../form-details-reducer';
import { AddressDetailsEdit } from './common/edit-address-details';
import { ContactDetailsEdit } from './common/edit-contact-details';
import { PersonalDetailsEdit } from './common/edit-personal-details';

interface Props {
    beneficiary?: boolean;
    formDetails: FormDetails;
    heading: string;
    index?: number;
    openModal: OpenModal;
    prefix: string;
    updateFormDetails: UpdateFormDetails;
}

const EditModalForm = MultiEditFormModal<FormDetails>();
export function editFormDetails({
    beneficiary,
    formDetails,
    heading,
    index,
    openModal,
    prefix,
    updateFormDetails,
}: Props) {
    const EditModalViews: ModalViewProps[] = [
        {
            name: 'Personal Details',
            view: <PersonalDetailsEdit prefix={prefix} beneficiary={beneficiary} />,
        },
        { name: 'Contact Details', view: <ContactDetailsEdit prefix={prefix} /> },
        { name: "Claimant's Address", view: <AddressDetailsEdit prefix={prefix} /> },
        {
            name: 'Payee Details',
            view: <BankingDetailsEdit propertyPrefix={`${prefix}.bankingDetails`} />,
        },
    ];
    openModal({
        modalContent: (
            <EditModalForm
                heading={`Update Form Details | ${heading}`}
                modalViews={EditModalViews}
                saveAction={updateFormDetails}
            />
        ),
        modalData: {
            screenIndex: index || 0,
            initialValues: formDetails,
        },
    });
}
