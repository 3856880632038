import { WorkflowScreen } from './workflow-screen';
import { WorkflowScreenContainer } from './workflow-screen-container';
import { WorkflowStep } from './workflow-step';
import { WorkflowUtilityScreen } from './workflow-utility-screen';
import { WorkflowAssessorScreen } from './workflow-assessor';

export class Workflow extends WorkflowScreenContainer {
    public constructor(
        label: string,
        children: (WorkflowStep | WorkflowUtilityScreen | WorkflowAssessorScreen)[],
        screenHeading?: string,
        canShow?: () => boolean
    ) {
        super(label, children, undefined, screenHeading, canShow);
    }

    public cloneWithNewParent(parent?: WorkflowScreen) {
        return new Workflow(
            this.label,
            this.children() as (WorkflowStep | WorkflowUtilityScreen | WorkflowAssessorScreen)[],
            this.screenHeading,
            this.canShow
        );
    }

    public steps = () => this.children().filter(child => child instanceof WorkflowStep);
    public utilityScreens = () =>
        this.children().filter(child => child instanceof WorkflowUtilityScreen);
    public assessorScreens = () =>
        this.children().filter(child => child instanceof WorkflowAssessorScreen);
}
