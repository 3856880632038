import { ExternalPolicy } from '../../reducer';
import { ReduxThunkAction } from '../../../../../../redux/redux-actions';
import { reduceAndSubmitClaim } from '../../../../../../claim/actions/reduce-and-submit-claim';
import { AddRecordCollectionAction } from '../../../../../../redux/collection-reducer';
import { ADD_EXTERNAL_POLICY } from '../../types';

type SubmitReturn = Promise<any>;
export type AddExternalPoliciesRecord = (externalPolicies: ExternalPolicy) => SubmitReturn;
type AddExternalPoliciesRecordThunk = (
    externalPolicies: ExternalPolicy
) => ReduxThunkAction<SubmitReturn>;

export const addExternalPoliciesRecordThunk: AddExternalPoliciesRecordThunk = (
    externalPolicies: ExternalPolicy
) => (dispatch, getState) =>
    reduceAndSubmitClaim(dispatch, getState, addExternalPoliciesActionCreator(externalPolicies));

export type AddExternalPoliciesRecordAction = AddRecordCollectionAction<ExternalPolicy>;

export const addExternalPoliciesActionCreator = (
    externalPolicies: ExternalPolicy
): AddExternalPoliciesRecordAction => {
    return {
        type: ADD_EXTERNAL_POLICY,
        record: externalPolicies,
    };
};
