import React from 'react'; // eslint-disable-line

import { Button, Divider, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { OpenDialog, openDialogAction } from '../../../../../confirmation-dialog';
import { FinancialAdviserContactDetails } from '../../../../../contact-details/financial-adviser/financial-adviser-contact-details';
import { Section } from '../../../../../controls/section';
import { EditFormModal } from '../../../../../form-modal';
import { OpenEditModal, openEditModalAction } from '../../../../../form-modal/actions';
import { State } from '../../../../../redux/root-reducer';
import { getFullName, getCodeLabel } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import {
    Claimant,
    ClaimantDetails,
    getDeathClaimContactDetails,
    OrganisationTypeSuffixes,
    OrganisationDetails,
    GuardianDetails,
} from '../../contact-details/contact-details-reducer';
import { ClaimantContactDetailsEditView } from './claimant-contact-details-edit';
import { LabeledValue } from '../../../../../controls/labeled-value';
import {
    DeleteAndSubmitClaimantRecord,
    deleteAndSubmitClaimantRecordThunk,
    UpdateAndSubmitClaimantRecord,
    updateAndSubmitClaimantRecordThunk,
} from '../actions/submit-details';
import { EntityType } from '../../../../../shared-types';
import { ViewBankingDetails } from '../../../common/banking-details/views/view-banking-details';
import { DisplayAddress } from '../../../../../forms/sub-forms/address-details';
import { ButtonWrapper } from '../../../../../forms';

interface OwnProps {}

interface StateProps {
    readonly claimants: Claimant[];
    readonly isReadOnly: boolean;
}

interface DispatchProps {
    deleteClaimant: DeleteAndSubmitClaimantRecord;
    openDialog: OpenDialog;
    openEditModal: OpenEditModal;
    updateClaimant: UpdateAndSubmitClaimantRecord;
}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const ClaimantEditFormModal = EditFormModal<Claimant>();
const claimantContactDetailsView: React.FunctionComponent<Props> = ({
    claimants,
    classes,
    deleteClaimant,
    openDialog,
    openEditModal,
    updateClaimant,
}: Props) => {
    if (claimants.length === 0) {
        return null;
    }

    return (
        <Section title={`Claimant's Details`}>
            {claimants.map((claimant, index) => {
                const claimantDetails = claimant.claimantDetails as ClaimantDetails;
                const financialAdviser = claimant.financialAdviser;
                const guardianDetails = claimantDetails.guardianDetails as GuardianDetails;

                return (
                    <React.Fragment key={`claimant-${index}`}>
                        {index > 0 ? (
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        ) : (
                            ''
                        )}
                        <Grid item xs={12} container spacing={0} justify="space-between">
                            <Grid item xs={8} lg={10}>
                                <Typography variant="h5">
                                    Claimant {index + 1} |{' '}
                                    {claimantDetails.claimantType === EntityType.Individual ||
                                    claimantDetails.claimantType === EntityType.Executor
                                        ? getFullName(claimantDetails.personalDetails)
                                        : getOrganisationName(
                                              claimantDetails.organisationDetails
                                          )}{' '}
                                    {claimantDetails.claimantIsAMinor && '(Minor)'}
                                </Typography>
                            </Grid>
                            <ButtonWrapper>
                                <Grid item xs={4} lg={2} className={classes.rightAlign}>
                                    <Tooltip title="Edit Claimant Details">
                                        <Button
                                            id="EditClaimantDetails"
                                            color="secondary"
                                            onClick={() => editClaimant(index)}
                                        >
                                            <Edit fontSize="small" />
                                        </Button>
                                    </Tooltip>
                                    {deleteClaimantButton(
                                        index,
                                        deleteClaimant,
                                        openDialog,
                                        claimantDetails.deletable
                                    )}
                                </Grid>
                            </ButtonWrapper>
                        </Grid>
                        {claimantDetails.claimantIsAMinor && (
                            <Grid item xs={12} container justify="flex-start">
                                <Grid item xs={4} lg={2}>
                                    <LabeledValue
                                        label="Guardian Name"
                                        value={guardianDetails.firstName}
                                    />
                                </Grid>
                                <Grid item xs={4} lg={2}>
                                    <LabeledValue
                                        label="Surname"
                                        value={guardianDetails.lastName}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={4} lg={2}>
                            <LabeledValue
                                label="Telephone number"
                                value={
                                    claimantDetails.communicationDetails
                                        ? claimantDetails.communicationDetails.telephoneNumber
                                        : ''
                                }
                            />
                        </Grid>
                        <Grid item xs={4} lg={2}>
                            <LabeledValue
                                label="Cellphone number"
                                value={
                                    claimantDetails.communicationDetails
                                        ? claimantDetails.communicationDetails.cellphoneNumber
                                        : ''
                                }
                            />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                            <LabeledValue
                                label="Email address"
                                value={
                                    claimantDetails.communicationDetails
                                        ? claimantDetails.communicationDetails.emailAddress
                                        : ''
                                }
                            />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                            <DisplayAddress
                                label="Address"
                                address={
                                    claimantDetails.claimantType === 'Organisation'
                                        ? claimantDetails.organisationDetails &&
                                          claimantDetails.organisationDetails.address
                                        : claimantDetails.postalAddress
                                }
                            />
                        </Grid>
                        {claimantDetails.bankingDetails && (
                            <ViewBankingDetails
                                bankingDetails={claimantDetails.bankingDetails}
                                beneficiaryId={claimant.id}
                            />
                        )}
                        {financialAdviser && (
                            <FinancialAdviserView
                                classes={classes}
                                financialAdviser={financialAdviser}
                            />
                        )}
                    </React.Fragment>
                );
            })}
        </Section>
    );

    function editClaimant(index: number) {
        const recordIndex = index || 0;
        openEditModal({
            modalContent: (
                <ClaimantEditFormModal
                    recordDetailView={<ClaimantContactDetailsEditView isEdit />}
                    records={claimants}
                    updateRecord={(record, index) => updateClaimant(index, record)}
                />
            ),
            modalData: {
                recordIndex,
            },
        });
    }
};

interface AdviserProps extends StyledComponent {
    financialAdviser: FinancialAdviserContactDetails;
}
const FinancialAdviserView: React.FunctionComponent<AdviserProps> = ({
    classes,
    financialAdviser,
}: AdviserProps) => {
    return (
        <React.Fragment>
            <Grid item xs={12} className={classes.marginTop}>
                <Typography variant="subtitle2">{`Claimant's Financial Adviser`}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5">
                    {financialAdviser.companyName
                        ? financialAdviser.companyName
                        : getFullName(financialAdviser)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container justify="flex-start">
                    <Grid item xs={4} lg={2}>
                        <LabeledValue
                            label="Consultant code"
                            value={financialAdviser.consultantCode}
                        />
                    </Grid>
                    <Grid item xs={4} lg={2}>
                        <LabeledValue
                            label="Telephone number"
                            value={financialAdviser.telephoneNumber}
                        />
                    </Grid>
                    <Grid item xs={4} lg={2}>
                        <LabeledValue
                            label="Cellphone number"
                            value={financialAdviser.cellphoneNumber}
                        />
                    </Grid>
                    <Grid item xs={4} lg={2}>
                        <LabeledValue label="Email address" value={financialAdviser.emailAddress} />
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const deleteClaimantButton = (
    index: number,
    deleteClaimant: DeleteAndSubmitClaimantRecord,
    openDialog: OpenDialog,
    deletable?: boolean
) => {
    const confirmDelete = () => {
        openDialog({
            content: {
                title: 'Delete Claimant',
                body: 'Are you sure you would like to delete the selected Claimant?',
            },
            yesAction: {
                action: async () => {
                    await deleteClaimant(index);
                },
            },
        });
    };

    return (
        <Tooltip title="Delete Claimant Details">
            <Button
                id="DeleteClaimantDetails"
                color="secondary"
                onClick={confirmDelete}
                disabled={!deletable}
            >
                <Delete />
            </Button>
        </Tooltip>
    );
};

export function getOrganisationName(organisation: OrganisationDetails = {}) {
    return `${organisation.companyName} ${getCodeLabel(
        OrganisationTypeSuffixes,
        organisation.organisationSuffix
    )}`;
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    claimants: getDeathClaimContactDetails(state).claimants,
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    deleteClaimant: (index: number) => dispatch(deleteAndSubmitClaimantRecordThunk(index)),
    openDialog: options => dispatch(openDialogAction(options)),
    openEditModal: options => dispatch(openEditModalAction(options)),
    updateClaimant: (index: number, claimant: Claimant) =>
        dispatch(updateAndSubmitClaimantRecordThunk(index, claimant)),
});

export const ClaimantContactDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(claimantContactDetailsView));
