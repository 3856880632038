export const avocations = [
    { value: 'Aerial Photography', label: 'Aerial Photography' },
    { value: 'Aviation', label: 'Aviation' },
    { value: 'Ballooning', label: 'Ballooning' },
    { value: 'Gliding', label: 'Gliding' },
    { value: 'Gyrocopter', label: 'Gyrocopter' },
    { value: 'Hang Gliding', label: 'Hang Gliding' },
    { value: 'Microlight', label: 'Microlight' },
    { value: 'Paragliding', label: 'Paragliding' },
    { value: 'Private Passenger', label: 'Private Passenger' },
    { value: 'Big Game Hunting', label: 'Big Game Hunting' },
    { value: 'Boarding', label: 'Boarding' },
    { value: 'Boxing', label: 'Boxing' },
    { value: 'Bungee Diving', label: 'Bungee Diving' },
    { value: 'Canoeing', label: 'Canoeing' },
    { value: 'Caving', label: 'Caving' },
    { value: 'Cycle Racing', label: 'Cycle Racing' },
    { value: 'Diving', label: 'Diving' },
    { value: 'Ice Hockey', label: 'Ice Hockey' },
    { value: 'Ice Skating', label: 'Ice Skating' },
    { value: 'Kiting', label: 'Kiting' },
    { value: 'Martial Arts', label: 'Martial Arts' },
    { value: 'Motor Sport Marshal', label: 'Motor Sport Marshal' },
    { value: 'Motor Sport Racing', label: 'Motor Sport Racing' },
    { value: 'Motorcycle Racing', label: 'Motorcycle Racing' },
    { value: 'Motorcycling', label: 'Motorcycling' },
    { value: 'Mountaineering', label: 'Mountaineering' },
    { value: 'Not Known Refer', label: 'Not Known Refer' },
    { value: 'Parachuting', label: 'Parachuting' },
    { value: 'Powerboat Racing', label: 'Powerboat Racing' },
    { value: 'Quad Biking', label: 'Quad Biking' },
    { value: 'Rugby', label: 'Rugby' },
    { value: 'Skiing', label: 'Skiing' },
    { value: 'Sky Surfing', label: 'Sky Surfing' },
    { value: 'Small Game Hunting', label: 'Small Game Hunting' },
    { value: 'Standard All Policy Classes', label: 'Standard All Policy Classes' },
    { value: 'Unrecognised', label: 'Unrecognised' },
    { value: 'Weightlifting', label: 'Weightlifting' },
    { value: 'White Water Canoeing', label: 'White Water Canoeing' },
    { value: 'Wrestling', label: 'Wrestling' },
    { value: 'yachting', label: 'Yachting' },
];
