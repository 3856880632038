import { combineReducersWithDefault } from '../../../redux/combine-reducers-with-default';
import { BaseClaim, baseClaimReducers, initialBaseClaim } from './base-claim-reducer';
import { getClaim, getPropertyFromClaim } from '..';
import { State } from '../../../redux/root-reducer';
import {
    RADeathLifeAssured,
    defaultRADeathLifeAssured,
    raDeathLifeAssuredDetailsReducer,
} from '../../../assessment/synopsis/ra-death/life-assured/reducer';
import { Nullable } from '../../../shared-types';
import {
    defaultRAClaimDetails,
    RAClaimDetails,
    raClaimDetailsReducer,
} from '../../../assessment/synopsis/ra-death/claim-details/reducer';
import { disputesReducer, Dispute } from '../../../assessment/synopsis/ra-death/disputes/reducer';
import {
    Dependant,
    dependantReducer,
} from '../../../assessment/synopsis/ra-death/dependants/reducer';
import {
    LiquidAndDistribution,
    liquidAndDistributionReducer,
} from '../../../assessment/synopsis/ra-death/liquid-and-distribution/liquid-and-distribution-reducer';
import {
    Assessment,
    defaultRaDeathAssessmentDetails,
    raDeathAssessmentDetailsReducer,
} from '../../../assessment/decisions/ra-death/ra-death-assessment-details-reducer';
import {
    RADeathRequirements,
    raDeathRequirementsReducer,
    defaultRADeathRequirements,
} from '../../../assessment/synopsis/ra-death/manage-requirements/reducer';

export interface RADeathClaim extends BaseClaim {
    readonly assessmentDetails: Assessment;
    readonly claimDetails: RAClaimDetails;
    readonly disputes: Dispute[];
    readonly dependants: Dependant[];
    readonly lifeAssuredDetails: Nullable<RADeathLifeAssured>;
    readonly liquidAndDistribution: LiquidAndDistribution[];
    readonly requirements: RADeathRequirements;
}
export const initialRADeathClaim: RADeathClaim = {
    ...initialBaseClaim,
    assessmentDetails: defaultRaDeathAssessmentDetails,
    claimDetails: defaultRAClaimDetails,
    disputes: [],
    dependants: [],
    lifeAssuredDetails: defaultRADeathLifeAssured,
    liquidAndDistribution: [],
    requirements: defaultRADeathRequirements,
};

const raDeathClaimReducers = {
    ...baseClaimReducers,
    assessmentDetails: raDeathAssessmentDetailsReducer,
    claimDetails: raClaimDetailsReducer,
    disputes: disputesReducer,
    dependants: dependantReducer,
    lifeAssuredDetails: raDeathLifeAssuredDetailsReducer,
    liquidAndDistribution: liquidAndDistributionReducer,
    requirements: raDeathRequirementsReducer,
};

export const raDeathClaimReducer = combineReducersWithDefault<RADeathClaim>(
    initialRADeathClaim,
    raDeathClaimReducers
);

export function getRADeathClaim(state: State) {
    return getClaim(state) as RADeathClaim;
}

export function getRaDeathClaimDetails(state: State) {
    return getPropertyFromClaim(getClaim(state), 'claimDetails', defaultRAClaimDetails);
}
