import { combineReducers } from 'redux';
import { collectionRecordReducer } from '../../../../../../redux/collection-record-reducer';
import { simplePropertyReducer } from '../../../../../../redux/simple-property-reducer';
import { postUnderwritingChangesReducer } from '../post-underwriting-changes/post-underwriting-changes-reducer';
import { underwritingNonDisclosuresReducer } from '../underwriting-non-disclosures/underwriting-non-disclosures-reducer';
import { underwritingPolicyDetailsReducer } from '../underwriting-policy-details/underwriting-policy-details-reducer';
import { UnderwritingDetails } from './underwriting-details-model';

const underwritingDetailsReducer = combineReducers<UnderwritingDetails>({
    policyDetails: underwritingPolicyDetailsReducer,
    requirements: simplePropertyReducer([]),
    specialOffers: simplePropertyReducer([]),
    benefitExclusions: simplePropertyReducer([]),
    disclosures: simplePropertyReducer([]),
    decisions: simplePropertyReducer([]),
    nonDisclosures: underwritingNonDisclosuresReducer,
    postUnderwritingChanges: postUnderwritingChangesReducer,
});

export const underwritingDetailsCollectionReducer = collectionRecordReducer({
    recordReducer: underwritingDetailsReducer,
});
