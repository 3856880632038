import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { ExpansionPanel } from '../../../../../forms/form-components/expansion-panel/expansion-panel';
import { State } from '../../../../../redux/root-reducer';
import { getObjectWarning } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { ClaimDetails, getClaimDetails } from '../claim-details-reducer';
import { ClaimDetailsView } from './claim-details';
import { CurrentMedicalConsultationView } from '../../medical-consultation/views/current-medical-consulation';
import { PreviousMedicalConsultationView } from '../../medical-consultation/views/previous-medical-consultaion';
import { ClaimNotesView } from '../../medical-consultation/views/claim-notes';

type InnerProps = StyledComponent & {
    readonly causeOfClaimLabel: React.ReactNode;
    readonly claimDetails: ClaimDetails;
    readonly showTreatingSpecialist?: boolean;
};

const claimEventDetailsView: React.FunctionComponent<InnerProps> = ({
    causeOfClaimLabel,
    claimDetails,
    showTreatingSpecialist,
}: InnerProps) => {
    const notifier = getObjectWarning(claimDetails, null);
    return (
        <ExpansionPanel name="Claim Details" notifier={notifier}>
            <ClaimDetailsView
                causeOfClaimLabel={causeOfClaimLabel}
                showTreatingSpecialist={showTreatingSpecialist}
            />
            <CurrentMedicalConsultationView />
            <PreviousMedicalConsultationView />
            <ClaimNotesView />
        </ExpansionPanel>
    );
};

const mapStateToProps = (state: State) => ({ claimDetails: getClaimDetails(state) });

const mapDispatchToProps = {};

export const ClaimEventDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(claimEventDetailsView));
