import axios from 'axios';
import { push as setLocation } from 'connected-react-router';
import { env } from '../../env';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { Nullable } from '../../shared-types';
import { ClientSearchCriteria, setSearchCriteriaAction } from '../../initiate-claim';
import { claimUpdatedThunk } from '../';
import { Claim } from '../reducers';
import { setReferenceAction } from './set-reference';
import { setReadOnlyAction } from './set-read-only';
import { setDeferredContractsAction } from './set-deferred-contracts';
import { FuneralClaim } from '../reducers/claim-reducers/funeral-claim-reducer';
import { ClaimType } from '../claim-types';
import containsDeferredContracts from '../../initiate-claim/actions/initiate-claim';
import { openDialogAction } from '../../confirmation-dialog';

const claimsUri = `${env().CLAIMS_API}/api/Claim`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LoadReturn = Promise<any>;
export type LoadClaim = (
    claimId?: string,
    referenceSource?: string,
    referenceId?: string,
    redirectUrl?: string,
    creationDate?: string,
    readOnlyClaim?: boolean
) => LoadReturn;
type LoadClaimThunk = (
    claimId?: string,
    referenceSource?: string,
    referenceId?: string,
    redirectUrl?: string,
    creationDate?: string,
    readOnlyClaim?: boolean
) => ReduxThunkAction;
export const loadClaimAction: LoadClaimThunk = (
    claimId?: string,
    referenceSource?: string,
    referenceId?: string,
    redirectUrl?: string,
    creationDate?: string,
    readOnlyClaim?: boolean
) => {
    return async dispatch => {
        const loadClaimUri = getLoadClaimUri(claimId, referenceSource, referenceId);
        const response = await axios.get<Claim>(loadClaimUri);
        if (response && response.data) {
            const responseClaim: Claim = response.data;

            if (responseClaim && responseClaim.claimType === ClaimType.Funeral) {
                const serialClaim = responseClaim as FuneralClaim;
                const contracts = serialClaim.lifeAssuredDetails?.contracts;

                if (contracts && contracts.length > 0) {
                    let isAllDeferred = true;

                    for (let i = 0; i < contracts.length; i++) {
                        if (!containsDeferredContracts(contracts[i].productName)) {
                            isAllDeferred = false;
                            break;
                        }
                    }

                    if (isAllDeferred) {
                        dispatch(
                            openDialogAction({
                                content: {
                                    title: '',
                                    body:
                                        'There are only FNB policies and the claim needs to be referred to FNB, please notify the claimant to refer the claim to FNB',
                                    error: true,
                                },
                            })
                        );
                    }

                    dispatch(setDeferredContractsAction(isAllDeferred));
                }
            }

            dispatch(claimUpdatedThunk(responseClaim));
            if (responseClaim.claimType === 'Uninitiated') {
                dispatch(setSearchCriteriaAction(getSearchCriteria(responseClaim.forms)));
                dispatch(setLocation('/initiate-claim/client-search'));
            }
        } else if (claimId) {
            throw new Error(`Claim with id ${claimId} not found`);
        } else if (referenceSource && referenceId && creationDate) {
            dispatch(setReferenceAction(referenceSource, referenceId, creationDate));
        }
        if (redirectUrl) {
            dispatch(setLocation(redirectUrl));
        }
        dispatch(setReadOnlyAction(readOnlyClaim));
        return {};
    };
};

function getLoadClaimUri(claimId?: string, referenceSource?: string, referenceId?: string) {
    if (claimId) {
        return `${claimsUri}/${claimId}`;
    }

    if (referenceSource && referenceId) {
        return `${claimsUri}?referenceSource=${referenceSource}&referenceId=${referenceId}`;
    }

    throw new Error('Either Claim ID or both Reference Source or Reference ID are required');
}

function getSearchCriteria(forms: Nullable<string[]>) {
    const searchCriteria: ClientSearchCriteria = {
        searchBy: 'Life Assured',
    };
    if (forms && forms.length > 0) {
        const firstForm = JSON.parse(forms[0]);
        if (firstForm) {
            if (firstForm.claimType === 'RA Death Claims - Retirement Products') {
                searchCriteria.idType =
                    firstForm.deceasedDetails.personalDetails.idType === 'RsaId'
                        ? 'ID Number'
                        : 'Passport Number';
                searchCriteria.idNumber = firstForm.deceasedDetails.personalDetails.idNumber;
                searchCriteria.dateOfBirth = firstForm.deceasedDetails.personalDetails.dateOfBirth;
                searchCriteria.firstName = firstForm.deceasedDetails.personalDetails.firstName;
                searchCriteria.lastName = firstForm.deceasedDetails.personalDetails.lastName;
            } else if (
                firstForm.claimType === 'Disability Claims' ||
                firstForm.claimType === 'Terminal Illness Claims' ||
                firstForm.claimType === 'Critical Illness - Dread Disease Claims' ||
                firstForm.claimType === 'Living Lifestyle Claims'
            ) {
                searchCriteria.idType =
                    firstForm.lifeAssured.PersonalDetails.lifeAssuredIsRSAResident === 'Yes '
                        ? 'ID Number'
                        : 'Passport Number';
                searchCriteria.idNumber = firstForm.lifeAssured.PersonalDetails.lifeAssuredIdNumber;
                searchCriteria.dateOfBirth =
                    firstForm.lifeAssured.PersonalDetails.lifeAssuredDateOfBirth;
                searchCriteria.firstName =
                    firstForm.lifeAssured.PersonalDetails.lifeAssuredFirstName;
                searchCriteria.lastName = firstForm.lifeAssured.PersonalDetails.lifeAssuredSurname;
            } else {
                searchCriteria.idType =
                    firstForm.deceasedDetails.idType === 'RsaId' ? 'ID Number' : 'Passport Number';
                searchCriteria.idNumber = firstForm.deceasedDetails.idNumber;
                searchCriteria.dateOfBirth = firstForm.deceasedDetails.dateOfBirth;
                searchCriteria.firstName = firstForm.deceasedDetails.firstName;
                searchCriteria.lastName = firstForm.deceasedDetails.surname;
            }
        }
    }

    return searchCriteria;
}
