import { claimSubmit, Claim } from '../../../../../claim';
import { addRecordReduction } from '../../../../../redux/reductions';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import {
    getRequirementsFromClaim,
    RequiredDocument,
} from '../../../common/requirements-management/reducer';
import { FuneralRequirements } from '../reducer';

type SubmitReturn = Promise<any>;
export type AddClaimantsRequirementsDocument = (
    document: RequiredDocument,
    index: number
) => SubmitReturn;
type AddClaimentsRequirementsDocumentThunk = (
    document: RequiredDocument,
    index: number
) => ReduxThunkAction;

export const addAndSubmitCliamantsRequirementsAction: AddClaimentsRequirementsDocumentThunk = (
    document: RequiredDocument,
    index: number
) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, getUpdatedClaim(document, index));
    };
};

function getUpdatedClaim(document: RequiredDocument, index: number): (claim: Claim) => Claim {
    return (claim: Claim) => {
        const currentRequirements = getRequirementsFromClaim(claim) as FuneralRequirements;
        if (!currentRequirements.claimants) {
            currentRequirements.claimants = [];
        }
        const currentDocuments: RequiredDocument[] =
            currentRequirements.claimants[index]['documents'];
        const currentDependantRequirements = currentRequirements.claimants;
        currentDependantRequirements[index].documents = addRecordReduction(
            currentDocuments,
            document
        );
        return {
            ...claim,
            requirements: {
                ...currentRequirements,
                claimants: currentDependantRequirements,
            },
        };
    };
}
