import React from 'react'; // eslint-disable-line

import { Button, Grid, Paper, Typography, Tooltip, withStyles } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { v4 as uuid } from 'uuid';
import {
    ButtonWrapper,
    Condition,
    FullWidthSearchSelectField,
    True,
    When,
} from '../../../../../forms';
import { AddFormModal } from '../../../../../form-modal';
import { openAddModalAction, OpenAddModal } from '../../../../../form-modal/actions';
import { State } from '../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../styles/styles';
import {
    AddAndSubmitExecutorRecord,
    AddAndSubmitOtherContactRecord,
    addAndSubmitExecutorRecordThunk,
    addAndSubmitOtherContactRecordThunk,
} from '../actions/submit-details';
import { ContactType, ContactTypes } from '../contact-details-reducer';
import { OtherContactDetailsEditView } from './other-contact-details-edit';

interface OwnProps {}

interface StateProps {
    isReadOnly: boolean;
}

interface DispatchProps {
    addExecutor: AddAndSubmitExecutorRecord;
    addOtherContact: AddAndSubmitOtherContactRecord;
    openAddModal: OpenAddModal;
}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
/* eslint-disable-next-line */
const ContactDetailsAddFormModal = AddFormModal<any>();
const addContactDetailsView: React.FunctionComponent<Props> = ({
    addExecutor,
    addOtherContact,
    classes,
    openAddModal,
}: Props) => {
    return (
        <Grid container justify="flex-start">
            <ButtonWrapper>
                <Grid item xs={12} className={classes.rightAlign}>
                    <Button id="AddDeathContactDetails" onClick={openAddView}>
                        <Tooltip title="Add Contact Details">
                            <AddCircle color="secondary" />
                        </Tooltip>
                    </Button>
                </Grid>
            </ButtonWrapper>
        </Grid>
    );

    function openAddView() {
        openAddModal({
            modalContent: (
                <ContactDetailsAddFormModal
                    blankRecord={{ id: uuid() }}
                    recordDetailView={EditContactView(classes)}
                    addRecord={async values => {
                        if (values.contactType === ContactType.Executor) {
                            await addExecutor(values);
                        }
                        if (values.contactType === ContactType.Other) {
                            await addOtherContact(values);
                        }
                    }}
                />
            ),
            modalData: {},
        });
    }
};

const EditContactView = (classes: Record<string, string>) => {
    return (
        <React.Fragment>
            <Condition
                when="contactType"
                passes={value => {
                    return value !== ContactType.Executor && value !== ContactType.Other;
                }}
            >
                <True>
                    <React.Fragment>
                        <Typography variant="h6">Select Contact Type</Typography>
                        <Paper className={classes.paper}>
                            <Grid container item xs={6} style={{ marginBottom: '150px' }}>
                                <FullWidthSearchSelectField
                                    name="contactType"
                                    options={ContactTypes}
                                />
                            </Grid>
                        </Paper>
                    </React.Fragment>
                </True>
            </Condition>
            <Condition when="contactType" matchesChild>
                <When is={ContactType.Executor}>
                    <OtherContactDetailsEditView contactType={ContactType.Executor} />
                </When>
                <When is={ContactType.Other}>
                    <OtherContactDetailsEditView contactType={ContactType.Other} />
                </When>
            </Condition>
        </React.Fragment>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    addExecutor: record => dispatch(addAndSubmitExecutorRecordThunk(record)),
    addOtherContact: record => dispatch(addAndSubmitOtherContactRecordThunk(record)),
    openAddModal: options => dispatch(openAddModalAction(options)),
});

export const AddContactDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(addContactDetailsView));
