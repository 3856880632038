import { Button, Grid, GridSize, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import React, { useState } from 'react'; // eslint-disable-line
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { OpenDialog, openDialogAction } from '../../../confirmation-dialog';
import { OpenModal, openModalAction } from '../../../form-modal/actions';
import { FormModalView } from '../../../form-modal/views/form-modal';
import cashBlue from '../../../master-layout/views/cash.svg';
import cashGrey from '../../../master-layout/views/cash-grey.svg';
import downarrow from '../../../master-layout/views/downarrow.svg';
import uparrow from '../../../master-layout/views/uparrow.svg';
import { State } from '../../../redux/root-reducer';
import { formatCurrency, formatPercent, getCodeDescription } from '../../../shared-functions';
import styles, { StyledComponent } from '../../../styles/styles';
import { DeletePaymentItem, deletePaymentItemRecordThunk } from '../../actions/delete-payment-item';
import { PayLiability, payLiabilityAction } from '../../actions/pay-liability';
import { PaymentItem } from '../../reducer';
import { PaymentItemEditView } from './edit-payment-item-modal';
import { PaymentItemExpandedView } from './payment-item-expanded-view';
import { getClaim } from '../../../claim';
import { ClaimType } from '../../../claim/claim-types';
import { Nullable } from '../../../shared-types';
import { PaymentIncomePayableView } from './payment-item-income-view';
import { IncomePaymentItemEditView } from './edit-income-payment-item-modal';
import { ButtonWrapper } from '../../../forms';

interface DispatchProps {
    deletePaymentItem: DeletePaymentItem;
    openDialog: OpenDialog;
    openModal: OpenModal;
    payLiability: PayLiability;
}

interface OwnProps {
    beneficiaryId: string;
    itemIndex: number;
    paymentIndex: number;
    paymentItem: PaymentItem;
}

interface StateProps {
    claimType: Nullable<ClaimType>;
    isReadOnly: boolean;
}

interface Props extends StyledComponent, DispatchProps, StateProps, OwnProps {}
const Item: React.FunctionComponent<Props> = ({
    beneficiaryId,
    claimType,
    classes,
    deletePaymentItem,
    itemIndex,
    openDialog,
    openModal,
    payLiability,
    paymentIndex,
    paymentItem,
    isReadOnly,
}: Props) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const toggleState = (evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        setIsExpanded(value => !value);
        evt.preventDefault();
        return false;
    };

    const deleteItem = () => {
        openDialog({
            content: {
                title: 'Delete Payment Record',
                body: 'Are you sure you would like to delete the selected payment item?',
            },
            yesAction: {
                action: () => deletePaymentItem(itemIndex, paymentIndex),
            },
        });
    };

    const editItem = () => {
        openModal({
            modalContent: (
                <FormModalView
                    formView={() => (
                        <EditPaymentView
                            claimType={claimType}
                            paymentIndex={paymentIndex}
                            paymentItemIndex={itemIndex}
                        />
                    )}
                />
            ),
        });
    };

    const pay = () => {
        payLiability({
            paymentId: paymentItem.id || '',
            beneficiaryId,
        });
    };

    const toggleGrid = isReadOnly
        ? { xs: 12 as GridSize, style: { marginTop: 34, marginLeft: -20 } }
        : { xs: 3 as GridSize };

    const pending = ['Pending', 'Failed', 'Rejected'].includes(paymentItem.status);
    return (
        <React.Fragment>
            <Grid container className={classes.resultsTable}>
                <Grid item xs={6} lg={2}>
                    <Typography className={classes.resultsLabel}>Policy Number</Typography>
                    <Typography>{paymentItem.policyNumber}</Typography>
                </Grid>
                <Grid item xs={6} lg={2}>
                    <Typography className={classes.resultsLabel}>
                        {getDescription(paymentItem.benefitCode)}
                    </Typography>
                    <Typography>{paymentItem.benefitName}</Typography>
                </Grid>
                <Grid item xs={6} lg={2}>
                    <Typography className={classes.resultsLabel}>Payee Type</Typography>
                    <Typography>
                        {getCodeDescription('payeeType', paymentItem.payeeType)}
                    </Typography>
                </Grid>
                <Grid item xs={6} lg={1}>
                    <Typography className={classes.resultsLabel}>Split %</Typography>
                    <Typography>{formatPercent((paymentItem.split || 0) / 100)}</Typography>
                </Grid>
                <Grid item xs={6} lg={2}>
                    <Typography className={classes.resultsLabel}>Amount payable</Typography>
                    <Typography>{formatCurrency(paymentItem.amountPayable)}</Typography>
                </Grid>
                <Grid item xs={6} lg={1}>
                    <Typography className={classes.resultsLabel}>Status</Typography>
                    <Typography>{getStatus(paymentItem.status)}</Typography>
                </Grid>
                <Grid item xs={6} lg={2} container justify="flex-start">
                    <ButtonWrapper>
                        <React.Fragment>
                            <Grid item xs={12} container justify="center">
                                <Typography className={classes.resultsLabel}>Actions</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Tooltip title="Delete Payment">
                                    <Button
                                        id="DeletePayment"
                                        color="secondary"
                                        disabled={!pending}
                                        style={{ padding: '0px' }}
                                        onClick={deleteItem}
                                    >
                                        <Delete />
                                    </Button>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={3}>
                                <Tooltip title="Edit Payment">
                                    <Button
                                        id="EditPaymentReceived"
                                        color="secondary"
                                        disabled={!pending}
                                        style={{ padding: '0px' }}
                                        onClick={editItem}
                                    >
                                        <Edit />
                                    </Button>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={3}>
                                <Tooltip title="Submit Payment">
                                    <Button
                                        id="SubmitPayment"
                                        color="secondary"
                                        disabled={!pending}
                                        onClick={pay}
                                    >
                                        <img alt="submit" src={pending ? cashBlue : cashGrey} />
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </React.Fragment>
                    </ButtonWrapper>
                    <Grid item container justify="flex-end" {...toggleGrid}>
                        <Tooltip title="Hide/Show Payment details">
                            <a href="/#" onClick={toggleState}>
                                <img alt="hide-show" src={isExpanded ? uparrow : downarrow} />
                            </a>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <ExpandedView claimType={claimType} expanded={isExpanded} paymentItem={paymentItem} />
        </React.Fragment>
    );
};

interface EditViewProps {
    claimType: Nullable<ClaimType>;
    paymentIndex: number;
    paymentItemIndex: number;
}

const EditPaymentView = ({ claimType, ...rest }: EditViewProps) => {
    if (claimType === ClaimType.IncomeProtector) {
        return <IncomePaymentItemEditView {...rest} />;
    }

    return <PaymentItemEditView {...rest} />;
};

interface ExpandedProps {
    claimType: Nullable<ClaimType>;
    expanded: boolean;
    paymentItem: PaymentItem;
}

const ExpandedView = ({ claimType, expanded, paymentItem }: ExpandedProps) => {
    if (expanded) {
        if (claimType === ClaimType.IncomeProtector) {
            return <PaymentIncomePayableView decisionId={paymentItem.decisionId} />;
        }

        return <PaymentItemExpandedView paymentItem={paymentItem} />;
    }

    return null;
};

function getStatus(status: string) {
    if (status) {
        return status.split(/(?=[A-Z])/).join(' ');
    }
    return status;
}

function getDescription(benefitCode?: string) {
    if (benefitCode && benefitCode !== '') {
        return 'Benefit';
    }
    return 'Product';
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    isReadOnly: state.claimSession.readOnly,
    claimType: getClaim(state).claimType,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    // eslint-disable-next-line
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    openDialog: options => dispatch(openDialogAction(options)),
    openModal: options => dispatch(openModalAction(options)),
    deletePaymentItem: (paymentItemIndex: number, clamantIndex: number) =>
        dispatch(deletePaymentItemRecordThunk(paymentItemIndex, clamantIndex)),
    payLiability: options => dispatch(payLiabilityAction(options)),
});

export const PaymentItemView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Item));
