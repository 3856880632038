import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { DeathRequirements, getDocumentsRequirementsFromClaim } from '../reducer';
import { claimSubmit, Claim } from '../../../../../claim';
import { addRecordReduction } from '../../../../../redux/reductions';
import { RequiredDocument } from '../../../common/requirements-management/reducer';

type SubmitReturn = Promise<any>;
export type AddClaimantDocumentStatusRecord = (
    document: RequiredDocument,
    index: number
) => SubmitReturn;
type AddClaimantDocumentStatusRecordThunk = (
    document: RequiredDocument,
    index: number
) => ReduxThunkAction;

export const addAndSubmitClaimantDocumentAction: AddClaimantDocumentStatusRecordThunk = (
    document: RequiredDocument,
    index: number
) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, getUpdatedClaim(document, index));
    };
};

function getUpdatedClaim(document: RequiredDocument, index: number): (claim: Claim) => Claim {
    return (claim: Claim) => {
        const currentRequirements = (getDocumentsRequirementsFromClaim(claim) ||
            {}) as DeathRequirements;
        if (!currentRequirements.claimants) {
            currentRequirements.claimants = [];
        }
        const currentDocuments: RequiredDocument[] =
            currentRequirements.claimants[index]['documents'];
        const currentClaimantRequirements = currentRequirements.claimants;
        currentClaimantRequirements[index].documents = addRecordReduction(
            currentDocuments,
            document
        );
        return {
            ...claim,
            documentsStatus: {
                ...currentRequirements,
                claimants: currentClaimantRequirements,
            },
        };
    };
}
