import { Claim, claimSubmit } from '../../../../../claim';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { DeathCertificate, getDeathCertification } from '../death-certification-reducer';

type UpdateDeathCertificateReturn = Promise<any>;
export type UpdateDeathCertificate = (
    deathCertificate: DeathCertificate
) => UpdateDeathCertificateReturn;
type UpdateDeathCertificateAction = (
    deathCertificate: DeathCertificate
) => ReduxThunkAction<UpdateDeathCertificateReturn>;

export const updateDeathCertificateAction: UpdateDeathCertificateAction = (
    deathCertificate: DeathCertificate
) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, getUpdatedClaim(deathCertificate));
    };
};

function getUpdatedClaim(deathCertificate: DeathCertificate): (claim: Claim) => Claim {
    return (claim: Claim) => {
        const deathCertification = getDeathCertification(claim);
        return {
            ...claim,
            deathCertification: {
                ...deathCertification,
                deathCertificate: deathCertificate,
            },
        };
    };
}
