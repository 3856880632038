import React from 'react'; // eslint-disable-line

import { Button, Divider, Grid, Paper, Typography, withStyles } from '@material-ui/core';
import { AddCircle, Cached } from '@material-ui/icons';
import { connect } from 'react-redux';
import { OpenAddModal, openAddModalAction } from '../../../../form-modal/actions';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { BenefitAssessmentDetails } from '../../assessment-details-reducer';
import {
    AddFuneralAssessmentDecisionProps,
    AssessmentDecisionAddView,
} from './assessment-decision-add';
import { FuneralAssessmentDecisionView } from './funeral-assessment-decision';
import { RaiseLiability, raiseLiabilityAction } from '../../actions/raise-liability';
import { LiabilityStatusView } from '../../common/views/liability-status-view';
import {
    DeathDetails,
    getFormDetails,
} from '../../../synopsis/funeral/form-details/form-details-reducer';
import {
    getLifeAssuredDetails,
    LifeAssuredContract,
} from '../../../../life-assured-details/reducer';
import containsDeferredContracts from '../../../../initiate-claim/actions/initiate-claim';
import { ButtonWrapper } from '../../../../forms';
import { Nullable } from '../../../../shared-types';
import { RulesResult } from '../../../synopsis/common/rules/rules-reducer';
interface OwnProps {
    policyNumber: string;
    benefit: BenefitAssessmentDetails;
    assessmentDecisionAddView:
        | React.FunctionComponent<AddFuneralAssessmentDecisionProps>
        | React.ComponentClass<AddFuneralAssessmentDecisionProps>;
}

interface StateProps {
    detailsOfDeath?: DeathDetails;
    isReadOnly: boolean;
    hasDeferredContracts: boolean;
    contracts: LifeAssuredContract[];
    claim?: Nullable<RulesResult[]>;
    isCireRan?: boolean;
    isOverriden?: boolean;
    claimType?: any;
}

interface DispatchProps {
    openAddModal: OpenAddModal;
    raiseLiability: RaiseLiability;
}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const benefitAssessmentDetailsView: React.FunctionComponent<Props> = ({
    benefit,
    classes,
    detailsOfDeath,
    openAddModal,
    policyNumber,
    raiseLiability,
    isReadOnly,
    hasDeferredContracts,
    contracts,
    claim,
    isCireRan,
    claimType,
}: Props) => {
    const claimLiability = benefit.claimLiability || {};
    return (
        <React.Fragment>
            <Grid container spacing={5} justify="space-between">
                <Grid item container justify="flex-start">
                    <Grid item xs={10}>
                        <Typography variant="subtitle2">Benefit - {benefit.benefitName}</Typography>
                    </Grid>
                    {(!isReadOnly || hasDeferredContracts) && (
                        <ButtonWrapper>
                            <Grid
                                container
                                item
                                xs={2}
                                justify="flex-end"
                                style={{ paddingRight: 5, marginTop: '-15px' }}
                            >
                                <Button
                                    id={'Add_' + benefit.benefitName}
                                    color="secondary"
                                    style={{ padding: 10, textTransform: 'none' }}
                                    onClick={addDecision}
                                    disabled={isDisabled()}
                                >
                                    <AddCircle />
                                    Add decision
                                </Button>
                            </Grid>
                        </ButtonWrapper>
                    )}
                </Grid>
            </Grid>
            {switchOn() && (
                <Grid item container>
                    <Grid item xs={10}>
                        <LiabilityStatusView
                            claimLiability={claimLiability}
                            isCireRan={CireClicked()}
                            isOverriden={isDisabled()}
                            claimType={claimType}
                        />
                    </Grid>
                    {canRaiseLiability() && !isDeferredContract(policyNumber) && (
                        <ButtonWrapper>
                            <Grid
                                container
                                item
                                xs={2}
                                justify="flex-end"
                                style={{ paddingRight: 5, marginTop: '-15px' }}
                            >
                                <Button
                                    id="Raise-liability"
                                    color="secondary"
                                    style={{ padding: 10, textTransform: 'none' }}
                                    disabled={isDisabled()}
                                    onClick={() =>
                                        raiseLiability({
                                            policyNumber: policyNumber,
                                            benefitCode: benefit.benefitCode,
                                            causeOfDeath:
                                                detailsOfDeath && detailsOfDeath.typeOfDeath,
                                            dateOfDeath:
                                                detailsOfDeath && detailsOfDeath.dateOfDeath,
                                        })
                                    }
                                >
                                    <Cached />
                                    Raise liability
                                </Button>
                            </Grid>
                        </ButtonWrapper>
                    )}
                </Grid>
            )}
            <DecisionsView benefit={benefit} classes={classes} />
        </React.Fragment>
    );

    function isDeferredContract(policyNumber: string) {
        const contract = contracts.find(c => c.contractNumber === policyNumber);
        if (!contract) {
            return false;
        } else {
            if (containsDeferredContracts(contract.productName)) {
                return true;
            }
        }
    }

    function addDecision() {
        openAddModal({
            modalContent: (
                <AssessmentDecisionAddView
                    {...benefit}
                    {...claimLiability}
                    policyNumber={policyNumber}
                    assessmentDescription={
                        decisionsPresent(benefit) &&
                        benefit.decisions.filter(d => !!d.final).length > 0
                            ? 'Reassessment'
                            : 'Assessment'
                    }
                />
            ),
            modalData: {},
        });
    }
    function canRaiseLiability() {
        return (
            policyNumber &&
            claimLiability.status !== 'Successful' &&
            detailsOfDeath &&
            detailsOfDeath?.dateOfDeath
        );
    }

    function switchOn() {
        return true;
    }

    function CireClicked() {
        if (claim && claim[0].isCireRan) {
            return true;
        }
        return false;
    }

    function isDisabled() {
        if (claim && claim[0].isCireRan) {
            let isDisabled = false;
            for (const rule of claim[0].rules) {
                if (rule.comment !== null || rule.rating === 'Reject') {
                    isDisabled = false;
                    continue;
                } else {
                    isDisabled = true;
                    break;
                }
            }
            return isDisabled;
        }
        return true;
    }
};

interface DecisionsProps extends StyledComponent {
    benefit: BenefitAssessmentDetails;
}

const DecisionsView: React.FunctionComponent<DecisionsProps> = ({
    benefit,
    classes,
}: DecisionsProps) => {
    if (!decisionsPresent(benefit)) {
        return <Divider className={classes.marginBottom} />;
    }

    return (
        <Paper className={classes.paper} style={{ marginTop: 2 }}>
            {benefit.decisions.map((assessmentDecision, assessmentIndex) => {
                return (
                    <React.Fragment key={assessmentDecision.id}>
                        <FuneralAssessmentDecisionView
                            benefitName={benefit.benefitName}
                            assessmentDecision={assessmentDecision}
                            claimLiability={benefit.claimLiability}
                        />
                        {assessmentIndex !== benefit.decisions.length - 1 && (
                            <Divider
                                key={`divider_${assessmentDecision.id}`}
                                className={classes.topAndBottomMargin}
                            />
                        )}
                    </React.Fragment>
                );
            })}
        </Paper>
    );
};

function decisionsPresent(benefit: BenefitAssessmentDetails) {
    return benefit.decisions && benefit.decisions.length > 0;
}

const mapStateToProps = (state: State) => ({
    claim: state.claimSession.claim.results,
    detailsOfDeath: getFormDetails(state).deceasedDetails.detailsOfDeath,
    isReadOnly: state.claimSession.readOnly,
    hasDeferredContracts: state.claimSession.hasDeferredContracts,
    contracts: getLifeAssuredDetails(state).contracts,
    claimType: state.claimSession.claim.claimType,
});

const mapDispatchToProps = {
    openAddModal: openAddModalAction,
    raiseLiability: raiseLiabilityAction,
};

export const BenefitAssessmentDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(benefitAssessmentDetailsView));
