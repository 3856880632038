import React from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import { capitalCase } from 'change-case';
import { connect, MapStateToProps } from 'react-redux';
import { v1 as uuid } from 'uuid';
import { AuthState } from '../../../../auth/auth-reducer';
import { AddFormModal } from '../../../../form-modal/views/add-form-modal';
import {
    Condition,
    FormHeading,
    FullWidthCheckBoxField,
    FullWidthRichTextField,
    FullWidthSelectField,
    FullWidthTextField,
    True,
    WhenFieldChanges,
} from '../../../../forms';
import { requiredValidator } from '../../../../forms/validations';
import { State } from '../../../../redux/root-reducer';
import { getDisplayDate, convertToNumber, formatCurrency } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import {
    AddFuneralAssessmentDecision,
    addFuneralAssessmentDecisionAction,
} from '../actions/add-funeral-assessment-decision';
import { ClaimType } from '../../../../claim/claim-types';
import { getClaim } from '../../../../claim';
import { Nullable } from '../../../../shared-types';
import { LabeledValue } from '../../../../controls/labeled-value';
import { CurrencyMask } from '../../../../forms/input-masks';
import { FuneralAssessmentDecisionViewModel } from '../funeral-assessment-details-reducer';
import {
    DeferredFuneralClaimDecisions,
    FuneralClaimDecisions,
} from '../../../../codes/funeral-claim-decisions';

export interface AddFuneralAssessmentDecisionProps {
    policyNumber: string;
    dateOfDeath?: Date;
    benefitName: string;
    commencementDate?: Date;
    claimAmount?: number;
    accountBalance?: number;
    assessmentDescription: string;
}

interface StateProps {
    currentUser: AuthState;
    claimType: Nullable<ClaimType>;
    hasDeferredContracts: boolean;
}

interface DispatchProps {
    addFuneralAssessmentDecision: AddFuneralAssessmentDecision;
}

interface DecisionProps extends AddFuneralAssessmentDecisionProps, StyledComponent {
    currentClaimType?: string;
    hasDeferredContracts: boolean;
}

type Props = DecisionProps & StateProps & DispatchProps & StyledComponent;
const FuneralAssessmentDecisionAddFormModal = AddFormModal<FuneralAssessmentDecisionViewModel>();
const asssessmentDecisionAddView: React.FunctionComponent<Props> = ({
    claimType,
    classes,
    currentUser,
    addFuneralAssessmentDecision,
    hasDeferredContracts = false,
    ...detailProps
}: Props) => {
    const currentClaimType = `${claimType} - ${capitalCase(detailProps.benefitName)}`;
    return (
        <FuneralAssessmentDecisionAddFormModal
            recordDetailView={() => (
                <FuneralClaimAssessmentDecisionDetailView
                    {...detailProps}
                    currentClaimType={currentClaimType}
                    classes={classes}
                    hasDeferredContracts={hasDeferredContracts}
                />
            )}
            addRecord={record => addFuneralAssessmentDecision(record)}
            blankRecord={getBlankAssessmentDecision()}
        />
    );

    function getBlankAssessmentDecision(): FuneralAssessmentDecisionViewModel {
        return {
            ...detailProps,
            id: uuid(),
            benefitName: detailProps.benefitName,
            amountPayable: detailProps.claimAmount,
            assessmentDate: new Date(),
            assessmentType: detailProps.assessmentDescription,
            assessor: currentUser.name,
            claimType: claimType,
            paymentBasis: '',
        };
    }
};

const FuneralClaimAssessmentDecisionDetailView: React.FunctionComponent<DecisionProps> = ({
    accountBalance,
    assessmentDescription,
    benefitName,
    claimAmount,
    classes,
    commencementDate,
    currentClaimType,
    dateOfDeath,
    policyNumber,
    hasDeferredContracts,
}: DecisionProps) => {
    return (
        <React.Fragment>
            <FormHeading>Add {assessmentDescription} Decision</FormHeading>
            <Grid container spacing={5} justify="flex-start" className={classes.marginTop}>
                <Grid item xs={3}>
                    <LabeledValue label="Policy number" value={policyNumber} />
                </Grid>
                <Grid item xs={3}>
                    <LabeledValue label="Benefit name" value={benefitName} />
                </Grid>
                <Grid item xs={3}>
                    <LabeledValue
                        label="Commencement date"
                        value={getDisplayDate(commencementDate)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LabeledValue label="Claim type" value={currentClaimType} />
                </Grid>
                <Grid item xs={3}>
                    <LabeledValue label="Claim amount" value={formatCurrency(claimAmount)} />
                </Grid>
                <Grid item xs={3}>
                    <LabeledValue label="Effective date" value={getDisplayDate(dateOfDeath)} />
                </Grid>
            </Grid>

            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <FullWidthRichTextField name="summary" validate={requiredValidator} />
                </Grid>
                <Grid item xs={12}>
                    <FullWidthCheckBoxField name="final" label="Final Decision" />
                    <WhenFieldChanges field="final" set="decision" to={null} />
                </Grid>
                <Condition when="final" is={true}>
                    <True>
                        <Grid item xs={4}>
                            <FullWidthSelectField
                                name="decision"
                                options={
                                    !hasDeferredContracts
                                        ? FuneralClaimDecisions
                                        : DeferredFuneralClaimDecisions
                                }
                                sortOptions
                                validate={requiredValidator}
                            />
                        </Grid>
                        <Condition when="decision" is="Decline">
                            <True>
                                <Grid item xs={4}>
                                    <FullWidthSelectField
                                        name="reason"
                                        options={[
                                            {
                                                value: 'Waiting Period',
                                                label: 'Waiting Period',
                                            },
                                            {
                                                value: 'Fraud',
                                                label: 'Fraud',
                                            },
                                            { value: 'Suicide', label: 'Suicide' },
                                            { value: 'No Claim/Cover', label: 'No Claim/Cover' },
                                            {
                                                value: 'Misrepresentation of Fact',
                                                label: 'Misrepresentation of Fact',
                                            },
                                        ]}
                                        sortOptions
                                        validate={requiredValidator}
                                    />
                                </Grid>
                            </True>
                        </Condition>
                        <Condition when="decision" is="Approve">
                            <True>
                                <Grid item xs={4}>
                                    <FullWidthTextField
                                        name="amountPayable"
                                        mask={CurrencyMask}
                                        validate={requiredValidator}
                                        parse={convertToNumber}
                                        disabled
                                    />
                                </Grid>
                            </True>
                        </Condition>
                    </True>
                </Condition>
            </Grid>
        </React.Fragment>
    );
};

const mapStateToProps: MapStateToProps<StateProps, AddFuneralAssessmentDecisionProps, State> = (
    state: State
) => ({
    currentUser: state.auth as AuthState,
    claimType: getClaim(state).claimType,
    hasDeferredContracts: state.claimSession.hasDeferredContracts,
});

const mapDispatchToProps = {
    addFuneralAssessmentDecision: addFuneralAssessmentDecisionAction,
};

export const AssessmentDecisionAddView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(asssessmentDecisionAddView));
