import { withStyles, Grid } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import styles, { StyledComponent } from '../../../../../styles/styles';
import { connect } from 'react-redux';
import { State } from '../../../../../redux/root-reducer';
import { FormDetails, getFormDetails } from '../form-details-reducer';
import { FullWidthTextField } from '../../../../../forms';
import { requiredValidator } from '../../../../../forms/validations';
import { EmailMask } from '../../../../../forms/input-masks/email-mask';
import { TelephoneNumberMask } from '../../../../../forms/input-masks';

interface BaseProps {
    formDetails: FormDetails;
    type: string;
    prefix: string;
}

interface Props extends BaseProps, StyledComponent {}

const contactDetailsEdit: React.FunctionComponent<Props> = ({
    classes,
    formDetails,
    type,
    prefix,
}: Props) => {
    return (
        <Grid container spacing={5} justify="flex-start">
            <Grid item xs={6}>
                <FullWidthTextField
                    label="Home telephone number"
                    name={`${prefix}.homeTelephoneNumber`}
                    mask={TelephoneNumberMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    label="Work telephone"
                    name={`${prefix}.workTelephoneNumber`}
                    mask={TelephoneNumberMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    label="Cellphone Number"
                    name={`${prefix}.cellphoneNumber`}
                    mask={TelephoneNumberMask}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    label="Fax Number"
                    name={`${prefix}.faxNumber`}
                    mask={TelephoneNumberMask}
                />
            </Grid>

            <Grid item xs={6}>
                <FullWidthTextField
                    label="Email Address"
                    name={`${prefix}.emailAddress`}
                    validate={requiredValidator}
                    mask={EmailMask}
                />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state: State) => ({
    formDetails: getFormDetails(state),
});

const mapDispatchToProps = {};

export const ContactDetailsEdit = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(contactDetailsEdit));
