import React from 'react'; // eslint-disable-line

import { FormRenderProps } from 'react-final-form';
import { connect } from 'react-redux';
import { AddFormModal } from '../../form-modal';
import { Doctor } from '../reducer';
import DoctorDetailsView from './doctor-details';

interface Props {
    maxRecords?: number;
    addRecord: (
        doctor: Doctor
    ) => void | Promise<void> | Record<string, any> | Promise<Record<string, any>>;
}

const DoctorAddFormModal = AddFormModal<Doctor>();
const doctorDetailsAddModal: React.FunctionComponent<Props> = ({
    maxRecords,
    addRecord,
}: Props) => {
    return (
        <DoctorAddFormModal
            recordDetailView={detailView}
            addRecord={record => {
                addRecord(record);
            }}
            blankRecord={blankDoctor}
            maxRecords={maxRecords}
        />
    );
};

function detailView(renderProps: FormRenderProps) {
    return <DoctorDetailsView {...renderProps} />;
}

const blankDoctor: Doctor = {
    dateOfConsultation: null,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export const DoctorDetailsAddModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(doctorDetailsAddModal);
