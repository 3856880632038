import { FormSubmissionAction } from '../reducers/fom-submission-reducer';
import { SET_SUBMITTING_FLAG } from '../types';

export type SetSubmittingFlag = (isSubmitting?: boolean) => FormSubmissionAction;
export const setSubmittingFlag: SetSubmittingFlag = (isSubmitting = true) => {
    return {
        type: SET_SUBMITTING_FLAG,
        payload: {
            isSubmitting,
        },
    };
};
