import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import arrayMutators from 'final-form-arrays';
import { connect, MapStateToProps } from 'react-redux';
import { FormErrors } from '../../../../../forms/form-components/form-submission-error';
import { GenericForm } from '../../../../../forms/form-components/generic-form';
import {
    UpdatePreviousPayments,
    updatePreviousPaymentsAction,
} from '../../../../../life-assured-details/actions/update-previous-payments';
import { getLifeAssuredDetails, Payment } from '../../../../../life-assured-details/reducer';
import { PreviousPaymentsView } from '../../../../../life-assured-details/views/previous-payments';
import { State } from '../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { OnSubmitSuccessProps } from '../../../../../workflow-navigation/actions/submit-form';

interface OwnProps {}

interface StateProps {
    previousPayments: Payment[];
}

interface DispatchProps {
    updatePreviousPayments: UpdatePreviousPayments;
}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent & OnSubmitSuccessProps;

const PreviousPaymentsForm = GenericForm<{ previousPayments: Payment[] }>();
const previousPaymentsEditModal: React.FunctionComponent<Props> = ({
    previousPayments,
    updatePreviousPayments,
    ...rest
}: Props) => {
    return (
        <PreviousPaymentsForm
            onSubmit={updatePreviousPayments}
            initialValues={{
                previousPayments,
            }}
            mutators={{
                ...arrayMutators,
            }}
            {...rest}
        >
            <PreviousPaymentsView />
            <FormErrors />
        </PreviousPaymentsForm>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    previousPayments: getLifeAssuredDetails(state).previousPayments,
});

const mapDispatchToProps = {
    updatePreviousPayments: updatePreviousPaymentsAction,
};

export const PreviousPaymentsEditModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(previousPaymentsEditModal));
