import { Grid, Paper, withStyles, Typography } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { ExpansionPanel } from '../../../../../forms/form-components/expansion-panel/expansion-panel';
import { getPolicyOwnerOrganizations, getPolicyOwners } from '../policy-owner-reducer';
import { State } from '../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../styles/styles';
import {
    PolicyOwnerOrganizationDetails,
    PolicyOwnerDetails,
} from '../../../../../life-assured-details/reducer';
import { getClaim } from '../../../../../claim';
import { getFullName, getDisplayDate } from '../../../../../shared-functions';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { AddressDetails, DisplayAddress } from '../../../../../forms/sub-forms/address-details';
import { CommunicationDetails } from '../../../../../forms/sub-forms/communication-details';

interface PolicyOwnerProps {
    readonly policyOwners: PolicyOwnerDetails[];
}

interface PolicyOwnerOrganizationProps {
    readonly policyOwnerOrganizations: PolicyOwnerOrganizationDetails[];
}

type Props = PolicyOwnerProps &
    PolicyOwnerOrganizationProps &
    StyledComponent & {
        versionNumber: string;
    };
const policyOwnerView: React.FunctionComponent<Props> = ({
    classes,
    policyOwnerOrganizations,
    policyOwners,
    versionNumber,
}: Props) => {
    if (versionNumber > '1.1.0.0') {
        return null;
    }

    return (
        <ExpansionPanel name="PolicyOwner">
            <PolicyOwner classes={classes} policyOwners={policyOwners} />
            <PolicyOwnerOrganization
                classes={classes}
                policyOwnerOrganizations={policyOwnerOrganizations}
            />
        </ExpansionPanel>
    );
};

type OwnerProps = PolicyOwnerProps & StyledComponent;
const PolicyOwner: React.FunctionComponent<OwnerProps> = ({
    classes,
    policyOwners,
}: OwnerProps) => {
    return (
        <React.Fragment>
            {policyOwners.map(policyOwner => (
                <Paper
                    className={classes.paper}
                    key={`${policyOwner.firstName}${policyOwner.lastName}`}
                >
                    <Grid container spacing={0}>
                        <Grid item xs={6} lg={9}>
                            <Typography className={classes.marginTop} variant="h5">
                                {getFullName(policyOwner)}
                            </Typography>
                            <LabeledValue label="ID Number" value={policyOwner.idNumber} />
                            <LabeledValue
                                label="Date of Birth"
                                value={getDisplayDate(policyOwner.dateOfBirth, 'dd LLLL yyyy')}
                            />
                            <LabeledValue
                                label="Income Tax Number"
                                value={
                                    policyOwner.incomeTaxNumber ? policyOwner.incomeTaxNumber : '-'
                                }
                            />
                        </Grid>
                        {!!policyOwner.ceded && (
                            <Grid item xs={6} lg={3}>
                                <Typography className={classes.marginTop} variant="subtitle1">
                                    &nbsp;
                                </Typography>
                                <LabeledValue
                                    label="Cessionary Details"
                                    value={policyOwner.cessionaryType}
                                />
                                <LabeledValue label="Ceded by" value={policyOwner.cessionaryName} />
                            </Grid>
                        )}
                    </Grid>
                    <CommunicationDetailsView
                        addressDetails={policyOwner.addressDetails}
                        contactDetails={policyOwner.contactDetails}
                    />
                </Paper>
            ))}
        </React.Fragment>
    );
};

type OrgProps = PolicyOwnerOrganizationProps & StyledComponent;
const PolicyOwnerOrganization: React.FunctionComponent<OrgProps> = ({
    classes,
    policyOwnerOrganizations,
}: OrgProps) => {
    return (
        <React.Fragment>
            {policyOwnerOrganizations.map(organization => (
                <Paper className={classes.paper} key={organization.organizationName}>
                    <Grid container spacing={1} justify="flex-start">
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={6} lg={2}>
                                    <LabeledValue
                                        label="Company Name"
                                        value={organization.organizationName}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={2}>
                                    <LabeledValue
                                        label="Company Registration Number"
                                        value={organization.organizationRegistrationNumber}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={2}>
                                    <LabeledValue
                                        label="Company Type"
                                        value={organization.organizationSuffix}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={2}>
                                    <LabeledValue
                                        label="Company Registration Date"
                                        value={getDisplayDate(
                                            organization.organizationRegistrationDate
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={2}>
                                    <LabeledValue
                                        label="Cessionary"
                                        value={organization.cessionaryType}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={2}>
                                    <LabeledValue
                                        label="Ceded By"
                                        value={organization.cessionaryName}
                                    />
                                </Grid>
                            </Grid>
                            <CommunicationDetailsView
                                addressDetails={organization.addressDetails}
                                contactDetails={organization.contactDetails}
                                isOrganization
                            />
                        </Grid>
                    </Grid>
                </Paper>
            ))}
        </React.Fragment>
    );
};

interface CommunicationProps {
    addressDetails?: AddressDetails;
    contactDetails: CommunicationDetails;
    isOrganization?: boolean;
}

const CommunicationDetailsView: React.FunctionComponent<CommunicationProps> = ({
    addressDetails,
    contactDetails,
    isOrganization,
}: CommunicationProps) => {
    const orgPrefix = isOrganization ? 'Company ' : '';
    return (
        <Grid item xs={12} container spacing={0}>
            {getAddress(addressDetails, orgPrefix)}
            {getTelephone(contactDetails.telephoneNumber, orgPrefix)}
            {getCellphone(contactDetails.cellphoneNumber, orgPrefix)}
            {getEmail(contactDetails.emailAddress, orgPrefix)}
        </Grid>
    );
};

function getAddress(addressDetails?: AddressDetails, orgPrefix?: string) {
    if (!addressDetails || !addressDetails.addressLine1) {
        return null;
    }

    return (
        <Grid item xs={6} lg={3}>
            <DisplayAddress label={`${orgPrefix}Address`} address={addressDetails} />
        </Grid>
    );
}

function getTelephone(telephone?: string, orgPrefix?: string) {
    if (!telephone) {
        return null;
    }

    return (
        <Grid item xs={6} lg={3}>
            <LabeledValue label={`${orgPrefix}Telephone Number`} value={telephone} />
        </Grid>
    );
}

function getCellphone(cellphone?: string, orgPrefix?: string) {
    if (!cellphone) {
        return null;
    }

    return (
        <Grid item xs={6} lg={3}>
            <LabeledValue label={`${orgPrefix}Cellphone Number`} value={cellphone} />
        </Grid>
    );
}

function getEmail(email?: string, orgPrefix?: string) {
    if (!email) {
        return null;
    }

    return (
        <Grid item xs={6} lg={3}>
            <LabeledValue label={`${orgPrefix}Email Address`} value={email} />
        </Grid>
    );
}

const mapStateToProps = (state: State) => {
    const claim = getClaim(state);
    const versionNumber = claim.version ? claim.version.createdInVersion || '1.1.0.0' : '1.1.0.0';
    return {
        policyOwners: getPolicyOwners(state) || [],
        policyOwnerOrganizations: getPolicyOwnerOrganizations(state) || [],
        versionNumber: versionNumber,
    };
};

const mapDispatchToProps = {};

export const PolicyOwnerView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(policyOwnerView));
