import { claimSubmit } from '../../../../../claim';
import { Claim } from '../../../../../claim/reducers';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import {
    Beneficiary,
    getLifeAssuredDetailsFromClaim,
} from '../../../../../life-assured-details/reducer';
import { Payment } from '../../../../../payment/reducer';
import { openToastAction } from '../../../../../confirmation-dialog';

type UpdateBeneficiaryReturn = Promise<any>;
export type UpdateBeneficiary = (
    beneficiary: Beneficiary,
    contractIndex: number,
    beneficiaryIndex: number
) => UpdateBeneficiaryReturn;
type UpdateBeneficiaryAction = (
    beneficiary: Beneficiary,
    contractIndex: number,
    beneficiaryIndex: number
) => ReduxThunkAction<UpdateBeneficiaryReturn>;

export const updateBeneficiaryAction: UpdateBeneficiaryAction = (
    beneficiary: Beneficiary,
    contractIndex: number,
    beneficiaryIndex: number
) => {
    return async (dispatch, getState) => {
        return claimSubmit(
            dispatch,
            getState,
            getUpdatedBeneficiary(beneficiary, contractIndex, beneficiaryIndex)
        );
    };
};

function getUpdatedBeneficiary(
    beneficiary: Beneficiary,
    contractIndex: number,
    beneficiaryIndex: number
): (claim: Claim) => Claim {
    return (claim: Claim) => {
        const currentLifeAssuredDetails = getLifeAssuredDetailsFromClaim(claim);

        currentLifeAssuredDetails.contracts[contractIndex].beneficiaries[
            beneficiaryIndex
        ] = beneficiary;

        return {
            ...claim,
            lifeAssuredDetails: {
                ...currentLifeAssuredDetails,
            },
        };
    };
}

type CheckBeneficiaryScreeningReturn = Promise<any>;
export type CheckBeneficiary = () => CheckBeneficiaryScreeningReturn;
type CheckBeneficiaryAction = () => ReduxThunkAction<CheckBeneficiaryScreeningReturn>;

export const checkBeneficiaryAction: CheckBeneficiaryAction = () => {
    return async (dispatch, getState) => {
        const payments = getState().claimSession.claim.payments;

        if (payments && payments.length > 0) {
            if (anyBeneficiaryErrored(payments)) {
                dispatch(
                    openToastAction({
                        toastContent: {
                            message: 'Beneficiary screening failed',
                            type: 'error',
                        },
                    })
                );
            }
        }

        return {};
    };
};

const anyBeneficiaryErrored = (payments: Payment[]) => {
    return !!payments.find(item => !!item.screeningError);
};
