import React from 'react'; // eslint-disable-line

import {
    Icon,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Tooltip,
    withStyles,
} from '@material-ui/core';
import { ListItemProps } from '@material-ui/core/ListItem';
import { Omit } from 'lodash';
import { connect } from 'react-redux';
import { Nullable } from '../../shared-types';
import { NavigateToScreenThunk, navigateToScreenThunk } from '../actions';
import { FormSubmissionState } from '../reducers/fom-submission-reducer';
import { WorkflowScreen } from '../reducers/workflow-screen';
import { WorkflowScreenContainer } from '../reducers/workflow-screen-container';
import styles, { StyledComponent } from './styles';

type Props = StyledComponent &
    Omit<ListItemProps, 'onClick'> & {
        screen: WorkflowScreen;
        activeScreen: Nullable<WorkflowScreen>;
        formSubmissionState: FormSubmissionState;
        indent?: number;
        indentStep?: number;
        navigateToScreen: NavigateToScreenThunk;
    };

const workflowScreenlinkListItem: React.FunctionComponent<Props> = ({
    classes,
    screen,
    activeScreen,
    formSubmissionState,
    indent = 10,
    indentStep = 10,
    navigateToScreen,
    ...rest
}: Props) => {
    const isContainer = screen instanceof WorkflowScreenContainer;
    const childCount = screen instanceof WorkflowScreenContainer ? screen.children().length : 0;
    const containerSelected = !!activeScreen && activeScreen.path().startsWith(screen.path());
    const selected =
        screen === activeScreen || (isContainer && childCount === 1 && containerSelected);
    function navigate() {
        if (screen instanceof WorkflowScreenContainer) {
            navigateToScreen(screen.descendantScreens()[0], formSubmissionState);
        } else {
            navigateToScreen(screen, formSubmissionState);
        }
    }
    return (
        <React.Fragment>
            <MenuItem
                classes={{ selected: classes.menuItemSelected }}
                // @ts-ignore
                button
                onClick={() => navigate()}
                disabled={isContainer && childCount === 0}
                selected={selected}
                style={{ paddingLeft: `${indent}px` }}
                {...rest}
            >
                <Tooltip title={screen.label}>
                    <ListItemText
                        primaryTypographyProps={{
                            className: classes.link,
                            style: { overflow: 'hidden', textOverflow: 'ellipsis' },
                        }}
                        primary={screen.label}
                    />
                </Tooltip>
                {childCount > 1 && !containerSelected && (
                    <ListItemIcon className={classes.listItemIcon}>
                        <Icon className={classes.link}>expand_more</Icon>
                    </ListItemIcon>
                )}
            </MenuItem>
            {containerSelected && (
                <NestedWorkflowScreenlinkList
                    screen={screen}
                    activeScreen={activeScreen}
                    formSubmissionState={formSubmissionState}
                    indent={indent + indentStep}
                    indentStep={indentStep}
                />
            )}
        </React.Fragment>
    );
};

interface InnerProps {
    screen: WorkflowScreen;
    activeScreen: Nullable<WorkflowScreen>;
    formSubmissionState: FormSubmissionState;
    indent: number;
    indentStep: number;
}
const NestedWorkflowScreenlinkList: React.FunctionComponent<InnerProps> = ({
    screen,
    activeScreen,
    formSubmissionState,
    indent,
    indentStep,
}: InnerProps) => {
    if (!(screen instanceof WorkflowScreenContainer)) {
        return null;
    }

    if (screen.children().length === 1) {
        return (
            <NestedWorkflowScreenlinkList
                screen={screen.children()[0]}
                activeScreen={activeScreen}
                formSubmissionState={formSubmissionState}
                indent={indent}
                indentStep={indentStep}
            />
        );
    }

    return (
        <MenuList>
            {screen.children().map(child => (
                <WorkflowScreenlinkListItem
                    key={child.path()}
                    screen={child}
                    activeScreen={activeScreen}
                    formSubmissionState={formSubmissionState}
                    indent={indent}
                    indentStep={indentStep}
                />
            ))}
        </MenuList>
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    navigateToScreen: navigateToScreenThunk,
};

export const WorkflowScreenlinkListItem = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(workflowScreenlinkListItem));
