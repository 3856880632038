import {
    FormDetails,
    formDetailsReducer,
    formDetailsListReducer,
} from '../../../assessment/synopsis/living-lifestyle/form-details/form-details-reducer';
import { OccupationalDutiesBreakdown } from '../../../claim-information/reducer';
import { combineReducersWithDefault } from '../../../redux/combine-reducers-with-default';
import { simplePropertyReducer } from '../../../redux/simple-property-reducer';
import { Nullable } from '../../../shared-types';
import { initialCommonClaim, CommonClaim, commonClaimReducers } from './retrenchment-claim-reducer';

export interface DreadClaim extends CommonClaim {
    readonly formDetails?: Nullable<FormDetails>;
    readonly formDetailsList?: FormDetails[];
    readonly previousOccupationalDuties?: Nullable<OccupationalDutiesBreakdown>;
}

export const initialDreadClaim: DreadClaim = initialCommonClaim;

export const dreadClaimReducer = combineReducersWithDefault<DreadClaim>(initialDreadClaim, {
    ...commonClaimReducers,
    formDetails: formDetailsReducer,
    formDetailsList: formDetailsListReducer,
    previousOccupationalDuties: simplePropertyReducer(null),
});
