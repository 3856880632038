import { reduceAndSubmitClaim } from '../../../../../claim/actions/reduce-and-submit-claim';
import {
    AddRecordCollectionAction,
    DeleteRecordCollectionAction,
    UpdateRecordCollectionAction,
} from '../../../../../redux/collection-reducer';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { Doctors } from '../reducer';
import {
    ADD_PREVIOUS_DOCTOR_RECORD,
    DELETE_PREVIOUS_DOCTOR_RECORD,
    UPDATE_PREVIOUS_DOCTOR_RECORD,
} from '../types';

type SubmitReturn = Promise<any>;
export type AddPreviousDoctor = (doctors: Doctors) => SubmitReturn;
type AddPreviousDoctorThunk = (doctors: Doctors) => ReduxThunkAction<SubmitReturn>;
export const addPreviousDoctorThunk: AddPreviousDoctorThunk = (doctors: Doctors) => (
    dispatch,
    getState
) => reduceAndSubmitClaim(dispatch, getState, addPreviousDoctorActionCreator(doctors));
export type AddPreviousDoctorRecordAction = AddRecordCollectionAction<Doctors>;
export const addPreviousDoctorActionCreator = (doctors: Doctors): AddPreviousDoctorRecordAction => {
    return {
        type: ADD_PREVIOUS_DOCTOR_RECORD,
        record: doctors,
    };
};

export type UpdatePreviousDoctor = (doctorIndex: number, doctors: Doctors) => SubmitReturn;
type UpdatePreviousDoctorThunk = (
    doctorIndex: number,
    doctors: Doctors
) => ReduxThunkAction<SubmitReturn>;
export const updatePreviousDoctorThunk: UpdatePreviousDoctorThunk = (
    doctorIndex: number,
    doctors: Doctors
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        updatePreviousDoctorActionCreator(doctorIndex, doctors)
    );
export type UpdatePreviousDoctorAction = UpdateRecordCollectionAction<Doctors>;
export const updatePreviousDoctorActionCreator = (
    doctorIndex: number,
    doctors: Doctors
): UpdatePreviousDoctorAction => {
    return {
        type: UPDATE_PREVIOUS_DOCTOR_RECORD,
        record: doctors,
        index: doctorIndex,
    };
};

export type DeletePreviousDoctor = (doctorIndex: number) => SubmitReturn;
type DeletePreviousDoctorThunk = (doctorIndex: number) => ReduxThunkAction<SubmitReturn>;
export const deletePreviousDoctorThunk: DeletePreviousDoctorThunk = (doctorIndex: number) => (
    dispatch,
    getState
) => reduceAndSubmitClaim(dispatch, getState, deletePreviousDoctorActionCreator(doctorIndex));
export type DeletePreviousDoctorAction = DeleteRecordCollectionAction;
export const deletePreviousDoctorActionCreator = (
    doctorIndex: number
): DeletePreviousDoctorAction => {
    return {
        type: DELETE_PREVIOUS_DOCTOR_RECORD,
        index: doctorIndex,
    };
};
