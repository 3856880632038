export function getPropertyValueFunction<T>(
    addressPropertyName?: string
    /* eslint-disable-next-line */
): (values: T, key: string) => any {
    if (!!addressPropertyName) {
        return (values, key) => {
            const nestedAddressPropertyName = addressPropertyName.split('.');
            /* eslint-disable-next-line */
            let propertyValues: any = undefined;
            nestedAddressPropertyName.forEach(nestedKey => {
                if (nestedKey !== '') {
                    propertyValues = propertyValues
                        ? propertyValues[nestedKey]
                        : values[nestedAddressPropertyName[0]];
                }
            });
            if (!!propertyValues) {
                return propertyValues[key];
            }
            return undefined;
        };
    }
    return (values, key) => values[key];
}
