import React from 'react'; // eslint-disable-line
import { Grid, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { getRADeathClaim } from '../../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { Code } from '../../../../../codes/codes';
import { Passports, TemporaryIds } from '../../../../../codes/id-document-types';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { AddressDetails, DisplayAddress } from '../../../../../forms/sub-forms/address-details';
import { State } from '../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../styles/styles';
import {
    getCodeDescription,
    getDisplayDate,
    getFullName,
    getOrganisationTypeSuffixLabel,
    getOrganisationTypeLabel,
} from '../../../../../shared-functions';
import {
    ContactDetails,
    Dependant,
    getBeneficiaries,
    getLinkedBeneficiaries as getLinkedDescription,
    Guardian,
    getPossibleParents,
} from '../reducer';
import { DependantViewCurator } from './dependant-view-curator';
import { DependantViewFinancialInformation } from './dependant-view-financial-info';
import { ViewBankingDetails } from '../../../common/banking-details/views/view-banking-details';

interface ExpandedProps extends StyledComponent {
    dependant: Dependant;
    beneficiaries: Code<string>[];
    possibleParents: Code<string>[];
}

const expandedView: React.FunctionComponent<ExpandedProps> = ({
    beneficiaries,
    dependant,
    possibleParents,
}: ExpandedProps) => {
    const personalDetails = dependant.personalDetails || {};
    if (dependant.dependantType === 'Individual') {
        return (
            <React.Fragment>
                <SubSectionHeading heading="Personal details" />
                <Grid item xs={6} lg={3}>
                    <LabeledValue label="Dependant type" value={dependant.dependantType} />
                </Grid>
                <Grid item xs={6} lg={3}>
                    <LabeledValue
                        label="Relationship to life assured"
                        value={dependant.relationship}
                    />
                </Grid>
                <Grid item xs={6} lg={3}>
                    <LabeledValue
                        label="Description of relationship"
                        value={dependant.relationshipDescription}
                    />
                </Grid>
                <Grid item xs={6} lg={3}>
                    <LabeledValue label="Claimant type" value={dependant.claimCapacity} />
                </Grid>
                <Grid item xs={6} lg={3}>
                    <LabeledValue
                        label="Linked to beneficiary on record"
                        value={getLinkedDescription(beneficiaries, dependant.id)}
                    />
                </Grid>
                {dependant.relationship === 'MinorChild' ? (
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Child of"
                            value={getLinkedDescription(possibleParents, dependant.parent)}
                        />
                    </Grid>
                ) : (
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Executor of the estate" value={dependant.isExecutor} />
                    </Grid>
                )}
                <Grid item xs={6} lg={3}>
                    <LabeledValue label="Beneficiary of will" value={dependant.beneficiaryOfWill} />
                </Grid>
                {Passports.includes(personalDetails.idType || '') && (
                    <Grid item xs={12} container justify="flex-start">
                        <Grid item xs={6} lg={3}>
                            <LabeledValue
                                label="ID type"
                                value={getCodeDescription('idType', personalDetails.idType)}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <LabeledValue
                                label="Passport number"
                                value={personalDetails.passportNumber}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <LabeledValue
                                label="Passport issue date"
                                value={getDisplayDate(personalDetails.passportIssueDate)}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <LabeledValue
                                label="Passport expiry date"
                                value={getDisplayDate(personalDetails.passportExpiryDate)}
                            />
                        </Grid>
                    </Grid>
                )}
                {TemporaryIds.includes(personalDetails.idType || '') && (
                    <Grid item xs={12} container justify="flex-start">
                        <Grid item xs={6} lg={3}>
                            <LabeledValue
                                label="ID type"
                                value={getCodeDescription('idType', personalDetails.idType)}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <LabeledValue label="ID number" value={personalDetails.idNumber} />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <LabeledValue
                                label="ID issue date"
                                value={getDisplayDate(personalDetails.idIssueDate)}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <LabeledValue
                                label="ID expiry date"
                                value={getDisplayDate(personalDetails.idExpiryDate)}
                            />
                        </Grid>
                    </Grid>
                )}
                {!TemporaryIds.includes(personalDetails.idType || '') &&
                    !Passports.includes(personalDetails.idType || '') && (
                        <Grid item xs={12} container justify="flex-start">
                            <Grid item xs={6} lg={3}>
                                <LabeledValue
                                    label="ID type"
                                    value={getCodeDescription('idType', personalDetails.idType)}
                                />
                            </Grid>
                            <Grid item xs={6} lg={3}>
                                <LabeledValue label="ID number" value={personalDetails.idNumber} />
                            </Grid>
                        </Grid>
                    )}
                <Grid item xs={12} container justify="flex-start">
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Date of birth"
                            value={getDisplayDate(personalDetails.dateOfBirth, 'dd LLLL yyyy')}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Age at life assured death"
                            value={personalDetails.age}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Gender" value={personalDetails.gender} />
                    </Grid>
                </Grid>
                <Grid item xs={12} container justify="flex-start">
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Marital status"
                            value={personalDetails.maritalStatus}
                        />
                    </Grid>
                    {personalDetails.dateOfMarriage && (
                        <Grid item xs={6} lg={3}>
                            <LabeledValue
                                label="Date of marriage"
                                value={getDisplayDate(personalDetails.dateOfMarriage)}
                            />
                        </Grid>
                    )}
                    {personalDetails.dateOfDivorce && (
                        <Grid item xs={6} lg={3}>
                            <LabeledValue
                                label="Date of divorce"
                                value={getDisplayDate(personalDetails.dateOfDivorce)}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12} container justify="flex-start">
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Has disability" value={dependant.disability} />
                    </Grid>
                    {dependant.disabilityDetails && (
                        <Grid item xs={6} lg={3}>
                            <LabeledValue
                                label="Details of disability"
                                value={dependant.disabilityDetails}
                            />
                        </Grid>
                    )}
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Under curatorship"
                            value={dependant.underCuratorship}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={6} lg={3}>
                    <LabeledValue label="Currently employed" value={dependant.employed} />
                </Grid>
                {dependant.employed === 'Yes' && (
                    <React.Fragment>
                        <Grid item xs={6} lg={3}>
                            <LabeledValue label="Occupation" value={dependant.occupation} />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <LabeledValue label="Employer name" value={dependant.company} />
                        </Grid>
                    </React.Fragment>
                )}
                {dependant.yearsInRelationship && (
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Years in reletionship"
                            value={dependant.yearsInRelationship}
                        />
                    </Grid>
                )}
                {dependant.relationship === 'MinorChild' && (
                    <Grid item xs={12} container justify="flex-start">
                        <Grid item xs={6} lg={3}>
                            <LabeledValue
                                label="Student or scholar"
                                value={dependant.studentOrScholar}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <LabeledValue label="current grade" value={dependant.schoolYearGrade} />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <LabeledValue
                                label="Number of years left"
                                value={dependant.schoolYearsLeft}
                            />
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <LabeledValue label="Name of school" value={dependant.institution} />
                        </Grid>
                    </Grid>
                )}
                {dependant.relationship === 'MajorChild' && (
                    <Grid item xs={12} container justify="flex-start">
                        <Grid item xs={6} lg={3}>
                            <LabeledValue
                                label="Student or scholar"
                                value={dependant.studentOrScholar}
                            />
                        </Grid>
                        {dependant.schoolYearGrade && (
                            <Grid item xs={6} lg={3}>
                                <LabeledValue
                                    label="Current year of study"
                                    value={dependant.schoolYearGrade}
                                />
                            </Grid>
                        )}
                        {dependant.institution && (
                            <Grid item xs={6} lg={3}>
                                <LabeledValue label="Institution" value={dependant.institution} />
                            </Grid>
                        )}
                        {dependant.courseType && (
                            <Grid item xs={6} lg={3}>
                                <LabeledValue label="Course type" value={dependant.courseType} />
                            </Grid>
                        )}
                    </Grid>
                )}
                <DependantViewContacts contactDetails={dependant.contactDetails || {}} />
                <DependantViewAddress
                    postalAddress={dependant.postalAddress}
                    residentialAddress={dependant.residentialAddress}
                    sameAsResidential={dependant.sameAsResidential}
                />
                <DependantViewCurator curator={dependant.curator} />
                <DependantViewGuardian guardian={dependant.guardian} />
                <DependantViewFinancialInformation
                    financialInformation={dependant.financialInformation || {}}
                    relationship={dependant.relationship}
                />
                {dependant.hasBankingDetails && (
                    <ViewBankingDetails
                        bankingDetails={dependant.bankingDetails || {}}
                        beneficiaryId={dependant.id}
                    />
                )}
            </React.Fragment>
        );
    } else {
        const organisationDetails = dependant.organisationDetails || {};
        return (
            <React.Fragment>
                <Grid item xs={6} lg={3}>
                    <LabeledValue
                        label="Relationship To Life Assured"
                        value={
                            organisationDetails.relationshipToLifeAssured !== 'Other'
                                ? organisationDetails.relationshipToLifeAssured
                                : organisationDetails.relationshipToLifeAssuredDescription
                        }
                    />
                </Grid>
                <Grid item xs={6} lg={3}>
                    <LabeledValue label="Claim Capacity" value={dependant.claimCapacity} />
                </Grid>
                <Grid item xs={6} lg={3}>
                    <LabeledValue
                        label="Type"
                        value={getOrganisationTypeLabel(organisationDetails.organisationType)}
                    />
                </Grid>
                <Grid item xs={6} lg={3}>
                    <LabeledValue
                        label="Suffix"
                        value={getOrganisationTypeSuffixLabel(
                            organisationDetails.organisationSuffix
                        )}
                    />
                </Grid>
                <Grid item xs={6} lg={3}>
                    <LabeledValue label="Name" value={organisationDetails.companyName} />
                </Grid>
                <Grid item xs={6} lg={3}>
                    <LabeledValue
                        label="Entity Registration Number"
                        value={organisationDetails.entityRegistrationNumber}
                    />
                </Grid>
                <Grid item xs={6} lg={3}>
                    <LabeledValue
                        label="Date Of Incorporation"
                        value={getDisplayDate(organisationDetails.dateOfIncorporation)}
                    />
                </Grid>
                <Grid item xs={6} lg={3}>
                    <LabeledValue
                        label="Country Of Incorporation"
                        value={organisationDetails.countryOfIncorporation}
                    />
                </Grid>
                <Grid item xs={6} lg={3}>
                    <LabeledValue label="Tax Status" value={organisationDetails.taxStatus} />
                </Grid>
                {dependant.contactDetails && (
                    <DependantViewContacts contactDetails={dependant.contactDetails || {}} />
                )}
                {organisationDetails.address && (
                    <DependantViewEntityAddress residentialAddress={organisationDetails.address} />
                )}
                {organisationDetails.authorisedPerson &&
                    organisationDetails.authorisedPerson.personalDetails && (
                        <React.Fragment>
                            <SubSectionHeading heading="Authorised Person&#39;s Details" />
                            {organisationDetails.authorisedPerson.personalDetails
                                .passportNumber && (
                                <Grid item xs={12} container justify="flex-start">
                                    <Grid item xs={4} lg={2}>
                                        <LabeledValue
                                            label="Passport"
                                            value={
                                                organisationDetails.authorisedPerson.personalDetails
                                                    .passportNumber
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={4} lg={2}>
                                        <LabeledValue
                                            label="Country Of Issue"
                                            value={
                                                organisationDetails.authorisedPerson.personalDetails
                                                    .passportCountryOfIssue
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={4} lg={2}>
                                        <LabeledValue
                                            label="Date of issue"
                                            value={getDisplayDate(
                                                organisationDetails.authorisedPerson.personalDetails
                                                    .passportIssueDate
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4} lg={2}>
                                        <LabeledValue
                                            label="Expiry Date"
                                            value={getDisplayDate(
                                                organisationDetails.authorisedPerson.personalDetails
                                                    .passportExpiryDate
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="First Name"
                                    value={
                                        organisationDetails.authorisedPerson.personalDetails
                                            .firstName
                                    }
                                />
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Last Name"
                                    value={
                                        organisationDetails.authorisedPerson.personalDetails
                                            .lastName
                                    }
                                />
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Initials"
                                    value={
                                        organisationDetails.authorisedPerson.personalDetails
                                            .initials
                                    }
                                />
                            </Grid>
                            {organisationDetails.authorisedPerson.personalDetails.idNumber && (
                                <Grid item xs={4} lg={3}>
                                    <LabeledValue
                                        label="ID Number"
                                        value={
                                            organisationDetails.authorisedPerson.personalDetails
                                                .idNumber
                                        }
                                    />
                                </Grid>
                            )}
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Date of Birth"
                                    value={getDisplayDate(
                                        organisationDetails.authorisedPerson.personalDetails
                                            .dateOfBirth,
                                        'dd LLLL yyyy'
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Gender"
                                    value={
                                        organisationDetails.authorisedPerson.personalDetails.gender
                                    }
                                />
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Marital Status"
                                    value={
                                        organisationDetails.authorisedPerson.personalDetails
                                            .maritalStatus
                                    }
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                {organisationDetails.authorisedPerson &&
                    organisationDetails.authorisedPerson.communicationDetails && (
                        <React.Fragment>
                            <SubSectionHeading heading="Authorised Person&#39;s Contact Details" />
                            <Grid item xs={12} lg={12} container justify="flex-start">
                                <Grid item xs={4} lg={3}>
                                    <LabeledValue
                                        label="Telephone number"
                                        value={
                                            organisationDetails.authorisedPerson
                                                .communicationDetails.telephoneNumber
                                        }
                                    />
                                </Grid>
                                <Grid item xs={4} lg={3}>
                                    <LabeledValue
                                        label="Cellphone number"
                                        value={
                                            organisationDetails.authorisedPerson
                                                .communicationDetails.cellphoneNumber
                                        }
                                    />
                                </Grid>
                                <Grid item xs={4} lg={3}>
                                    <LabeledValue
                                        label="E-mail Address"
                                        value={
                                            organisationDetails.authorisedPerson
                                                .communicationDetails.emailAddress
                                        }
                                    />
                                </Grid>
                                <Grid item xs={4} lg={3}>
                                    <LabeledValue
                                        label="Communication Preference"
                                        value={
                                            organisationDetails.authorisedPerson
                                                .communicationDetails.preferredMethodOfCommunication
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )}
                {organisationDetails.authorisedPerson &&
                    organisationDetails.authorisedPerson.residentialAddress && (
                        <DependantViewAddress
                            postalAddress={organisationDetails.authorisedPerson.postalAddress}
                            residentialAddress={
                                organisationDetails.authorisedPerson.residentialAddress
                            }
                            sameAsResidential={
                                organisationDetails.authorisedPerson.sameAsResidential
                            }
                        />
                    )}
                {dependant.bankingDetails && (
                    <ViewBankingDetails
                        bankingDetails={dependant.bankingDetails || {}}
                        beneficiaryId={dependant.id}
                    />
                )}
            </React.Fragment>
        );
    }
};

interface AddressProps {
    postalAddress?: AddressDetails;
    residentialAddress?: AddressDetails;
    sameAsResidential?: boolean;
}
const DependantViewAddress: React.FunctionComponent<AddressProps> = ({
    residentialAddress,
    ...rest
}: AddressProps) => {
    return (
        <React.Fragment>
            <SubSectionHeading heading="Address details" />
            <Grid item xs={6}>
                <DisplayAddress label="Residential address" address={residentialAddress} />
            </Grid>
            <Grid item xs={6}>
                <PostalAddress {...rest} label="Postal address" />
            </Grid>
        </React.Fragment>
    );
};

const DependantViewEntityAddress: React.FunctionComponent<AddressProps> = ({
    residentialAddress,
}: AddressProps) => {
    if (!residentialAddress) {
        return null;
    }
    return (
        <Grid item xs={6} lg={6}>
            <SubSectionHeading heading="Business Address" />
            <DisplayAddress label="" address={residentialAddress} />
        </Grid>
    );
};

interface ContactProps {
    contactDetails: ContactDetails;
}

const DependantViewContacts: React.FunctionComponent<ContactProps> = ({
    contactDetails,
}: ContactProps) => {
    return (
        <React.Fragment>
            <SubSectionHeading heading="Contact details" />
            <Grid item xs={6} lg={2}>
                <LabeledValue label="Cellphone number" value={contactDetails.cellphoneNumber} />
            </Grid>
            <Grid item xs={6} lg={2}>
                <LabeledValue label="Telephone number" value={contactDetails.telephoneNumber} />
            </Grid>
            <Grid item xs={6} lg={2}>
                <LabeledValue label="Fax number" value={contactDetails.faxNumber} />
            </Grid>
            <Grid item xs={6} lg={4}>
                <LabeledValue label="Email" value={contactDetails.emailAddress} />
            </Grid>
            <Grid item xs={6} lg={2}>
                <LabeledValue
                    label="Communication preference"
                    value={getCodeDescription(
                        'preferredmethodofcommunication',
                        contactDetails.preferredMethodOfCommunication
                    )}
                />
            </Grid>
        </React.Fragment>
    );
};

interface GuardianProps {
    guardian?: Guardian;
}

const DependantViewGuardian: React.FunctionComponent<GuardianProps> = ({
    guardian,
}: GuardianProps) => {
    if (!guardian) {
        return null;
    }
    return (
        <React.Fragment>
            <SubSectionHeading heading="Guardian's details" />
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Name" value={getFullName(guardian)} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Cellphone number"
                    value={guardian.contactDetails && guardian.contactDetails.cellphoneNumber}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Email"
                    value={guardian.contactDetails && guardian.contactDetails.emailAddress}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Communication preference"
                    value={getCodeDescription(
                        'preferredmethodofcommunication',
                        guardian.contactDetails &&
                            guardian.contactDetails.preferredMethodOfCommunication
                    )}
                />
            </Grid>
        </React.Fragment>
    );
};

interface SubSectionProps {
    heading: string;
}
export const SubSectionHeading: React.FunctionComponent<SubSectionProps> = ({
    heading,
}: SubSectionProps) => {
    return (
        <Grid item xs={12}>
            <Typography
                variant="subtitle1"
                style={{ fontSize: '20px', marginBottom: '-8px', marginTop: '8px' }}
            >
                {heading}
            </Typography>
        </Grid>
    );
};

interface PostalProps {
    label: string;
    postalAddress?: AddressDetails;
    sameAsResidential?: boolean;
}
const PostalAddress = ({ label, postalAddress, sameAsResidential }: PostalProps) => {
    if (sameAsResidential) {
        return <LabeledValue label={label} value="Same as residential" />;
    }

    return <DisplayAddress label={label} address={postalAddress} />;
};

const mapStateToProps = (state: State) => ({
    beneficiaries: getBeneficiaries(getRADeathClaim(state)),
    possibleParents: getPossibleParents(state),
});

export const DependantViewExpanded = connect(mapStateToProps, {})(withStyles(styles)(expandedView));
