import { Reducer } from 'redux';
import { Claim, getProperty, getPropertyFromClaim } from '../../../../claim';
import { ContactDetails, CommunicationDetails } from '../../../../contact-details/contact-details';
import { FinancialAdviserContactDetails } from '../../../../contact-details/financial-adviser/financial-adviser-contact-details';
import { AddressDetails } from '../../../../forms/sub-forms/address-details';
import { collectionReducer } from '../../../../redux/collection-reducer/collection-reducer';
import { combineReducersWithDefault } from '../../../../redux/combine-reducers-with-default';
import { State } from '../../../../redux/root-reducer';
import { Nullable, EntityType } from '../../../../shared-types';
import { PersonalDetails } from '../../../../life-assured-details/reducer';
import { BankingDetails } from '../../common/banking-details/banking-details';
import {
    DELETE_CLAIMANT_RECORD,
    ADD_CLAIMANT_RECORD,
    UPDATE_CLAIMANT_RECORD,
} from '../claimant-details/actions/types';
import {
    ADD_EXECUTOR_RECORD,
    ADD_OTHER_CONTACT_RECORD,
    DELETE_EXECUTOR_RECORD,
    DELETE_OTHER_CONTACT_RECORD,
    UPDATE_EXECUTOR_RECORD,
    UPDATE_OTHER_CONTACT_RECORD,
} from './actions/types';

export const ClaimantTypes = [
    {
        value: EntityType.Individual,
        label: EntityType.Individual,
    },
    {
        value: EntityType.Organisation,
        label: EntityType.Organisation,
    },
    {
        value: EntityType.Executor,
        label: EntityType.Executor,
    },
];

export enum OrganisationType {
    Trust = 'Trust',
    ForeignTrust = 'ForeignTrust',
    CloseCorporation = 'CloseCorporation',
    NonProfitOrganisation = 'NonProfitOrganisation',
    PrivateCompany = 'PrivateCompany',
    PublicCompany = 'PublicCompany',
    StateOwnedCompany = 'StateOwnedCompany',
    PersonalLiabilityCompany = 'PersonalLiabilityCompany',
    ForeignCompany = 'ForeignCompany',
}

export const OrganisationTypes = [
    { value: 'Trust', label: 'Trust' },
    { value: 'ForeignTrust', label: 'Foreign Trust' },
    { value: 'CloseCorporation', label: 'Close Corporation' },
    { value: 'NonProfitOrganisation', label: 'Non-Profit Organisation' },
    { value: 'PrivateCompany', label: 'Private Company' },
    { value: 'PublicCompany', label: 'Public Company' },
    { value: 'StateOwnedCompany', label: 'State Owned Company' },
    { value: 'PersonalLiabilityCompany', label: 'Personal Liability Company' },
    { value: 'ForeignCompany', label: 'Foreign Company' },
];

export enum OrganisationTypeSuffix {
    LTD = 'LTD',
    PTYLTD = 'PTY (LTD)',
    CC = 'CC',
    Incorporated = 'Incorporated',
    NPC = 'NPC',
    SOC = 'SOC',
}

export const OrganisationTypeSuffixes = [
    { value: 'LTD', label: 'LTD' },
    { value: 'PTYLTD', label: 'PTY (LTD)' },
    { value: 'CC', label: 'CC' },
    { value: 'Incorporated', label: 'Incorporated' },
    { value: 'NPC', label: 'NPC' },
    { value: 'SOC', label: 'SOC' },
    { value: 'None', label: 'None' },
];

export enum ContactType {
    Claimant = 'Claimant',
    Executor = 'Executor',
    Other = 'Other',
}

export const ContactTypes = [
    {
        value: ContactType.Executor,
        label: ContactType.Executor,
    },
    {
        value: ContactType.Other,
        label: ContactType.Other,
    },
];

export interface Executor extends ContactDetails {
    id?: string;
}

export interface AuthorisedPerson {
    readonly personalDetails?: PersonalDetails;
    readonly residentialAddress?: AddressDetails;
    readonly postalAddress?: AddressDetails;
    readonly communicationDetails?: CommunicationDetails;
    readonly sameAsResidential: boolean;
}

export interface OrganisationDetails {
    readonly organisationType?: OrganisationType;
    readonly organisationSuffix?: OrganisationTypeSuffix;
    readonly relationshipToOwner?: string;
    readonly relationshipToOwnerDescription?: string;
    readonly relationshipToLifeAssured?: string;
    readonly relationshipToLifeAssuredDescription?: string;
    readonly companyName?: string;
    readonly entityRegistrationNumber?: string;
    readonly dateOfIncorporation?: Date;
    readonly countryOfIncorporation?: string;
    readonly trustResolution?: string;
    readonly trustLetterOfAuthority?: string;
    readonly trustDeed?: string;
    readonly companyTaxNumber?: number;
    readonly companyResolution?: string;
    readonly membersIdDocument?: string;
    readonly ck1Document?: string;
    readonly taxStatus?: string;
    readonly communicationDetails?: CommunicationDetails;
    readonly address?: AddressDetails;
    readonly countryOfTaxResidence?: string;
    readonly authorisedPerson?: AuthorisedPerson;
}

export interface GuardianDetails {
    readonly firstName?: string;
    readonly lastName?: string;
    readonly idNumber?: string;
    readonly isRsaCitizen?: string;
    readonly dateOfBirth?: string;
    readonly relationshipToOwner?: string;
    readonly relationshipToOwnerDescription?: string;
    readonly relationshipToLifeAssured?: string;
    readonly relationshipToLifeAssuredDescription?: string;
    readonly taxStatus?: string;
    readonly countryOfResidence?: string;
    readonly taxReferenceNumber?: string;
    readonly proofOfGuardianship?: string;
}

export interface ClaimantDetails {
    readonly deletable?: boolean;
    readonly claimantType?: EntityType;
    readonly communicationDetails?: CommunicationDetails;
    readonly organisationDetails?: OrganisationDetails;
    readonly guardianDetails?: GuardianDetails;
    readonly residentialAddress?: AddressDetails;
    readonly personalDetails?: PersonalDetails;
    readonly postalAddress?: AddressDetails;
    bankingDetails?: BankingDetails;
    readonly claimantIsAMinor?: boolean;
}

export interface Claimant {
    id?: string;
    readonly claimantDetails?: ClaimantDetails;
    readonly financialAdviser?: FinancialAdviserContactDetails;
}

export interface OtherContact extends ContactDetails {
    id?: string;
    readonly description?: string;
}

export interface DeathClaimContactDetails {
    readonly claimants: Claimant[];
    readonly otherContacts: OtherContact[];
    readonly executors: Executor[];
    readonly financialAdviser: FinancialAdviserContactDetails;
}

export const defaultDeathClaimContactDetails: DeathClaimContactDetails = {
    claimants: [],
    otherContacts: [],
    executors: [],
    financialAdviser: {},
};

export const claimantRecordReducer = collectionReducer<Claimant>({
    addAction: ADD_CLAIMANT_RECORD,
    deleteAction: DELETE_CLAIMANT_RECORD,
    updateAction: UPDATE_CLAIMANT_RECORD,
});

export const otherContactRecordReducer = collectionReducer<OtherContact>({
    addAction: ADD_OTHER_CONTACT_RECORD,
    deleteAction: DELETE_OTHER_CONTACT_RECORD,
    updateAction: UPDATE_OTHER_CONTACT_RECORD,
});

const executorRecordReducer = collectionReducer<Executor>({
    addAction: ADD_EXECUTOR_RECORD,
    deleteAction: DELETE_EXECUTOR_RECORD,
    updateAction: UPDATE_EXECUTOR_RECORD,
});

const financialAdvisorReducer: Reducer<FinancialAdviserContactDetails> = (
    state: FinancialAdviserContactDetails = {}
) => {
    return state;
};

export const deathClaimContactDetailsReducer = combineReducersWithDefault<
    Nullable<DeathClaimContactDetails>
>(defaultDeathClaimContactDetails, {
    claimants: claimantRecordReducer,
    otherContacts: otherContactRecordReducer,
    executors: executorRecordReducer,
    financialAdviser: financialAdvisorReducer,
});

export function getDeathClaimContactDetails(state: State) {
    return getProperty(state, 'contactDetails', defaultDeathClaimContactDetails);
}

export function getDeathClaimContactDetailsFromClaim(claim: Claim): DeathClaimContactDetails {
    return getPropertyFromClaim(claim, 'contactDetails', defaultDeathClaimContactDetails);
}

export function getClaimants(state: State) {
    const contactDetails = getDeathClaimContactDetails(state);
    return contactDetails.claimants;
}

export function getExecutors(state: State) {
    const contactDetails = getDeathClaimContactDetails(state);
    return contactDetails.executors;
}
