import { UpdateRecordCollectionAction } from '../../../../../redux/collection-reducer';
import { AdditionalInformationRequiredRecord } from '../reducer';
import { UPDATE_ADDITIONAL_INFORMATION_REQUIRED } from '../types';

export type UpdateAdditionalInformationRequiredAction = UpdateRecordCollectionAction<AdditionalInformationRequiredRecord>;

export const updateAdditionalInformationRequiredActionCreator = (
    additionalInformationRequiredIndex: number,
    additionalInformationRequired: AdditionalInformationRequiredRecord
): UpdateAdditionalInformationRequiredAction => {
    return {
        type: UPDATE_ADDITIONAL_INFORMATION_REQUIRED,
        record: additionalInformationRequired,
        index: additionalInformationRequiredIndex,
    };
};
