import React from 'react'; // eslint-disable-line
import styles, { StyledComponent } from '../../../styles/styles';
import { withStyles, Grid, Typography, Paper } from '@material-ui/core';
import { CloseModal, closeModalAction } from '../../../form-modal/actions';
import { connect } from 'react-redux';
import {
    FormHeading,
    FullWidthCheckBoxField,
    FullWidthTextField,
    GenericForm,
    WhenFieldChanges,
} from '../../../forms';
import { manualScreenAction } from '../../actions/beneficiary-manual-screening';
import { BeneficiaryManualScreening } from '../../reducer';
import { requiredValidator } from '../../../forms/validations';
import { State } from '../../../redux/root-reducer';
import { reseverseName } from '../../../assesor-dashboard/actions/assessors-action';
import { hideLoadingAction, showLoadingAction } from '../../../forms/loading-screen/actions';
interface DispatchProps {
    closeModal: CloseModal;
    beneficiarymanualScreen: any;
}
interface OwnProps {
    classes: any;
    paymentIndex: number;
    auth: any;
    hideLoadingAction: any;
    showLoadingAction: any;
}

type Props = DispatchProps & StyledComponent & OwnProps;
const ManualScreeningForm = GenericForm<BeneficiaryManualScreening>();
const ManualScreening: React.FunctionComponent<Props> = ({
    classes,
    closeModal,
    paymentIndex,
    auth,
    beneficiarymanualScreen,
    hideLoadingAction,
    showLoadingAction,
}: Props) => {
    const onSubmit = (values: BeneficiaryManualScreening) => {
        showLoadingAction();
        const request = { ...values, assessor: reseverseName(auth.name) };
        beneficiarymanualScreen(paymentIndex, request).then(() => {
            closeModal();
            hideLoadingAction();
        });
    };

    return (
        <ManualScreeningForm onSubmit={onSubmit}>
            <React.Fragment>
                <Paper className={classes.paper}>
                    <FormHeading text="Manual Beneficiaty risk rating confirmation" />
                    <Grid container style={{ padding: 20 }}>
                        <Grid item xs={12}>
                            <Typography>Has the beneficiary been screened manually?</Typography>
                            <Grid item xs={2}>
                                <FullWidthCheckBoxField
                                    label="Yes"
                                    name="isBeneficiaryManualScreening"
                                    validate={requiredValidator}
                                />
                                <WhenFieldChanges
                                    field={`isBeneficiaryManualScreening`}
                                    becomes={false}
                                    set={'isBeneficiaryManualScreening'}
                                    to={null}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                Based on the manual results, are you sure you want to continue to
                                make a payment?
                            </Typography>
                            <Grid item xs={2}>
                                <FullWidthCheckBoxField
                                    label="Yes"
                                    name="isBeneficiaryManualScreeningConfirmed"
                                    validate={requiredValidator}
                                    required
                                />
                                <WhenFieldChanges
                                    field={`isBeneficiaryManualScreeningConfirmed`}
                                    becomes={false}
                                    set={`isBeneficiaryManualScreeningConfirmed`}
                                    to={null}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <FullWidthTextField
                                id="beneficiaryManualScreeningComment"
                                name="beneficiaryManualScreeningComment"
                                label="Comment"
                                validate={requiredValidator}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </React.Fragment>
        </ManualScreeningForm>
    );
};

const mapDispatchToProps = {
    closeModal: closeModalAction,
    hideLoadingAction: hideLoadingAction,
    beneficiarymanualScreen: manualScreenAction,
    showLoadingAction: showLoadingAction,
};

const mapStateToProps = (state: State) => ({
    auth: state.auth,
});

export const ManualScreeningView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(ManualScreening));
