import { Grid, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import styles, { StyledComponent } from '../../../../../styles/styles';
import { Rule } from '../rules-reducer';
import { Nullable } from '../../../../../shared-types';

interface Props extends StyledComponent {
    rules: Nullable<Rule[]>;
    isCireRan?: boolean;
}

const allPassed = (rules: Nullable<Rule[]>) => {
    if (!rules || rules.length === 0) {
        return true;
    }
};

const resultsNotificationsView = ({ classes, isCireRan, rules }: Props) => {
    if (isCireRan && allPassed(rules)) {
        return (
            <Grid container className={classes.topAndBottomMargin}>
                Claim passed all validations
            </Grid>
        );
    }

    return null;
};

export const ResultsNotificationsView = withStyles(styles)(resultsNotificationsView);
