import React from 'react'; // eslint-disable-line

import { Grid } from '@material-ui/core';
import { composeValidators, maxValue, requiredValidator } from '../../../../forms/validations';
import { FullWidthTextField, FullWidthDatePicker, WhenFieldChanges } from '../../../../forms';
import { PercentageMask } from '../../../../forms/input-masks/percentage-mask';
import { convertToNumber } from '../../../../shared-functions';
import { CurrencyMask } from '../../../../forms/input-masks';

interface Props {
    noPartialPayment: boolean;
    sumAssured?: number;
}

export const DisabilityAssessmentDecisionFields: React.FunctionComponent<Props> = ({
    noPartialPayment,
    sumAssured,
}: Props) => {
    return (
        <React.Fragment>
            <Grid item xs={4}>
                <FullWidthDatePicker name="effectiveDate" validate={requiredValidator} />
            </Grid>
            <Grid item xs={4}>
                <FullWidthTextField
                    name="percentagePayable"
                    mask={PercentageMask}
                    validate={requiredValidator}
                    parse={convertToNumber}
                    disabled={noPartialPayment}
                />
            </Grid>
            <Grid item xs={4}>
                <FullWidthTextField
                    name="amountPayable"
                    mask={CurrencyMask}
                    validate={composeValidators(
                        requiredValidator,
                        maxValue(sumAssured || 0, 'sum assured')
                    )}
                    parse={convertToNumber}
                    disabled={noPartialPayment}
                />
            </Grid>
            <WhenFieldChanges
                field="percentagePayable"
                set="amountPayable"
                to={(percent: number) => (!sumAssured ? undefined : sumAssured * (percent / 100))}
            />
        </React.Fragment>
    );
};
