import { ReduxThunkAction } from '../../../../redux/redux-actions';
import { reduceAndSubmitClaim } from '../../../../claim/actions/reduce-and-submit-claim';
import { AddRecordCollectionAction } from '../../../../redux/collection-reducer';
import { ADD_RA_DEATH_DECISION } from './types';
import { DecisionModel, Recommendation, Decision } from '../ra-death-assessment-details-reducer';

type SubmitReturn = Promise<any>;
export type AddAndSubmitAssessmentDecisionRecord = (decision: any) => SubmitReturn;
type AddAndSubmitAssessmentDecisionRecordThunk = (
    decision: DecisionModel
) => ReduxThunkAction<SubmitReturn>;

export const addAndSubmitAssessmentDecisionRecordThunk: AddAndSubmitAssessmentDecisionRecordThunk = (
    decision: DecisionModel
) => (dispatch, getState) =>
    reduceAndSubmitClaim(dispatch, getState, addAssessmentDecisionActionCreator(decision));

export type AddAssessmentDecisionRecordAction = AddRecordCollectionAction<Decision>;

export const addAssessmentDecisionActionCreator = (
    decision: DecisionModel
): AddAssessmentDecisionRecordAction => {
    const recommendations: Recommendation[] = [];
    const percentageSplits: Recommendation[] = [];
    decision.recommendations.forEach(recommendation => {
        recommendations.push({
            dependantId: recommendation.dependantId,
            reason: recommendation.reason || '',
            reasonDescription: recommendation.reasonDescription,
            allocation: recommendation.allocation || 0,
        });
    });
    decision.percentageSplits.forEach(recommendation => {
        percentageSplits.push({
            dependantId: recommendation.dependantId,
            reason: recommendation.reason || '',
            reasonDescription: recommendation.reasonDescription,
            allocation: recommendation.allocation || 0,
        });
    });
    const assessmentDecision: Decision = {
        ...decision,
        recommendations: recommendations,
        percentageSplits: percentageSplits,
    };
    return {
        type: ADD_RA_DEATH_DECISION,
        record: assessmentDecision,
    };
};
