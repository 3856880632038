import React from 'react'; // eslint-disable-line

import { Grid, withStyles, Typography } from '@material-ui/core';
import { FullWidthTextField } from '../../../../../forms';
import { TelephoneNumberMask } from '../../../../../forms/input-masks';
import { CommunicationDetailsSubForm } from '../../../../../forms/sub-forms/communication-details';
import styles, { StyledComponent } from '../../../../../styles/styles';

interface ContactProps extends StyledComponent {
    readonly heading?: string;
    readonly propertyPrefix?: string;
}

const contactDetails: React.FunctionComponent<ContactProps> = ({
    classes,
    heading,
    propertyPrefix,
}: ContactProps) => {
    const fullPropertyPrefix = propertyPrefix && propertyPrefix !== '' ? propertyPrefix + '.' : '';
    return (
        <React.Fragment>
            <Typography variant="subtitle2" className={classes.marginTop}>
                {heading ? heading : `Contact details`}
            </Typography>
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={6}>
                    <FullWidthTextField
                        name={`${fullPropertyPrefix}workTelephoneNumber`}
                        mask={TelephoneNumberMask}
                    />
                </Grid>
                <CommunicationDetailsSubForm
                    communicationDetailsPropertyName={fullPropertyPrefix}
                    displayOptions={{
                        telephoneNumber: true,
                        cellphoneNumber: true,
                        emailAddress: true,
                        preferredMethodOfCommunication: true,
                    }}
                    required="Any"
                    requiredOptions={{
                        cellphoneNumber: true,
                        emailAddress: true,
                        preferredMethodOfCommunication: true,
                    }}
                />
            </Grid>
        </React.Fragment>
    );
};

export const ContactDetailsEditView = withStyles(styles)(contactDetails);
