import { Grid, Paper, withStyles } from '@material-ui/core';
import { FormApi } from 'final-form';
import React from 'react'; // eslint-disable-line
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Submit, submitAction } from '../actions/submit';
import { ForensicInput, getForensicInput } from '../reducer';
import { FormSpy } from 'react-final-form';
import { DateTime } from 'luxon';
import {
    validations,
    GenericForm,
    FormErrors,
    FormHeading,
    FullWidthDatePicker,
    Condition,
    True,
    FullWidthTextField,
} from '../../../../../forms';
import { OnSubmitSuccessProps } from '../../../../../workflow-navigation';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { Nullable } from '../../../../../shared-types';
import { State } from '../../../../../redux/root-reducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

interface OwnProps {}

interface StateProps {
    forensicInput?: ForensicInput;
}

interface DispatchProps {
    submit: Submit;
}

type Props = DispatchProps & OwnProps & StateProps & OnSubmitSuccessProps & StyledComponent;

const Form = GenericForm<ForensicInput>();
function forensicInputFormWrapper({ classes, forensicInput, submit, ...rest }: Props) {
    return (
        <Form initialValues={forensicInput} onSubmit={submit} {...rest}>
            <InnerForm classes={classes} />
            <FormErrors />
        </Form>
    );
}

const InnerForm: React.FunctionComponent<StyledComponent> = ({ classes }) => {
    return FormSpy<ForensicInput>({
        render: ({ form }) => forensicInputForm({ classes, form }),
    });
};

type InnerProps = StyledComponent & {
    form: FormApi<ForensicInput>;
};

const forensicInputForm: React.FunctionComponent<InnerProps> = ({ form, classes }: InnerProps) => {
    function checkIfCleared(value: Nullable<DateTime>) {
        if (value === null && !!form) {
            form.reset();
        }
    }
    return (
        <Paper className={classes.paper}>
            <FormHeading text="Forensic Input" />
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={6}>
                    <Grid item xs={12}>
                        <FullWidthDatePicker
                            name="dateReferred"
                            onChange={checkIfCleared}
                            disableFuture
                        />
                    </Grid>
                    <Condition when="dateReferred" hasValue>
                        <True>
                            <Grid item xs={12}>
                                <FullWidthDatePicker name="dateFeedbackReceived" disableFuture />
                            </Grid>
                            <Grid item xs={12}>
                                <FullWidthDatePicker name="dateResolved" disableFuture />
                            </Grid>
                        </True>
                    </Condition>
                </Grid>
                <Grid item xs={6}>
                    <Condition when="dateReferred" hasValue>
                        <True>
                            <Grid item xs={12}>
                                <FullWidthTextField
                                    name="reasonForReferral"
                                    multiline
                                    validate={validations.requiredValidator}
                                />
                            </Grid>
                        </True>
                    </Condition>
                </Grid>
            </Grid>
        </Paper>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    forensicInput: getForensicInput(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    submit: details => dispatch(submitAction(details)),
});

export const ForensicInputForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(forensicInputFormWrapper));
