import { AnyAction, Reducer } from 'redux';
import { HIDE_LOADING, SHOW_LOADING } from './action-types';

export interface LoadingScreenState {
    readonly loadingCount: number;
}

export const initialLoadingScreenState: LoadingScreenState = {
    loadingCount: 0,
};

export const loadingScreenReducer: Reducer<LoadingScreenState, AnyAction> = (
    state: LoadingScreenState = initialLoadingScreenState,
    action: AnyAction
) => {
    switch (action.type) {
        case SHOW_LOADING:
            return {
                loadingCount: state.loadingCount + 1,
            };
        case HIDE_LOADING:
            return {
                loadingCount: Math.max(0, state.loadingCount - 1),
            };
        default:
            return state;
    }
};
