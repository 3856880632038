import { reduceAndSubmitClaim } from '../../claim/actions/reduce-and-submit-claim';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { Payment } from '../reducer';
import { addPaymentActionCreator } from './add-payment';
import { claimSubmit, Claim } from '../../claim';
import { Nullable } from '../../shared-types';
import { ClaimType } from '../../claim/claim-types';

type SubmitReturn = Promise<any>;
export type AddAndSubmitPayment = (
    payment: Payment,
    claimType: Nullable<ClaimType>
) => SubmitReturn;
type AddAndSubmitPaymentThunk = (
    payment: Payment,
    claimType: Nullable<ClaimType>
) => ReduxThunkAction<SubmitReturn>;
export const addAndSubmitPaymentThunk: AddAndSubmitPaymentThunk = (
    payment: Payment,
    claimType: Nullable<ClaimType>
) => (dispatch, getState) =>
    reduceAndSubmitClaim(dispatch, getState, addPaymentActionCreator(payment, claimType));

export type AddAndSubmitPaymentItem = (paymentItem: Payment, index: number) => SubmitReturn;
type AddPaymentPaymentItemRecordThunk = (paymentItem: Payment, index: number) => ReduxThunkAction;

export const addPaymentPaymentItemRecordThunk: AddPaymentPaymentItemRecordThunk = (
    payment: Payment,
    index: number
) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, getUpdatedPayment(payment, index));
    };
};

function getUpdatedPayment(paymentItem: any, index: number): (claim: Claim) => Claim {
    return (claim: Claim) => {
        const currentPaymentItem = claim.payments[index];
        const newPaymentItem = {
            ...paymentItem,
            capturedDate: new Date(),
        };
        if (currentPaymentItem) {
            paymentItem.beneficiaryId = currentPaymentItem.beneficiaryId;
            if (currentPaymentItem.paymentItems) {
                currentPaymentItem.paymentItems.push(newPaymentItem);
            } else {
                currentPaymentItem.paymentItems = [newPaymentItem];
            }
        }
        return claim;
    };
}
