import React from 'react'; // eslint-disable-line
import { GenericForm, FormErrors } from '../../../../forms';
import { StyledComponent } from '../../../../claim-audit-trail/styles';
import {
    updateFinancialDetailsAction,
    UpdateFinancialDetails,
} from '../claim-details/actions/update-ra-financial-details';
import { OnSubmitSuccessProps } from '../../../../workflow-navigation';
import { withStyles } from '@material-ui/styles';
import styles from '../../../../styles/styles';
import { State } from '../../../../redux/root-reducer';
import { connect } from 'react-redux';
import { FinancialDetails } from '../../death/claim-event-details/reducer';
import { RAFinancialDetailsEditView } from './ra-financial-details-edit-view';
import { getRaDeathClaimDetails } from './reducer';

interface Props extends OnSubmitSuccessProps, StyledComponent {
    updateFinancialDetails: UpdateFinancialDetails;
    financialDetails: FinancialDetails;
}

const RAFinancialDetailsForm = GenericForm<FinancialDetails>();
const raFinancialDetailsEditModal: React.FunctionComponent<Props> = ({
    financialDetails,
    updateFinancialDetails,
    ...rest
}: Props) => {
    const onSubmit = (submissionDetails: FinancialDetails) => {
        return updateFinancialDetails(submissionDetails);
    };
    return (
        <RAFinancialDetailsForm onSubmit={onSubmit} initialValues={financialDetails} {...rest}>
            <RAFinancialDetailsEditView />
            <FormErrors />
        </RAFinancialDetailsForm>
    );
};
const mapDispatchToProps = {
    updateFinancialDetails: updateFinancialDetailsAction,
};

const mapStateToProps = (state: State) => ({
    financialDetails: getRaDeathClaimDetails(state).financialDetails || {},
});

export const RAFinancialDetailsEditModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(raFinancialDetailsEditModal));
