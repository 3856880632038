import { claimSubmit } from '../../claim';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { DoctorsDetails } from '../reducer';

type SubmitReturn = Promise<any>;
export type Submit = (doctorDetails: DoctorsDetails) => SubmitReturn;
type SubmitAction = (doctorDetails: DoctorsDetails) => ReduxThunkAction<SubmitReturn>;
export const submitAction: SubmitAction = (doctorDetails: DoctorsDetails) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, claim => ({
            ...claim,
            doctorDetails,
        }));
    };
};
