import { createFeatureActionNamer } from '../../../../redux/action-name';

const getActionName = createFeatureActionNamer('additional-information-required');

export const ADD_ADDITIONAL_INFORMATION_REQUIRED = getActionName(
    'ADD_ADDITIONAL_INFORMATION_REQUIRED'
);
export const UPDATE_ADDITIONAL_INFORMATION_REQUIRED = getActionName(
    'UPDATE_ADDITIONAL_INFORMATION_REQUIRED'
);
export const DELETE_ADDITIONAL_INFORMATION_REQUIRED = getActionName(
    'DELETE_ADDITIONAL_INFORMATION_REQUIRED'
);
