import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { Claim, claimSubmit } from '../../../../../claim';
import { getResultsFromClaim, Rule } from '../rules-reducer';

type SubmitRulesResultsReturn = Promise<any>;
export type UpdateRules = (rules: Rule[], index: number) => SubmitRulesResultsReturn;
type UpdateRulesAction = (
    rules: Rule[],
    index: number
) => ReduxThunkAction<SubmitRulesResultsReturn>;
export const updateRulesAction: UpdateRulesAction = (rules: Rule[], index: number) => {
    return async (dispatch, getState) => {
        return await claimSubmit(dispatch, getState, getUpdateClaim(rules, index));
    };
};

function getUpdateClaim(rules: Rule[], index: number): (claim: Claim) => Claim {
    return (claim: Claim) => {
        const results = getResultsFromClaim(claim);
        results[index].rules = rules;

        return {
            ...claim,
            results,
        };
    };
}
