import { reduceAndSubmitClaim } from '../../../../../claim/actions/reduce-and-submit-claim';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { AdditionalInformationRequiredRecord } from '../reducer';
import { addAdditionalInformationRequiredActionCreator } from './add-additional-information-required';
import { deleteAdditionalInformationRequiredActionCreator } from './delete-additional-information-required';
import { updateAdditionalInformationRequiredActionCreator } from './update-additional-information-required';

type SubmitReturn = Promise<any>;

export type AddAndSubmitAdditionalInformationRequired = (
    AdditionalInformationRequired: AdditionalInformationRequiredRecord
) => SubmitReturn;
type AddAndSubmitAdditionalInformationRequiredRecordThunk = (
    AdditionalInformationRequired: AdditionalInformationRequiredRecord
) => ReduxThunkAction<SubmitReturn>;
export const addAndSubmitAdditionalInformationRequiredThunk: AddAndSubmitAdditionalInformationRequiredRecordThunk = (
    additionalInformationRequired: AdditionalInformationRequiredRecord
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        addAdditionalInformationRequiredActionCreator(additionalInformationRequired)
    );

export type UpdateAndSubmitAdditionalInformationRequired = (
    additionalInformationRequiredIndex: number,
    additionalInformationRequired: AdditionalInformationRequiredRecord
) => SubmitReturn;
type UpdateAndSubmitAdditionalInformationRequiredRecordThunk = (
    AdditionalInformationRequiredIndex: number,
    AdditionalInformationRequired: AdditionalInformationRequiredRecord
) => ReduxThunkAction<SubmitReturn>;
export const updateAndSubmitAdditionalInformationRequiredThunk: UpdateAndSubmitAdditionalInformationRequiredRecordThunk = (
    additionalInformationRequiredIndex: number,
    additionalInformationRequired: AdditionalInformationRequiredRecord
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        updateAdditionalInformationRequiredActionCreator(
            additionalInformationRequiredIndex,
            additionalInformationRequired
        )
    );

export type DeleteAndSubmitAdditionalInformationRequired = (
    AdditionalInformationRequiredIndex: number
) => SubmitReturn;
type DeleteAndSubmitAdditionalInformationRequiredThunk = (
    AdditionalInformationRequiredIndex: number
) => ReduxThunkAction<SubmitReturn>;
export const deleteAndSubmitAdditionalInformationRequiredThunk: DeleteAndSubmitAdditionalInformationRequiredThunk = (
    additionalInformationRequiredIndex: number
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        deleteAdditionalInformationRequiredActionCreator(additionalInformationRequiredIndex)
    );
