import React from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import { FullWidthTextField } from '../../../../../forms';
import { FirstNameMask, InitialsMask, LastNameMask } from '../../../../../forms/input-masks';
import { CommunicationDetailsSubForm } from '../../../../../forms/sub-forms/communication-details';
import { conditionalRequired } from '../../../../../forms/validations';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { getPropertyValueFunction } from '../../../../../forms/sub-forms/utility-functions';
import { FinancialAdviserContactDetails } from '../../../../../contact-details/financial-adviser/financial-adviser-contact-details';

type FinancialAdviserProps = StyledComponent & {
    propertyName?: string;
};

const financialAdviserDetails: React.FunctionComponent<FinancialAdviserProps> = ({
    classes,
    propertyName,
}: FinancialAdviserProps) => {
    const propertyPrefix = !!propertyName ? `${propertyName}.` : '';
    const propValue = getPropertyValueFunction<FinancialAdviserContactDetails>(propertyPrefix);
    return (
        <Grid container spacing={5} justify="flex-start">
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${propertyPrefix}initials`}
                    label="Initials"
                    mask={InitialsMask}
                    inputProps={{
                        style: { textTransform: 'uppercase' },
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${propertyPrefix}firstName`}
                    label="Name"
                    mask={FirstNameMask}
                    validate={conditionalRequired(values => {
                        if (!propValue(values, 'companyName')) {
                            return true;
                        }
                        if (
                            (propValue(values, 'title') ||
                                propValue(values, 'initials') ||
                                propValue(values, 'lastName') ||
                                propValue(values, 'consultantCode') ||
                                propValue(values, 'telephoneNumber') ||
                                propValue(values, 'cellphoneNumber') ||
                                propValue(values, 'emailAddress')) &&
                            !propValue(values, 'companyName')
                        ) {
                            return true;
                        }
                        return false;
                    })}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${propertyPrefix}lastName`}
                    label="Surname"
                    mask={LastNameMask}
                    validate={conditionalRequired(values => {
                        if (!propValue(values, 'companyName')) {
                            return true;
                        }
                        if (
                            (propValue(values, 'title') ||
                                propValue(values, 'initials') ||
                                propValue(values, 'firstName') ||
                                propValue(values, 'consultantCode') ||
                                propValue(values, 'telephoneNumber') ||
                                propValue(values, 'cellphoneNumber') ||
                                propValue(values, 'emailAddress')) &&
                            !propValue(values, 'companyName')
                        ) {
                            return true;
                        }
                        return false;
                    })}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${propertyPrefix}companyName`}
                    validate={conditionalRequired(values => {
                        if (
                            propValue(values, 'firstName') === undefined &&
                            propValue(values, 'lastName') === undefined
                        ) {
                            return true;
                        }
                        if (
                            (propValue(values, 'telephoneNumber') !== undefined ||
                                propValue(values, 'cellphoneNumber') !== undefined ||
                                propValue(values, 'emailAddress') !== undefined) &&
                            (!propValue(values, 'firstName') || !propValue(values, 'lastName'))
                        ) {
                            return true;
                        }
                        return false;
                    })}
                />
            </Grid>
            <CommunicationDetailsSubForm
                communicationDetailsPropertyName={propertyName}
                displayOptions={{
                    telephoneNumber: true,
                    cellphoneNumber: true,
                    emailAddress: true,
                }}
                required="All"
            />
        </Grid>
    );
};

export const FinancialAdviserDetailsEditView = withStyles(styles)(financialAdviserDetails);
