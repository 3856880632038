import { withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { getClaim } from '../../../../../claim';
import { ButtonCapture } from '../../../../../forms';
import { ExpansionPanel } from '../../../../../forms/form-components/expansion-panel/expansion-panel';
import { State } from '../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { AlternateVOPDView } from '../../../common/vopd/views';
import { DeathCertification, getDeathCertification } from '../death-certification-reducer';
import { DeathCertificateView } from './death-certificate';

interface Props extends StyledComponent {
    deathCertification: DeathCertification;
}

const deathCertificationView: React.FunctionComponent<Props> = ({ deathCertification }: Props) => {
    return (
        <ExpansionPanel name="Death Certificate Details">
            <DeathCertificateView />
            <ButtonCapture>
                <AlternateVOPDView vopd={deathCertification.vopd || {}} />
            </ButtonCapture>
        </ExpansionPanel>
    );
};

const mapStateToProps = (state: State) => ({
    deathCertification: getDeathCertification(getClaim(state)),
});

export const DeathCertificationView = connect(
    mapStateToProps,
    {}
)(withStyles(styles)(deathCertificationView));
