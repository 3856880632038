import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import {
    ChangeFieldSet,
    changeRecordFromAddChangeSet,
    changeRecordFromChangeSet,
    changeRecordFromDeleteChangeSet,
} from '../claim-audit-trail-functions';
import { addAuditTrailDetailsView } from '../common-views/claim-audit-trail-field-details';
import { mapSectionView } from '../common-views/claim-audit-trail-wrapper';

interface Props {
    changeSet: Record<string, any>;
}

const auditTrailContactDetailsView: React.FunctionComponent<Props> = ({ changeSet }: Props) => {
    const returnView: React.ReactNode[] = [];
    const sections = [
        {
            sectionName: 'Claimant',
            sectionKey: 'claimants',
        },
        {
            sectionName: 'Executor',
            sectionKey: 'executors',
        },
        {
            sectionName: 'Other Contact',
            sectionKey: 'otherContacts',
        },
    ];

    for (const section of sections) {
        const changeRecord = changeSet[section.sectionKey];
        if (changeRecord) {
            getViewFromList(changeRecord, returnView, section.sectionName);
        }
    }

    return mapSectionView(returnView);
};

function getViewFromList(
    changeRecord: Record<string, any>,
    returnView: React.ReactNode[],
    sectionName: string,
    subSectionName: string[] = []
) {
    Object.keys(changeRecord).forEach(recordKey => {
        let auditTrail: ChangeFieldSet[] = [];
        let documentNumber = 0;
        if (!isNaN(Number(recordKey)) && changeRecord[recordKey]) {
            documentNumber = Number(recordKey) + 1;
            if (Array.isArray(changeRecord[recordKey])) {
                if (changeRecord[recordKey][0]) {
                    if (
                        changeRecord[recordKey][0].hasOwnProperty('claimantDetails') ||
                        changeRecord[recordKey][0].hasOwnProperty('financialAdviser')
                    ) {
                        addToReturnView(
                            'Add',
                            changeRecord[recordKey][0],
                            sectionName,
                            documentNumber,
                            returnView
                        );
                        return;
                    }
                    auditTrail = changeRecordFromAddChangeSet(changeRecord[recordKey][0]);
                }
            } else {
                if (
                    changeRecord[recordKey].hasOwnProperty('claimantDetails') ||
                    changeRecord[recordKey].hasOwnProperty('financialAdviser') ||
                    (changeRecord[recordKey].claimantDetails &&
                        changeRecord[recordKey].claimantDetails.hasOwnProperty('guardian'))
                ) {
                    addToReturnView(
                        'Change',
                        changeRecord[recordKey],
                        sectionName,
                        documentNumber,
                        returnView
                    );
                    return;
                }
                auditTrail = changeRecordFromChangeSet(changeRecord[recordKey]);
            }
        } else {
            const deleteKey = recordKey.split('_');
            if (!isNaN(Number(deleteKey[1]))) {
                documentNumber = Number(deleteKey[1]) + 1;
                if (
                    changeRecord[recordKey][0].hasOwnProperty('claimantDetails') ||
                    changeRecord[recordKey][0].hasOwnProperty('financialAdviser')
                ) {
                    addToReturnView(
                        'Delete',
                        changeRecord[recordKey][0],
                        sectionName,
                        documentNumber,
                        returnView
                    );
                    return;
                }
                auditTrail = changeRecordFromDeleteChangeSet(changeRecord[recordKey][0]);
            }
        }
        if (auditTrail && auditTrail.length > 0) {
            subSectionName.push(`${sectionName} ${documentNumber}`);
            addAuditTrailDetailsView(returnView, auditTrail, subSectionName);
        }
    });
}

function addToReturnView(
    changeType: string,
    changeRecord: Record<string, any>,
    sectionName: string,
    documentNumber: number,
    returnView: React.ReactNode[]
) {
    let subSectionName = true;
    if (changeRecord.hasOwnProperty('claimantDetails')) {
        const bankingDetails: Record<string, any> =
            changeRecord['claimantDetails']['bankingDetails'];
        const postalAddress: Record<string, any> = changeRecord['claimantDetails']['postalAddress'];
        const residentialAddress: Record<string, any> =
            changeRecord['claimantDetails']['residentialAddress'];
        const guardianDetails: Record<string, any> =
            changeRecord['claimantDetails']['guardianDetails'];
        const organisationDetails: Record<string, any> =
            changeRecord['claimantDetails']['organisationDetails'];
        const claimantDeleteStatus: Record<string, any> =
            changeRecord['claimantDetails']['deletable'];

        const personalDetails: Record<string, any> = {
            ...changeRecord['claimantDetails'],
            postalAddress: null,
            residentialAddress: null,
            bankingDetails: null,
        };
        if (personalDetails) {
            if (personalDetails.hasOwnProperty('personalDetails')) {
                addAuditTrailToView(personalDetails['personalDetails'], 'Personal Details');
                subSectionName = false;
            }
        }
        if (guardianDetails) {
            addAuditTrailToView(guardianDetails, 'Guardian Details');
            subSectionName = false;
        }
        if (organisationDetails) {
            addAuditTrailToView(organisationDetails, 'Organisation Details');
            subSectionName = false;
        }
        if (postalAddress) {
            addAuditTrailToView(postalAddress, 'Postal Address');
            subSectionName = false;
        }
        if (residentialAddress) {
            addAuditTrailToView(residentialAddress, 'Residential Address');
            subSectionName = false;
        }
        if (bankingDetails) {
            const response = bankingDetails.bankResponse;
            delete bankingDetails['bankResponse'];
            if (Object.keys(bankingDetails).length > 0) {
                addAuditTrailToView(bankingDetails, 'Banking Details');
                subSectionName = false;
            }
            if (response) {
                if (Array.isArray(response) && response[0] === null) {
                    addAuditTrailDetailsView(
                        returnView,
                        changeRecordFromAddChangeSet(response[1]),
                        getHeading('Bank Details - AVS')
                    );
                } else if (Array.isArray(response) && response[1] === null) {
                    addAuditTrailDetailsView(
                        returnView,
                        changeRecordFromDeleteChangeSet(response[0]),
                        getHeading('Bank Details - AVS')
                    );
                } else {
                    addAuditTrailToView(response, 'Bank Details - AVS');
                }
                subSectionName = false;
            }
        }
        if (claimantDeleteStatus) {
            const deletable = {
                deletable: [
                    changeRecord['claimantDetails']['deletable'][0] === true ? 'Yes' : 'No',
                    changeRecord['claimantDetails']['deletable'][1] === false ? 'No' : 'Yes',
                ],
            };
            addAuditTrailToView(deletable, 'Contact Details');
        }
    }

    let addFinancialAdviser = false;
    let deleteFinancialAdviser = false;
    if (changeRecord.hasOwnProperty('financialAdviser')) {
        const faChangeRecord = changeRecord['financialAdviser'];
        if (faChangeRecord) {
            let financialAdviser: Record<string, any> = {};
            if (Array.isArray(faChangeRecord)) {
                if (faChangeRecord[1]) {
                    financialAdviser = faChangeRecord[1];
                    addFinancialAdviser = changeType === 'Change';
                } else {
                    financialAdviser = faChangeRecord[0];
                    deleteFinancialAdviser = true;
                }
            } else {
                financialAdviser = faChangeRecord;
            }
            addAuditTrailToView(financialAdviser, 'Financial Adviser');
        }
    }

    function addAuditTrailToView(changeObject: Record<string, any>, name: string) {
        let auditTrail: ChangeFieldSet[] = [];
        if (changeType === 'Add') {
            auditTrail = changeRecordFromAddChangeSet(changeObject);
        }
        if (changeType === 'Change') {
            if (deleteFinancialAdviser) {
                auditTrail = changeRecordFromDeleteChangeSet(changeObject);
            } else if (!addFinancialAdviser) {
                auditTrail = changeRecordFromChangeSet(changeObject);
            } else {
                auditTrail = changeRecordFromAddChangeSet(changeObject);
            }
        }
        if (changeType === 'Delete') {
            auditTrail = changeRecordFromDeleteChangeSet(changeObject);
        }
        addAuditTrailDetailsView(returnView, auditTrail, getHeading(name));
    }

    function getHeading(name: string) {
        return subSectionName ? [`${sectionName} ${documentNumber}`, name] : [name];
    }
}

export const AuditTrailContactDetailsDeathView = withStyles(styles)(auditTrailContactDetailsView);
