import { createFeatureActionNamer } from '../../../../../../../redux/action-name';

const getActionName = createFeatureActionNamer('underwriting-non-disclosures');

export const ADD_UNDERWRITING_NON_DISCLOSURE = getActionName('ADD_UNDERWRITING_NON_DISCLOSURE');
export const UPDATE_UNDERWRITING_NON_DISCLOSURE = getActionName(
    'UPDATE_UNDERWRITING_NON_DISCLOSURE'
);
export const DELETE_UNDERWRITING_NON_DISCLOSURE = getActionName(
    'DELETE_UNDERWRITING_NON_DISCLOSURE'
);
