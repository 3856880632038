import { Grid } from '@material-ui/core';
import { formatCurrency, getDisplayDate } from '../../../../shared-functions';
import React from "react"; // eslint-disable-line
import { LabeledValue } from '../../../../controls/labeled-value';
import { Section } from '../../../../controls/section';
import { AssessmentDecision } from '../../../decisions/assessment-details-reducer';

interface OverheadProps {
    oebSumAssured?: number;
    decision?: AssessmentDecision;
}
export const OverheadExpenseBenefit = ({ oebSumAssured, decision }: OverheadProps) => {
    return (
        <Section title="Overhead Expense Benefit details">
            <Grid item xs={4}>
                <LabeledValue
                    label="OEB sum assured"
                    value={oebSumAssured && formatCurrency(oebSumAssured || 0)}
                />
            </Grid>
            <Grid item xs={4}>
                <LabeledValue
                    label="Approved date from"
                    value={getDisplayDate(decision && decision.effectiveDate)}
                />
            </Grid>
            <Grid item xs={4}>
                <LabeledValue
                    label="Approved date to"
                    value={getDisplayDate(decision && decision.expiryDate)}
                />
            </Grid>
        </Section>
    );
};
