import { AddRecordCollectionAction } from '../../../../../redux/collection-reducer';
import { AdditionalInformationRequiredRecord } from '../reducer';
import { ADD_ADDITIONAL_INFORMATION_REQUIRED } from '../types';

export type AddAdditionalInformationRequiredAction = AddRecordCollectionAction<AdditionalInformationRequiredRecord>;

export const addAdditionalInformationRequiredActionCreator = (
    additionalInformationRequired: AdditionalInformationRequiredRecord
): AddAdditionalInformationRequiredAction => {
    return {
        type: ADD_ADDITIONAL_INFORMATION_REQUIRED,
        record: additionalInformationRequired,
    };
};
