import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { LabeledValue } from '../../../../controls/labeled-value';
import { OpenModal, openModalAction } from '../../../../form-modal/actions';
import { ButtonWrapper } from '../../../../forms';
import { DisplayAddress } from '../../../../forms/sub-forms/address-details';
import { State } from '../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import {
    UpdateNoticeOfDeath,
    updateNoticeOfDeathAction,
} from '../../common/notice-of-death/actions/update-notice-of-death';
import {
    getNoticeOfDeath,
    NoticeOfDeath,
} from '../../common/notice-of-death/notice-of-death-reducer';
import { editNoticeOfDeath } from './notice-of-death';

interface Props extends StyledComponent {
    isReadOnly: boolean;
    hasDeferredContracts: boolean;
    noticeOfDeath: NoticeOfDeath;
    openModal: OpenModal;
    updateNoticeOfDeath: UpdateNoticeOfDeath;
}

const deceasedDetails = ({
    classes,
    noticeOfDeath,
    isReadOnly,
    hasDeferredContracts,
    ...rest
}: Props) => {
    const deceased = noticeOfDeath.deceased || {};
    const personalDetails = deceased.personalDetails || {};
    const addressDetails = deceased.addressDetails || {};
    const edit = () => {
        editNoticeOfDeath({ noticeOfDeath, index: 1, ...rest });
    };
    return (
        <Grid item xs={12} container justify="flex-start" className={classes.marginTop}>
            <Grid item xs={12} container>
                <Grid item xs={10}>
                    <Typography className={classes.heading} variant="body1">
                        Deceased Details
                    </Typography>
                </Grid>
                {!hasDeferredContracts && (
                    <ButtonWrapper>
                        <Grid item xs={2} className={classes.rightAlign}>
                            <Tooltip title="Edit Deceased's Details">
                                <Button id="EditDeceasedDetails" color="secondary" onClick={edit}>
                                    <Edit />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </ButtonWrapper>
                )}
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Still Birth" value={deceased.stillBirth} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Medical Report Received" value={deceased.medicalReport} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Foetus Age (weeks)" value={deceased.foetusAge} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Body Identifiable" value={deceased.bodyIdentifiable} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="First Name" value={personalDetails.firstName} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Second Name" value={personalDetails.secondName} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Surname" value={personalDetails.lastName} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="ID Number" value={personalDetails.idNumber} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Date of Birth"
                    value={getDisplayDate(personalDetails.dateOfBirth)}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Date of Death" value={getDisplayDate(deceased.dateOfDeath)} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Place of Death" value={deceased.placeOfDeath} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Place of Death Registration"
                    value={deceased.placeOfDeathReg}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Cause of Death" value={deceased.causeOfDeath} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Natural Death" value={deceased.naturalDeath} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Police Report" value={deceased.policeReport} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Hospital" value={deceased.hospital} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <DisplayAddress label="Residential Address" address={addressDetails} />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state: State) => ({
    noticeOfDeath: getNoticeOfDeath(state),
    isReadOnly: state.claimSession.readOnly,
    hasDeferredContracts: state.claimSession.hasDeferredContracts,
});

const mapDispatchToProps = {
    openModal: openModalAction,
    updateNoticeOfDeath: updateNoticeOfDeathAction,
};

export const DeceasedDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(deceasedDetails));
