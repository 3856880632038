export const DocumentName = [
    { value: 'Claimant statement', label: 'Claimant statement' },
    { value: 'ID document', label: 'ID document' },
    { value: 'ID Smart card', label: 'ID Smart card' },
    { value: 'Proof of account', label: 'Proof of account' },
    { value: 'Medical Certificate', label: 'Medical Certificate' },
    { value: 'Sick note', label: 'Sick note' },
    { value: 'Medical Claim Form', label: 'Medical Claim Form' },
    { value: 'Lab report', label: 'Lab report' },
    { value: 'Histology', label: 'Histology' },
    { value: 'Radiology report / X-Rays', label: 'Radiology report / X-Rays' },
    { value: 'MRI', label: 'MRI' },
    { value: 'Pathology report', label: 'Pathology report' },
    { value: 'Blood tests', label: 'Blood tests' },
    { value: 'ECG', label: 'ECG' },
    { value: 'OT report', label: 'OT report' },
    { value: 'Angiogram report', label: 'Angiogram report' },
    { value: 'Scan report', label: 'Scan report' },
    { value: 'Doctors report', label: 'Doctors report' },
    { value: 'List of medication', label: 'List of medication' },
    { value: 'Operation report', label: 'Operation report' },
    { value: 'Hospital report', label: 'Hospital report' },
    { value: 'Medical Aid records', label: 'Medical Aid records' },
    { value: 'Supporting medical evidence ', label: 'Supporting medical evidence ' },
    {
        value: 'Personal Medical Attendant report (PMA)',
        label: 'Personal Medical Attendant report (PMA)',
    },
    {
        value: 'Initial notification of intent to retrench',
        label: 'Initial notification of intent to retrench',
    },
    { value: 'Salary Slip', label: 'Salary Slip' },
    { value: 'Retrenchment letter', label: 'Retrenchment letter' },
    { value: 'I & E report', label: 'I & E report' },
    { value: 'Tax Certificate', label: 'Tax Certificate' },
    { value: 'Commission Statements', label: 'Commission Statements' },
    { value: 'UI19', label: 'UI19' },
    { value: 'UIF Card', label: 'UIF Card' },
    { value: 'Proof of monthly unemployment', label: 'Proof of monthly unemployment' },
    { value: 'Proof of payment', label: 'Proof of payment' },
    { value: 'Proof of income', label: 'Proof of income' },
    { value: 'UI6A', label: 'UI6A' },
    { value: 'Bank Statement', label: 'Bank Statement' },
    { value: 'Certificate of service', label: 'Certificate of service' },
    { value: 'Letter of employment', label: 'Letter of employment' },
    { value: 'Employer Declaration', label: 'Employer Declaration' },
    { value: 'Job description', label: 'Job description' },
    { value: 'Affidavit', label: 'Affidavit' },
    { value: 'Certificate of Continued Disability', label: 'Certificate of Continued Disability' },
    { value: 'Special Questionnaire', label: 'Special Questionnaire' },
    { value: 'Underwriting Questionnaire', label: 'Underwriting Questionnaire' },
    { value: 'Medical Report', label: 'Medical Report' },
    { value: 'Other', label: 'Other' },
];
