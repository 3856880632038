import React from 'react'; // eslint-disable-line

import { KeyboardDatePickerProps } from '@material-ui/pickers';
import { Field } from 'react-final-form';
import { DatePicker, FieldSet } from '../../';

interface Props extends Partial<KeyboardDatePickerProps> {
    name: string;
    validate?: (value: any, allValues: Record<string, any>) => any;
    minDateField?: string;
    maxDateField?: string;
    readOnly?: boolean;
    [otherProp: string]: any;
}
export const FullWidthDatePicker: React.FunctionComponent<Props> = ({
    name,
    minDateField,
    maxDateField,
    fullWidth = true,
    ...rest
}: Props & any) => {
    let DatePickerFieldElement = (
        <Field
            {...rest}
            name={name}
            fullWidth={fullWidth}
            minDateField={minDateField}
            maxDateField={maxDateField}
            component={DatePicker}
            type="text"
        />
    );
    if (!!minDateField) {
        DatePickerFieldElement = addMinDateField(DatePickerFieldElement, minDateField);
    }

    if (!!maxDateField) {
        DatePickerFieldElement = addMaxDateField(DatePickerFieldElement, maxDateField);
    }

    return <FieldSet>{DatePickerFieldElement}</FieldSet>;
};

function addMinDateField(
    field: React.ReactElement<any>,
    minDateField: string
): React.ReactElement<any> {
    return (
        <Field
            name={minDateField}
            subscription={{ value: true }}
            component={({ input: { value } }) => {
                let minDate = value as Date;
                if (minDate) {
                    minDate = new Date(value.getFullYear(), value.getMonth(), value.getDate());
                }
                return React.cloneElement(field, { minDate: minDate || '1900-01-01' });
            }}
        />
    );
}

function addMaxDateField(
    field: React.ReactElement<any>,
    maxDateField: string
): React.ReactElement<any> {
    return (
        <Field
            name={maxDateField}
            subscription={{ value: true }}
            component={({ input: { value } }) =>
                React.cloneElement(field, { maxDate: value || '2100-01-01' })
            }
        />
    );
}
