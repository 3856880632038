/* eslint-disable react/prop-types */
import React from 'react'; // eslint-disable-line
import { Button, Grid, InputAdornment, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import {
    FullWidthSearchSelectField,
    FullWidthTextField,
    GenericForm,
    WhenFieldChanges,
} from '../../../../../../forms';
import { State } from '../../../../../../redux/root-reducer';
import {
    ClaimantRequirements,
    getRequirementsFromClaim,
} from '../../../../common/requirements-management/reducer';
import { Add, Delete } from '@material-ui/icons';
import { RequiredDocumentsView } from '../../../../common/requirements-management/views';
import {
    isEmpty,
    conditionalRequired,
    requiredValidator,
} from '../../../../../../forms/validations';
import { Claim, getClaim } from '../../../../../../claim';
import { BeneficiaryOption } from '../../../../../../payment/reducer';
import { EmailMask } from '../../../../../../forms/input-masks/email-mask';
import { FuneralRequirements } from '../../reducer';
import { closeModalAction } from '../../../../../../form-modal/actions';
import { DocumentOwnerType } from '../../../../../../shared-types';
import { ClaimType } from '../../../../../../claim/claim-types';
import { FuneralClaim } from '../../../../../../claim/reducers/claim-reducers/funeral-claim-reducer';
import {
    parseStringToDate,
    getDisplayNameFromAdName,
    getFullName,
} from '../../../../../../shared-functions';
import { Titles } from '../../../../../../codes/titles';
import {
    LifeAssuredDetails,
    getLifeAssuredDetails,
} from '../../../../../../life-assured-details/reducer';
import { FormSpy } from 'react-final-form';
import { FormApi } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { ClassNameMap } from '@material-ui/styles';
import { viewModalAction } from '../../../../../../confirmation-dialog';
import { communicationAction } from '../../action/communication-action';
import { AuthState } from '../../../../../../auth/auth-reducer';

type InnerProps = {
    form: FormApi<any>;
};

type Props = StyledComponent &
    InnerProps & {
        claimantRequirements: ClaimantRequirements[];
        claim: Claim;
        isShowDocumentRequirements?: boolean;
        lifeAssuredDetails: LifeAssuredDetails;
        isUnderInvestigation?: boolean;
        policyNumber?: string;
        isDocumentRequested?: boolean;
    };

type InnerFormProps = {
    claimants: any;
    documents: any;
    updateRequirements: any;
    updateModalData: any;
    currentUser: AuthState;
};
export interface Communications {
    communicationsClaimant?: string;
    communicationsEmailAddress?: string;
    communicationsAdditionalEmails?: Array<any>;
    requiredDocumentsBody?: string;
    communicationsPolicy: string;
}

const CommunicationsForm = GenericForm<Communications>();

const CommunicationEdit: React.FunctionComponent<any> = ({
    classes,
    claimantRequirements,
    claim,
    currentUser,
    communicationAction,
    lifeAssuredDetails,
    closeModal,
    updateModalData,
    isShowDocumentRequirements,
    isUnderInvestigation,
    policyNumber,
    isDocumentRequested,
    ...rest
}: any) => {
    const deseasedDocs = claim.requirements.lifeAssured.documents.filter(
        (document: any) => document.status === 'Requested'
    );
    isDocumentRequested = false;
    let isLifeAssuredDocRequested = false;
    if (deseasedDocs.length > 0) {
        isLifeAssuredDocRequested = true;
    }

    claim.requirements.policyOwner &&
        claim.requirements.policyOwner.documents.forEach((document: { status: string }) => {
            if (document.status === 'Requested') {
                isDocumentRequested = true;
            }
        });

    const claimants = getClaimantOptions(
        claim,
        isDocumentRequested,
        isLifeAssuredDocRequested,
        claimantRequirements
    );

    let requirements: any = [];
    let documents: any = [];
    const updateRequirements = (id: string) => {
        requirements = claimantRequirements.find((item: any) => item.id === id);

        documents =
            requirements && requirements.documents
                ? requirements.documents.filter((document: any) => document.status === 'Requested')
                : [];

        const lifeAssuredDocs = claim.requirements.lifeAssured.documents.filter(
            (document: any) => document.status === 'Requested'
        );

        lifeAssuredDocs.forEach(
            (document: { id: any; status: any; reason: any; dateRequested: any; name: any }) => {
                documents.push({
                    id: document.id,
                    status: document.status || '',
                    reason: document.reason,
                    dateRequested: document.dateRequested,
                    name: document.name,
                });
            }
        );
        return documents;
    };

    const onSubmit = async (values: Communications) => {
        const getDocuments = () => {
            const docs: Array<string | undefined> = [];
            documents.map((a: any) => {
                docs.push(a.name);
                return docs;
            });
            return docs.toString();
        };

        const policyOwnerIndex = claimantRequirements.findIndex(
            (item: any) => item.id === DocumentOwnerType.PolicyOwner
        );

        if (policyOwnerIndex > -1) claimantRequirements.splice(policyOwnerIndex, 1);

        const creationDate = sessionStorage.getItem('creationDate');
        communicationAction({
            to: values.communicationsEmailAddress,
            ccs: values.communicationsAdditionalEmails
                ? values.communicationsAdditionalEmails.filter(x => x !== null && x !== undefined)
                : [],
            requiredDocuments: getDocuments(),
            claimantId: values.communicationsClaimant,
            claimantName: getName(values.communicationsClaimant),
            claimDate: parseStringToDate(creationDate !== null ? creationDate : ''),
            claimantPolicy: values.communicationsPolicy,
            assessor: getDisplayNameFromAdName(currentUser.name),
            communicationType: isUnderInvestigation
                ? 'UNDER INVESTIGATION'
                : isShowDocumentRequirements
                ? 'MISSING INFORMATION'
                : 'FUNERAL CLAIM ASSESSOR APPOINTED',
        }).then(() => closeModal());
    };

    const getName = (val: any) => {
        const claimant: any = claimants.find(item => item.value === val);
        return claimant.label;
    };

    const initialValues = {
        communicationsClaimant: '',
        communicationsEmailAddress: '',
        requiredDocumentsBody: '',
        communicationsTitle: '',
        communicationsPolicy: '',
        claimantName: '',
        communicationsAdditionalEmails: [null],
    };

    return (
        <CommunicationsForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            {...rest}
            mutators={{
                // potentially other mutators could be merged here
                ...arrayMutators,
            }}
        >
            {() =>
                FormSpy<any>({
                    render: ({ form }) =>
                        InnerForm({
                            claimantRequirements,
                            claim,
                            currentUser,
                            lifeAssuredDetails,
                            classes,
                            updateRequirements,
                            isShowDocumentRequirements,
                            isUnderInvestigation,
                            policyNumber,
                            claimants,
                            documents,
                            form,
                            updateModalData,
                        }),
                })
            }
        </CommunicationsForm>
    );
};

export function getClaimantOptions(
    claim: Claim,
    isDocumentRequested?: boolean,
    isLifeAssuredDocRequested?: boolean,
    claimantDocs?: any
) {
    const commsClaimants: BeneficiaryOption[] = [];

    if (claim.claimType === ClaimType.Funeral) {
        const funeralClaim = claim as FuneralClaim;
        if (funeralClaim.formDetails) {
            const policyOwner = funeralClaim.formDetails.policyOwner;
            const requirements = claimantDocs?.find((item: any) => item.id === 'Policy Owner');
            const missingDocuments =
                requirements && requirements.documents
                    ? requirements.documents.filter(
                          (document: any) => document.status === 'Requested'
                      )
                    : [];
            if (missingDocuments.length > 0 || isDocumentRequested || isLifeAssuredDocRequested) {
                if (policyOwner && !isEmpty(policyOwner)) {
                    if (
                        !commsClaimants.includes({
                            value: DocumentOwnerType.PolicyOwner,
                            label: getFullName(policyOwner.personalDetails),
                        })
                    )
                        commsClaimants.push({
                            value: DocumentOwnerType.PolicyOwner,
                            label: getFullName(policyOwner.personalDetails),
                            title: getByTitle(policyOwner.personalDetails),
                            email: getEmailAddress(policyOwner.contactDetails),
                            surname: getSurname(policyOwner.personalDetails),
                        });
                }
            }
            const claimants = funeralClaim.formDetails.claimants;
            if (claimants) {
                claimants.forEach(claimant => {
                    const requirements = claimantDocs.find((item: any) => item.id === claimant.id);
                    const missingDocuments =
                        requirements && requirements.documents
                            ? requirements.documents.filter(
                                  (document: any) => document.status === 'Requested'
                              )
                            : [];

                    if (claimant.id) {
                        if (
                            !commsClaimants.includes({
                                value: claimant.id || '',
                                label: getFullName(claimant.personalDetails),
                            })
                        )
                            if (missingDocuments.length > 0 || isLifeAssuredDocRequested) {
                                commsClaimants.push({
                                    value: claimant.id || '',
                                    label: getFullName(claimant.personalDetails),
                                    title: getByTitle(claimant.personalDetails),
                                    email: getEmailAddress(claimant.contactDetails),
                                    surname: getSurname(claimant.personalDetails),
                                });
                            }
                    }
                });
            }
        }
    }

    return commsClaimants;
}

const getByTitle = (personalDetails: any) => {
    return personalDetails?.title;
};

const getSurname = (personalDetails: any) => {
    return personalDetails?.lastName;
};

const getEmailAddress = (contactDetails: any) => {
    return contactDetails?.emailAddress;
};

const InnerForm = ({
    claimantRequirements,
    claim,
    currentUser,
    lifeAssuredDetails,
    classes,
    updateRequirements,
    claimants,
    documents,
    form,
    isShowDocumentRequirements,
    isUnderInvestigation,
    policyNumber,
    updateModalData,
}: Props & InnerFormProps) => {
    const policyOwnerRequirements = (getRequirementsFromClaim(claim) as FuneralRequirements)
        .policyOwner;

    if (
        policyOwnerRequirements &&
        policyOwnerRequirements.documents &&
        policyOwnerRequirements.documents.length > 0
    ) {
        if (!claimantRequirements?.some(item => item.id === DocumentOwnerType.PolicyOwner))
            claimantRequirements.push({
                ...policyOwnerRequirements,
                id: DocumentOwnerType.PolicyOwner,
            });
    }

    const policies: any = [];

    lifeAssuredDetails &&
        lifeAssuredDetails.contracts.map((x: any) => {
            policies.push({ label: x.contractNumber, value: x.contractNumber });
            return policies;
        });

    const updateTitle = (val: string) => {
        const claimant: any = claimants.find((item: any) => item.value === val);
        if (val !== '') {
            return claimant?.title;
        }
        updateModalData({
            form: form.getState()?.values,
        });
    };

    const emailAddress = (val: string) => {
        const claimant: any = claimants.find((item: any) => item.value === val);
        if (val !== '') {
            return claimant?.email;
        }
    };

    const claimantSurname = (val: string) => {
        const claimant: any = claimants.find((item: any) => item.value === val);
        if (val !== '') {
            return claimant?.surname;
        }
    };

    const uodatePolicy = () => {
        updateModalData({
            form: form.getState()?.values,
        });
    };

    const { push } = form.mutators;

    return (
        <Grid container item xs={12}>
            <Grid>
                <Grid container style={{ marginBottom: '-40px' }}>
                    {isShowDocumentRequirements && (
                        <Grid item xs={6} lg={6} style={{ marginTop: '20px' }}>
                            <Typography className={classes.heading} variant="body2">
                                Requested Documentation
                            </Typography>
                            <RequiredDocumentsView documents={documents} isElevated={true} />
                        </Grid>
                    )}
                    <Grid item xs={6} lg={6}>
                        <Grid container spacing={5} style={{ marginBottom: '120px' }}>
                            <Grid item xs={12} lg={12}>
                                <FullWidthSearchSelectField
                                    name="communicationsClaimant"
                                    label="Claimant"
                                    validate={requiredValidator}
                                    helperText="Name of claimant"
                                    options={claimants}
                                />
                            </Grid>
                            <WhenFieldChanges
                                field={'communicationsClaimant'}
                                set={'communications'}
                                to={(id: any) => {
                                    if (id !== '') {
                                        updateModalData({
                                            form: form.getState()?.values,
                                        });
                                        updateRequirements(id);
                                        return id;
                                    }
                                }}
                            />
                            {isUnderInvestigation !== undefined && isUnderInvestigation && (
                                <>
                                    <Grid item xs={12} lg={12}>
                                        <FullWidthTextField
                                            name="communicationsSurname"
                                            label="Claimant surname"
                                            validate={requiredValidator}
                                            helperText="Surname of claimant"
                                        />
                                    </Grid>
                                    <WhenFieldChanges
                                        field={'communicationsClaimant'}
                                        set={'communicationsSurname'}
                                        to={(val: string) => claimantSurname(val)}
                                    />
                                </>
                            )}
                            <Grid item xs={12} lg={12}>
                                <FullWidthSearchSelectField
                                    name="communicationsTitle"
                                    label="Title"
                                    validate={requiredValidator}
                                    helperText="Title of claimant"
                                    options={Titles}
                                />
                                <WhenFieldChanges
                                    field={'communicationsClaimant'}
                                    set={'communicationsTitle'}
                                    to={(val: string) => updateTitle(val)}
                                />
                                <WhenFieldChanges
                                    field={'communicationsTitle'}
                                    set={'test'}
                                    to={(val: string) => updateTitle(val)}
                                />
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <FullWidthTextField
                                    name="communicationsEmailAddress"
                                    label="E-mail Address"
                                    validate={requiredValidator}
                                    mask={EmailMask}
                                    helperText="Recipient of E-mail who is required to send requested documentation"
                                />
                                <WhenFieldChanges
                                    field={'communicationsClaimant'}
                                    set={'communicationsEmailAddress'}
                                    to={(val: string) => emailAddress(val)}
                                />
                            </Grid>
                            {(isUnderInvestigation === undefined || !isUnderInvestigation) && (
                                <>
                                    <Grid item xs={12} lg={12}>
                                        <FieldArray
                                            name="communicationsAdditionalEmails"
                                            key={`communicationsAdditionalEmails`}
                                        >
                                            {({ fields }) =>
                                                fields.map(addtionalEmailRow(form, fields, classes))
                                            }
                                        </FieldArray>
                                    </Grid>
                                    <Grid item xs={12} lg={12}>
                                        <Button
                                            startIcon={<Add />}
                                            variant="contained"
                                            color="secondary"
                                            name="addAdditionalEmail"
                                            fullWidth
                                            disableElevation
                                            style={{ transform: 'none' }}
                                            onClick={() =>
                                                push('communicationsAdditionalEmails', undefined)
                                            }
                                        >
                                            Add Email Address
                                        </Button>
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12} lg={12}>
                                <FullWidthSearchSelectField
                                    name="communicationsPolicy"
                                    label="Policy"
                                    validate={requiredValidator}
                                    helperText="Policy for which the claim is being made"
                                    options={policies}
                                />
                                <WhenFieldChanges
                                    field={'communicationsPolicy'}
                                    set={'test'}
                                    to={(_val: string) => uodatePolicy()}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

function addtionalEmailRow(form: any, fields: any, classes: ClassNameMap<string>) {
    const remove = (fields: any, index: number) => {
        if (fields.length > 1) {
            fields.remove(index);
        } else {
        }
    };

    return function _addtionalEmailRow(_policyNumber: any, index: number) {
        return (
            <React.Fragment key={`communicationsAdditionalEmails[${index}]`}>
                <FullWidthTextField
                    name={`communicationsAdditionalEmails[${index}]`}
                    label="Additional email addresses"
                    helperText="Additional recipient to receive E-mail communication"
                    mask={EmailMask}
                    validate={conditionalRequired(
                        values =>
                            (values &&
                                values.communicationsAdditionalEmails &&
                                values.communicationsAdditionalEmails[index + 1] &&
                                values.communicationsAdditionalEmails[index + 1] !== undefined &&
                                values.communicationsAdditionalEmails[index + 1] !== null) ||
                            index > 0
                    )}
                    endadornment={
                        fields.length > 1 ? (
                            <InputAdornment position="end">
                                <Delete
                                    style={{ cursor: 'default' }}
                                    color="error"
                                    onClick={() => remove(fields, index)}
                                />
                            </InputAdornment>
                        ) : undefined
                    }
                />
            </React.Fragment>
        );
    };
}

const mapStateToProps = (state: State) => ({
    claim: getClaim(state),
    currentUser: state.auth as AuthState,
    lifeAssuredDetails: getLifeAssuredDetails(state),
    claimantRequirements:
        (getRequirementsFromClaim(getClaim(state)) as FuneralRequirements).claimants || [],
});

const mapDispatchToProps = {
    communicationAction: communicationAction,
    closeModal: closeModalAction,
    updateModalData: viewModalAction,
};

export const CommunicationEditView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(CommunicationEdit));
