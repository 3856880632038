import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { OpenDialog, openDialogAction } from '../../confirmation-dialog';
import { SetSubmissionCallbacks, setSubmissionCallbacks } from '../actions';
import { HandleSubmit } from '../actions/submit-form';

interface ComponentState {
    hasValidationErrors?: boolean;
    pristine?: boolean;
}

interface Props extends ComponentState {
    handleSubmit: HandleSubmit;
    openDialog: OpenDialog;
    setSubmissionCallbacks: SetSubmissionCallbacks;
}

class FormSubmitterClass extends React.Component<Props, ComponentState> {
    public constructor(props: Props) {
        super(props);
        this.state = this.getState(props);
    }
    public componentDidMount() {
        this.props.setSubmissionCallbacks(
            () => this.onSubmit(),
            () => this.isPristine(),
            () => this.hasValidationErrors()
        );
    }
    public componentWillUnmount() {
        this.props.setSubmissionCallbacks();
    }
    public componentDidUpdate(oldProps: Props) {
        if (
            this.props.pristine === oldProps.pristine &&
            this.props.hasValidationErrors === oldProps.hasValidationErrors
        ) {
            return;
        }
        this.setState(this.getState(this.props));
    }
    public render() {
        return null;
    }
    public async onSubmit() {
        return this.props.handleSubmit ? await this.props.handleSubmit() : true;
    }

    public isPristine(): boolean {
        return this.state.pristine === undefined || this.state.pristine;
    }

    public hasValidationErrors(): boolean {
        return !this.isPristine() && !!this.state.hasValidationErrors;
    }

    private getState(props: Props): ComponentState {
        return {
            hasValidationErrors: props.hasValidationErrors,
            pristine: props.pristine,
        };
    }
}
const mapStateToProps = () => ({});
const mapDispatchToProps = {
    openDialog: openDialogAction,
    setSubmissionCallbacks,
};
export const FormSubmitter = connect(mapStateToProps, mapDispatchToProps)(FormSubmitterClass);
