import React from 'react'; // eslint-disable-line

import { Field } from 'react-final-form';
import { FieldSet, SearchSelectField } from '../..';

interface Props {
    id?: string;
    name: string;
    label?: string;
    options?: any;
    sortOptions?: boolean;
}

export const FullWidthSearchSelectField: React.FunctionComponent<Props & any> = ({
    name,
    options,
    ...rest
}: Props & any) => {
    return (
        <FieldSet>
            <Field {...rest} name={name} component={SearchSelectField} options={options} />
        </FieldSet>
    );
};
