import { UpdateRecordCollectionAction } from '../../../../../../../redux/collection-reducer';
import { UnderwritingReferral } from '../underwriting-referral-model';
import { UPDATE_UNDERWRITING_REFERRAL } from './underwriting-referral-action-types';

export type UpdateUnderwritingReferralAction = UpdateRecordCollectionAction<UnderwritingReferral>;

export const updateUnderwritingReferralActionCreator = (
    underwritingReferralIndex: number,
    underwritingReferral: UnderwritingReferral
): UpdateUnderwritingReferralAction => {
    return {
        type: UPDATE_UNDERWRITING_REFERRAL,
        record: underwritingReferral,
        index: underwritingReferralIndex,
    };
};
