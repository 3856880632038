import { Divider, Paper, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { GenericForm } from '../../../../forms';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { OnSubmitSuccessProps } from '../../../../workflow-navigation';
import {
    UpdateNoticeOfDeath,
    updateNoticeOfDeathAction,
} from '../../common/notice-of-death/actions';
import {
    getNoticeOfDeath,
    NoticeOfDeath,
} from '../../common/notice-of-death/notice-of-death-reducer';

interface Props extends OnSubmitSuccessProps, StyledComponent {
    noticeOfDeath: NoticeOfDeath;
    title: string;
    updateNoticeOfDeath: UpdateNoticeOfDeath;
    view: React.ReactNode;
}

const DeceasedDetailsForm = GenericForm<NoticeOfDeath>();
const noticeOfDeathForm: React.FunctionComponent<Props> = ({
    classes,
    noticeOfDeath,
    title,
    updateNoticeOfDeath,
    view,
    ...rest
}: Props) => {
    const onSubmit = (noticeOfDeath: NoticeOfDeath) => {
        return updateNoticeOfDeath(noticeOfDeath);
    };

    return (
        <DeceasedDetailsForm onSubmit={onSubmit} initialValues={noticeOfDeath} {...rest}>
            <Typography variant="h5">{title}</Typography>
            <Divider />
            <Paper className={classes.paper}>{view}</Paper>
        </DeceasedDetailsForm>
    );
};

const mapDispatchToProps = { updateNoticeOfDeath: updateNoticeOfDeathAction };

const mapStateToProps = (state: State) => ({ noticeOfDeath: getNoticeOfDeath(state) });

export const NoticeOfDeathForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(noticeOfDeathForm));
