const digitRegex = /\d/;
export const TelephoneNumberMask: (value: string) => (string | RegExp)[] = (value: string) => {
    if (value[0] === '+') {
        return internationalTelephoneNumberMask(value);
    }

    if (value[0] === '0') {
        return nationalTelephoneNumberMask(value);
    }

    return [/[+0]/];
};

const internationalTelephoneNumberMask = (value: string) => {
    const numberMask: (string | RegExp)[] = [/\+/];
    return numberMask.concat(telephoneNumberMask(value.slice(1)));
};

const nationalTelephoneNumberMask = (value: string) => {
    const numberMask: (string | RegExp)[] = [/0/];
    return numberMask.concat(telephoneNumberMask(value.slice(1)));
};

const telephoneNumberMask = (value: string): (string | RegExp)[] => {
    const numberMask: (string | RegExp)[] = [];
    let digitCount = 0;

    for (const char of value.split('').slice()) {
        if (digitRegex.test(char)) {
            numberMask.push(digitRegex);
            if (++digitCount === 15) {
                return numberMask;
            }
        }
    }

    return numberMask;
};
