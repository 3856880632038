import { Nullable } from '../../../shared-types';
import parseName from '../parse-name/index';

interface Props {
    name: string;
    id?: Nullable<string>;
}

export function getComponentId({ name, id }: Props): string {
    const _id = parseId(id);
    if (_id) {
        return _id;
    }
    const _name = parseName(name, { stripBeforeLastDot: false });
    return _name;
}

function parseId(id?: Nullable<string>) {
    if (id) {
        return id.trim();
    }
    return id;
}
