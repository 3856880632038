import { withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { getClaim } from '../../../../../claim';
import { Submit, submitAction } from '../../../../../claim-information/actions/submit';
import { ClaimInformation, getClaimInformation } from '../../../../../claim-information/reducer';
import { OccupationalDutiesBreakdownView } from '../../../../../claim-information/views/occupational-duties-breakdown';
import { ClaimType } from '../../../../../claim/claim-types';
import { FormErrors, GenericForm } from '../../../../../forms';
import { occupationalDutiesBreakdownTableTotalDecorator } from '../../../../../occupation-details';
import { State } from '../../../../../redux/root-reducer';
import { Nullable } from '../../../../../shared-types';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { OnSubmitSuccessProps } from '../../../../../workflow-navigation';

interface Props extends StyledComponent, OnSubmitSuccessProps {
    claimType?: Nullable<ClaimType>;
    claimInformation: ClaimInformation;
    submit: Submit;
}

const OccupationDetailsForm = GenericForm<ClaimInformation>();
const OccupationDetailsEditModal: React.FunctionComponent<Props> = ({
    claimType,
    claimInformation,
    submit,
    ...rest
}: Props) => {
    return (
        <OccupationDetailsForm
            onSubmit={submit}
            initialValues={claimInformation}
            decorators={[occupationalDutiesBreakdownTableTotalDecorator]}
            {...rest}
        >
            <OccupationalDutiesBreakdownView claimType={claimType} isSynopsis />
            <FormErrors />
        </OccupationDetailsForm>
    );
};

const mapStateToProps = (state: State) => ({
    claimInformation: getClaimInformation(state),
    claimType: getClaim(state).claimType,
});

const mapDispatchToProps = {
    submit: submitAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(OccupationDetailsEditModal));
