import React from 'react'; // eslint-disable-line

import { Button, Grid, Tooltip, Paper, withStyles } from '@material-ui/core';
import { Delete, AddCircle } from '@material-ui/icons';
import { FormSpy } from 'react-final-form';
import {
    FullWidthRadioGroup,
    RadioOption,
    FullWidthTextField,
    FormHeading,
    FullWidthSearchSelectField,
    Condition,
    True,
    False,
    When,
    WhenFieldChanges,
} from '../../../../../forms';
import { requiredValidator, conditionalRequired } from '../../../../../forms/validations';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { FinancialBreakdown, Dependant } from '../reducer';
import { CurrencyMask } from '../../../../../forms/input-masks';
import { convertToNumber } from '../../../../../shared-functions';
import { FinancialAdviserDetailsEditView } from './edit-financial-adviser-details';

interface ComponentState {
    breakdown: FinancialBreakdown[];
}

interface Props extends ComponentState, StyledComponent {}

class FinancialInformationViewClass extends React.Component<Props, ComponentState> {
    public constructor(props: Props) {
        super(props);
    }

    public componentDidMount() {
        this.setState({
            breakdown: this.props.breakdown,
        });
    }

    public render() {
        const classes = this.props ? this.props.classes : {};
        const breakdown = this.state ? this.state.breakdown : this.props.breakdown;
        const addEntry = () => {
            breakdown.push({});
            this.setState({
                breakdown: breakdown,
            });
        };
        const deleteEntry = (index: number) => {
            breakdown.splice(index, 1);
            this.setState({
                breakdown: breakdown,
            });
        };
        return (
            <Paper className={classes.paper}>
                <FormHeading text="Financial details" />
                <Grid item xs={12}>
                    <FullWidthRadioGroup
                        name="financialInformation.dependentOnLifeAssured"
                        label="Was dependant on life assured"
                        validate={requiredValidator}
                    >
                        <RadioOption value="Yes" />
                        <RadioOption value="No" />
                    </FullWidthRadioGroup>
                </Grid>
                <WhenFieldChanges
                    field="relationship"
                    set="financialInformation.receivedMaintenance"
                    to={null}
                />
                <Condition when="relationship" is="ExSpouse">
                    <True>
                        <Grid item xs={12}>
                            <FullWidthRadioGroup name="financialInformation.receivedMaintenance">
                                <RadioOption value="Yes" />
                                <RadioOption value="No" />
                            </FullWidthRadioGroup>
                        </Grid>
                    </True>
                </Condition>
                {breakdown.map((pm, index) => {
                    return (
                        <Grid
                            item
                            container
                            spacing={5}
                            key={`pm_${index}`}
                            style={{ paddingLeft: '8px' }}
                        >
                            <Condition when="relationship" is="MinorChild">
                                <True>
                                    <MinorChildBreakdownView
                                        index={index}
                                        deleteEntry={() => deleteEntry(index)}
                                    />
                                </True>
                                <False>
                                    <BreakdownView
                                        index={index}
                                        deleteEntry={() => deleteEntry(index)}
                                    />
                                </False>
                            </Condition>
                            <WhenFieldChanges
                                field={`financialInformation.breakdown[${index}].incomeExpenditureDescription`}
                                set={`financialInformation.breakdown[${index}].incomeExpenditureDetails`}
                                to={null}
                            />
                        </Grid>
                    );
                })}
                <Grid item xs={12} container justify="flex-end">
                    <Tooltip title="Add Income/Expenditure">
                        <Button id={'Add_breakdown'} color="secondary" onClick={() => addEntry()}>
                            <AddCircle />
                        </Button>
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <FullWidthRadioGroup
                        name="financialInformation.hasFinancialAdviser"
                        validate={requiredValidator}
                    >
                        <RadioOption value="Yes" />
                        <RadioOption value="No" />
                    </FullWidthRadioGroup>
                    <WhenFieldChanges
                        field="financialInformation.hasFinancialAdviser"
                        becomes="Yes"
                        set="financialInformation.financialAdviceConsent"
                        to={null}
                    />
                    <WhenFieldChanges
                        field="financialInformation.hasFinancialAdviser"
                        becomes="No"
                        set="financialInformation.financialAdviser"
                        to={null}
                    />
                </Grid>
                <Condition when="financialInformation.hasFinancialAdviser" matchesChild>
                    <When is="No">
                        <Grid item xs={12}>
                            <FullWidthRadioGroup
                                name="financialInformation.financialAdviceConsent"
                                validate={requiredValidator}
                            >
                                <RadioOption value="Yes" />
                                <RadioOption value="No" />
                            </FullWidthRadioGroup>
                        </Grid>
                    </When>
                    <When is="Yes">
                        <FinancialAdviserDetailsEditView propertyName="financialInformation.financialAdviser" />
                    </When>
                </Condition>
            </Paper>
        );
    }
}

interface BreakdownProps {
    index: number;
    deleteEntry: (index: number) => void;
}

const BreakdownView: React.FunctionComponent<BreakdownProps> = ({ index, deleteEntry }) => {
    return FormSpy<Dependant>({
        // eslint-disable-next-line
        render: ({ form }) => {
            const deleteValues = (occurence: number) => {
                // eslint-disable-next-line
                const financialInformation = form.getState().values.financialInformation;
                if (
                    financialInformation !== undefined &&
                    financialInformation.breakdown !== undefined
                ) {
                    financialInformation.breakdown.splice(occurence, 1);
                }
            };
            return (
                <React.Fragment>
                    <Grid item xs={4} container justify="flex-start">
                        <Grid
                            item
                            xs={1}
                            style={{
                                paddingTop: '36px',
                                marginLeft: '-8px',
                                marginRight: '8px',
                            }}
                        >
                            <NumberedCircle value={index + 1} />
                        </Grid>
                        <Grid item xs={11}>
                            <FullWidthSearchSelectField
                                name={`financialInformation.breakdown[${index}].incomeExpenditureType`}
                                label="Type"
                                options={IncomeOrExpenditure}
                                validate={requiredValidator}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <FullWidthSearchSelectField
                            name={`financialInformation.breakdown[${index}].incomeExpenditureDescription`}
                            label="Description"
                            options={IncomeOrExpenseDescriptions}
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FullWidthTextField
                            name={`financialInformation.breakdown[${index}].incomeExpenditure`}
                            label="Amount"
                            validate={requiredValidator}
                            mask={CurrencyMask}
                            parse={convertToNumber}
                            helperText="in Rands per month"
                        />
                    </Grid>
                    <Condition
                        when={`financialInformation.breakdown[${index}].incomeExpenditureDescription`}
                        is="Other"
                    >
                        <True>
                            <Grid
                                item
                                xs={12}
                                container
                                spacing={5}
                                justify="flex-start"
                                style={{ paddingLeft: '40px' }}
                            >
                                <Grid item xs={4}>
                                    <FullWidthTextField
                                        name={`financialInformation.breakdown[${index}].incomeExpenditureDetails`}
                                        label="Details"
                                        validate={requiredValidator}
                                    />
                                </Grid>
                            </Grid>
                        </True>
                    </Condition>
                    <Grid item xs={4} style={{ paddingLeft: '40px' }}>
                        <FullWidthTextField
                            name={`financialInformation.breakdown[${index}].maintenance`}
                            label="Life assured's contribution"
                            validate={conditionalRequired(
                                values =>
                                    values &&
                                    values.financialInformation &&
                                    values.financialInformation.breakdown &&
                                    values.financialInformation.breakdown[index] &&
                                    values.financialInformation.breakdown[index]
                                        .maintenanceFrequency
                            )}
                            mask={CurrencyMask}
                            parse={convertToNumber}
                            helperText="in Rands"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FullWidthSearchSelectField
                            name={`financialInformation.breakdown[${index}].maintenanceFrequency`}
                            label="Frequency of contribution"
                            options={Frequency}
                            validate={conditionalRequired(
                                values =>
                                    values &&
                                    values.financialInformation &&
                                    values.financialInformation.breakdown &&
                                    values.financialInformation.breakdown[index] &&
                                    values.financialInformation.breakdown[index].maintenance
                            )}
                        />
                    </Grid>
                    <Grid item xs={4} container justify="flex-end">
                        <Tooltip
                            title="Delete"
                            style={{
                                marginTop: '30px',
                            }}
                        >
                            <Button
                                id={`Delete_breakdown_${index}`}
                                color="secondary"
                                onClick={() => {
                                    deleteValues(index);
                                    deleteEntry(index);
                                }}
                            >
                                <Delete />
                            </Button>
                        </Tooltip>
                    </Grid>
                </React.Fragment>
            );
        },
    });
};

const MinorChildBreakdownView: React.FunctionComponent<BreakdownProps> = ({
    index,
    deleteEntry,
}: BreakdownProps) => {
    return FormSpy<Dependant>({
        // eslint-disable-next-line
        render: ({ form }) => {
            const deleteValues = (occurence: number) => {
                // eslint-disable-next-line
                const financialInformation = form.getState().values.financialInformation;
                if (
                    financialInformation !== undefined &&
                    financialInformation.breakdown !== undefined
                ) {
                    financialInformation.breakdown.splice(occurence, 1);
                }
            };
            return (
                <React.Fragment>
                    <Grid item xs={4} container justify="flex-start">
                        <Grid
                            item
                            xs={1}
                            style={{
                                paddingTop: '36px',
                                marginLeft: '-8px',
                                marginRight: '8px',
                            }}
                        >
                            <NumberedCircle value={index + 1} />
                        </Grid>
                        <Grid item xs={11}>
                            <FullWidthSearchSelectField
                                name={`financialInformation.breakdown[${index}].incomeExpenditureDescription`}
                                label="Nature of dependency"
                                options={IncomeOrExpenseDescriptions}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <FullWidthTextField
                            name={`financialInformation.breakdown[${index}].maintenance`}
                            label="Life assured's contribution"
                            validate={requiredValidator}
                            mask={CurrencyMask}
                            parse={convertToNumber}
                            helperText="in Rands"
                        />
                    </Grid>
                    <Grid item xs={4} container justify="flex-start">
                        <Grid item xs={11} style={{ paddingRight: '8px', marginRight: '-14px' }}>
                            <FullWidthSearchSelectField
                                name={`financialInformation.breakdown[${index}].maintenanceFrequency`}
                                label="Frequency of contribution"
                                options={Frequency}
                                validate={requiredValidator}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Tooltip title="Delete" style={{ marginTop: '30px' }}>
                                <Button
                                    id={`Delete_breakdown_${index}`}
                                    color="secondary"
                                    onClick={() => {
                                        deleteValues(index);
                                        deleteEntry(index);
                                    }}
                                >
                                    <Delete />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Condition
                        when={`financialInformation.breakdown[${index}].incomeExpenditureDescription`}
                        is="Other"
                    >
                        <True>
                            <Grid
                                item
                                xs={12}
                                container
                                spacing={5}
                                justify="flex-start"
                                style={{ paddingLeft: '40px' }}
                            >
                                <Grid item xs={4}>
                                    <FullWidthTextField
                                        name={`financialInformation.breakdown[${index}].incomeExpenditureDetails`}
                                        label="Nature of dependency details"
                                        validate={requiredValidator}
                                    />
                                </Grid>
                            </Grid>
                        </True>
                    </Condition>
                </React.Fragment>
            );
        },
    });
};

const Frequency = [
    { value: 'Monthly', label: 'Monthly' },
    { value: 'Quarterly', label: 'Quarterly' },
    { value: 'Bi-annually', label: 'Bi-annually' },
    { value: 'Annually', label: 'Annually' },
];

const IncomeOrExpenditure = [
    { value: 'Income', label: 'Income' },
    { value: 'Expense', label: 'Expense' },
];

const IncomeOrExpenseDescriptions = [
    { value: 'Accommodation', label: 'Accommodation' },
    { value: 'Clothes', label: 'Clothes' },
    { value: 'Bond', label: 'Bond' },
    { value: 'Transport', label: 'Transport' },
    { value: 'Groceries', label: 'Groceries' },
    { value: 'Insurance', label: 'Insurance' },
    { value: 'Entertainment', label: 'Entertainment' },
    { value: 'Salary', label: 'Salary' },
    { value: 'Rental', label: 'Rental' },
    { value: 'MedicalAid', label: 'Medical aid' },
    { value: 'StudentFees', label: 'Student fees' },
    { value: 'Other', label: 'Other' },
];

const numberedCircleStyle = {
    borderRadius: '50%',
    height: '24px',
    width: '24px',
    paddingLeft: '6px',
    border: '2px solid #4669c4',
    color: '#4669c4',
};

interface NumberProps {
    value: number;
}
const NumberedCircle: React.FunctionComponent<NumberProps> = ({ value }: NumberProps) => {
    return <div style={numberedCircleStyle}>{value}</div>;
};

export const FinancialInformationEditView = withStyles(styles)(FinancialInformationViewClass);
