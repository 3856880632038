import React from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import { connect, MapStateToProps } from 'react-redux';
import { v1 as uuid } from 'uuid';
import { AuthState } from '../../../../auth/auth-reducer';
import { AddFormModal } from '../../../../form-modal/views/add-form-modal';
import {
    Condition,
    FormHeading,
    FullWidthCheckBoxField,
    FullWidthRichTextField,
    FullWidthSelectField,
    FullWidthTextField,
    True,
    When,
    WhenFieldChanges,
} from '../../../../forms';
import { requiredValidator } from '../../../../forms/validations';
import { State } from '../../../../redux/root-reducer';
import { getDisplayDate, convertToNumber, formatCurrency } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import {
    AddDeathAssessmentDecision,
    addDeathAssessmentDecisionAction,
} from '../actions/add-death-assessment-decision';
import { ClaimType } from '../../../../claim/claim-types';
import { Nullable } from '../../../../shared-types';
import { LabeledValue } from '../../../../controls/labeled-value';
import { CurrencyMask } from '../../../../forms/input-masks';
import { DeathAssessmentDecisionViewModel } from '../death-assessment-details-reducer';
import { getClaim } from '../../../../claim';
import { ClaimLiability } from '../../assessment-details-reducer';

export interface AddInvestmentAssessmentDecisionProps {
    policyNumber: string;
    commencementDate?: Date;
    dateOfDeath?: Date;
    assessmentDescription: string;
    claimLiability: ClaimLiability;
}

interface StateProps {
    claimType: Nullable<ClaimType>;
    currentUser: AuthState;
}

interface DispatchProps {
    addDeathAssessmentDecision: AddDeathAssessmentDecision;
}

interface DecisionProps extends AddInvestmentAssessmentDecisionProps, StyledComponent {
    currentClaimType?: string;
}

type Props = DecisionProps & StateProps & DispatchProps & StyledComponent;
const DeathAssessmentDecisionAddFormModal = AddFormModal<DeathAssessmentDecisionViewModel>();
const deathAssessmentDecisionAddView: React.FunctionComponent<Props> = ({
    classes,
    currentUser,
    addDeathAssessmentDecision,
    claimLiability,
    ...detailProps
}: Props) => {
    const currentClaimType = `${detailProps.claimType} - Investment`;
    return (
        <DeathAssessmentDecisionAddFormModal
            recordDetailView={() => (
                <DeathClaimAssessmentDecisionDetailView
                    {...detailProps}
                    currentClaimType={currentClaimType}
                    classes={classes}
                    claimLiability={claimLiability}
                />
            )}
            addRecord={record => addDeathAssessmentDecision(record)}
            blankRecord={getBlankAssessmentDecision()}
        />
    );

    function getBlankAssessmentDecision(): DeathAssessmentDecisionViewModel {
        return {
            ...detailProps,
            id: uuid(),
            effectiveDate: detailProps.dateOfDeath,
            amountPayable: claimLiability.claimAmount,
            assessmentDate: new Date(),
            assessmentType: detailProps.assessmentDescription,
            assessor: currentUser.name,
            claimType: detailProps.claimType,
        };
    }
};

const DeathClaimAssessmentDecisionDetailView: React.FunctionComponent<DecisionProps> = ({
    assessmentDescription,
    commencementDate,
    currentClaimType,
    claimLiability,
    classes,
    dateOfDeath,
    policyNumber,
}: DecisionProps) => {
    return (
        <React.Fragment>
            <FormHeading>Add {assessmentDescription} Decision</FormHeading>
            <Grid container justify="space-between">
                <Grid item xs={3}>
                    <LabeledValue label="Policy Number" value={policyNumber} />
                </Grid>
                <Grid item xs={3}>
                    <LabeledValue
                        label="Commencement Date"
                        value={getDisplayDate(commencementDate)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LabeledValue label="Claim type" value={currentClaimType} />
                </Grid>
                <Grid item xs={3}>
                    <LabeledValue
                        label="Quote Amount"
                        value={formatCurrency(claimLiability.quoteAmount)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LabeledValue
                        label="Claim Amount"
                        value={formatCurrency(claimLiability.claimAmount)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LabeledValue
                        label="Overpayment Amount"
                        value={formatCurrency(claimLiability.overpaymentAmount)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LabeledValue
                        label="Money in suspense"
                        value={formatCurrency(claimLiability.moneyInSuspense)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LabeledValue label="Effective Date" value={getDisplayDate(dateOfDeath)} />
                </Grid>
            </Grid>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <FullWidthRichTextField name="summary" validate={requiredValidator} />
                </Grid>
                <Grid item xs={12}>
                    <FullWidthCheckBoxField name="final" label="Final Decision" />
                    <WhenFieldChanges field="final" set="decision" to={null} />
                    <WhenFieldChanges field="final" set="reason" to={null} />
                </Grid>
                <Condition when="final" is={true}>
                    <True>
                        <Grid item xs={4}>
                            <FullWidthSelectField
                                name="decision"
                                options={[
                                    { value: 'Approve', label: 'Approve' },
                                    { value: 'Decline', label: 'Decline' },
                                ]}
                                sortOptions
                                validate={requiredValidator}
                            />
                            <WhenFieldChanges field="decision" set="reason" to={null} />
                        </Grid>
                        <Grid item xs={4}>
                            <Condition when="decision" matchesChild>
                                <When is="Approve">
                                    <FullWidthSelectField
                                        name="reason"
                                        options={[
                                            { value: 'Approve', label: 'Approve' },
                                            {
                                                value: 'New Terms Accepted',
                                                label: 'New Terms Accepted',
                                            },
                                            {
                                                value: 'Business Decision',
                                                label: 'Business Decision',
                                            },
                                            { value: 'Claim Committee', label: 'Claim Committee' },
                                            { value: 'Complaint', label: 'Complaint' },
                                        ]}
                                        sortOptions
                                        validate={requiredValidator}
                                    />
                                </When>
                                <When is="Decline">
                                    <FullWidthSelectField
                                        name="reason"
                                        options={[
                                            {
                                                value: 'Innocent Non-disclosure (I)',
                                                label: 'Innocent Non-disclosure (I)',
                                            },
                                            {
                                                value: 'Deliberate Non-disclosure (D)',
                                                label: 'Deliberate Non-disclosure (D)',
                                            },
                                            { value: 'Fraud', label: 'Fraud' },
                                            {
                                                value: 'Does not meet the criteria',
                                                label: 'Does not meet the criteria',
                                            },
                                            {
                                                value: 'No benefit exists',
                                                label: 'No benefit exists',
                                            },
                                            {
                                                value: 'Benefits expired',
                                                label: 'Benefits expired',
                                            },
                                            { value: 'Exclusion', label: 'Exclusion' },
                                            {
                                                value: 'Condition not covered',
                                                label: 'Condition not covered',
                                            },
                                            { value: 'Policy inactive', label: 'Policy inactive' },
                                            {
                                                value:
                                                    'New terms offered - does not qualify for a claim',
                                                label:
                                                    'New terms offered - does not qualify for a claim',
                                            },
                                            {
                                                value:
                                                    'New terms offered - does qualify for a claim',
                                                label:
                                                    'New terms offered - does qualify for a claim',
                                            },
                                            {
                                                value: 'Within waiting period',
                                                label: 'Within waiting period',
                                            },
                                        ]}
                                        sortOptions
                                        validate={requiredValidator}
                                    />
                                </When>
                                <When default>
                                    <FullWidthSelectField name="reason" disabled options={[]} />
                                </When>
                            </Condition>
                        </Grid>
                        <Condition when="decision" is="Approve">
                            <True>
                                <Grid item xs={4}>
                                    <FullWidthTextField
                                        name="amountPayable"
                                        mask={CurrencyMask}
                                        validate={requiredValidator}
                                        parse={convertToNumber}
                                    />
                                </Grid>
                            </True>
                        </Condition>
                    </True>
                </Condition>
            </Grid>
        </React.Fragment>
    );
};

const mapStateToProps: MapStateToProps<StateProps, AddInvestmentAssessmentDecisionProps, State> = (
    state: State
) => ({
    currentUser: state.auth as AuthState,
    claimType: getClaim(state).claimType,
});

const mapDispatchToProps = {
    addDeathAssessmentDecision: addDeathAssessmentDecisionAction,
};

export const InvestmentAssessmentDecisionAddView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(deathAssessmentDecisionAddView));
