import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import { OpenModal, openModalAction } from '../../../../../../form-modal/actions';
import { ButtonWrapper } from '../../../../../../forms';
import { DisplayAddress } from '../../../../../../forms/sub-forms/address-details';
import { State } from '../../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { UpdateFormDetails, updateFormDetailsAction } from '../../actions/update-form-details';
import { FormDetails, getFormDetails, OccupationDetails } from '../../form-details-reducer';
import { editDetails } from './deceased-details-view';

interface Props extends StyledComponent {
    formDetails: FormDetails;
    hasDeferredContracts: boolean;
    openModal: OpenModal;
    updateFormDetails: UpdateFormDetails;
}

const deceasedOccupationView = ({
    classes,
    formDetails,
    hasDeferredContracts,
    openModal,
    updateFormDetails,
}: Props) => {
    const occupation =
        (formDetails.deceasedDetails && formDetails.deceasedDetails.occupationDetails) || {};
    const edit = () => {
        editDetails({ formDetails, index: 3, openModal, updateFormDetails });
    };
    return (
        <Grid container>
            <Grid item xs={10}>
                <Typography className={classes.heading} variant="body1">
                    Deceased&apos;s Occupation Details
                </Typography>
            </Grid>
            {!hasDeferredContracts && (
                <ButtonWrapper>
                    <Grid item xs={2} className={classes.rightAlign}>
                        <Tooltip title="Edit Occupation Details">
                            <Button id="EditOccupation" color="secondary" onClick={edit}>
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            )}
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Occupation Status" value={occupation.status} />
            </Grid>
            <OccupationView occupation={occupation} />
        </Grid>
    );
};

interface OccProps {
    occupation: OccupationDetails;
}
const OccupationView = ({ occupation }: OccProps) => {
    if (!occupation.status || occupation.status === 'Unemployed') {
        return null;
    }
    const addressDetails = occupation.addressDetails || {};
    const { institution, studentNumber, contactPerson, contactNumber, address } = getLabels(
        occupation.status
    );
    return (
        <React.Fragment>
            <Grid item xs={6} lg={3}>
                <LabeledValue label={institution} value={occupation.institution} />
            </Grid>
            {occupation.status !== 'Employed' && (
                <Grid item xs={6} lg={3}>
                    <LabeledValue label={studentNumber} value={occupation.studentNumber} />
                </Grid>
            )}
            {occupation.status === 'Employed' && (
                <Grid item xs={6} lg={3}>
                    <LabeledValue label="Occupation" value={occupation.occupation} />
                </Grid>
            )}
            <Grid item xs={6} lg={3}>
                <LabeledValue label={contactPerson} value={occupation.contactPerson} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label={contactNumber} value={occupation.telephoneNumber} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <DisplayAddress label={address} address={addressDetails} />
            </Grid>
        </React.Fragment>
    );
};

export function getLabels(status?: string) {
    switch (status) {
        case 'Employed':
            return {
                institution: 'Employer',
                contactPerson: "Manager's Name",
                contactNumber: 'Work Number',
                address: 'Work Address',
            };
        case 'Student':
            return {
                institution: "Institute's Name",
                studentNumber: 'Student Number',
                contactPerson: 'Contact Person',
                contactNumber: "Institute's Number",
                address: "Institute's Address",
            };
        case 'Scholar':
            return {
                institution: "School's Name",
                studentNumber: 'Scholar Number',
                contactPerson: "Principal's Name",
                contactNumber: "School's Number",
                address: "School's Address",
            };
        default:
            return {
                institution: 'Employer/Institute Name',
                studentNumber: 'Student/Scholar Number',
                contactPerson: 'Contact Person',
                contactNumber: 'Contact Number',
                address: 'Address Details',
            };
    }
}

const mapStateToProps = (state: State) => ({
    formDetails: getFormDetails(state),
    hasDeferredContracts: state.claimSession.hasDeferredContracts,
});

const mapDispatchToProps = {
    openModal: openModalAction,
    updateFormDetails: updateFormDetailsAction,
};

export const DeceasedOccupationView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(deceasedOccupationView));
