import React from 'react'; // eslint-disable-line

import { Grid, withStyles, Typography, Paper } from '@material-ui/core';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { NonBusinessRelatedExpense, SelfEmployedIncome } from '../reducer';
import { formatCurrency, formatPercent } from '../../../../../shared-functions';

interface OwnProps {
    selfEmployedIncome?: SelfEmployedIncome;
}

type Props = OwnProps & StyledComponent;

const selfEmployedIncomeView: React.FunctionComponent<Props> = ({
    selfEmployedIncome,
    classes,
}: Props) => {
    if (!selfEmployedIncome) {
        return null;
    }
    return (
        <Grid container justify="flex-start">
            <Grid item xs={12}>
                <Typography variant="subtitle2">Self Employed Income</Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Grid container justify="flex-start">
                                <Grid item xs={12}>
                                    <LabeledValue
                                        label="Sales"
                                        value={formatCurrency(selfEmployedIncome.sales)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <LabeledValue
                                        label="Cost of Sales"
                                        value={formatCurrency(selfEmployedIncome.costOfSales)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <LabeledValue
                                        label="Income"
                                        value={formatCurrency(selfEmployedIncome.income)}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper className={classes.paper}>
                            <Grid container justify="flex-start">
                                <Grid item xs={6} lg={12}>
                                    <LabeledValue
                                        label="Expenses"
                                        value={formatCurrency(selfEmployedIncome.expenses)}
                                    />
                                </Grid>
                                <NonBusinessExpensesView
                                    classes={classes}
                                    nonBusinessRelatedExpenses={
                                        selfEmployedIncome.nonBusinessRelatedExpenses
                                    }
                                />
                                <Grid item xs={12}>
                                    <LabeledValue
                                        label="Non-Business Expenses Total"
                                        value={formatCurrency(
                                            selfEmployedIncome.nonBusinessExpensesTotal
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <LabeledValue
                                        label="Overhead Total"
                                        value={formatCurrency(selfEmployedIncome.overheadTotal)}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper className={classes.paper}>
                            <Grid item xs={6}>
                                <LabeledValue
                                    label="Financial Statement Period"
                                    value={selfEmployedIncome.financialStatementPeriod}
                                />
                            </Grid>
                        </Paper>
                        <Paper className={classes.paper}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <LabeledValue
                                        label="OEB Percentage"
                                        value={formatPercent(selfEmployedIncome.oebPercentage)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <LabeledValue
                                        label={`Percentage of OEB (${formatPercent(
                                            selfEmployedIncome.oebPercentage
                                        )})`}
                                        value={formatCurrency(selfEmployedIncome.percentageOfOEB)}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper className={classes.paper}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <LabeledValue
                                        label="Business Percentage Share"
                                        value={formatPercent(
                                            selfEmployedIncome.businessPercentageShare! / 100
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <LabeledValue
                                        label={`Partnership (${formatPercent(
                                            selfEmployedIncome.businessPercentageShare! / 100
                                        )})`}
                                        value={formatCurrency(selfEmployedIncome.partnership)}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper className={classes.paper}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <LabeledValue
                                        label="Defined Income"
                                        value={formatCurrency(selfEmployedIncome.definedIncome)}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper className={classes.paper}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <LabeledValue
                                        label="Average Monthly Earnings"
                                        value={formatCurrency(
                                            selfEmployedIncome.averageMonthlyEarnings
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <LabeledValue
                                        label="Estimated After Tax Income"
                                        value={formatCurrency(
                                            selfEmployedIncome.estimatedAfterTaxIncome
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

interface NonBusinessProps extends StyledComponent {
    nonBusinessRelatedExpenses?: NonBusinessRelatedExpense[];
}
const NonBusinessExpensesView = ({ classes, nonBusinessRelatedExpenses }: NonBusinessProps) => {
    if (!nonBusinessRelatedExpenses || nonBusinessRelatedExpenses.length === 0) {
        return null;
    }
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.label}>
                    Non Business Related Expenses
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="subtitle2" className={classes.label}>
                    Expense
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="subtitle2" className={classes.label}>
                    Amount
                </Typography>
            </Grid>
            {nonBusinessRelatedExpenses.map((expense, index) => {
                return (
                    <React.Fragment key={`sei_${index}`}>
                        <Grid item xs={6}>
                            <Typography className={classes.value}>
                                {expense.nonBusinessRelatedExpenseName}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.value}>
                                {formatCurrency(expense.nonBusinessRelatedExpenseValue)}
                            </Typography>
                        </Grid>
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

export const SelfEmployedIncomeView = withStyles(styles)(selfEmployedIncomeView);
