import { Claim, claimSubmit } from '../../../claim';
import { getLifeAssuredDetailsFromClaim } from '../../../life-assured-details/reducer';
import { ReduxThunkAction } from '../../../redux/redux-actions';

export interface ContractModel {
    policyNumber?: string;
    benefitName?: string;
    premiumPatternDesc?: string;
    aciPercentage?: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UpdateContractReturn = Promise<any>;
export type UpdateContract = (contractDetails: ContractModel) => UpdateContractReturn;
type UpdateContractAction = (
    contractDetails: ContractModel
) => ReduxThunkAction<UpdateContractReturn>;

export const updateContractAction: UpdateContractAction = (contractDetails: ContractModel) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, getUpdatedContractDetails(contractDetails));
    };
};

function getUpdatedContractDetails(contractDetails: ContractModel): (claim: Claim) => Claim {
    return (claim: Claim) => {
        const currentLifeAssuredDetails = getLifeAssuredDetailsFromClaim(claim);
        const contractIndex = currentLifeAssuredDetails.contracts.findIndex(
            c => c.contractNumber === contractDetails.policyNumber
        );
        if (contractIndex !== -1) {
            const currentContract = currentLifeAssuredDetails.contracts[contractIndex];
            currentLifeAssuredDetails.contracts[contractIndex] = {
                ...currentContract,
                premiumPatternDesc: contractDetails.premiumPatternDesc,
                aciPercentage: contractDetails.aciPercentage,
            };
            return {
                ...claim,
                lifeAssuredDetails: {
                    ...currentLifeAssuredDetails,
                },
            };
        }
        return claim;
    };
}
