import { State } from '../../../../redux/root-reducer';
import { getClaim, Claim, getPropertyFromClaim } from '../../../../claim';
import { AnyAction } from 'redux';
import { Nullable } from '../../../../shared-types';
import {
    RequiredDocument,
    RequirementDocuments,
} from '../../common/requirements-management/reducer';

export interface BeneficiaryRequirementsStatus {
    readonly name?: string;
    readonly lastName?: string;
    readonly documents?: RequiredDocument[];
}

export interface ClaimantRequirementsStatus extends RequirementDocuments {
    claimantId?: string;
    name?: string;
    lastName?: string;
}

export interface ExecutorRequirementsStatus {
    id?: string;
    name?: string;
    lastName?: string;
    documents?: RequiredDocument[];
}

export interface DeathRequirements {
    deceased?: RequiredDocument[];
    beneficiaries?: BeneficiaryRequirementsStatus[];
    claimants?: ClaimantRequirementsStatus[];
    executors?: ExecutorRequirementsStatus[];
}

export const defaultDeceasedRequirements: Nullable<DeathRequirements> = {};
export function documentsStatusReducer(
    state: Nullable<DeathRequirements> = null,
    action: AnyAction
) {
    switch (action.type) {
        default:
            return state;
    }
}

export function getDocumentsRequirements(state: State): Nullable<DeathRequirements> {
    return getDocumentsRequirementsFromClaim(getClaim(state));
}

export function getDocumentsRequirementsFromClaim(claim: Claim): Nullable<DeathRequirements> {
    return getPropertyFromClaim(claim, 'documentsStatus', defaultDeceasedRequirements);
}
