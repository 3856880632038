import { ContactPersonDetails } from './contact-person/contact-person-details';
import { validateContactPersonDetails } from './contact-person/validate';
import { validateFinancialAdviserContactDetails } from './financial-adviser/validate';
import { ClaimContactDetails } from './reducer';

export function validateContactDetails(contactDetails: ClaimContactDetails) {
    const errors = {
        financialAdviser: validateFinancialAdviserContactDetails(contactDetails.financialAdviser),
        contactPerson: validateContactPersonDetails(
            contactDetails.contactPerson as ContactPersonDetails
        ),
    };
    return errors;
}
