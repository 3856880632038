import React from 'react'; // eslint-disable-line

import { FormHelperText, InputLabel, RadioGroup, withStyles } from '@material-ui/core';
import { RadioGroupProps } from '@material-ui/core/RadioGroup';
import { createStyles, StyleRules, WithStyles } from '@material-ui/core/styles';
import { FieldRenderProps } from 'react-final-form';
import {
    addNameToChildren,
    getComponentId,
    nameToLabel,
    processMetaForErrors,
} from '../../form-component-utilities';

function styles(): StyleRules {
    return createStyles({
        radioGroup: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: '10px',
        },
    });
}

type Props = FieldRenderProps<any, any> &
    RadioGroupProps &
    WithStyles<typeof styles> & {
        name: string;
        label?: React.ReactNode;
        helperText?: React.ReactNode;
        children: React.ReactNode;
        id?: string;
        noLabel?: boolean;
    };

const radioGroupField: React.FunctionComponent<Props> = ({
    classes,
    label,
    helperText,
    id,
    children,
    input,
    noLabel,
    isDefaultCasing,
    meta,
    ...rest
}: Props) => {
    const { name, value } = input;
    const { errorMessage, showError } = processMetaForErrors(meta);
    const childrenWithProps = addNameToChildren({ id, name, children });
    const _id = getComponentId({ id, name });

    const showHelperText = showError || !!helperText;
    return (
        <React.Fragment>
            {noLabel ? null : (
                <InputLabel error={showError} htmlFor={_id} shrink>
                    {isDefaultCasing ? label : nameToLabel({ label, name })}
                </InputLabel>
            )}
            <RadioGroup {...rest} className={classes.radioGroup} id={_id} name={''} value={value}>
                {childrenWithProps}
            </RadioGroup>
            {showHelperText && (
                // @ts-ignore
                <FormHelperText error={showError} component="pre" style={{ marginTop: '-2px' }}>
                    {showError ? errorMessage : helperText}
                </FormHelperText>
            )}
        </React.Fragment>
    );
};

export const RadioGroupField = withStyles(styles)(radioGroupField);
