import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { getClaim } from '../../../claim';
import { ClaimType } from '../../../claim/claim-types';
import { LabeledValue } from '../../../controls/labeled-value';
import { State } from '../../../redux/root-reducer';
import { Nullable } from '../../../shared-types';
import styles, { StyledComponent } from '../../../styles/styles';
import {
    BeneficiaryDetails,
    getIdType,
    getPaymentBeneficiaries,
    idNumberFormatter,
    Payment,
    PaymentItem,
} from '../../reducer';
import { AddPaymentButton } from './add-payment-item';
import { BankingDetailsExpansionView } from './banking-details-expansion';
import { FuneralPaymentItems } from './funeral/payment-items-view';
import { PaymentItemsView } from './payment-items-view';
import { ButtonWrapper } from '../../../forms/form-components/button-wrapper/index';
import { OverrideHighRiskDetailsView } from '../high-risk-override/high-risk-override-view';
import { BeneficiatyRiskRatingDetailsView } from './beneficiary-risk-rating';

interface OwnProps extends StyledComponent {
    beneficiaries: BeneficiaryDetails[];
    claimType: Nullable<ClaimType>;
    payments: Payment[];
}

type Props = OwnProps;

const beneficiaryPaymentsView: React.FunctionComponent<Props> = ({
    beneficiaries,
    claimType,
    classes,
    payments,
}: Props) => {
    if (!payments || payments.length === 0) {
        return null;
    }

    return (
        <React.Fragment>
            {payments.map((payment, paymentIndex) => {
                const beneficiary = beneficiaries.find(
                    b => b.beneficiaryId === payment.beneficiaryId
                );
                if (!beneficiary) {
                    return null;
                }
                const beneficiaryId = beneficiary.beneficiaryId;
                const personalDetails = beneficiary.personalDetails;
                const organisationDetails = beneficiary.organisationDetails;
                const idType = personalDetails && personalDetails.idType;
                const isPassport = getIdType(idType);
                return (
                    <Paper className={classes.paper} key={paymentIndex}>
                        <Grid container item xs={6} alignItems="center">
                            <Typography variant="h6">Beneficiary Details</Typography>
                        </Grid>
                        {personalDetails && (
                            <Grid item xs={12} container justify="flex-start">
                                <Grid item xs={4} lg={2}>
                                    <LabeledValue label="Name" value={personalDetails.firstName} />
                                </Grid>
                                <Grid item xs={4} lg={2}>
                                    <LabeledValue
                                        label="Surname"
                                        value={personalDetails.lastName}
                                    />
                                </Grid>
                                {!isPassport ? (
                                    <Grid item xs={4} lg={2}>
                                        <LabeledValue
                                            label="ID Number"
                                            value={idNumberFormatter(
                                                personalDetails && personalDetails.idNumber
                                            )}
                                        />
                                    </Grid>
                                ) : (
                                    <Grid item xs={4} lg={2}>
                                        <LabeledValue
                                            label="Passport Number"
                                            value={personalDetails.passportNumber}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        )}
                        {organisationDetails && (
                            <Grid item xs={12} container justify="flex-start">
                                <Grid item xs={4} lg={2}>
                                    <LabeledValue
                                        label="Name"
                                        value={organisationDetails.companyName}
                                    />
                                </Grid>
                                <Grid item xs={4} lg={2}>
                                    <LabeledValue
                                        label="Registration Number"
                                        value={organisationDetails.entityRegistrationNumber}
                                    />
                                </Grid>
                                {organisationDetails.companyTaxNumber && (
                                    <Grid item xs={4} lg={2}>
                                        <LabeledValue
                                            label="Tax Number"
                                            value={organisationDetails.companyTaxNumber}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        )}
                        <BankingDetailsExpansionView beneficiaryId={beneficiaryId} />
                        <BeneficiatyRiskRatingDetailsView
                            beneficiaryId={beneficiaryId}
                            paymentIndex={paymentIndex}
                        />
                        <OverrideHighRiskDetailsView
                            payments={payments}
                            paymentIndex={paymentIndex}
                        />
                        <ButtonWrapper>
                            <AddPaymentButton payments={payments} paymentIndex={paymentIndex} />
                        </ButtonWrapper>
                        <PaymentItems
                            paymentItems={payment.paymentItems}
                            paymentIndex={paymentIndex}
                            beneficiaryId={beneficiaryId}
                            claimType={claimType}
                        />
                    </Paper>
                );
            })}
        </React.Fragment>
    );
};

interface PaymentItemsProps {
    beneficiaryId: string;
    claimType: Nullable<ClaimType>;
    paymentIndex: number;
    paymentItems: PaymentItem[];
}
const PaymentItems = ({ claimType, ...rest }: PaymentItemsProps) => {
    if (claimType === ClaimType.Funeral) {
        return <FuneralPaymentItems {...rest} />;
    }

    return <PaymentItemsView {...rest} />;
};

const mapStateToProps = (state: State) => {
    const claim = getClaim(state);
    return {
        beneficiaries: getPaymentBeneficiaries(claim),
        claimType: claim.claimType,
        payments: claim.payments,
    };
};

export const BeneficiaryPaymentsView = connect(mapStateToProps)(
    withStyles(styles)(beneficiaryPaymentsView)
);
