import { Reducer } from 'redux';
import { v1 as uuid } from 'uuid';
import { State } from '../redux/root-reducer';
import { CloseModalAction, OpenModalAction, UpdateCurrentModalDataAction } from './actions';
import { CLOSE_MODAL, OPEN_MODAL, UPDATE_CURRENT_MODAL_DATA } from './types';

export interface ModalDetails {
    readonly modalId: string;
    readonly modalContent: React.ReactNode;
    readonly modalData?: any;
    readonly parentModal?: ModalDetails;
}

export interface ModalState {
    readonly activeModal?: ModalDetails;
}

export const initialModalState: ModalState = {};

export type ModalAction = OpenModalAction | UpdateCurrentModalDataAction | CloseModalAction;
export const formModalReducer: Reducer<ModalState, ModalAction> = (
    state: ModalState = initialModalState,
    action: ModalAction
) => {
    switch (action.type) {
        case OPEN_MODAL: {
            const openModalAction = action as OpenModalAction;

            return {
                activeModal: {
                    ...openModalAction.payload,
                    modalId: uuid(),
                    parentModal: state.activeModal,
                },
            };
        }
        case UPDATE_CURRENT_MODAL_DATA: {
            if (!!state.activeModal) {
                const updateModalDataAction = action as UpdateCurrentModalDataAction;
                return {
                    ...state,
                    activeModal: {
                        ...state.activeModal,
                        modalData: {
                            ...state.activeModal.modalData,
                            ...updateModalDataAction.payload.modalData,
                        },
                    },
                };
            }
            break;
        }
        case CLOSE_MODAL: {
            if (!!state.activeModal) {
                return {
                    ...state,
                    activeModal: state.activeModal.parentModal,
                };
            }
            break;
        }
    }
    return state;
};

export function getModalData(state: State) {
    return (!!state.formModal.activeModal && state.formModal.activeModal.modalData) || {};
}

export function getRecordIndex(state: State): number {
    return getModalData(state).recordIndex || 0;
}

export function getParentRecordIndex(state: State): number {
    return getModalData(state).parentRecordIndex || 0;
}

export function getRecordCount(state: State): number {
    return getModalData(state).recordCount || 0;
}
