import React from 'react'; // eslint-disable-line
import { AssessmentDetailsView } from '../../common/views/assessment-details';
import { TerminalIllnessAssessmentDecisionDetailView } from './terminal-illness-assessment-decision-add';

export const TerminalIllnessAssessmentDetailsView: React.FunctionComponent = () => {
    return (
        <AssessmentDetailsView
            assessmentDecisionAddView={TerminalIllnessAssessmentDecisionDetailView}
        />
    );
};
