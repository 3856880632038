import { Reducer } from 'redux';
import { getProperty } from '../claim';
import { State } from '../redux/root-reducer';
import { Nullable } from '../shared-types';

export interface DisabilityDetails {
    readonly lastDayAtWork?: Date;
    readonly returnToWorkStatus?: string;
    readonly returnToWork?: Date;
    readonly reason?: string;
    readonly jobDescription?: string;
    readonly incomeTaxNumber?: string;
    readonly proofOfIncomeAtUnderwriting?: string;
}

export const defaultDisabilityDetails: DisabilityDetails = {};

export const disabilityDetailsReducer: Reducer<Nullable<DisabilityDetails>> = (
    state: Nullable<DisabilityDetails> = null
) => {
    return state;
};

export function getDisabilityDetails(state: State): DisabilityDetails {
    return getProperty(state, 'disabilityDetails', defaultDisabilityDetails);
}
