import { AnyAction } from 'redux';
import { getProperty } from '../claim';
import { State } from '../redux/root-reducer';
import { Nullable } from '../shared-types';

export interface IncomeManagementPlan {
    readonly policyNumber?: string;
    readonly policyStatus?: string;
    readonly ceaseDate?: Date;
    readonly reviewRequirements?: string;
    readonly nextReviewDate?: Date;
}

export const defaultIncomeManagementPlan: IncomeManagementPlan = {};

export function incomeManagementPlanReducer(
    state: Nullable<IncomeManagementPlan> = null,
    action: AnyAction
) {
    switch (action.type) {
        default:
            return state;
    }
}

export function getIncomeManagementPlan(state: State): IncomeManagementPlan {
    return getProperty(state, 'incomeManagementPlan', defaultIncomeManagementPlan);
}
