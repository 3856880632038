import { Button, Grid, Tooltip, withStyles } from '@material-ui/core';
import { DirectionsRun } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { getClaim } from '../../../../../claim';
import { ClaimType } from '../../../../../claim/claim-types';
import { State } from '../../../../../redux/root-reducer';
import { Nullable } from '../../../../../shared-types';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { RunRules, runRulesAction } from '../actions/run';
import { ButtonWrapper } from '../../../../../forms/form-components/button-wrapper/index';

interface Props extends StyledComponent {
    claimType: Nullable<ClaimType>;
    isReadOnly: boolean;
    hasDeferredContracts: boolean;
    runRules: RunRules;
}

function rulesRunButton({ claimType, classes, isReadOnly, hasDeferredContracts, runRules }: Props) {
    if (isReadOnly || !isCireAvailable() || hasDeferredContracts) {
        return null;
    }

    return (
        <ButtonWrapper>
            <Grid container justify="flex-start" className={classes.marginTop3}>
                <Tooltip title="Click here to run the CIRE Results">
                    <Button
                        id="RunRules"
                        className={classes.button}
                        color="secondary"
                        variant="contained"
                        onClick={runRules}
                    >
                        <DirectionsRun />
                    </Button>
                </Tooltip>
            </Grid>
        </ButtonWrapper>
    );

    function isCireAvailable() {
        if (claimType === ClaimType.Death || claimType === ClaimType.Funeral) {
            return true;
        }
    }
}

const mapStateToProps = (state: State) => ({
    claimType: getClaim(state).claimType,
    isReadOnly: state.claimSession.readOnly,
    hasDeferredContracts: state.claimSession.hasDeferredContracts,
});

const mapDispatchToProps = { runRules: runRulesAction };

export const RulesRunButton = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(rulesRunButton));
