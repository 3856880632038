import React, { useState } from 'react'; // eslint-disable-line
import { TextFieldProps } from '@material-ui/core/TextField';
import { FieldRenderProps } from 'react-final-form';
import { IconButton, TextField as MuiTextField, Typography, makeStyles } from '@material-ui/core';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import { nameToLabelWithError, processMetaForErrors } from '../../form-component-utilities';
import { getValidatedLabel } from '../../form-component-utilities/label-validator/label-validator';

const useStyles = makeStyles({
    inputGroup: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        borderRadius: '4px',
        maxWidth: '200px',
    },
    input: {
        textAlign: 'center',
        outline: 'none',
        border: 'none',
        padding: '12px 0',
        fontSize: '18px',
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
    },

    cssLabel: {
        color: '#0D1325',
        fontSize: '12px',
        letterSpacing: 0,
        lineHeight: '40px',
        marginBottom: -10,
    },
    textField: {
        '& input': {
            textAlign: 'center',
        },
    },
    cssOutlinedInputShaded: {
        '&$cssFocused $notchedOutline': {
            borderColor: `#36B37E`,
            borderWidth: '1px',
        },
        backgroundColor: '#F0F1F4',
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: `#36B37E`,
            borderWidth: '1px',
        },
        backgroundColor: '#FFFFFF',
    },
    cssFocused: {},
    notchedOutline: {
        borderWidth: '1px',
    },
    disabled: {
        opacity: '0.6',
        cursor: 'not-allowed',
    },
});

export type TextFieldTypes =
    | 'text'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'month'
    | 'number'
    | 'range'
    | 'search'
    | 'tel'
    | 'time'
    | 'url'
    | 'week';

export interface MaskedInputProps {
    guide?: boolean;
    placeholderChar?: string;
    keepCharPositions?: boolean;
    pipe?: (
        conformedValue: string,
        config: any
    ) => false | string | { value: string; indexesOfPipedChars: number[] };
    showMask?: boolean;
}
type Props = TextFieldProps &
    FieldRenderProps<any, any> &
    MaskedInputProps & {
        id?: string;
        label?: string;
        hideLabel?: React.ReactNode;
        fullLabel?: React.ReactNode;
        helperText?: React.ReactNode;
        type?: TextFieldTypes;
        onChange?: (values: Record<string, any>) => void;
    };

export const NumberCounter: React.FunctionComponent<Props> = ({
    id,
    input,
    meta,
    label,
    hideLabel,
    fullLabel,
    helperText,
    mask,
    guide,
    placeholderChar,
    keepCharPositions,
    pipe,
    shaded,
    showMask,
    inputProps,
    InputProps,
    labelprefixed,
    ...rest
}: Props) => {
    // eslint-disable-next-line prefer-const
    let { name, value, onChange, ...restInput } = input;
    const { errorMessage, showError } = processMetaForErrors(meta);

    const maskProps = mask
        ? {
              mask,
              guide,
              placeholderChar,
              keepCharPositions,
              pipe,
              showMask,
          }
        : {};

    inputProps = {
        ...restInput,
        ...inputProps,
        ...maskProps,
    };

    const [count, setCount] = useState(value || '');
    const customClasses = useStyles();

    const handleInputChange = (event: any) => {
        const targetvalue = parseInt(event.target.value === '' ? 0 : event.target.value);
        setCount(isNaN(targetvalue) ? 0 : targetvalue);
    };

    const increment = () => {
        const currentValue = count === '' ? 0 : parseInt(count);
        const newValue = currentValue + 1;
        value = newValue;
        onChange(newValue);
        setCount(newValue.toString());
        return value;
    };

    const decrement = () => {
        const currentValue = count === '' ? 0 : parseInt(count);
        const newValue = Math.max(currentValue - 1, 0);
        value = newValue;
        onChange(newValue);
        setCount(newValue.toString());
    };

    return (
        <div className={customClasses.textField}>
            {!hideLabel && (
                <Typography
                    className={customClasses.cssLabel}
                    style={showError ? { color: '#cd003d' } : {}}
                >
                    {!label
                        ? getValidatedLabel(rest, nameToLabelWithError({ label, name }))
                        : getValidatedLabel(rest, label)}
                </Typography>
            )}
            <div className={customClasses.inputGroup}>
                <MuiTextField
                    {...rest}
                    id={id || name}
                    name={name}
                    placeholder="0"
                    data-testid="valueOfCounter"
                    helperText={showError ? errorMessage : helperText}
                    className={customClasses.input}
                    classes={{ root: customClasses.textField }}
                    error={showError}
                    InputProps={{
                        ...InputProps,
                        style: { textAlign: 'center' },
                        classes: {
                            root: shaded
                                ? customClasses.cssOutlinedInputShaded
                                : customClasses.cssOutlinedInput,
                            focused: customClasses.cssFocused,
                            notchedOutline: customClasses.notchedOutline,
                        },
                        startAdornment: (
                            <>
                                <IconButton
                                    className={
                                        count === '' || count === '0' || count === undefined
                                            ? customClasses.disabled
                                            : ''
                                    }
                                    style={{ color: '#002B99' }}
                                    onClick={decrement}
                                    name="decrement"
                                    data-testid="decrement-button"
                                    size="small"
                                >
                                    <RemoveCircleOutlineRoundedIcon />
                                </IconButton>
                            </>
                        ),
                        endAdornment: (
                            <>
                                <IconButton
                                    style={{ color: '#002B99' }}
                                    onClick={increment}
                                    name="increment"
                                    data-testid="increment-button"
                                    size="small"
                                >
                                    <AddCircleOutlineRoundedIcon />
                                </IconButton>
                            </>
                        ),
                    }}
                    inputProps={inputProps}
                    onChange={handleInputChange}
                    value={count}
                    defaultValue={count}
                    variant="outlined"
                    fullWidth
                    FormHelperTextProps={{
                        style: { marginLeft: 0 },
                    }}
                />
            </div>
        </div>
    );
};
