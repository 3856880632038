import { Button, Grid, GridSize, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import React, { useState } from 'react'; // eslint-disable-line
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { OpenDialog, openDialogAction } from '../../../../confirmation-dialog';
import { OpenModal, openModalAction } from '../../../../form-modal/actions';
import { FormModalView } from '../../../../form-modal/views/form-modal';
import downarrow from '../../../../master-layout/views/downarrow.svg';
import uparrow from '../../../../master-layout/views/uparrow.svg';
import { State } from '../../../../redux/root-reducer';
import { formatCurrency, formatPercent, getCodeDescription } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import {
    DeletePaymentItem,
    deletePaymentItemRecordThunk,
} from '../../../actions/delete-payment-item';
import { PaymentItem } from '../../../reducer';
import { FuneralPaymentItemEditView } from './edit-payment-item-form';
import { PaymentItemExpandedView } from '../payment-item-expanded-view';
import { ButtonWrapper } from '../../../../forms/form-components/button-wrapper/index';

interface DispatchProps {
    deletePaymentItem: DeletePaymentItem;
    openDialog: OpenDialog;
    openModal: OpenModal;
}

interface OwnProps {
    itemIndex: number;
    paymentIndex: number;
    paymentItem: PaymentItem;
}

interface StateProps {
    isReadOnly: boolean;
}

interface Props extends StyledComponent, DispatchProps, StateProps, OwnProps {}
const Item: React.FunctionComponent<Props> = ({
    classes,
    deletePaymentItem,
    itemIndex,
    openDialog,
    openModal,
    paymentIndex,
    paymentItem,
    isReadOnly,
}: Props) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const toggleState = (evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        setIsExpanded(value => !value);
        evt.preventDefault();
        return false;
    };

    const deleteItem = () => {
        openDialog({
            content: {
                title: 'Delete Payment Record',
                body: 'Are you sure you would like to delete the selected payment item?',
            },
            yesAction: {
                action: () => deletePaymentItem(itemIndex, paymentIndex),
            },
        });
    };

    const editItem = () => {
        openModal({
            modalContent: (
                <FormModalView
                    formView={() => (
                        <FuneralPaymentItemEditView
                            paymentIndex={paymentIndex}
                            paymentItemIndex={itemIndex}
                        />
                    )}
                />
            ),
        });
    };

    const toggleGrid = isReadOnly
        ? { xs: 12 as GridSize, style: { marginTop: 34, marginLeft: -20 } }
        : { xs: 3 as GridSize };

    const pending = ['Pending', 'Failed', 'Rejected'].includes(paymentItem.status);
    return (
        <React.Fragment>
            <Grid container className={classes.resultsTable}>
                <Grid item xs={6} lg={2}>
                    <Typography className={classes.resultsLabel}>Policy Number</Typography>
                    <Typography>{paymentItem.policyNumber}</Typography>
                </Grid>
                <Grid item xs={6} lg={2}>
                    <Typography className={classes.resultsLabel}>Benefit</Typography>
                    <Typography>{paymentItem.benefitName}</Typography>
                </Grid>
                <Grid item xs={6} lg={2}>
                    <Typography className={classes.resultsLabel}>Payee Type</Typography>
                    <Typography>
                        {getCodeDescription('payeeType', paymentItem.payeeType)}
                    </Typography>
                </Grid>
                <Grid item xs={6} lg={1}>
                    <Typography className={classes.resultsLabel}>Split %</Typography>
                    <Typography>{formatPercent((paymentItem.split || 0) / 100)}</Typography>
                </Grid>
                <Grid item xs={6} lg={2}>
                    <Typography className={classes.resultsLabel}>Amount payable</Typography>
                    <Typography>{formatCurrency(paymentItem.amountPayable)}</Typography>
                </Grid>
                <Grid item xs={6} lg={1}>
                    <Typography className={classes.resultsLabel}>Status</Typography>
                    <Typography>{getStatus(paymentItem.status)}</Typography>
                </Grid>
                <Grid item xs={6} lg={2} container justify="flex-start">
                    <ButtonWrapper>
                        <React.Fragment>
                            <Grid item xs={12} container justify="center">
                                <Typography className={classes.resultsLabel}>Actions</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Tooltip title="Delete Payment">
                                    <Button
                                        id="DeletePayment"
                                        color="secondary"
                                        disabled={!pending}
                                        style={{ padding: '0px' }}
                                        onClick={deleteItem}
                                    >
                                        <Delete />
                                    </Button>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={4}>
                                <Tooltip title="Edit Payment">
                                    <Button
                                        id="EditPaymentReceived"
                                        color="secondary"
                                        disabled={!pending}
                                        style={{ padding: '0px' }}
                                        onClick={editItem}
                                    >
                                        <Edit />
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </React.Fragment>
                    </ButtonWrapper>
                    <Grid item container justify="flex-end" {...toggleGrid}>
                        <Tooltip title="Hide/Show Payment details">
                            <a href="/#" onClick={toggleState}>
                                <img alt="hide-show" src={isExpanded ? uparrow : downarrow} />
                            </a>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            {isExpanded && <PaymentItemExpandedView paymentItem={paymentItem} />}
        </React.Fragment>
    );
};

function getStatus(status: string) {
    if (status) {
        return status.split(/(?=[A-Z])/).join(' ');
    }
    return status;
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    // eslint-disable-next-line
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    openDialog: options => dispatch(openDialogAction(options)),
    openModal: options => dispatch(openModalAction(options)),
    deletePaymentItem: (paymentItemIndex: number, clamantIndex: number) =>
        dispatch(deletePaymentItemRecordThunk(paymentItemIndex, clamantIndex)),
});

export const PaymentItemView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Item));
