import React from 'react'; // eslint-disable-line

import { Button, Divider, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { OpenDialog, openDialogAction } from '../../../../../confirmation-dialog';
import { Section } from '../../../../../controls/section';
import { EditFormModal } from '../../../../../form-modal';
import { OpenEditModal, openEditModalAction } from '../../../../../form-modal/actions';
import { State } from '../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../styles/styles';
import {
    DeleteAndSubmitOtherContactRecord,
    deleteAndSubmitOtherContactRecordThunk,
    UpdateAndSubmitOtherContactRecord,
    updateAndSubmitOtherContactRecordThunk,
} from '../actions/submit-details';
import { ContactType, OtherContact, getDeathClaimContactDetails } from '../contact-details-reducer';
import { OtherContactDetailsEditView } from './other-contact-details-edit';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { ButtonWrapper } from '../../../../../forms';

interface OwnProps {}

interface StateProps {
    readonly otherContacts: OtherContact[];
    isReadOnly: boolean;
}

interface DispatchProps {
    deleteOtherContact: DeleteAndSubmitOtherContactRecord;
    openDialog: OpenDialog;
    openEditModal: OpenEditModal;
    updateOtherContact: UpdateAndSubmitOtherContactRecord;
}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const ClaimantEditFormModal = EditFormModal<OtherContact>();
const otherContactDetailsView: React.FunctionComponent<Props> = ({
    otherContacts,
    classes,
    deleteOtherContact,
    openDialog,
    openEditModal,
    updateOtherContact,
}: Props) => {
    if (otherContacts.length === 0) {
        return null;
    }

    return (
        <Section title={`Other Contacts' Details`}>
            {otherContacts.map((otherContact, index) => {
                return (
                    <React.Fragment key={`other-contact-${index}`}>
                        {index > 0 ? (
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        ) : (
                            ''
                        )}
                        <Grid item xs={12}>
                            <Grid container spacing={0} justify="space-between">
                                <Grid container spacing={0} item xs={8} lg={10}>
                                    <Typography variant="subtitle2">Contact {index + 1}</Typography>
                                </Grid>
                                <ButtonWrapper>
                                    <Grid item xs={4} lg={2} className={classes.rightAlign}>
                                        <Tooltip title="Edit Other Contacts' Details">
                                            <Button
                                                id="EditOtherDetails"
                                                color="secondary"
                                                onClick={() => editOtherContact(index)}
                                            >
                                                <Edit fontSize="small" />
                                            </Button>
                                        </Tooltip>
                                        {deleteOtherContactButton(
                                            index,
                                            deleteOtherContact,
                                            openDialog
                                        )}
                                    </Grid>
                                </ButtonWrapper>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify="flex-start">
                                <Grid item xs={3} lg={2}>
                                    <LabeledValue
                                        label="Description"
                                        value={otherContact.description}
                                    />
                                </Grid>
                                <Grid item xs={3} lg={2}>
                                    <LabeledValue label="Name" value={otherContact.firstName} />
                                </Grid>
                                <Grid item xs={3} lg={2}>
                                    <LabeledValue label="Surname" value={otherContact.lastName} />
                                </Grid>
                                <Grid item xs={3} lg={2}>
                                    <LabeledValue
                                        label="Telephone number"
                                        value={otherContact.telephoneNumber}
                                    />
                                </Grid>
                                <Grid item xs={3} lg={2}>
                                    <LabeledValue
                                        label="Cellphone number"
                                        value={otherContact.cellphoneNumber}
                                    />
                                </Grid>
                                <Grid item xs={3} lg={2}>
                                    <LabeledValue
                                        label="Email address"
                                        value={otherContact.emailAddress}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                );
            })}
        </Section>
    );

    function editOtherContact(index: number) {
        const recordIndex = index || 0;
        openEditModal({
            modalContent: (
                <ClaimantEditFormModal
                    recordDetailView={
                        <OtherContactDetailsEditView contactType={ContactType.Other} isEdit />
                    }
                    records={otherContacts}
                    updateRecord={(record, index) => updateOtherContact(index, record)}
                />
            ),
            modalData: {
                recordIndex,
            },
        });
    }
};

const deleteOtherContactButton = (
    index: number,
    deleteOtherContact: DeleteAndSubmitOtherContactRecord,
    openDialog: OpenDialog
) => {
    const confirmDelete = () => {
        openDialog({
            content: {
                title: 'Delete Other Contact',
                body: 'Are you sure you would like to delete the selected Other Contact?',
            },
            yesAction: {
                action: () => deleteOtherContact(index),
            },
        });
    };

    return (
        <Tooltip title="Delete Other Contact Details">
            <Button id="DeleteOtherContactDetails" color="secondary" onClick={confirmDelete}>
                <Delete />
            </Button>
        </Tooltip>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    otherContacts: getDeathClaimContactDetails(state).otherContacts,
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    deleteOtherContact: (index: number) => dispatch(deleteAndSubmitOtherContactRecordThunk(index)),
    openDialog: options => dispatch(openDialogAction(options)),
    openEditModal: options => dispatch(openEditModalAction(options)),
    updateOtherContact: (index: number, otherContact: OtherContact) =>
        dispatch(updateAndSubmitOtherContactRecordThunk(index, otherContact)),
});

export const OtherContactDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(otherContactDetailsView));
