import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import { ModalViewProps, MultiEditFormModal } from '../../../../../../form-modal';
import { OpenModal, openModalAction } from '../../../../../../form-modal/actions';
import { ButtonWrapper } from '../../../../../../forms';
import { DisplayAddress } from '../../../../../../forms/sub-forms/address-details';
import { State } from '../../../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { UpdateFormDetails, updateFormDetailsAction } from '../../actions/update-form-details';
import { FormDetails, getFormDetails } from '../../form-details-reducer';
import { DeclarationEdit } from '../declaration/edit-declaration';
import { FuneralParlourEdit } from './edit-funeral-parlour';

const EditModalForm = MultiEditFormModal<FormDetails>();
const EditModalViews: ModalViewProps[] = [
    { name: 'Funeral Parlour Details', view: <FuneralParlourEdit /> },
    { name: "Doctor's/Induna Details", view: <DeclarationEdit /> },
];

interface BaseProps {
    formDetails: FormDetails;
    openModal: OpenModal;
    updateFormDetails: UpdateFormDetails;
}

interface EditProps extends BaseProps {
    index: number;
}

interface Props extends BaseProps, StyledComponent {
    hasDeferredContracts: boolean;
}

const funeralParlour = ({
    classes,
    formDetails,
    hasDeferredContracts,
    openModal,
    updateFormDetails,
}: Props) => {
    const funeralParlour = formDetails.funeralParlour || {};
    const addressDetails = funeralParlour.addressDetails || {};

    const edit = () => {
        editDetails({ formDetails, index: 0, openModal, updateFormDetails });
    };
    return (
        <Grid item xs={12} container justify="flex-start" className={classes.marginTop}>
            <Grid item xs={10}>
                <Typography className={classes.heading} variant="body1">
                    Funeral Parlour Details
                </Typography>
            </Grid>
            {!hasDeferredContracts && (
                <ButtonWrapper>
                    <Grid item xs={2} className={classes.rightAlign}>
                        <Tooltip title="Edit Funeral Parlour Details">
                            <Button id="EditFuneralParlourDetails" color="secondary" onClick={edit}>
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            )}
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Funeral Date"
                    value={getDisplayDate(funeralParlour.dateOfFuneral)}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Parlour Name" value={funeralParlour.name} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Telephone Number" value={funeralParlour.telephoneNumber} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <DisplayAddress label="Address" address={addressDetails} />
            </Grid>
        </Grid>
    );
};

export function editDetails({ formDetails, index, openModal, updateFormDetails }: EditProps) {
    openModal({
        modalContent: (
            <EditModalForm
                heading="Update Form Details"
                modalViews={EditModalViews}
                saveAction={updateFormDetails}
            />
        ),
        modalData: {
            screenIndex: index || 0,
            initialValues: formDetails,
        },
    });
}

const mapStateToProps = (state: State) => ({
    formDetails: getFormDetails(state),
    hasDeferredContracts: state.claimSession.hasDeferredContracts,
});

const mapDispatchToProps = {
    openModal: openModalAction,
    updateFormDetails: updateFormDetailsAction,
};

export const FuneralParlourView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(funeralParlour));
