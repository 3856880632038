import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import { mapSectionView } from '../common-views/claim-audit-trail-wrapper';
import {
    ChangeFieldSet,
    changeRecordFromAddChangeSet,
    changeRecordFromChangeSet,
    changeRecordFromDeleteChangeSet,
} from '../claim-audit-trail-functions';
import { addAuditTrailDetailsView } from '../common-views/claim-audit-trail-field-details';
import { parseInt } from 'lodash';
import { getViewFromList } from './claim-audit-trail-lists-view';

interface Props {
    changeSet: Record<string, any>;
}

const auditTrailMedicalHistoryView: React.FunctionComponent<Props> = ({ changeSet }: Props) => {
    const claimNotes = 'claimNotes';
    const returnView: React.ReactNode[] = [];

    if (changeSet !== null) {
        if (changeSet.hasOwnProperty(claimNotes)) {
            getClaimNotes(changeSet, returnView);
        } else {
            if (Array.isArray(changeSet) && changeSet[0] === null) {
                const currentHistory = changeSet[1]['currentMedicalHistory'];
                if (currentHistory && currentHistory.length > 0) {
                    doctorsDetails(returnView, currentHistory, 'Current Medical Consultations');
                }
                const previousHistory = changeSet[1]['previousMedicalHistory'];
                if (previousHistory && previousHistory.length > 0) {
                    doctorsDetails(returnView, previousHistory, 'Previous Medical Consultations');
                }
            } else {
                Object.keys(changeSet).forEach(key => {
                    const changeRecord = changeSet[key];
                    const recordKey = key.toLowerCase();
                    if (recordKey === 'currentmedicalhistory') {
                        doctorsDetailsFromList(
                            changeRecord,
                            returnView,
                            'Current Medical Consultations'
                        );
                    }
                    if (recordKey === 'previousmedicalhistory') {
                        doctorsDetailsFromList(
                            changeRecord,
                            returnView,
                            'Previous Medical Consultations'
                        );
                    }
                });
            }
        }
    }
    return mapSectionView(returnView);
};

function doctorsDetails(view: React.ReactNode[], history: any, heading: string) {
    addAuditTrailDetailsView(view, changeRecordFromAddChangeSet(history[0]), [
        heading,
        `Doctors Details 1`,
    ]);
}

function doctorsDetailsFromList(
    changeRecord: Record<string, any>,
    returnView: React.ReactNode[],
    heading: string
) {
    const subSection: string[] = [heading];
    Object.keys(changeRecord).forEach(recordKey => {
        let auditTrail: ChangeFieldSet[] = [];
        let documentNumber = 0;
        let visits: any = undefined;
        let isDelete = false;
        if (!isNaN(Number(recordKey)) && changeRecord[recordKey]) {
            documentNumber = Number(recordKey) + 1;
            if (Array.isArray(changeRecord[recordKey])) {
                visits = changeRecord[recordKey][0]['doctorsVisits'];
                delete changeRecord[recordKey][0]['doctorsVisits'];
                if (changeRecord[recordKey][0]) {
                    auditTrail = changeRecordFromAddChangeSet(changeRecord[recordKey][0]);
                }
            } else {
                visits = changeRecord[recordKey]['doctorsVisits'];
                delete changeRecord[recordKey]['doctorsVisits'];
                auditTrail = changeRecordFromChangeSet(changeRecord[recordKey]);
            }
        } else {
            const deleteKey = recordKey.split('_');
            if (!isNaN(Number(deleteKey[1]))) {
                documentNumber = Number(deleteKey[1]) + 1;
                isDelete = true;
                visits = changeRecord[recordKey][0]['doctorsVisits'];
                delete changeRecord[recordKey][0]['doctorsVisits'];
                auditTrail = changeRecordFromDeleteChangeSet(changeRecord[recordKey][0]);
            }
        }
        if (auditTrail.length > 0) {
            subSection.push(`Doctors Details ${documentNumber}`);
            addAuditTrailDetailsView(returnView, auditTrail, subSection);
        }
        if (visits && hasChanges(visits)) {
            const visitsHeading =
                auditTrail.length === 0 ? [heading, `Doctors Details ${documentNumber}`] : [];
            if (isDelete) {
                visits.forEach((visit: any, index: number) => {
                    addAuditTrailDetailsView(returnView, changeRecordFromDeleteChangeSet(visit), [
                        `Consultation ${index + 1}`,
                    ]);
                });
            } else {
                if (Array.isArray(visits) && visits[0] === null) {
                    addAuditTrailDetailsView(
                        returnView,
                        changeRecordFromAddChangeSet(visits[1][0]),
                        [...visitsHeading, `Consultation 1`]
                    );
                } else {
                    getViewFromList(visits, returnView, 'Consultation', visitsHeading);
                }
            }
        }
    });
}

const getClaimNotes = (changeSet: any, returnView: React.ReactNode[]) => {
    const claimNotes = 'claimNotes';
    let records: ChangeFieldSet[] = [];
    Object.keys(changeSet[claimNotes]).forEach(noteIndex => {
        const notesValue = changeSet[claimNotes][noteIndex];
        if (Array.isArray(changeSet[claimNotes][noteIndex])) {
            for (const currentNotesIndex in notesValue) {
                if (!isNaN(Number(currentNotesIndex)) && noteIndex !== '_t') {
                    records = changeRecordFromAddChangeSet(notesValue[currentNotesIndex]);
                    if (records.length > 0) {
                        addAuditTrailDetailsView(returnView, records, [
                            `Claim Details Notes ${parseInt(noteIndex) + 1}`,
                        ]);
                    }
                }
            }
        } else if (!Array.isArray(changeSet[claimNotes][noteIndex]) && noteIndex !== '_t') {
            records = changeRecordFromChangeSet(notesValue);
            if (records.length > 0) {
                addAuditTrailDetailsView(returnView, records, [
                    `Edit Claim Details Notes ${parseInt(noteIndex) + 1}`,
                ]);
            }
        }
    });
    return mapSectionView(returnView);
};

function hasChanges(visits: any) {
    return !(Array.isArray(visits) && visits[0] === null && visits[1].length === 0);
}

export const AuditTrailMedicalHistoryView = withStyles(styles)(auditTrailMedicalHistoryView);
