import { some } from 'lodash';
import { DateTime } from 'luxon';
import * as rsaIdParser from 'south-african-id-parser';
import { Claim, claimSubmit } from '../../claim';
import { isEmpty } from '../../forms/validations';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { LifeAssuredDetails, MedicalAidDetails, PersonalDetails } from '../reducer';

type SubmitReturn = Promise<any>;
export type Submit = (details: LifeAssuredDetails) => SubmitReturn;
type SubmitAction = (details: LifeAssuredDetails) => ReduxThunkAction<SubmitReturn>;
export const submitAction: SubmitAction = (details: LifeAssuredDetails) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, getUpdatedClaim(details), validate);
    };
};

function getUpdatedClaim({
    personalDetails,
    residentialAddressDetails,
    postalAddressDetails,
    contactDetails,
    medicalAidDetails,
    claimLifeNotifications,
    contracts,
    previousPayments,
    originalDoctors,
}: LifeAssuredDetails): (claim: Claim) => Claim {
    return (claim: Claim) => ({
        ...claim,
        lifeAssuredDetails: {
            personalDetails: getPersonalDetails(personalDetails),
            residentialAddressDetails,
            postalAddressDetails,
            contactDetails,
            contracts,
            medicalAidDetails: getMedicalAidDetails(medicalAidDetails),
            claimLifeNotifications,
            previousPayments,
            originalDoctors,
        },
    });
}

export function getPersonalDetails(details: PersonalDetails): PersonalDetails {
    if (some(details)) {
        return {
            ...details,
            gender: details.gender ? details.gender : getGenderFromRsaId(details.idNumber),
            idNumber: isIdNumber(details.idType) ? details.idNumber : undefined,
            passportNumber: !isIdNumber(details.idType) ? details.passportNumber : undefined,
            passportCountryOfIssue: !isIdNumber(details.idType)
                ? details.passportCountryOfIssue
                : undefined,
            passportExpiryDate: !isIdNumber(details.idType)
                ? details.passportExpiryDate
                : undefined,
        };
    }
    return {};
}

function getGenderFromRsaId(id?: number) {
    if (!id || !rsaIdParser.validate(id)) {
        return undefined;
    }
    if (rsaIdParser.parseIsFemale(id)) {
        return 'Female';
    }
    return 'Male';
}

function isIdNumber(idType?: string) {
    if (idType === 'ID Number') {
        return true;
    }
    return false;
}

export function getMedicalAidDetails(details: MedicalAidDetails): MedicalAidDetails {
    if (details === null || details === undefined) {
        return {};
    }

    if (details.isLifeAssuredTheMainMember === 'No') {
        return details;
    }

    return {
        ...details,
        mainMembersTitle: undefined,
        mainMembersInitials: undefined,
        mainMembersFirstName: undefined,
        mainMembersLastName: undefined,
        mainMembersIdNumber: undefined,
    };
}

export function validate(claim: Claim) {
    const errors = {} as any;
    if (!('lifeAssuredDetails' in claim) || !claim.lifeAssuredDetails) {
        return errors;
    }
    const details = claim.lifeAssuredDetails;
    const personalDetails = {} as any;

    if (!isIdNumber(details.personalDetails.idType)) {
        const passportIssueDate = details.personalDetails.passportIssueDate
            ? DateTime.fromJSDate(details.personalDetails.passportIssueDate).startOf('day')
            : undefined;
        const passportExpiryDate = details.personalDetails.passportExpiryDate
            ? DateTime.fromJSDate(details.personalDetails.passportExpiryDate).startOf('day')
            : undefined;

        if (!!passportExpiryDate && !!passportIssueDate && passportExpiryDate < passportIssueDate) {
            personalDetails.passportIssueDate = 'Date must be on or before Passport Expiry Date';
            personalDetails.passportExpiryDate = 'Date must be on or after Passport Issue Date';
            errors.personalDetails = personalDetails;
        }
    }

    if (!isEmpty(errors)) {
        return {
            'front-end-errors': errors,
        };
    }
    return errors;
}
