import React from 'react'; // eslint-disable-line

import { Divider, Grid, Paper, Typography } from '@material-ui/core';

import { CommunicationMethods } from '../../codes/communication-methods';
import { Titles } from '../../codes/titles';
import { FullWidthSearchSelectField, FullWidthTextField } from '../../forms';
import { FirstNameMask, InitialsMask, LastNameMask, NumberMask } from '../../forms/input-masks';
import { CommunicationDetailsSubForm } from '../../forms/sub-forms/communication-details';
import { StyledComponent } from '../views/styles';

const CaptureBrokerContactDetailsView: React.FunctionComponent<StyledComponent> = ({
    classes,
}: StyledComponent) => {
    return (
        <Paper className={classes.paper}>
            <Typography variant="h5" className={classes.spacedTypography}>
                Financial Adviser Contact Details
            </Typography>
            <Divider />
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={6}>
                    <FullWidthSearchSelectField
                        name="financialAdviser.title"
                        id="financialAdviserTitle"
                        label="Title"
                        options={Titles}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        name="financialAdviser.initials"
                        id="financialAdviserInitials"
                        label="Initials"
                        mask={InitialsMask}
                        inputProps={{
                            style: { textTransform: 'uppercase' },
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        name="financialAdviser.firstName"
                        id="financialAdviserFirstName"
                        label="Name"
                        mask={FirstNameMask}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        name="financialAdviser.lastName"
                        id="financialAdviserLastName"
                        label="Surname"
                        mask={LastNameMask}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField name="financialAdviser.companyName" />
                </Grid>
                <CommunicationDetailsSubForm
                    communicationDetailsPropertyName="financialAdviser"
                    displayOptions={{
                        telephoneNumber: true,
                        cellphoneNumber: true,
                        emailAddress: true,
                    }}
                />
                <Grid item xs={6}>
                    <FullWidthTextField
                        name="financialAdviser.consultantCode"
                        id="consultantCode"
                        label="Consultant Code"
                        mask={NumberMask}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthSearchSelectField
                        name="financialAdviser.preferredMethodOfCommunication"
                        id="financialAdviserPreferredMethodOfCommunication"
                        label="Preferred Method Of Communication"
                        options={CommunicationMethods}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default CaptureBrokerContactDetailsView;
