import React from 'react'; // eslint-disable-line

import { Paper, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { ViewFormModal } from '../../form-modal';
import { FormHeading, GridColumn, nameToLabel } from '../../forms';
import { NestedRecordGrid } from '../../forms/form-components/nested-record-grid';
import { NestedGridColumns } from '../../forms/form-components/nested-record-grid/nested-grid-columns';
import { State } from '../../redux/root-reducer';
import { getDisplayDate } from '../../shared-functions';
import styles, { StyledComponent } from '../../styles/styles';
import {
    getRetrospectiveUnderwritingDecision,
    PolicyBenefitFinalDecisions,
    FinalDecisionDetails,
} from '../reducer';
import { FinalDecisionDetailsView } from './final-decision-details';
import { FinalDecisionDetailsAddModal } from './final-decision-details-add-modal';

type Props = StyledComponent & {
    records: PolicyBenefitFinalDecisions[];
};

const FinalDecisionDetailsViewFormModal = ViewFormModal<FinalDecisionDetails>();
const FinalDecisionDetailsGridView: React.FunctionComponent<Props> = ({
    classes,
    records,
}: Props) => {
    const leadingColumns: GridColumn[] = [
        {
            key: 'policyNumber',
            label: nameToLabel({
                name: 'policyNumber',
            }),
        },
        {
            key: 'benefitName',
            label: nameToLabel({
                name: 'benefitName',
            }),
        },
    ];
    const nestedColumns: NestedGridColumns = {
        key: 'finalDecisions',
        columns: [
            {
                key: 'decisionDate',
                label: nameToLabel({ name: 'decisionDate' }),
                format: (date?: Date) => (date ? getDisplayDate(date) : ''),
            },
            {
                key: 'retrospectiveUnderwritingDecision',
                label: nameToLabel({
                    name: 'retrospectiveUnderwritingDecision',
                }),
                text: true,
            },
        ],
    };

    return (
        <Paper className={classes.paper}>
            <FormHeading text="Final Decision Details" />
            <NestedRecordGrid
                tableHeading="Final Decision Details"
                records={records}
                addView={<FinalDecisionDetailsAddModal />}
                viewEditView={
                    <FinalDecisionDetailsViewFormModal
                        recordDetailView={() => <FinalDecisionDetailsView />}
                    />
                }
                leadingColumns={leadingColumns}
                nestedColumns={nestedColumns}
            />
        </Paper>
    );
};
const mapStateToProps = (state: State) => ({
    records: getRetrospectiveUnderwritingDecision(state).policyBenefitFinalDecisions || [],
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(FinalDecisionDetailsGridView));
