import React from 'react'; // eslint-disable-line

import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import { getDisplayDate } from '../../shared-functions';
import { UserInfo } from '../../shared-types';
import styles, { StyledComponent } from '../styles';

type Props = StyledComponent & {
    timestamp?: Date;
    user: UserInfo;
    viewList: any[];
};

const claimAuditTrailWrapper: React.FunctionComponent<Props> = ({
    classes,
    timestamp,
    user,
    viewList,
}: Props) => {
    return (
        <React.Fragment>
            <Grid container spacing={0} justify="flex-start">
                <Grid item xs={12}>
                    <Typography variant="subtitle2" style={{ fontSize: 'medium' }}>
                        {getDisplayDate(timestamp, 'dd/MM/yyyy HH:mm')} | {user.name} ({user.userId}
                        )
                    </Typography>
                </Grid>
                <Grid container item xs={12} justify="flex-start" spacing={0}>
                    <Grid item xs={1}>
                        <div className={classes.ruler}>
                            <div className={classes.circle}>
                                <div className={classes.innerCircle} />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={11}>
                        {viewList.map((view, index) => {
                            return (
                                <React.Fragment key={`${view.sectionName}${index}`}>
                                    <Typography variant="subtitle2" style={{ fontSize: 'medium' }}>
                                        {view.sectionName}
                                    </Typography>
                                    <Paper className={classes.paper}>{view.sectionView}</Paper>
                                </React.Fragment>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export function mapSectionView(returnView: any[]): React.ReactElement<any> | null {
    if (returnView.length === 0) {
        return null;
    }

    return (
        <React.Fragment>
            {returnView.length > 0 &&
                returnView.map((view, index) => {
                    return <React.Fragment key={'view' + index}>{view}</React.Fragment>;
                })}
        </React.Fragment>
    );
}

export const ClaimAuditTrailWrapper = withStyles(styles)(claimAuditTrailWrapper);
