export const LateSubmissionReason = [
    {
        value: 'Late claim submission - first-time submission',
        label: 'Late claim submission - first-time submission',
    },
    {
        value: 'Unable to make a decision due to outstanding requirements',
        label: 'Unable to make a decision due to outstanding requirements',
    },
    {
        value: 'From unclaimed/funeral queue - reopened',
        label: 'From unclaimed/funeral queue - reopened',
    },
    { value: 'Foreign exchange', label: 'Foreign exchange' },
    { value: 'Tax directive declining', label: 'Tax directive declining' },
    { value: 'Manual tax directive', label: 'Manual tax directive' },
    { value: 'Dispute', label: 'Dispute' },
    { value: 'PFA', label: 'PFA' },
    {
        value: '12-month waiting clause to pay non-dependent',
        label: '12-month waiting clause to pay non-dependent',
    },
    { value: 'Letter of guardianship to be issued', label: 'Letter of guardianship to be issued' },
    { value: 'Other', label: 'Other' },
];
