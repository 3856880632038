import React from 'react'; // eslint-disable-line

import { Divider, Grid, Paper, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import {
    ButtonWrapper,
    FullWidthDatePicker,
    FullWidthSearchSelectField,
    GenericForm,
} from '../../forms';
import { State } from '../../redux/root-reducer';
import { OnSubmitSuccessProps } from '../../workflow-navigation/actions/submit-form';
import {
    AuditTrailFilter,
    defaultAuditTrailFilter,
    getAuditTrailSectionNames,
    GetAssessmentDetailsSectionName,
    getClaimAuditUsers,
} from '../claim-audit-trail-reducer';
import styles, { StyledComponent } from '../styles';
import { AuditTrailSectionsView } from './claim-audit-trail-sections';

interface ComponentState {
    auditTrailFilter: AuditTrailFilter;
}

interface OwnProps {
    AssessmentDetailsSectionName: string;
}

type Props = ComponentState &
    OwnProps &
    OnSubmitSuccessProps &
    StyledComponent & {
        readonly claimAuditUsers: any[];
    };

const AuditTrailForm = GenericForm<AuditTrailFilter>();
class ClaimAuditTrail extends React.Component<Props, ComponentState> {
    public constructor(props: Props) {
        super(props);
    }

    public render() {
        const auditTrailFilter = this.state ? this.state.auditTrailFilter : {};
        const { classes, claimAuditUsers, onSubmitSuccess } = this.props;

        const startDateChange = (value: any) => {
            this.setState({
                auditTrailFilter: {
                    ...auditTrailFilter,
                    startDate: value,
                },
            });
        };
        const endDateChange = (value: any) => {
            this.setState({
                auditTrailFilter: {
                    ...auditTrailFilter,
                    endDate: value,
                },
            });
        };
        const userChange = (value: any) => {
            this.setState({
                auditTrailFilter: {
                    ...auditTrailFilter,
                    user: value,
                },
            });
        };
        const sectionNameChange = (value: any) => {
            this.setState({
                auditTrailFilter: {
                    ...auditTrailFilter,
                    sectionName: value,
                },
            });
        };

        return (
            <AuditTrailForm
                onSubmit={() => {
                    return;
                }}
                initialValues={auditTrailFilter}
                onSubmitSuccess={onSubmitSuccess}
            >
                <Paper className={classes.paper}>
                    <Grid container justify="flex-start" spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" style={{ fontSize: 'medium' }}>
                                Filter By
                            </Typography>
                        </Grid>
                        <ButtonWrapper>
                            <Grid item xs={6}>
                                <FullWidthDatePicker
                                    name="startDate"
                                    onChange={startDateChange}
                                    maxDate={auditTrailFilter.endDate}
                                    disableFuture
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FullWidthDatePicker
                                    name="endDate"
                                    onChange={endDateChange}
                                    minDate={auditTrailFilter.startDate}
                                    disableFuture
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FullWidthSearchSelectField
                                    name="user"
                                    options={claimAuditUsers}
                                    onChange={userChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FullWidthSearchSelectField
                                    name="sectionName"
                                    options={getAuditTrailSectionNames(
                                        this.props.AssessmentDetailsSectionName
                                    )}
                                    onChange={sectionNameChange}
                                />
                            </Grid>
                        </ButtonWrapper>
                    </Grid>
                    <Divider style={{ marginTop: 32, marginBottom: 32 }} />
                    <AuditTrailSectionsView auditTrailFilter={auditTrailFilter} />
                </Paper>
            </AuditTrailForm>
        );
    }
}

const mapStateToProps = (state: State) => ({
    claimAuditUsers: getClaimAuditUsers(state),
    auditTrailFilter: defaultAuditTrailFilter(),
    AssessmentDetailsSectionName: GetAssessmentDetailsSectionName(state),
});

const mapDispatchToProps = {};

export const ClaimAuditTrailView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(ClaimAuditTrail));
