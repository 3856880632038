import { Theme } from '@material-ui/core';
import { createStyles, StyleRules } from '@material-ui/core/styles';
import { CSSProperties, WithStyles } from '@material-ui/core/styles/withStyles';

export type StyledComponent = WithStyles<typeof styles>;

export function styles(theme: Theme): StyleRules {
    return createStyles({
        clearOverlay: clearOverlay(),
        greyOverlay: greyOverlay(),
    });
}

function clearOverlay(): CSSProperties {
    return {
        position: 'absolute' as const,
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: 2000,
        // transform: 'translate(0%, -50%)',
        // outline: 'none',
    };
}

function greyOverlay(): CSSProperties {
    return {
        ...clearOverlay(),
        backgroundColor: 'black',
        opacity: 0.4,
    };
}
