import { Claim, claimSubmit } from '../../../claim';
import { IncomeClaim } from '../../../claim/reducers/claim-reducers/income-claim-reducer';
import { ReduxThunkAction } from '../../../redux/redux-actions';

export interface ClaimBoosterModel {
    policyNumber?: string;
    benefitName?: string;
    claimBoosterPercentage?: number;
    claimBoosterAmount?: number;
    includeClaimBooster?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UpdateIncomeCalculationReturn = Promise<any>;
export type UpdateIncomeCalculation = (
    claimBooster: ClaimBoosterModel
) => UpdateIncomeCalculationReturn;
type UpdateIncomeCalculationAction = (
    incomeCalculation: ClaimBoosterModel
) => ReduxThunkAction<UpdateIncomeCalculationReturn>;

export const updateIncomeCalculationAction: UpdateIncomeCalculationAction = (
    incomeCalculation: ClaimBoosterModel
) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, getUpdatedContractDetails(incomeCalculation));
    };
};

function getUpdatedContractDetails(claimBooster: ClaimBoosterModel): (claim: Claim) => Claim {
    return (claim: Claim) => {
        const incomeClaim = claim as IncomeClaim;

        const incomeCalculations = incomeClaim.incomeCalculations || [];

        const currentCalcIndex = incomeCalculations?.findIndex(
            i => i.policyNumber === claimBooster.policyNumber && i.benefitCode === '104'
        );
        if (incomeCalculations.length > 0 && currentCalcIndex > -1) {
            incomeCalculations[currentCalcIndex].includeClaimBooster =
                claimBooster.includeClaimBooster;
            incomeCalculations[currentCalcIndex].claimBoosterPercentage =
                claimBooster.claimBoosterPercentage;
            incomeCalculations[currentCalcIndex].claimBoosterAmount =
                claimBooster.claimBoosterAmount;
        }

        return {
            ...claim,
            incomeCalculations,
        };
    };
}
