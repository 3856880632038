import { collectionReducer } from '../../../../../../redux/collection-reducer';
import {
    ADD_POST_UNDERWRITING_CHANGE,
    DELETE_POST_UNDERWRITING_CHANGE,
    UPDATE_POST_UNDERWRITING_CHANGE,
} from './actions/post-underwriting-change-action-types';
import { PostUnderwritingChange } from './post-underwriting-change-model';

export const postUnderwritingChangesReducer = collectionReducer<PostUnderwritingChange>({
    addAction: ADD_POST_UNDERWRITING_CHANGE,
    updateAction: UPDATE_POST_UNDERWRITING_CHANGE,
    deleteAction: DELETE_POST_UNDERWRITING_CHANGE,
});
