import { capitalCase } from 'change-case';
import { SyncValidator, UserInput } from '.';

export function minDateValidator(
    comparisonDateFieldName: string,
    comparisonDateFieldLabel?: string
): SyncValidator {
    comparisonDateFieldLabel = comparisonDateFieldLabel || capitalCase(comparisonDateFieldName);
    const errorMessage = `Date must be after ${comparisonDateFieldLabel}`;

    return dateValidator(
        comparisonDateFieldName,
        errorMessage,
        (value, compareValue) => value >= compareValue
    );
}

export function maxDateValidator(
    comparisonDateFieldName: string,
    comparisonDateFieldLabel?: string
): SyncValidator {
    comparisonDateFieldLabel = comparisonDateFieldLabel || capitalCase(comparisonDateFieldName);
    const errorMessage = `Date must be before ${comparisonDateFieldLabel}`;

    return dateValidator(
        comparisonDateFieldName,
        errorMessage,
        (value, compareValue) => value <= compareValue
    );
}

export function dateValidator(
    comparisonDateFieldName: string,
    errorMessage: string,
    predicate: (dateValue: Date, comparisonDateValue: Date) => boolean
): SyncValidator {
    return function validateDate(value: UserInput, values?: any) {
        if (!value || !values || !values.hasOwnProperty(comparisonDateFieldName)) {
            return undefined;
        }

        const compareValue = values[comparisonDateFieldName] as Date;

        if (predicate(value as Date, compareValue)) {
            return undefined;
        }

        return errorMessage;
    };
}
