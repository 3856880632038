import { reduceAndSubmitClaim } from '../../../../../claim/actions/reduce-and-submit-claim';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { Comments } from '../reducer';
import { ADD_CLAIM_COMMENTS } from '../types';

type SubmitReturn = Promise<any>;
export type AddAndSubmitComments = (comments: Comments) => SubmitReturn;
type AddAndSubmitCommentsThunk = (comments: Comments) => ReduxThunkAction<SubmitReturn>;

export const addAndSubmitCommentsThunk: AddAndSubmitCommentsThunk = (comments: Comments) => (
    dispatch,
    getState
) => reduceAndSubmitClaim(dispatch, getState, addCommentsActionCreator(comments));

export const addCommentsActionCreator = (comments: Comments) => {
    return {
        type: ADD_CLAIM_COMMENTS,
        record: comments,
    };
};
