export const ClaimConditionsDread = [
    {
        value:
            'Accidental HIV - Accidental HIV - Proof of accidental contraction HIV as per policy document',
        label:
            'Accidental HIV - Accidental HIV - Proof of accidental contraction HIV as per policy document',
    },
    {
        value: 'AIDS - Proof of clinical manifestation of AIDS as per policy document',
        label: 'AIDS - Proof of clinical manifestation of AIDS as per policy document',
    },
    {
        value: `Alzheimer's disease and progressive dementia - On diagnosis of Alzheimer's disease or progressive dementia`,
        label: `Alzheimer's disease and progressive dementia - On diagnosis of Alzheimer's disease or progressive dementia`,
    },
    {
        value:
            'Aneurysm - Endovascular surgery or stent to repair any aneurysm of the following branches of the aorta: subclavian, brachiocephalic, splenic, renal, iliac and ileofemoral arteries',
        label:
            'Aneurysm - Endovascular surgery or stent to repair any aneurysm of the following branches of the aorta: subclavian, brachiocephalic, splenic, renal, iliac and ileofemoral arteries',
    },
    {
        value: 'Aneurysm - Endovascular surgery or stent to repair any aortic aneurysm',
        label: 'Aneurysm - Endovascular surgery or stent to repair any aortic aneurysm',
    },
    {
        value:
            'Aneurysm - Surgery (including by-pass graft or keyhole surgery) to repair an aneurysm, dissection or a narrowing of the thoracic or abdominal aorta',
        label:
            'Aneurysm - Surgery (including by-pass graft or keyhole surgery) to repair an aneurysm, dissection or a narrowing of the thoracic or abdominal aorta',
    },
    {
        value:
            'Aneurysm - Surgical repairs (including bypass graft or keyhole surgery) of an ileo-femoral aneurysm',
        label:
            'Aneurysm - Surgical repairs (including bypass graft or keyhole surgery) of an ileo-femoral aneurysm',
    },
    {
        value:
            'Aneurysm - Surgical repairs (including bypass graft or keyhole surgery) of any aneurysm of the following branches of the aorta: subclavian, brachiocephalic, splenic, renal and iliac arteries',
        label:
            'Aneurysm - Surgical repairs (including bypass graft or keyhole surgery) of any aneurysm of the following branches of the aorta: subclavian, brachiocephalic, splenic, renal and iliac arteries',
    },
    {
        value: 'Aplastic anaemia and other blood disorders - A bone marrow transplantation',
        label: 'Aplastic anaemia and other blood disorders - A bone marrow transplantation',
    },
    {
        value:
            'Aplastic anaemia and other blood disorders - Aplastic anaemia and other blood disorders requiring repeated blood or blood product transfusion',
        label:
            'Aplastic anaemia and other blood disorders - Aplastic anaemia and other blood disorders requiring repeated blood or blood product transfusion',
    },
    {
        value: 'Aplastic anaemia and other blood disorders - Aplastic anaemia/bone marrow aplasia',
        label: 'Aplastic anaemia and other blood disorders - Aplastic anaemia/bone marrow aplasia',
    },
    {
        value:
            'Aplastic anaemia and other blood disorders - Myelodyplastic syndrome coring 2.5 or higher on International Prognostic Scoring System (IPPS)',
        label:
            'Aplastic anaemia and other blood disorders - Myelodyplastic syndrome coring 2.5 or higher on International Prognostic Scoring System (IPPS)',
    },
    {
        value:
            'Aplastic anaemia and other blood disorders - Myelodyplastic syndrome requiring bone marrow transplant',
        label:
            'Aplastic anaemia and other blood disorders - Myelodyplastic syndrome requiring bone marrow transplant',
    },
    {
        value: 'Aplastic anaemia and other blood disorders - Myeloproliferitive disorder',
        label: 'Aplastic anaemia and other blood disorders - Myeloproliferitive disorder',
    },
    {
        value:
            'Aplastic anaemia and other blood disorders - Polycythemia vera with major organ failure',
        label:
            'Aplastic anaemia and other blood disorders - Polycythemia vera with major organ failure',
    },
    {
        value: 'Arrhythmia - Arrhythmia having undergone a defibrillator insertion',
        label: 'Arrhythmia - Arrhythmia having undergone a defibrillator insertion',
    },
    {
        value: 'Arrhythmia - Arrhythmia having undergone a permanent pacemaker insertion',
        label: 'Arrhythmia - Arrhythmia having undergone a permanent pacemaker insertion',
    },
    {
        value: 'Arrhythmia - Arrhythmia having undergone pathway ablation',
        label: 'Arrhythmia - Arrhythmia having undergone pathway ablation',
    },
    {
        value: 'Arrhythmia - Intravenous inotropic support for more than 2 days',
        label: 'Arrhythmia - Intravenous inotropic support for more than 2 days',
    },
    {
        value: 'Arrhythmia - Permanent atrial fibrillation on optimal treatment',
        label: 'Arrhythmia - Permanent atrial fibrillation on optimal treatment',
    },
    {
        value: 'Cancer and leukaemia - Bladder cancer',
        label: 'Cancer and leukaemia - Bladder cancer',
    },
    {
        value: 'Cancer and leukaemia - Blood/bone marrow cancer',
        label: 'Cancer and leukaemia - Blood/bone marrow cancer',
    },
    { value: 'Cancer and leukaemia - Brain cancer', label: 'Cancer and leukaemia - Brain cancer' },
    {
        value: 'Cancer and leukaemia - Breast cancer',
        label: 'Cancer and leukaemia - Breast cancer',
    },
    {
        value: 'Cancer and leukaemia - Cervical cancer',
        label: 'Cancer and leukaemia - Cervical cancer',
    },
    {
        value: 'Cancer and leukaemia - Colorectal cancer',
        label: 'Cancer and leukaemia - Colorectal cancer',
    },
    {
        value: 'Cancer and leukaemia - Endometrial cancer',
        label: 'Cancer and leukaemia - Endometrial cancer',
    },
    { value: 'Cancer and leukaemia - Eye cancer', label: 'Cancer and leukaemia - Eye cancer' },
    { value: 'Cancer and leukaemia - Liver cancer', label: 'Cancer and leukaemia - Liver cancer' },
    { value: 'Cancer and leukaemia - Lung cancer', label: 'Cancer and leukaemia - Lung cancer' },
    { value: 'Cancer and leukaemia - Lymph cancer', label: 'Cancer and leukaemia - Lymph cancer' },
    {
        value: 'Cancer and leukaemia - Metastatic cancer',
        label: 'Cancer and leukaemia - Metastatic cancer',
    },
    {
        value: 'Cancer and leukaemia - Oesophageal cancer',
        label: 'Cancer and leukaemia - Oesophageal cancer',
    },
    { value: 'Cancer and leukaemia - Other cancer', label: 'Cancer and leukaemia - Other cancer' },
    {
        value: 'Cancer and leukaemia - Ovarian cancer',
        label: 'Cancer and leukaemia - Ovarian cancer',
    },
    {
        value: 'Cancer and leukaemia - Pancreatic cancer',
        label: 'Cancer and leukaemia - Pancreatic cancer',
    },
    {
        value: 'Cancer and leukaemia - Prostate cancer',
        label: 'Cancer and leukaemia - Prostate cancer',
    },
    { value: 'Cancer and leukaemia - Renal cancer', label: 'Cancer and leukaemia - Renal cancer' },
    { value: 'Cancer and leukaemia - Skin cancer', label: 'Cancer and leukaemia - Skin cancer' },
    {
        value: 'Cancer and leukaemia - Stomach cancer',
        label: 'Cancer and leukaemia - Stomach cancer',
    },
    {
        value: 'Cancer and leukaemia - Testicular cancer',
        label: 'Cancer and leukaemia - Testicular cancer',
    },
    {
        value: 'Cancer and leukaemia - Tongue cancer',
        label: 'Cancer and leukaemia - Tongue cancer',
    },
    {
        value: 'Cancer and leukaemia - Thyroid cancer',
        label: 'Cancer and leukaemia - Thyroid cancer',
    },
    {
        value: 'Cancer and leukaemia - Uterus cancer',
        label: 'Cancer and leukaemia - Uterus cancer',
    },
    { value: 'Cancer and leukaemia - Vulva cancer', label: 'Cancer and leukaemia - Vulva cancer' },
    { value: 'Cardiomyopathy - Heart transplant', label: 'Cardiomyopathy - Heart transplant' },
    {
        value:
            'Cardiomyopathy - Receiving optimal treatment (whether that is medical or surgical) for a cardiomyopathy',
        label:
            'Cardiomyopathy - Receiving optimal treatment (whether that is medical or surgical) for a cardiomyopathy',
    },
    {
        value:
            'Cardiomyopathy - Receiving optimal treatment (whether that is medical or surgical),for a cardiomyopathy',
        label:
            'Cardiomyopathy - Receiving optimal treatment (whether that is medical or surgical),for a cardiomyopathy',
    },
    {
        value:
            'Cardio-pulmonary dysfunction - Cardio-pulmonary dysfunction resulting from or presenting as confirmed irreversible Cor Pulmonale',
        label:
            'Cardio-pulmonary dysfunction - Cardio-pulmonary dysfunction resulting from or presenting as confirmed irreversible Cor Pulmonale',
    },
    {
        value:
            'Cardio-pulmonary dysfunction - Cardio-pulmonary dysfunction resulting from or presenting as primary pulmonary hypertension',
        label:
            'Cardio-pulmonary dysfunction - Cardio-pulmonary dysfunction resulting from or presenting as primary pulmonary hypertension',
    },
    {
        value:
            'Cardio-pulmonary dysfunction - Cardio-pulmonary dysfunction resulting from or presenting as pulmonary venous occlusive disease',
        label:
            'Cardio-pulmonary dysfunction - Cardio-pulmonary dysfunction resulting from or presenting as pulmonary venous occlusive disease',
    },
    {
        value:
            'Carotid arterial disease - Angioplasty with or without stenting in both carotid arteries',
        label:
            'Carotid arterial disease - Angioplasty with or without stenting in both carotid arteries',
    },
    {
        value:
            'Carotid arterial disease - Angioplasty with or without stenting in one carotid artery',
        label:
            'Carotid arterial disease - Angioplasty with or without stenting in one carotid artery',
    },
    {
        value:
            'Carotid arterial disease - By-pass graft or endarterectomy in both carotid arteries',
        label:
            'Carotid arterial disease - By-pass graft or endarterectomy in both carotid arteries',
    },
    {
        value: 'Carotid arterial disease - By-pass graft or endarterectomy in one carotid artery',
        label: 'Carotid arterial disease - By-pass graft or endarterectomy in one carotid artery',
    },
    {
        value:
            'Cerebrovascular incident (Stoke) - A stroke where there are residual symptoms or signs and a full recovery is not made',
        label:
            'Cerebrovascular incident (Stoke) - A stroke where there are residual symptoms or signs and a full recovery is not made',
    },
    {
        value:
            'Cerebrovascular incident (Stoke) - On diagnosis of a stroke as defined in the policy document',
        label:
            'Cerebrovascular incident (Stoke) - On diagnosis of a stroke as defined in the policy document',
    },
    {
        value: 'Cerebrovascular incident (Stoke) - Stroke resulting in full recovery',
        label: 'Cerebrovascular incident (Stoke) - Stroke resulting in full recovery',
    },
    {
        value:
            'Cerebrovascular incident (Stoke) - Stroke resulting in permanent neurological impairment ',
        label:
            'Cerebrovascular incident (Stoke) - Stroke resulting in permanent neurological impairment ',
    },
    {
        value:
            'Cerebrovascular incident (Stoke) - Stroke resulting in permanent neurological impairment measured by',
        label:
            'Cerebrovascular incident (Stoke) - Stroke resulting in permanent neurological impairment measured by',
    },
    {
        value: 'Cerebrovascular incident (Stoke) - Subarachnoid haemorrhage',
        label: 'Cerebrovascular incident (Stoke) - Subarachnoid haemorrhage',
    },
    {
        value: 'Chronic liver disorders - A liver transplant or total hepatectomy',
        label: 'Chronic liver disorders - A liver transplant or total hepatectomy',
    },
    {
        value: 'Chronic liver disorders - End-stage liver failure',
        label: 'Chronic liver disorders - End-stage liver failure',
    },
    {
        value: 'Chronic liver disorders - Fulminant hepatic necrosis',
        label: 'Chronic liver disorders - Fulminant hepatic necrosis',
    },
    {
        value: 'Chronic liver disorders - Liver dysfunction classified as Child-Pugh class A',
        label: 'Chronic liver disorders - Liver dysfunction classified as Child-Pugh class A',
    },
    {
        value: 'Chronic liver disorders - Liver dysfunction classified as Child-Pugh class B',
        label: 'Chronic liver disorders - Liver dysfunction classified as Child-Pugh class B',
    },
    {
        value: 'Chronic liver disorders - Liver dysfunction classified as Child-Pugh class C',
        label: 'Chronic liver disorders - Liver dysfunction classified as Child-Pugh class C',
    },
    {
        value:
            'Chronic liver disorders - On confirmed diagnosis of primary amyloidosis of the liver or spleen',
        label:
            'Chronic liver disorders - On confirmed diagnosis of primary amyloidosis of the liver or spleen',
    },
    {
        value: 'Chronic liver disorders - Partial hepatectomy due to disease',
        label: 'Chronic liver disorders - Partial hepatectomy due to disease',
    },
    {
        value: 'Chronic liver disorders - Portal hypertesion',
        label: 'Chronic liver disorders - Portal hypertesion',
    },
    {
        value:
            'Coma - Coma meeting the description in the policy document and resulting in permanent neurological deficit despite maximum medical improvement resulting in permanent inability to independently perform Activities of Daily Living',
        label:
            'Coma - Coma meeting the description in the policy document and resulting in permanent neurological deficit despite maximum medical improvement resulting in permanent inability to independently perform Activities of Daily Living',
    },
    {
        value:
            'Coma - Coma meeting the description in the policy document and the comatose state is persistent for longer than 96 hours',
        label:
            'Coma - Coma meeting the description in the policy document and the comatose state is persistent for longer than 96 hours',
    },
    {
        value: 'Coronary artery disease - Angioplasty with or without stenting',
        label: 'Coronary artery disease - Angioplasty with or without stenting',
    },
    {
        value: 'Coronary artery disease - Heart transplant',
        label: 'Coronary artery disease - Heart transplant',
    },
    {
        value:
            'Coronary artery disease - Surgery to correct the narrowing of, or blockage to, any one coronary artery by means of a by-pass graft',
        label:
            'Coronary artery disease - Surgery to correct the narrowing of, or blockage to, any one coronary artery by means of a by-pass graft',
    },
    {
        value:
            'Coronary artery disease - Surgery to correct the narrowing of, or blockage to, three or more coronary arteries by means of a by-pass graft',
        label:
            'Coronary artery disease - Surgery to correct the narrowing of, or blockage to, three or more coronary arteries by means of a by-pass graft',
    },
    {
        value:
            'Coronary artery disease - Surgery to correct the narrowing of, or blockage to, two coronary arteries by means of a by-pass graft',
        label:
            'Coronary artery disease - Surgery to correct the narrowing of, or blockage to, two coronary arteries by means of a by-pass graft',
    },
    {
        value:
            'Coronary artery disease - Unstable angina requiring intravenous thrombolytic therapy (excluding anticoagulant and anti-platelet agents)',
        label:
            'Coronary artery disease - Unstable angina requiring intravenous thrombolytic therapy (excluding anticoagulant and anti-platelet agents)',
    },
    {
        value: 'Ear, nose and throat disorders - Cochlear or middle ear implant',
        label: 'Ear, nose and throat disorders - Cochlear or middle ear implant',
    },
    {
        value: 'Ear, nose and throat disorders - Hearing loss',
        label: 'Ear, nose and throat disorders - Hearing loss',
    },
    {
        value: 'Ear, nose and throat disorders - Mastoiditis',
        label: 'Ear, nose and throat disorders - Mastoiditis',
    },
    {
        value: 'Ear, nose and throat disorders - Nasal bone destruction',
        label: 'Ear, nose and throat disorders - Nasal bone destruction',
    },
    {
        value: 'Ear, nose and throat disorders - Otosclerosis',
        label: 'Ear, nose and throat disorders - Otosclerosis',
    },
    {
        value: 'Ear, nose and throat disorders - Tympanosclerosis',
        label: 'Ear, nose and throat disorders - Tympanosclerosis',
    },
    { value: 'Endocrine conditions - Acromegaly', label: 'Endocrine conditions - Acromegaly' },
    {
        value: 'Endocrine conditions - Addisonian crisis',
        label: 'Endocrine conditions - Addisonian crisis',
    },
    {
        value: `Endocrine conditions - Conn's syndrome`,
        label: `Endocrine conditions - Conn's syndrome`,
    },
    {
        value: `Endocrine conditions - Cushing's syndrome`,
        label: `Endocrine conditions - Cushing's syndrome`,
    },
    {
        value: 'Endocrine conditions - Diabetes insipidus',
        label: 'Endocrine conditions - Diabetes insipidus',
    },
    {
        value: 'Endocrine conditions - Endocrine tumours having undergone surgical excision',
        label: 'Endocrine conditions - Endocrine tumours having undergone surgical excision',
    },
    {
        value: 'Endocrine conditions - Insulin treatment for Type II diabetes mellitus',
        label: 'Endocrine conditions - Insulin treatment for Type II diabetes mellitus',
    },
    {
        value: 'Endocrine conditions - Parathyroid tetany',
        label: 'Endocrine conditions - Parathyroid tetany',
    },
    {
        value: `Endocrine conditions - Sheehan's syndrome`,
        label: `Endocrine conditions - Sheehan's syndrome`,
    },
    {
        value: `Endocrine conditions - Simmond's disease`,
        label: `Endocrine conditions - Simmond's disease`,
    },
    {
        value: 'Endocrine conditions - Thyrotoxic crisis',
        label: 'Endocrine conditions - Thyrotoxic crisis',
    },
    {
        value: 'Endocrine conditions - Type I diabetes',
        label: 'Endocrine conditions - Type I diabetes',
    },
    {
        value: 'Eye disorders - Chronic corneal ulcer',
        label: 'Eye disorders - Chronic corneal ulcer',
    },
    { value: 'Eye disorders - Corneal transplant', label: 'Eye disorders - Corneal transplant' },
    {
        value: 'Eye disorders - Irreversible diabetic retinopathy (Grade III)',
        label: 'Eye disorders - Irreversible diabetic retinopathy (Grade III)',
    },
    {
        value: 'Eye disorders - Irreversible diabetic retinopathy (Grade IV)',
        label: 'Eye disorders - Irreversible diabetic retinopathy (Grade IV)',
    },
    {
        value: 'Eye disorders - Irreversible hemianopia in both eyes',
        label: 'Eye disorders - Irreversible hemianopia in both eyes',
    },
    {
        value: 'Eye disorders - Irreversible hemianopia in one eye',
        label: 'Eye disorders - Irreversible hemianopia in one eye',
    },
    {
        value: 'Eye disorders - Irreversible loss of sight in both eyes',
        label: 'Eye disorders - Irreversible loss of sight in both eyes',
    },
    {
        value: 'Eye disorders - Irreversible loss of sight in one eye',
        label: 'Eye disorders - Irreversible loss of sight in one eye',
    },
    { value: 'Eye disorders - Optic neuritis', label: 'Eye disorders - Optic neuritis' },
    { value: 'Eye disorders - Orbital abscess', label: 'Eye disorders - Orbital abscess' },
    { value: 'Eye disorders - Retinal detachment', label: 'Eye disorders - Retinal detachment' },
    {
        value: `Gastrointestinal disease - Crohn's disease or Ulcerative Colitis`,
        label: `Gastrointestinal disease - Crohn's disease or Ulcerative Colitis`,
    },
    {
        value: 'Gastrointestinal disease - Rectal fistula having undergone surgery',
        label: 'Gastrointestinal disease - Rectal fistula having undergone surgery',
    },
    {
        value: 'Gastrointestinal disease - Total colectomy',
        label: 'Gastrointestinal disease - Total colectomy',
    },
    {
        value: 'Gastrointestinal disease - Trachael oesophageal fistula having undergone surgery',
        label: 'Gastrointestinal disease - Trachael oesophageal fistula having undergone surgery',
    },
    { value: 'Heart transplant - Heart transplant', label: 'Heart transplant - Heart transplant' },
    {
        value: 'Living Lifestyle catch-all - Diagnosis of a terminal illness',
        label: 'Living Lifestyle catch-all - Diagnosis of a terminal illness',
    },
    {
        value:
            'Living Lifestyle catch-all - Permanent inability to independently perform Activities of Daily Living',
        label:
            'Living Lifestyle catch-all - Permanent inability to independently perform Activities of Daily Living',
    },
    {
        value: 'Living Lifestyle catch-all - Whole Person Impairment',
        label: 'Living Lifestyle catch-all - Whole Person Impairment',
    },
    {
        value: 'Loss of and loss of use of limbs - Loss of use of or loss of 1 foot',
        label: 'Loss of and loss of use of limbs - Loss of use of or loss of 1 foot',
    },
    {
        value: 'Loss of and loss of use of limbs - Loss of use of or loss of 1 hand',
        label: 'Loss of and loss of use of limbs - Loss of use of or loss of 1 hand',
    },
    {
        value: 'Loss of and loss of use of limbs - Loss of use of or loss of 1 hand and 1 foot',
        label: 'Loss of and loss of use of limbs - Loss of use of or loss of 1 hand and 1 foot',
    },
    {
        value: 'Loss of and loss of use of limbs - Loss of use of or loss of 1 limb',
        label: 'Loss of and loss of use of limbs - Loss of use of or loss of 1 limb',
    },
    {
        value: 'Loss of and loss of use of limbs - Loss of use of or loss of 2 feet',
        label: 'Loss of and loss of use of limbs - Loss of use of or loss of 2 feet',
    },
    {
        value: 'Loss of and loss of use of limbs - Loss of use of or loss of 2 hands',
        label: 'Loss of and loss of use of limbs - Loss of use of or loss of 2 hands',
    },
    {
        value:
            'Loss of and loss of use of limbs - Loss of use of or loss of 3 or more fingers on the same hand',
        label:
            'Loss of and loss of use of limbs - Loss of use of or loss of 3 or more fingers on the same hand',
    },
    {
        value: 'Loss of and loss of use of limbs - Loss of use of or loss of a thumb',
        label: 'Loss of and loss of use of limbs - Loss of use of or loss of a thumb',
    },
    {
        value: 'Loss of and loss of use of limbs - Loss of use of or loss of more than one limb',
        label: 'Loss of and loss of use of limbs - Loss of use of or loss of more than one limb',
    },
    {
        value: 'Loss of and loss of use of limbs - Major joint replacement',
        label: 'Loss of and loss of use of limbs - Major joint replacement',
    },
    {
        value:
            'Loss of and loss of use of limbs - Surgical repair of major motor nerve after complete severance',
        label:
            'Loss of and loss of use of limbs - Surgical repair of major motor nerve after complete severance',
    },
    { value: 'Major burns - Full thickness burns', label: 'Major burns - Full thickness burns' },
    {
        value: 'Major burns - Partial thickness burns',
        label: 'Major burns - Partial thickness burns',
    },
    {
        value:
            'Motor neuron disease - On diagnosis of motor neurone disease by an appropriate specialist',
        label:
            'Motor neuron disease - On diagnosis of motor neurone disease by an appropriate specialist',
    },
    {
        value: 'Multiple sclerosis - On diagnosis of multiple sclerosis',
        label: 'Multiple sclerosis - On diagnosis of multiple sclerosis',
    },
    {
        value: 'Multiple sclerosis - On diagnosis of progressive multiple sclerosis',
        label: 'Multiple sclerosis - On diagnosis of progressive multiple sclerosis',
    },
    {
        value:
            'Multiple sclerosis - Optic neuritis where 2 or more plaques are seen to be demyelinating on an MRI',
        label:
            'Multiple sclerosis - Optic neuritis where 2 or more plaques are seen to be demyelinating on an MRI',
    },
    {
        value:
            'Multiple sclerosis - Relapsing-remitting multiple sclerosis resulting in the permanent inability to independently perform Activities of Daily Living',
        label:
            'Multiple sclerosis - Relapsing-remitting multiple sclerosis resulting in the permanent inability to independently perform Activities of Daily Living',
    },
    {
        value:
            'Muscular dystrophy - Muscular dystrophy resulting in permanent inability to independently perform Activities of Daily Living',
        label:
            'Muscular dystrophy - Muscular dystrophy resulting in permanent inability to independently perform Activities of Daily Living',
    },
    {
        value: 'Muscular dystrophy - On diagnosis of muscular dystrophy',
        label: 'Muscular dystrophy - On diagnosis of muscular dystrophy',
    },
    {
        value:
            'Myasthenia gravis - Myasthenia gravis resulting in the permanent inability to independently perform activities of daily living',
        label:
            'Myasthenia gravis - Myasthenia gravis resulting in the permanent inability to independently perform activities of daily living',
    },
    {
        value: 'Myasthenia gravis - On diagnosis of myasthenia gravis',
        label: 'Myasthenia gravis - On diagnosis of myasthenia gravis',
    },
    {
        value:
            'Myocardial infarction (heart attack) - Acute heart failure as diagnosed by an appropriate specialist and EF < 45 % at time of diagnosis',
        label:
            'Myocardial infarction (heart attack) - Acute heart failure as diagnosed by an appropriate specialist and EF < 45 % at time of diagnosis',
    },
    {
        value: 'Myocardial infarction (heart attack) - Heart transplant',
        label: 'Myocardial infarction (heart attack) - Heart transplant',
    },
    {
        value:
            'Myocardial infarction (heart attack) - Myocardial infarction as defined in the policy document, but Trop T > 1,0 ng/ml or Trop I > 0,5 ng/ml',
        label:
            'Myocardial infarction (heart attack) - Myocardial infarction as defined in the policy document, but Trop T > 1,0 ng/ml or Trop I > 0,5 ng/ml',
    },
    {
        value:
            'Myocardial infarction (heart attack) - Myocardial infarction resulting in at least 2 of the following: ',
        label:
            'Myocardial infarction (heart attack) - Myocardial infarction resulting in at least 2 of the following: ',
    },
    {
        value:
            'Myocardial infarction (heart attack) - On diagnosis of a myocardial infarction as specified at the beginning of benefit category 2.3 Myocardial Infarction',
        label:
            'Myocardial infarction (heart attack) - On diagnosis of a myocardial infarction as specified at the beginning of benefit category 2.3 Myocardial Infarction',
    },
    {
        value:
            'Myocardial infarction (heart attack) - On diagnosis of a myocardial infarction as specified at the beginning of benefit category 2.3 Myocardial Infarction; and receiving optimal treatment (whether that is medical or surgical)',
        label:
            'Myocardial infarction (heart attack) - On diagnosis of a myocardial infarction as specified at the beginning of benefit category 2.3 Myocardial Infarction; and receiving optimal treatment (whether that is medical or surgical)',
    },
    {
        value: 'Other autoimmune disorders - Arteritis or endarteritis',
        label: 'Other autoimmune disorders - Arteritis or endarteritis',
    },
    {
        value: 'Other autoimmune disorders - Complications involving the musculoskeletal system',
        label: 'Other autoimmune disorders - Complications involving the musculoskeletal system',
    },
    {
        value: 'Other autoimmune disorders - Disorders involving one major organ',
        label: 'Other autoimmune disorders - Disorders involving one major organ',
    },
    {
        value:
            'Other autoimmune disorders - Disorders producing systoms and involving one or more major organs',
        label:
            'Other autoimmune disorders - Disorders producing systoms and involving one or more major organs',
    },
    {
        value:
            'Other autoimmune disorders - Disorders producing systoms and involving three or more major organs',
        label:
            'Other autoimmune disorders - Disorders producing systoms and involving three or more major organs',
    },
    {
        value:
            'Other autoimmune disorders - Disorders producing systoms and involving two or more major organs',
        label:
            'Other autoimmune disorders - Disorders producing systoms and involving two or more major organs',
    },
    {
        value:
            'Other autoimmune disorders - Disorders resulting in permanent inability to independently perform Activities of Daily Living',
        label:
            'Other autoimmune disorders - Disorders resulting in permanent inability to independently perform Activities of Daily Living',
    },
    {
        value:
            'Other autoimmune disorders - Disorders resulting in the failure of one or more major organs despite optimal treatment as defined in the relevant benefit categories, excluding the skin',
        label:
            'Other autoimmune disorders - Disorders resulting in the failure of one or more major organs despite optimal treatment as defined in the relevant benefit categories, excluding the skin',
    },
    {
        value:
            'Other central nervous system disorders - Any condition or brain abscess having undergone open brain surgery',
        label:
            'Other central nervous system disorders - Any condition or brain abscess having undergone open brain surgery',
    },
    {
        value: 'Other central nervous system disorders - Benign brain tumour',
        label: 'Other central nervous system disorders - Benign brain tumour',
    },
    {
        value:
            'Other central nervous system disorders - Benign brain tumour or brain abscess, intracranial aneurysm, or arterio-venous malformation resulting in permanent neurological impairment, assessed according to the permanent inability to independently perform Activities of Daily Living',
        label:
            'Other central nervous system disorders - Benign brain tumour or brain abscess, intracranial aneurysm, or arterio-venous malformation resulting in permanent neurological impairment, assessed according to the permanent inability to independently perform Activities of Daily Living',
    },
    {
        value:
            'Other central nervous system disorders - Brain abscess having undergone surgical drainage',
        label:
            'Other central nervous system disorders - Brain abscess having undergone surgical drainage',
    },
    {
        value: 'Other central nervous system disorders - Cerebral malaria',
        label: 'Other central nervous system disorders - Cerebral malaria',
    },
    {
        value:
            'Other central nervous system disorders - Cerebral malaria resulting in permanent neurological impairment, assessed according to the permanent inability to independently perform Activities of Daily Living',
        label:
            'Other central nervous system disorders - Cerebral malaria resulting in permanent neurological impairment, assessed according to the permanent inability to independently perform Activities of Daily Living',
    },
    {
        value:
            'Other central nervous system disorders - Cerebral oedema having undergone in-hospital treatment with specific therapeutic interventions',
        label:
            'Other central nervous system disorders - Cerebral oedema having undergone in-hospital treatment with specific therapeutic interventions',
    },
    {
        value:
            'Other central nervous system disorders - Conditions having undergone open brain surgery excluding arterio-venous malformation or intracranial aneurysm',
        label:
            'Other central nervous system disorders - Conditions having undergone open brain surgery excluding arterio-venous malformation or intracranial aneurysm',
    },
    {
        value: 'Other central nervous system disorders - Guillain-BarrÃ© syndrome',
        label: 'Other central nervous system disorders - Guillain-BarrÃ© syndrome',
    },
    {
        value: 'Other central nervous system disorders - Hydrocephalus',
        label: 'Other central nervous system disorders - Hydrocephalus',
    },
    {
        value:
            'Other central nervous system disorders - Intracranial aneurysm or arterio-venous malformation',
        label:
            'Other central nervous system disorders - Intracranial aneurysm or arterio-venous malformation',
    },
    {
        value:
            'Other central nervous system disorders - Permanent and complete inability to communicate or comprehend language symbols',
        label:
            'Other central nervous system disorders - Permanent and complete inability to communicate or comprehend language symbols',
    },
    {
        value:
            'Other central nervous system disorders - Raised intracranial pressure causing symptoms',
        label:
            'Other central nervous system disorders - Raised intracranial pressure causing symptoms',
    },
    {
        value:
            'Other central nervous system disorders - Recurrent benign brain tumour showing symptoms',
        label:
            'Other central nervous system disorders - Recurrent benign brain tumour showing symptoms',
    },
    {
        value:
            'Other central nervous system disorders - Status epilepticus (unremitting convulsions) resulting in permanent neurological impairment, assessed according to the permanent inability to independently perform Activities of Daily Living',
        label:
            'Other central nervous system disorders - Status epilepticus (unremitting convulsions) resulting in permanent neurological impairment, assessed according to the permanent inability to independently perform Activities of Daily Living',
    },
    {
        value: 'Pancreatic disorders - A pancreas transplant',
        label: 'Pancreatic disorders - A pancreas transplant',
    },
    {
        value: 'Pancreatic disorders - Chronic pancreatitis',
        label: 'Pancreatic disorders - Chronic pancreatitis',
    },
    {
        value: 'Pancreatic disorders - Complete pancreatectomy',
        label: 'Pancreatic disorders - Complete pancreatectomy',
    },
    {
        value: 'Pancreatic disorders - Pancreatic abscess having undergone surgical drainage',
        label: 'Pancreatic disorders - Pancreatic abscess having undergone surgical drainage',
    },
    {
        value: 'Pancreatic disorders - Partial pancreatectomy due to disease',
        label: 'Pancreatic disorders - Partial pancreatectomy due to disease',
    },
    { value: 'Paralysis - Diplegia', label: 'Paralysis - Diplegia' },
    { value: 'Paralysis - Hemiplegia', label: 'Paralysis - Hemiplegia' },
    { value: 'Paralysis - Paraplegia', label: 'Paralysis - Paraplegia' },
    { value: 'Paralysis - Quadriplegia', label: 'Paralysis - Quadriplegia' },
    {
        value: `Parkinson's disease - On diagnosis of Parkinson's disease`,
        label: `Parkinson's disease - On diagnosis of Parkinson's disease`,
    },
    {
        value: `Parkinson's disease - Parkinson's disease resulting in the permanent inability to independently perform Activities of Daily Living`,
        label: `Parkinson's disease - Parkinson's disease resulting in the permanent inability to independently perform Activities of Daily Living`,
    },
    {
        value:
            'Pericardial disease - Acute or chronic pericarditis as seen on special investigation',
        label:
            'Pericardial disease - Acute or chronic pericarditis as seen on special investigation',
    },
    {
        value: 'Pericardial disease - Keyhole pericardiectomy',
        label: 'Pericardial disease - Keyhole pericardiectomy',
    },
    {
        value: 'Pericardial disease - Open-heart pericardiectomy',
        label: 'Pericardial disease - Open-heart pericardiectomy',
    },
    {
        value: 'Pericardial disease - Pericardiectomy done endoscopically',
        label: 'Pericardial disease - Pericardiectomy done endoscopically',
    },
    {
        value:
            'Pericardial disease - Receiving optimal treatment (whether that is medical or surgical) for a pericardial disease',
        label:
            'Pericardial disease - Receiving optimal treatment (whether that is medical or surgical) for a pericardial disease',
    },
    {
        value:
            'Peripheral arterial disease - Gangrene or loss of limb due to peripheral arterial disease',
        label:
            'Peripheral arterial disease - Gangrene or loss of limb due to peripheral arterial disease',
    },
    {
        value:
            'Peripheral arterial disease - Occlusion of major artery (50% or more) as evidenced by positive imaging and clinical findings',
        label:
            'Peripheral arterial disease - Occlusion of major artery (50% or more) as evidenced by positive imaging and clinical findings',
    },
    {
        value:
            'Peripheral arterial disease - On diagnosis of a peripheral arterial disease as specified at the beginning of benefit category 2.7 Peripheral arterial disease; and angioplasty with 2 or more stents in a single vessel or in separate vessels',
        label:
            'Peripheral arterial disease - On diagnosis of a peripheral arterial disease as specified at the beginning of benefit category 2.7 Peripheral arterial disease; and angioplasty with 2 or more stents in a single vessel or in separate vessels',
    },
    {
        value:
            'Peripheral arterial disease - On diagnosis of a peripheral arterial disease as specified at the beginning of benefit category 2.7 Peripheral arterial disesse; and angioplasty with or without stenting of a peripheral artery',
        label:
            'Peripheral arterial disease - On diagnosis of a peripheral arterial disease as specified at the beginning of benefit category 2.7 Peripheral arterial disesse; and angioplasty with or without stenting of a peripheral artery',
    },
    {
        value:
            'Peripheral arterial disease - Receiving optimal treatment (whether that is surgical or medical) for peripheral arterial disease, but still experiencing persistent claudication and leg ulcer/s, dystrophic changes or colour changes',
        label:
            'Peripheral arterial disease - Receiving optimal treatment (whether that is surgical or medical) for peripheral arterial disease, but still experiencing persistent claudication and leg ulcer/s, dystrophic changes or colour changes',
    },
    {
        value:
            'Peripheral arterial disease - Surgery to correct the narrowing of, or blockage to, a peripheral artery / arteries by means of a by-pass graft',
        label:
            'Peripheral arterial disease - Surgery to correct the narrowing of, or blockage to, a peripheral artery / arteries by means of a by-pass graft',
    },
    {
        value: 'Renal disorders - Chronic nephrotic syndrome',
        label: 'Renal disorders - Chronic nephrotic syndrome',
    },
    {
        value: 'Renal disorders - Chronic tubulointerstitial disease',
        label: 'Renal disorders - Chronic tubulointerstitial disease',
    },
    {
        value:
            'Renal disorders - Chronic, irreversible kidney failure requiring and already having undergone regular dialysis treatment',
        label:
            'Renal disorders - Chronic, irreversible kidney failure requiring and already having undergone regular dialysis treatment',
    },
    {
        value:
            'Renal disorders - Endoscopic or minimally invasive renal surgery (excluding biopsy or any surgery for renal stones)',
        label:
            'Renal disorders - Endoscopic or minimally invasive renal surgery (excluding biopsy or any surgery for renal stones)',
    },
    { value: 'Renal disorders - Kidney transplant', label: 'Renal disorders - Kidney transplant' },
    {
        value: 'Renal disorders - Nephrotic syndrome with renal artery or renal vein thrombosis',
        label: 'Renal disorders - Nephrotic syndrome with renal artery or renal vein thrombosis',
    },
    {
        value: 'Renal disorders - Open kidney surgery not for diagnostic purposes',
        label: 'Renal disorders - Open kidney surgery not for diagnostic purposes',
    },
    {
        value: 'Renal disorders - Partial or total nephrectomy',
        label: 'Renal disorders - Partial or total nephrectomy',
    },
    {
        value: 'Renal disorders - Primary amyloidosis of the kidney',
        label: 'Renal disorders - Primary amyloidosis of the kidney',
    },
    {
        value: 'Renal disorders - Progressive chronic kidney disease',
        label: 'Renal disorders - Progressive chronic kidney disease',
    },
    { value: 'Renal disorders - Renal abscess', label: 'Renal disorders - Renal abscess' },
    {
        value: 'Renal disorders - Renal cortical necrosis',
        label: 'Renal disorders - Renal cortical necrosis',
    },
    {
        value: 'Respiratory disorders - Bronchietasis',
        label: 'Respiratory disorders - Bronchietasis',
    },
    {
        value: 'Respiratory disorders - Bronchopleural fistula having undergone surgery',
        label: 'Respiratory disorders - Bronchopleural fistula having undergone surgery',
    },
    {
        value: 'Respiratory disorders - Chronic irreversible lung diseases',
        label: 'Respiratory disorders - Chronic irreversible lung diseases',
    },
    {
        value: 'Respiratory disorders - Decortication',
        label: 'Respiratory disorders - Decortication',
    },
    {
        value:
            'Respiratory disorders - Drainage of empyema or lung abscess or pleural effusion requiring drainage',
        label:
            'Respiratory disorders - Drainage of empyema or lung abscess or pleural effusion requiring drainage',
    },
    {
        value: 'Respiratory disorders - Fibrosing alveolitis',
        label: 'Respiratory disorders - Fibrosing alveolitis',
    },
    {
        value: 'Respiratory disorders - Heart-lung transplant',
        label: 'Respiratory disorders - Heart-lung transplant',
    },
    {
        value: 'Respiratory disorders - Insertion of veno-caval filter',
        label: 'Respiratory disorders - Insertion of veno-caval filter',
    },
    {
        value: 'Respiratory disorders - Interstitial lung diseases',
        label: 'Respiratory disorders - Interstitial lung diseases',
    },
    {
        value: 'Respiratory disorders - Lung transplant',
        label: 'Respiratory disorders - Lung transplant',
    },
    {
        value: 'Respiratory disorders - Other open lung surgery not for diagnostic purposes',
        label: 'Respiratory disorders - Other open lung surgery not for diagnostic purposes',
    },
    { value: 'Respiratory disorders - Pleurectomy', label: 'Respiratory disorders - Pleurectomy' },
    {
        value: 'Respiratory disorders - Pneumoconiosis',
        label: 'Respiratory disorders - Pneumoconiosis',
    },
    {
        value: 'Respiratory disorders - Pulmonary emboli as seen on special investigations',
        label: 'Respiratory disorders - Pulmonary emboli as seen on special investigations',
    },
    {
        value: 'Respiratory disorders - Recurrent pulmonary embolism',
        label: 'Respiratory disorders - Recurrent pulmonary embolism',
    },
    {
        value: 'Respiratory disorders - Removal of a lung',
        label: 'Respiratory disorders - Removal of a lung',
    },
    {
        value: 'Respiratory disorders - Removal of one lobe of the lungs',
        label: 'Respiratory disorders - Removal of one lobe of the lungs',
    },
    {
        value:
            'Respiratory disorders - Status asthmaticus having undergone intubation and ICU admission',
        label:
            'Respiratory disorders - Status asthmaticus having undergone intubation and ICU admission',
    },
    {
        value:
            'Rheumatoid arthritis - Musculoskeletal involvement having undergone surgery including joint replacement, joint reconstruction or joint fixation',
        label:
            'Rheumatoid arthritis - Musculoskeletal involvement having undergone surgery including joint replacement, joint reconstruction or joint fixation',
    },
    {
        value:
            'Rheumatoid arthritis - Musculoskeletal involvement of the major joints with evidence of ongoing therapy',
        label:
            'Rheumatoid arthritis - Musculoskeletal involvement of the major joints with evidence of ongoing therapy',
    },
    {
        value:
            'Rheumatoid arthritis - Progressive systemic involvement of the heart or lungs or vasculitis despite optimal therapy',
        label:
            'Rheumatoid arthritis - Progressive systemic involvement of the heart or lungs or vasculitis despite optimal therapy',
    },
    {
        value:
            'Rheumatoid arthritis - Rheumatoid arthritis resulting in permanent inability to independently perform Activities of Daily Living',
        label:
            'Rheumatoid arthritis - Rheumatoid arthritis resulting in permanent inability to independently perform Activities of Daily Living',
    },
    {
        value: 'Speech disorders - Permanent aphasia',
        label: 'Speech disorders - Permanent aphasia',
    },
    {
        value: 'Speech disorders - Permanent dysarthria',
        label: 'Speech disorders - Permanent dysarthria',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Admittance to a recognised physical or mental rehabilitation institution',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Admittance to a recognised physical or mental rehabilitation institution',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Decompression laminectomy',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Decompression laminectomy',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Drainage via burrhole',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Drainage via burrhole',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Emergency tracheostomy or cricothyrotomy',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Emergency tracheostomy or cricothyrotomy',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Head injury',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Head injury',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - ICU admission with mechanical ventilation for at least 96 hours',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - ICU admission with mechanical ventilation for at least 96 hours',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Le Fort II and III facial injuries',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Le Fort II and III facial injuries',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Major surgical reconstruction',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Major surgical reconstruction',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Orbital fracture',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Orbital fracture',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Spinal fusion',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Spinal fusion',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Spinal injury resulting in paraplegia, diplegia, hemiplegia, quadriplegia and cauda equina syndrome',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Spinal injury resulting in paraplegia, diplegia, hemiplegia, quadriplegia and cauda equina syndrome',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Traumatic injuries meeting the policy document description and resulting in permanent neurological deficit despite maximum medical improvement resulting in permanent inability to independently perform Activities of Daily Living',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Traumatic injuries meeting the policy document description and resulting in permanent neurological deficit despite maximum medical improvement resulting in permanent inability to independently perform Activities of Daily Living',
    },
    {
        value:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Traumatic injuries meeting the policy document description and the comatose state is persistent for longer than 96 hours',
        label:
            'Traumatic injuries including brain injuries, head and neck injuries, spinal injuries and coma - Traumatic injuries meeting the policy document description and the comatose state is persistent for longer than 96 hours',
    },
    {
        value: 'Urogenital disorders - Partial amputation of the penis',
        label: 'Urogenital disorders - Partial amputation of the penis',
    },
    {
        value: 'Urogenital disorders - Partial cystectomy (removal of at least 50% of the bladder)',
        label: 'Urogenital disorders - Partial cystectomy (removal of at least 50% of the bladder)',
    },
    {
        value:
            'Urogenital disorders - Radical cystectomy resulting in a need for an external bag or catheterisation',
        label:
            'Urogenital disorders - Radical cystectomy resulting in a need for an external bag or catheterisation',
    },
    {
        value: 'Urogenital disorders - Total amputation of the penis',
        label: 'Urogenital disorders - Total amputation of the penis',
    },
    {
        value: 'Urogenital disorders - Unilateral or bilateral orchidectomy',
        label: 'Urogenital disorders - Unilateral or bilateral orchidectomy',
    },
    {
        value: 'Urogenital disorders - Vesico-vaginal fistula having undergone surgery',
        label: 'Urogenital disorders - Vesico-vaginal fistula having undergone surgery',
    },
    {
        value:
            'Valvular and septal diseases of the heart - Endocarditis (as indicated by appropriate tests)',
        label:
            'Valvular and septal diseases of the heart - Endocarditis (as indicated by appropriate tests)',
    },
    {
        value: 'Valvular and septal diseases of the heart - Heart transplant',
        label: 'Valvular and septal diseases of the heart - Heart transplant',
    },
    {
        value:
            'Valvular and septal diseases of the heart - Keyhole valvular surgery via the thoracic wall',
        label:
            'Valvular and septal diseases of the heart - Keyhole valvular surgery via the thoracic wall',
    },
    {
        value:
            'Valvular and septal diseases of the heart - Open heart surgery for valvular conditions',
        label:
            'Valvular and septal diseases of the heart - Open heart surgery for valvular conditions',
    },
    {
        value:
            'Valvular and septal diseases of the heart - Receiving optimal treatment (whether that is medical or surgical) for a valvular or septal heart disease',
        label:
            'Valvular and septal diseases of the heart - Receiving optimal treatment (whether that is medical or surgical) for a valvular or septal heart disease',
    },
    {
        value:
            'Valvular and septal diseases of the heart - Surgical valvular procedure done endoscopically (including catheter-based procedures)',
        label:
            'Valvular and septal diseases of the heart - Surgical valvular procedure done endoscopically (including catheter-based procedures)',
    },
    {
        value:
            'Valvular and septal diseases of the heart - Symptomatic atrial or ventricular septal defect having undergone surgical closure',
        label:
            'Valvular and septal diseases of the heart - Symptomatic atrial or ventricular septal defect having undergone surgical closure',
    },
    { value: 'WPI', label: 'WPI' },
    { value: 'Other', label: 'Other' },
];
