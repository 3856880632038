import React from 'react'; // eslint-disable-line
import { Grid, withStyles } from '@material-ui/core';
import styles, { StyledComponent } from '../../../styles/styles';
import { Payment } from '../../reducer';
import { LabeledValue } from '../../../controls/labeled-value';
import { getDisplayDate } from '../../../shared-functions';

interface Props extends StyledComponent {
    payments: Payment[];
    paymentIndex: number;
}

const overrideHighRiskDetailsView = ({ payments, paymentIndex, classes }: Props) => {
    const payment = payments[paymentIndex];
    return (
        <React.Fragment>
            <Grid item xs={12} container justify="flex-start">
                {payment.riskRating?.highRiskOverride?.reasonForOverride && (
                    <Grid item xs={12}>
                        <Grid item xs={6} lg={3}>
                            <LabeledValue
                                label="Override Comment"
                                value={payment.riskRating?.highRiskOverride?.reasonForOverride}
                            />
                        </Grid>
                    </Grid>
                )}

                {payment.riskRating?.highRiskOverride?.overridingAssessor && (
                    <Grid item xs={6} lg={6}>
                        <LabeledValue
                            label="Manager / Senior Assessor"
                            value={payment.riskRating?.highRiskOverride?.overridingAssessor}
                        />
                    </Grid>
                )}

                {payment.riskRating?.highRiskOverride?.dateOfOverride && (
                    <LabeledValue
                        label="Date"
                        value={getDisplayDate(payment.riskRating?.highRiskOverride?.dateOfOverride)}
                    />
                )}
            </Grid>
        </React.Fragment>
    );
};

export const OverrideHighRiskDetailsView = withStyles(styles)(overrideHighRiskDetailsView);
