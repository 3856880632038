import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { AddCircle, Delete, Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { OpenDialog, openDialogAction } from '../../../../../../confirmation-dialog';
import { OpenModal, openModalAction } from '../../../../../../form-modal/actions';
import { State } from '../../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import {
    DeleteClaimant,
    deleteClaimantThunk,
    UpdateFormDetails,
    updateFormDetailsAction,
} from '../../actions/update-form-details';
import { FormDetails, getFormDetails } from '../../form-details-reducer';
import { BankingDetailsView } from '../common/banking-details';
import { ContactDetailsView } from '../common/contact-details';
import { PersonalDetailsView } from '../common/personal-details';
import { editFormDetails } from '../edit-form-details';
import { GuardianDetailsView } from '../common/guardian-details';
import { DisplayAddress } from '../../../../../../forms/sub-forms/address-details';
import { ButtonWrapper } from '../../../../../../forms';

interface BaseProps {
    formDetails: FormDetails;
}

interface UpdateProps {
    openModal: OpenModal;
    updateFormDetails: UpdateFormDetails;
}

const claimantsView = ({ classes, formDetails }: BaseProps & StyledComponent) => {
    const claimants = formDetails.claimants || [];
    return (
        <Grid item xs={12} container justify="flex-start" className={classes.marginTop}>
            <Grid item xs={10}>
                <Typography variant="body1" className={classes.heading}>
                    Claimants Details
                </Typography>
            </Grid>
            <Grid item xs={2} className={classes.rightAlign}>
                <AddButton />
            </Grid>
            {claimants.map((claimant, index) => {
                const contacts = claimant.contactDetails || {};
                const address = claimant.addressDetails || {};
                const banking = claimant.bankingDetails || {};
                const guardianDetials = claimant.guardianDetails;
                const prefix = `claimants[${index}]`;
                const heading = `Claimant ${index + 1}`;
                return (
                    <React.Fragment key={`cv${index}`}>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2">Claimant {index + 1}</Typography>
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: 'right' }}>
                            <UpdateButtons claimantIndex={index} deletable={claimant.deletable} />
                        </Grid>
                        <PersonalDetailsView claimantDetails={claimant} />
                        <ContactDetailsView contactDetails={contacts} />
                        <DisplayAddress label="Address Details" address={address} />
                        <GuardianDetailsView gaurdianDetails={guardianDetials} />
                        <BankingDetailsView
                            bankingDetails={banking}
                            beneficiaryId={claimant.id}
                            heading={heading}
                            prefix={prefix}
                        />
                    </React.Fragment>
                );
            })}
        </Grid>
    );
};

const mapStateToProps = (state: State) => ({
    formDetails: getFormDetails(state),
    isReadOnly: state.claimSession.readOnly,
    hasDeferredContracts: state.claimSession.hasDeferredContracts,
});

const mapDispatchToAddProps = {
    openModal: openModalAction,
    updateFormDetails: updateFormDetailsAction,
};

interface AddProps extends BaseProps, UpdateProps {
    isReadOnly: boolean;
    hasDeferredContracts: boolean;
}

const AddButton = connect(
    mapStateToProps,
    mapDispatchToAddProps
)(({ formDetails, isReadOnly, hasDeferredContracts, ...rest }: AddProps) => {
    if (isReadOnly || hasDeferredContracts) {
        return null;
    }
    const addClaimant = () => {
        const claimantIndex = formDetails.claimants.length;
        const prefix = `claimants[${claimantIndex}]`;
        const heading = `Claimant ${claimantIndex + 1}`;
        editFormDetails({ beneficiary: true, formDetails, heading, prefix, ...rest });
    };
    return (
        <ButtonWrapper>
            <Tooltip title="Add Claimant">
                <Button id="AddClaimant" color="secondary" onClick={addClaimant}>
                    <AddCircle />
                </Button>
            </Tooltip>
        </ButtonWrapper>
    );
});

const mapDispatchToButtonsProps = {
    ...mapDispatchToAddProps,
    deleteClaimant: deleteClaimantThunk,
    openDialog: openDialogAction,
};

interface ButtonsProps extends BaseProps, AddProps {
    claimantIndex: number;
    deletable?: boolean;
    deleteClaimant: DeleteClaimant;
    openDialog: OpenDialog;
}
const UpdateButtons = connect(
    mapStateToProps,
    mapDispatchToButtonsProps
)(
    ({
        claimantIndex,
        deletable,
        deleteClaimant,
        isReadOnly,
        hasDeferredContracts,
        openDialog,
        ...rest
    }: ButtonsProps) => {
        if (isReadOnly || hasDeferredContracts) {
            return null;
        }
        const claimantNumber = claimantIndex + 1;
        const prefix = `claimants[${claimantIndex}]`;
        const heading = `Claimant ${claimantIndex + 1}`;
        const editClaimant = () => {
            editFormDetails({ beneficiary: true, heading, prefix, ...rest });
        };
        const confirmDelete = () => {
            openDialog({
                content: {
                    title: 'Delete Claimant',
                    body: `Are you sure you would like to delete Claimant ${claimantNumber}?`,
                },
                yesAction: {
                    action: async () => {
                        await deleteClaimant(claimantIndex);
                    },
                },
            });
        };

        return (
            <ButtonWrapper>
                <React.Fragment>
                    <Tooltip title={`Edit Claimant ${claimantNumber}`}>
                        <Button id="EditClaimant" color="secondary" onClick={editClaimant}>
                            <Edit />
                        </Button>
                    </Tooltip>
                    <Tooltip title={`Delete Claimant ${claimantNumber}`}>
                        <Button
                            id="DeleteClaimant"
                            color="secondary"
                            onClick={confirmDelete}
                            disabled={!deletable}
                        >
                            <Delete />
                        </Button>
                    </Tooltip>
                </React.Fragment>
            </ButtonWrapper>
        );
    }
);

export const ClaimantsView = connect(mapStateToProps, {})(withStyles(styles)(claimantsView));
