import { UpdateRecordCollectionAction } from '../../../../../redux/collection-reducer';
import { Dependant, getUpdatedDependant } from '../reducer';
import { UPDATE_DEPENDANT } from './types';

export type UpdateDependantAction = UpdateRecordCollectionAction<Dependant>;

export const updateDependantActionCreator = (
    index: number,
    dependant: Dependant
): UpdateDependantAction => {
    return {
        type: UPDATE_DEPENDANT,
        record: getUpdatedDependant(dependant),
        index: index,
    };
};
