import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import { OpenModal, openModalAction } from '../../../../../../form-modal/actions';
import { ButtonWrapper, ButtonCapture } from '../../../../../../forms';
import { State } from '../../../../../../redux/root-reducer';
import { getCodeDescription, GetVerificationWidget } from '../../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { BankingDetails } from '../../../../common/banking-details/banking-details';
import { UpdateFormDetails, updateFormDetailsAction } from '../../actions/update-form-details';
import { FormDetails, getFormDetails } from '../../form-details-reducer';
import { editFormDetails } from '../edit-form-details';
import { BankResponseView } from './bank-response';

interface BankingProps extends StyledComponent {
    bankingDetails: BankingDetails;
    beneficiaryId?: string;
    formDetails: FormDetails;
    heading: string;
    isReadOnly: boolean;
    hasDeferredContracts: boolean;
    openModal: OpenModal;
    prefix: string;
    updateFormDetails: UpdateFormDetails;
}

const bankingDetails: React.FunctionComponent<BankingProps> = ({
    bankingDetails,
    beneficiaryId,
    classes,
    isReadOnly,
    hasDeferredContracts,
    ...rest
}: BankingProps) => {
    return (
        <React.Fragment>
            <Grid item xs={12} container>
                <Grid item xs={10}>
                    <Typography variant="body1" className={classes.heading}>
                        Payee Details
                    </Typography>
                </Grid>
                {!hasDeferredContracts && (
                    <ButtonWrapper>
                        <Grid item xs={2} className={classes.rightAlign}>
                            <Tooltip title="Edit Payee Details">
                                <Button
                                    id="EditPayeeDetails"
                                    color="secondary"
                                    onClick={() => editFormDetails({ index: 3, ...rest })}
                                >
                                    <Edit />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </ButtonWrapper>
                )}
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Account holder's initials"
                    value={bankingDetails.accountHoldersInitials}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Account holder's surname/company name"
                    value={bankingDetails.accountHoldersName}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label={`Account holder's ${getIdType(
                        bankingDetails.accountHoldersIdType
                    )} number`}
                    value={bankingDetails.accountHoldersIdNumber}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Bank name"
                    value={getCodeDescription('bankName', bankingDetails.bankName)}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Branch name" value={bankingDetails.bankBranch} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Branch code" value={bankingDetails.branchCode} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Account number" value={bankingDetails.accountNumber} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Account type" value={bankingDetails.accountType} />
            </Grid>
            {bankingDetails.accountHolderRelationship && (
                <Grid item xs={6} lg={3}>
                    <LabeledValue
                        label="Account holder relationship"
                        value={bankingDetails.accountHolderRelationship}
                    />
                </Grid>
            )}
            {bankingDetails.accountHolderRelationshipDescription && (
                <Grid item xs={6} lg={3}>
                    <LabeledValue
                        label="Relationship description"
                        value={bankingDetails.accountHolderRelationshipDescription}
                    />
                </Grid>
            )}
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Account status"
                    value={GetVerificationWidget(bankingDetails.bankAccountStatus)}
                />
            </Grid>
            <ButtonCapture>
                <BankResponseView
                    bankResponse={bankingDetails.bankResponse}
                    beneficiaryId={beneficiaryId}
                />
            </ButtonCapture>
        </React.Fragment>
    );
};
function getIdType(idType?: string) {
    if (typeof idType === 'undefined' || idType === null || idType === '') {
        return '';
    } else {
        return idType === 'ID' ? idType : idType?.toLowerCase();
    }
}

const mapStateToProps = (state: State) => ({
    formDetails: getFormDetails(state),
    isReadOnly: state.claimSession.readOnly,
    hasDeferredContracts: state.claimSession.hasDeferredContracts,
});

const mapDispatchToProps = {
    openModal: openModalAction,
    updateFormDetails: updateFormDetailsAction,
};

export const BankingDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(bankingDetails));
