import React from 'react'; // eslint-disable-line

import { Divider, Grid, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import {
    Condition,
    FullWidthDatePicker,
    FullWidthSearchSelectField,
    FullWidthReadonlyField,
    FullWidthRichTextField,
    FullWidthTextField,
    True,
    BindFieldToField,
    WhenFieldChanges,
} from '../../../../forms';
import {
    requiredValidator,
    mustBeNumber,
    composeValidators,
    UserInput,
    ValidationResult,
} from '../../../../forms/validations';
import { State } from '../../../../redux/root-reducer';
import { formatPercent } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import {
    getDependants,
    Dependant,
    getPossibleParents,
    getLinkedBeneficiaries,
    getDependantsRelationship,
} from '../../../synopsis/ra-death/dependants/reducer';
import { DecisionModel, RecommendationModel } from '../ra-death-assessment-details-reducer';
import { Code } from '../../../../codes/codes';

interface DecisionProps extends StyledComponent {
    dependants: Dependant[];
    possibleParents: Code<string>[];
}
const RaDeathDecisionEditView: React.FunctionComponent<DecisionProps> = ({
    classes,
    dependants,
    possibleParents,
}: DecisionProps) => {
    return (
        <Grid container justify="flex-start" spacing={5}>
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    name="assessmentType"
                    label="Select an action"
                    options={AssessmentTypes()}
                    validate={requiredValidator}
                    sortOptions={false}
                />
                <WhenFieldChanges field="assessmentType" set="trusteeSummary" to={null} />
                <WhenFieldChanges field="assessmentType" set="trusteeMeetingDate" to={null} />
            </Grid>
            <Grid item container justify="flex-start" spacing={5} className={classes.marginTop}>
                <Grid item xs={3}>
                    <Typography>Dependant</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography>Age</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Grid item xs={12}>
                        <Typography>Relationship</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>to life assured</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Typography>Allocation</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography>Allocation reason</Typography>
                </Grid>
            </Grid>
            {dependants.map((dependant, index) => {
                let childOf = ' ';
                if (dependant.relationship === 'MinorChild') {
                    const linkedParents = getLinkedBeneficiaries(possibleParents, dependant.parent);
                    if (Array.isArray(linkedParents)) {
                        childOf = `${getDependantsRelationship(
                            dependant.relationshipDescription
                        )} of ${linkedParents[0]}`;
                    }
                }
                const backgroundColor = index % 2 === 0 ? { backgroundColor: '#F0F1F4' } : {};
                return (
                    <Grid
                        style={{ paddingLeft: 40, paddingRight: 40 }}
                        key={`recommendation_${index}`}
                    >
                        <Grid
                            item
                            container
                            justify="flex-start"
                            spacing={5}
                            style={backgroundColor}
                        >
                            <Grid item xs={12} style={{ marginLeft: -20 }}>
                                <Divider style={{ backgroundColor: '#8B92A7', marginRight: -40 }} />
                            </Grid>
                            <Grid item xs={3} style={{ marginLeft: -10 }}>
                                <FullWidthTextField
                                    name={`recommendations[${index}].name`}
                                    disabled
                                    helperText={childOf}
                                    hideLabel
                                />
                            </Grid>
                            <Grid item xs={1} style={{ paddingRight: 15 }}>
                                <FullWidthTextField
                                    name={`recommendations[${index}].age`}
                                    disabled
                                    hideLabel
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FullWidthTextField
                                    name={`recommendations[${index}].relationship`}
                                    disabled
                                    hideLabel
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FullWidthTextField
                                    name={`recommendations[${index}].allocation`}
                                    validate={composeValidators(
                                        requiredValidator,
                                        mustBeNumber,
                                        allocationValidator((values: any) => values)
                                    )}
                                    hideLabel
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FullWidthSearchSelectField
                                    name={`recommendations[${index}].reason`}
                                    options={Reasons()}
                                    hideLabel
                                />
                            </Grid>
                            <Condition when={`recommendations[${index}].reason`} is="Other">
                                <True>
                                    <Grid item xs={12} container spacing={5} justify="flex-end">
                                        <Grid
                                            item
                                            xs={12}
                                            style={{
                                                paddingRight: 0,
                                                marginRight: -10,
                                                paddingLeft: 25,
                                            }}
                                        >
                                            <FullWidthTextField
                                                name={`recommendations[${index}].reasonDescription`}
                                                validate={requiredValidator}
                                                multiline
                                            />
                                        </Grid>
                                    </Grid>
                                </True>
                            </Condition>
                        </Grid>
                    </Grid>
                );
            })}
            <Grid item xs={12}>
                <Divider style={{ backgroundColor: '#8B92A7' }} />
            </Grid>
            <Grid
                item
                xs={12}
                container
                justify="flex-start"
                spacing={5}
                style={{ paddingLeft: 40 }}
            >
                <Grid item xs={6} />
                <Grid item xs={2}>
                    <FullWidthReadonlyField
                        name="totalAllocation"
                        format={formatPercent}
                        hideLabel
                    />
                    <BindFieldToField
                        field="totalAllocation"
                        toField="recommendations"
                        transform={values => {
                            let total = 0;
                            if (Array.isArray(values)) {
                                values.forEach(value => {
                                    if (value.allocation) {
                                        total = total + Number(value.allocation);
                                    }
                                });
                            }
                            return total / 100;
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container justify="center" spacing={5}>
                <Grid item xs={10}>
                    <FullWidthRichTextField
                        showOcr={false}
                        name="briefSummary"
                        label="Brief summary of the claim"
                        validate={requiredValidator}
                    />
                </Grid>
                <Grid item xs={10}>
                    <FullWidthRichTextField name="assessmentSummary" validate={requiredValidator} />
                </Grid>
                <Grid item xs={5}>
                    <FullWidthDatePicker name="assessmentDate" disabled />
                </Grid>
                <Grid item xs={5}>
                    <FullWidthTextField name="assessor" label="assessed by" disabled />
                </Grid>
                <Condition when="assessmentType" is="Final Decision">
                    <True>
                        <React.Fragment>
                            <Grid item xs={10}>
                                <FullWidthRichTextField
                                    name="trusteeSummary"
                                    label="Trustee decision summary"
                                    validate={requiredValidator}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <FullWidthDatePicker
                                    name="trusteeMeetingDate"
                                    validate={requiredValidator}
                                    disableFuture
                                />
                            </Grid>
                            <Grid item xs={5} />
                        </React.Fragment>
                    </True>
                </Condition>
            </Grid>
        </Grid>
    );
};

function AssessmentTypes() {
    return ['Assessment', 'Submit to trustees', 'Interim Decision', 'Final Decision'].map(
        assessmentType => {
            return {
                value: assessmentType,
                label: assessmentType,
            };
        }
    );
}

function Reasons() {
    return ['Not financially dependent', 'Insufficient funds', 'Waived their rights', 'Other'].map(
        reason => {
            return {
                value: reason,
                label: reason,
            };
        }
    );
}

const allocationValidator = (values: any) => {
    return function validate(value: UserInput, values: DecisionModel): ValidationResult {
        if (value && values.recommendations) {
            let totalAllocation = 0;
            if (Array.isArray(values.recommendations)) {
                values.recommendations.forEach((recommendation: RecommendationModel) => {
                    if (recommendation.allocation) {
                        totalAllocation = totalAllocation + Number(recommendation.allocation);
                    }
                });
                if (totalAllocation !== 100) {
                    return 'Total allocation should equal 100%';
                }
            }
        }
        return undefined;
    };
};

const mapStateToProps = (state: State) => ({
    dependants: getDependants(state),
    possibleParents: getPossibleParents(state),
});

const mapDispatchToProps = {};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(RaDeathDecisionEditView));
