import { Button, Grid, Tooltip, withStyles } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { FormRenderProps } from 'react-final-form';
import { connect } from 'react-redux';
import { AuthState } from '../../../../../auth/auth-reducer';
import { getClaim } from '../../../../../claim';
import { AddFormModal } from '../../../../../form-modal';
import { OpenAddModal, openAddModalAction } from '../../../../../form-modal/actions';
import { ButtonWrapper } from '../../../../../forms';
import { ExpansionPanel } from '../../../../../forms/form-components/expansion-panel/expansion-panel';
import { State } from '../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../styles/styles';
import {
    AddAndSubmitAdditionalInformationRequired,
    addAndSubmitAdditionalInformationRequiredThunk,
} from '../actions';
import { AdditionalInformationRequiredRecord, getAdditionalInformationRequired } from '../reducer';
import { AdditionalInformationRequestedRecordView } from './additional-information-requested-record';
import { AdditionalInformationRequiredRecordDetailView } from './additional-information-required-record-detail';

interface OwnProps {}

interface StateProps {
    currentUser: AuthState;
    records: AdditionalInformationRequiredRecord[];
    versionNumber: string;
    isReadOnly: boolean;
}

interface DispatchProps {
    addRecord: AddAndSubmitAdditionalInformationRequired;
    openAddModal: OpenAddModal;
}

type Props = DispatchProps & OwnProps & StateProps & StyledComponent;

const additionalInformationRequestedView: React.FunctionComponent<Props> = ({
    addRecord,
    classes,
    currentUser,
    openAddModal,
    records,
    versionNumber,
    isReadOnly,
}: Props) => {
    if (versionNumber > '1.1.0.0') {
        return null;
    }
    return (
        <ExpansionPanel name="Additional Information Requested">
            <ButtonWrapper>
                <Grid container spacing={5} justify="flex-start">
                    <Grid item xs={12} lg={12} className={classes.rightAlign}>
                        {addAdditionalInformationRequiredButton(
                            addRecord,
                            currentUser,
                            openAddModal
                        )}
                    </Grid>
                </Grid>
            </ButtonWrapper>
            {records.map((record, index) => {
                return (
                    <AdditionalInformationRequestedRecordView
                        index={index}
                        classes={classes}
                        key={`Additional-Info-${index}`}
                        record={record}
                    />
                );
            })}
        </ExpansionPanel>
    );
};

const AdditionalInformationRequiredAddFormModal = AddFormModal<AdditionalInformationRequiredRecord>();
const addAdditionalInformationRequiredButton = (
    addRecord: AddAndSubmitAdditionalInformationRequired,
    currentUser: AuthState,
    openAddModal: OpenAddModal
) => {
    let assessor = 'Unknown';
    if (currentUser) {
        assessor = `${currentUser.name} (${currentUser.userId})`;
    }
    const blankAdditionalInformationRequiredRecord: AdditionalInformationRequiredRecord = {
        detailsOfInformationRequested: '',
        reasonForRequest: '',
        dateRequested: undefined,
        dateReceived: undefined,
        assessedBy: assessor,
        hasWaiver: false,
        waiver: null,
        sendToUnderwriter: false,
    };

    const openAddView = () => {
        openAddModal({
            modalContent: (
                <AdditionalInformationRequiredAddFormModal
                    blankRecord={blankAdditionalInformationRequiredRecord}
                    recordDetailView={detailView}
                    addRecord={infomationReceived => addRecord(infomationReceived)}
                />
            ),
            modalData: {},
        });
    };

    return (
        <ButtonWrapper>
            <Tooltip title="Add Additional Information Requested">
                <Button id="AddAdditionalInformation" color="secondary" onClick={openAddView}>
                    <AddCircle />
                </Button>
            </Tooltip>
        </ButtonWrapper>
    );
};

const detailView: React.FunctionComponent<FormRenderProps> = (renderProps: FormRenderProps) => {
    return (
        <AdditionalInformationRequiredRecordDetailView
            heading="Add Additional Information Requested"
            {...renderProps}
        />
    );
};

const mapStateToProps = (state: State) => {
    const claim = getClaim(state);
    const versionNumber = claim.version ? claim.version.createdInVersion || '1.1.0.0' : '1.1.0.0';
    return {
        records: getAdditionalInformationRequired(state).records,
        currentUser: state.auth as AuthState,
        versionNumber: versionNumber,
        isReadOnly: state.claimSession.readOnly,
    };
};

const mapDispatchToProps = {
    addRecord: addAndSubmitAdditionalInformationRequiredThunk,
    openAddModal: openAddModalAction,
};

export const AdditionalInformationRequestedView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(additionalInformationRequestedView));
