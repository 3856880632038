import React from 'react'; // eslint-disable-line
import { ButtonWrapper } from '../../../../../forms';
import { Button, Grid, Tooltip, withStyles } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { connect, MapDispatchToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { v1 as uuid } from 'uuid';
import { AddFormModal } from '../../../../../form-modal';
import {
    openAddModalAction,
    OpenAddModal,
    CloseModal,
    closeModalAction,
} from '../../../../../form-modal/actions';
import { State } from '../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { addAndSubmitDependantThunk, AddAndSubmitDependant } from '../actions/submit-details';
import { Dependant } from '../reducer';
import { DependantEditView } from './edit-dependant-details';
import {
    Decision,
    DecisionModel,
    getBlankAssessmentRecord,
    getRaDeathAssessmentDecisions,
} from '../../../../decisions/ra-death/ra-death-assessment-details-reducer';
import { getRADeathClaim } from '../../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import {
    AddAndSubmitAssessmentDecisionRecord,
    addAndSubmitAssessmentDecisionRecordThunk,
} from '../../../../decisions/ra-death/actions/add-assessment-decision';
import { openBeneficiarySplitModal } from './dependants-beneficiary-split-modal';

interface OwnProps {}

interface DispatchProps {
    addClaimant: AddAndSubmitDependant;
    openAddModal: OpenAddModal;
    closeModal: CloseModal;
    addAssessmentDecision: AddAndSubmitAssessmentDecisionRecord;
}

interface StateProps {
    isReadOnly: boolean;
    readonly decisions: Decision[];
    readonly dependants: Dependant[];
}
type Props = DispatchProps & StyledComponent & StateProps;
/* eslint-disable-next-line */
const ContactDetailsAddFormModal = AddFormModal<Dependant>();

const addClaimantDetails: React.FunctionComponent<Props> = ({
    addClaimant,
    classes,
    openAddModal,
    closeModal,
    isReadOnly,
    addAssessmentDecision,
    decisions,
    dependants,
}: Props) => {
    if (isReadOnly) {
        return null;
    }
    return (
        <Grid container justify="flex-start">
            <ButtonWrapper>
                <Grid item xs={12} className={classes.rightAlign}>
                    <Button id="AddClaimantDetails" onClick={() => openAddView(null)}>
                        <Tooltip title="Add Claimant Details">
                            <AddCircle color="secondary" />
                        </Tooltip>
                    </Button>
                </Grid>
            </ButtonWrapper>
        </Grid>
    );

    function openAddView(decision: DecisionModel | null) {
        const dependant = getBlankDependant();
        const blankDecision = getBlankAssessmentRecord(decisions, dependants, 'currentUser');
        openAddModal({
            modalContent: (
                <ContactDetailsAddFormModal
                    blankRecord={dependant}
                    recordDetailView={<DependantEditView breakdown={[]} />}
                    addRecord={value => addDependantDecisionRec(value, blankDecision)}
                    saveAndAddAnother={(values: Dependant) =>
                        saveAndAddAnother(values, blankDecision)
                    }
                    isUseAltFunc={true}
                    isSkipClose={true}
                />
            ),
            modalData: {},
        });
    }

    async function saveAndAddAnother(dependant: Dependant, decisionModel: DecisionModel) {
        addClaimant(dependant);
        dependants.push(dependant);
        decisionModel.percentageSplits.push({
            dependantId: dependant.id,
            reason: 'none',
            reasonDescription: dependant.relationshipDescription,
            allocation: 0,
            age: '',
            name: '',
            relationship: '',
        });
        await addAssessmentDecision(decisionModel);
    }

    async function addDependantDecisionRec(dependant: Dependant, decisionModel: DecisionModel) {
        closeModal();
        addClaimant(dependant);
        dependants.push(dependant);
        decisionModel.percentageSplits.push({
            dependantId: dependant.id,
            reason: 'none',
            reasonDescription: dependant.relationshipDescription,
            allocation: 0,
            age: '',
            name: '',
            relationship: '',
        });
        await addAssessmentDecision(decisionModel);
        openBeneficiarySplitModal(
            null,
            decisions,
            dependants,
            closeModal,
            openAddModal,
            UpdatePercentageSplit
        );
    }

    async function UpdatePercentageSplit(decisionModel: DecisionModel) {
        await addAssessmentDecision(decisionModel);
    }
};

const getBlankDependant = () => {
    const dependant: Dependant = {
        id: uuid(),
        bankingDetails: {
            bankAccountStatus: 'Not verified',
        },
        isDependantSelected: false,
        percentageSplit: '',
    };
    return dependant;
};

const mapStateToProps = (state: State) => ({
    isReadOnly: state.claimSession.readOnly,
    decisions: getRaDeathAssessmentDecisions(state),
    dependants: getRADeathClaim(state).dependants || [],
    decision: getBlankAssessmentRecord(
        getRADeathClaim(state).assessmentDetails.decisions,
        getRADeathClaim(state).dependants,
        state.auth?.name
    ),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    addClaimant: record => dispatch(addAndSubmitDependantThunk(record)),
    openAddModal: options => dispatch(openAddModalAction(options)),
    closeModal: () => dispatch(closeModalAction()),
    addAssessmentDecision: (record: any) =>
        dispatch(addAndSubmitAssessmentDecisionRecordThunk(record)),
});

export const AddDependantView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(addClaimantDetails));
