import { Grid, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { LabeledValue } from '../../../../../../controls/labeled-value';
import styles from '../../../../../../styles/styles';
import { ContactDetails } from '../../form-details-reducer';

interface Props {
    contactDetails?: ContactDetails;
    employed?: string;
}

const contactDetailsView = ({ contactDetails, employed }: Props) => {
    if (!contactDetails) {
        return null;
    }

    return (
        <React.Fragment>
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Home Telephone Number"
                    value={contactDetails.homeTelephoneNumber}
                />
            </Grid>
            {employed === 'Yes' && (
                <Grid item xs={6} lg={3}>
                    <LabeledValue label="Work Number" value={contactDetails.workTelephoneNumber} />
                </Grid>
            )}
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Cellphone Number" value={contactDetails.cellphoneNumber} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="E-mail Address" value={contactDetails.emailAddress} />
            </Grid>
        </React.Fragment>
    );
};

export const ContactDetailsView = withStyles(styles)(contactDetailsView);
