import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { sentenceCase } from 'change-case';
import { connect } from 'react-redux';
import {
    DeathRequirements,
    getDocumentsRequirements,
} from '../../assessment/synopsis/death/manage-requirements/reducer';
import { State } from '../../redux/root-reducer';
import { Nullable } from '../../shared-types';
import styles from '../../styles/styles';
import {
    ChangeFieldSet,
    changeRecordFromChangeSet,
    changeRecordFromAddChangeSet,
    changeRecordFromDeleteChangeSet,
} from '../claim-audit-trail-functions';
import { addAuditTrailDetailsView } from '../common-views/claim-audit-trail-field-details';
import { mapSectionView } from '../common-views/claim-audit-trail-wrapper';
import { RequiredDocument } from '../../assessment/synopsis/common/requirements-management/reducer';

interface Props {
    documentsStatus: Nullable<DeathRequirements>;
    changeSet: Record<string, any>;
}
const documentsKey = 'documents';
const executorsKey = 'executors';
const claimantsKey = 'claimants';
const deceasedKey = 'deceased';
let deceasedDocuments: RequiredDocument[] = [];

const auditDocumentsStatus: React.FunctionComponent<Props> = ({
    documentsStatus,
    changeSet,
}: Props) => {
    const returnView: React.ReactNode[] = [];
    deceasedDocuments = documentsStatus && documentsStatus.deceased ? documentsStatus.deceased : [];
    const executorDocuments =
        documentsStatus && documentsStatus.executors ? documentsStatus.executors : [];
    const claimant =
        documentsStatus && documentsStatus.claimants && documentsStatus.claimants
            ? documentsStatus.claimants
            : [];
    Object.keys(changeSet).forEach(key => {
        const changeRecord = changeSet[key];
        const lowerCaseKey = key.toLowerCase();
        if (lowerCaseKey === deceasedKey) {
            getDocumentStatusRecord(changeRecord, returnView, 'deceased');
        }
        Object.keys(changeRecord).forEach(changeKey => {
            if (lowerCaseKey === executorsKey || lowerCaseKey === claimantsKey) {
                const contactType = lowerCaseKey === executorsKey ? 'executor' : 'claimant';
                if (changeKey !== '_t') {
                    if (changeRecord[changeKey][0]) {
                        if (changeKey.startsWith('_')) {
                            deleteDocumentRecord(changeKey, changeRecord, returnView, contactType);
                        } else {
                            getDocumentStatusRecord(changeRecord, returnView, contactType);
                        }
                    }
                    if (
                        changeRecord[changeKey] &&
                        changeRecord[changeKey].hasOwnProperty(documentsKey)
                    ) {
                        const contactName =
                            lowerCaseKey === executorsKey
                                ? executorDocuments[changeKey]
                                    ? `${executorDocuments[changeKey].name} ${executorDocuments[changeKey].name}`
                                    : undefined
                                : claimant[changeKey]
                                ? `${claimant[changeKey].name} ${claimant[changeKey].lastName}`
                                : undefined;
                        const contactDocuments =
                            lowerCaseKey === executorsKey
                                ? executorDocuments[changeKey] &&
                                  executorDocuments[changeKey][documentsKey] &&
                                  executorDocuments[changeKey][documentsKey]
                                : claimant[changeKey] &&
                                  claimant[changeKey][documentsKey] &&
                                  claimant[changeKey][documentsKey];
                        getDocumentsRecord(
                            changeRecord[changeKey][documentsKey],
                            returnView,
                            contactType,
                            contactDocuments,
                            contactName
                        );
                    }
                }
            }
        });
    });
    return mapSectionView(returnView);
};

export function getDocumentsRecord(
    changeRecord: Record<string, any>,
    returnView: React.ReactNode[],
    contactType: string,
    documentList: any[],
    contactName?: string
) {
    const displayType = contactName
        ? `${sentenceCase(contactType)} - ${contactName}`
        : sentenceCase(contactType);
    let sectionName: any = [];
    if (changeRecord) {
        Object.keys(changeRecord).forEach(recordIndex => {
            if (!isNaN(Number(recordIndex))) {
                let documentDetails: ChangeFieldSet[] = [];
                const documentName =
                    documentList && documentList[recordIndex] && documentList[recordIndex].name;
                if (Array.isArray(changeRecord[recordIndex])) {
                    const addedDocument = changeRecord[recordIndex][0];
                    delete addedDocument['name'];
                    documentDetails = changeRecordFromAddChangeSet(addedDocument);
                    sectionName = [`${displayType}`, documentName];
                } else {
                    documentDetails = changeRecordFromChangeSet(changeRecord[recordIndex]);
                    if (Number(recordIndex) === 0) {
                        sectionName = [displayType, documentName];
                    } else {
                        sectionName = [documentName];
                    }
                }
                addAuditTrailDetailsView(returnView, documentDetails, sectionName);
            }
        });
    }
}

export const getDocumentStatusRecord = (
    changeRecord: any,
    returnView: React.ReactNode[],
    documentType: string
) => {
    let sectionName: string[] = [];
    if (documentType === deceasedKey) {
        let headingSet = false;
        Object.keys(changeRecord).forEach(docIndex => {
            if (docIndex !== '_t') {
                if (Array.isArray(changeRecord[docIndex])) {
                    Object.keys(changeRecord[docIndex]).forEach(recordIndex => {
                        if (!isNaN(Number(recordIndex))) {
                            const changes = changeRecordFromAddChangeSet(
                                changeRecord[docIndex][recordIndex]
                            );
                            if (recordIndex === '0') {
                                sectionName = [
                                    `${sentenceCase(documentType)}`,
                                    changeRecord[docIndex][recordIndex].name,
                                ];
                            } else {
                                sectionName = [changeRecord[docIndex][recordIndex].name];
                            }
                            addAuditTrailDetailsView(returnView, changes, sectionName);
                        }
                    });
                } else {
                    const changeDocumentName =
                        deceasedDocuments[docIndex] && deceasedDocuments[docIndex].name;
                    const changes = changeRecordFromChangeSet(changeRecord[docIndex]);
                    if (headingSet) {
                        sectionName = [changeDocumentName];
                    } else {
                        sectionName = [`${sentenceCase(documentType)}`, changeDocumentName];
                        headingSet = true;
                    }
                    addAuditTrailDetailsView(returnView, changes, sectionName);
                }
            }
        });
    } else {
        let documents = changeRecord[documentsKey];
        let claimantsName = `${changeRecord['name']} ${changeRecord['lastName']}`;

        if (changeRecord.hasOwnProperty('_t')) {
            Object.keys(changeRecord).forEach(index => {
                if (!isNaN(Number(index)) && changeRecord[index][0]) {
                    documents = changeRecord[index][0][documentsKey];
                    claimantsName = `${changeRecord[index][0]['name']} ${changeRecord[index][0]['lastName']}`;
                }
            });
        }

        Object.keys(documents).forEach(docIndex => {
            if (docIndex !== '_t' && typeof docIndex !== 'undefined') {
                const changes = documents[docIndex];
                const documentName = changes && changes.name;
                delete changes['name'];
                const documentDetails = changeRecordFromAddChangeSet(changes);
                if (docIndex === '0') {
                    sectionName = [
                        `${sentenceCase(documentType)} - ${claimantsName}`,
                        documentName,
                    ];
                } else {
                    sectionName = [documentName];
                }

                addAuditTrailDetailsView(returnView, documentDetails, sectionName);
            }
        });
    }
};

export const deleteDocumentRecord = (
    changeKey: string,
    changeRecord: Record<string, any>,
    returnView: React.ReactNode[],
    sectionName: string
) => {
    const documentDetails = changeRecord[changeKey][0][documentsKey];
    const section = `${sentenceCase(sectionName)} - ${changeRecord[changeKey][0].name} ${
        changeRecord[changeKey][0].lastName
    }`;
    let sectionHeadings: string[] = [];
    if (Array.isArray(documentDetails) && documentDetails.length > 0) {
        for (const docIndex in documentDetails) {
            const documentName = documentDetails[docIndex].name;
            delete documentDetails[docIndex]['name'];
            if (docIndex === '0') {
                sectionHeadings = [section, documentName];
            } else {
                sectionHeadings = [documentName];
            }
            const record = changeRecordFromDeleteChangeSet(documentDetails[docIndex]);
            if (record.length > 0) {
                addAuditTrailDetailsView(returnView, record, sectionHeadings);
            }
        }
    }
};

const mapStateToProps = (state: State) => ({
    documentsStatus: getDocumentsRequirements(state),
});

const mapDispatchToProps = {};

export const AuditDocumentsStatus = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(auditDocumentsStatus));
