import React, { useEffect, useState } from 'react'; // eslint-disable-line
import { Grid, Typography, MenuItem, MenuList, Button, Box } from '@material-ui/core';
import { FormIconTextField } from '../../../forms/form-components/icon-text-field/form-icon-text-field';
import SearchIcon from '@material-ui/icons/Search';
import { StyledComponent } from '../../../styles/styles';
import { connect } from 'react-redux';
import { State } from '../../../redux/root-reducer';
import { ADUser } from '../../reducer/assessors-reducer';
import { CheckCircle } from '@material-ui/icons';
import { adUserRequest, SaveADUser, saveADUserAction } from '../../actions/assessors-save-action';
import { GenericForm } from '../../../forms';
import { CloseModal, closeModalAction } from '../../../form-modal/actions';

interface OwnProps {
    classes: any;
    adUsers: ADUser;
    selectedUser?: adUserRequest;
}
interface DispatchProps {
    saveADUser: SaveADUser;
    closeModal: CloseModal;
}

type Props = OwnProps & DispatchProps & StyledComponent;
const AssessorDashboardADSearchForm = GenericForm<ADUser>();
const AssessorDashboardADSearch: React.FunctionComponent<Props> = ({
    classes,
    adUsers,
    saveADUser,
    closeModal,
}: Props) => {
    const [adUsersLists, setAddUserLists] = useState<ADUser[] | []>([]);
    const [isSearch, setIsSearch] = useState(false);
    const [isToggleTick, setToggleTick] = useState(false);
    const [selectedUser, setSelectedUser] = useState<any>([]);
    const [isFocused, setIsfocused] = useState(false);

    useEffect(() => {
        if (isSearch && !isFocused) {
            setIsfocused(true);
        }
    }, [isFocused, isSearch]);

    const onChange = (value: string) => {
        const UpdatedADUsers: any = [];

        if (value === '') {
            setAddUserLists([]);
            setIsSearch(false);
            setToggleTick(false);
            return;
        }
        setToggleTick(false);

        adUsers.map((user: ADUser) => {
            let fullName;
            user.displayName.includes(',')
                ? (fullName = user.displayName.split(','))
                : (fullName = user.displayName.split(' '));

            const firstName = fullName[0];
            const lastName = fullName[fullName.length - 1].replace(/\s/g, '');

            if (
                firstName.toLowerCase().startsWith(value.toLowerCase()) ||
                lastName.toLowerCase().startsWith(value.toLowerCase())
            ) {
                UpdatedADUsers.push(user);
            }
            return UpdatedADUsers;
        });

        setIsSearch(true);
        setAddUserLists(UpdatedADUsers);
    };

    const onSubmit = () => {
        saveADUser(selectedUser);
        closeModal();
    };

    const onCancel = () => {
        closeModal();
    };

    const onDiscard = () => {
        onChange('');
        setToggleTick(false);
    };

    return (
        <AssessorDashboardADSearchForm onSubmit={onSubmit}>
            <Grid
                container
                direction="column"
                alignItems="center"
                style={{ height: 350, padding: 10, margin: 10 }}
            >
                <Grid container item alignItems="center" direction="column">
                    <Typography
                        className={classes.assessorText}
                        style={{
                            fontWeight: 600,
                            fontSize: 24,
                            marginBottom: 20,
                            marginTop: 10,
                        }}
                    >
                        {' '}
                        Add Assessor
                    </Typography>
                    <Grid
                        item
                        style={{
                            width: '60%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <FormIconTextField
                            name="search-ad-user"
                            classes={classes}
                            className={classes.adSearchBox}
                            label="Search assessors"
                            textStyle={{
                                height: 80,
                            }}
                            isFocused={isFocused}
                            disabled={isToggleTick}
                            onChange={e => onChange(e.target.value)}
                            iconStart={<SearchIcon fontSize="small" color="disabled" />}
                        />
                    </Grid>

                    {isSearch && !isToggleTick && (
                        <Box
                            className={classes.boxPaper}
                            style={{
                                marginTop: '-2rem',
                                zIndex: 1,
                                maxHeight: 200,
                                overflow: 'auto',
                                width: '60%',
                            }}
                        >
                            <MenuList id="composition-menu" aria-labelledby="composition-button">
                                {adUsersLists.length !== 0 ? (
                                    adUsersLists.map((adUser: ADUser, idx: number) => {
                                        let fullName;
                                        adUser.displayName.includes(',')
                                            ? (fullName = adUser.displayName.split(','))
                                            : (fullName = adUser.displayName.split(' '));

                                        const lastName = fullName[0];
                                        const firstName = fullName[fullName.length - 1];

                                        return (
                                            <MenuItem
                                                key={idx}
                                                onClick={() =>
                                                    showTickOnSelected(isToggleTick, {
                                                        firstName: firstName,
                                                        lastName: lastName,
                                                        mail: adUser.mail,
                                                    })
                                                }
                                            >
                                                <Grid container direction="column">
                                                    <Typography style={{ fontWeight: 'bold' }}>
                                                        {firstName + ' ' + lastName}
                                                    </Typography>
                                                    <Typography>{adUser.mail}</Typography>
                                                </Grid>
                                            </MenuItem>
                                        );
                                    })
                                ) : (
                                    <>
                                        {isSearch && (
                                            <Typography style={{ padding: 10 }}>
                                                {' '}
                                                No results found
                                            </Typography>
                                        )}
                                    </>
                                )}
                            </MenuList>
                        </Box>
                    )}
                    {isToggleTick && (
                        <Grid
                            container
                            alignContent="center"
                            justify="center"
                            direction="row"
                            style={{
                                width: '60%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Grid container direction="column">
                                <Typography style={{ fontWeight: 'bold' }}>
                                    {selectedUser.firstName + ' ' + selectedUser.lastName}
                                </Typography>

                                <Typography>{selectedUser.mail}</Typography>
                            </Grid>
                            <Grid container alignContent="flex-end" justify="flex-end">
                                {TickStatusIcon()}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid container spacing={0} justify="flex-end" style={{ marginTop: '3.5rem' }}>
                    {isToggleTick && (
                        <>
                            <Button
                                id="Save"
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                onClick={onSubmit}
                            >
                                Save
                            </Button>
                            <Button
                                id="Discard"
                                variant="contained"
                                className={classes.button}
                                onClick={onDiscard}
                            >
                                Discard
                            </Button>
                        </>
                    )}
                    <Button
                        id="Cancel"
                        variant="contained"
                        className={classes.button}
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </AssessorDashboardADSearchForm>
    );

    function showTickOnSelected(checked: boolean, adUser: any) {
        if (!checked) {
            setToggleTick(true);
            setSelectedUser(adUser);
        } else {
            setToggleTick(false);
        }
    }

    function TickStatusIcon() {
        return <CheckCircle style={{ color: '#36b37e', marginLeft: '50%', marginTop: '-8%' }} />;
    }
};

const mapDispatchToProps = {
    saveADUser: saveADUserAction,
    closeModal: closeModalAction,
};

const mapStateToProps = (state: State) => ({
    adUsers: state.assessorDashboard.adUsers,
});

export default connect(mapStateToProps, mapDispatchToProps)(AssessorDashboardADSearch);
