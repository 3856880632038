import { ReduxAction } from '../../redux/redux-actions';
import { Doctor } from '../reducer';
import { ADD_DOCTOR } from '../types';

export type AddDoctorAction = ReduxAction<Doctor>;
export type AddDoctor = (record: Doctor) => AddDoctorAction;
export const addDoctorAction: AddDoctor = (record: Doctor) => {
    return {
        type: ADD_DOCTOR,
        payload: record,
    };
};
