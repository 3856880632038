import React from 'react'; // eslint-disable-line
import { Grid, Tooltip, Button } from '@material-ui/core';
import { ButtonWrapper, FullWidthTextField } from '../../../../../../forms';
import { requiredValidator } from '../../../../../../forms/validations';
import { Delete, AddCircle } from '@material-ui/icons';
import { Beneficiary } from '../../reducer';

interface ComponentState {
    beneficiaries: Beneficiary[];
    isReadOnly: boolean;
}
interface BeneficiariesProps {
    classes: any;
}
export class BeneficiariesView extends React.Component<BeneficiariesProps, ComponentState> {
    public constructor(props: BeneficiariesProps) {
        super(props);
        this.setState({
            beneficiaries: [],
        });
    }
    public render() {
        const beneficiaries = this.state ? this.state.beneficiaries : [];
        const classes = this.props.classes;
        const addEntry = () => {
            beneficiaries.push({});
            this.setState({
                beneficiaries: beneficiaries,
            });
            this.forceUpdate();
        };
        const deleteEntry = (index: number) => {
            beneficiaries.splice(index, 1);
            this.setState({
                beneficiaries: beneficiaries,
            });
            this.forceUpdate();
        };
        return (
            <React.Fragment>
                {beneficiaries.map((_pm, index) => {
                    return (
                        <Grid item container spacing={5} key={`pm_${index}`}>
                            <Grid item xs={6}>
                                <FullWidthTextField
                                    name={`beneficiaries[${index}].firstName`}
                                    validate={requiredValidator}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <FullWidthTextField
                                    name={`beneficiaries[${index}].lastName`}
                                    validate={requiredValidator}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <ButtonWrapper>
                                    <Tooltip
                                        title="Delete beneficiary"
                                        style={{
                                            marginTop: '30px',
                                            marginLeft: '-30px',
                                        }}
                                    >
                                        <Button
                                            id={`Delete_beneficiary_${index}`}
                                            color="secondary"
                                            onClick={() => deleteEntry(index)}
                                        >
                                            <Delete />
                                        </Button>
                                    </Tooltip>
                                </ButtonWrapper>
                            </Grid>
                        </Grid>
                    );
                })}
                <Grid container spacing={0} justify="flex-end" className={classes.marginTop3}>
                    <ButtonWrapper>
                        <Tooltip title="Add Beneficiary">
                            <Button
                                id="AddExternalPolicy"
                                color="secondary"
                                className={classes.button}
                                style={{ textTransform: 'none' }}
                                onClick={() => addEntry()}
                            >
                                <AddCircle />
                                Add Beneficiary
                            </Button>
                        </Tooltip>
                    </ButtonWrapper>
                </Grid>
            </React.Fragment>
        );
    }
}
