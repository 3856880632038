import { DeleteRecordCollectionAction } from '../../redux/collection-reducer';
import { DELETE_INFORMATION_RECEIVED_RECORD } from '../types';

export type DeleteInformationReceivedRecordAction = DeleteRecordCollectionAction;

export const deleteInformationReceivedRecordActionCreator = (
    informationReceivedRecordIndex: number
): DeleteInformationReceivedRecordAction => {
    return {
        type: DELETE_INFORMATION_RECEIVED_RECORD,
        index: informationReceivedRecordIndex,
    };
};
