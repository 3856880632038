import { withStyles, Grid, Typography, Paper, Tooltip, Button } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import styles, { StyledComponent } from '../../../../../styles/styles';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { Edit } from '@material-ui/icons';
import { connect } from 'react-redux';
import { State } from '../../../../../redux/root-reducer';
import { FormDetails, getFormDetails } from '../form-details-reducer';
import { OpenModal, openModalAction } from '../../../../../form-modal/actions';
import { editPolicyHolderDetails } from './policy-holder-edit';
import { UpdateFormDetails, updateFormDetailsAction } from '../actions/update-form-details';

interface BaseProps {
    formDetails?: FormDetails;
    updateFormDetails?: UpdateFormDetails;
    disableEditButton?: boolean;
}
interface Props extends BaseProps, StyledComponent {
    readonly openModal: OpenModal;
}

const employmentDetailsView: React.FunctionComponent<Props> = ({
    classes,
    openModal,
    formDetails,
    updateFormDetails,
    disableEditButton,
}: Props) => {
    const edit = () => {
        const details = formDetails || {};
        editPolicyHolderDetails(openModal, details, 3, updateFormDetails);
    };
    const employmentDetails =
        formDetails?.policyHolder && formDetails?.policyHolder?.individual?.employmentDetails;

    return (
        <React.Fragment>
            <Grid container justify="space-between" className={classes.marginTop2}>
                <Grid container item xs={10} alignItems="center">
                    <Typography style={{ padding: 10 }} className={classes.heading} variant="body1">
                        Employment Details
                    </Typography>
                </Grid>
            </Grid>
            <Paper className={classes.paper}>
                <Grid container item xs={12} alignItems="center" justify="flex-end">
                    <Tooltip title="Edit Employment Details">
                        <Button
                            id="EditPersonalDetails"
                            color="secondary"
                            onClick={edit}
                            disabled={!disableEditButton}
                        >
                            <Edit />
                        </Button>
                    </Tooltip>
                </Grid>
                <Grid container justify="flex-start">
                    <Grid item xs={12} container justify="flex-start">
                        <Grid item xs={4} lg={3}>
                            <LabeledValue
                                label="Employed"
                                value={employmentDetails?.isPolicyHolderEmployed}
                            />
                        </Grid>
                        {employmentDetails?.isPolicyHolderEmployed === 'Yes' && (
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Employer Name"
                                    value={employmentDetails?.employerName}
                                />
                            </Grid>
                        )}
                        {employmentDetails?.isPolicyHolderEmployed === 'Yes' && (
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Nominated Occuption"
                                    value={employmentDetails?.nominatedOccupation}
                                />
                            </Grid>
                        )}
                        {employmentDetails?.isPolicyHolderEmployed === 'Yes' && (
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Nominated Industry"
                                    value={employmentDetails?.industryOfOccupation}
                                />
                            </Grid>
                        )}
                        {employmentDetails?.saTaxReferenceNumber && (
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="SA Income reference tax number"
                                    value={employmentDetails?.saTaxReferenceNumber}
                                />
                            </Grid>
                        )}
                        {employmentDetails?.taxOffice && (
                            <Grid item xs={4} lg={3}>
                                <LabeledValue
                                    label="Tax office"
                                    value={employmentDetails?.taxOffice}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

const mapStateToProps = (state: State, ownProps: BaseProps) => ({
    formDetails: ownProps.formDetails || getFormDetails(state),
    disableEditButton: ownProps.disableEditButton || false,
});

const mapDispatchToProps = {
    openModal: openModalAction,
    updateFormDetails: updateFormDetailsAction,
};

export const EmploymentDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(employmentDetailsView));
