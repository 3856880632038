import axios from 'axios';
import { claimUpdatedThunk } from '../';
import { env } from '../../env';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { Claim } from '../reducers';

const claimsUri = `${env().CLAIMS_API}/api/Claim/`;

type SubmitReturn = Promise<any>;
export type SubmitClaim = (claim: Claim) => SubmitReturn;
export type SubmitClaimThunk = (claim: Claim) => ReduxThunkAction;
export const submitClaimThunk: SubmitClaimThunk = (claim: Claim) => {
    return async dispatch => {
        const updateClaimUri = `${claimsUri}${claim.claimId}`;

        const requestClaim: Claim = claim;

        const response = await axios.put<Claim>(updateClaimUri, requestClaim);

        const responseClaim: Claim = response.data;

        dispatch(claimUpdatedThunk(responseClaim));
        return {};
    };
};
