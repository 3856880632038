import React from 'react'; // eslint-disable-line
import { Grid, Paper, Divider, Typography, withStyles } from '@material-ui/core';
import {
    FullWidthTextField,
    FullWidthDatePicker,
    FullWidthCheckBoxField,
    Condition,
    True,
    WhenFieldChanges,
    FullWidthSearchSelectField,
    FullWidthRadioGroup,
    RadioOption,
} from '../../../../../forms';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { NumberMask } from '../../../../../forms/input-masks';
import { requiredValidator } from '../../../../../forms/validations';
import { NaturalCauseOfDeath, UnnaturalCauseOfDeath } from '../../../../../codes/cause-of-claim';

const claimsDetailsView: React.FunctionComponent<StyledComponent> = ({
    classes,
}: StyledComponent) => {
    return (
        <React.Fragment>
            <Typography variant="h5">Claim Event Details</Typography>
            <Divider></Divider>
            <Paper className={classes.paper}>
                <Grid container spacing={5} justify="flex-start">
                    <Grid item xs={7} lg={6}>
                        <FullWidthDatePicker
                            id="dateOfEvent"
                            name="dateOfEvent"
                            validate={requiredValidator}
                            disableFuture
                        />
                    </Grid>
                    <Grid item xs={7} lg={6}>
                        <FullWidthTextField
                            id="placeOfDeath"
                            name="placeOfDeath"
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={7} lg={6}>
                        <FullWidthTextField id="postalCode" name="postalCode" mask={NumberMask} />
                    </Grid>
                    <Condition when="dateOfEvent" hasValue>
                        <True>
                            <Grid item xs={7} lg={6}>
                                <FullWidthDatePicker
                                    id="dateClaimReported"
                                    name="dateClaimReported"
                                    validate={requiredValidator}
                                    minDateField="dateOfEvent"
                                    disableFuture
                                />
                            </Grid>
                        </True>
                    </Condition>
                    <Grid item xs={6}>
                        <FullWidthRadioGroup
                            label="Cause of death"
                            name="typeOfDeath"
                            validate={requiredValidator}
                            id="typeOfDeath"
                        >
                            <RadioOption value="Natural" />
                            <RadioOption value="Unnatural" />
                        </FullWidthRadioGroup>
                        <WhenFieldChanges field="typeOfDeath" set="causeOfDeath" to={null} />
                    </Grid>
                    <Condition when="typeOfDeath" is="Natural">
                        <True>
                            <Grid item xs={7} lg={6}>
                                <FullWidthSearchSelectField
                                    label="Cause of death"
                                    id="causeOfDeath"
                                    name="causeOfDeath"
                                    options={NaturalCauseOfDeath}
                                    validate={requiredValidator}
                                />
                                <WhenFieldChanges
                                    field="causeOfDeath"
                                    set="causeOfDeathDescription"
                                    to={null}
                                />
                            </Grid>
                        </True>
                    </Condition>
                    <Condition when="typeOfDeath" is="Unnatural">
                        <True>
                            <Grid item xs={7} lg={6}>
                                <FullWidthSearchSelectField
                                    id="causeOfDeath"
                                    name="causeOfDeath"
                                    label="Cause of death"
                                    options={UnnaturalCauseOfDeath}
                                    validate={requiredValidator}
                                />
                                <WhenFieldChanges
                                    field="causeOfDeath"
                                    set="causeOfDeathDescription"
                                    to={null}
                                />
                            </Grid>
                        </True>
                    </Condition>
                    <Condition when="causeOfDeath" is={['799', '798']}>
                        <True>
                            <Grid item xs={7} lg={6}>
                                <FullWidthTextField
                                    id="causeOfDeathDescription"
                                    name="causeOfDeathDescription"
                                    validate={requiredValidator}
                                />
                            </Grid>
                        </True>
                    </Condition>
                    <Grid item xs={7} lg={6}>
                        <FullWidthCheckBoxField name="reportedToPolice" />
                        <WhenFieldChanges
                            field="reportedToPolice"
                            becomes={false}
                            set="caseNumber"
                            to={null}
                        />
                    </Grid>
                    <Condition when="reportedToPolice">
                        <True>
                            <Grid item xs={7} lg={6}>
                                <FullWidthTextField
                                    id="caseNumber"
                                    name="caseNumber"
                                    validate={requiredValidator}
                                />
                            </Grid>
                        </True>
                    </Condition>
                </Grid>
            </Paper>
        </React.Fragment>
    );
};
export const ClaimsDetailsViewEdit = withStyles(styles)(claimsDetailsView);
