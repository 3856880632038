import React from 'react'; // eslint-disable-line

import { Field } from 'react-final-form';
import { FieldSet } from '../../';
import { RadioGroupField } from '../radio-group-field';

type Props = {
    name: string;
    label?: React.ReactNode;
    children: React.ReactNode;
    id?: string;
    noLabel?: boolean;
} & any;

const fullWidthRadioGroup: React.FunctionComponent<Props> = ({
    name,
    label,
    noLabel,
    children,
    ...rest
}: Props) => {
    return (
        <FieldSet>
            <Field
                {...rest}
                name={name}
                component={RadioGroupField}
                label={label}
                noLabel={noLabel}
            >
                {children}
            </Field>
        </FieldSet>
    );
};

export const FullWidthRadioGroup = fullWidthRadioGroup;
