import { Button, Grid, Paper, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { Form } from 'react-final-form';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { OpenDialog, openDialogAction } from '../../../../confirmation-dialog';
import { LabeledValue } from '../../../../controls/labeled-value';
import { EditFormModal } from '../../../../form-modal';
import { OpenEditModal, openEditModalAction } from '../../../../form-modal/actions';
import { ButtonWrapper, FullWidthCheckBoxField } from '../../../../forms';
import {
    DeleteAndSubmitInformationReceivedRecord,
    deleteAndSubmitInformationReceivedRecordThunk,
    UpdateAndSubmitInformationReceivedRecord,
    updateAndSubmitInformationReceivedRecordThunk,
} from '../../../../information-received-summary/actions/submit-information-received';
import InformationReceivedRecordDetailView from '../../../../information-received-summary/views/information-received-record-detail';
import {
    getInformationReceivedSummary,
    InformationReceivedRecord,
} from '../../../../information-received-summary/reducer';
import { State } from '../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';

interface OwnProps {
    index: number;
    informationReceivedRecord: InformationReceivedRecord;
}

interface StateProps {
    informationReceivedSummary: InformationReceivedRecord[];
}

interface DispatchProps {
    updateRecord: UpdateAndSubmitInformationReceivedRecord;
    deleteRecord: DeleteAndSubmitInformationReceivedRecord;
    openEditModal: OpenEditModal;
    openDialog: OpenDialog;
}

type Props = DispatchProps & OwnProps & StateProps & StyledComponent;

const informationReceivedRecordView: React.FunctionComponent<Props> = ({
    classes,
    deleteRecord,
    index,
    informationReceivedRecord,
    informationReceivedSummary,
    openEditModal,
    openDialog,
    updateRecord,
}: Props) => {
    return (
        <Paper className={classes.paper} key={index}>
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={12} lg={12} container justify="space-between">
                    <Grid item xs={8} lg={10}>
                        <Typography variant="subtitle2">{`Document ${index + 1}`}</Typography>
                    </Grid>
                    <Grid item container xs={4} lg={2} justify="flex-end">
                        {editInformationReceivedRecordButton(
                            informationReceivedSummary,
                            index,
                            updateRecord,
                            openEditModal
                        )}
                        {deleteInformationReceivedRecordButton(index, deleteRecord, openDialog)}
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <LabeledValue
                        label="Document ID"
                        value={informationReceivedRecord.documentID}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <LabeledValue
                        label="Document Name"
                        value={informationReceivedRecord.documentName}
                    />
                </Grid>
                {informationReceivedRecord.documentName === 'Other' && (
                    <Grid item xs={12} lg={3}>
                        <LabeledValue
                            label="Document Description"
                            value={informationReceivedRecord.documentDescription}
                        />
                    </Grid>
                )}
                <Grid item xs={12} lg={3}>
                    <LabeledValue
                        label="Report Date"
                        value={getDisplayDate(informationReceivedRecord.dateOfReport)}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <LabeledValue label="Assessor" value={informationReceivedRecord.assessor} />
                </Grid>
                <Grid item xs={12} lg={9}>
                    <LabeledValue
                        label="Summary"
                        value={informationReceivedRecord.summary}
                        isUnsafe
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <LabeledValue
                        label="Summary Date"
                        value={getDisplayDate(informationReceivedRecord.dateOfSummary)}
                    />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Form
                        // tslint:disable-next-line:no-empty
                        onSubmit={() => {}}
                        initialValues={{ ...informationReceivedRecord }}
                        render={() => (
                            <FullWidthCheckBoxField
                                name="sendToUnderwriter"
                                label="Send Information to Underwriting"
                                disabled
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

const InformationReceivedEditFormModal = EditFormModal<InformationReceivedRecord>();
const editInformationReceivedRecordButton = (
    informationReceivedRecords: InformationReceivedRecord[],
    informationReceivedRecordIndex: number,
    updateRecord: UpdateAndSubmitInformationReceivedRecord,
    openEditModal: OpenEditModal
) => {
    const openEditView = () => {
        openEditModal({
            modalContent: (
                <InformationReceivedEditFormModal
                    recordDetailView={
                        <InformationReceivedRecordDetailView heading="Received or additional information?" />
                    }
                    records={informationReceivedRecords}
                    updateRecord={(informationReceivedRecord, index) =>
                        updateRecord(index, informationReceivedRecord)
                    }
                />
            ),
            modalData: {
                recordIndex: informationReceivedRecordIndex,
            },
        });
    };

    return (
        <ButtonWrapper>
            <Tooltip title="Edit Information Received">
                <Button id="EditInformationReceived" color="secondary" onClick={openEditView}>
                    <Edit />
                </Button>
            </Tooltip>
        </ButtonWrapper>
    );
};

const deleteInformationReceivedRecordButton = (
    informationReceivedRecordIndex: number,
    deleteRecord: DeleteAndSubmitInformationReceivedRecord,
    openDialog: OpenDialog
) => {
    const confirmDelete = () => {
        openDialog({
            content: {
                title: 'Delete Information Received Record',
                body: 'Are you sure you would like to delete the selected information received?',
            },
            yesAction: {
                action: () => deleteRecord(informationReceivedRecordIndex),
            },
        });
    };

    return (
        <ButtonWrapper>
            <Tooltip title="Delete Information Received">
                <Button id="DeleteInformationReceived" color="secondary" onClick={confirmDelete}>
                    <Delete />
                </Button>
            </Tooltip>
        </ButtonWrapper>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = state => ({
    informationReceivedSummary: getInformationReceivedSummary(state).documents,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    updateRecord: (index: number, informationReceivedRecord: InformationReceivedRecord) =>
        dispatch(updateAndSubmitInformationReceivedRecordThunk(index, informationReceivedRecord)),
    deleteRecord: (index: number) => dispatch(deleteAndSubmitInformationReceivedRecordThunk(index)),
    openEditModal: options => dispatch(openEditModalAction(options)),
    openDialog: options => dispatch(openDialogAction(options)),
});

export const InformationReceivedRecordView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(informationReceivedRecordView));
