import { claimSubmit, Claim } from '../../../../../claim';
import { addRecordReduction } from '../../../../../redux/reductions';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import {
    getRequirementsFromClaim,
    RequiredDocument,
} from '../../../common/requirements-management/reducer';
import { FuneralRequirements } from '../reducer';

type SubmitReturn = Promise<any>;
export type AddPolicyOwnerDocument = (documents: RequiredDocument) => SubmitReturn;
type AddPolicyOwnerDocumentThunk = (documents: RequiredDocument) => ReduxThunkAction;

export const addPolicyOwnerDocumentAction: AddPolicyOwnerDocumentThunk = (
    documents: RequiredDocument
) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, getUpdatedClaim(documents));
    };
};

function getUpdatedClaim(document: RequiredDocument): (claim: Claim) => Claim {
    return (claim: Claim) => {
        const currentRequirements = getRequirementsFromClaim(claim) as FuneralRequirements;
        const policyOwner = currentRequirements.policyOwner || { documents: [] };
        policyOwner.documents = addRecordReduction(policyOwner.documents, document);
        return {
            ...claim,
            requirements: {
                ...currentRequirements,
                policyOwner,
            },
        };
    };
}
