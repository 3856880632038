import React, { useEffect, useState } from 'react'; // eslint-disable-line
import { TextField, TextFieldProps } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { callAllFunctions } from '../../';
import { FieldRenderProps } from 'react-final-form';
import { MaskedInputProps } from 'react-text-mask';

type Props = TextFieldProps &
    MaskedInputProps &
    FieldRenderProps<any, any> & {
        iconStart?: JSX.Element;
        iconEnd?: JSX.Element;
        InputProps?: any;
        label?: string;
        classes?: any;
        textStyle?: any;
        name?: any;
        onChange: (values?: any) => void;
    };

export const IconTextField: React.FunctionComponent<Props> = ({
    input,
    iconStart,
    iconEnd,
    InputProps,
    onChange,
    classes,
    textStyle,
    name,
    inputProps,
    ...rest
}: Props) => {
    const { value, ...restInput } = input;
    const shrink = value.length > 0;
    useEffect(() => {
        if (rest.disabled) {
            setVal('');
            input.onChange('');
        }
    }, [input, rest.disabled]);

    const [val, setVal] = useState(value);

    inputProps = {
        ...restInput,
        ...inputProps,
    };

    return (
        <React.Fragment>
            <TextField
                {...rest}
                size="small"
                variant="outlined"
                name={name}
                InputLabelProps={{
                    htmlFor: name,
                    shrink: shrink,
                    className: shrink ? undefined : classes.inputLabelNoShrink,
                    style: { fontSize: '0.8rem', marginLeft: shrink ? 5 : 30 },
                }}
                autoFocus={rest.isFocused}
                onChange={callAllFunctions(onChange, input.onChange)}
                style={textStyle}
                InputProps={{
                    ...InputProps,
                    startAdornment: iconStart ? (
                        <InputAdornment position="start">{iconStart}</InputAdornment>
                    ) : null,
                    endAdornment: iconEnd ? (
                        <InputAdornment position="end">{iconEnd}</InputAdornment>
                    ) : null,
                }}
                inputProps={inputProps} // eslint-disable-line react/jsx-no-duplicate-props
                value={val}
            />
        </React.Fragment>
    );
};
