import { reduceAndSubmitClaim } from '../../../../../claim/actions/reduce-and-submit-claim';
import { UpdateRecordCollectionAction } from '../../../../../redux/collection-reducer';
import { addRecordReduction, updateRecordReduction } from '../../../../../redux/reductions';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { Consultation } from '../../../death/life-assured-details/reducer';
import { Doctors } from '../reducer';
import { UPDATE_CURRENT_DOCTOR_RECORD } from '../types';

type SubmitReturn = Promise<any>;
export type AddDoctorsConsultation = (
    consultation: Consultation,
    doctors: Doctors,
    doctorsIndex: number
) => SubmitReturn;
type AddCurrentDoctorsConsultationThunk = (
    consultation: Consultation,
    doctors: Doctors,
    doctorsIndex: number
) => ReduxThunkAction<SubmitReturn>;

export const addCurrentDoctorsConsultationThunk: AddCurrentDoctorsConsultationThunk = (
    consultation: Consultation,
    doctors: Doctors,
    doctorsIndex: number
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        addCurrentDoctorsConsultationActionCreator(consultation, doctors, doctorsIndex)
    );
export type AddCurrentDoctorsConsultationAction = UpdateRecordCollectionAction<Doctors>;
export const addCurrentDoctorsConsultationActionCreator = (
    consultation: Consultation,
    doctors: Doctors,
    doctorsIndex: number
): AddCurrentDoctorsConsultationAction => {
    doctors.doctorsVisits = addRecordReduction(doctors.doctorsVisits || [], consultation);
    return {
        type: UPDATE_CURRENT_DOCTOR_RECORD,
        record: doctors,
        index: doctorsIndex,
    };
};

export type UpdateDoctorsConsultation = (
    consultation: Consultation,
    doctors: Doctors,
    visitIndex: number,
    doctorsIndex: number
) => SubmitReturn;
type UpdateCurrentDoctorsConsultationThunk = (
    consultation: Consultation,
    doctors: Doctors,
    visitIndex: number,
    doctorsIndex: number
) => ReduxThunkAction<SubmitReturn>;

export const updateCurrentDoctorsConsultationThunk: UpdateCurrentDoctorsConsultationThunk = (
    consultation: Consultation,
    doctors: Doctors,
    consultIndex: number,
    doctorsIndex: number
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        updateCurrentDoctorsConsultationActionCreator(
            consultation,
            doctors,
            consultIndex,
            doctorsIndex
        )
    );
export type UpdateCurrentDoctorsConsultationAction = UpdateRecordCollectionAction<Doctors>;
export const updateCurrentDoctorsConsultationActionCreator = (
    consultation: Consultation,
    doctor: Doctors,
    consultIndex: number,
    doctorsIndex: number
): UpdateCurrentDoctorsConsultationAction => {
    const updatedConsultations = updateRecordReduction(
        doctor.doctorsVisits || [],
        consultation,
        consultIndex
    );
    const updatedDoctor = {
        ...doctor,
        doctorsVisits: updatedConsultations,
    };
    return {
        type: UPDATE_CURRENT_DOCTOR_RECORD,
        record: updatedDoctor,
        index: doctorsIndex,
    };
};

export type DeleteDoctorsConsultation = (
    doctors: Doctors,
    consultIndex: number,
    doctorsIndex: number
) => SubmitReturn;
type DeleteCurrentDoctorsConsultationThunk = (
    doctor: Doctors,
    consultIndex: number,
    doctorsIndex: number
) => ReduxThunkAction<SubmitReturn>;

export const deleteCurrentDoctorsConsultationThunk: DeleteCurrentDoctorsConsultationThunk = (
    doctor: Doctors,
    consultIndex: number,
    doctorsIndex
) => (dispatch, getState) =>
    reduceAndSubmitClaim(
        dispatch,
        getState,
        deleteCurrentDoctorsConsultationActionCreator(doctor, consultIndex, doctorsIndex)
    );
export type DeleteCurrentDoctorsConsultationAction = UpdateRecordCollectionAction<Doctors>;
export const deleteCurrentDoctorsConsultationActionCreator = (
    doctor: Doctors,
    consultIndex: number,
    doctorsIndex: number
): DeleteCurrentDoctorsConsultationAction => {
    if (doctor && doctor.doctorsVisits) {
        doctor.doctorsVisits.splice(consultIndex, 1);
    }
    return {
        type: UPDATE_CURRENT_DOCTOR_RECORD,
        record: doctor,
        index: doctorsIndex,
    };
};
