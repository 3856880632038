import { ActionCreator } from 'redux';
import { ReduxAction, ReduxThunkAction } from '../../redux/redux-actions';
import { Claim } from '../reducers';
import { CLAIM_UPDATED } from '../types';

export type ClaimUpdatedAction = ReduxAction<Claim>;
export const claimUpdatedActionCreator: ActionCreator<ClaimUpdatedAction> = (claim: Claim) => ({
    type: CLAIM_UPDATED,
    payload: claim,
});

export type ClaimUpdatedThunk = (claim: Claim) => ReduxThunkAction;
export const claimUpdatedThunk: ClaimUpdatedThunk = (claim: Claim) => {
    return async dispatch => {
        sessionStorage.setItem('currentClaimId', claim.claimId);

        dispatch(claimUpdatedActionCreator(claim));

        return {};
    };
};
