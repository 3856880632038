import { CodeList } from '../codes/codes';
import { capitalCase } from 'change-case';

export enum ClaimType {
    Death = 'Death',
    Disability = 'Disability',
    IncomeProtector = 'IncomeProtector',
    Retrenchment = 'Retrenchment',
    Dread = 'Dread',
    RADeath = 'RADeath',
    Funeral = 'Funeral',
    TerminalIllness = 'TerminalIllness',
    Uninitiated = 'Uninitiated',
    EarlyRetirement = 'EarlyRetirement',
}

export const claimTypes: CodeList<string> = Object.keys(ClaimType).map(claimType => ({
    value: claimType,
    label: capitalCase(claimType),
}));
