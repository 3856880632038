import { getClaim, Claim, getPropertyFromClaim } from '../../../../claim';
import { RADeathClaim } from '../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { Code } from '../../../../codes/codes';
import { BankingDetails } from '../../common/banking-details/banking-details';
import { AddressDetails } from '../../../../forms/sub-forms/address-details';
import { CommunicationDetails } from '../../../../forms/sub-forms/communication-details';
import { PersonalDetails } from '../../../../life-assured-details/reducer';
import { collectionReducer } from '../../../../redux/collection-reducer';
import { State } from '../../../../redux/root-reducer';
import { ADD_DEPENDANT, DELETE_DEPENDANT, UPDATE_DEPENDANT } from './actions/types';
import { OrganisationDetails } from '../../death/contact-details/contact-details-reducer';
import { FinancialAdviserContactDetails } from '../../../../contact-details/financial-adviser/financial-adviser-contact-details';
import { getFullName, getCodeLabel } from '../../../../shared-functions';
import { RelationshipsChildren } from '../../../../codes/relationship-types';

export interface ContactDetails extends CommunicationDetails {
    homeTelephoneNumber?: string;
}

export interface Curator extends PersonalDetails {
    taxStatus?: string;
    contactDetails?: ContactDetails;
    residentialAddress?: AddressDetails;
    postalAddress?: AddressDetails;
}

export interface Guardian extends Curator {
    relationshipToMinor: string;
    relationshipToMinorDescription: string;
    relationshipToLifeAssured: string;
    relationshipToLifeAssuredDescription: string;
}

export interface FinancialBreakdown {
    incomeExpenditureType?: string;
    incomeExpenditureDescription?: string;
    incomeExpenditureDetails?: string;
    incomeExpenditure?: number;
    maintenance?: number;
    maintenanceFrequency?: string;
}

export interface FinancialInformation {
    dependentOnLifeAssured?: string;
    receivedMaintenance?: string;
    hasFinancialAdviser?: string;
    financialAdviceConsent?: string;
    breakdown?: FinancialBreakdown[];
    financialAdviser?: FinancialAdviserContactDetails;
}

export interface Dependant {
    id: string;
    dependantType?: string;
    natureOfDependency?: string;
    relationship?: string;
    relationshipDescription?: string;
    linkedToBeneficiary?: string;
    claimCapacity?: string;
    beneficiaryOfWill?: string;
    isExecutor?: string;
    dateOfDivorceFromLifeAssured?: Date;
    dateOfMarriageToLifeAssured?: Date;
    divorceSettlement?: string;
    remarried?: string;
    yearsInRelationship?: string;
    yearsEstranged?: string;
    disability?: string;
    disabilityDetails?: string;
    underCuratorship?: string;
    employed?: string;
    occupation?: string;
    company?: string;
    studentOrScholar?: string;
    schoolYearGrade?: string;
    schoolYearsLeft?: string;
    courseType?: string;
    courseName?: string;
    institution?: string;
    statementCompleted?: string;
    personalDetails?: PersonalDetails;
    sameAsResidential?: boolean;
    postalAddress?: AddressDetails;
    residentialAddress?: AddressDetails;
    bankingDetails?: BankingDetails;
    contactDetails?: ContactDetails;
    curator?: Curator;
    financialInformation?: FinancialInformation;
    organisationDetails?: OrganisationDetails;
    guardian?: Guardian;
    parent?: string;
    hasBankingDetails?: boolean;
    isDependantSelected: boolean;
    percentageSplit: string;
}

export const dependantReducer = collectionReducer<Dependant>({
    addAction: ADD_DEPENDANT,
    deleteAction: DELETE_DEPENDANT,
    updateAction: UPDATE_DEPENDANT,
});

export function getDependants(state: State): Dependant[] {
    return getDependantsFromClaim(getClaim(state));
}

export function getDependantsFromClaim(claim: Claim): Dependant[] {
    return getPropertyFromClaim(claim, 'dependants', []);
}

const DependantParents = ['ExSpouse', 'EstrangedSpouse', 'Partner', 'Spouse'];
export function getPossibleParents(state: State) {
    const dependants = getDependants(state);
    return getSpouses(dependants);
}

export function getSpouses(dependants: Dependant[]) {
    const possibleParents: Code<string>[] = [];
    dependants.forEach(dependant => {
        if (dependant.relationship && DependantParents.includes(dependant.relationship)) {
            possibleParents.push({
                label: getFullName(dependant.personalDetails),
                value: dependant.id,
            });
        }
    });
    return possibleParents;
}

export function getLinkedBeneficiaries(beneficiaries: Code<string>[], id?: string) {
    const beneficiaryList: string[] = [];
    if (id) {
        beneficiaries.forEach(beneficiary => {
            if (beneficiary.value === id) {
                beneficiaryList.push(beneficiary.label);
            }
        });
    }
    return beneficiaryList.length > 0 ? beneficiaryList : 'Not listed';
}

export function getDependantsRelationship(description?: string) {
    return getCodeLabel(RelationshipsChildren, description);
}

export function getBeneficiaries(claim: RADeathClaim) {
    const beneficiaries: Code<string>[] = [];
    if (claim.lifeAssuredDetails) {
        claim.lifeAssuredDetails.contracts.forEach(contract => {
            if (contract.beneficiaries) {
                contract.beneficiaries.forEach(beneficiary => {
                    if (beneficiary.linkToClaimant) {
                        const firstName =
                            beneficiary.personalDetails && beneficiary.personalDetails.firstName;
                        const lastName =
                            beneficiary.personalDetails && beneficiary.personalDetails.lastName;
                        beneficiaries.push({
                            value: `${beneficiary.linkToClaimant}`,
                            label: `${firstName || ''} ${lastName || ''} (${
                                contract.contractNumber
                            })`,
                        });
                    }
                });
            }
        });
    }

    return beneficiaries;
}

export function getDependantsDisplayName(dependant?: Dependant) {
    if (!dependant) {
        return '';
    }
    switch (dependant && dependant.dependantType) {
        case 'Organisation':
            const organisationDetails = dependant.organisationDetails || {};
            return getFullName(
                organisationDetails.authorisedPerson
                    ? organisationDetails.authorisedPerson.personalDetails
                    : dependant.personalDetails
            );
        case 'Individual':
            return getFullName(dependant.personalDetails);
        default:
            return '';
    }
}

export function getUpdatedDependant(dependant: Dependant): Dependant {
    return {
        ...dependant,
        postalAddress: dependant.sameAsResidential
            ? dependant.residentialAddress
            : dependant.postalAddress,
        curator: getUpdatedCurator(dependant.curator),
    };
}

function getUpdatedCurator(curator?: Curator) {
    if (curator) {
        return {
            ...curator,
            postalAddress: curator.sameAsResidential
                ? curator.residentialAddress
                : curator.postalAddress,
        };
    }
    return undefined;
}
