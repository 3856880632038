import { Button, Grid, Tooltip, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { ForensicInput, getForensicInput } from '../reducer';
import { OpenModal, openModalAction } from '../../../../../form-modal/actions';
import { ExpansionPanel } from '../../../../../forms/form-components/expansion-panel/expansion-panel';
import { getDisplayDate } from '../../../../../shared-functions';
import { FormModalView } from '../../../../../form-modal/views/form-modal';
import { ForensicInputForm } from './forensic-input-form';
import { State } from '../../../../../redux/root-reducer';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { ButtonWrapper } from '../../../../../forms';

type Props = StyledComponent & {
    forensicInput: ForensicInput;
    openModal: OpenModal;
    isReadOnly: boolean;
};
const forensicInputView: React.FunctionComponent<Props> = ({
    classes,
    forensicInput,
    openModal,
    isReadOnly,
}: Props) => {
    return (
        <ExpansionPanel name="Forensic Input">
            <Grid container spacing={2}>
                <Grid item xs={4} lg={2}>
                    <LabeledValue
                        label="Date Referred"
                        value={getDisplayDate(forensicInput.dateReferred)}
                    />
                </Grid>
                <Grid item xs={6} lg={9}>
                    <LabeledValue
                        label="Reason For Referral"
                        value={forensicInput.reasonForReferral}
                    />
                </Grid>
                <ButtonWrapper>
                    <Grid item xs={2} lg={1} className={classes.rightAlign}>
                        <Tooltip title="Edit Forensic Input">
                            <Button id="EditForensicInput" color="secondary" onClick={() => edit()}>
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
                <Grid item xs={4} lg={2}>
                    <LabeledValue
                        label="Date Feedback Received"
                        value={getDisplayDate(forensicInput.dateFeedbackReceived)}
                    />
                </Grid>
                <Grid item xs={4} lg={2}>
                    <LabeledValue
                        label="Date Resolved"
                        value={getDisplayDate(forensicInput.dateResolved)}
                    />
                </Grid>
            </Grid>
        </ExpansionPanel>
    );

    function edit() {
        openModal({
            modalContent: <FormModalView formView={ForensicInputForm} />,
        });
    }
};

const mapStateToProps = (state: State) => ({
    forensicInput: getForensicInput(state),
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = {
    openModal: openModalAction,
};

export const ForensicInputView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(forensicInputView));
