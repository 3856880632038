import { DateTransform } from './transforms/date-transform';
import { Transform } from './transforms/transform';

const transforms: Transform[] = [new DateTransform()];

export function responseTransformInteceptor(response: any): any {
    let data = response.data;

    data = transform(data);

    response.data = data;

    return response;
}

export function transform(object: any): any {
    if (!object) {
        return object;
    }

    const objectTransform = transforms.find(t => t.handles(object));
    if (objectTransform) {
        return objectTransform.transform(object);
    }

    if (Array.isArray(object)) {
        object.forEach((value, i) => {
            object[i] = transform(value);
        });
        return object;
    }

    if (typeof object === 'object') {
        Object.keys(object).forEach(k => {
            object[k] = transform(object[k]);
        });
        return object;
    }

    return object;
}
