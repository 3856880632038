import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { ExpansionPanel } from '../../../../forms/form-components/expansion-panel/expansion-panel';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { UnderwritingDetails, UnderwritingDetailsView } from './components/underwriting-details';

interface OwnProps {
    policyIndex: number;
    policyUnderwritingDetails: UnderwritingDetails;
    isUnderWriting?: boolean;
}

interface StateProps {}

interface DispatchProps {}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const policyUnderwritingView: React.FunctionComponent<Props> = ({
    policyIndex,
    policyUnderwritingDetails,
    isUnderWriting,
}: Props) => {
    return (
        <ExpansionPanel
            name={`Policy ${policyUnderwritingDetails.policyDetails.policyNumber}`}
            style={{ marginLeft: -24, marginRight: -24 }}
        >
            <UnderwritingDetailsView
                policyIndex={policyIndex}
                underwritingDetails={policyUnderwritingDetails}
                isUnderWriting={isUnderWriting}
            />
        </ExpansionPanel>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {};

export const PolicyUnderwritingView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(policyUnderwritingView));
