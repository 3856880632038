import { Reducer, AnyAction } from 'redux';
import { Claim, getClaim, getPropertyFromClaim } from '../../../../claim';
import { combineReducersWithDefault } from '../../../../redux/combine-reducers-with-default';
import { State } from '../../../../redux/root-reducer';
import { Nullable } from '../../../../shared-types';
import { VOPD, vopdReducer } from '../../common/vopd/reducer';
import { DeathClaimDetails } from '../../death/claim-event-details/reducer';

export interface DeathCertificate {
    serialNumber?: string;
    firstName?: string;
    secondName?: string;
    lastName?: string;
    idNumber?: number;
    dateOfBirth?: Date;
    dateOfDeath?: Date;
    placeOfDeath?: string;
    causeOfDeath?: string;
    issueDate?: Date;
    issuedBy?: string;
}

export interface DeathCertification {
    deathCertificate: DeathCertificate;
    vopd: VOPD;
}

export const defaultDeathCertificate: DeathCertificate = {};
export const defaultDeathCertification: DeathCertification = {
    deathCertificate: defaultDeathCertificate,
    vopd: {},
};

export const deathCertificateReducer: Reducer = (
    state: Nullable<DeathClaimDetails> = null,
    action: AnyAction
) => {
    return state;
};

export const funeralDeathCertificationReducer = combineReducersWithDefault<DeathCertification>(
    defaultDeathCertification,
    {
        deathCertificate: deathCertificateReducer,
        vopd: vopdReducer,
    }
);

export function getDeathCertification(claim: Claim) {
    return getPropertyFromClaim(claim, 'deathCertification', defaultDeathCertification);
}

export function getDeathCertificateFromClaim(claim: Claim) {
    return getDeathCertification(claim).deathCertificate || defaultDeathCertificate;
}

export function getDeathCertificate(state: State) {
    return getDeathCertificateFromClaim(getClaim(state));
}
