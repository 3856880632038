import { Grid, Paper, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { FormHeading, FullWidthDatePicker, FullWidthTextField } from '../../../../../../forms';
import { requiredValidator } from '../../../../../../forms/validations';
import styles, { StyledComponent } from '../../../../../../styles/styles';

const doctorsConsultationEdit: React.FunctionComponent<StyledComponent> = ({
    classes,
}: StyledComponent) => {
    return (
        <React.Fragment>
            <Paper className={classes.paper}>
                <FormHeading text="Edit reason for doctors visit" />
                <Grid container spacing={5} justify="flex-start">
                    <Grid item xs={6}>
                        <FullWidthDatePicker
                            label="Date of attendance"
                            name="date"
                            validate={requiredValidator}
                            disableFuture
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            multiline
                            label="Reason"
                            name="reason"
                            validate={requiredValidator}
                            multiple
                        />
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

export const DoctorsConsultationEdit = withStyles(styles)(doctorsConsultationEdit);
