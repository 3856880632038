import React from 'react'; // eslint-disable-line
import { TextField as MuiTextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { FieldRenderProps } from 'react-final-form';
import MaskedInput, { maskArray } from 'react-text-mask';
import { callAllFunctions, getComponentId, nameToLabel, processMetaForErrors } from '../../';

export type TextFieldTypes =
    | 'text'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'month'
    | 'number'
    | 'range'
    | 'search'
    | 'tel'
    | 'time'
    | 'url'
    | 'week';

export interface MaskedInputProps {
    mask?: maskArray | ((value: string) => maskArray);
    guide?: boolean;
    placeholderChar?: string;
    keepCharPositions?: boolean;
    pipe?: (
        conformedValue: string,
        config: any
    ) => false | string | { value: string; indexesOfPipedChars: number[] };
    showMask?: boolean;
}
type Props = TextFieldProps &
    FieldRenderProps<any, any> &
    MaskedInputProps & {
        id?: string;
        label?: React.ReactNode;
        hideLabel?: React.ReactNode;
        fullLabel?: React.ReactNode;
        helperText?: React.ReactNode;
        type?: TextFieldTypes;
        onChange?: (values: Record<string, any>) => void;
    };

export const TextField: React.FunctionComponent<Props> = ({
    id,
    input,
    meta,
    label,
    hideLabel,
    fullLabel,
    helperText,
    onChange,
    mask,
    guide,
    placeholderChar,
    keepCharPositions,
    pipe,
    showMask,
    inputProps,
    InputProps,
    ...rest
}: Props) => {
    const { name, value, ...restInput } = input;
    const { errorMessage, showError } = processMetaForErrors(meta);
    const inputLabelProps = { htmlFor: name };
    // Only use MaskedInput component internally if a Mask is supplied
    if (!!mask) {
        InputProps = {
            ...InputProps,
            inputComponent: TextMask,
        };
    }

    if (rest.startadornment) {
        InputProps = {
            ...InputProps,
            startAdornment: rest.startadornment,
        };
    }

    if (rest.endadornment) {
        InputProps = {
            ...InputProps,
            endAdornment: rest.endadornment,
        };
    }

    const maskProps = !mask
        ? {}
        : {
              mask,
              guide,
              placeholderChar,
              keepCharPositions,
              pipe,
              showMask,
          };

    inputProps = {
        ...restInput,
        ...inputProps,
        ...maskProps,
    };

    return (
        <React.Fragment>
            <MuiTextField
                {...rest}
                id={getComponentId({ id, name })}
                label={hideLabel ? '' : fullLabel ? label : nameToLabel({ label, name })}
                InputLabelProps={inputLabelProps}
                name={name}
                helperText={showError ? errorMessage : helperText}
                // @ts-ignore
                FormHelperTextProps={{ component: 'pre' }}
                error={showError}
                InputProps={InputProps}
                inputProps={inputProps} // eslint-disable-line react/jsx-no-duplicate-props
                onChange={() => {
                    callAllFunctions(onChange, input.onChange);
                }}
                value={value}
            />
        </React.Fragment>
    );
};

type TextMaskProps = MaskedInputProps &
    any & {
        inputRef?: React.Ref<any> | React.RefObject<any>;
    };
const TextMask: React.FunctionComponent<TextMaskProps> = ({ inputRef, ...rest }: TextMaskProps) => {
    return (
        <MaskedInput
            {...rest}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
        />
    );
};
