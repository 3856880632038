import React from 'react'; // eslint-disable-line

import { Divider, Grid, Typography, withStyles } from '@material-ui/core';
import { Field } from 'react-final-form';
import { Checkbox, FieldSet } from '../../../../forms';
import { LifeAssuredContract } from '../../../../life-assured-details/reducer';
import { formatCurrency, getDisplayDate } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import { getPolicyAge } from './death-benefit-details';
import { FullWidthRichTextField } from '../../../../forms/form-components/full-width-rich-text-field';
type Props = StyledComponent & {
    contract: LifeAssuredContract;
    contractIndex: number;
};
const reassuranceDetailsView: React.FunctionComponent<Props> = ({
    classes,
    contract,
    contractIndex,
}: Props) => {
    if (contractIndex < 0) {
        return null;
    }
    const reassuranceBenefits = contract.benefits;
    return (
        <React.Fragment>
            <Typography variant="h5" className={classes.spacedTypography}>
                Edit Policy Details: {contract.contractNumber}
            </Typography>
            <Divider />
            {reassuranceBenefits &&
                reassuranceBenefits.map((benefit, benIndex) => {
                    const benefitIndex = contract.benefits ? contract.benefits.indexOf(benefit) : 0;
                    const paidName = `contracts[${contractIndex}].benefits[${benefitIndex}]`;
                    return (
                        <Grid
                            item
                            xs={12}
                            container
                            justify="flex-start"
                            key={`${benefit.name}${benIndex}`}
                        >
                            {benIndex > 0 ? (
                                <Grid item xs={12}>
                                    <Divider className={classes.marginTop} />
                                </Grid>
                            ) : (
                                ''
                            )}
                            <Grid item xs={12} className={classes.marginTop}>
                                <Typography variant="subtitle2">
                                    Benefit - {benefit.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} container justify="flex-start">
                                <Grid item xs={3} lg={3}>
                                    <Typography variant="subtitle2" className={classes.marginTop}>
                                        Commencement date
                                    </Typography>
                                    <Typography>{getDisplayDate(benefit.startDate)}</Typography>
                                </Grid>
                                <Grid item xs={3} lg={3}>
                                    <Typography variant="subtitle2" className={classes.marginTop}>
                                        Sum Assured
                                    </Typography>
                                    <Typography>{formatCurrency(benefit.benefitAmount)}</Typography>
                                </Grid>
                                <Grid item xs={3} lg={3}>
                                    <Typography variant="subtitle2" className={classes.marginTop}>
                                        Policy Age
                                    </Typography>
                                    <Typography>{getPolicyAge(benefit.startDate)}</Typography>
                                </Grid>
                                <Grid item xs={3} lg={3} style={{ padding: '15px' }}>
                                    <Typography variant="subtitle2" style={{ marginLeft: '-14px' }}>
                                        Paid
                                    </Typography>
                                    <Field
                                        name={`${paidName}.isBenefitPaid`}
                                        type="checkbox"
                                        render={({ input }) => {
                                            return (
                                                // @ts-ignore
                                                <Checkbox
                                                    input={input}
                                                    // @ts-ignore
                                                    style={{ padding: 0, marginLeft: '-5px' }}
                                                    hideLabel={true}
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            {benefit.reassuranceDetails && benefit.reassuranceDetails.length > 0 && (
                                <React.Fragment>
                                    <Grid item xs={3} lg={3}>
                                        <Typography
                                            variant="subtitle2"
                                            className={classes.marginTop}
                                        >
                                            Reinsurer
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} lg={3}>
                                        <Typography
                                            variant="subtitle2"
                                            className={classes.marginTop}
                                        >
                                            Sum Insured
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} lg={3}>
                                        <Typography
                                            variant="subtitle2"
                                            className={classes.marginTop}
                                        >
                                            Reinsurance Type
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} lg={3}>
                                        <Typography
                                            variant="subtitle2"
                                            className={classes.marginTop}
                                        >
                                            Refer to Reinsurer
                                        </Typography>
                                    </Grid>
                                    {benefit.reassuranceDetails &&
                                        benefit.reassuranceDetails.map((reassurance, raIndex) => {
                                            const reasuranceName = `contracts[${contractIndex}].benefits[${benefitIndex}].reassuranceDetails[${raIndex}]`;
                                            return (
                                                <React.Fragment key={`reassurance${raIndex}`}>
                                                    <Grid item xs={3} lg={3}>
                                                        <Typography>
                                                            {reassurance.reinsurerName}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} lg={3}>
                                                        <Typography>
                                                            {formatCurrency(
                                                                reassurance.sumReinsured
                                                            )}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} lg={3}>
                                                        <Typography>
                                                            {reassurance.reassuranceType}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} lg={3}>
                                                        <FieldSet
                                                            style={{
                                                                margin: 0,
                                                                marginLeft: '11px',
                                                            }}
                                                        >
                                                            <Field
                                                                name={`${reasuranceName}.referToReinsurer`}
                                                                type="checkbox"
                                                                render={({ input }) => {
                                                                    return (
                                                                        // @ts-ignore
                                                                        <Checkbox
                                                                            input={input}
                                                                            // @ts-ignore
                                                                            style={{ padding: 0 }}
                                                                            hideLabel={true}
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                        </FieldSet>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className={classes.marginTop}
                                                    >
                                                        <Typography variant="subtitle2">
                                                            {`Benefit terms & conditions`}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} lg={12}>
                                                        <FieldSet
                                                            style={{
                                                                margin: 0,
                                                                marginLeft: '2px',
                                                                marginRight: '2px',
                                                            }}
                                                        >
                                                            <Field
                                                                name={`${reasuranceName}.termsAndConditions`}
                                                                subscription={{ value: true }}
                                                                component={() => (
                                                                    <FullWidthRichTextField
                                                                        id={`termsAndConditions-${benefitIndex}`}
                                                                        name={`${reasuranceName}.termsAndConditions`}
                                                                        hideLabel
                                                                    />
                                                                )}
                                                            />
                                                        </FieldSet>
                                                    </Grid>
                                                </React.Fragment>
                                            );
                                        })}
                                </React.Fragment>
                            )}
                        </Grid>
                    );
                })}
        </React.Fragment>
    );
};
export const ReassuranceReferView = withStyles(styles)(reassuranceDetailsView);
