import React from 'react'; // eslint-disable-line

import { FormControl } from '@material-ui/core';
import { FormControlProps } from '@material-ui/core/FormControl';

interface Props {
    children?: React.ReactNode;
}

export const FieldSet: React.FunctionComponent<Props & FormControlProps & any> = ({
    children,
    ...rest
}: Props & FormControlProps & any) => {
    return (
        <FormControl component="fieldset" fullWidth margin="normal" {...rest}>
            {children}
        </FormControl>
    );
};
