import React from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Section } from '../../../../../../controls/section';
import { FullWidthDatePicker, FullWidthTextField } from '../../../../../../forms';
import { requiredValidator } from '../../../../../../forms/validations';
import { State } from '../../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../../styles/styles';

interface OwnProps {}

interface StateProps {}

interface DispatchProps {}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;

const underwritingReferralForm: React.FunctionComponent<Props> = () => {
    return (
        <Section title="Referral">
            <Grid item xs={6} lg={4}>
                <FullWidthDatePicker
                    name="date"
                    label="Date"
                    validate={requiredValidator}
                    disableFuture
                    minDateField="claimEventDate"
                />
            </Grid>
            <Grid item xs={6} lg={4}>
                <FullWidthDatePicker
                    name="claimEventDate"
                    label="Claim Event Date"
                    validate={requiredValidator}
                    disableFuture
                    maxDateField="date"
                />
            </Grid>
            <Grid item xs={12}>
                <FullWidthTextField
                    name="reason"
                    label="Reason"
                    validate={requiredValidator}
                    multiline
                />
            </Grid>
        </Section>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = () => ({});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {};

export const UnderwritingReferralForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(underwritingReferralForm));
