import React from 'react'; // eslint-disable-line

import { CheckboxProps } from '@material-ui/core/Checkbox';
import { Field } from 'react-final-form';
import { Checkbox, FieldSet } from '../../';

interface Props {
    name: string;
}

export const FullWidthCheckBoxField: React.FunctionComponent<Props & CheckboxProps & any> = ({
    name,
    ...rest
}: Props & CheckboxProps & any) => {
    return (
        <FieldSet>
            <Field {...rest} name={name} component={Checkbox} type="checkbox" />
        </FieldSet>
    );
};
