import React from 'react'; // eslint-disable-line

import { Typography, withStyles } from '@material-ui/core';
import styles, { StyledComponent } from '../../../../styles/styles';
import { PolicyAssessmentDetails } from '../../assessment-details-reducer';
import { BenefitAssessmentDetailsView } from './benefit-assessment-details';
import { AddAssessmentDecisionProps } from './assessment-decision-add';

interface OwnProps {
    policyAssessmentDetails: PolicyAssessmentDetails;
    policyIndex: number;
    assessmentDecisionAddView:
        | React.FunctionComponent<AddAssessmentDecisionProps>
        | React.ComponentClass<AddAssessmentDecisionProps>;
}

type Props = OwnProps & StyledComponent;
const policyAssessmentDetailsView: React.FunctionComponent<Props> = ({
    policyAssessmentDetails,
    policyIndex,
    assessmentDecisionAddView,
    classes,
}: Props) => {
    return (
        <React.Fragment>
            <Typography
                variant="subtitle2"
                className={`${classes.marginTop} ${classes.marginBottom}`}
            >
                Policy Number - {policyAssessmentDetails.policyNumber}
            </Typography>
            {policyAssessmentDetails.benefits &&
                policyAssessmentDetails.benefits.map((benefitAssessmentDetails, index) => (
                    <BenefitAssessmentDetailsView
                        key={benefitAssessmentDetails.benefitName}
                        benefitAssessmentDetails={benefitAssessmentDetails}
                        benefitIndex={index}
                        assessmentDecisionAddView={assessmentDecisionAddView}
                        policyIndex={policyIndex}
                        {...policyAssessmentDetails}
                    />
                ))}
        </React.Fragment>
    );
};

export const PolicyAssessmentDetailsView = withStyles(styles)(policyAssessmentDetailsView);
