import { Button, Grid, GridSize, Paper, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { Form, FormRenderProps } from 'react-final-form';
import { connect } from 'react-redux';
import {
    DeleteAndSubmitAdditionalInformationRequired,
    deleteAndSubmitAdditionalInformationRequiredThunk,
    UpdateAndSubmitAdditionalInformationRequired,
    updateAndSubmitAdditionalInformationRequiredThunk,
} from '../actions/submit-additional-information-required';
import { OpenDialog, openDialogAction } from '../../../../../confirmation-dialog';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { EditFormModal } from '../../../../../form-modal';
import { OpenEditModal, openEditModalAction } from '../../../../../form-modal/actions';
import { FullWidthCheckBoxField } from '../../../../../forms';
import { State } from '../../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { AdditionalInformationRequiredRecordDetailView } from './additional-information-required-record-detail';
import { AdditionalInformationRequiredWaiverDetailView } from './additional-information-required-waiver-detail';
import { AdditionalInformationRequiredRecord, getAdditionalInformationRequired } from '../reducer';

interface OwnProps {
    index: number;
    record: AdditionalInformationRequiredRecord;
}

interface StateProps {
    additionalInformationRequired: AdditionalInformationRequiredRecord[];
}

interface DispatchProps {
    updateRecord: UpdateAndSubmitAdditionalInformationRequired;
    deleteRecord: DeleteAndSubmitAdditionalInformationRequired;
    openEditModal: OpenEditModal;
    openDialog: OpenDialog;
}

type Props = DispatchProps & OwnProps & StateProps & StyledComponent;

const additionalInformationRequestedRecordView: React.FunctionComponent<Props> = ({
    additionalInformationRequired,
    classes,
    deleteRecord,
    index,
    openDialog,
    openEditModal,
    record,
    updateRecord,
}: Props) => {
    const documentName = 'Document ' + (index + 1);
    return (
        <Paper className={classes.paper} key={index}>
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={12} lg={12} className={classes.marginTop3}>
                    <Grid container justify="space-between">
                        <Grid item xs={8} lg={10}>
                            <Typography variant="subtitle2">{documentName}</Typography>
                        </Grid>
                        <Grid container item xs={4} lg={2} justify="flex-end">
                            {editAdditionalInformationButton(
                                additionalInformationRequired,
                                index,
                                updateRecord,
                                openEditModal
                            )}
                            {deleteAdditionalInformationButton(index, deleteRecord, openDialog)}
                        </Grid>
                    </Grid>
                </Grid>
                {item({
                    xs: 12,
                    lg: 12,
                    label: 'Information Requested',
                    value: record.detailsOfInformationRequested,
                })}
                {item({
                    xs: 12,
                    lg: 12,
                    label: 'Reasons for request',
                    value: record.reasonForRequest,
                })}
                {item({
                    xs: 12,
                    lg: 4,
                    label: 'Date Requested',
                    value: getDisplayDate(record.dateRequested),
                })}
                {item({
                    xs: 12,
                    lg: 4,
                    label: 'Date Received',
                    value: getDisplayDate(record.dateReceived),
                })}
                {item({
                    xs: 12,
                    lg: 4,
                    label: 'Assessed By',
                    value: record.assessedBy,
                })}
                {renderWaiver({ classes: classes, record: record })}
                <Grid item xs={12} lg={12} className={classes.marginTop3}>
                    <Form
                        // tslint:disable-next-line:no-empty
                        onSubmit={() => {}}
                        initialValues={{ sendToUnderwriter: record.sendToUnderwriter }}
                        render={() => (
                            <FullWidthCheckBoxField
                                name="sendToUnderwriter"
                                label="Send Information to Underwriting"
                                disabled
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </Paper>
    );

    interface WaiverProps extends StyledComponent {
        record: AdditionalInformationRequiredRecord;
    }

    function renderWaiver({ classes, record }: WaiverProps) {
        if (!record.waiver) {
            return '';
        }
        return (
            <React.Fragment>
                <Grid item xs={12} lg={12} className={classes.marginTop3}>
                    <Typography variant="subtitle2">Document Waived</Typography>
                </Grid>
                {item({
                    xs: 12,
                    lg: 4,
                    label: 'Date Waived',
                    value: getDisplayDate(record.waiver.dateWaived),
                })}
                {item({
                    xs: 12,
                    lg: 4,
                    label: 'Waived By',
                    value: record.waiver.waivedBy,
                })}
                {item({
                    xs: 12,
                    lg: 12,
                    label: 'Reason Waived',
                    value: record.waiver.reasonWaived,
                })}
            </React.Fragment>
        );
    }

    interface ItemProps {
        readonly xs: GridSize;
        readonly lg: GridSize;
        readonly label: React.ReactNode;
        readonly value: React.ReactNode;
    }

    function item({ xs, lg, label, value }: ItemProps) {
        return (
            <Grid item xs={xs} lg={lg} className={classes.marginTop3}>
                <LabeledValue label={label} value={value} />
            </Grid>
        );
    }
};

const AdditionalInformationRequiredRecordEditFormModal = EditFormModal<AdditionalInformationRequiredRecord>();
const editAdditionalInformationButton = (
    additionalInformationRecords: AdditionalInformationRequiredRecord[],
    recordIndex: number,
    updateRecord: UpdateAndSubmitAdditionalInformationRequired,
    openEditModal: OpenEditModal
) => {
    const openEditView = () => {
        openEditModal({
            modalContent: (
                <AdditionalInformationRequiredRecordEditFormModal
                    recordDetailView={detailView}
                    records={additionalInformationRecords}
                    updateRecord={record => updateRecord(recordIndex, record)}
                />
            ),
            modalData: {
                recordIndex,
            },
        });
    };

    return (
        <Tooltip title="Edit Additional Information Requested">
            <Button id="EditAdditionalInformation" color="secondary" onClick={openEditView}>
                <Edit />
            </Button>
        </Tooltip>
    );
};

const detailView: React.FunctionComponent<FormRenderProps> = (renderProps: FormRenderProps) => {
    return (
        <React.Fragment>
            <AdditionalInformationRequiredRecordDetailView
                heading="Edit Additional Information Requested"
                {...renderProps}
            />
            <AdditionalInformationRequiredWaiverDetailView {...renderProps} />
        </React.Fragment>
    );
};

const deleteAdditionalInformationButton = (
    recordIndex: number,
    deleteRecord: DeleteAndSubmitAdditionalInformationRequired,
    openDialog: OpenDialog
) => {
    const confirmDelete = () => {
        openDialog({
            content: {
                title: 'Delete Additional Information Requested Record',
                body:
                    'Are you sure you would like to delete the selected Additional Information Requested?',
            },
            yesAction: {
                action: () => deleteRecord(recordIndex),
            },
        });
    };

    return (
        <Tooltip title="Delete Additional Information Requested">
            <Button id="DeleteAdditionalInformation" color="secondary" onClick={confirmDelete}>
                <Delete />
            </Button>
        </Tooltip>
    );
};

const mapStateToProps = (state: State) => ({
    additionalInformationRequired: getAdditionalInformationRequired(state).records,
});

const mapDispatchToProps = {
    updateRecord: updateAndSubmitAdditionalInformationRequiredThunk,
    deleteRecord: deleteAndSubmitAdditionalInformationRequiredThunk,
    openEditModal: openEditModalAction,
    openDialog: openDialogAction,
};

export const AdditionalInformationRequestedRecordView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(additionalInformationRequestedRecordView));
