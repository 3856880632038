import { ReduxAction } from '../../redux/redux-actions';
import { WorkflowScreen } from '../reducers/workflow-screen';
import { ADD_SCREEN_TO_WORKFLOW } from '../types';

export type AddScreenToWorkflowAction = ReduxAction<WorkflowScreen>;
export type AddScreenToWorkflow = (newScreen: WorkflowScreen) => AddScreenToWorkflowAction;
export const addScreenToWorkflow: AddScreenToWorkflow = (newScreen: WorkflowScreen) => {
    if (!newScreen) {
        throw new Error('newScreen is a required parameter');
    }
    return {
        type: ADD_SCREEN_TO_WORKFLOW,
        payload: newScreen,
    };
};
