import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { formatCurrency } from '../../../shared-functions';
import styles, { StyledComponent } from '../../../styles/styles';
import { PaymentItem } from '../../reducer';
import { PaymentItemView } from './payment-item-view';

interface Props extends StyledComponent {
    beneficiaryId: string;
    paymentIndex: number;
    paymentItems: PaymentItem[];
}
const paymentItems: React.FunctionComponent<Props> = ({
    beneficiaryId,
    classes,
    paymentItems,
    paymentIndex,
}: Props) => {
    if (paymentItems.length === 0) {
        return null;
    }
    let totalPayable = 0;
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography variant="h6" className={classes.title}>
                    Payment Details
                </Typography>
            </Grid>
            {paymentItems.map((paymentItem, itemIndex) => {
                totalPayable += paymentItem.amountPayable;
                return (
                    <PaymentItemView
                        beneficiaryId={beneficiaryId}
                        itemIndex={itemIndex}
                        paymentIndex={paymentIndex}
                        paymentItem={paymentItem}
                        key={`item_${paymentItem.id}${itemIndex}`}
                    />
                );
            })}
            <Grid container justify="flex-end">
                <Grid item xs={3}>
                    <Typography className={classes.totalPayableLabel}>Total payable</Typography>
                    <Typography className={classes.totalPayableValue}>
                        {formatCurrency(totalPayable)}
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export const PaymentItemsView = withStyles(styles)(paymentItems);
