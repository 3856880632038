import React from 'react'; // eslint-disable-line
import { ButtonWrapper } from '../../../../../forms';
import { Button, Divider, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getRADeathClaim } from '../../../../../claim/reducers/claim-reducers/ra-death-claim-reducer';
import { OpenDialog, openDialogAction } from '../../../../../confirmation-dialog';
import { Section } from '../../../../../controls/section';
import { EditFormModal } from '../../../../../form-modal';
import { OpenEditModal, openEditModalAction } from '../../../../../form-modal/actions';
import { State } from '../../../../../redux/root-reducer';
import { getCodeDescription, getFullName, totalAllocation } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import {
    deleteAndSubmitDependantThunk,
    updateAndSubmitDependantThunk,
    DeleteAndSubmitDependant,
    UpdateAndSubmitDependant,
} from '../actions/submit-details';
import { Dependant, FinancialBreakdown } from '../reducer';
import { DependantViewExSpouse } from './dependant-view-ex-spouse';
import { DependantViewExpanded } from './dependant-view-expanded';
import { DependantViewOther } from './dependant-view-other';
import { DependantViewSpouse } from './dependant-view-spouse';
import { DependantViewToggle } from './dependant-view-toggle';
import { DependantEditView } from './edit-dependant-details';
import { EntityType } from '../../../../../shared-types';
import { RaDeathAssessmentDecisionView } from '../../../../decisions/ra-death/views/ra-death-assessment-percentage-split-view';
import {
    AddAndSubmitAssessmentDependentRecord,
    addAndSubmitAssessmentDependantRecordThunk,
} from '../../../../decisions/ra-death/actions/add-assessment-decision-split';
import {
    Decision,
    DecisionModel,
    getBlankAssessmentRecord,
    getRaDeathAssessmentDecisions,
} from '../../../../decisions/ra-death/ra-death-assessment-details-reducer';
import {
    openAddModalAction,
    OpenAddModal,
    CloseModal,
    closeModalAction,
} from '../../../../../form-modal/actions';
import { openBeneficiarySplitModal } from './dependants-beneficiary-split-modal';
import {
    addAndSubmitAssessmentDecisionRecordThunk,
    AddAndSubmitAssessmentDecisionRecord,
} from '../../../../decisions/ra-death/actions/add-assessment-decision';

interface OwnProps {}

interface StateProps {
    readonly dependants: Dependant[];
    readonly decisions: Decision[];
    readonly isReadOnly: boolean;
    decision: DecisionModel;
}

interface DispatchProps {
    deleteDependant: DeleteAndSubmitDependant;
    openDialog: OpenDialog;
    openEditModal: OpenEditModal;
    updateDependant: UpdateAndSubmitDependant;
    addAssessmentDecision: AddAndSubmitAssessmentDependentRecord;
    closeModal: CloseModal;
    openAddModal: OpenAddModal;
    updateAssessmentDecision: AddAndSubmitAssessmentDecisionRecord;
}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const DependantEditFormModal = EditFormModal<Dependant>();
const dependantsView: React.FunctionComponent<Props> = ({
    dependants,
    decisions,
    classes,
    deleteDependant,
    openDialog,
    openEditModal,
    updateDependant,
    isReadOnly,
    openAddModal,
    closeModal,
    decision,
    addAssessmentDecision,
    updateAssessmentDecision,
}: Props) => {
    if (dependants.length === 0) {
        return null;
    }

    return (
        <>
            <Section title={`Dependant Details`}>
                {dependants.map((dependant, index) => {
                    const breakdown =
                        (dependant.financialInformation &&
                            dependant.financialInformation.breakdown) ||
                        [];
                    return (
                        <React.Fragment key={`dependant-${index}`}>
                            {index > 0 ? (
                                <Grid item xs={12}>
                                    <Divider
                                        style={{ marginLeft: '-16px', marginRight: '-16px' }}
                                    />
                                </Grid>
                            ) : (
                                ''
                            )}
                            <Grid item xs={12} container spacing={0} justify="space-between">
                                {dependant.dependantType === EntityType.Organisation &&
                                    !!dependant.organisationDetails && (
                                        <Grid item xs={8} lg={10}>
                                            <Typography variant="h5">
                                                {getCodeDescription(
                                                    'relationship',
                                                    dependant.organisationDetails
                                                        .relationshipToLifeAssured
                                                )}{' '}
                                                |{' '}
                                                {getFullName(
                                                    dependant.organisationDetails.authorisedPerson
                                                        ? dependant.organisationDetails
                                                              .authorisedPerson.personalDetails
                                                        : dependant.personalDetails
                                                )}
                                            </Typography>
                                        </Grid>
                                    )}
                                {dependant.dependantType === EntityType.Individual && (
                                    <Grid item xs={8} lg={10}>
                                        <Typography variant="h5">
                                            {getCodeDescription(
                                                'relationship',
                                                dependant.relationship
                                            )}{' '}
                                            | {getFullName(dependant.personalDetails)}
                                        </Typography>
                                    </Grid>
                                )}
                                <ButtonWrapper>
                                    <Grid item xs={4} lg={2} className={classes.rightAlign}>
                                        <Tooltip title="Edit Dependant">
                                            <Button
                                                id="EditDependant"
                                                color="secondary"
                                                onClick={() => editDependant(index, breakdown)}
                                            >
                                                <Edit fontSize="small" />
                                            </Button>
                                        </Tooltip>
                                        <DeleteDependantButton
                                            index={index}
                                            deleteDependant={deleteDependant}
                                            openDialog={openDialog}
                                            openAddModal={openAddModal}
                                            decisions={decisions}
                                            dependants={dependants}
                                            closeModal={closeModal}
                                            updateAssessmentDecision={updateAssessmentDecision}
                                        />
                                    </Grid>
                                </ButtonWrapper>
                            </Grid>
                            {dependant.dependantType === EntityType.Individual && (
                                <DependantIndividual dependant={dependant} />
                            )}
                            {dependant.dependantType === EntityType.Organisation && (
                                <DependantEntity dependant={dependant} />
                            )}
                        </React.Fragment>
                    );
                })}
            </Section>

            <Section title={`Percentage Split`}>
                <React.Fragment>
                    <Grid
                        item
                        xs={12}
                        container
                        spacing={0}
                        justify="space-between"
                        style={{ marginBottom: 25 }}
                    >
                        <RaDeathAssessmentDecisionView isPercentageSplit={false} />
                    </Grid>
                </React.Fragment>
                {totalAllocation(decision) < 100 && (
                    <Grid container direction="row" justify="flex-end">
                        <Typography style={{ color: '#ff0000' }}>
                            The total percentage split must equal to 100%
                        </Typography>
                    </Grid>
                )}
            </Section>
        </>
    );

    function editDependant(index: number, breakdown: FinancialBreakdown[]) {
        const recordIndex = index || 0;
        openEditModal({
            modalContent: (
                <DependantEditFormModal
                    recordDetailView={<DependantEditView isEdit breakdown={breakdown} />}
                    records={dependants}
                    updateRecord={(record, index) => updateDependantDecision(index, record)}
                />
            ),
            modalData: {
                recordIndex,
            },
        });
    }
    async function updateDependantDecision(index: number, dependant: Dependant) {
        await updateDependant(index, dependant);
        await addAssessmentDecision(decision, dependant);
    }
};

interface DependantProps {
    dependant: Dependant;
}
const DependantIndividual: React.FunctionComponent<DependantProps> = ({
    dependant,
}: DependantProps) => {
    return (
        <DependantViewToggle
            expandedView={<DependantViewExpanded dependant={dependant} />}
            minimisedView={<DependantViewMinimised dependant={dependant} />}
        />
    );
};

const DependantViewMinimised: React.FunctionComponent<DependantProps> = ({
    dependant,
}: DependantProps) => {
    switch (dependant.relationship) {
        case 'Spouse':
        case 'EstrangedSpouse':
            return <DependantViewSpouse dependant={dependant} />;
        case 'ExSpouse':
            return <DependantViewExSpouse dependant={dependant} />;
        default:
            return <DependantViewOther dependant={dependant} />;
    }
};

const DependantEntity: React.FunctionComponent<DependantProps> = ({
    dependant,
}: DependantProps) => {
    return (
        <DependantViewToggle
            expandedView={<DependantViewExpanded dependant={dependant} />}
            minimisedView={<DependantViewMinimised dependant={dependant} />}
        />
    );
};

interface DeleteProps {
    index: number;
    deleteDependant: DeleteAndSubmitDependant;
    openDialog: OpenDialog;
    readonly decisions: Decision[];
    readonly dependants: Dependant[];
    closeModal: CloseModal;
    openAddModal: OpenAddModal;
    updateAssessmentDecision: AddAndSubmitAssessmentDecisionRecord;
}

const DeleteDependantButton: React.FunctionComponent<DeleteProps> = ({
    index,
    deleteDependant,
    openDialog,
    closeModal,
    openAddModal,
    decisions,
    dependants,
    updateAssessmentDecision,
}: DeleteProps) => {
    const deleteDependantAndOpenView = async () => {
        await deleteDependant(index);

        async function UpdatePercentageSplit(decisionModel: DecisionModel) {
            await updateAssessmentDecision(decisionModel);
        }

        const updateDecision: any = getBlankAssessmentRecord(decisions, dependants, 'currentUser');
        updateDecision.percentageSplits = updateDecision.percentageSplits.filter(
            (_x: any, i: any) => i !== index
        );
        openBeneficiarySplitModal(
            updateDecision,
            decisions,
            dependants,
            closeModal,
            openAddModal,
            UpdatePercentageSplit
        );
    };

    const confirmDelete = () => {
        openDialog({
            content: {
                title: 'Delete Dependant',
                body: 'Are you sure you would like to delete the selected Dependant?',
            },
            yesAction: {
                action: async () => {
                    await deleteDependantAndOpenView();
                },
            },
        });
    };

    return (
        <Tooltip title="Delete Dependant">
            <Button id="DeleteDependant" color="secondary" onClick={confirmDelete}>
                <Delete fontSize="small" />
            </Button>
        </Tooltip>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    dependants: getRADeathClaim(state).dependants || [],
    isReadOnly: state.claimSession.readOnly,
    decisions: getRaDeathAssessmentDecisions(state),
    decision: getBlankAssessmentRecord(
        getRADeathClaim(state).assessmentDetails.decisions,
        getRADeathClaim(state).dependants,
        state.auth?.name
    ),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    deleteDependant: (index: number) => dispatch(deleteAndSubmitDependantThunk(index)),
    openDialog: options => dispatch(openDialogAction(options)),
    openEditModal: options => dispatch(openEditModalAction(options)),
    updateDependant: (index: number, dependant: Dependant) =>
        dispatch(updateAndSubmitDependantThunk(index, dependant)),
    addAssessmentDecision: (record: any, dependant: Dependant) =>
        dispatch(addAndSubmitAssessmentDependantRecordThunk(record, dependant)),
    updateAssessmentDecision: (record: any) =>
        dispatch(addAndSubmitAssessmentDecisionRecordThunk(record)),
    openAddModal: options => dispatch(openAddModalAction(options)),
    closeModal: () => dispatch(closeModalAction()),
});

export const DependantsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(dependantsView));
