import React from 'react'; // eslint-disable-line
import { ModalViewProps, MultiEditFormModal } from '../../../../form-modal';
import { OpenModal } from '../../../../form-modal/actions';
import { ExpansionPanel } from '../../../../forms/form-components/expansion-panel/expansion-panel';
import { UpdateNoticeOfDeath } from '../../common/notice-of-death/actions/update-notice-of-death';
import { NoticeOfDeath } from '../../common/notice-of-death/notice-of-death-reducer';
import { EditDeceasedDetailsView } from '../../common/notice-of-death/views/edit-deceased-details-view';
import { EditDoctorIndunaDetails } from '../../common/notice-of-death/views/edit-doctor-induna-view';
import { EditFormInfoDetails } from '../../common/notice-of-death/views/edit-form-info-details';
import { EditFuneralParlourView } from '../../common/notice-of-death/views/edit-funeral-parlour-view';
import { EditInformantView } from '../../common/notice-of-death/views/edit-informant-view';
import { DeceasedDetailsView } from './deceased-details-view';
import { IndunaDoctorDetailsView } from './induna-doctor-details-view';
import { FormInfoDetailsView } from './form-info-details-view';
import { FuneralParlourDetailsView } from './funeral-parlour-view';
import { InformantDetailsView } from './informant-details-view';
import { ButtonCapture } from '../../../../forms';

interface Props {}

export const NoticeOfDeathView: React.FunctionComponent<Props> = () => {
    return (
        <ExpansionPanel name="Notice Of Death" secondaryLabel="(BI1663/BI1680)">
            <FormInfoDetailsView />
            <DeceasedDetailsView />
            <InformantDetailsView />
            <FuneralParlourDetailsView />
            <ButtonCapture>
                <IndunaDoctorDetailsView />
            </ButtonCapture>
        </ExpansionPanel>
    );
};

const EditModalForm = MultiEditFormModal<NoticeOfDeath>();
const EditModalViews: ModalViewProps[] = [
    { name: 'Form Details', view: <EditFormInfoDetails /> },
    { name: 'Deceased Details', view: <EditDeceasedDetailsView /> },
    { name: 'Informant Details', view: <EditInformantView /> },
    { name: 'Funeral Parlour details', view: <EditFuneralParlourView /> },
    { name: "Doctor's/Induna's Details", view: <EditDoctorIndunaDetails /> },
];
interface BaseProps {
    noticeOfDeath: NoticeOfDeath;
    openModal: OpenModal;
    updateNoticeOfDeath: UpdateNoticeOfDeath;
}

interface EditProps extends BaseProps {
    index: number;
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function editNoticeOfDeath({
    noticeOfDeath,
    index,
    openModal,
    updateNoticeOfDeath,
}: EditProps) {
    openModal({
        modalContent: (
            <EditModalForm
                heading="Update Notice Of Death"
                modalViews={EditModalViews}
                saveAction={updateNoticeOfDeath}
            />
        ),
        modalData: {
            screenIndex: index || 0,
            initialValues: noticeOfDeath,
        },
    });
}
