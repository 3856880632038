import React from 'react'; // eslint-disable-line

import { Button, Divider, Grid, Typography, withStyles } from '@material-ui/core';
import { AddCircle, Cached } from '@material-ui/icons';
import { connect } from 'react-redux';
import { OpenAddModal, openAddModalAction } from '../../../../form-modal/actions';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { RaiseLiability, raiseLiabilityAction } from '../../actions/raise-liability';
import { LiabilityStatusView } from '../../common/views/liability-status-view';
import { OpenDialog, openDialogAction } from '../../../../confirmation-dialog';
import { ERBPolicyAssessment } from '../../../../claim/reducers/claim-reducers/early-retirement-claim-reducer';
import { AddERBPolicyAssessmentDecisionProps } from './early-retirement-assessment-decision-add';
import {
    ClaimDetails,
    getClaimDetails,
    noCauseOfClaimDetails,
} from '../../../synopsis/common/claim-details/claim-details-reducer';
import { ERBAssessmentView } from './early-retirement-assessment';
import { ERBAssessmentSummary } from './assessment-summary';
import { AuthState } from '../../../../auth/auth-reducer';
import { ButtonWrapper } from '../../../../forms';

interface OwnProps {
    policyIndex: number;
    investmentPolicyAssessment: ERBPolicyAssessment;
    investmentAssessmentDecisionAddView:
        | React.FunctionComponent<AddERBPolicyAssessmentDecisionProps>
        | React.ComponentClass<AddERBPolicyAssessmentDecisionProps>;
}

interface StateProps {
    claimDetails?: ClaimDetails;
    isReadOnly: boolean;
    currentUser: AuthState;
    noCauseOfClaimDetails: boolean;
}

interface DispatchProps {
    openAddModal: OpenAddModal;
    raiseLiability: RaiseLiability;
    openDialog: OpenDialog;
}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const erbPolicyAssessmentDetailsView: React.FunctionComponent<Props> = ({
    classes,
    claimDetails,
    investmentPolicyAssessment,
    investmentAssessmentDecisionAddView: InvestmentAssessmentDecisionAddView,
    isReadOnly,
    openAddModal,
    openDialog,
    raiseLiability,
    currentUser,
    policyIndex,
    noCauseOfClaimDetails,
}: Props) => {
    const claimLiability = investmentPolicyAssessment.claimLiability || {};
    return (
        <React.Fragment>
            {investmentPolicyAssessment && (
                <React.Fragment key={investmentPolicyAssessment.policyNumber}>
                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle2"
                            className={`${classes.marginTop} ${classes.marginBottom}`}
                        >
                            Policy Number - {investmentPolicyAssessment.policyNumber}
                        </Typography>
                    </Grid>
                    <ERBAssessmentSummary
                        {...{
                            assessmentDetails: investmentPolicyAssessment,
                            currentUser,
                            index: 0,
                            policyIndex,
                        }}
                    />
                    <Divider />
                    <ERBAssessmentSummary
                        {...{
                            assessmentDetails: investmentPolicyAssessment,
                            currentUser,
                            index: 1,
                            policyIndex,
                        }}
                    />
                    <Divider />
                    <ButtonWrapper>
                        <Grid container item xs={12} justify="flex-end" style={{ paddingRight: 5 }}>
                            <Button
                                id={'Add_' + investmentPolicyAssessment.policyNumber}
                                color="secondary"
                                style={{ padding: 10, textTransform: 'none' }}
                                onClick={() => addDecision()}
                            >
                                <AddCircle />
                                Add decision
                            </Button>
                        </Grid>
                    </ButtonWrapper>
                    {switchOn() && (
                        <Grid container justify="space-between">
                            <Grid item container>
                                <Grid item xs={10}>
                                    <LiabilityStatusView claimLiability={claimLiability} />
                                </Grid>
                                <ButtonWrapper>
                                    {canRaiseLiability() && (
                                        <Grid
                                            container
                                            item
                                            xs={2}
                                            justify="flex-end"
                                            style={{ paddingRight: 5, marginTop: '-15px' }}
                                        >
                                            <Button
                                                id="Investment-raise-liability"
                                                color="secondary"
                                                style={{ padding: 10, textTransform: 'none' }}
                                                onClick={() =>
                                                    raiseLiability({
                                                        policyNumber:
                                                            investmentPolicyAssessment.policyNumber,
                                                        dateOfDeath:
                                                            claimDetails &&
                                                            claimDetails.dateOfEvent,
                                                    })
                                                }
                                            >
                                                <Cached />
                                                Raise liability
                                            </Button>
                                        </Grid>
                                    )}
                                </ButtonWrapper>
                            </Grid>
                        </Grid>
                    )}
                    <ERBAssessmentView {...investmentPolicyAssessment} />
                </React.Fragment>
            )}
        </React.Fragment>
    );

    function addDecision() {
        if (noCauseOfClaimDetails) {
            openDialog({
                content: {
                    title: 'Error adding decision',
                    body:
                        'Please capture claim catergory, claim condition, detailed claim condition, etc. in the claim event details section before adding a decision',
                },
            });

            return;
        }

        openAddModal({
            modalContent: (
                <InvestmentAssessmentDecisionAddView
                    assessmentDescription={
                        investmentPolicyAssessment.decisions &&
                        investmentPolicyAssessment.decisions.length > 0 &&
                        investmentPolicyAssessment.decisions.filter(d => !!d.final).length > 0
                            ? 'Reassessment'
                            : 'Assessment'
                    }
                    dateOfEvent={claimDetails && claimDetails.dateOfEvent}
                    causeOfClaim={claimDetails && claimDetails.currentCauseOfClaim}
                    {...investmentPolicyAssessment}
                />
            ),
            modalData: {},
        });
    }

    function canRaiseLiability() {
        return (
            investmentPolicyAssessment.policyNumber &&
            claimLiability.status !== 'Successful' &&
            claimDetails &&
            claimDetails.dateOfEvent
        );
    }

    function switchOn() {
        return true;
    }
};

const mapStateToProps = (state: State) => ({
    claimDetails: getClaimDetails(state),
    isReadOnly: state.claimSession.readOnly,
    currentUser: state.auth as AuthState,
    noCauseOfClaimDetails: noCauseOfClaimDetails(state),
});

const mapDispatchToProps = {
    openAddModal: openAddModalAction,
    raiseLiability: raiseLiabilityAction,
    openDialog: openDialogAction,
};

export const ERBPolicyAssessmentDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(erbPolicyAssessmentDetailsView));
