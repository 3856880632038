import { Button, Grid, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect, MapStateToProps } from 'react-redux';
import styles, { StyledComponent } from '../../styles/styles';
import {
    GetFormSubmissionState,
    GetWorkflowState,
    NavigateToNextScreen,
    navigateToNextScreenThunk,
} from '../actions/navigate-to-next-screen';
import { FormSubmissionState } from '../reducers/fom-submission-reducer';
import { WorkflowState } from '../reducers/workflow-reducer';
import { WorkflowUtilityScreen } from '../reducers/workflow-utility-screen';
import { WorkflowAssessorScreen } from '../reducers/workflow-assessor';

interface OwnProps {
    getWorkflowState: GetWorkflowState;
    getFormSubmissionState: GetFormSubmissionState;
}

interface StateProps {
    workflowState: WorkflowState;
    formSubmissionState: FormSubmissionState;
}

interface DispatchProps {
    navigateToNextScreen: NavigateToNextScreen;
}

type Props = StyledComponent & OwnProps & StateProps & DispatchProps;

class FormNavigationClass extends React.Component<Props> {
    public render() {
        const { classes }: Props = this.props;
        const hiddenStyle: React.CSSProperties = { visibility: 'hidden' };

        const onNextClick = async () => {
            await this.submit();
        };
        const onPrevClick = async () => {
            await this.movePrevious();
        };

        if (!this.isMovePreviousAvailable() && !this.isMoveNextAvailable()) {
            return null;
        }

        return (
            <Grid
                container
                spacing={0}
                justify="space-between"
                onKeyUpCapture={event => console.log(event)}
            >
                <Button
                    id="Back"
                    variant="contained"
                    className={classes.button}
                    onClick={onPrevClick}
                    disabled={!this.canMovePrevious()}
                    style={this.isMovePreviousAvailable() ? undefined : hiddenStyle}
                >
                    Back
                </Button>
                <Button
                    id="Next"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={onNextClick}
                    disabled={!this.canMoveNext()}
                    style={this.isMoveNextAvailable() ? undefined : hiddenStyle}
                >
                    Next
                </Button>
            </Grid>
        );
    }

    private moveNext() {
        return this.props.navigateToNextScreen(
            () => this.props.workflowState,
            () => this.props.formSubmissionState,
            'Forward'
        );
    }

    private movePrevious() {
        return this.props.navigateToNextScreen(
            () => this.props.workflowState,
            () => this.props.formSubmissionState,
            'Backward'
        );
    }

    private canMoveNext() {
        return !this.props.formSubmissionState.isSubmitting;
    }

    private isMoveNextAvailable() {
        if (!this.props.workflowState.activeWorkflow || !this.props.workflowState.activeScreen) {
            return false;
        }

        const currentScreenIndex = this.props.workflowState.activeWorkflow
            .descendants()
            .indexOf(this.props.workflowState.activeScreen);
        if (currentScreenIndex < 0) {
            throw new Error(
                `Current screen ${this.props.workflowState.activeScreen.path()} not found`
            );
        }

        const nextScreen = this.props.workflowState.activeWorkflow.descendants()[
            currentScreenIndex + 1
        ];

        return (
            !!nextScreen &&
            !(
                this.props.workflowState.activeScreen instanceof WorkflowUtilityScreen ||
                this.props.workflowState.activeScreen instanceof WorkflowAssessorScreen
            ) &&
            !(
                nextScreen instanceof WorkflowUtilityScreen ||
                nextScreen instanceof WorkflowAssessorScreen
            )
        );
    }

    private canMovePrevious() {
        return !this.props.formSubmissionState.isSubmitting;
    }

    private isMovePreviousAvailable() {
        if (!this.props.workflowState.activeWorkflow || !this.props.workflowState.activeScreen) {
            return false;
        }
        const currentScreenIndex = this.props.workflowState.activeWorkflow
            .descendantScreens()
            .indexOf(this.props.workflowState.activeScreen);

        const previousScreen = this.props.workflowState.activeWorkflow.descendantScreens()[
            currentScreenIndex - 1
        ];
        return (
            !!previousScreen &&
            !(
                this.props.workflowState.activeScreen instanceof WorkflowUtilityScreen ||
                this.props.workflowState.activeScreen instanceof WorkflowAssessorScreen
            ) &&
            !(
                previousScreen instanceof WorkflowUtilityScreen ||
                previousScreen instanceof WorkflowAssessorScreen
            )
        );
    }

    private async submit(): Promise<any> {
        if (!this.props.formSubmissionState.onSubmit) {
            this.moveNext();
            return;
        }

        this.props.formSubmissionState.onSubmit();
    }
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, any> = (
    state: any,
    props: OwnProps
) => ({
    workflowState: props.getWorkflowState(state),
    formSubmissionState: props.getFormSubmissionState(state),
});

const mapDispatchToProps = {
    navigateToNextScreen: navigateToNextScreenThunk,
};

export const FormNavigation = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(FormNavigationClass));
