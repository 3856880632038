import { createMuiTheme } from '@material-ui/core/styles';
import * as colours from './colours';
// See https://material-ui.com/customization/themes/ for info
const theme = createMuiTheme({
    overrides: {
        MuiListItemIcon: {
            root: {
                color: undefined,
            },
        },
        MuiFormHelperText: {
            root: {
                whiteSpace: 'pre-wrap',
            },
        },
        MuiGrid: {
            'spacing-xs-5': {
                marginTop: 0,
                marginBottom: 0,
                '& > $item': {
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            },
        },
    },
    palette: {
        type: 'light',
        common: {
            white: colours.white,
            black: colours.black,
        },
        primary: {
            main: colours.deepocean,
        },
        secondary: {
            main: colours.bluetang,
        },
        error: {
            main: colours.raspberry,
        },
    },
});
export default theme;
