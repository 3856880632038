import { Action } from 'redux';
import { DELETE_DOCTOR } from '../types';

export interface DeleteDoctorAction extends Action<string> {
    index: number;
}
export type DeleteDoctor = (index: number) => DeleteDoctorAction;
export const deleteDoctorAction: DeleteDoctor = (index: number) => {
    return {
        type: DELETE_DOCTOR,
        index,
    };
};
