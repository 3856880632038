import React from 'react'; // eslint-disable-line

import { CircularProgress, Grid, withStyles } from '@material-ui/core';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { LoadingScreenState } from '../loading-screen-reducer';
import { StyledComponent, styles } from './styles';

interface OwnProps {
    getLoadingScreenState: (state: any) => LoadingScreenState;
}

interface StateProps {
    isLoading?: boolean;
}

type Props = OwnProps & StateProps & StyledComponent;

class LoadingScreenViewClass extends React.Component<Props> {
    private focusRef: React.RefObject<HTMLInputElement>;
    public constructor(props: Props) {
        super(props);
        this.focusRef = React.createRef();
    }

    public componentDidUpdate() {
        if (this.focusRef.current) {
            this.focusRef.current.focus();
        }
    }

    public render() {
        const { isLoading, classes } = this.props;
        if (!isLoading) {
            return null;
        }

        return (
            <React.Fragment>
                <div className={classes.greyOverlay}>
                    <input ref={this.focusRef} style={{ opacity: 0 }} />
                </div>
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.clearOverlay}
                >
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, any> = (state, props) => ({
    isLoading: props.getLoadingScreenState(state).loadingCount > 0,
});

const mapDispatchToProps: MapDispatchToProps<Record<string, any>, OwnProps> = {};

export const LoadingScreenView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(LoadingScreenViewClass));
