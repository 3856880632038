import React from 'react'; // eslint-disable-line

import { Grid, withStyles, Tooltip, Button, Paper, Typography } from '@material-ui/core';
import { AddCircle, Edit } from '@material-ui/icons';
import { connect, MapDispatchToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AddFormModal, EditFormModal } from '../../../../../form-modal';
import { ExpansionPanel } from '../../../../../forms/form-components/expansion-panel/expansion-panel';
import { getMedicalHistory } from '../reducer';
import {
    OpenAddModal,
    openAddModalAction,
    OpenEditModal,
    openEditModalAction,
    OpenModal,
    openModalAction,
} from '../../../../../form-modal/actions';

import styles, { StyledComponent } from '../../../../../styles/styles';
import { State } from '../../../../../redux/root-reducer';
import { AuthState } from '../../../../../auth/auth-reducer';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { OpenDialog, openDialogAction } from '../../../../../confirmation-dialog';
import { ClaimNotesForm } from './claim-notes-form';
import {
    AddAndSubmitClaimNotes,
    addAndSubmitClaimNotesThunk,
    UpdateAndSubmitClaimNotesRecord,
    updateAndSubmitClaimNotesThunk,
} from '../action/add-claim-notes';
import { getDisplayDate } from '../../../../../shared-functions';
import { DeathClaimNotes } from '../../../death/claim-event-details/reducer';
import { ButtonWrapper } from '../../../../../forms';

interface DispatchProps {
    openAddModal: OpenAddModal;
    openModal: OpenModal;
    addClaimNotes: AddAndSubmitClaimNotes;
    openEditModal: OpenEditModal;
    openDialog: OpenDialog;
    updateClaimNotes: UpdateAndSubmitClaimNotesRecord;
}
interface OwnProps {}
type Props = StyledComponent &
    DispatchProps &
    OwnProps & {
        currentUser: AuthState;
        isReadOnly: boolean;
        claimNotes: DeathClaimNotes[];
    };
const ConsultationNotesAddFormModal = AddFormModal<DeathClaimNotes>();
const ClaimNotesEditFormModal = EditFormModal<DeathClaimNotes>();
const claimNotesView: React.FunctionComponent<Props> = ({
    classes,
    openAddModal,
    isReadOnly,
    addClaimNotes,
    claimNotes,
    updateClaimNotes,
    openEditModal,
}: Props) => {
    const openEditView = (consultationIndex: number) => {
        openEditModal({
            modalContent: (
                <ClaimNotesEditFormModal
                    recordDetailView={<ClaimNotesForm heading="Edit Claim Notes" />}
                    records={claimNotes}
                    updateRecord={(claimNotes, index) => updateClaimNotes(index, claimNotes)}
                />
            ),
            modalData: {
                recordIndex: consultationIndex,
            },
        });
    };
    return (
        <ExpansionPanel name="Tele Claim Notes" style={{ marginBottom: '14px' }}>
            <Grid container justify="space-between" className={classes.marginTop}>
                <Grid item xs={10} lg={11}>
                    <Typography variant="subtitle2">Tele Claim Notes</Typography>
                </Grid>
                <ButtonWrapper>
                    <Grid item xs={2} lg={1} className={classes.rightAlign}>
                        <Tooltip title="Add Consultation Notes">
                            <Button
                                id="AddConsultationNotes"
                                color="secondary"
                                onClick={() =>
                                    openAddModal({
                                        modalContent: (
                                            <ConsultationNotesAddFormModal
                                                recordDetailView={
                                                    <ClaimNotesForm heading="Add Claim Notes" />
                                                }
                                                addRecord={record =>
                                                    addClaimNotes({
                                                        ...record,
                                                    })
                                                }
                                            />
                                        ),
                                        modalData: {},
                                    })
                                }
                            >
                                <AddCircle />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            </Grid>
            {claimNotes &&
                claimNotes.length > 0 &&
                claimNotes.map((notes, notesIndex) => {
                    if (!notes.notesSummary) return null;

                    return (
                        <Paper className={classes.paper} key={notesIndex}>
                            <Grid item xs={12}>
                                <Grid container justify="flex-start">
                                    <Grid item xs={8}>
                                        <Typography style={{ marginTop: '6px' }} variant="h5">
                                            {getTeleClaimsSections(notesIndex)}
                                        </Typography>
                                    </Grid>
                                    <ButtonWrapper>
                                        <Grid item xs={4} className={classes.rightAlign}>
                                            <Tooltip title="Edit Claim Notes">
                                                <Button
                                                    id="EditClaimNote"
                                                    color="secondary"
                                                    onClick={() => openEditView(notesIndex)}
                                                >
                                                    <Edit />
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                    </ButtonWrapper>
                                </Grid>
                                <Grid item xs={12} container justify="flex-start">
                                    <Grid item xs={12}>
                                        <LabeledValue
                                            label="Last Date Updated"
                                            value={getDisplayDate(notes.dateCaptured)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ overflowWrap: 'break-word' }}>
                                        <LabeledValue
                                            label="Notes Summary"
                                            value={notes.notesSummary}
                                            isUnsafe
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    );
                })}
        </ExpansionPanel>
    );
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    /* eslint-disable-next-line */
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    addClaimNotes: record => dispatch(addAndSubmitClaimNotesThunk(record)),
    openModal: record => dispatch(openModalAction(record)),
    openAddModal: record => dispatch(openAddModalAction(record)),
    openEditModal: record => dispatch(openEditModalAction(record)),
    updateClaimNotes: (index: number, claimNotes: DeathClaimNotes) =>
        dispatch(updateAndSubmitClaimNotesThunk(index, claimNotes)),
    openDialog: options => dispatch(openDialogAction(options)),
});
const mapStateToProps = (state: State) => ({
    currentUser: state.auth as AuthState,
    isReadOnly: state.claimSession.readOnly,
    claimNotes: getMedicalHistory(state).claimNotes,
});
export const ClaimNotesView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(claimNotesView));

const getTeleClaimsSections = (index: number) => {
    switch (index) {
        case 0:
            return 'Welcome Notes';
        case 1:
            return 'Disclaimer Notes';
        case 2:
            return 'Screening Notes';
        case 3:
            return 'Process Notes';
        case 4:
            return 'Medical Questions Notes';
        case 5:
            return 'Current Treatment Notes';
        case 6:
            return 'Current Medical Condition Notes';
        case 7:
            return 'Past Medical Condition(s) Notes';
        case 8:
            return 'Medical Aid Question(s) Notes';
        case 9:
            return 'Banking Information Notes';
        case 10:
            return 'Information Record(s) Notes';
        case 11:
            return 'Declaration Notes';
        case 12:
            return 'Contact Details Notes';
    }
};
