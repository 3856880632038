import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import { FormApi } from 'final-form';
import React from 'react'; // eslint-disable-line
import {
    BindFieldToField,
    Condition,
    FullWidthDatePicker,
    FullWidthRadioGroup,
    FullWidthSearchSelectField,
    FullWidthTextField,
    RadioOption,
    True,
    WhenFieldChanges,
} from '../../../../../forms';
import { FirstNameMask, LastNameMask, NumberMask } from '../../../../../forms/input-masks';
import { AddressDetailsSubForm } from '../../../../../forms/sub-forms/address-details';
import {
    composeValidators,
    conditionalRequired,
    maximumNumber,
    requiredValidator,
    rsaIdNumberValidator,
} from '../../../../../forms/validations';
import { defaultDateOfBirth } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { NoticeOfDeath } from '../notice-of-death-reducer';
import {
    DeathCertificate,
    getDeathCertificate,
} from '../../../funeral/death-certification/death-certification-reducer';
import { FormSpy } from 'react-final-form';
import { FormDetails, getFormDetails } from '../../../funeral/form-details/form-details-reducer';
import { OnSubmitSuccessProps } from '../../../../../workflow-navigation/actions/submit-form';
import { connect } from 'react-redux';
import { State } from '../../../../../redux/root-reducer';

interface Props extends OnSubmitSuccessProps, StyledComponent {
    readonly deathCertificate: DeathCertificate;
    readonly formDetails: FormDetails;
    readonly isDeath?: boolean;
}

const DeceasedDetailsModal: React.FunctionComponent<Props> = ({
    classes,
    deathCertificate,
    formDetails,
    isDeath,
    ...rest
}: Props) => {
    return <InnerForm {...{ classes, formDetails, deathCertificate, isDeath }} />;
};

type InnerFormProps = StyledComponent & {
    formDetails: FormDetails;
    deathCertificate: DeathCertificate;
    isDeath?: boolean;
};
const InnerForm: React.FunctionComponent<InnerFormProps> = ({
    formDetails,
    isDeath,
    deathCertificate,
    classes,
}) => {
    return FormSpy<NoticeOfDeath>({
        render: ({ form }) =>
            deceasedDetailsEditView({
                classes,
                form,
                formDetails,
                deathCertificate,
                isDeath,
            }),
    });
};

type InnerProps = InnerFormProps & {
    form: FormApi<NoticeOfDeath>;
};

const deceasedDetailsEditView: React.FunctionComponent<InnerProps> = ({
    classes,
    form,
    formDetails,
    deathCertificate,
    isDeath,
}: InnerProps) => {
    const fullPrefix = 'deceased.';
    const addressType = isDeath ? 'residential' : 'street';
    const currentDeceasedDetails = form.getState().values.deceased || {};
    function populateFromFormDetails() {
        if (!!form) {
            const detailsOfDeath = getDetailsOfDeath(formDetails);
            form.batch(() => {
                form.change('deceased', {
                    ...currentDeceasedDetails,
                    ...formDetails.deceasedDetails,
                    personalDetails: {
                        ...currentDeceasedDetails.personalDetails,
                        ...formDetails.deceasedDetails,
                    },
                    dateOfDeath: detailsOfDeath.dateOfDeath,
                    placeOfDeath: detailsOfDeath.placeOfDeath,
                    causeOfDeath: getCauseOfDeath(detailsOfDeath.typeOfDeath),
                });
            });
        }
    }

    function populateFromDeathCertificate() {
        if (!!form) {
            form.batch(() => {
                form.change('deceased', {
                    ...currentDeceasedDetails,
                    personalDetails: {
                        ...currentDeceasedDetails.personalDetails,
                        ...deathCertificate,
                    },
                    dateOfDeath: deathCertificate.dateOfDeath,
                    placeOfDeath: deathCertificate.placeOfDeath,
                    causeOfDeath: deathCertificate.causeOfDeath,
                });
            });
        }
    }

    return (
        <Grid container spacing={5} justify="flex-start">
            {!isDeath && (
                <Grid item xs={12} container justify="flex-end">
                    <Button onClick={populateFromFormDetails} className={classes.copyButton}>
                        <FileCopy className={classes.copyIcon} />
                        Copy from form details
                    </Button>
                    <Button onClick={populateFromDeathCertificate} className={classes.copyButton}>
                        <FileCopy className={classes.copyIcon} />
                        Copy from death certificate
                    </Button>
                </Grid>
            )}
            <StillBornDetails fullPrefix={fullPrefix} isDeath={isDeath} />
            <Grid item xs={6}>
                <FullWidthRadioGroup
                    name={`${fullPrefix}medicalReport`}
                    label="Medical Report Received"
                    validate={requiredValidator}
                >
                    <RadioOption value="Yes" />
                    <RadioOption value="No" />
                </FullWidthRadioGroup>
            </Grid>
            <Grid item xs={6}>
                <FullWidthRadioGroup
                    name={`${fullPrefix}bodyIdentifiable`}
                    validate={conditionalRequired(isNotStillBirth)}
                >
                    <RadioOption value="Yes" />
                    <RadioOption value="No" />
                </FullWidthRadioGroup>
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${fullPrefix}personalDetails.firstName`}
                    validate={conditionalRequired(isNotStillBirth)}
                    mask={FirstNameMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${fullPrefix}personalDetails.secondName`}
                    mask={FirstNameMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${fullPrefix}personalDetails.lastName`}
                    label="Surname"
                    validate={requiredValidator}
                    mask={LastNameMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${fullPrefix}personalDetails.idNumber`}
                    label="ID Number"
                    validate={composeValidators(
                        conditionalRequired(isNotStillBirth),
                        rsaIdNumberValidator
                    )}
                    mask={NumberMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthDatePicker
                    name={`${fullPrefix}personalDetails.dateOfBirth`}
                    validate={requiredValidator}
                    disableFuture
                />
                <BindFieldToField
                    field={`${fullPrefix}personalDetails.dateOfBirth`}
                    toField={`${fullPrefix}personalDetails.idNumber`}
                    transform={defaultDateOfBirth}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthDatePicker
                    name={`${fullPrefix}dateOfDeath`}
                    validate={requiredValidator}
                    disableFuture
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${fullPrefix}placeOfDeath`}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${fullPrefix}placeOfDeathReg`}
                    label="Place Of Death Registration"
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    name={`${fullPrefix}causeOfDeath`}
                    options={[
                        { value: 'Natural Causes', label: 'Natural Causes' },
                        { value: 'Unnatural Causes', label: 'Unnatural Causes' },
                        { value: 'Under Investigation', label: 'Under Investigation' },
                    ]}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={`${fullPrefix}naturalDeath`}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField name={`${fullPrefix}hospital`} />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField name={`${fullPrefix}policeReport`} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.marginTop}>
                    Residential Address
                </Typography>
            </Grid>
            <AddressDetailsSubForm
                addressPropertyName={`${fullPrefix}addressDetails`}
                addressType={addressType}
            />
        </Grid>
    );
};

interface StillBornProps {
    fullPrefix: string;
    isDeath?: boolean;
}
const StillBornDetails = ({ fullPrefix, isDeath }: StillBornProps) => {
    if (isDeath) {
        return null;
    }
    return (
        <React.Fragment>
            <Grid item xs={6}>
                <FullWidthRadioGroup name={`${fullPrefix}stillBirth`} validate={requiredValidator}>
                    <RadioOption value="Yes" />
                    <RadioOption value="No" />
                </FullWidthRadioGroup>
                <WhenFieldChanges
                    field={`${fullPrefix}stillBirth`}
                    becomes="No"
                    set={`${fullPrefix}foetusAge`}
                    to={null}
                />
            </Grid>
            <Condition when={`${fullPrefix}stillBirth`} is="Yes">
                <True>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            name={`${fullPrefix}foetusAge`}
                            label="Foetus age (weeks)"
                            validate={composeValidators(
                                requiredValidator,
                                maximumNumber(42, 'Age in weeks should not exceed')
                            )}
                            mask={NumberMask}
                        />
                    </Grid>
                </True>
            </Condition>
        </React.Fragment>
    );
};

export function isNotStillBirth(values: any) {
    return !(values && values.deceased && values.deceased.stillBirth === 'Yes');
}

function getDetailsOfDeath(formDetails: FormDetails) {
    if (formDetails.deceasedDetails.detailsOfDeath) {
        return formDetails.deceasedDetails.detailsOfDeath;
    }
    return {};
}

function getCauseOfDeath(causeOfDeath?: string) {
    switch (causeOfDeath) {
        case 'Natural':
            return 'Natural Causes';
        case 'Unnatural':
            return 'Unnatural Causes';
        default:
            return undefined;
    }
}

const mapStateToProps = (state: State) => ({
    deathCertificate: getDeathCertificate(state),
    formDetails: getFormDetails(state),
});

const mapDispatchToProps = {};

export const EditDeceasedDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(DeceasedDetailsModal));
