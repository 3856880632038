import { Grid, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import {
    Condition,
    FullWidthCheckBoxField,
    FullWidthDatePicker,
    FullWidthTextField,
    HasValue,
    MultiCondition,
    True,
    WhenFieldChanges,
} from '../../../../../forms';
import { requiredValidator } from '../../../../../forms/validations';
import styles, { StyledComponent } from '../../../../../styles/styles';

const waiverView: React.FunctionComponent<StyledComponent> = () => {
    return (
        <React.Fragment>
            <WaiverCheckBox />
            <Condition when="hasWaiver">
                <True>
                    <Grid container spacing={5}>
                        <Grid item lg={6}>
                            <FullWidthDatePicker
                                id="dateWaived"
                                name="waiver.dateWaived"
                                label="Date Waived"
                                validate={requiredValidator}
                                minDateField="dateRequested"
                                disableFuture
                            />
                        </Grid>
                        <Grid item lg={6}>
                            <FullWidthTextField
                                id="waivedBy"
                                name="waiver.waivedBy"
                                label="Waived By"
                                validate={requiredValidator}
                            />
                        </Grid>
                        <Grid item lg={6}>
                            <FullWidthTextField
                                id="reasonWaived"
                                name="waiver.reasonWaived"
                                label="Reason Waived"
                                multiline
                                validate={requiredValidator}
                            />
                        </Grid>
                    </Grid>
                </True>
            </Condition>
        </React.Fragment>
    );
};

const WaiverCheckBox = () => {
    return (
        <MultiCondition
            fieldChecks={[
                {
                    fieldName: 'detailsOfInformationRequested',
                    check: HasValue,
                },
                {
                    fieldName: 'reasonForRequest',
                    check: HasValue,
                },
                {
                    fieldName: 'dateRequested',
                    check: HasValue,
                },
                {
                    fieldName: 'assessedBy',
                    check: HasValue,
                },
                {
                    fieldName: 'dateReceived',
                    check: dr => !dr,
                },
            ]}
        >
            <FullWidthCheckBoxField
                name="hasWaiver"
                id="hasWaiver"
                label="Waive Information Requested"
            />
            <WhenFieldChanges field="hasWaiver" becomes={false} set="waiver" to={null} />
        </MultiCondition>
    );
};

export const AdditionalInformationRequiredWaiverDetailView = withStyles(styles)(waiverView);
