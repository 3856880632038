import { Grid } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { ReasonForRequest, RequirementReasons } from '../../../../../codes/reason-for-request';
import {
    Condition,
    False,
    FormHeading,
    FullWidthRadioGroup,
    FullWidthSearchSelectField,
    RadioOption,
    True,
    WhenFieldChanges,
} from '../../../../../forms';
import { requiredValidator } from '../../../../../forms/validations';
import { RequiredDocument } from '../reducer';

interface Props {
    documents: RequiredDocument[];
    prefix: string;
    isDeath?: boolean;
    name?: string;
}

export const RequiredDocumentsEditView: React.FunctionComponent<Props> = ({
    documents,
    prefix,
    isDeath,
    name,
}: Props) => {
    const reasonOptions = isDeath ? ReasonForRequest : RequirementReasons;
    const noLongerRequired = isDeath ? 'No longer required' : 'NoLongerRequired';
    const headingName = name && name !== '' ? `${name}'s` : '';
    return (
        <React.Fragment>
            <FormHeading text={`Manage ${headingName} Requirements`} />
            <Grid container spacing={2} justify="flex-start">
                {documents.map((document, index) => {
                    const fullPrefix = `${prefix}[${index}]`;
                    return (
                        <React.Fragment key={index}>
                            <Grid item xs={6}>
                                <FullWidthRadioGroup
                                    name={`${fullPrefix}.status`}
                                    label={document.name}
                                    isDefaultCasing={true}
                                    id={`${fullPrefix}.status` + index}
                                >
                                    <RadioOption value="Received" />
                                    <RadioOption value="Verified" />
                                    <RadioOption value="Requested" />
                                    <RadioOption value="Waived" />
                                </FullWidthRadioGroup>
                                <WhenFieldChanges
                                    field={`${fullPrefix}.status`}
                                    becomes="Waived"
                                    set={`${fullPrefix}.reason`}
                                    to={noLongerRequired}
                                />
                                <WhenFieldChanges
                                    field={`${fullPrefix}.status`}
                                    condition={value => value !== 'Waived'}
                                    set={`${fullPrefix}.reason`}
                                    to={null}
                                />
                                <Condition when={`${fullPrefix}.status`} is="Requested">
                                    <True>
                                        <FullWidthSearchSelectField
                                            name={`${fullPrefix}.reason`}
                                            label="Status Reason"
                                            id={`${fullPrefix}.reason` + index}
                                            options={reasonOptions}
                                            validate={requiredValidator}
                                        />
                                    </True>
                                    <False>
                                        <FullWidthSearchSelectField
                                            name={`${fullPrefix}.reason`}
                                            label="Status Reason"
                                            id={`${fullPrefix}.reason` + index}
                                            options={reasonOptions}
                                            disabled
                                        />
                                    </False>
                                </Condition>
                            </Grid>
                        </React.Fragment>
                    );
                })}
            </Grid>
        </React.Fragment>
    );
};
