import React from 'react'; // eslint-disable-line

import { Field } from 'react-final-form';
import { nameToLabel, Radio } from '../../';

interface OptionProps {
    name?: string;
    value: string;
    label?: string;
}

export const RadioOption: React.FunctionComponent<OptionProps & any> = ({
    name,
    value,
    label,
    ...rest
}: OptionProps & any) => {
    return (
        <Field
            {...rest}
            name={name || ''}
            component={Radio}
            type="radio"
            value={value}
            label={nameToLabel({ label, name: value })}
        />
    );
};
