import { AnyAction } from 'redux';
import { getProperty } from '../../../../claim';
import { Nullable } from '../../../../shared-types';
import { State } from '../../../../redux/root-reducer';

export interface ForensicInput {
    readonly dateReferred?: Date;
    readonly dateFeedbackReceived?: Date;
    readonly dateResolved?: Date;
    readonly reasonForReferral?: string;
}

export const defaultForensicInput: ForensicInput = {};

export function forensicInputReducer(state: Nullable<ForensicInput> = null, action: AnyAction) {
    switch (action.type) {
        default:
            return state;
    }
}

export function getForensicInput(state: State): ForensicInput {
    return getProperty(state, 'forensicInput', defaultForensicInput);
}
