import React from 'react'; // eslint-disable-line

import { Divider, Grid, Typography, withStyles } from '@material-ui/core';
import { DocumentName } from '../../../../../codes/document-name';
import {
    Condition,
    FullWidthCheckBoxField,
    FullWidthDatePicker,
    FullWidthRadioGroup,
    FullWidthRichTextField,
    FullWidthSearchSelectField,
    FullWidthTextField,
    RadioOption,
    True,
    WhenFieldChanges,
} from '../../../../../forms';
import { conditionalRequired, requiredValidator } from '../../../../../forms/validations';
import styles, { StyledComponent } from '../../../../../styles/styles';
import AdditionalInformationRequiredRecordDetailView from './additional-information-required-record-detail';
import { SelfEmployedIncomeDetailView } from './self-employed-income-detail';
import { NonBusinessRelatedExpense } from '../reducer';
import { RetrenchmentClaimCalculatorView } from './retrenchment-claim-calculator';

interface RadioButtonProps {
    modalType: string;
}

type Props = StyledComponent & {
    heading: React.ReactNode;
    nonBusinessRelatedExpenses?: NonBusinessRelatedExpense[];
} & RadioButtonProps;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PassesFunction = (value: any) => boolean;

interface AddEditProp {
    selectionValue?: boolean;
    readonly passes?: PassesFunction;
}

const AddReceiveSelectionView: React.FunctionComponent<AddEditProp> = (props: AddEditProp) => {
    const { selectionValue } = props;

    return (
        <Grid item xs={6} lg={6}>
            <FullWidthRadioGroup
                label="Received or additional information?"
                name="receivedOrAdditional"
            >
                <RadioOption value="Received" disabled={selectionValue} />
                <RadioOption value="Additional" disabled={selectionValue} />
            </FullWidthRadioGroup>
        </Grid>
    );
};

const InformationReceivedRecordDetailView: React.FunctionComponent<Props> = ({
    classes,
    heading,
    modalType,
    nonBusinessRelatedExpenses,
}: Props) => {
    return (
        <React.Fragment>
            <Typography variant="h5" className={classes.spacedTypography}>
                {heading}
            </Typography>
            <Divider />
            <Grid container spacing={5}>
                <AddReceiveSelectionView selectionValue={modalType === 'Edit' ? true : false} />
                <Grid item xs={6} lg={6}>
                    <FullWidthTextField
                        id="assessor"
                        name="assessor"
                        label="Assessed by"
                        disabled
                    />
                </Grid>
                <WhenFieldChanges
                    field="receivedOrAdditional"
                    becomes="Received"
                    set="detailsOfInformationRequested"
                    to={null}
                />
                <WhenFieldChanges
                    field="receivedOrAdditional"
                    becomes="Received"
                    set="reasonForRequest"
                    to={null}
                />
                <WhenFieldChanges
                    field="receivedOrAdditional"
                    becomes="Received"
                    set="dateRequested"
                    to={null}
                />
                <WhenFieldChanges
                    field="receivedOrAdditional"
                    becomes="Received"
                    set="dateReceived"
                    to={null}
                />
                <WhenFieldChanges
                    field="receivedOrAdditional"
                    becomes="Additional"
                    set="documentID"
                    to={null}
                />
                <WhenFieldChanges
                    field="receivedOrAdditional"
                    becomes="Additional"
                    set="documentName"
                    to={null}
                />
                <WhenFieldChanges
                    field="receivedOrAdditional"
                    becomes="Additional"
                    set="documentDescription"
                    to={null}
                />
                <WhenFieldChanges
                    field="receivedOrAdditional"
                    becomes="Additional"
                    set="dateOfReport"
                    to={null}
                />
                <WhenFieldChanges
                    field="receivedOrAdditional"
                    becomes="Additional"
                    set="summary"
                    to={null}
                />
                <WhenFieldChanges
                    field="receivedOrAdditional"
                    becomes="Additional"
                    set="sendToUnderwriter"
                    to={null}
                />

                <Condition when="receivedOrAdditional" is="Received">
                    <True>
                        <Grid item xs={6} lg={6}>
                            <FullWidthTextField
                                id="documentID"
                                name="documentID"
                                validate={requiredValidator}
                                // eslint-disable-next-line
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <FullWidthSearchSelectField
                                id="documentName"
                                name="documentName"
                                validate={requiredValidator}
                                options={DocumentName}
                            />
                            <WhenFieldChanges
                                field="documentName"
                                set="documentDescription"
                                to={null}
                            />
                        </Grid>
                        <Condition when="documentName" is="Other">
                            <True>
                                <Grid item xs={12} lg={12}>
                                    <FullWidthTextField
                                        name="documentDescription"
                                        id="documentDescription"
                                        validate={conditionalRequired(
                                            values => values.documentName === 'Other'
                                        )}
                                    />
                                </Grid>
                            </True>
                        </Condition>
                        <Grid item xs={6} lg={6}>
                            <FullWidthDatePicker
                                id="dateOfReport"
                                name="dateOfReport"
                                validate={requiredValidator}
                                disableFuture
                            />
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <FullWidthDatePicker
                                id="dateOfSummary"
                                name="dateOfSummary"
                                validate={requiredValidator}
                                disabled
                            />
                        </Grid>
                        <Condition when="documentName" is="Proof of income">
                            <True>
                                <Grid item xs={12} lg={12}>
                                    <SelfEmployedIncomeDetailView
                                        nonBusinessRelatedExpenses={nonBusinessRelatedExpenses}
                                    />
                                </Grid>
                            </True>
                        </Condition>
                        <Condition when="documentName" is="Salary Slip">
                            <True>
                                <Grid item xs={12} lg={12}>
                                    <RetrenchmentClaimCalculatorView classes={classes} />
                                </Grid>
                            </True>
                        </Condition>
                        <Grid item xs={12} lg={12}>
                            <FullWidthRichTextField name="summary" validate={requiredValidator} />
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <FullWidthCheckBoxField
                                name="sendToUnderwriter"
                                label="Refer to Underwriter"
                            />
                        </Grid>
                    </True>
                </Condition>
                <Condition when="receivedOrAdditional" is="Additional">
                    <True>
                        <AdditionalInformationRequiredRecordDetailView />
                    </True>
                </Condition>
            </Grid>
        </React.Fragment>
    );
};

export default withStyles(styles)(InformationReceivedRecordDetailView);
