import * as types from '../types/assessors-types';
import { AnyAction } from 'redux';
import { Decision } from '../../assessment/decisions/ra-death/ra-death-assessment-details-reducer';

interface AssessorsLimits {
    canApproved: boolean;
    productType: string;
    amount: string;
    isDisable?: boolean;
}
export interface Assessors {
    reverse(): unknown;
    readonly _id: string;
    readonly id: string;
    readonly name: string;
    readonly surname: string;
    readonly status: string;
    readonly email: string;
    readonly assessorLimits: Array<AssessorsLimits>;
}

export interface AssessorDetails {
    policyNumber: string;
    benefitCode: string;
    benefitName: string;
    decisions: Decision;
    assessor: string;
    policyNumbers: Array<string>;
    decisionAction: string;
    approverName: string;
    requesterEmail: string;
    comment: string;
}

export interface ADUser {
    map(arg0: (user: any) => any): unknown;
    displayName: string;
    mail: string;
}
export interface ManagerComment {
    assessor?: string;
    comment?: string;
    date?: Date;
    id?: string;
}

export const initialAssessorLimitsState = {
    assessorLoaded: false,
    assessorUpdated: false,
    assessorLimits: [],
    adUsers: [],
};

type Action = AnyAction;

export function assessorLimitsReducer(state = initialAssessorLimitsState, action: Action) {
    switch (action.type) {
        case types.LOAD_ASSESSORS:
            return {
                ...state,
                assessorLimits: action.assessorLimits,
            };
        case types.LOAD_AD_USERS:
            return {
                ...state,
                adUsers: action.adUsers,
            };
        case types.UPDATE_ASSESSOR:
            return {
                ...state,
                assessorLimits: action.assessorLimits,
                assessorUpdated: true,
            };
        case types.EDIT_ASSESSOR:
            return {
                ...state,
                assessorUpdated: false,
            };
        case types.SAVE_ASSESSOR:
            return {
                ...state,
                assessorLimits: action.assessorLimits,
                assessorUpdated: true,
            };
        default:
            return state;
    }
}
