import React from 'react'; // eslint-disable-line

import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { Doctor } from '../../../../../doctor-details/reducer';
import { getLifeAssuredDetails } from '../../../../../life-assured-details/reducer';
import { State } from '../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { DoctorsView } from './doctors-view';

type Props = StyledComponent & {
    originalDoctorsDetails: Doctor[];
    heading?: string;
};

const originalDoctorsDetailsView: React.FunctionComponent<Props> = ({
    classes,
    heading,
    originalDoctorsDetails,
}: Props) => {
    if (!originalDoctorsDetails || originalDoctorsDetails.length === 0) {
        return null;
    }

    return (
        <Grid container className={classes.marginTop2}>
            <Grid container item xs={12} alignItems="center">
                <Typography variant="subtitle2">{`${
                    heading ? heading : 'Original Doctors Details'
                }`}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <DoctorsView classes={classes} doctors={originalDoctorsDetails} />
                </Paper>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state: State) => ({
    originalDoctorsDetails: getLifeAssuredDetails(state).originalDoctors,
});

const mapDispatchToProps = {};

export const OriginalDoctorsDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(originalDoctorsDetailsView));
