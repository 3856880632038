import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import {
    getAssessmentDetails,
    PolicyAssessmentDetails,
} from '../../assessment/decisions/assessment-details-reducer';
import { State } from '../../redux/root-reducer';
import {
    ChangeFieldSet,
    changeRecordFromAddChangeSet,
    changeRecordFromChangeSet,
} from '../claim-audit-trail-functions';
import {
    addAuditTrailDetailsView,
    AuditTrailDetails,
} from '../common-views/claim-audit-trail-field-details';
import { mapSectionView } from '../common-views/claim-audit-trail-wrapper';

interface Props {
    policyAssessmentDecisions: PolicyAssessmentDetails[];
    changeSet: Record<string, any>;
}

const auditTrailAssessmentView: React.FunctionComponent<Props> = ({
    policyAssessmentDecisions,
    changeSet,
}: Props) => {
    const returnView: React.ReactNode[] = [];
    if (changeSet.hasOwnProperty('decisions')) {
        Object.keys(changeSet['decisions']).forEach(index => {
            if (!isNaN(parseInt(index))) {
                Object.keys(changeSet['decisions'][index]).forEach(decisionKey => {
                    if (!isNaN(parseInt(decisionKey))) {
                        const record = changeSet['decisions'][index][decisionKey];
                        const recommendationRecord = record['recommendations'];
                        delete record['recommendations'];
                        addAuditTrailDetailsView(returnView, changeRecordFromAddChangeSet(record), [
                            'Decisions',
                        ]);
                        if (recommendationRecord) {
                            Object.keys(recommendationRecord).forEach((recKey, index: number) => {
                                if (!isNaN(parseInt(recKey))) {
                                    const recommendations = recommendationRecord[recKey];
                                    if (
                                        recommendations &&
                                        recommendations.hasOwnProperty('dependantId')
                                    ) {
                                        delete recommendations['dependantId'];
                                        addAuditTrailDetailsView(
                                            returnView,
                                            changeRecordFromAddChangeSet(recommendations),
                                            ['Recommendation - ' + (index + 1)]
                                        );
                                    }
                                }
                            });
                        }

                        return mapSectionView(returnView);
                    }
                });
            }
        });
    } else {
        returnView.push(getPolicyAssessmentDecision(changeSet, policyAssessmentDecisions));
    }

    return mapSectionView(returnView);
};

function getPolicyAssessmentDecision(
    changeRecord: Record<string, any>,
    policyAssessmentDecisions: PolicyAssessmentDetails[]
) {
    let records: ChangeFieldSet[] = [];
    let policyNumberIndex = -1;
    let benefitNumberIndex = -1;
    let assessmentDescription = 'Assessment';

    Object.keys(changeRecord).forEach(policyIndex => {
        Object.keys(changeRecord[policyIndex]).forEach(benefitsKey => {
            if (benefitsKey.toLowerCase() === 'benefits') {
                Object.keys(changeRecord[policyIndex][benefitsKey]).forEach(benefitIndex => {
                    if (!isNaN(Number(benefitIndex))) {
                        policyNumberIndex = Number(policyIndex);
                        benefitNumberIndex = Number(benefitIndex);
                        Object.keys(changeRecord[policyIndex][benefitsKey][benefitIndex]).forEach(
                            decisionsKey => {
                                const errorMessage = 'errorMessage';
                                const claimLiability = 'claimLiability';
                                if (decisionsKey.toLowerCase() === 'claimliability') {
                                    if (
                                        changeRecord[policyIndex][benefitsKey][benefitIndex][
                                            claimLiability
                                        ].hasOwnProperty(errorMessage)
                                    ) {
                                        changeRecord[policyIndex][benefitsKey][benefitIndex][
                                            claimLiability
                                        ]['message'] = [
                                            changeRecord[policyIndex][benefitsKey][benefitIndex][
                                                claimLiability
                                            ][errorMessage][0],
                                            changeRecord[policyIndex][benefitsKey][benefitIndex][
                                                claimLiability
                                            ][errorMessage][1],
                                        ];
                                        delete changeRecord[policyIndex][benefitsKey][benefitIndex][
                                            claimLiability
                                        ][errorMessage];
                                    }
                                    records = changeRecordFromChangeSet(
                                        changeRecord[policyIndex][benefitsKey][benefitIndex][
                                            'claimLiability'
                                        ]
                                    );
                                }
                                if (decisionsKey.toLowerCase() === 'decisions') {
                                    Object.keys(
                                        changeRecord[policyIndex][benefitsKey][benefitIndex][
                                            decisionsKey
                                        ]
                                    ).forEach(decisionIndex => {
                                        if (!isNaN(Number(decisionIndex))) {
                                            if (
                                                Array.isArray(
                                                    changeRecord[policyIndex][benefitsKey][
                                                        benefitIndex
                                                    ][decisionsKey][decisionIndex]
                                                )
                                            ) {
                                                assessmentDescription =
                                                    changeRecord[policyIndex][benefitsKey][
                                                        benefitIndex
                                                    ][decisionsKey][decisionIndex][0]
                                                        .assessmentType;
                                                records = changeRecordFromAddChangeSet(
                                                    changeRecord[policyIndex][benefitsKey][
                                                        benefitIndex
                                                    ][decisionsKey][decisionIndex][0]
                                                );
                                            } else {
                                                assessmentDescription =
                                                    changeRecord[policyIndex][benefitsKey][
                                                        benefitIndex
                                                    ][decisionsKey][decisionIndex].assessmentType;
                                                records = changeRecordFromChangeSet(
                                                    changeRecord[policyIndex][benefitsKey][
                                                        benefitIndex
                                                    ][decisionsKey][decisionIndex]
                                                );
                                            }
                                            if (!assessmentDescription) {
                                                assessmentDescription =
                                                    decisionIndex === '0'
                                                        ? 'Assessment'
                                                        : 'Reassessment';
                                            }
                                        }
                                    });
                                }
                                if (['opinion', 'summary'].includes(decisionsKey.toLowerCase())) {
                                    records = changeRecordFromChangeSet(
                                        changeRecord[policyIndex][benefitsKey][0]
                                    );
                                }
                            }
                        );
                    }
                });
            }
        });
    });

    const headings: string[] = getSectionHeadings(
        assessmentDescription,
        policyAssessmentDecisions,
        policyNumberIndex,
        benefitNumberIndex
    );

    return <AuditTrailDetails subSection={headings} auditTrailRecords={records} />;
}

function getSectionHeadings(
    assessmentDescription: string,
    policyAssessmentDecisions: PolicyAssessmentDetails[],
    policyNumberIndex: number,
    benefitNumberIndex: number
) {
    const headings: string[] = [];
    if (policyAssessmentDecisions[policyNumberIndex]) {
        headings.push(
            `Policy Number - ${policyAssessmentDecisions[policyNumberIndex].policyNumber}`
        );
    }
    if (
        policyAssessmentDecisions[policyNumberIndex] &&
        policyAssessmentDecisions[policyNumberIndex].benefits &&
        policyAssessmentDecisions[policyNumberIndex].benefits.length > 0 &&
        policyAssessmentDecisions[policyNumberIndex].benefits[benefitNumberIndex]
    ) {
        const benefitName =
            policyAssessmentDecisions[policyNumberIndex].benefits[benefitNumberIndex].benefitName;
        headings.push(`${assessmentDescription} for Benefit - ${benefitName}`);
    }
    return headings;
}

const mapStateToProps = (state: State) => ({
    policyAssessmentDecisions: getAssessmentDetails(state),
});

const mapDispatchToProps = {};

export const AuditTrailAssessmentView = connect(
    mapStateToProps,
    mapDispatchToProps
)(auditTrailAssessmentView);
