import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { DeathCertifiers } from '../../../../../../codes/death-certifiers';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import { ModalViewProps, MultiEditFormModal } from '../../../../../../form-modal';
import { OpenModal, openModalAction } from '../../../../../../form-modal/actions';
import { DisplayAddress } from '../../../../../../forms/sub-forms/address-details';
import { State } from '../../../../../../redux/root-reducer';
import { getCodeLabel } from '../../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { UpdateFormDetails, updateFormDetailsAction } from '../../actions/update-form-details';
import { FormDetails, getFormDetails } from '../../form-details-reducer';
import { FuneralParlourEdit } from '../funeral-parlour/edit-funeral-parlour';
import { DeclarationEdit } from './edit-declaration';
import { ButtonWrapper } from '../../../../../../forms/form-components/button-wrapper/index';

const EditModalForm = MultiEditFormModal<FormDetails>();
const EditModalViews: ModalViewProps[] = [
    { name: 'Funeral Parlour Details', view: <FuneralParlourEdit /> },
    { name: "Doctor's/Induna Details", view: <DeclarationEdit /> },
];

interface BaseProps {
    formDetails: FormDetails;
    openModal: OpenModal;
    updateFormDetails: UpdateFormDetails;
}

interface EditProps extends BaseProps {
    index: number;
}

interface Props extends BaseProps, StyledComponent {
    hasDeferredContracts: boolean;
}

const declaration = ({
    classes,
    formDetails,
    hasDeferredContracts,
    openModal,
    updateFormDetails,
}: Props) => {
    const declaration = formDetails.declaration || {};
    const addressDetails = declaration.addressDetails || {};

    const edit = () => {
        editDetails({ formDetails, index: 1, openModal, updateFormDetails });
    };
    return (
        <Grid item xs={12} container justify="flex-start" className={classes.marginTop}>
            <Grid item xs={10}>
                <Typography className={classes.heading} variant="body1">
                    Doctor&apos;s/Induna Details
                </Typography>
            </Grid>
            {!hasDeferredContracts && (
                <ButtonWrapper>
                    <Grid item xs={2} className={classes.rightAlign}>
                        <Tooltip title="Edit Certifier's Details">
                            <Button id="EditCertifierDetails" color="secondary" onClick={edit}>
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
            )}
            <Grid item xs={6} lg={3}>
                <LabeledValue
                    label="Certified By"
                    value={getCodeLabel(DeathCertifiers, declaration.certifiedBy)}
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="First Name" value={declaration.firstName} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Second Name" value={declaration.secondName} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Last Name" value={declaration.lastName} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="ID Number" value={declaration.idNumber} />
            </Grid>
            <Grid item xs={6} lg={3}>
                <LabeledValue label="Telephone Number" value={declaration.telephoneNumber} />
            </Grid>
            {declaration.certifiedBy === 'Doctor' && (
                <React.Fragment>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue label="Hospital Name" value={declaration.hospitalName} />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <LabeledValue
                            label="Hospital Contact Number"
                            value={declaration.hospitalContactNo}
                        />
                    </Grid>
                </React.Fragment>
            )}
            {addressDetails && (
                <Grid item xs={6} lg={3}>
                    <DisplayAddress label="Address Details" address={addressDetails} />
                </Grid>
            )}
        </Grid>
    );
};

export function editDetails({ formDetails, index, openModal, updateFormDetails }: EditProps) {
    openModal({
        modalContent: (
            <EditModalForm
                heading="Update Form Details"
                modalViews={EditModalViews}
                saveAction={updateFormDetails}
            />
        ),
        modalData: {
            screenIndex: index || 1,
            initialValues: formDetails,
        },
    });
}

const mapStateToProps = (state: State) => ({
    formDetails: getFormDetails(state),
    hasDeferredContracts: state.claimSession.hasDeferredContracts,
});

const mapDispatchToProps = {
    openModal: openModalAction,
    updateFormDetails: updateFormDetailsAction,
};

export const DeclarationView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(declaration));
