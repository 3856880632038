import { withStyles, Grid } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import styles, { StyledComponent } from '../../../../../styles/styles';
import { connect } from 'react-redux';
import { State } from '../../../../../redux/root-reducer';
import { FormDetails, getFormDetails } from '../form-details-reducer';
import {
    Condition,
    FullWidthDatePicker,
    FullWidthSearchSelectField,
    FullWidthTextField,
    BindFieldToField,
    False,
    True,
} from '../../../../../forms';
import { MaritalStatus } from '../../../../../codes/marital-statuses';
import { Titles } from '../../../../../codes/titles';
import { PolicyHolderTypes, YesOrNoOptions } from '../../../../../shared-types';
import { Gender } from '../../../../../codes/gender';
import { CountriesOfIssue } from '../../../../../codes/countries-of-issue';
import { requiredValidator } from '../../../../../forms/validations';
import { FirstNameMask, LastNameMask, NumberMask } from '../../../../../forms/input-masks';
import { defaultDateOfBirth } from '../../../../../shared-functions';

interface BaseProps {
    formDetails: FormDetails;
    type: string;
    prefix: string;
    policyHolderPrefix: string;
}

interface Props extends BaseProps, StyledComponent {}

const personalDetailsEdit: React.FunctionComponent<Props> = ({
    type,
    prefix,
    policyHolderPrefix,
    formDetails,
}: Props) => {
    return (
        <Grid container spacing={5} justify="flex-start">
            {type === 'PolicyHolder' && (
                <Grid item xs={6}>
                    <FullWidthSearchSelectField
                        name={policyHolderPrefix}
                        options={PolicyHolderTypes}
                    />
                </Grid>
            )}
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    label="Title"
                    name={type === 'LifeAssured' ? `${prefix}.lifeAssuredTitle` : `${prefix}.title`}
                    options={Titles}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    label="First Name"
                    name={
                        type === 'LifeAssured'
                            ? `${prefix}.lifeAssuredFirstName`
                            : `${prefix}.firstName`
                    }
                    validate={requiredValidator}
                    mask={FirstNameMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    label="Surname"
                    name={
                        type === 'LifeAssured'
                            ? `${prefix}.lifeAssuredSurname`
                            : `${prefix}.surname`
                    }
                    validate={requiredValidator}
                    mask={LastNameMask}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    name={
                        type === 'LifeAssured'
                            ? `${prefix}.maiden`
                            : `${prefix}.maidenName
`
                    }
                    mask={LastNameMask}
                />
            </Grid>

            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    label="Gender"
                    name={
                        type === 'LifeAssured' ? `${prefix}.lifeAssuredGender` : `${prefix}.gender`
                    }
                    options={Gender}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    label="Marital Status"
                    name={
                        type === 'LifeAssured'
                            ? `${prefix}.lifeAssuredMaritalStatus`
                            : `${prefix}.maritalStatus`
                    }
                    options={MaritalStatus}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <Condition when={`${prefix}.maritalStatus`} is="Married">
                    <True>
                        <FullWidthDatePicker
                            label="Date of Marriage"
                            name={`${prefix}.dateOfMarriage`}
                            validate={requiredValidator}
                        />
                    </True>
                </Condition>
                <Condition when={`${prefix}.lifeAssuredMaritalStatus`} is="Married">
                    <True>
                        <FullWidthDatePicker
                            label="Date of Marriage"
                            name={`${prefix}.lifeAssuredDateOfMarriage`}
                            validate={requiredValidator}
                        />
                    </True>
                </Condition>
                <Condition when={`${prefix}.maritalStatus`} is="Divorced">
                    <True>
                        <FullWidthDatePicker
                            label="Date of Divorce"
                            name={`${prefix}.dateOfDivorce`}
                            validate={requiredValidator}
                        />
                    </True>
                </Condition>
                <Condition when={`${prefix}.lifeAssuredMaritalStatus`} is="Divorced">
                    <True>
                        <FullWidthDatePicker
                            label="Date of Divorce"
                            name={`${prefix}.lifeAssuredDateOfDivorce`}
                            validate={requiredValidator}
                        />
                    </True>
                </Condition>
            </Grid>

            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    label="SA Resident"
                    fullLabel
                    name={
                        type === 'LifeAssured'
                            ? `${prefix}.lifeAssuredIsRSAResident`
                            : `${prefix}.isRSAResident`
                    }
                    options={YesOrNoOptions}
                    validate={requiredValidator}
                />
            </Grid>
            <Condition when={`${prefix}.isRSAResident`} is="Yes">
                <True>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            label="ID Number"
                            name={`${prefix}.idNumber` || `${prefix}.lifeAssuredIdNumber`}
                            validate={requiredValidator}
                        />
                    </Grid>
                </True>
            </Condition>
            <Condition when={`${prefix}.lifeAssuredIsRSAResident`} is="Yes">
                <True>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            label="ID Number"
                            name={`${prefix}.lifeAssuredIdNumber`}
                            validate={requiredValidator}
                        />
                    </Grid>
                </True>
            </Condition>
            <Condition when={`${prefix}.isRSAResident`} is="No">
                <True>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            label="Passport Number"
                            name={`${prefix}.PassportNumber`}
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthSearchSelectField
                            label="Country of Issue"
                            name={`${prefix}.countryOfIssue`}
                            options={CountriesOfIssue}
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthDatePicker
                            label="Date of Issue"
                            name={`${prefix}.dateOfIssue`}
                            validate={requiredValidator}
                            disableFuture
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthDatePicker
                            label="Date of Expiry"
                            name={`${prefix}.dateOfExpiry`}
                            validate={requiredValidator}
                        />
                    </Grid>
                </True>
            </Condition>
            <Condition when={`${prefix}.lifeAssuredIsRSAResident`} is="No">
                <True>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            label="Passport Number"
                            name={`${prefix}.lifeAssuredPassportNumber`}
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthSearchSelectField
                            label="Country of Issue"
                            name={`${prefix}.lifeAssureCountryOfIssue`}
                            options={CountriesOfIssue}
                            validate={requiredValidator}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthDatePicker
                            label="Date of Issue"
                            name={`${prefix}.lifeAssuredDateOfIssue`}
                            validate={requiredValidator}
                            disableFuture
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FullWidthDatePicker
                            label="Date of Expiry"
                            name={`${prefix}.lifeAssuredDateOfExpiry`}
                            validate={requiredValidator}
                        />
                    </Grid>
                </True>
            </Condition>
            <Grid item xs={6}>
                <FullWidthDatePicker
                    label="Date of Birth"
                    name={
                        type === 'LifeAssured'
                            ? `${prefix}.lifeAssuredDateOfBirth`
                            : `${prefix}.dateOfBirth`
                    }
                    validate={requiredValidator}
                />
                <Condition
                    when={
                        type === 'LifeAssured'
                            ? `${prefix}.lifeAssuredDateOfBirth`
                            : `${prefix}.dateOfBirth`
                    }
                    hasValue
                >
                    <False>
                        <BindFieldToField
                            field={
                                type === 'LifeAssured'
                                    ? `${prefix}.lifeAssuredDateOfBirth`
                                    : `${prefix}.dateOfBirth`
                            }
                            toField={
                                type === 'LifeAssured'
                                    ? `${prefix}.lifeAssuredIdNumber`
                                    : `${prefix}.idNumber`
                            }
                            transform={defaultDateOfBirth}
                        />
                    </False>
                </Condition>
            </Grid>
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    label="Country of Birth"
                    name={
                        type === 'LifeAssured'
                            ? `${prefix}.lifeAssuredCountryOfBirth`
                            : `${prefix}.countryOfBirth`
                    }
                    options={CountriesOfIssue}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    label="Place of Birth"
                    name={
                        type === 'LifeAssured'
                            ? `${prefix}.lifeAssuredPlaceOfBirth`
                            : `${prefix}.placeOfBirth`
                    }
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    label="Country of residence"
                    name={
                        type === 'LifeAssured'
                            ? `${prefix}.lifeAssuredCountryOfResidence`
                            : `${prefix}.countryOfResidence`
                    }
                    options={CountriesOfIssue}
                    validate={requiredValidator}
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField
                    label="SA Income tax number"
                    mask={NumberMask}
                    fullLabel
                    name={
                        type === 'LifeAssured'
                            ? `${prefix}.lifeAssuredIncomeTaxNumber`
                            : `${prefix}.incomeTaxNumber`
                    }
                />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state: State) => ({
    formDetails: getFormDetails(state),
});

const mapDispatchToProps = {};

export const PersonalDetailsEdit = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(personalDetailsEdit));
