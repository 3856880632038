import { FindClientsByPersonalDetailsCriteria } from './find-clients-by-personal-details';
import { FindClientsByPolicyCriteria } from './find-clients-by-policy';
import { SET_SEARCH_CRITERIA } from '../types';
import { ReduxAction } from '../../redux/redux-actions';

export type SearchByType = 'Life Assured' | 'Policy Number';

export interface ClientSearchCriteria
    extends FindClientsByPolicyCriteria,
        FindClientsByPersonalDetailsCriteria {
    searchBy: SearchByType;
}

export interface SetSearchCriteriaActionPayload {
    searchCriteria: ClientSearchCriteria;
}

export type SetSearchCriteriaAction = (
    searchCriteria: ClientSearchCriteria
) => ReduxAction<SetSearchCriteriaActionPayload>;
export const setSearchCriteriaAction: SetSearchCriteriaAction = (
    searchCriteria: ClientSearchCriteria
) => ({
    type: SET_SEARCH_CRITERIA,
    payload: { searchCriteria },
});
