import { Button, Grid, withStyles } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import { FormApi } from 'final-form';
import React from 'react'; // eslint-disable-line
import { Field, FormSpy } from 'react-final-form';
import { connect } from 'react-redux';
import { NaturalCauseOfDeath, UnnaturalCauseOfDeath } from '../../../../../../codes/cause-of-claim';
import {
    Condition,
    FullWidthDatePicker,
    FullWidthRadioGroup,
    FullWidthSearchSelectField,
    FullWidthTextField,
    RadioOption,
    True,
    WhenFieldChanges,
} from '../../../../../../forms';
import { requiredValidator } from '../../../../../../forms/validations';
import { State } from '../../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import {
    getNoticeOfDeath,
    NoticeOfDeath,
} from '../../../../common/notice-of-death/notice-of-death-reducer';
import {
    DeathCertificate,
    getDeathCertificate,
} from '../../../death-certification/death-certification-reducer';
import { FormDetails } from '../../form-details-reducer';

interface Props extends StyledComponent {
    readonly deathCertificate: DeathCertificate;
    readonly noticeOfDeath: NoticeOfDeath;
}

interface InnerProps extends Props {
    readonly form: FormApi<FormDetails>;
}

const detailsOfDeath = ({ classes, deathCertificate, noticeOfDeath }: Props) => {
    return FormSpy<FormDetails>({
        render: ({ form }) =>
            detailsOfDeathForm({
                classes,
                form,
                noticeOfDeath,
                deathCertificate,
            }),
    });
};

const detailsOfDeathForm = ({ classes, deathCertificate, form, noticeOfDeath }: InnerProps) => {
    const prefix = 'deceasedDetails.detailsOfDeath.';
    const currentDeceasedDetails = form.getState().values.deceasedDetails;
    const currentDetailsOfDeath = currentDeceasedDetails.detailsOfDeath || {};
    function populateFromNoticeOfDeath() {
        if (!!form) {
            const deceased = noticeOfDeath.deceased || {};
            form.change('deceasedDetails', {
                ...currentDeceasedDetails,
                detailsOfDeath: {
                    ...currentDetailsOfDeath,
                    dateOfDeath: deceased.dateOfDeath,
                    placeOfDeath: deceased.placeOfDeath,
                    typeOfDeath: getTypeOfDeath(deceased.causeOfDeath),
                },
            });
        }
    }

    function populateFromDeathCertificate() {
        if (!!form) {
            form.change('deceasedDetails', {
                ...currentDeceasedDetails,
                detailsOfDeath: {
                    ...currentDetailsOfDeath,
                    dateOfDeath: deathCertificate.dateOfDeath,
                    placeOfDeath: deathCertificate.placeOfDeath,
                    typeOfDeath: getTypeOfDeath(deathCertificate.causeOfDeath),
                },
            });
        }
    }

    return (
        <Grid container spacing={5} justify="flex-start">
            <Grid item xs={12} container justify="flex-end">
                <Button onClick={populateFromNoticeOfDeath} className={classes.copyButton}>
                    <FileCopy className={classes.copyIcon} />
                    Copy from notice of death
                </Button>
                <Button onClick={populateFromDeathCertificate} className={classes.copyButton}>
                    <FileCopy className={classes.copyIcon} />
                    Copy from death certificate
                </Button>
            </Grid>
            <Grid item xs={6}>
                <FullWidthDatePicker
                    name={`${prefix}dateOfDeath`}
                    validate={requiredValidator}
                    disableFuture
                />
            </Grid>
            <Grid item xs={6}>
                <FullWidthTextField name={`${prefix}placeOfDeath`} validate={requiredValidator} />
            </Grid>
            <Grid item xs={6}>
                <FullWidthSearchSelectField
                    name={`${prefix}typeOfDeath`}
                    label="Cause Of Death"
                    options={[
                        { value: 'Natural', label: 'Natural' },
                        { value: 'Unnatural', label: 'Unnatural' },
                        { value: 'Investigation', label: 'Under Investigation' },
                    ]}
                    validate={requiredValidator}
                />
                <WhenFieldChanges
                    field={`${prefix}typeOfDeath`}
                    set={`${prefix}causeOfDeath`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}typeOfDeath`}
                    condition={t => t !== 'Unnatural'}
                    set={`${prefix}reportedToPolice`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}typeOfDeath`}
                    condition={t => t !== 'Unnatural'}
                    set={`${prefix}policeStation`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}typeOfDeath`}
                    condition={t => t !== 'Unnatural'}
                    set={`${prefix}caseNumber`}
                    to={null}
                />
                <WhenFieldChanges
                    field={`${prefix}causeOfDeath`}
                    set={`${prefix}causeOfDeathDescription`}
                    to={null}
                />
            </Grid>
            <Condition when={`${prefix}typeOfDeath`} is={['Natural', 'Unnatural']}>
                <True>
                    <Field
                        name={`${prefix}typeOfDeath`}
                        subscription={{ value: true }}
                        component={({ input: { value: typeOfDeath } }) => {
                            const options =
                                typeOfDeath === 'Natural'
                                    ? NaturalCauseOfDeath
                                    : UnnaturalCauseOfDeath;
                            return (
                                <Grid item xs={6}>
                                    <FullWidthSearchSelectField
                                        name={`${prefix}causeOfDeath`}
                                        label="Condition"
                                        options={options}
                                    />
                                </Grid>
                            );
                        }}
                    />
                </True>
            </Condition>
            <Condition when={`${prefix}causeOfDeath`} is={['799', '798']}>
                <True>
                    <Grid item xs={6}>
                        <FullWidthTextField
                            name={`${prefix}causeOfDeathDescription`}
                            validate={requiredValidator}
                        />
                    </Grid>
                </True>
            </Condition>
            <Condition when={`${prefix}typeOfDeath`} is="Unnatural">
                <True>
                    <Grid item xs={6}>
                        <FullWidthRadioGroup
                            name={`${prefix}reportedToPolice`}
                            validate={requiredValidator}
                        >
                            <RadioOption value="Yes" />
                            <RadioOption value="No" />
                        </FullWidthRadioGroup>
                    </Grid>
                    <WhenFieldChanges
                        field={`${prefix}reportedToPolice`}
                        becomes="No"
                        set={`${prefix}policeStation`}
                        to={null}
                    />
                    <WhenFieldChanges
                        field={`${prefix}reportedToPolice`}
                        becomes="No"
                        set={`${prefix}caseNumber`}
                        to={null}
                    />
                    <Condition when={`${prefix}reportedToPolice`} is="Yes">
                        <True>
                            <Grid item xs={6}>
                                <FullWidthTextField name={`${prefix}policeStation`} />
                            </Grid>
                            <Grid item xs={6}>
                                <FullWidthTextField name={`${prefix}caseNumber`} />
                            </Grid>
                        </True>
                    </Condition>
                </True>
            </Condition>
        </Grid>
    );
};

function getTypeOfDeath(values?: string) {
    switch (values) {
        case 'Natural Causes':
            return 'Natural';
        case 'Unnatural Causes':
            return 'Unnatural';
        case 'Under Investigation':
            return 'Investigation';
        default:
            return undefined;
    }
}

const mapStateToProps = (state: State) => ({
    deathCertificate: getDeathCertificate(state),
    noticeOfDeath: getNoticeOfDeath(state),
});

export const DetailsOfDeathEdit = connect(mapStateToProps, {})(withStyles(styles)(detailsOfDeath));
