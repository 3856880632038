import { Divider, Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { ExpansionPanel } from '../../../../forms/form-components/expansion-panel/expansion-panel';
import { State } from '../../../../redux/root-reducer';
import { formatCurrency, formatPercent, frequencyMultiplier } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import { Dependant, getDependants, getDependantsDisplayName } from '../dependants/reducer';

interface SummaryProps {
    dependants: Dependant[];
}
interface Props extends StyledComponent, SummaryProps {}
const dependencySummary: React.FunctionComponent<Props> = ({ dependants }: Props) => {
    return (
        <ExpansionPanel name="Financial dependency summary">
            <SummaryView dependants={dependants} />
        </ExpansionPanel>
    );
};

interface Dependency {
    name: string;
    amount: number;
}
const SummaryView = ({ dependants }: SummaryProps) => {
    let totalMaintenance = 0;
    const dependencies: Dependency[] = [];
    dependants.forEach(dependant => {
        if (dependant.financialInformation) {
            const breakdown = dependant.financialInformation.breakdown;
            if (breakdown) {
                let totalAmount = 0;
                breakdown.forEach(info => {
                    totalAmount +=
                        ((info.maintenance || 0) * frequencyMultiplier(info.maintenanceFrequency)) /
                        12;
                });
                totalMaintenance += totalAmount;
                dependencies.push({
                    name: getDependantsDisplayName(dependant),
                    amount: totalAmount,
                });
            }
        }
    });
    return (
        <Grid container style={{ marginTop: '4px', marginBottom: '4px' }}>
            <Grid item xs={4}>
                <Typography variant="subtitle2">Dependant relationship</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="subtitle2">Amount</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="subtitle2">Percentage</Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider style={{ marginTop: '4px', marginBottom: '4px' }} />
            </Grid>
            {dependencies.map((d, index) => {
                return (
                    <React.Fragment key={`dependancy-${index}`}>
                        <Grid item xs={4}>
                            <Typography>{d.name}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>{formatCurrency(d.amount)}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>{formatPercent(d.amount / totalMaintenance)}</Typography>
                        </Grid>
                    </React.Fragment>
                );
            })}
            <Grid item xs={12}>
                <Divider
                    style={{ borderBottom: '3px double', marginTop: '4px', marginBottom: '4px' }}
                />
            </Grid>
            <Grid item xs={4}>
                <Typography>Total</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography>{formatCurrency(totalMaintenance)}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography>{formatPercent(1)}</Typography>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state: State) => ({
    dependants: getDependants(state),
});

export const DependencySummaryView = connect(
    mapStateToProps,
    {}
)(withStyles(styles)(dependencySummary));
