import { Button, Grid, Icon, Paper, Tooltip, withStyles } from '@material-ui/core';
import { FormApi } from 'final-form';
import React from 'react'; // eslint-disable-line
import { FormSpy } from 'react-final-form';
import { connect } from 'react-redux';
import {
    DialogContentDetails,
    EmptyDialogContent,
    OpenDialog,
    openDialogAction,
} from '../../confirmation-dialog';
import { GenericForm } from '../../forms';
import { deleteRecordReduction, updateRecordReduction } from '../../redux/reductions';
import { State } from '../../redux/root-reducer';
import { OnSubmitSuccessProps } from '../../workflow-navigation';
import {
    CloseModal,
    closeModalAction,
    RecordView,
    UpdateCurrentModalData,
    updateCurrentModalDataAction,
} from '../actions';
import { getRecordIndex } from '../reducer';
import styles, { StyledComponent } from '../styles';

interface ComponentState<TValues> {
    readonly records: TValues[];
    readonly recordIndex: number;
}

interface InnerProps<TValues> extends StyledComponent, ComponentState<TValues> {
    readonly recordDetailView: React.ReactNode | RecordView;
    readonly canUpdateRecord?: (values: TValues) => boolean;
    readonly updateRecord: (
        values: TValues,
        index: number
    ) => void | Promise<void | Record<string, any>> | Record<string, any>;
    readonly deleteRecord?: (
        index: number
    ) => void | Promise<void> | Record<string, any> | Promise<Record<string, any>>;
    readonly deleteDialogDetails?: DialogContentDetails;
    readonly saveDialogDetails?: DialogContentDetails;
    readonly openDialog: OpenDialog;
    readonly updateCurrentModalData: UpdateCurrentModalData;
    readonly closeModal: CloseModal;
}

interface Props<TValues> extends OnSubmitSuccessProps, InnerProps<TValues> {
    validate?: (values: TValues) => Record<string, any> | Promise<Record<string, any>>;
    decorator?: any;
}

interface FormProps<TValues> {
    readonly pristine: boolean;
    readonly form: FormApi<TValues>;
}

type InnerFormProps<TValues> = InnerProps<TValues> & FormProps<TValues>;

const defaultSaveDialogDetails: DialogContentDetails = {
    title: 'Warning Unsaved Changes',
    body: 'Current changes will be lost, would you like to save them?',
};

class EditFormModalFormWrapper<TValues> extends React.Component<
    Props<TValues>,
    ComponentState<TValues>
> {
    public constructor(props: Props<TValues>) {
        super(props);
        this.state = {
            records: this.props.records,
            recordIndex: this.props.recordIndex,
        };
    }

    public componentDidUpdate() {
        if (this.state.records.length === 0) {
            this.props.closeModal();
        }
    }

    public render() {
        const Form = GenericForm<TValues>();
        const records = this.state.records;
        const {
            closeModal,
            validate,
            decorator,
            classes,
            recordDetailView,
            canUpdateRecord,
            updateRecord,
            deleteRecord,
            deleteDialogDetails,
            saveDialogDetails,
            recordIndex,
            openDialog,
            updateCurrentModalData,
            ...rest
        }: Props<TValues> = this.props;
        if (records.length === 0) {
            return null;
        }

        let index = this.state.recordIndex;
        if (this.state.recordIndex < 0) {
            index = 0;
        }
        if (this.state.recordIndex >= records.length) {
            index = records.length - 1;
        }

        const initialValue = records[index];

        return (
            <Form
                initialValues={initialValue}
                onSubmit={this.onSubmit}
                validate={validate}
                decorators={decorator}
                {...rest}
            >
                {() =>
                    FormSpy<TValues>({
                        subscription: { pristine: true },
                        render: ({ form, pristine }) =>
                            this.editFormModal({
                                classes,
                                records,
                                recordDetailView,
                                updateRecord,
                                deleteRecord,
                                deleteDialogDetails,
                                saveDialogDetails,
                                recordIndex,
                                openDialog,
                                updateCurrentModalData,
                                closeModal,
                                pristine,
                                form,
                            }),
                    })
                }
            </Form>
        );
    }

    private editFormModal: React.FunctionComponent<InnerFormProps<TValues>> = ({
        classes,
        records,
        recordDetailView,
        canUpdateRecord,
        updateRecord,
        deleteRecord,
        deleteDialogDetails,
        saveDialogDetails = defaultSaveDialogDetails,
        recordIndex,
        openDialog,
        updateCurrentModalData,
        closeModal,
        pristine,
        form,
    }: InnerFormProps<TValues>) => {
        const canUpdate = !canUpdateRecord || canUpdateRecord(records[recordIndex]);
        const hideMoveButtons = records.length <= 1;

        const openDeleteDialog = (deleteAction: () => void) => {
            openDialog({
                content: deleteDialogDetails || EmptyDialogContent,
                yesAction: { action: deleteAction },
            });
        };

        const openSaveDialog = (saveAction: () => void, dontSaveAction: () => void) => {
            openDialog({
                content: saveDialogDetails,
                yesAction: { action: saveAction },
                noAction: { action: dontSaveAction },
                canCancel: true,
            });
        };

        const setSubmitFunction = (
            func: (
                record: TValues
            ) => void | Promise<void> | Record<string, any> | Promise<Record<string, any>>
        ) => {
            this.submitFunction = func;
            return undefined;
        };

        const save = async (record: TValues) => {
            const result = await updateRecord(record, recordIndex);
            return result;
        };

        const saveAndClose = async (record: TValues) => {
            const result = await updateRecord(record, recordIndex);
            if (!result || Object.keys(result).length === 0) {
                closeModal();
            }
            return result;
        };

        const move = (newIndex: number) => {
            if (newIndex >= 0 && newIndex < records.length) {
                this.setState({
                    recordIndex: newIndex,
                });
                updateCurrentModalData({
                    recordIndex: newIndex,
                });
            }
        };

        const confirmMove = (record: TValues, newIndex: number) => {
            if (!pristine && record && canUpdate) {
                openSaveDialog(
                    () => {
                        this.setState({
                            records: updateRecordReduction(records, record, recordIndex),
                        });
                        updateRecord(record, recordIndex);
                        move(newIndex);
                    },
                    () => {
                        move(newIndex);
                    }
                );
            } else {
                move(newIndex);
            }
        };

        const canMovePrevious = () => {
            const newIndex = recordIndex - 1;
            return newIndex >= 0;
        };
        const movePrevious = (record: TValues) => {
            const newIndex = recordIndex - 1;
            confirmMove(record, newIndex);
        };

        const canMoveNext = () => {
            const newIndex = recordIndex + 1;
            return newIndex < records.length;
        };
        const moveNext = (record: TValues) => {
            const newIndex = recordIndex + 1;
            confirmMove(record, newIndex);
        };

        const confirmDelete = () => {
            if (deleteRecord) {
                openDeleteDialog(() => {
                    this.setState({
                        records: deleteRecordReduction(records, recordIndex),
                    });
                    deleteRecord(recordIndex);
                });
            }
        };

        /* eslint-disable indent */
        const detailView =
            typeof recordDetailView === 'function'
                ? (recordDetailView as RecordView)()
                : recordDetailView;
        /* eslint-enable indent */

        return (
            <Paper className={classes.paper}>
                {detailView}
                <Grid container spacing={0} justify="flex-end">
                    {canUpdate && (
                        <Button
                            id="Save"
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={() => {
                                setSubmitFunction(save);
                                form.submit();
                            }}
                        >
                            Save
                        </Button>
                    )}
                    {canUpdate && (
                        <Button
                            id="SaveClose"
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={() => {
                                setSubmitFunction(saveAndClose);
                                form.submit();
                            }}
                            type="submit"
                        >
                            {'Save & Close'}
                        </Button>
                    )}
                    <Button
                        id="Cancel"
                        variant="contained"
                        className={classes.button}
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>
                    {!(hideMoveButtons && !canMovePrevious()) && (
                        <Button
                            id="MovePrevious"
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            disabled={!canMovePrevious()}
                            onClick={() => {
                                setSubmitFunction(movePrevious);
                                form.submit();
                            }}
                        >
                            <Icon>keyboard_arrow_left</Icon>
                        </Button>
                    )}
                    {!(hideMoveButtons && !canMoveNext()) && (
                        <Button
                            id="MoveNext"
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            disabled={!canMoveNext()}
                            onClick={() => {
                                setSubmitFunction(moveNext);
                                form.submit();
                            }}
                        >
                            <Icon>keyboard_arrow_right</Icon>
                        </Button>
                    )}
                    {canUpdate && deleteRecord && (
                        <Tooltip title="Delete">
                            <Button
                                id="Delete"
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                onClick={confirmDelete}
                            >
                                <Icon>delete</Icon>
                            </Button>
                        </Tooltip>
                    )}
                </Grid>
            </Paper>
        );
    };

    private submitFunction: (
        values: TValues
    ) => void | Promise<void> | Record<string, any> | Promise<Record<string, any>> = () => {};

    private onSubmit = (values: TValues) => {
        if (values) {
            return this.submitFunction(values);
        }
    };
}

const mapStateToProps = (state: State) => ({
    recordIndex: getRecordIndex(state),
});

const mapDispatchToProps = {
    openDialog: openDialogAction,
    updateCurrentModalData: updateCurrentModalDataAction,
    closeModal: closeModalAction,
};

export function EditFormModal<TValues>() {
    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        withStyles(styles)(
            EditFormModalFormWrapper as new (
                props: Props<TValues>
            ) => EditFormModalFormWrapper<TValues>
        )
    );
}
