import { Button, Grid, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { getClaim } from '../../../../claim/reducers';
import { State } from '../../../../redux/root-reducer';
import styles from '../../../../styles/styles';
import {
    askSynopsisType,
    generateUnderwrittingSynopsis,
    trusteeResolution,
} from './actions/get-file';
import { ClaimType } from '../../../../claim/claim-types';
import { ButtonWrapper, NonRaDeathWrapper, RaDeathWrapper } from '../../../../forms';
import { AuthGuard } from '../../../../auth/authGuard';
const hasUnderwritterAccess = AuthGuard(['Agent']);

function generateSynopsis({
    classes,
    claimId,
    claimType,
    generateSynopsisAndGetUrl,
    generateTrusteeResolution,
    generateUnderwrittingSynopsis,
}: any) {
    switch (claimType) {
        case ClaimType.Funeral:
            return null;
        case ClaimType.RADeath:
            return (
                <ButtonWrapper>
                    <RaDeathWrapper>
                        <Grid container justify="flex-end" className={classes.marginTop3}>
                            <Button
                                id="GenerateSynopsis"
                                color="secondary"
                                variant="contained"
                                className={classes.button}
                                onClick={() => generateTrusteeResolution(claimId)}
                            >
                                Generate Trustee Resolution
                            </Button>
                        </Grid>
                    </RaDeathWrapper>
                </ButtonWrapper>
            );
        default:
            if (!hasUnderwritterAccess()) {
                return (
                    <NonRaDeathWrapper>
                        <ButtonWrapper>
                            <Grid container justify="flex-end" className={classes.marginTop3}>
                                <Button
                                    id="GenerateSynopsis"
                                    color="secondary"
                                    variant="contained"
                                    className={classes.button}
                                    onClick={() => generateUnderwrittingSynopsis(claimId)}
                                >
                                    Generate Synopsis
                                </Button>
                            </Grid>
                        </ButtonWrapper>
                    </NonRaDeathWrapper>
                );
            }
            return (
                <NonRaDeathWrapper>
                    <ButtonWrapper>
                        <Grid container justify="flex-end" className={classes.marginTop3}>
                            <Button
                                id="GenerateSynopsis"
                                color="secondary"
                                variant="contained"
                                className={classes.button}
                                onClick={() => generateSynopsisAndGetUrl(claimId)}
                            >
                                Generate Synopsis
                            </Button>
                        </Grid>
                    </ButtonWrapper>
                </NonRaDeathWrapper>
            );
    }
}

const mapStateToProps = (state: State) => {
    const claim = getClaim(state);
    return {
        claimId: claim.claimId,
        claimType: claim.claimType,
    };
};

const mapDispatchToProps = {
    generateSynopsisAndGetUrl: askSynopsisType,
    generateTrusteeResolution: trusteeResolution,
    generateUnderwrittingSynopsis: generateUnderwrittingSynopsis,
};

export const GenerateSynopsis = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(generateSynopsis));
