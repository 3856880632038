import React from 'react'; // eslint-disable-line
import { withStyles, Grid, Paper, Tooltip, Button } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { OpenAddModal, openAddModalAction } from '../../../form-modal/actions';
import { AddFormModal } from '../../../form-modal';
import { State } from '../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../styles/styles';
import { AddAndSubmitPayment, addAndSubmitPaymentThunk } from '../../actions/submit-payment';
import { Payment, BeneficiaryOption, getBeneficiaryOptions } from '../../reducer';
import { AddBeneficiaryDetailView } from '../beneficiary/add-beneficiary-modal';
import { BeneficiaryPaymentsView } from './payment-beneficiaries-view';
import { getClaim } from '../../../claim';
import { Nullable } from '../../../shared-types';
import { ClaimType } from '../../../claim/claim-types';
import { ButtonWrapper } from '../../../forms';
import { openToastAction } from '../../../confirmation-dialog';
import {
    CheckBeneficiary,
    checkBeneficiaryAction,
} from '../../../assessment/synopsis/death/policy-details/action/update-beneficiary-details';

interface OwnProps {}

interface StateProps {
    beneficiaryOptions: BeneficiaryOption[];
    claimType: Nullable<ClaimType>;
    payments: Payment[];
    isReadOnly: boolean;
}

interface DispatchProps {
    addPayment: AddAndSubmitPayment;
    openAddModal: OpenAddModal;
    checkBeneficiary: CheckBeneficiary;
    openToast?: any;
}

type Props = DispatchProps & OwnProps & StateProps & StyledComponent;
const paymentDetails: React.FunctionComponent<Props> = ({
    addPayment,
    beneficiaryOptions,
    claimType,
    classes,
    openAddModal,
    payments,
    isReadOnly,
    openToast,
    checkBeneficiary,
}: Props) => {
    const filteredOptions: BeneficiaryOption[] = [];
    for (const index in beneficiaryOptions) {
        const paymentIndex = payments.findIndex(
            p => p.beneficiaryId === beneficiaryOptions[index].value
        );
        if (paymentIndex === -1) {
            filteredOptions.push(beneficiaryOptions[index]);
        }
    }

    return (
        <Paper className={classes.paper}>
            <Grid item xs={12} lg={12} className={classes.rightAlign}>
                {addBeneficiaryButton(
                    addPayment,
                    filteredOptions,
                    openAddModal,
                    isReadOnly,
                    claimType,
                    checkBeneficiary,
                    openToast
                )}
            </Grid>
            <BeneficiaryPaymentsView />
        </Paper>
    );
};
const PaymentAddFormModal = AddFormModal<Payment>();
const addBeneficiaryButton = (
    addPayment: AddAndSubmitPayment,
    beneficiaryOptions: BeneficiaryOption[],
    openAddModal: OpenAddModal,
    isReadOnly: boolean,
    claimType: Nullable<ClaimType>,
    checkBeneficiary: CheckBeneficiary,
    openToast?: any
) => {
    if (beneficiaryOptions.length < 1 || isReadOnly) {
        return null;
    }

    const addBeneficiary = () => {
        const addBeneficiaryToast = (payment: Payment) => {
            return addPayment(payment, claimType).then((resp: any) => {
                checkBeneficiary();
            });
        };

        openAddModal({
            modalContent: (
                <PaymentAddFormModal
                    recordDetailView={
                        <AddBeneficiaryDetailView beneficiaryOptions={beneficiaryOptions} />
                    }
                    addRecord={payment => addBeneficiaryToast(payment)}
                />
            ),
            modalData: {},
        });
    };
    return (
        <ButtonWrapper>
            <Tooltip title="Add Beneficiary">
                <Button
                    style={{ textTransform: 'none' }}
                    id="AddBeneficiary"
                    color="secondary"
                    onClick={addBeneficiary}
                >
                    <AddCircle fontSize="small" />
                    Add Beneficiary
                </Button>
            </Tooltip>
        </ButtonWrapper>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => {
    const claim = getClaim(state);
    return {
        beneficiaryOptions: getBeneficiaryOptions(claim),
        claimType: claim.claimType,
        isReadOnly: state.claimSession.readOnly,
        payments: claim.payments,
    };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    openToast: (content: any) => dispatch(openToastAction(content)),
    openAddModal: options => dispatch(openAddModalAction(options)),
    addPayment: (payment: Payment, claimType: Nullable<ClaimType>) =>
        dispatch(addAndSubmitPaymentThunk(payment, claimType)),
    checkBeneficiary: () => dispatch(checkBeneficiaryAction()),
});

export const PaymentDetails = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(paymentDetails));
