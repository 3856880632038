import { ModalAction } from '../reducer';
import { OPEN_MODAL, UPDATE_CURRENT_MODAL_DATA } from '../types';

interface EditModalData {
    readonly recordIndex: number;
}

export interface OpenEditModalOptions {
    readonly modalContent: React.ReactNode;
    readonly modalData: EditModalData;
}

export type OpenEditModal = (options: OpenEditModalOptions) => ModalAction;
export const openEditModalAction: OpenEditModal = (options: OpenEditModalOptions) => {
    return {
        type: OPEN_MODAL,
        payload: {
            modalOpen: true,
            modalContent: options.modalContent,
            modalData: options.modalData,
        },
    };
};

export type UpdateEditModalData = (modalData: EditModalData) => ModalAction;
export const updateEditModalData: UpdateEditModalData = (modalData: any) => {
    return {
        type: UPDATE_CURRENT_MODAL_DATA,
        payload: {
            modalData,
        },
    };
};
