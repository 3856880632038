import React from 'react'; // eslint-disable-line

import { Paper, withStyles } from '@material-ui/core';
import { connect, MapStateToProps } from 'react-redux';
import { FormHeading } from '../../../../forms';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { GenerateSynopsis } from '../../../synopsis/common/generate-synopsis';

import { AssessmentDetailsView } from './assessment-details';
import { AddFuneralAssessmentDecisionProps } from './assessment-decision-add';
import {
    AssessmentDetails,
    getFuneralAssessmentDetails,
} from '../funeral-assessment-details-reducer';

interface OwnProps {
    readonly assessmentDecisionAddView:
        | React.FunctionComponent<AddFuneralAssessmentDecisionProps>
        | React.ComponentClass<AddFuneralAssessmentDecisionProps>;
}

interface StateProps {
    readonly assessmentDetails: AssessmentDetails[];
}

type Props = StateProps & OwnProps & StyledComponent;
const funeralAssessmentDetailsView: React.FunctionComponent<Props> = ({
    assessmentDetails,
    assessmentDecisionAddView,
    classes,
}: Props) => {
    return (
        <React.Fragment>
            <Paper className={classes.paper}>
                <FormHeading>Assessment Decisions</FormHeading>
                {assessmentDetails && (
                    <AssessmentDetailsView
                        assessmentDetails={assessmentDetails}
                        assessmentDecisionAddView={assessmentDecisionAddView}
                    />
                )}
            </Paper>
            <GenerateSynopsis />
        </React.Fragment>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    assessmentDetails: getFuneralAssessmentDetails(state),
});

const mapDispatchToProps = {};

export const FuneralAssessmentDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(funeralAssessmentDetailsView));
