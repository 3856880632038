import { WorkflowScreen } from './workflow-screen';
import { WorkflowScreenContainer } from './workflow-screen-container';

export class WorkflowStep extends WorkflowScreenContainer {
    public cloneWithNewParent(parent?: WorkflowScreen): WorkflowStep {
        return new WorkflowStep(
            this.label,
            this.children(),
            parent,
            this.screenHeading,
            this.canShow
        );
    }
}
