export const ClaimCategory = [
    { value: 'The cardiovascular system', label: 'The cardiovascular system' },
    { value: 'The digestive system', label: 'The digestive system' },
    { value: 'The skin', label: 'The skin' },
    { value: 'The endocrine system', label: 'The endocrine system' },
    { value: 'The visual system', label: 'The visual system' },
    { value: 'Mental and behavioral disorders', label: 'Mental and behavioral disorders' },
    { value: 'The limbs', label: 'The limbs' },
    {
        value: 'Activities of daily living catch-all',
        label: 'Activities of daily Living catch-all',
    },
    { value: 'The respiratory system', label: 'The respiratory system' },
    { value: 'The urinary system', label: 'The urinary system' },
    { value: 'The haemopoietic system (blood)', label: 'The haemopoietic system (blood)' },
    {
        value: 'Ear, nose, throat and related structures',
        label: 'Ear, nose, throat and related structures',
    },
    { value: 'The central nervous system', label: 'The central nervous system' },
    { value: 'The spine', label: 'The spine' },
    { value: 'Cancer', label: 'Cancer' },
];
