import { Button, Divider, Grid, Paper, Tooltip, withStyles } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { AuthState } from '../../../../../auth/auth-reducer';
import { LabeledValue } from '../../../../../controls/labeled-value';
import { AddFormModal } from '../../../../../form-modal';
import { OpenAddModal, openAddModalAction } from '../../../../../form-modal/actions';
import { ButtonWrapper } from '../../../../../forms';
import { ExpansionPanel } from '../../../../../forms/form-components/expansion-panel/expansion-panel';
import { State } from '../../../../../redux/root-reducer';
import { getDisplayDate } from '../../../../../shared-functions';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { AddAndSubmitComments, addAndSubmitCommentsThunk } from '../actions/add-comments';
import { Comments, getComments } from '../reducer';
import { CommentsViewEdit } from './edit-comments';

interface DetailsProps extends StyledComponent {
    comments: Comments[];
}

interface Props extends DetailsProps {
    addComments: AddAndSubmitComments;
    currentUser: AuthState;
    openAddModal: OpenAddModal;
}
const commentsView: React.FunctionComponent<Props> = ({ ...props }: Props) => {
    return (
        <ExpansionPanel name="Comments">
            <CommentsInnerView {...props} />
        </ExpansionPanel>
    );
};

const CommentsAddFormModal = AddFormModal<Comments>();
const CommentsInnerView = ({
    addComments,
    classes,
    comments,
    currentUser,
    openAddModal,
}: Props) => {
    const add = () => {
        openAddModal({
            modalContent: (
                <CommentsAddFormModal
                    blankRecord={{
                        assessor: `${currentUser.name} (${currentUser.userId})`,
                        date: new Date(),
                        id: uuid(),
                    }}
                    recordDetailView={<CommentsViewEdit />}
                    addRecord={addComments}
                />
            ),
            modalData: {},
        });
    };
    return (
        <React.Fragment>
            <ButtonWrapper>
                <Grid container justify="flex-end">
                    <Tooltip title="Add Comment">
                        <Button id="AddComment" color="secondary" onClick={add}>
                            <AddCircle />
                        </Button>
                    </Tooltip>
                </Grid>
            </ButtonWrapper>
            <CommentDetails classes={classes} comments={comments} />
        </React.Fragment>
    );
};

const CommentDetails = ({ classes, comments }: DetailsProps) => {
    if (comments.length === 0) {
        return null;
    }
    return (
        <Paper className={classes.paper}>
            {comments.map((comment, index) => {
                return (
                    <Grid container justify="flex-start" key={comment.id}>
                        {index > 0 && (
                            <Grid item xs={12}>
                                <Divider style={{ margin: '2px -16px 8px -16px' }} />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <LabeledValue label="Comment" value={comment.comment} isUnsafe />
                        </Grid>
                        <Grid item xs={6}>
                            <LabeledValue label="Assessor" value={comment.assessor} />
                        </Grid>
                        <Grid item xs={6}>
                            <LabeledValue label="Date" value={getDisplayDate(comment.date)} />
                        </Grid>
                    </Grid>
                );
            })}
        </Paper>
    );
};

const mapStateToProps = (state: State) => ({
    comments: getComments(state),
    currentUser: state.auth as AuthState,
});

const mapDispatchToProps = {
    addComments: addAndSubmitCommentsThunk,
    openAddModal: openAddModalAction,
};

export const CommentsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(commentsView));
