import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './root-reducer';

function create(history: History) {
    const windowIfDefined = typeof window === 'undefined' ? null : (window as any);
    const composeEnhancer = windowIfDefined.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(
        rootReducer,
        composeEnhancer(applyMiddleware(thunk, routerMiddleware(history)))
    );
    return store;
}
export default create;
