import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { GenericForm } from '../../forms';
import { State } from '../../redux/root-reducer';
import styles, { StyledComponent } from '../../styles/styles';
import { OnSubmitSuccessProps } from '../../workflow-navigation';
import { Submit, submitAction } from '../actions/submit';
import {
    getRetrospectiveUnderwritingDecision,
    RetrospectiveUnderwritingDecisionDetails,
} from '../reducer';
import FinalDecisionDetailsGridView from './final-decision-details-grid';
import { RetrospectiveUnderwritingView } from './retrospective-underwriting-view';

type InnerProps = StyledComponent & {
    hideFinalDecision?: boolean;
};

type Props = OnSubmitSuccessProps &
    InnerProps & {
        submit: Submit;
        decisionDetails?: RetrospectiveUnderwritingDecisionDetails;
    };

const Form = GenericForm<RetrospectiveUnderwritingDecisionDetails>();
const retrospectiveUnderwritingDecisionForm: React.FunctionComponent<Props> = ({
    classes,
    decisionDetails,
    hideFinalDecision,
    submit,
    ...rest
}: Props) => {
    return (
        <Form onSubmit={values => submit(values || {})} initialValues={decisionDetails} {...rest}>
            <InnerForm classes={classes} hideFinalDecision={hideFinalDecision} />
        </Form>
    );
};

const InnerForm: React.FunctionComponent<InnerProps> = ({
    classes,
    hideFinalDecision,
}: InnerProps) => {
    return (
        <React.Fragment>
            <RetrospectiveUnderwritingView />
            {!hideFinalDecision && <FinalDecisionDetailsGridView classes={classes} />}
        </React.Fragment>
    );
};

const mapStateToProps = (state: State) => ({
    decisionDetails: getRetrospectiveUnderwritingDecision(state).decisionDetails,
});

const mapDispatchToProps = {
    submit: submitAction,
};

export const RetrospectiveUnderwritingDecisionForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(retrospectiveUnderwritingDecisionForm));
