import React from 'react'; // eslint-disable-line

import { Grid, Paper, Typography, withStyles, Button, Tooltip } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { getClaim } from '../../../../claim';
import { Code } from '../../../../codes/codes';
import { LifeAssuredContract, Beneficiary } from '../../../../life-assured-details/reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { LabeledValue } from '../../../../controls/labeled-value';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { OpenEditModal, openEditModalAction } from '../../../../form-modal/actions';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { State } from '../../../../redux/root-reducer';
import { EditFormModal } from '../../../../form-modal';
import { Dependant, getDependantsFromClaim } from '../../ra-death/dependants/reducer';
import { BeneficiaryDetailsView } from './beneficiary-details-edit-modal';
import {
    Claimant,
    getDeathClaimContactDetailsFromClaim,
} from '../contact-details/contact-details-reducer';
import { updateBeneficiaryAction, UpdateBeneficiary } from './action/update-beneficiary-details';
import { EntityType } from '../../../../shared-types';
import { ButtonWrapper } from '../../../../forms';
import { ClaimType } from '../../../../claim/claim-types';

interface DispatchProps {
    openEditModal: OpenEditModal;
    updateBeneficiary: UpdateBeneficiary;
}

interface OwnProps {
    contractIndex: number;
    claimType: ClaimType;
}

interface StateProps {
    claimants: Code<string>[];
}

type Props = StyledComponent &
    DispatchProps &
    OwnProps &
    StateProps & {
        contract: LifeAssuredContract;
    };

const BeneficiaryEditFormModal = EditFormModal<Beneficiary>();
const beneficiaryDetails: React.FunctionComponent<Props> = ({
    classes,
    contract,
    claimants,
    openEditModal,
    contractIndex,
    updateBeneficiary,
    claimType,
}: Props) => {
    if (!contract.beneficiaries || contract.beneficiaries.length === 0) {
        return null;
    }

    const editBeneficiary = (beneficiaryIndex: number) => {
        openEditModal({
            modalContent: (
                <BeneficiaryEditFormModal
                    recordDetailView={<BeneficiaryDetailsView claimants={claimants} />}
                    records={contract.beneficiaries}
                    updateRecord={beneficiary =>
                        updateBeneficiary(beneficiary, contractIndex, beneficiaryIndex)
                    }
                />
            ),
            modalData: {
                recordIndex: beneficiaryIndex,
            },
        });
    };

    const getBeneficiaryName = (linkToClaimant?: string): string => {
        const claimant = claimants.find(c => c.value === linkToClaimant);
        return claimant ? claimant.label : '';
    };
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography variant="subtitle2">Beneficiary Details</Typography>
            </Grid>
            {contract.beneficiaries.map((beneficiary, beneficiaryIndex) => {
                if (!beneficiary.personalDetails) {
                    return null;
                }
                return (
                    <Paper className={classes.paper} key={`${beneficiaryIndex}beneficiary`}>
                        <Grid container justify="flex-start">
                            <Grid container spacing={0} item xs={10} lg={11}>
                                <Typography variant="h5">
                                    {!!beneficiary.personalDetails &&
                                    (beneficiary.beneficiaryType === 'Individual' ||
                                        beneficiary.beneficiaryType === 'Estate')
                                        ? beneficiary.personalDetails.firstName
                                        : ''}
                                    {!!beneficiary.organisationDetails &&
                                    beneficiary.beneficiaryType === 'Organisation'
                                        ? beneficiary.organisationDetails.companyName
                                        : ''}
                                </Typography>
                            </Grid>
                            <ButtonWrapper>
                                <Grid item xs={2} lg={1} style={{ textAlign: 'right' }}>
                                    <Tooltip title="Link to claimant">
                                        <Button
                                            id="linkToClaimant"
                                            color="secondary"
                                            onClick={() => editBeneficiary(beneficiaryIndex)}
                                        >
                                            <Edit fontSize="small" />
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            </ButtonWrapper>
                            {!!beneficiary.personalDetails &&
                                beneficiary.beneficiaryType === 'Individual' && (
                                    <React.Fragment>
                                        <Grid item xs={3} lg={3}>
                                            <LabeledValue
                                                label="Linked to claimant"
                                                value={getBeneficiaryName(
                                                    beneficiary.linkToClaimant
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3} lg={3}>
                                            <LabeledValue
                                                label="Relationship to life assured"
                                                value={
                                                    beneficiary.personalDetails
                                                        .relationshipToLifeAssured === 'Other'
                                                        ? beneficiary.personalDetails
                                                              .relationshipToLifeAssuredDescription
                                                        : beneficiary.personalDetails
                                                              .relationshipToLifeAssured
                                                }
                                            />
                                        </Grid>
                                    </React.Fragment>
                                )}
                            {!!beneficiary.organisationDetails &&
                                beneficiary.beneficiaryType === 'Organisation' && (
                                    <React.Fragment>
                                        <Grid item xs={3} lg={3}>
                                            <LabeledValue
                                                label="Linked to claimant"
                                                value={getBeneficiaryName(
                                                    beneficiary.linkToClaimant
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={3} lg={3}>
                                            <LabeledValue
                                                label="Name"
                                                value={beneficiary.organisationDetails.companyName}
                                            />
                                        </Grid>
                                        <Grid item xs={3} lg={3}>
                                            <LabeledValue
                                                label="Relationship to life Assured"
                                                value={
                                                    beneficiary.organisationDetails
                                                        .relationshipToLifeAssured !== 'Other'
                                                        ? beneficiary.organisationDetails
                                                              .relationshipToLifeAssured
                                                        : beneficiary.organisationDetails
                                                              .relationshipToLifeAssuredDescription
                                                }
                                            />
                                        </Grid>
                                    </React.Fragment>
                                )}
                            <Grid item xs={3} lg={3}>
                                <LabeledValue label="% Split" value={beneficiary.percentageSplit} />
                            </Grid>
                        </Grid>
                    </Paper>
                );
            })}
        </React.Fragment>
    );
};

export const getClaimantsList = (claimants?: Claimant[]) => {
    if (!claimants || claimants.length === 0) {
        return [];
    }
    const claimantList: Code<string>[] = [];
    claimants.forEach(claimant => {
        let name = '';
        if (claimant && claimant.claimantDetails) {
            if (
                claimant.claimantDetails.claimantType === EntityType.Individual ||
                claimant.claimantDetails.claimantType === EntityType.Executor
            ) {
                name = claimant.claimantDetails.personalDetails
                    ? `${claimant.claimantDetails.personalDetails.firstName} ${claimant.claimantDetails.personalDetails.lastName}`
                    : '';
            } else if (claimant.claimantDetails.claimantType === EntityType.Organisation) {
                name = claimant.claimantDetails.organisationDetails
                    ? `${claimant.claimantDetails.organisationDetails.companyName}`
                    : '';
            }
        }
        claimantList.push({ value: claimant.id || '', label: name });
    });

    return claimantList;
};

export const getDependantsList = (dependants?: Dependant[]) => {
    if (!dependants || dependants.length === 0) {
        return [];
    }
    const dependantList: Code<string>[] = [];
    dependants.forEach(dependant => {
        let name = '';
        if (dependant) {
            if (dependant.dependantType === EntityType.Individual) {
                name = dependant.personalDetails
                    ? `${dependant.personalDetails.firstName || ''} ${
                          dependant.personalDetails.lastName || ''
                      }`
                    : '';
            } else if (dependant.dependantType === EntityType.Organisation) {
                name = dependant.organisationDetails
                    ? dependant.organisationDetails.companyName || ''
                    : '';
            }
        }
        dependantList.push({ value: dependant.id || '', label: name });
    });

    return dependantList;
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => {
    let claimants: Code<string>[] = [];
    const claim = getClaim(state);

    if (claim.claimType === 'Death') {
        claimants = getClaimantsList(getDeathClaimContactDetailsFromClaim(claim).claimants);
    }
    if (claim.claimType === 'RADeath') {
        claimants = getDependantsList(getDependantsFromClaim(claim));
    }
    return {
        claimants: claimants,
        isReadOnly: state.claimSession.readOnly,
        claimType: claim.claimType,
    };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    openEditModal: options => dispatch(openEditModalAction(options)),
    updateBeneficiary: (
        beneficiary: Beneficiary,
        contractIndex: number,
        beneficiaryIndex: number
    ) => dispatch(updateBeneficiaryAction(beneficiary, contractIndex, beneficiaryIndex)),
});

export const BeneficiaryDetails = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(beneficiaryDetails));
