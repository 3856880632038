import React from 'react'; // eslint-disable-line
import styles, { StyledComponent } from '../../styles/styles';
import { withStyles } from '@material-ui/core';
import { Grid, Typography } from '@material-ui/core';
import JSONPretty from 'react-json-pretty';
import { connect } from 'react-redux';

interface OwnProps {
    errorMessage: string;
    jsonString: string;
}

type Props = OwnProps & StyledComponent;

const jsonErrorView: React.FunctionComponent<Props> = ({
    errorMessage,
    jsonString,
    classes,
}: Props) => {
    return (
        <React.Fragment>
            <Typography>{errorMessage}</Typography>
            <Grid container spacing={5} justify="space-between" className={classes.marginTop}>
                <Grid item></Grid>
                <Grid item>
                    <JSONPretty id="json-pretty" data={jsonString}></JSONPretty>
                </Grid>
                <Grid item></Grid>
            </Grid>
        </React.Fragment>
    );
};

export const JsonErrorView = connect(null, null)(withStyles(styles)(jsonErrorView));
