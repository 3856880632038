import { claimSubmit } from '../../../../../claim';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { DeathClaimDetails } from '../../../death/claim-event-details/reducer';
import { getRAClaimDetailsFromClaim } from '../reducer';

type SubmitReturn = Promise<any>;
export type UpdateRAClaimDetails = (deathClaimDetails: DeathClaimDetails) => SubmitReturn;
type UpdateRAClaimDetailsAction = (
    deathClaimDetails: DeathClaimDetails
) => ReduxThunkAction<SubmitReturn>;

export const updateRAClaimDetailsAction: UpdateRAClaimDetailsAction = (
    deathClaimDetails: DeathClaimDetails
) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, claim => {
            return {
                ...claim,
                claimDetails: {
                    ...getRAClaimDetailsFromClaim(claim),
                    claimEventDetails: deathClaimDetails,
                },
            };
        });
    };
};
