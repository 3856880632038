import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { hideLoadingAction, showLoadingAction } from '../../forms/loading-screen/actions';
import { ReduxThunkAction } from '../../redux/redux-actions';
import { State } from '../../redux/root-reducer';
import { getAccount, redirectToLogin, sleep, tryGetTokenSilent } from '../authentication-service';
import { setActiveUserActionCreator } from './set-active-user';

type GetAuthTokenReturn = Promise<string>;
export type GetAuthToken = () => GetAuthTokenReturn;
type GetAuthTokenThunk = () => ReduxThunkAction<GetAuthTokenReturn>;
export const getAuthTokenThunk: GetAuthTokenThunk = () => {
    return async dispatch => {
        const accessToken = await getAccessToken(dispatch);
        if (accessToken) {
            dispatch(setActiveUserActionCreator(getAccount()));
        }
        return accessToken;
    };
};

async function getAccessToken(dispatch: ThunkDispatch<State, null, AnyAction>): Promise<string> {
    dispatch(showLoadingAction());
    let token = await tryGetTokenSilent();
    if (token) {
        dispatch(hideLoadingAction());
        return token.accessToken;
    }
    // sometimes the library takes time to set the token :/
    await sleep(5000);
    token = await tryGetTokenSilent();
    if (token) {
        dispatch(hideLoadingAction());
        return token.accessToken;
    }
    redirectToLogin();
    return '';
}
