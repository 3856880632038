import React from 'react'; // eslint-disable-line
import { withStyles, Grid } from '@material-ui/core';
import { BankingDetails } from '../../../assessment/synopsis/common/banking-details/banking-details';
import { LabeledValue } from '../../../controls/labeled-value';
import { getCodeDescription, GetVerificationWidget } from '../../../shared-functions';
import styles, { StyledComponent } from '../../../styles/styles';

type Props = StyledComponent & {
    bankingDetails: BankingDetails;
    riskStatus?: string;
    screeningDate?: Date;
};
const paymentBankingDetailsView: React.FunctionComponent<Props> = ({ bankingDetails }: Props) => {
    if (!bankingDetails) {
        return null;
    }
    return (
        <React.Fragment>
            <Grid item xs={12} container justify="flex-start">
                <Grid item xs={4} lg={2}>
                    <LabeledValue
                        label="Account holder name"
                        value={`${bankingDetails.accountHoldersInitials || ''} ${
                            bankingDetails.accountHoldersName || ''
                        }`}
                    />
                </Grid>
                <Grid item xs={4} lg={2}>
                    <LabeledValue
                        label="Bank Name"
                        value={getCodeDescription('bankName', bankingDetails.bankName)}
                    />
                </Grid>
                <Grid item xs={4} lg={2}>
                    <LabeledValue
                        label="Bank Account Number"
                        value={bankingDetails.accountNumber}
                    />
                </Grid>
                <Grid item xs={4} lg={2}>
                    <LabeledValue label="Branch Name" value={bankingDetails.bankBranch} />
                </Grid>
                <Grid item xs={4} lg={1}>
                    <LabeledValue label="Branch Code" value={bankingDetails.branchCode} />
                </Grid>
                <Grid
                    item
                    xs={4}
                    lg={2}
                    container
                    justify="flex-start"
                    style={{ marginLeft: '12px' }}
                >
                    <Grid item xs={12} lg={8}>
                        <LabeledValue label="Account Type" value={bankingDetails.accountType} />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <LabeledValue
                            label="Status"
                            value={GetVerificationWidget(
                                bankingDetails.bankAccountStatus || 'Not verified'
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={4} lg={2}>
                    <LabeledValue
                        label="Special Instructions"
                        value={bankingDetails.specialInstructions || 'None'}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export const PaymentBankingDetailsView = withStyles(styles)(paymentBankingDetailsView);
