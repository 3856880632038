import { Grid, withStyles } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { Section } from '../../../../controls/section';
import {
    FormErrors,
    FullWidthReadonlyField,
    FullWidthTextField,
    GenericForm,
} from '../../../../forms';
import { PercentageMask } from '../../../../forms/input-masks/percentage-mask';
import { requiredValidator } from '../../../../forms/validations';
import { LifeAssuredContract } from '../../../../life-assured-details/reducer';
import { convertToNumber } from '../../../../shared-functions';
import styles, { StyledComponent } from '../../../../styles/styles';
import { OnSubmitSuccessProps } from '../../../../workflow-navigation';
import {
    ContractModel,
    UpdateContract,
    updateContractAction,
} from '../../actions/update-contract-details';

type Props = StyledComponent &
    OnSubmitSuccessProps & {
        benefitName?: string;
        policyDetails: LifeAssuredContract;
        updateContract: UpdateContract;
    };

const PolicyDetailsForm = GenericForm<ContractModel>();
const editPolicyDetails: React.FunctionComponent<Props> = ({
    benefitName,
    policyDetails,
    updateContract,
    ...rest
}: Props) => {
    const onSubmit = (values: ContractModel) => updateContract(values);
    const initialValues = {
        policyNumber: policyDetails.contractNumber,
        benefitName: benefitName,
        premiumPatternDesc: policyDetails.premiumPatternDesc,
        aciPercentage: policyDetails.aciPercentage,
    };
    return (
        <PolicyDetailsForm initialValues={initialValues} onSubmit={onSubmit} {...rest}>
            <Section title="Policy Details">
                <Grid item xs={6}>
                    <FullWidthReadonlyField name="policyNumber" />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthReadonlyField name="benefitName" />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        name="premiumPatternDesc"
                        label="Premium Pattern"
                        validate={requiredValidator}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        name="aciPercentage"
                        label="ACI %"
                        mask={PercentageMask}
                        parse={convertToNumber}
                        validate={requiredValidator}
                    />
                </Grid>
            </Section>
            <FormErrors />
        </PolicyDetailsForm>
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    updateContract: updateContractAction,
};

export const EditPolicyDetails = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(editPolicyDetails));
