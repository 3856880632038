import { Claim, claimSubmit } from '../../../../../claim';
import { ReduxThunkAction } from '../../../../../redux/redux-actions';
import { Requirements } from '../reducer';

type SubmitReturn = Promise<any>;
export type UpdateRequirements = (requirements: Requirements) => SubmitReturn;
type UpdateRequirementsAction = (requirements: Requirements) => ReduxThunkAction<SubmitReturn>;

export const updateRequirementsAction: UpdateRequirementsAction = (requirements: Requirements) => {
    return async (dispatch, getState) => {
        return claimSubmit(dispatch, getState, getUpdatedClaim(requirements));
    };
};

function getUpdatedClaim(requirements: Requirements): (claim: Claim) => Claim {
    return (claim: Claim) => {
        return {
            ...claim,
            requirements,
        };
    };
}
