import { DeleteRecordCollectionAction } from '../../../../../redux/collection-reducer';
import { DELETE_INFORMATION_RECORD } from '../types';

export type DeleteInformationRecordAction = DeleteRecordCollectionAction;

export const deleteInformationRecordActionCreator = (
    informationRecordIndex: number
): DeleteInformationRecordAction => {
    return {
        type: DELETE_INFORMATION_RECORD,
        index: informationRecordIndex,
    };
};
