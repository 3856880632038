import React from 'react'; // eslint-disable-line

import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { GenericForm } from '../../forms';
import { State } from '../../redux/root-reducer';
import { OnSubmitSuccessProps } from '../../workflow-navigation';
import { Submit, submitAction } from '../actions/submit';
import { ContactPersonDetails } from '../contact-person/contact-person-details';
import CaptureClaimContactDetailsView from '../contact-person/view';
import { FinancialAdviserContactDetails } from '../financial-adviser/financial-adviser-contact-details';
import CaptureBrokerContactDetailsView from '../financial-adviser/view';
import { getContactDetails } from '../reducer';
import { validateContactDetails } from '../validate';
import styles, { StyledComponent } from './styles';
import {
    FormDetails,
    getFormDetails,
} from '../../assessment/synopsis/living-lifestyle/form-details/form-details-reducer';

interface Props extends OnSubmitSuccessProps, StyledComponent {
    contactPerson?: ContactPersonDetails;
    formDetails?: FormDetails;
    financialAdviser?: FinancialAdviserContactDetails;
    submit: Submit;
}

const ContactDetailsForm = GenericForm<{
    contactPerson?: ContactPersonDetails;
    financialAdviser?: FinancialAdviserContactDetails;
}>();
const contactDetailsView: React.FunctionComponent<Props> = ({
    classes,
    contactPerson,
    formDetails,
    financialAdviser,
    submit,
    ...rest
}: Props) => {
    const formContactDetails = {
        title: formDetails?.claimant?.personalDetails?.title,
        firstName: formDetails?.claimant?.personalDetails?.firstName,
        lastName: formDetails?.claimant?.personalDetails?.surname,
        sameDetailsAsFa: false,
        telephoneNumber: formDetails?.claimant?.contactDetails?.telephoneNumber,
        cellphoneNumber: formDetails?.claimant?.contactDetails?.cellphoneNumber,
        initials: formDetails?.claimant?.personalDetails?.initials,
        faxNumber: formDetails?.claimant?.contactDetails?.faxNumber,
        emailAddress: formDetails?.claimant?.contactDetails?.emailAddress,
        preferredMethodOfCommunication:
            formDetails?.claimant?.contactDetails?.preferredMethodOfCommunication,
    };

    const financialAdviserContact = {
        telephoneNumber: financialAdviser?.telephoneNumber
            ? financialAdviser?.telephoneNumber
            : formDetails?.claimant?.contactDetails?.telephoneNumber,
        cellphoneNumber: financialAdviser?.cellphoneNumber
            ? financialAdviser?.cellphoneNumber
            : formDetails?.claimant?.contactDetails?.cellphoneNumber,
        faxNumber: financialAdviser?.faxNumber
            ? financialAdviser?.faxNumber
            : formDetails?.claimant?.contactDetails?.faxNumber,
        emailAddress: financialAdviser?.emailAddress
            ? financialAdviser?.emailAddress
            : formDetails?.claimant?.contactDetails?.emailAddress,
        preferredMethodOfCommunication: financialAdviser?.preferredMethodOfCommunication
            ? financialAdviser?.preferredMethodOfCommunication
            : formDetails?.claimant?.contactDetails?.preferredMethodOfCommunication,
        initials: financialAdviser?.initials
            ? financialAdviser?.initials
            : formDetails?.claimant?.personalDetails?.initials,
        title: financialAdviser?.title
            ? financialAdviser?.title
            : formDetails?.claimant?.personalDetails?.title,
        firstName: financialAdviser?.firstName
            ? financialAdviser?.firstName
            : formDetails?.claimant?.personalDetails?.firstName,
        lastName: financialAdviser?.lastName
            ? financialAdviser?.lastName
            : formDetails?.claimant?.personalDetails?.surname,
        companyName: financialAdviser?.companyName
            ? financialAdviser?.companyName
            : formDetails?.claimant?.personalDetails?.companyName,
        consultantCode: financialAdviser?.consultantCode
            ? financialAdviser?.consultantCode
            : formDetails?.claimant?.personalDetails?.consultantCode,
    };

    return (
        <ContactDetailsForm
            onSubmit={submit}
            initialValues={{
                contactPerson: contactPerson
                    ? contactPerson
                    : formDetails?.claimant?.policyDetails?.isOwner === 'Yes' ||
                      formDetails?.claimant?.policyDetails?.claimingCapacity === 'Relative'
                    ? formContactDetails
                    : contactPerson,
                financialAdviser:
                    formDetails?.claimant?.policyDetails?.claimingCapacity === 'Financial Advisor'
                        ? financialAdviserContact
                        : financialAdviser,
            }}
            validate={validateContactDetails}
            {...rest}
        >
            <CaptureBrokerContactDetailsView classes={classes} />
            <CaptureClaimContactDetailsView classes={classes} />
        </ContactDetailsForm>
    );
};

const mapStateToProps = (state: State) => ({
    contactPerson: getContactDetails(state).contactPerson,
    financialAdviser: getContactDetails(state).financialAdviser,
    formDetails: getFormDetails(state),
});

const mapDispatchToProps = {
    submit: submitAction,
};

export const ContactDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(contactDetailsView));
